import React, { ReactElement } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../assets/svg/CloseIcon';
import Confetti from '../components/elements/Confetti';

interface Props {
    showModal: boolean;
    setShowModal: any;
    setOnClickNextChallenge: (value: boolean) => void;
    setOnClickTryAgain: (value: boolean) => void;
    challengeName: string;
}

export default function CompleteChallenge({
    showModal,
    setShowModal,
    setOnClickNextChallenge,
    setOnClickTryAgain,
    challengeName,
}: Props): ReactElement {
    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    const handleOnClickNextChallenge = () => {
        setOnClickNextChallenge(true);
        setOnClickTryAgain(false);
        setShowModal(false);
    };

    const handleOnClickTryAgain = () => {
        setOnClickTryAgain(true);
        setOnClickNextChallenge(false);
        setShowModal(false);
    };

    return (
        <div>
            <Modal isOpen={showModal} style={customStyles} ariaHideApp={false}>
                <>
                    <div className="flex-shrink justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
                        <Confetti />
                        <div style={{ width: '531px' }} className="relative my-6  mx-auto">
                            {/* content */}
                            <div
                                className="absolute z-50 top-6 right-6 cursor-pointer"
                                onClick={() => handleOnClickTryAgain()}
                            >
                                <CloseIcon size={24} color="rgba(17, 17, 17, 0.48)" />
                            </div>
                            <div
                                className="rounded-lg shadow-lg relative flex flex-col items-center w-full bg-white outline-none focus:outline-none px-4 py-6"
                                style={{ maxHeight: '324px' }}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        top: '-90px',
                                    }}
                                    className="flex flex-col items-center"
                                >
                                    <span className="challengeComplete self-center"></span>
                                    <div className="text-purple-500 mt-4">What a Champ!</div>
                                    <div className="mb-2">
                                        <h1 className="text-xl font-bold">Congratulations!</h1>
                                    </div>
                                    <div className="text-purple-500 mb-8 text-center">
                                        {`You have successfully completed ${
                                            challengeName || 'the challenge'
                                        }. Keep attempting more challenges to score
                                        more.`}
                                    </div>
                                    <div className="flex flex-row gap-2">
                                        <button
                                            className="w-40 px-2 text-purple-700 h-12 bg-white border border-purple-700 rounded font-bold"
                                            onClick={() => handleOnClickTryAgain()}
                                        >
                                            Try Again
                                        </button>
                                        <button
                                            className="w-40 px-2 bg-purple-700 h-12 text-white rounded font-bold"
                                            onClick={() => handleOnClickNextChallenge()}
                                        >
                                            Next Challenge
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        </div>
    );
}
