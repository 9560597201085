import React, { ReactElement, useState } from 'react';
import * as RiIcons from 'react-icons/ri';
import DatePicker from 'react-datepicker';

interface Props {
    title?: string;
    setDateFrom?: any;
    setDateTo?: any;
    dateFrom?: any;
    DateTo?: any;
    label1?: any;
    label2?: any;
}

export default function FilterDropdownByDate({
    title,
    setDateFrom,
    setDateTo,
    dateFrom,
    DateTo,
    label1,
    label2,
}: Props): ReactElement {
    const [isExpand, setIsExpand] = useState(false);

    return (
        <div className="flex flex-col my-1.5 w-full">
            <div className="flex flex-row" onClick={() => setIsExpand(!isExpand)}>
                {isExpand ? (
                    <RiIcons.RiArrowDropDownLine style={{ height: '25px', width: '25px' }} />
                ) : (
                    <RiIcons.RiArrowDropRightLine style={{ height: '25px', width: '25px' }} />
                )}
                <label className="flex flex-col mb-1.5 cursor-pointer" htmlFor="tab_1">
                    {title}
                </label>
            </div>

            {isExpand && (
                <div className="flex flex-row">
                    <div className="flex flex-col pl-3 pb-2">
                        <label className="flex flex-col mb-1.5 cursor-pointer" htmlFor="tab_1">
                            {label1}
                        </label>
                        <DatePicker
                            required
                            showYearDropdown
                            showMonthDropdown
                            yearDropdownItemNumber={112}
                            scrollableYearDropdown
                            className="text-sm h-10 p-2 inputFieldThickness rounded"
                            selected={dateFrom ? new Date(dateFrom) : null}
                            onChange={date => {
                                if (date != null) {
                                    setDateFrom(date);
                                }
                            }}
                            dateFormat="do MMMM yyyy"
                            placeholderText="From"
                        />
                    </div>
                    <div className="flex flex-col pl-3 pb-2">
                        <label className="flex flex-col mb-1.5 cursor-pointer" htmlFor="tab_1">
                            {label2}
                        </label>
                        <DatePicker
                            required
                            showYearDropdown
                            showMonthDropdown
                            yearDropdownItemNumber={112}
                            scrollableYearDropdown
                            className="text-sm h-10 p-2 inputFieldThickness rounded"
                            selected={DateTo ? new Date(DateTo) : null}
                            onChange={date => {
                                if (date != null) {
                                    setDateTo(date);
                                }
                            }}
                            dateFormat="do MMMM yyyy"
                            placeholderText="To"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
