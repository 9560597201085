import React from 'react';

function DownArrow() {
    return (
        <svg
            width="15"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.514648 1.4648L8.99965 9.94981L17.4846 1.4648L16.0696 0.0498047L8.99965 7.1218L1.92865 0.0498047L0.514648 1.4648Z"
                fill="#4C2EC2"
            />
        </svg>
    );
}

export default DownArrow;
