import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { RootState } from '../../redux/rootReducer';
import {
    bulkUpdateChallenges,
    bulkUpdateEvents,
    bulkUpdateStudent,
    bulkUpdate,
    getTags,
} from '../../redux/rootActions';
import { TagsType } from '../../redux/tags/reducers';

interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    tabStatus: string;
    selectedStudents?: any;
    selectedRecords?: any;
    selectedTracks?: any;
    selectedChallenges?: any;
    selectedEvents?: any;
    moduleName?: string;
}

export default function AssignTagModal({
    modalVisible,
    setModalVisible,
    tabStatus,
    selectedStudents,
    selectedRecords,
    selectedTracks,
    selectedChallenges,
    selectedEvents,
    moduleName,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selectedTag, setSelectedTag] = useState<TagsType>();
    const [value, setValue] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const tagsData = useSelector((state: RootState) => state.tags.tagData);

    const obj = {
        listToUpdate: selectedChallenges
            ? selectedChallenges?.map(elem => {
                  return {
                      tagId: selectedTag?.id,
                      id: elem?.id,
                  };
              })
            : selectedEvents
            ? selectedEvents?.map(elem => {
                  return {
                      tagId: selectedTag?.id,
                      id: elem?.id,
                  };
              })
            : selectedStudents
            ? selectedStudents?.map(elem => {
                  return {
                      tagId: selectedTag?.id,
                      id: elem?.id,
                  };
              })
            : selectedRecords
            ? selectedRecords?.map(elem => {
                  return {
                      tagId: selectedTag?.id,
                      id: parseInt(elem?.studentId),
                  };
              })
            : selectedTracks?.map(elem => {
                  return {
                      tagId: selectedTag?.id,
                      id: elem?.id,
                  };
              }),
    };

    const serchByNameOrId = e => {
        setValue(e.target.value);
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        dispatch(getTags(true, moduleName, searchTerm));
        setDropdownVisible(true);
    }, [dispatch, searchTerm, moduleName]);

    return (
        <Modal
            open={modalVisible}
            onClose={() => {
                setModalVisible(false);
            }}
            center
            showCloseIcon={true}
            styles={{
                modal: {
                    borderRadius: 5,
                    maxWidth: '628px',
                    width: '400px',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    overflowY: 'visible',
                    boxShadow: '0 12px 15px 0 rgb(0 0 0 / 10%)',
                },
                overlay: { background: 'none' },
                closeButton: { width: '20px' },
            }}
        >
            <div className="">
                <div className="text-base text-medium pb-4">Assign Tags</div>
                <div className="flex">
                    <div className="w-1/3">Select Tag</div>
                    <div className="text-base text-center text-gray-800 pb-4 px-2 w-full">
                        <div>
                            <input
                                className={`rounded-md flex cursor-pointer items-center focus:outline-none w-full md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                id="id"
                                type="text"
                                placeholder="Search tags"
                                onChange={e => serchByNameOrId(e)}
                                autoComplete="off"
                                value={value}
                            />
                            {tagsData?.length > 0 && dropdownVisible && (
                                <ul
                                    className="w-full top-14 left-0 z-10 pl-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    role="menu"
                                >
                                    {tagsData.map(tag => (
                                        <div
                                            className="p-2 hover:bg-gray-200 flex justify-start cursor-pointer"
                                            key={tag.id}
                                            onClick={() => {
                                                setValue(tag.tagName);
                                                setSelectedTag(tag);
                                                setDropdownVisible(false);
                                            }}
                                        >
                                            {tag.tagCategory + ': ' + tag.tagName}
                                        </div>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex justify-end w-full">
                    <button
                        className={`bg-blue-700 text-white rounded-sm focus:outline-none px-8 mr-2 md:mr-0 py-2`}
                        onClick={() => {
                            if (selectedChallenges) {
                                dispatch(bulkUpdateChallenges(obj, tabStatus));
                            } else if (selectedEvents) {
                                dispatch(bulkUpdateEvents(obj, tabStatus));
                            } else if (selectedTracks) {
                                dispatch(bulkUpdate(obj, tabStatus));
                            } else {
                                dispatch(bulkUpdateStudent(obj, tabStatus));
                            }
                            setModalVisible(false);
                        }}
                    >
                        Assign
                    </button>
                </div>
            </div>
        </Modal>
    );
}
