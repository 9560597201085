import React, { ReactElement } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

interface Props {
    showModal: boolean;
    setShowModal: any;
    selectTrack?: any;
    challengeId?: any;
    selectedChallenge?: any;
}

export default function RepublishedChallengeMessageModal({
    showModal,
    setShowModal,
    selectTrack,
    challengeId,
    selectedChallenge,
}: Props): ReactElement {
    const history = useHistory();

    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    return (
        <div>
            <Modal isOpen={showModal} style={customStyles}>
                <>
                    <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                        <div style={{ width: '643px' }} className="relative my-6  mx-auto">
                            {/* content */}
                            <div
                                className="rounded-lg shadow-lg relative flex flex-col items-center w-full bg-white outline-none focus:outline-none p-6"
                                style={{ maxHeight: '656px' }}
                            >
                                <div className="mb-4">
                                    <h1 className="text-xl font-bold">
                                        Challenge has been Updated
                                    </h1>
                                </div>
                                <div className="mb-8 text-center">
                                    This challenge has been updated with more new content. Try it
                                    again now to earn more points in this challenge.
                                </div>
                                <button
                                    className="w-40 px-2 bg-purple-950 h-12 text-white rounded font-bold"
                                    onClick={() => {
                                        history.push({
                                            pathname: `/dashBoard/learn/Challenges/${challengeId}`,
                                            state: {
                                                path: `/dashBoard/myProgress`,
                                                track: selectTrack,
                                                challenges: selectedChallenge,
                                            },
                                        });
                                    }}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        </div>
    );
}
