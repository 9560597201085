import React, { ReactElement } from 'react';

interface Props {
    size: number;
}

export default function ClockIcon({ size }: Props): ReactElement {
    return (
        <svg
            height={size || '59'}
            width={size || '59'}
            viewBox="0 0 59 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M29.4748 4.9165C15.9048 4.9165 4.91602 15.9298 4.91602 29.4998C4.91602 43.0698 15.9048 54.0832 29.4748 54.0832C43.0694 54.0832 54.0827 43.0698 54.0827 29.4998C54.0827 15.9298 43.0694 4.9165 29.4748 4.9165ZM29.4994 49.1665C18.6335 49.1665 9.83268 40.3657 9.83268 29.4998C9.83268 18.634 18.6335 9.83317 29.4994 9.83317C40.3652 9.83317 49.166 18.634 49.166 29.4998C49.166 40.3657 40.3652 49.1665 29.4994 49.1665Z"
                fill="#4C2EC2"
            />
            <path
                d="M30.7285 17.2085H27.041V31.9585L39.9473 39.7022L41.791 36.6785L30.7285 30.1147V17.2085Z"
                fill="#4C2EC2"
            />
        </svg>
    );
}
