import React, { ReactElement } from 'react';

interface Props {
    width: number;
    height: number;
}

export default function RemoveBin({ width, height }: Props): ReactElement {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 409 409"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="409" height="409" fill="#00000000" />
            <g clipPath="url(#clip0_0_1)">
                <path
                    d="M87.748 388.784C88.209 399.794 97.269 408.483 108.287 408.483H300.198C311.216 408.483 320.276 399.794 320.737 388.784L334.442 99.468H74.043L87.748 388.784ZM247.655 171.329C247.655 166.719 251.393 162.98 256.005 162.98H269.36C273.969 162.98 277.71 166.718 277.71 171.329V336.622C277.71 341.233 273.972 344.971 269.36 344.971H256.005C251.395 344.971 247.655 341.235 247.655 336.622V171.329ZM189.216 171.329C189.216 166.719 192.954 162.98 197.565 162.98H210.92C215.529 162.98 219.269 166.718 219.269 171.329V336.622C219.269 341.233 215.532 344.971 210.92 344.971H197.565C192.955 344.971 189.216 341.235 189.216 336.622V171.329ZM130.775 171.329C130.775 166.719 134.513 162.98 139.124 162.98H152.48C157.09 162.98 160.829 166.718 160.829 171.329V336.622C160.829 341.233 157.091 344.971 152.48 344.971H139.124C134.514 344.971 130.775 341.235 130.775 336.622V171.329Z"
                    fill="#E75346"
                />
                <path
                    d="M343.567 21.043H255.032V4.305C255.032 1.928 253.105 0 250.727 0H157.756C155.379 0 153.452 1.928 153.452 4.305V21.042H64.916C57.791 21.042 52.016 26.818 52.016 33.943V74.47H356.467V33.944C356.467 26.819 350.692 21.043 343.567 21.043Z"
                    fill="#E75346"
                />
            </g>
            <defs>
                <clipPath id="clip0_0_1">
                    <rect width="408.483" height="408.483" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
