import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { accountType } from '../../../../../constants';
import { getCompanyPublicData } from '../../../../../redux/rootActions';
import { RootState } from '../../../../../redux/rootReducer';
import CompanyCard from './CompanyCard';

const Companies = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const companyOpenData = useSelector((state: RootState) => state.companies.companyPublicData);
    const authToken = useSelector((state: RootState) => state.authStudent.authToken);

    const [viewButton, setViewButton] = useState(false);

    useEffect(() => {
        dispatch(getCompanyPublicData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <div className="hidden lg:block">
                <div className="bg-purple-85 pb-32 lg:pb-44 xl:pb-56 4xl:pb-80">
                    <div className="flex justify-center bg-purple-85">
                        <div className="space-y-5">
                            <div className="mt-12 text-xl lg:text-2xl-2 flex justify-center font-bold font-manrope">
                                Are you a Sri Lankan tech company looking to hire the most
                                passionate youth of the country?
                            </div>
                            <div className=" text-purple-950 flex justify-center text-xl font-manrope font-semibold">
                                Partner with BRIDGE to get access to 20,000+ youth and to onboard
                                them to your organisation.
                            </div>
                            <div className="flex justify-center">
                                <div
                                    onClick={() => {
                                        history.push(
                                            authToken ? '/dashBoard/leaderboard' : '/leaderboard'
                                        );
                                    }}
                                    className="border-1 rounded bg-purple-950 text-white w-56 h-12 flex justify-center items-center font-bold cursor-pointer"
                                >
                                    View Student Leaderboard
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            left: '0',
                            right: '0',
                        }}
                        className="flex justify-center space-x-7 mt-5 px-10"
                    >
                        <picture>
                            <source
                                media="(min-width: 1920px)"
                                srcSet={`/assets/company1Large.png`}
                            />
                            <source media="(min-width: 1440px)" srcSet={`/assets/company1.png`} />
                            <source media="(min-width: 1024px)" srcSet={`/assets/company1.png`} />
                            <img className="" src={`/assets/company1.png`} alt="logo" />
                        </picture>
                        <picture className="rounded">
                            <source
                                media="(min-width: 1920px)"
                                srcSet={`/assets/company2Large.jpg`}
                            />
                            <source media="(min-width: 1440px)" srcSet={`/assets/company2.jpg`} />
                            <source media="(min-width: 1024px)" srcSet={`/assets/company2.jpg`} />
                            <img className="rounded" src={`/assets/company2.jpg`} alt="logo" />
                        </picture>
                        <picture>
                            <source
                                media="(min-width: 1920px)"
                                srcSet={`/assets/company3Large.jpg`}
                            />
                            <source media="(min-width: 1440px)" srcSet={`/assets/company3.jpg`} />
                            <source media="(min-width: 1024px)" srcSet={`/assets/company3.jpg`} />
                            <img className="rounded" src={`/assets/company3.jpg`} alt="logo" />
                        </picture>
                        <picture>
                            <source
                                media="(min-width: 1920px)"
                                srcSet={`/assets/company4Large.jpg`}
                            />
                            <source media="(min-width: 1440px)" srcSet={`/assets/company4.jpg`} />
                            <source media="(min-width: 1024px)" srcSet={`/assets/company4.jpg`} />
                            <img className="rounded" src={`/assets/company4.jpg`} alt="logo" />
                        </picture>
                        <picture>
                            <source
                                media="(min-width: 1920px)"
                                srcSet={`/assets/company5Large.png`}
                            />
                            <source media="(min-width: 1440px)" srcSet={`/assets/company5.png`} />
                            <source media="(min-width: 1024px)" srcSet={`/assets/company5.png`} />
                            <img className="rounded" src={`/assets/company5.png`} alt="logo" />
                        </picture>
                    </div>
                </div>
                <div className="flex flex-row px-20 lg:px-32 4xl:px-64 mt-32 xl:mt-40 4xl:mt-52 justify-between">
                    <div className="flex items-center mr-2">
                        <div>
                            <div className="mt-8 w-72 xl:w-96 lg:text-xl lg:text-2xl-2 font-bold font-manrope">
                                Are you a SLASSCOM member? <br />
                                Talk to corporate office to get access to BRIDGE student database.
                            </div>
                            <div
                                onClick={() => {
                                    window.open('https://slasscom.lk/', '_blank');
                                }}
                                className="mt-8 border-1 rounded border-2 border-purple-950 text-purple-950 text-sm lg:text-base w-36 h-8 lg:w-48 lg:h-12 flex justify-center items-center font-bold cursor-pointer"
                            >
                                SLASSCOM Website
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end">
                        <div className="flex space-x-4 lg:space-x-6 xl:space-x-10">
                            <picture>
                                <source
                                    media="(min-width: 1920px)"
                                    srcSet={`/assets/company8Large.jpg`}
                                />
                                <source
                                    media="(min-width: 1440px)"
                                    srcSet={`/assets/company6.png`}
                                />
                                <source
                                    media="(min-width: 1024px)"
                                    srcSet={`/assets/company6.png`}
                                />
                                <img className="rounded " src={`/assets/company6.png`} alt="logo" />
                            </picture>
                            <picture>
                                <source
                                    media="(min-width: 1920px)"
                                    srcSet={`/assets/company7Large.jpg`}
                                />
                                <source
                                    media="(min-width: 1440px)"
                                    srcSet={`/assets/company7.jpg`}
                                />
                                <source
                                    media="(min-width: 1024px)"
                                    srcSet={`/assets/company7.jpg`}
                                />
                                <img className="rounded " src={`/assets/company7.jpg`} alt="logo" />
                            </picture>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row px-20 lg:px-32 4xl:px-64 mt-12 justify-between">
                    <div className="flex">
                        <div className="flex space-x-4 lg:space-x-6 xl:space-x-10">
                            <picture>
                                <source
                                    media="(min-width: 1920px)"
                                    srcSet={`/assets/company8Large.jpg`}
                                />
                                <source
                                    media="(min-width: 1440px)"
                                    srcSet={`/assets/company8.jpg`}
                                />
                                <source
                                    media="(min-width: 1024px)"
                                    srcSet={`/assets/company8.jpg`}
                                />
                                <img className="rounded " src={`/assets/company8.jpg`} alt="logo" />
                            </picture>
                            <picture>
                                <source
                                    media="(min-width: 1920px)"
                                    srcSet={`/assets/company9Large.png`}
                                />
                                <source
                                    media="(min-width: 1440px)"
                                    srcSet={`/assets/company9.png`}
                                />
                                <source
                                    media="(min-width: 1024px)"
                                    srcSet={`/assets/company9.png`}
                                />
                                <img className="rounded " src={`/assets/company9.png`} alt="logo" />
                            </picture>
                        </div>
                    </div>

                    <div className="flex items-center ml-2 justify-end">
                        <div>
                            <div className="w-72 xl:w-96 mt-2 xl:mt-8 lg:text-xl lg:text-2xl-2 font-bold font-manrope">
                                Not a SLASSCOM member? You can still request to join BRIDGE program.
                            </div>
                            <div
                                onClick={() => {
                                    history.push('/companies/joinSlasscom');
                                }}
                                className="mt-8 border-1 rounded border-2 border-purple-950 text-sm lg:text-base text-purple-950 w-36 h-8 lg:w-48 lg:h-12 flex justify-center items-center font-bold cursor-pointer"
                            >
                                Request to Join
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-12">
                    <div className="flex justify-center">
                        <div className="text-xl lg:text-2xl-2 font-manrope font-bold">
                            Become a partner of BRIDGE and get access to the most passionate youth
                            of the country.
                        </div>
                    </div>
                    <div className="flex mt-4 justify-center">
                        <div className="text-xl">
                            View our partner companies who joined hands with us!
                        </div>
                    </div>

                    <div className=" flex flex-col mt-1 justify-center items-center">
                        {companyOpenData
                            ?.filter(
                                company => company.accountType === accountType.PREMIUM_CORPORATE
                            )
                            .map(company => (
                                <CompanyCard key={company.id} company={company} />
                            ))}
                        <div
                            className="bg-purple-85 rounded-md w-72 h-40 flex items-center justify-center mx-3 mt-8 cursor-pointer"
                            onMouseOver={() => {
                                setViewButton(true);
                            }}
                            onMouseOut={() => {
                                setViewButton(false);
                            }}
                            onClick={() => {
                                history.push(`/view-all/company`);
                            }}
                        >
                            <div className="text-purple-950 font-semibold border-b-2 border-purple-950">
                                View more
                            </div>
                            <img className="ml-4" src={`/assets/ViewMoreArrow.png`} alt="logo" />
                            {/* {viewButton ? <div>View more</div> : null} */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:hidden">
                <div className="">
                    <div className="flex justify-center items-center bg-purple-85 px-6 p-4">
                        <div className="space-y-5 mb-2">
                            <div className="text-center text-lg flex justify-center font-bold font-manrope">
                                Are you a Sri Lankan tech company looking to hire the most
                                passionate youth of the country?
                            </div>
                            <div className=" text-center text-purple-950 flex justify-center text-xs font-manrope font-semibold">
                                Partner with BRIDGE to get access to 20,000+ youth and to onboard
                                them to your organisation.
                            </div>
                            <div className="flex justify-center">
                                <div
                                    onClick={() => {
                                        history.push('/leaderboard');
                                    }}
                                    className="border-1 rounded bg-purple-950 text-white px-4 h-12 flex justify-center items-center font-bold cursor-pointer"
                                >
                                    View Student Leaderboard
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center h-44 w-full overflow-hidden">
                        <div className="h-full w-full relative">
                            <img
                                className="rounded"
                                src={`/assets/company1Large.png`}
                                alt="logo"
                                style={{
                                    height: '80%',
                                    position: 'absolute',
                                    left: '0',
                                    right: '0',
                                    zIndex: 5,
                                }}
                            />

                            <img
                                className="rounded"
                                src={`/assets/company2Large.jpg`}
                                alt="logo"
                                style={{
                                    height: '90%',
                                    position: 'absolute',
                                    left: '15%',
                                    right: '0',
                                    zIndex: 8,
                                }}
                            />

                            <img
                                className="rounded"
                                src={`/assets/company3Large.jpg`}
                                alt="logo"
                                style={{
                                    height: '100%',
                                    position: 'absolute',
                                    left: '35%',
                                    right: '0',
                                    zIndex: 10,
                                }}
                            />

                            <img
                                className="rounded"
                                src={`/assets/company4Large.jpg`}
                                alt="logo"
                                style={{
                                    height: '90%',
                                    position: 'absolute',
                                    left: '50%',
                                    right: '0',
                                    zIndex: 8,
                                }}
                            />

                            <img
                                className="rounded"
                                src={`/assets/company5Large.png`}
                                alt="logo"
                                style={{
                                    height: '80%',
                                    position: 'absolute',
                                    left: '75%',
                                    right: '0',
                                    zIndex: 5,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col px-4 mt-4 justify-between gap-y-5">
                    <div className="flex flex-col mr-2 gap-y-4 mt-8 w-full">
                        <div className="text-lg font-bold font-manrope">
                            Are you a SLASSCOM member? <br />
                            Talk to corporate office to get access to BRIDGE student database.
                        </div>
                        <div className="flex">
                            <div
                                onClick={() => {
                                    window.open('https://slasscom.lk/', '_blank');
                                }}
                                className="rounded border-2 border-purple-950 text-purple-950 px-4 h-12 flex justify-center items-center font-bold cursor-pointer"
                            >
                                SLASSCOM Website
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col">
                        <div className="flex gap-x-3 w-full">
                            <img
                                className="rounded"
                                style={{ width: '48%' }}
                                src={`/assets/company6.png`}
                                alt="logo"
                            />
                            <img
                                className="rounded"
                                style={{ width: '48%' }}
                                src={`/assets/company7.jpg`}
                                alt="logo"
                            />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col px-4 mt-2 justify-between gap-y-5">
                    <div className="flex flex-col mr-2 gap-y-4 mt-4 w-full">
                        <div className="text-lg font-bold font-manrope">
                            Not a SLASSCOM member? You can still request to join BRIDGE program.
                        </div>
                        <div className="flex">
                            <div
                                onClick={() => {
                                    history.push('/companies/joinSlasscom');
                                }}
                                className="rounded border-2 border-purple-950 text-purple-950 px-4 h-12 flex justify-center items-center font-bold cursor-pointer"
                            >
                                Request to Join
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col">
                        <div className="flex gap-x-3 w-full">
                            <img
                                className="rounded"
                                style={{ width: '48%' }}
                                src={`/assets/company8.jpg`}
                                alt="logo"
                            />
                            <img
                                className="rounded"
                                style={{ width: '48%' }}
                                src={`/assets/company9.png`}
                                alt="logo"
                            />
                        </div>
                    </div>
                </div>

                <div className="my-12 px-4">
                    <div className="flex justify-center">
                        <div className="text-center text-lg font-manrope font-bold">
                            Become a partner of BRIDGE and get access to the most passionate youth
                            of the country.
                        </div>
                    </div>
                    <div className="flex mt-4 justify-center">
                        <div className="text-center">
                            View our partner companies who joined hands with us!
                        </div>
                    </div>

                    <div className=" flex mt-1 flex-wrap justify-center">
                        {companyOpenData
                            ?.filter(
                                company => company.accountType === accountType.PREMIUM_CORPORATE
                            )
                            .map(company => (
                                <CompanyCard key={company.id} company={company} />
                            ))}
                        <div
                            className="bg-purple-85 rounded-md w-72 4xl:w-80 h-40 4xl:h-52 flex items-center justify-center mx-3 mt-8 cursor-pointer"
                            onMouseOver={() => {
                                setViewButton(true);
                            }}
                            onMouseOut={() => {
                                setViewButton(false);
                            }}
                            onClick={() => {
                                history.push(`/view-all/company`);
                            }}
                        >
                            <div className="text-purple-950 font-semibold border-b-2 border-purple-950">
                                View more
                            </div>
                            <img className="ml-4" src={`/assets/ViewMoreArrow.png`} alt="logo" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Companies;
