import React, { ReactElement } from 'react';

export default function LockCircle(): ReactElement {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="19.375" cy="20.625" r="15.625" fill="white" />
            <path
                d="M19.9997 3.33203C10.7997 3.33203 3.33301 10.7987 3.33301 19.9987C3.33301 29.1987 10.7997 36.6654 19.9997 36.6654C29.1997 36.6654 36.6663 29.1987 36.6663 19.9987C36.6663 10.7987 29.1997 3.33203 19.9997 3.33203ZM19.9997 33.332C12.6497 33.332 6.66634 27.3487 6.66634 19.9987C6.66634 12.6487 12.6497 6.66536 19.9997 6.66536C27.3497 6.66536 33.333 12.6487 33.333 19.9987C33.333 27.3487 27.3497 33.332 19.9997 33.332Z"
                fill="#4C2EC2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8109 17.9522C25.4946 17.9522 26.1502 18.2237 26.6337 18.7072C27.1171 19.1906 27.3887 19.8463 27.3887 20.53V25.6856C27.3887 26.3692 27.1171 27.0249 26.6337 27.5084C26.1502 27.9918 25.4946 28.2634 24.8109 28.2634H14.4997C13.816 28.2634 13.1603 27.9918 12.6769 27.5084C12.1935 27.0249 11.9219 26.3692 11.9219 25.6856V20.53C11.9219 19.8463 12.1935 19.1906 12.6769 18.7072C13.1603 18.2237 13.816 17.9522 14.4997 17.9522V15.3744C14.4997 14.007 15.0429 12.6957 16.0097 11.7288C16.9766 10.7619 18.2879 10.2188 19.6553 10.2188C21.0226 10.2188 22.334 10.7619 23.3008 11.7288C24.2677 12.6957 24.8109 14.007 24.8109 15.3744V17.9522ZM19.6553 11.9373C20.5669 11.9373 21.4411 12.2994 22.0857 12.944C22.7302 13.5886 23.0924 14.4628 23.0924 15.3744V17.9522H16.2182V15.3744C16.2182 14.4628 16.5803 13.5886 17.2249 12.944C17.8695 12.2994 18.7437 11.9373 19.6553 11.9373ZM24.8109 19.6707H14.4997C14.2718 19.6707 14.0532 19.7612 13.8921 19.9224C13.7309 20.0835 13.6404 20.3021 13.6404 20.53V25.6856C13.6404 25.9135 13.7309 26.132 13.8921 26.2932C14.0532 26.4543 14.2718 26.5448 14.4997 26.5448H24.8109C25.0388 26.5448 25.2573 26.4543 25.4185 26.2932C25.5796 26.132 25.6702 25.9135 25.6702 25.6856V20.53C25.6702 20.3021 25.5796 20.0835 25.4185 19.9224C25.2573 19.7612 25.0388 19.6707 24.8109 19.6707Z"
                fill="#4C2EC2"
            />
        </svg>
    );
}
