import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TracksType } from '../../../redux/tracks/reducers';
import { s3UrlPrefix } from '../../../constants';
import * as FaIcons from 'react-icons/fa';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroupsView from '../../TagGroupsView';
interface CustomState {
    track: TracksType;
}
export default function ViewTrack() {
    const location = useLocation();
    const history = useHistory();
    const state = location.state as CustomState;
    const [imageUrl] = useState(`${s3UrlPrefix}${state?.track?.imageLink}`);
    const [imageLocation] = useState(state?.track?.imageLink);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        setTags(categorizeTags(state?.track?.tags));
    }, [state]);

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    <div>
                        <div className="flex justify-between items-center mb-4 md:mb-6">
                            <p className="text-xl font-semibold text-left">View Track</p>
                            <div
                                className={`flex h-14 md:h-16 ${
                                    state?.track?.trackStatus !== 'DELETED'
                                        ? 'w-1/2 md:w-1/4 lg:pl-8'
                                        : 'w-1/4 md:w-1/6 lg:pl-16'
                                }`}
                            >
                                <button
                                    type="submit"
                                    className={`md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm ${
                                        state?.track?.trackStatus !== 'DELETED'
                                            ? 'w-full md:w-1/2'
                                            : 'w-full hover:bg-blue-700 hover:text-white'
                                    } my-2 focus:outline-none`}
                                    onClick={() => {
                                        history.push('/admin/tracks');
                                    }}
                                >
                                    OK
                                </button>
                                {state?.track?.trackStatus !== 'DELETED' && (
                                    <button
                                        type="submit"
                                        className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                        onClick={() => {
                                            history.push({
                                                pathname: `/admin/tracks/${state.track.id}/edit`,
                                                state: { track: state.track },
                                            });
                                        }}
                                    >
                                        Edit
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 pb-6">
                            <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                <div className="text-lg font-medium mb-6">Track Details</div>
                                <div className="mb-6 w-full md:mb-0 flex">
                                    <label
                                        htmlFor="title"
                                        className={`flex items-start w-1/3 text-gray-700 block font-medium leading-149 text-sm md:text-base `}
                                    >
                                        Track ID
                                    </label>
                                    <input
                                        className={`rounded-sm focus:outline-none h-8 w-3/4 md:w-1/2 p-2 border-2 text-base focus:border-blue-900 border-gray-200 md:text-base`}
                                        id="id"
                                        type="text"
                                        value={state?.track?.trackId}
                                        disabled
                                    />
                                </div>

                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        htmlFor="title"
                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Track Name
                                    </label>
                                    <input
                                        className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-1/2 p-2 border-2 text-base focus:border-blue-900 border-gray-200 md:text-base`}
                                        id="name"
                                        type="text"
                                        value={state?.track?.trackName}
                                        disabled
                                    />
                                </div>
                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        htmlFor="title"
                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Track Status
                                    </label>
                                    <input
                                        className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-1/2 p-2 border-2 text-base focus:border-blue-900 border-gray-200 md:text-base`}
                                        id="name"
                                        type="text"
                                        value={state?.track?.trackStatus}
                                        disabled
                                    />
                                </div>
                                <div className="my-6 w-full md:mb-0 flex h-24">
                                    <label
                                        htmlFor="description"
                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700
                                        `}
                                    >
                                        Track description
                                    </label>
                                    <textarea
                                        className={`resize-none focus:outline-none p-2 md:ml-4 lg:ml-6 w-3/4 border-2 text-base min-h-24 focus:border-blue-900 border-gray-200`}
                                        id="description"
                                        value={state?.track?.trackDescription}
                                        disabled
                                    />
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        htmlFor="description"
                                        className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Track Image
                                    </label>
                                    <div
                                        className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                            imageUrl ? 'w-fit' : ''
                                        }  border-opacity-100 border-black-600
                                                  border-2`}
                                    >
                                        <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-72">
                                            {imageUrl && imageLocation && (
                                                <img
                                                    src={imageUrl}
                                                    alt={'name'}
                                                    className="h-44 lg:h-72"
                                                    style={{ maxHeight: 300 }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        htmlFor="description"
                                        className={`flex items-start w-1/3 text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Challenges
                                    </label>
                                    <div className="w-3/4 md:w-1/2">
                                        {state?.track?.challenges?.map((challenge, i) => {
                                            return (
                                                <div
                                                    className="px-4 border-2 rounded-sm text-gray-700 text-sm md:text-base flex items-center"
                                                    key={i}
                                                >
                                                    <FaIcons.FaBars />
                                                    <p className="my-2 ml-4">
                                                        {challenge?.challengeName}
                                                    </p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                <div className="flex border-2 flex-col ml-2 justify-items-center">
                                    <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                        <div className="sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                            Tags
                                        </div>
                                    </div>
                                    <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                        <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                            <TagGroupsView selectedTags={tags} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
