import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { SidebarData } from './ProfileSideBarData';
import SubMenu from './ProfileSubMenu';
import Tour from '../Pages/Learn/Tour';
import { USER_PROFILE } from '../../utils/storageWeb';
import NextArrow from '../../../assets/svg/NextArrow';
import CloseOutlineIcon from '../../../assets/svg/CloseOutlineIcon';
import useWidth from '../../hooks/useWidth';
interface sideBarProps {
    sidebar: boolean;
}

const SidebarNav = styled('nav')<sideBarProps>`
    background: #ffffff;
    min-width: max-content;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: scroll;
    scrollbar-width: none;
    transition: 350ms;
    z-index: 60;
    border: 1px solid #f5f6f9;
    @media screen and (min-width: 1024px) {
        border: 1px solid #f4ede5;
        margin-top: 70px;
        z-index: 10;
    }
`;

const SidebarWrap = styled.div`
    width: 100%;
`;

const ArrowBtn = styled.div`
    padding-left: 11px;
`;

interface Props {
    sidebar: boolean;
    setSidebar: (value: boolean) => void;
    setIsSideBarExpanded: (value: boolean) => void;
}
export default function Sidebar({
    sidebar,
    setSidebar,
    setIsSideBarExpanded,
}: Props): ReactElement {
    const [showSuccessModal, setShowSuccessModal] = React.useState(false);
    const history = useHistory();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const [focused, setFocused] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const viewWidth = useWidth();

    // event listner to listen to sidebar how much scrolled from the top
    const [scroll, setScroll] = useState(-40);
    const scroller = document.querySelector('#sidebar');
    scroller?.addEventListener('scroll', event => {
        setScroll(scroller.scrollTop - 40);
    });

    // make the sidebar scroll to specific point
    const sidebarAutoScroll = y => {
        scroller?.scrollTo(0, y);
    };

    const expandBtnClicked = () => {
        const value = !isExpanded;
        setIsExpanded(value);
        setIsSideBarExpanded(value);
    };

    return (
        <>
            {viewWidth > 1023 ? (
                <SidebarNav
                    sidebar={sidebar}
                    className={`hidden ${
                        sidebar === false ? 'hidden' : 'lg:flex'
                    } mt-10 DashboardSidebar`}
                    id="sidebar"
                >
                    <Tour
                        showSuccessModal={showSuccessModal}
                        setShowSuccessModal={setShowSuccessModal}
                        scroll={scroll}
                        sidebarAutoScroll={sidebarAutoScroll}
                        setFocused={setFocused}
                    />
                    <SidebarWrap>
                        {/* <NavIcon to="#" className="flex lg:hidden">
                        <AiIcons.AiOutlineClose onClick={showSidebar} />
                    </NavIcon> */}

                        <div>
                            {SidebarData.map((item, index) => {
                                return (
                                    <SubMenu
                                        profile={profile}
                                        item={item}
                                        key={index}
                                        focused={focused}
                                        isTourActivated={showSuccessModal}
                                    />
                                );
                            })}
                        </div>
                        <div
                            style={{ borderTop: '1px solid #F4EDE5' }}
                            className={`py-6 pl-10 pr-7 hidden lg:block ${
                                focused === 'QA' && 'border-r-2 border-purple-600'
                            }`}
                        >
                            <div className="text-purple-950 mb-2.5 text-sm">Quick Tips</div>
                            <div
                                onClick={() => {
                                    history.push({
                                        pathname: `/dashBoard/help/start-track`,
                                    });
                                }}
                                className="text-xs mb-4 text-gray-500 cursor-pointer"
                            >
                                How to start a track?
                            </div>
                            <div
                                onClick={() => {
                                    history.push({
                                        pathname: `/dashBoard/help/getting-started`,
                                    });
                                }}
                                className="text-xs mb-4 text-gray-500 cursor-pointer"
                            >
                                How to get started with FCB?
                            </div>
                            <div
                                onClick={() => {
                                    history.push({
                                        pathname: `/dashBoard/help/request-mentor`,
                                    });
                                }}
                                className="text-xs mb-4 text-gray-500 cursor-pointer"
                            >
                                How to request a mentor?
                            </div>
                            <div
                                onClick={() => {
                                    history.push({
                                        pathname: `/dashBoard/help/troubleshooting`,
                                    });
                                }}
                                className="text-xs mb-4 text-gray-500 cursor-pointer"
                            >
                                How to troubleshoot issues?
                            </div>
                            <div
                                onClick={() => {
                                    history.push({
                                        pathname: `/dashBoard/help/settings`,
                                    });
                                }}
                                className="text-xs mb-4 text-gray-500 cursor-pointer"
                            >
                                How to manage your account?
                            </div>
                            <div
                                onClick={() => {
                                    history.push({
                                        pathname: `/dashBoard/help`,
                                    });
                                }}
                                className="text-xs text-purple-950 underline cursor-pointer"
                            >
                                Need more help
                            </div>
                        </div>
                        <div
                            style={{ borderTop: '1px solid #F4EDE5' }}
                            className={`pt-8 pb-4 pl-10 hidden lg:block ${
                                focused === 'Tour' && 'border-r-2 border-purple-600'
                            }`}
                        >
                            <div className="text-purple-950 mb-2.5 text-sm">Get started</div>
                            <div
                                onClick={() => {
                                    setShowSuccessModal(true);
                                    document.documentElement.scrollTop = 0;
                                    window.scrollTo(0, 0);
                                    sidebarAutoScroll(0);
                                    setFocused('Learn');
                                }}
                                className="border-2 rounded border-purple-950 text-purple-950  w-32 h-8 flex justify-center items-center font-bold cursor-pointer whitespace-nowrap"
                            >
                                Take a tour
                            </div>
                        </div>
                    </SidebarWrap>
                </SidebarNav>
            ) : (
                <SidebarNav
                    sidebar={sidebar}
                    className={`${
                        sidebar === false ? 'hidden' : 'flex flex-col lg:hidden justify-between'
                    } pb-12 DashboardSidebar mobile-sidebar ${isExpanded ? 'expand-sidebar' : ''}`}
                    id="sidebar"
                >
                    <div className="flex">
                        <SidebarWrap>
                            <div>
                                {SidebarData.map((item, index) => {
                                    return (
                                        <SubMenu
                                            isExpanded={isExpanded}
                                            profile={profile}
                                            item={item}
                                            key={index}
                                            focused={focused}
                                        />
                                    );
                                })}
                            </div>
                            <div
                                style={{ borderTop: '1px solid #F4EDE5' }}
                                className={`py-6 pl-10 pr-7 ${
                                    !isExpanded ? 'hidden lg:block' : ''
                                } ${focused === 'QA' && 'border-r-2 border-purple-600'}`}
                            >
                                <div className={`text-purple-950 mb-2.5 text-sm`}>Quick Tips</div>
                                <div
                                    onClick={() => {
                                        history.push({
                                            pathname: `/dashBoard/help/start-track`,
                                        });
                                    }}
                                    className="text-xs mb-4 text-gray-500 cursor-pointer"
                                >
                                    How to start a track?
                                </div>
                                <div
                                    onClick={() => {
                                        history.push({
                                            pathname: `/dashBoard/help/getting-started`,
                                        });
                                    }}
                                    className="text-xs mb-4 text-gray-500 cursor-pointer"
                                >
                                    How to get started with FCB?
                                </div>
                                <div
                                    onClick={() => {
                                        history.push({
                                            pathname: `/dashBoard/help/request-mentor`,
                                        });
                                    }}
                                    className="text-xs mb-4 text-gray-500 cursor-pointer"
                                >
                                    How to request a mentor?
                                </div>
                                <div
                                    onClick={() => {
                                        history.push({
                                            pathname: `/dashBoard/help/troubleshooting`,
                                        });
                                    }}
                                    className="text-xs mb-4 text-gray-500 cursor-pointer"
                                >
                                    How to troubleshoot issues?
                                </div>
                                <div
                                    onClick={() => {
                                        history.push({
                                            pathname: `/dashBoard/help/settings`,
                                        });
                                    }}
                                    className="text-xs mb-4 text-gray-500 cursor-pointer"
                                >
                                    How to manage your account?
                                </div>
                                <div
                                    onClick={() => {
                                        history.push({
                                            pathname: `/dashBoard/help`,
                                        });
                                    }}
                                    className={`text-xs mb-4 ${
                                        !isExpanded ? ' text-gray-500' : 'underline text-purple-950'
                                    }  cursor-pointer`}
                                >
                                    Need more help
                                </div>
                            </div>
                            <div
                                style={{ borderTop: '1px solid #F4EDE5' }}
                                className={`pt-8 pb-4 pl-10 hidden lg:block ${
                                    focused === 'Tour' && 'border-r-2 border-purple-600'
                                }`}
                            >
                                <div className="text-purple-950 mb-2.5 text-sm">Get started</div>
                                <div
                                    onClick={() => {
                                        setShowSuccessModal(true);
                                        document.documentElement.scrollTop = 0;
                                        window.scrollTo(0, 0);
                                        sidebarAutoScroll(0);
                                        setFocused('Learn');
                                    }}
                                    className="border-2 rounded border-purple-950 text-purple-950  w-32 h-8 flex justify-center items-center font-bold cursor-pointer whitespace-nowrap"
                                >
                                    Take a tour
                                </div>
                            </div>
                        </SidebarWrap>
                    </div>
                    {!isExpanded ? (
                        <ArrowBtn className="flex justify-start lg:hidden">
                            <button
                                className="transform rotate-180 focus:outline-none"
                                onClick={expandBtnClicked}
                            >
                                <NextArrow size={24} />
                            </button>
                        </ArrowBtn>
                    ) : (
                        <button
                            className="transform rotate-180 focus:outline-none flex justify-center lg:hidden"
                            onClick={expandBtnClicked}
                        >
                            <CloseOutlineIcon size={32} color="#4C2EC2" />
                        </button>
                    )}
                </SidebarNav>
            )}
        </>
    );
}
