import publicProfileReducer, { publicProfileInitialState } from './webRedux/publicProfile/reducers';
import { combineReducers } from 'redux';
import challengesReducer, { ChallengesInitialState } from './challenges/reducers';
import tracksReducer, { TracksInitialState } from './tracks/reducers';
import studentReducer, { studentInitialState } from './students/reducers';
import tagReducer, { TagsInitialState } from './tags/reducers';
import tracksTabReducer, { TracksTabInitialState } from './tracktab/reducers';
import challengesTabReducer, { ChallengesTabInitialState } from './Challengetab/reducers';
import studentsTabReducer, { StudentTabInitialState } from './studenttab/reducers';
import storiesReducer, { StoriesInitialState } from './successStories/reducers';
import institutesReducer, { InstitutesInitialState } from './institutes/reducers';
import InstituteTabReducer, { InstitutesTabInitialState } from './instituteTab/reducers';
import companiesReducer, { CompaniesInitialState } from './companies/reducers';
import companiesTabReducer, { CompaniesTabInitialState } from './companiesTab/reducers';
import CertificatesReducer, { CertificateInitialState } from './certificates/reducers';
import certificatesTabReducer, { CertifcatesTabInitialState } from './certificatesTab/reducers';
import eventsReducer, { EventsInitialState } from './events/reducers';
import eventsTabReducer, { EventsTabInitialState } from './eventsTab/reducers';
import badgesReducer, { BadgesInitialState } from './badges/reducers';
import mentorsReducer, { MentorsInitialState } from './mentors/reducers';
import mentorTabReducer, { MentorsTabInitialState } from './mentorTab/reducers';
import notificationsReducer, { NotificationsInitialState } from './notifications/reducers';
import notificationsTabReducer, { NotificationsTabInitialState } from './notifiationTab/reducers';
import UsersReducer, { UsersInitialState } from './users/reducers';
import authReducer, { AuthInitialState } from './authAdmin/reducers';
import AnalyticsReducer, { AnalyticsInitialState } from './analytics/reducers';
import analyticsTabReducer, { AnalyticsTabInitialState } from './analyticsTab/reducers';
import WebUsersReducer, { webStudentInitialState } from './webRedux/webUsers/reducers';
import webMentorsReducer, { WebMentorsInitialState } from './webRedux/webMentors/reducers';
import joinFCBReducer, { JoinFCBInitialState } from './webRedux/joinFCB/reducers';
import registerReducer, { RegisterInitialState } from './webRedux/register/reducers';
import authWebReducer, { AuthWebInitialState } from './webRedux//authWeb/reducers';
import DashboardReducer, { DashboarInitialState } from './dashboard/reducers';
import webSuccessStoryReducer, { successStoryInitialState } from './webRedux/successStory/reducers';
import webChallengeReducer, { webChallengeInitialState } from './webRedux/challenges/reducers';
import webNotificationReducer, { WebNotificationState } from './webRedux/webNotifications/reducers';
import authStudentReducer, { AuthStudentInitialState } from './authStudent/reducers';

export interface RootState {
    tracks: TracksInitialState;
    challenges: ChallengesInitialState;
    students: studentInitialState;
    tags: TagsInitialState;
    filteredTrack: TracksTabInitialState;
    filteredChallenge: ChallengesTabInitialState;
    filteredStudent: StudentTabInitialState;
    successStories: StoriesInitialState;
    institutes: InstitutesInitialState;
    filteredInstitutes: InstitutesTabInitialState;
    companies: CompaniesInitialState;
    filteredCompanies: CompaniesTabInitialState;
    certificates: CertificateInitialState;
    filteredCertificates: CertifcatesTabInitialState;
    events: EventsInitialState;
    filteredEvents: EventsTabInitialState;
    badges: BadgesInitialState;
    mentors: MentorsInitialState;
    filteredMentor: MentorsTabInitialState;
    notifications: NotificationsInitialState;
    filteredNotifications: NotificationsTabInitialState;
    users: UsersInitialState;
    auth: AuthInitialState;
    authStudent: AuthStudentInitialState;
    analytics: AnalyticsInitialState;
    filteredAnalytics: AnalyticsTabInitialState;
    dashboard: DashboarInitialState;
    webUser: webStudentInitialState;
    newWebUser: RegisterInitialState;
    webMentor: WebMentorsInitialState;
    webAuth: AuthWebInitialState;
    webStory: successStoryInitialState;
    webChallenge: webChallengeInitialState;
    joinFCB: JoinFCBInitialState;
    WebNotification: WebNotificationState;
    publicProfile: publicProfileInitialState;
}

const appReducer = combineReducers({
    tracks: tracksReducer,
    challenges: challengesReducer,
    students: studentReducer,
    tags: tagReducer,
    filteredTrack: tracksTabReducer,
    filteredChallenge: challengesTabReducer,
    filteredStudent: studentsTabReducer,
    successStories: storiesReducer,
    institutes: institutesReducer,
    filteredInstitutes: InstituteTabReducer,
    companies: companiesReducer,
    filteredCompanies: companiesTabReducer,
    certificates: CertificatesReducer,
    filteredCertificates: certificatesTabReducer,
    events: eventsReducer,
    filteredEvents: eventsTabReducer,
    badges: badgesReducer,
    mentors: mentorsReducer,
    filteredMentor: mentorTabReducer,
    notifications: notificationsReducer,
    filteredNotifications: notificationsTabReducer,
    users: UsersReducer,
    auth: authReducer,
    authStudent: authStudentReducer,
    analytics: AnalyticsReducer,
    filteredAnalytics: analyticsTabReducer,
    dashboard: DashboardReducer,
    webUser: WebUsersReducer,
    newWebUser: registerReducer,
    webAuth: authWebReducer,
    webMentor: webMentorsReducer,
    webStory: webSuccessStoryReducer,
    webChallenge: webChallengeReducer,
    joinFCB: joinFCBReducer,
    WebNotification: webNotificationReducer,
    publicProfile: publicProfileReducer,
});

const rootReducer = (state: any, action: any) => {
    if (action.type === '@WEBAUTH_WEB_LOGOUT') {
        // logout => clear whole redux store
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export default rootReducer;
