import React, { useEffect } from 'react';
import { BiCheckCircle } from 'react-icons/bi';

interface Props {
    message: string;
    showToast: any;
    error?: boolean;
    selfDestruct?: boolean;
    selfDestructTimer?: any;
    customWidth?: string;
    fullWidth?: boolean;
}

const Toastr = ({
    message,
    showToast,
    error = false,
    selfDestruct,
    selfDestructTimer,
    customWidth,
    fullWidth,
}: Props) => {
    useEffect(() => {
        if (selfDestruct) {
            setTimeout(() => {
                showToast(false);
            }, selfDestructTimer);
        }
    }, [selfDestructTimer, selfDestruct, showToast]);

    return (
        <div
            style={
                fullWidth
                    ? {
                          width: '100%',
                          left: 0,
                          top: '100px',
                          zIndex: 70,
                      }
                    : {
                          width: customWidth || 'auto',
                          right: 1,
                          left: '253px',
                          top: '100px',
                      }
            }
            className={`lg:ml-10 z-50 px-6 py-3 fixed rounded-md ${
                error ? `text-red-700 bg-red-300` : `text-green-700 bg-green-200`
            } ${showToast ? `flex flex-row` : `hidden`}`}
        >
            <BiCheckCircle color="rgba(4, 120, 87)" size={25} />
            <div className={`text-lg lg:text-xl font-bold ml-2`}>{message}</div>
        </div>
    );
};

export default Toastr;
