import React, { useEffect } from 'react';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Logout from '../../../assets/svg/Logout';
import { clearWebAuth, logoutWeb } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { SocialMediaPlatform } from '../../../redux/students/reducers';

interface paramsType {
    id: string;
}

interface Props {
    isShowExpandable: boolean;
    setIsNavBarExpanded: (value: boolean) => void;
}
const ExpandableNavBar = styled.div`
    z-index: 100;
    height: calc(100vh - 70px); /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100 - 70px);
`;

export default function ProfileNavBarMobileExpandablePublicSite({
    isShowExpandable,
    setIsNavBarExpanded,
}: Props) {
    const student = useSelector((state: RootState) => state.students.student);
    const notifications = useSelector(
        (state: RootState) => state.WebNotification.webNotificationsData
    );
    const location = useLocation<paramsType>();
    const history = useHistory();
    const dispatch = useDispatch();
    // const handleChangeInResize = () => {
    //     // eslint-disable-next-line @typescript-eslint/no-shadow
    //     const vh = window.innerHeight * 0.01;
    //     document.documentElement.style.setProperty('--vh', `${vh}px`);
    // };

    // useEffect(() => {
    //     const vh = window.innerHeight * 0.01;
    //     document.documentElement.style.setProperty('--vh', `${vh}px`);
    //     window.addEventListener('resize', handleChangeInResize);
    //     return () => {
    //         window.removeEventListener('resize', handleChangeInResize);
    //     };
    // }, []);

    return (
        <>
            {isShowExpandable && (
                <ExpandableNavBar className=" bg-white flex flex-col justify-between gap-y-8 w-full">
                    <div
                        className="mt-16 flex flex-col mx-10 gap-y-5 text-black-400"
                        onClick={() => setIsNavBarExpanded(false)}
                    >
                        <NavLink activeClassName="font-bold" to="/home">
                            Home
                        </NavLink>
                        <NavLink activeClassName="font-bold" to="/solve-challenges">
                            Solve Challenges
                        </NavLink>
                        <NavLink activeClassName="font-bold" to="/leaderboard">
                            Leaderboard
                        </NavLink>
                        <NavLink activeClassName="font-bold" to="/companies">
                            Companies
                        </NavLink>
                        <NavLink activeClassName="font-bold" to="/success-stories">
                            Success Stories
                        </NavLink>
                        <NavLink activeClassName="font-bold" to="/events">
                            Events
                        </NavLink>
                        <NavLink activeClassName="font-bold" to="/institutes">
                            Institutes
                        </NavLink>
                    </div>
                    <div className="flex flex-col gap-y-3 items-center mb-16">
                        <div
                            className="rounded text-purple-950 border-2 border-purple-950 cursor-pointer h-10 w-48 flex justify-center items-center"
                            onClick={() => {
                                history.push({
                                    pathname: `/signin`,
                                });
                                setIsNavBarExpanded(false);
                            }}
                        >
                            Sign in
                        </div>
                        <div
                            className="rounded text-white bg-purple-950 cursor-pointer h-10 w-48 flex justify-center items-center"
                            onClick={() => {
                                history.push({
                                    pathname: `/register`,
                                });
                                setIsNavBarExpanded(false);
                            }}
                        >
                            Register
                        </div>
                    </div>
                </ExpandableNavBar>
            )}
        </>
    );
}
