/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Line } from 'rc-progress';
import Modal from 'react-modal';
import ReactMarkdown from 'react-markdown';
import Confetti from '../../../elements/Confetti';

import { USER_PROFILE } from '../../../../utils/storageWeb';
import { youtubeParser } from '../../../../utils/';
import { RootState } from '../../../../../redux/rootReducer';
import { LearnChallengesType } from '../../../../../redux/challenges/reducers';
import { TracksType } from '../../../../../redux/tracks/reducers';

import {
    getChallengesStatus,
    submitChallenge,
    submitTask,
    SUBMIT_COMPLETE_CHALLENGE_RESET,
    SUBMIT_TASK_RESET,
} from '../../../../../redux/webRedux/challenges/actions';
import { getChallengeByIdForStudent } from '../../../../../redux/rootActions';
import { decodeValue } from '../../../../../utils/stringToBytes';

import TaskSection from './TaskSection';
import EarnBadgeModal from '../../../../modals/EarnBadgeModal';
import RequestMentorModal from '../../RequestMentors/RequestMentorModal';

import BackArrow from '../../../../../assets/svg/BackArrow';
import DownArrow from '../../../../../assets/svg/DownArrow';
import UpArrow from '../../../../../assets/svg/UpArrow';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CompleteChallenge from '../../../../modals/CompleteChallengeModal';
import ChallengeCompletionCertificateModal from '../../../../modals/ChallengeCompletionCertificateModal';
import Loader from '../../../../../assets/svg/Loader';
import Toastr from '../../../elements/Toastr';
import MessageToaster from '../../../elements/messageToaster';
import { s3UrlPrefix } from '../../../../../constants';
import StartChallengeModal from '../../../../../components/modals/StartChallengeModal';
import AbondanChallengeModal from '../../../../../components/modals/AbondanChallengeModal';
import CompleteChallengeModal from '../../../../../components/modals/CompleteChallengeModal';
import { getDecryptedData } from '../../../../../utils/decrypt';
import useWidth from '../../../../hooks/useWidth';
import Education from '../../../../../assets/svg/Education';
import { encryptData } from '../../../../../utils/encrypt';

interface CustomState {
    path: string;
    track: TracksType;
    challenges: LearnChallengesType;
}

interface taskSection {
    title: string;
    taskNo: number;
    subsections: any;
}
interface paramsType {
    challengeID: string;
}

function LinkRenderer(props) {
    return (
        <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    );
}

const MarkdownRenderer = ({ children }) => {
    return (
        <ReactMarkdown
            className="prose prose-sm w-full overflow-auto font-sans max-w-none"
            components={{ a: LinkRenderer }}
        >
            {children?.replace(/(?:\r\n|\r|\n)/g, '  \n')}
        </ReactMarkdown>
    );
};

const Challenge = () => {
    const location = useLocation();
    const userProfile = useSelector((state: RootState) => state.authStudent.userProfile);
    const studentData = useSelector((state: RootState) => state.webUser.student);
    const history = useHistory();
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const { challengeID } = useParams<paramsType>();

    const locationState = location.state as CustomState;
    const achievements = useSelector((state: RootState) => state.webChallenge.achivements);
    const achievementSuccess = useSelector(
        (state: RootState) => state.webChallenge.submitChallengeSuccess
    );
    const challengesStatus = useSelector((state: RootState) => state.webChallenge.challengesData);
    const submittedTaskData = useSelector(
        (state: RootState) => state.webChallenge.submittedTaskData
    );
    const challengeData = useSelector(
        (state: RootState) => state.challenges.challengeByIdByStudentData
    );

    const challengeByIdByStudentLoading = useSelector(
        (state: RootState) => state.challenges.challengeByIdByStudentLoading
    );

    const submittedTaskDataLoading = useSelector(
        (state: RootState) => state.webChallenge.submittedTaskDataLoading
    );

    const submitChallengeIsLoading = useSelector(
        (state: RootState) => state.webChallenge.isSubmitChallengeLoading
    );

    const [videoSection, setVideoSection] = useState(false);
    const [programChallengeSection, setProgramChallengeSection] = useState(false);
    // eslint-disable-next-line
    const [sidebar, setSidebar] = useState(false);
    const [MCQComplete, setMCQComplete] = useState(false);
    const [uploadFileComplete, setUploadFileComplete] = useState(false);
    const [challengeComplete, setChallengeComplete] = useState(false);
    // eslint-disable-next-line
    const [cvUrl, setCvUrl] = useState(null);
    const [uploadedFile, setUploadedFile] = useState<string>(null);
    const [showToast, setShowToast] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    const [challengeStartedMessage, setChallengeStartedMessage] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    const [badgeShowModal, setBadgeShowModal] = React.useState(false);
    const [certificateShowModal, setCertificateShowModal] = React.useState(false);
    const [completeChallengeShowModal, setCompleteChallengeShowModal] = React.useState(false);
    const [onClickNextChallenge, setOnClickNextChallenge] = React.useState(false);
    const [onClickTryAgain, setOnClickTryAgain] = React.useState(false);
    const [IsCertificatesAvailable, setIsCertificatesAvailable] = React.useState(false);
    const [showAbleToRequestModal, setShowAbleToRequestModal] = React.useState(false);
    const [isNextChallengeClicked, setIsNextChallengeClicked] = React.useState(false);

    const [markdownDescription, setMarkdownDescription] = useState(null);
    const [taskSectionList, setTaskSectionList] = useState<taskSection[]>(null);
    const [selectedSection, setSelectedSection] = useState<number>(null);
    const [challengeStarted, setChallengeStarted] = useState(false);
    const [hasChallengeStarted, setHasChallengeStarted] = useState(false);
    const [maximumPointsForChallenge, setMaximumPointsForChallenge] = useState(0);
    const [earnedPointsForChallenge, setEarnedPointsForChallenge] = useState(0);
    const [earnedPercentage, setEarnedPercentage] = useState(0);
    const [maximumPointsForQuestion, setMaximumPointsForQuestion] = useState(0);
    const [earnedlPointsForQuestion, setEarnedlPointsForQuestion] = useState(0);
    const [startLearningModal, setStartLearningModal] = useState(false);
    const [challengeOngoing, setChallengeOngoing] = useState(false);
    const [abandonModalVisible, setAbandonModalVisible] = useState(false);
    const [openSelection, setOpenSelection] = useState<number>(0);

    const [challengeName, setChallengeName] = useState('');
    const [challengeCompleteModal, setChallengeCompleteModal] = useState(false);

    const [isMcq, setIsMcq] = useState(false);

    const viewPortWidth = useWidth();
    const challengesUrl = `/dashBoard/learn/Challenges`;
    const recommendedScore = 80;

    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            backdropFilter: 'blur(7px)',
            overflow: 'auto',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    useEffect(() => {
        let earnedpercentage = 0;

        earnedpercentage = (100 / maximumPointsForChallenge) * earnedPointsForChallenge;
        setEarnedPercentage(earnedpercentage);
    }, [maximumPointsForChallenge, earnedPointsForChallenge]);

    useEffect(() => {
        if (showToast) {
            setTimeout(() => setShowToast(false), 4000);
        }
    }, [showToast]);

    useEffect(() => {
        if (hasChallengeStarted) {
            setTimeout(() => setHasChallengeStarted(false), 4000);
        }
    }, [hasChallengeStarted]);

    useEffect(() => {
        if (submittedTaskData) {
            setEarnedPointsForChallenge(submittedTaskData.earnedMarksChallenge);
            setEarnedlPointsForQuestion(submittedTaskData.earnedMarks);
            setMaximumPointsForChallenge(submittedTaskData.totalMarksChallenge);
            setMaximumPointsForQuestion(submittedTaskData.totalMarks);
            setShowToast(!isMcq);
            setShowModal(isMcq);
            if (
                submittedTaskData.areAllTasksCompleted !== null &&
                submittedTaskData.areAllTasksCompleted
            ) {
                setChallengeComplete(true);
            }
            if (selectedSection !== -1)
                document.querySelector(`#section${selectedSection}`).scrollIntoView({
                    behavior: 'smooth',
                });
        }
    }, [submittedTaskData]);

    useEffect(() => {
        return () => {
            dispatch({ type: SUBMIT_TASK_RESET });
            dispatch({ type: SUBMIT_COMPLETE_CHALLENGE_RESET });
        };
    }, []);

    useEffect(() => {
        if (challengeComplete) {
            setChallengeCompleteModal(true);
        }
    }, [challengeComplete]);

    const handleSectionData = taskList => {
        if (taskList) {
            const sectionList = [];
            const headerIndexList = [];
            const headers = taskList?.filter(task => task.type === 'header');
            if (headers && headers?.length > 0) {
                headers?.forEach(header => {
                    const index = taskList?.indexOf(header);
                    headerIndexList.push(index);
                });
                headerIndexList?.forEach((hederIndex, index) => {
                    const subTaskList = taskList?.slice(hederIndex + 1, headerIndexList[index + 1]);
                    const section = {
                        isCompleted: taskList[hederIndex]?.isCompleted,
                        title: taskList[hederIndex]?.label,
                        taskNo: taskList[hederIndex]?.task_no,
                        subsections: subTaskList,
                    };

                    sectionList.push(section);
                });
            }
            setTaskSectionList(sectionList);
        }
    };

    useEffect(() => {
        if (
            challengesStatus?.completed?.length >= 25 &&
            !studentData?.mentorRequestId &&
            challengeComplete
        ) {
            setShowAbleToRequestModal(true);
        }
    }, [challengesStatus]);

    useEffect(() => {
        dispatch(
            getChallengeByIdForStudent(
                profile?.userId,
                locationState?.challenges?.id?.toString() ?? challengeID?.substring(3)
            )
        );
    }, [dispatch]);

    useEffect(() => {
        if (MCQComplete && uploadFileComplete) {
            setChallengeComplete(true);
        }
    }, [MCQComplete, uploadFileComplete]);

    useEffect(() => {
        if (achievementSuccess && !showAbleToRequestModal) {
            setChallengeCompleteModal(false);
            setChallengeName(challengeData?.challengeName);
            setCompleteChallengeShowModal(true);
        }
    }, [achievementSuccess, challengeData?.challengeName, showAbleToRequestModal]);

    useEffect(() => {
        if (onClickNextChallenge || onClickTryAgain) {
            if (
                achievements?.earnedBadges?.length !== 0 &&
                achievements?.earnedCertificate?.length !== 0
            ) {
                setIsNextChallengeClicked(onClickNextChallenge || false);
                setIsCertificatesAvailable(true);
                setBadgeShowModal(true);
            } else if (
                achievements?.earnedBadges?.length !== 0 &&
                achievements?.earnedCertificate.length === 0
            ) {
                setIsNextChallengeClicked(onClickNextChallenge || false);
                setBadgeShowModal(true);
            } else if (
                achievements?.earnedBadges?.length === 0 &&
                achievements?.earnedCertificate?.length !== 0
            ) {
                setIsNextChallengeClicked(onClickNextChallenge || false);
                setCertificateShowModal(true);
            } else {
                if (onClickNextChallenge) {
                    history.push({
                        pathname: locationState?.path,
                        state: {
                            tracks: locationState?.track,
                        },
                    });
                }
            }
        }
    }, [achievements, challengesUrl, history, onClickNextChallenge, onClickTryAgain]);

    useEffect(() => {
        if (IsCertificatesAvailable && !badgeShowModal) {
            setCertificateShowModal(true);
        }
    }, [IsCertificatesAvailable, badgeShowModal]);

    const onComplete = useCallback(() => {
        const info = {
            userId: profile?.userId,
            challengeId: challengeData?.id,
        };
        dispatch(submitChallenge(info));
        dispatch(getChallengesStatus(userProfile?.userId));
    }, [challengeData?.id, dispatch, userProfile]);

    useEffect(() => {
        if (challengeData?.material) {
            const decodeMarkdownData = decodeValue(challengeData?.material?.description);
            if (decodeMarkdownData && decodeMarkdownData != null) {
                setMarkdownDescription(decodeMarkdownData);
            }

            if (challengeData?.material && challengeData?.material?.encryptQuestionnaire) {
                const quiz = getDecryptedData(challengeData?.material?.encryptQuestionnaire);
                if (quiz) {
                    handleSectionData(quiz);
                }
            }
            setEarnedPointsForChallenge(challengeData.earnedMarks);
            setMaximumPointsForChallenge(challengeData.totalMarks);
        } else {
            setMarkdownDescription('');
            setEarnedPointsForChallenge(null);
            setMaximumPointsForChallenge(null);
            setTaskSectionList(null);
        }
    }, [challengeData]);

    const selectedSectionHandler = (index: number) => {
        if (challengeOngoing) {
            setOpenSelection(index);
            setAbandonModalVisible(true);
        } else {
            setSelectedSection(index);
        }
    };

    const updateTaskSectionListHandler = data => {
        setTaskSectionList(data);
    };

    const startLearningHandler = () => {
        setStartLearningModal(true);
    };

    const submitTaskHandler = (taskData, isMcqType) => {
        setIsMcq(isMcqType);
        dispatch(
            submitTask(
                encryptData(JSON.stringify(taskData)),
                new Date(),
                profile?.userId,
                challengeData?.id,
                locationState?.track?.id,
                taskData.task_no
            )
        );
    };
    const setOnClickSuccess = () => {
        setChallengeOngoing(false);
        setChallengeStarted(true);
        setHasChallengeStarted(true);
        setChallengeStartedMessage(
            `Welcome to the ${challengeData?.challengeName} challenge . You just have to complete the below steps to score ${maximumPointsForChallenge}XP points . Good luck!`
        );
        setVideoSection(true);
    };

    const clickOpenSelection = () => {
        setChallengeOngoing(false);
        setSelectedSection(openSelection);
    };

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div style={{ marginLeft: '250px' }}>
                    <Toastr message={message} showToast={showToast} />
                    <StartChallengeModal
                        modalVisible={startLearningModal}
                        setModalVisible={setStartLearningModal}
                        challengeData={challengeData}
                        setOnClickSuccess={setOnClickSuccess}
                        selectedSection={selectedSection}
                        selectedSectionHandler={selectedSectionHandler}
                    />
                    <AbondanChallengeModal
                        modalVisible={abandonModalVisible}
                        setModalVisible={setAbandonModalVisible}
                        setOnClickSuccess={clickOpenSelection}
                    />
                    <CompleteChallengeModal
                        modalVisible={challengeCompleteModal}
                        setModalVisible={setChallengeCompleteModal}
                        setOnClickSuccess={onComplete}
                    />
                    <div className={`md:ml-24 md:mt-14 flex mr-24`}>
                        <RequestMentorModal
                            showAbleToRequestModal={showAbleToRequestModal}
                            setShowAbleToRequestModal={setShowAbleToRequestModal}
                            customStyles={customStyles}
                        />
                        <EarnBadgeModal
                            showModal={badgeShowModal}
                            setShowModal={setBadgeShowModal}
                            Badges={achievements?.earnedBadges}
                            isCertificatesAvailable={IsCertificatesAvailable}
                            isNextChallengeClicked={isNextChallengeClicked}
                            selectedTrack={locationState?.track}
                        />
                        <CompleteChallenge
                            showModal={completeChallengeShowModal}
                            setShowModal={setCompleteChallengeShowModal}
                            setOnClickNextChallenge={setOnClickNextChallenge}
                            setOnClickTryAgain={setOnClickTryAgain}
                            challengeName={challengeName}
                        />
                        <ChallengeCompletionCertificateModal
                            showModal={certificateShowModal}
                            setShowModal={setCertificateShowModal}
                            isNextChallengeClicked={isNextChallengeClicked}
                            certificate={achievements?.earnedCertificate[0]}
                            selectedTrack={locationState?.track}
                        />
                        {challengeByIdByStudentLoading || submittedTaskDataLoading ? (
                            <div className="flex align-middle items-center content-center w-full h-full">
                                <Loader />
                            </div>
                        ) : (
                            <div className="w-full">
                                <div
                                    style={{ backgroundColor: '#FBFBFB' }}
                                    className="flex justify-between align-bottom pt-20 z-40 fixed pl-16 pr-5 left-10 right-0 ml-64 h-32 top-0"
                                >
                                    <div
                                        className="cursor-pointer flex"
                                        onClick={() =>
                                            history.push({
                                                pathname: locationState?.path,
                                                state: {
                                                    tracks: locationState?.track,
                                                },
                                            })
                                        }
                                    >
                                        <BackArrow size="27" />
                                        <p className="text-base ml-2 font-semibold hover:underline">
                                            Back
                                        </p>
                                    </div>
                                    {earnedPointsForChallenge != null && (
                                        <>
                                            <div className="flex justify-center w-full">
                                                <div
                                                    className="justify-center items-center mx-5 mb-2 mt-1"
                                                    style={{ maxWidth: '70%' }}
                                                >
                                                    <div className="challenge-progress-line w-full px-5">
                                                        <Line
                                                            percent={
                                                                (earnedPointsForChallenge /
                                                                    maximumPointsForChallenge) *
                                                                100
                                                            }
                                                            strokeWidth={1}
                                                            strokeColor="#4C2EC2"
                                                        />
                                                        {maximumPointsForChallenge -
                                                            earnedPointsForChallenge ===
                                                        0 ? (
                                                            <div className="text-xs font-normal mt-1">
                                                                You have completed this challenge
                                                                with 100% marks
                                                            </div>
                                                        ) : (
                                                            <div className="text-xs font-normal mt-1">
                                                                Earn{' '}
                                                                {`${(
                                                                    maximumPointsForChallenge -
                                                                    earnedPointsForChallenge
                                                                ).toFixed(2)}`}{' '}
                                                                XP points more to climb up your
                                                                position in the leaderboard
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="text-xs font-normal w-auto mx-1 whitespace-nowrap">
                                                    {`${earnedPointsForChallenge} / ${maximumPointsForChallenge} XP`}
                                                </div>
                                            </div>
                                            {/* {!startLearningModal && (
                                                <div
                                                    className={`${
                                                        challengeData?.StatusValue === 0
                                                            ? 'rounded ml-1 mb-2 mt-1 font-semibold text-purple-800 border-2 border-purple-800 cursor-pointer h-10 w-40 flex justify-center items-center'
                                                            : 'rounded ml-1 mb-2 font-semibold text-white bg-purple-800 cursor-pointer h-10 w-40 flex justify-center items-center'
                                                    } `}
                                                    onClick={() => {
                                                        startLearningHandler();
                                                    }}
                                                >
                                                    {challengeData?.StatusValue === 0
                                                        ? 'Try Again'
                                                        : challengeData?.StatusValue === 1
                                                        ? 'Continue'
                                                        : 'Start learning'}
                                                </div>
                                            )} */}
                                        </>
                                    )}
                                </div>
                                <div className="flex justify-between mb-4 pt-14">
                                    <div className="mr-20">
                                        <div className="font-semibold text-2xl mb-1">
                                            {challengeData?.challengeName}
                                        </div>
                                        <p className="font-normal text-justify">
                                            {challengeData?.challengeDescription}
                                        </p>
                                    </div>
                                    {challengeData && (
                                        <img
                                            className="h-40 w-40"
                                            src={
                                                challengeData?.imageLink
                                                    ? `${s3UrlPrefix}${challengeData.imageLink}`
                                                    : `/assets/computer.png`
                                            }
                                        />
                                    )}
                                </div>

                                <div className="mb-32">
                                    <>
                                        <div className="flex justify-between">
                                            <MarkdownRenderer>
                                                {markdownDescription}
                                            </MarkdownRenderer>
                                        </div>
                                        <MessageToaster
                                            message={challengeStartedMessage}
                                            showToast={hasChallengeStarted}
                                        />
                                        <div className="pr-40">
                                            {challengeData && (
                                                <div className="font-semibold text-xl mb-1 mt-6">
                                                    To Complete, follow these steps :
                                                </div>
                                            )}

                                            {/* Start of course video */}
                                            {challengeData?.introLink && (
                                                <div
                                                    style={{
                                                        background: videoSection ? '#F5F3FC' : '',
                                                    }}
                                                    className={`mt-6 flex flex-col ${
                                                        videoSection
                                                            ? 'bg-purple-100'
                                                            : 'border-2 rounded'
                                                    }`}
                                                >
                                                    <div
                                                        className=" flex items-center h-24 justify-between cursor-pointer"
                                                        onClick={() => {
                                                            if (videoSection) {
                                                                setVideoSection(false);
                                                            } else {
                                                                setVideoSection(true);
                                                            }
                                                        }}
                                                    >
                                                        <div className="flex items-center ml-8">
                                                            <div>
                                                                <div
                                                                    className={`text-xs lg:text-base font-bold ${
                                                                        uploadFileComplete
                                                                            ? 'mt-4'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    Watch this intro video to get a
                                                                    better understanding
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-3 rounded-full hover:bg-purple-100 hover:text-blue-900 cursor-pointer mr-4">
                                                            {videoSection ? (
                                                                <UpArrow />
                                                            ) : (
                                                                <DownArrow />
                                                            )}
                                                        </div>
                                                    </div>
                                                    {videoSection ? (
                                                        <div
                                                            style={{ maxHeight: '575px' }}
                                                            id="mcqsection"
                                                            className="border-t-2"
                                                        >
                                                            <div className="my-10 flex items-center justify-center">
                                                                <iframe
                                                                    width="853"
                                                                    height="480"
                                                                    src={`https://youtube.com/embed/${youtubeParser(
                                                                        challengeData.introLink
                                                                    )}`}
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen
                                                                    title="Embedded youtube"
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                            {/* End of course video */}
                                            {taskSectionList?.map((section, sectionIndex) => (
                                                <div
                                                    id={`section${selectedSection}`}
                                                    key={sectionIndex}
                                                >
                                                    <TaskSection
                                                        taskSectionList={taskSectionList}
                                                        section={section}
                                                        selectedSection={selectedSection}
                                                        sectionIndex={sectionIndex}
                                                        selectedSectionHandler={
                                                            selectedSectionHandler
                                                        }
                                                        updateTaskSectionListHandler={
                                                            updateTaskSectionListHandler
                                                        }
                                                        submitTaskHandler={submitTaskHandler}
                                                        challengeStarted={challengeStarted}
                                                        setMessage={setMessage}
                                                        challengeData={challengeData}
                                                        setChallengeStarted={setChallengeStarted}
                                                        setHasChallengeStarted={
                                                            setHasChallengeStarted
                                                        }
                                                        setChallengeStartedMessage={
                                                            setChallengeStartedMessage
                                                        }
                                                        setVideoSection={setVideoSection}
                                                        totalChallengePoints={
                                                            maximumPointsForChallenge
                                                        }
                                                        setChallengeOngoing={setChallengeOngoing}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        {/* {challengeComplete && (
                                            <div className="flex justify-end">
                                                <button
                                                    onClick={() => {
                                                        onComplete();
                                                    }}
                                                    className="mt-8 mr-40 rounded border-2  font-semibold w-44 h-10 justify-center items-center flex hover:text-white hover:bg-green-500 cursor-pointer text-green-500 border-green-500"
                                                >
                                                    {submitChallengeIsLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        'Complete Challenge'
                                                    )}
                                                </button>
                                            </div>
                                        )} */}

                                        <Modal isOpen={showModal} style={customStyles}>
                                            <>
                                                <div className="flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none overflow-hidden">
                                                    {Math.round(
                                                        (earnedlPointsForQuestion /
                                                            maximumPointsForQuestion) *
                                                            100
                                                    ) >= recommendedScore && <Confetti />}
                                                    <div
                                                        style={
                                                            Math.round(
                                                                (earnedlPointsForQuestion /
                                                                    maximumPointsForQuestion) *
                                                                    100
                                                            ) >= recommendedScore
                                                                ? {
                                                                      height: '350px',
                                                                      width: '530px',
                                                                  }
                                                                : {
                                                                      height: '340px',
                                                                      width: '530px',
                                                                  }
                                                        }
                                                        className="flex flex-col justify-center items-center w-full relative rounded-lg shadow-lg bg-white outline-none focus:outline-none"
                                                    >
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: '-65px',
                                                            }}
                                                            className="flex flex-col justify-center items-center px-4"
                                                        >
                                                            <span className="challengeComplete self-center mb-8" />
                                                            <div className="text-base font-normal text-purple-950">
                                                                {Math.round(
                                                                    (earnedlPointsForQuestion /
                                                                        maximumPointsForQuestion) *
                                                                        100
                                                                ) >= recommendedScore
                                                                    ? 'You are Awesome!'
                                                                    : 'There is always a next time!'}
                                                            </div>
                                                            {Math.round(
                                                                (earnedlPointsForQuestion /
                                                                    maximumPointsForQuestion) *
                                                                    100
                                                            ) >= recommendedScore && (
                                                                <div className="text-xl font-bold mt-2 mb-3">
                                                                    Congratulations on Completion!
                                                                </div>
                                                            )}
                                                            <div
                                                                className={`flex justify-center items-center text-center mb-1 ${
                                                                    Math.round(
                                                                        (earnedlPointsForQuestion /
                                                                            maximumPointsForQuestion) *
                                                                            100
                                                                    ) < recommendedScore && `mt-4`
                                                                }`}
                                                            >
                                                                <span className="text-base font-normal">
                                                                    You score :&nbsp;
                                                                </span>
                                                                <span className="text-base font-bold text-purple-950">
                                                                    {Math.round(
                                                                        (earnedlPointsForQuestion /
                                                                            maximumPointsForQuestion) *
                                                                            100
                                                                    )}
                                                                    %&nbsp;
                                                                </span>
                                                                {earnedlPointsForQuestion <
                                                                    maximumPointsForQuestion && (
                                                                    <span className="text-sm font-normal text-purple-950">
                                                                        (Score{' '}
                                                                        {earnedlPointsForQuestion}
                                                                        XP)
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="flex justify-center items-center text-center">
                                                                <span className="text-base font-normal text-red-500">
                                                                    Recommended minimum score
                                                                    :&nbsp;
                                                                </span>
                                                                <span className="text-base font-bold text-purple-950">
                                                                    {recommendedScore}%&nbsp;
                                                                </span>
                                                            </div>
                                                            <div className="text-base font-normal text-purple-950 text-center mt-3 mb-4">
                                                                {Math.round(
                                                                    (earnedlPointsForQuestion /
                                                                        maximumPointsForQuestion) *
                                                                        100
                                                                ) >= recommendedScore
                                                                    ? `You have successfully completed the exercise. Check out your next step.`
                                                                    : `Don't worry. There is always a next time!`}
                                                            </div>
                                                            <div className="flex justify-center">
                                                                {Math.round(
                                                                    (earnedlPointsForQuestion /
                                                                        maximumPointsForQuestion) *
                                                                        100
                                                                ) >= recommendedScore ? (
                                                                    <button
                                                                        className="border-2 border-purple-950 bg-purple-950 text-white font-bold rounded w-40 h-12 ml-3"
                                                                        onClick={() => {
                                                                            setSelectedSection(
                                                                                selectedSection + 1
                                                                            );
                                                                            setShowModal(false);
                                                                        }}
                                                                    >
                                                                        Next Step
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        className="border-2 border-purple-950 text-purple-950 text-base font-bold rounded w-40 h-12 mr-3"
                                                                        onClick={() => {
                                                                            setSelectedSection(
                                                                                selectedSection + 1
                                                                            );
                                                                            setShowModal(false);
                                                                        }}
                                                                    >
                                                                        Continue
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </Modal>
                                    </>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="ml-2">
                    <Toastr fullWidth={true} message={message} showToast={showToast} />
                    <StartChallengeModal
                        modalVisible={startLearningModal}
                        setModalVisible={setStartLearningModal}
                        challengeData={challengeData}
                        setOnClickSuccess={setOnClickSuccess}
                        selectedSection={selectedSection}
                        selectedSectionHandler={selectedSectionHandler}
                    />
                    <AbondanChallengeModal
                        modalVisible={abandonModalVisible}
                        setModalVisible={setAbandonModalVisible}
                        setOnClickSuccess={clickOpenSelection}
                    />
                    <CompleteChallengeModal
                        modalVisible={challengeCompleteModal}
                        setModalVisible={setChallengeCompleteModal}
                        setOnClickSuccess={onComplete}
                    />
                    <div className={`flex flex-col`}>
                        <RequestMentorModal
                            showAbleToRequestModal={showAbleToRequestModal}
                            setShowAbleToRequestModal={setShowAbleToRequestModal}
                            customStyles={customStyles}
                        />
                        <EarnBadgeModal
                            showModal={badgeShowModal}
                            setShowModal={setBadgeShowModal}
                            Badges={achievements?.earnedBadges}
                            isCertificatesAvailable={IsCertificatesAvailable}
                            isNextChallengeClicked={isNextChallengeClicked}
                            selectedTrack={locationState?.track}
                        />
                        <CompleteChallenge
                            showModal={completeChallengeShowModal}
                            setShowModal={setCompleteChallengeShowModal}
                            setOnClickNextChallenge={setOnClickNextChallenge}
                            setOnClickTryAgain={setOnClickTryAgain}
                            challengeName={challengeName}
                        />
                        <ChallengeCompletionCertificateModal
                            showModal={certificateShowModal}
                            setShowModal={setCertificateShowModal}
                            isNextChallengeClicked={isNextChallengeClicked}
                            certificate={achievements?.earnedCertificate[0]}
                            selectedTrack={locationState?.track}
                        />
                        {challengeByIdByStudentLoading || submittedTaskDataLoading ? (
                            <div className="flex align-middle items-center content-center w-full h-full">
                                <Loader />
                            </div>
                        ) : (
                            <div className="w-full">
                                <div className="flex flex-col gap-y-4 mt-4 ml-5">
                                    <div
                                        className="cursor-pointer flex"
                                        onClick={() =>
                                            history.push({
                                                pathname: locationState?.path,
                                                state: {
                                                    tracks: locationState?.track,
                                                },
                                            })
                                        }
                                    >
                                        <BackArrow size="27" />
                                        <p className="text-base ml-2 font-semibold hover:underline">
                                            Back
                                        </p>
                                    </div>
                                    <div className="flex gap-x-3">
                                        <div className="flex flex-col w-2/3">
                                            <div className="font-bold text-lg mb-1">
                                                {challengeData?.challengeName}
                                            </div>
                                            <p className="text-sm">
                                                {challengeData?.challengeDescription}
                                            </p>
                                        </div>
                                        <div className="flex flex-col">
                                            <img
                                                className="h-20 w-20"
                                                src={
                                                    challengeData?.imageLink
                                                        ? `${s3UrlPrefix}${challengeData.imageLink}`
                                                        : `/assets/computer.png`
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="flex gap-x-1 items-center">
                                        <div className="flex flex-col gap-y-2 w-9/12">
                                            <div
                                                className="text-black-400"
                                                style={{ fontSize: 10 }}
                                            >
                                                {`Your score: ${earnedPointsForChallenge} XP/${maximumPointsForChallenge} XP`}
                                            </div>
                                            <div className="challenge-progress-line">
                                                <Line
                                                    percent={
                                                        (earnedPointsForChallenge /
                                                            maximumPointsForChallenge) *
                                                        100
                                                    }
                                                    strokeWidth={1}
                                                    strokeColor="#4C2EC2"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            {' '}
                                            <Education size={50} />
                                        </div>
                                    </div>

                                    {/* {!startLearningModal && (
                                        <div
                                            className={`${
                                                challengeData?.StatusValue === 0
                                                    ? 'rounded mb-2 mt-1 font-semibold text-purple-800 border-2 border-purple-800 cursor-pointer h-10 w-40 flex justify-center items-center'
                                                    : 'rounded mb-2 font-semibold text-white bg-purple-800 cursor-pointer h-10 w-40 flex justify-center items-center'
                                            } `}
                                            onClick={() => {
                                                startLearningHandler();
                                            }}
                                        >
                                            {challengeData?.StatusValue === 0
                                                ? 'Try Again'
                                                : challengeData?.StatusValue === 1
                                                ? 'Continue'
                                                : 'Start learning'}
                                        </div>
                                    )} */}
                                </div>

                                <div className="mt-8 mb-32 px-4">
                                    <>
                                        <div className="flex justify-between">
                                            <MarkdownRenderer>
                                                {markdownDescription}
                                            </MarkdownRenderer>
                                        </div>
                                        <MessageToaster
                                            message={challengeStartedMessage}
                                            showToast={hasChallengeStarted}
                                        />
                                        <div className="border-b-2 mt-2 mb-6" />

                                        <div className="pr-5">
                                            {/* <div className="font-semibold text-xl mb-1 mt-6">
                                                Steps to Follow Challenges
                                            </div> */}

                                            {/* Start of course video */}
                                            {challengeData?.introLink && (
                                                <div
                                                    style={{
                                                        background: videoSection ? '#F5F3FC' : '',
                                                    }}
                                                    className={`mt-6 flex flex-col ${
                                                        videoSection
                                                            ? 'bg-purple-100'
                                                            : 'border-2 rounded'
                                                    }`}
                                                >
                                                    <div
                                                        className=" flex items-center h-14 lg:h-24 justify-between cursor-pointer"
                                                        onClick={() => {
                                                            if (videoSection) {
                                                                setVideoSection(false);
                                                            } else {
                                                                setVideoSection(true);
                                                            }
                                                        }}
                                                    >
                                                        <div className="flex items-center ml-4">
                                                            <div>
                                                                <div
                                                                    className={`text-xs lg:text-base font-bold ${
                                                                        uploadFileComplete
                                                                            ? 'mt-4'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    Watch this intro video to get a
                                                                    better understanding
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-1 rounded-full hover:bg-purple-100 hover:text-blue-900 cursor-pointer mr-4">
                                                            {videoSection ? (
                                                                <UpArrow />
                                                            ) : (
                                                                <DownArrow />
                                                            )}
                                                        </div>
                                                    </div>
                                                    {videoSection ? (
                                                        <div
                                                            style={{ maxHeight: '575px' }}
                                                            id="mcqsection"
                                                            className="border-t-2"
                                                        >
                                                            <div className="my-10 flex items-center justify-center">
                                                                <iframe
                                                                    src={`https://youtube.com/embed/${youtubeParser(
                                                                        challengeData.introLink
                                                                    )}`}
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen
                                                                    title="Embedded youtube"
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                            {/* End of course video */}
                                            {taskSectionList?.map((section, sectionIndex) => (
                                                <div
                                                    id={`section${selectedSection}`}
                                                    key={sectionIndex}
                                                >
                                                    <TaskSection
                                                        key={sectionIndex}
                                                        taskSectionList={taskSectionList}
                                                        section={section}
                                                        selectedSection={selectedSection}
                                                        sectionIndex={sectionIndex}
                                                        selectedSectionHandler={
                                                            selectedSectionHandler
                                                        }
                                                        updateTaskSectionListHandler={
                                                            updateTaskSectionListHandler
                                                        }
                                                        submitTaskHandler={submitTaskHandler}
                                                        challengeStarted={challengeStarted}
                                                        setMessage={setMessage}
                                                        challengeData={challengeData}
                                                        setChallengeStarted={setChallengeStarted}
                                                        setHasChallengeStarted={
                                                            setHasChallengeStarted
                                                        }
                                                        setChallengeStartedMessage={
                                                            setChallengeStartedMessage
                                                        }
                                                        setVideoSection={setVideoSection}
                                                        totalChallengePoints={
                                                            maximumPointsForChallenge
                                                        }
                                                        setChallengeOngoing={setChallengeOngoing}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        {/* {challengeComplete && (
                                            <div className="flex justify-end">
                                                <button
                                                    onClick={() => {
                                                        onComplete();
                                                    }}
                                                    className="mt-8 mr-40 rounded border-2  font-semibold w-44 h-10 justify-center items-center flex hover:text-white hover:bg-green-500 cursor-pointer text-green-500 border-green-500"
                                                >
                                                    {submitChallengeIsLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        'Complete Challenge'
                                                    )}
                                                </button>
                                            </div>
                                        )} */}

                                        <Modal isOpen={showModal} style={customStyles}>
                                            <>
                                                <div className="flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none overflow-hidden">
                                                    {Math.round(
                                                        (earnedlPointsForQuestion /
                                                            maximumPointsForQuestion) *
                                                            100
                                                    ) >= recommendedScore && <Confetti />}
                                                    <div
                                                        style={
                                                            Math.round(
                                                                (earnedlPointsForQuestion /
                                                                    maximumPointsForQuestion) *
                                                                    100
                                                            ) >= recommendedScore
                                                                ? {
                                                                      height: '350px',
                                                                      width: '530px',
                                                                  }
                                                                : {
                                                                      height: '340px',
                                                                      width: '530px',
                                                                  }
                                                        }
                                                        className="flex flex-col justify-center items-center w-full relative rounded-lg shadow-lg bg-white outline-none focus:outline-none"
                                                    >
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: '-65px',
                                                            }}
                                                            className="flex flex-col justify-center items-center px-4"
                                                        >
                                                            <span className="challengeComplete self-center mb-8" />
                                                            <div className="text-base font-normal text-purple-950">
                                                                {Math.round(
                                                                    (earnedlPointsForQuestion /
                                                                        maximumPointsForQuestion) *
                                                                        100
                                                                ) >= recommendedScore
                                                                    ? 'You are Awesome!'
                                                                    : 'There is always a next time!'}
                                                            </div>
                                                            {Math.round(
                                                                (earnedlPointsForQuestion /
                                                                    maximumPointsForQuestion) *
                                                                    100
                                                            ) >= recommendedScore && (
                                                                <div className="text-xl font-bold mt-2 mb-3">
                                                                    Congratulations on Completion!
                                                                </div>
                                                            )}
                                                            <div
                                                                className={`flex justify-center items-center text-center mb-1 ${
                                                                    Math.round(
                                                                        (earnedlPointsForQuestion /
                                                                            maximumPointsForQuestion) *
                                                                            100
                                                                    ) < recommendedScore && `mt-4`
                                                                }`}
                                                            >
                                                                <span className="text-base font-normal">
                                                                    You score :&nbsp;
                                                                </span>
                                                                <span className="text-base font-bold text-purple-950">
                                                                    {Math.round(
                                                                        (earnedlPointsForQuestion /
                                                                            maximumPointsForQuestion) *
                                                                            100
                                                                    )}
                                                                    %&nbsp;
                                                                </span>
                                                                {earnedlPointsForQuestion <
                                                                    maximumPointsForQuestion && (
                                                                    <span className="text-sm font-normal text-purple-950">
                                                                        (Score{' '}
                                                                        {earnedlPointsForQuestion}
                                                                        XP)
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="flex justify-center items-center text-center">
                                                                <span className="text-base font-normal text-red-500">
                                                                    Recommended minimum score
                                                                    :&nbsp;
                                                                </span>
                                                                <span className="text-base font-bold text-purple-950">
                                                                    {recommendedScore}%&nbsp;
                                                                </span>
                                                            </div>
                                                            <div className="text-base font-normal text-purple-950 text-center mt-3 mb-4">
                                                                {Math.round(
                                                                    (earnedlPointsForQuestion /
                                                                        maximumPointsForQuestion) *
                                                                        100
                                                                ) >= recommendedScore
                                                                    ? `You have successfully completed the exercise. Check out your next step.`
                                                                    : `Don't worry. There is always a next time!`}
                                                            </div>
                                                            <div className="flex justify-center">
                                                                {Math.round(
                                                                    (earnedlPointsForQuestion /
                                                                        maximumPointsForQuestion) *
                                                                        100
                                                                ) >= recommendedScore ? (
                                                                    <button
                                                                        className="border-2 border-purple-950 bg-purple-950 text-white font-bold rounded w-40 h-12 ml-3"
                                                                        onClick={() => {
                                                                            setSelectedSection(
                                                                                selectedSection + 1
                                                                            );
                                                                            setShowModal(false);
                                                                        }}
                                                                    >
                                                                        Next Step
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        className="border-2 border-purple-950 text-purple-950 text-base font-bold rounded w-40 h-12 mr-3"
                                                                        onClick={() => {
                                                                            setSelectedSection(
                                                                                selectedSection + 1
                                                                            );
                                                                            setShowModal(false);
                                                                        }}
                                                                    >
                                                                        Continue
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </Modal>
                                    </>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Challenge;
