import React, { ReactElement, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';

import OtpInput from 'react-otp-input';

import PhoneInput from 'react-phone-input-2';
import { RootState } from '../../redux/rootReducer';
import { mobielNumberVerification } from '../utils';
import { loginMobileWeb } from '../../redux/webRedux/authWeb/actions';
import { generateOTP, verifyMobileNumber } from '../../redux/rootActions';
import CloseIcon from '../../assets/svg/CloseIcon';
import { USER_PROFILE } from '../utils/storageWeb';

interface Props {
    mobileNumber: any;
    showModal: boolean;
    setShowModal: any;
    setVerifyPhoneNumberSuccess: any;
}

export default function VerifyPhoneNumberModal({
    mobileNumber,
    showModal,
    setShowModal,
    setVerifyPhoneNumberSuccess,
}: Props): ReactElement {
    const dispatch = useDispatch();

    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const loginErrorMessage = useSelector((state: RootState) => state.webAuth.loginErrorMessage);

    const isOtpSent = useSelector((state: RootState) => state.newWebUser.otpSent);
    const mobileLoginError = useSelector((state: RootState) => state.newWebUser.mobileLoginError);
    const otpResponse = useSelector((state: RootState) => state.newWebUser.otpResponse);

    const confirmMobileVerificationSuccess = useSelector(
        (state: RootState) => state.webUser.confirmMobileVerificationSuccess
    );

    const [invalidLogin, setInvalidLogin] = useState(false);

    const [isMobileError, setIsMobileError] = useState<boolean>(false);

    const [mobileCodeSent, setMobileCodeSent] = useState(false);
    const [countDown, setCountDown] = useState<string>();
    const [timerTigger, setTimerTigger] = useState(false);
    const [OTPCode, setOTPCode] = useState<any>();
    const [OTPError, setOTPError] = useState<boolean>(false);

    const [otpResentSuccess, setOtpResentSuccess] = useState(false);

    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };
    useEffect(() => {
        if (otpResentSuccess) {
            setTimeout(() => {
                setOtpResentSuccess(false);
            }, 3000);
        }
    }, [otpResentSuccess]);

    let timeleft = 60;
    useEffect(() => {
        if (timerTigger === true) {
            const downloadTimer = setInterval(function () {
                if (timeleft <= 0) {
                    clearInterval(downloadTimer);
                    setCountDown('Expired');
                } else if (timeleft < 10) {
                    setCountDown('00:0' + timeleft);
                } else {
                    setCountDown('00:' + timeleft);
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
                timeleft -= 1;
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeleft, timerTigger]);

    useEffect(() => {
        if (mobielNumberVerification(mobileNumber)) {
            setIsMobileError(false);
        }
    }, [mobileNumber]);

    useEffect(() => {
        setTimeout(() => {
            setInvalidLogin(false);
        }, 3000);
    }, [invalidLogin]);

    const submitMobileLoggin = () => {
        dispatch(verifyMobileNumber(OTPCode, mobileNumber, profile?.userId));
    };

    useEffect(() => {
        if (otpResponse) {
            setMobileCodeSent(true);
        } else if (mobileLoginError) {
            setIsMobileError(true);
        }
    }, [isOtpSent, otpResponse, mobileLoginError]);

    useEffect(() => {
        if (confirmMobileVerificationSuccess) {
            setVerifyPhoneNumberSuccess(true);
            setShowModal(false);
        }
    }, [confirmMobileVerificationSuccess]);

    useEffect(() => {
        setTimeout(() => {
            setIsMobileError(false);
        }, 4000);
    }, [isMobileError]);

    return (
        <div>
            <Modal isOpen={showModal} style={customStyles}>
                <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                    <div
                        style={{ width: '643px' }}
                        className="hidden lg:block relative my-6  mx-auto"
                    >
                        {/* content */}
                        <div
                            className="absolute z-50 top-6 right-6 cursor-pointer"
                            onClick={() => setShowModal(false)}
                        >
                            <CloseIcon size={24} color="rgba(17, 17, 17, 0.48)" />
                        </div>
                        <div
                            className="border-2  rounded-lg shadow-lg relative flex flex-col items-center w-full bg-white outline-none focus:outline-none p-6"
                            style={{ maxHeight: '656px' }}
                        >
                            <div>
                                <div className="my-3 md:my-6">
                                    <label
                                        className={`block text-sm font-medium leading-149 mb-2 ${
                                            isMobileError ? 'text-red-500' : ''
                                        }`}
                                        htmlFor={'phoneNumber'}
                                    >
                                        OTP Verification code
                                    </label>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        className={`border-b flex flex-row ${
                                            isMobileError ? 'border-red-500' : 'border-gray-600'
                                        }`}
                                    >
                                        <OtpInput
                                            value={OTPCode}
                                            onChange={otp => {
                                                if (!isNaN(Number(otp))) {
                                                    setOTPCode(otp);
                                                }
                                            }}
                                            separator={
                                                <span>
                                                    <strong>-</strong>
                                                </span>
                                            }
                                            shouldAutoFocus={true}
                                            inputStyle={{
                                                width: '3rem',
                                                height: '3rem',
                                                margin: '0 1.5rem 0.5rem',
                                                borderRadius: 4,
                                                border: '1px solid rgba(0,0,0,1)',
                                            }}
                                        />
                                    </div>
                                    {
                                        // eslint-disable-next-line eqeqeq
                                        OTPError === true ? (
                                            <div
                                                className="sm:w-1/2 md:w-full sm:mt-0 bg-red-100 shadow-xs mt-2"
                                                style={{
                                                    borderRadius: 2,
                                                    minWidth: 242,
                                                }}
                                            >
                                                <div
                                                    className="flex flex-row "
                                                    style={{
                                                        height: 40,
                                                        padding: 9,
                                                    }}
                                                >
                                                    <span className="ml-2 font-semibold text-sm">
                                                        Invalid OTP verification code
                                                    </span>
                                                    <span
                                                        onClick={() => {
                                                            submitMobileLoggin();
                                                            setOtpResentSuccess(true);
                                                        }}
                                                        className="ml-2 font-semibold text-red-500 text-sm underline cursor-pointer"
                                                    >
                                                        Resend code
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <p className="mt-2 text-sm">
                                                <span
                                                    id="countdown"
                                                    className="text-purple-950 mx-1"
                                                >
                                                    {countDown}
                                                </span>{' '}
                                                Did not receive the verification yet?
                                                <span
                                                    onClick={() => {
                                                        timeleft = 60;
                                                        submitMobileLoggin();
                                                        setOtpResentSuccess(true);
                                                    }}
                                                    className="ml-2 text-purple-950 hover:underline cursor-pointer"
                                                >
                                                    Resend code
                                                </span>
                                            </p>
                                        )
                                    }
                                    {otpResentSuccess ? (
                                        <div className="mt-2 flex justify-center text-center text-green-400">
                                            <img
                                                src="/assets/tick.png"
                                                className="mr-2 w-4 h-4 mt-1"
                                                alt=""
                                            />
                                            OTP code resent!
                                        </div>
                                    ) : null}
                                </div>

                                {loginErrorMessage ? (
                                    <div
                                        className="sm:w-1/2 md:w-full sm:mt-0 bg-red-100 shadow-xs mb-2"
                                        style={{
                                            borderRadius: 2,
                                            minWidth: 242,
                                        }}
                                    >
                                        <div
                                            className="text-center"
                                            style={{ height: 40, padding: 9 }}
                                        >
                                            <span className="font-semibold text-sm ">
                                                {loginErrorMessage}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}

                                <div className="mb-4 md:mb-8 text-center">
                                    <button
                                        type="submit"
                                        className={`focus:outline-none bg-purple-400 text-white font-semibold rounded border hover:border-transparent w-40 h-10 sm:w-80 md:w-full sm:h-12 ${
                                            !isNaN(mobileNumber) ? 'bg-purple-950' : ''
                                        }`}
                                        style={{
                                            boxShadow: '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                        }}
                                        disabled={isNaN(mobileNumber)}
                                        onClick={() => {
                                            submitMobileLoggin();
                                        }}
                                    >
                                        Verify
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '360px' }} className="relative lg:hidden my-6">
                        {/* content */}
                        <div
                            className="absolute z-50 top-6 right-6 cursor-pointer"
                            onClick={() => setShowModal(false)}
                        >
                            <CloseIcon size={24} color="rgba(17, 17, 17, 0.48)" />
                        </div>
                        <div
                            className="border-2  rounded-lg shadow-lg relative flex flex-col items-center w-full bg-white outline-none focus:outline-none p-6"
                            style={{ maxHeight: '400px' }}
                        >
                            <div>
                                <div className="my-3 md:my-6">
                                    <label
                                        className={`block text-sm font-medium leading-149 mb-2 ${
                                            isMobileError ? 'text-red-500' : ''
                                        }`}
                                        htmlFor={'phoneNumber'}
                                    >
                                        OTP Verification code
                                    </label>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        className={`border-b flex flex-row ${
                                            isMobileError ? 'border-red-500' : 'border-gray-600'
                                        }`}
                                    >
                                        <OtpInput
                                            value={OTPCode}
                                            onChange={otp => {
                                                if (!isNaN(Number(otp))) {
                                                    setOTPCode(otp);
                                                }
                                            }}
                                            separator={
                                                <span>
                                                    <strong>-</strong>
                                                </span>
                                            }
                                            shouldAutoFocus={true}
                                            inputStyle={{
                                                width: '2rem',
                                                height: '3rem',
                                                margin: '0 1.5rem 0.5rem',
                                                borderRadius: 4,
                                                border: '1px solid rgba(0,0,0,1)',
                                            }}
                                        />
                                    </div>
                                    {
                                        // eslint-disable-next-line eqeqeq
                                        OTPError === true ? (
                                            <div
                                                className="sm:w-1/2 md:w-full sm:mt-0 bg-red-100 shadow-xs mt-2"
                                                style={{
                                                    borderRadius: 2,
                                                    minWidth: 242,
                                                }}
                                            >
                                                <div
                                                    className="flex flex-row "
                                                    style={{
                                                        height: 40,
                                                        padding: 9,
                                                    }}
                                                >
                                                    <span className="ml-2 font-semibold text-sm">
                                                        Invalid OTP verification code
                                                    </span>
                                                    <span
                                                        onClick={() => {
                                                            submitMobileLoggin();
                                                            setOtpResentSuccess(true);
                                                        }}
                                                        className="ml-2 font-semibold text-red-500 text-sm underline cursor-pointer"
                                                    >
                                                        Resend code
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <p className="mt-2 text-sm">
                                                <span
                                                    id="countdown"
                                                    className="text-purple-950 mx-1"
                                                >
                                                    {countDown}
                                                </span>{' '}
                                                Did not receive the verification yet?
                                                <span
                                                    onClick={() => {
                                                        timeleft = 60;
                                                        submitMobileLoggin();
                                                        setOtpResentSuccess(true);
                                                    }}
                                                    className="ml-2 text-purple-950 hover:underline cursor-pointer"
                                                >
                                                    Resend code
                                                </span>
                                            </p>
                                        )
                                    }
                                    {otpResentSuccess ? (
                                        <div className="mt-2 flex justify-center text-center text-green-400">
                                            <img
                                                src="/assets/tick.png"
                                                className="mr-2 w-4 h-4 mt-1"
                                                alt=""
                                            />
                                            OTP code resent!
                                        </div>
                                    ) : null}
                                </div>

                                {loginErrorMessage ? (
                                    <div
                                        className="sm:w-1/2 md:w-full sm:mt-0 bg-red-100 shadow-xs mb-2"
                                        style={{
                                            borderRadius: 2,
                                            minWidth: 242,
                                        }}
                                    >
                                        <div
                                            className="text-center"
                                            style={{ height: 40, padding: 9 }}
                                        >
                                            <span className="font-semibold text-sm ">
                                                {loginErrorMessage}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}

                                <div className="mb-4 md:mb-8 text-center">
                                    <button
                                        type="submit"
                                        className={`focus:outline-none bg-purple-400 text-white font-semibold rounded border hover:border-transparent w-48 h-12 ${
                                            !isNaN(mobileNumber) ? 'bg-purple-950' : ''
                                        }`}
                                        style={{
                                            boxShadow: '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                        }}
                                        disabled={isNaN(mobileNumber)}
                                        onClick={() => {
                                            submitMobileLoggin();
                                        }}
                                    >
                                        Verify
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
