import React, { ReactElement } from 'react';

interface Props {
    size?: number;
}

export default function NextArrow({ size }: Props): ReactElement {
    return (
        <svg
            width={size || 30}
            height={size || 30}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.0002 28.3334C7.63637 28.3334 1.66683 22.3639 1.66683 15.0001C1.66683 7.63628 7.63637 1.66675 15.0002 1.66675C22.364 1.66675 28.3335 7.63628 28.3335 15.0001C28.3335 22.3639 22.364 28.3334 15.0002 28.3334Z"
                fill="white"
                stroke={'#4C2EC2'}
                strokeWidth="2"
            />
            <path
                d="M17 9.66675L11.6667 15.0001L17 20.3334"
                stroke={'#4C2EC2'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
