import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { USER_PROFILE } from '../../utils/storage';
import { getCompanies, joinedDate } from '../rootActions';
import { students, trackCreatedDate, trackLastUpdatedDate } from '../tracks/actions';
import { TracksType } from '../tracks/reducers';

const PREFIX = '@COMPANY_TAB';

export const ADD_COMPANY_TAB = PREFIX + 'ADD_COMPANY_TAB';
export const ADD_COMPANY_TAB_SUCCESS = PREFIX + 'ADD_COMPANY_TAB_SUCCESS';
export const ADD_COMPANY_TAB_FAILED = PREFIX + 'ADD_COMPANY_TAB_FAILED';
export const GET_COMPANY_TAB = PREFIX + 'GET_COMPANY_TAB';
export const GET_COMPANY_TAB_SUCCESS = PREFIX + 'GET_COMPANY_TAB_SUCCESS';
export const GET_COMPANY_TAB_FAILED = PREFIX + 'GET_COMPANY_TAB_FAILED';

export interface CompanyTabCreateType {
    id?: string | null;
    tabName?: string | null;
    tags?: any[];
    students?: students;
    tracks?: TracksType[];
    trackLastUpdatedDate?: trackLastUpdatedDate;
    accountType?: string | null;
    joinedDate?: joinedDate;
    trackCreatedBy?: string | null;
    trackCreatedDate?: trackCreatedDate;
    trackName?: string | null;
    program?: number;
}

export const getCompanyTabs = (id?: string, association?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_COMPANY_TAB });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/users/admins/filter?id=${id}&association=${association}`,
            config
        );
        dispatch({
            type: GET_COMPANY_TAB_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_COMPANY_TAB_FAILED, payload: getErrorMessage(error) });
    }
};

export const addCompaniesTab = (tab: CompanyTabCreateType) => async (dispatch: any) => {
    try {
        if (tab?.accountType === undefined || tab?.accountType === null) {
            delete tab?.accountType;
        }
        if (tab?.joinedDate === undefined || tab?.joinedDate === null) {
            delete tab?.joinedDate;
        }
        if (tab?.program === undefined || tab?.program === null) {
            delete tab?.program;
        }
        if (tab?.students === undefined || tab?.students === null) {
            delete tab?.students;
        }
        if (tab?.tags === undefined || tab?.tags === null) {
            delete tab?.tags;
        }
        if (tab?.trackName === undefined || tab?.trackName === null) {
            delete tab?.trackName;
        }
        if (tab?.tracks === undefined || tab?.tracks === null) {
            delete tab?.tracks;
        }
        dispatch({ type: ADD_COMPANY_TAB });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/organizations/companies/filter`, tab, config);
        const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
        await dispatch(getCompanyTabs(profile?.userId, 'COMPANY'));

        dispatch({ type: ADD_COMPANY_TAB_SUCCESS, payload: res.data.responseDto.data });
    } catch (error) {
        dispatch({ type: ADD_COMPANY_TAB_FAILED, payload: getErrorMessage(error) });
    }
};
