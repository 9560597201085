import {
    ADD_EVENT,
    ADD_EVENT_FAILED,
    ADD_EVENT_SUCCESS,
    CLEAR_EVENTS_MESSAGES,
    EVENT_BULK_UPDATE,
    EVENT_BULK_UPDATE_FAILED,
    EVENT_BULK_UPDATE_SUCCESS,
    DELETE_EVENT,
    DELETE_EVENT_FAILED,
    DELETE_EVENT_SUCCESS,
    EDIT_EVENT,
    EDIT_EVENT_FAILED,
    EDIT_EVENT_SUCCESS,
    GET_EVENTS,
    GET_EVENTS_FAILED,
    GET_EVENTS_SUCCESS,
    GET_EVENT_BY_ID,
    GET_EVENT_BY_ID_FAILED,
    GET_EVENT_BY_ID_SUCCESS,
    GET_PUBLIC_EVENTS,
    GET_PUBLIC_EVENTS_FAILED,
    GET_PUBLIC_EVENTS_SUCCESS,
    GET_PUBLIC_EVENT_BY_ID,
    GET_PUBLIC_EVENT_BY_ID_FAILED,
    GET_PUBLIC_EVENT_BY_ID_SUCCESS,
} from './actions';

export interface EventEditType {
    eventId: string;
    eventLink: string;
    eventName: string;
    eventStatus: string;
    eventImage: string;
    eventDescription: string;
    eventStartTime: string;
    eventEndTime: string;
    location: string;
    registerLink: string;
    tags: [];
    users: [];
}
export interface EventType {
    id: string;
    eventId: string;
    eventLink: string;
    eventName: string;
    eventStatus: string;
    eventType: string;
    eventImage: string;
    eventDescription: string;
    eventStartTime: string;
    eventEndTime: string;
    location: string;
    registerLink: string;
    tags: any;
    users: [];
}
export interface CountType {
    ALL: number;
    ACTIVE: number;
    INACTIVE: number;
    DELETED: number;
}
export interface EventsInitialState {
    eventsData: EventType[];
    event: EventType;
    publicEventsData: EventType[];
    publicEvent: EventType;
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    eventByIdLoading: boolean;
    eventByIdError: string | null;
    eventError: string | null;
    createEventError: string | null;
    eventCreateSuccessMessage: string | null;
    isDeleteLoading: boolean;
    deleteEventError: string | null;
    eventDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editEventError: string | null;
    eventUpdateSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    eventBulkSuccessMessage: string | null;
}

const initialState: EventsInitialState = {
    eventsData: [],
    publicEventsData: [],
    event: null,
    publicEvent: null,
    pageCount: null,
    count: null,
    isLoading: false,
    eventByIdLoading: false,
    eventByIdError: null,
    eventError: null,
    createEventError: null,
    eventCreateSuccessMessage: null,
    isDeleteLoading: false,
    deleteEventError: null,
    eventDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editEventError: null,
    eventUpdateSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    eventBulkSuccessMessage: null,
};

const eventsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_EVENTS:
            return { ...state, isLoading: true, eventsError: null };
        case GET_EVENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                eventsData: action.payload.items,
                pageCount: action.payload.totalPages,
            };
        case GET_EVENTS_FAILED:
            return { ...state, isLoading: false, eventsError: action.payload };
        case GET_EVENT_BY_ID:
            return { ...state, eventByIdLoading: true, eventByIdError: null };
        case GET_EVENT_BY_ID_SUCCESS:
            return {
                ...state,
                eventByIdLoading: false,
                event: action.payload,
            };
        case GET_EVENT_BY_ID_FAILED:
            return { ...state, eventByIdLoading: false, eventByIdError: action.payload };

        case GET_PUBLIC_EVENTS:
            return { ...state, isLoading: true, eventsError: null };
        case GET_PUBLIC_EVENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                publicEventsData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case GET_PUBLIC_EVENTS_FAILED:
            return { ...state, isLoading: false, eventsError: action.payload };
        case GET_PUBLIC_EVENT_BY_ID:
            return { ...state, eventByIdLoading: true, eventByIdError: null };
        case GET_PUBLIC_EVENT_BY_ID_SUCCESS:
            return {
                ...state,
                eventByIdLoading: false,
                publicEvent: action.payload,
            };
        case GET_PUBLIC_EVENT_BY_ID_FAILED:
            return { ...state, eventByIdLoading: false, eventByIdError: action.payload };

        case ADD_EVENT:
            return { ...state, isCreateLoading: true, createEventError: null };
        case ADD_EVENT_SUCCESS:
            return {
                ...state,
                isCreateLoading: false,
                eventCreateSuccessMessage: action.payload,
            };
        case ADD_EVENT_FAILED:
            return { ...state, isCreateLoading: false, createEventrror: action.payload };
        case DELETE_EVENT:
            return { ...state, isDeleteLoading: true, deleteEventrror: null };
        case DELETE_EVENT_SUCCESS:
            return {
                ...state,
                isDeleteLoading: false,
                eventDeleteSuccessMessage: action.payload,
            };
        case DELETE_EVENT_FAILED:
            return { ...state, isDeleteLoading: false, deleteEventError: action.payload };
        case EDIT_EVENT:
            return { ...state, isEditLoading: true, editEventError: null };
        case EDIT_EVENT_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                eventUpdateSuccessMessage: action.payload,
            };
        case EDIT_EVENT_FAILED:
            return { ...state, isEditLoading: false, editEventError: action.payload };
        case EVENT_BULK_UPDATE:
            return { ...state, isBulkUpdateLoading: true, bulkError: null };
        case EVENT_BULK_UPDATE_SUCCESS:
            return {
                ...state,
                isBulkUpdateLoading: false,
                eventBulkSuccessMessage: action.payload,
            };
        case EVENT_BULK_UPDATE_FAILED:
            return { ...state, isBulkUpdateLoading: false, bulkError: action.payload };
        case CLEAR_EVENTS_MESSAGES:
            return {
                ...state,
                eventCreateSuccessMessage: null,
                createEventError: null,
                eventUpdateSuccessMessage: null,
                editEventError: null,
                deleteEventError: null,
                eventDeleteSuccessMessage: null,
                eventBulkSuccessMessage: null,
                bulkError: null,
            };
        default:
            return state;
    }
};

export default eventsReducer;
