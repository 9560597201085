import React, { ReactElement } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    authType: string;
}

export default function SocialMediaLoginUnableChange({
    modalVisible,
    setModalVisible,
    authType,
}: Props): ReactElement {
    const history = useHistory();

    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    return (
        <>
            <Modal isOpen={modalVisible} style={customStyles}>
                <>
                    <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                        <div style={{ width: '400px' }} className="relative  mx-auto">
                            {/* content */}
                            <div
                                className="border-2  rounded-lg shadow-lg relative flex flex-col items-center w-full bg-white outline-none focus:outline-none p-8"
                                style={{ maxHeight: '656px' }}
                            >
                                <div className="mb-8">
                                    You cannot change your Email/Password as you have logged in
                                    through {authType}
                                </div>
                                <button
                                    className="w-40 px-2 bg-purple-950 h-12 text-white rounded font-bold"
                                    onClick={() => {
                                        setModalVisible(false);
                                    }}
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        </>
    );
}
