import React, { ReactElement } from 'react';

interface Props {
    color: string;
    size?: number;
}

export default function Challenge({ color, size }: Props): ReactElement {
    return (
        <svg
            width={size || '22'}
            height={size || '21'}
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.5 17.5V4.72077C20.8601 4.87327 21.1895 5.04697 21.5 5.25736V19.5786C21.5 19.5787 21.4999 19.5788 21.4998 19.5789C21.4973 19.5824 21.4941 19.5863 21.4902 19.5902C21.4863 19.5941 21.4824 19.5973 21.4789 19.5998C21.4788 19.5999 21.4788 19.5999 21.4787 19.5999L21.469 19.5999L21.4686 19.5999C19.9775 18.8061 18.1964 18.5 16.5 18.5C15.5945 18.5 14.5118 18.6718 13.4882 18.9486C12.5969 19.1896 11.7208 19.5183 11.0258 19.9043C9.47198 18.8609 7.38255 18.5 5.5 18.5C4.00079 18.5 2.02273 18.9509 0.611333 19.6123C0.569224 19.6198 0.533853 19.6294 0.507223 19.6375C0.504451 19.6345 0.502079 19.6316 0.500152 19.6289C0.5001 19.6288 0.50005 19.6287 0.5 19.6286V5.25602C1.81767 4.35665 3.68928 4 5.5 4C7.31072 4 9.18233 4.35665 10.5 5.25602V18.5V19.4057L11.2664 18.9231C11.8846 18.5339 12.7832 18.1752 13.7493 17.9139C14.7132 17.6532 15.7055 17.5 16.5 17.5C17.6604 17.5 18.8075 17.6453 19.8484 17.9765L20.5 18.1838V17.5ZM8.876 17.8944L9.5 18.0541V17.41V6.13V5.80361L9.2012 5.67227C8.21192 5.23742 6.90953 5 5.5 5C4.09047 5 2.78809 5.23742 1.7988 5.67227L1.5 5.80361V6.13V17.5V18.1483L2.12704 17.9836C3.27626 17.6817 4.49267 17.5 5.5 17.5C6.47302 17.5 7.67874 17.5879 8.876 17.8944ZM13.5 14.8773V5.20711L17.5 1.20711V11.2773L13.5 14.8773Z"
                fill="rgb(112, 122, 146)"
                stroke={color}
            />
        </svg>
    );
}
