import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { s3UrlPrefix } from '../../../../../constants';
import { getTracks, getCertificates } from '../../../../../redux/rootActions';
import { RootState } from '../../../../../redux/rootReducer';
import { AUTH_TOKENS } from '../../../../utils/storageWeb';
import { CertificateType } from '../../../../../redux/certificates/reducers';
import Loader from '../../../../../assets/svg/Loader';
import useWidth from '../../../../hooks/useWidth';

const Contact = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const loggedIn = localStorage.getItem(AUTH_TOKENS);
    const isAllTrackLoading = useSelector((state: RootState) => state.tracks.isAllTrackLoading);
    const trackData = useSelector((state: RootState) => state.tracks.trackData);
    const allCertificates = useSelector((state: RootState) => state.certificates.certificatesData);
    const viewPortWidth = useWidth();

    const [tagHeight, setTagHeight] = useState<any>();

    useEffect(() => {
        dispatch(getTracks(false, null, 100, 'PUBLISHED'));
        dispatch(getCertificates(null, 100, 'ACTIVE'));
    }, [dispatch]);

    useEffect(() => {
        if (!isAllTrackLoading) {
            setTagHeight(document?.getElementById('tagHeight')?.offsetWidth);
        }
    }, [isAllTrackLoading]);

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div>
                    <div className="bg-purple-85 px-10  xl:px-32 flex justify-between">
                        <div className="flex items-center py-10 md:pr-16">
                            <div>
                                <div className=" text-xl font-manrope font-bold text-purple-950">
                                    FUTURE CAREERS BRIDGE
                                </div>
                                <div className=" text-xl font-manrope font-bold">
                                    Expand your career opportunities
                                </div>
                                <div className="text-sm text-justify mt-3 publicBannerImage font-manrope font-semibold">
                                    Future Careers BRIDGE is a virtual ICT learning program created
                                    and managed by The Sri Lanka Association of Software and
                                    Services Companies (SLASSCOM)
                                </div>
                                <div className="mt-3">
                                    <div
                                        onClick={() => {
                                            history.push('/signin');
                                        }}
                                        className="border-1 rounded bg-purple-950 text-white w-36 h-12 flex justify-center items-center font-semibold cursor-pointer"
                                    >
                                        Get started now
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div>
                                <picture>
                                    <source
                                        media="(min-width: 1920px)"
                                        srcSet={`/assets/solveChallengeBanner.png`}
                                    />
                                    <source
                                        media="(min-width: 1440px)"
                                        srcSet={`/assets/solveChallengeBanner.png`}
                                    />
                                    <source
                                        media="(min-width: 1024px)"
                                        srcSet={`/assets/solveChallengeBanner.png`}
                                    />
                                    <img
                                        className="h-full w-full hidden lg:block"
                                        src={`/assets/solveChallengeBannerSmall.png`}
                                        alt="logo"
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                    <div className="px-10 xl:px-44 ">
                        <div className=" flex-row justify-between px-3 mt-10">
                            <div className="mt-2 flex justify-between font-bold text-xl">
                                <div>
                                    Explore our Career tracks that will help you to shape up your
                                    career dream.
                                </div>
                            </div>
                        </div>

                        {isAllTrackLoading ? (
                            <div className="py-10">
                                <Loader />
                            </div>
                        ) : (
                            <div className="trackCardContainer">
                                {trackData?.map(track => (
                                    <div key={track.id} className="rounded-lg trackCard mt-3">
                                        <img
                                            className="trackCardImage  rounded-t-lg"
                                            src={
                                                track.imageLink
                                                    ? `${s3UrlPrefix}${track.imageLink}`
                                                    : `/assets/challenge-image-skeleton-rectangle.png`
                                            }
                                            alt="profile"
                                        />
                                        <div className="mx-2 mt-6 pb-3">
                                            <div className="font-bold font-manrope text-xl mb-3 h-8 overflow-hidden">
                                                {track.trackName?.length < 19
                                                    ? track.trackName
                                                    : track.trackName?.substring(0, 19) + '...'}
                                            </div>

                                            <div
                                                style={tagHeight > 32 ? { overflow: 'hidden' } : {}}
                                                id="tagHeight"
                                                className="flex flex-wrap h-8"
                                            >
                                                {track.tags?.map(tag => (
                                                    <div
                                                        key={tag.id}
                                                        className="mr-2 mt-2 bg-purple-200 text-purple-950 text-xs rounded-full px-3 py-1 w-max"
                                                    >
                                                        {tag.tagName}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="mb-3 mt-4">
                                                <p
                                                    style={{ height: '104px' }}
                                                    className="text-gray-500 text-xs mb-1 pr-2 text-left"
                                                >
                                                    {track.trackDescription
                                                        ? track.trackDescription?.length > 250
                                                            ? track.trackDescription?.substring(
                                                                  0,
                                                                  250
                                                              ) + '.....'
                                                            : track.trackDescription
                                                        : ''}
                                                </p>
                                            </div>
                                            <button
                                                onClick={() => {
                                                    loggedIn
                                                        ? history.push({
                                                              pathname: `/solve-challenges/tracks/challenges`,
                                                              state: { tracks: track },
                                                          })
                                                        : history.push({
                                                              pathname: `/signin`,
                                                              state: { tracks: track },
                                                          });
                                                }}
                                                className="border-2 border-purple-950 text-purple-950 rounded font-semibold h-10 px-6 xl:px-12 ml-1"
                                            >
                                                Start learning
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {allCertificates.length > 0 ? (
                            <div>
                                <div className="text-xl font-bold text-purple-600 pt-10">
                                    Get certified
                                </div>
                                <div className="flex flex-row justify-between pb-3">
                                    <div className="text-sm pt-2">
                                        Earn achievements and excel in specified field of your
                                        liking to grow your career path
                                    </div>
                                    {allCertificates.length > 5 ? (
                                        <div className="flex justify-end font-bold text-xl">
                                            <p
                                                onClick={() => {
                                                    history.push(`/solve-challenges/certificates`);
                                                }}
                                                className="text-purple-600 hover:underline cursor-pointer font-normal text-base whitespace-nowrap ml-1 mt-1"
                                            >
                                                View All
                                            </p>
                                        </div>
                                    ) : null}
                                </div>
                                <div
                                    // style={{ maxHeight: '310px' }}
                                    className="inline-grid gap-6 grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 4xl:grid-cols-5 overflow-hidden"
                                >
                                    {allCertificates &&
                                        allCertificates
                                            ?.slice(0, 5)
                                            .map((certificate: CertificateType) => (
                                                <div
                                                    key={certificate.id}
                                                    style={{ width: '250px' }}
                                                    className={`rounded border border-gray-200 pb-2`}
                                                >
                                                    <img
                                                        className="w-full rounded-t"
                                                        src="/assets/certificate.png"
                                                    />
                                                    <div className="ml-3">
                                                        <div className="my-3 text-lg font-bold h-6 overflow-hidden">
                                                            {certificate.certificateName}
                                                        </div>
                                                        {!!loggedIn && (
                                                            <button
                                                                className={`mt-3 rounded border-2 border-purple-950 px-2 h-10 text-sm font-bold text-purple-950 focus:outline-none`}
                                                                onClick={() =>
                                                                    history.push('/signin')
                                                                }
                                                            >
                                                                View Certificate
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                </div>
                            </div>
                        ) : null}
                        <div
                            style={{
                                background:
                                    'linear-gradient(93.16deg, #6E41E2 1.57%, #0C247A 98.41%)',
                            }}
                            className="flex justify-between rounded mt-10  mb-20 text-white "
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                className="py-8 pl-4 lg:pl-8 4xl:pl-28"
                            >
                                <div className="space-y-5 ">
                                    <div className=" text-2xl-2 font-bold font-manrope">
                                        Now lets work on building your career competencies
                                    </div>
                                    <div className="text-base trackTipSection">
                                        The Technology and IT Enabled Service Industry offers a wide
                                        variety of career options. You may select a track
                                        highlighted below to complete challenges specialised for a
                                        career. Companies in the industry usually select potential
                                        candidates from the top students in these career paths. You
                                        should ideally complete ALL challenges in your chosen career
                                        path, so you will be recognised on the leaderboard, so
                                        strive for the top positions in order to be selected for
                                        internship interviews by companies. You can complete any
                                        number of career paths that interest you.
                                    </div>
                                    <div className="flex space-x-2">
                                        <div
                                            onClick={() => {
                                                history.push('/signin');
                                            }}
                                            className="border-2 rounded  text-white w-36 h-12 flex justify-center items-center font-bold cursor-pointer"
                                        >
                                            Learn more
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img
                                    className="hidden lg:block h-full w-full"
                                    src={`/assets/TracksBottomImageSmall.png`}
                                    srcSet="/assets/TracksBottomImage.png 1440w"
                                    sizes="(min-width: 1440px) 1440px"
                                    alt="logo"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="bg-purple-85 flex justify-between">
                        <div className="flex flex-col items-center pb-10 gap-y-7">
                            <img
                                className="h-full w-full"
                                src={`/assets/solveChallengeBannerSmall.png`}
                                alt="logo"
                            />
                            <div className="px-10">
                                <div className=" text-lg font-manrope font-bold text-purple-950">
                                    FUTURE CAREERS BRIDGE
                                </div>
                                <div className=" text-lg font-manrope font-bold">
                                    Expand your career opportunities
                                </div>
                                <div className="text-xs text-justify mt-3 publicBannerImage font-manrope font-semibold">
                                    Future Careers BRIDGE is a virtual ICT learning program created
                                    and managed by The Sri Lanka Association of Software and
                                    Services Companies (SLASSCOM)
                                </div>
                                <div className="mt-3">
                                    <div
                                        onClick={() => {
                                            history.push('/signin');
                                        }}
                                        className="border-1 rounded bg-purple-950 text-white w-36 h-12 flex justify-center items-center font-semibold cursor-pointer"
                                    >
                                        Get started now
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-10">
                        <div className=" flex-row justify-between mt-10">
                            <div className="mt-2 flex justify-between font-bold text-lg">
                                <div>
                                    Explore our Career tracks that will help you to shape up your
                                    career dream.
                                </div>
                            </div>
                        </div>

                        {isAllTrackLoading ? (
                            <div className="py-10">
                                <Loader />
                            </div>
                        ) : (
                            <div className="flex flex-col items-center justify-start gap-y-5">
                                {trackData?.map(track => (
                                    <div key={track.id} className="rounded-lg trackCard mt-3">
                                        <img
                                            className="trackCardImage  rounded-t-lg"
                                            src={
                                                track.imageLink
                                                    ? `${s3UrlPrefix}${track.imageLink}`
                                                    : `/assets/challenge-image-skeleton-rectangle.png`
                                            }
                                            alt="profile"
                                        />
                                        <div className="ml-4 mt-6 pb-3">
                                            <div className="font-bold font-manrope text-lg mb-3 max-h-16 overflow-ellipsis">
                                                {track.trackName?.length < 22
                                                    ? track.trackName
                                                    : track.trackName?.substring(0, 22) + '...'}
                                            </div>

                                            <div className="flex flex-wrap h-8 overflow-hidden">
                                                {track.tags?.map(tag => (
                                                    <div
                                                        key={tag.id}
                                                        className="mr-2 mt-2 bg-purple-200 text-purple-950 text-xs rounded-full px-3 py-1 w-max"
                                                    >
                                                        {tag.tagName}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="mb-4 mt-4">
                                                <p className="text-gray-500 text-xs mb-1 pr-2 text-left max-h-28 overflow-ellipsis">
                                                    {track.trackDescription
                                                        ? track.trackDescription?.length > 250
                                                            ? track.trackDescription?.substring(
                                                                  0,
                                                                  250
                                                              ) + '.....'
                                                            : track.trackDescription
                                                        : ''}
                                                </p>
                                            </div>
                                            <button
                                                onClick={() => {
                                                    loggedIn
                                                        ? history.push({
                                                              pathname: `/solve-challenges/tracks/challenges`,
                                                              state: { tracks: track },
                                                          })
                                                        : history.push({
                                                              pathname: `/signin`,
                                                              state: { tracks: track },
                                                          });
                                                }}
                                                className="border-2 border-purple-950 text-purple-950 rounded font-semibold h-10 px-6 xl:px-12 ml-1"
                                            >
                                                Start learning
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {allCertificates.length > 0 ? (
                            <div>
                                <div className="text-xl font-bold text-purple-600 pt-10">
                                    Get certified
                                </div>
                                <div className="flex flex-row justify-between pb-3">
                                    <div className="text-sm pt-2">
                                        Earn achievements and excel in specified field of your
                                        liking to grow your career path
                                    </div>
                                    {allCertificates.length > 2 ? (
                                        <div className="flex justify-end font-bold text-xl">
                                            <p
                                                onClick={() => {
                                                    history.push(`/solve-challenges/certificates`);
                                                }}
                                                className="text-purple-600 hover:underline cursor-pointer font-normal text-sm whitespace-nowrap ml-1 mt-1"
                                            >
                                                View All
                                            </p>
                                        </div>
                                    ) : null}
                                </div>
                                <div
                                    className="grid grid-cols-2 overflow-hidden"
                                    style={{ maxHeight: '200px' }}
                                >
                                    {allCertificates &&
                                        allCertificates
                                            ?.slice(0, 2)
                                            .map((certificate: CertificateType) => (
                                                <div
                                                    key={certificate.id}
                                                    style={{ width: 'calc(100% - 8px)' }}
                                                    className={`rounded border border-gray-200 pb-2`}
                                                >
                                                    <img
                                                        className="w-full rounded-t"
                                                        src="/assets/certificate.png"
                                                    />
                                                    <div className="ml-3">
                                                        <div className="my-3 text-lg font-bold h-6 overflow-hidden">
                                                            {certificate.certificateName}
                                                        </div>
                                                        {!!loggedIn && (
                                                            <button
                                                                className={`mt-3 rounded border-2 border-purple-950 px-2 h-10 text-sm font-bold text-purple-950 focus:outline-none`}
                                                                onClick={() =>
                                                                    history.push('/signin')
                                                                }
                                                            >
                                                                View Certificate
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div
                        style={{
                            background: '#4C2EC2',
                        }}
                        className="flex justify-between rounded-t mt-10  mb-16 text-white "
                    >
                        <div className="flex flex-col items-center justify-center">
                            <img
                                className="h-full w-full rounded object-contain"
                                src={`/assets/TracksBottomImageSmall.png`}
                                alt="logo"
                            />
                            <div className="py-8 px-5 space-y-5 ">
                                <div className=" text-lg font-bold font-manrope">
                                    Now lets work on building your career competencies
                                </div>
                                <div className="text-xs">
                                    The Technology and IT Enabled Service Industry offers a wide
                                    variety of career options. You may select a track highlighted
                                    above to complete challenges specialised for a career. Companies
                                    in the industry usually select potential candidates from the top
                                    students in these career paths. You should ideally complete ALL
                                    challenges in your chosen career path, so you will be recognised
                                    on the leaderboard, so strive for the top positions in order to
                                    be selected for internship interviews by companies. You can
                                    complete any number of career paths that interest you.
                                </div>
                                <div className="flex space-x-2">
                                    <div
                                        onClick={() => {
                                            history.push('/signin');
                                        }}
                                        className="border-2 rounded  text-white w-36 h-12 flex justify-center items-center font-bold cursor-pointer"
                                    >
                                        Learn more
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Contact;
