import React, { ReactElement } from 'react';

interface Props {
    color: string;
}

export default function SupportMobile({ color }: Props): ReactElement {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.5 16.5V17.5H11.5V16.5H12.5ZM8.36451 3.22314C9.5171 2.74572 10.7524 2.5 12 2.5C13.2476 2.5 14.4829 2.74572 15.6355 3.22314C16.7881 3.70056 17.8354 4.40033 18.7175 5.28249C19.5997 6.16464 20.2994 7.21192 20.7769 8.36451C21.2543 9.5171 21.5 10.7524 21.5 12C21.5 14.5196 20.4991 16.9359 18.7175 18.7175C16.9359 20.4991 14.5196 21.5 12 21.5C10.7524 21.5 9.5171 21.2543 8.36451 20.7769C7.21191 20.2994 6.16464 19.5997 5.28249 18.7175C3.50089 16.9359 2.5 14.5196 2.5 12C2.5 9.48044 3.50089 7.06408 5.28249 5.28249C6.16464 4.40033 7.21191 3.70056 8.36451 3.22314ZM3.5 12C3.5 16.6861 7.31386 20.5 12 20.5C16.6861 20.5 20.5 16.6861 20.5 12C20.5 7.31386 16.6861 3.5 12 3.5C7.31386 3.5 3.5 7.31386 3.5 12ZM9.52513 7.52513C10.1815 6.86875 11.0717 6.5 12 6.5C12.9283 6.5 13.8185 6.86875 14.4749 7.52513C15.1313 8.1815 15.5 9.07174 15.5 10C15.5 11.003 14.9268 11.5559 14.1707 12.2175C14.1398 12.2446 14.1083 12.272 14.0765 12.2997C13.4679 12.8297 12.7294 13.4731 12.5439 14.5H11.5289C11.5861 14.0299 11.7234 13.6739 11.8965 13.3877C12.131 13.0002 12.4498 12.7087 12.8104 12.4233C12.8856 12.3637 12.966 12.3022 13.0489 12.2387C13.3361 12.0188 13.6543 11.7753 13.9008 11.5048C14.2477 11.1242 14.5 10.6493 14.5 10C14.5 9.33696 14.2366 8.70107 13.7678 8.23223C13.2989 7.76339 12.663 7.5 12 7.5C11.337 7.5 10.7011 7.76339 10.2322 8.23223C9.88304 8.58143 9.64782 9.02328 9.5505 9.5H8.53588C8.64317 8.75661 8.98776 8.06249 9.52513 7.52513Z"
                fill={color}
                stroke={color}
            />
        </svg>
    );
}
