import { Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../../assets/svg/Loader';
import {
    clearAuth,
    clearUsersMessages,
    editUser,
    logout,
    sendEmailVerification,
    SEND_EMAIL_VERIFICATION_RESET,
} from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import UsersSuccessModal from '../../modals/UsersSuccessModal';
import * as Yup from 'yup';
import Modal from 'react-modal';
import EmailVerificationSuccessModal from '../../modals/EmailVerificationSuccessModal';

const Security = () => {
    const dispatch = useDispatch();
    const formRef = useRef<any>();
    const history = useHistory();
    const isEditLoading = useSelector((state: RootState) => state.users.isEditLoading);
    const sendEmailVerificationError = useSelector(
        (state: RootState) => state.webUser.sendEmailVerificationError
    );
    const user = useSelector((state: RootState) => state.users.User);
    const updateError = useSelector((state: RootState) => state.users.editUserError);
    const updateSuccessMessage = useSelector(
        (state: RootState) => state.users.UserUpdateSuccessMessage
    );
    const sendEmailVerificationSuccess = useSelector(
        (state: RootState) => state.webUser.sendEmailVerificationSuccessMessage
    );
    const isLoading = useSelector((state: RootState) => state.webUser.isLoading);

    const [newEmailAddress, setNewEmailAddress] = useState<string>(null);
    const [editSuccessVisible, setEditSuccessVisible] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = React.useState(false);
    const changeEmailFeedbackUrl = '/admin/settings/changeEmail';

    const handleClearMessages = useCallback(() => {
        if (updateError || updateSuccessMessage) {
            setEditSuccessVisible(true);
        }
    }, [dispatch, updateSuccessMessage, updateError]);
    const handleClose = () => {
        if (!updateError) {
            dispatch(logout());
            dispatch(clearAuth());
            history.push('/admin/signIn');
        }
        dispatch(clearUsersMessages());
        setEditSuccessVisible(false);
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    useEffect(() => {
        if (sendEmailVerificationSuccess) {
            if (newEmailAddress) {
                setShowSuccessModal(true);
                dispatch({ type: SEND_EMAIL_VERIFICATION_RESET });
            }
        }
    }, [dispatch, newEmailAddress, sendEmailVerificationSuccess]);

    return (
        <div className="mx-6 lg:ml-2 h-full">
            <div className="bg-snow-600">
                <div className="relative">
                    <Formik
                        enableReinitialize={true}
                        innerRef={formRef}
                        initialValues={{
                            editEmailClicked: false,
                            emailId: user?.contact?.emailId,
                        }}
                        validationSchema={Yup.object({
                            emailId: Yup.string().when('editEmailClicked', {
                                is: true,
                                then: Yup.string()
                                    .email('Invalid email')
                                    .required('Email is required'),
                            }),
                        })}
                        onSubmit={async values => {
                            dispatch(
                                sendEmailVerification(user?.userId, user?.userName, values?.emailId)
                            );
                        }}
                    >
                        {({ handleSubmit, errors, values, touched, setFieldValue }) => (
                            <div className="grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2">
                                <div className="col-span-12 md:col-span-6 mb-2 md:mb-4">
                                    <div className="mb-6 w-full md:mb-0 flex flex-col my-4">
                                        <p className="font-medium leading-149 text-sm md:text-lg text-gray-700">
                                            Email Address
                                        </p>
                                        <div className="font-medium text-gray-700 mb-3 flex">
                                            <p>
                                                Your current email address is{' '}
                                                <a className="text-blue-700 no-underline">
                                                    {user?.emailId}
                                                </a>
                                            </p>
                                        </div>

                                        {!values.editEmailClicked && (
                                            <button
                                                className="bg-snow-900 border-2 border-blue-800 border-opacity-40 h-11  w-full md:w-1/3 text-blue-700 font-medium rounded focus:outline-none"
                                                onClick={() => {
                                                    setFieldValue('editEmailClicked', true);
                                                }}
                                            >
                                                Change email address
                                            </button>
                                        )}
                                        {values.editEmailClicked && (
                                            <div className="flex flex-col w-full">
                                                <label
                                                    htmlFor="emailId"
                                                    className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    New email
                                                </label>
                                                <input
                                                    className={`rounded flex items-center focus:outline-none w-full md:w-1/3 p-2 border-2 text-base focus:border-blue-800 ${
                                                        errors.emailId && touched.emailId
                                                            ? 'border-red-500'
                                                            : 'border-gray-300'
                                                    } md:text-base`}
                                                    id="emailId"
                                                    type="text"
                                                    value={values.emailId}
                                                    onChange={e => {
                                                        setFieldValue('emailId', e.target.value);
                                                        setNewEmailAddress(e.target.value);
                                                    }}
                                                    autoComplete="off"
                                                />
                                                {errors.emailId && touched.emailId ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {errors.emailId}
                                                    </div>
                                                ) : sendEmailVerificationError ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {sendEmailVerificationError}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>

                                    {values.editEmailClicked && (
                                        <div className="flex w-full justify-end items-center mt-4 md:mt-8">
                                            <div className="flex w-1/2 lg:w-1/3 justify-end">
                                                <button
                                                    className="bg-snow-900 border-2 border-blue-800 md:mr-4 border-opacity-40 h-11  w-full md:w-1/3 text-blue-900 text-sm rounded focus:outline-none"
                                                    onClick={() => {
                                                        setFieldValue('editEmailClicked', false);
                                                        setFieldValue('editPasswordClicked', false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="bg-blue-700 text-white text h-11 rounded focus:outline-none  w-full md:w-1/3"
                                                    onClick={() => {
                                                        handleSubmit();
                                                    }}
                                                >
                                                    {isLoading ? <Loader /> : 'Save'}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {showSuccessModal && (
                                    <EmailVerificationSuccessModal
                                        modalVisible={showSuccessModal}
                                        setModalVisible={setShowSuccessModal}
                                        changeEmailFeedbackUrl={changeEmailFeedbackUrl}
                                        newEmailAddress={newEmailAddress}
                                    />
                                )}
                            </div>
                        )}
                    </Formik>
                </div>
                <div className="relative">
                    <Formik
                        enableReinitialize={true}
                        innerRef={formRef}
                        initialValues={{
                            editPasswordClicked: false,
                            oldPassword: '',
                            newPassword: '',
                            confirmPassword: '',
                        }}
                        validationSchema={Yup.object({
                            oldPassword: Yup.string().required('Old Password is required'),
                            newPassword: Yup.string()
                                .required('Password is required')
                                .min(6, 'Password must be at least 6 characters'),
                            confirmPassword: Yup.string()
                                .required('Confirm Password is required')
                                .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
                        })}
                        onSubmit={async values => {
                            dispatch(
                                editUser(user?.id, {
                                    oldPassword: values.oldPassword,
                                    password: values.confirmPassword,
                                })
                            );
                        }}
                    >
                        {({ handleSubmit, errors, values, touched, setFieldValue }) => (
                            <div className="grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2 pb-8 mb-6">
                                <div className="col-span-12 md:col-span-6 mb-2 md:mb-4">
                                    <div className="mb-6 w-full md:mb-0 flex flex-col my-4">
                                        <p className="font-medium leading-149 text-sm md:text-lg text-gray-700">
                                            Security
                                        </p>

                                        {!values.editPasswordClicked && (
                                            <button
                                                className="bg-snow-900 border-2 border-blue-800 border-opacity-40 h-11  w-full md:w-1/3 text-blue-700 font-medium rounded focus:outline-none"
                                                onClick={() => {
                                                    setFieldValue('editPasswordClicked', true);
                                                }}
                                            >
                                                Change password
                                            </button>
                                        )}
                                        {values.editPasswordClicked && (
                                            <>
                                                <div className="flex flex-col w-full mb-2">
                                                    <label
                                                        htmlFor="oldPassword"
                                                        className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Old Password
                                                    </label>
                                                    <div className="relative w-full md:w-1/3">
                                                        <input
                                                            className={`rounded flex items-center focus:outline-none w-full p-2 border-2 text-base focus:border-blue-800 ${
                                                                errors.oldPassword &&
                                                                touched.oldPassword
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="oldPassword"
                                                            type={
                                                                showOldPassword
                                                                    ? 'text'
                                                                    : 'password'
                                                            }
                                                            value={values.oldPassword}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'oldPassword',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                        {errors.oldPassword &&
                                                        touched.oldPassword ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.oldPassword}
                                                            </div>
                                                        ) : null}
                                                        <div className="absolute inset-y-2 right-0 pt-1 pr-3 flex text-base item-center">
                                                            {values.oldPassword.length > 0 ? (
                                                                <>
                                                                    <img
                                                                        src="/assets/passwordShowIcon.png"
                                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                            showOldPassword
                                                                                ? 'block'
                                                                                : 'hidden'
                                                                        }`}
                                                                        onClick={() =>
                                                                            setShowOldPassword(
                                                                                !showOldPassword
                                                                            )
                                                                        }
                                                                        alt="show-password"
                                                                        role="presentation"
                                                                        draggable="false"
                                                                    />
                                                                    <img
                                                                        src="/assets/passwordHideIcon.png"
                                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                            showOldPassword
                                                                                ? 'hidden'
                                                                                : 'block'
                                                                        }`}
                                                                        alt="hide-password"
                                                                        role="presentation"
                                                                        onClick={() =>
                                                                            setShowOldPassword(
                                                                                !showOldPassword
                                                                            )
                                                                        }
                                                                        draggable="false"
                                                                    />
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col w-full mb-2">
                                                    <label
                                                        htmlFor="newPassword"
                                                        className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        New Password
                                                    </label>
                                                    <div className="relative w-full md:w-1/3">
                                                        <input
                                                            className={`rounded flex items-center focus:outline-none w-full p-2 border-2 text-base focus:border-blue-800 ${
                                                                errors.newPassword &&
                                                                touched.newPassword
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="newPassword"
                                                            type={
                                                                showPassword ? 'text' : 'password'
                                                            }
                                                            value={values.newPassword}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'newPassword',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                        {errors.newPassword &&
                                                        touched.newPassword ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.newPassword}
                                                            </div>
                                                        ) : null}
                                                        <div className="absolute inset-y-2 right-0 pt-1 pr-3 flex text-base item-center">
                                                            {values.newPassword.length > 0 ? (
                                                                <>
                                                                    <img
                                                                        src="/assets/passwordShowIcon.png"
                                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                            showPassword
                                                                                ? 'block'
                                                                                : 'hidden'
                                                                        }`}
                                                                        onClick={() =>
                                                                            setShowPassword(
                                                                                !showPassword
                                                                            )
                                                                        }
                                                                        alt="show-password"
                                                                        role="presentation"
                                                                        draggable="false"
                                                                    />
                                                                    <img
                                                                        src="/assets/passwordHideIcon.png"
                                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                            showPassword
                                                                                ? 'hidden'
                                                                                : 'block'
                                                                        }`}
                                                                        alt="hide-password"
                                                                        role="presentation"
                                                                        onClick={() =>
                                                                            setShowPassword(
                                                                                !showPassword
                                                                            )
                                                                        }
                                                                        draggable="false"
                                                                    />
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col w-full mb-2">
                                                    <label
                                                        htmlFor="confirmPassword"
                                                        className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Confirm Password
                                                    </label>
                                                    <div className="relative w-full md:w-1/3">
                                                        <input
                                                            className={`rounded flex items-center focus:outline-none w-full p-2 border-2 text-base focus:border-blue-800 ${
                                                                errors.confirmPassword &&
                                                                touched.confirmPassword
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="confirmPassword"
                                                            type={
                                                                showConfirmPassword
                                                                    ? 'text'
                                                                    : 'password'
                                                            }
                                                            value={values.confirmPassword}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'confirmPassword',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                        {errors.confirmPassword &&
                                                        touched.confirmPassword ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.confirmPassword}
                                                            </div>
                                                        ) : null}
                                                        <div className="absolute inset-y-2 right-0 pt-1 pr-3 flex text-base item-center">
                                                            {values.confirmPassword.length > 0 ? (
                                                                <>
                                                                    <img
                                                                        src="/assets/passwordShowIcon.png"
                                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                            showConfirmPassword
                                                                                ? 'block'
                                                                                : 'hidden'
                                                                        }`}
                                                                        onClick={() =>
                                                                            setShowConfirmPassword(
                                                                                !showConfirmPassword
                                                                            )
                                                                        }
                                                                        alt="show-password"
                                                                        role="presentation"
                                                                        draggable="false"
                                                                    />
                                                                    <img
                                                                        src="/assets/passwordHideIcon.png"
                                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                            showConfirmPassword
                                                                                ? 'hidden'
                                                                                : 'block'
                                                                        }`}
                                                                        alt="hide-password"
                                                                        role="presentation"
                                                                        onClick={() =>
                                                                            setShowConfirmPassword(
                                                                                !showConfirmPassword
                                                                            )
                                                                        }
                                                                        draggable="false"
                                                                    />
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    {values.editPasswordClicked && (
                                        <div className="flex w-full justify-end items-center mt-4 md:mt-8">
                                            <div className="flex w-1/2 lg:w-1/3 justify-end">
                                                <button
                                                    className="bg-snow-900 border-2 border-blue-800 md:mr-4 border-opacity-40 h-11  w-full md:w-1/3 text-blue-900 text-sm rounded focus:outline-none"
                                                    onClick={() => {
                                                        setFieldValue('editEmailClicked', false);
                                                        setFieldValue('editPasswordClicked', false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="bg-blue-700 text-white text h-11 rounded focus:outline-none  w-full md:w-1/3"
                                                    onClick={() => {
                                                        handleSubmit();
                                                    }}
                                                >
                                                    {isEditLoading ? <Loader /> : 'Save'}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {editSuccessVisible && (
                                    <UsersSuccessModal
                                        modalVisible={editSuccessVisible}
                                        handleClose={handleClose}
                                    />
                                )}
                            </div>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default Security;
