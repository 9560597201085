import React, { useEffect, useState } from 'react';

import ProfileTab from './ProfileTab';
import NotificationTab from './NotificationTab';
import SecurityTab from './SecurityTab';
import ActivityLogTab from './ActivityLog';
import { useLocation } from 'react-router-dom';
import useWidth from '../../../hooks/useWidth';
import styled from 'styled-components';
interface tabStateType {
    selectedTab: string;
}

const MobileTabHeader = styled.div`
    font-size: 10px;
`;
const Settings = () => {
    const location = useLocation();
    const tabState = location.state as tabStateType;
    const [selectedTab, setSelectedTab] = useState('profile');
    const viewPortWidth = useWidth();
    useEffect(() => {
        if (tabState) {
            setSelectedTab(tabState.selectedTab);
        }
        if (window.location.href.includes('security')) {
            setSelectedTab('security');
        }
    }, [tabState]);
    return (
        <>
            {viewPortWidth > 1023 ? (
                <div className="mx-10 md:mx-5 md:ml-14 lg:ml-72 lg:pl-3.5 lg:pt-2 mt-6">
                    <div className="font-manrope font-bold text-2xl-2">Settings</div>
                    <div className="flex flex-row mt-7">
                        <div
                            className={`${
                                selectedTab === 'profile'
                                    ? 'border-b-2 text-purple-950 border-purple-950 font-bold'
                                    : 'font-semibold text-gray-400'
                            }  mr-4 cursor-pointer`}
                            onClick={() => {
                                setSelectedTab('profile');
                            }}
                        >
                            My Profile
                        </div>
                        <div
                            className={`${
                                selectedTab === 'notification'
                                    ? 'border-b-2 text-purple-950 border-purple-950 font-bold'
                                    : 'font-semibold text-gray-400'
                            }  mr-4 cursor-pointer`}
                            onClick={() => {
                                setSelectedTab('notification');
                            }}
                        >
                            Notification
                        </div>
                        <div
                            className={`${
                                selectedTab === 'security'
                                    ? 'border-b-2 text-purple-950 border-purple-950 font-bold'
                                    : 'font-semibold text-gray-400'
                            }  mr-4 cursor-pointer`}
                            onClick={() => {
                                setSelectedTab('security');
                            }}
                        >
                            Account Access &amp; Security
                        </div>
                        <div
                            className={`${
                                selectedTab === 'activityLog'
                                    ? 'border-b-2 text-purple-950 border-purple-950 font-bold'
                                    : 'font-semibold text-gray-400'
                            }  mr-4 cursor-pointer`}
                            onClick={() => {
                                setSelectedTab('activityLog');
                            }}
                        >
                            Activity Log
                        </div>
                    </div>
                    {selectedTab === 'profile' ? <ProfileTab /> : null}

                    {selectedTab === 'notification' ? <NotificationTab /> : null}

                    {selectedTab === 'security' ? <SecurityTab /> : null}

                    {selectedTab === 'activityLog' ? <ActivityLogTab /> : null}
                </div>
            ) : (
                <div className="mt-5">
                    <div className="px-2 flex flex-row mt-7 gap-x-2">
                        <MobileTabHeader
                            className={`${
                                selectedTab === 'profile'
                                    ? 'border-b-2 text-purple-950 border-purple-950 font-bold'
                                    : 'font-semibold text-gray-400'
                            }   cursor-pointer`}
                            onClick={() => {
                                setSelectedTab('profile');
                            }}
                        >
                            My Profile
                        </MobileTabHeader>
                        <MobileTabHeader
                            className={`${
                                selectedTab === 'notification'
                                    ? 'border-b-2 text-purple-950 border-purple-950 font-bold'
                                    : 'font-semibold text-gray-400'
                            }   cursor-pointer`}
                            onClick={() => {
                                setSelectedTab('notification');
                            }}
                        >
                            Notification
                        </MobileTabHeader>
                        <MobileTabHeader
                            className={`${
                                selectedTab === 'security'
                                    ? 'border-b-2 text-purple-950 border-purple-950 font-bold'
                                    : 'font-semibold text-gray-400'
                            }   cursor-pointer`}
                            onClick={() => {
                                setSelectedTab('security');
                            }}
                        >
                            Account Access &amp; Security
                        </MobileTabHeader>
                        <MobileTabHeader
                            className={`${
                                selectedTab === 'activityLog'
                                    ? 'border-b-2 text-purple-950 border-purple-950 font-bold'
                                    : 'font-semibold text-gray-400'
                            }   cursor-pointer`}
                            onClick={() => {
                                setSelectedTab('activityLog');
                            }}
                        >
                            Activity Log
                        </MobileTabHeader>
                    </div>

                    <div className="px-4"> {selectedTab === 'profile' ? <ProfileTab /> : null}</div>

                    {selectedTab === 'notification' ? <NotificationTab /> : null}

                    <div className="px-4">
                        {selectedTab === 'security' ? <SecurityTab /> : null}
                    </div>

                    <div className="px-4">
                        {selectedTab === 'activityLog' ? <ActivityLogTab /> : null}
                    </div>
                </div>
            )}
        </>
    );
};

export default Settings;
