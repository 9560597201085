import React, { ReactElement, useState } from 'react';
import { InstitutesType } from '../../../../redux/institutes/reducers';
import * as HiIcons from 'react-icons/hi';
import { RootState } from '../../../../redux/rootReducer';
import { useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { INSTITUTE_ADMIN } from '../../../../redux/authAdmin/reducers';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Home town',
                padding: { top: 10 },
                font: {
                    size: 14,
                },
            },
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                autoSkip: false,
            },
        },
        y: {
            title: {
                display: true,
                text: 'Number of Students',
                font: {
                    size: 14,
                },
            },
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                callback: function (value) {
                    if (Math.floor(value) === value) {
                        return value;
                    }
                },
            },
        },
    },
};
interface Props {
    institute: InstitutesType;
    setInstitute: (value: InstitutesType) => void;
    onlyInstitute: boolean;
    setOnlyInstitute: (value: boolean) => void;
}

export default function StudentProgression({
    institute,
    setInstitute,
    onlyInstitute,
    setOnlyInstitute,
}: Props): ReactElement {
    const [showInstitutesDropdown, setShowInstitutesDropdown] = useState(false);
    const institutes = useSelector((state: RootState) => state.institutes.publicInstitutData);
    const progessionData: any = useSelector((state: RootState) => state.dashboard.progessionData);
    const user = useSelector((state: RootState) => state.users.User);
    const labels = progessionData?.labels;
    const data = {
        labels,
        datasets: [
            {
                data: progessionData?.values,
                backgroundColor: ['rgba(196, 181, 253,1)'],
                maxBarThickness: 200,
            },
        ],
    };
    return (
        <>
            <div className="flex w-1/2 lg:w-1/3 mb-2">
                <div className="mb-4 w-full md:mb-0 flex">
                    <div className="flex flex-col w-full">
                        {user?.roles[0]?.roleName !== INSTITUTE_ADMIN ? (
                            <div className="w-full flex items-center">
                                <label className="mx-2">Institute : </label>
                                <div
                                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none md:w-2/3 lg:w-3/5 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className="flex justify-between w-full md:p-2"
                                        onClick={() =>
                                            setShowInstitutesDropdown(!showInstitutesDropdown)
                                        }
                                    >
                                        <div className="text-gray-700 font-medium pl-2">
                                            {institute
                                                ? institute?.instName
                                                : onlyInstitute
                                                ? 'All (Across all institutes)'
                                                : 'All students in the FCB'}
                                        </div>
                                        <div className="flex flex-col">
                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                            <HiIcons.HiChevronDown className="mr-2" />
                                        </div>
                                    </div>
                                    {showInstitutesDropdown ? (
                                        <ul
                                            // style={{ whiteSpace: 'pre' }}
                                            className="absolute max-h-48 overflow-y-auto w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none "
                                            role="menu"
                                        >
                                            <div
                                                className={`p-2 hover:bg-gray-200 ${
                                                    !institute && !onlyInstitute && 'bg-gray-300'
                                                }`}
                                                key="0"
                                                // value=""
                                                onClick={() => {
                                                    setInstitute(null);
                                                    setOnlyInstitute(false);
                                                    setShowInstitutesDropdown(false);
                                                }}
                                            >
                                                All students in the FCB
                                            </div>
                                            <div
                                                className={`p-2 hover:bg-gray-200 ${
                                                    !institute && onlyInstitute && 'bg-gray-300'
                                                }`}
                                                key="0"
                                                // value=""
                                                onClick={() => {
                                                    setInstitute(null);
                                                    setOnlyInstitute(true);
                                                    setShowInstitutesDropdown(false);
                                                }}
                                            >
                                                All (Across all institutes)
                                            </div>
                                            {institutes?.map(inst => (
                                                <div
                                                    className={`p-2 hover:bg-gray-200 ${
                                                        institute?.instName === inst?.instName &&
                                                        'bg-gray-300'
                                                    }`}
                                                    key={inst?.id}
                                                    // value={inst?.id}
                                                    onClick={() => {
                                                        setInstitute(inst);
                                                        setOnlyInstitute(false);
                                                        setShowInstitutesDropdown(false);
                                                    }}
                                                >
                                                    {inst?.instName}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="w-full overflow-hidden pt-4">
                <div className="w-full overflow-x-auto pl-1 pb-6" style={{ height: '400px' }}>
                    <Bar options={options} data={data} />
                </div>
            </div>
            <div className="flex flex-col items-center justify-center pb-6">
                <div className="text-gray-700 text-sm md:text-base md:font-semibold mb-2">
                    Total Students Progression - {progessionData?.items?.Total}
                </div>
                <div className="text-blue-700 text-sm md:text-base md:font-semibold">
                    Male Students - {progessionData?.items?.Male} &nbsp; &nbsp; Female Students -
                    {progessionData?.items?.Female}
                </div>
            </div>
        </>
    );
}
