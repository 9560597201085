import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as AiIcons from 'react-icons/ai';
import SubMenu from './SubMenu';
import { SidebarData } from './SideBarData';
import { getUserProfile } from '../../utils/storage';
import { COMPANY_ADMIN, INSTITUTE_ADMIN, UserProfileType } from '../../redux/authAdmin/reducers';
interface sideBarProps {
    sidebar: boolean;
}

const NavIcon = styled(Link)`
    background: #ffffff;
    margin-left: 2rem;
    font-size: 2rem;
    height: 5vh;
    justify-content: center;
    align-items: center;
`;

const SidebarNav = styled('nav')<sideBarProps>`
    background: #ffffff;
    min-width: max-content;
    margin-top: 66px;
    justify-content: center;
    overflow-y: scroll;
    scrollbar-width: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 10;
`;

const SidebarWrap = styled.div`
    width: 100%;
`;
interface Props {
    sidebar: boolean;
    setSidebar: (value: boolean) => void;
}

export default function Sidebar({ sidebar, setSidebar }: Props): ReactElement {
    const showSidebar = () => setSidebar(!sidebar);
    const user: UserProfileType | any = getUserProfile();
    const isCompanyAdmin = user?.roles?.[0] === COMPANY_ADMIN;
    const isInstituteAdmin = user?.roles?.[0] === INSTITUTE_ADMIN;

    return (
        <>
            <SidebarNav
                sidebar={sidebar}
                className={`${
                    sidebar === false ? 'hidden' : 'w-1/3 md:w-1/5 xl:w-1/6 lg:flex'
                } border-r-2 DashboardSidebar`}
            >
                <SidebarWrap>
                    <NavIcon to="#" className="flex lg:hidden">
                        <AiIcons.AiOutlineClose onClick={showSidebar} />
                    </NavIcon>
                    {SidebarData.filter(data => {
                        if (isCompanyAdmin || isInstituteAdmin) {
                            return (
                                data.title === 'Dashboard' ||
                                data.title === 'Students' ||
                                data.title === 'Leaderboard' ||
                                data.title === 'My Settings'
                            );
                        } else {
                            return data;
                        }
                    }).map((item, index) => {
                        return <SubMenu item={item} key={index} />;
                    })}
                </SidebarWrap>
            </SidebarNav>
        </>
    );
}
