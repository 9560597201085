import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { USER_PROFILE } from '../../utils/storage';
import { challenges, trackCreatedDate, students, trackLastUpdatedDate } from '../tracks/actions';

const PREFIX = '@TAB';

export const ADD_TAB = PREFIX + 'ADD_TAB';
export const ADD_TAB_SUCCESS = PREFIX + 'ADD_TAB_SUCCESS';
export const ADD_TAB_FAILED = PREFIX + 'ADD_TAB_FAILED';
export const GET_TRACK_TAB = PREFIX + 'GET_TRACK_TAB';
export const GET_TRACK_TAB_SUCCESS = PREFIX + 'GET_TRACK_TAB_SUCCESS';
export const GET_TRACK_TAB_FAILED = PREFIX + 'GET_TRACK_TAB_FAILED';

export interface TrackTabCreateType {
    id?: string | null;
    tabName?: string | null;
    tags?: any[];
    students?: students;
    challenges: challenges;
    trackLastUpdatedDate?: trackLastUpdatedDate;
    trackLastUpdatedBy?: string | null;
    trackCreatedBy?: string | null;
    trackCreatedDate?: trackCreatedDate;
    trackName?: string | null;
    program?: number;
}

export const getTrackTabs = (id?: string, association?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_TRACK_TAB });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/users/admins/filter?id=${id}&association=${association}`,
            config
        );
        dispatch({
            type: GET_TRACK_TAB_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_TRACK_TAB_FAILED, payload: getErrorMessage(error) });
    }
};

export const addTrackTab = (tab: TrackTabCreateType) => async (dispatch: any) => {
    try {
        if (
            tab?.challenges?.to === 0 ||
            tab?.challenges === null ||
            tab?.challenges === undefined
        ) {
            delete tab?.challenges;
        }
        if (tab?.program === 0 || tab?.program === null || tab?.program === undefined) {
            delete tab?.program;
        }
        if (
            tab?.students === null ||
            tab?.students === undefined ||
            Object.keys(tab?.students).length === 0 ||
            !tab?.students?.from ||
            !tab?.students?.to
        ) {
            delete tab?.students;
        }
        if (tab?.tags === null || tab?.tags === undefined || tab?.tags?.length === 0) {
            delete tab?.students;
        }
        dispatch({ type: ADD_TAB });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/tracks/filter`, tab, config);
        const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
        await dispatch(getTrackTabs(profile?.userId, 'TRACK'));
        dispatch({ type: ADD_TAB_SUCCESS, payload: res.data.responseDto.data });
    } catch (error) {
        dispatch({ type: ADD_TAB_FAILED, payload: getErrorMessage(error) });
    }
};
