import React from 'react';
import CloseIcon from '../../../assets/svg/CloseIcon';
import { AiFillCheckCircle, AiFillMinusCircle } from 'react-icons/ai';
import { IoAddCircleSharp } from 'react-icons/io5';
import * as FaIcons from 'react-icons/fa';
import * as MdIcons from 'react-icons/md';
import { FiMaximize2, FiMinus } from 'react-icons/fi';

interface Props {
    assessment: any;
    setAssessment: (value: any) => void;
    minimize: boolean[];
    setMinimize: (value: boolean[]) => void;
}
export default function ChallengeAssessment({
    assessment,
    minimize,
    setAssessment,
    setMinimize,
}: Props) {
    const closeSection = index => {
        setAssessment(assessment.filter((_, i) => i !== index));
    };
    const handleChange = (e, index, key) => {
        const assessmentCopy = [...assessment];
        assessmentCopy[index][key] = e.target.value;
        setAssessment(assessmentCopy);
    };
    const handleMinimize = index => {
        const minimizeCopy = [...minimize];
        minimizeCopy[index] = true;
        setMinimize(minimizeCopy);
    };
    const handleMaximize = index => {
        const minimizeCopy = [...minimize];
        minimizeCopy[index] = false;
        setMinimize(minimizeCopy);
    };
    const actionButtons = index => {
        return (
            <>
                {minimize[index] === false ? (
                    <FiMinus
                        className="absolute rounded top-1 right-8 text-2xl cursor-pointer hover:bg-gray-200 p-1"
                        onClick={() => handleMinimize(index)}
                    />
                ) : (
                    <FiMaximize2
                        className="absolute rounded top-1 right-8 text-2xl cursor-pointer hover:bg-gray-200 p-1"
                        onClick={() => handleMaximize(index)}
                    />
                )}
                <div
                    className="absolute rounded top-1 right-2 cursor-pointer hover:bg-red-100 p-1"
                    onClick={() => closeSection(index)}
                >
                    <CloseIcon size={16} color="#ef4444" />
                </div>
            </>
        );
    };
    return (
        <div className="w-full">
            {assessment.map((item, index) => {
                if (item.type === 'header') {
                    return (
                        <div className="relative assessmentSection rounded-md my-2 mx-2 cursor-pointer py-2 px-1 border-t-2 border-gray-400">
                            <div style={{ minHeight: '24px' }} className="flex flex-row">
                                <FaIcons.FaHeading className="mr-4 mt-1" />
                                {item.label}
                            </div>
                            {actionButtons(index)}
                            {minimize[index] === false && (
                                <div className="rounded bg-gray-200 flex flex-row py-2">
                                    <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Title</b>
                                    <input
                                        className="w-3/4 2xl:w-5/6 h-8 rounded focus:outline-none mr-4 pl-2 border border-gray-400 border border-gray-400"
                                        type="text"
                                        value={item.label}
                                        onChange={e => handleChange(e, index, 'label')}
                                    />
                                </div>
                            )}
                        </div>
                    );
                } else if (item.type === 'paragraph') {
                    return (
                        <div className="relative assessmentSection rounded-md my-2 mx-2 cursor-pointer py-2 px-1">
                            <div style={{ minHeight: '24px' }} className="flex flex-row">
                                <FaIcons.FaParagraph className="mr-4 mt-1" />
                                {item.label}
                            </div>
                            {actionButtons(index)}
                            {minimize[index] === false && (
                                <div className="rounded bg-gray-200 flex flex-row py-2">
                                    <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Content</b>
                                    <textarea
                                        className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                        value={item.label}
                                        onChange={e => handleChange(e, index, 'label')}
                                    />
                                </div>
                            )}
                        </div>
                    );
                } else if (item.type === 'text') {
                    return (
                        <div className="relative assessmentSection rounded-md my-2 mx-2 cursor-pointer py-2 px-1">
                            <div style={{ minHeight: '24px' }} className="flex flex-row">
                                <MdIcons.MdShortText className="mr-4 mt-1" />
                                {item.label}
                            </div>
                            {actionButtons(index)}
                            {minimize[index] === false ? (
                                <>
                                    <div className="rounded-t bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Question</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="text"
                                            value={item.label}
                                            onChange={e => handleChange(e, index, 'label')}
                                        />
                                    </div>
                                    <div className="bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Help Text</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="text"
                                            value={item.description}
                                            onChange={e => handleChange(e, index, 'description')}
                                        />
                                    </div>
                                    <div className="bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Placeholder</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="text"
                                            value={item.placeholder}
                                            onChange={e => handleChange(e, index, 'placeholder')}
                                        />
                                    </div>
                                    <div className="bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Value</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="text"
                                            value={item.value}
                                            onChange={e => handleChange(e, index, 'value')}
                                        />
                                    </div>
                                    <div className="rounded-b bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Points</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="number"
                                            value={item.points}
                                            onWheel={e => e.currentTarget.blur()}
                                            onChange={e => handleChange(e, index, 'points')}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex flex-row h-6 px-2 border border-gray-400 rounded">
                                        {item.value}
                                    </div>
                                    <div className="rounded-b flex flex-row h-6 px-2">
                                        Points: {item.points}
                                    </div>
                                </>
                            )}
                        </div>
                    );
                } else if (item.type === 'textarea') {
                    return (
                        <div className="relative assessmentSection rounded-md my-2 mx-2 cursor-pointer py-2 px-1">
                            <div style={{ minHeight: '24px' }} className="flex flex-row">
                                <FaIcons.FaAlignJustify className="mr-4 mt-1" />
                                {item.label}
                            </div>
                            {actionButtons(index)}
                            {minimize[index] === false ? (
                                <>
                                    <div className="rounded-t bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Question</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="text"
                                            value={item.label}
                                            onChange={e => handleChange(e, index, 'label')}
                                        />
                                    </div>
                                    <div className="bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Help Text</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="text"
                                            value={item.description}
                                            onChange={e => handleChange(e, index, 'description')}
                                        />
                                    </div>
                                    <div className="bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Placeholder</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="text"
                                            value={item.placeholder}
                                            onChange={e => handleChange(e, index, 'placeholder')}
                                        />
                                    </div>
                                    <div className="bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Value</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="text"
                                            value={item.value}
                                            onChange={e => handleChange(e, index, 'value')}
                                        />
                                    </div>
                                    <div className="rounded-b bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Points</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="number"
                                            value={item.points}
                                            onWheel={e => e.currentTarget.blur()}
                                            onChange={e => handleChange(e, index, 'points')}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex flex-row h-6 px-2 border border-gray-400 rounded">
                                        {item.value}
                                    </div>
                                    <div className="rounded-b flex flex-row h-6 px-2">
                                        Points: {item.points}
                                    </div>
                                </>
                            )}
                        </div>
                    );
                } else if (item.type === 'file') {
                    return (
                        <div className="relative assessmentSection rounded-md my-2 mx-2 cursor-pointer py-2 px-1">
                            <div style={{ minHeight: '24px' }} className="flex flex-row">
                                <FaIcons.FaFileUpload className="mr-4 mt-1" />
                                {item.label}
                            </div>
                            {actionButtons(index)}
                            {minimize[index] === false ? (
                                <>
                                    <div className="rounded-t bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Question</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="text"
                                            value={item.label}
                                            onChange={e => handleChange(e, index, 'label')}
                                        />
                                    </div>
                                    <div className="bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Help Text</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="text"
                                            value={item.description}
                                            onChange={e => handleChange(e, index, 'description')}
                                        />
                                    </div>
                                    <div className="rounded-b bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Points</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="number"
                                            value={item.points}
                                            onWheel={e => e.currentTarget.blur()}
                                            onChange={e => handleChange(e, index, 'points')}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="rounded-b flex flex-row h-6 px-2">
                                        Points: {item.points}
                                    </div>
                                </>
                            )}
                        </div>
                    );
                } else if (item.type === 'radio-group') {
                    return (
                        <div className="relative assessmentSection rounded-md my-2 mx-2 cursor-pointer py-2 px-1">
                            <div style={{ minHeight: '24px' }} className="flex flex-row">
                                <FaIcons.FaListOl className="mr-4 mt-1" />
                                {item.label}
                            </div>
                            {actionButtons(index)}
                            {minimize[index] === false ? (
                                <>
                                    <div className="rounded-t bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Question</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="text"
                                            value={item.label}
                                            onChange={e => handleChange(e, index, 'label')}
                                        />
                                    </div>
                                    <div className="bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Help Text</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="text"
                                            value={item.description}
                                            onChange={e => handleChange(e, index, 'description')}
                                        />
                                    </div>
                                    <div className="bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Options</b>
                                        <tbody className="w-3/4 2xl:w-5/6 rounded mr-4 bg-white border border-gray-400">
                                            {item.values.map((ansItem, i) => (
                                                <tr key={i} className="flex flex-row">
                                                    <input
                                                        className="w-11/12 flex flex-row p-1 pl-2 focus:outline-none rounded border-b border-r border-gray-400"
                                                        type="text"
                                                        value={ansItem.label}
                                                        onChange={e => {
                                                            const assessmentCopy = [...assessment];
                                                            assessmentCopy[index].values[i].label =
                                                                e.target.value;
                                                            setAssessment(assessmentCopy);
                                                        }}
                                                    />
                                                    <ul>
                                                        <AiFillCheckCircle
                                                            className="text-2xl mt-1 ml-4 cursor-pointer"
                                                            color={
                                                                ansItem.value + ' form-control' ===
                                                                item.answer
                                                                    ? '#1168f5'
                                                                    : '#b4b9c2'
                                                            }
                                                            onClick={() => {
                                                                const assessmentCopy = [
                                                                    ...assessment,
                                                                ];
                                                                assessmentCopy[index].answer =
                                                                    ansItem.value + ' form-control';
                                                                setAssessment(assessmentCopy);
                                                            }}
                                                        />
                                                    </ul>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </div>
                                    <div>
                                        <div className="flex justify-end bg-gray-200 pr-4">
                                            {assessment[index].values.length < 4 && (
                                                <IoAddCircleSharp
                                                    onClick={() => {
                                                        const assessmentCopy = [...assessment];
                                                        assessmentCopy[index].values.push({
                                                            label: `Option ${
                                                                assessment[index].values.length + 1
                                                            }`,
                                                            value: `option-${
                                                                assessment[index].values.length + 1
                                                            }`,
                                                        });
                                                        setAssessment(assessmentCopy);
                                                    }}
                                                    color="#1168f5"
                                                    className="text-2xl cursor-pointer"
                                                />
                                            )}
                                            {assessment[index].values.length > 2 && (
                                                <AiFillMinusCircle
                                                    onClick={() => {
                                                        const assessmentCopy = [...assessment];
                                                        assessmentCopy[index].values.pop();
                                                        setAssessment(assessmentCopy);
                                                    }}
                                                    color="#ef4444"
                                                    className="text-2xl cursor-pointer"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="rounded-b bg-gray-200 flex flex-row py-2">
                                        <b className="pl-12 pr-6 w-1/4 2xl:w-1/6">Points</b>
                                        <input
                                            className="w-3/4 2xl:w-5/6 rounded focus:outline-none mr-4 pl-2 border border-gray-400"
                                            type="number"
                                            value={item.points}
                                            onWheel={e => e.currentTarget.blur()}
                                            onChange={e => handleChange(e, index, 'points')}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex flex-col px-2 text-sm">
                                        {item.values.map((ansItem, i) => (
                                            <div key={i}>
                                                {i + 1 + '. '}
                                                {ansItem.label}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="rounded-b flex flex-row h-6 px-2">
                                        Points: {item.points}
                                    </div>
                                </>
                            )}
                        </div>
                    );
                } else return null;
            })}
        </div>
    );
}
