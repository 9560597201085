import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import moment from 'moment';

import { RootState } from '../../../../redux/rootReducer';
import { getStudentSpecificBadgesData } from '../../../../redux/rootActions';
import { BadgeType } from '../../../../redux/badges/reducers';
import { s3UrlPrefix } from '../../../../constants';

import CloseIcon from '../../../../assets/svg/CloseIcon';

export default function Batches(): ReactElement {
    const history = useHistory();
    const dispatch = useDispatch();
    const userProfile = useSelector((state: RootState) => state.authStudent.userProfile);

    const student = useSelector((state: RootState) => state.students.student);
    const studentsBadgeData = useSelector((state: RootState) => state.badges.StudentBadgesData);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedBadgeData, setSelectedBadgeData] = useState<BadgeType>();
    const [studentsBadges, setStudentsBadges] = useState<BadgeType[]>([]);
    const [earnedBadgeIds, setEarnedBadgeIds] = useState([]);

    useEffect(() => {
        dispatch(getStudentSpecificBadgesData(userProfile?.userId));
    }, [dispatch, userProfile]);

    useEffect(() => {
        if (student?.badges.length > 0) {
            const newArr = student.badges.map(item => {
                return item.badgeId;
            });
            setEarnedBadgeIds(newArr);
        } else {
            setEarnedBadgeIds([]);
        }
    }, [student]);

    useEffect(() => {
        const allBadges = studentsBadgeData?.earned.concat(
            studentsBadgeData?.unearned?.inProgress,
            studentsBadgeData?.unearned?.notStarted
        );
        setStudentsBadges(allBadges);
    }, [studentsBadgeData]);

    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(239, 235, 255, 0.7)',
            backdropFilter: 'blur(7px)',
            overflow: 'auto',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    const handleSelectBadge = badgeData => {
        setSelectedBadgeData(badgeData);
        setShowModal(true);
    };

    return (
        <div className="mb-9">
            <div className="flex flex-row justify-between mb-2">
                <div className="font-bold font-manrope lg:text-xl">
                    Badges ({studentsBadgeData?.earned?.length} earned)
                </div>

                <div className="flex flex-row justify-end items-center">
                    <p
                        onClick={() => {
                            history.push(`/dashBoard/myProgress/badges`);
                        }}
                        className="mr-4 my-1 text-purple-600 hover:underline cursor-pointer text-sm"
                    >
                        View All ({studentsBadges?.length})
                    </p>
                </div>
            </div>
            {studentsBadgeData?.earned.length === 0 ? (
                <p className="text-xs lg:text-sm font-normal text-black mb-4">
                    You have not earned any badges yet. This is will be a great time for you to
                    start working on your challenges and earn badges
                </p>
            ) : null}
            <div style={{ maxHeight: '130px' }} className="flex flex-wrap gap-2 overflow-hidden">
                {studentsBadges
                    ? studentsBadges.map(badge => (
                          <div onClick={() => handleSelectBadge(badge)} key={badge.id}>
                              <div className="flex flex-col justify-center items-center">
                                  <div
                                      className={`flex justify-center items-center border-2 rounded p-2 mb-2 cursor-pointer 
                                      ${
                                          badge.challengeProgress.progressStatus !== 0 &&
                                          `border-gray-100`
                                      }`}
                                      style={{ width: '100px', height: '100px' }}
                                  >
                                      <img
                                          className={`object-contain filter ${
                                              badge.challengeProgress.progressStatus !== 0
                                                  ? `grayscale`
                                                  : 'grayscale-0'
                                          }`}
                                          src={
                                              badge.imageUrl
                                                  ? `${s3UrlPrefix + badge.imageUrl}`
                                                  : '/assets/badge.png'
                                          }
                                          alt={`${badge.badgeName} badge`}
                                      />
                                  </div>
                                  <p
                                      className={`text-xs lg:text-base font-normal text-center w-24 lg:w-28 m-0 cursor-pointer ${
                                          badge.challengeProgress.progressStatus !== 0
                                              ? 'text-gray-300'
                                              : 'text-black'
                                      }`}
                                  >
                                      {badge.badgeName.length > 11
                                          ? badge.badgeName.substring(0, 11) + ' ...'
                                          : badge.badgeName}
                                  </p>
                              </div>
                          </div>
                      ))
                    : null}
            </div>

            <Modal isOpen={showModal} style={customStyles} ariaHideApp={false}>
                <div className="flex-shrink justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
                    <div style={{ width: '650px' }} className="relative">
                        <div
                            className="absolute z-50 top-6 right-6 cursor-pointer"
                            onClick={() => setShowModal(false)}
                        >
                            <CloseIcon size={24} color="rgba(17, 17, 17, 0.48)" />
                        </div>
                        <div className="border-2 rounded-lg shadow-lg bg-white outline-none focus:outline-none flex flex-col items-center px-16 pt-10 pb-7">
                            <img
                                src={
                                    selectedBadgeData?.imageUrl
                                        ? `${s3UrlPrefix + selectedBadgeData?.imageUrl}`
                                        : '/assets/badge.png'
                                }
                                alt={`${selectedBadgeData?.badgeName} badge`}
                                className="mb-8 object-contain"
                                style={{ width: '90px', height: '90px' }}
                            />
                            <h1 className="text-xl font-bold mb-3">
                                {selectedBadgeData?.challengeProgress.progressStatus === 1 ||
                                selectedBadgeData?.challengeProgress.progressStatus === 2
                                    ? `You are close to earning a ${selectedBadgeData.badgeName} badge!`
                                    : selectedBadgeData?.badgeName}
                            </h1>
                            <p className="text-base font-normal text-purple-950 text-center">
                                {selectedBadgeData?.challengeProgress.progressStatus === 1 ||
                                selectedBadgeData?.challengeProgress.progressStatus === 2
                                    ? `You are ${
                                          selectedBadgeData?.challengeProgress.totalCount -
                                          selectedBadgeData?.challengeProgress.completedCount
                                      } challenges away from earning the ${
                                          selectedBadgeData?.badgeName
                                      } badge. Start on the challenges now to earn the badge!`
                                    : selectedBadgeData?.badgeDescription +
                                      `. This badge was earned on ${moment(
                                          selectedBadgeData?.issuedDate
                                      ).format('MMM Do YYYY')}`}
                            </p>
                            <button
                                onClick={() => history.push(`/dashBoard/learn`)}
                                className="bg-purple-950 text-base text-white font-bold rounded px-3 h-12 mt-1"
                            >
                                Continue Learning
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
