import React, { ReactElement } from 'react';
import Modal from 'react-modal';

interface Props {
    showModal: boolean;
    setShowModal: any;
    setOnClickSubmit: () => void;
}

export default function MobileNotVerifiedModal({
    showModal,
    setShowModal,
    setOnClickSubmit,
}: Props): ReactElement {
    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    const handleOnClickSubmit = () => {
        setOnClickSubmit();
        setShowModal(false);
    };

    return (
        <div>
            <Modal isOpen={showModal} style={customStyles} ariaHideApp={false}>
                <>
                    <div className="flex-shrink justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
                        <div style={{ width: '531px' }} className="relative my-6  mx-auto">
                            <div
                                className="rounded-lg shadow-lg relative flex flex-col items-center w-full bg-white outline-none focus:outline-none px-4 py-3"
                                style={{ maxHeight: '324px' }}
                            >
                                <div className="mt-6 mb-2">
                                    <h1 className="text-xl font-bold">Verification Required</h1>
                                </div>
                                <div className=" text-black mb-8 text-center">
                                    Please verify the Mobile Number to continue
                                </div>
                                <div className="flex flex-row gap-2 mb-6">
                                    <button
                                        className="w-40 px-2 bg-purple-700 h-12 text-white rounded font-bold"
                                        onClick={() => handleOnClickSubmit()}
                                    >
                                        Verify
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        </div>
    );
}
