import React from 'react';
import * as HiIcons from 'react-icons/hi';
interface Props {
    tags: any;
    setTags: any;
    selectedTags: any;
}
export default function TagGroups({ selectedTags, setTags, tags }: Props) {
    return (
        <div className="mb-8 w-min">
            {selectedTags
                ? selectedTags?.map((tagGroup, key) => (
                      <>
                          <div key={key}>{tagGroup[0].tagCategory}</div>
                          {tagGroup.map(group => (
                              <>
                                  <p
                                      style={{
                                          maxHeight: '10px',
                                          maxWidth: 'inherit',
                                          display: 'flex',
                                          alignItems: 'center',
                                      }}
                                      key={`${tagGroup.id}${key}`}
                                      className={`rounded-sm border-2 my-1 mr-4 text-gray-500 border-gray-400 font-medium py-3 px-1 w-max`}
                                  >
                                      {group?.tagName}

                                      <HiIcons.HiOutlineXCircle
                                          size={23}
                                          className="ml-2 text-gray-500 cursor-pointer"
                                          onClick={() => {
                                              setTags(
                                                  tags.filter(
                                                      item => item.tagName !== group.tagName
                                                  )
                                              );
                                          }}
                                      />
                                  </p>
                              </>
                          ))}
                      </>
                  ))
                : null}
        </div>
    );
}
