import React, { ReactElement, useEffect, useState } from 'react';
import * as HiIcons from 'react-icons/hi';
import { months } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { getTags, getChallenges } from '../../../redux/rootActions';

interface Props {
    studentLessThan: string;
    setStudentLessThan: (value: string) => void;
    studentMoreThan: string;
    setStudentMoreThan: (value: string) => void;
    status: string;
    setStatus: (value: string) => void;
    startDate: Date;
    setStartDate: (value: Date) => void;
    endDAte: Date;
    setEndDate: (value: Date) => void;
    tags: any[];
    setTags: (value: any[]) => void;
    program: any[];
    setProgram: (value: any[]) => void;
    challenges: any[];
    setChallenges: (value: any[]) => void;
}

export default function AddTabModal({
    studentLessThan,
    setStudentLessThan,
    studentMoreThan,
    setStudentMoreThan,
    setStartDate,
    setEndDate,
    tags,
    setTags,
    challenges,
    setChallenges,
    program,
    setProgram,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const ChallengeData = useSelector((state: RootState) => state.challenges.challengesData);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [trackFilterAction, setTrackFilterAction] = useState<string>('Select a column');

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const month = months[new Date().getMonth()];
    const day = new Date().getDate();
    const year = new Date().getFullYear();

    const EndMonth = months[new Date().getMonth()];
    const EndDay = new Date().getDate();
    const EndYear = new Date().getFullYear();

    const date = new Date(year, new Date().getMonth(), 1);
    const days = [];
    while (date.getMonth() === new Date().getMonth()) {
        days.push(new Date(date).getDate());
        date.setDate(date.getDate() + 1);
    }

    const secondDate = new Date(year, months.indexOf(EndMonth), 1);
    const Enddays = [];
    while (secondDate.getMonth() === months.indexOf(EndMonth)) {
        Enddays.push(new Date(secondDate).getDate());
        secondDate.setDate(secondDate.getDate() + 1);
    }

    const years = [];
    let startYear = new Date().getFullYear();
    const maxYear = startYear + 10;
    for (let i = startYear; i <= maxYear; i++) {
        years.push(startYear);
        startYear++;
    }

    const TrackFilter = [
        { id: 0, val: 'Student' },
        { id: 1, val: 'Programs' },
        { id: 3, val: 'Tags' },
        { id: 4, val: 'Challenges' },
    ];

    useEffect(() => {
        const newdate = new Date(year, months.indexOf(month), day);
        setStartDate(newdate);

        const newEnddate = new Date(EndYear, months.indexOf(EndMonth), EndDay);
        setEndDate(newEnddate);
        // eslint-disable-next-line
    }, [EndDay, EndMonth, EndYear, day, month, year]);

    useEffect(() => {
        dispatch(getTags(true));
        dispatch(getChallenges());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={`flex flex-col my-1.5 ${dropdownVisible && 'h-60'}`}>
                <div className="flex flex-col my-1.5">Select filter column</div>
                <div
                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                >
                    <div
                        className="flex justify-between w-full"
                        onClick={() => setDropdownVisible(true)}
                    >
                        <div className="text-gray-700 font-medium pl-2">{trackFilterAction}</div>
                        <div className="">
                            <div className="flex flex-col">
                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                <HiIcons.HiChevronDown className="mr-2" />
                            </div>
                        </div>
                    </div>
                    {dropdownVisible ? (
                        <ul
                            className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            onMouseLeave={() => setDropdownVisible(false)}
                        >
                            {TrackFilter.map(action => (
                                <div
                                    className="p-2 pl-2 hover:bg-gray-200 "
                                    key={action.id}
                                    onClick={() => {
                                        setTrackFilterAction(action.val);
                                        setDropdownVisible(false);
                                    }}
                                >
                                    {action.val}
                                </div>
                            ))}
                        </ul>
                    ) : null}
                </div>
            </div>
            {trackFilterAction === 'Student' ? (
                <>
                    <div className="flex flex-col my-1.5 w-full md:w-2/4">
                        <label className="flex flex-col my-1.5" htmlFor="tab_1">
                            Enter number of student less than
                        </label>
                        <input
                            className="border-2 border-gray-300 rounded pl-2 py-1"
                            type="text"
                            id="tab_1"
                            value={studentLessThan}
                            placeholder="Number of student"
                            onChange={e => {
                                setStudentLessThan(e.target.value);
                            }}
                        />
                    </div>
                    <div className="flex flex-col my-1.5 w-full md:w-2/4">
                        <label className="flex flex-col my-1.5" htmlFor="tab_2">
                            Enter number of student more than
                        </label>
                        <input
                            className="border-2 border-gray-300 rounded pl-2 py-1"
                            type="text"
                            id="tab_2"
                            placeholder="Number of student"
                            value={studentMoreThan}
                            onChange={e => {
                                setStudentMoreThan(e.target.value);
                            }}
                        />
                    </div>
                </>
            ) : null}

            {trackFilterAction === 'Challenges' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select the set of challenges</label>
                        {ChallengeData.map(challenge => (
                            // eslint-disable-next-line react/jsx-key
                            <div className="my-1">
                                <label>
                                    <input
                                        className="mr-1"
                                        type="checkbox"
                                        name="checkbox"
                                        value={challenge.id}
                                        onClick={e => {
                                            if (challenges.includes(e.currentTarget.value)) {
                                                setChallenges(
                                                    challenges.filter(
                                                        item => item !== e.currentTarget.value
                                                    )
                                                );
                                            } else {
                                                setChallenges([
                                                    ...challenges,
                                                    e.currentTarget.value,
                                                ]);
                                            }
                                        }}
                                    />
                                    {challenge.challengeName}
                                </label>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}

            {trackFilterAction === 'Programs' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select the desired programs</label>
                        <div className="my-1">
                            <label>
                                <input
                                    className="mr-1"
                                    type="checkbox"
                                    name="checkbox"
                                    value="bridge"
                                    onClick={e => {
                                        if (program.includes(e.currentTarget.value)) {
                                            setProgram(
                                                program.filter(
                                                    item => item !== e.currentTarget.value
                                                )
                                            );
                                        } else {
                                            setProgram([...program, e.currentTarget.value]);
                                        }
                                    }}
                                />
                                BRIDGE
                            </label>
                        </div>
                        <div className="my-1">
                            <label>
                                <input
                                    className="mr-1"
                                    type="checkbox"
                                    name="checkbox"
                                    value="bootcamp"
                                    onClick={e => {
                                        if (program.includes(e.currentTarget.value)) {
                                            setProgram(
                                                program.filter(
                                                    item => item !== e.currentTarget.value
                                                )
                                            );
                                        } else {
                                            setProgram([...program, e.currentTarget.value]);
                                        }
                                    }}
                                />
                                Bootcamp
                            </label>
                        </div>
                        <div className="my-1">
                            <label>
                                <input
                                    className="mr-1"
                                    type="checkbox"
                                    name="checkbox"
                                    value="professionalFramework"
                                    onClick={e => {
                                        if (program.includes(e.currentTarget.value)) {
                                            setProgram(
                                                program.filter(
                                                    item => item !== e.currentTarget.value
                                                )
                                            );
                                        } else {
                                            setProgram([...program, e.currentTarget.value]);
                                        }
                                    }}
                                />
                                Professional Framework
                            </label>
                        </div>
                    </div>
                </>
            ) : null}

            {trackFilterAction === 'Tags' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select the desired tags</label>
                        {tagData.map(tag => (
                            // eslint-disable-next-line react/jsx-key
                            <div className="my-1">
                                <label>
                                    <input
                                        className="mr-1"
                                        type="checkbox"
                                        name="checkbox"
                                        value={tag.id}
                                        onClick={e => {
                                            if (tags.includes(e.currentTarget.value)) {
                                                setTags(
                                                    tags.filter(
                                                        item => item !== e.currentTarget.value
                                                    )
                                                );
                                            } else {
                                                setTags([...tags, e.currentTarget.value]);
                                            }
                                        }}
                                    />
                                    {tag.tagName}
                                </label>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}
        </>
    );
}
