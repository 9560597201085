import React, { ReactElement } from 'react';

interface Props {
    size?: number;
    color?: string;
}

export default function Logout({ size, color }: Props): ReactElement {
    return (
        <svg
            width={size || 22}
            height={size || 22}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_197_2909)">
                <path
                    d="M15.5547 16.8438V18.5625C15.5547 20.458 14.0127 22 12.1172 22H3.48047C1.58498 22 0.0429688 20.458 0.0429688 18.5625V3.4375C0.0429688 1.54201 1.58498 0 3.48047 0H12.1172C14.0127 0 15.5547 1.54201 15.5547 3.4375V5.15625C15.5547 5.63092 15.17 6.01562 14.6953 6.01562C14.2206 6.01562 13.8359 5.63092 13.8359 5.15625V3.4375C13.8359 2.48984 13.0648 1.71875 12.1172 1.71875H3.48047C2.53281 1.71875 1.76172 2.48984 1.76172 3.4375V18.5625C1.76172 19.5102 2.53281 20.2812 3.48047 20.2812H12.1172C13.0648 20.2812 13.8359 19.5102 13.8359 18.5625V16.8438C13.8359 16.3691 14.2206 15.9844 14.6953 15.9844C15.17 15.9844 15.5547 16.3691 15.5547 16.8438ZM21.4137 9.52379L19.4893 7.59943C19.1537 7.26373 18.6095 7.26373 18.274 7.59943C17.9383 7.93495 17.9383 8.47911 18.274 8.81464L19.6428 10.1836H9.32422C8.84955 10.1836 8.46484 10.5683 8.46484 11.043C8.46484 11.5176 8.84955 11.9023 9.32422 11.9023H19.6428L18.274 13.2713C17.9383 13.6068 17.9383 14.151 18.274 14.4865C18.4418 14.6544 18.6617 14.7383 18.8816 14.7383C19.1016 14.7383 19.3215 14.6544 19.4893 14.4865L21.4137 12.5621C22.2514 11.7244 22.2514 10.3615 21.4137 9.52379Z"
                    fill={color || '#EA4242'}
                />
            </g>
            <defs>
                <clipPath id="clip0_197_2909">
                    <rect width={size || 22} height={size || 22} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
