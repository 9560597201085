import { decryptKey } from '../constants';
import CryptoJS from 'crypto-js';

export const getDecryptedData = (encryptedText: string) => {
    try {
        const parsedBase64Key = CryptoJS.enc.Base64.parse(decryptKey);
        const decryptedData = CryptoJS.AES.decrypt(encryptedText, parsedBase64Key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);

        return JSON.parse(decryptedText);
    } catch (error) {
        console.log('getDecryptedData error', error);
    }
};
