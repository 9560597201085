import React, { ReactElement } from 'react';

interface Props {
    color: string;
}

export default function BookMobile({ color }: Props): ReactElement {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.5 18.5V5.72077C21.8601 5.87327 22.1895 6.04697 22.5 6.25736V20.5786C22.5 20.5787 22.4999 20.5788 22.4998 20.5789C22.4973 20.5824 22.4941 20.5863 22.4902 20.5902C22.4863 20.5941 22.4824 20.5973 22.4789 20.5998C22.4788 20.5999 22.4788 20.5999 22.4787 20.5999L22.469 20.5999L22.4686 20.5999C20.9775 19.8061 19.1964 19.5 17.5 19.5C16.5945 19.5 15.5118 19.6718 14.4882 19.9486C13.5969 20.1896 12.7208 20.5183 12.0258 20.9043C10.472 19.8609 8.38255 19.5 6.5 19.5C5.00079 19.5 3.02273 19.9509 1.61133 20.6123C1.56922 20.6198 1.53385 20.6294 1.50722 20.6375C1.50445 20.6345 1.50208 20.6316 1.50015 20.6289C1.5001 20.6288 1.50005 20.6287 1.5 20.6286V6.25602C2.81767 5.35665 4.68928 5 6.5 5C8.31072 5 10.1823 5.35665 11.5 6.25602V19.5V20.4057L12.2664 19.9231C12.8846 19.5339 13.7832 19.1752 14.7493 18.9139C15.7132 18.6532 16.7055 18.5 17.5 18.5C18.6604 18.5 19.8075 18.6453 20.8484 18.9765L21.5 19.1838V18.5ZM9.876 18.8944L10.5 19.0541V18.41V7.13V6.80361L10.2012 6.67227C9.21192 6.23742 7.90953 6 6.5 6C5.09047 6 3.78809 6.23742 2.7988 6.67227L2.5 6.80361V7.13V18.5V19.1483L3.12704 18.9836C4.27626 18.6817 5.49267 18.5 6.5 18.5C7.47302 18.5 8.67874 18.5879 9.876 18.8944ZM14.5 15.8773V6.20711L18.5 2.20711V12.2773L14.5 15.8773Z"
                fill={color}
                stroke={color}
            />
        </svg>
    );
}
