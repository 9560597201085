import React, { ReactElement } from 'react';

interface Props {
    size?: number;
}

export default function NextButton({ size }: Props): ReactElement {
    return (
        <svg
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.9543 1 1 9.9543 1 21C1 32.0457 9.9543 41 21 41Z"
                fill="white"
                stroke="white"
                strokeWidth="2"
            />
            <path
                d="M17 13L25 21L17 29"
                stroke="#4C2EC2"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
