import React, { ReactElement, useEffect, useState } from 'react';
import * as HiIcons from 'react-icons/hi';
import { months } from '../../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { getCompanies, getTags, getTracks } from '../../../redux/rootActions';

interface Props {
    tags: any[];
    setTags: (value: any[]) => void;
    companies: string;
    setCompanies: (value: string) => void;
}

export default function AddTabModal({
    tags,
    setTags,
    companies,
    setCompanies,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const companyData = useSelector((state: RootState) => state.companies.companiesData);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [trackFilterAction, setTrackFilterAction] = useState<string>('Select a column');

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const month = months[new Date().getMonth()];
    const day = new Date().getDate();
    const year = new Date().getFullYear();

    const EndMonth = months[new Date().getMonth()];
    const EndDay = new Date().getDate();
    const EndYear = new Date().getFullYear();

    const date = new Date(year, new Date().getMonth(), 1);
    const days = [];
    while (date.getMonth() === new Date().getMonth()) {
        days.push(new Date(date).getDate());
        date.setDate(date.getDate() + 1);
    }

    const secondDate = new Date(year, months.indexOf(EndMonth), 1);
    const Enddays = [];
    while (secondDate.getMonth() === months.indexOf(EndMonth)) {
        Enddays.push(new Date(secondDate).getDate());
        secondDate.setDate(secondDate.getDate() + 1);
    }

    const years = [];
    let startYear = new Date().getFullYear();
    const maxYear = startYear + 10;
    for (let i = startYear; i <= maxYear; i++) {
        years.push(startYear);
        startYear++;
    }

    const TrackFilter = [
        { id: 0, val: 'Company' },
        { id: 3, val: 'Tags' },
    ];

    useEffect(() => {
        dispatch(getTags(true));
        dispatch(getTracks(true));
        dispatch(getCompanies(0, 100));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={`flex flex-col my-1.5 ${dropdownVisible && 'h-40'}`}>
                <div className="flex flex-col my-1.5">Select filter column</div>
                <div
                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                >
                    <div
                        className="flex justify-between w-full"
                        onClick={() => setDropdownVisible(true)}
                    >
                        <div className="text-gray-700 font-medium pl-2">{trackFilterAction}</div>
                        <div className="">
                            <div className="flex flex-col">
                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                <HiIcons.HiChevronDown className="mr-2" />
                            </div>
                        </div>
                    </div>
                    {dropdownVisible ? (
                        <ul
                            className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            onMouseLeave={() => setDropdownVisible(false)}
                        >
                            {TrackFilter.map(action => (
                                <div
                                    className="p-2 pl-2 hover:bg-gray-200 "
                                    key={action.id}
                                    onClick={() => {
                                        setTrackFilterAction(action.val);
                                        setDropdownVisible(false);
                                    }}
                                >
                                    {action.val}
                                </div>
                            ))}
                        </ul>
                    ) : null}
                </div>
            </div>
            {trackFilterAction === 'Company' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select the set of tracks</label>
                        {companyData.map(company => (
                            // eslint-disable-next-line react/jsx-key
                            <div className="my-1">
                                <label>
                                    <input
                                        className="mr-1"
                                        type="radio"
                                        name="checkbox"
                                        value={company.comName}
                                        onClick={e => {
                                            setCompanies(e.currentTarget.value);
                                        }}
                                    />
                                    {company.comName}
                                </label>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}

            {trackFilterAction === 'Tags' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select the desired tags</label>
                        {tagData.map(tag => (
                            // eslint-disable-next-line react/jsx-key
                            <div className="my-1">
                                <label>
                                    <input
                                        className="mr-1"
                                        type="checkbox"
                                        name="checkbox"
                                        value={tag.id}
                                        onClick={e => {
                                            if (tags.includes(parseInt(e.currentTarget.value))) {
                                                setTags(
                                                    tags.filter(
                                                        item =>
                                                            item !== parseInt(e.currentTarget.value)
                                                    )
                                                );
                                            } else {
                                                setTags([...tags, parseInt(e.currentTarget.value)]);
                                            }
                                        }}
                                    />
                                    {tag.tagName}
                                </label>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}
        </>
    );
}
