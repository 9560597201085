import React, { ReactElement } from 'react';
import { Modal } from 'react-responsive-modal';

interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
}

export default function NoPermissionToViewStudent({
    modalVisible,
    setModalVisible,
}: Props): ReactElement {
    return (
        <Modal
            open={modalVisible}
            onClose={() => {
                setModalVisible(false);
            }}
            center
            styles={{ modal: { borderRadius: 12, maxWidth: 555 } }}
            focusTrapped={false}
        >
            <div className="px-4 mt-10 md:px-5">
                <div className="text-lg ml-4" style={{ color: '#9698A9' }}>
                    The student has not given you permission to view this record
                </div>
                <div className="mt-8 flex justify-end">
                    <button
                        className="border bg-blue-600 rounded-md text-white text-sm h-10 leading-4 px-8 ml-2 focus:outline-none hover:text-white hover:bg-blue-800"
                        onClick={() => {
                            setModalVisible(false);
                        }}
                    >
                        Ok
                    </button>
                </div>
            </div>
        </Modal>
    );
}
