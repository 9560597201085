import React, { useState } from 'react';
import Overview from './Overview';
import RegistrationOverview from './RegistrationOverview/RegistrationOverview';
import ProgressionOverview from './StudentProgression/ProgressionOverview';
import { Leaderboard } from './Leaderboard/Leaderboard';
import moment from 'moment';
import InstituteProgressionOverview from './InstituteWiseProgress/InstituteProgressionOverview';
import { INSTITUTE_ADMIN } from '../../../redux/authAdmin/reducers';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';

export const Dashboard = () => {
    const user = useSelector((state: RootState) => state.users.User);
    return (
        <div className="mx-6 lg:ml-0">
            <div className="mt-12 bg-snow-600">
                {user?.roles[0]?.roleName !== INSTITUTE_ADMIN ? <Overview /> : null}
                <div className="flex w-full h-full">
                    <div className={`mr-4 w-full`}>
                        <RegistrationOverview />
                        <ProgressionOverview />
                        <Leaderboard />
                        <InstituteProgressionOverview />
                    </div>
                </div>
            </div>
        </div>
    );
};
