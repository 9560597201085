import React, { ReactElement, useState } from 'react';
import * as RiIcons from 'react-icons/ri';

interface Props {
    title?: string;
    data?: any;
    setSelectItems?: any;
    selectItems?: any;
}

export default function FilterDropdownByNames({
    title,
    data,
    setSelectItems,
    selectItems,
}: Props): ReactElement {
    const [isExpand, setIsExpand] = useState(false);

    return (
        <div className="flex flex-col my-1.5 w-full">
            <div className="flex flex-row" onClick={() => setIsExpand(!isExpand)}>
                {isExpand ? (
                    <RiIcons.RiArrowDropDownLine style={{ height: '25px', width: '25px' }} />
                ) : (
                    <RiIcons.RiArrowDropRightLine style={{ height: '25px', width: '25px' }} />
                )}
                <label className="flex flex-col mb-1.5 cursor-pointer" htmlFor="tab_1">
                    {title}
                </label>
            </div>

            {isExpand &&
                (data.length > 0 ? (
                    data.map((item, index) => {
                        return (
                            <div
                                key={`item_${index}`}
                                className="flex flex-row pl-10 items-center hover:bg-gray-200 mb-1.5 w-full "
                            >
                                <input
                                    className="cursor-pointer"
                                    checked={selectItems.includes(item.name.toString())}
                                    type="checkbox"
                                    name="checkbox"
                                    value={item.name}
                                    onClick={e => {
                                        if (selectItems.includes(e.currentTarget.value)) {
                                            setSelectItems(
                                                selectItems.filter(i => i !== e.currentTarget.value)
                                            );
                                        } else {
                                            setSelectItems([...selectItems, e.currentTarget.value]);
                                        }
                                    }}
                                />
                                <label className="flex flex-col pl-1">{item.name}</label>
                            </div>
                        );
                    })
                ) : (
                    <label className="flex flex-col pl-4 text-gray-400">{'No records found'}</label>
                ))}
        </div>
    );
}
