import React, { ReactElement } from 'react';

interface Props {
    size: string;
}

export default function BackArrow({ size }: Props): ReactElement {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.0002 28.3334C7.63637 28.3334 1.66683 22.3638 1.66683 15C1.66683 7.63622 7.63637 1.66669 15.0002 1.66669C22.364 1.66669 28.3335 7.63622 28.3335 15C28.3335 22.3638 22.364 28.3334 15.0002 28.3334Z"
                fill="white"
                stroke="#121212"
                strokeWidth="2"
            />
            <path
                d="M17 9.66669L11.6667 15L17 20.3334"
                stroke="#121212"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
