import React, { ReactElement, useState } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../assets/svg/CloseIcon';
import Confetti from '../components/elements/Confetti';
import { s3UrlPrefix } from '../../constants';
import { useHistory } from 'react-router-dom';
import { USER_PROFILE } from '../utils/storageWeb';

interface Props {
    showModal: boolean;
    setShowModal: any;
    Badges: BadgeType[] | null;
    isCertificatesAvailable?: boolean;
    isNextChallengeClicked?: boolean;
    selectedTrack: any;
}

interface BadgeType {
    badgeName: string;
    badgeDescription: string | null;
    imageUrl: string | null;
}

export default function BadgeEarn({
    showModal,
    setShowModal,
    Badges,
    isCertificatesAvailable: IsCertificatesAvailable,
    isNextChallengeClicked,
    selectedTrack,
}: Props): ReactElement {
    const history = useHistory();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));

    const [closedModals, setClosedModals] = useState([]);
    const challengesUrl = `/dashBoard/learn/Challenges`;

    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    const handleModals = closed => {
        setClosedModals([...closedModals, closed]);
        if (closed === 0) {
            if (IsCertificatesAvailable) {
                setShowModal(false);
            } else {
                if (isNextChallengeClicked) {
                    history.push({
                        pathname: challengesUrl,
                        state: {
                            tracks: selectedTrack,
                        },
                    });
                } else {
                    setShowModal(false);
                }
            }
        }
    };

    return (
        <div>
            {Badges?.filter((badge, index) => !closedModals.includes(index)).map((Badge, index) => (
                <Modal key={index} isOpen={showModal} style={customStyles} ariaHideApp={false}>
                    <>
                        <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                            <Confetti />
                            <div style={{ width: '643px' }} className="relative my-6  mx-auto">
                                {/* content */}
                                <div
                                    className="absolute z-50 top-6 right-6 cursor-pointer"
                                    onClick={() => handleModals(index)}
                                >
                                    <CloseIcon size={24} color="rgba(17, 17, 17, 0.48)" />
                                </div>
                                <div
                                    className="border-2  rounded-lg shadow-lg relative flex flex-col items-center w-full bg-white outline-none focus:outline-none p-6"
                                    style={{ maxHeight: '656px' }}
                                >
                                    <img
                                        src={
                                            Badge?.imageUrl
                                                ? `${s3UrlPrefix + Badge.imageUrl}`
                                                : '/assets/badge.png'
                                        }
                                        alt="badge"
                                        className="mb-2 object-contain"
                                        style={{ width: '90px', height: '90px' }}
                                    />
                                    <div className="text-purple-500 mb-2 ">{Badge?.badgeName}</div>
                                    <div className=" mb-4">
                                        <h1 className="text-xl font-bold">Congratulations!</h1>
                                    </div>
                                    <div className="text-purple-500 mb-8">
                                        {`You have earned the ${Badge?.badgeName} Badge! Keep up the great work. `}
                                    </div>
                                    <button
                                        className="w-40 px-2 bg-purple-950 h-12 text-white rounded font-bold"
                                        onClick={() => handleModals(index)}
                                    >
                                        Continue Learning
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            ))}
        </div>
    );
}
