import React from 'react';
import styled from 'styled-components';
import { s3UrlPrefix } from '../../../../../constants';
import { StoryType } from '../../../../../redux/successStories/reducers';
import useWidth from '../../../../hooks/useWidth';
import { youtubeParser } from '../../../../utils';

interface Props {
    story: StoryType;
    selectedURLHandler: (data: string) => void;
    showModalHandler: (data: boolean) => void;
}

const DarkenedImage = styled.img`
    --tw-brightness: brightness(0.6);
`;

const FeaturedSuccessStories: React.FC<Props> = ({
    story,
    selectedURLHandler,
    showModalHandler,
}) => {
    const viewPortWidth = useWidth();
    const getId = url => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return match && match[2].length === 11 ? match[2] : null;
    };
    return (
        <>
            {viewPortWidth > 1023 ? (
                <div
                    style={{ width: '420px', height: '270px' }}
                    className="container rounded-lg mx-4"
                >
                    <img
                        src={`https://img.youtube.com/vi/${youtubeParser(story.storyLink)}/0.jpg`}
                        className="youtubeImage rounded self-center place-content-center"
                    />
                    <div className="middle">
                        <div className="rounded px-3 text-white">
                            <div className="flex items-center">
                                <img
                                    className="w-10 h-10 rounded-full"
                                    src={
                                        story.imageUrl
                                            ? `${s3UrlPrefix}${story.imageUrl}`
                                            : '/assets/placeholder-user.png'
                                    }
                                    alt="profile pic"
                                />
                                <div className="ml-4 lg:font-bold text-sm lg:text-base">
                                    {story.firstName}&nbsp;{story.lastName}
                                </div>
                            </div>
                            <div className="mt-3 text-sm featureStoryDescription lg:text-base">
                                {story.content}
                            </div>
                            <div
                                onClick={() => {
                                    selectedURLHandler(youtubeParser(story.storyLink));
                                    showModalHandler(true);
                                }}
                                className="text-white border-2 border-white rounded flex items-center justify-center w-36 h-10 mt-3 mb-3 cursor-pointer"
                            >
                                Watch Now
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="rounded-lg w-full relative">
                    <DarkenedImage
                        src={`https://img.youtube.com/vi/${youtubeParser(story.storyLink)}/0.jpg`}
                        className="rounded filter h-60 object-cover object-center"
                    />
                    <div className="absolute rounded px-6 text-white" style={{ bottom: '5%' }}>
                        <div className="flex items-center">
                            <img
                                className="w-8 h-8 rounded-full"
                                src={
                                    story.imageUrl
                                        ? `${s3UrlPrefix}${story.imageUrl}`
                                        : '/assets/placeholder-user.png'
                                }
                                alt="profile pic"
                            />
                            <div className="ml-4 lg:font-bold text-sm">
                                {story.firstName}&nbsp;{story.lastName}
                            </div>
                        </div>
                        <div className="mt-3 text-sm featureStoryDescription">{story.content}</div>
                        <div
                            onClick={() => {
                                selectedURLHandler(youtubeParser(story.storyLink));
                                showModalHandler(true);
                            }}
                            className="text-white border-2 border-white rounded flex items-center justify-center w-36 h-10 mt-3 mb-3 cursor-pointer"
                        >
                            Watch Now
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FeaturedSuccessStories;
