import React, { ReactElement, useEffect } from 'react';
import { InstitutesType } from '../../../../redux/institutes/reducers';
import { RootState } from '../../../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getInstitutesPublicData } from '../../../../redux/rootActions';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Home town',
                padding: { top: 10 },
                font: {
                    size: 14,
                },
            },
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                autoSkip: false,
            },
        },
        y: {
            title: {
                display: true,
                text: 'Number of Students',
                font: {
                    size: 14,
                },
            },
            grid: {
                display: false,
                drawBorder: false,
            },

            ticks: {
                callback: function (value) {
                    if (Math.floor(value) === value) {
                        return value;
                    }
                },
            },
        },
    },
};
interface Props {
    institute: InstitutesType;
    setInstitute: (value: InstitutesType) => void;
}

export default function StudentRegistration({ institute, setInstitute }: Props): ReactElement {
    const dispatch = useDispatch();
    const registrationData: any = useSelector(
        (state: RootState) => state.dashboard.registrationData
    );
    const labels = registrationData?.labels;
    // for (let i = labels?.length; i < 8; i++) {
    //     labels.push('');
    // }
    const data = {
        labels,
        datasets: [
            {
                data: registrationData?.values,
                backgroundColor: ['rgba(196, 181, 253,1)'],
                maxBarThickness: 200,
            },
        ],
    };
    useEffect(() => {
        dispatch(getInstitutesPublicData());
    }, [dispatch]);
    return (
        <>
            <div className="w-full overflow-hidden pt-8">
                <div className="w-full overflow-x-auto pl-1 pb-6" style={{ height: '400px' }}>
                    <Bar options={options} data={data} />
                </div>
            </div>
            <div className="flex flex-col items-center justify-center pb-6">
                <div className="text-gray-700 text-sm md:text-base  md:font-semibold mb-2">
                    Total Students Registered - {registrationData?.items?.Total}
                </div>
                <div className="text-blue-700 text-sm md:text-base md:font-semibold">
                    Male Students - {registrationData?.items?.Male} &nbsp; &nbsp; Female Students -{' '}
                    {registrationData?.items?.Female}
                </div>
            </div>
        </>
    );
}
