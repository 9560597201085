import { SocialMediaUrlType } from './../../students/reducers';
import {
    GET_PUBLIC_PROFILE,
    GET_PUBLIC_PROFILE_FAILED,
    GET_PUBLIC_PROFILE_SUCCESS,
} from './actions';
export interface badgesType {
    badgeCreatedBy: string;
    badgeCreatedDate: string;
    badgeDescription: string;
    badgeId: string;
    badgeLastUpdatedBy: string;
    badgeLastUpdatedDate: string;
    badgeName: string;
    badgeStatus: string;
    badgeType: string;
    criteria: string | null;
    id: number;
    imageUrl: string;
    tags: tagsType[];
}

export interface bridgeProgressType {
    challengeName: string;
    score: number;
    trackName: string;
}

export interface trackType {
    id: number;
    imageLink: string;
    trackCreatedBy: string;
    trackCreatedDate: string;
    trackDescription: string;
    trackId: string;
    trackLastUpdatedBy: string;
    trackLastUpdatedDate: string;
    trackName: string;
    trackStatus: string;
}

export interface certificatesType {
    certCreatedDate: string;
    certId: string;
    certStatus: string;
    certificateName: string;
    certificateSize: string | null;
    contentDocUrl: string;
    contentPdfUrl: null;
    description: string;
    id: number;
    tags: tagsType[];
    track: trackType;
}

export interface tagsType {
    id: number;
    module: string;
    systemTag: boolean;
    tagCategory: string;
    tagCreatedBy: string;
    tagCreatedDate: string;
    tagLastUpdatedBy: string | null;
    tagLastUpdatedDate: string;
    tagName: string;
}

export interface publicProfileType {
    badges: badgesType[];
    bridgeProgress: bridgeProgressType[];
    certificates: certificatesType[];
    empStatus: string;
    firstName: string;
    lastName: string;
    gender: string;
    isPursuingHigherStudies: string;
    hometown: string;
    imageUrl: string;
    id: number;
    socialMediaUrls: SocialMediaUrlType[];
    tags: tagsType[];
    isLoading: boolean;
}

export interface publicProfileInitialState {
    publicProfileData: publicProfileType | null;
    isLoading: boolean;
    publicProfileDataFailMessage: string;
}

const initialState: publicProfileInitialState = {
    publicProfileData: null,
    isLoading: false,
    publicProfileDataFailMessage: '',
};

const publicProfileReducer = (state = initialState, action: any): publicProfileInitialState => {
    switch (action.type) {
        case GET_PUBLIC_PROFILE:
            return {
                ...state,
                isLoading: true,
                publicProfileData: null,
                publicProfileDataFailMessage: '',
            };
        case GET_PUBLIC_PROFILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                publicProfileData: action.payload,
                publicProfileDataFailMessage: '',
            };

        case GET_PUBLIC_PROFILE_FAILED:
            return {
                ...state,
                isLoading: false,
                publicProfileDataFailMessage: action.payload,
            };
        default:
            return state;
    }
};

export default publicProfileReducer;
