import React from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../../../assets/svg/CloseIcon';
import { useHistory } from 'react-router-dom';

interface Props {
    showAbleToRequestModal: boolean;
    setShowAbleToRequestModal: any;
    customStyles: Modal.styles;
    isLargeScreen?: boolean;
}

const RequestMentorModal = ({
    showAbleToRequestModal,
    setShowAbleToRequestModal,
    customStyles,
    isLargeScreen = true,
}: Props) => {
    const history = useHistory();

    return (
        <Modal isOpen={showAbleToRequestModal} style={customStyles}>
            <>
                <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                    <div
                        style={{ width: '642px', height: '315px' }}
                        className="relative my-6 mx-auto"
                    >
                        {/* content */}
                        <div
                            className="absolute z-50 top-6 right-6 cursor-pointer"
                            onClick={() => setShowAbleToRequestModal(false)}
                        >
                            <CloseIcon size={24} color="rgba(17, 17, 17, 0.48)" />
                        </div>
                        <div
                            style={isLargeScreen ? { maxHeight: '320px' } : { maxHeight: '380px' }}
                            className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-6"
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    top: '-85px',
                                }}
                                className="flex flex-col -bottom-2"
                            >
                                <span className="clapping self-center mb-4"></span>
                                <p className="text-center my-2 text-purple-950">
                                    Hello, Its good news for you!
                                </p>
                                <h1 className="mb-2 mt-3 text-xl text-center font-bold font-manrope">
                                    Now you can Request a Mentor!
                                </h1>
                                <p className="text-center my-2 text-purple-950">
                                    Need guidance in finding your career path? Worry no more, now
                                    you can request a mentor to guide you through the process.
                                </p>
                                {!window.location.pathname.includes('/requestMentor') && (
                                    <div className="flex justify-center mt-3 gap-2">
                                        <button
                                            onClick={() => {
                                                setShowAbleToRequestModal(false);
                                            }}
                                            className="w-40 px-2 text-purple-700 h-10 bg-white border border-purple-700 rounded font-bold "
                                        >
                                            Later
                                        </button>
                                        <button
                                            onClick={() => {
                                                setShowAbleToRequestModal(false);
                                                history.push({
                                                    pathname: `/dashBoard/requestMentor`,
                                                    state: {
                                                        fromChallenge: true,
                                                    },
                                                });
                                            }}
                                            className="bg-purple-950 text-white font-semibold rounded px-4 h-10 "
                                        >
                                            Request a mentor
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Modal>
    );
};
export default RequestMentorModal;
