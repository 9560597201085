import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { clearAuth, resetPassword } from '../redux/authAdmin/actions';
import { RootState } from '../redux/rootReducer';
import { useLocation } from 'react-router-dom';
import AuthSuccessMessages from './modals/AuthSuccessMessages';

export default function PasswordReset(): ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();
    const useQuery = () => {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    };
    const query = useQuery().get('token');

    const resetResponse = useSelector((state: RootState) => state.auth.resetResponse);
    const resetError = useSelector((state: RootState) => state.auth.resetError);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [resetSuccessVisible, setResetSuccessVisible] = useState(false);

    const passwordShow = () => {
        setShowPassword(!showPassword);
    };

    const validationSchema = Yup.object({
        newPassword: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .required('Confirm Password cannot be blank')
            .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
    });

    const handleClearMessages = useCallback(() => {
        if (resetResponse) {
            setResetSuccessVisible(true);
        }
    }, [dispatch, resetResponse, history]);
    const handleClose = () => {
        dispatch(clearAuth());
        history.push('/admin/signIn');
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    return (
        <div>
            <div className="flex justify-center mt-12">
                <div className="w-2/3 lg:w-1/4">
                    <div className="flex flex-col mt-12">
                        <div className="font-bold md:text-3xl mb-4">Create a New Password</div>
                        <div className="">
                            <div className="">
                                <div className="flex-col sm:flex w-full mb-3"></div>

                                <div>
                                    <Formik
                                        initialValues={{ newPassword: '', confirmPassword: '' }}
                                        validationSchema={validationSchema}
                                        onSubmit={async ({ confirmPassword }) => {
                                            dispatch(resetPassword(query, confirmPassword));
                                        }}
                                    >
                                        {({
                                            handleChange,
                                            handleSubmit,
                                            values,
                                            errors,
                                            touched,
                                        }) => (
                                            <form
                                                onSubmit={event => {
                                                    event.preventDefault();
                                                    handleSubmit();
                                                }}
                                            >
                                                <div className="mb-6 md:mb-4">
                                                    <div>
                                                        <label
                                                            className={`block text-sm font-medium leading-149 mb-2 md:text-lg`}
                                                            htmlFor={'newPassword'}
                                                        >
                                                            New Password
                                                        </label>
                                                        <div className="relative">
                                                            <input
                                                                className={`rounded flex items-center focus:outline-none w-full p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.newPassword &&
                                                                    touched.newPassword
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="newPassword"
                                                                type={
                                                                    showPassword
                                                                        ? 'text'
                                                                        : 'password'
                                                                }
                                                                placeholder="New password"
                                                                onChange={handleChange(
                                                                    'newPassword'
                                                                )}
                                                                value={values.newPassword}
                                                            />
                                                            {errors.newPassword &&
                                                            touched.newPassword ? (
                                                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                                    {errors.newPassword}
                                                                </div>
                                                            ) : null}
                                                            <div className="absolute inset-y-2 right-0 pt-1 pr-3 flex text-base item-center">
                                                                {values.newPassword.length > 0 ? (
                                                                    <>
                                                                        <img
                                                                            src="/assets/passwordShowIcon.png"
                                                                            className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                                showPassword
                                                                                    ? 'block'
                                                                                    : 'hidden'
                                                                            }`}
                                                                            onClick={passwordShow}
                                                                            alt="show-password"
                                                                            role="presentation"
                                                                            draggable="false"
                                                                        />
                                                                        <img
                                                                            src="/assets/passwordHideIcon.png"
                                                                            className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                                showPassword
                                                                                    ? 'hidden'
                                                                                    : 'block'
                                                                            }`}
                                                                            alt="hide-password"
                                                                            role="presentation"
                                                                            onClick={passwordShow}
                                                                            draggable="false"
                                                                        />
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mb-6 md:mb-4">
                                                    <div>
                                                        <label
                                                            className={`block text-sm font-medium leading-149 mb-2 md:text-lg`}
                                                            htmlFor={'confirmPassword'}
                                                        >
                                                            Confirm Password
                                                        </label>
                                                        <div className="relative">
                                                            <input
                                                                className={`rounded flex items-center focus:outline-none w-full p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.confirmPassword &&
                                                                    touched.confirmPassword
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="confirmPassword"
                                                                type={
                                                                    showConfirmPassword
                                                                        ? 'text'
                                                                        : 'password'
                                                                }
                                                                placeholder="Confirm password"
                                                                onChange={handleChange(
                                                                    'confirmPassword'
                                                                )}
                                                                value={values.confirmPassword}
                                                            />
                                                            {errors.confirmPassword &&
                                                            touched.confirmPassword ? (
                                                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                                    {errors.confirmPassword}
                                                                </div>
                                                            ) : null}
                                                            <div className="absolute inset-y-2 right-0 pt-1 pr-3 flex text-base item-center">
                                                                {values.confirmPassword.length >
                                                                0 ? (
                                                                    <>
                                                                        <img
                                                                            src="/assets/passwordShowIcon.png"
                                                                            className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                                showConfirmPassword
                                                                                    ? 'block'
                                                                                    : 'hidden'
                                                                            }`}
                                                                            onClick={() =>
                                                                                setShowConfirmPassword(
                                                                                    !showConfirmPassword
                                                                                )
                                                                            }
                                                                            alt="show-password"
                                                                            role="presentation"
                                                                            draggable="false"
                                                                        />
                                                                        <img
                                                                            src="/assets/passwordHideIcon.png"
                                                                            className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                                showConfirmPassword
                                                                                    ? 'hidden'
                                                                                    : 'block'
                                                                            }`}
                                                                            alt="hide-password"
                                                                            role="presentation"
                                                                            onClick={() =>
                                                                                setShowConfirmPassword(
                                                                                    !showConfirmPassword
                                                                                )
                                                                            }
                                                                            draggable="false"
                                                                        />
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex flex-col">
                                                    <div className="mb-2 md:my-4 text-center">
                                                        <button
                                                            type="submit"
                                                            className="focus:outline-none bg-blue-700 text-white text-snow-900 text-base rounded hover:border-transparent w-40 h-10 w-full sm:h-12"
                                                            style={{
                                                                boxShadow:
                                                                    '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                            }}
                                                        >
                                                            Reset Password
                                                        </button>
                                                    </div>
                                                    {resetError && (
                                                        <div className="text-red-400 text-sm flex justify-center">
                                                            {resetError}
                                                        </div>
                                                    )}
                                                </div>
                                                {resetSuccessVisible && (
                                                    <AuthSuccessMessages
                                                        modalVisible={resetSuccessVisible}
                                                        handleClose={handleClose}
                                                    />
                                                )}
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
