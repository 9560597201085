import axios from 'axios';
import { API_URL } from '../../../constants';
import { axiosConfig } from '../../../utils/axiosApi';
import { getErrorMessage } from '../../../utils/axiosErrorHandler';

const PREFIX = '@WEB_NOTIFICATIONS';

export const GET_WEB_NOTIFICATIONS = PREFIX + 'GET_WEB_NOTIFICATIONS';
export const GET_WEB_NOTIFICATIONS_SUCCESS = PREFIX + 'GET_WEB_NOTIFICATIONS_SUCCESS';
export const GET_WEB_NOTIFICATIONS_FAILED = PREFIX + 'GET_WEB_NOTIFICATIONS_FAILED';
export const SET_WEB_NOTIFICATIONS_TYPE = PREFIX + 'SET_WEB_NOTIFICATIONS_TYPE';
export const SET_WEB_NOTIFICATIONS_TYPE_SUCCESS = PREFIX + 'SET_WEB_NOTIFICATIONS_TYPE_SUCCESS';
export const SET_WEB_NOTIFICATIONS_TYPE_FAILED = PREFIX + 'SET_WEB_NOTIFICATIONS_TYPE_FAILED';

export const getWebNotifications = (id?: any) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_WEB_NOTIFICATIONS });
        const config = await axiosConfig(false);
        // const response = await axios.get(`${API_URL}/notifications/user/${id}`, config);
        const response = await axios.get(`${API_URL}/notifications/user/${id}`, config);
        dispatch({ type: GET_WEB_NOTIFICATIONS_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_WEB_NOTIFICATIONS_FAILED, payload: getErrorMessage(error) });
    }
};

export const setNotificationType = (user: any) => async (dispatch: any) => {
    try {
        dispatch({ type: SET_WEB_NOTIFICATIONS_TYPE });
        const config = await axiosConfig(false);
        const response = await axios.put(
            `${API_URL}/users/student/me/settings`,
            { notifications: user },
            config
        );
        dispatch({ type: SET_WEB_NOTIFICATIONS_TYPE_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: SET_WEB_NOTIFICATIONS_TYPE_FAILED, payload: getErrorMessage(error) });
    }
};
