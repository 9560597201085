import React from 'react';
import DashBoard from '../../../assets/svg/DashBoard';
import Challenge from '../../../assets/svg/Challenge';
import LeaderBoard from '../../../assets/svg/LeaderBoard';
import RequestMentor from '../../../assets/svg/RequestMentor';
import Support from '../../../assets/svg/Support';
import Settings from '../../../assets/svg/Settings';
import BookMobile from '../../../assets/svg/BookMobile';
import ProgressMobile from '../../../assets/svg/ProgressMobile';
import LeaderboardMobile from '../../../assets/svg/LeaderboardMobile';
import MentorMobile from '../../../assets/svg/MentorMobile';
import SupportMobile from '../../../assets/svg/SupportMobile';
import SettingsMobile from '../../../assets/svg/SettingsMobile';

export const SidebarData = [
    {
        title: 'Learn',
        path: `dashBoard/learn`,
        getIcon: function getIcon() {
            return <BookMobile color="rgb(112, 122, 146)" />;
        },
        getIcon2: function getIcon2() {
            return <BookMobile color="white" />;
        },
        icon: <Challenge color="rgb(112, 122, 146)" />,
        icon2: <Challenge color="white" />,
    },
    {
        title: 'My Progress',
        path: `dashBoard/myProgress`,
        getIcon: function getIcon() {
            return <ProgressMobile color="rgb(112, 122, 146)" />;
        },
        getIcon2: function getIcon2() {
            return <ProgressMobile color="white" />;
        },
        icon: <DashBoard color="rgb(112, 122, 146)" />,
        icon2: <DashBoard color="white" />,
    },
    {
        title: 'Leaderboard',
        path: `dashBoard/leaderboard`,
        getIcon: function getIcon() {
            return <LeaderboardMobile color="rgb(112, 122, 146)" />;
        },
        getIcon2: function getIcon2() {
            return <LeaderboardMobile color="white" />;
        },
        icon: <LeaderBoard color="rgb(112, 122, 146)" />,
        icon2: <LeaderBoard color="white" />,
    },
    {
        title: 'Request a mentor',
        path: `dashBoard/requestMentor`,
        getIcon: function getIcon() {
            return <MentorMobile color="rgb(112, 122, 146)" />;
        },
        getIcon2: function getIcon2() {
            return <MentorMobile color="white" />;
        },
        icon: <RequestMentor color="rgb(112, 122, 146)" />,
        icon2: <RequestMentor color="white" />,
    },
    {
        title: 'Help',
        path: `dashBoard/help`,
        getIcon: function getIcon() {
            return <SupportMobile color="rgb(112, 122, 146)" />;
        },
        getIcon2: function getIcon2() {
            return <SupportMobile color="white" />;
        },
        icon: <Support color="rgb(112, 122, 146)" />,
        icon2: <Support color="white" />,
    },
    {
        title: 'Settings',
        path: `dashBoard/settings`,
        getIcon: function getIcon() {
            return <SettingsMobile color="rgb(112, 122, 146)" />;
        },
        getIcon2: function getIcon2() {
            return <SettingsMobile color="white" />;
        },
        icon: <Settings color="rgb(112, 122, 146)" />,
        icon2: <Settings color="white" />,
    },
];
