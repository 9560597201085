import {
    CLEAR_REGISTRATION_DATA_MESSAGES,
    GET_LEADERBOARD_DATA,
    GET_LEADERBOARD_DATA_FAILED,
    GET_LEADERBOARD_DATA_SUCCESS,
    GET_STUDENT_RANK,
    GET_STUDENT_RANK_FAILED,
    GET_STUDENT_RANK_SUCCESS,
    GET_PROGRESSION_DATA,
    GET_PROGRESSION_DATA_FAILED,
    GET_PROGRESSION_DATA_SUCCESS,
    GET_HIREDFCB_DATA,
    GET_HIREDFCB_DATA_FAILED,
    GET_HIREDFCB_DATA_SUCCESS,
    GET_OVERVIEW_DATA,
    GET_OVERVIEW_DATA_FAILED,
    GET_OVERVIEW_DATA_SUCCESS,
    GET_INSTITUTE_WISE_PROGRESSION_DATA_FAILED,
    GET_INSTITUTE_WISE_PROGRESSION_DATA_SUCCESS,
    GET_INSTITUTE_WISE_PROGRESSION_DATA,
    GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA_FAILED,
    GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA_SUCCESS,
    GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA,
    GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA_FAILED,
    GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA_SUCCESS,
    GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA,
    GET_CATEGORIES,
    GET_CATEGORIES_FAILED,
    GET_CATEGORIES_SUCCESS,
    GET_STUDENT_REGISTRATION_DATA_FAILED,
    GET_STUDENT_REGISTRATION_DATA_SUCCESS,
    GET_STUDENT_REGISTRATION_DATA,
    GET_INSTITUTE_REGISTRATION_DATA,
    GET_INSTITUTE_REGISTRATION_DATA_FAILED,
    GET_INSTITUTE_REGISTRATION_DATA_SUCCESS,
    GET_COMPANY_REGISTRATION_DATA,
    GET_COMPANY_REGISTRATION_DATA_FAILED,
    GET_COMPANY_REGISTRATION_DATA_SUCCESS,
    DOWNLOAD_CSV_ALL_REGISTRATION,
    DOWNLOAD_CSV_ALL_REGISTRATION_SUCCESS,
    DOWNLOAD_CSV_ALL_REGISTRATION_FAILED,
    DOWNLOAD_CSV_ALL_REGISTRATION_RESET,
    DOWNLOAD_CSV_INSTITUTE_REGISTRATION,
    DOWNLOAD_CSV_INSTITUTE_REGISTRATION_SUCCESS,
    DOWNLOAD_CSV_INSTITUTE_REGISTRATION_FAILED,
    DOWNLOAD_CSV_INSTITUTE_REGISTRATION_RESET,
    DOWNLOAD_CSV_COMPANY_REGISTRATION,
    DOWNLOAD_CSV_COMPANY_REGISTRATION_SUCCESS,
    DOWNLOAD_CSV_COMPANY_REGISTRATION_FAILED,
    DOWNLOAD_CSV_COMPANY_REGISTRATION_RESET,
    DOWNLOAD_CSV_STUDENNT_PROGRESSION,
    DOWNLOAD_CSV_STUDENNT_PROGRESSION_SUCCESS,
    DOWNLOAD_CSV_STUDENNT_PROGRESSION_FAILED,
    DOWNLOAD_CSV_HIRED_BY_FCB,
    DOWNLOAD_CSV_HIRED_BY_FCB_SUCCESS,
    DOWNLOAD_CSV_HIRED_BY_FCB_FAILED,
    DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION,
    DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION_SUCCESS,
    DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION_FAILED,
    DOWNLOAD_CSV_HIRED_BY_FCB_RESET,
    DOWNLOAD_CSV_STUDENNT_PROGRESSION_RESET,
} from './actions';
export interface valueType {
    PREMIUM_CORPORATE: [];
    CORPORATE: [];
}
export interface itemsType {
    Total: number;
    Female: number;
    Male: number;
}
export interface RegistrationDataType {
    labels: string[];
    values: [] | valueType;
    items: itemsType;
}
export interface ProgressionDataType {
    labels: string[];
    values: [] | valueType;
    items: itemsType;
}
export interface HiredFCBDataType {
    labels: string[];
    values: [] | valueType;
    items: itemsType;
}
export interface instituteWiseProgressionDataType {
    labels: string[];
    values: [];
}
export interface categoryType {
    name: string;
    options: [];
}
export interface categoriesDataType {
    category1: categoryType;
    category2: categoryType;
    category3: categoryType;
}
export interface LeaderboardType {
    avgScore: number;
    completedChallengeCount: number;
    email: string;
    gender: string;
    hometown: string;
    institute: string;
    linkedInProfileUrl: null;
    mobile: string;
    rank: number;
    score: number;
    studentId: string;
    studentName: string;
    imageUrl: any;
    mcqTotalScore: number;
    totalScore: number;
    numOfMcqChallengeTasks: number;
    enablePublicProfile: boolean;
    attempts: number;
}
export interface OverviewType {
    companies: number;
    institutes: number;
    mentors: number;
    students: number;
}
export interface DashboarInitialState {
    registrationData: RegistrationDataType;
    isLoadingRegistrationData: boolean;
    registrationDataError: string | null;

    instituteRegistrationData: RegistrationDataType;
    isLoadingInstituteRegistrationData: boolean;
    instituteRegistrationDataError: string | null;

    companyRegistrationData: RegistrationDataType;
    isLoadingCompanyRegistrationData: boolean;
    companyRegistrationDataError: string | null;

    isLoadingLeaderboardData: boolean;
    isLoadingStudentRank: boolean;
    leaderboard: LeaderboardType[];
    studentRank: LeaderboardType;
    pageCount: number;
    progessionData: ProgressionDataType;
    isLoadingProgessionData: boolean;
    leaderboardError: string | null;
    studentRankError: string | null;
    progressionDataError: string | null;

    isLoadingHiredFCBData: boolean;
    HiredFCBData: HiredFCBDataType;
    HiredFCBDataError: string | null;

    isOverviewLoading: boolean;
    overviewData: OverviewType;
    overviewError: string | null;
    isInstituteWiseProgressLoading: boolean;
    instituteWiseProgressData: instituteWiseProgressionDataType;
    instituteWiseProgressRegisterData: instituteWiseProgressionDataType;
    instituteWiseProgressProgressData: instituteWiseProgressionDataType;
    InstituteWiseProgressError: string | null;
    isCategoriesLoading: boolean;
    categoriesData: categoriesDataType;
    categoriesError: string | null;
    isAllRegCSVLoading: boolean;
    allRegisterCSVMessage: string | null;
    instituteRegisterCSVMessage: string | null;
    companyRegisterCSVMessage: string | null;
    instituteRegisterCSVSuccessful: boolean;
    allRegisterCSVSuccessful: boolean;
    companyRegisterCSVSuccessful: boolean;
    institutewiseProgressionEmailMessage: string | null;
    institutewiseProgressionEmailSuccess: boolean;
    studentProgressionCSVMessage: string | null;
    hiredbyFCBEmailMessage: string | null;
    studentProgressionSuccess: boolean;
    isHiredByFCBCSVSuccess: boolean;

    isInstRegCSVLoading: boolean;
    isComptRegCSVLoading: boolean;
    isStuProgCSVLoading: boolean;
    isHiredByFCBCSVLoading: boolean;
    isInstiProgCSVLoading: boolean;
}

const initialState: DashboarInitialState = {
    registrationData: null,
    isLoadingRegistrationData: false,
    registrationDataError: null,

    instituteRegistrationData: null,
    isLoadingInstituteRegistrationData: false,
    instituteRegistrationDataError: null,

    companyRegistrationData: null,
    isLoadingCompanyRegistrationData: false,
    companyRegistrationDataError: null,
    isLoadingStudentRank: false,
    isLoadingLeaderboardData: false,
    leaderboard: [],
    studentRank: null,
    pageCount: null,
    progessionData: null,
    isLoadingProgessionData: false,
    leaderboardError: null,
    studentRankError: null,
    progressionDataError: null,

    isLoadingHiredFCBData: false,
    HiredFCBData: null,
    HiredFCBDataError: null,

    isOverviewLoading: false,
    overviewData: null,
    overviewError: null,
    isInstituteWiseProgressLoading: false,
    instituteWiseProgressData: null,
    instituteWiseProgressRegisterData: null,
    instituteWiseProgressProgressData: null,
    InstituteWiseProgressError: null,
    isCategoriesLoading: false,
    categoriesData: null,
    categoriesError: null,
    isAllRegCSVLoading: false,
    allRegisterCSVMessage: null,
    instituteRegisterCSVMessage: null,
    companyRegisterCSVMessage: null,
    companyRegisterCSVSuccessful: false,
    instituteRegisterCSVSuccessful: false,
    allRegisterCSVSuccessful: false,
    institutewiseProgressionEmailMessage: null,
    studentProgressionCSVMessage: null,
    hiredbyFCBEmailMessage: null,
    studentProgressionSuccess: false,
    isHiredByFCBCSVSuccess: false,
    institutewiseProgressionEmailSuccess: false,

    isInstRegCSVLoading: false,
    isComptRegCSVLoading: false,
    isStuProgCSVLoading: false,
    isHiredByFCBCSVLoading: false,
    isInstiProgCSVLoading: false,
};

const DashboardReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_OVERVIEW_DATA:
            return { ...state, isOverviewLoading: true, overviewError: null };
        case GET_OVERVIEW_DATA_SUCCESS:
            return {
                ...state,
                isOverviewLoading: false,
                overviewData: action.payload,
            };
        case GET_OVERVIEW_DATA_FAILED:
            return {
                ...state,
                isOverviewLoading: false,
                overviewError: action.payload,
            };
        case GET_STUDENT_REGISTRATION_DATA:
            return { ...state, isLoadingRegistrationData: true, registrationDataError: null };
        case GET_STUDENT_REGISTRATION_DATA_SUCCESS:
            return {
                ...state,
                isLoadingRegistrationData: false,
                registrationData: action.payload,
            };
        case GET_STUDENT_REGISTRATION_DATA_FAILED:
            return {
                ...state,
                isLoadingRegistrationData: false,
                registrationDataError: action.payload,
            };

        case GET_INSTITUTE_REGISTRATION_DATA:
            return {
                ...state,
                isLoadingInstituteRegistrationData: true,
                instituteRegistrationDataError: null,
            };
        case GET_INSTITUTE_REGISTRATION_DATA_SUCCESS:
            return {
                ...state,
                isLoadingInstituteRegistrationData: false,
                instituteRegistrationData: action.payload,
            };
        case GET_INSTITUTE_REGISTRATION_DATA_FAILED:
            return {
                ...state,
                isLoadingInstituteRegistrationData: false,
                instituteRegistrationDataError: action.payload,
            };

        case GET_COMPANY_REGISTRATION_DATA:
            return {
                ...state,
                isLoadingCompanyRegistrationData: true,
                companyRegistrationDataError: null,
            };
        case GET_COMPANY_REGISTRATION_DATA_SUCCESS:
            return {
                ...state,
                isLoadingCompanyRegistrationData: false,
                companyRegistrationData: action.payload,
            };
        case GET_COMPANY_REGISTRATION_DATA_FAILED:
            return {
                ...state,
                isLoadingCompanyRegistrationData: false,
                companyRegistrationDataError: action.payload,
            };

        case GET_STUDENT_RANK:
            return { ...state, isLoadingStudentRank: true, studentRankError: null };
        case GET_STUDENT_RANK_SUCCESS:
            return {
                ...state,
                isLoadingStudentRank: false,
                studentRank: action.payload,
                // pageCount: action.payload.totalPages,
            };
        case GET_STUDENT_RANK_FAILED:
            return {
                ...state,
                isLoadingStudentRank: false,
                studentRankError: action.payload,
            };

        case GET_LEADERBOARD_DATA:
            return { ...state, isLoadingLeaderboardData: true, leaderboardError: null };
        case GET_LEADERBOARD_DATA_SUCCESS:
            return {
                ...state,
                isLoadingLeaderboardData: false,
                leaderboard: action.payload.items,
                pageCount: action.payload.totalPages,
            };
        case GET_LEADERBOARD_DATA_FAILED:
            return {
                ...state,
                isLoadingLeaderboardData: false,
                leaderboardError: action.payload,
            };
        case GET_PROGRESSION_DATA:
            return { ...state, isLoadingProgessionData: true, progressionDataError: null };
        case GET_PROGRESSION_DATA_SUCCESS:
            return {
                ...state,
                isLoadingProgessionData: false,
                progessionData: action.payload,
            };
        case GET_PROGRESSION_DATA_FAILED:
            return {
                ...state,
                isLoadingProgessionData: false,
                progressionDataError: action.payload,
            };
        case GET_HIREDFCB_DATA:
            return {
                ...state,
                isLoadingHiredFCBData: true,
                HiredFCBDataError: null,
            };
        case GET_HIREDFCB_DATA_SUCCESS:
            return {
                ...state,
                isLoadingHiredFCBData: false,
                HiredFCBData: action.payload,
            };
        case GET_HIREDFCB_DATA_FAILED:
            return {
                ...state,
                isLoadingHiredFCBData: false,
                HiredFCBDataError: action.payload,
            };
        case GET_INSTITUTE_WISE_PROGRESSION_DATA:
            return {
                ...state,
                isInstituteWiseProgressLoading: true,
                InstituteWiseProgressError: null,
            };
        case GET_INSTITUTE_WISE_PROGRESSION_DATA_SUCCESS:
            return {
                ...state,
                isInstituteWiseProgressLoading: false,
                instituteWiseProgressData: action.payload,
            };
        case GET_INSTITUTE_WISE_PROGRESSION_DATA_FAILED:
            return {
                ...state,
                isInstituteWiseProgressLoading: false,
                InstituteWiseProgressError: action.payload,
            };

        case GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA:
            return {
                ...state,
                isInstituteWiseProgressLoading: true,
                InstituteWiseProgressError: null,
            };
        case GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA_SUCCESS:
            return {
                ...state,
                isInstituteWiseProgressLoading: false,
                instituteWiseProgressRegisterData: action.payload,
            };
        case GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA_FAILED:
            return {
                ...state,
                isInstituteWiseProgressLoading: false,
                InstituteWiseProgressError: action.payload,
            };

        case GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA:
            return {
                ...state,
                isInstituteWiseProgressLoading: true,
                InstituteWiseProgressError: null,
            };
        case GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA_SUCCESS:
            return {
                ...state,
                isInstituteWiseProgressLoading: false,
                instituteWiseProgressProgressData: action.payload,
            };
        case GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA_FAILED:
            return {
                ...state,
                isInstituteWiseProgressLoading: false,
                InstituteWiseProgressError: action.payload,
            };

        case DOWNLOAD_CSV_ALL_REGISTRATION_RESET:
            return {
                ...state,
                allRegisterCSVSuccessful: false,
            };

        case DOWNLOAD_CSV_ALL_REGISTRATION:
            return {
                ...state,
                isAllRegCSVLoading: true,
                csvError: null,
                allRegisterCSVSuccessful: false,
            };
        case DOWNLOAD_CSV_ALL_REGISTRATION_SUCCESS:
            return {
                ...state,
                isAllRegCSVLoading: false,
                allRegisterCSVMessage: action.payload,
                allRegisterCSVSuccessful: true,
            };
        case DOWNLOAD_CSV_ALL_REGISTRATION_FAILED:
            return {
                ...state,
                isAllRegCSVLoading: false,
                // categoriesError: action.payload,
                allRegisterCSVSuccessful: false,
            };

        case DOWNLOAD_CSV_INSTITUTE_REGISTRATION_RESET:
            return {
                ...state,
                instituteRegisterCSVSuccessful: false,
            };

        case DOWNLOAD_CSV_INSTITUTE_REGISTRATION:
            return {
                ...state,
                isInstRegCSVLoading: true,
                csvError: null,
                instituteRegisterCSVSuccessful: false,
            };
        case DOWNLOAD_CSV_INSTITUTE_REGISTRATION_SUCCESS:
            return {
                ...state,
                isInstRegCSVLoading: false,
                instituteRegisterCSVMessage: action.payload,
                instituteRegisterCSVSuccessful: true,
            };
        case DOWNLOAD_CSV_INSTITUTE_REGISTRATION_FAILED:
            return {
                ...state,
                isInstRegCSVLoading: false,
                // categoriesError: action.payload,
                instituteRegisterCSVSuccessful: false,
            };

        case DOWNLOAD_CSV_COMPANY_REGISTRATION_RESET:
            return {
                ...state,
                companyRegisterCSVSuccessful: false,
            };

        case DOWNLOAD_CSV_COMPANY_REGISTRATION:
            return {
                ...state,
                isComptRegCSVLoading: true,
                csvError: null,
                companyRegisterCSVSuccessful: false,
            };
        case DOWNLOAD_CSV_COMPANY_REGISTRATION_SUCCESS:
            return {
                ...state,
                isComptRegCSVLoading: false,
                companyRegisterCSVMessage: action.payload,
                companyRegisterCSVSuccessful: true,
            };
        case DOWNLOAD_CSV_COMPANY_REGISTRATION_FAILED:
            return {
                ...state,
                isComptRegCSVLoading: false,
                // categoriesError: action.payload,
                companyRegisterCSVSuccessful: false,
            };

        case DOWNLOAD_CSV_STUDENNT_PROGRESSION:
            return {
                ...state,
                isStuProgCSVLoading: true,
                csvError: null,
                studentProgressionSuccess: false,
            };
        case DOWNLOAD_CSV_STUDENNT_PROGRESSION_SUCCESS:
            return {
                ...state,
                isStuProgCSVLoading: false,
                studentProgressionCSVMessage: action.payload,
                studentProgressionSuccess: true,
            };
        case DOWNLOAD_CSV_STUDENNT_PROGRESSION_FAILED:
            return {
                ...state,
                isStuProgCSVLoading: false,
                // categoriesError: action.payload,
                studentProgressionSuccess: false,
            };

        case DOWNLOAD_CSV_STUDENNT_PROGRESSION_RESET:
            return {
                ...state,
                studentProgressionSuccess: false,
            };

        case DOWNLOAD_CSV_HIRED_BY_FCB_RESET:
            return {
                ...state,
                isHiredByFCBCSVSuccess: false,
            };

        case DOWNLOAD_CSV_HIRED_BY_FCB:
            return {
                ...state,
                isHiredByFCBCSVLoading: true,
                csvError: null,
                isHiredByFCBCSVSuccess: false,
            };
        case DOWNLOAD_CSV_HIRED_BY_FCB_SUCCESS:
            return {
                ...state,
                isHiredByFCBCSVLoading: false,
                hiredbyFCBEmailMessage: action.payload,
                isHiredByFCBCSVSuccess: true,
            };
        case DOWNLOAD_CSV_HIRED_BY_FCB_FAILED:
            return {
                ...state,
                isHiredByFCBCSVLoading: false,
                // categoriesError: action.payload,
                isHiredByFCBCSVSuccess: false,
            };

        case DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION:
            return {
                ...state,
                isInstiProgCSVLoading: true,
                csvError: null,
                institutewiseProgressionEmailSuccess: false,
            };
        case DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION_SUCCESS:
            return {
                ...state,
                isInstiProgCSVLoading: false,
                institutewiseProgressionEmailMessage: action.payload,
                institutewiseProgressionEmailSuccess: true,
            };
        case DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION_FAILED:
            return {
                ...state,
                isInstiProgCSVLoading: false,
                // categoriesError: action.payload,
                institutewiseProgressionEmailSuccess: false,
            };

        case GET_CATEGORIES:
            return { ...state, isCategoriesLoading: true, categoriesError: null };
        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                isCategoriesLoading: false,
                categoriesData: action.payload,
            };
        case GET_CATEGORIES_FAILED:
            return {
                ...state,
                isCategoriesLoading: false,
                categoriesError: action.payload,
            };
        case CLEAR_REGISTRATION_DATA_MESSAGES:
            return {
                ...state,
                registrationDataError: null,
                progressionDataError: null,
                InstituteWiseProgressError: null,
            };
        default:
            return state;
    }
};

export default DashboardReducer;
