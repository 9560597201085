import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import AssignTagModal from '../../modals/AssignTagModal';
import SuccessStoriesTable from './SuccessStoriesTable';
import Tab from './Tab';
import { getSuccessStories } from '../../../redux/rootActions';

export const SuccessStories = () => {
    const dispatch = useDispatch();
    const successStories = useSelector((state: RootState) => state.successStories.successStories);
    const pageCount = useSelector((state: RootState) => state.successStories.pageCount);
    const [selectedTab, setSelectedTab] = useState(1);
    const [selectedStudents, setSlectedStudents] = useState([]);
    const [assignTagsModalVisible, setAssignTagsModalVisible] = useState(false);
    const fetchIdRef = React.useRef(0);
    const tabStatus = selectedTab === 1 ? 'published' : 'unpublished';

    const columns = useMemo(
        () => [
            {
                Header: 'Student ID',
                accessor: 'userId',
            },
            {
                Header: 'Student Name',
                accessor: 'firstName',
            },
            {
                Header: 'Email',
                accessor: 'studentEmail',
            },
            {
                Header: 'Date',
                accessor: 'createdDate',
            },
            {
                Header: '',
                accessor: '',
                id: 'actionColumn',
                disableSortBy: true,
                width: 5,
            },
        ],
        []
    );

    const fetchData = React.useCallback(
        ({ pageSize, pageIndex }) => {
            const fetchId = ++fetchIdRef.current;
            if (fetchId === fetchIdRef.current) {
                dispatch(getSuccessStories(pageIndex, pageSize, tabStatus));
            }
        },
        [dispatch, tabStatus]
    );

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-16 bg-snow-600">
                <Tab />
                <div className="flex w-full h-screen">
                    <div className={'mr-4 w-full'}>
                        <SuccessStoriesTable
                            columns={columns}
                            data={successStories}
                            fetchData={fetchData}
                            pageCount={pageCount}
                            setSlectedTracks={setSlectedStudents}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    </div>
                </div>
            </div>
            {assignTagsModalVisible ? (
                <AssignTagModal
                    modalVisible={assignTagsModalVisible}
                    setModalVisible={setAssignTagsModalVisible}
                    selectedStudents={selectedStudents}
                    tabStatus={tabStatus}
                />
            ) : null}
        </div>
    );
};
