import { ContactType } from '../students/reducers';
import { TracksType } from '../tracktab/reducers';
import {
    ADD_MENTOR_TAB,
    ADD_MENTOR_TAB_FAILED,
    ADD_MENTOR_TAB_SUCCESS,
    GET_MENTOR_TAB,
    GET_MENTOR_TAB_FAILED,
    GET_MENTOR_TAB_SUCCESS,
} from './actions';

export interface MentorEditType {
    mentorId: string;
    mentorName: string;
    mentorStatus: string;
    mentorType: string;
    imagePath: string;
    mentorDescription: string;
    companyName: string;
    designation: string;
    mentorEmail: string;
    contactNumber: string;
    linkedinProfile: string;
    filePath: string;
}
export interface MentorActivateType {
    mentorId: string;
    mentorName: string;
    mentorStatus: string;
}
export interface MentorType {
    id: string;
    mentorId: string;
    mentorName: string;
    mentorStatus: string;
    mentorType: string;
    imagePath: string;
    description: string;
    companyName: string;
    designation: string;
    mentorEmail: string;
    contactNumber: string;
    linkedinProfile: string;
    filePath: string;
    createdDate: string;
}
export interface CountType {
    ALL: number;
    DELETED: number;
}
export interface MentorsTabInitialState {
    mentorsData: MentorType[];
    mentor: MentorType;
    mentorTabData: [];
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    mentorByIdLoading: boolean;
    mentorByIdError: string | null;
    mentorsError: string | null;
    createMentorError: string | null;
    mentorCreateSuccessMessage: string | null;
    isDeleteLoading: boolean;
    deleteMentorError: string | null;
    mentorDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editMentorError: string | null;
    mentorUpdateSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    mentorBulkSuccessMessage: string | null;
    isPdfLoading: boolean;
    pdfError: string | null;
    pdfLink: string | null;
}

const initialState: MentorsTabInitialState = {
    mentorsData: [],
    mentor: null,
    mentorTabData: [],
    pageCount: null,
    count: null,
    isLoading: false,
    mentorByIdLoading: false,
    mentorByIdError: null,
    mentorsError: null,
    createMentorError: null,
    mentorCreateSuccessMessage: null,
    isDeleteLoading: false,
    deleteMentorError: null,
    mentorDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editMentorError: null,
    mentorUpdateSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    mentorBulkSuccessMessage: null,
    isPdfLoading: false,
    pdfError: null,
    pdfLink: null,
};

const mentorTabReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_MENTOR_TAB:
            return { ...state, isLoading: true, trackError: null };
        case ADD_MENTOR_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                mentorsData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case ADD_MENTOR_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        case GET_MENTOR_TAB:
            return { ...state, isLoading: true, trackError: null };
        case GET_MENTOR_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                mentorTabData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case GET_MENTOR_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        default:
            return state;
    }
};

export default mentorTabReducer;
