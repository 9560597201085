import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import Tab from './Tab';
import NotificationsTable from './NotificationsTable';
import { getNotifications } from '../../../redux/notifications/actions';
import _ from 'lodash';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from 'react-icons/bs';
import * as BiIcons from 'react-icons/bi';
import Loader from '../../../assets/svg/Loader';
import Add from '../../../assets/svg/Add';
import AddFilter from './AddFilter';
import { getTags, getBadges, getNotificationTemplates } from '../../../redux/rootActions';
import { moduleNames, NotificationTypes } from '../../../constants';
import { useQuery } from '../../../web/hooks/useQuery';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export const Notifications = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const searchTermRef = useRef();
    const backToFirstRef = useRef(null);
    const notificationsData = useSelector(
        (state: RootState) => state.notifications.notificationsData
    );
    const isLoading = useSelector((state: RootState) => state.notifications.isLoading);
    const filteredNotificationsData = useSelector(
        (state: RootState) => state.filteredNotifications.notificationsData
    );
    const notificationTemplateData = useSelector(
        (state: RootState) => state.notifications.notificationTemplateData
    );
    const pageCount = useSelector((state: RootState) => state.notifications.pageCount);
    const [tags, setTags] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [searchResultsVisible, setSearchResultsVisible] = useState(false);
    const [selectedMainTab, setSelectedMainTab] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [clickedNotification, setClickedNotification] = useState<any[]>();
    const fetchIdRef = React.useRef(0);
    const [pageSizeS, setPageSizeS] = useState(0);
    const [pageIndexS, setPageIndexS] = useState(0);
    const [templateTypeList, setTemplateTypeList] = useState([]);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [tagsSelected, setTagsSelected] = useState([]);
    const [notificationTypesSelected, setNotificationTypesSelected] = useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [filterTags, setFilterTags] = useState([]);
    const [filterNotificationTypes, setFilterNotificationTypes] = useState([]);

    const notificationTypesQS = query.get('notificationTypes');
    const tagIdsQS = query.get('tagIds');
    const initialRender = useRef(true);

    const tabStatus =
        selectedTab === 1
            ? ''
            : selectedTab === 2
            ? 'EMAIL'
            : selectedTab === 3
            ? 'MOBILE'
            : 'SITE';

    const columns = useMemo(
        () => [
            {
                Header: 'Notification ID',
                accessor: 'notId',
            },
            {
                Header: 'Campaign Title',
                accessor: 'campaignTitle',
            },
            {
                Header: 'Notification Type',
                accessor: 'type',
            },
            {
                Header: 'Notification Mode',
                accessor: 'sentType',
            },
            {
                Header: 'Template Title',
                accessor: 'notificationTitle',
            },
            {
                Header: 'Created Date',
                accessor: data => {
                    return data?.notificationDate != null
                        ? moment(data?.notificationDate).local().format('YYYY-MM-DD')
                        : null;
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (notificationTypesQS) {
            setNotificationTypesSelected(notificationTypesQS.split(','));
        }
        if (tagIdsQS) {
            setTagsSelected(tagIdsQS.split(','));
        }
        dispatch(getTags(true, moduleNames.Student));
        dispatch(getNotificationTemplates(''));
    }, []);

    const fetchData = React.useCallback(
        ({ pageSize, pageIndex }) => {
            setPageSizeS(pageSize);
            setPageIndexS(pageIndex);
            const fetchId = ++fetchIdRef.current;
            if (fetchId === fetchIdRef.current) {
                dispatch(
                    getNotifications(
                        pageIndex,
                        pageSize,
                        tabStatus,
                        tagIdsQS?.split(',').toString(),
                        searchTermRef.current,
                        notificationTypesSelected.toString() ||
                            notificationTypesQS?.split(',').toString()
                    )
                );
            }
        },
        [dispatch, tabStatus, tagIdsQS, notificationTypesSelected, notificationTypesQS]
    );

    const searchByNameOrId = async () => {
        await backToFirstRef?.current?.goToFirst();
        dispatch(
            getNotifications(
                0,
                pageSizeS,
                tabStatus,
                tagsSelected.toString(),
                searchTerm,
                notificationTypesSelected.toString()
            )
        );
    };

    const searchInputHandler = e => {
        searchTermRef.current = e.target.value;
        setSearchTerm(e.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            searchByNameOrId();
        }
    };

    const onRequestClose = () => {
        setShowModal(false);
    };
    useEffect(() => {
        const goBack = async () => {
            backToFirstRef?.current?.goToFirst();
        };
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            goBack().then(() => {
                history.replace({
                    search: `?tagIds=${tagsSelected.toString()}&notificationTypes=${notificationTypesSelected.toString()}`,
                });
                dispatch(
                    getNotifications(
                        pageIndexS,
                        pageSizeS,
                        tabStatus,
                        tagsSelected.toString(),
                        searchTerm,
                        notificationTypesSelected.toString()
                    )
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagsSelected, notificationTypesSelected]);

    useEffect(() => {
        if (tagData.length > 0) {
            const newTagsArr = tagData.map(item => {
                return { name: item.tagName, category: item.tagCategory, id: item.id };
            });
            setFilterTags(newTagsArr);
        } else {
            setFilterTags([]);
        }
        setFilterNotificationTypes(NotificationTypes);
        // const categoryOptions = [{ id: 0, name: 'Custom' }];
        // if (notificationsData) {
        //     notificationsData?.forEach(template => {
        //         const option = {
        //             id: template?.id,
        //             name: template?.notificationTitle,
        //         };
        //         categoryOptions.push(option);
        //     });
        //     setFilterNotificationTypes(categoryOptions);
        // }
    }, [tagData, NotificationTypes]);

    useEffect(() => {
        if (tagData.length > 0) {
            dispatch(
                getNotifications(
                    pageIndexS,
                    pageSizeS,
                    tabStatus,
                    tagsSelected.toString(),
                    searchTerm,
                    notificationTypesSelected.toString()
                )
            );
        }
    }, [searchTerm]);

    const openFilter = () => {
        dispatch(getTags(true, moduleNames.Student));
        setShowModal(true);
    };

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <Tab selectedTab={selectedMainTab} setSelectedTab={setSelectedMainTab} />
                <div className="flex w-full">
                    <div className={'mr-4 w-full'}>
                        <div className="flex h-12 md:h-14 justify-between md:justify-start">
                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex">
                                <input
                                    className={`rounded-sm w-full h-full text-gray-500 font-medium focus:outline-none p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                    id="id"
                                    type="text"
                                    placeholder="Search by Campaign Title"
                                    onChange={e => searchInputHandler(e)}
                                    autoComplete="off"
                                    onKeyDown={handleKeyDown}
                                />
                                <button
                                    className={`ml-2 px-5 border-2 font-medium text-sm rounded-md focus:outline-none ${
                                        searchTerm.length > 0
                                            ? ' text-white border-blue-700 bg-blue-700 cursor-pointer'
                                            : 'cursor-not-allowed border-gray-300'
                                    }`}
                                    disabled={searchTerm.length === 0}
                                    onClick={searchByNameOrId}
                                >
                                    <BsIcons.BsSearch
                                        style={searchTerm.length > 0 && { color: '#ffffff' }}
                                    />
                                </button>
                            </div>
                        </div>
                        <div className="flex h-12 md:h-14 justify-between md:justify-start align-center p-5">
                            <BiIcons.BiFilterAlt
                                style={{
                                    color: 'rgba(29, 78, 216)',
                                    height: '20px',
                                    width: '20px',
                                    marginRight: '10px',
                                    alignSelf: 'baseline',
                                }}
                            />
                            <div
                                className="sm:text-sm md:text-base text-gray-400 pr-2 cursor-pointer"
                                onClick={openFilter}
                            >
                                Filter
                            </div>
                            <button onClick={openFilter}>
                                <Add size={25} color={'rgba(29, 78, 216)'} />
                            </button>
                            <AddFilter
                                setNotificationTypesSelected={setNotificationTypesSelected}
                                notificationTypesSelected={notificationTypesSelected}
                                setTagsSelected={setTagsSelected}
                                tagsSelected={tagsSelected}
                                isSelected={showModal}
                                onRequestClose={onRequestClose}
                                filterTags={filterTags}
                                filterNotificationTypes={filterNotificationTypes}
                            />
                            {tagsSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Tags filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setTagsSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                            {notificationTypesSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Notification Types filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setNotificationTypesSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        {isLoading && (
                            <div className=" w-full h-98  flex items-center">
                                <Loader />
                            </div>
                        )}
                        <div className={`${isLoading && 'hidden'}`}>
                            <NotificationsTable
                                columns={columns}
                                data={
                                    selectedMainTab !== 1
                                        ? filteredNotificationsData
                                        : clickedNotification?.length > 0
                                        ? clickedNotification
                                        : notificationsData
                                }
                                fetchData={fetchData}
                                pageCount={pageCount}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                backToFirstRef={backToFirstRef}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
