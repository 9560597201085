import {
    ADD_BADGE,
    ADD_BADGE_FAILED,
    ADD_BADGE_SUCCESS,
    BADGES_BULK_UPDATE,
    BADGES_BULK_UPDATE_FAILED,
    BADGES_BULK_UPDATE_SUCCESS,
    CLEAR_BADGES_MESSAGES,
    DELETE_BADGE,
    DELETE_BADGE_FAILED,
    DELETE_BADGE_SUCCESS,
    EDIT_BADGE,
    EDIT_BADGE_FAILED,
    EDIT_BADGE_SUCCESS,
    GET_BADGES,
    GET_BADGES_FAILED,
    GET_BADGES_SUCCESS,
    GET_STUDENT_BADGES,
    GET_STUDENT_BADGES_SUCCESS,
    GET_STUDENT_BADGES_FAILED,
    GET_BADGE_BY_ID,
    GET_BADGE_BY_ID_FAILED,
    GET_BADGE_BY_ID_SUCCESS,
} from './actions';

export interface BadgeEditType {
    badgeId: string;
    badgeName: string;
    badgeStatus: string;
    badgeType: string;
    badgeImage: string;
    badgeDescription: string;
    badgeCreatedDate: string;
    badgeCreatedBy: string;
    badgeLastUpdatedBy: string;
    badgeLastUpdatedDate: string;
}

export interface BadgeActivateType {
    badgeId: string;
    badgeName: string;
    badgeStatus: string;
}

export interface ChallengeProgressType {
    completedCount: number;
    progressStatus: number;
    remainingCount: number;
    totalCount: number;
}

export interface BadgeType {
    id: string;
    badgeId: string;
    badgeName: string;
    badgeStatus: string;
    badgeType: string;
    badgeImage: string;
    badgeDescription: string;
    badgeCreatedDate: string;
    badgeCreatedBy: string;
    badgeLastUpdatedBy: string;
    badgeLastUpdatedDate: string;
    issuedDate: null | string;
    imageUrl: string;
    tags: any;
    criteria: any;
    challengeProgress?: ChallengeProgressType;
}

export interface StudentBadgesType {
    studentId: string | number;
    earned: BadgeType[];
    unearned: UnearnedStudentBadgesType;
}

export interface UnearnedStudentBadgesType {
    inProgress: BadgeType[];
    notStarted: BadgeType[];
}

export interface CountType {
    ALL: number;
    ACTIVE: number;
    INACTIVE: number;
    DELETED: number;
}

export interface BadgesInitialState {
    BadgesData: BadgeType[];
    StudentBadgesData: StudentBadgesType;
    StudentBadgesError: string | null;
    StudentBadgeLoading: boolean;
    Badge: BadgeType;
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    BadgeByIdLoading: boolean;
    BadgeByIdError: string | null;
    BadgesError: string | null;
    createBadgeError: string | null;
    BadgeCreateSuccessMessage: string | null;
    isDeleteLoading: boolean;
    deleteBadgeError: string | null;
    BadgeDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editBadgeError: string | null;
    BadgeUpdateSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    BadgeBulkSuccessMessage: string | null;
}

const initialState: BadgesInitialState = {
    BadgesData: [],
    StudentBadgesData: null,
    StudentBadgesError: null,
    StudentBadgeLoading: false,
    Badge: null,
    pageCount: null,
    count: null,
    isLoading: false,
    BadgeByIdLoading: false,
    BadgeByIdError: null,
    BadgesError: null,
    createBadgeError: null,
    BadgeCreateSuccessMessage: null,
    isDeleteLoading: false,
    deleteBadgeError: null,
    BadgeDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editBadgeError: null,
    BadgeUpdateSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    BadgeBulkSuccessMessage: null,
};

const badgesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_BADGES:
            return { ...state, isLoading: true, BadgesError: null };
        case GET_BADGES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                BadgesData: action.payload.items,
                pageCount: action.payload.totalPages,
            };

        case GET_BADGES_FAILED:
            return { ...state, isLoading: false, BadgesError: action.payload };
        case GET_STUDENT_BADGES:
            return { ...state, StudentBadgeLoading: true, StudentBadgesError: null };
        case GET_STUDENT_BADGES_SUCCESS:
            return {
                ...state,
                StudentBadgeLoading: false,
                StudentBadgesData: action.payload,
            };
        case GET_STUDENT_BADGES_FAILED:
            return { ...state, StudentBadgeLoading: false, StudentBadgesError: action.payload };
        case GET_BADGE_BY_ID:
            return { ...state, BadgeByIdLoading: true, BadgeByIdError: null };
        case GET_BADGE_BY_ID_SUCCESS:
            return {
                ...state,
                BadgeByIdLoading: false,
                Badge: action.payload,
            };
        case GET_BADGE_BY_ID_FAILED:
            return { ...state, BadgeByIdLoading: false, BadgeByIdError: action.payload };

        case ADD_BADGE:
            return { ...state, isCreateLoading: true, createBadgeError: null };
        case ADD_BADGE_SUCCESS:
            return {
                ...state,
                isCreateLoading: false,
                BadgeCreateSuccessMessage: action.payload,
            };
        case ADD_BADGE_FAILED:
            return { ...state, isCreateLoading: false, createBadgeError: action.payload };
        case DELETE_BADGE:
            return { ...state, isDeleteLoading: true, deleteBadgerror: null };
        case DELETE_BADGE_SUCCESS:
            return {
                ...state,
                isDeleteLoading: false,
                BadgeDeleteSuccessMessage: action.payload,
            };
        case DELETE_BADGE_FAILED:
            return { ...state, isDeleteLoading: false, deleteBadgerror: action.payload };
        case EDIT_BADGE:
            return { ...state, isEditLoading: true, editBadgeError: null };
        case EDIT_BADGE_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                BadgeUpdateSuccessMessage: action.payload,
            };
        case EDIT_BADGE_FAILED:
            return { ...state, isEditLoading: false, editBadgeError: action.payload };
        case BADGES_BULK_UPDATE:
            return { ...state, isBulkUpdateLoading: true, bulkError: null };
        case BADGES_BULK_UPDATE_SUCCESS:
            return {
                ...state,
                isBulkUpdateLoading: false,
                BadgeBulkSuccessMessage: action.payload,
            };
        case BADGES_BULK_UPDATE_FAILED:
            return { ...state, isBulkUpdateLoading: false, bulkError: action.payload };
        case CLEAR_BADGES_MESSAGES:
            return {
                ...state,
                BadgeCreateSuccessMessage: null,
                createBadgeError: null,
                BadgeUpdateSuccessMessage: null,
                editBadgeError: null,
                deleteBadgeError: null,
                BadgeDeleteSuccessMessage: null,
                BadgeBulkSuccessMessage: null,
                bulkError: null,
            };
        default:
            return state;
    }
};

export default badgesReducer;
