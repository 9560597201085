import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { USER_PROFILE } from '../../utils/storage';
import { getInstitutes, joinedDate } from '../rootActions';

const PREFIX = '@INSTITUTE_TAB';

export const ADD_INSTITUE_TAB = PREFIX + 'ADD_INSTITUE_TAB';
export const ADD_INSTITUE_TAB_SUCCESS = PREFIX + 'ADD_INSTITUE_TAB_SUCCESS';
export const ADD_INSTITUE_TAB_FAILED = PREFIX + 'ADD_INSTITUE_TAB_FAILED';
export const GET_INSTITUTE_TAB = PREFIX + 'GET_INSTITUTE_TAB';
export const GET_INSTITUTE_TAB_SUCCESS = PREFIX + 'GET_INSTITUTE_TAB_SUCCESS';
export const GET_INSTITUTE_TAB_FAILED = PREFIX + 'GET_INSTITUTE_TAB_FAILED';

export interface InstituteTabCreateType {
    id?: string | null;
    tabName?: string | null;
    institutes?: any[];
    categories?: any[];
    joinedDate: joinedDate;
    dateSelected?: boolean;
    joinDateSelected?: boolean;
}

export const getInstituteTabs = (id?: string, association?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_INSTITUTE_TAB });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/users/admins/filter?id=${id}&association=${association}`,
            config
        );
        dispatch({
            type: GET_INSTITUTE_TAB_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_INSTITUTE_TAB_FAILED, payload: getErrorMessage(error) });
    }
};

export const addInstitueTab = (tab: InstituteTabCreateType) => async (dispatch: any) => {
    try {
        if (tab?.joinDateSelected === false) {
            delete tab?.joinedDate;
            delete tab?.joinDateSelected;
            delete tab?.dateSelected;
        }
        if (
            tab?.categories?.length === 0 ||
            tab?.categories === null ||
            tab?.categories === undefined
        ) {
            delete tab?.categories;
        }
        if (
            tab?.institutes?.length === 0 ||
            tab?.institutes === undefined ||
            tab?.institutes === null
        ) {
            delete tab?.institutes;
        }
        if (tab?.joinedDate === undefined || tab?.joinedDate === null) {
            delete tab?.joinedDate;
        }
        dispatch({ type: ADD_INSTITUE_TAB });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/organizations/institutes/filter`, tab, config);
        const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
        await dispatch(getInstituteTabs(profile?.userId, 'INSTITUTE'));

        dispatch({ type: ADD_INSTITUE_TAB_SUCCESS, payload: res.data.responseDto.data });
    } catch (error) {
        dispatch({ type: ADD_INSTITUE_TAB_FAILED, payload: getErrorMessage(error) });
    }
};
