import { signInResponseType } from '../../redux/webRedux/authWeb/reducers';
import { webAuthTimeout } from '../../constants';
import moment from 'moment';
import { logoutWeb } from '../../redux/rootActions';
import { store } from '../..';

export const AUTH_TOKENS = 'AUTH_TOKENS_STUDENT';
export const USER_PROFILE = 'USER_PROFILE_STUDENT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN_STUDENT';
export const EXPIRE_TIME = 'EXPIRE_TIME_STUDENT';
export const NOTIFICATION_COUNT = 'NOTIFICATION_COUNT_STUDENT';
export const CURRENT_STUDENT = 'CURRENT_STUDENT_USER';

export const getStudentAuthCreds = () => {
    try {
        const tokens = localStorage.getItem(AUTH_TOKENS);
        return JSON.parse(tokens);
    } catch (error) {
        console.error('getAuthTokens error', error);
    }
};
export const getUserProfile = () => {
    try {
        const user = localStorage.getItem(USER_PROFILE);
        return user;
    } catch (error) {
        console.error('getUserProfille error', error);
    }
};
export const getCurrentStudent = () => {
    try {
        const currentStudent = localStorage.getItem(CURRENT_STUDENT);
        return currentStudent;
    } catch (error) {
        console.error('getCurrentUser error', error);
    }
};
export const getRefreshToken = () => {
    try {
        const tokens = localStorage.getItem(REFRESH_TOKEN);
        return JSON.parse(tokens);
    } catch (error) {
        console.error('getRefreshToken error', error);
    }
};
export const storeWebAuthCreds = async (response: signInResponseType) => {
    try {
        localStorage.setItem(AUTH_TOKENS, JSON.stringify(response.token));
        localStorage.setItem(REFRESH_TOKEN, JSON.stringify(response.refreshToken));
        localStorage.setItem(
            USER_PROFILE,
            JSON.stringify({
                userName: response.username,
                userId: response.userId,
                userEmail: response.email,
                roles: response.roles,
                storyShow: true,
                showLearnTip: true,
            })
        );
        localStorage.setItem(
            EXPIRE_TIME,
            JSON.stringify(new Date(new Date().getTime() + webAuthTimeout * 24 * 3600 * 1000))
        );

        const oldNotificationCount = JSON.parse(localStorage.getItem(NOTIFICATION_COUNT));
        if (
            !(
                moment(new Date()).format('YYYY-MM-DD') ===
                moment(oldNotificationCount?.date).format('YYYY-MM-DD')
            )
        ) {
            localStorage.setItem(
                NOTIFICATION_COUNT,
                JSON.stringify({ count: 0, date: new Date() })
            );
        }
    } catch (error) {
        console.error('storeAuthCreds error', error);
    }
};

export const storeGoogleCreds = async (response: any) => {
    try {
        localStorage.setItem(AUTH_TOKENS, JSON.stringify(response.token));
        localStorage.setItem(REFRESH_TOKEN, JSON.stringify(response.refreshToken));
        localStorage.setItem(
            USER_PROFILE,
            JSON.stringify({
                userName: response.username,
                userId: response.userId,
                userEmail: response.email,
                roles: response.roles,
                storyShow: true,
                showLearnTip: true,
            })
        );
    } catch (error) {
        console.error('storeAuthCreds error', error);
    }
};

export const clearStudentLocalStorage = () => {
    try {
        localStorage.removeItem(AUTH_TOKENS);
        localStorage.removeItem(USER_PROFILE);
        localStorage.removeItem(REFRESH_TOKEN);
        localStorage.removeItem(EXPIRE_TIME);
    } catch (error) {
        console.log('clearStudentLocalStorage error', error);
    }
};

export const setAccessToken = (token: string) => {
    try {
        if (token) {
            localStorage.setItem(AUTH_TOKENS, JSON.stringify(token));
        } else {
            store.dispatch(logoutWeb());
        }
    } catch (error) {
        console.error('setAccessToken error', error);
    }
};

export const setCurrentStudent = (email: string) => {
    try {
        localStorage.setItem(CURRENT_STUDENT, JSON.stringify(email));
    } catch (error) {
        console.error('setCurrentStudent error', error);
    }
};

export const removeCurrentStudent = () => {
    try {
        localStorage.removeItem(CURRENT_STUDENT);
    } catch (error) {
        console.error('removeCurrentStudent error', error);
    }
};
