import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { sendEmailToResetPassword } from '../redux/authAdmin/actions';
import { RootState } from '../redux/rootReducer';
import Loader from '../assets/svg/Loader';

export default function ForgetPassword(): ReactElement {
    const dispatch = useDispatch();
    const emailSentResponse = useSelector((state: RootState) => state.auth.emailSentResponse);
    const emailSentError = useSelector((state: RootState) => state.auth.emailSentError);
    const isEmailSending = useSelector((state: RootState) => state.auth.isEmailSending);

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email').required('Email is required'),
    });

    return (
        <div>
            <div className="flex justify-center mt-12">
                <div className="w-2/3 lg:w-1/4">
                    <div className="flex flex-col mt-12">
                        <div className="font-bold md:text-xl mb-4">Enter your Email Address</div>
                        <div className="">
                            <div className="">
                                <div className="flex-col sm:flex w-full mb-3"></div>

                                <div>
                                    <Formik
                                        initialValues={{ email: '' }}
                                        validationSchema={validationSchema}
                                        onSubmit={async ({ email }) => {
                                            dispatch(sendEmailToResetPassword(email));
                                        }}
                                    >
                                        {({
                                            handleChange,
                                            handleSubmit,
                                            values,
                                            errors,
                                            touched,
                                        }) => (
                                            <form
                                                onSubmit={event => {
                                                    event.preventDefault();
                                                    handleSubmit();
                                                }}
                                            >
                                                <div className="mb-6 md:mb-4">
                                                    <input
                                                        className={`rounded flex items-center focus:outline-none w-full p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.email && touched.email
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="email"
                                                        type="text"
                                                        placeholder="Email Address"
                                                        onChange={handleChange('email')}
                                                        value={values.email}
                                                    />
                                                    {errors.email && touched.email ? (
                                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                            {errors.email}
                                                        </div>
                                                    ) : null}
                                                    {emailSentResponse && (
                                                        <div className="mt-4 md:text-base font-medium">
                                                            You will recieve an email with further
                                                            instructions
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="flex flex-col">
                                                    <div className="mb-2 md:my-4 text-center">
                                                        <button
                                                            type="submit"
                                                            className="focus:outline-none bg-blue-700 text-white text-snow-900 text-base rounded hover:border-transparent w-40 h-10 sm:w-80 md:w-full sm:h-12"
                                                            style={{
                                                                boxShadow:
                                                                    '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                            }}
                                                        >
                                                            {isEmailSending ? <Loader /> : 'Send'}
                                                        </button>
                                                    </div>
                                                    {emailSentError && (
                                                        <div className="text-red-400 text-sm flex justify-center">
                                                            {emailSentError}
                                                        </div>
                                                    )}
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
