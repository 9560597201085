import { ContactType } from '../students/reducers';
import { TracksType } from '../tracktab/reducers';
import {
    GET_ANALYTICS,
    GET_ANALYTICS_SUCCESS,
    GET_ANALYTICS_FAILED,
    DOWNLOAD_CSV_ANALYTICS,
    DOWNLOAD_CSV_ANALYTICS_SUCCESS,
    DOWNLOAD_CSV_ANALYTICS_FAILED,
    DOWNLOAD_CSV_ANALYTICS_RESET,
    CLEAR_ANALYTICS_MESSAGES,
} from './actions';

export interface analyticsType {
    id: string;
    userId: string;
    userName: string;
    gender: string;
    userCreatedDate: string;
    track: TracksType;
    analysedSubmissions: any[];
    emailId: string;
    averageScore: number;
    noOfChallenges: number;
    contact: ContactType;
}
export interface CountType {
    ALL: number;
    DELETED: number;
    ACTIVE: number;
    DRAFT: number;
}
export interface AnalyticsInitialState {
    analyticsData: analyticsType[];
    pageCount: number;
    count: any;
    isLoading: boolean;
    analyticsError: string | null;
    analyticsEmailMessage: string | null;
    isAnalyticsCSVLoading: boolean;
    LeaderboardCSVSuccess: boolean;
}

const initialState: AnalyticsInitialState = {
    analyticsData: [],
    pageCount: null,
    count: null,
    isLoading: false,
    analyticsError: null,
    analyticsEmailMessage: null,
    isAnalyticsCSVLoading: false,
    LeaderboardCSVSuccess: false,
};

const AnalyticsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_ANALYTICS:
            return { ...state, isLoading: true, analyticsError: null };
        case GET_ANALYTICS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                analyticsData: action.payload.items,
                pageCount: action.payload.totalPages,
            };
        case GET_ANALYTICS_FAILED:
            return { ...state, isLoading: false, analyticsError: action.payload };

        case DOWNLOAD_CSV_ANALYTICS:
            return {
                ...state,
                isAnalyticsCSVLoading: true,
                analyticsCSVError: null,
                LeaderboardCSVSuccess: false,
            };
        case DOWNLOAD_CSV_ANALYTICS_SUCCESS:
            return {
                ...state,
                isAnalyticsCSVLoading: false,
                analyticsEmailMessage: action.payload,
                LeaderboardCSVSuccess: true,
            };
        case DOWNLOAD_CSV_ANALYTICS_FAILED:
            return {
                ...state,
                isAnalyticsCSVLoading: false,
                analyticsCSVError: action.payload,
                LeaderboardCSVSuccess: false,
            };
        case DOWNLOAD_CSV_ANALYTICS_RESET:
            return {
                ...state,
                LeaderboardCSVSuccess: false,
            };
        case CLEAR_ANALYTICS_MESSAGES:
            return {
                ...state,
                analyticsError: null,
            };
        default:
            return state;
    }
};

export default AnalyticsReducer;
