import React, { ReactElement } from 'react';
import { CountType } from '../../../redux/mentors/reducers';

interface Props {
    selectedTab: number;
    setSelectedTab: (value: number) => void;
    tabCounts: CountType;
}

export default function TableTabView({
    selectedTab,
    setSelectedTab,
    tabCounts,
}: Props): ReactElement {
    return (
        <div className="flex flex-row">
            <div
                className={`${
                    selectedTab === 1
                        ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                        : 'text-black-300 border-snow-500'
                } flex text-sm md:text-base xl:text-lg mr-6 cursor-pointer items-center`}
                onClick={() => {
                    setSelectedTab(1);
                }}
            >
                All
            </div>
            <div
                className={`${
                    selectedTab === 2
                        ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                        : 'text-black-300 border-snow-500'
                } flex text-sm md:text-base xl:text-lg mr-6 cursor-pointer items-center`}
                onClick={() => {
                    setSelectedTab(2);
                }}
            >
                Assigned
            </div>
        </div>
    );
}
