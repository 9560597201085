import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEventById } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { s3UrlPrefix } from '../../../constants';
import EventsSuccessModal from '../../modals/EventsSuccessModal';
import { EventType } from '../../../redux/events/reducers';
import RightSideContent from '../../RightSideContent';
import DateTimePicker from '../../DateTimePicker';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroupsView from '../../TagGroupsView';
interface CustomState {
    event: EventType;
}
interface paramsType {
    id: string;
}
export default function ViewEvent() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const params = useParams<paramsType>();
    const eventState = location.state as CustomState;
    const event = useSelector((state: RootState) => state.events.event);
    const [imageUrl] = useState(`${s3UrlPrefix}${eventState?.event?.eventImage}`);
    // const [editSuccessVisible, setEditSuccessVisible] = useState(false);
    const [tags, setTags] = useState(categorizeTags(eventState?.event?.tags));

    useEffect(() => {
        dispatch(getEventById(params.id));
    }, [dispatch, params.id]);

    return (
        <div className="mx-6 lg:ml-2 h-full">
            <div className="mt-12 bg-snow-600">
                <div className=" mt-12">
                    <div className="relative">
                        <div className="flex justify-between items-center mb-4 md:mb-6">
                            <p className="text-xl font-semibold text-left">View Event</p>
                            <div
                                className={`flex h-14 md:h-16 ${
                                    eventState?.event?.eventStatus !== 'DELETED'
                                        ? 'w-1/2 md:w-1/4 lg:pl-8'
                                        : 'w-1/4 md:w-1/6 lg:pl-16'
                                }`}
                            >
                                <button
                                    type="submit"
                                    className={`md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm ${
                                        eventState?.event?.eventStatus !== 'DELETED'
                                            ? 'w-full md:w-1/2'
                                            : 'w-full hover:bg-blue-700 hover:text-white'
                                    } my-2 focus:outline-none`}
                                    onClick={() => {
                                        history.push('/admin/events');
                                    }}
                                >
                                    OK
                                </button>
                                {eventState?.event?.eventStatus !== 'DELETED' && (
                                    <button
                                        type="submit"
                                        className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-normal rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                        onClick={() => {
                                            history.push({
                                                pathname: `/admin/events/${eventState?.event?.id}/edit`,
                                                state: { event: event },
                                            });
                                        }}
                                    >
                                        Edit
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-7 gap-4 md:gap-8 pt-2 pb-8 mb-6">
                            <div className="col-span-12 md:col-span-5 mb-2 md:mb-4">
                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Event ID
                                    </label>

                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-3/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            value={event?.eventId}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Event Name
                                    </label>

                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-3/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            value={event?.eventName}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Event Status
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-3/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            value={event?.eventStatus}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Event Type
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-3/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            value={event?.eventType}
                                            disabled
                                        />
                                    </div>
                                </div>
                                {event?.eventType === 'PHYSICAL' && (
                                    <div className="my-6 w-full md:mb-0 flex">
                                        <label
                                            className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                        >
                                            Address
                                        </label>
                                        <div className="flex flex-col w-full">
                                            <input
                                                className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-3/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                value={event?.location}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                )}

                                {event?.eventType === 'VIRTUAL' && (
                                    <div className="my-6 w-full md:mb-0 flex">
                                        <label
                                            className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                        >
                                            Event Link
                                        </label>
                                        <div className="flex flex-col w-full">
                                            <input
                                                className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-3/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                value={event?.eventLink}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Registration Link
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-3/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            value={event?.registerLink}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Duration
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <DateTimePicker
                                            startDate={eventState?.event?.eventStartTime}
                                            endDate={eventState?.event?.eventEndTime}
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex h-32">
                                    <label
                                        className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Description
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <textarea
                                            className={`resize-none border-gray-300 focus:outline-none p-2 w-3/4 md:w-3/5 border-2 text-sm h-32 focus:border-blue-900`}
                                            value={event?.eventDescription}
                                            placeholder=""
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        className={`flex items-start w-1/5 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Event Image
                                    </label>
                                    <div
                                        className={`bg-snow-600 w-3/4 md:w-3/5 mb-2 md:mb-4 relative ${
                                            imageUrl ? 'w-fit' : ''
                                        }`}
                                    >
                                        <div
                                            className={`text-base border-dashed border-black-600 border-opacity-100 border-2 justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64`}
                                        >
                                            {imageUrl && eventState?.event?.eventImage && (
                                                <img
                                                    src={imageUrl}
                                                    alt={'name'}
                                                    className="h-44 lg:h-64"
                                                    style={{ maxHeight: 300 }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                <div className="flex border-2 flex-col ml-2 justify-items-center">
                                    <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                        <div className="sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                            Tags
                                        </div>
                                    </div>
                                    <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                        <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                            <TagGroupsView selectedTags={tags} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {editSuccessVisible && (
                            <EventsSuccessModal
                                modalVisible={editSuccessVisible}
                                setModalVisible={setEditSuccessVisible}
                            />
                        )} */}
                    </div>
                </div>
            </div>
        </div>
    );
}
