import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../../../../../assets/svg/Loader';
import {
    clearWebAuth,
    confirmEmailVerification,
    logoutWeb,
} from '../../../../../../redux/rootActions';
import { RootState } from '../../../../../../redux/rootReducer';

const WebVerifyEmail = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useSelector((state: RootState) => state.webUser.isLoading);

    const verifyToken = location?.search.slice(7);

    useEffect(() => {
        dispatch(confirmEmailVerification(verifyToken));
        dispatch(logoutWeb());
    }, [dispatch, verifyToken]);

    return (
        <div className="flex w-full items-center justify-center">
            <div className="flex flex-col p-4 w-1/3">
                <div className="flex text-2xl font-semibold justify-center">
                    Email successfully verified
                </div>
                <div className="flex mt-4 justify-center">
                    Please login to Future Careers BRIDGE using your credentials.
                </div>
                <div className="flex mt-4 justify-center">
                    <button
                        type="button"
                        className="py-2.5 px-4 w-full rounded bg-purple-950 text-white"
                        onClick={() => {
                            history.push('/signin');
                        }}
                    >
                        {loading ? <Loader /> : 'Sign In'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WebVerifyEmail;
