import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { USER_PROFILE } from '../../utils/storage';

const PREFIX = '@TAB';

export const ADD_MENTOR_TAB = PREFIX + 'ADD_MENTOR_TAB';
export const ADD_MENTOR_TAB_SUCCESS = PREFIX + 'ADD_MENTOR_TAB_SUCCESS';
export const ADD_MENTOR_TAB_FAILED = PREFIX + 'ADD_MENTOR_TAB_FAILED';
export const GET_MENTOR_TAB = PREFIX + 'GET_MENTOR_TAB';
export const GET_MENTOR_TAB_SUCCESS = PREFIX + 'GET_MENTOR_TAB_SUCCESS';
export const GET_MENTOR_TAB_FAILED = PREFIX + 'GET_MENTOR_TAB_FAILED';

export interface MentorTabCreateType {
    id?: string | null;
    tabName?: string | null;
    tags?: any[];
    companyName?: string | null;
}

export interface Company {
    from: number | null;
    to: number | null;
}

export const getMentorTabs = (id?: string, association?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_MENTOR_TAB });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/users/admins/filter?id=${id}&association=${association}`,
            config
        );
        dispatch({
            type: GET_MENTOR_TAB_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_MENTOR_TAB_FAILED, payload: getErrorMessage(error) });
    }
};

export const addMentorTab = (tab: MentorTabCreateType) => async (dispatch: any) => {
    try {
        if (tab?.companyName === undefined || tab?.companyName === null) {
            delete tab.companyName;
        }
        if (tab?.tags?.length === 0 || tab?.tags === undefined || tab?.tags === null) {
            delete tab.tags;
        }
        dispatch({ type: ADD_MENTOR_TAB });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/mentors/filter`, tab, config);
        const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
        await dispatch(getMentorTabs(profile?.userId, 'MENTOR'));
        dispatch({ type: ADD_MENTOR_TAB_SUCCESS, payload: res.data.responseDto.data });
    } catch (error) {
        dispatch({ type: ADD_MENTOR_TAB_FAILED, payload: getErrorMessage(error) });
    }
};
