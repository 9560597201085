import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as MdIcons from 'react-icons/md';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
export const SidebarData = [
    {
        title: 'Dashboard',
        path: '/admin/dashboard',
        icon: <AiIcons.AiFillHome />,
    },
    {
        title: 'Tracks',
        path: '/admin/tracks',
        icon: <IoIcons.IoIosPaper />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    },
    {
        title: 'Challenges',
        path: '/admin/challenges',
        icon: <IoIcons.IoIosPaper />,
    },
    {
        title: 'Achievements',
        path: '/admin/achievements/badges',
        icon: <GiIcons.GiAchievement />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Badges',
                path: '/admin/achievements/badges',
                icon: <IoIcons.IoIosPaper />,
            },
            {
                title: 'Certificates',
                path: '/admin/achievements/certificates',
                icon: <IoIcons.IoIosPaper />,
            },
        ],
    },
    {
        title: 'Students',
        path: '/admin/students',
        icon: <FaIcons.FaUserGraduate />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'All Students',
                path: '/admin/students',
                icon: <IoIcons.IoIosPaper />,
            },
            {
                title: 'Success Stories',
                path: '/admin/students/successStories',
                icon: <IoIcons.IoIosPaper />,
            },
        ],
    },
    {
        title: 'Institutes',
        path: '/admin/institutes',
        icon: <AiIcons.AiFillCalculator />,
    },
    {
        title: 'Mentors',
        path: '/admin/mentors',
        icon: <FaIcons.FaUser />,
    },
    {
        title: 'Companies',
        path: '/admin/companies',
        icon: <FaIcons.FaBuilding />,
    },
    {
        title: 'Events',
        path: '/admin/events',
        icon: <BsIcons.BsFillCalendarFill />,
    },
    {
        title: 'Notifications',
        path: '/admin/notifications',
        icon: <FaIcons.FaEnvelopeOpenText />,
    },
    {
        title: 'Leaderboard',
        path: '/admin/leaderboard',
        icon: <IoIcons.IoMdAnalytics />,
    },
    {
        title: 'Users',
        path: '/admin/users',
        icon: <FaIcons.FaUser />,
    },
    {
        title: 'My Settings',
        path: '/admin/settings',
        icon: <MdIcons.MdSettings />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    },
];
