import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { USER_PROFILE } from '../../utils/storage';

const PREFIX = '@TAB';

export const ADD_ANALYTICS_TAB = PREFIX + 'ADD_ANALYTICS_TAB';
export const ADD_ANALYTICS_TAB_SUCCESS = PREFIX + 'ADD_ANALYTICS_TAB_SUCCESS';
export const ADD_ANALYTICS_TAB_FAILED = PREFIX + 'ADD_ANALYTICS_TAB_FAILED';
export const GET_ANALYTICS_TAB = PREFIX + 'GET_ANALYTICS_TAB';
export const GET_ANALYTICS_TAB_SUCCESS = PREFIX + 'GET_ANALYTICS_TAB_SUCCESS';
export const GET_ANALYTICS_TAB_FAILED = PREFIX + 'GET_ANALYTICS_TAB_FAILED';

export interface AnalyticsTabCreateType {
    id?: string | null;
    tabName?: string | null;
    tags?: any[];
    gender?: string | null;
    average?: Average;
    // homeTowns?: any[];
    tracks?: any[];
    homeTown?: string | null;
    institutes?: any[];
}

export interface Average {
    from: number | null;
    to: number | null;
}

export const getAnalyticsTabs = (id?: string, association?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_ANALYTICS_TAB });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/users/admins/filter?id=${id}&association=${association}`,
            config
        );
        dispatch({
            type: GET_ANALYTICS_TAB_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_ANALYTICS_TAB_FAILED, payload: getErrorMessage(error) });
    }
};

export const addAnalyticsTab = (tab: AnalyticsTabCreateType) => async (dispatch: any) => {
    try {
        if (
            tab?.average?.from === undefined ||
            tab?.average?.from === null ||
            tab?.average === undefined ||
            tab?.average === null
        ) {
            delete tab.average;
        }
        if (tab?.homeTown === undefined || tab?.homeTown === null) {
            delete tab.homeTown;
        }
        if (tab?.gender === undefined || tab?.gender === null) {
            delete tab.gender;
        }
        if (tab?.tags?.length === 0 || tab?.tags === undefined || tab?.tags === null) {
            delete tab.tags;
        }
        if (tab?.tracks?.length === 0 || tab?.tracks === undefined || tab?.tracks === null) {
            delete tab.tracks;
        }
        if (
            tab?.institutes?.length === 0 ||
            tab?.institutes === undefined ||
            tab?.institutes === null
        ) {
            delete tab.institutes;
        }
        dispatch({ type: ADD_ANALYTICS_TAB });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/analytics/filter`, tab, config);
        const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
        await dispatch(getAnalyticsTabs(profile?.userId, 'ANALYTIC'));

        dispatch({ type: ADD_ANALYTICS_TAB_SUCCESS, payload: res.data.responseDto.data });
    } catch (error) {
        dispatch({ type: ADD_ANALYTICS_TAB_FAILED, payload: getErrorMessage(error) });
    }
};
