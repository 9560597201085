import React, { ReactElement } from 'react';

export default function Verified(): ReactElement {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle opacity="0.12" cx="20" cy="20" r="20" fill="#27AE60" />
            <path
                d="M16.0273 20.4585C15.8315 20.2978 15.5763 20.2107 15.313 20.2145C15.0497 20.2183 14.7977 20.3128 14.6076 20.479C14.4175 20.6452 14.3033 20.8709 14.2879 21.1108C14.2725 21.3506 14.3571 21.5869 14.5248 21.7722L16.8412 23.9902C16.9382 24.083 17.0547 24.157 17.1838 24.2077C17.3129 24.2584 17.4519 24.2848 17.5924 24.2853C17.7322 24.286 17.8708 24.2611 17.9999 24.2121C18.1289 24.163 18.2458 24.0908 18.3437 23.9997L25.4182 17.3361C25.5141 17.2461 25.5896 17.1398 25.6405 17.0231C25.6914 16.9065 25.7165 16.7819 25.7146 16.6563C25.7127 16.5308 25.6836 16.4069 25.6292 16.2916C25.5748 16.1763 25.496 16.0719 25.3973 15.9844C25.2986 15.8969 25.1821 15.828 25.0542 15.7816C24.9263 15.7352 24.7897 15.7122 24.6521 15.714C24.5146 15.7157 24.3787 15.7422 24.2523 15.7919C24.126 15.8415 24.0115 15.9134 23.9156 16.0034L17.6029 21.9626L16.0273 20.4585Z"
                fill="#27AE60"
            />
        </svg>
    );
}
