import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendEmailVerification, SEND_EMAIL_VERIFICATION_RESET } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';

const ChangeEmailFeedback = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.users.User);
    const { email } = (props.location && props.location.state) || {};
    const [resent, setResent] = useState(false);
    const settingeURL = '/admin/settings';
    const sendEmailVerificationSuccess = useSelector(
        (state: RootState) => state.webUser.sendEmailVerificationSuccessMessage
    );
    const resend = useCallback(() => {
        if (!resent) {
            dispatch(sendEmailVerification(user?.userId, user?.userName, email));
            setResent(true);
            setTimeout(() => {
                setResent(false);
            }, 4000);
        }
    }, [resent]);
    useEffect(() => {
        if (sendEmailVerificationSuccess) {
            dispatch({ type: SEND_EMAIL_VERIFICATION_RESET });
        }
    }, [dispatch, sendEmailVerificationSuccess]);

    return (
        <>
            <div className="flex w-full h-screen items-center justify-center">
                <div className="flex flex-col p-4 w-1/3">
                    <div className="flex text-2xl font-semibold justify-center">
                        Check your Mail
                    </div>
                    <div className="flex mt-4">
                        <div>
                            There is a pending change on your email. We have sent a verification to
                            your new email,
                            <span className="text-blue-800">{` "${email || ''}"`}</span>
                        </div>
                    </div>
                    <div
                        className={`flex mt-4 ${
                            resent ? 'text-green-400' : 'text-blue-800'
                        } justify-center cursor-pointer`}
                        onClick={resend}
                    >
                        {resent ? 'Confirmation Email Resent !' : 'Resend Verification Email'}
                    </div>
                    <div
                        className={`flex mt-4 text-blue-800 justify-center cursor-pointer ${
                            resent && 'opacity-50'
                        }`}
                        onClick={() =>
                            !resent &&
                            history.push({
                                pathname: settingeURL,
                                state: {
                                    selectedTab: 3,
                                },
                            })
                        }
                    >
                        Return to Account Security
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangeEmailFeedback;
