import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Camera from '../../../assets/svg/Camera';
import { useDispatch, useSelector } from 'react-redux';
import { clearEventsMessages, editEvent, getEventById, getTags } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { moduleNames, s3Config, s3UrlPrefix } from '../../../constants';
import S3 from 'react-aws-s3';
import * as HiIcons from 'react-icons/hi';
import EventsSuccessModal from '../../modals/EventsSuccessModal';
import { EventType } from '../../../redux/events/reducers';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import RightSideContent from '../../RightSideContent';
import DateTimePicker from '../../DateTimePicker';
import CropModal from '../../modals/cropModal';
import moment from 'moment';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroups from '../../TagGroups';
interface CustomState {
    event: EventType;
}
interface paramsType {
    id: string;
}
export default function EditEvent() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const params = useParams<paramsType>();
    const eventState = location.state as CustomState;
    const event = useSelector((state: RootState) => state.events.event);
    const isEditLoading = useSelector((state: RootState) => state.events.isEditLoading);
    const editEventError = useSelector((state: RootState) => state.events.editEventError);
    const eventUpdateSuccessMessage = useSelector(
        (state: RootState) => state.events.eventUpdateSuccessMessage
    );
    const deleteEventError = useSelector((state: RootState) => state.events.deleteEventError);
    const eventDeleteSuccessMessage = useSelector(
        (state: RootState) => state.events.eventDeleteSuccessMessage
    );
    const formRef = useRef<any>();
    const [imageUrl, setImageUrl] = useState(`${s3UrlPrefix + eventState?.event?.eventImage}`);
    const [imageLocation, setImageLocation] = useState(eventState?.event?.eventImage);
    const [isUploading, setIsUploading] = useState(false);
    const [editSuccessVisible, setEditSuccessVisible] = useState(false);
    const [category, setCategory] = useState<string>('Virtual Event');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [startDate, setStartDate] = useState<string>(eventState?.event?.eventStartTime);
    const [endDate, setEndDate] = useState<string>(eventState?.event?.eventEndTime);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [tags, setTags] = useState(eventState?.event?.tags);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openCrop, setOpenCrop] = useState(false);

    const [tagsHelpText, setTagsHelpText] = useState<boolean>(false);

    const categoryOptions = [
        { id: 0, val: 'Virtual Event' },
        { id: 1, val: 'Physical Event' },
    ];

    const serchTagNameOrId = e => {
        setSearchTerm(e.target.value);
        // setTags([]);
    };
    useEffect(() => {
        setSelectedTags(categorizeTags(tags));
    }, [tags]);

    useEffect(() => {
        if (showDropdown) {
            dispatch(getTags(true, moduleNames.Events, searchTerm));
        }
    }, [dispatch, searchTerm, showDropdown]);

    useEffect(() => {
        setTags(eventState?.event?.tags);
    }, [eventState]);

    useEffect(() => {
        dispatch(getEventById(params.id));
    }, [dispatch, params.id]);

    const handleClearMessages = useCallback(() => {
        if (
            eventUpdateSuccessMessage ||
            editEventError ||
            deleteEventError ||
            eventDeleteSuccessMessage
        ) {
            setEditSuccessVisible(true);
        }
    }, [
        dispatch,
        eventUpdateSuccessMessage,
        editEventError,
        eventDeleteSuccessMessage,
        deleteEventError,
        history,
    ]);
    const handleClose = () => {
        dispatch(clearEventsMessages());
        if (!editEventError) {
            history.push('/admin/events');
        }
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    const handleImage = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setImageUrl(URL.createObjectURL(file));
            setOpenCrop(true);
        }
    };
    const uploadImageToS3 = async croppedImg => {
        setIsUploading(true);
        const imageName = Date.now().toString();
        s3Config.dirName = `events/${imageName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(croppedImg, imageName)
            .then(data => {
                setImageLocation(data.key);
                setIsUploading(false);
            })
            .catch(err => {
                console.error(err);
                setIsUploading(false);
            });
    };
    const handleCloseCropModal = () => {
        setOpenCrop(false);
        setImageUrl(`${s3UrlPrefix + eventState?.event?.eventImage}`);
    };
    return (
        <div className="mx-6 lg:ml-2 h-full">
            <div className="mt-12 bg-snow-600">
                <div className=" mt-12">
                    <div className="relative">
                        <Formik
                            enableReinitialize={true}
                            innerRef={formRef}
                            initialValues={{
                                eventName: event?.eventName.trim(),
                                location: event?.location,
                                eventLink: event?.eventLink,
                                eventStatus: event?.eventStatus,
                                eventImage: imageLocation,
                                image: undefined,
                                eventStartTime: event?.eventStartTime,
                                eventDescription: event?.eventDescription.trim(),
                                eventEndTime: event?.eventEndTime,
                                eventType: event?.eventType,
                                registration: event?.registerLink,
                            }}
                            validationSchema={Yup.object({
                                eventName: Yup.string()
                                    .max(200, 'Name should be less than 200 characters')
                                    .required('Name is required'),
                                eventType: Yup.string(),
                                eventDescription: Yup.string()
                                    .nullable()
                                    .max(500, 'Description should be less than 500 characters')
                                    .required('Description is required'),
                                eventLink: Yup.string()
                                    .nullable()
                                    .when('eventType', {
                                        is: 'VIRTUAL',
                                        then: Yup.string()
                                            .nullable()
                                            .required('Link is required')
                                            .matches(
                                                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
                                                'Website should be a valid URL'
                                            ),
                                    }),
                                registration: Yup.string()
                                    .nullable()
                                    .matches(
                                        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
                                        'Registration should be a valid URL'
                                    ),
                                location: Yup.string()
                                    .nullable()
                                    .when('eventType', {
                                        is: 'PHYSICAL',
                                        then: Yup.string()
                                            .nullable()
                                            .required('Location is required'),
                                    }),
                            })}
                            onSubmit={async values => {
                                dispatch(
                                    editEvent(
                                        event?.id,
                                        {
                                            eventName: values.eventName,
                                            location: values.location,
                                            eventLink: values.eventLink,
                                            eventStatus: values.eventStatus,
                                            eventImage: imageLocation,
                                            eventStartTime: startDate,
                                            eventDescription: values.eventDescription,
                                            eventEndTime: endDate,
                                            eventType: values.eventType,
                                            registerLink: values.registration,
                                            tags: tags,
                                        },
                                        null
                                    )
                                );
                            }}
                        >
                            {({
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                values,
                                errors,
                                touched,
                            }) => (
                                <>
                                    <div className="flex justify-between items-center mb-4 md:mb-6">
                                        <p className="text-xl font-semibold text-left">
                                            Edit Event
                                        </p>
                                        <div className="flex w-1/2 md:w-1/4 lg:pl-8 h-16">
                                            <button
                                                className="md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => history.push('/admin/events/')}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => handleSubmit()}
                                            >
                                                {isEditLoading ? <Loader /> : 'Save'}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="border-2 border-gray-300 px-6  pt-2 pb-8 mb-6">
                                        <div className="grid grid-cols-12 md:grid-cols-7 gap-4 md:gap-8">
                                            <div className="col-span-12 md:col-span-5 mb-2 md:mb-4">
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Event ID
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-3/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                            value={event?.eventId}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="eventName"
                                                        className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Event Name
                                                        <div className="text-red-600">*</div>
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-3/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                errors.eventName &&
                                                                touched.eventName
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="eventName"
                                                            type="text"
                                                            value={values.eventName}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'eventName',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                        {errors.eventName && touched.eventName ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.eventName}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="my-6 w-full md:mb-0 flex">
                                                    <label
                                                        className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Event Status
                                                        <div className="text-red-600">*</div>
                                                    </label>
                                                    <div className="flex text-sm md:text-base items-center w-full">
                                                        <input
                                                            className="mr-1"
                                                            type="radio"
                                                            value="ACTIVE"
                                                            id="active"
                                                            name="status"
                                                            checked={
                                                                values.eventStatus === 'ACTIVE'
                                                            }
                                                            onChange={handleChange('eventStatus')}
                                                        />
                                                        <label className="mr-4 md:mr-8">
                                                            Active
                                                        </label>
                                                        <input
                                                            className="mr-1"
                                                            type="radio"
                                                            value="INACTIVE"
                                                            id="inactive"
                                                            name="status"
                                                            checked={
                                                                values.eventStatus === 'INACTIVE'
                                                            }
                                                            onChange={handleChange('eventStatus')}
                                                        />
                                                        <label className="">Inactive</label>
                                                    </div>
                                                </div>
                                                <div className="my-6 w-full md:mb-0 flex">
                                                    <label
                                                        className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Event Type
                                                        <div className="text-red-600">*</div>
                                                    </label>
                                                    <div className="w-full flex">
                                                        <div
                                                            className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-3/5 md:w-3/5 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                                        >
                                                            <div
                                                                className="flex justify-between w-full"
                                                                onClick={() =>
                                                                    setDropdownVisible(true)
                                                                }
                                                            >
                                                                <div className="text-gray-700 font-medium pl-2">
                                                                    {category}
                                                                </div>
                                                                <div className="">
                                                                    <div className="flex flex-col">
                                                                        <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                        <HiIcons.HiChevronDown className="mr-2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {dropdownVisible ? (
                                                                <ul
                                                                    className="absolute w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() =>
                                                                        setDropdownVisible(false)
                                                                    }
                                                                >
                                                                    {categoryOptions.map(action => (
                                                                        <div
                                                                            className="p-2 hover:bg-gray-200 "
                                                                            key={action.id}
                                                                            onClick={() => {
                                                                                setCategory(
                                                                                    action.val
                                                                                );
                                                                                setFieldValue(
                                                                                    'eventType',
                                                                                    action.id === 0
                                                                                        ? 'VIRTUAL'
                                                                                        : 'PHYSICAL'
                                                                                );
                                                                                setDropdownVisible(
                                                                                    false
                                                                                );
                                                                            }}
                                                                        >
                                                                            {action.val}
                                                                        </div>
                                                                    ))}
                                                                </ul>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                {values.eventType === 'PHYSICAL' && (
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="location"
                                                            className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Address
                                                            <div className="text-red-600">*</div>
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-3/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.location &&
                                                                    touched.location
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="name"
                                                                type="text"
                                                                value={values.location}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'location',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.location && touched.location ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.location}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                )}

                                                {values.eventType === 'VIRTUAL' && (
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="eventLink"
                                                            className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Event Link
                                                            <div className="text-red-600">*</div>
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-3/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.eventLink &&
                                                                    touched.eventLink
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="eventLink"
                                                                type="text"
                                                                value={values.eventLink}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'eventLink',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.eventLink &&
                                                            touched.eventLink ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.eventLink}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="registration"
                                                        className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Registration Link
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-3/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                errors.registration &&
                                                                touched.registration
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="registration"
                                                            type="text"
                                                            value={values.registration}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'registration',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                        {errors.registration &&
                                                        touched.registration ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.registration}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="duration"
                                                        className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Duration
                                                        <div className="text-red-600">*</div>
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <DateTimePicker
                                                            startDate={
                                                                eventState?.event?.eventStartTime
                                                            }
                                                            endDate={
                                                                eventState?.event?.eventEndTime
                                                            }
                                                            setStartDate={setStartDate}
                                                            setEndDate={setEndDate}
                                                        />
                                                        {moment(startDate).isAfter(
                                                            moment(endDate)
                                                        ) && (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {
                                                                    'End date and time should be greater than start date and time'
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="my-6 w-full md:mb-0 flex h-32">
                                                    <label
                                                        htmlFor="eventDescription"
                                                        className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Description
                                                        <div className="text-red-600">*</div>
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <textarea
                                                            className={`resize-none focus:outline-none p-2 w-3/4 md:w-3/5 border-2 text-sm md:text-base h-32 focus:border-blue-900 ${
                                                                errors.eventDescription &&
                                                                touched.eventDescription
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            }`}
                                                            id="description"
                                                            value={values.eventDescription}
                                                            placeholder=""
                                                            onChange={handleChange(
                                                                'eventDescription'
                                                            )}
                                                        />
                                                        {errors.eventDescription &&
                                                        touched.eventDescription ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.eventDescription}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="my-6 w-full md:mb-0 flex">
                                                    <label
                                                        htmlFor="image"
                                                        className={`flex items-start w-1/5 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Event Image
                                                    </label>
                                                    <div
                                                        className={`bg-snow-600 w-3/4 md:w-3/5 mb-2 md:mb-4 relative ${
                                                            imageUrl ? 'w-fit' : ''
                                                        }`}
                                                    >
                                                        <div
                                                            className={`text-base border-dashed border-opacity-100 border-2 ${
                                                                errors.image && touched.image
                                                                    ? 'border-red-500'
                                                                    : 'border-black-600'
                                                            } justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64`}
                                                        >
                                                            {!imageUrl && (
                                                                <div className="w-full">
                                                                    <div className="flex justify-center">
                                                                        <Camera />
                                                                    </div>
                                                                    <div>
                                                                        <label
                                                                            className="underline cursor-pointer"
                                                                            htmlFor="image"
                                                                        >
                                                                            Upload Image
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {imageUrl && imageLocation && (
                                                                <img
                                                                    src={imageUrl}
                                                                    alt={'name'}
                                                                    className="h-44 lg:h-64"
                                                                    style={{ maxHeight: 300 }}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="absolute mt-1 text-gray-400 text-sm font-medium leading-149">
                                                            Image size 1520*422
                                                        </div>
                                                        <div
                                                            className="absolute mt-6 text-center"
                                                            style={{
                                                                left: '50%',
                                                                transform: 'translate(-50%,-50%)',
                                                            }}
                                                        >
                                                            {imageUrl && !isUploading && (
                                                                <div>
                                                                    <label
                                                                        className="underline cursor-pointer"
                                                                        htmlFor="image"
                                                                    >
                                                                        Change Image
                                                                    </label>
                                                                </div>
                                                            )}
                                                            {isUploading && (
                                                                <div className="flex items-center">
                                                                    <div className="mr-2">
                                                                        Uploading
                                                                    </div>
                                                                    <div>
                                                                        <Loader />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {touched.image ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.image}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <input
                                                        className={`focus:outline-none w-full focus:border-blue-900`}
                                                        id="image"
                                                        type="file"
                                                        accept="image/png, image/jpeg"
                                                        onChange={async e => {
                                                            const file = [];
                                                            file.push(e.target.files[0]);
                                                            setFieldValue('image', file);
                                                            handleImage(e);
                                                        }}
                                                        style={{ display: 'none' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                                <div className="flex border-2 flex-col ml-2 justify-items-center">
                                                    <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                                        <div className="flex sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                            <div>Assign Tags</div>
                                                            <div>
                                                                <HiIcons.HiQuestionMarkCircle
                                                                    onClick={() => {
                                                                        setTagsHelpText(true);
                                                                    }}
                                                                    className="ml-2 mt-1 cursor-pointer"
                                                                />
                                                            </div>
                                                        </div>
                                                        {tagsHelpText ? (
                                                            <div
                                                                onMouseOverCapture={() => {
                                                                    setTagsHelpText(true);
                                                                    // setProfileDropdownVisible(false);
                                                                }}
                                                                onMouseLeave={() =>
                                                                    setTagsHelpText(false)
                                                                }
                                                                className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-6 right-12"
                                                            >
                                                                Assign already created tags to the
                                                                new record created.
                                                            </div>
                                                        ) : null}
                                                        <div
                                                            className="sm:text-sm md:text-base text-gray-400 underline pr-2 cursor-pointer"
                                                            onClick={() => {
                                                                setTags([]);
                                                            }}
                                                        >
                                                            Clear All
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                                        <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                            <TagGroups
                                                                selectedTags={selectedTags}
                                                                tags={tags}
                                                                setTags={setTags}
                                                            />

                                                            <div
                                                                onMouseLeave={() => {
                                                                    setShowDropdown(false);
                                                                }}
                                                            >
                                                                <input
                                                                    className={`rounded w-full text-gray-500 font-medium focus:outline-none mt-2 p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                                                    id="id"
                                                                    type="text"
                                                                    placeholder="Search tags"
                                                                    onChange={e =>
                                                                        serchTagNameOrId(e)
                                                                    }
                                                                    onMouseEnter={() => {
                                                                        setShowDropdown(true);
                                                                    }}
                                                                    autoComplete="off"
                                                                />
                                                                {showDropdown && tagData?.length ? (
                                                                    <ul
                                                                        className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                        role="menu"
                                                                        onMouseLeave={() => {
                                                                            setShowDropdown(false);
                                                                        }}
                                                                    >
                                                                        {tagData?.map(tag => (
                                                                            <div
                                                                                className="p-2 hover:bg-gray-200 cursor-pointer"
                                                                                key={tag.id}
                                                                                onClick={() => {
                                                                                    if (
                                                                                        tags
                                                                                            .map(
                                                                                                checktag =>
                                                                                                    checktag.tagName
                                                                                            )
                                                                                            .includes(
                                                                                                tag.tagName
                                                                                            ) ===
                                                                                        false
                                                                                    ) {
                                                                                        setTags([
                                                                                            ...tags,
                                                                                            tag,
                                                                                        ]);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {tag.tagCategory +
                                                                                    ': ' +
                                                                                    tag.tagName}
                                                                            </div>
                                                                        ))}
                                                                    </ul>
                                                                ) : showDropdown &&
                                                                  tagData.length === 0 ? (
                                                                    <ul
                                                                        className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                        role="menu"
                                                                        onMouseLeave={() =>
                                                                            setShowDropdown(false)
                                                                        }
                                                                    >
                                                                        <div className="p-2 flex justify-center font-medium text-gray-400">
                                                                            No records found
                                                                        </div>
                                                                    </ul>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=" ml-2 justify-items-center">
                                                    <div className="flex flex-col text-sm pl-2 lg:pl-4 mt-6 space-y-4 text-gray-400 ">
                                                        <div>
                                                            Created tags can be retrieved by typing
                                                            the text in the search bar.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {event?.eventStatus !== 'ACTIVE' && (
                                            <div className="my-6 md:mb-0 flex justify-end">
                                                <button
                                                    type="submit"
                                                    className="sm:py-1 md:py-3 ml-2 lg:ml-8 hover:bg-red-500 text-red-500 hover:text-white border-2 font-medium border-red-600 border-opacity-40 text-sm rounded-sm w-1/2 md:w-1/6 my-2 focus:outline-none"
                                                    onClick={() =>
                                                        setDeleteConfirmationVisible(true)
                                                    }
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    {deleteConfirmationVisible ? (
                                        <DeleteConfirmationModal
                                            modalVisible={deleteConfirmationVisible}
                                            setModalVisible={setDeleteConfirmationVisible}
                                            eventId={event?.id}
                                        />
                                    ) : null}
                                    {(eventUpdateSuccessMessage ||
                                        editEventError ||
                                        deleteEventError ||
                                        eventDeleteSuccessMessage) && (
                                        <EventsSuccessModal
                                            modalVisible={editSuccessVisible}
                                            handleClose={handleClose}
                                        />
                                    )}
                                    <CropModal
                                        ratio={3.6}
                                        photoURL={imageUrl}
                                        setPhotoURL={setImageUrl}
                                        setOpenCrop={setOpenCrop}
                                        openCrop={openCrop}
                                        handleCloseCropModal={handleCloseCropModal}
                                        uploadImageToS3={uploadImageToS3}
                                    />
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
