import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../../../assets/svg/Loader';
import { RESET_SEND_MOBILE_VERIFICATION, verifyMobileNumber } from '../../../../redux/rootActions';
import { RootState } from '../../../../redux/rootReducer';
import { USER_PROFILE } from '../../../utils/storageWeb';
import ChangeMobileResponseModal from './ChangeMobileReponseModal';
import * as Yup from 'yup';

const ChangeMobileFeedback = props => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { phoneNumber } = (props.location && props.location.state) || {};
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const settingsURL = `/dashBoard/settings`;
    const regx = /^[0-9\b|-]+$/;

    const confirmMobileVerificationSuccess = useSelector(
        (state: RootState) => state.webUser.confirmMobileVerificationSuccess
    );

    const confirmMobileVerificationFailed = useSelector(
        (state: RootState) => state.webUser.confirmMobileVerificationError
    );

    const confirmMobileVerificationLoading = useSelector(
        (state: RootState) => state.webUser.confirmMobileVerificationLoading
    );

    const [otpCode, setOtpCode] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');

    const handleOtpCodeInput = e => {
        if (!regx.test(e.target.value)) return;
        if (e.target.value.length < 7) {
            const newValue = e.target.value + '-';
            setOtpCode(newValue);
        } else if (e.target.value.length === 7) {
            setOtpCode(e.target.value);
        }
    };

    const handleKeyDown = e => {
        if (!regx.test(e.target.value)) return;
        if (e.code === 'Backspace' || e.code === 'Delete') {
            setOtpCode('');
        }
    };

    const getNumericOtp = (otpNumber: string) => {
        return otpNumber.replace(/-/g, '');
    };

    useEffect(() => {
        if (confirmMobileVerificationSuccess) {
            setMessage('Your phone number has been successfully updated!');
            setShowModal(true);
        }
    }, [confirmMobileVerificationSuccess]);

    useEffect(() => {
        if (confirmMobileVerificationFailed) {
            setMessage('Your phone number update failed');
            setShowModal(true);
        }
    }, [confirmMobileVerificationFailed]);

    useEffect(() => {
        dispatch({ type: RESET_SEND_MOBILE_VERIFICATION });
    }, [dispatch]);

    return (
        <>
            <div className="flex w-full h-screen items-center justify-center">
                <ChangeMobileResponseModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    message={message}
                />
                <div className="flex flex-col p-4 w-full lg:w-1/3">
                    <div className="flex text-lg lg:text-2xl font-semibold justify-center">
                        Check your SMS
                    </div>
                    <div className="flex mt-4 text-center lg:text-left">
                        There is a pending change on your phone number. We have sent a code to your
                        new phone number,
                    </div>
                    <div className="flex text-purple-950">{`"${phoneNumber || ''}"`}</div>
                    <div className="flex">
                        <Formik
                            initialValues={{ otpNumber: '' }}
                            onSubmit={async () => {
                                dispatch(
                                    verifyMobileNumber(
                                        getNumericOtp(otpCode),
                                        phoneNumber,
                                        profile?.userId
                                    )
                                );
                            }}
                            validationSchema={Yup.object({
                                otpNumber: Yup.string()
                                    .max(7, 'Invalid Otp')
                                    .required('OTP code required'),
                            })}
                        >
                            {({ handleSubmit, handleChange, errors, touched }) => (
                                <div className="flex flex-col mt-7 w-full">
                                    <div className="mt-3 flex flex-col">
                                        <label className="font-manrope text-sm" htmlFor="otpNumber">
                                            Enter code
                                        </label>
                                        <input
                                            className="inputFieldThickness pl-2 py-1 h-12"
                                            type="text"
                                            id="otpNumber"
                                            placeholder="Enter code"
                                            value={otpCode}
                                            onChange={e => {
                                                handleChange(e);
                                                handleOtpCodeInput(e);
                                            }}
                                            onKeyDown={e => handleKeyDown(e)}
                                        />
                                        {errors.otpNumber && touched.otpNumber ? (
                                            <div className="text-red-500 text-xs my-1">
                                                {errors.otpNumber}
                                            </div>
                                        ) : null}
                                    </div>
                                    <button
                                        type="button"
                                        disabled={otpCode === null}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                        className={`mt-6 rounded border-2  font-semibold h-10 justify-center items-center flex text-sm ${
                                            otpCode === null
                                                ? 'text-white border-gray-400 bg-gray-400 cursor-default'
                                                : 'text-white bg-purple-950 cursor-pointer border-purple-950'
                                        }`}
                                    >
                                        {confirmMobileVerificationLoading ? (
                                            <Loader />
                                        ) : (
                                            'Change phone number'
                                        )}
                                    </button>
                                </div>
                            )}
                        </Formik>
                    </div>
                    <div
                        role={'presentation'}
                        className="flex mt-4 text-purple-950 justify-center cursor-pointer"
                        onClick={() =>
                            history.push({
                                pathname: settingsURL,
                                state: {
                                    selectedTab: 'security',
                                },
                            })
                        }
                    >
                        Return to Account Security
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangeMobileFeedback;
