import React, { ReactElement } from 'react';
import { CountType } from '../../../redux/students/reducers';

interface Props {
    selectedTab: number;
    setSelectedTab: (value: number) => void;
    tabCounts: CountType;
}

export default function TableTabView({
    selectedTab,
    setSelectedTab,
    tabCounts,
}: Props): ReactElement {
    return (
        <div className="flex flex-row">
            <div
                className={`${
                    selectedTab === 1
                        ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                        : 'text-black-300 border-snow-500'
                } flex text-sm md:text-base xl:text-lg cursor-pointer justify-center w-40`}
                onClick={() => {
                    setSelectedTab(1);
                }}
            >
                Active
                <div
                    className="rounded-full bg-gray-200 text-center ml-1 lg:ml-2 h-6 text-blue-800 text-sm px-1"
                    style={{
                        minWidth: '24px',
                        paddingTop: '1px',
                        paddingBottom: '1px',
                    }}
                >
                    {tabCounts?.ACTIVE}
                </div>
            </div>
            <div
                className={`${
                    selectedTab === 2
                        ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                        : 'text-black-300 border-snow-500'
                } flex text-sm md:text-base xl:text-lg cursor-pointer justify-center w-40`}
                onClick={() => {
                    setSelectedTab(2);
                }}
            >
                Deactivated
                <div
                    className="rounded-full bg-gray-200 text-center ml-1 lg:ml-2 h-6 text-blue-800 text-sm px-1"
                    style={{
                        minWidth: '24px',
                        paddingTop: '1px',
                        paddingBottom: '1px',
                    }}
                >
                    {tabCounts?.DEACTIVATED}
                </div>
            </div>
            <div
                className={`${
                    selectedTab === 3
                        ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                        : 'text-black-300 border-snow-500'
                } flex text-sm md:text-base xl:text-lg cursor-pointer justify-center w-40`}
                onClick={() => {
                    setSelectedTab(3);
                }}
            >
                Deleted
                <div
                    className="rounded-full bg-gray-200 text-center ml-1 lg:ml-2 h-6 text-blue-800 text-sm px-1"
                    style={{
                        minWidth: '24px',
                        paddingTop: '1px',
                        paddingBottom: '1px',
                    }}
                >
                    {tabCounts?.DELETED}
                </div>
            </div>
            <div
                className={`${
                    selectedTab === 4
                        ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                        : 'text-black-300 border-snow-500'
                } flex text-sm md:text-base xl:text-lg cursor-pointer justify-center w-40`}
                onClick={() => {
                    setSelectedTab(4);
                }}
            >
                All
                <div
                    className="rounded-full bg-gray-200 text-center ml-1 lg:ml-2 h-6 text-blue-800 text-sm px-1"
                    style={{
                        minWidth: '24px',
                        paddingTop: '1px',
                        paddingBottom: '1px',
                    }}
                >
                    {tabCounts?.ALL}
                </div>
            </div>
        </div>
    );
}
