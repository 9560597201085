import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../../../redux/rootReducer';
import { getBadges, getRecommandedTracks } from '../../../../redux/rootActions';
import { USER_PROFILE } from '../../../utils/storageWeb';
import { s3UrlPrefix } from '../../../../constants';
import BackArrow from '../../../../assets/svg/BackArrow';

export default function RecommandedTracks(): ReactElement {
    const history = useHistory();
    const dispatch = useDispatch();

    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));

    const recommandedTrackData = useSelector(
        (state: RootState) => state.tracks.recommandedTrackData
    );
    const isAllTrackLoading = useSelector((state: RootState) => state.tracks.isAllTrackLoading);

    const [tagHeight, setTagHeight] = useState<any>();

    useEffect(() => {
        dispatch(getRecommandedTracks(profile?.userId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        dispatch(getBadges());
    }, [dispatch]);

    useEffect(() => {
        if (!isAllTrackLoading) {
            setTagHeight(document?.getElementById('tagHeight')?.offsetWidth);
        }
    }, [isAllTrackLoading]);

    return (
        <div className="px-4 lg:px-0 lg:mx-10 md:ml-14 lg:ml-72 lg:pl-3.5 lg:pt-2 mt-6 mb-4">
            <div className="flex cursor-pointer" onClick={() => history.push(`/dashBoard/learn`)}>
                <BackArrow size="27" />
                <p className="ml-2">Back</p>
            </div>
            <div className="flex flex-col justify-between mt-3 mb-6">
                <div className="font-bold text-xl font-manrope">Recommended Tracks</div>
                <div className="mt-2">
                    Explore the areas of interest and find the right tracks for you.
                </div>
            </div>
            <div className="trackCardContainer">
                {recommandedTrackData?.map(track => (
                    <div key={track.id} className="trackCard rounded-lg">
                        <img
                            className="trackCardImage rounded-t-lg"
                            src={
                                track.imageLink
                                    ? `${s3UrlPrefix}${track.imageLink}`
                                    : `/assets/challenge-image-skeleton-rectangle.png`
                            }
                        />
                        <div className="ml-4 mt-6 pb-3">
                            <div className="font-bold font-manrope text-xl mb-3 h-7 overflow-hidden">
                                {track.trackName?.length < 19
                                    ? track.trackName
                                    : track.trackName?.substring(0, 19) + '...'}
                            </div>

                            <div className="flex flex-wrap h-8 overflow-hidden">
                                {track.tags?.map(tag => (
                                    <div
                                        key={tag.id}
                                        className="mr-2 mt-2 bg-purple-200 text-purple-950 text-xs rounded-full px-3 py-1 w-max"
                                    >
                                        {tag.tagName}
                                    </div>
                                ))}
                            </div>
                            <div className="my-3">
                                <p className="challengeDescription lg:h-16 max-h-16">
                                    {track.trackDescription}
                                </p>
                            </div>
                            <button
                                onClick={() => {
                                    history.push({
                                        pathname: `/dashBoard/learn/RecommandedChallenges`,
                                        state: { tracks: track },
                                    });
                                }}
                                className="border-2 border-purple-950 text-purple-950 rounded font-semibold h-10 px-6 xl:px-12 ml-1"
                            >
                                Get Started
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
