import React, { ReactElement, useEffect, useState } from 'react';
import moment from 'moment';
import * as HiIcons from 'react-icons/hi';
import { accountType, months } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { getTracks, getInstitutes } from '../../../redux/rootActions';

interface Props {
    studentLessThan: string;
    setStudentLessThan: (value: string) => void;
    studentMoreThan: string;
    setStudentMoreThan: (value: string) => void;
    accountType: string;
    setAccountType: (value: string) => void;
    startDate: Date;
    setStartDate: (value: Date) => void;
    endDAte: Date;
    setEndDate: (value: Date) => void;
    joinStartDate: Date;
    setJoinStartDate: (value: Date) => void;
    joinEndDAte: Date;
    setJoinEndDate: (value: Date) => void;
    tags: any[];
    setTags: (value: any[]) => void;
    program: any[];
    setProgram: (value: any[]) => void;
    challenges: any[];
    setChallenges: (value: any[]) => void;
    tracks: any[];
    setTracks: (value: any[]) => void;
    institue: string;
    setInstitue: (value: string) => void;
    homeTown: string;
    setHomeTown: (value: string) => void;
}

export default function AddTabModal({
    setAccountType,
    setStartDate,
    setEndDate,
    setJoinStartDate,
    setJoinEndDate,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const [trackFilterAction, setTrackFilterAction] = useState<string>('Select a column');

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const month = months[new Date().getMonth()];
    const day = new Date().getDate();
    const year = new Date().getFullYear();

    const EndMonth = months[new Date().getMonth()];
    const EndDay = new Date().getDate();
    const EndYear = new Date().getFullYear();

    const date = new Date(year, new Date().getMonth(), 1);
    const days = [];
    while (date.getMonth() === new Date().getMonth()) {
        days.push(new Date(date).getDate());
        date.setDate(date.getDate() + 1);
    }

    const secondDate = new Date(year, months.indexOf(EndMonth), 1);
    const Enddays = [];
    while (secondDate.getMonth() === months.indexOf(EndMonth)) {
        Enddays.push(new Date(secondDate).getDate());
        secondDate.setDate(secondDate.getDate() + 1);
    }

    const years = [];
    let startYear = new Date().getFullYear();
    const maxYear = startYear + 10;
    for (let i = startYear; i <= maxYear; i++) {
        years.push(startYear);
        startYear++;
    }

    // Join date states
    const [JoinmonthDropdown, setJoinMonthDropdown] = useState(false);
    const [JoindateDropdown, setJoinDateDropdown] = useState(false);
    const [JoinyearDropdown, setJoinYearDropdown] = useState(false);

    const [Joinmonth, setJoinMonth] = useState<string>(months[new Date().getMonth()]);
    const [Joinday, setJoinday] = useState(new Date().getDate());
    const [Joinyear, setJoinYear] = useState<number>(new Date().getFullYear());

    const [JoinEndMonthDropdown, setJoinEndMonthDropdown] = useState(false);
    const [JoinEndDateDropdown, setJoinEndDateDropdown] = useState(false);
    const [JoinEndYearDropdown, setJoinEndYearDropdown] = useState(false);
    const [JoinEndMonth, setJoinEndMonth] = useState<string>(months[new Date().getMonth()]);
    const [JoinEndDay, setJoinEndDay] = useState(new Date().getDate());
    const [JoinEndYear, setJoinEndYear] = useState<number>(new Date().getFullYear());

    const Joindate = new Date(year, new Date().getMonth(), 1);
    const Joindays = [];
    while (Joindate.getMonth() === new Date().getMonth()) {
        Joindays.push(new Date(Joindate).getDate());
        Joindate.setDate(Joindate.getDate() + 1);
    }

    const JoinsecondDate = new Date(year, months.indexOf(JoinEndMonth), 1);
    const JoinEnddays = [];
    while (JoinsecondDate.getMonth() === months.indexOf(JoinEndMonth)) {
        JoinEnddays.push(new Date(JoinsecondDate).getDate());
        JoinsecondDate.setDate(JoinsecondDate.getDate() + 1);
    }

    const Joinyears = [];
    let JoinstartYear = new Date().getFullYear();
    const JoinmaxYear = startYear + 10;
    for (let i = startYear; i <= JoinmaxYear; i++) {
        Joinyears.push(JoinstartYear);
        JoinstartYear++;
    }

    const TrackFilter = [
        { id: 0, val: 'Joined Date' },
        { id: 1, val: 'Account Type' },
    ];

    useEffect(() => {
        const newdate = new Date(year, months.indexOf(month), day);
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);

        if (
            moment(newdate)?.format('YYYY-MM-DD')?.toString() !==
            moment(todayDate)?.format('YYYY-MM-DD')?.toString()
        ) {
            setStartDate(newdate);
        }

        const newEnddate = new Date(EndYear, months.indexOf(EndMonth), EndDay);
        if (
            moment(newEnddate)?.format('YYYY-MM-DD')?.toString() !==
            moment(todayDate)?.format('YYYY-MM-DD')?.toString()
        ) {
            setEndDate(newEnddate);
        }

        const joinnewdate = new Date(Joinyear, months.indexOf(Joinmonth), Joinday);
        if (
            moment(joinnewdate)?.format('YYYY-MM-DD')?.toString() !==
            moment(todayDate)?.format('YYYY-MM-DD')?.toString()
        ) {
            setJoinStartDate(joinnewdate);
        }

        const joinnewEnddate = new Date(JoinEndYear, months.indexOf(JoinEndMonth), JoinEndDay);
        if (
            moment(joinnewEnddate)?.format('YYYY-MM-DD')?.toString() !==
            moment(todayDate)?.format('YYYY-MM-DD')?.toString()
        ) {
            setJoinEndDate(joinnewEnddate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        EndDay,
        EndMonth,
        EndYear,
        day,
        month,
        year,
        JoinEndDay,
        JoinEndMonth,
        JoinEndYear,
        Joinday,
        Joinmonth,
        Joinyear,
    ]);

    useEffect(() => {
        dispatch(getTracks(true));
        dispatch(getInstitutes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={`flex flex-col my-1.5 ${dropdownVisible && 'h-40'}`}>
                <div className="flex flex-col my-1.5">Select filter column</div>
                <div
                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                >
                    <div
                        className="flex justify-between w-full"
                        onClick={() => setDropdownVisible(true)}
                    >
                        <div className="text-gray-700 font-medium pl-2">{trackFilterAction}</div>
                        <div className="">
                            <div className="flex flex-col">
                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                <HiIcons.HiChevronDown className="mr-2" />
                            </div>
                        </div>
                    </div>
                    {dropdownVisible ? (
                        <ul
                            className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            onMouseLeave={() => setDropdownVisible(false)}
                        >
                            {TrackFilter.map(action => (
                                <div
                                    className="p-2 pl-2 hover:bg-gray-200 "
                                    key={action.id}
                                    onClick={() => {
                                        setTrackFilterAction(action.val);
                                        setDropdownVisible(false);
                                    }}
                                >
                                    {action.val}
                                </div>
                            ))}
                        </ul>
                    ) : null}
                </div>
            </div>

            {trackFilterAction === 'Joined Date' ? (
                <>
                    <div className="flex flex-col my-1.5 ">
                        <label className="my-1">Choose the date start and end</label>
                        <label className="my-1">Start Date</label>
                        <div className="flex flex-row">
                            <label className="mr-1 flex items-center">Date</label>
                            <div className="flex flex-col my-1.5 h-2 mx-1">
                                <div
                                    className={`rounded flex relative cursor-pointer items-center focus:outline-none w-full  md:px-2 md:py-1 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className="flex justify-between w-full"
                                        onClick={() => setJoinDateDropdown(true)}
                                    >
                                        <div className="text-gray-700 font-medium text-center pr-1">
                                            {Joinday}
                                        </div>
                                        <div className="">
                                            <div className="flex flex-col ">
                                                <HiIcons.HiChevronUp className=" -mb-2" />
                                                <HiIcons.HiChevronDown className="" />
                                            </div>
                                        </div>
                                    </div>
                                    {JoindateDropdown ? (
                                        <ul
                                            className="overflow-y-auto max-h-28 absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setJoinDateDropdown(false)}
                                        >
                                            {Joindays.map(action => (
                                                <div
                                                    className="p-2 hover:bg-gray-200 "
                                                    key={action}
                                                    onClick={() => {
                                                        setJoinday(action);
                                                        setJoinDateDropdown(false);
                                                    }}
                                                >
                                                    {action}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>

                            <label className="mx-1 flex items-center">Month</label>
                            <div className="flex flex-col my-1.5 mx-1">
                                <div
                                    className={`rounded flex relative cursor-pointer items-center focus:outline-none w-full md:px-2 md:py-1 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className="flex justify-between w-full"
                                        onClick={() => setJoinMonthDropdown(true)}
                                    >
                                        <div className="text-gray-700 font-medium pr-1">
                                            {Joinmonth}
                                        </div>
                                        <div className="">
                                            <div className="flex flex-col ">
                                                <HiIcons.HiChevronUp className=" -mb-2" />
                                                <HiIcons.HiChevronDown className="" />
                                            </div>
                                        </div>
                                    </div>
                                    {JoinmonthDropdown ? (
                                        <ul
                                            className="overflow-y-auto max-h-28 absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setJoinMonthDropdown(false)}
                                        >
                                            {moment.months().map(action => (
                                                <div
                                                    className="p-2 hover:bg-gray-200 "
                                                    key={action}
                                                    onClick={() => {
                                                        setJoinMonth(action);
                                                        setJoinMonthDropdown(false);
                                                    }}
                                                >
                                                    {action}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>

                            <label className="mx-1 flex items-center">Year</label>
                            <div className="flex flex-col my-1.5 mx-1">
                                <div
                                    className={`rounded flex relative cursor-pointer items-center focus:outline-none w-full  md:px-2 md:py-1 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className="flex justify-between w-full"
                                        onClick={() => setJoinYearDropdown(true)}
                                    >
                                        <div className="text-gray-700 font-medium pr-1">
                                            {Joinyear}
                                        </div>
                                        <div className="">
                                            <div className="flex flex-col">
                                                <HiIcons.HiChevronUp className="-mb-2" />
                                                <HiIcons.HiChevronDown className="" />
                                            </div>
                                        </div>
                                    </div>
                                    {JoinyearDropdown ? (
                                        <ul
                                            className="overflow-y-auto max-h-28 absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setJoinYearDropdown(false)}
                                        >
                                            {Joinyears.map(action => (
                                                <div
                                                    className="p-2 hover:bg-gray-200 "
                                                    key={action}
                                                    onClick={() => {
                                                        setJoinYear(action);
                                                        setJoinYearDropdown(false);
                                                    }}
                                                >
                                                    {action}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <label className="my-1">End Date</label>
                        <div className="flex flex-row">
                            <label className="mr-1 flex items-center">Date</label>
                            <div className="flex flex-col my-1.5 h-2 mx-1">
                                <div
                                    className={`rounded flex relative cursor-pointer items-center focus:outline-none w-full  md:px-2 md:py-1 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className="flex justify-between w-full"
                                        onClick={() => setJoinEndDateDropdown(true)}
                                    >
                                        <div className="text-gray-700 font-medium text-center pr-1">
                                            {JoinEndDay}
                                        </div>
                                        <div className="">
                                            <div className="flex flex-col ">
                                                <HiIcons.HiChevronUp className=" -mb-2" />
                                                <HiIcons.HiChevronDown className="" />
                                            </div>
                                        </div>
                                    </div>
                                    {JoinEndDateDropdown ? (
                                        <ul
                                            className="overflow-y-auto max-h-28 absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setJoinEndDateDropdown(false)}
                                        >
                                            {JoinEnddays.map(action => (
                                                <div
                                                    className="p-2 hover:bg-gray-200 "
                                                    key={action}
                                                    onClick={() => {
                                                        setJoinEndDay(action);
                                                        setJoinEndDateDropdown(false);
                                                    }}
                                                >
                                                    {action}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>

                            <label className="mx-1 flex items-center">Month</label>
                            <div className="flex flex-col my-1.5 mx-1">
                                <div
                                    className={`rounded flex relative cursor-pointer items-center focus:outline-none w-full md:px-2 md:py-1 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className="flex justify-between w-full"
                                        onClick={() => setJoinEndMonthDropdown(true)}
                                    >
                                        <div className="text-gray-700 font-medium pr-1">
                                            {JoinEndMonth}
                                        </div>
                                        <div className="">
                                            <div className="flex flex-col ">
                                                <HiIcons.HiChevronUp className=" -mb-2" />
                                                <HiIcons.HiChevronDown className="" />
                                            </div>
                                        </div>
                                    </div>
                                    {JoinEndMonthDropdown ? (
                                        <ul
                                            className="overflow-y-auto max-h-28 absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setJoinEndMonthDropdown(false)}
                                        >
                                            {moment.months().map(action => (
                                                <div
                                                    className="p-2 hover:bg-gray-200 "
                                                    key={action}
                                                    onClick={() => {
                                                        setJoinEndMonth(action);
                                                        setJoinEndMonthDropdown(false);
                                                    }}
                                                >
                                                    {action}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>

                            <label className="mx-1 flex items-center">Year</label>
                            <div className="flex flex-col my-1.5 mx-1">
                                <div
                                    className={`rounded flex relative cursor-pointer items-center focus:outline-none w-full  md:px-2 md:py-1 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className="flex justify-between w-full"
                                        onClick={() => setJoinEndYearDropdown(true)}
                                    >
                                        <div className="text-gray-700 font-medium pr-1">
                                            {JoinEndYear}
                                        </div>
                                        <div className="">
                                            <div className="flex flex-col">
                                                <HiIcons.HiChevronUp className="-mb-2" />
                                                <HiIcons.HiChevronDown className="" />
                                            </div>
                                        </div>
                                    </div>
                                    {JoinEndYearDropdown ? (
                                        <ul
                                            className="overflow-y-auto max-h-28 absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setJoinEndYearDropdown(false)}
                                        >
                                            {Joinyears.map(action => (
                                                <div
                                                    className="p-2 hover:bg-gray-200 "
                                                    key={action}
                                                    onClick={() => {
                                                        setJoinEndYear(action);
                                                        setJoinEndYearDropdown(false);
                                                    }}
                                                >
                                                    {action}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
            {trackFilterAction === 'Account Type' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select the desired Account Type</label>
                        <div className="my-1">
                            <label>
                                <input
                                    className="mr-1"
                                    type="radio"
                                    name="checkbox"
                                    value={accountType.PREMIUM_CORPORATE}
                                    onClick={e => {
                                        setAccountType(e.currentTarget.value);
                                    }}
                                />
                                PREMIUM CORPORATE
                            </label>
                        </div>
                        <div className="my-1">
                            <label>
                                <input
                                    className="mr-1"
                                    type="radio"
                                    name="checkbox"
                                    value={accountType.CORPORATE}
                                    onClick={e => {
                                        setAccountType(e.currentTarget.value);
                                    }}
                                />
                                CORPORATE
                            </label>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
}
