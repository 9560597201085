import {
    clearAdminLocalStorage,
    getRefreshToken as getRefreshTokenAdmin,
    setAccessToken as setAccessTokenAdmin,
} from './storage';
import { setAccessToken, getRefreshToken } from '../web/utils/storageWeb';
import axios from 'axios';
import { API_URL } from '../constants';

const refreshTokenFunc = async () => {
    const refreshToken = window.location.pathname.includes('/admin')
        ? getRefreshTokenAdmin()
        : getRefreshToken();

    try {
        const response = fetch(`${API_URL}/users/refreshToken`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                refreshToken: refreshToken,
            }),
        })
            .then(res => res.json())
            .then(res => {
                window.location.pathname.includes('/admin')
                    ? setAccessTokenAdmin(res.responseDto?.accessToken)
                    : setAccessToken(res.responseDto?.accessToken);
                return res.responseDto;
            });
        return response;
    } catch (error) {
        clearAdminLocalStorage();
    }
};

export function axiosResponseInterceptor() {
    axios.interceptors.response.use(
        response => response,
        async error => {
            const config = error?.config;
            if (error.response && error?.response?.status === 401 && !config?._retry) {
                config._retry = true;

                const result = await refreshTokenFunc();

                if (result?.accessToken) {
                    config.headers = {
                        ...config.headers,
                        authorization: `Bearer ${result?.accessToken}`,
                    };
                }

                return axios(config);
            }
            return Promise.reject(error);
        }
    );
}
