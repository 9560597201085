import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import * as HiIcons from 'react-icons/hi';
import {
    clearNotificationsMessages,
    getInstitutes,
    getNotificationTemplates,
    getTags,
    getUsersToNotify,
    saveNotificationTemplate,
    sendNotification,
} from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { useHistory } from 'react-router-dom';
import ReactSelect, { components } from 'react-select';
import NotificationsSuccessModal from '../../modals/NotificationsSuccessModal';
import NotificationTemplateSaveFeedback from '../../modals/NotificationTemplateSaveFeedbackModal';
import NotificationTemplateSuccessModal from '../../modals/NotificationTemplateSuccessModal';
import { moduleNames } from '../../../constants';
import SendNotificationsTable from './SendNotificationsTable';
import { LoadingIndicator } from 'react-select/dist/declarations/src/components/indicators';
export const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};
export default function SendNotifications() {
    const formRef = useRef<any>();
    const dispatch = useDispatch();
    const history = useHistory();
    const isCreateLoading = useSelector((state: RootState) => state.notifications.isCreateLoading);
    const users = useSelector((state: RootState) => state.notifications.notifyUsers);

    const createError = useSelector(
        (state: RootState) => state.notifications.createNotificationError
    );
    const createSuccessMessage = useSelector(
        (state: RootState) => state.notifications.notificationCreateSuccessMessage
    );
    const institutesData = useSelector((state: RootState) => state.institutes.institutesData);
    const tagsData = useSelector((state: RootState) => state.tags.tagData);

    const notificationTemplateData = useSelector(
        (state: RootState) => state.notifications.notificationTemplateData
    );
    const createNotificationTemplateSuccess = useSelector(
        (state: RootState) => state.notifications.createNotificationTemplateSuccessMessage
    );

    const createNotificationTemplateError = useSelector(
        (state: RootState) => state.notifications.createNotificationTemplateError
    );

    const [createSuccessVisible, setCreateSuccessVisible] = useState(false);
    const [onClickOkBtn, setOnClickOkBtn] = useState(false);
    const [showSaveNotificationTemplate, setShowSaveNotificationTemplate] = useState(false);
    const [onClickNoBtn, setOnClickNoBtn] = useState(false);
    const [onClickYesBtn, setOnClickYesBtn] = useState(false);
    const [showNotificationTemplateSuccess, setShowNotificationTemplateSuccess] = useState(false);
    const [category, setCategory] = useState<any>({ id: 0, val: 'Custom' });
    const [selectedInstitutes, setSelectedInstitutes] = useState<any>();
    const [selectedTags, setSelectedTags] = useState<any>();
    const [templateTitle, setTemplateTitle] = useState('');
    const [templateTypeList, setTemplateTypeList] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSizeS, setPageSizeS] = useState(10);
    const [emailHelpText, setEmailHelpText] = useState<boolean>(false);
    const initialRender = useRef(true);
    const columns = useMemo(
        () => [
            {
                Header: 'Student ID',
                accessor: 'id',
            },
            {
                Header: 'Student Name',
                accessor: 'firstName',
            },
            {
                Header: 'Email',
                accessor: 'emailId',
            },
            {
                Header: 'Mobile',
                accessor: 'mobileNo',
            },
        ],
        []
    );

    const style = {
        control: base => ({
            ...base,
            '&:hover': { borderColor: '#1e3a8a', opacity: 1 },
            border: '2px solid rgba(209, 213, 219, 1)',
            borderRadius: '0.125rem',
            boxShadow: 'none',
        }),
        option: (styles, { isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused
                    ? '#e5e7eb'
                    : isSelected
                    ? '#e5e7eb'
                    : isDisabled
                    ? '#d1d5db'
                    : '#ffffff',
                color: '#333333',
            };
        },
    };

    const instituteArray = institutesData?.map(elem => {
        return {
            value: elem.id,
            label: elem.instName,
        };
    });

    const tagsArray = tagsData?.map(elem => {
        return {
            value: elem.id,
            // label: elem.tagName,
            label: elem.tagCategory + ': ' + elem.tagName,
        };
    });

    const handleChangeInstitutes = selected => {
        setSelectedInstitutes(selected);
    };

    const handleChangeTags = selected => {
        setSelectedTags(selected);
    };

    const handleNotificationType = selected => {
        setCategory(selected);
    };

    const handleSaveNotificationTemplate = useCallback(() => {
        if (formRef.current) {
            const formValues = formRef.current.values;
            if (formValues) {
                const templateData = {
                    sentTypeList: formValues.sentTypeList,
                    notificationTitle: formValues.notificationTitle,
                    campaignTitle: formValues.title,
                    content: formValues.content,
                };

                dispatch(saveNotificationTemplate(templateData));
            }
        }
    }, [dispatch, formRef]);

    useEffect(() => {
        dispatch(getUsersToNotify(0, 10, {}));
        dispatch(getInstitutes(0, 100));
        dispatch(getTags(true, moduleNames.Student));
        dispatch(getNotificationTemplates(''));
    }, [dispatch]);

    const fetchData = React.useCallback(
        ({ pageIndex, pageSize }) => {
            setPage(pageIndex);
            setPageSizeS(pageSize);
            dispatch(
                getUsersToNotify(pageIndex, pageSize, {
                    institutes: selectedInstitutes?.map(elem => {
                        return elem.value;
                    }),
                    tags: selectedTags?.map(elem => {
                        return elem.value;
                    }),
                })
            );
        },
        [dispatch]
    );

    useEffect(() => {
        if (selectedInstitutes || selectedTags) {
            setPage(0);
            dispatch(
                getUsersToNotify(page, pageSizeS, {
                    institutes: selectedInstitutes?.map(elem => {
                        return elem.value;
                    }),
                    tags: selectedTags?.map(elem => {
                        return elem.value;
                    }),
                })
            );
        }
    }, [dispatch, selectedInstitutes, selectedTags]);

    useEffect(() => {
        if (createSuccessMessage || createError) {
            setCreateSuccessVisible(true);
        }
    }, [createError, createSuccessMessage]);

    useEffect(() => {
        if (onClickOkBtn) {
            setShowSaveNotificationTemplate(true);
        }
    }, [onClickOkBtn]);

    useEffect(() => {
        if (onClickYesBtn) {
            handleSaveNotificationTemplate();
        }
    }, [handleSaveNotificationTemplate, onClickYesBtn]);

    useEffect(() => {
        if (onClickNoBtn) {
            setTimeout(() => {
                dispatch(clearNotificationsMessages());
                if (!createError) {
                    history.push('/admin/notifications');
                }
            }, 3000);
        }
    }, [createError, dispatch, history, onClickNoBtn]);

    useEffect(() => {
        if (createNotificationTemplateError || createNotificationTemplateSuccess) {
            setShowNotificationTemplateSuccess(true);
            setTimeout(() => {
                dispatch(clearNotificationsMessages());
                if (!createError || !createNotificationTemplateError) {
                    history.push('/admin/notifications');
                }
            }, 3000);
        }
    }, [
        createError,
        createNotificationTemplateError,
        createNotificationTemplateSuccess,
        dispatch,
        history,
    ]);

    useEffect(() => {
        const categoryOptions = [{ value: 0, label: 'Custom' }];
        if (notificationTemplateData) {
            notificationTemplateData?.forEach(template => {
                const option = {
                    value: template?.id,
                    label: template?.notificationTitle,
                };
                categoryOptions.push(option);
            });
            setTemplateTypeList(categoryOptions);
        }
    }, [notificationTemplateData]);

    useEffect(() => {
        if (category) {
            const selected = notificationTemplateData?.find(
                template => template.id === category.value
            );
            if (selected && formRef.current) {
                formRef.current.setFieldValue('title', selected?.campaignTitle);
                formRef.current.setFieldValue('notificationTitle', templateTitle);
                formRef.current.setFieldValue('content', selected?.content);
                formRef.current.setFieldValue('sentTypeList', selected?.sentTypeList);
            } else {
                formRef.current.handleReset();
            }
        }
    }, [category, notificationTemplateData]);

    useEffect(() => {
        formRef.current.setFieldValue('notificationTitle', templateTitle);
    }, [templateTitle]);

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    <div className="relative">
                        <Formik
                            innerRef={formRef}
                            initialValues={{
                                type: 'CUSTOM',
                                sentTypeList: '',
                                title: '',
                                notificationTitle: '',
                                content: '',
                                companies: undefined,
                                institutes: undefined,
                                tags: undefined,
                            }}
                            validationSchema={Yup.object({
                                content: Yup.string()
                                    .max(500, 'Content should be less than 500 characters')
                                    .required('Content is required'),
                                sentTypeList: Yup.array().required('Notification mode is required'),
                                title: Yup.string().required('Title is required'),
                            })}
                            onSubmit={async values => {
                                dispatch(
                                    sendNotification({
                                        type: values.type,
                                        sentTypeList: values.sentTypeList,
                                        campaignTitle: values.title.trim(),
                                        content: values.content.trim(),
                                        studentFilter: {
                                            institutes: selectedInstitutes?.map(elem => {
                                                return elem.value;
                                            }),
                                            tags: selectedTags?.map(elem => {
                                                return elem.value;
                                            }),
                                        },
                                    })
                                );
                            }}
                        >
                            {({
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                values,
                                errors,
                                touched,
                            }) => (
                                <>
                                    <div className="flex justify-between items-center mb-4 md:mb-6">
                                        <p className="text-xl font-semibold text-left">
                                            Send Notification
                                        </p>
                                    </div>
                                    <div className="border-2 border-gray-300 px-6 lg:px-32 pt-2 pb-4 mb-6">
                                        <div className="mb-2 md:mb-4">
                                            <div className="my-6 w-full md:mb-0 flex">
                                                <label
                                                    className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Notification Type
                                                </label>
                                                <div className="w-full flex">
                                                    <div className="w-3/4 md:w-4/5 flex">
                                                        <ReactSelect
                                                            options={templateTypeList}
                                                            isSearchable={true}
                                                            className={'w-full'}
                                                            styles={style}
                                                            closeMenuOnSelect={true}
                                                            hideSelectedOptions={false}
                                                            onChange={e => {
                                                                handleNotificationType(e);
                                                                setFieldValue('type', e?.value);
                                                            }}
                                                            value={category}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex">
                                                <label
                                                    className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Notification Mode
                                                    <div className="text-red-600">*</div>
                                                </label>
                                                <div className="flex flex-col w-full">
                                                    <div className="flex text-sm md:text-base items-center w-full">
                                                        <input
                                                            className="mr-1"
                                                            type="checkbox"
                                                            checked={values.sentTypeList.includes(
                                                                'EMAIL'
                                                            )}
                                                            value="EMAIL"
                                                            id="EMAIL"
                                                            name="sentTypeList"
                                                            onChange={handleChange('sentTypeList')}
                                                        />
                                                        <label className="mr-4 w-1/6">Email</label>
                                                        <input
                                                            className="mr-1"
                                                            checked={values.sentTypeList.includes(
                                                                'MOBILE'
                                                            )}
                                                            type="checkbox"
                                                            value="MOBILE"
                                                            id="MOBILE"
                                                            name="sentTypeList"
                                                            onChange={handleChange('sentTypeList')}
                                                        />
                                                        <label className="mr-4 w-1/6">SMS</label>
                                                    </div>
                                                    {errors.sentTypeList && touched.sentTypeList ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.sentTypeList}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="title"
                                                    className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Campaign Title
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.title && touched.title
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="title"
                                                        type="text"
                                                        value={values.title}
                                                        onChange={e => {
                                                            setFieldValue('title', e.target.value);
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.title && touched.title ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.title}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex h-24">
                                                <label
                                                    htmlFor="content"
                                                    className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Message
                                                    <div className="text-red-600">*</div>
                                                </label>
                                                <div className="flex flex-col w-full">
                                                    <textarea
                                                        className={`resize-none focus:outline-none p-2 w-4/5 border-2 text-sm h-24 focus:border-blue-900 ${
                                                            errors.content && touched.content
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        }`}
                                                        id="content"
                                                        value={values.content}
                                                        placeholder=""
                                                        onChange={handleChange('content')}
                                                    />
                                                    {errors.content && touched.content ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.content}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex">
                                                <label
                                                    className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Institute
                                                </label>
                                                <div className="flex flex-col w-full">
                                                    <div className="w-3/4 md:w-4/5 flex">
                                                        <ReactSelect
                                                            options={instituteArray}
                                                            isMulti
                                                            isSearchable={false}
                                                            className={'w-full'}
                                                            styles={style}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            components={{ Option }}
                                                            onChange={e => {
                                                                handleChangeInstitutes(e);
                                                                setFieldValue('institutes', e);
                                                            }}
                                                            value={selectedInstitutes}
                                                        />
                                                    </div>
                                                    {errors.institutes && touched.institutes ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.institutes}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex">
                                                <label
                                                    className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Tag
                                                    <HiIcons.HiQuestionMarkCircle
                                                        onClick={() => {
                                                            setEmailHelpText(true);
                                                        }}
                                                        className="ml-2 mt-1 cursor-pointer"
                                                    />
                                                    {emailHelpText ? (
                                                        <div
                                                            onMouseOverCapture={() => {
                                                                setEmailHelpText(true);
                                                                // setProfileDropdownVisible(false);
                                                            }}
                                                            onMouseLeave={() =>
                                                                setEmailHelpText(false)
                                                            }
                                                            className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-7"
                                                            style={{
                                                                width: '375px',
                                                            }}
                                                        >
                                                            Enter the tags you have added in
                                                            Students and Leaderboard modules to sort
                                                            the students.
                                                        </div>
                                                    ) : null}
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <div className="w-3/4 md:w-4/5 flex">
                                                        <ReactSelect
                                                            options={tagsArray}
                                                            isMulti
                                                            isSearchable={false}
                                                            className={'w-full'}
                                                            styles={style}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            components={{ Option }}
                                                            onChange={e => {
                                                                handleChangeTags(e);
                                                                setFieldValue('tags', e);
                                                            }}
                                                            value={selectedTags}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="instLocation"
                                                    className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Student Details
                                                </label>

                                                <div className="flex w-full">
                                                    <section className=" w-3/4 md:w-4/5">
                                                        <div className=" w-full mb-8 overflow-hidden rounded-sm">
                                                            <div className="w-full pl-0.5 max-h-72 overflow-y-scroll overflow-x-visible">
                                                                {users ? (
                                                                    <SendNotificationsTable
                                                                        columns={columns}
                                                                        data={users.items}
                                                                        fetchData={fetchData}
                                                                        pageCount={users.totalPages}
                                                                    />
                                                                ) : (
                                                                    'Loading...'
                                                                )}
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-end mb-4 md:mb-6">
                                        <div className="flex justify-end w-1/2 lg:w-1/3 h-18">
                                            <button
                                                className="md:py-3 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded w-full md:w-1/3 my-2 focus:outline-none"
                                                onClick={() =>
                                                    history.push('/admin/notifications/')
                                                }
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="md:py-3 ml-6 lg:ml-8 bg-blue-700 text-white text rounded w-full md:w-1/3 md:text-sm my-2 focus:outline-none"
                                                onClick={() => handleSubmit()}
                                            >
                                                {isCreateLoading ? <Loader /> : 'Send Notification'}
                                            </button>
                                        </div>
                                    </div>
                                    {createSuccessVisible && (
                                        <NotificationsSuccessModal
                                            modalVisible={createSuccessVisible}
                                            setModalVisible={setCreateSuccessVisible}
                                            setOnClickOkBtn={setOnClickOkBtn}
                                        />
                                    )}
                                    {showSaveNotificationTemplate && (
                                        <NotificationTemplateSaveFeedback
                                            modalVisible={showSaveNotificationTemplate}
                                            templateTitle={templateTitle}
                                            setTemplateTitle={setTemplateTitle}
                                            setModalVisible={setShowSaveNotificationTemplate}
                                            setOnClickNoBtn={setOnClickNoBtn}
                                            setOnClickYesBtn={setOnClickYesBtn}
                                        />
                                    )}
                                    {showNotificationTemplateSuccess && (
                                        <NotificationTemplateSuccessModal
                                            modalVisible={showNotificationTemplateSuccess}
                                            setModalVisible={setShowNotificationTemplateSuccess}
                                        />
                                    )}
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
