import React, { useState, useMemo, useEffect, useRef } from 'react';
import * as RiIcons from 'react-icons/ri';
import RightSideContent from '../../RightSideContent';
import * as HiIcons from 'react-icons/hi';
import * as BsIcons from 'react-icons/bs';
import * as BiIcons from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { getMentors, getTags, getMentorCompanies } from '../../../redux/rootActions';
import Tab from './Tab';
import BulkRestoreConfirmationModal from '../../modals/BulkRestoreConfirmation';
import BulkDeleteConfirmationModal from '../../modals/BulkDeleteConfirmation';
import MentorsTable from './MentorsTable';
import NewMentorsTable from './NewMentorsTable';
import Loader from '../../../assets/svg/Loader';
import Add from '../../../assets/svg/Add';
import AddFilter from './AddFilter';
import { moduleNames } from '../../../constants';
import { useQuery } from '../../../web/hooks/useQuery';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export const Mentors = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const searchTermRef = useRef();
    const backToFirstRef = useRef(null);
    const mentorsData = useSelector((state: RootState) => state.mentors.mentorsData);
    const isLoading = useSelector((state: RootState) => state.mentors.isLoading);
    const filteredMentorsData = useSelector((state: RootState) => state.filteredMentor.mentorsData);
    const pageCount = useSelector((state: RootState) => state.mentors.pageCount);
    const filterdPagageCount = useSelector((state: RootState) => state.filteredMentor.pageCount);
    const [tags, setTags] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [searchResultsVisible, setSearchResultsVisible] = useState(false);
    const [sidebar, setSidebar] = useState(true);
    const showSidebar = () => setSidebar(!sidebar);
    const [bulkAction, setBulkAction] = useState<string>('Bulk Action');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selectedInstitutes, setSlectedInstitutes] = useState([]);
    const [selectedMainTab, setSelectedMainTab] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [restoreBulkConfirmationVisible, setRestoreBulkConfirmationVisible] = useState(false);
    const [deleteBulkConfirmationVisible, setDeleteBulkConfirmationVisible] = useState(false);
    const [clickedChallenge, setClickedChallenge] = useState<any[]>();
    const fetchIdRef = React.useRef(0);
    const tabStatus = selectedTab === 1 ? '' : 'DELETED';
    const [pageSizeS, setPageSizeS] = useState(0);
    const [pageIndexS, setPageIndexS] = useState(0);
    const [tagIds, setTagIds] = useState('');
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [tagsSelected, setTagsSelected] = useState([]);
    const [companiesSelected, setCompaniesSelected] = useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [filterTags, setFilterTags] = useState([]);
    const [filterCompanies, setFilterCompanies] = useState([]);
    const mentorCompanyData = useSelector((state: RootState) => state.mentors.menterCompanies);

    const companiesQS = query.get('companies');
    const tagIdsQS = query.get('tagIds');
    const initialRender = useRef(true);

    const bulkOptions = [
        { id: 0, val: 'Delete' },
        { id: 1, val: 'Restore' },
    ];
    const mentorsBulk = {
        listToUpdate: selectedInstitutes.map(elem => {
            return {
                action: bulkAction === 'Restore' ? 'ACTIVE' : 'DELETED',
                id: elem.id,
            };
        }),
    };

    const applyBulkAction = async () => {
        if (bulkAction === 'Restore') {
            setRestoreBulkConfirmationVisible(true);
        } else if (bulkAction === 'Delete') {
            setDeleteBulkConfirmationVisible(true);
        }
    };

    useEffect(() => {
        if (companiesQS) {
            setCompaniesSelected(companiesQS.split(','));
        }
        if (tagIdsQS) {
            setTagsSelected(tagIdsQS.split(','));
        }
        dispatch(getTags(true, moduleNames.Mentors));
        dispatch(getMentorCompanies(true));
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: '',
                id: 'actionColumn',
                disableSortBy: true,
                width: 5,
                // eslint-disable-next-line react/display-name
                Cell: (
                    <div className="cursor__pointer">
                        <button
                            value="menu cursor-pointer"
                            className="text-gray-500 rounded cursor-pointer focus:outline-none flex mx-auto px-1"
                        >
                            <BsIcons.BsThreeDotsVertical className="mx-auto" />
                        </button>
                    </div>
                ),
            },
            {
                Header: 'Mentor ID',
                accessor: 'mentorId',
            },
            {
                Header: 'Mentor Name',
                accessor: 'mentorName',
            },
            {
                Header: 'Mentor Email',
                accessor: 'mentorEmail',
            },
            {
                Header: 'Contact Number',
                accessor: 'contactNumber',
            },
            {
                Header: 'Designation',
                accessor: 'designation',
            },
            {
                Header: 'Company',
                accessor: 'companyName',
            },
            {
                Header: 'Joined Date',
                id: 'createdDate',
                accessor: data => {
                    return data?.createdDate != null
                        ? moment(data?.createdDate).local().format('YYYY-MM-DD')
                        : null;
                },
            },
            {
                Header: 'Status',
                accessor: 'mentorStatus',
            },
        ],
        []
    );

    const fetchData = React.useCallback(
        ({ pageSize, pageIndex }) => {
            setPageSizeS(pageSize);
            setPageIndexS(pageIndex);
            const tagIdsT = tags?.map(tagss => tagss.id).toString();
            setTagIds(tagIdsT);
            const fetchId = ++fetchIdRef.current;
            if (fetchId === fetchIdRef.current) {
                dispatch(
                    getMentors(
                        true,
                        pageIndex,
                        pageSize,
                        tabStatus,
                        tagIdsT || tagIdsQS?.split(',').toString(),
                        searchTermRef.current,
                        companiesSelected.toString() || companiesQS?.split(',').toString()
                    )
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, tabStatus, tags, tagIdsQS, companiesSelected, companiesQS]
    );

    const searchByNameOrId = async () => {
        await backToFirstRef?.current?.goToFirst();
        dispatch(
            getMentors(
                true,
                0,
                pageSizeS,
                tabStatus,
                tagsSelected.toString(),
                searchTerm,
                companiesSelected.toString()
            )
        );
    };

    const searchInputHandler = e => {
        searchTermRef.current = e.target.value;
        setSearchTerm(e.target.value);
    };
    useEffect(() => {
        setBulkAction('Bulk Action');
    }, [selectedTab]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            searchByNameOrId();
        }
    };

    const onRequestClose = () => {
        setShowModal(false);
    };
    useEffect(() => {
        const goBack = async () => {
            backToFirstRef?.current?.goToFirst();
        };
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            goBack().then(() => {
                history.replace({
                    search: `?companies=${companiesSelected.toString()}&tagIds=${tagsSelected.toString()}`,
                });
                dispatch(
                    getMentors(
                        true,
                        pageIndexS,
                        pageSizeS,
                        tabStatus,
                        tagsSelected.toString(),
                        searchTerm,
                        companiesSelected.toString()
                    )
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagsSelected, companiesSelected]);

    useEffect(() => {
        if (tagData.length > 0) {
            const newTagsArr = tagData.map(item => {
                return { name: item.tagName, category: item.tagCategory, id: item.id };
            });
            setFilterTags(newTagsArr);
        } else {
            setFilterTags([]);
        }
        if (mentorCompanyData.length > 0) {
            const newCompaniesArr = mentorCompanyData.map((item, key) => {
                return { name: item, id: key };
            });
            setFilterCompanies(newCompaniesArr);
        } else {
            setFilterCompanies([]);
        }
    }, [tagData, mentorCompanyData]);

    useEffect(() => {
        if (searchTerm.length === 0) {
            dispatch(
                getMentors(
                    true,
                    pageIndexS,
                    pageSizeS,
                    tabStatus,
                    tagsSelected.toString(),
                    searchTerm,
                    companiesSelected.toString()
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    const openFilter = () => {
        dispatch(getTags(true, moduleNames.Mentors));
        setShowModal(true);
    };

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <Tab selectedTab={selectedMainTab} setSelectedTab={setSelectedMainTab} />
                <div className="flex w-full">
                    <div
                        style={{
                            width: sidebar === false ? '100%' : '75%',
                        }}
                    >
                        <div className="flex h-12 md:h-14 justify-between md:justify-start">
                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex">
                                <input
                                    className={`rounded-sm w-full h-full text-gray-500 font-medium focus:outline-none p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                    id="id"
                                    type="text"
                                    placeholder="Search by ID or Name"
                                    onChange={e => searchInputHandler(e)}
                                    autoComplete="off"
                                    onKeyDown={handleKeyDown}
                                />
                                <button
                                    className={`ml-2 px-5 border-2 font-medium text-sm rounded-md focus:outline-none ${
                                        searchTerm.length > 0
                                            ? ' text-white border-blue-700 bg-blue-700 cursor-pointer'
                                            : 'cursor-not-allowed border-gray-300'
                                    }`}
                                    disabled={searchTerm.length === 0}
                                    onClick={searchByNameOrId}
                                >
                                    <BsIcons.BsSearch
                                        style={searchTerm.length > 0 && { color: '#ffffff' }}
                                    />
                                </button>
                            </div>
                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex">
                                <div
                                    className={`rounded-md flex relative items-center focus:outline-none w-3/4 md:w-3/4 lg:w-3/4 xl:w-2/3 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className={`flex justify-between w-full md:p-2 ${
                                            selectedInstitutes.length <= 1
                                                ? 'cursor-not-allowed'
                                                : 'cursor-pointer'
                                        }  `}
                                        onClick={() =>
                                            selectedInstitutes.length > 1
                                                ? setDropdownVisible(true)
                                                : null
                                        }
                                    >
                                        <div className="text-gray-700 font-medium pl-2">
                                            {bulkAction}
                                        </div>
                                        <div className="">
                                            <div className="flex flex-col">
                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                <HiIcons.HiChevronDown className="mr-2" />
                                            </div>
                                        </div>
                                    </div>
                                    {dropdownVisible ? (
                                        <ul
                                            className="absolute w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setDropdownVisible(false)}
                                        >
                                            {bulkOptions
                                                .filter(option => {
                                                    if (selectedTab === 1) {
                                                        return option.val === 'Delete';
                                                    } else return option.val === 'Restore';
                                                })
                                                .map(action => (
                                                    <div
                                                        className="p-2 hover:bg-gray-200 "
                                                        key={action.id}
                                                        onClick={() => {
                                                            setBulkAction(action.val);
                                                            setDropdownVisible(false);
                                                        }}
                                                    >
                                                        {action.val}
                                                    </div>
                                                ))}
                                        </ul>
                                    ) : null}
                                </div>
                                <button
                                    className={`ml-2 px-2 border-2 ${
                                        bulkAction === 'Bulk Action'
                                            ? 'text-gray-400 border-gray-300'
                                            : 'text-white border-blue-700 bg-blue-700'
                                    } font-medium text-sm rounded-md focus:outline-none  hover:border-blue-700 hover:text-white hover:bg-blue-700`}
                                    onClick={applyBulkAction}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                        <div className="flex h-12 md:h-14 justify-between md:justify-start align-center p-5">
                            <BiIcons.BiFilterAlt
                                style={{
                                    color: 'rgba(29, 78, 216)',
                                    height: '20px',
                                    width: '20px',
                                    marginRight: '10px',
                                    alignSelf: 'baseline',
                                }}
                            />
                            <div
                                className="sm:text-sm md:text-base text-gray-400 pr-2 cursor-pointer"
                                onClick={openFilter}
                            >
                                Filter
                            </div>
                            <button onClick={openFilter}>
                                <Add size={25} color={'rgba(29, 78, 216)'} />
                            </button>
                            <AddFilter
                                setCompaniesSelected={setCompaniesSelected}
                                companiesSelected={companiesSelected}
                                setTagsSelected={setTagsSelected}
                                tagsSelected={tagsSelected}
                                isSelected={showModal}
                                onRequestClose={onRequestClose}
                                filterTags={filterTags}
                                filterCompanies={filterCompanies}
                            />
                            {tagsSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Tags filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setTagsSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                            {companiesSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Companies filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setCompaniesSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        {isLoading && (
                            <div className=" w-full h-98  flex items-center">
                                <Loader />
                            </div>
                        )}
                        <div className={`${isLoading && 'hidden'}`}>
                            <MentorsTable
                                columns={columns}
                                data={
                                    selectedMainTab !== 1
                                        ? filteredMentorsData
                                        : clickedChallenge?.length > 0
                                        ? clickedChallenge
                                        : mentorsData
                                }
                                fetchData={fetchData}
                                pageCount={pageCount}
                                setSlectedChallenges={setSlectedInstitutes}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                setBulkAction={setBulkAction}
                                backToFirstRef={backToFirstRef}
                            />
                        </div>
                    </div>
                    <div
                        className={`relative border-l-2 ${
                            sidebar === false ? '' : 'right-auto w-1/4'
                        }`}
                    >
                        {sidebar === false && (
                            <div className=" ">
                                <RiIcons.RiArrowDropLeftLine
                                    style={{
                                        borderRadius: 50,
                                        borderWidth: 1,
                                        borderColor: 'rgba(0,0,0,0.2)',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        height: '25px',
                                        width: '25px',
                                        top: '100px',
                                        left: '-13px',
                                        backgroundColor: 'white',
                                    }}
                                    onClick={showSidebar}
                                />
                            </div>
                        )}

                        {sidebar === true && (
                            <div className="h-full">
                                <RiIcons.RiArrowDropRightLine
                                    style={{
                                        borderRadius: 50,
                                        borderWidth: 1,
                                        borderColor: 'rgba(0,0,0,0.2)',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        height: '25px',
                                        width: '25px',
                                        top: '100px',
                                        left: '-13px',
                                        backgroundColor: 'white',
                                    }}
                                    onClick={showSidebar}
                                />
                                <div className="h-96 ml-2 w-full mt-10">
                                    <RightSideContent
                                        tags={tags}
                                        setTags={setTags}
                                        moduleName={moduleNames.Mentors}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {restoreBulkConfirmationVisible ? (
                <BulkRestoreConfirmationModal
                    modalVisible={restoreBulkConfirmationVisible}
                    setModalVisible={setRestoreBulkConfirmationVisible}
                    mentors={mentorsBulk}
                    tabStatus={tabStatus}
                />
            ) : null}
            {deleteBulkConfirmationVisible ? (
                <BulkDeleteConfirmationModal
                    modalVisible={deleteBulkConfirmationVisible}
                    setModalVisible={setDeleteBulkConfirmationVisible}
                    mentors={mentorsBulk}
                    tabStatus={tabStatus}
                />
            ) : null}
        </div>
    );
};
