import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Camera from '../../../assets/svg/Camera';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearMentorsMessages,
    editMentor,
    getMentees,
    getMentorById,
    getMentorPdfUrl,
    getPresignedUrlMentor,
    assignMentees,
    getTags,
} from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { moduleNames, phoneRegExp, s3Config, s3UrlPrefix } from '../../../constants';
import S3 from 'react-aws-s3';
import MentorSuccessModal from '../../modals/MentorSuccessModal';
import { MentorType } from '../../../redux/mentors/reducers';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import MenteeTable from './MenteeTable';
import * as HiIcons from 'react-icons/hi';
import { BiRightArrow } from 'react-icons/bi';
import { AiFillDelete } from 'react-icons/ai';
import PhoneInput from 'react-phone-input-2';
import diff from 'object-diff';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroups from '../../TagGroups';

interface CustomState {
    mentor: MentorType;
}
interface paramsType {
    id: string;
}
export default function EditMentor() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const fetchIdRef = React.useRef(0);
    const params = useParams<paramsType>();
    const mentorState = location.state as CustomState;
    const mentor = useSelector((state: RootState) => state.mentors.mentor);
    const menteesData = useSelector((state: RootState) => state.mentors.menteesData);
    const [filteredMenteesData, setFilteredMenteesData] = useState([]);
    const formRef = useRef<any>();
    const [imageUrl, setImageUrl] = useState(`${s3UrlPrefix}${mentorState?.mentor?.imagePath}`);
    const [imageLocation, setImageLocation] = useState(mentorState?.mentor?.imagePath);
    const [fileLocations, setFileLocations] = useState(mentorState?.mentor?.filePath);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [createSuccessVisible, setCreateSuccessVisible] = useState(false);
    const [uploadHover, setUploadHover] = useState(false);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [clickedStudent, setClickedStudent] = useState<any[]>([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [menteeArray, setMenteeArray] = useState([]);
    const isEditLoading = useSelector((state: RootState) => state.mentors.isEditLoading);
    const editMentorError = useSelector((state: RootState) => state.mentors.editMentorError);
    const assignMenteeError = useSelector((state: RootState) => state.mentors.assignMenteeError);
    const mentorUpdateSuccessMessage = useSelector(
        (state: RootState) => state.mentors.mentorUpdateSuccessMessage
    );
    const deleteMentorError = useSelector((state: RootState) => state.mentors.deleteMentorError);
    const mentorDeleteSuccessMessage = useSelector(
        (state: RootState) => state.mentors.mentorDeleteSuccessMessage
    );
    const pageCount = useSelector((state: RootState) => state.mentors.menteePageCount);

    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [tags, setTags] = useState(mentorState?.mentor?.tags);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [typedSearch, setTypedSearch] = useState('');
    const [searchResultsVisible, setSearchResultsVisible] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    const [tagsHelpText, setTagsHelpText] = useState<boolean>(false);

    const serchTagNameOrId = e => {
        setSearchTerm(e.target.value);
        // setTags([]);
    };

    useEffect(() => {
        setSelectedTags(categorizeTags(tags));
    }, [tags]);

    useEffect(() => {
        if (clickedStudent.length > 0) {
            setFilteredMenteesData(clickedStudent);
        } else {
            setFilteredMenteesData(menteesData);
        }
    }, [menteesData, clickedStudent]);

    useEffect(() => {
        if (showDropdown) {
            dispatch(getTags(true, moduleNames.Mentors, searchTerm));
        }
    }, [dispatch, searchTerm, showDropdown, clickedStudent]);

    useEffect(() => {
        setTags(mentorState?.mentor?.tags);
    }, [mentorState]);
    const columns = useMemo(
        () => [
            {
                Header: 'Student ID',
                accessor: 'userId',
            },
            {
                Header: 'Student Name',
                accessor: data => {
                    return `${data.firstName ? data.firstName : ''} ${
                        data.lastName ? data.lastName : ''
                    }`;
                },
            },

            {
                Header: 'LinkedIn Profile',
                accessor: data => {
                    return `${data.linkedInProfileUrl ? data.linkedInProfileUrl : ''}`;
                },
            },
            {
                Header: 'Edit',
                id: 'actionColumn',
                accessor: data => {
                    let text;
                    if (
                        menteeArray.find(mentee => {
                            if (mentee.userId === data?.id && mentee.command === 'ASSIGN') {
                                return true;
                            } else return false;
                        })
                    ) {
                        return 'Remove';
                    } else if (
                        menteeArray.find(mentee => {
                            if (mentee.userId === data?.id && mentee.command === 'UNASSIGN') {
                                return true;
                            } else return false;
                        })
                    ) {
                        return 'Add';
                    } else if (data.mentor) {
                        return 'Remove';
                    } else {
                        return 'Add';
                    }
                },
            },
        ],
        [menteeArray]
    );
    const handleClearMessages = useCallback(() => {
        if (
            mentorUpdateSuccessMessage ||
            editMentorError ||
            deleteMentorError ||
            mentorDeleteSuccessMessage ||
            assignMenteeError
        ) {
            setCreateSuccessVisible(true);
        }
    }, [
        dispatch,
        mentorUpdateSuccessMessage,
        editMentorError,
        deleteMentorError,
        mentorDeleteSuccessMessage,
        assignMenteeError,
        history,
    ]);
    const handleClose = () => {
        dispatch(clearMentorsMessages());
        if (!editMentorError) {
            history.push('/admin/mentors');
        }
    };
    const serchByNameOrId = e => {
        setSearchTerm(e.target.value);
        setClickedStudent([]);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            serchByNameOrId(event);
        }
    };

    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    const fetchData = React.useCallback(
        ({ pageIndex }) => {
            const fetchId = ++fetchIdRef.current;
            if (fetchId === fetchIdRef.current) {
                dispatch(getMentees(pageIndex, !(selectedTab === 1), searchTerm, params.id));
            }
        },
        [searchTerm, dispatch, selectedTab, params.id]
    );

    const gotoPdfUrl = async index => {
        const fileName = fileLocations?.[index].toString().split('/', 4);
        const link = await dispatch(getMentorPdfUrl(fileName?.[3]));
        const linkToString = link?.toString();
        window.open(linkToString, '_blank');
    };

    useEffect(() => {
        dispatch(getMentorById(params.id));
    }, [dispatch, params.id]);

    const handleImage = (e: any) => {
        const _URL = window.URL || window.webkitURL;
        let file: File, img;
        if ((file = e.target.files[0])) {
            img = new Image();
            img.src = _URL.createObjectURL(file);
            setImageUrl(img.src);
        }
    };

    const uploadImageToS3 = async e => {
        setIsImageUploading(true);
        const imageName = Date.now().toString();
        s3Config.dirName = `mentors/images/${imageName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(e.target.files[0], imageName)
            .then(data => {
                setImageLocation(data.key);
                setIsImageUploading(false);
            })
            .catch(err => {
                console.error(err);
                setIsImageUploading(false);
            });
    };
    const uploadFilesToS3 = async e => {
        setIsFileUploading(true);
        const locationURL = await dispatch(
            getPresignedUrlMentor(e.target.files[0]?.name, e.target.files[0])
        );
        const locationArray = await locationURL?.toString().split('/', 6);
        setFileLocations([
            ...fileLocations,
            `mentors/docs/${e.target.files[0]?.name}/${locationArray?.[5]}.pdf`,
        ]);
        setIsFileUploading(false);
    };
    return (
        <div className="mx-6 lg:ml-2 h-full">
            <div className="mt-12 bg-snow-600">
                <div className=" mt-12">
                    <div className="relative">
                        <Formik
                            enableReinitialize={true}
                            innerRef={formRef}
                            initialValues={{
                                mentorName: mentor?.mentorName,
                                companyName: mentor?.companyName,
                                designation: mentor?.designation,
                                mentorEmail: mentor?.mentorEmail,
                                contactNumber: mentor?.contactNumber,
                                filePath: fileLocations,
                                file: undefined,
                                linkedinProfile: mentor?.linkedinProfile,
                                imagePath: imageLocation,
                                description: mentor?.description,
                            }}
                            validationSchema={Yup.object({
                                mentorName: Yup.string()
                                    .nullable()
                                    .max(100, 'Name should be less than 100 characters')
                                    .required('Name is required'),
                                description: Yup.string()
                                    .nullable()
                                    .max(500, 'Description should be less than 500 characters')
                                    .required('Description is required'),
                                mentorEmail: Yup.string()
                                    .nullable()
                                    .email('Invalid email')
                                    .required('Email is required'),
                                designation: Yup.string()
                                    .nullable()
                                    .required('Location is required'),
                                contactNumber: Yup.string()
                                    .nullable()
                                    .matches(phoneRegExp, 'Contact number is not valid')
                                    .required('Contact number is required'),
                                linkedinProfile: Yup.string()
                                    .nullable()
                                    .required('Profile URL is required'),
                            })}
                            onSubmit={async values => {
                                let updatedFileds = diff(mentor, {
                                    mentorName: values.mentorName.trim(),
                                    designation: values.designation.trim(),
                                    imagePath: imageLocation,
                                    companyName: values.companyName.trim(),
                                    mentorEmail: values.mentorEmail,
                                    description: values.description.trim(),
                                    contactNumber: values.contactNumber,
                                    linkedinProfile: values.linkedinProfile,
                                });
                                if (
                                    JSON.stringify(mentor.filePath) !==
                                    JSON.stringify(fileLocations)
                                ) {
                                    updatedFileds = Object.assign(updatedFileds, {
                                        filePath: fileLocations,
                                    });
                                }
                                if (JSON.stringify(mentor.tags) !== JSON.stringify(tags)) {
                                    updatedFileds = Object.assign(updatedFileds, {
                                        tags: tags,
                                    });
                                }
                                dispatch(editMentor(mentor?.id, updatedFileds));
                                dispatch(assignMentees(mentor?.id, menteeArray));
                            }}
                        >
                            {({
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                values,
                                errors,
                                touched,
                            }) => (
                                <>
                                    <div className="flex justify-between items-center mb-2">
                                        <p className="text-xl font-semibold text-left ml-2">
                                            Edit Mentor
                                        </p>
                                        <div className="flex w-1/2 md:w-1/4 lg:pl-8 h-16">
                                            <button
                                                className="md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-700 font-medium text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => history.push('/admin/mentors')}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => handleSubmit()}
                                            >
                                                {isEditLoading ? <Loader /> : 'Save'}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2 pb-8 mb-6">
                                        <div className="col-span-12 md:col-span-4 mb-2 md:mb-4">
                                            <div className="text-lg font-medium mb-6">
                                                Mentor Details
                                            </div>
                                            <div className="mb-8 mt-6 flex flex-row justify-between">
                                                <div className="flex flex-row relative">
                                                    {uploadHover ? (
                                                        <>
                                                            <label
                                                                htmlFor="image"
                                                                onMouseEnter={() => {
                                                                    setUploadHover(true);
                                                                }}
                                                                className={`absolute  ${
                                                                    imageLocation
                                                                        ? 'left-7'
                                                                        : 'left-10'
                                                                } bottom-16 block px-3 py-2 text-base font-medium text-gray-700 no-underline bg-white cursor-pointer`}
                                                            >
                                                                {imageLocation ? (
                                                                    'Change'
                                                                ) : (
                                                                    <Camera />
                                                                )}
                                                            </label>
                                                            <input
                                                                style={{ display: 'none' }}
                                                                id="image"
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                onChange={async e => {
                                                                    const file = [];
                                                                    file.push(e.target.files[0]);
                                                                    setFieldValue('image', file);
                                                                    handleImage(e);
                                                                    uploadImageToS3(e);
                                                                }}
                                                            />
                                                        </>
                                                    ) : null}
                                                    <div className="flex justify-center mt-4 mb-4">
                                                        <img
                                                            className={`blur-3xl rounded-full object-cover h-36 w-36 cursor-pointer`}
                                                            src={`${
                                                                imageLocation
                                                                    ? imageUrl
                                                                    : '/assets/user.png'
                                                            }`}
                                                            alt="profile"
                                                            onMouseEnter={() => {
                                                                setUploadHover(true);
                                                            }}
                                                            onMouseLeave={() =>
                                                                setUploadHover(false)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="mentorName"
                                                    className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Mentor ID
                                                </label>
                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                        value={mentor?.mentorId}
                                                        autoComplete="off"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="mentorName"
                                                    className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Mentor Name
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.mentorName && touched.mentorName
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="mentorName"
                                                        type="text"
                                                        value={values.mentorName}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'mentorName',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.mentorName && touched.mentorName ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.mentorName}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="mentorCompany"
                                                    className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Company
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.companyName &&
                                                            touched.companyName
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="badgeName"
                                                        type="text"
                                                        value={values.companyName}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'companyName',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.companyName && touched.companyName ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.companyName}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="designation"
                                                    className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Designation
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.designation &&
                                                            touched.designation
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="designation"
                                                        type="text"
                                                        value={values.designation}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'designation',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.designation && touched.designation ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.designation}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="mentorEmail"
                                                    className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Email
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.mentorEmail &&
                                                            touched.mentorEmail
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="badgeName"
                                                        type="text"
                                                        value={values.mentorEmail}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'mentorEmail',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.mentorEmail && touched.mentorEmail ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.mentorEmail}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="contactNumber"
                                                    className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Contact Number
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <label
                                                        htmlFor=""
                                                        className={`rounded-sm rounded-tl rounded-bl flex items-center focus:outline-none w-4/5 md:w-3/4  border-2 text-base focus:border-blue-900 ${
                                                            errors.contactNumber &&
                                                            touched.contactNumber
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                    >
                                                        <PhoneInput
                                                            inputStyle={{
                                                                width: '100%',
                                                                height: '42px',
                                                                fontSize: '14px',
                                                                paddingLeft: '48px',
                                                                borderRadius: '4px',
                                                                border: 0,
                                                                fontFamily: 'Lato',
                                                            }}
                                                            dropdownClass={
                                                                'mobileNumberCountryDropdown'
                                                            }
                                                            country="lk"
                                                            specialLabel={''}
                                                            value={values.contactNumber}
                                                            placeholder="Enter your Mobile Number (Eg: 94712345678)"
                                                            onChange={e => {
                                                                if (!isNaN(Number(e))) {
                                                                    setFieldValue(
                                                                        'contactNumber',
                                                                        e
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </label>
                                                    {errors.contactNumber &&
                                                    touched.contactNumber ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.contactNumber}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="linkedinProfile"
                                                    className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Profile URL
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                        id="linkedinProfile"
                                                        type="text"
                                                        value={values.linkedinProfile}
                                                        placeholder="Ex: Linked-In"
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'linkedinProfile',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.linkedinProfile &&
                                                    touched.linkedinProfile ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.linkedinProfile}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex h-32">
                                                <label
                                                    htmlFor="description"
                                                    className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Profile Description
                                                    <div className="text-red-600">*</div>
                                                </label>
                                                <div className="flex flex-col w-full">
                                                    <textarea
                                                        className={`resize-none focus:outline-none p-2 w-4/5 md:w-3/4 border-2 text-sm h-32 focus:border-blue-900 ${
                                                            errors.description &&
                                                            touched.description
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        }`}
                                                        id="description"
                                                        value={values.description}
                                                        placeholder=""
                                                        onChange={handleChange('description')}
                                                    />
                                                    {errors.description && touched.description ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.description}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                            <div className="flex border-2 flex-col ml-2 justify-items-center">
                                                <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                                    <div className="flex sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                        <div>Assign Tags</div>
                                                        <div>
                                                            <HiIcons.HiQuestionMarkCircle
                                                                onClick={() => {
                                                                    setTagsHelpText(true);
                                                                }}
                                                                className="ml-2 mt-1 cursor-pointer"
                                                            />
                                                        </div>
                                                    </div>
                                                    {tagsHelpText ? (
                                                        <div
                                                            onMouseOverCapture={() => {
                                                                setTagsHelpText(true);
                                                                // setProfileDropdownVisible(false);
                                                            }}
                                                            onMouseLeave={() =>
                                                                setTagsHelpText(false)
                                                            }
                                                            className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-6 right-12"
                                                        >
                                                            Assign already created tags to the new
                                                            record created.
                                                        </div>
                                                    ) : null}
                                                    <div
                                                        className="sm:text-sm md:text-base text-gray-400 underline pr-2 cursor-pointer"
                                                        onClick={() => {
                                                            setTags([]);
                                                        }}
                                                    >
                                                        Clear All
                                                    </div>
                                                </div>
                                                <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                                    <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                        <TagGroups
                                                            selectedTags={selectedTags}
                                                            tags={tags}
                                                            setTags={setTags}
                                                        />
                                                        <div
                                                            onMouseLeave={() => {
                                                                setShowDropdown(false);
                                                            }}
                                                        >
                                                            <input
                                                                className={`rounded w-full text-gray-500 font-medium focus:outline-none mt-2 p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                                                id="id"
                                                                type="text"
                                                                placeholder="Search tags"
                                                                onChange={e => serchTagNameOrId(e)}
                                                                onMouseEnter={() => {
                                                                    setShowDropdown(true);
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {showDropdown && tagData?.length ? (
                                                                <ul
                                                                    className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() => {
                                                                        setShowDropdown(false);
                                                                    }}
                                                                >
                                                                    {tagData?.map(tag => (
                                                                        <div
                                                                            className="p-2 hover:bg-gray-200 cursor-pointer"
                                                                            key={tag.id}
                                                                            onClick={() => {
                                                                                if (
                                                                                    tags
                                                                                        .map(
                                                                                            checktag =>
                                                                                                checktag.tagName
                                                                                        )
                                                                                        .includes(
                                                                                            tag.tagName
                                                                                        ) === false
                                                                                ) {
                                                                                    setTags([
                                                                                        ...tags,
                                                                                        tag,
                                                                                    ]);
                                                                                }
                                                                            }}
                                                                        >
                                                                            {tag.tagCategory +
                                                                                ': ' +
                                                                                tag.tagName}
                                                                        </div>
                                                                    ))}
                                                                </ul>
                                                            ) : showDropdown &&
                                                              tagData.length === 0 ? (
                                                                <ul
                                                                    className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() =>
                                                                        setShowDropdown(false)
                                                                    }
                                                                >
                                                                    <div className="p-2 flex justify-center font-medium text-gray-400">
                                                                        No records found
                                                                    </div>
                                                                </ul>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" ml-2 justify-items-center">
                                                <div className="flex flex-col text-sm pl-2 lg:pl-4 mt-6 space-y-4 text-gray-400 ">
                                                    <div>
                                                        Created tags can be retrieved by typing the
                                                        text in the search bar.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-2 border-gray-300 px-2 md:px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6">
                                        <div className="col-span-12 md:col-span-4 mb-2 md:mb-4 ml-2">
                                            <div className="text-lg font-medium mb-6">
                                                Qualifications
                                            </div>

                                            <div className="mb-6 w-full md:mb-0 my-4 ml-4">
                                                <div className="flex justify-between">
                                                    <label
                                                        htmlFor="mentorName"
                                                        className={`flex items-start mb-4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Relevant Documentations
                                                    </label>
                                                    <label
                                                        className="flex items-center bg-snow-900 border-2 border-blue-800 border-opacity-40 hover:bg-white h-8 text-blue-700 font-medium text-sm rounded-sm focus:outline-none px-8"
                                                        htmlFor="file"
                                                    >
                                                        {isFileUploading ? (
                                                            <Loader />
                                                        ) : (
                                                            'Choose a file to upload'
                                                        )}
                                                    </label>
                                                </div>
                                                <div className=" w-full">
                                                    {fileLocations?.map((doc, index) => (
                                                        <div
                                                            key={index}
                                                            className="flex justify-between hover:bg-gray-100 rounded px-2"
                                                        >
                                                            <div className="flex flex-row">
                                                                <BiRightArrow className="flex items-center mt-1" />
                                                                <div
                                                                    onClick={() =>
                                                                        gotoPdfUrl(index)
                                                                    }
                                                                    className="underline text-blue-400 cursor-pointer"
                                                                >
                                                                    {doc.split('/', 4)[2]}
                                                                </div>
                                                            </div>
                                                            <AiFillDelete
                                                                onClick={() => {
                                                                    setFileLocations(
                                                                        fileLocations.filter(
                                                                            (_, i) => index !== i
                                                                        )
                                                                    );
                                                                }}
                                                                fill="red"
                                                                className="flex items-center mt-1 transform hover:scale-110"
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                <input
                                                    className={`focus:outline-none w-full focus:border-blue-900`}
                                                    id="file"
                                                    type="file"
                                                    accept=".pdf,"
                                                    onChange={async e => {
                                                        uploadFilesToS3(e);
                                                    }}
                                                    style={{ display: 'none' }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2 pb-8 mb-6">
                                        <div className="col-span-12 md:col-span-4 mb-2 md:mb-4">
                                            <div className="text-lg font-medium mb-6">
                                                Assign Mentees
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="search"
                                                    className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Search Mentees
                                                </label>

                                                <div className="relative flex w-full">
                                                    <input
                                                        className={`rounded-sm flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.mentorName && touched.mentorName
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="search"
                                                        type="text"
                                                        value={typedSearch}
                                                        onChange={e => {
                                                            setTypedSearch(e.target.value);
                                                            if (e.target.value === '') {
                                                                serchByNameOrId(e);
                                                            }
                                                        }}
                                                        onKeyDown={handleKeyDown}
                                                        autoComplete="off"
                                                        placeholder="Search by Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-12 md:col-start-2 md:col-span-4 mb-2 md:mb-4">
                                            <div className={`mr-4 w-full `}>
                                                <MenteeTable
                                                    columns={columns}
                                                    data={filteredMenteesData}
                                                    fetchData={fetchData}
                                                    pageCount={pageCount}
                                                    selectedTab={selectedTab}
                                                    setSelectedTab={setSelectedTab}
                                                    menteeArray={menteeArray}
                                                    setMenteeArray={setMenteeArray}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {mentor?.mentorStatus !== 'ACTIVE' && (
                                        <div className="my-6 md:mb-0 flex justify-end">
                                            <button
                                                type="submit"
                                                className="sm:py-1 md:py-3 ml-2 lg:ml-6 hover:bg-red-500 text-red-500 hover:text-white border-2 font-medium border-red-600 border-opacity-40 text-sm rounded-sm w-1/2 md:w-1/6 my-2 focus:outline-none"
                                                onClick={() => setDeleteConfirmationVisible(true)}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    )}
                                    {deleteConfirmationVisible ? (
                                        <DeleteConfirmationModal
                                            modalVisible={deleteConfirmationVisible}
                                            setModalVisible={setDeleteConfirmationVisible}
                                            mentorId={mentor?.id}
                                        />
                                    ) : null}
                                    {createSuccessVisible && (
                                        <MentorSuccessModal
                                            modalVisible={createSuccessVisible}
                                            handleClose={handleClose}
                                        />
                                    )}
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
