import React, { ReactElement } from 'react';

export default function Camera(): ReactElement {
    return (
        <svg
            width="32"
            height="27"
            viewBox="0 0 32 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28.6296 4.15744H23.75L22.5875 0.899565C22.5072 0.676637 22.36 0.483941 22.1661 0.347799C21.9721 0.211656 21.7409 0.138707 21.5039 0.138916H10.4961C10.0117 0.138916 9.57755 0.443893 9.41609 0.899565L8.25 4.15744H3.37037C1.78449 4.15744 0.5 5.44193 0.5 7.02781V23.3889C0.5 24.9748 1.78449 26.2593 3.37037 26.2593H28.6296C30.2155 26.2593 31.5 24.9748 31.5 23.3889V7.02781C31.5 5.44193 30.2155 4.15744 28.6296 4.15744ZM28.9167 23.3889C28.9167 23.5468 28.7875 23.676 28.6296 23.676H3.37037C3.2125 23.676 3.08333 23.5468 3.08333 23.3889V7.02781C3.08333 6.86994 3.2125 6.74077 3.37037 6.74077H10.0691L10.6826 5.02572L11.5043 2.72225H20.4921L21.3138 5.02572L21.9273 6.74077H28.6296C28.7875 6.74077 28.9167 6.86994 28.9167 7.02781V23.3889ZM16 9.03706C12.8282 9.03706 10.2593 11.606 10.2593 14.7778C10.2593 17.9496 12.8282 20.5185 16 20.5185C19.1718 20.5185 21.7407 17.9496 21.7407 14.7778C21.7407 11.606 19.1718 9.03706 16 9.03706ZM16 18.2222C14.0984 18.2222 12.5556 16.6794 12.5556 14.7778C12.5556 12.8762 14.0984 11.3334 16 11.3334C17.9016 11.3334 19.4444 12.8762 19.4444 14.7778C19.4444 16.6794 17.9016 18.2222 16 18.2222Z"
                fill="#636668"
            />
        </svg>
    );
}
