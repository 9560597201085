import React from 'react';
import { useHistory } from 'react-router-dom';

export default function PageNotFound() {
    const history = useHistory();
    return (
        <div className={'flex items-center justify-center mt-32 ml-3 mr-3'}>
            <div className="pageNotFound_1 self-center hidden lg:block"></div>
            <div className="flex-col justify-center self-center">
                <div className="pageNotFound_2 px-10 self-center"></div>
                <div
                    onClick={() => {
                        history.replace('/home');
                    }}
                    className="border-2 mt-10 mx-auto rounded border-purple-950 text-purple-950  w-40 h-10 flex justify-center items-center self-center font-bold cursor-pointer whitespace-nowrap"
                >
                    Back To Home
                </div>
            </div>
        </div>
    );
}
