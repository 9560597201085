import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    clearStudentMessages,
    editStudent,
    StudentEditType,
} from '../../../redux/students/actions';
import { CareerType } from '../../../redux/students/reducers';
import * as HiIcons from 'react-icons/hi';
import { USER_PROFILE } from '../../../utils/storage';

interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    setCareerProgressAdded: (value: boolean) => void;
    student: any;
}

export default function AddCareerProgressModal({
    modalVisible,
    setModalVisible,
    setCareerProgressAdded,
    student,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));

    const [notesTooltipVisible, setNotesTooltipVisible] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const typeFilter = [
        { id: 0, val: 'Interview', key: 'INTERVIEW' },
        { id: 1, val: 'Internship', key: 'INTERNSHIP' },
        { id: 2, val: 'Training', key: 'TRAINING' },
        { id: 3, val: 'Bridge event', key: 'BRIDGE_EVENT' },
    ];

    return (
        <Modal
            open={modalVisible}
            onClose={() => {
                setModalVisible(false);
            }}
            center
            showCloseIcon={false}
            styles={{
                modal: {
                    borderRadius: 5,
                    maxWidth: '700px',
                    minWidth: '650px',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    boxShadow: '0 12px 15px 0 rgb(0 0 0 / 10%)',
                },
                overlay: { background: 'none' },
            }}
        >
            <div className="w-full">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        description: '',
                        type: { id: 0, val: 'Interview', key: 'INTERVIEW' },
                    }}
                    validationSchema={Yup.object({
                        description: Yup.string()
                            .max(500, 'Description should be less than 500 characters')
                            .required('Description is required'),
                    })}
                    onSubmit={async values => {
                        const careerStatusArray: any = [
                            ...student?.careerStatus,
                            {
                                message: values.description,
                                type: values.type.key,
                                logCreatedBy: profile.userEmail,
                            },
                        ];
                        const studentEditType: StudentEditType = {
                            careerStatus: careerStatusArray,
                        };

                        dispatch(editStudent(student?.id, studentEditType));
                    }}
                >
                    {({ handleChange, handleSubmit, setFieldValue, values, errors, touched }) => (
                        <>
                            <div className="text-lg font-semibold mb-6">
                                Add Industry Highlight Details
                            </div>
                            <div className="my-6 w-full md:mb-0 flex">
                                <label
                                    className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                >
                                    Industry Highlight type
                                </label>
                                <div className="flex text-sm md:text-base items-center w-full relative">
                                    <div
                                        className="flex justify-between w-full px-2 h-11 items-center inputFieldThickness"
                                        onClick={() => setDropdownVisible(!dropdownVisible)}
                                    >
                                        <div className={`text-md  text-gray-700`}>
                                            {values.type.val}
                                        </div>
                                        <div className="flex flex-row">
                                            <div className="flex flex-col">
                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                <HiIcons.HiChevronDown className="mr-2" />
                                            </div>
                                        </div>
                                    </div>
                                    {dropdownVisible && (
                                        <ul
                                            className="absolute w-full  cursor-pointer pl-0 top-12 right-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setDropdownVisible(false)}
                                        >
                                            {typeFilter.map(action => (
                                                <div
                                                    className="p-2 pl-2 hover:bg-gray-200 "
                                                    key={action.id}
                                                    onClick={() => {
                                                        setFieldValue('type', action);
                                                        setDropdownVisible(false);
                                                    }}
                                                >
                                                    {action.val}
                                                </div>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div className="my-6 w-full md:mb-4 flex h-28">
                                <label
                                    htmlFor="description"
                                    className={`flex items-start w-1/2 text-sm font-medium leading-149 md:text-base text-gray-700`}
                                >
                                    Notes
                                    <HiIcons.HiQuestionMarkCircle
                                        onClick={() => {
                                            setNotesTooltipVisible(true);
                                        }}
                                        className="ml-2 mt-1 cursor-pointer"
                                    />
                                </label>

                                {notesTooltipVisible ? (
                                    <div
                                        onMouseOverCapture={() => {
                                            setNotesTooltipVisible(true);
                                        }}
                                        onMouseLeave={() => setNotesTooltipVisible(false)}
                                        className="bg-snow-900 border-2 rounded-md absolute z-10 px-4 py-2 mt-7"
                                        style={{
                                            boxShadow: '0px 0px 10px 1px rgba(197, 203, 220, 0.32)',
                                            width: '200px',
                                        }}
                                    >
                                        Please add job interview details, job details and
                                        participated event details in here.
                                    </div>
                                ) : null}
                                <div className="flex flex-col w-full">
                                    <textarea
                                        className={`resize-none focus:outline-none p-2 w-full border-2 text-base h-28 focus:border-blue-900 ${
                                            errors.description && touched.description
                                                ? 'border-red-500'
                                                : 'border-gray-300'
                                        }`}
                                        id="trackDescription"
                                        value={values.description}
                                        onChange={handleChange('description')}
                                    />
                                    {errors.description && touched.description ? (
                                        <div className="text-red-500 text-xs my-1">
                                            {errors.description}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex justify-end mb-1 md:mb-6">
                                <div className="flex">
                                    <button
                                        className="px-6 md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                        onClick={() => {
                                            dispatch(clearStudentMessages());
                                            setModalVisible(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="px-8 md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                        onClick={e => {
                                            dispatch(clearStudentMessages());
                                            e.preventDefault();
                                            setCareerProgressAdded(true);
                                            handleSubmit();
                                        }}
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        </Modal>
    );
}
