import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import * as HiIcons from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import BarChart from './BarChart';
import { InstitutesType } from '../../../../redux/institutes/reducers';
import {
    downloadCSVInstitutewiseProgression,
    getCategories,
    getChallenges,
    getInstituteWiseProgressionData,
    getInstituteWiseProgressionProgressData,
    getInstituteWiseProgressionRegisterData,
    getUsersBridgeInvitationCodes,
} from '../../../../redux/rootActions';
import { TracksType } from '../../../../redux/tracks/reducers';
import { INSTITUTE_ADMIN } from '../../../../redux/authAdmin/reducers';
import { RootState } from '../../../../redux/rootReducer';
import { USER_PROFILE } from '../../../../utils/storage';
import { ChallengesType } from '../../../../redux/challenges/reducers';
import Loader from '../../../../assets/svg/Loader';
import { API_URL } from '../../../../constants';
import CSVEmailedSuccessModal from '../../../modals/CSVEmailedSuccessModal';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
                drawBorder: false,
            },
        },
        y: {
            grid: {
                display: false,
                drawBorder: false,
            },
        },
    },
};

const InstituteProgressionOverview = () => {
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const [showInstitutesDropdown, setShowInstitutesDropdown] = useState(false);
    const [filteredInstitutes, setFilteredInstitutes] = useState([]);
    const isInstiProgCSVLoading = useSelector(
        (state: RootState) => state.dashboard.isInstiProgCSVLoading
    );
    const institutewiseProgressionEmailMessage = useSelector(
        (state: RootState) => state.dashboard.institutewiseProgressionEmailMessage
    );
    const institutewiseProgressionEmailSuccess = useSelector(
        (state: RootState) => state.dashboard.institutewiseProgressionEmailSuccess
    );
    const user = useSelector((state: RootState) => state.users.User);
    const institutes = useSelector((state: RootState) => state.institutes.publicInstitutData);
    const [showCodeDropdown, setShowCodeDropdown] = useState(false);
    const [selectedInvitationCode, setSelectedInvitationCode] = useState([]);
    const invitationCodes = useSelector((state: RootState) => state.users.invitationCodes);

    const [isProgree, setIsProgree] = useState(false);

    const [category1, setCategory1] = useState('');
    const [category2, setCategory2] = useState('');
    const [category3, setCategory3] = useState('');

    const [categoryRegister1, setCategoryRegister1] = useState('');
    const [categoryRegister2, setCategoryRegister2] = useState('');
    const [categoryRegister3, setCategoryRegister3] = useState('');

    const [institute, setInstitute] = useState<InstitutesType>();
    const [track, setTrack] = useState<TracksType>();
    const [challenge, setChallenge] = useState<ChallengesType>();
    const [invitationCode, setInvitationCode] = useState('');

    const [modalVisible, setModalVisible] = useState<boolean>();
    const [message, setMessage] = useState<string>();

    const instituteWiseProgressRegisterData = useSelector(
        (state: RootState) => state.dashboard.instituteWiseProgressRegisterData
    );

    const instituteWiseProgressProgressData = useSelector(
        (state: RootState) => state.dashboard.instituteWiseProgressProgressData
    );
    // const labels = instituteWiseProgressRegisterData?.labels;
    const registerData = {
        labels: instituteWiseProgressRegisterData?.labels,
        datasets: [
            {
                data: instituteWiseProgressRegisterData?.values,
                backgroundColor: ['rgba(196, 181, 253,1)'],
                maxBarThickness: 200,
            },
        ],
    };

    const progressData = {
        labels: instituteWiseProgressProgressData?.labels,
        datasets: [
            {
                data: instituteWiseProgressProgressData?.values,
                backgroundColor: ['rgba(196, 181, 253,1)'],
                maxBarThickness: 200,
            },
        ],
    };

    useEffect(() => {
        if (institutewiseProgressionEmailMessage) {
            setMessage(institutewiseProgressionEmailMessage);
            setModalVisible(true);
        }
    }, [institutewiseProgressionEmailMessage, institutewiseProgressionEmailSuccess]);

    useEffect(() => {
        dispatch(getUsersBridgeInvitationCodes());
    }, [dispatch]);

    useEffect(() => {
        if (user?.roles[0]?.roleName === INSTITUTE_ADMIN) {
            const filteredList = institutes.filter(inst => inst?.id === profile?.instituteId);
            setFilteredInstitutes(filteredList);
        } else {
            setFilteredInstitutes(institutes);
        }
    }, [institutes, profile?.instituteId, setInstitute, user?.roles]);
    useEffect(() => {
        if (institute) {
            dispatch(
                getInstituteWiseProgressionRegisterData(
                    institute?.id,
                    track?.id,
                    invitationCode,
                    categoryRegister1,
                    categoryRegister1,
                    categoryRegister1
                )
            );
        }
    }, [
        dispatch,
        track,
        institute,
        categoryRegister1,
        categoryRegister2,
        categoryRegister3,
        invitationCode,
        challenge?.id,
    ]);

    useEffect(() => {
        if (institute) {
            dispatch(
                getInstituteWiseProgressionProgressData(
                    institute?.id,
                    track?.id,
                    invitationCode,
                    category1,
                    category2,
                    category3,
                    challenge?.id
                )
            );
        }
    }, [
        dispatch,
        track,
        institute,
        category1,
        category2,
        category3,
        invitationCode,
        challenge?.id,
    ]);
    useEffect(() => {
        if (institute) {
            dispatch(getCategories(institute?.id));
            setCategory1('');
            setCategory2('');
            setCategory3('');
            setCategoryRegister1('');
            setCategoryRegister2('');
            setCategoryRegister3('');
        }
    }, [dispatch, institute]);

    useEffect(() => {
        dispatch(getChallenges(0, 100, 'PUBLISHED'));
    }, [dispatch]);

    return (
        <section className="pb-12">
            <div className="flex justify-between items-center mb-4 md:mb-8 pt-12 border-t-2">
                <div
                    className={` text-xl md:text-3xl flex w-full text-center text-black-700 font-bold`}
                >
                    Institute Wise Progression
                </div>
                {/* <button
                    onClick={() => {
                        dispatch(downloadCSVInstitutewiseProgression());
                    }}
                    className="bg-snow-900 border-2 border-blue-700 border-opacity-40 text-blue-700 hover:text-white hover:bg-blue-700 text-sm md:font-semibold rounded-sm w-1/2 md:w-1/3 xl:w-1/5 h-11 focus:outline-none"
                >
                    {isCSVLoading ? <Loader /> : 'Download CSV'}
                </button> */}
                <button
                    onClick={() => {
                        dispatch(downloadCSVInstitutewiseProgression(institute?.id));
                    }}
                    className="bg-snow-900 border-2 border-blue-700 border-opacity-40 text-blue-700 hover:text-white hover:bg-blue-700 text-sm md:font-semibold rounded-sm w-1/2 md:w-1/3 xl:w-1/5 h-11 focus:outline-none"
                >
                    {/* <a href={`${API_URL}/csv/progression/instituteWise`}>
                        {isInstiProgCSVLoading ? <Loader /> : 'Email the CSV'}
                    </a> */}
                    {isInstiProgCSVLoading ? <Loader /> : 'Email the CSV'}
                </button>
            </div>

            <CSVEmailedSuccessModal
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                message={message}
                setMessage={setMessage}
            />

            <div>
                <div className={` text-purple-950 flex w-full text-center font-bold mb-6`}>
                    Filters
                </div>
                <div className="flex w-full mb-2">
                    <div className="mb-2 w-full md:mb-0 flex">
                        <div className="flex flex-col w-full">
                            <div className="w-full flex items-center">
                                <label className="mx-2 w-1/4"> Institute : </label>
                                <div
                                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none md:w-2/3 lg:w-3/5  border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className="flex justify-between w-full md:p-2"
                                        onClick={() =>
                                            setShowInstitutesDropdown(!showInstitutesDropdown)
                                        }
                                    >
                                        <div className="text-gray-700 font-medium pl-2">
                                            {institute ? institute?.instName : 'Institute'}
                                        </div>
                                        <div className="flex flex-col">
                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                            <HiIcons.HiChevronDown className="mr-2" />
                                        </div>
                                    </div>
                                    {showInstitutesDropdown ? (
                                        <ul
                                            className="absolute max-h-48 overflow-y-auto w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                        >
                                            {filteredInstitutes?.map(inst => (
                                                <div
                                                    className="p-2 hover:bg-gray-200 "
                                                    key={inst?.id}
                                                    // value={inst?.id}
                                                    onClick={() => {
                                                        setInstitute(inst);

                                                        setShowInstitutesDropdown(false);
                                                    }}
                                                >
                                                    {inst?.instName}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 w-full md:mb-0 flex">
                        <div className="flex flex-col w-full">
                            <div className="w-full flex items-center">
                                <label className="mx-2 w-1/2"> Bridge Invitation Code : </label>
                                <div
                                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none md:w-2/3 lg:w-3/5 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className="flex justify-between w-full md:p-2"
                                        onClick={() => setShowCodeDropdown(!showCodeDropdown)}
                                    >
                                        <div className="flex flex-wrap">
                                            {selectedInvitationCode.length > 0
                                                ? selectedInvitationCode?.map(action => (
                                                      <div
                                                          style={{
                                                              maxHeight: '10px',
                                                              display: 'flex',
                                                              justifyContent: 'center',
                                                              alignItems: 'center',
                                                          }}
                                                          key={action}
                                                          className={`rounded-full border-2 my-1 mr-4 text-purple-950 border-purple-75 bg-purple-75 font-medium py-3 px-2`}
                                                      >
                                                          {action}
                                                          <HiIcons.HiOutlineX
                                                              style={{ color: '#4C2EC2' }}
                                                              className="ml-2  cursor-pointer"
                                                              onClick={() =>
                                                                  setSelectedInvitationCode(
                                                                      selectedInvitationCode?.filter(
                                                                          item => item !== action
                                                                      )
                                                                  )
                                                              }
                                                          />
                                                      </div>
                                                  ))
                                                : 'Bridge Invitation Code'}
                                        </div>
                                        <div className="flex flex-col">
                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                            <HiIcons.HiChevronDown className="mr-2" />
                                        </div>
                                    </div>
                                    {showCodeDropdown ? (
                                        <ul
                                            className="absolute max-h-48 overflow-y-auto w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                        >
                                            {invitationCodes?.map(option => (
                                                <div className="p-2 hover:bg-gray-200" key={option}>
                                                    <input
                                                        id={option}
                                                        className="styled-checkbox"
                                                        type="checkbox"
                                                        value="I have not done O/Ls yet"
                                                        checked={selectedInvitationCode?.includes(
                                                            option
                                                        )}
                                                        onChange={() => {
                                                            if (
                                                                !selectedInvitationCode?.includes(
                                                                    option
                                                                )
                                                            ) {
                                                                setSelectedInvitationCode([
                                                                    ...selectedInvitationCode,
                                                                    option,
                                                                ]);
                                                            }
                                                            setShowCodeDropdown(false);
                                                        }}
                                                    />
                                                    <label className="ml-3" htmlFor={option}>
                                                        {option}
                                                    </label>
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={` text-purple-950 flex w-full text-center font-bold mb-6`}>
                Student Registrations
            </div>

            <BarChart
                institute={institute}
                setInstitute={setInstitute}
                setCategory1={setCategoryRegister1}
                setCategory2={setCategoryRegister2}
                setCategory3={setCategoryRegister3}
                invitationCode={invitationCode}
                setInvitationCode={setInvitationCode}
                isProgress={false}
                datas={registerData}
            />

            <div className={` text-purple-950 flex w-full text-center font-bold mb-6 mt-12`}>
                Student Progressing
            </div>

            <BarChart
                institute={institute}
                setInstitute={setInstitute}
                setCategory1={setCategory1}
                setCategory2={setCategory2}
                setCategory3={setCategory3}
                track={track}
                setTrack={setTrack}
                invitationCode={invitationCode}
                setInvitationCode={setInvitationCode}
                isProgress={true}
                challenge={challenge}
                setChallenge={setChallenge}
                datas={progressData}
            />
        </section>
    );
};

export default InstituteProgressionOverview;
