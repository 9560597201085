import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../../redux/rootReducer';
import { getCertificates } from '../../../../../redux/rootActions';

import BackArrow from '../../../../../assets/svg/BackArrow';
import { CertificateType } from '../../../../../redux/certificates/reducers';

export default function SolveChalCertificates(): ReactElement {
    const history = useHistory();
    const dispatch = useDispatch();

    const allCertificates = useSelector((state: RootState) => state.certificates.certificatesData);

    useEffect(() => {
        dispatch(getCertificates(null, 100, 'ACTIVE'));
    }, [dispatch]);

    return (
        <>
            <div className="hidden lg:block my-10 px-28">
                <div
                    className="flex mb-3 mt-1 cursor-pointer"
                    onClick={() => history.push(`/solve-challenges`)}
                >
                    <BackArrow size="27" />
                    <p className="text-base ml-2 font-semibold">Back</p>
                </div>
                <div className="flex flex-col justify-between mb-3 ">
                    <div className="font-semibold text-2xl ">All Certificates</div>
                    <div>Explore the certificates available in Future Careers BRIDGE.</div>
                </div>
                <div
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        gap: '25px',
                        marginRight: '1em',
                        flexDirection: 'row',
                        alignContent: 'flex-start',
                    }}
                >
                    {allCertificates &&
                        allCertificates?.map((certificate: CertificateType) => (
                            <div
                                key={certificate.id}
                                style={{ width: '250px' }}
                                className={`rounded border border-gray-200 pb-2`}
                            >
                                <img className="w-full rounded-t" src="/assets/certificate.png" />
                                <div className="ml-3">
                                    <div className="my-3 text-lg font-bold">
                                        {certificate.certificateName}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            <div className="lg:hidden mt-6 mb-10 px-6">
                <div
                    className="flex mb-3 mt-1 cursor-pointer"
                    onClick={() => history.push(`/solve-challenges`)}
                >
                    <BackArrow size="27" />
                    <p className="text-base ml-2 font-semibold">Back</p>
                </div>
                <div className="flex flex-col justify-between mb-3 ">
                    <div className="font-semibold text-lg ">All Certificates</div>
                    <div>Explore the certificates available in Future Careers BRIDGE.</div>
                </div>
                <div className="flex flex-row flex-wrap gap-x-2 gap-y-4">
                    {allCertificates &&
                        allCertificates?.map((certificate: CertificateType) => (
                            <div
                                key={certificate.id}
                                style={{ width: '48%' }}
                                className={`rounded border border-gray-200 pb-2`}
                            >
                                <img className="w-full rounded-t" src="/assets/certificate.png" />
                                <div className="ml-3">
                                    <div className="my-3 font-bold">
                                        {certificate.certificateName}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
}
