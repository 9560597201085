import {
    ADD_TAG,
    ADD_TAG_FAILED,
    ADD_TAG_SUCCESS,
    CLEAR_TAGS_MESSAGES,
    DELETE_TAG,
    DELETE_TAG_FAILED,
    DELETE_TAG_SUCCESS,
    GET_TAGS,
    GET_TAGS_FAILED,
    GET_TAGS_SUCCESS,
} from './actions';

export interface TagsType {
    id: number;
    systemTag: boolean;
    tagCategory: string;
    tagCreatedBy: string;
    tagCreatedDate: string;
    tagLastUpdatedBy: string;
    tagLastUpdatedDate: string;
    tagName: string;
}
export interface CreateTagsType {
    tagCategory: string;
    tagName: string;
    tagCreatedBy: string;
    module: string;
}
export interface TagsInitialState {
    tagData: TagsType[];
    isLoading: boolean;
    tagsError: string | null;
    createTagError: string | null;
    tagCreateSuccessMessage: string | null;
    isCreateLoading: boolean;
    deleteTagError: string | null;
    tagDeleteSuccessMessage: string | null;
    isDeleteLoading: boolean;
}

const tagsInitialState: TagsInitialState = {
    tagData: [],
    isLoading: false,
    tagsError: null,
    createTagError: null,
    tagCreateSuccessMessage: null,
    isCreateLoading: false,
    deleteTagError: null,
    tagDeleteSuccessMessage: null,
    isDeleteLoading: false,
};

const tagsReducer = (state = tagsInitialState, action: any) => {
    switch (action.type) {
        case GET_TAGS:
            return { ...state, isLoading: true, tagError: null };
        case GET_TAGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tagData: action.payload,
            };
        case GET_TAGS_FAILED:
            return { ...state, isLoading: false, tagError: action.payload };
        case ADD_TAG:
            return { ...state, isCreateLoading: true, createTagError: null };
        case ADD_TAG_SUCCESS:
            return { ...state, isCreateLoading: false, tagCreateSuccessMessage: action.payload };
        case ADD_TAG_FAILED:
            return { ...state, isCreateLoading: false, createTagError: action.payload };

        case DELETE_TAG:
            return { ...state, isDeleteLoading: true, deleteTagError: null };
        case DELETE_TAG_SUCCESS:
            return { ...state, isDeleteLoading: false, tagDeleteSuccessMessage: action.payload };
        case DELETE_TAG_FAILED:
            return { ...state, isDeleteLoading: false, deleteTagError: action.payload };
        case CLEAR_TAGS_MESSAGES:
            return {
                ...state,
                tagsError: null,
                createTagError: null,
                tagCreateSuccessMessage: null,
                tagDeleteSuccessMessage: null,
                deleteTagError: null,
            };
        default:
            return state;
    }
};

export default tagsReducer;
