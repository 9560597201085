import { CLEAR_AUTH, SAVE_AUTH_TOKEN, SAVE_EXPIRE_TIME, SAVE_USER_PROFILE } from './actions';

export interface UserProfileType {
    username: string;
    userId: string;
    userEmail: string;
    roles: [];
    showLearnTip: boolean;
    storyShow: boolean;
}
export interface AuthStudentInitialState {
    userProfile: UserProfileType;
    authToken: string;
    expireTime: string;
}

export const initialState: AuthStudentInitialState = {
    userProfile: null,
    authToken: null,
    expireTime: null,
};
const authStudentReducer = (state = initialState, action: any): AuthStudentInitialState => {
    switch (action.type) {
        case SAVE_AUTH_TOKEN:
            return { ...state, authToken: action.payload };
        case SAVE_USER_PROFILE:
            return { ...state, userProfile: action.payload };
        case SAVE_EXPIRE_TIME:
            return { ...state, expireTime: action.payload };
        case CLEAR_AUTH:
            return {
                ...state,
                authToken: null,
                userProfile: null,
            };
        default:
            return state;
    }
};

export default authStudentReducer;
