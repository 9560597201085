import React, { ReactElement, useState } from 'react';
import Modal from 'react-modal';
import { Document, Page, pdfjs } from 'react-pdf';
import CloseIcon from '../../assets/svg/CloseIcon';
import { FiFacebook, FiTwitter, FiLinkedin } from 'react-icons/fi';
import { s3UrlPrefix } from '../../constants';
import { CertificateType, EarnCertificatesType } from '../../redux/certificates/reducers';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';

interface Props {
    showModal: boolean;
    setShowModal: any;
    certificateData: CertificateType;
    earnedCertificateData: EarnCertificatesType;
}

export default function EarnCertificateModal({
    showModal,
    setShowModal,
    certificateData,
    earnedCertificateData,
}: Props): ReactElement {
    // pdf show stuff
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess() {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const domainPrefix = process.env.REACT_APP_DOMAIN;

    const ErrorMsg = 'Oops!! something went wrong';
    const customStyles: Modal.Styles = {
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // top: 0,
            // left: 0,
            // right: 0,
            // bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            zIndex: 999,
        },
        content: {
            // left: '50%',
            // right: 'auto',
            bottom: 'auto',
            top: 'auto',
            right: 'auto',
            left: 'auto',
            // marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    return (
        <Modal
            isOpen={showModal}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => setShowModal(false)}
        >
            <>
                <div className="flex-shrink justify-center  items-center flex   inset-0 z-50 outline-none focus:outline-none">
                    <div style={{ width: 'auto' }} className="relative my-2  mx-auto">
                        {/* content */}

                        <div
                            className="absolute z-50 top-6 right-6 cursor-pointer"
                            onClick={() => setShowModal(false)}
                        >
                            <CloseIcon size={24} color="rgba(17, 17, 17, 0.48)" />
                        </div>
                        <div className="border-2  rounded-lg shadow-lg relative  w-full bg-white outline-none focus:outline-none px-12 pt-10 pb-4">
                            <div className=" mb-2">
                                <h1 className="text-xl font-bold">
                                    {certificateData?.certificateName}
                                </h1>
                            </div>
                            <div className="flex justify-between">
                                <div className="text-purple-500 mb-4 ">
                                    You have scored {certificateData?.earnedMarks}XPS out of{' '}
                                    {certificateData?.totalMarks}XPS
                                </div>
                            </div>
                            {certificateData?.certificateUrl ? (
                                <div>
                                    <Document
                                        file={`${s3UrlPrefix + certificateData?.certificateUrl}`}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page height="500" pageNumber={pageNumber} />
                                    </Document>
                                    <div className="mb-4 font-bold text-sm">
                                        Share your success with your friends
                                    </div>
                                    <div className="flex justify-between">
                                        {certificateData?.certificateCode && (
                                            <div className="grid grid-cols-3 gap-x-4 gap-y-4 text-xs justify-items-center">
                                                <FacebookShareButton
                                                    url={`${domainPrefix}/certificate/public/${certificateData?.certificateCode}`}
                                                    quote={
                                                        'Got a certificate from Future Careers BRIDGE'
                                                    }
                                                    hashtag={'#Future_Careers_BRIDGE'}
                                                >
                                                    <FiFacebook className="text-2xl transform hover:scale-105" />
                                                </FacebookShareButton>
                                                <LinkedinShareButton
                                                    url={`${domainPrefix}/certificate/public/${certificateData?.certificateCode}`}
                                                    title={
                                                        'Got a certificate from Future Careers BRIDGE'
                                                    }
                                                >
                                                    <FiLinkedin className="text-2xl transform hover:scale-105" />
                                                </LinkedinShareButton>
                                                <TwitterShareButton
                                                    url={`${domainPrefix}/certificate/public/${certificateData?.certificateCode}`}
                                                    title={
                                                        'Got a certificate from Future Careers BRIDGE'
                                                    }
                                                    hashtags={['Future_Careers_BRIDGE']}
                                                >
                                                    <FiTwitter className="text-2xl transform hover:scale-105" />
                                                </TwitterShareButton>
                                                <div>Facebook</div>
                                                <div>LinkedIn</div>
                                                <div>Twitter</div>
                                            </div>
                                        )}
                                        <button
                                            onClick={() => setShowModal(false)}
                                            className="w-40 px-2 bg-purple-950 h-12 text-white rounded mt-2 font-bold"
                                        >
                                            Continue Learning
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <p className="mb-4 text-center">{ErrorMsg}</p>
                                    <div className="flex justify-end">
                                        <button
                                            onClick={() => setShowModal(false)}
                                            className="w-40 px-2 bg-purple-950 h-12 text-white rounded mt-2 font-bold"
                                        >
                                            Reload
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        </Modal>
    );
}
