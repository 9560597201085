import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { clearUsersMessages, editUser, getUserById } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import UsersSuccessModal from '../../modals/UsersSuccessModal';
import Loader from '../../../assets/svg/Loader';
import { SLASSCOM_ADMIN, SUPER_ADMIN, UserProfileType } from '../../../redux/authAdmin/reducers';
import { getUserProfile } from '../../../utils/storage';
import { Value } from 'sass';

const Privilages = () => {
    const dispatch = useDispatch();
    const formRef = useRef<any>();
    const history = useHistory();
    const isEditLoading = useSelector((state: RootState) => state.users.isEditLoading);
    const user = useSelector((state: RootState) => state.users.User);
    const updateError = useSelector((state: RootState) => state.users.editUserError);
    const updateSuccessMessage = useSelector(
        (state: RootState) => state.users.UserUpdateSuccessMessage
    );
    const [editSuccessVisible, setEditSuccessVisible] = useState(false);
    const profile: UserProfileType | any = getUserProfile();
    const isSuperAdmin = profile?.roles?.[0] === SUPER_ADMIN;
    const isSlasscomAdmin = profile?.roles?.[0] === SLASSCOM_ADMIN;
    const handleClearMessages = useCallback(() => {
        if (updateError || updateSuccessMessage) {
            setEditSuccessVisible(true);
        }
    }, [dispatch, updateSuccessMessage, updateError]);
    const handleClose = () => {
        dispatch(clearUsersMessages());
        setEditSuccessVisible(false);
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);
    return (
        <div className="mx-6 lg:ml-2 h-full">
            <div className="bg-snow-600">
                <div className="relative">
                    <Formik
                        enableReinitialize={true}
                        innerRef={formRef}
                        initialValues={{
                            // notificationList: user?.notificationList,
                            // notificationList: [],
                            checked: user?.notificationList,
                        }}
                        onSubmit={async values => {
                            await dispatch(
                                editUser(user?.id, {
                                    notificationList: values.checked,
                                })
                            );
                            dispatch(getUserById(profile.userId));
                        }}
                    >
                        {({ handleSubmit }) => (
                            <div className="grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2 pb-8 mb-6">
                                {isSuperAdmin || isSlasscomAdmin ? (
                                    <div className="col-span-12 md:col-span-6 mb-2 md:mb-4">
                                        <div className="mb-6 w-full md:mb-0 flex flex-col my-4">
                                            <p className="font-medium leading-149 text-sm md:text-lg text-gray-700">
                                                Share
                                            </p>
                                            {}{' '}
                                            <li className="list-disc font-medium text-gray-700 mb-3">
                                                Allow users to share documents outside this
                                                organization
                                            </li>
                                            <li className=" list-disc font-medium text-gray-700">
                                                Allow users to receive documents outside this
                                                organization
                                            </li>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex flex-col my-4">
                                            <p className="font-medium leading-149 text-sm md:text-lg text-gray-700">
                                                Export
                                            </p>
                                            <li className="list-disc font-medium text-gray-700 mb-3">
                                                Allow users to export documents outside this
                                                organization
                                            </li>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-span-12 md:col-span-6 mb-2 md:mb-4">
                                        <div className="mb-6 w-full md:mb-0 flex flex-col my-4">
                                            <label className="font-medium text-gray-700 mb-4">
                                                <Field
                                                    type="checkbox"
                                                    name="checked"
                                                    value="STUDENT_PROGRESS"
                                                    className="mr-4"
                                                />
                                                Remind everyday to check student progress.
                                            </label>
                                            <label className="font-medium text-gray-700 mb-4">
                                                <Field
                                                    type="checkbox"
                                                    name="checked"
                                                    value="LEADERBOARD"
                                                    className="mr-4"
                                                />
                                                Notify leaderboard top student.
                                            </label>

                                            {/* <div className="font-medium text-gray-700 mb-4">
                                                <input
                                                    type="checkbox"
                                                    className="mr-4"
                                                    id="notificationList"
                                                    name="notificationList"
                                                    value="LEADERBOARD"
                                                    checked={values.notificationList.includes(
                                                        'LEADERBOARD'
                                                    )}
                                                    onChange={e => {
                                                        handleChange(e.target.value);
                                                    }}
                                                    defaultChecked={user?.privilege?.includes(
                                                        'LEADERBOARD'
                                                    )}
                                                />
                                                Notify leaderboard top student.
                                            </div> */}
                                        </div>

                                        <div className="flex w-full justify-end items-center mb-4 md:mb-6">
                                            <div className="flex w-1/2 lg:w-1/3 justify-end">
                                                <button
                                                    className="bg-snow-900 border-2 border-blue-800 md:mr-4 border-opacity-40 h-11  w-full md:w-1/3 text-blue-900 text-sm rounded focus:outline-none"
                                                    onClick={() => history.push('/admin/settings')}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="bg-blue-700 text-white text h-11 rounded focus:outline-none  w-full md:w-1/3"
                                                    onClick={() => handleSubmit()}
                                                >
                                                    {isEditLoading ? <Loader /> : 'Save'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {editSuccessVisible && (
                                    <UsersSuccessModal
                                        modalVisible={editSuccessVisible}
                                        handleClose={handleClose}
                                    />
                                )}
                            </div>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default Privilages;
