import React, { useState } from 'react';
import JsxParser from 'react-jsx-parser';

import UpArrow from '../../../../../assets/svg/UpArrow';
import DownArrow from '../../../../../assets/svg/DownArrow';
import useWidth from '../../../../hooks/useWidth';
import { useHistory } from 'react-router-dom';
import BackArrow from '../../../../../assets/svg/BackArrow';
import { USER_PROFILE } from '../../../../utils/storageWeb';

const topicData = [
    {
        id: 1,
        title: 'Most common issues',
        sections: [
            {
                id: 1,
                title: 'Troubleshooting sign in issues',
                subSections: [
                    {
                        description: `I don’t remember my password. If you can’t recall the password for your account, you can click on the link to reset your password <br></br> <a class="text-purple-950" href="/dashBoard/settings?tabState=security">Password reset and log into your account</a>`,
                        image: null,
                    },
                    {
                        description:
                            'If you still facing the same login issue please contact us through our email. We will be helping to you to login. <br></br> <a class="text-purple-950" href="mailto: futurecareersbridge@gmail.com">futurecareersbridge@gmail.com</a>',
                        image: null,
                    },
                ],
            },
            {
                id: 2,
                title: 'Troubleshooting file upload',
                subSections: [
                    {
                        description:
                            'Please check you network connection while you upload your file and make sure you don’t go back from the uploading page.',
                        image: null,
                    },
                    {
                        description: 'Check the file format.',
                        image: null,
                    },
                    {
                        description:
                            'If you still facing the same file uploading issue please contact us through our email. We will be helping to you to upload your file. <br></br> <a class="text-purple-950" href="mailto: futurecareersbridge@gmail.com">futurecareersbridge@gmail.com</a>',
                        image: null,
                    },
                ],
            },
        ],
    },
];

const Index = () => {
    const [selectedSection, setSelectedSection] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const viewPortWidth = useWidth();
    const history = useHistory();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));

    const handleAccordion = (index: number) => {
        if (selectedSection === index) {
            setSelectedSection(null);
            setIsExpanded(false);
        } else {
            setSelectedSection(index);
            setIsExpanded(true);
        }
    };

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div className="mx-10 md:mx-5 md:ml-14 lg:ml-72 lg:pl-3.5 lg:pt-2 md:mt-14 lg:mb-28">
                    <div
                        className="flex mb-5 cursor-pointer"
                        onClick={() => history.push(`/dashBoard/help`)}
                    >
                        <BackArrow size="27" />
                        <p className="text-base ml-2 font-semibold  hover:underline">Back</p>
                    </div>
                    <div className="mb-6 border-b-2 border-purple-75">
                        <div className="text-2xl-2 leading-10 font-manrope font-bold mb-2">
                            Trouble Shooting
                        </div>
                        <div className="text-base font-normal mb-7">
                            Experiencing a technical issue?
                        </div>
                    </div>
                    {topicData.map(section => (
                        <div key={section.id} className="mb-4">
                            <div className="text-xl font-bold font-manrope mb-3">
                                {section.title}
                            </div>
                            {section.sections.map(subSection => (
                                <div
                                    key={subSection.id}
                                    style={{ borderWidth: 1 }}
                                    className={`rounded mb-3 
                        ${
                            selectedSection === subSection.id && isExpanded
                                ? 'border-purple-50 bg-purple-85'
                                : 'border-gray-300 bg-transparent'
                        }`}
                                >
                                    <div
                                        className={`px-11 py-8 relative flex items-center text-base cursor-pointer 
                            ${
                                selectedSection === subSection.id && isExpanded
                                    ? 'font-bold text-purple-950'
                                    : 'font-normal'
                            }`}
                                        onClick={() => handleAccordion(subSection.id)}
                                    >
                                        {subSection.title}
                                        <div className="absolute right-7">
                                            {selectedSection === subSection.id && isExpanded ? (
                                                <UpArrow />
                                            ) : (
                                                <DownArrow />
                                            )}
                                        </div>
                                    </div>
                                    {selectedSection === subSection.id &&
                                        isExpanded &&
                                        subSection.subSections.map((item, subIndex) => (
                                            <div
                                                key={subIndex}
                                                className="flex justify-between items-center gap-10 p-6 pl-11 bg-purple-85 border-t-2 border-purple-75"
                                            >
                                                <div
                                                    className={`${
                                                        item.image ? `w-8/12` : `w-full`
                                                    } text-sm font-normal`}
                                                >
                                                    <JsxParser jsx={item.description} />
                                                </div>
                                                {item.image && (
                                                    <img
                                                        className="max-w-sm h-full object-contain"
                                                        src={item.image}
                                                        alt="FAQ step's image"
                                                    />
                                                )}
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ) : (
                <div className="px-4 mt-4">
                    <div className="flex mb-3 mt-1 cursor-pointer" onClick={() => history.goBack()}>
                        <BackArrow size="27" />
                        <p className="text-base ml-2 font-semibold">Back</p>
                    </div>
                    <div className="mb-6 border-b-2 border-purple-75">
                        <div className="text-xl leading-10 font-manrope font-bold mb-2">
                            Trouble Shooting
                        </div>
                        <div className="text-xs font-normal mb-7">
                            Experiencing a technical issue?
                        </div>
                    </div>
                    {topicData.map(section => (
                        <div key={section.id} className="mb-8">
                            <div className="text-lg font-bold font-manrope mb-3">
                                {section.title}
                            </div>
                            {section.sections.map(subSection => (
                                <div
                                    key={subSection.id}
                                    style={{ borderWidth: 1 }}
                                    className={`rounded mb-3 
                            ${
                                selectedSection === subSection.id && isExpanded
                                    ? 'border-purple-50 bg-purple-85'
                                    : 'border-gray-300 bg-transparent'
                            }`}
                                >
                                    <div
                                        className={`px-11 py-8 w-full relative flex items-center text-xs cursor-pointer 
                                ${
                                    selectedSection === subSection.id && isExpanded
                                        ? 'font-bold text-purple-950'
                                        : 'font-normal'
                                }`}
                                        onClick={() => handleAccordion(subSection.id)}
                                    >
                                        <span className="w-11/12">{subSection.title}</span>
                                        <div className="absolute right-7">
                                            {selectedSection === subSection.id && isExpanded ? (
                                                <UpArrow />
                                            ) : (
                                                <DownArrow />
                                            )}
                                        </div>
                                    </div>
                                    {selectedSection === subSection.id &&
                                        isExpanded &&
                                        subSection.subSections.map((item, subIndex) => (
                                            <div
                                                key={subIndex}
                                                className="flex flex-col justify-between items-center gap-y-4 py-5 px-11 bg-purple-85 border-t-2 border-purple-75"
                                            >
                                                {item.image && (
                                                    <img
                                                        className="w-full h-full object-contain"
                                                        src={item.image}
                                                        alt="FAQ step's image"
                                                    />
                                                )}
                                                <div className={`w-full text-xs font-normal`}>
                                                    <JsxParser jsx={item.description} />
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default Index;
