import { getStudentAuthCreds } from '../web/utils/storageWeb';
import { getAdminAuthCreds } from './storage';

interface AxiosConfig {
    headers: {
        Authorization: string;
    };
}

export async function axiosConfig(isAdmin: boolean): Promise<AxiosConfig> {
    const token = isAdmin ? await getAdminAuthCreds() : await getStudentAuthCreds();
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
}
