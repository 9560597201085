import React from 'react';
import { useHistory } from 'react-router';
import { s3UrlPrefix } from '../../../../../constants';
import { CompanyType } from '../../../../../redux/companies/reducers';

interface Props {
    company: CompanyType;
}

const CompanyCard = ({ company }: Props) => {
    const history = useHistory();

    return (
        <>
            <div
                style={{
                    width: '303px',
                    height: '229px',
                }}
                key={company.id}
                className="instituteCardcontainer bg-purple-85 rounded-md hidden lg:flex flex-col items-center justify-center m-4"
            >
                <img
                    className="rounded instituteCardImage"
                    src={
                        company?.comImage !== null
                            ? `${s3UrlPrefix}${company?.comImage}`
                            : '/assets/BlankImage.png'
                    }
                    alt="logo"
                />
                <div className="instituteCardMiddle">
                    <div
                        onClick={() => {
                            history.push(`/company/${company.id}`);
                        }}
                        className="mt-8 border-1 rounded border-2 border-purple-950 text-purple-950 w-32 h-12 flex justify-center items-center font-semibold cursor-pointer"
                    >
                        View more
                    </div>
                </div>
            </div>
            <div
                style={{
                    width: '280px',
                    height: '165px',
                }}
                key={company.id}
                className="instituteCardcontainer bg-purple-85 rounded-md flex flex-col items-center justify-center m-4 lg:hidden"
                onClick={() => {
                    history.push(`/company/${company.id}`);
                }}
            >
                <img
                    className="rounded instituteCardImage"
                    src={
                        company?.comImage !== null
                            ? `${s3UrlPrefix}${company?.comImage}`
                            : '/assets/BlankImage.png'
                    }
                    alt="logo"
                />
            </div>
        </>
    );
};

export default CompanyCard;
