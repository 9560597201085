import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect, { components } from 'react-select';
import ReactMarkdown from 'react-markdown';
import S3 from 'react-aws-s3';
import diff from 'object-diff';
import * as RiIcons from 'react-icons/ri';
import * as HiIcons from 'react-icons/hi';
import Camera from '../../../assets/svg/Camera';
import {
    clearChallengeMessages,
    editChallenge,
    getChallengeById,
    getTags,
    getTracks,
} from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { moduleNames, s3Config, s3UrlPrefix, YOUTUBE_VIDEO_URL_REGEX } from '../../../constants';
import Loader from '../../../assets/svg/Loader';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import { ChallengesType } from '../../../redux/challenges/reducers';
import ChallengeSuccesfullyCreatedModal from '../../modals/ChallengeSuccessfullyCreatedModal';
import { decodeValue, StringToBytesArray } from '../../../utils/stringToBytes';
import { getDecryptedData } from '../../../utils/decrypt';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroups from '../../TagGroups';
import ChallengeAssessment from './ChallengeAssessment';
import { assessmentItems, assessmentBuilder } from './AssesmentFundamentals';

interface CustomState {
    challenge: ChallengesType;
}
interface paramsType {
    id: string;
}
export const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} />
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

function LinkRenderer(props) {
    console.log({ props });
    return (
        <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    );
}

const MarkdownRenderer = ({ children }) => {
    return (
        <ReactMarkdown
            className="prose border-2 h-96 overflow-auto border-gray-300 p-2"
            components={{ a: LinkRenderer }}
        >
            {children?.replace(/(?:\r\n|\r|\n)/g, '  \n')}
        </ReactMarkdown>
    );
};

export default function EditChallenge() {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<paramsType>();
    const challengeState = location.state as CustomState;
    const AssessmentEnd = useRef<any>(null);
    const editChallengeError = useSelector(
        (state: RootState) => state.challenges.editChallengeError
    );
    const isEditLoading = useSelector((state: RootState) => state.challenges.isEditLoading);
    const challengeByIdLoading = useSelector(
        (state: RootState) => state.challenges.challengeByIdLoading
    );
    const tracks = useSelector((state: RootState) => state.tracks.trackData);
    const challenge = useSelector((state: RootState) => state.challenges.challenge);
    const chalengeUpdateSuccess = useSelector(
        (state: RootState) => state.challenges.challengeUpdateSuccessMessage
    );
    const [imageUrl, setImageUrl] = useState(
        `${s3UrlPrefix}${challengeState?.challenge?.imageLink}`
    );
    const [isUploading, setIsUploading] = useState(false);
    const [imageLocation, setImageLocation] = useState(challengeState?.challenge?.imageLink);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [challengeEditSuccessVisible, setChallengeEditSuccessVisible] = useState(false);
    const [challengeErrorVisible, setChallengeErrorVisible] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [tags, setTags] = useState(challengeState?.challenge?.tags);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [optionSelected, setOptionSelected] = useState<any>();
    const [idList] = useState(challengeState?.challenge?.trackIds);
    const [markdownHelpTxt, setMarkdownHelpTxt] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [tagsHelpText, setTagsHelpText] = useState<boolean>(false);
    const [assessment, setAssessment] = useState([]);
    const [assessmentMinimize, setAssessmentMinimize] = useState<any>([]);

    const markdownGuideUrl = 'https://www.markdownguide.org/basic-syntax/';

    const style = {
        control: base => ({
            ...base,
            '&:hover': { borderColor: '#1e3a8a', opacity: 1 },
            border: '2px solid rgba(209, 213, 219, 1)',
            borderRadius: '0.125rem',
            boxShadow: 'none',
        }),
        option: (styles, { isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused
                    ? '#e5e7eb'
                    : isSelected
                    ? '#e5e7eb'
                    : isDisabled
                    ? '#d1d5db'
                    : '#ffffff',
                color: '#333333',
            };
        },
    };
    useEffect(() => {
        if (challenge?.material?.encryptQuestionnaire) {
            const decryptedAssesment = JSON.parse(
                getDecryptedData(challenge.material.encryptQuestionnaire)
            );
            setAssessment(decryptedAssesment);
            setAssessmentMinimize(Array(decryptedAssesment.length).fill(true));
        }
    }, [challenge]);
    useEffect(() => {
        dispatch(getTracks(true, 0, 100));
    }, [dispatch]);

    useEffect(() => {
        const result = idList
            ?.map(id => {
                const track = tracks.find(item => item.id === id);
                return track;
            })
            .filter(item => item !== undefined);

        const tracksArray = [
            result?.map(elem => {
                return {
                    value: elem.id,
                    label: elem.trackName,
                };
            }),
        ];
        setOptionSelected(tracksArray[0]);
    }, [tracks, idList]);

    useEffect(() => {
        setSelectedTags(categorizeTags(tags));
    }, [tags]);

    const selectedTracksIds = optionSelected?.map(elem => {
        return elem.value;
    });
    const serchTagNameOrId = e => {
        setSearchTerm(e.target.value);
    };
    useEffect(() => {
        if (showDropdown) {
            dispatch(getTags(true, moduleNames.Challenges, searchTerm));
        }
    }, [dispatch, searchTerm, showDropdown]);

    useEffect(() => {
        setTags(challengeState?.challenge?.tags);
    }, [challengeState]);

    const handleClearMessages = useCallback(() => {
        if (chalengeUpdateSuccess) {
            setChallengeEditSuccessVisible(true);
        }
        if (editChallengeError) {
            setChallengeErrorVisible(true);
        }
    }, [dispatch, chalengeUpdateSuccess, editChallengeError, history]);
    const handleClose = () => {
        dispatch(clearChallengeMessages());
        if (!editChallengeError) {
            history.push('/admin/challenges/');
        }
    };
    const scrollToBottom = () => {
        AssessmentEnd.current?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);
    useEffect(() => {
        dispatch(getChallengeById(params.id));
    }, [dispatch, params.id]);
    const handleImage = (e: any) => {
        const _URL = window.URL || window.webkitURL;
        let file: File, img;
        if ((file = e.target.files[0])) {
            img = new Image();
            img.src = _URL.createObjectURL(file);
            setImageUrl(img.src);
        }
    };
    const uploadImageToS3 = async e => {
        setIsUploading(true);
        const imageName = Date.now().toString();
        s3Config.dirName = `challenges/${imageName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(e.target.files[0], imageName)
            .then(data => {
                setImageLocation(data.key);
                setIsUploading(false);
            })
            .catch(err => {
                console.error(err);
                setIsUploading(false);
            });
    };
    const handleChangeChallenges = selected => {
        setOptionSelected(selected);
    };
    const tracksArray = [
        tracks?.map(elem => {
            return {
                value: elem.id,
                label: elem.trackName,
            };
        }),
    ];
    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    {challengeByIdLoading ? (
                        <Loader />
                    ) : (
                        <div className="relative">
                            <Formik
                                // enableReinitialize={true}
                                initialValues={{
                                    challengeName: challenge?.challengeName,
                                    challengeCreatedBy: '',
                                    challengeLastUpdatedBy: '',
                                    imageLink: imageLocation,
                                    introLink: challenge?.introLink,
                                    challengeStatus: challenge?.challengeStatus,
                                    challengeDescription: challenge?.challengeDescription,
                                    markdownInput: challenge?.material?.description
                                        ? decodeValue(challenge?.material?.description)
                                        : '',
                                    tracks: optionSelected,
                                }}
                                validationSchema={Yup.object({
                                    challengeName: Yup.string()
                                        .max(100, 'Name should be less than 100 characters')
                                        .required('Name is required'),

                                    challengeDescription: Yup.string()
                                        .nullable()
                                        .max(
                                            1000,
                                            'Challenge description should be less than 1000 characters'
                                        )
                                        .required('Challenge description is required'),
                                    markdownInput: Yup.string()
                                        .max(
                                            10000,
                                            'Challenge description should be less than 10000 characters'
                                        )
                                        .required('Challenge description is required'),
                                    introLink: Yup.string()
                                        .nullable()
                                        .matches(
                                            new RegExp(YOUTUBE_VIDEO_URL_REGEX, 'gm'),
                                            'Please enter a valid URL'
                                        ),
                                    imageLink: Yup.string()
                                        .nullable()
                                        .required('Challenge image is required'),
                                })}
                                onSubmit={async values => {
                                    const updatedFileds = diff(challengeState.challenge, {
                                        challengeName: values.challengeName.trim(),
                                        challengeCreatedBy: '',
                                        challengeLastUpdatedBy: '',
                                        introLink: values.introLink,
                                        challengeStatus: values.challengeStatus,
                                        challengeDescription: values.challengeDescription.trim(),
                                        imageLink: imageLocation,
                                        tags: tags,
                                        trackIds: selectedTracksIds,
                                    });
                                    dispatch(
                                        editChallenge(
                                            challenge.id,
                                            updatedFileds,
                                            null,
                                            StringToBytesArray(values.markdownInput) !==
                                                challenge?.material?.description
                                                ? StringToBytesArray(values.markdownInput)
                                                : null,
                                            JSON.stringify(assessment)
                                        )
                                    );
                                }}
                            >
                                {({
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    values,
                                    errors,
                                    touched,
                                }) => (
                                    <>
                                        <div className="flex justify-between items-center mb-4 md:mb-6">
                                            <p className="text-xl font-semibold text-left">
                                                Edit Challenge
                                            </p>
                                            <div className="flex w-1/2 md:w-1/4 lg:pl-8 h-16">
                                                <button
                                                    className="md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                    onClick={() => {
                                                        dispatch(clearChallengeMessages());
                                                        history.push('/admin/challenges/');
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                    onClick={() => handleSubmit()}
                                                >
                                                    {isEditLoading ? <Loader /> : 'Save'}
                                                </button>
                                            </div>
                                        </div>

                                        <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 pb-4 md:gap-8 pt-2 mb-6">
                                            <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                                <div className="text-lg font-medium mb-6">
                                                    Challenge Details
                                                </div>

                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="name"
                                                        className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Challenge Name
                                                        <div className="text-red-600">*</div>
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                errors.challengeName &&
                                                                touched.challengeName
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="name"
                                                            type="text"
                                                            value={values.challengeName}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'challengeName',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                        {errors.challengeName &&
                                                        touched.challengeName ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.challengeName}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="my-6 w-full md:mb-0 flex">
                                                    <label
                                                        className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Tracks Applicable
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <div className="w-3/4 md:w-4/5 flex">
                                                            <ReactSelect
                                                                options={tracksArray[0]}
                                                                isMulti
                                                                isSearchable={false}
                                                                className={'w-full'}
                                                                styles={style}
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                components={{ Option }}
                                                                onChange={e => {
                                                                    handleChangeChallenges(e);
                                                                    setFieldValue('tracks', e);
                                                                }}
                                                                isDisabled={
                                                                    challenge?.challengeStatus ===
                                                                    'PUBLISHED'
                                                                }
                                                                value={optionSelected}
                                                            />
                                                        </div>
                                                        {errors.tracks && touched.tracks ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.tracks}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="introLink"
                                                        className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Intro Video Link
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                errors.introLink &&
                                                                touched.introLink
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="introLink"
                                                            type="text"
                                                            value={values.introLink}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'introLink',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                        {errors.introLink && touched.introLink ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.introLink}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="my-6 w-full md:mb-0 flex">
                                                    <label
                                                        className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Challenge Status
                                                    </label>
                                                    <div className="flex text-sm md:text-base items-center w-full">
                                                        <input
                                                            className="mr-1"
                                                            type="radio"
                                                            value="PUBLISHED"
                                                            id="publish"
                                                            name="status"
                                                            defaultChecked={
                                                                values.challengeStatus ===
                                                                'PUBLISHED'
                                                            }
                                                            onChange={handleChange(
                                                                'challengeStatus'
                                                            )}
                                                            disabled
                                                        />
                                                        <label className="mr-4 md:mr-8">
                                                            Published
                                                        </label>
                                                        <input
                                                            className="mr-1"
                                                            type="radio"
                                                            value="UNPUBLISHED"
                                                            id="unpublish"
                                                            name="status"
                                                            defaultChecked={
                                                                values.challengeStatus ===
                                                                'UNPUBLISHED'
                                                            }
                                                            onChange={handleChange(
                                                                'challengeStatus'
                                                            )}
                                                            disabled
                                                        />
                                                        <label className="">Unpublished</label>
                                                    </div>
                                                </div>
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="introLink"
                                                        className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Challenge Description
                                                        <div className="text-red-600">*</div>
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <textarea
                                                            className={`resize-none focus:outline-none p-2 w-3/4 md:w-4/5 border-2 text-sm h-24 focus:border-blue-900 border-gray-300`}
                                                            value={values.challengeDescription}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'challengeDescription',
                                                                    e.target.value
                                                                );
                                                            }}
                                                        />
                                                        {errors.challengeDescription &&
                                                        touched.challengeDescription ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.challengeDescription}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="my-6 w-full md:mb-0 flex">
                                                    <label
                                                        htmlFor="description"
                                                        className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Challenge Image
                                                        <div className="text-red-600">*</div>
                                                    </label>
                                                    <div
                                                        className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                                            imageUrl ? 'w-fit' : ''
                                                        }  border-opacity-100 border-black-600 border-2`}
                                                    >
                                                        <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64">
                                                            {!imageLocation && !isUploading && (
                                                                <div className="w-full">
                                                                    <div className="flex justify-center">
                                                                        <Camera />
                                                                    </div>
                                                                    <div>
                                                                        <label
                                                                            className="underline cursor-pointer"
                                                                            htmlFor="imageLink"
                                                                        >
                                                                            Upload Image
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {imageUrl && imageLocation && (
                                                                <img
                                                                    src={imageUrl}
                                                                    alt={'name'}
                                                                    className="h-44 lg:h-64"
                                                                    style={{ maxHeight: 300 }}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="absolute mt-1 text-gray-400 text-sm font-medium leading-149">
                                                            Image size 180*180
                                                        </div>
                                                        <div
                                                            className="absolute mt-6 text-center"
                                                            style={{
                                                                left: '50%',
                                                                transform: 'translate(-50%,-50%)',
                                                            }}
                                                        >
                                                            {imageUrl &&
                                                                imageLocation &&
                                                                !isUploading && (
                                                                    <div className="flex items-center">
                                                                        <label
                                                                            className="underline cursor-pointer"
                                                                            htmlFor="imageLink"
                                                                        >
                                                                            Change Image
                                                                        </label>
                                                                    </div>
                                                                )}
                                                            {isUploading && (
                                                                <div className="flex items-center">
                                                                    <div className="mr-2">
                                                                        Uploading
                                                                    </div>
                                                                    <div>
                                                                        <Loader />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {touched.imageLink ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.imageLink}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <input
                                                        className={`focus:outline-none w-full focus:border-blue-900`}
                                                        id="imageLink"
                                                        type="file"
                                                        accept="image/png, image/jpeg"
                                                        onChange={async e => {
                                                            const file = [];
                                                            file.push(e.target.files[0]);
                                                            setFieldValue(
                                                                'imageLink',
                                                                file[0].name
                                                            );
                                                            handleImage(e);
                                                            uploadImageToS3(e);
                                                        }}
                                                        style={{ display: 'none' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                                <div className="flex border-2 flex-col ml-2 justify-items-center">
                                                    <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                                        <div className="flex sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                            <div>Assign Tags</div>
                                                            <div>
                                                                <HiIcons.HiQuestionMarkCircle
                                                                    onClick={() => {
                                                                        setTagsHelpText(true);
                                                                    }}
                                                                    className="ml-2 mt-1 cursor-pointer"
                                                                />
                                                            </div>
                                                        </div>
                                                        {tagsHelpText ? (
                                                            <div
                                                                onMouseOverCapture={() => {
                                                                    setTagsHelpText(true);
                                                                    // setProfileDropdownVisible(false);
                                                                }}
                                                                onMouseLeave={() =>
                                                                    setTagsHelpText(false)
                                                                }
                                                                className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-6 right-12"
                                                            >
                                                                Assign already created tags to the
                                                                new record created.
                                                            </div>
                                                        ) : null}
                                                        <div
                                                            className="sm:text-sm md:text-base text-gray-400 underline pr-2 cursor-pointer"
                                                            onClick={() => {
                                                                setTags([]);
                                                            }}
                                                        >
                                                            Clear All
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                                        <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                            <TagGroups
                                                                selectedTags={selectedTags}
                                                                tags={tags}
                                                                setTags={setTags}
                                                            />

                                                            <div
                                                                onMouseLeave={() => {
                                                                    setShowDropdown(false);
                                                                }}
                                                            >
                                                                <input
                                                                    className={`rounded w-full text-gray-500 font-medium focus:outline-none mt-2 p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                                                    id="id"
                                                                    type="text"
                                                                    placeholder="Search tags"
                                                                    onChange={e =>
                                                                        serchTagNameOrId(e)
                                                                    }
                                                                    onMouseEnter={() => {
                                                                        setShowDropdown(true);
                                                                    }}
                                                                    autoComplete="off"
                                                                />
                                                                {showDropdown && tagData?.length ? (
                                                                    <ul
                                                                        className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                        role="menu"
                                                                        onMouseLeave={() =>
                                                                            setShowDropdown(false)
                                                                        }
                                                                    >
                                                                        {tagData?.map(tag => (
                                                                            <div
                                                                                className="p-2 hover:bg-gray-200 cursor-pointer"
                                                                                key={tag.id}
                                                                                onClick={() => {
                                                                                    if (
                                                                                        tags
                                                                                            .map(
                                                                                                checktag =>
                                                                                                    checktag.tagName
                                                                                            )
                                                                                            .includes(
                                                                                                tag.tagName
                                                                                            ) ===
                                                                                        false
                                                                                    ) {
                                                                                        setTags([
                                                                                            ...tags,
                                                                                            tag,
                                                                                        ]);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {tag.tagCategory +
                                                                                    ': ' +
                                                                                    tag.tagName}
                                                                            </div>
                                                                        ))}
                                                                    </ul>
                                                                ) : showDropdown &&
                                                                  tagData.length === 0 ? (
                                                                    <ul
                                                                        className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                        role="menu"
                                                                        onMouseLeave={() =>
                                                                            setShowDropdown(false)
                                                                        }
                                                                    >
                                                                        <div className="p-2 flex justify-center font-medium text-gray-400">
                                                                            No records found
                                                                        </div>
                                                                    </ul>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=" ml-2 justify-items-center">
                                                    <div className="flex flex-col text-sm pl-2 lg:pl-4 mt-6 space-y-4 text-gray-400 ">
                                                        <div>
                                                            Created tags can be retrieved by typing
                                                            the text in the search bar.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-2 border-gray-300 px-6 flex w-full pt-2 mb-6 relative">
                                            <div
                                                className={`${
                                                    sidebar === false ? 'w-full' : 'w-1/2'
                                                } mb-2 md:mb-4`}
                                            >
                                                <div className="text-lg flex font-medium mb-6">
                                                    Challenge markdown Description
                                                    <div className="text-red-600">*</div>
                                                    <div className="w-1/4">
                                                        <HiIcons.HiQuestionMarkCircle
                                                            onClick={() => {
                                                                setMarkdownHelpTxt(
                                                                    !markdownHelpTxt
                                                                );
                                                            }}
                                                            className="ml-2 mt-1 cursor-pointer"
                                                        />
                                                        {markdownHelpTxt ? (
                                                            <div
                                                                onMouseOverCapture={() => {
                                                                    setMarkdownHelpTxt(true);
                                                                }}
                                                                onMouseLeave={() =>
                                                                    setMarkdownHelpTxt(false)
                                                                }
                                                                className="border-2 text-sm rounded-md absolute z-50 px-2 py-3 mt-3 bg-white"
                                                                style={{
                                                                    boxShadow:
                                                                        '0px 0px 10px 1px rgba(197, 203, 220, 0.32)',
                                                                    width: '360px',
                                                                    left: 320,
                                                                    top: 0,
                                                                }}
                                                            >
                                                                Guide to use mark down editor{' '}
                                                                <span className="underline cursor-pointer">
                                                                    <a
                                                                        target="_blank"
                                                                        href={markdownGuideUrl}
                                                                        rel="noreferrer"
                                                                    >
                                                                        learn more
                                                                    </a>
                                                                </span>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <textarea
                                                    className={`resize-none focus:outline-none p-2 w-full border-2 text-sm h-96 focus:border-blue-900 border-gray-300`}
                                                    value={values.markdownInput}
                                                    onChange={e =>
                                                        setFieldValue(
                                                            'markdownInput',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                {errors.markdownInput && touched.markdownInput ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {errors.markdownInput}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div
                                                className={`mb-2 md:mb-4 relative border-l-2 ml-2 ${
                                                    sidebar === false ? '' : 'right-auto w-1/2'
                                                }`}
                                            >
                                                {sidebar === false && (
                                                    <div className=" ">
                                                        <RiIcons.RiArrowDropLeftLine
                                                            style={{
                                                                borderRadius: 50,
                                                                borderWidth: 1,
                                                                borderColor: 'rgba(0,0,0,0.2)',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                position: 'absolute',
                                                                height: '25px',
                                                                width: '25px',
                                                                top: '100px',
                                                                left: '-13px',
                                                                backgroundColor: 'white',
                                                            }}
                                                            onClick={showSidebar}
                                                        />
                                                    </div>
                                                )}{' '}
                                                {sidebar === true && (
                                                    <div className="h-full">
                                                        <RiIcons.RiArrowDropRightLine
                                                            style={{
                                                                borderRadius: 50,
                                                                borderWidth: 1,
                                                                borderColor: 'rgba(0,0,0,0.2)',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                position: 'absolute',
                                                                height: '25px',
                                                                width: '25px',
                                                                top: '100px',
                                                                left: '-13px',
                                                                backgroundColor: 'white',
                                                            }}
                                                            onClick={showSidebar}
                                                        />
                                                        <div className="h-96 ml-2 w-full">
                                                            <div className="text-lg font-medium mb-10 md:mb-6">
                                                                Preview
                                                            </div>
                                                            <MarkdownRenderer>
                                                                {values.markdownInput}
                                                            </MarkdownRenderer>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            className={`border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-2 ${
                                                challenge?.challengeStatus === 'PUBLISHED' &&
                                                'pointer-events-none bg-gray-100'
                                            }`}
                                        >
                                            <div className="text-lg font-medium mb-2">
                                                Challenge Assesment
                                            </div>
                                            <div className="col-span-12 md:col-span-5 mb-2 md:mb-4 flex flex-row">
                                                <ul
                                                    className="w-1/4 2xl:w-1/6 mr-4 cursor-pointer  rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                    style={{
                                                        maxHeight: '248px',
                                                        position: 'sticky',
                                                        top: 100,
                                                    }}
                                                >
                                                    {assessmentItems.map(item => (
                                                        <div
                                                            className="flex p-2 pl-2 hover:bg-gray-200 flex-row"
                                                            key={item.id}
                                                            onClick={() => {
                                                                setAssessment([
                                                                    ...assessment,
                                                                    assessmentBuilder(item.type),
                                                                ]);
                                                                scrollToBottom();

                                                                setAssessmentMinimize([
                                                                    ...assessmentMinimize,
                                                                    false,
                                                                ]);
                                                            }}
                                                        >
                                                            <span className="flex items-center px-4">
                                                                {item.icon}
                                                            </span>
                                                            <span>{item.val}</span>
                                                        </div>
                                                    ))}
                                                </ul>
                                                <div className="w-3/4 2xl:w-5/6">
                                                    <ChallengeAssessment
                                                        assessment={assessment}
                                                        setAssessment={setAssessment}
                                                        minimize={assessmentMinimize}
                                                        setMinimize={setAssessmentMinimize}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {challenge?.challengeStatus !== 'PUBLISHED' && (
                                            <div
                                                ref={AssessmentEnd}
                                                className="mb-4 md:mb-0 flex justify-end"
                                            >
                                                <button
                                                    type="submit"
                                                    className="sm:py-1 md:py-3 ml-2 lg:ml-4 hover:bg-red-500 text-red-500 hover:text-white border-2 font-medium border-red-600 border-opacity-40 text-sm rounded-sm w-1/2 md:w-1/6 my-2 focus:outline-none"
                                                    onClick={() =>
                                                        setDeleteConfirmationVisible(true)
                                                    }
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Formik>
                        </div>
                    )}
                </div>
                {chalengeUpdateSuccess ? (
                    <ChallengeSuccesfullyCreatedModal
                        modalVisible={challengeEditSuccessVisible}
                        handleClose={handleClose}
                    />
                ) : null}
                {editChallengeError ? (
                    <ChallengeSuccesfullyCreatedModal
                        modalVisible={challengeErrorVisible}
                        handleClose={handleClose}
                    />
                ) : null}
                {deleteConfirmationVisible ? (
                    <DeleteConfirmationModal
                        modalVisible={deleteConfirmationVisible}
                        setModalVisible={setDeleteConfirmationVisible}
                        tabStatus="PUBLISHED"
                        challengeId={challenge.id}
                    />
                ) : null}
            </div>
        </div>
    );
}
