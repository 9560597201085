import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { RootState } from '../../redux/rootReducer';

interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
}

export default function StudentSuccessfullModal({
    modalVisible,
    setModalVisible,
}: Props): ReactElement {
    const createTrackError = useSelector((state: RootState) => state.tracks.createTrackError);
    const trackCreateSuccess = useSelector(
        (state: RootState) => state.tracks.trackCreateSuccessMessage
    );
    const StudentUpdateSuccess = useSelector(
        (state: RootState) => state.students.StudentUpdateSuccessMessage
    );
    const editTrackError = useSelector((state: RootState) => state.tracks.editTrackError);
    return (
        <Modal
            open={modalVisible}
            onClose={() => {
                setModalVisible(false);
            }}
            center
            showCloseIcon={false}
            styles={{
                modal: {
                    borderRadius: 5,
                    maxWidth: '628px',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    boxShadow: '0 12px 15px 0 rgb(0 0 0 / 10%)',
                },
                overlay: { background: 'none' },
            }}
        >
            <div className="">
                <div className="text-base text-center text-gray-800 px-4 pt-6 pb-4">
                    {trackCreateSuccess ||
                        createTrackError ||
                        StudentUpdateSuccess ||
                        editTrackError}
                </div>
                <div className="flex justify-end w-full">
                    <button
                        className="bg-blue-700 text-white rounded-sm focus:outline-none mt-4 px-8 mr-2 md:mr-0 py-2"
                        onClick={() => {
                            setModalVisible(false);
                        }}
                    >
                        OK
                    </button>
                </div>
            </div>
        </Modal>
    );
}
