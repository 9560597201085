import React, { useState } from 'react';
import Slider from 'react-slick';
import NextButton from '../../../../../assets/svg/NextButton';
import FeaturedSuccessStories from './FeaturedSuccessStories';
import ModalVideo from 'react-modal-video';
import { StoryType } from '../../../../../redux/successStories/reducers';
import useWidth from '../../../../hooks/useWidth';

interface Props {
    stories: StoryType[];
}

const FeaturedSuccessStory: React.FC<Props> = ({ stories }) => {
    let slider: any;
    const [showModal, setShowModal] = useState(false);
    const [selectedUrl, setSelectedUrl] = useState('');
    const viewPortWidth = useWidth();
    function SampleNextArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                style={
                    viewPortWidth > 1023
                        ? {
                              ...style,
                              position: 'absolute',
                              right: '-90px',
                          }
                        : {
                              ...style,
                              position: 'absolute',
                              right: '-25px',
                          }
                }
                className={`nextPreviousButton cursor-pointer ${
                    viewPortWidth <= 1023 ? 'z-50' : ''
                }`}
                onClick={onClick}
            >
                <NextButton />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                style={
                    viewPortWidth > 1023
                        ? {
                              ...style,
                              position: 'absolute',
                              left: '-80px',
                              transform: 'rotate(180deg)',
                          }
                        : {
                              ...style,
                              position: 'absolute',
                              left: '-25px',
                              transform: 'rotate(180deg)',
                          }
                }
                className={`nextPreviousButton cursor-pointer ${
                    viewPortWidth <= 1023 ? 'z-50' : ''
                }`}
                onClick={onClick}
            >
                <NextButton />
            </div>
        );
    }

    const settings = {
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        variableWidth: true,
        infinite: false,
        dots: false,
        speed: 500,
        slidesToShow: 1,
    };

    const smallScreenSettings = {
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        variableWidth: false,
        infinite: true,
        dots: false,
        speed: 500,
        slidesToShow: 1,
    };

    const selectedURLHandler = (url: string) => {
        setSelectedUrl(url);
    };

    const showModalHandler = (data: boolean) => {
        setShowModal(data);
    };

    return (
        <>
            {viewPortWidth > 1023 ? (
                <>
                    <div className="mt-6 mb-10 w-10/12">
                        <Slider ref={c => (slider = c)} {...settings}>
                            {stories?.map((story: StoryType) => (
                                <FeaturedSuccessStories
                                    key={story.id}
                                    story={story}
                                    selectedURLHandler={selectedURLHandler}
                                    showModalHandler={showModalHandler}
                                />
                            ))}
                        </Slider>
                    </div>
                    <div className="flex justify-center">
                        <ModalVideo
                            channel="youtube"
                            autoplay
                            isOpen={showModal}
                            videoId={selectedUrl}
                            onClose={() => setShowModal(false)}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="mt-6 mb-10 w-10/12">
                        <Slider ref={c => (slider = c)} {...smallScreenSettings}>
                            {stories?.map((story: StoryType) => (
                                <FeaturedSuccessStories
                                    key={story.id}
                                    story={story}
                                    selectedURLHandler={selectedURLHandler}
                                    showModalHandler={showModalHandler}
                                />
                            ))}
                        </Slider>
                    </div>
                    <div className="flex justify-center">
                        <ModalVideo
                            channel="youtube"
                            autoplay
                            isOpen={showModal}
                            videoId={selectedUrl}
                            onClose={() => setShowModal(false)}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default FeaturedSuccessStory;
