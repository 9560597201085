import {
    ADD_COMPANY_TAB,
    ADD_COMPANY_TAB_FAILED,
    ADD_COMPANY_TAB_SUCCESS,
    GET_COMPANY_TAB,
    GET_COMPANY_TAB_FAILED,
    GET_COMPANY_TAB_SUCCESS,
} from './actions';
import { CompanyType } from '../companies/reducers';

export interface CountType {
    ALL: number;
    ACTIVE: number;
    INACTIVE: number;
    DELETED: number;
}

export interface CompaniesTabInitialState {
    companiesData: CompanyType[];
    pageCount: number;
    count: CountType;
    companyTabData: [];
    isLoading: boolean;
    companyByIdLoading: boolean;
    companyByIdError: string | null;
    companiesError: string | null;
    createChallengeError: string | null;
    companyCreateSuccessMessage: string | null;
    isDeleteLoading: boolean;
    deleteChallengeError: string | null;
    companyDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editChallengeError: string | null;
    companyUpdateSuccessMessage: string | null;
    isAddMaterialLoading: boolean;
    materialError: string | null;
    materialSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    companyBulkSuccessMessage: string | null;
}

const initialState: CompaniesTabInitialState = {
    companiesData: [],
    pageCount: null,
    count: null,
    companyTabData: [],
    isLoading: false,
    companyByIdLoading: false,
    companyByIdError: null,
    companiesError: null,
    createChallengeError: null,
    companyCreateSuccessMessage: null,
    isDeleteLoading: false,
    deleteChallengeError: null,
    companyDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editChallengeError: null,
    companyUpdateSuccessMessage: null,
    isAddMaterialLoading: false,
    materialError: null,
    materialSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    companyBulkSuccessMessage: null,
};

const companiesTabReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_COMPANY_TAB:
            return { ...state, isLoading: true, trackError: null };
        case ADD_COMPANY_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                companiesData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case ADD_COMPANY_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        case GET_COMPANY_TAB:
            return { ...state, isLoading: true, trackError: null };
        case GET_COMPANY_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                companyTabData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case GET_COMPANY_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        default:
            return state;
    }
};

export default companiesTabReducer;
