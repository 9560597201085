import React, { ReactElement, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Redirect, useHistory } from 'react-router-dom';
import useWidth from '../../hooks/useWidth';
import ProfileNavBarMobileExpandablePublicSite from '../SideBar/ProfileNavBarMobileExpandablePublicSite';
import { Nav, NavLink, NavMenu, NavBtn, SignInBtnLink, RegisterBtnLink } from './NavbarElements';

interface Props {
    isNavBarExpanded: boolean;
    setIsNavBarExpanded: (value: boolean) => void;
}

export default function Navbar({ isNavBarExpanded, setIsNavBarExpanded }: Props): ReactElement {
    const isSideBar = false;
    const viewWidth = useWidth();
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    if (window.location.pathname.includes('/dashBoard')) {
        return (
            <Redirect
                to={{
                    pathname: '/signin',
                    state: { redirect: window.location.pathname },
                }}
            />
        );
    }

    return (
        <>
            <div style={{ height: 70 }}>
                {isSideBar ? (
                    'sidebar'
                ) : viewWidth > 1023 ? (
                    <Nav style={{ borderBottom: '1px solid #F4EDE5' }} className="bg-snow-900 z-50">
                        <NavLink to="/">
                            <img src={`/assets/NewWeb.png`} width="200" alt="logo" />
                        </NavLink>
                        <NavMenu>
                            <NavLink to="/home">Home</NavLink>
                            <NavLink to="/solve-challenges">Solve Challenges</NavLink>
                            <NavLink to="/leaderboard">Leaderboard</NavLink>
                            <NavLink to="/companies">Companies</NavLink>
                            <NavLink to="/success-stories">Success Stories</NavLink>
                            <NavLink to="/events">Events</NavLink>
                            <NavLink to="/institutes">Institutes</NavLink>
                            {/* Second Nav */}
                            {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
                        </NavMenu>
                        <NavBtn>
                            <SignInBtnLink
                                className={`${
                                    window.location.href.includes('/signin')
                                        ? 'cursor-default bg-purple-950 text-white'
                                        : 'hover:bg-purple-950 hover:text-white text-purple-950'
                                }`}
                                to="/signin"
                            >
                                Sign in
                            </SignInBtnLink>
                            <RegisterBtnLink
                                className={`${
                                    window.location.href.includes('/register')
                                        ? 'cursor-default bg-purple-950 text-white'
                                        : 'hover:bg-purple-950 hover:text-white text-purple-950'
                                }`}
                                to="/register"
                            >
                                Register
                            </RegisterBtnLink>
                        </NavBtn>
                    </Nav>
                ) : (
                    <>
                        <Nav
                            style={{
                                borderBottom: '1px solid #F4EDE5',
                                height: 70,
                                zIndex: 100,
                            }}
                            className="bg-snow-900 z-50"
                        >
                            <div className="pr-8 pl-5 flex items-center justify-between h-full w-full">
                                <div>
                                    <img
                                        onClick={() => {
                                            history.push('/');
                                        }}
                                        className="cursor-pointer"
                                        src={`/assets/NewWeb.png`}
                                        width="110"
                                        alt="logo"
                                    />
                                </div>
                                <div>
                                    {!isNavBarExpanded ? (
                                        <FaBars
                                            color="#000000"
                                            width={16}
                                            onClick={() => setIsNavBarExpanded(true)}
                                        />
                                    ) : (
                                        <FaTimes
                                            color="#000000"
                                            width={12}
                                            onClick={() => setIsNavBarExpanded(false)}
                                        />
                                    )}
                                </div>
                            </div>
                        </Nav>
                        <ProfileNavBarMobileExpandablePublicSite
                            isShowExpandable={isNavBarExpanded}
                            setIsNavBarExpanded={setIsNavBarExpanded}
                        />
                    </>
                )}
            </div>
        </>
    );
}
