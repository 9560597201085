import React, { ReactElement } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CloseIcon from '../../../../assets/svg/CloseIcon';
import { RESET_CONFIRM_MOBILE_VERIFICATION } from '../../../../redux/rootActions';

interface Props {
    showModal: boolean;
    setShowModal: any;
    message: string;
}

export default function ChangeMobileResponseModal({
    showModal,
    setShowModal,
    message,
}: Props): ReactElement {
    const history = useHistory();
    const dispatch = useDispatch();
    const settingsURL = `/dashBoard/settings`;
    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    return (
        <div>
            <Modal isOpen={showModal} style={customStyles} ariaHideApp={false}>
                <>
                    <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                        <div style={{ width: '400px' }} className="relative my-6  mx-auto">
                            <div
                                className="absolute z-50 top-6 right-6 cursor-pointer"
                                onClick={() => {
                                    setShowModal(false);
                                    dispatch({ type: RESET_CONFIRM_MOBILE_VERIFICATION });
                                    history.push(settingsURL);
                                }}
                            >
                                <CloseIcon size={24} color="rgba(17, 17, 17, 0.48)" />
                            </div>
                            <div
                                className="border-2  rounded-lg shadow-lg relative flex flex-col items-center w-full bg-white outline-none focus:outline-none p-6"
                                style={{ maxHeight: '656px' }}
                            >
                                <div className="flex justify-center p-8">{message}</div>
                                <button
                                    type="button"
                                    className="px-4 bg-purple-700 h-12 text-white rounded font-bold"
                                    onClick={() => {
                                        setShowModal(false);
                                        dispatch({ type: RESET_CONFIRM_MOBILE_VERIFICATION });
                                        history.push({
                                            pathname: settingsURL,
                                            state: {
                                                selectedTab: 'security',
                                            },
                                        });
                                    }}
                                >
                                    Return to account security
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        </div>
    );
}
