import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import Loader from '../../assets/svg/Loader';
import { disableConfirmationMessage, restoreConfirmationMessage } from '../../constants';
import { ChallengeCreateType } from '../../redux/challenges/reducers';
import {
    bulkUpdate,
    bulkUpdateBadges,
    bulkUpdateCertificates,
    bulkUpdateChallenges,
    editChallenge,
    editTrack,
    getBadges,
    getCertificates,
    TrackCreateType,
} from '../../redux/rootActions';
import { RootState } from '../../redux/rootReducer';
interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    trackId?: number;
    challengeId?: string;
    challenge?: ChallengeCreateType;
    track?: TrackCreateType;
    tracks?: any;
    challenges?: any;
    selectedTab?: number;
    tabStatus: string;
    selectedTracks?: any;
    selectedChallenges?: any;
}

export default function DisableRestoreConfirmationModal({
    modalVisible,
    setModalVisible,
    trackId,
    challengeId,
    track,
    challenge,
    tracks,
    challenges,
    selectedTab,
    tabStatus,
    selectedTracks,
    selectedChallenges,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const isEditLoading = useSelector((state: RootState) => state.tracks.isEditLoading);
    const isTracksBulkUpdateLoading = useSelector(
        (state: RootState) => state.tracks.isBulkUpdateLoading
    );
    const isChallengeBulkUpdateLoading = useSelector(
        (state: RootState) => state.challenges.isBulkUpdateLoading
    );

    const certificatesData = useSelector((state: RootState) => state.certificates.certificatesData);
    const badgesData = useSelector((state: RootState) => state.badges.BadgesData);

    const [certificates, setCertificated] = useState<any>();
    const [badges, setBadges] = useState<any>();

    useEffect(() => {
        dispatch(getCertificates(0, 100, 'ACTIVE'));
        dispatch(getBadges(0, 100, 'ACTIVE'));
    }, [dispatch]);

    useEffect(() => {
        setCertificated(certificatesData?.filter(certificate => certificate.track.id === trackId));
    }, [certificatesData]);

    useEffect(() => {
        setBadges(badgesData?.filter(badge => badge.criteria.idList[0] === challengeId));
    }, [badgesData]);
    // changeable content count
    const changeCount = (selectedChallenges || selectedTracks)?.filter(
        item => (item.trackStatus || item.challengeStatus) !== 'UNPUBLISHED'
    ).length;
    const messageMaker = () => {
        if (selectedTab !== 3) {
            if (tracks || challenges) {
                return changeCount === 0
                    ? `The selected records are already in unpublished state`
                    : changeCount === (selectedChallenges || selectedTracks).length
                    ? `${restoreConfirmationMessage} ${tracks ? 'tracks' : 'certificates'}`
                    : `Only ${changeCount} ${tracks ? 'track' : 'certificate'}${
                          changeCount > 1 ? 's are' : ' is'
                      } in published state. Are you sure you want to unpublish ${
                          changeCount > 1 ? 'them' : 'it'
                      }?`;
            } else {
                const postFix = trackId ? ' track?' : ' challenge?';
                return restoreConfirmationMessage + postFix;
            }
        } else {
            return `${restoreConfirmationMessage}${challengeId ? ' challenge?' : ' track?'}`;
        }
    };
    return (
        <Modal
            open={modalVisible}
            onClose={() => {
                setModalVisible(false);
            }}
            center
            styles={{ modal: { borderRadius: 12, maxWidth: 555 } }}
            focusTrapped={false}
        >
            <div className="px-4 mt-10 md:px-5">
                <div className="text-lg ml-4" style={{ color: '#9698A9' }}>
                    {messageMaker()}
                </div>
                <div className="mt-8 flex justify-end">
                    <button
                        className="border rounded-md border-blue-600 text-blue-600 text-sm h-10 px-8 mr-2 focus:outline-none"
                        onClick={() => {
                            setModalVisible(false);
                        }}
                    >
                        Cancel
                    </button>

                    {changeCount !== 0 && (
                        <button
                            className="border bg-blue-600 rounded-md text-white text-sm h-10 leading-4 px-8 ml-2 focus:outline-none hover:text-white hover:bg-blue-800"
                            onClick={async () => {
                                if (trackId) {
                                    dispatch(editTrack(trackId, track, tabStatus));
                                } else if (challengeId) {
                                    dispatch(editChallenge(challengeId, challenge, tabStatus));
                                } else if (tracks) {
                                    dispatch(bulkUpdate(tracks, tabStatus));
                                }
                                // else if (challenges) {
                                //     dispatch(bulkUpdateChallenges(challenges, tabStatus));
                                // }
                                setModalVisible(false);
                            }}
                        >
                            {isEditLoading ? (
                                <Loader />
                            ) : isTracksBulkUpdateLoading ? (
                                <Loader />
                            ) : isChallengeBulkUpdateLoading ? (
                                <Loader />
                            ) : selectedTab !== 3 ? (
                                'Unpublish'
                            ) : (
                                'Restore'
                            )}
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
}
