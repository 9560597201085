import axios from 'axios';
import { API_URL, deleteTrackSuccessMessage } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { ChallengesType } from '../challenges/reducers';

const PREFIX = '@TRACKS_';

export const GET_TRACKS = PREFIX + 'GET_TRACKS';
export const GET_TRACKS_SUCCESS = PREFIX + 'GET_TRACKS_SUCCESS';
export const GET_TRACKS_FAILED = PREFIX + 'GET_TRACKS_FAILED';
export const GET_TRACKS_BY_ID = PREFIX + 'GET_TRACKS_BY_ID';
export const GET_TRACKS_BY_ID_SUCCESS = PREFIX + 'GET_TRACKS_BY_ID_SUCCESS';
export const GET_TRACKS_BY_ID_FAILED = PREFIX + 'GET_TRACKS_BY_ID_FAILED';
export const GET_TRACKS_BY_CHALLENGE_STATUS = PREFIX + 'GET_TRACKS_BY_CHALLENGE_STATUS';
export const GET_TRACKS_BY_CHALLENGE_STATUS_SUCCESS =
    PREFIX + 'GET_TRACKS_BY_CHALLENGE_STATUS_SUCCESS';
export const GET_TRACKS_BY_CHALLENGE_STATUS_FAILED =
    PREFIX + 'GET_TRACKS_BY_CHALLENGE_STATUS_FAILED';
export const GET_RECOMMANDED_TRACKS = PREFIX + 'GET_RECOMMANDED_TRACKS';
export const GET_RECOMMANDED_TRACKS_SUCCESS = PREFIX + 'GET_RECOMMANDED_TRACKS_SUCCESS';
export const GET_RECOMMANDED_TRACKS_FAILED = PREFIX + 'GET_RECOMMANDED_TRACKS_FAILED';
export const ADD_TRACK = PREFIX + 'ADD_TRACK';
export const ADD_TRACK_SUCCESS = PREFIX + 'ADD_TRACK_SUCCESS';
export const ADD_TRACK_FAILED = PREFIX + 'ADD_TRACK_FAILED';
export const EDIT_TRACK = PREFIX + 'EDIT_TRACK';
export const EDIT_TRACK_SUCCESS = PREFIX + 'EDIT_TRACK_SUCCESS';
export const EDIT_TRACK_FAILED = PREFIX + 'EDIT_TRACK_FAILED';
export const DELETE_TRACK = PREFIX + 'DELETE_TRACK';
export const DELETE_TRACK_SUCCESS = PREFIX + 'DELETE_TRACK_SUCCESS';
export const DELETE_TRACK_FAILED = PREFIX + 'DELETE_TRACK_FAILED';
export const BULK_UPDATE = PREFIX + 'BULK_UPDATE';
export const BULK_UPDATE_SUCCESS = PREFIX + 'BULK_UPDATE_SUCCESS';
export const BULK_UPDATE_FAILED = PREFIX + 'BULK_UPDATE_FAILED';
export const CLEAR_TRACK_MESSAGES = PREFIX + 'CLEAR_TRACK_MESSAGES';

export interface TrackCreateType {
    trackName: string | null;
    trackCreatedBy: string | null;
    trackDescription: string | null;
    trackStatus: string | null;
    imageLink: string | null;
    tags?: any[];
    challenges?: ChallengesType[];
}

export interface TrackAndCertificateCreateType {
    trackName: string | null;
    trackCreatedBy: string | null;
    trackDescription: string | null;
    trackStatus: string | null;
    imageLink: string | null;
    tags?: any[];
    certificateName: string | null;
    description: string | null;
    contentDocUrl: string | null;
    certStatus: string | null;
}

export interface InstituteType {
    orgName: string;
    orgEmail: string;
}

export interface challenges {
    from: number | null;
    to: number | null;
}
export interface trackCreatedDate {
    from: string | null;
    to: string | null;
}
export interface students {
    from: string | null;
    to: string | null;
}
export interface trackLastUpdatedDate {
    from: string | null;
    to: string | null;
}

export const getTracks =
    (
        isAdmin: boolean,
        page?: number,
        size?: number,
        status?: string,
        tags?: string,
        searchTerm?: string,
        challenges?: string
    ) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_TRACKS });
            const config = await axiosConfig(isAdmin);
            const response = await axios.get(
                `${API_URL}/tracks?page=${page || 0}&size=${size || 10}${
                    status ? `&status=${status}` : ''
                }${searchTerm ? `&searchText=${searchTerm}` : ''}${tags ? `&tags=${tags}` : ''}${
                    challenges ? `&challenges=${challenges}` : ''
                }`,
                config
            );
            dispatch({ type: GET_TRACKS_SUCCESS, payload: response.data.responseDto });
        } catch (error) {
            dispatch({ type: GET_TRACKS_FAILED, payload: getErrorMessage(error) });
        }
    };

export const getTrackById = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_TRACKS_BY_ID });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/tracks/${id}`, config);
        dispatch({ type: GET_TRACKS_BY_ID_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_TRACKS_BY_ID_FAILED, payload: getErrorMessage(error) });
    }
};

export const getTrackByChallengeStatus = (studentId: number | string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_TRACKS_BY_CHALLENGE_STATUS });
        const config = await axiosConfig(false);
        const response = await axios.get(
            `${API_URL}/tracks/groupby/challenge-status?studentId=${studentId}`,
            config
        );
        dispatch({
            type: GET_TRACKS_BY_CHALLENGE_STATUS_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_TRACKS_BY_CHALLENGE_STATUS_FAILED, payload: getErrorMessage(error) });
    }
};

export const getRecommandedTracks = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_RECOMMANDED_TRACKS });
        const config = await axiosConfig(false);
        const response = await axios.get(`${API_URL}/tracks/recommend?studentId=${id}`, config);

        dispatch({ type: GET_RECOMMANDED_TRACKS_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_RECOMMANDED_TRACKS_FAILED, payload: getErrorMessage(error) });
    }
};

export const addTrack = (track: TrackCreateType) => async (dispatch: any) => {
    try {
        dispatch({ type: ADD_TRACK });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/tracks/`, track, config);
        await dispatch(getTracks(true));
        if (res?.data?.status) {
            if (res?.data?.errorCode === 204)
                dispatch({ type: ADD_TRACK_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 204) {
                dispatch({
                    type: ADD_TRACK_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: ADD_TRACK_FAILED, payload: getErrorMessage(error) });
    }
};

export const addTrackAndCertificate = (track: any) => async (dispatch: any) => {
    try {
        dispatch({ type: ADD_TRACK });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/tracks/certificate`, track, config);
        await dispatch(getTracks(true));
        if (res?.data?.status) {
            if (res?.data?.errorCode === 204)
                dispatch({ type: ADD_TRACK_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 204) {
                dispatch({
                    type: ADD_TRACK_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: ADD_TRACK_FAILED, payload: getErrorMessage(error) });
    }
};

export const editTrack =
    (id: number, track: TrackCreateType, status?: string) => async (dispatch: any) => {
        try {
            dispatch({ type: EDIT_TRACK });
            const config = await axiosConfig(true);
            const res = await axios.put(`${API_URL}/tracks/${id}`, track, config);
            await dispatch(getTracks(true, 0, 10, status));
            if (res?.data?.status) {
                dispatch({ type: EDIT_TRACK_SUCCESS, payload: res.data.responseDto });
            }
            if (!res?.data?.status) {
                if (res?.data?.errorCode !== 205) {
                    dispatch({
                        type: EDIT_TRACK_FAILED,
                        payload: res.data.errorDescription,
                    });
                }
            }
        } catch (error) {
            dispatch({ type: EDIT_TRACK_FAILED, payload: getErrorMessage(error) });
        }
    };

export const deleteTrack = (id: number, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: DELETE_TRACK });
        const config = await axiosConfig(true);
        await axios.delete(`${API_URL}/tracks/${id}`, config);
        await dispatch(getTracks(true, 0, 10, status));
        dispatch({ type: DELETE_TRACK_SUCCESS, payload: deleteTrackSuccessMessage });
    } catch (error) {
        dispatch({ type: DELETE_TRACK_FAILED, payload: getErrorMessage(error) });
    }
};

export const clearTrackMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_TRACK_MESSAGES });
};

export const bulkUpdate = (tracks, tabStatus) => async (dispatch: any) => {
    try {
        dispatch({ type: BULK_UPDATE });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/tracks/bulk`, tracks, config);
        await dispatch(getTracks(true, 0, 10, tabStatus));
        if (res?.data?.status) {
            if (res?.data?.errorCode === 202)
                dispatch({ type: BULK_UPDATE_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 202) {
                dispatch({
                    type: BULK_UPDATE_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: BULK_UPDATE_FAILED, payload: getErrorMessage(error) });
    }
};
