import React, { useEffect, useState } from 'react';
import SettingsPages from './SettingsPages';
import { useLocation } from 'react-router-dom';
interface tabStateType {
    selectedTab: number;
}
export const Settings = () => {
    const location = useLocation();
    const tabState = location.state as tabStateType;
    const [selectedTab, setSelectedTab] = useState(1);
    useEffect(() => {
        if (tabState) {
            setSelectedTab(tabState.selectedTab);
        }
    }, [tabState]);
    return (
        <div className="mx-6 lg:ml-0">
            <div className="mt-24 bg-snow-600">
                <div className={`border-b-4 relative w-full border-gray-300`} role="presentation">
                    <div
                        className={`py-2 text-xs sm:text-lg md:text-2xl absolute bottom-0 flex w-full text-center text-blue-700 font-semibold`}
                    >
                        Settings
                    </div>
                </div>

                <div className="flex w-full">
                    <div className={`mr-4 w-full`}>
                        <SettingsPages selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                    </div>
                </div>
            </div>
        </div>
    );
};
