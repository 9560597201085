import React, { ReactElement } from 'react';

interface Props {
    color: string;
    size?: number;
}

export default function DashBoard({ color, size }: Props): ReactElement {
    return (
        <svg
            width={size || '24'}
            height={size || '25'}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.4568 13.1619L20.457 13.1602C21.0125 8.64436 17.9318 4.52565 13.5008 3.74835V2.72745C17.7344 3.39782 21.0433 6.85171 21.453 11.1763L21.4533 11.1789C21.9572 16.2186 18.4405 20.71 13.5008 21.4908V20.4917C17.1612 19.8616 19.9873 16.8771 20.4568 13.1619ZM11.5008 12.8461V7.62918H12.0008V12.3792V12.6639L12.2456 12.8092L16.3104 15.221L16.0823 15.595L11.5008 12.8461ZM6.41649 4.44139C7.62347 3.55644 9.02568 2.98589 10.5008 2.75617V3.76548C9.29263 3.99773 8.14863 4.48763 7.15201 5.20262L6.41649 4.44139ZM3.62444 10.6292H2.61809C2.84951 9.15573 3.42891 7.75844 4.30807 6.55358L5.03166 7.27716C4.32389 8.28161 3.84775 9.42694 3.62444 10.6292ZM5.03358 16.9814L4.31872 17.7013C3.44707 16.4959 2.86813 15.1027 2.63062 13.6292H3.63281C3.85273 14.8344 4.3306 15.978 5.03358 16.9814ZM10.5008 21.556C9.02807 21.3092 7.63413 20.7184 6.43265 19.8317L7.1422 19.1519C8.14711 19.8573 9.29299 20.3368 10.5008 20.5571V21.556Z"
                fill="white"
                stroke={color}
            />
        </svg>
    );
}
