import React, { ReactElement } from 'react';

export default function TabView(): ReactElement {
    return (
        <>
            <div className="flex items-center pt-6">
                <div className={`flex w-full`}>
                    <div
                        className={'border-b-4 relative cursor-pointer w-full border-snow-500'}
                        role="presentation"
                    >
                        <div
                            className={
                                'py-2 text-xs sm:text-lg md:text-xl text-blue-700 font-semibold absolute bottom-0 flex w-full text-center '
                            }
                        >
                            Student Success Stories
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
