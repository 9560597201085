import React, { ReactElement, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useDispatch } from 'react-redux';
import { getFileDownloadPresignedURL } from '../../../redux/rootActions';
import {
    DOCUMENT_DOWNLOAD_ERROR,
    DOCUMENT_LINK_ERROR,
    DOCUMENT_NOT_FOUND_ERROR,
} from '../../../constants';
import { TrackChallengeWiseSubmissions } from '../../../redux/students/reducers';
import moment from 'moment';
import parse from 'html-react-parser';

interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    submissionsData?: TrackChallengeWiseSubmissions;
}

export default function ViewSubmissionAnswers({
    modalVisible,
    setModalVisible,
    submissionsData,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const [errorMsg, setErrorMsg] = useState('');
    const [answerModal, setAnswerModal] = useState(false);
    const [textAreaAnswer, setTextAreaAnswer] = useState<string>(null);

    const handleFileOpen = async key => {
        if (key?.startsWith(`assessment/`)) {
            const fileName = key?.split('/')[2];

            const presignedUrl = await dispatch(getFileDownloadPresignedURL(fileName));

            if (presignedUrl && presignedUrl?.toString() !== DOCUMENT_NOT_FOUND_ERROR) {
                const link = document.createElement('a');
                link.href = presignedUrl.toString();
                link.setAttribute('rel', 'noopener noreferrer');
                link.setAttribute('target', '_blank');
                link.click();
            } else {
                setErrorMsg(presignedUrl ? presignedUrl?.toString() : DOCUMENT_DOWNLOAD_ERROR);
            }
        } else if (key?.match(/^\S+@\S+\.\S+$/)) {
            setTextAreaAnswer(key);
            setAnswerModal(true);
        } else if (
            key?.match(
                /((http|https):\/\/)?(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
            )
        ) {
            if (!key?.startsWith(`http`)) {
                window.open(`http://`.concat(key));
            } else {
                window.open(key);
            }
        } else {
            setErrorMsg(DOCUMENT_LINK_ERROR);
        }
    };

    return (
        <>
            <Modal
                open={modalVisible}
                onClose={() => {
                    setModalVisible(false);
                }}
                center
                showCloseIcon={false}
                styles={{
                    modal: {
                        borderRadius: 5,
                        maxWidth: '1300px',
                        minWidth: '850px',
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        boxShadow: '0 12px 15px 0 rgb(0 0 0 / 10%)',
                    },
                    overlay: { background: 'none' },
                }}
            >
                <div className="flex flex-col">
                    <div className="text-md text-gray-800 font-bold px-4">
                        {submissionsData.challengeName
                            ? `${submissionsData.challengeName} assesment submission details`
                            : 'Assesment submission details'}
                    </div>
                    <div className="mt-4 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pb-3 mb-6 overflow-auto px-4">
                        <div className="col-span-12 md:col-span-5">
                            <table className="border-2 w-full">
                                <thead className="border-2">
                                    <tr className="border-2">
                                        <th className="border-2 p-2">Track Name</th>
                                        <th className="border-2 p-2">Challenge Name</th>
                                        <th className="border-2 p-2">Question Type</th>
                                        <th className="border-2 p-2">Submitted Date/time</th>
                                        <th className="border-2 p-2">Question</th>
                                        <th className="border-2 p-2">Answer</th>
                                        <th className="border-2 p-2">Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {submissionsData?.submissions.map((submision, key) => (
                                        <tr key={`key_${key}`}>
                                            <td className="border-2 p-2">
                                                {submissionsData.trackName}
                                            </td>
                                            <td className="border-2 p-2">
                                                {submissionsData.challengeName}
                                            </td>
                                            <td className="border-2 p-2">{submision.taskType}</td>
                                            <td className="border-2 p-2">
                                                {moment(submissionsData.lastSubmitDate).format(
                                                    'DD/MM/YYYY hh:mm A'
                                                )}
                                            </td>
                                            <td className="border-2 p-2">
                                                {submision?.answers?.answers[0].type === 'file' ? (
                                                    <div>
                                                        {parse(
                                                            submision?.answers?.answers[0].label
                                                        )}
                                                    </div>
                                                ) : submision?.answers?.answers[0].type ===
                                                  'radio-group' ? (
                                                    <div>MCQ submission</div>
                                                ) : submision?.answers?.answers[0].type ===
                                                      'text' ||
                                                  submision?.answers?.answers[0].type ===
                                                      'textarea' ? (
                                                    <div>
                                                        {parse(
                                                            submision?.answers?.answers[0].label
                                                        )}
                                                    </div>
                                                ) : null}
                                            </td>
                                            <td className="border-2 p-2">
                                                {submision?.answers?.answers[0].type === 'file' ? (
                                                    <div
                                                        className="text-blue-700 underline cursor-pointer"
                                                        role="button"
                                                        onClick={() =>
                                                            handleFileOpen(
                                                                submision?.answers?.answers[0]
                                                                    .answer
                                                            )
                                                        }
                                                    >
                                                        view
                                                    </div>
                                                ) : submision?.answers?.answers[0].type ===
                                                  'radio-group' ? (
                                                    <div>MCQ submission</div>
                                                ) : submision?.answers?.answers[0].type ===
                                                      'text' ||
                                                  submision?.answers?.answers[0].type ===
                                                      'textarea' ? (
                                                    <div
                                                        className="text-blue-700 underline cursor-pointer"
                                                        onClick={() => {
                                                            setTextAreaAnswer(
                                                                submision?.answers?.answers[0]
                                                                    .answer
                                                            );
                                                            setAnswerModal(true);
                                                        }}
                                                    >
                                                        view
                                                    </div>
                                                ) : null}
                                            </td>
                                            <td className="border-2 p-2">
                                                {submision.submissionScore
                                                    ? submision.submissionScore
                                                    : 'N/A'}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {errorMsg && (
                                <div className="flex mt-2">
                                    <p className="text-red-600">{errorMsg}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`flex justify-end w-full ${errorMsg ? 'mt-8' : ''}`}>
                        <button
                            className="bg-blue-700 text-white rounded-sm focus:outline-none mt-4 px-8 mr-2 md:mr-0 py-2"
                            onClick={() => {
                                setModalVisible(false);
                            }}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={answerModal}
                onClose={() => {
                    setTextAreaAnswer(null);
                    setAnswerModal(false);
                }}
                center
                showCloseIcon={false}
                styles={{
                    modal: {
                        borderRadius: 5,
                        maxWidth: '1300px',
                        minWidth: '850px',
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        boxShadow: '0 12px 15px 0 rgb(0 0 0 / 10%)',
                    },
                    overlay: { background: 'none' },
                }}
            >
                <div className="flex flex-col">
                    <div className="mt-4 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pb-3 mb-6 overflow-auto px-4">
                        <div className="col-span-12 md:col-span-5">{textAreaAnswer}</div>
                    </div>
                    <div className={`flex justify-end w-full ${errorMsg ? 'mt-8' : ''}`}>
                        <button
                            className="bg-blue-700 text-white rounded-sm focus:outline-none mt-4 px-8 mr-2 md:mr-0 py-2"
                            onClick={() => {
                                setTextAreaAnswer(null);
                                setAnswerModal(false);
                            }}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
