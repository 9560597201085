import {
    REGISTER,
    REGISTER_FAILED,
    REGISTER_SUCCESS,
    RESEND_EMAIL_WEB,
    RESEND_EMAIL_WEB_SUCCESS,
    RESEND_EMAIL_WEB_FAILED,
    GENERATE_OTP_WEB,
    GENERATE_OTP_WEB_SUCCESS,
    GENERATE_OTP_WEB_FAILED,
    GENERATE_OTP_SIGNUP_WEB,
    GENERATE_OTP_SIGNUP_WEB_SUCCESS,
    GENERATE_OTP_SIGNUP_WEB_FAILED,
    GENERATE_OTP_SIGNIN_WEB,
    GENERATE_OTP_SIGNIN_WEB_SUCCESS,
    GENERATE_OTP_SIGNIN_WEB_FAILED,
    REGISTER_MOBILE,
    REGISTER_MOBILE_FAILED,
    REGISTER_MOBILE_SUCCESS,
} from './actions';
export interface registerResponseType {
    username: string;
    token: string;
    refreshToken: string;
    userId: string;
    email: string;
    roles: [];
}
export interface RegisterInitialState {
    isLoading: boolean;
    registerResponse: registerResponseType | null;
    registerResendResponse: registerResponseType | null;
    registerMobileResponse: registerResponseType | null;
    registerErrorMessage: string | null;
    mobileLoginErrorMessage: string | null;
    mobileRegisterErrorMessage: string | null;
    isLogout: boolean;
    emailResent: boolean;
    otpSent: boolean;
    otpSignUpSent: boolean;
    mobileLoginError: boolean;
    mobileRegisterError: boolean;
    otpResponse: any;
    otpSignUpResponse: any;
    otpErrorMessage: string | null;
}

export const initialState: RegisterInitialState = {
    isLoading: false,
    registerResponse: null,
    registerResendResponse: null,
    registerMobileResponse: null,
    registerErrorMessage: null,
    mobileRegisterErrorMessage: null,
    mobileLoginErrorMessage: null,
    mobileLoginError: false,
    mobileRegisterError: false,
    isLogout: false,
    emailResent: false,
    otpSent: false,
    otpSignUpSent: false,
    otpResponse: null,
    otpSignUpResponse: null,
    otpErrorMessage: null,
};
const registerReducer = (state = initialState, action: any): RegisterInitialState => {
    switch (action.type) {
        case REGISTER:
            return { ...state, isLoading: true, registerErrorMessage: null };
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                registerResponse: action.payload,
                isLogout: false,
            };
        case REGISTER_FAILED:
            return { ...state, isLoading: false, registerErrorMessage: action.payload };

        case REGISTER_MOBILE:
            return { ...state, isLoading: true, registerMobileResponse: null };
        case REGISTER_MOBILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                registerMobileResponse: action.payload,
                isLogout: false,
            };
        case REGISTER_MOBILE_FAILED:
            return { ...state, isLoading: false, registerMobileResponse: action.payload };

        case RESEND_EMAIL_WEB:
            return { ...state, emailResent: true, registerErrorMessage: null };
        case RESEND_EMAIL_WEB_SUCCESS:
            return {
                ...state,
                emailResent: false,
                registerResendResponse: action.payload,
                isLogout: false,
            };
        case RESEND_EMAIL_WEB_FAILED:
            return { ...state, emailResent: false, registerErrorMessage: action.payload };

        case GENERATE_OTP_SIGNUP_WEB:
            return {
                ...state,
                otpSignUpSent: true,
                mobileRegisterError: false,
                otpSignUpResponse: null,
            };
        case GENERATE_OTP_SIGNUP_WEB_SUCCESS:
            return {
                ...state,
                otpSignUpSent: false,
                otpSignUpResponse: action.payload,
            };
        case GENERATE_OTP_SIGNUP_WEB_FAILED:
            return {
                ...state,
                otpSignUpSent: false,
                mobileRegisterError: true,
                mobileRegisterErrorMessage: action.payload,
            };

        case GENERATE_OTP_SIGNIN_WEB:
            return { ...state, otpSent: true, mobileLoginError: false, otpResponse: null };
        case GENERATE_OTP_SIGNIN_WEB_SUCCESS:
            return {
                ...state,
                otpSent: false,
                otpResponse: action.payload,
            };
        case GENERATE_OTP_SIGNIN_WEB_FAILED:
            return {
                ...state,
                otpSent: false,
                mobileLoginError: true,
                mobileLoginErrorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default registerReducer;
