import React, { createRef, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { editChallenge, getChallengeById, getTracks } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { disabledAllFields, disabledAttr, s3UrlPrefix } from '../../../constants';
import Loader from '../../../assets/svg/Loader';
import ReactMarkdown from 'react-markdown';
import { ChallengesType } from '../../../redux/challenges/reducers';
import ChallengeSuccesfullyCreatedModal from '../../modals/ChallengeSuccessfullyCreatedModal';
import { decodeValue, StringToBytesArray } from '../../../utils/stringToBytes';
import { getDecryptedData } from '../../../utils/decrypt';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroupsView from '../../TagGroupsView';
import ChallengeAssessment from './ChallengeAssessment';
import { assessmentItems, assessmentBuilder } from './AssesmentFundamentals';
declare global {
    interface Window {
        jQuery: any;
        $: any;
    }
}
interface CustomState {
    challenge: ChallengesType;
}
interface paramsType {
    id: string;
}

function LinkRenderer(props) {
    return (
        <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    );
}

const MarkdownRenderer = ({ children }) => {
    return (
        <ReactMarkdown
            className="prose border-2 h-96 overflow-auto border-gray-300 p-2"
            components={{ a: LinkRenderer }}
        >
            {children}
        </ReactMarkdown>
    );
};

export default function EditChallenge() {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<paramsType>();
    const challengeState = location.state as CustomState;
    const editChallengeError = useSelector(
        (state: RootState) => state.challenges.editChallengeError
    );
    const challengeByIdLoading = useSelector(
        (state: RootState) => state.challenges.challengeByIdLoading
    );
    const challenge = useSelector((state: RootState) => state.challenges.challenge);
    const chalengeUpdateSuccess = useSelector(
        (state: RootState) => state.challenges.challengeUpdateSuccessMessage
    );
    const tracks = useSelector((state: RootState) => state.tracks.trackData);
    const [tags, setTags] = useState(categorizeTags(challengeState?.challenge.tags));
    const [imageUrl] = useState(`${s3UrlPrefix}${challengeState?.challenge?.imageLink}`);
    const [imageLocation] = useState(challengeState?.challenge?.imageLink);
    // const [challengeEditSuccessVisible, setChallengeEditSuccessVisible] = useState(false);
    // const [challengeErrorVisible, setChallengeErrorVisible] = useState(false);
    const [idList] = useState(challengeState?.challenge?.trackIds);
    const [optionSelected, setOptionSelected] = useState<any>();
    const [assessment, setAssessment] = useState([]);
    const [assessmentMinimize, setAssessmentMinimize] = useState<any>([]);

    useEffect(() => {
        if (challenge?.material?.encryptQuestionnaire) {
            const decryptedAssesment = JSON.parse(
                getDecryptedData(challenge.material.encryptQuestionnaire)
            );
            setAssessment(decryptedAssesment);
            setAssessmentMinimize(Array(decryptedAssesment.length).fill(true));
        }
    }, [challenge]);
    useEffect(() => {
        dispatch(getTracks(true, 0, 100));
    }, [dispatch]);
    useEffect(() => {
        const result = idList
            ?.map(id => {
                const track = tracks.find(item => item.id === id);
                return track;
            })
            .filter(item => item !== undefined);

        const tracksArray = [
            result?.map(elem => {
                return {
                    value: elem.id,
                    label: elem.trackName,
                };
            }),
        ];
        setOptionSelected(tracksArray[0]);
    }, [tracks, idList]);
    useEffect(() => {
        dispatch(getChallengeById(params.id));
    }, [dispatch, params.id]);

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    {challengeByIdLoading ? (
                        <Loader />
                    ) : (
                        <div className="relative">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    challengeName: challenge?.challengeName,
                                    challengeCreatedBy: '',
                                    challengeLastUpdatedBy: '',
                                    pointsAwarded: challenge?.pointsAwarded,
                                    imageLink: challenge?.imageLink,
                                    prerequisitePoints: challenge?.prerequisitePoints,
                                    introLink: challenge?.introLink,
                                    challengeStatus: challenge?.challengeStatus,
                                    markdownInput: challenge?.material?.description
                                        ? decodeValue(challenge?.material?.description)
                                        : '',
                                }}
                                onSubmit={async values => {
                                    dispatch(
                                        editChallenge(
                                            challenge.id,
                                            {
                                                challengeName: values.challengeName,
                                                challengeCreatedBy: '',
                                                challengeLastUpdatedBy: '',
                                                pointsAwarded: values.pointsAwarded,
                                                prerequisitePoints: values.prerequisitePoints,
                                                introLink: values.introLink,
                                                challengeStatus: values.challengeStatus,
                                                imageLink: imageLocation,
                                            },
                                            StringToBytesArray(values.markdownInput),
                                            ''
                                        )
                                    );
                                }}
                            >
                                {({ values, errors, touched }) => (
                                    <>
                                        <div className="flex justify-between items-center mb-4 md:mb-6">
                                            <p className="text-xl font-semibold text-left">
                                                View Challenge
                                            </p>
                                            <div
                                                className={`flex h-14 md:h-16 ${
                                                    challengeState?.challenge?.challengeStatus !==
                                                    'DELETED'
                                                        ? 'w-1/2 md:w-1/4 lg:pl-8'
                                                        : 'w-1/4 md:w-1/6 lg:pl-16'
                                                }`}
                                            >
                                                <button
                                                    type="submit"
                                                    className={`md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm ${
                                                        challengeState?.challenge
                                                            ?.challengeStatus !== 'DELETED'
                                                            ? 'w-full md:w-1/2'
                                                            : 'w-full hover:bg-blue-700 hover:text-white'
                                                    } my-2 focus:outline-none`}
                                                    onClick={() => {
                                                        history.push('/admin/challenges');
                                                    }}
                                                >
                                                    OK
                                                </button>
                                                {challengeState?.challenge?.challengeStatus !==
                                                    'DELETED' && (
                                                    <button
                                                        type="submit"
                                                        className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                        onClick={() => {
                                                            history.push({
                                                                pathname: `/admin/challenges/${challengeState?.challenge?.id}/edit`,
                                                                state: { challenge: challenge },
                                                            });
                                                        }}
                                                    >
                                                        Edit
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 pb-4 md:gap-8 pt-2 mb-6">
                                            <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                                <div className="text-lg font-medium mb-6">
                                                    Challenge Details
                                                </div>

                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="name"
                                                        className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Challenge Name
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded-sm flex items-center focus:outline-none  w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                errors.challengeName &&
                                                                touched.challengeName
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="name"
                                                            type="text"
                                                            value={values.challengeName}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="introLink"
                                                        className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Tracks Applicable
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        {optionSelected?.map((track, i) => {
                                                            return (
                                                                <div className="flex mt-2" key={i}>
                                                                    <input
                                                                        className={`border-2 p-2  w-3/4 md:w-4/5 border-gray-300`}
                                                                        value={track.label}
                                                                        disabled
                                                                    />
                                                                    <div className="flex"></div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="introLink"
                                                        className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Intro Video Link
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded-sm flex items-center focus:outline-none  w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                errors.introLink &&
                                                                touched.introLink
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="introLink"
                                                            type="text"
                                                            value={values.introLink}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Challenge Status
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base border-gray-300 md:text-base`}
                                                            type="text"
                                                            value={values.challengeStatus}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="introLink"
                                                        className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Challenge Description
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <textarea
                                                            className={`resize-none focus:outline-none p-2 w-3/4 md:w-4/5 border-2 text-sm h-24 focus:border-blue-900 border-gray-300`}
                                                            value={challenge?.challengeDescription}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="my-6 w-full md:mb-0 flex">
                                                    <label
                                                        htmlFor="description"
                                                        className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Challenge Image
                                                    </label>
                                                    <div
                                                        className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                                            imageUrl ? 'w-fit' : ''
                                                        } border-opacity-100 border-black-600
                                               border-2`}
                                                    >
                                                        <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64">
                                                            {imageUrl && imageLocation && (
                                                                <img
                                                                    src={imageUrl}
                                                                    alt={'name'}
                                                                    className="h-44 lg:h-64"
                                                                    style={{ maxHeight: 300 }}
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className="absolute mt-4 md:mt-0 my-4 text-center"
                                                            style={{
                                                                left: '50%',
                                                                transform: 'translate(-50%,-50%)',
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                                <div className="flex border-2 flex-col ml-2 justify-items-center">
                                                    <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                                        <div className="sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                            Tags
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                                        <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                            <TagGroupsView selectedTags={tags} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2 mb-6">
                                            <div className="col-span-12 md:col-span-6 mb-2 md:mb-4">
                                                <div className="text-lg font-medium mb-6">
                                                    Preview
                                                </div>
                                                <MarkdownRenderer>
                                                    {values.markdownInput}
                                                </MarkdownRenderer>
                                            </div>
                                        </div>
                                        {getDecryptedData(
                                            challenge?.material?.encryptQuestionnaire
                                        ) !== '[]' && (
                                            <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6  pointer-events-none bg-gray-100">
                                                <div className="text-lg font-medium mb-2">
                                                    Challenge Assesment
                                                </div>
                                                <div className="col-span-12 md:col-span-5 mb-2 md:mb-4 flex flex-row">
                                                    <ul
                                                        className="w-1/4 2xl:w-1/6 mr-4 cursor-pointer  rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                        style={{
                                                            maxHeight: '248px',
                                                            position: 'sticky',
                                                            top: 100,
                                                        }}
                                                    >
                                                        {assessmentItems.map(item => (
                                                            <div
                                                                className="flex p-2 pl-2 hover:bg-gray-200 flex-row"
                                                                key={item.id}
                                                                onClick={() => {
                                                                    //
                                                                }}
                                                            >
                                                                <span className="flex items-center px-4">
                                                                    {item.icon}
                                                                </span>
                                                                <span>{item.val}</span>
                                                            </div>
                                                        ))}
                                                    </ul>
                                                    <div className="w-3/4 2xl:w-5/6">
                                                        <ChallengeAssessment
                                                            assessment={assessment}
                                                            setAssessment={setAssessment}
                                                            minimize={assessmentMinimize}
                                                            setMinimize={setAssessmentMinimize}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Formik>
                        </div>
                    )}
                </div>
                {/* {chalengeUpdateSuccess ? (
                    <ChallengeSuccesfullyCreatedModal
                        modalVisible={challengeEditSuccessVisible}
                        setModalVisible={setChallengeEditSuccessVisible}
                    />
                ) : null}
                {editChallengeError ? (
                    <ChallengeSuccesfullyCreatedModal
                        modalVisible={challengeErrorVisible}
                        setModalVisible={setChallengeErrorVisible}
                    />
                ) : null} */}
                {/* {deleteConfirmationVisible ? (
                    <DeleteConfirmationModal
                        modalVisible={deleteConfirmationVisible}
                        setModalVisible={setDeleteConfirmationVisible}
                        trackId={challenge.id}
                    />
                ) : null} */}
            </div>
        </div>
    );
}
