import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import * as HiIcons from 'react-icons/hi';
import {
    addUser,
    clearUsersMessages,
    getCompanies,
    getCompanyPublicData,
    getInstitutes,
    getInstitutesPublicData,
    getTags,
} from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { useHistory } from 'react-router-dom';
import UsersSuccessModal from '../../modals/UsersSuccessModal';
import { InstitutesType } from '../../../redux/institutes/reducers';
import { CompanyType } from '../../../redux/companies/reducers';
import { COMPANY_ADMIN, INSTITUTE_ADMIN } from '../../../redux/authAdmin/reducers';
import PhoneInput from 'react-phone-input-2';
import { phoneRegExp } from '../../../constants';

export default function AddUser() {
    const dispatch = useDispatch();
    const history = useHistory();
    const isCreateLoading = useSelector((state: RootState) => state.users.isCreateLoading);
    const companies = useSelector((state: RootState) => state.companies.companyPublicData);
    const institutes = useSelector((state: RootState) => state.institutes.publicInstitutData);
    const createError = useSelector((state: RootState) => state.users.createUserError);
    const createSuccessMessage = useSelector(
        (state: RootState) => state.users.UserCreateSuccessMessage
    );
    const formRef = useRef<any>();
    const [institute, setInstitute] = useState<InstitutesType>();
    const [company, setCompany] = useState<CompanyType>();
    const [createSuccessVisible, setCreateSuccessVisible] = useState(false);
    const [category, setCategory] = useState<string>('Select a role');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [showInstitutesDropdown, setShowInstitutesDropdown] = useState(false);
    const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);

    useEffect(() => {
        if (showInstitutesDropdown || showCompanyDropdown) {
            dispatch(getInstitutesPublicData());
            dispatch(getCompanyPublicData());
        }
    }, [dispatch, showInstitutesDropdown, showCompanyDropdown]);
    const categoryOptions = [
        { id: 0, val: 'SLASSCOM Admin' },
        { id: 1, val: 'Company Admin' },
        { id: 2, val: 'Institute Admin' },
    ];

    useEffect(() => {
        dispatch(getCompanies(0, 100));
        dispatch(getInstitutes(0, 100));
        dispatch(getTags(true));
    }, [dispatch]);

    const handleClearMessages = useCallback(() => {
        if (createSuccessMessage || createError) {
            setCreateSuccessVisible(true);
        }
    }, [dispatch, createSuccessMessage, createError, history]);
    const handleClose = () => {
        dispatch(clearUsersMessages());
        setCreateSuccessVisible(false);
        if (!createError) {
            history.push('/admin/users');
        }
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    <div className="relative">
                        <Formik
                            innerRef={formRef}
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                institute: '',
                                company: '',
                                status: 'ACTIVE',
                                password: 'adminpassword',
                                email: '',
                                contactNumber: '',
                                roles: '',
                            }}
                            validationSchema={Yup.object({
                                firstName: Yup.string()
                                    .max(100, 'FirstName should be less than 100 characters')
                                    .required('First name is required'),
                                lastName: Yup.string()
                                    .max(100, 'Last Name should be less than 100 characters')
                                    .required('Last name is required'),
                                email: Yup.string()
                                    .email('Invalid email')
                                    .required('Email is required'),
                                roles: Yup.string().required('Role is required'),
                                institute: Yup.string().when('roles', {
                                    is: 'INSTITUTE_ADMIN',
                                    then: Yup.string().required('Institute is required'),
                                }),
                                contactNumber: Yup.string()
                                    .matches(phoneRegExp, 'Contact number is not valid')
                                    .required('Contact number is required'),
                                company: Yup.string().when('roles', {
                                    is: 'COMPANY_ADMIN',
                                    then: Yup.string().required('Company is required'),
                                }),
                            })}
                            onSubmit={async values => {
                                dispatch(
                                    addUser({
                                        userName: values.email,
                                        firstName: values.firstName.trim(),
                                        lastName: values.lastName.trim(),
                                        status: values.status,
                                        password: values.password,
                                        emailId: values.email,
                                        roles: [
                                            {
                                                roleName: values.roles,
                                            },
                                        ],
                                        contact: { mobileNo: values.contactNumber },
                                        instituteId: values.institute,
                                        companyId: values.company,
                                    })
                                );
                            }}
                        >
                            {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                                <>
                                    <div className="flex justify-between items-center ml-4 mb-4 md:mb-6">
                                        <p className="text-xl font-semibold text-left">Add User</p>
                                    </div>
                                    <div className="px-6 lg:px-12 pt-2 pb-4 mb-6">
                                        <div className="mb-2 md:mb-4">
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="firstName"
                                                    className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    First Name
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.firstName && touched.firstName
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="firstName"
                                                        type="text"
                                                        value={values.firstName}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'firstName',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.firstName && touched.firstName ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.firstName}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="lastName"
                                                    className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Last Name
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.lastName && touched.lastName
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="lastName"
                                                        type="text"
                                                        value={values.lastName}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'lastName',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.lastName && touched.lastName ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.lastName}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="email"
                                                    className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Email
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.email && touched.email
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="email"
                                                        type="text"
                                                        value={values.email}
                                                        onChange={e => {
                                                            setFieldValue('email', e.target.value);
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.email && touched.email ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.email}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="contactNumber"
                                                    className={`flex items-start w-1/4 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Contact Number
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <label
                                                        htmlFor=""
                                                        className={`rounded-sm rounded-tl rounded-bl flex items-center focus:outline-none w-3/4 md:w-4/5  border-2 text-base focus:border-blue-900 ${
                                                            errors.contactNumber &&
                                                            touched.contactNumber
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                    >
                                                        <PhoneInput
                                                            inputStyle={{
                                                                width: '100%',
                                                                height: '42px',
                                                                fontSize: '14px',
                                                                paddingLeft: '48px',
                                                                borderRadius: '4px',
                                                                border: 0,
                                                                fontFamily: 'Lato',
                                                            }}
                                                            dropdownClass={
                                                                'mobileNumberCountryDropdown'
                                                            }
                                                            country="lk"
                                                            specialLabel={''}
                                                            value={values.contactNumber}
                                                            placeholder="Enter your Mobile Number (Eg: 94712345678)"
                                                            onChange={e => {
                                                                if (!isNaN(Number(e))) {
                                                                    setFieldValue(
                                                                        'contactNumber',
                                                                        e
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </label>

                                                    {errors.contactNumber &&
                                                    touched.contactNumber ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.contactNumber}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="title"
                                                    className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    User Role
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <div
                                                        className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-4/5 md:p-2 border-2 text-sm focus:border-blue-900 ${
                                                            errors.roles && touched.roles
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                    >
                                                        <div
                                                            className="flex justify-between w-full"
                                                            onClick={() => setDropdownVisible(true)}
                                                        >
                                                            <div className="text-gray-700 font-medium pl-2">
                                                                {category}
                                                            </div>
                                                            <div className="">
                                                                <div className="flex flex-col">
                                                                    <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                    <HiIcons.HiChevronDown className="mr-2" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {dropdownVisible ? (
                                                            <ul
                                                                className="absolute w-full cursor-pointer top-11 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                role="menu"
                                                                onMouseLeave={() =>
                                                                    setDropdownVisible(false)
                                                                }
                                                            >
                                                                {categoryOptions.map(action => (
                                                                    <div
                                                                        className="p-2 hover:bg-gray-200 "
                                                                        key={action.id}
                                                                        onClick={() => {
                                                                            setCategory(action.val);
                                                                            setFieldValue(
                                                                                'roles',
                                                                                action.id === 0
                                                                                    ? 'SLASSCOM_ADMIN'
                                                                                    : action.id ===
                                                                                      1
                                                                                    ? 'COMPANY_ADMIN'
                                                                                    : 'INSTITUTE_ADMIN'
                                                                            );
                                                                            setDropdownVisible(
                                                                                false
                                                                            );
                                                                        }}
                                                                    >
                                                                        {action.val}
                                                                    </div>
                                                                ))}
                                                            </ul>
                                                        ) : null}
                                                    </div>
                                                    {errors.roles && touched.roles ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.roles}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {values.roles === INSTITUTE_ADMIN && (
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="title"
                                                        className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Institute
                                                        <div className="text-red-600">*</div>
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <div
                                                            className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-4/5 md:p-2 border-2 text-sm focus:border-blue-900 ${
                                                                errors.institute &&
                                                                touched.institute
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                        >
                                                            <div
                                                                className="flex justify-between w-full"
                                                                onClick={() =>
                                                                    setShowInstitutesDropdown(true)
                                                                }
                                                            >
                                                                <div className="text-gray-700 font-medium pl-2">
                                                                    {institute?.instName}
                                                                </div>
                                                                <div className="">
                                                                    <div className="flex flex-col">
                                                                        <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                        <HiIcons.HiChevronDown className="mr-2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {showInstitutesDropdown ? (
                                                                <ul
                                                                    className="absolute w-full cursor-pointer max-h-32 overflow-y-scroll top-11 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() =>
                                                                        setShowInstitutesDropdown(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    {institutes.map(inst => (
                                                                        <div
                                                                            className="p-2 hover:bg-gray-200 "
                                                                            key={inst.id}
                                                                            onClick={() => {
                                                                                setInstitute(inst);
                                                                                setFieldValue(
                                                                                    'institute',
                                                                                    inst.id
                                                                                );
                                                                                setShowInstitutesDropdown(
                                                                                    false
                                                                                );
                                                                            }}
                                                                        >
                                                                            {inst.instName}
                                                                        </div>
                                                                    ))}
                                                                </ul>
                                                            ) : null}
                                                        </div>
                                                        {errors.institute && touched.institute ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.institute}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            )}

                                            {values.roles === COMPANY_ADMIN && (
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="title"
                                                        className={`flex items-start w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Company
                                                        <div className="text-red-600">*</div>
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <div
                                                            className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-4/5 md:p-2 border-2 text-sm focus:border-blue-900 ${
                                                                errors.company && touched.company
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                        >
                                                            <div
                                                                className="flex justify-between w-full"
                                                                onClick={() =>
                                                                    setShowCompanyDropdown(true)
                                                                }
                                                            >
                                                                <div className="text-gray-700 font-medium pl-2">
                                                                    {company?.comName}
                                                                </div>
                                                                <div className="">
                                                                    <div className="flex flex-col">
                                                                        <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                        <HiIcons.HiChevronDown className="mr-2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {showCompanyDropdown ? (
                                                                <ul
                                                                    className="absolute w-full cursor-pointer top-11 pl-0 max-h-32 overflow-y-scroll  left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() =>
                                                                        setShowCompanyDropdown(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    {companies.map(com => (
                                                                        <div
                                                                            className="p-2 hover:bg-gray-200 "
                                                                            key={com.id}
                                                                            onClick={() => {
                                                                                setCompany(com);
                                                                                setFieldValue(
                                                                                    'company',
                                                                                    com.id
                                                                                );
                                                                                setShowCompanyDropdown(
                                                                                    false
                                                                                );
                                                                            }}
                                                                        >
                                                                            {com.comName}
                                                                        </div>
                                                                    ))}
                                                                </ul>
                                                            ) : null}
                                                        </div>
                                                        {errors.company && touched.company ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.company}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex justify-end items-center mb-4 md:mb-6">
                                        <div className="flex w-1/2 lg:w-1/3 h-16">
                                            <button
                                                className="md:py-3 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-700 font-medium text-sm rounded w-full md:w-1/3 my-2 focus:outline-none"
                                                onClick={() => history.push('/admin/users/')}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="md:py-3 mx-6 lg:mx-8 bg-blue-700 text-white text rounded w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => handleSubmit()}
                                            >
                                                {isCreateLoading ? <Loader /> : 'Add User'}
                                            </button>
                                        </div>
                                    </div>
                                    {createSuccessVisible && (
                                        <UsersSuccessModal
                                            modalVisible={createSuccessVisible}
                                            handleClose={handleClose}
                                        />
                                    )}
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
