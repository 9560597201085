import React, { useEffect, useState } from 'react';
import { useTable, useRowSelect } from 'react-table';
import { LeaderboardType } from '../../../../redux/dashboard/reducers';
interface Props {
    data: LeaderboardType[];
    columns: any;
}

const LeaderboardTable = ({ columns, data }: Props) => {
    const [showColumnDropdown, setShowColumnDropdown] = useState(false);
    const [viewAllClicked, setViewAllClicked] = useState(false);
    const [clickedColumnId, setClickedColumnId] = useState();
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        allColumns,
        setHiddenColumns,
    } = useTable(
        {
            columns,
            data,
        },
        useRowSelect
    );
    const VisibleColumns = allColumns.filter(
        col => col.isVisible === true && col.id !== 'selection' && col.id !== 'actionColumn'
    );
    useEffect(() => {
        if (viewAllClicked) {
            setHiddenColumns([]);
        } else {
            setHiddenColumns(['gender', 'linkedInProfileUrl', 'institute', 'homeTown', 'attempts']);
        }
    }, [viewAllClicked, setHiddenColumns]);

    const columnClickHandler = columnId => {
        setClickedColumnId(columnId);
        columnId !== 'selection' && columnId !== 'actionColumn'
            ? setShowColumnDropdown(true)
            : setShowColumnDropdown(false);
    };

    return (
        <section className="py-1 h-full">
            <div className="w-full mb-8">
                <div className="w-full overflow-x-auto pl-1 h-full" style={{ minHeight: '50vh' }}>
                    {rows.length > 0 ? (
                        <table className="w-full border-b-2 border-gray-300" {...getTableProps()}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr
                                        key={headerGroup}
                                        {...headerGroup.getHeaderGroupProps()}
                                        className="text-sm xl:text-base font-medium tracking-wide text-start text-gray-700 bg-gray-100 border-2 border-gray-300"
                                        onMouseLeave={() => {
                                            setShowColumnDropdown(false);
                                        }}
                                    >
                                        {headerGroup.headers.map(column => (
                                            <th
                                                key={column}
                                                {...column.getHeaderProps()}
                                                className={`align-top px-2 py-3  ${
                                                    column.id === clickedColumnId ? 'relative' : ''
                                                } ${
                                                    column.id !== 'selection' &&
                                                    column.id !== 'actionColumn'
                                                        ? 'cursor-pointer'
                                                        : ''
                                                }`}
                                                onClick={() => columnClickHandler(column.id)}
                                            >
                                                <div className="text-center">
                                                    {column.render('Header')}
                                                </div>

                                                {column.id === clickedColumnId &&
                                                    showColumnDropdown && (
                                                        <div className="mt-3 absolute left-0 shadow-md z-10 w-44 cursor-default">
                                                            <ul
                                                                className="bg-gray-200 shadow border-2 border-gray-400 p-2 mb-0"
                                                                onMouseLeave={() =>
                                                                    setShowColumnDropdown(false)
                                                                }
                                                            >
                                                                <div className="pb-2">
                                                                    <p className="text-sm mb-2">
                                                                        Show Column
                                                                    </p>
                                                                    <div className="font-medium text-gray-600 text-sm flex py-1 py-0.5">
                                                                        <input
                                                                            className="mr-1 mt-1"
                                                                            type="checkbox"
                                                                            defaultChecked={
                                                                                viewAllClicked
                                                                            }
                                                                            onClick={() => {
                                                                                setViewAllClicked(
                                                                                    !viewAllClicked
                                                                                );
                                                                            }}
                                                                        />
                                                                        <div>View All</div>
                                                                    </div>
                                                                    {allColumns.map(col => (
                                                                        <div key={col.id}>
                                                                            {col.id !==
                                                                                'selection' &&
                                                                                col.id !==
                                                                                    'actionColumn' && (
                                                                                    <label className="font-medium text-gray-600 text-sm flex py-0.5">
                                                                                        <input
                                                                                            className="mr-1 mt-1"
                                                                                            type="checkbox"
                                                                                            {...col.getToggleHiddenProps()}
                                                                                            disabled={
                                                                                                col.isVisible &&
                                                                                                VisibleColumns.length <=
                                                                                                    6
                                                                                            }
                                                                                        />
                                                                                        <div>
                                                                                            {
                                                                                                col.Header
                                                                                            }
                                                                                        </div>
                                                                                    </label>
                                                                                )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody className="bg-white" {...getTableBodyProps()}>
                                {rows.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr
                                            key={row}
                                            {...row.getRowProps()}
                                            className="text-gray-700 border-l-2 border-r-2 border-gray-300"
                                        >
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        key={cell}
                                                        {...cell.getCellProps({})}
                                                        className={`py-3 text-sm font-semibold px-2 text-center`}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <div className="flex justify-center bg-gray-100 h-16 text-sm md:text-base font-semibold items-center text-gray-600 border-2 border-gray-300">
                            No Leaderboard found
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default LeaderboardTable;
