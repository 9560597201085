const PREFIX = '@AUTH_S_';

export const SAVE_AUTH_TOKEN = PREFIX + 'SAVE_AUTH_TOKEN';
export const SAVE_USER_PROFILE = PREFIX + 'SAVE_USER_PROFILE';
export const SAVE_EXPIRE_TIME = PREFIX + 'SAVE_EXPIRE_TIME';

export const CLEAR_AUTH = PREFIX + 'CLEAR_AUTH';

export const saveAuthToken = (authToken: string) => async (dispatch: any) => {
    try {
        dispatch({ type: SAVE_AUTH_TOKEN, payload: authToken });
    } catch (error) {
        console.log('error', error);
    }
};

export const saveUserProfile = (user: any) => async (dispatch: any) => {
    try {
        dispatch({ type: SAVE_USER_PROFILE, payload: user });
    } catch (error) {
        console.log('error', error);
    }
};
export const saveExpireTime = (expireTime: any) => async (dispatch: any) => {
    try {
        dispatch({ type: SAVE_EXPIRE_TIME, payload: expireTime });
    } catch (error) {
        console.log('error', error);
    }
};

export const clearStudentAuth = () => (dispatch: any) => {
    dispatch({ type: CLEAR_AUTH });
};
