import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import LeaderboardTable from './LeaderboardTable';
import { getLeaderboardData, getTracks } from '../../../../redux/rootActions';
import { TracksType } from '../../../../redux/tracks/reducers';
import * as HiIcons from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

export const Leaderboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const leaderboard = useSelector((state: RootState) => state.dashboard.leaderboard);
    const trackData = useSelector((state: RootState) => state.tracks.trackData);
    const [showTracksDropdown, setShowTracksDropdown] = useState(false);
    const [track, setTrack] = useState<TracksType>();

    const columns = useMemo(
        () => [
            {
                Header: 'Rank',
                accessor: (data, index) => {
                    return index + 1;
                },
            },
            {
                Header: 'Student ID',
                accessor: 'studentId',
            },
            {
                Header: 'Student Name',
                accessor: 'studentName',
            },
            {
                Header: 'Contact Number',
                accessor: 'mobile',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'No of Challenges Attempted',
                accessor: 'completedChallengeCount',
            },
            {
                Header: 'Average Score',
                accessor: data => {
                    return Math.round(data?.avgScore);
                },
            },
            {
                Header: 'Task Attempts',
                accessor: 'attempts',
            },
            {
                Header: 'Score',
                accessor: data => {
                    return Math.round(data?.totalScore * 100);
                },
            },
            {
                Header: 'LinkedIn Profile',
                accessor: 'linkedInProfileUrl',
            },
            {
                Header: 'Institute',
                accessor: 'institute',
            },
            {
                Header: 'Gender',
                accessor: 'gender',
            },
            {
                Header: 'Hometown',
                accessor: 'hometown',
            },
        ],
        []
    );
    useEffect(() => {
        dispatch(getTracks(true, 0, 100, ''));
    }, [dispatch]);
    useEffect(() => {
        dispatch(getLeaderboardData(track?.id, 0, 10));
    }, [dispatch, track]);

    return (
        <div className="mx-6 lg:ml-0 pb-6">
            <div className="flex justify-between items-center mb-4 md:mb-8 pt-12 border-t-2">
                <div
                    className={` text-xl md:text-3xl flex w-full text-center text-black-700 font-bold`}
                >
                    Leaderboard
                </div>
                <button
                    className="bg-snow-900 border-2 border-blue-700 border-opacity-40 text-blue-700 text-sm md:font-semibold rounded-sm w-1/2 md:w-1/3 xl:w-1/5 h-11 focus:outline-none"
                    onClick={() => {
                        history.push('/admin/leaderboard');
                    }}
                >
                    View More
                </button>
            </div>
            <div className="flex w-1/2 lg:w-1/3 mb-2 pt-2">
                <div className="mb-4 w-full md:mb-0 flex">
                    <div className="flex flex-col w-full">
                        <div className="w-full flex items-center">
                            <label className="mx-2"> Track : </label>
                            <div
                                className={`rounded-md flex relative cursor-pointer items-center focus:outline-none md:w-2/3 lg:w-3/5  border-2 text-sm focus:border-blue-900 border-gray-300`}
                            >
                                <div
                                    className="flex justify-between w-full md:p-2"
                                    onClick={() => setShowTracksDropdown(!showTracksDropdown)}
                                >
                                    <div className="text-gray-700 font-medium pl-2">
                                        {track ? track?.trackName : 'All'}
                                    </div>
                                    <div className="flex flex-col">
                                        <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                        <HiIcons.HiChevronDown className="mr-2" />
                                    </div>
                                </div>
                                {showTracksDropdown ? (
                                    <ul
                                        className="absolute max-h-48 overflow-y-auto w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        role="menu"
                                    >
                                        <div
                                            className={`p-2 hover:bg-gray-200 ${
                                                !track && 'bg-gray-300'
                                            }`}
                                            key="0"
                                            // value=""
                                            onClick={() => {
                                                setTrack(null);
                                                setShowTracksDropdown(false);
                                            }}
                                        >
                                            All
                                        </div>
                                        {trackData?.map(trackItem => (
                                            <div
                                                className={`p-2 hover:bg-gray-200 ${
                                                    track?.trackName === trackItem?.trackName &&
                                                    'bg-gray-300'
                                                }`}
                                                key={trackItem?.id}
                                                // value={trackItem?.id}
                                                onClick={() => {
                                                    setTrack(trackItem);
                                                    setShowTracksDropdown(false);
                                                }}
                                            >
                                                {trackItem?.trackName}
                                            </div>
                                        ))}
                                    </ul>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex w-full">
                <div className={`mr-4 w-full`}>
                    <LeaderboardTable columns={columns} data={leaderboard} />
                </div>
            </div>
        </div>
    );
};
