import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { s3UrlPrefix } from '../../../../../constants';
import { RootState } from '../../../../../redux/rootReducer';

interface Props {
    institute: any;
    isLargeScreen?: boolean;
}

const InstituteCard = ({ institute, isLargeScreen = true }: Props) => {
    const history = useHistory();
    return (
        <>
            {isLargeScreen ? (
                <div
                    style={{
                        width: '303px',
                        height: '229px',
                    }}
                    key={institute.id}
                    className="instituteCardcontainer bg-purple-85 rounded-md flex flex-col items-center justify-center m-4"
                >
                    <img
                        className="rounded instituteCardImage"
                        src={
                            institute.logoImage !== null
                                ? `${s3UrlPrefix}${institute.logoImage}`
                                : '/assets/BlankImage.png'
                        }
                        alt="logo"
                    />
                    <div className="instituteCardMiddle">
                        <div
                            onClick={() => {
                                history.push(`/institute/${institute.id}`);
                            }}
                            className="mt-8 border-1 rounded border-2 border-purple-950 text-purple-950 w-32 h-12 flex justify-center items-center font-semibold cursor-pointer"
                        >
                            View more
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    style={{ width: '280px', height: '170px' }}
                    key={institute.id}
                    className="instituteCardcontainer bg-purple-85 rounded-md flex flex-col items-center justify-center m-4"
                    onClick={() => {
                        history.push(`/institute/${institute.id}`);
                    }}
                >
                    <img
                        className="rounded object-cover object-center"
                        style={{ maxWidth: '250px', maxHeight: '130px' }}
                        src={
                            institute.logoImage !== null
                                ? `${s3UrlPrefix}${institute.logoImage}`
                                : '/assets/BlankImage.png'
                        }
                        alt="logo"
                    />
                </div>
            )}
        </>
    );
};

export default InstituteCard;
