import React, { ReactElement } from 'react';

interface Props {
    color: string;
}

export default function MentorMobile({ color }: Props): ReactElement {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.5 4V3.5H20H10.4714C10.4324 3.15849 10.3538 2.823 10.2387 2.5H20C20.3978 2.5 20.7794 2.65804 21.0607 2.93934C21.342 3.22064 21.5 3.60218 21.5 4V15C21.5 15.3978 21.342 15.7794 21.0607 16.0607C20.7794 16.342 20.3978 16.5 20 16.5H11.5V15.5H20H20.5V15V4ZM3 13.5H2V9C2 8.60218 2.15804 8.22064 2.43934 7.93934C2.72064 7.65804 3.10218 7.5 3.5 7.5H14.5V8.5H9H8.5V9V21.5H7.5V16V15.5H7H5H4.5V16V21.5H3.5V14V13.5H3ZM7.5 4C7.5 4.39783 7.34196 4.77936 7.06066 5.06066C6.77936 5.34197 6.39782 5.5 6 5.5C5.60218 5.5 5.22064 5.34197 4.93934 5.06066C4.65804 4.77936 4.5 4.39783 4.5 4C4.5 3.60218 4.65804 3.22064 4.93934 2.93934C5.22064 2.65804 5.60218 2.5 6 2.5C6.39782 2.5 6.77936 2.65804 7.06066 2.93934C7.34196 3.22064 7.5 3.60218 7.5 4Z"
                fill={color}
                stroke={color}
            />
        </svg>
    );
}
