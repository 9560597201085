import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { USER_PROFILE } from '../../../utils/storageWeb';
import { RootState } from '../../../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
    changePassword,
    clearWebAuth,
    getStudentActivityLog,
    logoutWeb,
    sendEmailVerification,
    sendMobileVerification,
    SEND_EMAIL_VERIFICATION_RESET,
} from '../../../../redux/rootActions';
import { Formik } from 'formik';
import Loader from '../../../../assets/svg/Loader';
import { PASSWORD_CHANGE_SUCCESS_MESSAGE } from '../../../../constants';
import * as Yup from 'yup';
import CloseIcon from '../../../../assets/svg/CloseIcon';
import Toastr from '../../elements/Toastr';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import useWidth from '../../../hooks/useWidth';
import SocialMediaLoginUnableChange from '../../../modals/SocialMediaLoginUnableChange';

const Settings = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const studentData = useSelector((state: RootState) => state.webUser.student);
    const studentDataUpdateSuccess = useSelector(
        (state: RootState) => state.webUser.StudentUpdateSuccessMessage
    );
    const sendEmailVerificationSuccess = useSelector(
        (state: RootState) => state.webUser.sendEmailVerificationSuccessMessage
    );
    const sendEmailVerificationError = useSelector(
        (state: RootState) => state.webUser.sendEmailVerificationError
    );
    const isLoading = useSelector((state: RootState) => state.webUser.isLoading);

    const sendMobileVerificationLoading = useSelector(
        (state: RootState) => state.webUser.sendMobileVerificationLoading
    );

    const sendMobileVerificationSuccess = useSelector(
        (state: RootState) => state.webUser.sendMobileVerificationSuccess
    );

    const sendMobileVerificationError = useSelector(
        (state: RootState) => state.webUser.sendMobileVerificationError
    );

    const changeUserPasswordSuccess = useSelector(
        (state: RootState) => state.webUser.changeUserPasswordSuccess
    );

    const changeUserPasswordLoading = useSelector(
        (state: RootState) => state.webUser.changeUserPasswordLoading
    );

    const [mobileNumberUpdateMessage, setMobileNumberUpdateMessage] = useState('');

    const [showToast, setShowToast] = React.useState(false);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = React.useState(false);
    const [showSuccessModalPassword, setShowSuccessModalPassword] = React.useState(false);
    const [showModalPhoneNumber, setShowModalPhoneNumber] = React.useState(false);
    const [newEmailAddress, setNewEmailAddress] = useState<string>(null);
    const [currentEmailAddress, setCurrentEmailAddress] = useState<string>(studentData?.emailId);
    const [currentPhoneNumber, setCurrentPhoneNumber] = useState<number>(
        studentData?.contact?.mobileNo
    );
    const [newPhoneNumber, setNewPhoneNumber] = useState<string>('');
    const [currentPassword, setCurrentPassword] = useState<string>();
    const [newPassword, setNewPassword] = useState<string>(null);
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>(null);

    const [isErrorNewEmailAddress, setIsErrorNewEmailAddress] = useState<boolean>(false);
    const [newEmailErrorMessage, setNewEmailErrorMessage] = useState<string>('');
    const viewPortWidth = useWidth();
    const changeEmailFeedbackUrl = '/dashBoard/profile/changeEmail';
    const changeMobileFeedbackUrl = '/dashBoard/profile/changeMobile';

    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            backdropFilter: 'blur(7px)',
            overflow: 'auto',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    const passwordValidationSchema = Yup.object({
        currentPassword: Yup.string().required('Current password is required'),
        password: Yup.string()
            .required('Password cannot be blank')
            .min(
                8,
                'Password not strong. Enter at least 8 characters. Must Contain One Uppercase, One Lowercase, One Number and one special case Character'
            )
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
            ),
        confirmPassword: Yup.string()
            .required('Confirm password is required')
            .oneOf([Yup.ref('password'), null], 'Password must match'),
    });

    useEffect(() => {
        dispatch(getStudentActivityLog(false, profile?.userId));
    }, [dispatch]);

    useEffect(() => {
        setCurrentEmailAddress(studentData?.emailId);
        setCurrentPhoneNumber(studentData?.contact?.mobileNo);
    }, [studentData]);

    useEffect(() => {
        if (studentDataUpdateSuccess) {
            if (newPhoneNumber) {
                setShowModalPhoneNumber(true);
            }
        }
    }, [newPhoneNumber, studentDataUpdateSuccess]);

    useEffect(() => {
        if (sendEmailVerificationSuccess) {
            if (newEmailAddress) {
                setShowSuccessModal(true);
                dispatch({ type: SEND_EMAIL_VERIFICATION_RESET });
            }
        }
    }, [dispatch, newEmailAddress, sendEmailVerificationSuccess]);

    useEffect(() => {
        if (sendMobileVerificationSuccess && newPhoneNumber) {
            history.push({
                pathname: changeMobileFeedbackUrl,
                state: {
                    phoneNumber: newPhoneNumber,
                },
            });
        }
    }, [changeMobileFeedbackUrl, history, newPhoneNumber, sendMobileVerificationSuccess]);

    useEffect(() => {
        if (sendMobileVerificationError) {
            setMobileNumberUpdateMessage(sendMobileVerificationError);
            setShowModalPhoneNumber(true);
        }
    }, [sendMobileVerificationError]);

    useEffect(() => {
        if (isErrorNewEmailAddress) {
            setNewEmailErrorMessage('Enter a valid email address');
        } else if (sendEmailVerificationError) {
            setNewEmailErrorMessage(sendEmailVerificationError);
        }
    }, [sendEmailVerificationError, isErrorNewEmailAddress]);

    useEffect(() => {
        if (changeUserPasswordSuccess) {
            setToastMessage(PASSWORD_CHANGE_SUCCESS_MESSAGE);
            setShowSuccessModalPassword(true);
            setTimeout(() => {
                dispatch(logoutWeb());
                history.push('/signin');
            }, 10000);
        }
    }, [changeUserPasswordSuccess, dispatch, history]);

    useEffect(() => {
        // setShowSuccessModalPassword(true);
        // setShowModalPhoneNumber(true);
        // setShowSuccessModal(true);
    }, []);
    return (
        <>
            {viewPortWidth > 1023 ? (
                <>
                    <Toastr message={toastMessage} showToast={showToast} />
                    <Modal isOpen={showSuccessModal} style={customStyles}>
                        <>
                            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-1/3 my-6 mx-auto">
                                    {/* content */}
                                    <div
                                        style={{ maxHeight: '300px' }}
                                        className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-6"
                                    >
                                        <div
                                            style={{
                                                position: 'relative',
                                                top: '-90px',
                                            }}
                                            className="flex flex-col -bottom-2"
                                        >
                                            <span className="successTick self-center"></span>
                                            <h1 className="mb-2 mt-3 text-3xl text-center font-bold">
                                                Successful
                                            </h1>
                                            <p className="text-center my-2 font-semibold">
                                                Your request for change email have been processed
                                                successfully. We have sent you a verification email
                                                to your new email address {newEmailAddress}.
                                            </p>
                                            <div className="flex justify-center mt-3">
                                                <button
                                                    onClick={() => {
                                                        setShowSuccessModal(false);
                                                        history.push({
                                                            pathname: changeEmailFeedbackUrl,
                                                            state: {
                                                                email: newEmailAddress,
                                                            },
                                                        });
                                                    }}
                                                    className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                                >
                                                    Ok
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Modal>

                    <Modal isOpen={showModalPhoneNumber} style={customStyles}>
                        <>
                            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-1/3 my-6 mx-auto">
                                    {/* content */}
                                    <div
                                        className="absolute z-50 top-6 right-6 cursor-pointer"
                                        onClick={() => {
                                            setShowModalPhoneNumber(false);
                                        }}
                                    >
                                        <CloseIcon size={20} color="rgba(17, 17, 17, 0.48)" />
                                    </div>
                                    <div
                                        style={{ maxHeight: '656px' }}
                                        className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-12"
                                    >
                                        <div className="flex flex-col justify-center text-center">
                                            {mobileNumberUpdateMessage.includes('already exists')
                                                ? 'Your mobile number already exists. Please use a different mobile number.'
                                                : mobileNumberUpdateMessage}
                                        </div>
                                        <div className="flex justify-center mt-5">
                                            <button
                                                onClick={() => {
                                                    setShowModalPhoneNumber(false);
                                                    setNewPhoneNumber(null);
                                                }}
                                                className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                            >
                                                Ok
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Modal>

                    <Modal isOpen={showSuccessModalPassword} style={customStyles}>
                        <>
                            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-1/3 my-6 mx-auto">
                                    {/* content */}
                                    <div
                                        style={{ maxHeight: '270px' }}
                                        className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-6"
                                    >
                                        <div
                                            style={{
                                                position: 'relative',
                                                top: '-90px',
                                            }}
                                            className="flex flex-col -bottom-2"
                                        >
                                            <span className="successTick self-center"></span>
                                            <h1 className="mb-2 mt-3 text-3xl text-center font-bold">
                                                Successful
                                            </h1>
                                            <p className="text-center my-2 font-semibold">
                                                You have successfully changed your password
                                            </p>
                                            <div className="flex justify-center mt-3">
                                                <button
                                                    onClick={() => {
                                                        setShowSuccessModalPassword(false);
                                                        dispatch(logoutWeb());
                                                        dispatch(clearWebAuth());
                                                        history.push('/signin');
                                                    }}
                                                    className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                                >
                                                    Ok
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Modal>
                    <SocialMediaLoginUnableChange
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                        authType={studentData?.authType}
                    />
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        onSubmit={async () => {
                            dispatch(
                                sendEmailVerification(
                                    profile?.userId,
                                    profile?.userEmail,
                                    newEmailAddress
                                )
                            );
                        }}
                    >
                        {({ handleSubmit }) => (
                            <div className="flex flex-col mt-7">
                                <div className="font-bold font-manrope text-xl">Change Email</div>
                                <div className="font-bold mt-2.5 text-sm text-gray-500">
                                    Current email address
                                </div>
                                <div className="mt-1.5">
                                    Your current email address is{' '}
                                    <span className="text-purple-950">{currentEmailAddress}</span>
                                </div>
                                <div
                                    onClick={() => {
                                        if (
                                            studentData?.authType === 'GOOGLE' ||
                                            studentData?.authType === 'FACEBOOK' ||
                                            studentData?.authType === 'LINKEDIN'
                                        ) {
                                            setModalVisible(true);
                                        }
                                    }}
                                    className="mt-3 flex flex-col"
                                >
                                    <label className="font-manrope text-sm mb-2" htmlFor="email">
                                        New email address
                                    </label>
                                    <input
                                        className="inputFieldThickness pl-2 py-1 h-10 w-2/5 "
                                        type="text"
                                        id="email"
                                        placeholder={`${
                                            studentData?.authType === 'GOOGLE' ||
                                            studentData?.authType === 'FACEBOOK' ||
                                            studentData?.authType === 'LINKEDIN'
                                                ? ''
                                                : 'Enter new email address'
                                        }`}
                                        value={newEmailAddress || ''}
                                        disabled={
                                            studentData?.authType === 'GOOGLE' ||
                                            studentData?.authType === 'FACEBOOK' ||
                                            studentData?.authType === 'LINKEDIN'
                                        }
                                        onChange={e => {
                                            setNewEmailErrorMessage('');
                                            setIsErrorNewEmailAddress(false);
                                            setNewEmailAddress(e.target.value);
                                        }}
                                        autoComplete="new-password"
                                    />
                                </div>
                                {isErrorNewEmailAddress || sendEmailVerificationError ? (
                                    <div className="text-red-500 text-xs my-1">
                                        {newEmailErrorMessage}
                                    </div>
                                ) : null}
                                <button
                                    disabled={newEmailAddress === null}
                                    onClick={() => {
                                        if (
                                            newEmailAddress.match(
                                                /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
                                            )
                                        ) {
                                            handleSubmit();
                                        } else {
                                            setIsErrorNewEmailAddress(true);
                                        }
                                    }}
                                    className={`mt-6 rounded border-2  font-semibold w-52 h-10 justify-center items-center flex text-sm ${
                                        newEmailAddress === null
                                            ? 'text-white border-gray-400 bg-gray-400 cursor-default'
                                            : 'hover:text-white hover:bg-purple-950 cursor-pointer text-purple-950 border-purple-950'
                                    }`}
                                >
                                    {isLoading ? <Loader /> : 'Change email address'}
                                </button>
                            </div>
                        )}
                    </Formik>

                    <Formik
                        enableReinitialize
                        initialValues={{ phoneNumber: '' }}
                        onSubmit={async () => {
                            dispatch(sendMobileVerification(profile?.userId, newPhoneNumber));
                        }}
                        validationSchema={Yup.object({
                            phoneNumber: Yup.string().matches(
                                /^[0-9]{9,13}$/,
                                'Please enter a valid phone number'
                            ),
                        })}
                    >
                        {({ values, handleSubmit, handleChange, setValues, errors, touched }) => (
                            <div className="flex flex-col mt-8">
                                <div className="font-bold font-manrope text-xl">
                                    Change Phone Number
                                </div>
                                <div className="font-bold mt-2.5 text-sm text-gray-500">
                                    Current phone number
                                </div>
                                <div className="mt-1.5">
                                    Your current phone number is{' '}
                                    <span className="text-purple-950">{currentPhoneNumber}</span>
                                </div>
                                <div className=" mt-3 flex flex-col">
                                    <label className="font-manrope text-sm" htmlFor="phoneNumber">
                                        Phone number
                                    </label>
                                    <div className="inputFieldThickness w-2/5">
                                        <PhoneInput
                                            inputStyle={{
                                                width: '100%',
                                                height: '48px',
                                                fontSize: '14px',
                                                paddingLeft: '48px',
                                                borderRadius: '4px',
                                                border: 0,
                                                fontFamily: 'Lato',
                                            }}
                                            countryCodeEditable={false}
                                            enableSearch={true}
                                            dropdownClass={'mobileNumberCountryDropdown'}
                                            specialLabel={''}
                                            country="lk"
                                            value={values?.phoneNumber}
                                            placeholder="Enter your Mobile Number (Eg: +94712345678)"
                                            onChange={(e, country: any) => {
                                                const Index = country.dialCode.length;

                                                if (e[Index] === '0') {
                                                    const modifiedNumber =
                                                        e.slice(0, Index) + e.slice(Index + 1);

                                                    setNewPhoneNumber(modifiedNumber);
                                                    setValues({ phoneNumber: modifiedNumber });
                                                } else {
                                                    setNewPhoneNumber(e);
                                                    setValues({ phoneNumber: e });
                                                }
                                            }}
                                        />
                                    </div>
                                    {errors.phoneNumber && touched.phoneNumber ? (
                                        <div className="text-red-500 text-xs my-1">
                                            {errors.phoneNumber}
                                        </div>
                                    ) : null}
                                </div>
                                <button
                                    type="button"
                                    disabled={newPhoneNumber === ''}
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                    className={`mt-6 rounded border-2  font-semibold w-52 h-10 justify-center items-center flex text-sm ${
                                        newPhoneNumber === ''
                                            ? 'text-white border-gray-400 bg-gray-400 cursor-default'
                                            : 'hover:text-white hover:bg-purple-950 cursor-pointer text-purple-950 border-purple-950'
                                    }`}
                                >
                                    {sendMobileVerificationLoading ? (
                                        <Loader />
                                    ) : (
                                        'Change phone number'
                                    )}
                                </button>
                            </div>
                        )}
                    </Formik>

                    <Formik
                        initialValues={{ password: '', currentPassword: '', confirmPassword: '' }}
                        onSubmit={async () => {
                            dispatch(changePassword(currentPassword, newPassword, profile?.userId));
                        }}
                        validationSchema={passwordValidationSchema}
                    >
                        {({ handleSubmit, setFieldValue, errors, touched }) => (
                            <div className="mb-24">
                                <div className="flex flex-col mt-8">
                                    <div className="font-bold font-manrope text-xl">
                                        Change Password
                                    </div>
                                    <div className="font-bold mt-2.5 text-sm text-gray-500">
                                        Change password
                                    </div>
                                    <div
                                        className=" mt-3 flex flex-col"
                                        onClick={() => {
                                            if (
                                                studentData?.authType === 'GOOGLE' ||
                                                studentData?.authType === 'FACEBOOK' ||
                                                studentData?.authType === 'LINKEDIN'
                                            ) {
                                                setModalVisible(true);
                                            }
                                        }}
                                    >
                                        <label
                                            className="font-manrope text-sm"
                                            htmlFor="currentPassword"
                                        >
                                            Current Password
                                        </label>
                                        <input
                                            className="inputFieldThickness pl-2 py-1 h-12 w-2/5 "
                                            type="text"
                                            id="currentPassword"
                                            value={currentPassword || ''}
                                            placeholder={`${
                                                studentData?.authType === 'GOOGLE' ||
                                                studentData?.authType === 'FACEBOOK' ||
                                                studentData?.authType === 'LINKEDIN'
                                                    ? ''
                                                    : 'Enter current password'
                                            }`}
                                            autoComplete="new-password"
                                            disabled={
                                                studentData?.authType === 'GOOGLE' ||
                                                studentData?.authType === 'FACEBOOK' ||
                                                studentData?.authType === 'LINKEDIN'
                                            }
                                            onChange={e => {
                                                setCurrentPassword(e.target.value);
                                                setFieldValue('currentPassword', e.target.value);
                                            }}
                                        />
                                        {errors.currentPassword && touched.currentPassword ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                {errors.currentPassword}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="flex ">
                                        <div
                                            onClick={() => {
                                                if (
                                                    studentData?.authType === 'GOOGLE' ||
                                                    studentData?.authType === 'FACEBOOK' ||
                                                    studentData?.authType === 'LINKEDIN'
                                                ) {
                                                    setModalVisible(true);
                                                }
                                            }}
                                            className=" mt-3 flex flex-col w-2/5"
                                        >
                                            <label
                                                className="font-manrope text-sm"
                                                htmlFor="password"
                                            >
                                                New Password
                                            </label>
                                            <input
                                                className="inputFieldThickness pl-2 py-1 h-12"
                                                type="text"
                                                id="password"
                                                name="password"
                                                value={newPassword || ''}
                                                disabled={!currentPassword}
                                                onChange={e => {
                                                    setNewPassword(e.target.value);
                                                    setFieldValue('password', e.target.value);
                                                }}
                                            />
                                            {errors.password && touched.password ? (
                                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                    {errors.password}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div
                                            onClick={() => {
                                                if (
                                                    studentData?.authType === 'GOOGLE' ||
                                                    studentData?.authType === 'FACEBOOK' ||
                                                    studentData?.authType === 'LINKEDIN'
                                                ) {
                                                    setModalVisible(true);
                                                }
                                            }}
                                            className="ml-4 mt-3 flex flex-col w-2/5"
                                        >
                                            <label
                                                className="font-manrope text-sm"
                                                htmlFor="confirmPassword"
                                            >
                                                Confirm Password
                                            </label>
                                            <input
                                                className="inputFieldThickness pl-2 py-1 h-12"
                                                type="text"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                value={confirmNewPassword || ''}
                                                disabled={!currentPassword}
                                                onChange={e => {
                                                    setConfirmNewPassword(e.target.value);
                                                    setFieldValue(
                                                        'confirmPassword',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {errors.confirmPassword && touched.confirmPassword ? (
                                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                    {errors.confirmPassword}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <button
                                        disabled={
                                            confirmNewPassword === null &&
                                            newPassword === null &&
                                            currentPassword === null
                                        }
                                        onClick={() => {
                                            if (
                                                confirmNewPassword !== null &&
                                                newPassword !== null &&
                                                currentPassword !== null
                                            ) {
                                                handleSubmit();
                                            }
                                        }}
                                        className={`mt-6 rounded border-2  font-semibold w-52 h-10 justify-center items-center flex text-sm ${
                                            confirmNewPassword === null ||
                                            newPassword === null ||
                                            currentPassword === null
                                                ? 'text-white border-gray-400 bg-gray-400 cursor-default'
                                                : 'hover:text-white hover:bg-purple-950 cursor-pointer text-purple-950 border-purple-950'
                                        }`}
                                    >
                                        {changeUserPasswordLoading ? <Loader /> : 'Change password'}
                                    </button>
                                </div>
                            </div>
                        )}
                    </Formik>
                </>
            ) : (
                <>
                    <Toastr message={toastMessage} showToast={showToast} />
                    <Modal isOpen={showSuccessModal} style={customStyles}>
                        <>
                            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-full my-6 mx-auto">
                                    {/* content */}
                                    <div
                                        style={{ maxHeight: '350px' }}
                                        className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-6"
                                    >
                                        <div
                                            style={{
                                                position: 'relative',
                                                top: '-90px',
                                            }}
                                            className="flex flex-col -bottom-2"
                                        >
                                            <span className="successTick self-center"></span>
                                            <h1 className="mb-2 mt-3 text-3xl text-center font-bold">
                                                Successful
                                            </h1>
                                            <p className="text-center my-2 font-semibold">
                                                Your request for change email have been processed
                                                successfully. We have sent you a verification email
                                                to your new email address {newEmailAddress}.
                                            </p>
                                            <div className="flex justify-center mt-3">
                                                <button
                                                    onClick={() => {
                                                        setShowSuccessModal(false);
                                                        history.push({
                                                            pathname: changeEmailFeedbackUrl,
                                                            state: {
                                                                email: newEmailAddress,
                                                            },
                                                        });
                                                    }}
                                                    className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                                >
                                                    Ok
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Modal>

                    <Modal isOpen={showModalPhoneNumber} style={customStyles}>
                        <>
                            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-full my-6 mx-auto">
                                    {/* content */}
                                    <div
                                        className="absolute z-50 top-6 right-6 cursor-pointer"
                                        onClick={() => {
                                            setShowModalPhoneNumber(false);
                                        }}
                                    >
                                        <CloseIcon size={20} color="rgba(17, 17, 17, 0.48)" />
                                    </div>
                                    <div
                                        style={{ maxHeight: '656px' }}
                                        className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-12"
                                    >
                                        <div className="flex flex-col justify-center">
                                            {mobileNumberUpdateMessage}
                                        </div>
                                        <div className="flex justify-center mt-5">
                                            <button
                                                onClick={() => {
                                                    setShowModalPhoneNumber(false);
                                                    setNewPhoneNumber(null);
                                                }}
                                                className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                            >
                                                Ok
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Modal>

                    <Modal isOpen={showSuccessModalPassword} style={customStyles}>
                        <>
                            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-full my-6 mx-auto">
                                    {/* content */}
                                    <div
                                        style={{ maxHeight: '270px' }}
                                        className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-6"
                                    >
                                        <div
                                            style={{
                                                position: 'relative',
                                                top: '-90px',
                                            }}
                                            className="flex flex-col -bottom-2"
                                        >
                                            <span className="successTick self-center"></span>
                                            <h1 className="mb-2 mt-3 text-3xl text-center font-bold">
                                                Successful
                                            </h1>
                                            <p className="text-center my-2 font-semibold">
                                                You have successfully changed your password
                                            </p>
                                            <div className="flex justify-center mt-3">
                                                <button
                                                    onClick={() => {
                                                        setShowSuccessModalPassword(false);
                                                        dispatch(logoutWeb());
                                                        dispatch(clearWebAuth());
                                                        history.push('/signin');
                                                    }}
                                                    className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                                >
                                                    Ok
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Modal>
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        onSubmit={async () => {
                            dispatch(
                                sendEmailVerification(
                                    profile?.userId,
                                    profile?.userEmail,
                                    newEmailAddress
                                )
                            );
                        }}
                    >
                        {({ handleSubmit }) => (
                            <div className="flex flex-col mt-7">
                                <div className="font-bold font-manrope text-lg">Change Email</div>
                                <div className="font-bold mt-2.5 text-xs text-gray-500">
                                    Current email address
                                </div>
                                <div className="mt-1.5" style={{ fontSize: 10 }}>
                                    Your current email address is{' '}
                                    <span className="text-purple-950">{currentEmailAddress}</span>
                                </div>
                                <div className="mt-3 flex flex-col">
                                    <label className="font-manrope text-xs mb-2" htmlFor="email">
                                        New email address
                                    </label>
                                    <input
                                        className="inputFieldThickness pl-2 py-1 h-10 w-full"
                                        type="text"
                                        id="email"
                                        placeholder={`${
                                            studentData?.authType === 'GOOGLE' ||
                                            studentData?.authType === 'FACEBOOK' ||
                                            studentData?.authType === 'LINKEDIN'
                                                ? ''
                                                : 'Enter new email address'
                                        }`}
                                        disabled={
                                            studentData?.authType === 'GOOGLE' ||
                                            studentData?.authType === 'FACEBOOK' ||
                                            studentData?.authType === 'LINKEDIN'
                                        }
                                        value={newEmailAddress || ''}
                                        onChange={e => {
                                            setNewEmailErrorMessage('');
                                            setIsErrorNewEmailAddress(false);
                                            setNewEmailAddress(e.target.value);
                                        }}
                                        autoComplete="new-password"
                                    />
                                </div>
                                {isErrorNewEmailAddress || newEmailErrorMessage ? (
                                    <div className="text-red-500 my-1" style={{ fontSize: 10 }}>
                                        {newEmailErrorMessage}
                                    </div>
                                ) : null}
                                <button
                                    disabled={newEmailAddress === null}
                                    onClick={() => {
                                        if (
                                            newEmailAddress.match(
                                                /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
                                            )
                                        ) {
                                            handleSubmit();
                                        } else {
                                            setIsErrorNewEmailAddress(true);
                                        }
                                    }}
                                    className={`mt-6 rounded border-2  font-semibold w-52 h-10 justify-center items-center flex text-sm ${
                                        newEmailAddress === null
                                            ? 'text-white border-gray-400 bg-gray-400 cursor-default'
                                            : 'hover:text-white hover:bg-purple-950 cursor-pointer text-purple-950 border-purple-950'
                                    }`}
                                >
                                    {isLoading ? <Loader /> : 'Change email address'}
                                </button>
                            </div>
                        )}
                    </Formik>

                    <Formik
                        enableReinitialize
                        initialValues={{ phoneNumber: '' }}
                        onSubmit={async () => {
                            dispatch(sendMobileVerification(profile?.userId, newPhoneNumber));
                        }}
                        validationSchema={Yup.object({
                            phoneNumber: Yup.string().matches(
                                /^[0-9]{9,13}$/,
                                'Please enter a valid phone number'
                            ),
                        })}
                    >
                        {({ values, handleSubmit, handleChange, setValues, errors, touched }) => (
                            <div className="flex flex-col mt-7">
                                <div className="font-bold font-manrope text-lg">
                                    Change Phone Number
                                </div>
                                <div className="font-bold mt-2.5 text-xs text-gray-500">
                                    Current phone number
                                </div>
                                <div className="mt-1.5" style={{ fontSize: 10 }}>
                                    Your current phone number is{' '}
                                    <span className="text-purple-950">{currentPhoneNumber}</span>
                                </div>
                                <div className=" mt-3 flex flex-col">
                                    <label className="font-manrope text-xs" htmlFor="phoneNumber">
                                        Phone number
                                    </label>
                                    <div className="inputFieldThickness w-full">
                                        <PhoneInput
                                            inputStyle={{
                                                width: '100%',
                                                height: '48px',
                                                fontSize: '12px',
                                                paddingLeft: '48px',
                                                paddingTop: '4px',
                                                paddingBottom: '4px',
                                                borderRadius: '4px',
                                                border: 0,
                                                fontFamily: 'Lato',
                                                textOverflow: 'ellipsis',
                                            }}
                                            countryCodeEditable={false}
                                            enableSearch={true}
                                            dropdownClass={'mobileNumberCountryDropdown'}
                                            specialLabel={''}
                                            country="lk"
                                            value={values?.phoneNumber}
                                            placeholder="Enter your Mobile Number (Eg: +94712345678)"
                                            onChange={(e, country: any) => {
                                                const Index = country.dialCode.length;

                                                if (e[Index] === '0') {
                                                    const modifiedNumber =
                                                        e.slice(0, Index) + e.slice(Index + 1);

                                                    setNewPhoneNumber(modifiedNumber);
                                                    setValues({ phoneNumber: modifiedNumber });
                                                } else {
                                                    setNewPhoneNumber(e);
                                                    setValues({ phoneNumber: e });
                                                }
                                            }}
                                        />
                                    </div>
                                    {errors.phoneNumber && touched.phoneNumber ? (
                                        <div className="text-red-500 my-1" style={{ fontSize: 10 }}>
                                            {errors.phoneNumber}
                                        </div>
                                    ) : null}
                                </div>
                                <button
                                    type="button"
                                    disabled={newPhoneNumber === ''}
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                    className={`mt-6 rounded border-2  font-semibold w-52 h-10 justify-center items-center flex text-sm ${
                                        newPhoneNumber === ''
                                            ? 'text-white border-gray-400 bg-gray-400 cursor-default'
                                            : 'hover:text-white hover:bg-purple-950 cursor-pointer text-purple-950 border-purple-950'
                                    }`}
                                >
                                    {sendMobileVerificationLoading ? (
                                        <Loader />
                                    ) : (
                                        'Change phone number'
                                    )}
                                </button>
                            </div>
                        )}
                    </Formik>

                    <Formik
                        initialValues={{ password: '', currentPassword: '', confirmPassword: '' }}
                        onSubmit={async () => {
                            dispatch(changePassword(currentPassword, newPassword, profile?.userId));
                        }}
                        validationSchema={passwordValidationSchema}
                    >
                        {({ handleSubmit, setFieldValue, errors, touched }) => (
                            <div className="mb-12">
                                <div className="flex flex-col mt-7">
                                    <div className="font-bold font-manrope text-lg">
                                        Change Password
                                    </div>
                                    <div className="font-bold mt-2.5 text-xs text-gray-500">
                                        Change password
                                    </div>
                                    <div className=" mt-3 flex flex-col">
                                        <label
                                            style={{ fontSize: 10 }}
                                            className="font-manrope"
                                            htmlFor="currentPassword"
                                        >
                                            Current Password
                                        </label>
                                        <input
                                            className="inputFieldThickness pl-2 py-1 h-12 w-full "
                                            type="text"
                                            id="currentPassword"
                                            value={currentPassword || ''}
                                            placeholder={`${
                                                studentData?.authType === 'GOOGLE' ||
                                                studentData?.authType === 'FACEBOOK' ||
                                                studentData?.authType === 'LINKEDIN'
                                                    ? ''
                                                    : 'Enter current password'
                                            }`}
                                            disabled={
                                                studentData?.authType === 'GOOGLE' ||
                                                studentData?.authType === 'FACEBOOK' ||
                                                studentData?.authType === 'LINKEDIN'
                                            }
                                            autoComplete="new-password"
                                            onChange={e => {
                                                setCurrentPassword(e.target.value);
                                                setFieldValue('currentPassword', e.target.value);
                                            }}
                                        />
                                        {errors.currentPassword && touched.currentPassword ? (
                                            <div
                                                className="text-red-500 mt-1"
                                                style={{ fontSize: 10 }}
                                            >
                                                {errors.currentPassword}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="flex flex-col ">
                                        <div className=" mt-3 flex flex-col w-full">
                                            <label
                                                style={{ fontSize: 10 }}
                                                className="font-manrope"
                                                htmlFor="password"
                                            >
                                                New Password
                                            </label>
                                            <input
                                                className="inputFieldThickness pl-2 py-1 h-12"
                                                type="text"
                                                id="password"
                                                name="password"
                                                value={newPassword || ''}
                                                disabled={!currentPassword}
                                                onChange={e => {
                                                    setNewPassword(e.target.value);
                                                    setFieldValue('password', e.target.value);
                                                }}
                                            />
                                            {errors.password && touched.password ? (
                                                <div
                                                    className="text-red-500 mt-1"
                                                    style={{ fontSize: 10 }}
                                                >
                                                    {errors.password}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="mt-3 flex flex-col w-full">
                                            <label
                                                style={{ fontSize: 10 }}
                                                className="font-manrope"
                                                htmlFor="confirmPassword"
                                            >
                                                Confirm Password
                                            </label>
                                            <input
                                                className="inputFieldThickness pl-2 py-1 h-12"
                                                type="text"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                value={confirmNewPassword || ''}
                                                disabled={!currentPassword}
                                                onChange={e => {
                                                    setConfirmNewPassword(e.target.value);
                                                    setFieldValue(
                                                        'confirmPassword',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {errors.confirmPassword && touched.confirmPassword ? (
                                                <div
                                                    className="text-red-500 mt-1"
                                                    style={{ fontSize: 10 }}
                                                >
                                                    {errors.confirmPassword}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <button
                                        disabled={
                                            confirmNewPassword === null &&
                                            newPassword === null &&
                                            currentPassword === null
                                        }
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                        className={`mt-6 rounded border-2  font-semibold w-52 h-10 justify-center items-center flex text-sm ${
                                            confirmNewPassword === null ||
                                            newPassword === null ||
                                            currentPassword === null
                                                ? 'text-white border-gray-400 bg-gray-400 cursor-default'
                                                : 'hover:text-white hover:bg-purple-950 cursor-pointer text-purple-950 border-purple-950'
                                        }`}
                                    >
                                        {changeUserPasswordLoading ? <Loader /> : 'Change password'}
                                    </button>
                                </div>
                            </div>
                        )}
                    </Formik>
                </>
            )}
        </>
    );
};

export default Settings;
