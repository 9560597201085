import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCompanies,
    getInstitutes,
    getNotificationById,
    getTags,
} from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { useHistory, useParams } from 'react-router-dom';
import { components } from 'react-select';
export const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};
interface paramsType {
    id: string;
}
export default function ViewNotification() {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<paramsType>();
    const notification = useSelector((state: RootState) => state.notifications.notification);

    useEffect(() => {
        dispatch(getCompanies(0, 100));
        dispatch(getInstitutes(0, 100));
        dispatch(getTags(true));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getNotificationById(params.id));
    }, [dispatch, params.id]);

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    <div className="relative">
                        <div className="flex justify-between items-center mb-4 md:mb-6 md:pt-2">
                            <p className="text-xl font-semibold text-left">View Notification</p>
                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    className="py-1 px-2 md:px-4 w-32 bg-blue-700 text-white text-sm rounded-sm focus:outline-none h-12 "
                                    onClick={() => {
                                        history.push('/admin/notifications');
                                    }}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                        <div className="border-2 border-gray-300 px-6 lg:px-32 pt-2 pb-4 mb-6">
                            <div className="mb-2 md:mb-4">
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Notification ID
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center border-gray-300 focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            id="id"
                                            type="text"
                                            value={notification?.id}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Campaign Title
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center border-gray-300 focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            id="id"
                                            type="text"
                                            value={notification?.campaignTitle}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Notification Content
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center border-gray-300 focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            id="id"
                                            type="text"
                                            value={notification?.content}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Notification Mode
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center border-gray-300 focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            id="id"
                                            type="text"
                                            value={notification?.sentType}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Notification Type
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center border-gray-300 focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            id="id"
                                            type="text"
                                            value={notification?.type}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Notified Student Count
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center border-gray-300 focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            id="id"
                                            type="text"
                                            value={notification?.studentCount}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
