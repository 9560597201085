import React, { ReactElement } from 'react';

interface Props {
    selectedTab: number;
    setSelectedTab: (value: number) => void;
}

export default function TableTabView({ selectedTab, setSelectedTab }: Props): ReactElement {
    return (
        <>
            <div className="flex flex-row">
                <div
                    className={`${
                        selectedTab === 1
                            ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                            : 'text-black-300 border-snow-500'
                    } flex text-sm md:text-base xl:text-lg mr-6 cursor-pointer items-center`}
                    onClick={() => {
                        setSelectedTab(1);
                    }}
                >
                    Published
                </div>
                <div
                    className={`${
                        selectedTab === 2
                            ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                            : 'text-black-300 border-snow-500'
                    } flex text-sm md:text-base xl:text-lg mr-6 cursor-pointer items-center`}
                    onClick={() => {
                        setSelectedTab(2);
                    }}
                >
                    Requested
                </div>
            </div>
        </>
    );
}
