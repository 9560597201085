import React, { useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import LandingPage from '../web/components/navbar/NavBar';
import Footer from '../web/components/navbar/Footer';
import FooterLoginStudent from '../web/components/SideBar/Footer';
import ProfileNavBar from '../web/components/SideBar/ProfileNavBar';
import FooterStudent from '../web/components/navbar/FooterStudent';

import { AUTH_TOKENS, EXPIRE_TIME, USER_PROFILE } from '../web/utils/storageWeb';
import useWidth from '../web/hooks/useWidth';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import { saveAuthToken, saveExpireTime, saveUserProfile } from '../redux/authStudent/actions';
import { logoutWeb } from '../redux/rootActions';

const ScreensWithNavAndSideBar = ({ children, sidebar }) => {
    const authToken = useSelector((state: RootState) => state.authStudent.authToken);
    const userProfile = useSelector((state: RootState) => state.authStudent.userProfile);
    const expireTime = useSelector((state: RootState) => state.authStudent.expireTime);

    const dispatch = useDispatch();

    if (!authToken) {
        // eslint-disable-next-line no-var
        var authTokenFromLocal = localStorage.getItem(AUTH_TOKENS);
        dispatch(saveAuthToken(authTokenFromLocal));
    }
    if (!userProfile) {
        const userProfileFromLocal = localStorage.getItem(USER_PROFILE);
        dispatch(saveUserProfile(JSON.parse(userProfileFromLocal)));
    }
    if (!expireTime) {
        const expireTimeFromLocal = localStorage.getItem(EXPIRE_TIME);
        dispatch(saveExpireTime(JSON.parse(expireTimeFromLocal)));
    }

    const [isNavBarExpanded, setIsNavBarExpanded] = useState<boolean>(false);
    const [isSideBarExpanded, setIsSideBarExpanded] = useState<boolean>(false);
    const isClassApply =
        window.location.pathname.includes('/dashBoard') ||
        window.location.pathname.includes('/signin') ||
        window.location.pathname.includes('/register');
    const viewPortWidth = useWidth();
    const getStyles = () => {
        const styles: React.CSSProperties = {};

        if (viewPortWidth < 1024 && window.location.pathname.includes('/dashBoard')) {
            styles.width = 'calc(100vw - 50px)';
            styles.marginLeft = '50px';
        }
        if (isClassApply) {
            if (window.location.pathname.includes('/dashBoard')) {
                styles.marginTop = '4.5rem';
            } else if (viewPortWidth > 1023) {
                styles.marginTop = '4.5rem';
            } else {
                styles.marginTop = '2.5rem';
            }
        }
        return styles;
    };
    return (
        <div style={{ minHeight: '100vh', maxWidth: '100vw' }} className="flex flex-col">
            {(authToken && authToken !== 'undefined' && authToken !== null) ||
            (authTokenFromLocal &&
                authTokenFromLocal !== 'undefined' &&
                authTokenFromLocal !== null) ? (
                <ProfileNavBar
                    isNavBarExpanded={isNavBarExpanded}
                    setIsNavBarExpanded={setIsNavBarExpanded}
                    setIsSideBarExpanded={setIsSideBarExpanded}
                />
            ) : (
                <LandingPage
                    isNavBarExpanded={isNavBarExpanded}
                    setIsNavBarExpanded={setIsNavBarExpanded}
                />
            )}
            {!isNavBarExpanded && !isSideBarExpanded && (
                <>
                    <div
                        className={` w-full ${isClassApply ? 'lg:pl-4 2xl:pl-6' : ''}`}
                        style={getStyles()}
                    >
                        {children}
                    </div>
                    <FooterLoginStudent />
                </>
            )}
        </div>
    );
};

function WebProtectedRoutesWithLayout({ component: Component, sidebar, ...restOfProps }) {
    return (
        <Route
            {...restOfProps}
            render={props => (
                <ScreensWithNavAndSideBar sidebar={sidebar}>
                    <Component {...props} />
                </ScreensWithNavAndSideBar>
            )}
        />
    );
}

export default WebProtectedRoutesWithLayout;
