import { StudentType } from '../students/reducers';
import {
    GET_MENTORS,
    GET_MENTORS_SUCCESS,
    GET_MENTORS_FAILED,
    GET_MENTOR_BY_ID,
    GET_MENTOR_BY_ID_SUCCESS,
    GET_MENTOR_BY_ID_FAILED,
    ADD_MENTOR,
    ADD_MENTOR_SUCCESS,
    ADD_MENTOR_FAILED,
    DELETE_MENTOR,
    DELETE_MENTOR_SUCCESS,
    DELETE_MENTOR_FAILED,
    EDIT_MENTOR,
    EDIT_MENTOR_SUCCESS,
    EDIT_MENTOR_FAILED,
    MENTORS_BULK_UPDATE,
    MENTORS_BULK_UPDATE_SUCCESS,
    MENTORS_BULK_UPDATE_FAILED,
    CLEAR_MENTORS_MESSAGES,
    GET_PDF,
    GET_PDF_FAILED,
    GET_PDF_SUCCESS,
    GET_DOCUMENT_PRESIGNED,
    GET_DOCUMENT_PRESIGNED_FAILED,
    GET_DOCUMENT_PRESIGNED_SUCCESS,
    GET_MENTEES,
    GET_MENTEES_FAILED,
    GET_MENTEES_SUCCESS,
    ASSIGN_MENTEES,
    ASSIGN_MENTEES_FAILED,
    ASSIGN_MENTEES_SUCCESS,
    GET_MENTOR_COMPANIES,
    GET_MENTOR_COMPANIES_SUCCESS,
    GET_MENTOR_COMPANIES_FAILED,
} from './actions';

export interface MentorEditType {
    mentorId: string;
    mentorName: string;
    mentorStatus: string;
    mentorType: string;
    imagePath: string;
    mentorDescription: string;
    companyName: string;
    designation: string;
    mentorEmail: string;
    contactNumber: string;
    linkedinProfile: string;
    filePath: string;
}
export interface MentorActivateType {
    mentorId: string;
    mentorName: string;
    mentorStatus: string;
}
export interface MentorType {
    id: string;
    mentorId: string;
    mentorName: string;
    mentorStatus: string;
    mentorType: string;
    imagePath: string;
    description: string;
    companyName: string;
    designation: string;
    mentorEmail: string;
    contactNumber: string;
    linkedinProfile: string;
    filePath: string[];
    createdDate: string;
    tags?: any;
}
export interface CountType {
    ALL: number;
    DELETED: number;
}
export interface MentorsInitialState {
    mentorsData: MentorType[];
    menteesData: StudentType[];
    menterCompanies: any;
    mentor: MentorType;
    pageCount: number;
    menteePageCount: number;
    count: CountType;
    isLoading: boolean;
    mentorByIdLoading: boolean;
    mentorByIdError: string | null;
    mentorsError: string | null;
    createMentorError: string | null;
    mentorCreateSuccessMessage: string | null;
    isDeleteLoading: boolean;
    deleteMentorError: string | null;
    mentorDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editMentorError: string | null;
    assignMenteeError: string | null;
    mentorUpdateSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    mentorBulkSuccessMessage: string | null;
    isPdfLoading: boolean;
    pdfError: string | null;
    presignedSuccess: string | null;
    pdfLink: string | null;
}

const initialState: MentorsInitialState = {
    mentorsData: [],
    menteesData: [],
    menterCompanies: [],
    mentor: null,
    pageCount: null,
    menteePageCount: null,
    count: null,
    isLoading: false,
    mentorByIdLoading: false,
    mentorByIdError: null,
    mentorsError: null,
    createMentorError: null,
    mentorCreateSuccessMessage: null,
    isDeleteLoading: false,
    deleteMentorError: null,
    mentorDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editMentorError: null,
    assignMenteeError: null,
    mentorUpdateSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    mentorBulkSuccessMessage: null,
    isPdfLoading: false,
    pdfError: null,
    pdfLink: null,
    presignedSuccess: null,
};

const mentorsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_MENTORS:
            return { ...state, isLoading: true, mentorsError: null };
        case GET_MENTORS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                mentorsData: action.payload.items,
                pageCount: action.payload.totalPages,
            };
        case GET_MENTORS_FAILED:
            return { ...state, isLoading: false, mentorsError: action.payload };
        case GET_MENTOR_COMPANIES:
            return { ...state, isLoading: true, menterCompanies: [] };
        case GET_MENTOR_COMPANIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                menterCompanies: action.payload.companyNames,
            };
        case GET_MENTOR_COMPANIES_FAILED:
            return { ...state, isLoading: false };
        case GET_MENTOR_BY_ID:
            return { ...state, mentorByIdLoading: true, mentorByIdError: null };
        case GET_MENTOR_BY_ID_SUCCESS:
            return {
                ...state,
                mentorByIdLoading: false,
                mentor: action.payload,
            };
        case GET_MENTOR_BY_ID_FAILED:
            return { ...state, mentorByIdLoading: false, mentorByIdError: action.payload };

        case ADD_MENTOR:
            return { ...state, isCreateLoading: true, createMentorError: null };
        case ADD_MENTOR_SUCCESS:
            return {
                ...state,
                isCreateLoading: false,
                mentorCreateSuccessMessage: action.payload,
            };
        case ADD_MENTOR_FAILED:
            return { ...state, isCreateLoading: false, createMentorrror: action.payload };
        case DELETE_MENTOR:
            return { ...state, isDeleteLoading: true, deleteMentorrror: null };
        case DELETE_MENTOR_SUCCESS:
            return {
                ...state,
                isDeleteLoading: false,
                mentorDeleteSuccessMessage: action.payload,
            };
        case DELETE_MENTOR_FAILED:
            return { ...state, isDeleteLoading: false, deleteMentorError: action.payload };
        case EDIT_MENTOR:
            return { ...state, isEditLoading: true, editMentorError: null };
        case EDIT_MENTOR_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                mentorUpdateSuccessMessage: action.payload,
            };
        case EDIT_MENTOR_FAILED:
            return { ...state, isEditLoading: false, editMentorError: action.payload };

        case MENTORS_BULK_UPDATE:
            return { ...state, isBulkUpdateLoading: true, bulkError: null };
        case MENTORS_BULK_UPDATE_SUCCESS:
            return {
                ...state,
                isBulkUpdateLoading: false,
                mentorBulkSuccessMessage: action.payload,
            };
        case MENTORS_BULK_UPDATE_FAILED:
            return { ...state, isBulkUpdateLoading: false, bulkError: action.payload };
        case GET_PDF:
            return { ...state, isPdfLoading: true, pdfError: null };
        case GET_PDF_SUCCESS:
            return {
                ...state,
                isPdfLoading: false,
                pdfLink: action.payload,
            };
        case GET_PDF_FAILED:
            return { ...state, isPdfLoading: false, pdfError: action.payload };
        case GET_DOCUMENT_PRESIGNED:
            return { ...state, isPdfLoading: true, pdfError: null };
        case GET_DOCUMENT_PRESIGNED_SUCCESS:
            return {
                ...state,
                isPdfLoading: false,
                presignedSuccess: action.payload,
            };
        case GET_DOCUMENT_PRESIGNED_FAILED:
            return { ...state, isPdfLoading: false, pdfError: action.payload };
        case GET_MENTEES:
            return { ...state, mentorByIdLoading: true, mentorByIdError: null };
        case GET_MENTEES_SUCCESS:
            return {
                ...state,
                mentorByIdLoading: false,
                menteesData: action.payload.items,
                menteePageCount: action.payload.totalPages,
            };
        case GET_MENTEES_FAILED:
            return { ...state, mentorByIdLoading: false, mentorByIdError: action.payload };
        case ASSIGN_MENTEES:
            return { ...state, isEditLoading: true, assignMenteeError: null };
        case ASSIGN_MENTEES_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                mentorUpdateSuccessMessage: action.payload,
            };
        case ASSIGN_MENTEES_FAILED:
            return { ...state, isEditLoading: false, assignMenteeError: action.payload };
        case CLEAR_MENTORS_MESSAGES:
            return {
                ...state,
                mentorCreateSuccessMessage: null,
                createMentorError: null,
                mentorUpdateSuccessMessage: null,
                editMentorError: null,
                deleteMentorError: null,
                mentorDeleteSuccessMessage: null,
                mentorBulkSuccessMessage: null,
                bulkError: null,
                assignMenteeError: null,
            };
        default:
            return state;
    }
};

export default mentorsReducer;
