import React, { useState, useMemo, useEffect, useRef } from 'react';
import * as RiIcons from 'react-icons/ri';
import RightSideContent from '../../RightSideContent';
import * as HiIcons from 'react-icons/hi';
import * as BsIcons from 'react-icons/bs';
import * as BiIcons from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { getInstitutes, getTags } from '../../../redux/rootActions';
import Tab from './Tab';
import InstituteTable from './InstituteTable';
import { InstitutesType } from '../../../redux/institutes/reducers';
import _ from 'lodash';
import BulkRestoreConfirmationModal from '../../modals/BulkRestoreConfirmation';
import BulkDeleteConfirmationModal from '../../modals/BulkDeleteConfirmation';
import NewInstituteTable from './NewInstituteTable';
import Loader from '../../../assets/svg/Loader';
import { instituteTypes, moduleNames } from '../../../constants';
import Add from '../../../assets/svg/Add';
import AddFilter from './AddFilter';
import { useQuery } from '../../../web/hooks/useQuery';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export const Institutes = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const searchTermRef = useRef();
    const backToFirstRef = useRef(null);
    const institutesData = useSelector((state: RootState) => state.institutes.institutesData);
    const isLoading = useSelector((state: RootState) => state.institutes.isLoading);
    const filteredInstituteData = useSelector(
        (state: RootState) => state.filteredInstitutes.institutesData
    );
    const pageCount = useSelector((state: RootState) => state.institutes.pageCount);
    const [tags, setTags] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [searchResultsVisible, setSearchResultsVisible] = useState(false);
    const [sidebar, setSidebar] = useState(true);
    const showSidebar = () => setSidebar(!sidebar);
    const [bulkAction, setBulkAction] = useState<string>('Bulk Action');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selectedInstitutes, setSlectedInstitutes] = useState([]);
    const [selectedMainTab, setSelectedMainTab] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [clickedChallenge, setClickedChallenge] = useState<InstitutesType[]>();
    const [restoreBulkConfirmationVisible, setRestoreBulkConfirmationVisible] = useState(false);
    const [deleteBulkConfirmationVisible, setDeleteBulkConfirmationVisible] = useState(false);
    const [pageSizeS, setPageSizeS] = useState(0);
    const [pageIndexS, setPageIndexS] = useState(0);
    const [tagIds, setTagIds] = useState('');
    const [joinedDateFromSelected, setJoinedDateFromSelected] = useState('');
    const [joinedDateToSelected, setJoinedDateToSelected] = useState('');
    const [instituteTypesSelected, setInstituteTypesSelected] = useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [filterInstituteTypes, setFilterInstituteTypes] = useState([]);
    const [cleanedInstituteData, setCleanedInstituteData] = useState<InstitutesType[]>([]);
    const tagData = useSelector((state: RootState) => state.tags.tagData);

    const [tagsSelected, setTagsSelected] = useState([]);
    const [filterTags, setFilterTags] = useState([]);

    const instituteTypesQS = query.get('instituteTypes');
    const joinedDateFromQS = query.get('joinedDateFrom');
    const joinedDateToQS = query.get('joinedDateTo');
    const tagIdsQS = query.get('tagIds');
    const initialRender = useRef(true);

    const fetchIdRef = React.useRef(0);
    const tabStatus = selectedTab === 1 ? '' : 'DELETED';
    const bulkOptions = [
        { id: 0, val: 'Delete' },
        { id: 1, val: 'Restore' },
    ];
    const instituteTypesData = [
        {
            id: instituteTypes.GOVERNMENT_UNIVERSITY,
            name: instituteTypes.GovernmentUniversity,
        },
        {
            id: instituteTypes.PRIVATE_UNIVERSITY,
            name: instituteTypes.PrivateUniversity,
        },
        {
            id: instituteTypes.VOCATIONAL_INSTITUTE,
            name: instituteTypes.VocationalTrainingInstitute,
        },
        {
            id: instituteTypes.OTHER,
            name: instituteTypes.Other,
        },
    ];
    const instituteBulk = {
        listToUpdate: selectedInstitutes.map(elem => {
            return {
                action: bulkAction === 'Restore' ? 'ACTIVE' : 'DELETED',
                id: elem.id,
            };
        }),
    };

    const applyBulkAction = async () => {
        if (bulkAction === 'Restore') {
            setRestoreBulkConfirmationVisible(true);
        } else if (bulkAction === 'Delete') {
            setDeleteBulkConfirmationVisible(true);
        }
    };

    useEffect(() => {
        const otherTemp = institutesData.filter(institute => institute.instName !== 'Other');
        setCleanedInstituteData(otherTemp);
        if (tagData.length > 0) {
            const newTagsArr = tagData.map(item => {
                return { name: item.tagName, category: item.tagCategory, id: item.id };
            });
            setFilterTags(newTagsArr);
        } else {
            setFilterTags([]);
        }
    }, [institutesData, tagData]);

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: '',
                id: 'actionColumn',
                disableSortBy: true,
                width: 5,
                // eslint-disable-next-line react/display-name
                Cell: (
                    <div className="cursor__pointer">
                        <button
                            value="menu cursor-pointer"
                            className="text-gray-500 rounded cursor-pointer focus:outline-none lg:ml-1 lg:mx-1"
                        >
                            <BsIcons.BsThreeDotsVertical className="mx-auto" />
                        </button>
                    </div>
                ),
            },
            {
                Header: 'Institute ID',
                accessor: 'instId',
            },
            {
                Header: 'Institute Name',
                accessor: 'instName',
            },
            {
                Header: 'Email',
                accessor: 'instEmail',
            },
            {
                Header: 'Contact Number',
                accessor: 'contactNo',
            },
            {
                Header: 'Representative Name',
                accessor: 'repName',
            },
            {
                Header: 'Category',
                accessor: 'category',
            },
            {
                Header: 'Joined Date',
                accessor: data => {
                    return data?.instJoinedDate != null
                        ? moment(data?.instJoinedDate).local().format('YYYY-MM-DD')
                        : null;
                },
            },
            {
                Header: 'Location',
                accessor: 'location',
            },
            {
                Header: 'Status',
                accessor: 'instStatus',
            },
            {
                Header: 'Course Names',
                id: 'courses',
                accessor: data => {
                    const output = [];
                    _.map(data.courses, course => {
                        output.push(course);
                    });
                    return output.join(', ');
                },
            },
            {
                Header: 'Faculties',
                id: 'faculties',
                accessor: data => {
                    const output = [];
                    _.map(data.faculties, faculty => {
                        output.push(faculty);
                    });
                    return output.join(', ');
                },
            },
            {
                Header: 'Institute Description',
                accessor: 'instDescription',
            },
        ],
        []
    );

    const fetchData = React.useCallback(
        ({ pageSize, pageIndex }) => {
            setPageSizeS(pageSize);
            setPageIndexS(pageIndex);
            const tagIdsT = tags?.map(tagss => tagss.id).toString();
            setTagIds(tagIdsT);
            const fetchId = ++fetchIdRef.current;
            if (fetchId === fetchIdRef.current) {
                dispatch(
                    getInstitutes(
                        pageIndex,
                        pageSize,
                        tabStatus,
                        tagIdsT || tagIdsQS?.split(',').toString(),
                        searchTermRef.current,
                        instituteTypesSelected.toString() ||
                            instituteTypesQS?.split(',').toString(),
                        joinedDateFromSelected.toString() || joinedDateFromQS,
                        joinedDateToSelected.toString() || joinedDateToQS
                    )
                );
            }
        },
        [
            dispatch,
            tabStatus,
            tags,
            tagIdsQS,
            joinedDateFromQS,
            joinedDateToQS,
            instituteTypesSelected,
            joinedDateFromSelected,
            joinedDateToSelected,
        ]
    );

    const searchByNameOrId = async () => {
        await backToFirstRef?.current?.goToFirst();
        dispatch(
            getInstitutes(
                0,
                pageSizeS,
                tabStatus,
                tagIdsQS?.split(',').toString(),
                searchTerm,
                instituteTypesSelected.toString(),
                joinedDateFromSelected.toString(),
                joinedDateToSelected.toString()
            )
        );
    };

    const searchInputHandler = e => {
        searchTermRef.current = e.target.value;
        setSearchTerm(e.target.value);
    };
    useEffect(() => {
        setBulkAction('Bulk Action');
    }, [selectedTab]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            searchByNameOrId();
        }
    };

    const onRequestClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        const goBack = async () => {
            backToFirstRef?.current?.goToFirst();
        };
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            goBack().then(() => {
                history.replace({
                    search: `?instituteTypes=${instituteTypesSelected.toString()}&joinedDateFrom=${joinedDateFromSelected.toString()}&joinedDateTo=${joinedDateToSelected.toString()}&tagIds=${tagsSelected.toString()}`,
                });
                dispatch(
                    getInstitutes(
                        pageIndexS,
                        pageSizeS,
                        tabStatus,
                        tagsSelected.toString(),
                        searchTerm,
                        instituteTypesSelected.toString(),
                        joinedDateFromSelected.toString(),
                        joinedDateToSelected.toString()
                    )
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagsSelected, instituteTypesSelected, joinedDateFromSelected, joinedDateToSelected]);

    useEffect(() => {
        if (instituteTypesQS) {
            setInstituteTypesSelected(instituteTypesQS.split(','));
        }
        if (tagIdsQS) {
            setTagsSelected(tagIdsQS.split(','));
        }
        if (joinedDateFromQS) {
            setJoinedDateFromSelected(joinedDateFromQS);
        }
        if (joinedDateToQS) {
            setJoinedDateToSelected(joinedDateToQS);
        }
        setFilterInstituteTypes(instituteTypesData);
        dispatch(getTags(true, moduleNames.Institute));
    }, []);

    useEffect(() => {
        if (searchTerm.length === 0) {
            dispatch(
                getInstitutes(
                    pageIndexS,
                    pageSizeS,
                    tabStatus,
                    tagsSelected.toString(),
                    searchTerm,
                    instituteTypesSelected.toString(),
                    joinedDateFromSelected.toString(),
                    joinedDateToSelected.toString()
                )
            );
        }
    }, [searchTerm]);

    const openFilter = () => {
        setShowModal(true);
    };

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <Tab selectedTab={selectedMainTab} setSelectedTab={setSelectedMainTab} />
                <div className="flex w-full">
                    <div
                        style={{
                            width: sidebar === false ? '100%' : '75%',
                        }}
                    >
                        <div className="flex h-12 md:h-14 justify-between md:justify-start">
                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex">
                                <input
                                    className={`rounded-sm w-full h-full text-gray-500 font-medium focus:outline-none p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                    id="id"
                                    type="text"
                                    placeholder="Search by ID or Name"
                                    onChange={e => searchInputHandler(e)}
                                    autoComplete="off"
                                    onKeyDown={handleKeyDown}
                                />
                                <button
                                    className={`ml-2 px-5 border-2 font-medium text-sm rounded-md focus:outline-none ${
                                        searchTerm.length > 0
                                            ? ' text-white border-blue-700 bg-blue-700 cursor-pointer'
                                            : 'cursor-not-allowed border-gray-300'
                                    }`}
                                    disabled={searchTerm.length === 0}
                                    onClick={searchByNameOrId}
                                >
                                    <BsIcons.BsSearch
                                        style={searchTerm.length > 0 && { color: '#ffffff' }}
                                    />
                                </button>
                            </div>
                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex">
                                <div
                                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-3/4 md:w-3/4 lg:w-3/4 xl:w-2/3 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className={`flex justify-between w-full md:p-2 ${
                                            selectedInstitutes.length <= 1
                                                ? 'cursor-not-allowed'
                                                : 'cursor-pointer'
                                        }  `}
                                        onClick={() =>
                                            selectedInstitutes.length > 1
                                                ? setDropdownVisible(true)
                                                : null
                                        }
                                    >
                                        <div className="text-gray-700 font-medium pl-2">
                                            {bulkAction}
                                        </div>
                                        <div className="">
                                            <div className="flex flex-col">
                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                <HiIcons.HiChevronDown className="mr-2" />
                                            </div>
                                        </div>
                                    </div>
                                    {dropdownVisible ? (
                                        <ul
                                            className="absolute w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setDropdownVisible(false)}
                                        >
                                            {bulkOptions
                                                .filter(option => {
                                                    if (selectedTab === 1) {
                                                        return option.val === 'Delete';
                                                    } else return option.val === 'Restore';
                                                })
                                                .map(action => (
                                                    <div
                                                        className="p-2 hover:bg-gray-200 "
                                                        key={action.id}
                                                        onClick={() => {
                                                            setBulkAction(action.val);
                                                            setDropdownVisible(false);
                                                        }}
                                                    >
                                                        {action.val}
                                                    </div>
                                                ))}
                                        </ul>
                                    ) : null}
                                </div>
                                <button
                                    className={`ml-2 px-2 border-2 ${
                                        bulkAction === 'Bulk Action'
                                            ? 'text-gray-400 border-gray-300'
                                            : 'text-white border-blue-700 bg-blue-700'
                                    } font-medium text-sm rounded-md focus:outline-none  hover:border-blue-700 hover:text-white hover:bg-blue-700`}
                                    onClick={applyBulkAction}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                        <div className="flex h-12 md:h-14 justify-between md:justify-start align-center p-5">
                            <BiIcons.BiFilterAlt
                                style={{
                                    color: 'rgba(29, 78, 216)',
                                    height: '20px',
                                    width: '20px',
                                    marginRight: '10px',
                                    alignSelf: 'baseline',
                                }}
                            />
                            <div
                                className="sm:text-sm md:text-base text-gray-400 pr-2 cursor-pointer"
                                onClick={openFilter}
                            >
                                Filter
                            </div>
                            <button onClick={openFilter}>
                                <Add size={25} color={'rgba(29, 78, 216)'} />
                            </button>
                            <AddFilter
                                setInstituteTypesSelected={setInstituteTypesSelected}
                                instituteTypesSelected={instituteTypesSelected}
                                joinedDateFromSelected={joinedDateFromSelected}
                                setJoinedDateFromSelected={setJoinedDateFromSelected}
                                joinedDateToSelected={joinedDateToSelected}
                                setJoinedDateToSelected={setJoinedDateToSelected}
                                isSelected={showModal}
                                onRequestClose={onRequestClose}
                                filterInstituteTypes={filterInstituteTypes}
                                setTagsSelected={setTagsSelected}
                                tagsSelected={tagsSelected}
                                filterTags={filterTags}
                            />
                            {instituteTypesSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Institute Types filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setInstituteTypesSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                            {(joinedDateFromSelected || joinedDateToSelected) && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Joined Date filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => {
                                            setJoinedDateFromSelected('');
                                            setJoinedDateToSelected('');
                                        }}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                            {tagsSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 ml-2 mr-1">
                                        Tags
                                    </div>
                                    <div className="rounded-full text-base mr-2 h-6 text-blue-800">
                                        {tagsSelected.length}
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setTagsSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        {isLoading && (
                            <div className=" w-full h-98  flex items-center">
                                <Loader />
                            </div>
                        )}
                        <div className={`${isLoading && 'hidden'}`}>
                            <InstituteTable
                                columns={columns}
                                data={
                                    selectedMainTab !== 1
                                        ? filteredInstituteData
                                        : clickedChallenge?.length > 0
                                        ? clickedChallenge
                                        : cleanedInstituteData
                                }
                                fetchData={fetchData}
                                pageCount={pageCount}
                                setSlectedChallenges={setSlectedInstitutes}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                backToFirstRef={backToFirstRef}
                            />
                        </div>
                    </div>
                    <div
                        className={`relative border-l-2 ${
                            sidebar === false ? '' : 'right-auto w-1/4'
                        }`}
                    >
                        {sidebar === false && (
                            <div className=" ">
                                <RiIcons.RiArrowDropLeftLine
                                    style={{
                                        borderRadius: 50,
                                        borderWidth: 1,
                                        borderColor: 'rgba(0,0,0,0.2)',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        height: '25px',
                                        width: '25px',
                                        top: '100px',
                                        left: '-13px',
                                        backgroundColor: 'white',
                                    }}
                                    onClick={showSidebar}
                                />
                            </div>
                        )}

                        {sidebar === true && (
                            <div className="h-full">
                                <RiIcons.RiArrowDropRightLine
                                    style={{
                                        borderRadius: 50,
                                        borderWidth: 1,
                                        borderColor: 'rgba(0,0,0,0.2)',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        height: '25px',
                                        width: '25px',
                                        top: '100px',
                                        left: '-13px',
                                        backgroundColor: 'white',
                                    }}
                                    onClick={showSidebar}
                                />
                                <div className="h-96 ml-2 w-full mt-10">
                                    <RightSideContent
                                        tags={tags}
                                        setTags={setTags}
                                        moduleName={moduleNames.Institute}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {restoreBulkConfirmationVisible ? (
                <BulkRestoreConfirmationModal
                    modalVisible={restoreBulkConfirmationVisible}
                    setModalVisible={setRestoreBulkConfirmationVisible}
                    institutes={instituteBulk}
                    tabStatus={tabStatus}
                />
            ) : null}
            {deleteBulkConfirmationVisible ? (
                <BulkDeleteConfirmationModal
                    modalVisible={deleteBulkConfirmationVisible}
                    setModalVisible={setDeleteBulkConfirmationVisible}
                    institutes={instituteBulk}
                    tabStatus={tabStatus}
                />
            ) : null}
        </div>
    );
};
