import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { loginAdmin } from '../redux/authAdmin/actions';
import { RootState } from '../redux/rootReducer';
import Loader from '../assets/svg/Loader';
import { STUDENT, UserProfileType } from '../redux/authAdmin/reducers';
import { signinError } from '../constants';
interface CustomState {
    redirect: string;
}
export default function AdminSignIn(): ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const redirectLink = location.state as CustomState;
    const isLoading = useSelector((state: RootState) => state.auth.isLoading);
    const loginResponse: UserProfileType | any = useSelector(
        (state: RootState) => state.auth.loginResponse
    );
    const loginErrorMessage = useSelector((state: RootState) => state.auth.loginErrorMessage);
    const [showPassword, setShowPassword] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const passwordShow = () => {
        setShowPassword(!showPassword);
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password cannot be blank'),
    });
    useEffect(() => {
        if (loginResponse?.token && !(loginResponse?.roles[0] === STUDENT)) {
            if (redirectLink?.redirect) history.push(redirectLink.redirect);
            else history.push('/admin/dashboard');
        }
        if (loginResponse?.roles[0] === STUDENT) {
            setShowErrorMessage(true);
        }
    }, [loginResponse, history]);
    return (
        <div>
            <div className="flex justify-center mt-12">
                <div className="w-2/3 lg:w-1/4">
                    <div className="flex flex-col mt-12">
                        <div className="font-bold md:text-3xl mb-4">SIGN IN</div>
                        <div className="">
                            <div className="">
                                <div className="flex-col sm:flex w-full mb-3"></div>

                                <div>
                                    <Formik
                                        initialValues={{ email: '', password: '' }}
                                        validationSchema={validationSchema}
                                        onSubmit={async ({ email, password }) => {
                                            dispatch(loginAdmin(email, password));
                                        }}
                                    >
                                        {({
                                            handleChange,
                                            handleSubmit,
                                            values,
                                            errors,
                                            touched,
                                        }) => (
                                            <form
                                                onSubmit={event => {
                                                    event.preventDefault();
                                                    handleSubmit();
                                                }}
                                            >
                                                <div className="mb-6 md:mb-4">
                                                    <div className="flex flex-row">
                                                        <label
                                                            className={`block text-sm font-medium leading-149 md:text-lg ${
                                                                errors.email && touched.email
                                                                    ? ''
                                                                    : 'mb-2'
                                                            }`}
                                                            htmlFor={'email'}
                                                        >
                                                            Email
                                                        </label>
                                                    </div>
                                                    <input
                                                        id="email"
                                                        type="email"
                                                        placeholder="Enter email"
                                                        className={`rounded flex items-center focus:outline-none w-full p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.email && touched.email
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        onChange={handleChange('email')}
                                                        value={values.email}
                                                    />

                                                    {errors.email && touched.email ? (
                                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                            {errors.email}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div className="mb-6 md:mb-4">
                                                    <div>
                                                        <label
                                                            className={`block text-sm font-medium leading-149 mb-2 md:text-lg ${
                                                                errors.password && touched.password
                                                                    ? ''
                                                                    : ''
                                                            }`}
                                                            htmlFor={'password'}
                                                        >
                                                            Password
                                                        </label>
                                                        <div className="relative">
                                                            <input
                                                                className={`rounded flex items-center focus:outline-none w-full p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.password &&
                                                                    touched.password
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="password"
                                                                type={
                                                                    showPassword
                                                                        ? 'text'
                                                                        : 'password'
                                                                }
                                                                placeholder="Enter password"
                                                                onChange={handleChange('password')}
                                                                value={values.password}
                                                            />
                                                            {errors.password && touched.password ? (
                                                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                                    {errors.password}
                                                                </div>
                                                            ) : null}
                                                            <div className="absolute inset-y-2 right-0 pt-1 pr-3 flex text-base item-center">
                                                                {values.password.length > 0 ? (
                                                                    <>
                                                                        <img
                                                                            src="/assets/passwordShowIcon.png"
                                                                            className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                                showPassword
                                                                                    ? 'block'
                                                                                    : 'hidden'
                                                                            }`}
                                                                            onClick={passwordShow}
                                                                            alt="show-password"
                                                                            role="presentation"
                                                                            draggable="false"
                                                                        />
                                                                        <img
                                                                            src="/assets/passwordHideIcon.png"
                                                                            className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                                showPassword
                                                                                    ? 'hidden'
                                                                                    : 'block'
                                                                            }`}
                                                                            alt="hide-password"
                                                                            role="presentation"
                                                                            onClick={passwordShow}
                                                                            draggable="false"
                                                                        />
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className={`my-4 ${
                                                        (errors.password && touched.password) ||
                                                        (errors.email && touched.email)
                                                            ? 'mb-2 md:mb-2'
                                                            : 'mb-4 md:mb-8'
                                                    }`}
                                                >
                                                    <label
                                                        className="flex justify-end pr-1 cursor-pointer font-semibold text-blue-700"
                                                        htmlFor="remeber-me"
                                                        onClick={() =>
                                                            history.push('/admin/forgot-password')
                                                        }
                                                    >
                                                        Forgot Password?
                                                    </label>
                                                </div>

                                                <div className="flex flex-col">
                                                    <div className="mb-2 md:my-4 text-center">
                                                        <button
                                                            type="submit"
                                                            className="focus:outline-none bg-blue-700 text-white text-snow-900 text-base rounded hover:border-transparent w-40 h-10 sm:w-80 md:w-full sm:h-12"
                                                            style={{
                                                                boxShadow:
                                                                    '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                            }}
                                                        >
                                                            {isLoading ? <Loader /> : 'Sign In'}
                                                        </button>
                                                    </div>
                                                    {(loginErrorMessage || showErrorMessage) && (
                                                        <div className="text-red-400 text-sm flex justify-center">
                                                            {loginErrorMessage || signinError}
                                                        </div>
                                                    )}
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
