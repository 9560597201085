import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { MentorType } from '../../../../redux/webRedux/webUsers/reducers';
import moment from 'moment';
import { s3UrlPrefix } from '../../../../constants';
import { saveAs } from 'file-saver';
import useWidth from '../../../hooks/useWidth';
import styled from 'styled-components';
import Toastr from '../../elements/Toastr';
import { clearWebUsersMessages, resendEmailVerificationWeb } from '../../../../redux/rootActions';
import Verified from '../../../../assets/svg/Verified';
import VerifyEmailSuccess from '../../../modals/VerifyEmailSuccess';
import * as HiIcons from 'react-icons/hi';

const SmallFontDiv = styled.div`
    font-size: 10px;
`;

const SmallFontLabel = styled.label`
    font-size: 10px;
`;

const SocialIconMobile = styled.img`
    width: 18px;
    height: 18px;
`;

const ProfileTab = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const emailResent = useSelector((state: RootState) => state?.newWebUser?.emailResent);
    const studentData = useSelector((state: RootState) => state.webUser.student);
    const [mentor, setMentor] = useState<MentorType>(studentData?.mentor);
    const [batchNumber, setBatchNumber] = useState<any>(studentData?.StudentInstitute?.studentId);
    const [trackingCode, setTrackingCode] = useState<any>(studentData?.bridgeInvitationCode);
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    const [firstName, setFirstName] = useState<string>(studentData?.firstName);
    const [fullName, setFullName] = useState<string>(null);
    const [lastName, setLastName] = useState<string>(studentData?.lastName);
    const [nicNumber, setNICNumber] = useState<string>(studentData?.nic);
    const [studentID, setStudentID] = useState<string>(studentData?.id);
    const [mobileNumber, setMobileNumber] = useState<number>(studentData?.contact?.mobileNo);
    const [email, setEmail] = useState<string>(studentData?.emailId);
    const [studentIdHelpText, setStudentIdHelpText] = useState<boolean>(false);
    const [address, setAddress] = useState<string>(studentData?.contact?.address1);
    const [facebook, setFacebook] = useState<string>();
    const [linkedin, setLinkedin] = useState<string>();
    const [twitter, setTwitter] = useState<string>();
    const [gender, setGender] = useState<string>();
    const [homeTown, setHomeTown] = useState<string>();

    const [institue, setInstitute] = useState<string>(null);
    const [educationStatus, setEducationStatus] = useState<any>(studentData?.educationStatus);
    const [interestedTags, setInterestedTags] = useState([]);
    const [isHiredThroughFCB, setIsHiredThroughFCB] = useState<boolean>(studentData?.hiredByFCB);
    const [careerStatus, setCareerStatus] = useState([]);
    const [uploadedFile, setUploadedFile] = useState<string>();
    const [isCat1, setIsCat1] = useState(null);
    const [isCat2, setIsCat2] = useState(null);
    const [isCat3, setIsCat3] = useState(null);
    const [showToolTip, setShowToolTip] = useState(false);

    const [cat1, setCat1] = useState(null);
    const [cat2, setCat2] = useState(null);
    const [cat3, setCat3] = useState(null);

    const [saveSuccessPopup, setSaveSuccessPopup] = useState<boolean>(false);
    const studentUpdateSuccessPopup = useSelector(
        (state: RootState) => state.webUser.studentUpdateSuccessPopup
    );

    const [imageUrl, setImageUrl] = useState(null);

    const [stream, setStream] = useState<any>();
    const [isPursuingHigherEducation, setIsPursuingHigherEducation] = useState('Yes');
    const [joinedFutureCareerBridgeReason, setJoinedFutureCareerBridgeReason] = useState(
        'I want to find an internship'
    );
    const [isEmployed, setIsEmployed] = useState('No');
    const [company, setCompany] = useState<string>(null);
    const viewPortWidth = useWidth();

    const [showSuccessModal, setShowSuccessModal] = React.useState(false);

    useEffect(() => {
        if (emailResent) {
            setShowSuccessModal(true);
        }
    }, [emailResent]);

    useEffect(() => {
        if (saveSuccessPopup) {
            setTimeout(() => setSaveSuccessPopup(false), 4000);
            dispatch(clearWebUsersMessages());
        }
    }, [saveSuccessPopup, dispatch]);

    useEffect(() => {
        if (studentUpdateSuccessPopup) {
            setSaveSuccessPopup(true);
        }
    }, [studentUpdateSuccessPopup]);

    useEffect(() => {
        if (studentData?.InstituteName) {
            setInstitute(studentData?.InstituteName);
        }
        if (studentData?.StudentInstitute?.instCategory1 !== null) {
            setCat1(studentData?.StudentInstitute?.instCategory1?.name);
        }
        if (studentData?.StudentInstitute?.instCategory2 !== null) {
            setCat2(studentData?.StudentInstitute?.instCategory2?.name);
        }
        if (studentData?.StudentInstitute?.instCategory3 !== null) {
            setCat3(studentData?.StudentInstitute?.instCategory3?.name);
        }
        if (
            // selectedInstitue
            //     ?.map(data => data?.instCategory1 !== null && data?.instCategory1?.name !== '')
            //     .includes(true)
            studentData?.StudentInstitute?.instCategory1?.option !== null
        ) {
            setIsCat1(studentData?.StudentInstitute?.instCategory1?.option);
        }
        if (studentData?.StudentInstitute?.instCategory2?.option !== null) {
            setIsCat2(studentData?.StudentInstitute?.instCategory2?.option);
        }
        if (studentData?.StudentInstitute?.instCategory3?.category3?.option !== null) {
            setIsCat3(studentData?.StudentInstitute?.instCategory3?.category3?.option);
        }
        if (studentData?.reasonForJoin) {
            setJoinedFutureCareerBridgeReason(studentData?.reasonForJoin);
        }
        if (studentData?.companyName) {
            setCompany(studentData?.companyName);
        }
        if (studentData?.empStatus) {
            setIsEmployed(studentData?.empStatus);
        }
        if (studentData?.pursuingHigherStudies !== null) {
            if (studentData?.pursuingHigherStudies === false) {
                setIsPursuingHigherEducation('No');
            } else {
                setIsPursuingHigherEducation('Yes');
            }
        }
        if (studentData?.imageUrl) {
            setImageUrl(studentData?.imageUrl);
        }
        if (studentData?.cvLink) {
            setUploadedFile(studentData?.cvLink);
        }
        if (studentData?.socialMediaUrls) {
            setLinkedin(
                studentData?.socialMediaUrls
                    ?.filter(data => data.platform === 'LINKEDIN')
                    ?.map(link => link.url)
            );
            setFacebook(
                studentData?.socialMediaUrls
                    ?.filter(data => data.platform === 'FACEBOOK')
                    ?.map(link => link.url)
            );
            setTwitter(
                studentData?.socialMediaUrls
                    ?.filter(data => data.platform === 'TWITTER')
                    ?.map(link => link.url)
            );
        }

        if (studentData?.educationStatus !== null) {
            setEducationStatus(studentData?.educationStatus);
        }
        if (studentData?.mentor !== null) {
            setMentor(studentData?.mentor);
        }
        if (studentData?.bridgeInvitationCode) {
            setTrackingCode(studentData?.bridgeInvitationCode);
        }
        if (studentData?.hiredByFCB) {
            setIsHiredThroughFCB(studentData?.hiredByFCB);
        }
        if (studentData?.careerStatus?.length > 0) {
            setCareerStatus(studentData?.careerStatus);
        }

        if (studentData?.tags) {
            setInterestedTags(studentData?.tags);
        }

        setFirstName(studentData?.firstName);
        if (studentData?.StudentInstitute?.studentId) {
            setBatchNumber(studentData?.StudentInstitute?.studentId);
        }
        if (studentData?.birthDate !== null && studentData?.birthDate !== undefined) {
            setDateOfBirth(new Date(studentData?.birthDate));
        } else {
            setDateOfBirth(new Date('1999-01-01'));
        }
        setLastName(studentData?.lastName);
        setNICNumber(studentData?.nic);
        setStudentID(studentData?.id);
        setMobileNumber(studentData?.contact?.mobileNo);
        setEmail(studentData?.emailId);
        setAddress(studentData?.contact?.address1);
        if (studentData?.contact?.city) {
            setHomeTown(studentData?.contact?.city);
        }

        if (studentData?.institute?.orgName) {
            setInstitute(studentData?.institute?.orgName);
        }
        if (studentData?.gender) {
            setGender(studentData?.gender);
        }
        if (studentData?.alStream) {
            setStream(studentData?.alStream);
        }
    }, [studentData]);

    useEffect(() => {
        let newFullName;
        if (firstName !== null) {
            newFullName = firstName;
            if (lastName !== null) {
                newFullName = firstName + ' ' + lastName;
            }
        }
        setFullName(newFullName);
    }, [firstName, lastName]);

    const saveFile = () => {
        saveAs(`${s3UrlPrefix}${studentData.cvLink}`, `${studentData?.firstName}-cv.pdf`);
    };

    useEffect(() => {
        if (showToolTip) {
            // eslint-disable-next-line no-var
            var timer = setTimeout(() => setShowToolTip(false), 2000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [showToolTip]);

    return (
        <>
            {viewPortWidth > 1023 ? (
                <>
                    <>
                        <Toastr
                            message={'Changes saved successfully!'}
                            showToast={saveSuccessPopup}
                        />
                        <VerifyEmailSuccess
                            modalVisible={showSuccessModal}
                            setModalVisible={setShowSuccessModal}
                        />
                        <div className="my-8 flex flex-row justify-between">
                            <div className="flex flex-row ">
                                <img
                                    className={`blur-3xl rounded-full h-32 w-32`}
                                    src={`${
                                        imageUrl === '' || imageUrl === null
                                            ? '/assets/user.png'
                                            : imageUrl.includes('webUsers')
                                            ? s3UrlPrefix + imageUrl
                                            : imageUrl
                                    }`}
                                    alt="profile"
                                />
                                <div className="flex flex-col ml-6 pt-1 justify-center">
                                    <p className="text-lg font-bold font-manrope mb-1.5">
                                        {fullName}
                                    </p>
                                    <p className="text-xs text-gray-500 mb-2.5">
                                        {/* {studentData?.contact?.city +
                                        ', ' +
                                        studentData?.contact?.province} */}
                                        {studentData?.contact?.city}
                                    </p>
                                    {studentData?.socialMediaUrls?.length > 0 ? (
                                        <div className="flex space-x-2">
                                            {!linkedin?.includes(null) &&
                                            !linkedin?.includes('') ? (
                                                <img
                                                    className="cursor-pointer"
                                                    src={`/assets/profileLinkedinIcon.png`}
                                                    alt="profile"
                                                    onClick={() => {
                                                        window.open(`${linkedin}`, '_blank');
                                                    }}
                                                />
                                            ) : null}

                                            {!facebook?.includes(null) &&
                                            !facebook?.includes('') ? (
                                                <img
                                                    className="cursor-pointer"
                                                    src={`/assets/profileFacebookIcon.png`}
                                                    alt="profile"
                                                    onClick={() => {
                                                        window.open(`${facebook}`, '_blank');
                                                    }}
                                                />
                                            ) : null}

                                            {!twitter?.includes(null) && !twitter?.includes('') ? (
                                                <img
                                                    className=" cursor-pointer"
                                                    src={`/assets/profileTwitterIcon.png`}
                                                    alt="profile"
                                                    onClick={() => {
                                                        window.open(`${twitter}`, '_blank');
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    onClick={() => {
                                        history.push(`/dashBoard/profile/edit`);
                                    }}
                                    className="border-2 border-purple-950 text-purple-950 rounded font-semibold h-10 px-3 ml-1"
                                >
                                    Edit profile
                                </button>
                            </div>
                        </div>

                        <div className="font-bold font-manrope text-xl mb-3">Public Profile</div>
                        <div className="flex flex-row">
                            <div className="flex flex-col mb-1 w-full md:w-2/5">
                                <label
                                    className="flex flex-row font-manrope text-sm my-1"
                                    htmlFor="tab_1"
                                >
                                    My Public Profile URL
                                </label>
                                <div
                                    className={`pl-4 flex items-center h-12 inputFieldThickness`}
                                    id="tab_1"
                                >{`${window.location.origin}/public-profile/${studentData?.id}`}</div>
                            </div>
                            <div className="flex flex-col mb-1 ml-8 w-full md:w-2/5">
                                <div className="font-medium text-gray-600 text-sm align-top items-start flex py-2 mt-5">
                                    <div
                                        onClick={() => {
                                            window.open(
                                                `${window.location.origin}/public-profile/${studentData?.id}`,
                                                '_blank'
                                            );
                                        }}
                                        className={`underline text-purple-950 cursor-pointer whitespace-nowrap pr-2`}
                                    >
                                        Open link
                                    </div>
                                    <input
                                        className="mr-1 mt-1"
                                        id="public_profile_consent"
                                        type="checkbox"
                                        checked={studentData?.isPublic}
                                        disabled={true}
                                    />
                                    <label htmlFor="consent">
                                        Keep my profile publicly linked and accessible through the
                                        BRIDGE website leaderboard and via QR code on the
                                        certificates.
                                        {studentData?.isPublic && (
                                            <span className=" block font-bold text-gray-700">
                                                Your personal information and BRIDGE progress
                                                information will now be shared publicly.
                                            </span>
                                        )}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="font-bold font-manrope text-xl mb-3">Personal details</div>
                        <div className="flex flex-row">
                            <div className="flex flex-col mb-1 w-full md:w-2/5">
                                <label
                                    className="flex flex-row font-manrope text-sm my-1"
                                    htmlFor="tab_1"
                                >
                                    First Name
                                </label>
                                <input
                                    className={` pl-4 py-1 h-12 inputFieldThickness`}
                                    type="text"
                                    id="tab_1"
                                    value={firstName || ''}
                                    disabled
                                />
                            </div>
                            <div className="flex flex-col mb-1 ml-8 w-full md:w-2/5">
                                <label
                                    className="flex flex-row font-manrope text-sm my-1"
                                    htmlFor="tab_1"
                                >
                                    Last Name
                                </label>
                                <input
                                    className={`pl-4 py-1 h-12 inputFieldThickness`}
                                    type="text"
                                    id="tab_1"
                                    value={lastName || ''}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="flex flex-col my-1 w-full md:w-2/5">
                                <div className="flex flex-row">
                                    <label
                                        className="flex flex-row font-manrope text-sm my-1"
                                        htmlFor="tab_1"
                                    >
                                        Student ID{' '}
                                    </label>
                                    <HiIcons.HiQuestionMarkCircle
                                        onClick={() => {
                                            setStudentIdHelpText(true);
                                        }}
                                        className="ml-2 mt-1 cursor-pointer"
                                    />
                                    {studentIdHelpText ? (
                                        <div
                                            onMouseOverCapture={() => {
                                                setStudentIdHelpText(true);
                                                // setProfileDropdownVisible(false);
                                            }}
                                            onMouseLeave={() => setStudentIdHelpText(false)}
                                            className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-7"
                                            style={{
                                                width: '375px',
                                            }}
                                        >
                                            Please note that this is a new ID assigned for the new
                                            Future Careers BRIDGE portal and not the previous BRIDGE
                                            ID
                                        </div>
                                    ) : null}
                                </div>
                                <input
                                    className={`pl-4 py-1 h-12 inputFieldThickness`}
                                    type="text"
                                    id="tab_1"
                                    value={studentID || ''}
                                    disabled
                                />
                            </div>
                            <div className="flex flex-col my-1 ml-8 w-full md:w-2/5">
                                <label
                                    className="flex flex-col font-manrope text-sm my-1"
                                    htmlFor="tab_1"
                                >
                                    NIC / Passport Number
                                </label>
                                <input
                                    className="inputFieldThickness pl-4 py-1 h-12"
                                    type="text"
                                    id="tab_1"
                                    value={nicNumber || ''}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="flex flex-row mt-2">
                            <div className="flex flex-col my-1 w-full md:w-2/5">
                                <label
                                    className="flex flex-row font-manrope text-sm my-1"
                                    htmlFor="tab_1"
                                >
                                    Date of birth
                                </label>
                                <input
                                    className={`pl-4 py-1 h-12 inputFieldThickness`}
                                    type="text"
                                    id="tab_1"
                                    value={
                                        moment(dateOfBirth).format('YYYY/MM/DD').toString() || ''
                                    }
                                    disabled
                                />
                            </div>
                            <div className="flex flex-col my-1 ml-8 w-full md:w-2/5">
                                <label
                                    className="flex flex-row font-manrope text-sm my-1"
                                    htmlFor="tab_1"
                                >
                                    Gender
                                </label>
                                <input
                                    className={`pl-4 py-1 h-12 inputFieldThickness`}
                                    type="text"
                                    id="tab_1"
                                    value={gender || ''}
                                    disabled
                                />
                            </div>
                        </div>

                        <div className="flex flex-row">
                            <div className="flex flex-col my-1 w-full md:w-2/5">
                                <div className="flex flex-row">
                                    <label
                                        className="flex flex-row font-manrope text-sm my-1"
                                        htmlFor="tab_1"
                                    >
                                        Email
                                    </label>
                                </div>
                                <label
                                    style={{ background: '#f3f4f6' }}
                                    className={`pl-4 py-1 h-12 inputFieldThickness flex flex-row w-full justify-between items-center`}
                                    id="tab_1"
                                >
                                    <div className="">{email || ''}</div>
                                    {studentData?.isEmailVerified ? (
                                        <div className="mr-2 ">
                                            <Verified />
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => {
                                                dispatch(resendEmailVerificationWeb(email));
                                            }}
                                            className="flex items-center mr-2 my-2 bg-green-450 text-white text-xs rounded-full px-3 py-2 w-max cursor-pointer"
                                        >
                                            Verify
                                        </div>
                                    )}
                                </label>
                            </div>
                            <div className="flex flex-col my-1 ml-8 w-full md:w-2/5">
                                <div className="flex flex-row">
                                    <label
                                        className="flex flex-row font-manrope text-sm my-1"
                                        htmlFor="tab_1"
                                    >
                                        Mobile
                                    </label>
                                </div>
                                <label
                                    style={{ background: '#f3f4f6' }}
                                    className={`pl-4 py-1 h-12 inputFieldThickness flex flex-row w-full justify-between items-center`}
                                    id="tab_1"
                                >
                                    <div className="">{mobileNumber || ''}</div>
                                    {studentData?.isMobileVerified ? (
                                        <div className="mr-2 ">
                                            <Verified />
                                        </div>
                                    ) : (
                                        ' '
                                    )}
                                </label>
                            </div>
                        </div>

                        <div className="flex flex-col mb-1 w-full md:w-2/5">
                            <label className="flex flex-row font-manrope text-sm my-1">
                                Home town (District)
                            </label>
                            <input
                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                type="text"
                                id="tab_1"
                                value={homeTown || ''}
                                disabled
                            />
                        </div>
                        <div className="flex flex-col mb-1 w-full md:w-4/7">
                            <label
                                className="flex flex-row font-manrope text-sm my-1"
                                htmlFor="tab_1"
                            >
                                Postal Address
                            </label>
                            <input
                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                type="text"
                                id="tab_1"
                                value={address || ''}
                                disabled
                            />
                        </div>

                        <div className="font-bold font-manrope text-xl mt-4 mb-2">
                            Education details
                        </div>

                        <div className="flex flex-col my-1 w-full md:w-3/4">
                            <div className="flex flex-row my-1">Education status</div>
                            <input
                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                type="text"
                                id="tab_1"
                                value={educationStatus || ''}
                                disabled
                            />
                        </div>
                        {educationStatus !== 'I have not done O/Ls yet' ? (
                            <>
                                {educationStatus === 'I have not done A/Ls yet' ||
                                educationStatus === 'I have completed A/Ls' ? (
                                    <>
                                        <div>
                                            <div className="font-bold font-manrope text-lg mt-8 mb-5">
                                                Stream of study in Advanced Level
                                            </div>

                                            <div className="flex flex-col mt-3 mb-1 w-full md:w-3/4">
                                                <label
                                                    className="flex flex-row my-1"
                                                    htmlFor="tab_1"
                                                >
                                                    Stream
                                                </label>
                                                <input
                                                    className={`text-sm pl-2 py-1 h-12 inputFieldThickness`}
                                                    type="text"
                                                    id="tab_1"
                                                    value={stream || ''}
                                                    disabled
                                                    onChange={e => {
                                                        setStream(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null}

                                <div>
                                    <div className="font-bold font-manrope text-lg mt-8">
                                        Are you currently pursuing Higher Education?
                                    </div>
                                    <div className="mt-5">
                                        <div className="mb-2">
                                            <input
                                                id="higherEd1"
                                                className="newone"
                                                type="radio"
                                                value="Yes"
                                                checked={isPursuingHigherEducation === 'Yes'}
                                                disabled
                                            />
                                            <label className="ml-3" htmlFor="higherEd1">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="mb-2">
                                            <input
                                                id="higherEd2"
                                                className="newone"
                                                type="radio"
                                                value="No"
                                                checked={isPursuingHigherEducation === 'No'}
                                                disabled
                                            />
                                            <label className="ml-3" htmlFor="higherEd2">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {isPursuingHigherEducation === 'Yes' ? (
                                    <>
                                        <div className="flex flex-col w-full md:w-3/4">
                                            <div className="flex flex-row mb-1">
                                                Higher Education Institute
                                            </div>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                type="text"
                                                id="tab_1"
                                                value={institue || ''}
                                                disabled
                                            />
                                        </div>

                                        {institue !== null ? (
                                            <div>
                                                {isCat1 ? (
                                                    <div className="flex flex-col w-full md:w-3/4">
                                                        <div className="flex flex-row mb-1">
                                                            {cat1}
                                                        </div>
                                                        <input
                                                            className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                            type="text"
                                                            id="tab_1"
                                                            value={isCat1 || ''}
                                                            disabled
                                                        />
                                                    </div>
                                                ) : null}

                                                {isCat2 ? (
                                                    <div className="flex flex-col w-full md:w-3/4">
                                                        <div className="flex flex-row mb-1">
                                                            {cat2}
                                                        </div>
                                                        <input
                                                            className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                            type="text"
                                                            id="tab_1"
                                                            value={isCat2 || ''}
                                                            disabled
                                                        />
                                                    </div>
                                                ) : null}

                                                {isCat3 ? (
                                                    <div className="flex flex-col w-full md:w-3/4">
                                                        <div className="flex flex-row mb-1">
                                                            {cat3}
                                                        </div>
                                                        <input
                                                            className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                            type="text"
                                                            id="tab_1"
                                                            value={isCat3 || ''}
                                                            disabled
                                                        />
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}

                                        {batchNumber ? (
                                            <div className="flex flex-col my-1 w-full md:w-3/4">
                                                <label
                                                    className="flex flex-col font-manrope text-sm my-1"
                                                    htmlFor="tab_1"
                                                >
                                                    Student registration number/student ID given by
                                                    your institute
                                                </label>
                                                <input
                                                    className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                    type="text"
                                                    id="tab_1"
                                                    value={batchNumber || ''}
                                                    disabled
                                                />
                                            </div>
                                        ) : null}
                                    </>
                                ) : null}
                            </>
                        ) : null}

                        {trackingCode ? (
                            <div className="flex flex-col my-1 w-full md:w-3/4">
                                <label
                                    className="flex flex-col font-manrope text-sm my-1"
                                    htmlFor="tab_1"
                                >
                                    Bridge Invitation Code given by your Institute
                                </label>
                                <input
                                    className={`inputFieldThickness pl-4 py-1 h-12`}
                                    type="text"
                                    id="tab_1"
                                    value={trackingCode || ''}
                                    disabled
                                />
                            </div>
                        ) : null}

                        <div>
                            <div className="font-bold font-manrope text-lg mt-8">
                                I joined Future Careers BRIDGE program because,
                            </div>
                            <div className="mt-5">
                                <div className="mb-2">
                                    <input
                                        id="futurecar1"
                                        className="newone"
                                        type="radio"
                                        value="Only interested in studying at this time"
                                        checked={
                                            joinedFutureCareerBridgeReason ===
                                            'Only interested in studying at this time'
                                        }
                                        disabled
                                    />
                                    <label className="ml-3" htmlFor="futurecar1">
                                        Only interested in studying at this time
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        id="futurecar2"
                                        className="newone"
                                        type="radio"
                                        value="Looking for an industry internship"
                                        checked={
                                            joinedFutureCareerBridgeReason ===
                                            'Looking for an industry internship'
                                        }
                                        disabled
                                    />
                                    <label className="ml-3" htmlFor="futurecar2">
                                        Looking for an industry internship
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        id="futurecar3"
                                        className="newone"
                                        type="radio"
                                        value="Already in employment but looking for new Tech career"
                                        checked={
                                            joinedFutureCareerBridgeReason ===
                                            'Already in employment but looking for new Tech career'
                                        }
                                        disabled
                                    />
                                    <label className="ml-3" htmlFor="futurecar3">
                                        Already in employment but looking for new Tech career
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="font-bold font-manrope text-lg mt-8">
                                Are you presently employed (full time or part time)?
                            </div>
                            <div className="mt-5">
                                <div className="mb-2">
                                    <input
                                        id="employed1"
                                        className="newone"
                                        type="radio"
                                        value="No"
                                        checked={isEmployed === 'No'}
                                        disabled
                                    />
                                    <label className="ml-3" htmlFor="employed1">
                                        No
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        id="employed2"
                                        className="newone"
                                        type="radio"
                                        value="Yes, at a company"
                                        checked={isEmployed === 'Yes, at a company'}
                                        disabled
                                    />
                                    <label className="ml-3" htmlFor="employed2">
                                        Yes, at a company
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        id="employed3"
                                        className="newone"
                                        type="radio"
                                        value="Yes, self-employed"
                                        checked={isEmployed === 'Yes, self-employed'}
                                        disabled
                                    />
                                    <label className="ml-3" htmlFor="employed3">
                                        Yes, self-employed
                                    </label>
                                </div>
                            </div>
                        </div>

                        {isEmployed === 'Yes, at a company' ? (
                            <>
                                <div className="font-bold font-manrope text-lg mt-8 mb-5">
                                    Company Details
                                </div>

                                {/* <p className="my-3">
                                            If you are joining BRIDGE through an education
                                            institute, please mention the institute name. In doubt,
                                            keep this empty.
                                        </p> */}

                                <div>
                                    <div className="flex flex-col mt-5 mb-1 w-full md:w-3/4">
                                        <label className="flex flex-row my-1" htmlFor="tab_1">
                                            Company name
                                        </label>
                                        <input
                                            className={`text-sm pl-2 py-1 h-12 inputFieldThickness`}
                                            type="text"
                                            id="tab_1"
                                            value={company}
                                            disabled
                                            placeholder="Company name"
                                            onChange={e => {
                                                setCompany(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </>

                    {studentData?.cvLink && (
                        <div>
                            <div className="font-bold font-manrope text-lg mt-4">My resume</div>
                            <div className="mt-5">
                                <button
                                    onClick={saveFile}
                                    className={`border-purple-950 text-purple-950 mt-2.5 mb-4 border-2 rounded font-semibold flex justify-center items-center h-10 cursor-pointer px-8`}
                                >
                                    Download resume
                                </button>
                            </div>
                        </div>
                    )}
                    <div className="flex flex-col">
                        <h4 className="font-bold font-manrope text-xl mt-4 mb-2">
                            Areas of Interest
                        </h4>
                        <div className="flex flex-wrap  mr-20">
                            {interestedTags
                                ?.filter(tag =>
                                    ['interest', 'interests', 'interest '].includes(
                                        tag.tagCategory.toLowerCase()
                                    )
                                )
                                .map(action => (
                                    <div
                                        style={{
                                            maxHeight: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        key={action.id}
                                        className={`rounded-full border-2 my-1 mr-4 text-white bg-purple-400 border-purple-400 py-3 px-2 `}
                                    >
                                        {action.tagName}
                                    </div>
                                ))}
                        </div>
                    </div>

                    {mentor && (
                        <div className="flex flex-col">
                            <div className="font-bold font-manrope text-xl mt-4 mb-2">
                                Assigned Mentor
                            </div>
                            <div className="flex pt-1">
                                <img
                                    style={{
                                        width: '203px',
                                        height: '186px',
                                        borderRadius: '4px',
                                    }}
                                    className="w-full rounded"
                                    src={`${
                                        mentor?.imagePath
                                            ? `${s3UrlPrefix}${mentor?.imagePath}`
                                            : '/assets/MentorPic.png '
                                    }`}
                                    alt="profile"
                                />
                                <div className="ml-6 w-full">
                                    <div className="flex flex-row">
                                        <div className="flex flex-col my-1 w-full md:w-2/5">
                                            <label
                                                className="flex flex-row my-1 font-manrope text-sm"
                                                htmlFor="tab_1"
                                            >
                                                Mentor name
                                            </label>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                type="text"
                                                id="mentor_tab"
                                                value={mentor?.mentorName}
                                                disabled
                                            />
                                        </div>
                                        <div className="flex flex-col my-1 ml-8 w-full md:w-2/5">
                                            <label
                                                className="flex flex-row my-1 font-manrope text-sm"
                                                htmlFor="tab_1"
                                            >
                                                Company
                                            </label>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                type="text"
                                                id="tab_1"
                                                value={mentor?.companyName}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="flex flex-col my-1 w-full md:w-2/5">
                                            <label
                                                className="flex flex-row my-1 font-manrope text-sm"
                                                htmlFor="tab_1"
                                            >
                                                Designation
                                            </label>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                type="text"
                                                id="mentor_tab"
                                                value={mentor?.designation}
                                                disabled
                                            />
                                        </div>
                                        <div className="flex flex-col my-1 ml-8 w-full md:w-2/5">
                                            <label
                                                className="flex flex-row my-1 font-manrope text-sm"
                                                htmlFor="tab_1"
                                            >
                                                LinkedIn profile
                                            </label>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                type="text"
                                                id="tab_1"
                                                value={mentor?.linkedinProfile}
                                                disabled
                                            />
                                        </div>
                                        {mentor?.linkedinProfile ? (
                                            <>
                                                <div
                                                    onClick={() => {
                                                        setShowToolTip(true);
                                                        navigator.clipboard.writeText(
                                                            mentor?.linkedinProfile
                                                        );
                                                    }}
                                                    className="text-purple-950 underline text-sm cursor-pointer mt-12 ml-4"
                                                >
                                                    Copy Link
                                                </div>
                                                {showToolTip && (
                                                    <div
                                                        onMouseOverCapture={() => {
                                                            setShowToolTip(true);
                                                        }}
                                                        onMouseLeave={() => setShowToolTip(false)}
                                                        className="bg-white inputFieldThickness absolute z-10 right-0 px-4 py-2 text-xs font-bold"
                                                    >
                                                        <div>{'Copied to clipboard.'}</div>
                                                    </div>
                                                )}
                                            </>
                                        ) : null}
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="flex flex-col my-1 w-full md:w-2/5">
                                            <label
                                                className="flex flex-row my-1 font-manrope text-sm"
                                                htmlFor="tab_1"
                                            >
                                                Email
                                            </label>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                type="text"
                                                id="mentor_tab"
                                                value={mentor?.mentorEmail}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="flex flex-col">
                        <h4 className="font-bold font-manrope text-xl mt-4 ">Employment Status</h4>
                        <div className="flex">
                            <div className="text-sm pt-1 text-justify">
                                Did you get employment at a company after you started BRIDGE
                                program?
                            </div>
                            <div className="text-purple-950 ml-3 pt-1 text-sm">
                                {isHiredThroughFCB === true ? 'Yes' : 'No'}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col mb-32">
                        <div className="flex mt-4 mb-2">
                            <div className="font-bold font-manrope text-xl ">
                                My Industry Highlights
                            </div>
                        </div>

                        {careerStatus.length > 0 ? (
                            <table className="inputFieldThickness w-full">
                                <thead className="inputFieldThickness">
                                    <tr className="text-sm">
                                        <th className="p-2">Description</th>
                                        <th className="p-2">Updated Date/Time</th>
                                        <th className="p-2">Updated By</th>
                                    </tr>
                                </thead>
                                {careerStatus.map(data => (
                                    <tbody key={data.id}>
                                        <tr className="text-sm">
                                            <td className="inputFieldThickness p-2">
                                                {data.message}
                                            </td>
                                            <td className="inputFieldThickness p-2">
                                                {moment(data.logUpdatedDate)
                                                    .local()
                                                    .format('YYYY-MM-DD , h:mm:ss a')}
                                            </td>
                                            <td className="inputFieldThickness p-2">
                                                {data.logCreatedBy}
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        ) : (
                            <>
                                <td></td>
                                <td style={{ textAlign: 'center' }}>No record available</td>
                                <td></td>
                            </>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <>
                        <Toastr
                            fullWidth={true}
                            message={'Changes saved successfully!'}
                            showToast={saveSuccessPopup}
                        />
                        <div className="my-8 flex flex-col justify-between gap-y-6">
                            <div className="flex flex-row ">
                                <img
                                    className={`blur-3xl rounded-full h-16 w-16`}
                                    src={`${
                                        imageUrl === '' || imageUrl === null
                                            ? '/assets/user.png'
                                            : imageUrl.includes('webUsers')
                                            ? s3UrlPrefix + imageUrl
                                            : imageUrl
                                    }`}
                                    alt="profile"
                                />
                                <div className="flex flex-col ml-6 pt-1 justify-center">
                                    <p className="text-sm font-bold font-manrope mb-1.5">
                                        {fullName}
                                    </p>
                                    <p className=" text-gray-500 mb-2.5" style={{ fontSize: 10 }}>
                                        {/* {studentData?.contact?.city +
                                        ', ' +
                                        studentData?.contact?.province} */}
                                        {studentData?.contact?.city}
                                    </p>
                                    {studentData?.socialMediaUrls?.length > 0 ? (
                                        <div className="flex space-x-2">
                                            {!linkedin?.includes(null) &&
                                            !linkedin?.includes('') ? (
                                                <SocialIconMobile
                                                    className="cursor-pointer"
                                                    src={`/assets/profileLinkedinIcon.png`}
                                                    alt="profile"
                                                    onClick={() => {
                                                        window.open(`${linkedin}`, '_blank');
                                                    }}
                                                />
                                            ) : null}

                                            {!facebook?.includes(null) &&
                                            !facebook?.includes('') ? (
                                                <SocialIconMobile
                                                    className="cursor-pointer"
                                                    src={`/assets/profileFacebookIcon.png`}
                                                    alt="profile"
                                                    onClick={() => {
                                                        window.open(`${facebook}`, '_blank');
                                                    }}
                                                />
                                            ) : null}

                                            {!twitter?.includes(null) && !twitter?.includes('') ? (
                                                <SocialIconMobile
                                                    className=" cursor-pointer"
                                                    src={`/assets/profileTwitterIcon.png`}
                                                    alt="profile"
                                                    onClick={() => {
                                                        window.open(`${twitter}`, '_blank');
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    onClick={() => {
                                        history.push(`/dashBoard/profile/edit`);
                                    }}
                                    className="border-2 border-purple-950 text-purple-950 rounded font-semibold h-10 px-3 ml-1"
                                >
                                    Edit profile
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-col mb-1 w-full">
                            <SmallFontLabel
                                className="flex flex-row font-manrope my-1"
                                htmlFor="tab_1"
                            >
                                My Public Profile URL
                            </SmallFontLabel>
                            <div
                                className={` pl-4 py-1 h-12 pt-3 whitespace-nowrap overflow-x-hidden inputFieldThickness`}
                                id="tab_1"
                            >{`${window.location.origin}/public-profile/${studentData?.id}`}</div>
                        </div>
                        <div className="flex flex-col mb-1 w-full">
                            <div
                                onClick={() => {
                                    studentData?.isPublic &&
                                        window.open(
                                            `${window.location.origin}/public-profile/${studentData?.id}`,
                                            '_blank'
                                        );
                                }}
                                className={`underline ${
                                    studentData?.isPublic
                                        ? 'text-purple-950 cursor-pointer'
                                        : ' text-gray-600 cursor-not-allowed'
                                } cursor-pointer whitespace-nowrap pr-2`}
                            >
                                Open link
                            </div>
                            <div>
                                <input
                                    className="mr-1 mt-1"
                                    id="public_profile_consent"
                                    type="checkbox"
                                    checked={studentData?.isPublic}
                                    disabled={true}
                                />
                                <label htmlFor="consent">
                                    Keep my profile publicly linked and accessible through the
                                    BRIDGE website leaderboard and via QR code on the certificates.
                                    {studentData?.isPublic && (
                                        <span className=" block font-bold text-gray-700">
                                            Your personal information and BRIDGE progress
                                            information will now be shared publicly.
                                        </span>
                                    )}
                                </label>
                            </div>
                        </div>

                        <div className="font-bold font-manrope text-lg mb-3">Personal details</div>

                        <div className="flex flex-col mb-1 w-full">
                            <SmallFontLabel
                                className="flex flex-row font-manrope my-1"
                                htmlFor="tab_1"
                            >
                                First Name
                            </SmallFontLabel>
                            <input
                                className={` pl-4 py-1 h-12 inputFieldThickness`}
                                type="text"
                                id="tab_1"
                                value={firstName || ''}
                                disabled
                            />
                        </div>
                        <div className="flex flex-col mb-1 w-full">
                            <SmallFontLabel
                                className="flex flex-row font-manrope my-1"
                                htmlFor="tab_1"
                            >
                                Last Name
                            </SmallFontLabel>
                            <input
                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                type="text"
                                id="tab_1"
                                value={lastName || ''}
                                disabled
                            />
                        </div>

                        <div className="flex flex-col my-1 w-full">
                            <SmallFontLabel
                                className="flex flex-col font-manrope my-1"
                                htmlFor="tab_1"
                            >
                                Student ID
                            </SmallFontLabel>
                            <input
                                className="inputFieldThickness pl-4 py-1 h-12"
                                type="text"
                                id="tab_1"
                                value={studentID || ''}
                                disabled
                            />
                        </div>
                        <div className="flex flex-col my-1 w-full">
                            <SmallFontLabel
                                className="flex flex-col font-manrope my-1"
                                htmlFor="tab_1"
                            >
                                NIC / Passport Number
                            </SmallFontLabel>
                            <input
                                className="inputFieldThickness pl-4 py-1 h-12"
                                type="text"
                                id="tab_1"
                                value={nicNumber || ''}
                                disabled
                            />
                        </div>

                        <div className="flex flex-col my-1 w-full">
                            <SmallFontLabel
                                className="flex flex-row font-manrope my-1"
                                htmlFor="tab_1"
                            >
                                Date of birth
                            </SmallFontLabel>
                            <input
                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                type="text"
                                id="tab_1"
                                value={moment(dateOfBirth).format('YYYY/MM/DD').toString() || ''}
                                disabled
                            />
                        </div>
                        <div className="flex flex-col my-1 w-full">
                            <SmallFontLabel
                                className="flex flex-row font-manrope my-1"
                                htmlFor="tab_1"
                            >
                                Gender
                            </SmallFontLabel>
                            <input
                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                type="text"
                                id="tab_1"
                                value={gender || ''}
                                disabled
                            />
                        </div>

                        <div className="flex flex-col my-1 w-full">
                            <SmallFontLabel
                                className="flex flex-row font-manrope my-1"
                                htmlFor="tab_1"
                            >
                                Email
                            </SmallFontLabel>
                            <input
                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                type="email"
                                id="tab_1"
                                value={email || ''}
                                disabled
                            />
                        </div>
                        <div className="flex flex-col my-1 w-full">
                            <SmallFontLabel
                                className="flex flex-row font-manrope my-1"
                                htmlFor="tab_1"
                            >
                                Mobile
                            </SmallFontLabel>
                            <input
                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                type="text"
                                id="tab_1"
                                value={mobileNumber || ''}
                                disabled
                            />
                        </div>

                        <div className="flex flex-col mb-1 w-full">
                            <SmallFontLabel className="flex flex-row font-manrope my-1">
                                Home town (District)
                            </SmallFontLabel>
                            <input
                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                type="text"
                                id="tab_1"
                                value={homeTown || ''}
                                disabled
                            />
                        </div>
                        <div className="flex flex-col mb-1 w-full">
                            <SmallFontLabel
                                className="flex flex-row font-manrope my-1"
                                htmlFor="tab_1"
                            >
                                Postal Address
                            </SmallFontLabel>
                            <input
                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                type="text"
                                id="tab_1"
                                value={address || ''}
                                disabled
                            />
                        </div>

                        <div className="font-bold font-manrope text-lg mt-4 mb-2">
                            Education details
                        </div>

                        <div className="flex flex-col my-1 w-full md:w-3/4">
                            <SmallFontLabel
                                className="flex flex-row font-manrope my-1"
                                htmlFor="tab_1"
                            >
                                Education status
                            </SmallFontLabel>
                            <input
                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                type="text"
                                id="tab_1"
                                value={educationStatus || ''}
                                disabled
                            />
                        </div>
                        {educationStatus !== 'I have not done O/Ls yet' ? (
                            <>
                                {educationStatus === 'I have not done A/Ls yet' ||
                                educationStatus === 'I have completed A/Ls' ? (
                                    <>
                                        <div>
                                            <div className="font-bold font-manrope text-lg mt-8 mb-3">
                                                Stream of study in Advanced Level
                                            </div>

                                            <div className="flex flex-col mt-3 mb-1 w-full">
                                                <SmallFontLabel
                                                    className="flex flex-row my-1"
                                                    htmlFor="tab_1"
                                                >
                                                    Stream
                                                </SmallFontLabel>
                                                <input
                                                    className={`text-sm pl-2 py-1 h-12 inputFieldThickness`}
                                                    type="text"
                                                    id="tab_1"
                                                    value={stream || ''}
                                                    disabled
                                                    onChange={e => {
                                                        setStream(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null}

                                <div className="mb-3">
                                    <div className="font-bold font-manrope text-lg mt-8">
                                        Are you currently pursuing Higher Education?
                                    </div>
                                    <div className="mt-3">
                                        <div className="mb-2">
                                            <input
                                                id="higherEd1"
                                                className="newone"
                                                type="radio"
                                                value="Yes"
                                                checked={isPursuingHigherEducation === 'Yes'}
                                                disabled
                                            />
                                            <label
                                                className="ml-3 text-sm font-manrope"
                                                htmlFor="higherEd1"
                                            >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="mb-2">
                                            <input
                                                id="higherEd2"
                                                className="newone"
                                                type="radio"
                                                value="No"
                                                checked={isPursuingHigherEducation === 'No'}
                                                disabled
                                            />
                                            <label
                                                className="ml-3 text-sm font-manrope"
                                                htmlFor="higherEd2"
                                            >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {isPursuingHigherEducation === 'Yes' ? (
                                    <>
                                        <div className="flex flex-col w-full my-1">
                                            <SmallFontDiv className="flex flex-row mb-1 font-manrope my-1">
                                                Higher Education Institute
                                            </SmallFontDiv>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness overflow-ellipsis `}
                                                type="text"
                                                id="tab_1"
                                                value={institue || ''}
                                                disabled
                                            />
                                        </div>
                                        {institue !== null ? (
                                            <div>
                                                {isCat1 ? (
                                                    <div className="flex flex-col w-full my-1">
                                                        <SmallFontDiv className="flex flex-row mb-1 font-manrope my-1">
                                                            {cat1}
                                                        </SmallFontDiv>
                                                        <input
                                                            className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                            type="text"
                                                            id="tab_1"
                                                            value={isCat1 || ''}
                                                            disabled
                                                        />
                                                    </div>
                                                ) : null}

                                                {isCat2 ? (
                                                    <div className="flex flex-col w-full my-1">
                                                        <SmallFontDiv className="flex flex-row mb-1 font-manrope my-1">
                                                            {cat2}
                                                        </SmallFontDiv>
                                                        <input
                                                            className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                            type="text"
                                                            id="tab_1"
                                                            value={isCat2 || ''}
                                                            disabled
                                                        />
                                                    </div>
                                                ) : null}

                                                {isCat3 ? (
                                                    <div className="flex flex-col w-full my-1">
                                                        <div className="flex flex-row mb-1 font-manrope my-1">
                                                            {cat3}
                                                        </div>
                                                        <input
                                                            className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                            type="text"
                                                            id="tab_1"
                                                            value={isCat3 || ''}
                                                            disabled
                                                        />
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}

                                        {batchNumber ? (
                                            <div className="flex flex-col my-1 w-full">
                                                <SmallFontLabel
                                                    className="flex flex-col font-manrope my-1"
                                                    htmlFor="tab_1"
                                                >
                                                    Student registration number/student ID given by
                                                    your institute
                                                </SmallFontLabel>
                                                <input
                                                    className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                    type="text"
                                                    id="tab_1"
                                                    value={batchNumber || ''}
                                                    disabled
                                                />
                                            </div>
                                        ) : null}

                                        {trackingCode ? (
                                            <div className="flex flex-col my-1 w-full md:w-3/4">
                                                <SmallFontLabel
                                                    className="flex flex-col font-manrope my-1"
                                                    htmlFor="tab_1"
                                                >
                                                    Bridge Invitation Code given by your Institute
                                                </SmallFontLabel>
                                                <input
                                                    className={`inputFieldThickness pl-4 py-1 h-12`}
                                                    type="text"
                                                    id="tab_1"
                                                    value={trackingCode || ''}
                                                    disabled
                                                />
                                            </div>
                                        ) : null}
                                    </>
                                ) : null}
                            </>
                        ) : null}

                        <div>
                            <div className="font-bold font-manrope text-lg mt-8">
                                I joined Future Careers BRIDGE program because,
                            </div>
                            <div className="mt-3">
                                <div className="mb-2">
                                    <input
                                        id="futurecar1"
                                        className="newone"
                                        type="radio"
                                        value="Only interested in studying at this time"
                                        checked={
                                            joinedFutureCareerBridgeReason ===
                                            'Only interested in studying at this time'
                                        }
                                        disabled
                                    />
                                    <label className="ml-3 text-sm" htmlFor="futurecar1">
                                        Only interested in studying at this time
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        id="futurecar2"
                                        className="newone"
                                        type="radio"
                                        value="Looking for an industry internship"
                                        checked={
                                            joinedFutureCareerBridgeReason ===
                                            'Looking for an industry internship'
                                        }
                                        disabled
                                    />
                                    <label className="ml-3 text-sm" htmlFor="futurecar2">
                                        Looking for an industry internship
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        id="futurecar3"
                                        className="newone"
                                        type="radio"
                                        value="Already in employment but looking for new Tech career"
                                        checked={
                                            joinedFutureCareerBridgeReason ===
                                            'Already in employment but looking for new Tech career'
                                        }
                                        disabled
                                    />
                                    <label className="ml-3 text-sm" htmlFor="futurecar3">
                                        Already in employment but looking for new Tech career
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="font-bold font-manrope text-lg mt-8">
                                Are you presently employed (full time or part time)?
                            </div>
                            <div className="mt-3">
                                <div className="mb-2">
                                    <input
                                        id="employed1"
                                        className="newone"
                                        type="radio"
                                        value="No"
                                        checked={isEmployed === 'No'}
                                        disabled
                                    />
                                    <label className="ml-3 text-sm" htmlFor="employed1">
                                        No
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        id="employed2"
                                        className="newone"
                                        type="radio"
                                        value="Yes, at a company"
                                        checked={isEmployed === 'Yes, at a company'}
                                        disabled
                                    />
                                    <label className="ml-3 text-sm" htmlFor="employed2">
                                        Yes, at a company
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        id="employed3"
                                        className="newone"
                                        type="radio"
                                        value="Yes, self-employed"
                                        checked={isEmployed === 'Yes, self-employed'}
                                        disabled
                                    />
                                    <label className="ml-3 text-sm" htmlFor="employed3">
                                        Yes, self-employed
                                    </label>
                                </div>
                            </div>
                        </div>

                        {isEmployed === 'Yes, at a company' ? (
                            <>
                                <div className="font-bold font-manrope text-lg mt-8 mb-3">
                                    Company Details
                                </div>

                                {/* <p className="my-3">
                                            If you are joining BRIDGE through an education
                                            institute, please mention the institute name. In doubt,
                                            keep this empty.
                                        </p> */}

                                <div>
                                    <div className="flex flex-col mb-1 w-full">
                                        <SmallFontLabel
                                            className="flex flex-row my-1"
                                            htmlFor="tab_1"
                                        >
                                            Company name
                                        </SmallFontLabel>
                                        <input
                                            className={`text-sm pl-2 py-1 h-12 inputFieldThickness`}
                                            type="text"
                                            id="tab_1"
                                            value={company}
                                            disabled
                                            placeholder="Company name"
                                            onChange={e => {
                                                setCompany(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </>

                    {studentData?.cvLink && (
                        <div className="flex flex-col justify-between gap-y-4 mb-2 mt-4">
                            <div className={`font-bold font-manrope text-lg`}>My resume</div>
                            <button
                                onClick={saveFile}
                                className={`border-purple-950 text-purple-950 text-sm border-2 rounded font-semibold flex justify-center items-center h-10 cursor-pointer w-36`}
                            >
                                Download resume
                            </button>
                        </div>
                    )}
                    <div className="flex flex-col">
                        <h4 className="font-bold font-manrope text-lg mt-4 mb-2">
                            Areas of Interest
                        </h4>
                        <div className="flex flex-wrap w-full">
                            {interestedTags?.map(action => (
                                <div
                                    style={{
                                        maxHeight: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    key={action.id}
                                    className={`rounded-full border-2 my-1 mr-4 text-white bg-purple-400 border-purple-400 py-3 px-2 text-sm`}
                                >
                                    {action.tagName}
                                </div>
                            ))}
                        </div>
                    </div>

                    {mentor && (
                        <div className="flex flex-col">
                            <div className="font-bold font-manrope text-lg mt-4 mb-2">
                                Assigned Mentor
                            </div>
                            <div className="flex flex-col pt-1">
                                <img
                                    style={{
                                        width: '203px',
                                        height: '186px',
                                        borderRadius: '4px',
                                    }}
                                    className="w-full rounded"
                                    src={`${
                                        mentor?.imagePath
                                            ? `${s3UrlPrefix}${mentor?.imagePath}`
                                            : '/assets/MentorPic.png '
                                    }`}
                                    alt="profile"
                                />
                                <div className="w-full">
                                    <div className="flex flex-col">
                                        <div className="flex flex-col my-1 w-full md:w-2/5">
                                            <label
                                                className="flex flex-row my-1 font-manrope text-sm"
                                                htmlFor="tab_1"
                                            >
                                                Mentor name
                                            </label>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness overflow-ellipsis`}
                                                type="text"
                                                id="mentor_tab"
                                                value={mentor?.mentorName}
                                                disabled
                                            />
                                        </div>
                                        <div className="flex flex-col my-1  w-full md:w-2/5">
                                            <label
                                                className="flex flex-row my-1 font-manrope text-sm"
                                                htmlFor="tab_1"
                                            >
                                                Company
                                            </label>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness overflow-ellipsis`}
                                                type="text"
                                                id="tab_1"
                                                value={mentor?.companyName}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="flex flex-col my-1 w-full md:w-2/5">
                                            <label
                                                className="flex flex-row my-1 font-manrope text-sm"
                                                htmlFor="tab_1"
                                            >
                                                Designation
                                            </label>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness overflow-ellipsis`}
                                                type="text"
                                                id="mentor_tab"
                                                value={mentor?.designation}
                                                disabled
                                            />
                                        </div>
                                        <div className="flex flex-col my-1  w-full md:w-2/5">
                                            <label
                                                className="flex flex-row my-1 font-manrope text-sm"
                                                htmlFor="tab_1"
                                            >
                                                LinkedIn profile
                                            </label>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness overflow-ellipsis`}
                                                type="text"
                                                id="tab_1"
                                                value={mentor?.linkedinProfile}
                                                disabled
                                            />
                                        </div>
                                        {mentor?.linkedinProfile ? (
                                            <div>
                                                <div
                                                    onClick={() => {
                                                        setShowToolTip(true);
                                                        navigator.clipboard.writeText(
                                                            mentor?.linkedinProfile
                                                        );
                                                    }}
                                                    className="text-purple-950 underline text-sm cursor-pointer self-end mt-2"
                                                >
                                                    Copy Link
                                                </div>
                                                {showToolTip && (
                                                    <div
                                                        onMouseOverCapture={() => {
                                                            setShowToolTip(true);
                                                        }}
                                                        onMouseLeave={() => setShowToolTip(false)}
                                                        className="bg-white inputFieldThickness absolute z-10 px-4 py-2 text-xs font-bold"
                                                    >
                                                        <div>{'Copied to clipboard.'}</div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="flex flex-col my-1 w-full md:w-2/5">
                                            <label
                                                className="flex flex-row my-1 font-manrope text-sm"
                                                htmlFor="tab_1"
                                            >
                                                Email
                                            </label>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness overflow-ellipsis`}
                                                type="text"
                                                id="mentor_tab"
                                                value={mentor?.mentorEmail}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="flex flex-col">
                        <h4 className="font-bold font-manrope text-lg mt-4 ">Employment Status</h4>
                        <div className="flex pt-1">
                            <div className="text-xs text-justify">
                                Did you get employment at a company after you started BRIDGE
                                program?
                            </div>
                            <div className="text-purple-950 ml-3 text-sm font-medium">
                                {isHiredThroughFCB === true ? 'Yes' : 'No'}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col mb-16">
                        <div className="flex mt-4 mb-2">
                            <div className="font-bold font-manrope text-lg ">
                                My Industry Highlights
                            </div>
                        </div>
                        <div
                            className="w-11/12 overflow-x-scroll horizontal-scrollable-leaderboard"
                            style={{ fontSize: 10 }}
                        >
                            {careerStatus.length > 0 ? (
                                <table className="inputFieldThickness w-full mb-2 table-fixed text-center">
                                    <thead className="inputFieldThickness">
                                        <tr className="h-10">
                                            <th className="w-64 px-2">Description</th>
                                            <th className="w-28 px-2">Updated Date/Time</th>
                                            <th className="w-64 px-2">Updated By</th>
                                        </tr>
                                    </thead>
                                    {careerStatus.map(data => (
                                        <tbody key={data.id}>
                                            <tr className="h-10">
                                                <td className="inputFieldThickness px-2">
                                                    {data.message}
                                                </td>
                                                <td className="inputFieldThickness px-2">
                                                    {new Date(data.logCreatedDate).toLocaleString()}
                                                </td>
                                                <td className="inputFieldThickness px-2">
                                                    {data.logCreatedBy}
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table>
                            ) : (
                                <>
                                    <td></td>
                                    <td style={{ textAlign: 'center' }}>No record available</td>
                                    <td></td>
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ProfileTab;
