import React, { ReactElement, useEffect, useState } from 'react';
import Modal from 'react-modal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { USER_PROFILE } from '../../../utils/storageWeb';
import { RootState } from '../../../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { editWebUser } from '../../../../redux/rootActions';
import CloseIcon from '../../../../assets/svg/CloseIcon';

interface Props {
    showSuccessModal: boolean;
    setShowSuccessModal: (value: boolean) => void;
    scroll: number;
    sidebarAutoScroll: (value: number) => void;
    setFocused: (value: any) => void;
    id?: string;
}

export default function Tour({
    showSuccessModal,
    setShowSuccessModal,
    scroll,
    sidebarAutoScroll,
    setFocused,
    id,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const studentData = useSelector((state: RootState) => state.webUser.student);

    const [sidebar, setSidebar] = useState(false);
    // const [showSuccessModal, setShowSuccessModal] = React.useState(true);
    const [currentTourTip, setCurrentTourTip] = useState('Learn');

    const customStyles: Modal.Styles = {
        overlay: {
            // top: 0,
            // left: 0,
            // right: 0,
            // bottom: 0,
            backgroundColor: 'rgba(239, 235, 255, 0.7)',

            // backdropFilter: 'blur(7px)',
            // overflow: 'inherit',
            // zIndex: 999,
        },
        content: {
            // left: '10%',
            // right: 'auto',
            // marginRight: '-50%',
            // padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            overflow: 'inherit',
            position: 'fixed',
            top: `${-scroll}px`,
            // zIndex: 999,
        },
    };

    useEffect(() => {
        if (!studentData?.welcomeTour && studentData) {
            setShowSuccessModal(true);
        }
    }, [studentData]);

    const handleClose = () => {
        if (!studentData?.welcomeTour) {
            dispatch(
                editWebUser(profile?.userId, {
                    welcomeTour: true,
                })
            );
        }
        setShowSuccessModal(false);
        setFocused('');
        setCurrentTourTip('Learn');
    };

    return (
        <div className="h-full">
            {currentTourTip === 'Learn' ? (
                <Modal isOpen={showSuccessModal} style={customStyles} id={id || 'custom-modal'}>
                    <>
                        <div className="modalClass flex inset-0 z-50 outline-none focus:outline-none">
                            <div className="flex relative w-1/3 mt-4 ml-60">
                                <div className="mt-3">
                                    <svg
                                        width="8"
                                        height="16"
                                        viewBox="0 0 8 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.672147 9.49482C-0.224048 8.69924 -0.224048 7.30076 0.672146 6.50518L8 -4.4509e-07L8 16L0.672147 9.49482Z"
                                            fill="#4C2EC2"
                                        />
                                    </svg>
                                </div>
                                <div
                                    style={{ maxHeight: '270px', backgroundColor: '#4C2EC2' }}
                                    className=" rounded-lg  shadow-lg relative flex flex-col w-full bg-purple-950 outline-none focus:outline-none px-12 py-6"
                                >
                                    <div className="flex flex-col -bottom-2">
                                        <div className="flex justify-between">
                                            <h1 className="text-lg font-manrope font-bold text-white">
                                                Learn
                                            </h1>
                                            <div className="cursor-pointer" onClick={handleClose}>
                                                <CloseIcon size={20} />
                                            </div>
                                        </div>

                                        <p className=" my-2 text-sm text-white">
                                            Learn is the page where you can view all tracks and get
                                            started on challenges.
                                        </p>
                                        <div className="flex justify-end mt-3">
                                            <button
                                                onClick={() => {
                                                    setCurrentTourTip('MyProgress');
                                                    sidebarAutoScroll(20);
                                                    setFocused('My Progress');
                                                }}
                                                className="text-purple-950 bg-white font-semibold text-sm rounded w-40 h-10 "
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            ) : null}

            {currentTourTip === 'MyProgress' ? (
                <Modal isOpen={showSuccessModal} style={customStyles} id={id || 'custom-modal'}>
                    <>
                        <div className=" flex  inset-0 z-50 outline-none focus:outline-none">
                            <div className="flex relative w-1/3 mt-16 ml-60">
                                <div className="mt-3">
                                    <svg
                                        width="8"
                                        height="16"
                                        viewBox="0 0 8 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.672147 9.49482C-0.224048 8.69924 -0.224048 7.30076 0.672146 6.50518L8 -4.4509e-07L8 16L0.672147 9.49482Z"
                                            fill="#4C2EC2"
                                        />
                                    </svg>
                                </div>
                                <div
                                    style={{ maxHeight: '270px', backgroundColor: '#4C2EC2' }}
                                    className=" rounded-lg  shadow-lg relative flex flex-col w-full bg-purple-950 outline-none focus:outline-none px-12 py-6"
                                >
                                    <div className="flex flex-col -bottom-2">
                                        <div className="flex justify-between">
                                            <h1 className="text-lg font-manrope font-bold text-white">
                                                My progress
                                            </h1>
                                            <div className="cursor-pointer" onClick={handleClose}>
                                                <CloseIcon size={20} />
                                            </div>
                                        </div>

                                        <p className=" my-2 text-sm text-white">
                                            Here you can view your personal progress, overview of
                                            all tracks, certificates, badges earned etc.
                                        </p>
                                        <div className="flex justify-end mt-3">
                                            <button
                                                style={{ backgroundColor: '#4C2EC2' }}
                                                onClick={() => {
                                                    setCurrentTourTip('Learn');
                                                    sidebarAutoScroll(0);
                                                    setFocused('Learn');
                                                }}
                                                className="bg-purple-950 text-white border-white border-2 font-semibold text-sm rounded w-40 h-10 mr-2 "
                                            >
                                                Previous
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setCurrentTourTip('Leaderboard');
                                                    sidebarAutoScroll(40);
                                                    setFocused('Leaderboard');
                                                }}
                                                className="text-purple-950 bg-white border-2 border-white text-sm font-semibold rounded w-40 h-10 "
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            ) : null}
            {currentTourTip === 'Leaderboard' ? (
                <Modal isOpen={showSuccessModal} style={customStyles} id={id || 'custom-modal'}>
                    <>
                        <div className=" flex  inset-0 z-50 outline-none focus:outline-none">
                            <div className="flex relative w-1/3 mt-28 ml-60">
                                <div className="mt-3">
                                    <svg
                                        width="8"
                                        height="16"
                                        viewBox="0 0 8 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.672147 9.49482C-0.224048 8.69924 -0.224048 7.30076 0.672146 6.50518L8 -4.4509e-07L8 16L0.672147 9.49482Z"
                                            fill="#4C2EC2"
                                        />
                                    </svg>
                                </div>
                                <div
                                    style={{ maxHeight: '270px', backgroundColor: '#4C2EC2' }}
                                    className=" rounded-lg  shadow-lg relative flex flex-col w-full bg-purple-950 outline-none focus:outline-none px-12 py-6"
                                >
                                    <div className="flex flex-col -bottom-2">
                                        <div className="flex justify-between">
                                            <h1 className="text-lg font-manropefont-bold text-white">
                                                Leaderboard
                                            </h1>
                                            <div className="cursor-pointer" onClick={handleClose}>
                                                <CloseIcon size={20} />
                                            </div>
                                        </div>

                                        <p className=" my-2 text-sm text-white">
                                            The leaderboard measures your progress and your peers
                                            progress and shows progress by ranking.
                                        </p>
                                        <div className="flex justify-end mt-3">
                                            <button
                                                style={{ backgroundColor: '#4C2EC2' }}
                                                onClick={() => {
                                                    setCurrentTourTip('MyProgress');
                                                    sidebarAutoScroll(20);
                                                    setFocused('My Progress');
                                                }}
                                                className="bg-purple-950 text-white border-white border-2 font-semibold text-sm rounded w-40 h-10 mr-2 "
                                            >
                                                Previous
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setCurrentTourTip('RequestMentor');
                                                    sidebarAutoScroll(60);
                                                    setFocused('Request a mentor');
                                                }}
                                                className="text-purple-950 bg-white border-2 border-white text-sm font-semibold rounded w-40 h-10 "
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            ) : null}
            {currentTourTip === 'RequestMentor' ? (
                <Modal isOpen={showSuccessModal} style={customStyles} id={id || 'custom-modal'}>
                    <>
                        <div className=" flex  inset-0 z-50 outline-none focus:outline-none">
                            <div className="flex relative w-1/3 mt-40 ml-60">
                                <div className="mt-3">
                                    <svg
                                        width="8"
                                        height="16"
                                        viewBox="0 0 8 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.672147 9.49482C-0.224048 8.69924 -0.224048 7.30076 0.672146 6.50518L8 -4.4509e-07L8 16L0.672147 9.49482Z"
                                            fill="#4C2EC2"
                                        />
                                    </svg>
                                </div>
                                <div
                                    style={{ maxHeight: '270px', backgroundColor: '#4C2EC2' }}
                                    className=" rounded-lg  shadow-lg relative flex flex-col w-full bg-purple-950 outline-none focus:outline-none px-12 py-6"
                                >
                                    <div className="flex flex-col -bottom-2">
                                        <div className="flex justify-between">
                                            <h1 className="text-lg font-manrope font-bold text-white">
                                                Request a mentor
                                            </h1>
                                            <div className="cursor-pointer" onClick={handleClose}>
                                                <CloseIcon size={20} />
                                            </div>
                                        </div>

                                        <p className=" my-2 text-sm text-white">
                                            You can request and be mentored by experts in the field
                                            and gain more knowledge!
                                        </p>
                                        <div className="flex justify-end mt-3">
                                            <button
                                                style={{ backgroundColor: '#4C2EC2' }}
                                                onClick={() => {
                                                    setCurrentTourTip('Leaderboard');
                                                    sidebarAutoScroll(40);
                                                    setFocused('Leaderboard');
                                                }}
                                                className="bg-purple-950 text-white border-white border-2 font-semibold text-sm rounded w-40 h-10 mr-2 "
                                            >
                                                Previous
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setCurrentTourTip('Help');
                                                    sidebarAutoScroll(80);
                                                    setFocused('Help');
                                                }}
                                                className="text-purple-950 bg-white border-2 border-white  font-semibold text-sm rounded w-40 h-10 "
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            ) : null}
            {currentTourTip === 'Help' ? (
                <Modal isOpen={showSuccessModal} style={customStyles} id={id || 'custom-modal'}>
                    <>
                        <div className=" flex  inset-0 z-50 outline-none focus:outline-none">
                            <div className="flex relative w-1/3 mt-52 ml-60">
                                <div className="mt-3">
                                    <svg
                                        width="8"
                                        height="16"
                                        viewBox="0 0 8 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.672147 9.49482C-0.224048 8.69924 -0.224048 7.30076 0.672146 6.50518L8 -4.4509e-07L8 16L0.672147 9.49482Z"
                                            fill="#4C2EC2"
                                        />
                                    </svg>
                                </div>
                                <div
                                    style={{ maxHeight: '270px', backgroundColor: '#4C2EC2' }}
                                    className=" rounded-lg  shadow-lg relative flex flex-col w-full bg-purple-950 outline-none focus:outline-none px-12 py-6"
                                >
                                    <div className="flex flex-col -bottom-2">
                                        <div className="flex justify-between">
                                            <h1 className="text-lg font-manrope font-bold text-white">
                                                Help
                                            </h1>
                                            <div className="cursor-pointer" onClick={handleClose}>
                                                <CloseIcon size={20} />
                                            </div>
                                        </div>

                                        <p className=" my-2 text-sm text-white">
                                            Get help on how to progress through the Future Careers
                                            BRIDGE program.
                                        </p>
                                        <div className="flex justify-end mt-3">
                                            <button
                                                style={{ backgroundColor: '#4C2EC2' }}
                                                onClick={() => {
                                                    setCurrentTourTip('RequestMentor');
                                                    sidebarAutoScroll(60);
                                                    setFocused('Request a mentor');
                                                }}
                                                className="bg-purple-950 text-white border-white border-2 font-semibold text-sm rounded w-40 h-10 mr-2 "
                                            >
                                                Previous
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setCurrentTourTip('Settings');
                                                    sidebarAutoScroll(100);
                                                    setFocused('Settings');
                                                }}
                                                className="text-purple-950 bg-white border-2 border-white  font-semibold text-sm rounded w-40 h-10 "
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            ) : null}
            {currentTourTip === 'Settings' ? (
                <Modal isOpen={showSuccessModal} style={customStyles} id={id || 'custom-modal'}>
                    <>
                        <div className=" flex  inset-0 z-50 outline-none focus:outline-none">
                            <div className="flex relative w-1/3 mt-64 ml-60">
                                <div className="mt-3">
                                    <svg
                                        width="8"
                                        height="16"
                                        viewBox="0 0 8 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.672147 9.49482C-0.224048 8.69924 -0.224048 7.30076 0.672146 6.50518L8 -4.4509e-07L8 16L0.672147 9.49482Z"
                                            fill="#4C2EC2"
                                        />
                                    </svg>
                                </div>
                                <div
                                    style={{ maxHeight: '270px', backgroundColor: '#4C2EC2' }}
                                    className=" rounded-lg  shadow-lg relative flex flex-col w-full bg-purple-950 outline-none focus:outline-none px-12 py-6"
                                >
                                    <div className="flex flex-col -bottom-2">
                                        <div className="flex justify-between">
                                            <h1 className="text-lg font-manrope font-bold text-white">
                                                Settings
                                            </h1>
                                            <div className="cursor-pointer" onClick={handleClose}>
                                                <CloseIcon size={20} />
                                            </div>
                                        </div>

                                        <p className=" my-2 text-sm text-white">
                                            Access your account settings and edit details about your
                                            profile.
                                        </p>
                                        <div className="flex justify-end mt-3">
                                            <button
                                                style={{ backgroundColor: '#4C2EC2' }}
                                                onClick={() => {
                                                    setCurrentTourTip('Help');
                                                    sidebarAutoScroll(80);
                                                    setFocused('Help');
                                                }}
                                                className="bg-purple-950 text-white border-white border-2 font-semibold text-sm rounded w-40 h-10 mr-2 "
                                            >
                                                Previous
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setCurrentTourTip('QuickTip');
                                                    sidebarAutoScroll(120);
                                                    setFocused('QA');
                                                }}
                                                className="text-purple-950 bg-white border-2 border-white  font-semibold text-sm rounded w-40 h-10 "
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            ) : null}
            {currentTourTip === 'QuickTip' ? (
                <Modal isOpen={showSuccessModal} style={customStyles} id={id || 'custom-modal'}>
                    <>
                        <div className=" flex  inset-0 z-50 outline-none focus:outline-none">
                            <div
                                style={{ marginTop: '410px' }}
                                className="flex relative w-1/3  ml-60"
                            >
                                <div className="mt-3">
                                    <svg
                                        width="8"
                                        height="16"
                                        viewBox="0 0 8 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.672147 9.49482C-0.224048 8.69924 -0.224048 7.30076 0.672146 6.50518L8 -4.4509e-07L8 16L0.672147 9.49482Z"
                                            fill="#4C2EC2"
                                        />
                                    </svg>
                                </div>
                                <div
                                    style={{ maxHeight: '270px', backgroundColor: '#4C2EC2' }}
                                    className=" rounded-lg  shadow-lg relative flex flex-col w-full bg-purple-950 outline-none focus:outline-none px-12 py-6"
                                >
                                    <div className="flex flex-col -bottom-2">
                                        <div className="flex justify-between">
                                            <h1 className="text-lg font-manrope font-bold text-white">
                                                Quick Tips & FAQs
                                            </h1>
                                            <div className="cursor-pointer" onClick={handleClose}>
                                                <CloseIcon size={20} />
                                            </div>
                                        </div>

                                        <p className=" my-2 text-sm text-white">
                                            Get quick help on how to progress through the Future
                                            Careers BRIDGE program.
                                        </p>
                                        <div className="flex justify-end mt-3">
                                            <button
                                                style={{ backgroundColor: '#4C2EC2' }}
                                                onClick={() => {
                                                    setCurrentTourTip('Settings');
                                                    sidebarAutoScroll(100);
                                                    setFocused('Settings');
                                                }}
                                                className="bg-purple-950 text-white border-white border-2 font-semibold text-sm rounded w-40 h-10 mr-2 "
                                            >
                                                Previous
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setCurrentTourTip('GetStarted');
                                                    sidebarAutoScroll(220);
                                                    setFocused('Tour');
                                                }}
                                                className="text-purple-950 bg-white border-2 border-white  font-semibold text-sm rounded w-40 h-10 "
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            ) : null}
            {currentTourTip === 'GetStarted' ? (
                <Modal isOpen={showSuccessModal} style={customStyles} id={id || 'custom-modal'}>
                    <>
                        <div className=" flex  inset-0 z-50 outline-none focus:outline-none">
                            <div
                                style={{ marginTop: '500px' }}
                                className="flex relative w-1/3 ml-60"
                            >
                                <div className="mt-32">
                                    <svg
                                        width="8"
                                        height="16"
                                        viewBox="0 0 8 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.672147 9.49482C-0.224048 8.69924 -0.224048 7.30076 0.672146 6.50518L8 -4.4509e-07L8 16L0.672147 9.49482Z"
                                            fill="#4C2EC2"
                                        />
                                    </svg>
                                </div>
                                <div
                                    style={{ maxHeight: '270px', backgroundColor: '#4C2EC2' }}
                                    className=" rounded-lg  shadow-lg relative flex flex-col w-full bg-purple-950 outline-none focus:outline-none px-12 py-6"
                                >
                                    <div className="flex flex-col -bottom-2">
                                        <div className="flex justify-between">
                                            <h1 className="text-lg font-manrope font-bold text-white">
                                                Take a tour
                                            </h1>
                                            <div className="cursor-pointer" onClick={handleClose}>
                                                <CloseIcon size={20} />
                                            </div>
                                        </div>

                                        <p className=" my-2 text-sm text-white">
                                            Don’t worry, if you ever need help, this is where to
                                            click for a complete tour.
                                        </p>
                                        <div className="flex justify-end mt-3">
                                            <button
                                                onClick={() => {
                                                    handleClose();
                                                    sidebarAutoScroll(0);
                                                }}
                                                className="text-purple-950 bg-white border-2 border-white  font-semibold text-sm rounded w-40 h-10 "
                                            >
                                                Got it
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            ) : null}
        </div>
    );
}
