import React, { useEffect } from 'react';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Logout from '../../../assets/svg/Logout';
import { clearWebAuth, logoutWeb } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { SocialMediaPlatform } from '../../../redux/students/reducers';
import { s3UrlPrefix } from '../../../constants';

interface paramsType {
    id: string;
}

interface Props {
    isShowExpandable: boolean;
    setIsNavBarExpanded: (value: boolean) => void;
}
const ExpandableNavBar = styled.div`
    z-index: 100;
    height: calc(100vh - 70px); /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100 - 70px);
`;

const CityLabel = styled.div`
    font-size: 10px;
`;

const SocialMediaLinks = styled.div``;

const RoundSocialMediaButton = function ({ children, url }) {
    return (
        <div className="w-5 h-5 rounded-full bg-purple-950 flex items-center justify-center">
            <a href={url} target="_blank" rel="noreferrer" className="no-underline">
                {children}
            </a>
        </div>
    );
};

export default function ProfileNavBarMobileExpandable({
    isShowExpandable,
    setIsNavBarExpanded,
}: Props) {
    const student = useSelector((state: RootState) => state.webUser.student);
    const notifications = useSelector(
        (state: RootState) => state.WebNotification.webNotificationsData
    );
    const location = useLocation<paramsType>();
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <>
            {isShowExpandable && (
                <ExpandableNavBar
                    className=" bg-white flex flex-col gap-y-8 w-full"
                    style={
                        location.pathname.includes('/dashBoard')
                            ? { marginTop: 70 }
                            : { marginTop: 0 }
                    }
                >
                    {!location.pathname.toLocaleLowerCase().includes('/onboard') ? (
                        <>
                            <div className="flex flex-col mt-8 justify-start items-center gap-y-4">
                                <div
                                    className="self-end mr-6 -mb-10"
                                    role="presentation"
                                    onClick={() => {
                                        dispatch(logoutWeb());
                                        history.push('/signin');
                                        setIsNavBarExpanded(false);
                                    }}
                                >
                                    <Logout />
                                </div>
                                <img
                                    className="rounded-full h-10 w-10 mr-2"
                                    src={`${
                                        [null, ''].includes(student?.imageUrl)
                                            ? '/assets/user.png'
                                            : s3UrlPrefix + student?.imageUrl
                                    }`}
                                    alt="profile"
                                />

                                <div className="capitalize text-center font-bold text-black-400 text-sm font-manrope">
                                    {student?.firstName} {student?.lastName}
                                </div>
                                <CityLabel className="text-gray-400 -mt-2">
                                    {student?.contact?.city}
                                </CityLabel>
                                <SocialMediaLinks className="flex gap-x-2 -mt-2">
                                    {student?.socialMediaUrls.map(socialMediaUrl => {
                                        if (socialMediaUrl.url !== '') {
                                            if (socialMediaUrl.platform === 'FACEBOOK') {
                                                return (
                                                    <RoundSocialMediaButton
                                                        url={socialMediaUrl?.url}
                                                        key={socialMediaUrl?.id}
                                                    >
                                                        <FaFacebookF color="#FFFFFF" size={10} />
                                                    </RoundSocialMediaButton>
                                                );
                                            } else if (socialMediaUrl.platform === 'TWITTER') {
                                                return (
                                                    <RoundSocialMediaButton
                                                        url={socialMediaUrl?.url}
                                                        key={socialMediaUrl?.id}
                                                    >
                                                        <FaTwitter color="#FFFFFF" size={10} />
                                                    </RoundSocialMediaButton>
                                                );
                                            } else if (socialMediaUrl.platform === 'LINKEDIN') {
                                                return (
                                                    <RoundSocialMediaButton
                                                        url={socialMediaUrl?.url}
                                                        key={socialMediaUrl?.id}
                                                    >
                                                        <FaLinkedinIn color="#FFFFFF" size={10} />
                                                    </RoundSocialMediaButton>
                                                );
                                            } else {
                                                return null;
                                            }
                                        } else {
                                            return null;
                                        }
                                    })}
                                </SocialMediaLinks>
                            </div>
                            <div className="flex flex-col mx-10 gap-y-5 text-black-400">
                                <NavLink
                                    activeClassName="font-bold"
                                    to="/home"
                                    onClick={() => setIsNavBarExpanded(false)}
                                >
                                    Home
                                </NavLink>
                                <NavLink
                                    activeClassName="font-bold"
                                    to={`/dashBoard/learn`}
                                    onClick={() => setIsNavBarExpanded(false)}
                                    isActive={(match, navLocation) => {
                                        const query2: string = navLocation.pathname.split('/')[1];
                                        const query3: string = navLocation.pathname.split('/')[2];
                                        return (
                                            query2?.toLocaleLowerCase().includes('dashboard') &&
                                            !query3?.toLocaleLowerCase().includes('notifications')
                                        );
                                    }}
                                >
                                    Dashboard
                                </NavLink>
                                <NavLink
                                    activeClassName="font-bold"
                                    to="/companies"
                                    onClick={() => setIsNavBarExpanded(false)}
                                >
                                    Companies
                                </NavLink>
                                <NavLink
                                    activeClassName="font-bold"
                                    to="/success-stories"
                                    onClick={() => setIsNavBarExpanded(false)}
                                >
                                    Success stories
                                </NavLink>
                                <NavLink
                                    activeClassName="font-bold"
                                    to="/events"
                                    onClick={() => setIsNavBarExpanded(false)}
                                >
                                    Events
                                </NavLink>
                                <NavLink
                                    activeClassName="font-bold"
                                    to="/institutes"
                                    onClick={() => setIsNavBarExpanded(false)}
                                >
                                    Institutes
                                </NavLink>
                                <div className="flex">
                                    <NavLink
                                        activeClassName="font-bold"
                                        to={`/dashBoard/notifications`}
                                        onClick={() => setIsNavBarExpanded(false)}
                                    >
                                        <span>Notifications</span>
                                    </NavLink>
                                    {notifications.length > 0 && (
                                        <div className="h-2 w-2 bg-red-500 rounded-full"></div>
                                    )}
                                </div>
                            </div>
                            {location.pathname.split('/')[2] !== 'learn' && (
                                <div className="mt-auto flex flex-col items-center mb-8">
                                    <div
                                        className="rounded text-purple-950 border-2 border-purple-950 cursor-pointer h-10 w-48 flex justify-center items-center"
                                        onClick={() => {
                                            setIsNavBarExpanded(false);
                                            history.push({
                                                pathname: `/dashBoard/learn`,
                                            });
                                        }}
                                    >
                                        Start Learning
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="flex flex-col mt-8 justify-start items-center gap-y-4">
                                <div
                                    className="self-end mr-6 -mb-10"
                                    role="presentation"
                                    onClick={() => {
                                        dispatch(logoutWeb());
                                        history.push('/signin');
                                        setIsNavBarExpanded(false);
                                    }}
                                >
                                    <Logout />
                                </div>
                                {student?.firstName && student.firstName.trim().length > 0 ? (
                                    <div className="bg-purple-950 text-center rounded-full flex items-center justify-center h-14 w-14 text-white text-lg font-manrope">
                                        {student?.firstName.charAt(0).toLocaleUpperCase()}
                                    </div>
                                ) : (
                                    <img
                                        className="rounded-full h-20 w-20"
                                        src="/assets/user.png"
                                        alt="profile"
                                    />
                                )}
                                <div className="capitalize text-center font-bold text-black-400 text-sm font-manrope">
                                    {student?.firstName} {student?.lastName}
                                </div>
                            </div>
                        </>
                    )}
                </ExpandableNavBar>
            )}
        </>
    );
}
