import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { USER_PROFILE } from '../../utils/storage';
import { getStudents } from '../rootActions';

const PREFIX = '@TAB';

export const ADD_STUDENT_TAB = PREFIX + 'ADD_STUDENT_TAB';
export const ADD_STUDENT_TAB_SUCCESS = PREFIX + 'ADD_STUDENT_TAB_SUCCESS';
export const ADD_STUDENT_TAB_FAILED = PREFIX + 'ADD_STUDENT_TAB_FAILED';
export const GET_STUDENT_TAB = PREFIX + 'GET_STUDENT_TAB';
export const GET_STUDENT_TAB_SUCCESS = PREFIX + 'GET_STUDENT_TAB_SUCCESS';
export const GET_STUDENT_TAB_FAILED = PREFIX + 'GET_STUDENT_TAB_FAILED';

export interface lastActiveDate {
    from: string | null;
    to: string | null;
}

export interface joinedDate {
    from: string | null;
    to: string | null;
}

export interface StudentTabCreateType {
    id?: string | null;
    tabName?: string | null;
    // tags?: any[];
    // trackLastUpdatedDate?: trackLastUpdatedDate;
    // trackLastUpdatedBy?: string | null;
    // trackCreatedBy?: string | null;
    // trackCreatedDate?: trackCreatedDate;
    // trackName?: string | null;
    // program?: number;
    joinedDate?: joinedDate;
    status?: string | null;
    lastActiveDate?: lastActiveDate;
    homeTowns?: any[];
    institutes?: any[];
    tracks?: any[];
    dateSelected?: boolean;
    joinDateSelected?: boolean;
}

export const getStudentTabs = (id?: string, association?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_STUDENT_TAB });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/users/admins/filter?id=${id}&association=${association}`,
            config
        );
        dispatch({
            type: GET_STUDENT_TAB_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_STUDENT_TAB_FAILED, payload: getErrorMessage(error) });
    }
};

export const addStudentTab = (tab: StudentTabCreateType) => async (dispatch: any) => {
    try {
        if (tab?.joinDateSelected === false) {
            delete tab?.joinedDate;
            delete tab?.joinDateSelected;
        }
        if (tab?.dateSelected === false) {
            delete tab?.lastActiveDate;
            delete tab?.dateSelected;
        }
        if (tab?.status === undefined || tab?.status === null) {
            delete tab?.status;
        }
        if (tab?.institutes?.length === 0 || tab?.institutes?.includes(NaN)) {
            delete tab?.institutes;
        }
        if (tab?.homeTowns === undefined || tab?.homeTowns === null) {
            delete tab?.homeTowns;
        }
        if (tab?.tracks?.length === 0 || tab?.tracks === null) {
            delete tab?.tracks;
        }
        dispatch({ type: ADD_STUDENT_TAB });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/users/filter`, tab, config);
        const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
        await dispatch(getStudentTabs(profile?.userId, 'STUDENT'));

        dispatch({ type: ADD_STUDENT_TAB_SUCCESS, payload: res.data.responseDto.data });
    } catch (error) {
        dispatch({ type: ADD_STUDENT_TAB_FAILED, payload: getErrorMessage(error) });
    }
};
