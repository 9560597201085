import { StudentType } from '../students/reducers';
import {
    ADD_STUDENT_TAB,
    ADD_STUDENT_TAB_FAILED,
    ADD_STUDENT_TAB_SUCCESS,
    GET_STUDENT_TAB,
    GET_STUDENT_TAB_FAILED,
    GET_STUDENT_TAB_SUCCESS,
} from './actions';

export interface Tags {
    id: number;
    systemTag: boolean;
    tagCategory: string;
    tagCreatedBy: string;
    tagCreatedDate: string;
    tagLastUpdatedBy: string;
    tagLastUpdatedDate: string;
    tagName: string;
}
export interface CountType {
    DEACTIVATED: number;
    ACTIVE: number;
    DELETED: number;
    ALL: number;
}
export interface StudentTabInitialState {
    studentData: StudentType[];
    student: StudentType;
    studentTabData: [];
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    studentByIdLoading: boolean;
    studentByIdError: string | null;
    studentsError: string | null;
    isStudentByIdLoading: boolean;
    isDeleteLoading: boolean;
    deleteStudentError: string | null;
    studentDeleteSuccessMessage: string | null;
    isEditLoading: boolean;
    editStudentError: string | null;
    StudentUpdateSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    studentBulkSuccessMessage: string | null;
}

const initialState: StudentTabInitialState = {
    studentData: [],
    student: null,
    studentTabData: [],
    pageCount: null,
    count: null,
    isLoading: false,
    studentByIdLoading: false,
    studentByIdError: null,
    isStudentByIdLoading: false,
    studentsError: null,
    isDeleteLoading: false,
    deleteStudentError: null,
    studentDeleteSuccessMessage: null,
    isEditLoading: false,
    editStudentError: null,
    StudentUpdateSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    studentBulkSuccessMessage: null,
};

const studentsTabReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_STUDENT_TAB:
            return { ...state, isLoading: true, trackError: null };
        case ADD_STUDENT_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                studentData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case ADD_STUDENT_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        case GET_STUDENT_TAB:
            return { ...state, isLoading: true, trackError: null };
        case GET_STUDENT_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                studentTabData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case GET_STUDENT_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        default:
            return state;
    }
};

export default studentsTabReducer;
