import './main.css';
import './assets/styles/global.css';
import './assets/styles/rc-time-picker.css';
import './assets/styles/react-datepicker.css';
import 'react-responsive-modal/styles.css';
import '@fortawesome/fontawesome-free/js/all.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons/lib';
import AdminRoutes from './Routes/AdminRoutes';
import WebRoutes from './Routes/WebRoutes';
function App() {
    const [sidebar, setSidebar] = useState(true);
    // Handle Address bar in mobile browsers when calculating height
    // Search code for "var(--vh, 1vh)"" to find code usage
    const handleChangeInResize = () => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    useEffect(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', handleChangeInResize);
        return () => {
            window.removeEventListener('resize', handleChangeInResize);
        };
    }, []);

    return (
        <IconContext.Provider value={{ color: '#707a92' }}>
            {window.location.pathname.includes('/admin') ? (
                <AdminRoutes setSidebar={setSidebar} sidebar={sidebar} />
            ) : (
                <WebRoutes setSidebar={setSidebar} sidebar={sidebar} />
            )}
            <link
                rel="stylesheet"
                href="https://use.fontawesome.com/releases/v5.13.0/css/all.css"
            />
            <script
                type="text/javascript"
                src="/test/wp-content/themes/child/script/jquery.jcarousel.min.js"
            ></script>
        </IconContext.Provider>
    );
}

export default App;
