import {
    SUBMIT_CHALLENGE,
    SUBMIT_CHALLENGE_SUCCESS,
    SUBMIT_CHALLENGE_FAILED,
    SUBMIT_CHALLENGE_RESET,
    CHALLENGES_STATUS,
    CHALLENGES_STATUS_SUCCESS,
    CHALLENGES_STATUS_FAILED,
    SUBMIT_TASK,
    SUBMIT_TASK_SUCCESS,
    SUBMIT_TASK_FAILED,
    SUBMIT_TASK_RESET,
    SUBMIT_COMPLETE_CHALLENGE_RESET,
} from './actions';

import { StudentTracksChallengesType } from '../../challenges/reducers';

export interface earnedCertificateType {
    certificateName: string | null;
    description: string | null;
    contentDocUrl: string | null;
    contentPdfUrl: string | null;
    certCreatedDate: string | null;
    certificateSize: any | null;
    totalMarks: any;
    earnedMarks: any;
    track: {
        trackName: string | null;
        trackDescription: string | null;
        imageLink: string | null;
    };
}
export interface earnedBadgeType {
    badgeName: string | null;
    badgeDescription: string | null;
    imageUrl: string | null;
}
export interface achivementsType {
    earnedCertificate: earnedCertificateType[];
    earnedBadges: earnedBadgeType[];
}

export interface webChallengeInitialState {
    isSubmitChallengeLoading: boolean;
    achivements: achivementsType | null;
    submitChallengeError: string | null;
    submitChallengeSuccess: boolean | null;
    isChallengesStatusLoading: boolean;
    challengesData: StudentTracksChallengesType | null;
    challengesStatusError: string | null;
    challengesStatusSuccess: boolean | null;
    submittedTaskData: any;
    submittedTaskDataError: string | null;
    submittedTaskDataLoading: boolean;
}

const initialState: webChallengeInitialState = {
    isSubmitChallengeLoading: false,
    achivements: null,
    submitChallengeError: null,
    submitChallengeSuccess: null,
    isChallengesStatusLoading: false,
    challengesData: null,
    challengesStatusError: null,
    challengesStatusSuccess: null,
    submittedTaskData: null,
    submittedTaskDataError: null,
    submittedTaskDataLoading: false,
};

const webChallengeReducer = (state = initialState, action: any): webChallengeInitialState => {
    switch (action.type) {
        case SUBMIT_CHALLENGE:
            return { ...state, isSubmitChallengeLoading: true };
        case SUBMIT_CHALLENGE_SUCCESS:
            return {
                ...state,
                isSubmitChallengeLoading: false,
                submitChallengeSuccess: true,
                achivements: action.payload,
            };
        case SUBMIT_CHALLENGE_FAILED:
            return {
                ...state,
                isSubmitChallengeLoading: false,
                submitChallengeError: action.payload,
            };
        case SUBMIT_CHALLENGE_RESET:
            return { ...state, submitChallengeSuccess: false };
        case SUBMIT_TASK:
            return { ...state, submittedTaskDataLoading: true, submittedTaskDataError: null };
        case SUBMIT_TASK_SUCCESS:
            return {
                ...state,
                submittedTaskDataLoading: false,
                submittedTaskData: action.payload,
            };
        case SUBMIT_TASK_FAILED:
            return {
                ...state,
                submittedTaskDataLoading: false,
                submittedTaskDataError: action.payload,
            };
        case SUBMIT_TASK_RESET:
            return {
                ...state,
                submittedTaskData: null,
            };
        case CHALLENGES_STATUS:
            return { ...state, isChallengesStatusLoading: true };
        case CHALLENGES_STATUS_SUCCESS:
            return {
                ...state,
                isChallengesStatusLoading: false,
                challengesStatusSuccess: true,
                challengesData: action.payload,
            };
        case CHALLENGES_STATUS_FAILED:
            return {
                ...state,
                isChallengesStatusLoading: false,
                challengesStatusError: action.payload,
            };
        case SUBMIT_COMPLETE_CHALLENGE_RESET:
            return {
                ...state,
                achivements: null,
                submitChallengeSuccess: false,
                submitChallengeError: null,
            };
        default:
            return state;
    }
};

export default webChallengeReducer;
