import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearChallengeMessages } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import SuccesfullyDeletedModal from '../../modals/SuccesfullyDeletedModal';
import { useTable, useRowSelect, usePagination } from 'react-table';
import * as BsIcons from 'react-icons/bs';
import * as HiIcons from 'react-icons/hi';
import { IconContext } from 'react-icons';
import BulkUpdatSuccessModal from '../../modals/BulkUpdatSuccessModal';
import BulkUpdateErrorModal from '../../modals/BulkUpdateErrorModal';
import { ChallengeCreateType, ChallengesType } from '../../../redux/challenges/reducers';
import IndeterminateCheckbox from '../Tracks/IndeterminateCheckBox';
import SuccessfullyDisabledChallengeModal from '../../modals/SuccessfullyDisabledChallengeModal';
import DisableRestoreConfirmationModal from '../../modals/DisableRestoreConfirmationModal';
import PublishConfirmationModal from '../../modals/PublishConfirmationModal';
import TableTabView from './TableTabView';
interface Props {
    data: ChallengesType[];
    columns: any;
    fetchData;
    pageCount: number;
    setSlectedChallenges;
    selectedTab: number;
    setSelectedTab: (value: number) => void;
    backToFirstRef: any;
}

const ChallengesTable = ({
    columns,
    data,
    fetchData,
    pageCount: controlledPageCount,
    setSlectedChallenges,
    selectedTab,
    setSelectedTab,
    backToFirstRef,
}: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showMenu, setshowMenu] = useState(false);
    const [showColumnDropdown, setShowColumnDropdown] = useState(false);
    const [clickedChallenge, setClickedChallenge] = useState<ChallengesType>();
    const [clickedColumnId, setClickedColumnId] = useState();
    const [selectedChallengeForUnpublish, setSelectedChallengeForUnpublish] =
        useState<ChallengeCreateType>();
    const [selectedChallengeForPublish, setSelectedChallengeForPublish] =
        useState<ChallengeCreateType>();
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [deleteSuccesVisible, setDeleteSuccesVisible] = useState(false);
    const [disableSuccesVisible, setDisableSuccesVisible] = useState(false);
    const [bulkUpdateSuccesVisible, setBulkUpdateSuccesVisible] = useState(false);
    const [bulkUpdateErrorVisible, setBulkUpdateErrorVisible] = useState(false);
    const [disableConfirmationVisible, setDisableConfirmationVisible] = useState(false);
    const [showRecordsDropdown, setShowRecordsDropdown] = useState(false);
    const [publishConfirmationVisible, setPublishConfirmationVisible] = useState(false);
    const [goto, setGoto] = useState<any>();
    const tabCounts = useSelector((state: RootState) => state.challenges.count);
    const deleteChallengeError = useSelector(
        (state: RootState) => state.challenges.deleteChallengeError
    );
    const challengeBulkSuccessMessage = useSelector(
        (state: RootState) => state.challenges.challengeBulkSuccessMessage
    );
    const bulkError = useSelector((state: RootState) => state.challenges.bulkError);
    const challengeDeleteSuccess = useSelector(
        (state: RootState) => state.challenges.challengeDeleteSuccessMessage
    );
    const editChallengeError = useSelector(
        (state: RootState) => state.challenges.editChallengeError
    );
    const challengeUpdateSuccess = useSelector(
        (state: RootState) => state.challenges.challengeUpdateSuccessMessage
    );
    const tabStatus =
        selectedTab === 1
            ? 'PUBLISHED'
            : selectedTab === 2
            ? 'UNPUBLISHED'
            : selectedTab === 3
            ? 'DELETED'
            : '';
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        gotoPage,
        selectedFlatRows,
        allColumns,
        getToggleHideAllColumnsProps,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
            manualPagination: true,
            pageCount: controlledPageCount,
        },
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(column => [
                {
                    id: 'selection',
                    // eslint-disable-next-line react/display-name
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div className="flex justify-center">
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // eslint-disable-next-line react/display-name
                    Cell: ({ row }) => (
                        // eslint-disable-next-line react/display-name
                        <div className="flex justify-center">
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...column,
            ]);
        }
    );
    const VisibleColumns = allColumns.filter(
        col => col.isVisible === true && col.id !== 'selection' && col.id !== 'actionColumn'
    );

    useEffect(() => {
        gotoPage(0);
    }, [selectedTab, gotoPage]);

    useEffect(() => {
        selectedFlatRows.map(a => {
            return a.original;
        });
        setSlectedChallenges(
            selectedFlatRows.map(a => {
                return a.original;
            })
        );
    }, [selectedFlatRows, setSlectedChallenges]);

    useEffect(() => {
        fetchData({ pageIndex, pageSize });
    }, [fetchData, pageIndex, pageSize]);

    useImperativeHandle(backToFirstRef, () => ({
        goToFirst() {
            gotoPage(0);
        },
    }));

    const handleClearMessages = useCallback(() => {
        if (challengeDeleteSuccess) {
            setDeleteSuccesVisible(true);
        }
        if (challengeUpdateSuccess) {
            setDisableSuccesVisible(true);
        }
        if (challengeBulkSuccessMessage) {
            setBulkUpdateSuccesVisible(true);
        }
        if (bulkError) {
            setBulkUpdateErrorVisible(true);
        }
    }, [
        dispatch,
        challengeDeleteSuccess,
        deleteChallengeError,
        challengeUpdateSuccess,
        editChallengeError,
        challengeBulkSuccessMessage,
        bulkError,
    ]);
    const handleClose = () => {
        dispatch(clearChallengeMessages());
        setBulkUpdateErrorVisible(false);
        setBulkUpdateSuccesVisible(false);
        setDeleteSuccesVisible(false);
        setDisableSuccesVisible(false);
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    const showOptions = (challenge: any) => {
        setshowMenu(true);
        setClickedChallenge(challenge);
        setSelectedChallengeForUnpublish({
            challengeStatus: 'UNPUBLISHED',
            challengeName: challenge.challengeName,
            pointsAwarded: challenge.pointsAwarded,
            prerequisitePoints: challenge.prerequisitePoints,
            imageLink: challenge.imageLink,
            introLink: challenge.introLink,
        });
        setSelectedChallengeForPublish({
            challengeStatus: 'PUBLISHED',
            challengeName: challenge.challengeName,
            pointsAwarded: challenge.pointsAwarded,
            prerequisitePoints: challenge.prerequisitePoints,
            imageLink: challenge.imageLink,
            introLink: challenge.introLink,
        });
    };

    const columnClickHandler = columnId => {
        setClickedColumnId(columnId);
        columnId !== 'selection' && columnId !== 'actionColumn'
            ? setShowColumnDropdown(true)
            : setShowColumnDropdown(false);
    };

    const viewChallenge = (challenge: any) => {
        history.push({
            pathname: `/admin/challenges/${challenge.id}/view`,
            state: { challenge: challenge },
        });
    };
    const editChallenge = async challenge => {
        history.push({
            pathname: `/admin/challenges/${challenge.id}/edit`,
            state: { challenge: challenge },
        });
    };
    return (
        <section className="h-full pr-4">
            <div className="flex justify-between w-full items-end mb-4">
                <div className="w-5/6 md:w-4/5">
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        tabCounts={tabCounts}
                    />
                </div>
                <div className="mt-4 ml-1 md:ml-4 w-12 md:w-32 flex justify-end items-center">
                    <div className="hidden md:flex md:w-1/2">Records</div>
                    <div
                        className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-1/2 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                    >
                        <div
                            className="flex justify-between w-full"
                            onClick={() => setShowRecordsDropdown(true)}
                        >
                            <div className="text-gray-700 font-medium pl-2">{pageSize}</div>
                            <div className="">
                                <div className="flex flex-col">
                                    <HiIcons.HiChevronUp className="-mb-2" />
                                    <HiIcons.HiChevronDown className="" />
                                </div>
                            </div>
                        </div>
                        {showRecordsDropdown ? (
                            <ul
                                className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                role="menu"
                                onMouseLeave={() => setShowRecordsDropdown(false)}
                            >
                                {[10, 25, 50, 100].map(value => (
                                    <div
                                        className="p-2 hover:bg-gray-200 "
                                        key={value}
                                        onClick={() => {
                                            setPageSize(value);
                                            setShowRecordsDropdown(false);
                                        }}
                                    >
                                        {value}
                                    </div>
                                ))}
                            </ul>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="w-full mb-8 overflow-hidden h-full">
                <div className="w-full overflow-x-auto pl-1 h-full" style={{ minHeight: '50vh' }}>
                    <table className="w-full" {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr
                                    key={headerGroup}
                                    {...headerGroup.getHeaderGroupProps()}
                                    className="text-sm xl:text-base font-medium tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-600"
                                    onMouseLeave={() => {
                                        setShowColumnDropdown(false);
                                        setshowMenu(false);
                                    }}
                                >
                                    {headerGroup.headers.map(column => (
                                        <th
                                            key={column}
                                            {...column.getHeaderProps()}
                                            className={`px-2 py-3 border align-top ${
                                                column.id === clickedColumnId ? 'relative' : ''
                                            } ${
                                                column.id !== 'selection' &&
                                                column.id !== 'actionColumn'
                                                    ? 'cursor-pointer'
                                                    : ''
                                            }`}
                                            onClick={() => columnClickHandler(column.id)}
                                        >
                                            <div
                                                className={`flex ${
                                                    column.id !== 'selection'
                                                        ? 'justify-between'
                                                        : 'justify-center'
                                                }`}
                                            >
                                                <div className="whitespace-nowrap">
                                                    {column.render('Header')}
                                                </div>
                                                {column.id !== 'selection' &&
                                                    column.id !== 'actionColumn' && (
                                                        <div className="flex flex-col justify-start">
                                                            <HiIcons.HiChevronDown
                                                                className="ml-2"
                                                                style={{
                                                                    color: '#374151',
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                            </div>

                                            {column.id === clickedColumnId && showColumnDropdown && (
                                                <div className="mt-3 absolute left-0 shadow-md z-10 w-44 cursor-default">
                                                    <ul
                                                        className="bg-gray-200 shadow border-2 border-gray-400 p-2 mb-0"
                                                        onMouseLeave={() =>
                                                            setShowColumnDropdown(false)
                                                        }
                                                    >
                                                        <div className="pb-2">
                                                            <p className="text-sm mb-2">
                                                                Show Column
                                                            </p>
                                                            <div className="font-medium text-gray-600 text-sm">
                                                                <IndeterminateCheckbox
                                                                    disabled
                                                                    {...getToggleHideAllColumnsProps()}
                                                                />{' '}
                                                                View All
                                                            </div>
                                                            {allColumns.map(col => (
                                                                <div key={col.id}>
                                                                    {col.id !== 'selection' &&
                                                                        col.id !==
                                                                            'actionColumn' && (
                                                                            <label className="font-medium text-gray-600 text-sm">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    {...col.getToggleHiddenProps()}
                                                                                    disabled={
                                                                                        col.isVisible &&
                                                                                        VisibleColumns.length <=
                                                                                            4
                                                                                    }
                                                                                />{' '}
                                                                                {col.Header}
                                                                            </label>
                                                                        )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </ul>
                                                </div>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="bg-white" {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <tr key={row} {...row.getRowProps()} className="text-gray-700">
                                        {row.cells.map(cell => {
                                            return (
                                                <td
                                                    key={cell}
                                                    {...cell.getCellProps({})}
                                                    className={`py-3 text-sm font-semibold border ${
                                                        cell.column.id === 'actionColumn'
                                                            ? 'relative'
                                                            : cell.column.id !== 'selection'
                                                            ? 'px-2 cursor-pointer'
                                                            : ''
                                                    }`}
                                                    onClick={() => {
                                                        if (cell.column.id === 'actionColumn') {
                                                            showOptions(row.original);
                                                        } else if (cell.column.id !== 'selection') {
                                                            viewChallenge(row.original);
                                                        }
                                                    }}
                                                >
                                                    {cell.render('Cell')}
                                                    {cell.column.id === 'actionColumn' &&
                                                        showMenu &&
                                                        clickedChallenge === row.original && (
                                                            <div className="dropdown-content bottom-3 absolute left-0 shadow-md z-10 w-18">
                                                                <ul
                                                                    className="bg-gray-600 shadow rounded py-1 pl-0 mb-0"
                                                                    onMouseLeave={() =>
                                                                        setshowMenu(false)
                                                                    }
                                                                >
                                                                    {selectedTab !== 3 && (
                                                                        <li
                                                                            className="cursor-pointer text-sm leading-3 tracking-normal py-1 hover:bg-gray-400 text-white px-2 font-normal"
                                                                            onClick={() => {
                                                                                editChallenge(
                                                                                    clickedChallenge
                                                                                );
                                                                            }}
                                                                        >
                                                                            Edit
                                                                        </li>
                                                                    )}
                                                                    {selectedTab === 2 && (
                                                                        <li
                                                                            className="cursor-pointer text-sm leading-3 tracking-normal py-1 hover:bg-gray-400 text-white px-2 font-normal"
                                                                            onClick={() => {
                                                                                if (
                                                                                    clickedChallenge.challengeStatus ===
                                                                                    'UNPUBLISHED'
                                                                                ) {
                                                                                    setDeleteConfirmationVisible(
                                                                                        true
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </li>
                                                                    )}
                                                                    {clickedChallenge.challengeStatus ===
                                                                        'UNPUBLISHED' && (
                                                                        <li
                                                                            className="cursor-pointer text-sm leading-3 tracking-normal py-1 hover:bg-gray-400 text-white px-2 font-normal"
                                                                            onClick={() =>
                                                                                setPublishConfirmationVisible(
                                                                                    true
                                                                                )
                                                                            }
                                                                        >
                                                                            Publish
                                                                        </li>
                                                                    )}
                                                                    {clickedChallenge.challengeStatus ===
                                                                        'PUBLISHED' && (
                                                                        <li
                                                                            className="cursor-pointer text-sm leading-3 tracking-normal py-1 hover:bg-gray-400 text-white px-2 font-normal"
                                                                            onClick={() =>
                                                                                setDisableConfirmationVisible(
                                                                                    true
                                                                                )
                                                                            }
                                                                        >
                                                                            Unpublish
                                                                        </li>
                                                                    )}
                                                                    {selectedTab === 3 && (
                                                                        <li
                                                                            className="cursor-pointer text-sm leading-3 tracking-normal py-1 hover:bg-gray-400 text-white px-2 font-normal"
                                                                            onClick={() =>
                                                                                setDisableConfirmationVisible(
                                                                                    true
                                                                                )
                                                                            }
                                                                        >
                                                                            Restore
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="pb-4">
                <div className="flex justify-end  mb-2">
                    <IconContext.Provider
                        value={{ color: !canPreviousPage ? '#bbbcbc' : '#707a92' }}
                    >
                        <button
                            className="text-xl font-medium text-gray-900"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            <BsIcons.BsChevronLeft className="" />
                        </button>
                    </IconContext.Provider>
                    <span className="flex items-center font-semibold text-gray-700">
                        <p className="border px-2 mx-2  my-auto">{pageIndex + 1}</p>
                        of
                        <p className="border px-2 mx-2  my-auto">
                            {pageOptions.length === 0 ? '1' : pageOptions.length}
                        </p>
                    </span>
                    <IconContext.Provider value={{ color: !canNextPage ? '#bbbcbc' : '#707a92' }}>
                        <button
                            className="text-xl font-medium text-gray-900"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        >
                            <BsIcons.BsChevronRight className="" />
                        </button>
                    </IconContext.Provider>
                </div>
                <div className="flex justify-end font-bold">
                    <div
                        className="bg-gray-200 border-gray-400 border mr-1 rounded px-1 cursor-pointer"
                        onClick={() => {
                            gotoPage(goto - 1);
                        }}
                    >
                        Go
                    </div>
                    to
                    <input
                        className="border font-bold px-2 mr-7 ml-2 w-8 focus:outline-none rounded"
                        value={goto}
                        type="number"
                        onChange={e => setGoto(e.target.value)}
                    />
                </div>
            </div>
            {deleteConfirmationVisible ? (
                <DeleteConfirmationModal
                    modalVisible={deleteConfirmationVisible}
                    setModalVisible={setDeleteConfirmationVisible}
                    challengeId={clickedChallenge?.id}
                    setshowMenu={setshowMenu}
                    tabStatus={tabStatus}
                />
            ) : null}
            {disableConfirmationVisible ? (
                <DisableRestoreConfirmationModal
                    modalVisible={disableConfirmationVisible}
                    setModalVisible={setDisableConfirmationVisible}
                    challengeId={clickedChallenge?.id}
                    challenge={selectedChallengeForUnpublish}
                    selectedTab={selectedTab}
                    tabStatus={tabStatus}
                />
            ) : null}
            {publishConfirmationVisible ? (
                <PublishConfirmationModal
                    modalVisible={publishConfirmationVisible}
                    setModalVisible={setPublishConfirmationVisible}
                    challengeId={clickedChallenge?.id}
                    challenge={selectedChallengeForPublish!}
                    tabStatus={tabStatus}
                />
            ) : null}
            {deleteSuccesVisible ? (
                <SuccesfullyDeletedModal
                    modalVisible={deleteSuccesVisible}
                    handleClose={handleClose}
                />
            ) : null}
            {disableSuccesVisible ? (
                <SuccessfullyDisabledChallengeModal
                    modalVisible={disableSuccesVisible}
                    handleClose={handleClose}
                />
            ) : null}
            {bulkUpdateSuccesVisible ? (
                <BulkUpdatSuccessModal
                    modalVisible={bulkUpdateSuccesVisible}
                    handleClose={handleClose}
                />
            ) : null}
            {bulkUpdateErrorVisible ? (
                <BulkUpdateErrorModal
                    modalVisible={bulkUpdateErrorVisible}
                    handleClose={handleClose}
                />
            ) : null}
        </section>
    );
};

export default ChallengesTable;
