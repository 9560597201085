import React, { useState, useMemo, useEffect, useRef } from 'react';
import Tab from './Tab';
import AnalyticsTable from './AnalyticsTable';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from 'react-icons/bs';
import * as BiIcons from 'react-icons/bi';
import RightSideContent from '../../RightSideContent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { analyticsType } from '../../../redux/analytics/reducers';
import NewAnalyticsTable from './NewAnalyticsTable';
import { TracksType } from '../../../redux/tracks/reducers';
import * as HiIcons from 'react-icons/hi';
import Loader from '../../../assets/svg/Loader';
import Add from '../../../assets/svg/Add';
import AddFilter from './AddFilter';
import { CityFilter, AverageScore, Gender, moduleNames } from '../../../constants';
import { getAnalytics, getTracks } from '../../../redux/rootActions';
import { useQuery } from '../../../web/hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { COMPANY_ADMIN, UserProfileType } from '../../../redux/authAdmin/reducers';
import { getUserProfile } from '../../../utils/storage';
import BulkRestoreConfirmationModal from '../../modals/BulkRestoreConfirmation';
import BulkDeleteConfirmationModal from '../../modals/BulkDeleteConfirmation';
import AssignTagModal from '../../modals/AssignTagModal';

export const Analytics = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const searchTermRef = useRef();
    const backToFirstRef = useRef(null);
    const analyticsData = useSelector((state: RootState) => state.analytics.analyticsData);
    const isLoading = useSelector((state: RootState) => state.analytics.isLoading);
    const StudentBulkSuccessMessage = useSelector(
        (state: RootState) => state.students.studentBulkSuccessMessage
    );
    const filteredAnalyticsData = useSelector(
        (state: RootState) => state.filteredAnalytics.analyticsData
    );
    const [selectedStudents, setSlectedStudents] = useState([]);
    const [bulkAction, setBulkAction] = useState<string>('Bulk Action');
    const trackData = useSelector((state: RootState) => state.tracks.trackData);
    const pageCount = useSelector((state: RootState) => state.analytics.pageCount);
    const [tags, setTags] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedMainTab, setSelectedMainTab] = useState(1);
    const [ClickedAnalytics, setClickedAnalytics] = useState<analyticsType[]>();
    const [searchTerm, setSearchTerm] = useState('');
    const [showTracksDropdown, setShowTracksDropdown] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [track, setTrack] = useState<TracksType>();
    const fetchIdRef = React.useRef(0);
    const [rankBase, setRankBase] = useState(0);
    const [pageSizeS, setPageSizeS] = useState(0);
    const [pageIndexS, setPageIndexS] = useState(0);
    const [tagIds, setTagIds] = useState('');
    const [hometownsSelected, setHometownsSelected] = useState([]);
    const [tracksSelected, setTracksSelected] = useState([]);
    const [avaragesSelected, setAvaragesSelected] = useState([]);
    const [gendersSelected, setGendersSelected] = useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [filterHometowns, setFilterHometowns] = useState([]);
    const [filterTracks, setFilterTracks] = useState([]);
    const [filterAverages, setFilterAvarages] = useState([]);
    const [filterGenders, setFilterGenders] = useState([]);
    const [assignTagsModalVisible, setAssignTagsModalVisible] = useState(false);

    const hometownsQS = query.get('hometowns');
    const tracksQS = query.get('tracks');
    const avaragesQS = query.get('avarages');
    const gendersQS = query.get('genders');
    const initialRender = useRef(true);

    const [sidebar, setSidebar] = useState(true);
    const showSidebar = () => setSidebar(!sidebar);

    const bulkOptions = [{ id: 1, val: 'Assign tags' }];

    const columns = useMemo(
        () => [
            {
                Header: 'Rank',
                accessor: (data, index) => {
                    const Rank = rankBase + index + 1;
                    return Rank;
                },
            },
            {
                Header: 'Student ID',
                accessor: 'studentId',
            },
            {
                Header: 'Student Name',
                accessor: 'studentName',
            },
            {
                Header: 'Contact Number',
                accessor: 'mobile',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'No of Attempted Challenges',
                accessor: 'completedChallengeCount',
            },
            {
                Header: 'Average Score',
                accessor: data => {
                    return Math.round(data?.avgScore);
                },
            },
            {
                Header: 'Task Attempts',
                accessor: 'attempts',
            },
            {
                Header: 'LinkedIn',
                accessor: 'linkedInProfileUrl',
            },
            {
                Header: 'Institute',
                accessor: 'institute',
            },
            {
                Header: 'Gender',
                accessor: 'gender',
            },
            {
                Header: 'Hometown',
                accessor: 'hometown',
            },
        ],
        [rankBase]
    );

    const applyBulkAction = async () => {
        if (bulkAction === 'Assign tags') {
            setAssignTagsModalVisible(true);
        }
    };

    useEffect(() => {
        if (hometownsQS) {
            setHometownsSelected(hometownsQS.split(','));
        }
        if (tracksQS) {
            setTracksSelected(tracksQS.split(','));
        }
        if (avaragesQS) {
            setAvaragesSelected(avaragesQS.split(','));
        }
        if (gendersQS) {
            setGendersSelected(gendersQS.split(','));
        }
        dispatch(getTracks(true, 0, 100, ''));
    }, [dispatch]);

    const fetchData = React.useCallback(
        ({ pageSize, pageIndex }) => {
            setPageSizeS(pageSize);
            setPageIndexS(pageIndex);
            const tagIdsT = tags?.map(tagss => tagss.id).toString();
            setTagIds(tagIdsT);
            const fetchId = ++fetchIdRef.current;
            setRankBase(pageIndex * pageSize);
            if (fetchId === fetchIdRef.current) {
                dispatch(
                    getAnalytics(
                        pageIndex,
                        pageSize,
                        track?.id || Number(tracksSelected) || Number(tracksQS?.split(',')),
                        searchTermRef.current,
                        hometownsSelected.toString() || hometownsQS?.split(',').toString(),
                        avaragesSelected.toString()?.split(' ')[0] ||
                            avaragesQS?.toString()?.split(' ')[0],
                        avaragesSelected.toString()?.split(' ')[
                            avaragesSelected.toString()?.split(' ').length - 2
                        ] || avaragesQS?.split(' ')[avaragesQS?.split(' ').length - 2],
                        gendersSelected.toString() || gendersQS?.split(',').toString()
                    )
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, track, hometownsQS, tracksQS, avaragesQS, gendersQS]
    );

    const searchByNameOrId = async () => {
        await backToFirstRef?.current?.goToFirst();
        dispatch(
            getAnalytics(
                0,
                pageSizeS,
                track?.id || Number(tracksSelected),
                searchTerm,
                hometownsSelected.toString(),
                avaragesSelected.toString().split(' ')[0],
                avaragesSelected.toString().split(' ')[
                    avaragesSelected.toString().split(' ').length - 2
                ],
                gendersSelected.toString()
            )
        );
    };
    const onRequestClose = () => {
        setShowModal(false);
    };
    useEffect(() => {
        const goBack = async () => {
            backToFirstRef?.current?.goToFirst();
        };
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            goBack().then(() => {
                history.replace({
                    search: `?hometowns=${hometownsSelected.toString()}&tracks=${tracksSelected.toString()}&avarages=${avaragesSelected.toString()}&genders=${gendersSelected.toString()}`,
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        hometownsSelected,
        tracksSelected,
        avaragesSelected,
        gendersSelected,
        StudentBulkSuccessMessage,
    ]);

    useEffect(() => {
        if (searchTerm.length === 0) {
            dispatch(
                getAnalytics(
                    pageIndexS,
                    pageSizeS,
                    track?.id || Number(tracksSelected),
                    searchTerm,
                    hometownsSelected.toString(),
                    avaragesSelected.toString().split(' ')[0],
                    avaragesSelected.toString().split(' ')[
                        avaragesSelected.toString().split(' ').length - 2
                    ],
                    gendersSelected.toString()
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    useEffect(() => {
        if (CityFilter.length > 0) {
            const newHometownsArr = CityFilter.map(item => {
                return { name: item.val, id: item.id };
            });
            setFilterHometowns(newHometownsArr);
        } else {
            setFilterHometowns([]);
        }
        if (trackData.length > 0) {
            const newTracksArr = trackData.map(item => {
                return { name: item.trackName, id: item.id };
            });
            setFilterTracks(newTracksArr);
        } else {
            setFilterTracks([]);
        }
        if (AverageScore.length > 0) {
            const newAvg = AverageScore.map(item => {
                return { name: item.val, id: item.id };
            });
            setFilterAvarages(newAvg);
        } else {
            setFilterAvarages([]);
        }
        if (Gender.length > 0) {
            const newGender = Gender.map(item => {
                return { name: item.val, id: item.id };
            });
            setFilterGenders(newGender);
        } else {
            setFilterGenders([]);
        }
    }, [trackData, AverageScore, CityFilter, Gender]);

    const searchInputHandler = e => {
        searchTermRef.current = e.target.value;
        setSearchTerm(e.target.value);
    };

    const openFilter = () => {
        setShowModal(true);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            searchByNameOrId();
        }
    };
    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <Tab
                    selectedTab={selectedMainTab}
                    setSelectedTab={setSelectedMainTab}
                    selectedTrackId={
                        track?.id || Number(tracksSelected) || Number(tracksQS?.split(','))
                    }
                />
                <div className="flex w-full">
                    <div
                        style={{
                            width: sidebar === false ? '100%' : '75%',
                        }}
                    >
                        <div className="flex h-12 md:h-14 justify-between md:justify-start">
                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex">
                                <input
                                    className={`rounded-sm w-full h-full text-gray-500 font-medium focus:outline-none p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                    id="id"
                                    type="text"
                                    placeholder="Search by ID or Name"
                                    onChange={e => searchInputHandler(e)}
                                    autoComplete="off"
                                    onKeyDown={handleKeyDown}
                                />
                                <button
                                    className={`ml-2 px-5 border-2 font-medium text-sm rounded-md focus:outline-none ${
                                        searchTerm.length > 0
                                            ? ' text-white border-blue-700 bg-blue-700 cursor-pointer'
                                            : 'cursor-not-allowed border-gray-300'
                                    }`}
                                    disabled={searchTerm.length === 0}
                                    onClick={searchByNameOrId}
                                >
                                    <BsIcons.BsSearch
                                        style={searchTerm.length > 0 && { color: '#ffffff' }}
                                    />
                                </button>
                            </div>

                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex">
                                <div
                                    className={`rounded-md flex relative items-center focus:outline-none w-3/4 md:w-3/4 lg:w-3/4 xl:w-2/3 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className={`flex justify-between w-full md:p-2 cursor-pointer`}
                                        onClick={() =>
                                            selectedStudents.length > 1
                                                ? setDropdownVisible(true)
                                                : null
                                        }
                                    >
                                        <div className="text-gray-700 font-medium pl-2">
                                            {bulkAction}
                                        </div>
                                        <div className="">
                                            <div className="flex flex-col">
                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                <HiIcons.HiChevronDown className="mr-2" />
                                            </div>
                                        </div>
                                    </div>
                                    {dropdownVisible ? (
                                        <ul
                                            className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setDropdownVisible(false)}
                                        >
                                            {bulkOptions.map(action => (
                                                <div
                                                    className="p-2 hover:bg-gray-200 "
                                                    key={action.id}
                                                    onClick={() => {
                                                        setBulkAction(action.val);
                                                        setDropdownVisible(false);
                                                    }}
                                                >
                                                    {action.val}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                                <button
                                    className={`ml-2 px-2 border-2 ${
                                        bulkAction === 'Bulk Action'
                                            ? 'text-gray-400 border-gray-300'
                                            : 'text-white border-blue-700 bg-blue-700'
                                    } font-medium text-sm rounded-md focus:outline-none  hover:border-blue-700 hover:text-white hover:bg-blue-700`}
                                    onClick={applyBulkAction}
                                >
                                    Apply
                                </button>
                            </div>
                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex items-center">
                                <label className="mx-2"> Track : </label>
                                <div
                                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none  w-3/4 md:w-3/4 lg:w-3/4 xl:w-2/3 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className="flex justify-between w-full"
                                        onClick={() => setShowTracksDropdown(!showTracksDropdown)}
                                    >
                                        <div className="text-gray-700 font-medium pl-2">
                                            {track ? track?.trackName : 'All'}
                                        </div>
                                        <div className="flex flex-col">
                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                            <HiIcons.HiChevronDown className="mr-2" />
                                        </div>
                                    </div>
                                    {showTracksDropdown ? (
                                        <ul
                                            className="absolute w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                        >
                                            <div
                                                className={`p-2 hover:bg-gray-200 ${
                                                    !track && 'bg-gray-300'
                                                }`}
                                                key="0"
                                                onClick={() => {
                                                    setTrack(null);
                                                    setShowTracksDropdown(false);
                                                }}
                                            >
                                                All
                                            </div>
                                            {trackData?.map(trackItem => (
                                                <div
                                                    className={`p-2 hover:bg-gray-200 ${
                                                        track?.trackName === trackItem?.trackName &&
                                                        'bg-gray-300'
                                                    }`}
                                                    key={trackItem?.id}
                                                    onClick={() => {
                                                        setTrack(trackItem);
                                                        setShowTracksDropdown(false);
                                                    }}
                                                >
                                                    {trackItem?.trackName}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="flex h-12 md:h-14 justify-between md:justify-start align-center p-5">
                            <BiIcons.BiFilterAlt
                                style={{
                                    color: 'rgba(29, 78, 216)',
                                    height: '20px',
                                    width: '20px',
                                    marginRight: '10px',
                                    alignSelf: 'baseline',
                                }}
                            />
                            <div
                                className="sm:text-sm md:text-base text-gray-400 pr-2 cursor-pointer"
                                onClick={openFilter}
                            >
                                Filter
                            </div>
                            <button onClick={openFilter}>
                                <Add size={25} color={'rgba(29, 78, 216)'} />
                            </button>
                            <AddFilter
                                setHometownsSelected={setHometownsSelected}
                                hometownsSelected={hometownsSelected}
                                setTracksSelected={setTracksSelected}
                                tracksSelected={tracksSelected}
                                setAvaragesSelected={setAvaragesSelected}
                                avaragesSelected={avaragesSelected}
                                setGendersSelected={setGendersSelected}
                                gendersSelected={gendersSelected}
                                // setTagsSelected={setTagsSelected}
                                // tagsSelected={tagsSelected}
                                isSelected={showModal}
                                onRequestClose={onRequestClose}
                                filterHometowns={filterHometowns}
                                filterTracks={filterTracks}
                                filterAverages={filterAverages}
                                filterGenders={filterGenders}
                                // filterTags={filterTags}
                            />
                            {hometownsSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Hometown filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setHometownsSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                            {tracksSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Track filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setTracksSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                            {avaragesSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Averages filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setAvaragesSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                            {gendersSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Gender filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setGendersSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        {isLoading && (
                            <div className=" w-full h-98  flex items-center">
                                <Loader />
                            </div>
                        )}
                        <div className={`${isLoading && 'hidden'}`}>
                            <AnalyticsTable
                                columns={columns}
                                data={
                                    selectedMainTab !== 1
                                        ? filteredAnalyticsData
                                        : ClickedAnalytics?.length > 0
                                        ? ClickedAnalytics
                                        : analyticsData
                                }
                                fetchData={fetchData}
                                pageCount={pageCount}
                                setSlectedRecords={setSlectedStudents}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                backToFirstRef={backToFirstRef}
                            />
                        </div>
                    </div>
                    <div
                        className={`relative border-l-2 ${
                            sidebar === false ? '' : 'right-auto w-1/4'
                        }`}
                    >
                        {sidebar === false && (
                            <div className=" ">
                                <RiIcons.RiArrowDropLeftLine
                                    style={{
                                        borderRadius: 50,
                                        borderWidth: 1,
                                        borderColor: 'rgba(0,0,0,0.2)',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        height: '25px',
                                        width: '25px',
                                        top: '100px',
                                        left: '-13px',
                                        backgroundColor: 'white',
                                    }}
                                    onClick={showSidebar}
                                />
                            </div>
                        )}

                        {sidebar === true && (
                            <div className="h-full">
                                <RiIcons.RiArrowDropRightLine
                                    style={{
                                        borderRadius: 50,
                                        borderWidth: 1,
                                        borderColor: 'rgba(0,0,0,0.2)',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        height: '25px',
                                        width: '25px',
                                        top: '100px',
                                        left: '-13px',
                                        backgroundColor: 'white',
                                    }}
                                    onClick={showSidebar}
                                />
                                <div className="h-96 ml-2 w-full mt-10">
                                    <RightSideContent
                                        tags={tags}
                                        setTags={setTags}
                                        moduleName={moduleNames.Student}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {assignTagsModalVisible ? (
                <AssignTagModal
                    modalVisible={assignTagsModalVisible}
                    setModalVisible={setAssignTagsModalVisible}
                    tabStatus={'ACTIVE'}
                    selectedRecords={selectedStudents}
                    moduleName={moduleNames.Student}
                />
            ) : null}
        </div>
    );
};
