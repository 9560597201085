import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import * as RiIcons from 'react-icons/ri';
import Modal from 'react-modal';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { learnChallenges } from '../../../../constants';
import { useHistory } from 'react-router-dom';
import RecommandedTracks from './RecommandedTracks';
import RecommandedChallenges from './RecommandedChallenges';
import RightSideContent from '../../../../components/RightSideContent';
import { USER_PROFILE } from '../../../utils/storageWeb';
import { RootState } from '../../../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getTracks } from '../../../../redux/rootActions';
import CloseIcon from '../../../../assets/svg/CloseIcon';
import Tour from './Tour';
import Loader from '../../../../assets/svg/Loader';
import styled from 'styled-components';

const DarkenedImage = styled.img`
    --tw-brightness: brightness(0.8);
`;

const Learn = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const trackData = useSelector((state: RootState) => state.tracks.trackData);
    const isAllTrackLoading = useSelector((state: RootState) => state.tracks.isAllTrackLoading);

    const [showTips, setShowTips] = useState(profile?.showLearnTip);
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    const [showSuccessModal, setShowSuccessModal] = React.useState(true);

    const customStyles: Modal.Styles = {
        overlay: {
            // top: 0,
            // left: 0,
            // right: 0,
            // bottom: 0,
            backgroundColor: 'rgba(239, 235, 255, 0.7)',
            // backdropFilter: 'blur(7px)',
            // overflow: 'auto',
            // zIndex: 999,
        },
        content: {
            // left: '50%',
            // right: 'auto',
            bottom: 'auto',
            // marginRight: '-50%',
            // padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    useEffect(() => {
        dispatch(getTracks(false, 0, 100, 'PUBLISHED'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setShowTips(profile?.showLearnTip);
    }, [profile]);

    const doNotShowhandler = () => {
        localStorage.setItem(USER_PROFILE, JSON.stringify({ ...profile, showLearnTip: false }));
        setShowTips(false);
    };

    // useEffect(() => {
    //     if (studentData?.lastName) {
    //         history.push(`/${profile.userId}/dashBoard/learn`);
    //     } else {
    //         history.push(`/onboard`);
    //     }
    // }, [studentData]);

    return (
        <div>
            <div
                className={`hidden lg:flex mx-10 md:mx-5 md:ml-14 lg:ml-72 lg:pl-3.5 lg:pt-2 md:mt-14 lg:mt-10 `}
            >
                <div className={`w-full`}>
                    {showTips ? (
                        <div className="">
                            <div className="bg-purple-85  flex justify-between">
                                <div className="flex items-center  py-8 pl-8">
                                    <div className="space-y-5 ">
                                        <div>
                                            <div className=" text-3xl font-manrope font-bold ">
                                                New to BRIDGE?
                                            </div>
                                            <div className="mt-3 text-2xl font-manrope font-bold ">
                                                Let&apos;s get started on your learning journey with
                                                our Fundamentals Career Track!
                                            </div>
                                        </div>

                                        <div className="trackTipSection text-justify">
                                            Learn the basic skills required by the tech industry by
                                            taking these fundamental challenges. Once completed, you
                                            can take challenges from specific career tracks of your
                                            choice.
                                        </div>
                                        <div className="flex space-x-2">
                                            <div
                                                onClick={() => {
                                                    history.push({
                                                        pathname: `/dashBoard/learn/Challenges`,
                                                        state: {
                                                            tracks: trackData.filter(
                                                                track =>
                                                                    track.trackName ===
                                                                        'Fundamental' ||
                                                                    track.trackName ===
                                                                        'Fundamentals'
                                                            )[0],
                                                        },
                                                    });
                                                }}
                                                className="border-1 rounded bg-purple-950 text-white py-3 px-8 flex justify-center items-center font-semibold text-sm cursor-pointer"
                                            >
                                                Fundamental Challenges
                                            </div>
                                            <div
                                                onClick={doNotShowhandler}
                                                className="border-2 rounded border-purple-950 text-purple-950  py-3 px-5 flex justify-center items-center font-semibold text-sm cursor-pointer whitespace-nowrap"
                                            >
                                                Do not show anymore
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex pl-20">
                                    <div className="">
                                        <img
                                            className="hidden xl:block h-full"
                                            src={`/assets/local/Learn_MyProgress/Start_working_track1.jpg`}
                                            alt="logo"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <div className={`${showTips ? 'mt-8' : ''}`}>
                        <RecommandedTracks />
                    </div>

                    <div className="bg-purple-85 mt-12 rounded py-8">
                        <div className="flex flex-col  px-8">
                            <div className="space-y-3">
                                <div className=" text-xl font-bold">Explore All Tracks</div>
                                <div className="text-justify ">
                                    Select a track from the list below.
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-center px-8 mt-3">
                            {isAllTrackLoading ? (
                                <div>
                                    <Loader />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        flexGrow: 1,
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        flexWrap: 'wrap',
                                        gap: '16px',
                                        marginRight: '1em',
                                        flexDirection: 'row',
                                        alignContent: 'flex-start',
                                    }}
                                >
                                    {trackData?.map(track => (
                                        <div
                                            key={track.trackId}
                                            onClick={() => {
                                                history.push({
                                                    pathname: `/dashBoard/learn/Challenges`,
                                                    state: { tracks: track },
                                                });
                                            }}
                                            style={{ borderWidth: 1 }}
                                            className={`bg-white  border-purple-950 text-purple-950 rounded p-3 flex justify-center items-center text-xs cursor-pointer whitespace-nowrap `}
                                        >
                                            {track?.trackName}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        style={{
                            background: 'linear-gradient(93.16deg, #6E41E2 1.57%, #0C247A 98.41%)',
                        }}
                        className="flex justify-between rounded mt-8  mb-20 text-white overflow-hidden"
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            className="py-8 pl-4 pr-4 xl:pr-0 lg:pl-8 4xl:pl-28"
                        >
                            <div className="space-y-5 ">
                                <div className=" text-2xl-2 font-bold font-manrope">
                                    Now lets work on building your career competencies
                                </div>
                                <div className="text-base trackTipSection text-justify">
                                    The Technology and IT Enabled Service Industry offers a wide
                                    variety of career options. You may select a track highlighted
                                    above to complete challenges specialised for a career. Companies
                                    in the industry usually select potential candidates from the top
                                    students in these career paths. You should ideally complete ALL
                                    challenges in your chosen career path, so you will be recognised
                                    on the leaderboard, so strive for the top positions in order to
                                    be selected for internship interviews by companies. You can
                                    complete any number of career paths that interest you.
                                </div>
                                <div className="flex space-x-2">
                                    <div
                                        onClick={() => {
                                            history.push(`/dashBoard/help`);
                                        }}
                                        className="border-2 rounded  text-white w-36 h-12 flex justify-center items-center font-bold cursor-pointer"
                                    >
                                        Learn more
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img
                                className="hidden xl:block h-full"
                                src={`/assets/local/Learn_MyProgress/Start_working_track2.jpg`}
                                width="487px"
                                alt="logo"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`flex flex-col lg:hidden `}>
                <div className={`w-full`}>
                    {showTips ? (
                        <div className="">
                            <div className="bg-purple-85 w-full flex flex-col justify-between">
                                <div className="relative">
                                    <DarkenedImage
                                        className="h-full object-contain filter"
                                        src={`/assets/local/Learn_MyProgress/Start_working_track1.jpg`}
                                        alt="logo"
                                    />
                                    <div
                                        className="absolute top-5 right-6"
                                        onClick={doNotShowhandler}
                                    >
                                        {' '}
                                        <CloseIcon size={16} color={'#FFFFFF'} thickness={2} />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-y-4 px-5 py-6">
                                    <div className="space-y-4">
                                        <div className="text-xl font-manrope font-bold">
                                            New to BRIDGE?
                                        </div>
                                        <div className="text-lg font-manrope font-bold">
                                            Let&apos;s get started on your learning journey with our
                                            Fundamental Career Track!
                                        </div>
                                    </div>

                                    <div className="text-justify text-xs w-full">
                                        Learn the basic skills required by the tech industry by
                                        taking these fundamental challenges. Once completed, you can
                                        take challenges from specific career tracks of your choice.
                                    </div>
                                    <div className="flex flex-col gap-y-4">
                                        <div
                                            onClick={() => {
                                                history.push({
                                                    pathname: `/dashBoard/learn/Challenges`,
                                                    state: {
                                                        tracks: trackData.filter(
                                                            track =>
                                                                track.trackName === 'Fundamental' ||
                                                                track.trackName === 'Fundamentals'
                                                        )[0],
                                                    },
                                                });
                                            }}
                                            className="rounded bg-purple-950 text-white h-10 w-48 flex justify-center items-center font-semibold text-sm cursor-pointer"
                                        >
                                            Fundamental Challenges
                                        </div>
                                        <div
                                            onClick={doNotShowhandler}
                                            className="border-2 rounded border-purple-950 text-purple-950 h-10 w-48 flex justify-center items-center  cursor-pointer whitespace-nowrap"
                                        >
                                            Do not show anymore
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <div className={`${showTips ? 'mt-8' : ''} ml-4`}>
                        <RecommandedTracks />
                    </div>

                    <div className="bg-purple-85 mt-12 flex justify-between rounded w-full">
                        <div className="flex items-center py-8 px-8 w-full">
                            <div className="space-y-3">
                                <div className=" text-xl font-bold">Explore All Tracks</div>
                                <div className="text-justify ">
                                    Select a track from the list below.
                                </div>
                                {isAllTrackLoading ? (
                                    <div className="py-10 ml-4">
                                        <Loader />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            flexWrap: 'wrap',
                                            gap: '16px',
                                            marginRight: '1em',
                                            flexDirection: 'row',
                                            alignContent: 'flex-start',
                                        }}
                                    >
                                        {trackData?.map(track => (
                                            <div
                                                key={track.trackId}
                                                onClick={() => {
                                                    history.push({
                                                        pathname: `/dashBoard/learn/Challenges`,
                                                        state: { tracks: track },
                                                    });
                                                }}
                                                style={{ borderWidth: 1 }}
                                                className={`bg-white  border-purple-950 text-purple-950 rounded p-3 flex justify-center items-center text-xs cursor-pointer whitespace-nowrap `}
                                            >
                                                {track?.trackName}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            background: 'linear-gradient(93.16deg, #6E41E2 1.57%, #0C247A 98.41%)',
                        }}
                        className="flex justify-between rounded mt-8  mb-20 text-white overflow-hidden w-full"
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            className="py-8 pl-4 pr-4 xl:pr-0 lg:pl-8 4xl:pl-28 w-full"
                        >
                            <div className="space-y-5 ">
                                <div className="text-lg lg:text-2xl-2 font-bold font-manrope">
                                    Now lets work on building your career competencies
                                </div>
                                <div className="text-xs lg:text-base trackTipSection text-left lg:text-justify">
                                    The Technology and IT Enabled Service Industry offers a wide
                                    variety of career options. You may select a track highlighted
                                    above to complete challenges specialised for a career. Companies
                                    in the industry usually select potential candidates from the top
                                    students in these career paths. You should ideally complete ALL
                                    challenges in your chosen career path, so you will be recognised
                                    on the leaderboard, so strive for the top positions in order to
                                    be selected for internship interviews by companies. You can
                                    complete any number of career paths that interest you.
                                </div>
                                <div className="flex space-x-2">
                                    <div
                                        onClick={() => {
                                            history.push(`/dashBoard/help`);
                                        }}
                                        className="border-2 rounded  text-white w-36 h-12 flex justify-center items-center font-bold cursor-pointer"
                                    >
                                        Learn more
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img
                                className="hidden lg:block h-full"
                                src={`/assets/local/Learn_MyProgress/Start_working_track2.jpg`}
                                width="487px"
                                alt="logo"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Learn;
