import React, { ReactElement } from 'react';

interface Props {
    width?: string;
    height?: string;
}

export default function EventDuration({ width, height }: Props): ReactElement {
    return (
        <svg
            width={width || '16'}
            height={height || '28'}
            viewBox="0 0 16 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 0.666016H16V8.66602L10.6667 13.9993L16 19.3327V27.3327H0V19.3327L5.33333 13.9993L0 8.66602V0.666016ZM13.3333 19.9993L8 14.666L2.66667 19.9993V24.666H13.3333V19.9993ZM8 13.3327L13.3333 7.99935V3.33268H2.66667V7.99935L8 13.3327ZM5.33333 5.99935H10.6667V6.99935L8 9.66602L5.33333 6.99935V5.99935Z"
                fill="#4C2EC2"
            />
        </svg>
    );
}
