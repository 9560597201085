export function StringToBytesArray(binaryString: string) {
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    const decoder = new TextDecoder('utf8');
    const b64encoded = btoa(decoder.decode(bytes));
    return b64encoded;
}

export function decodeValue(encodedString: string) {
    const decodedString = atob(encodedString);
    return decodedString;
}
