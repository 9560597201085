import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { logout } from '../authAdmin/actions';
import { PrivacyPolicyDataType } from './reducers';

const PREFIX = '@USERS';

export const GET_USERS = PREFIX + 'GET_USERS';
export const GET_USERS_SUCCESS = PREFIX + 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = PREFIX + 'GET_USERS_FAILED';
export const GET_USER_BY_ID = PREFIX + 'GET_USER_BY_ID';
export const GET_USER_BY_ID_SUCCESS = PREFIX + 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_FAILED = PREFIX + 'GET_USER_BY_ID_FAILED';
export const ADD_USER = PREFIX + 'ADD_USER';
export const ADD_USER_SUCCESS = PREFIX + 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = PREFIX + 'ADD_USER_FAILED';
export const EDIT_USER = PREFIX + 'EDIT_USER';
export const EDIT_USER_SUCCESS = PREFIX + 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILED = PREFIX + 'EDIT_USER_FAILED';
export const DELETE_USER = PREFIX + 'DELETE_USER';
export const DELETE_USER_SUCCESS = PREFIX + 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = PREFIX + 'DELETE_USER_FAILED';
export const USERS_BULK_UPDATE = PREFIX + 'USERS_BULK_UPDATE';
export const USERS_BULK_UPDATE_SUCCESS = PREFIX + 'USERS_BULK_UPDATE_SUCCESS';
export const USERS_BULK_UPDATE_FAILED = PREFIX + 'USERS_BULK_UPDATE_FAILED';
export const GET_BRIDGE_INVITATION_CODES = PREFIX + 'GET_BRIDGE_INVITATION_CODES';
export const GET_BRIDGE_INVITATION_CODES_SUCCESS = PREFIX + 'GET_BRIDGE_INVITATION_CODES_SUCCESS';
export const GET_BRIDGE_INVITATION_CODES_FAILED = PREFIX + 'GET_BRIDGE_INVITATION_CODES_FAILED';
export const CLEAR_USERS_MESSAGES = PREFIX + 'CLEAR_USERS_MESSAGES';
export const GET_PRIVACY_POLICY = PREFIX + 'GET_PRIVACY_POLICY';
export const GET_PRIVACY_POLICY_SUCCESS = PREFIX + 'GET_PRIVACY_POLICY_SUCCESS';
export const GET_PRIVACY_POLICY_FAILED = PREFIX + 'GET_PRIVACY_POLICY_FAILED';
export const CREATE_PRIVACY_POLICY = PREFIX + 'CREATE_PRIVACY_POLICY';
export const CREATE_PRIVACY_POLICY_SUCCESS = PREFIX + 'CREATE_PRIVACY_POLICY_SUCCESS';
export const CREATE_PRIVACY_POLICY_FAILED = PREFIX + 'CREATE_PRIVACY_POLICY_FAILED';
export const EDIT_PRIVACY_POLICY = PREFIX + 'EDIT_PRIVACY_POLICY';
export const EDIT_PRIVACY_POLICY_SUCCESS = PREFIX + 'EDIT_PRIVACY_POLICY_SUCCESS';
export const EDIT_PRIVACY_POLICY_FAILED = PREFIX + 'EDIT_PRIVACY_POLICY_FAILED';

export const getUsers =
    (page?: number, size?: number, status?: string, tag?: any[], searchTerm?: string) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_USERS });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/users/admins?page=${page || 0}&size=${size || 10}${
                    status ? `&status=${status}` : ''
                }${searchTerm ? `&searchText=${searchTerm}` : ''}`,
                config
            );
            dispatch({
                type: GET_USERS_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_USERS_FAILED, payload: getErrorMessage(error) });
        }
    };
export const getUserById = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_USER_BY_ID });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/users/admin/${id}`, config);
        dispatch({ type: GET_USER_BY_ID_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch(logout());
        dispatch({ type: GET_USER_BY_ID_FAILED, payload: getErrorMessage(error) });
    }
};

export const addUser = (user: any) => async (dispatch: any) => {
    try {
        dispatch({ type: ADD_USER });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/users/admin/create`, user, config);
        if (res?.data?.status) {
            if (res?.data?.errorCode === 222) {
                await dispatch(getUsers());
                await dispatch({
                    type: ADD_USER_SUCCESS,
                    payload: res.data.responseDto,
                });
            }
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 222) {
                dispatch({
                    type: ADD_USER_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: ADD_USER_FAILED, payload: getErrorMessage(error) });
    }
};
export const editUser = (id: string, user: any, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: EDIT_USER });
        const config = await axiosConfig(true);
        const res = await axios.put(`${API_URL}/users/admin/${id}`, user, config);
        await dispatch(getUsers(0, 10, status));

        if (res?.data?.status) {
            dispatch({ type: EDIT_USER_SUCCESS, payload: res.data.responseDto });
        }

        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 214) {
                dispatch({
                    type: EDIT_USER_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: EDIT_USER_FAILED, payload: getErrorMessage(error) });
    }
};
export const deleteUser = (id: string, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: DELETE_USER });
        const config = await axiosConfig(true);
        const res = await axios.delete(`${API_URL}/users/admin/${id}`, config);
        await dispatch(getUsers(0, 10, status));
        dispatch({ type: DELETE_USER_SUCCESS, payload: res.data.responseDto });
    } catch (error) {
        dispatch({ type: DELETE_USER_FAILED, payload: getErrorMessage(error) });
    }
};
export const bulkUpdateUsers = (users, tabStatus) => async (dispatch: any) => {
    try {
        dispatch({ type: USERS_BULK_UPDATE });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/users/admin/bulk`, users, config);
        await dispatch(getUsers(0, 10, tabStatus));
        if (res?.data?.status) {
            if (res?.data?.errorCode === 202)
                dispatch({ type: USERS_BULK_UPDATE_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 202) {
                dispatch({
                    type: USERS_BULK_UPDATE_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: USERS_BULK_UPDATE_FAILED, payload: getErrorMessage(error) });
    }
};
export const getUsersBridgeInvitationCodes = () => async (dispatch: any) => {
    try {
        dispatch({ type: GET_BRIDGE_INVITATION_CODES });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/users/bridge-invitation-codes`, config);
        dispatch({
            type: GET_BRIDGE_INVITATION_CODES_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_BRIDGE_INVITATION_CODES_FAILED, payload: getErrorMessage(error) });
    }
};
export const clearUsersMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_USERS_MESSAGES });
};
export const getPrivacyPolicy = () => async (dispatch: any) => {
    try {
        dispatch({ type: GET_PRIVACY_POLICY });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/policy`, config);
        dispatch({ type: GET_PRIVACY_POLICY_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_PRIVACY_POLICY_FAILED, payload: getErrorMessage(error) });
    }
};
export const createPrivacyPolicy = (data: PrivacyPolicyDataType) => async (dispatch: any) => {
    try {
        dispatch({ type: CREATE_PRIVACY_POLICY });
        const config = await axiosConfig(true);
        const response = await axios.post(`${API_URL}/policy`, data, config);
        dispatch({ type: CREATE_PRIVACY_POLICY_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: CREATE_PRIVACY_POLICY_FAILED, payload: getErrorMessage(error) });
    }
};
export const editPrivacyPolicy =
    (id: string, data: PrivacyPolicyDataType) => async (dispatch: any) => {
        try {
            dispatch({ type: EDIT_PRIVACY_POLICY });
            const config = await axiosConfig(true);
            const res = await axios.put(`${API_URL}/policy/${id}`, data, config);
            dispatch({ type: EDIT_PRIVACY_POLICY_SUCCESS, payload: res.data.responseDto });
        } catch (error) {
            dispatch({ type: EDIT_PRIVACY_POLICY_FAILED, payload: getErrorMessage(error) });
        }
    };
