import { ChallengesType } from '../challenges/reducers';
import {
    ADD_TRACK,
    ADD_TRACK_FAILED,
    ADD_TRACK_SUCCESS,
    BULK_UPDATE,
    BULK_UPDATE_FAILED,
    BULK_UPDATE_SUCCESS,
    CLEAR_TRACK_MESSAGES,
    DELETE_TRACK,
    DELETE_TRACK_FAILED,
    DELETE_TRACK_SUCCESS,
    EDIT_TRACK,
    EDIT_TRACK_FAILED,
    EDIT_TRACK_SUCCESS,
    GET_TRACKS,
    GET_TRACKS_BY_ID,
    GET_TRACKS_BY_ID_FAILED,
    GET_TRACKS_BY_ID_SUCCESS,
    GET_TRACKS_BY_CHALLENGE_STATUS,
    GET_TRACKS_BY_CHALLENGE_STATUS_SUCCESS,
    GET_TRACKS_BY_CHALLENGE_STATUS_FAILED,
    GET_TRACKS_FAILED,
    GET_TRACKS_SUCCESS,
    GET_RECOMMANDED_TRACKS,
    GET_RECOMMANDED_TRACKS_FAILED,
    GET_RECOMMANDED_TRACKS_SUCCESS,
} from './actions';

export interface CountType {
    PUBLISHED: number;
    UNPUBLISHED: number;
    DELETED: number;
    ALL: number;
}

export interface TracksType {
    id: number;
    trackId: string;
    trackName: string;
    trackCreatedBy: string;
    trackCreatedDate: string;
    trackLastUpdatedBy: string;
    trackLastUpdatedDate: string;
    trackDescription: string;
    trackStatus: string;
    challenges: ChallengesType[];
    imageLink: string;
    users: any;
    acTemplate: string;
    feedback: string;
    certificate: any;
    tags?: Tags[];
    program?: any;
    earnedMarks?: any;
    totalMarks?: any;
    challengeProgress?: any;
    noOfChallenges?: string;
    averageScore?: any;
    latestSubmissionDate?: any;
    totalStudent?: number;
}

export interface TracksByChallengeStatusType {
    studentId: string | number;
    completed: TracksType[];
    notStarted: TracksType[];
    onGoing: TracksType[];
}

export interface Tags {
    id: number;
    systemTag: boolean;
    tagCategory: string;
    tagCreatedBy: string;
    tagCreatedDate: string;
    tagLastUpdatedBy: string;
    tagLastUpdatedDate: string;
    tagName: string;
}

export interface TracksInitialState {
    trackData: TracksType[];
    trackByChallengeStatusData: TracksByChallengeStatusType;
    trackByChallengeStatusLoading: boolean;
    trackByChallengeStatusError: string | null;
    recommandedTrackData: TracksType[];
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    isAllTrackLoading: boolean;
    trackError: string | null;
    createTrackError: string | null;
    trackCreateSuccessMessage: string | null;
    trackUpdateSuccessMessage: string | null;
    trackDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editTrackError: string | null;
    isDeleteLoading: boolean;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    trackBulkSuccessMessage: string | null;
    deleteTrackError: string | null;
    trackByIdLoading: boolean;
    trackByIdError: string | null;
    track: TracksType;
}

const initialState: TracksInitialState = {
    trackData: [],
    trackByChallengeStatusData: null,
    trackByChallengeStatusLoading: false,
    trackByChallengeStatusError: null,
    recommandedTrackData: [],
    pageCount: null,
    isLoading: false,
    isAllTrackLoading: false,
    trackError: null,
    count: null,
    createTrackError: null,
    trackCreateSuccessMessage: null,
    trackUpdateSuccessMessage: null,
    trackDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editTrackError: null,
    isDeleteLoading: false,
    isBulkUpdateLoading: false,
    bulkError: null,
    trackBulkSuccessMessage: null,
    deleteTrackError: null,
    trackByIdLoading: false,
    trackByIdError: null,
    track: null,
};

const tracksReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_TRACKS:
            return { ...state, isAllTrackLoading: true, trackError: null };
        case GET_TRACKS_SUCCESS:
            return {
                ...state,
                isAllTrackLoading: false,
                count: action.payload.counts,
                trackData: action.payload.items,
                pageCount: action.payload.totalPages,
            };

        case GET_TRACKS_FAILED:
            return { ...state, isAllTrackLoading: false, trackError: action.payload };
        case GET_TRACKS_BY_CHALLENGE_STATUS:
            return {
                ...state,
                trackByChallengeStatusLoading: true,
                trackByChallengeStatusError: null,
            };
        case GET_TRACKS_BY_CHALLENGE_STATUS_SUCCESS:
            return {
                ...state,
                trackByChallengeStatusLoading: false,
                trackByChallengeStatusData: action.payload,
            };
        case GET_TRACKS_BY_CHALLENGE_STATUS_FAILED:
            return {
                ...state,
                trackByChallengeStatusLoading: false,
                trackByChallengeStatusError: action.payload,
            };
        case GET_TRACKS_BY_ID:
            return { ...state, trackByIdLoading: true, trackByIdError: null };
        case GET_TRACKS_BY_ID_SUCCESS:
            return {
                ...state,
                trackByIdLoading: false,
                track: action.payload,
            };
        case GET_TRACKS_BY_ID_FAILED:
            return { ...state, trackByIdLoading: false, trackByIdError: action.payload };

        case GET_RECOMMANDED_TRACKS:
            return { ...state, isLoading: true, trackError: null };
        case GET_RECOMMANDED_TRACKS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                // count: action.payload.counts,
                recommandedTrackData: action.payload,
                // pageCount: action.payload.totalPages,
            };
        case GET_RECOMMANDED_TRACKS_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };

        case ADD_TRACK:
            return { ...state, isCreateLoading: true, createTrackError: null };
        case ADD_TRACK_SUCCESS:
            return { ...state, isCreateLoading: false, trackCreateSuccessMessage: action.payload };
        case ADD_TRACK_FAILED:
            return { ...state, isCreateLoading: false, createTrackError: action.payload };
        case EDIT_TRACK:
            return { ...state, isEditLoading: true, editTrackError: null };
        case EDIT_TRACK_SUCCESS:
            return { ...state, isEditLoading: false, trackUpdateSuccessMessage: action.payload };
        case EDIT_TRACK_FAILED:
            return { ...state, isEditLoading: false, editTrackError: action.payload };
        case DELETE_TRACK:
            return { ...state, isDeleteLoading: true, deleteTrackError: null };
        case DELETE_TRACK_SUCCESS:
            return { ...state, isDeleteLoading: false, trackDeleteSuccessMessage: action.payload };
        case DELETE_TRACK_FAILED:
            return { ...state, isDeleteLoading: false, deleteTrackError: action.payload };
        case BULK_UPDATE:
            return { ...state, isBulkUpdateLoading: true, bulkError: null };
        case BULK_UPDATE_SUCCESS:
            return {
                ...state,
                isBulkUpdateLoading: false,
                trackBulkSuccessMessage: action.payload,
            };
        case BULK_UPDATE_FAILED:
            return { ...state, isBulkUpdateLoading: false, bulkError: action.payload };
        case CLEAR_TRACK_MESSAGES:
            return {
                ...state,
                trackCreateSuccessMessage: null,
                createTrackError: null,
                editTrackError: null,
                trackUpdateSuccessMessage: null,
                deleteTrackError: null,
                trackDeleteSuccessMessage: null,
                bulkError: null,
                trackBulkSuccessMessage: null,
            };
        default:
            return state;
    }
};

export default tracksReducer;
