import axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { USER_PROFILE } from '../../utils/storage';

const PREFIX = '@COMPANIES';

export const GET_COMPANIES = PREFIX + 'GET_COMPANIES';
export const GET_COMPANIES_SUCCESS = PREFIX + 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILED = PREFIX + 'GET_COMPANIES_FAILED';
export const GET_COMPANY_PUBLIC = PREFIX + 'GET_COMPANY_PUBLIC';
export const GET_COMPANY_PUBLIC_SUCCESS = PREFIX + 'GET_COMPANY_PUBLIC_SUCCESS';
export const GET_COMPANY_PUBLIC_FAILED = PREFIX + 'GET_COMPANY_PUBLIC_FAILED';
export const GET_COMPANY_BY_ID = PREFIX + 'GET_COMPANY_BY_ID';
export const GET_COMPANY_BY_ID_SUCCESS = PREFIX + 'GET_COMPANY_BY_ID_SUCCESS';
export const GET_COMPANY_BY_ID_FAILED = PREFIX + 'GET_COMPANY_BY_ID_FAILED';
export const GET_PUBLIC_COMPANY_BY_ID = PREFIX + 'GET_PUBLIC_COMPANY_BY_ID';
export const GET_PUBLIC_COMPANY_BY_ID_SUCCESS = PREFIX + 'GET_PUBLIC_COMPANY_BY_ID_SUCCESS';
export const GET_PUBLIC_COMPANY_BY_ID_FAILED = PREFIX + 'GET_PUBLIC_COMPANY_BY_ID_FAILED';
export const ADD_COMPANY = PREFIX + 'ADD_COMPANY';
export const ADD_COMPANY_SUCCESS = PREFIX + 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAILED = PREFIX + 'ADD_COMPANY_FAILED';
export const EDIT_COMPANY = PREFIX + 'EDIT_COMPANY';
export const EDIT_COMPANY_SUCCESS = PREFIX + 'EDIT_COMPANY_SUCCESS';
export const EDIT_COMPANY_FAILED = PREFIX + 'EDIT_COMPANY_FAILED';
export const DELETE_COMPANY = PREFIX + 'DELETE_COMPANY';
export const DELETE_COMPANY_SUCCESS = PREFIX + 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILED = PREFIX + 'DELETE_COMPANY_FAILED';
export const COMPANY_BULK_UPDATE = PREFIX + 'COMPANY_BULK_UPDATE';
export const COMPANY_BULK_UPDATE_SUCCESS = PREFIX + 'COMPANY_BULK_UPDATE_SUCCESS';
export const COMPANY_BULK_UPDATE_FAILED = PREFIX + 'COMPANY_BULK_UPDATE_FAILED';
export const CLEAR_COMPANIES_MESSAGES = PREFIX + 'CLEAR_COMPANIES_MESSAGES';

export const getCompanies =
    (
        page?: number,
        size?: number,
        status?: string,
        tags?: string,
        searchTerm?: string,
        type?: string,
        joinedDateFrom?: string,
        joinedDateTo?: string
    ) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_COMPANIES });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/organizations/companies?page=${page || 0}&size=${size || 10}${
                    status ? `&status=${status}` : ''
                }${searchTerm ? `&searchText=${searchTerm}` : ''}${tags ? `&tags=${tags}` : ''}${
                    type ? `&type=${type}` : ''
                }${
                    joinedDateFrom
                        ? `&joinedDateFrom=${moment(joinedDateFrom).format('DD-MM-yyyy')}`
                        : ''
                }${
                    joinedDateTo ? `&joinedDateTo=${moment(joinedDateTo).format('DD-MM-yyyy')}` : ''
                }`,
                config
            );
            dispatch({
                type: GET_COMPANIES_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_COMPANIES_FAILED, payload: getErrorMessage(error) });
        }
    };

export const getCompanyPublicData =
    (page?: number, size?: number, status?: string) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_COMPANY_PUBLIC });
            const response = await axios.get(
                `${API_URL}/open/companies?page=${page || 0}&size=${size || 100}${
                    status ? `&status=${status}` : ''
                }`
            );
            dispatch({
                type: GET_COMPANY_PUBLIC_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_COMPANY_PUBLIC_FAILED, payload: getErrorMessage(error) });
        }
    };
export const getPublicCompanyById = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_PUBLIC_COMPANY_BY_ID });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/open/company/${id}`, config);
        dispatch({ type: GET_PUBLIC_COMPANY_BY_ID_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_PUBLIC_COMPANY_BY_ID_FAILED, payload: getErrorMessage(error) });
    }
};
export const getCompanyById = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_COMPANY_BY_ID });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/organizations/companies/${id}`, config);
        dispatch({ type: GET_COMPANY_BY_ID_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_COMPANY_BY_ID_FAILED, payload: getErrorMessage(error) });
    }
};

export const addCompany = (Company: any) => async (dispatch: any) => {
    try {
        dispatch({ type: ADD_COMPANY });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/organizations/companies`, Company, config);
        if (res?.data?.status) {
            if (res?.data?.errorCode === 220) {
                await dispatch(getCompanies());
                await dispatch({
                    type: ADD_COMPANY_SUCCESS,
                    payload: res.data.responseDto,
                });
            }
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 217) {
                dispatch({
                    type: ADD_COMPANY_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: ADD_COMPANY_FAILED, payload: getErrorMessage(error) });
    }
};
export const editCompany = (id: string, Company: any, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: EDIT_COMPANY });
        const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
        const isCOMPAdmin = ['COMPANY_ADMIN'].includes(profile.roles[0]);
        const config = await axiosConfig(true);
        const res = await axios.put(`${API_URL}/organizations/companies/${id}`, Company, config);
        dispatch({ type: EDIT_COMPANY_SUCCESS, payload: res.data.responseDto });
        if (res?.data?.status && !isCOMPAdmin) {
            await dispatch(getCompanies(0, 10, status));
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 212) {
                dispatch({
                    type: EDIT_COMPANY_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: EDIT_COMPANY_FAILED, payload: getErrorMessage(error) });
    }
};
export const deleteCompany = (id: string, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: DELETE_COMPANY });
        const config = await axiosConfig(true);
        const res = await axios.delete(`${API_URL}/organizations/companies/${id}`, config);
        await dispatch(getCompanies(0, 10, status));
        dispatch({ type: DELETE_COMPANY_SUCCESS, payload: res.data.responseDto });
    } catch (error) {
        dispatch({ type: DELETE_COMPANY_FAILED, payload: getErrorMessage(error) });
    }
};
export const bulkUpdateCompany = (Companies, tabStatus) => async (dispatch: any) => {
    try {
        dispatch({ type: COMPANY_BULK_UPDATE });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/organizations/companies/bulk`, Companies, config);
        await dispatch(getCompanies(0, 10, tabStatus));
        if (res?.data?.status) {
            if (res?.data?.errorCode === 202)
                dispatch({ type: COMPANY_BULK_UPDATE_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 202) {
                dispatch({
                    type: COMPANY_BULK_UPDATE_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: COMPANY_BULK_UPDATE_FAILED, payload: getErrorMessage(error) });
    }
};
export const clearCompaniesMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_COMPANIES_MESSAGES });
};
