import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Camera from '../../../assets/svg/Camera';
import { TracksType } from '../../../redux/tracks/reducers';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearTrackMessages, editTrack, getTags, getTrackById } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { moduleNames, s3Config, s3UrlPrefix } from '../../../constants';
import S3 from 'react-aws-s3';
import Loader from '../../../assets/svg/Loader';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import TrackSuccessfullModal from '../../modals/TrackSuccessfullModal';
import * as HiIcons from 'react-icons/hi';
import * as FaIcons from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CropModal from '../../modals/cropModal';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroups from '../../TagGroups';

interface CustomState {
    track: TracksType;
}
interface paramsType {
    id: string;
}

export default function AddNewTrack() {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<paramsType>();
    const trackState = location.state as CustomState;
    const formRef = useRef<any>();
    const editTrackError = useSelector((state: RootState) => state.tracks.editTrackError);
    const track = useSelector((state: RootState) => state.tracks.track);
    const trackUpdateSuccess = useSelector(
        (state: RootState) => state.tracks.trackUpdateSuccessMessage
    );
    const [imageUrl, setImageUrl] = useState(`${s3UrlPrefix + trackState?.track?.imageLink}`);
    const [isUploading, setIsUploading] = useState(false);
    const [imageLocation, setImageLocation] = useState(trackState?.track?.imageLink);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [trackUpdateSuccessVisible, setTrackUpdateSuccessVisible] = useState(false);
    const [trackErrorVisible, setTrackErrorVisible] = useState(false);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [tags, setTags] = useState(trackState?.track?.tags);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [challengesList, setChallengesList] = useState(trackState?.track?.challenges);
    const [showDropdown, setShowDropdown] = useState(false);
    const [openCrop, setOpenCrop] = useState(false);
    const [tagsHelpText, setTagsHelpText] = useState<boolean>(false);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const onDragEnd = result => {
        if (!result.destination) return;
        if (result.destination.index === result.source.index) return;
        const orderedList: any = reorder(
            challengesList,
            result.source.index,
            result.destination.index
        );
        setChallengesList(orderedList);
    };

    const serchTagNameOrId = e => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        dispatch(getTrackById(params.id));
    }, [dispatch, params.id]);

    useEffect(() => {
        if (showDropdown) {
            dispatch(getTags(true, moduleNames.Tracks, searchTerm));
        }
    }, [dispatch, searchTerm, showDropdown]);

    useEffect(() => {
        setTags(trackState?.track?.tags);
    }, [trackState]);

    useEffect(() => {
        setSelectedTags(categorizeTags(tags));
    }, [tags]);

    const handleClearMessages = useCallback(() => {
        if (trackUpdateSuccess) {
            setTrackUpdateSuccessVisible(true);
        }
        if (editTrackError) {
            setTrackErrorVisible(true);
        }
    }, [dispatch, trackUpdateSuccess, editTrackError, history]);
    const handleClose = () => {
        dispatch(clearTrackMessages());
        if (!editTrackError) {
            history.push('/admin/tracks/');
        }
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    const handleImage = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setImageUrl(URL.createObjectURL(file));
            setOpenCrop(true);
        }
    };
    const uploadImageToS3 = async croppedImg => {
        setIsUploading(true);
        const imageName = Date.now().toString();
        s3Config.dirName = `tracks/${imageName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(croppedImg, imageName)
            .then(data => {
                setImageLocation(data.key);
                setIsUploading(false);
            })
            .catch(err => {
                console.error(err);
                setIsUploading(false);
            });
    };
    const handleCloseCropModal = () => {
        setOpenCrop(false);
        setImageUrl(`${s3UrlPrefix + trackState?.track?.imageLink}`);
    };
    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    <div className="relative">
                        <Formik
                            enableReinitialize={true}
                            innerRef={formRef}
                            initialValues={{
                                trackName: track?.trackName,
                                trackStatus: track?.trackStatus,
                                trackDescription: track?.trackDescription
                                    ? track.trackDescription
                                    : '',
                                imageLink: imageLocation,
                                trackCreatedBy: '',
                                challenges: track?.challenges,
                            }}
                            validationSchema={Yup.object({
                                trackName: Yup.string()
                                    .max(100, 'Name should be less than 100 characters')
                                    .required('Name is required'),
                                trackDescription: Yup.string()
                                    .max(500, 'Description should be less than 500 characters')
                                    .required('Description is required'),
                                trackStatus: Yup.string().required('Status is required'),
                                imageLink: Yup.string()
                                    .nullable()
                                    .required('Track image is required'),
                            })}
                            onSubmit={async values => {
                                dispatch(
                                    editTrack(trackState.track.id, {
                                        trackName: values.trackName.trim(),
                                        trackCreatedBy: '',
                                        trackDescription: values.trackDescription.trim(),
                                        imageLink: imageLocation,
                                        trackStatus: values.trackStatus,
                                        challenges: challengesList,
                                        tags: tags,
                                    })
                                );
                            }}
                        >
                            {({
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                values,
                                errors,
                                touched,
                            }) => (
                                <>
                                    <div className="flex justify-between items-center mb-4 md:mb-6">
                                        <p className="text-xl font-semibold text-left">
                                            Edit Track
                                        </p>
                                        <div className="flex w-1/2 md:w-1/4 lg:pl-8 h-14 md:h-16">
                                            <button
                                                className="md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => {
                                                    dispatch(clearTrackMessages());
                                                    history.push('/admin/tracks/');
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => handleSubmit()}
                                            >
                                                {'Update'}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="border-2 border-gray-300 px-6  pt-2 mb-6">
                                        <div className="grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8">
                                            <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                                <div className="text-lg font-medium mb-6">
                                                    Track Details
                                                </div>
                                                <div className="mb-6 w-full md:mb-0 flex">
                                                    <label
                                                        htmlFor="title"
                                                        className={`flex items-start w-1/3 text-gray-700 block font-medium leading-149 text-sm md:text-base `}
                                                    >
                                                        Track ID
                                                    </label>
                                                    <input
                                                        className={`rounded-sm focus:outline-none w-3/4 md:w-1/2 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                        id="id"
                                                        type="text"
                                                        value={trackState?.track?.trackId}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="title"
                                                        className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Track Name
                                                        <div className="text-red-600">*</div>
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded-sm flex items-center focus:outline-none w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                errors.trackName &&
                                                                touched.trackName
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="name"
                                                            type="text"
                                                            value={values.trackName}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'trackName',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                        {errors.trackName && touched.trackName ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.trackName}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="title"
                                                        className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Track Status
                                                    </label>
                                                    <div className="flex text-sm md:text-base items-center w-full">
                                                        <input
                                                            className="mr-1"
                                                            type="radio"
                                                            value="PUBLISHED"
                                                            id="publish"
                                                            name="status"
                                                            defaultChecked={
                                                                trackState?.track?.trackStatus ===
                                                                'PUBLISHED'
                                                            }
                                                            onChange={handleChange('trackStatus')}
                                                        />
                                                        <label className="mr-4 md:mr-8">
                                                            Published
                                                        </label>
                                                        <input
                                                            className="mr-1"
                                                            type="radio"
                                                            value="UNPUBLISHED"
                                                            id="unpublish"
                                                            name="status"
                                                            defaultChecked={
                                                                trackState?.track?.trackStatus ===
                                                                'UNPUBLISHED'
                                                            }
                                                            onChange={handleChange('trackStatus')}
                                                        />
                                                        <label className="">Unpublished</label>
                                                    </div>
                                                </div>
                                                <div className="my-6 w-full md:mb-0 flex h-24">
                                                    <label
                                                        htmlFor="description"
                                                        className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Track description
                                                        <div className="text-red-600">*</div>
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <textarea
                                                            className={`resize-none focus:outline-none p-2 w-4/5 border-2 text-base h-24 focus:border-blue-900 ${
                                                                errors.trackDescription &&
                                                                touched.trackDescription
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            }`}
                                                            id="trackDescription"
                                                            value={values.trackDescription}
                                                            onChange={handleChange(
                                                                'trackDescription'
                                                            )}
                                                        />
                                                        {errors.trackDescription &&
                                                        touched.trackDescription ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.trackDescription}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="my-6 w-full md:mb-0 flex">
                                                    <label
                                                        htmlFor="description"
                                                        className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Track Image
                                                        <div className="text-red-600">*</div>
                                                    </label>
                                                    <div
                                                        className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                                            imageUrl ? 'w-fit' : ''
                                                        }  border-opacity-100 border-black-600
                                                  border-2`}
                                                    >
                                                        <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64">
                                                            {!imageLocation && (
                                                                <div className="w-full">
                                                                    <div className="flex justify-center">
                                                                        <Camera />
                                                                    </div>
                                                                    <div>
                                                                        <label
                                                                            className="underline cursor-pointer"
                                                                            htmlFor="imageLink"
                                                                        >
                                                                            Upload Image
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {imageUrl && imageLocation && (
                                                                <img
                                                                    src={imageUrl}
                                                                    alt={'name'}
                                                                    className="h-44 lg:h-64"
                                                                    style={{ maxHeight: 300 }}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="absolute mt-1 text-gray-400 text-sm font-medium leading-149">
                                                            Image size 250*159
                                                        </div>
                                                        <div
                                                            className="absolute mt-4 pt-4 md:mt-0 text-center w-full"
                                                            style={{
                                                                left: '50%',
                                                                transform: 'translate(-50%,-50%)',
                                                            }}
                                                        >
                                                            {imageUrl &&
                                                                imageLocation &&
                                                                !isUploading && (
                                                                    <div>
                                                                        <label
                                                                            className="underline cursor-pointer text-sm"
                                                                            htmlFor="imageLink"
                                                                        >
                                                                            Change Image
                                                                        </label>
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <div
                                                            className="absolute mt-6 text-center"
                                                            style={{
                                                                left: '50%',
                                                                transform: 'translate(-50%,-50%)',
                                                            }}
                                                        >
                                                            {isUploading && (
                                                                <div className="flex items-center">
                                                                    <div className="mr-2">
                                                                        Uploading
                                                                    </div>
                                                                    <div>
                                                                        <Loader />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {errors.imageLink &&
                                                            touched.imageLink ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.imageLink}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <input
                                                        className={`focus:outline-none w-full focus:border-blue-900`}
                                                        id="imageLink"
                                                        type="file"
                                                        accept="image/png, image/jpeg"
                                                        onChange={e => {
                                                            const file = [];
                                                            file.push(e.target.files[0]);
                                                            setFieldValue(
                                                                'imageLink',
                                                                file[0].name
                                                            );
                                                            handleImage(e);
                                                        }}
                                                        style={{ display: 'none' }}
                                                    />
                                                </div>

                                                <div className="my-6 pb-4 pt-8 w-full md:mb-0 flex">
                                                    <label
                                                        htmlFor="description"
                                                        className={`flex items-start w-1/3 text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Challenges
                                                    </label>
                                                    <div className="w-3/4 md:w-1/2">
                                                        <DragDropContext onDragEnd={onDragEnd}>
                                                            <Droppable droppableId="list">
                                                                {provided => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.droppableProps}
                                                                    >
                                                                        {challengesList?.map(
                                                                            (item, index) => (
                                                                                <Draggable
                                                                                    draggableId={
                                                                                        item.challengeId
                                                                                    }
                                                                                    key={item.id}
                                                                                    index={index}
                                                                                >
                                                                                    {prov => (
                                                                                        <div
                                                                                            className="px-4 border-2 rounded-sm text-gray-700 text-sm md:text-base flex items-center"
                                                                                            ref={
                                                                                                prov.innerRef
                                                                                            }
                                                                                            {...prov.draggableProps}
                                                                                            {...prov.dragHandleProps}
                                                                                        >
                                                                                            <FaIcons.FaBars />
                                                                                            <p className="my-2 ml-4">
                                                                                                {
                                                                                                    item.challengeName
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                                <div className="flex border-2 flex-col ml-2 justify-items-center">
                                                    <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                                        <div className="flex sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                            <div>Assign Tags</div>
                                                            <div>
                                                                <HiIcons.HiQuestionMarkCircle
                                                                    onClick={() => {
                                                                        setTagsHelpText(true);
                                                                    }}
                                                                    className="ml-2 mt-1 cursor-pointer"
                                                                />
                                                            </div>
                                                        </div>
                                                        {tagsHelpText ? (
                                                            <div
                                                                onMouseOverCapture={() => {
                                                                    setTagsHelpText(true);
                                                                    // setProfileDropdownVisible(false);
                                                                }}
                                                                onMouseLeave={() =>
                                                                    setTagsHelpText(false)
                                                                }
                                                                className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-6 right-12"
                                                            >
                                                                Assign already created tags to the
                                                                new record created.
                                                            </div>
                                                        ) : null}
                                                        <div
                                                            className="sm:text-sm md:text-base text-gray-400 underline pr-2 cursor-pointer"
                                                            onClick={() => {
                                                                setTags([]);
                                                            }}
                                                        >
                                                            Clear All
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                                        <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                            <TagGroups
                                                                selectedTags={selectedTags}
                                                                tags={tags}
                                                                setTags={setTags}
                                                            />
                                                            <div
                                                                onMouseLeave={() => {
                                                                    setShowDropdown(false);
                                                                }}
                                                            >
                                                                <input
                                                                    className={`rounded w-full text-gray-500 font-medium focus:outline-none mt-2 p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                                                    id="id"
                                                                    type="text"
                                                                    placeholder="Search tags"
                                                                    onChange={e =>
                                                                        serchTagNameOrId(e)
                                                                    }
                                                                    onMouseEnter={() => {
                                                                        setShowDropdown(true);
                                                                    }}
                                                                    autoComplete="off"
                                                                />
                                                                {showDropdown && tagData?.length ? (
                                                                    <ul
                                                                        className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                        role="menu"
                                                                        onMouseLeave={() => {
                                                                            setShowDropdown(false);
                                                                        }}
                                                                    >
                                                                        {tagData?.map(tag => (
                                                                            <div
                                                                                className="p-2 hover:bg-gray-200 cursor-pointer"
                                                                                key={tag.id}
                                                                                onClick={() => {
                                                                                    if (
                                                                                        tags
                                                                                            .map(
                                                                                                checktag =>
                                                                                                    checktag.tagName
                                                                                            )
                                                                                            .includes(
                                                                                                tag.tagName
                                                                                            ) ===
                                                                                        false
                                                                                    ) {
                                                                                        setTags([
                                                                                            ...tags,
                                                                                            tag,
                                                                                        ]);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {tag.tagCategory +
                                                                                    ': ' +
                                                                                    tag.tagName}
                                                                            </div>
                                                                        ))}
                                                                    </ul>
                                                                ) : showDropdown &&
                                                                  tagData.length === 0 ? (
                                                                    <ul
                                                                        className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                        role="menu"
                                                                        onMouseLeave={() =>
                                                                            setShowDropdown(false)
                                                                        }
                                                                    >
                                                                        <div className="p-2 flex justify-center font-medium text-gray-400">
                                                                            No records found
                                                                        </div>
                                                                    </ul>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=" ml-2 justify-items-center">
                                                    <div className="flex flex-col text-sm pl-2 lg:pl-4 mt-6 space-y-4 text-gray-400 ">
                                                        <div>
                                                            Created tags can be retrieved by typing
                                                            the text in the search bar.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {track?.trackStatus !== 'PUBLISHED' && (
                                            <div className="md:mb-0 flex justify-end">
                                                <button
                                                    type="submit"
                                                    className="sm:py-1 md:py-3 ml-2 lg:ml-8 hover:bg-red-500 text-red-500 hover:text-white border-2 font-medium border-red-600 border-opacity-40 text-sm rounded-sm w-1/2 md:w-1/6 my-2 focus:outline-none"
                                                    onClick={() =>
                                                        setDeleteConfirmationVisible(true)
                                                    }
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
                {deleteConfirmationVisible ? (
                    <DeleteConfirmationModal
                        modalVisible={deleteConfirmationVisible}
                        setModalVisible={setDeleteConfirmationVisible}
                        trackId={trackState.track.id}
                    />
                ) : null}
                {trackUpdateSuccess && (
                    <TrackSuccessfullModal
                        modalVisible={trackUpdateSuccessVisible}
                        handleClose={handleClose}
                    />
                )}
                {editTrackError && (
                    <TrackSuccessfullModal
                        modalVisible={trackErrorVisible}
                        handleClose={handleClose}
                    />
                )}
                <CropModal
                    ratio={1}
                    photoURL={imageUrl}
                    setPhotoURL={setImageUrl}
                    setOpenCrop={setOpenCrop}
                    openCrop={openCrop}
                    handleCloseCropModal={handleCloseCropModal}
                    uploadImageToS3={uploadImageToS3}
                />
            </div>
        </div>
    );
}
