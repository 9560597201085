import React, { ReactElement, useEffect, useState } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../assets/svg/CloseIcon';
import { youTubeUrlRegExp } from '../../constants';

interface Props {
    showModal: boolean;
    setShowModal: any;
    successStorySubmitHandler: any;
}

export default function AddStoryModal({
    showModal,
    setShowModal,
    successStorySubmitHandler,
}: Props): ReactElement {
    const [story, setStory] = useState('');
    const [youtubeURL, setYoutubeURL] = useState('');

    const validater = () => {
        const match = youtubeURL.match(youTubeUrlRegExp);
        if (match && match[2].length === 11) {
            return true;
        } else {
            return false;
        }
    };
    const invalid = story.length === 0 || (youtubeURL && !validater());

    const cleanFields = () => {
        setStory('');
        setYoutubeURL('');
    };
    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    return (
        <Modal isOpen={showModal} style={customStyles} ariaHideApp={false}>
            <>
                <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-5/12 my-6  mx-auto">
                        {/* content */}
                        <div
                            className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
                            style={{ maxHeight: '656px' }}
                        >
                            {/* header */}
                            <div>
                                <div className="flex flex-row">
                                    <div className="w-11/12 flex justify-center">
                                        <h1 className="pl-8 mb-4 text-xl font-bold">
                                            Share Your Success Story
                                        </h1>
                                    </div>

                                    <div
                                        className="w-1/12  flex justify-end"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <CloseIcon size={20} color={'gray'} />
                                    </div>
                                </div>

                                <div className="flex mb-8 text-sm  justify-center">
                                    <p>
                                        Tell us about your journey in Future Careers BRIDGE program
                                        and how it helped you to excel in your Career.
                                    </p>
                                </div>
                            </div>

                            <div className="overflow-y-auto">
                                <div className="flex flex-col my-1.5">
                                    <label className="flex flex-col mb-1.5 ml-1" htmlFor="tab_1">
                                        Enter your success story
                                    </label>
                                    <textarea
                                        className="border-2  border-gray-300 rounded px-4 pt-3 h-36 resize-none"
                                        id="tab_1"
                                        maxLength={250}
                                        value={story}
                                        placeholder="How was your journey with us"
                                        onChange={e => {
                                            setStory(e.target.value);
                                        }}
                                    />
                                    <label
                                        className="flex flex-col mb-1.5 ml-1 text-right text-gray-400 text-sm"
                                        htmlFor="tab_1"
                                    >
                                        {`${story.length}/250`}
                                    </label>
                                    <label
                                        className="flex flex-col mt-10 mb-1.5 ml-1"
                                        htmlFor="tab_1"
                                    >
                                        Enter your video link here
                                    </label>
                                    <textarea
                                        className="border-2  border-gray-300 rounded px-4 pt-3 h-14 resize-none"
                                        id="tab_1"
                                        value={youtubeURL}
                                        placeholder="Enter the video url here"
                                        onChange={e => {
                                            setYoutubeURL(e.target.value);
                                        }}
                                    />
                                    {invalid && youtubeURL.length !== 0 ? (
                                        <div className="text-red-500 mb-3 text-xs my-1">
                                            Must be a valid YouTube URL
                                        </div>
                                    ) : (
                                        <div className="mb-8"></div>
                                    )}

                                    <div className="flex items-center justify-end rounded-b">
                                        <input
                                            className="w-36 h-12 text-purple-950 border-purple-950 border-2 rounded  bg-white hover:shadow-2xl font-bold px-6 py-2 text-sm cursor-pointer outline-none focus:outline-none mr-5 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            value="Cancel"
                                            onClick={() => setShowModal(false)}
                                        />
                                        <input
                                            className={`w-36 h-12 text-white  border-2 rounded ${
                                                invalid
                                                    ? 'bg-gray-300 border-gray-300'
                                                    : 'bg-purple-950 hover:shadow-2xl border-purple-950'
                                            }  font-bold  px-6 py-2 text-sm cursor-pointer outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                                            type="submit"
                                            value="Submit"
                                            disabled={invalid}
                                            onClick={() => {
                                                successStorySubmitHandler(story, youtubeURL);
                                                cleanFields();
                                                setShowModal(false);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Modal>
    );
}
