import { TracksType } from '../tracks/reducers';
import {
    CLEAR_STUDENT_MESSAGES,
    DELETE_STUDENT,
    DELETE_STUDENT_FAILED,
    DELETE_STUDENT_SUCCESS,
    EDIT_STUDENT,
    EDIT_STUDENT_FAILED,
    EDIT_STUDENT_SUCCESS,
    GET_DOWNLOAD_FILE_PRESIGNED_URL,
    GET_DOWNLOAD_FILE_PRESIGNED_URL_FAILED,
    GET_DOWNLOAD_FILE_PRESIGNED_URL_SUCCESS,
    GET_STUDENTBYID,
    GET_STUDENTBYID_FAILED,
    GET_STUDENTBYID_SUCCESS,
    RESET_STUDENTBYID,
    GET_STUDENTS,
    GET_STUDENTS_FAILED,
    GET_STUDENTS_SUCCESS,
    STUDENT_BULK_UPDATE,
    STUDENT_BULK_UPDATE_FAILED,
    STUDENT_BULK_UPDATE_SUCCESS,
    DOWNLOAD_CSV_STUDENT,
    DOWNLOAD_CSV_STUDENT_FAILED,
    DOWNLOAD_CSV_STUDENT_SUCCESS,
    DOWNLOAD_CSV_STUDENT_RESET,
} from './actions';
export interface CountType {
    DEACTIVATED: number;
    ACTIVE: number;
    DELETED: number;
    ALL: number;
}
export interface InstituteType {
    id: string;
    orgName: string;
    orgEmail: string;
    contactNo: number;
    location: string;
    faculty: string;
    studentId: string;
    instCategory1: categoryType;
    instCategory2: categoryType;
    instCategory3: categoryType;
    batchName: string | null;
}
export interface ContactType {
    emailId: string;
    address1: string;
    address2: string;
    city: string;
    district: string;
    landlineNo: number;
    mobileNo: number;
    province: string;
}
export interface MentorType {
    id: string;
    mentorName: string;
    companyName: number;
    designation: string;
    mentorEmail: string;
    mentorStatus: string;
}

export interface CareerType {
    id: any;
    entityId: string;
    entityName: string;
    type: string;
    message: string;
    logCreatedBy?: string;
    logCreatedDate?: string;
    logUpdatedDate?: string;
}
export interface categoryType {
    name: string;
    option: string;
}
export interface InstituteCategoryType {
    category1?: categoryType;
    category2?: categoryType;
    category3?: categoryType;
}

export enum SocialMediaPlatform {
    LinkedIn = 'LINKEDIN',
    Facebook = 'FACEBOOK',
    Twitter = 'TWITTER',
}

export interface SocialMediaUrlType {
    id: number;
    platform: SocialMediaPlatform;
    url: string;
}

export interface BridgeProgress {
    trackName: string;
    lastAttemptChallengeName: string;
    averageScorePercentage: number;
    trackChallengesCount: number;
    studentAttemptChallengeCount: number;
    latestSubmissionDate: string;
}

export interface TrackChallengeWiseSubmissions {
    trackName: string;
    challengeName: string;
    lastSubmitDate: string;
    submissions: any[];
}

export interface StudentType {
    id: string;
    imageUrl: string;
    userId: string;
    userName: string;
    gender: string;
    birthDate: string;
    StudentInstitute: InstituteType;
    instituteCategories: InstituteCategoryType;
    contact: ContactType;
    emailId: string;
    InstituteName: string;
    educationStatus: string;
    empStatus: string;
    reasonForJoin: string;
    pursuingHigherStudies: string;
    alStream: string;
    companyName: string;
    bridgeInvitationCode: string;
    mentor: MentorType;
    lastActive: string;
    firstName: string;
    lastName: string;
    tracks: TracksType[];
    userPoints: number;
    userLastUpdatedBy: string;
    userLastUpdatedDate: string;
    joinedDate: string;
    nic: string;
    tags: any;
    status: string;
    secondaryEduLevel: string;
    cvLink: string;
    higherEduStatus: string;
    careerStatus: [CareerType];
    certificates: any[];
    badges: any[];
    hiredByFCB: boolean;
    Submissions: any[];
    onBoardingCompleted?: boolean;
    SuccessStoryPublished: boolean;
    socialMediaUrls: SocialMediaUrlType[];
    bridgeProgresses: BridgeProgress[];
    completedChallengeCount: number;
    trackChallengeWiseSubmissions: TrackChallengeWiseSubmissions[];
    totalScore: number;
    SuccessStoryStatus: string;
    mentorRequestId: number;
}

export interface studentInitialState {
    studentData: StudentType[];
    student: StudentType;
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    studentByIdLoading: boolean;
    studentByIdError: string | null;
    studentsError: string | null;
    isStudentByIdLoading: boolean;
    isDeleteLoading: boolean;
    deleteStudentError: string | null;
    studentDeleteSuccessMessage: string | null;
    isEditLoading: boolean;
    editStudentError: string | null;
    StudentUpdateSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    studentBulkSuccessMessage: string | null;
    studentEmailMessage: string | null;
    submissionDocLink: string | null;
    submissionDocLinkError: string | null;
    submissionDocLinkSuccess: string | null;
    isStudentCSVLoading: boolean;
    studentCSVSuccess: boolean;
}

const initialState: studentInitialState = {
    studentData: [],
    student: null,
    pageCount: null,
    isLoading: false,
    count: null,
    studentByIdLoading: false,
    studentByIdError: null,
    isStudentByIdLoading: false,
    studentsError: null,
    isDeleteLoading: false,
    deleteStudentError: null,
    studentDeleteSuccessMessage: null,
    isEditLoading: false,
    editStudentError: null,
    StudentUpdateSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    studentBulkSuccessMessage: null,
    studentEmailMessage: null,
    submissionDocLink: null,
    submissionDocLinkError: null,
    submissionDocLinkSuccess: null,
    isStudentCSVLoading: false,
    studentCSVSuccess: false,
};

const studentReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_STUDENTS:
            return { ...state, isLoading: true, studentsError: null };
        case GET_STUDENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                studentData: action.payload.items,
                pageCount: action.payload.totalPages,
            };

        case GET_STUDENTS_FAILED:
            return { ...state, isLoading: false, studentsError: action.payload, student: null };
        case GET_STUDENTBYID:
            return { ...state, studentByIdLoading: true, studentByIdError: null, student: null };
        case GET_STUDENTBYID_SUCCESS:
            return {
                ...state,
                studentByIdLoading: false,
                student: action.payload,
            };
        case GET_STUDENTBYID_FAILED:
            return { ...state, studentByIdLoading: false, studentByIdError: action.payload };
        case RESET_STUDENTBYID:
            return {
                ...state,
                student: null,
            };

        case DELETE_STUDENT:
            return { ...state, isDeleteLoading: true, deleteStudentError: null };
        case DELETE_STUDENT_SUCCESS:
            return {
                ...state,
                isDeleteLoading: false,
                studentDeleteSuccessMessage: action.payload,
            };
        case DELETE_STUDENT_FAILED:
            return { ...state, isDeleteLoading: false, deleteStudentError: action.payload };
        case EDIT_STUDENT:
            return {
                ...state,
                isEditLoading: true,
                editStudentError: null,
                StudentUpdateSuccessMessage: null,
            };
        case EDIT_STUDENT_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                StudentUpdateSuccessMessage: action.payload,
            };
        case EDIT_STUDENT_FAILED:
            return { ...state, isEditLoading: false, editStudentError: action.payload };

        case STUDENT_BULK_UPDATE:
            return { ...state, isBulkUpdateLoading: true, bulkError: null };
        case STUDENT_BULK_UPDATE_SUCCESS:
            return {
                ...state,
                isBulkUpdateLoading: false,
                studentBulkSuccessMessage: action.payload,
            };
        case STUDENT_BULK_UPDATE_FAILED:
            return { ...state, isBulkUpdateLoading: false, bulkError: action.payload };
        case CLEAR_STUDENT_MESSAGES:
            return {
                ...state,
                StudentUpdateSuccessMessage: null,
                editStudentError: null,
                deleteStudentError: null,
                studentDeleteSuccessMessage: null,
                studentBulkSuccessMessage: null,
                bulkError: null,
            };
        case GET_DOWNLOAD_FILE_PRESIGNED_URL:
            return { ...state, submissionDocLinkError: null };
        case GET_DOWNLOAD_FILE_PRESIGNED_URL_FAILED:
            return { ...state, submissionDocLinkError: action.payload };
        case GET_DOWNLOAD_FILE_PRESIGNED_URL_SUCCESS:
            return { ...state, submissionDocLink: action.payload };
        case DOWNLOAD_CSV_STUDENT:
            return {
                ...state,
                isStudentCSVLoading: true,
                studentsCVSError: null,
                studentCSVSuccess: false,
            };
        case DOWNLOAD_CSV_STUDENT_SUCCESS:
            return {
                ...state,
                isStudentCSVLoading: false,
                studentEmailMessage: action.payload,
                studentCSVSuccess: true,
            };

        case DOWNLOAD_CSV_STUDENT_FAILED:
            return {
                ...state,
                isStudentCSVLoading: false,
                studentsCVSError: action.payload,
                studentCSVSuccess: false,
            };
        case DOWNLOAD_CSV_STUDENT_RESET:
            return {
                ...state,
                studentCSVSuccess: false,
            };
        default:
            return state;
    }
};

export default studentReducer;
