import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CompanyType } from '../../../../../redux/companies/reducers';
import { getPublicCompanyById } from '../../../../../redux/rootActions';
import { RootState } from '../../../../../redux/rootReducer';
import { s3UrlPrefix } from '../../../../../constants';
import BackArrow from '../../../../../assets/svg/BackArrow';

interface paramsType {
    id: string;
}

const Company = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<paramsType>();

    const publicCompany = useSelector((state: RootState) => state.companies.publicCompany);
    const [companyName, setCompanyName] = useState<any>(publicCompany?.comName);
    const [comlocation, setComLocation] = useState<any>(publicCompany?.comDescription);
    const [description, setDescription] = useState<any>(publicCompany?.comLocation);

    useEffect(() => {
        setCompanyName(publicCompany?.comName);
        setDescription(publicCompany?.comDescription);
        setComLocation(publicCompany?.comLocation);
    }, [publicCompany]);

    useEffect(() => {
        dispatch(getPublicCompanyById(params.id));
    }, [dispatch, params.id]);
    return (
        <>
            <div className="hidden lg:block">
                <img
                    className="absolute border-2 border-gray-300"
                    style={
                        publicCompany?.bannerImage !== null
                            ? {
                                  top: '280px',
                                  left: '126px',
                              }
                            : {}
                    }
                    width="275px"
                    src={
                        publicCompany?.comImage !== null
                            ? `${s3UrlPrefix + publicCompany?.comImage}`
                            : '/assets/BlankImage.png'
                    }
                />
                <div className="flex justify-center">
                    <img
                        style={{
                            maxWidth: 'none',
                            width: '100%',
                        }}
                        className="w-full"
                        src={
                            publicCompany?.bannerImage !== null
                                ? `${s3UrlPrefix + publicCompany?.bannerImage}`
                                : `/assets/companybanner.png`
                        }
                        alt="logo"
                    />
                </div>
                <div className="px-44">
                    <div
                        className="flex mb-5 mt-10 cursor-pointer"
                        onClick={() => history.push(`/view-all/company`)}
                    >
                        <BackArrow size="27" />
                        <p className="text-base ml-2 font-semibold  hover:underline">Back</p>
                    </div>
                    <div className="flex justify-between  mb-9">
                        <div>
                            <div className="text-4xl font-semibold">{publicCompany?.comName}</div>
                            <div>{publicCompany?.comLocation}</div>
                        </div>
                        {publicCompany?.siteUrl && (
                            <button
                                className="px-4 font-bold bg-purple-950 text-white rounded h-12"
                                onClick={() => {
                                    const url = publicCompany.siteUrl.split('.');
                                    if (url[0] === 'www') {
                                        window.open(`//${publicCompany.siteUrl}`, '_blank');
                                    } else {
                                        window.open(publicCompany.siteUrl, '_blank');
                                    }
                                }}
                            >
                                Visit Website
                            </button>
                        )}
                    </div>

                    <div className="mb-9">
                        <div className="text-xl font-semibold">Description</div>
                        <div className="border-b-2 my-2"></div>
                        <div className="text-justify">{publicCompany?.comDescription}</div>
                    </div>
                    <div className="mb-28">
                        <div className="flex justify-between">
                            <div className="mt-4 text-xl font-semibold">Career opportunities</div>
                            {publicCompany?.applyURL && (
                                <button
                                    className="px-4 font-bold border-2 border-purple-950 text-purple-950 rounded h-12"
                                    onClick={() => {
                                        const url = publicCompany.applyURL.split('.');
                                        if (url[0] === 'www') {
                                            window.open(`//${publicCompany.applyURL}`, '_blank');
                                        } else {
                                            window.open(publicCompany.applyURL, '_blank');
                                        }
                                    }}
                                >
                                    Apply Now
                                </button>
                            )}
                        </div>
                        <div className="border-b-2 my-2 "></div>
                        <div style={{ background: '#F5F3FC' }} className="text-justify px-6 py-1">
                            {publicCompany?.careerOpportunities
                                ?.toString()
                                .split(',')
                                .map((opportunity, index) => (
                                    <div key={index} className="my-4 font-medium">
                                        {opportunity}
                                    </div>
                                ))}
                        </div>
                        {/* <div className="text-justify">{publicCompany?.careerOpportunities}</div> */}
                    </div>
                </div>
            </div>
            <div className="lg:hidden">
                <div className="relative">
                    <img
                        style={{
                            height: '400px',
                        }}
                        className="object-cover object-center"
                        src={
                            publicCompany?.bannerImage !== null
                                ? `${s3UrlPrefix + publicCompany?.bannerImage}`
                                : `/assets/companybanner.png`
                        }
                        alt="logo"
                    />
                    <img
                        className="absolute border-2 border-gray-300"
                        style={{
                            bottom: '10%',
                            left: '5%',
                        }}
                        width="275px"
                        src={
                            publicCompany?.comImage !== null
                                ? `${s3UrlPrefix + publicCompany?.comImage}`
                                : '/assets/BlankImage.png'
                        }
                    />
                </div>

                <div className="px-4">
                    <div className="flex mb-5 mt-6 cursor-pointer" onClick={() => history.goBack()}>
                        <BackArrow size="27" />
                        <p className="text-base ml-2 font-semibold  hover:underline">Back</p>
                    </div>
                    <div className="flex flex-col gap-y-3 justify-between mb-9">
                        <div className="text-lg font-bold font-manrope">
                            {publicCompany?.comName}
                        </div>
                        {publicCompany?.comLocation && (
                            <div className="text-xs font-manrope">{publicCompany?.comLocation}</div>
                        )}
                        {publicCompany?.siteUrl && (
                            <div className="flex">
                                <button
                                    className="px-4 font-bold bg-purple-950 text-white rounded h-12"
                                    onClick={() => {
                                        const url = publicCompany.siteUrl.split('.');
                                        if (url[0] === 'www') {
                                            window.open(`//${publicCompany.siteUrl}`, '_blank');
                                        } else {
                                            window.open(publicCompany.siteUrl, '_blank');
                                        }
                                    }}
                                >
                                    Visit Website
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <div className="text-lg font-bold font-manrope">Description</div>
                        <div className="border-b-2 my-2"></div>
                        <div className="text-justify text-xs">{publicCompany?.comDescription}</div>
                    </div>
                    <div className="mb-28">
                        <div className="flex justify-between">
                            <div className="mt-4 text-lg font-bold">Career opportunities</div>{' '}
                            {publicCompany?.applyURL && (
                                <button
                                    className="px-4 font-bold border-2 border-purple-950 text-purple-950 rounded h-12"
                                    onClick={() => {
                                        const url = publicCompany.applyURL.split('.');
                                        if (url[0] === 'www') {
                                            window.open(`//${publicCompany?.applyURL}`, '_blank');
                                        } else {
                                            window.open(publicCompany?.applyURL, '_blank');
                                        }
                                    }}
                                >
                                    Apply Now
                                </button>
                            )}
                        </div>
                        <div className="border-b-2 my-2 "></div>
                        <div className="text-justify text-xs">
                            {publicCompany?.careerOpportunities}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Company;
