import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
    @media screen and (min-width: 1440px) {
        box-sizing: border-box;
        background: #fff;
        height: 70px;
        display: flex;
        justify-content: space-between;
        z-index: 10;
        padding-left: 54px;
        padding-right: 54px;
    }
    @media screen and (min-width: 1920px) {
        box-sizing: border-box;
        background: #fff;
        height: 70px;
        display: flex;
        justify-content: space-between;
        z-index: 10;
        padding-left: 114px;
        padding-right: 114px;
    }
    position: fixed;
    width: 100%;
    font-family: 'Sora', sans-serif;
    box-sizing: border-box;
    background: #fff;
    height: 70px;
    display: flex;
    justify-content: space-between;
    z-index: 10;
    padding-left: 40px;
    padding-right: 5px;
`;

export const NavLink = styled(Link)`
    @media screen and (min-width: 1440px) {
        color: #000;
        display: flex;
        font-size: 14px;
        align-items: center;
        text-decoration: none;
        padding-right: 24px;
        height: 100%;
        cursor: pointer;
        &.active {
            color: #4c2ec2;
            text-shadow: 0.5px 0 0 currentColor;
        }
        &:hover {
            color: #4c2ec2;
            text-shadow: 0.5px 0 0 currentColor;
        }
    }
    color: #000;
    display: flex;
    font-size: 14px;
    align-items: center;
    text-decoration: none;
    padding-right: 10px;
    height: 100%;
    cursor: pointer;
    &.active {
        color: #4c2ec2;
        text-shadow: 0.5px 0 0 currentColor;
    }
    &:hover {
        color: #4c2ec2;
        text-shadow: 0.5px 0 0 currentColor;
    }
`;

export const Bars = styled(FaBars)`
    display: none;
    color: #fff;
    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 75%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    /* Second Nav */
    /* margin-right: 24px; */
    /* Third Nav */
    /* width: 100vw;
  white-space: nowrap; */
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    /* Third Nav */
    /* justify-content: flex-end;
  width: 100vw; */
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const SignInBtnLink = styled(Link)`
    @media screen and (min-width: 1440px) {
        border: 2px solid #4c2ec2;
        text-shadow: 0.5px 0 0 currentColor;
        border-radius: 4px;
        padding: 10px 32px;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
    }
    border: 2px solid #4c2ec2;
    text-shadow: 0.5px 0 0 currentColor;
    border-radius: 4px;
    padding: 6px 22px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
`;

export const RegisterBtnLink = styled(Link)`
    @media screen and (min-width: 1440px) {
        border: 2px solid #4c2ec2;
        text-shadow: 0.5px 0 0 currentColor;
        border-radius: 4px;
        padding: 10px 32px;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        /* Second Nav */
        margin-left: 24px;
    }
    border: 2px solid #4c2ec2;
    text-shadow: 0.5px 0 0 currentColor;
    border-radius: 4px;
    padding: 6px 22px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    /* Second Nav */
    margin-left: 10px;
`;
