import axios from 'axios';
import { API_URL, webAuthTimeout, signinError } from '../../../constants';
import { getErrorMessage } from '../../../utils/axiosErrorHandler';
import {
    clearStudentLocalStorage,
    storeGoogleCreds,
    storeWebAuthCreds,
} from '../../../web/utils/storageWeb';
import { googleOAUthResponseType, signInResponseType } from './reducers';

const PREFIX = '@WEBAUTH_';

export const WEB_LOGIN = PREFIX + 'WEB_LOGIN';
export const WEB_LOGIN_SUCCESS = PREFIX + 'WEB_LOGIN_SUCCESS';
export const WEB_LOGIN_FAILED = PREFIX + 'WEB_LOGIN_FAILED';
export const WEB_MOBILE_LOGIN = PREFIX + 'WEB_MOBILE_LOGIN';
export const WEB_MOBILE_LOGIN_SUCCESS = PREFIX + 'WEB_MOBILE_LOGIN_SUCCESS';
export const WEB_MOBILE_LOGIN_FAILED = PREFIX + 'WEB_MOBILE_LOGIN_FAILED';
export const WEB_LOGIN_RESET = PREFIX + 'WEB_LOGIN_RESET';
export const WEB_PASSWORD_RESET = PREFIX + 'WEB_PASSWORD_RESET';
export const WEB_PASSWORD_RESET_SUCCESS = PREFIX + 'WEB_PASSWORD_RESET_SUCCESS';
export const WEB_PASSWORD_RESET_FAILED = PREFIX + 'WEB_PASSWORD_RESET_FAILED';
export const WEB_SEND_EMAIL_TO_RESET_PASSWORD = PREFIX + 'WEB_SEND_EMAIL_TO_RESET_PASSWORD';
export const WEB_SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS =
    PREFIX + 'WEB_SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS';
export const WEB_SEND_EMAIL_TO_RESET_PASSWORD_FAILED =
    PREFIX + 'WEB_SEND_EMAIL_TO_RESET_PASSWORD_FAILED';
export const WEB_LOGIN_FAILED_2 = PREFIX + 'WEB_LOGIN_FAILED_2';
export const WEB_MOBILE_LOGIN_FAILED_2 = PREFIX + 'WEB_MOBILE_LOGIN_FAILED_2';

export const WEB_LOGOUT = PREFIX + 'WEB_LOGOUT';

export const WEB_CLEAR_AUTH = PREFIX + 'WEB_CLEAR_AUTH';

export const handleWebTokens = (response: signInResponseType) => {
    const user = {
        token: response.token,
        refreshToken: response.refreshToken,
        username: response.username,
        userId: response.userId,
        email: response.email,
        roles: response.roles,
    };
    storeWebAuthCreds(user);
};

export const hadleGoogleTokens = (response: googleOAUthResponseType) => {
    const user = {
        token: response.accessToken,
        username: response.username,
        userId: response.userId,
        email: response.email,
        roles: ['STUDENT'],
    };
    storeGoogleCreds(user);
};

export const logoutWeb = () => async (dispatch: any) => {
    try {
        dispatch({ type: WEB_LOGOUT });
        clearStudentLocalStorage();
        // window.location.href = `${window.location.origin}/signin`;
    } catch (error) {
        console.log('logout', getErrorMessage(error));
    }
};

export const loginWeb = (userName: string, password: string) => async (dispatch: any) => {
    try {
        dispatch({ type: WEB_LOGIN });
        const response = await axios.post(`${API_URL}/users/signin`, {
            userName: userName,
            password: password,
        });
        if (!response?.data?.status) {
            if (response?.data?.errorCode !== 222) {
                dispatch({
                    type: WEB_LOGIN_FAILED,
                    payload: response.data.errorDescription,
                });
            }
        }
        if (response.data.responseDto.roles.includes('STUDENT')) {
            if (
                response.data.responseDto?.isMobileVerified ||
                response.data.responseDto?.isEmailVerified
            ) {
                handleWebTokens(response.data.responseDto);
            }
            dispatch({ type: WEB_LOGIN_SUCCESS, payload: response.data.responseDto });
        } else {
            dispatch({
                type: WEB_LOGIN_FAILED,
                payload: signinError,
            });
        }
    } catch (error) {
        dispatch({ type: WEB_LOGIN_FAILED_2, payload: getErrorMessage(error) });
    }
};

export const socialMediaLogin = (accessToken: string) => async (dispatch: any) => {
    try {
        dispatch({ type: WEB_LOGIN });

        const response = await axios.post(`${API_URL}/social/auth/signin`, null, {
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
        });
        if (!response?.data?.status) {
            if (response?.data?.errorCode !== 222) {
                dispatch({
                    type: WEB_LOGIN_FAILED,
                    payload: response.data.errorDescription,
                });
            }
        }
        if (response.data.responseDto.roles.includes('STUDENT')) {
            if (
                response.data.responseDto?.isMobileVerified ||
                response.data.responseDto?.isEmailVerified
            ) {
                handleWebTokens(response.data.responseDto);
            }
            dispatch({ type: WEB_LOGIN_SUCCESS, payload: response.data.responseDto });
            // dispatch(checkAuthTimeout(webAuthTimeout));
        } else {
            dispatch({
                type: WEB_LOGIN_FAILED,
                payload: signinError,
            });
        }
    } catch (error) {
        dispatch({ type: WEB_LOGIN_FAILED, payload: getErrorMessage(error) });
    }
};

export const loginLinkedIn = (linkedinResponse: any) => async (dispatch: any) => {
    try {
        dispatch({ type: WEB_LOGIN });
        const response = await axios.post(`${API_URL}/social/auth/linkedin`, {
            accessToken: linkedinResponse,
        });
        if (
            response.data.responseDto?.isMobileVerified ||
            response.data.responseDto?.isEmailVerified
        ) {
            handleWebTokens(response.data.responseDto);
        }
        dispatch({ type: WEB_LOGIN_SUCCESS, payload: response.data.responseDto });
        // dispatch(checkAuthTimeout(webAuthTimeout));
    } catch (error) {
        dispatch({ type: WEB_LOGIN_FAILED_2, payload: getErrorMessage(error) });
    }
};

export const loginFacebook = (facebookResponse: any) => async (dispatch: any) => {
    try {
        dispatch({ type: WEB_LOGIN });
        const response = await axios.post(`${API_URL}/social/auth/facebook`, {
            email: facebookResponse.email,
            firstName: facebookResponse.username,
            accessToken: facebookResponse.accessToken,
            roles: facebookResponse.roles,
        });
        if (
            response.data.responseDto?.isMobileVerified ||
            response.data.responseDto?.isEmailVerified
        ) {
            handleWebTokens(response.data.responseDto);
        }
        dispatch({ type: WEB_LOGIN_SUCCESS, payload: response.data.responseDto });
        // dispatch(checkAuthTimeout(webAuthTimeout));
    } catch (error) {
        dispatch({ type: WEB_LOGIN_FAILED_2, payload: getErrorMessage(error) });
    }
};

export const loginMobileWeb = (otpNum: any, mobileNumber: any) => async (dispatch: any) => {
    try {
        dispatch({ type: WEB_MOBILE_LOGIN });
        const response = await axios.post(`${API_URL}/users/signin/mobile`, {
            mobileNumber: mobileNumber,
            otpNum: otpNum,
        });
        if (!response?.data?.status) {
            if (response?.data?.errorCode !== 222) {
                dispatch({
                    type: WEB_MOBILE_LOGIN_FAILED,
                    payload: response.data.errorDescription,
                });
            }
        }
        if (
            response.data.responseDto?.isMobileVerified ||
            response.data.responseDto?.isEmailVerified
        ) {
            handleWebTokens(response.data.responseDto);
        }
        dispatch({ type: WEB_MOBILE_LOGIN_SUCCESS, payload: response.data.responseDto });
        // dispatch(checkAuthTimeout(webAuthTimeout));
    } catch (error) {
        dispatch({ type: WEB_MOBILE_LOGIN_FAILED_2, payload: getErrorMessage(error) });
    }
};

export const resetWebPassword = (token: string, password: string) => async (dispatch: any) => {
    try {
        dispatch({ type: WEB_PASSWORD_RESET });
        const response = await axios.post(`${API_URL}/users/admin/reset?token=${token}`, {
            password: password,
        });
        if (response?.data?.errorCode === 223) {
            dispatch({ type: WEB_PASSWORD_RESET_SUCCESS, payload: response.data.responseDto });
        }
        if (response?.data?.errorCode !== 223) {
            dispatch({ type: WEB_PASSWORD_RESET_FAILED, payload: response.data.errorDescription });
        }
    } catch (error) {
        dispatch({ type: WEB_PASSWORD_RESET_FAILED, payload: getErrorMessage(error) });
    }
};

export const webSendEmailToResetPassword = (email: string) => async (dispatch: any) => {
    try {
        dispatch({ type: WEB_SEND_EMAIL_TO_RESET_PASSWORD });
        const response = await axios.post(`${API_URL}/users/forget/password`, { email: email });

        if (response?.data?.errorCode === 222) {
            dispatch({
                type: WEB_SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS,
                payload: response.data.responseDto,
            });
        } else {
            dispatch({
                type: WEB_SEND_EMAIL_TO_RESET_PASSWORD_FAILED,
                payload: response.data.errorDescription,
            });
        }
    } catch (error) {
        dispatch({
            type: WEB_SEND_EMAIL_TO_RESET_PASSWORD_FAILED,
            payload: getErrorMessage(error),
        });
    }
};

export const clearWebAuth = () => (dispatch: any) => {
    dispatch({ type: WEB_CLEAR_AUTH });
};
