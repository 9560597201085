import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Tracks } from '../components/Pages/Tracks/Tracks';
import { Students } from '../components/Pages/Students/Students';
import { Challenges } from '../components/Pages/Challenges/Challenges';
import { AdminCompanies } from '../components/Pages/Companies/AdminCompanies';
import AddNewTrack from '../components/Pages/Tracks/AddNewTrack';
import ViewTrack from '../components/Pages/Tracks/ViewTrack';
import EditTrack from '../components/Pages/Tracks/EditTrack';
import ViewStudent from '../components/Pages/Students/ViewStudent';
import EditStudent from '../components/Pages/Students/EditStudent';
import { AdminEvents } from '../components/Pages/Events/Events';
import AddNewChallenge from '../components/Pages/Challenges/AddNewChallenge';
import EditChallenge from '../components/Pages/Challenges/EditChallenge';
import ViewChallenge from '../components/Pages/Challenges/ViewChallenge';
import { SuccessStories } from '../components/Pages/SuccessStories/SuccessStories';
import { Institutes } from '../components/Pages/Institutes/Institutes';
import AddNewInstitute from '../components/Pages/Institutes/AddNewInstitute';
import EditInstitute from '../components/Pages/Institutes/EditInstitute';
import ViewInstitute from '../components/Pages/Institutes/ViewInstitute';
import AddCompany from '../components/Pages/Companies/AddCompany';
import EditCompany from '../components/Pages/Companies/EditCompany';
import ViewCompany from '../components/Pages/Companies/viewCompany';
import { Certificates } from '../components/Pages/Certificates/Certificates';
import AddCertificate from '../components/Pages/Certificates/AddCertificate';
import EditCertificate from '../components/Pages/Certificates/EditCertificate';
import ViewCertificate from '../components/Pages/Certificates/ViewCertificate';
import AddEvent from '../components/Pages/Events/AddEvent';
import EditEvent from '../components/Pages/Events/EditEvent';
import ViewEvent from '../components/Pages/Events/ViewEvent';
import { Mentors } from '../components/Pages/Mentors/Mentors';
import { Badges } from '../components/Pages/Badges/Badges';
import AddNewBadge from '../components/Pages/Badges/AddNewBadge';
import EditBadge from '../components/Pages/Badges/EditBadge';
import ViewBadge from '../components/Pages/Badges/ViewBadge';
import AddNewMentor from '../components/Pages/Mentors/AddMentor';
import { Notifications } from '../components/Pages/Notifications/Notifications';
import EditMentor from '../components/Pages/Mentors/EditMentor';
import ViewMentor from '../components/Pages/Mentors/ViewMentor';
import SendNotifications from '../components/Pages/Notifications/SendNotifications';
import { Users } from '../components/Pages/Users/Users';
import AddUser from '../components/Pages/Users/AddUser';
import adminSignIn from '../components/AdminSignIn';
import {
    AdminRoutesWithLayout as RouteWithLayout,
    AdminRoutesWithOutLayout as RouteWithOutLayout,
    ProtectedSuperAdminRoutesWithLayout as ProtectedSuperRoutes,
    ProtectedAllAdminRoutesWithLayout as ProtectedAllRoutes,
} from './ProtectedRoutesWithLayoutsAdmin';
import EditUser from '../components/Pages/Users/EditUser';
import { Settings } from '../components/Pages/Settings/Settings';
import PasswordReset from '../components/PasswordReset';
import ForgetPassword from '../components/ForgetPassword';
import { Analytics } from '../components/Pages/Analytics/Analytics';
import {
    COMPANY_ADMIN,
    INSTITUTE_ADMIN,
    SLASSCOM_ADMIN,
    SUPER_ADMIN,
    UserProfileType,
} from '../redux/authAdmin/reducers';
import { getUserProfile } from '../utils/storage';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import ViewNotification from '../components/Pages/Notifications/ViewNotification';
import { Dashboard } from '../components/Pages/Dashboard/Dashboard';
import ChangeEmailFeedback from '../components/Pages/Settings/ChangeEmailFeedback';
import VerifyEmail from '../components/Pages/Settings/VerifyEmail';
import PageNotFound from '../web/components/Pages/Common/PageNotFoundAdmin';
interface Props {
    sidebar: boolean;
    setSidebar: (value: boolean) => void;
}
function AdminRoutes({ sidebar, setSidebar }: Props) {
    const user: UserProfileType | any = getUserProfile();
    const loginResponse: UserProfileType | any = useSelector(
        (state: RootState) => state.auth.loginResponse
    );
    const isSuperAdmin = user?.roles?.[0] === SUPER_ADMIN;
    const isSlasscomAdmin = user?.roles?.[0] === SLASSCOM_ADMIN;
    const isCompanyAdmin = user?.roles?.[0] === COMPANY_ADMIN;
    const isInstituteAdmin = user?.roles?.[0] === INSTITUTE_ADMIN;
    return (
        <Router>
            <Switch>
                <ProtectedAllRoutes
                    path="/admin/dashboard"
                    exact
                    component={Dashboard}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/tracks"
                    exact
                    component={Tracks}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/tracks/new"
                    exact
                    component={AddNewTrack}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/tracks/:id/view"
                    exact
                    component={ViewTrack}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/tracks/:id/edit"
                    exact
                    component={EditTrack}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/challenges"
                    exact
                    component={Challenges}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/challenges/new"
                    exact
                    component={AddNewChallenge}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/challenges/:id/edit"
                    exact
                    component={EditChallenge}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/challenges/:id/view"
                    exact
                    component={ViewChallenge}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/institutes"
                    exact
                    component={Institutes}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/institutes/new"
                    exact
                    component={AddNewInstitute}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/institutes/:id/edit"
                    exact
                    component={EditInstitute}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/institutes/:id/view"
                    exact
                    component={ViewInstitute}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/companies"
                    exact
                    component={AdminCompanies}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/companies/new"
                    exact
                    component={AddCompany}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/companies/:id/edit"
                    exact
                    component={EditCompany}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/companies/:id/view"
                    exact
                    component={ViewCompany}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/achievements/certificates"
                    exact
                    component={Certificates}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/achievements/certificates/new"
                    exact
                    component={AddCertificate}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/achievements/certificates/:id/edit"
                    exact
                    component={EditCertificate}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/achievements/certificates/:id/view"
                    exact
                    component={ViewCertificate}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/events"
                    exact
                    component={AdminEvents}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/events/new"
                    exact
                    component={AddEvent}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/events/:id/edit"
                    exact
                    component={EditEvent}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/events/:id/view"
                    exact
                    component={ViewEvent}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/achievements/badges"
                    exact
                    component={Badges}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/achievements/badges/new"
                    exact
                    component={AddNewBadge}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/achievements/badges/:id/edit"
                    exact
                    component={EditBadge}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/achievements/badges/:id/view"
                    exact
                    component={ViewBadge}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/mentors"
                    exact
                    component={Mentors}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/mentors/new"
                    exact
                    component={AddNewMentor}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/mentors/:id/edit"
                    exact
                    component={EditMentor}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/mentors/:id/view"
                    exact
                    component={ViewMentor}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/notifications"
                    exact
                    component={Notifications}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/notifications/new"
                    exact
                    component={SendNotifications}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/notifications/:id/view"
                    exact
                    component={ViewNotification}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/users"
                    exact
                    component={Users}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/users/new"
                    exact
                    component={AddUser}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/users/:id/edit"
                    exact
                    component={EditUser}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedSuperRoutes
                    path="/admin/students/successStories"
                    exact
                    component={SuccessStories}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedAllRoutes
                    path="/admin/students/:id/view"
                    exact
                    component={ViewStudent}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedAllRoutes
                    path="/admin/students/:id/edit"
                    exact
                    component={EditStudent}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedAllRoutes
                    path="/admin/students"
                    exact
                    component={Students}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedAllRoutes
                    path="/admin/settings"
                    exact
                    component={Settings}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <ProtectedAllRoutes
                    path="/admin/leaderboard"
                    exact
                    component={Analytics}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <RouteWithOutLayout
                    path="/admin/settings/changeEmail"
                    exact
                    component={ChangeEmailFeedback}
                />
                <ProtectedAllRoutes
                    path="/admin/"
                    exact
                    component={Dashboard}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />

                <RouteWithLayout
                    path="/admin/signIn"
                    exact
                    component={adminSignIn}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <RouteWithLayout
                    path="/admin/reset-password"
                    exact
                    component={PasswordReset}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <RouteWithLayout
                    path="/admin/forgot-password"
                    exact
                    component={ForgetPassword}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                />
                <RouteWithOutLayout path="/admin/email/change" component={VerifyEmail} />
                <Route path="*" component={PageNotFound} />
            </Switch>
        </Router>
    );
}

export default AdminRoutes;
