import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Add from '../../../assets/svg/Add';
import { addNotificationsTab, getNotificationtTabs } from '../../../redux/notifiationTab/actions';
import { Companies } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { USER_PROFILE } from '../../../utils/storage';

import AddTabs from './AddTabs';
interface Props {
    selectedTab: number;
    setSelectedTab: (value: number) => void;
}

export default function TabView({ selectedTab, setSelectedTab }: Props): ReactElement {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = React.useState(false);
    const [tab, setTab] = useState([]);
    const [tabName, setTabName] = useState<string>();
    const [selectedTabFilteration, setSelectedTabFilteration] = useState<any>();

    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const notificationTabData = useSelector(
        (state: RootState) => state.filteredNotifications.notificationTabData
    );

    const [tags, setTags] = useState([]);
    const [institue, setInstitue] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [notificationType, setNotificationType] = useState<string>();

    useEffect(() => {
        if (selectedTab !== 1) {
            setSelectedTabFilteration(
                tab?.filter(action => action.id === selectedTab - 2).map(data => data?.filters)[0]
            );
        }
    }, [notificationTabData, selectedTab]);

    useEffect(() => {
        dispatch(getNotificationtTabs(profile?.userId, 'NOTIFICATION'));
    }, []);

    useEffect(() => {
        if (selectedTab === selectedTabFilteration?.id + 2) {
            dispatch(
                addNotificationsTab({
                    id: selectedTabFilteration?.id,
                    tabName: selectedTabFilteration?.tabName,
                    institutes: selectedTabFilteration?.institue,
                    companies: selectedTabFilteration?.company,
                    tags: selectedTabFilteration?.tags,
                    notificationType: selectedTabFilteration?.notificationType,
                })
            );
        }
    }, [selectedTab, selectedTabFilteration]);
    // }, [selectedTab, selectedTabFilteration, notificationTabData]);

    useEffect(() => {
        setTab(notificationTabData);
    }, [notificationTabData]);

    const onAdd = () => {
        if (!tab.includes(tabName)) {
            setTab([...tab, tabName]);
        }
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const company: Companies = {
            from: '1',
            to: companies.length.toString(),
        };
        dispatch(
            addNotificationsTab({
                tabName: tabName,
                institutes: institue,
                companies: company,
                tags: tags,
                notificationType: notificationType,
            })
        );
    };

    return (
        <>
            <div className="flex items-center">
                <div className={`flex w-full`}>
                    <div
                        className={`border-b-4 relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/6  ${
                            selectedTab === 1 ? `border-blue-700` : 'border-snow-500'
                        }`}
                        role="presentation"
                        onClick={() => setSelectedTab(1)}
                    >
                        <div
                            className={`py-2 text-xs sm:text-lg absolute bottom-0 flex w-full justify-center ${
                                selectedTab === 1 ? `text-blue-700 font-semibold` : 'text-black-300'
                            }`}
                        >
                            All Notifications
                        </div>
                    </div>
                    {/* {tab?.length > 0
                        ? tab?.map(action => (
                              <div
                                  className={`border-b-4 relative cursor-pointer w-1/2 md:w-1/6  ${
                                      selectedTab === action?.filters?.id + 2
                                          ? `border-blue-700`
                                          : 'border-snow-500'
                                  }`}
                                  role="presentation"
                                  key={action?.filters?.id}
                                  onClick={() => {
                                      setSelectedTab(action?.filters?.id + 2);
                                  }}
                              >
                                  <div
                                      className={`py-2 text-xs sm:text-lg absolute bottom-0 flex w-full text-center justify-center ${
                                          selectedTab === 2
                                              ? `text-blue-700 font-semibold`
                                              : 'text-black-300'
                                      }`}
                                  >
                                      {action?.filters?.tabName}
                                  </div>
                              </div>
                          ))
                        : null} */}
                    <div
                        className={`border-b-4 relative cursor-pointer pl-4 ${
                            selectedTab === 2 ? `` : ''
                        }`}
                        role="presentation"
                    >
                        <div className="py-2 text-xs sm:text-lg absolute bottom-0 text-center flex justify-center w-full">
                            {/* <button onClick={() => setShowModal(true)}>
                                <Add size={35} />
                            </button> */}

                            <AddTabs
                                isSelected={showModal}
                                setIsSelected={setShowModal}
                                onAdd={onAdd}
                                tabName={tabName}
                                setTabName={setTabName}
                                notificationType={notificationType}
                                setNotificationType={setNotificationType}
                                companies={companies}
                                setCompanies={setCompanies}
                                institue={institue}
                                setInstitue={setInstitue}
                                tags={tags}
                                setTags={setTags}
                            />
                        </div>
                    </div>

                    <div className="border-b-4 text-sm border-snow-500 w-2/4 md:w-full lg:w-full">
                        <div
                            className="text-right right-0 flex justify-end text-blue-900 py-1"
                            role="presentation"
                        >
                            <button
                                className="py-1 px-2 md:px-4 w-36 bg-blue-700 text-white text-sm rounded-sm focus:outline-none h-12"
                                onClick={() => history.push('/admin/notifications/new')}
                            >
                                Send Notification
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
