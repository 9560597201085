import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { INSTITUTE_ADMIN } from '../../../../redux/authAdmin/reducers';
import { CompanyType } from '../../../../redux/companies/reducers';
import { InstitutesType } from '../../../../redux/institutes/reducers';
import { RootState } from '../../../../redux/rootReducer';

interface Props {
    selectedTab: number;
    setSelectedTab: (value: number) => void;
    setInstitute: (value: InstitutesType) => void;
    setCompany: (value: CompanyType) => void;
}

export default function TableTabView({
    selectedTab,
    setSelectedTab,
    setInstitute,
    setCompany,
}: Props): ReactElement {
    const user = useSelector((state: RootState) => state.users.User);
    return (
        <div className="flex flex-row">
            {user?.roles[0]?.roleName !== INSTITUTE_ADMIN ? (
                <>
                    <div
                        className={`${
                            selectedTab === 1
                                ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                                : 'text-black-300 border-snow-500'
                        } flex text-sm md:text-base xl:text-lg mr-6 cursor-pointer items-center`}
                        onClick={() => {
                            setSelectedTab(1);
                            setInstitute(null);
                            setCompany(null);
                        }}
                    >
                        All Registration
                    </div>
                    <div
                        className={`${
                            selectedTab === 2
                                ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                                : 'text-black-300 border-snow-500'
                        } flex text-sm md:text-base xl:text-lg mr-6 cursor-pointer items-center`}
                        onClick={() => {
                            setSelectedTab(2);
                            setInstitute(null);
                            setCompany(null);
                        }}
                    >
                        Institute-wise Registration
                    </div>
                    <div
                        className={`${
                            selectedTab === 3
                                ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                                : 'text-black-300 border-snow-500'
                        } flex text-sm md:text-base xl:text-lg mr-6 cursor-pointer items-center`}
                        onClick={() => {
                            setSelectedTab(3);
                            setInstitute(null);
                            setCompany(null);
                        }}
                    >
                        Company-wise Registration
                    </div>
                </>
            ) : (
                <div
                    className={`text-black-300 border-snow-500 flex text-sm md:text-base xl:text-lg mr-6 items-center`}
                >
                    Institute-wise Registration
                </div>
            )}
        </div>
    );
}
