import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { UserProfileType } from '../../../redux/authAdmin/reducers';
import { clearWebAuth, logoutWeb } from '../../../redux/rootActions';

const SidebarLink = styled(Link)`
    display: flex;
    color: #4d4e52;
    justify-content: space-between;
    align-items: center;
    padding-left: 11px;
    list-style: none;
    height: 56px;
    width: 50px;
    text-decoration: none;
    font-size: 18px;
    &:hover {
        background: #4c2ec2;
        color: white;
        cursor: pointer;
    }
    @media screen and (min-width: 1024px) {
        width: 290px;
        height: 50px;
        padding-left: 41px;
    }
`;
const DropdownLink = styled(Link)`
    height: 0px;
    padding-left: 2rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #4d4e52;
    font-size: 18px;
    &:hover {
        background: #c4c1cc;
        cursor: pointer;
    }
`;
interface Props {
    item: any;
    focused: string;
    profile: UserProfileType;
    isExpanded?: boolean;
    isTourActivated?: boolean;
}
const SubMenu: React.FC<Props> = ({ item, focused, profile, isExpanded, isTourActivated }) => {
    const [subnav, setSubnav] = useState(false);
    const location = useLocation();
    const [hoverOver, setHoverOver] = useState(false);
    const showSubnav = () => setSubnav(!subnav);
    const dispatch = useDispatch();
    const history = useHistory();

    if (!profile) {
        dispatch(logoutWeb());
        history.push('/signin');
    }

    return (
        <>
            {profile && (
                <SidebarLink
                    to={`/${item.path}`}
                    onClick={item.subNav && showSubnav}
                    onMouseOverCapture={() => {
                        setHoverOver(true);
                    }}
                    onMouseLeave={() => {
                        setHoverOver(false);
                    }}
                    style={
                        focused === item.title
                            ? { background: '#4C2EC2', color: 'white' }
                            : focused && item?.path === location?.pathname
                            ? { background: '#75BEE7', color: 'white' }
                            : !focused && location?.pathname.includes(item?.path)
                            ? { background: '#4C2EC2', color: 'white' }
                            : {}
                    }
                    className={isExpanded ? 'expand-sidebar' : ''}
                >
                    <div className="flex items-center">
                        <span className="hidden lg:block w-6">
                            {focused === item.title ||
                            hoverOver === true ||
                            (location?.pathname.includes(item?.path) && !isTourActivated)
                                ? item.icon2
                                : item.icon}
                        </span>
                        <span className="lg:hidden">
                            {focused === item.title ||
                            hoverOver === true ||
                            location?.pathname.includes(item?.path)
                                ? item.getIcon2(24)
                                : item.getIcon(24)}
                        </span>
                        {item?.path !== location?.pathname ? (
                            <span
                                className={` mx-6 text-base ${
                                    !isExpanded ? 'hidden lg:block' : 'block'
                                }`}
                            >
                                {item.title}
                            </span>
                        ) : (
                            <span
                                className={`font-bold text-base mx-6 ${
                                    !isExpanded ? 'hidden lg:block' : 'block'
                                } text-white`}
                            >
                                {item.title}
                            </span>
                        )}
                    </div>
                    <div>
                        {item.subNav && subnav
                            ? item.iconOpened
                            : item.subNav
                            ? item.iconClosed
                            : null}
                    </div>
                </SidebarLink>
            )}
            {subnav &&
                item.subNav.map(
                    (
                        object: { path: any; icon: any; title: any },
                        index: React.Key | null | undefined
                    ) => {
                        return (
                            <DropdownLink
                                to={object.path}
                                key={index}
                                className={`${
                                    object?.path === location?.pathname
                                        ? 'border-l-4 border-blue-600 bg-gray-300'
                                        : ''
                                }`}
                            >
                                {object.icon}
                                <span className={`ml-4`}>{object.title}</span>
                            </DropdownLink>
                        );
                    }
                )}
        </>
    );
};

export default SubMenu;
