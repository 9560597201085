import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../redux/rootReducer';
import { getStudentTracksChallenges } from '../../../../redux/rootActions';
import { TracksType, TracksByChallengeStatusType } from '../../../../redux/tracks/reducers';
import { ChallengesType, StudentTracksChallengesType } from '../../../../redux/challenges/reducers';
import { s3UrlPrefix } from '../../../../constants';

import { Line } from 'rc-progress';
import useWidth from '../../../hooks/useWidth';
import Loader from '../../../../assets/svg/Loader';

interface Props {
    routerHistory: any;
    profileData: any;
    tracksData: TracksByChallengeStatusType;
    challengeId: any;
    setSelectedChallengeId: any;
    setModal: any;
    selectTrack: any;
    setSelectTrack: any;
    setSelectedChallenge: any;
    selectedChallenge: any;
}

const Challenges: React.FC<Props> = ({
    routerHistory,
    profileData,
    tracksData,
    setSelectedChallengeId,
    challengeId,
    setModal,
    setSelectTrack,
    selectTrack,
    setSelectedChallenge,
    selectedChallenge,
}) => {
    const dispatch = useDispatch();
    const viewPortWidth = useWidth();
    const challengesAPIData = useSelector(
        (state: RootState) => state.challenges.studentTracksChallengesData
    );

    const [selectedTab, setSelectedTab] = useState('onGoing');
    const [selectedTrack, setSelectedTrack] = useState<TracksType>(null);
    const [challanges, setChallenges] = useState<StudentTracksChallengesType>(null);
    const studentTracksChallengesLoading = useSelector(
        (state: RootState) => state.challenges.studentTracksChallengesLoading
    );

    const getChallenges = (track: TracksType) => {
        setSelectedTrack(track);
        dispatch(getStudentTracksChallenges(profileData?.userId, track.id));
    };

    useEffect(() => {
        challengesAPIData && setChallenges(challengesAPIData);
    }, [dispatch, challengesAPIData]);

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div className="mb-8">
                    <div className="font-bold font-manrope text-xl mb-3">My Progression</div>
                    <div
                        className={`${
                            tracksData && tracksData[selectedTab].length > 0
                                ? 'bg-purple-85 px-8 py-6 rounded'
                                : 'bg-transparent'
                        }`}
                    >
                        <div className="flex flex-row mb-4">
                            <div
                                className={`${
                                    selectedTab === 'onGoing'
                                        ? 'border-b-2 text-purple-950 border-purple-950 font-bold pb-1'
                                        : ' text-gray-500'
                                }  mr-4 cursor-pointer`}
                                onClick={() => {
                                    setSelectedTrack(null);
                                    setChallenges(null);
                                    setSelectedTab('onGoing');
                                }}
                            >
                                On going
                            </div>
                            <div
                                className={`${
                                    selectedTab === 'completed'
                                        ? 'border-b-2 text-purple-950 border-purple-950 font-bold pb-1'
                                        : ' text-gray-500'
                                }  mr-4 cursor-pointer`}
                                onClick={() => {
                                    setSelectedTrack(null);
                                    setChallenges(null);
                                    setSelectedTab('completed');
                                }}
                            >
                                Completed
                            </div>
                        </div>
                        <div className="flex flex-wrap gap-3">
                            {tracksData &&
                                tracksData[selectedTab].map((track: TracksType) => (
                                    <div
                                        key={track.id}
                                        onClick={() => getChallenges(track)}
                                        style={{ borderWidth: 1 }}
                                        className={`rounded flex items-center h-11 px-3 text-xs font-normal border-purple-950 cursor-pointer ${
                                            selectedTrack && selectedTrack?.id === track.id
                                                ? 'bg-purple-950 text-white'
                                                : 'bg-white text-purple-950'
                                        }`}
                                    >
                                        {track.trackName}
                                    </div>
                                ))}
                        </div>
                        {tracksData && tracksData[selectedTab].length > 0 ? (
                            <>
                                {selectedTrack && (
                                    <div className="mt-6">
                                        <div className="text-lg font-bold mb-3">
                                            {selectedTrack && selectedTrack?.trackName}
                                        </div>
                                        <div className="flex flex-col gap-11">
                                            {studentTracksChallengesLoading ? (
                                                <Loader />
                                            ) : (
                                                challanges &&
                                                challanges[selectedTab].map(
                                                    (challenge: ChallengesType) => (
                                                        <div
                                                            key={challenge.id}
                                                            className="flex rounded"
                                                        >
                                                            <img
                                                                style={{
                                                                    width: '180px',
                                                                    height: '180px',
                                                                }}
                                                                className="rounded"
                                                                src={
                                                                    challenge.imageLink
                                                                        ? `${s3UrlPrefix}${challenge.imageLink}`
                                                                        : `/assets/challenge-image-skeleton-square.png`
                                                                }
                                                            />
                                                            <div className="w-full ml-4">
                                                                <p className="text-lg font-bold mb-3">
                                                                    {challenge.challengeName}
                                                                </p>
                                                                <p className="challengeDescription h-8">
                                                                    {challenge.challengeDescription}
                                                                </p>
                                                                <div className="flex flex-row w-2/3">
                                                                    <div className=" flex-col">
                                                                        <div className="challenge-progress-line w-full py-2">
                                                                            <Line
                                                                                percent={
                                                                                    (challenge.earnedMarks /
                                                                                        challenge.totalMarks) *
                                                                                    100
                                                                                }
                                                                                strokeWidth={1}
                                                                                strokeColor={`${
                                                                                    challenge.isChallengeRepublished &&
                                                                                    selectedTab ===
                                                                                        'completed'
                                                                                        ? '#F16341'
                                                                                        : '#4C2EC2'
                                                                                }`}
                                                                            />
                                                                            {challenge.totalMarks -
                                                                                challenge.earnedMarks ===
                                                                            0 ? (
                                                                                <div className="text-xs font-normal mt-1">
                                                                                    You have
                                                                                    completed this
                                                                                    challenge with
                                                                                    100% marks
                                                                                </div>
                                                                            ) : (
                                                                                <div className="text-xs font-normal mt-1">
                                                                                    Earn{' '}
                                                                                    {`${
                                                                                        challenge.totalMarks &&
                                                                                        challenge.earnedMarks
                                                                                            ? (challenge.totalMarks -
                                                                                                  challenge.earnedMarks <
                                                                                              0
                                                                                                  ? 0
                                                                                                  : challenge.totalMarks -
                                                                                                    challenge.earnedMarks
                                                                                              ).toFixed(
                                                                                                  2
                                                                                              )
                                                                                            : challenge.earnedMarks.toFixed(
                                                                                                  2
                                                                                              )
                                                                                    }`}{' '}
                                                                                    XP points more
                                                                                    to climb up your
                                                                                    position in the
                                                                                    leaderboard
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-xs font-normal w-44 mx-1 mt-1">
                                                                        {`${challenge.earnedMarks} / ${challenge.totalMarks} XP`}
                                                                    </div>
                                                                </div>
                                                                <div className="flex">
                                                                    {selectedTab ===
                                                                        'completed' && (
                                                                        <div className="text-sm text-green-600 font-bold h-10 pr-5 flex items-center">
                                                                            Completed
                                                                        </div>
                                                                    )}
                                                                    <button
                                                                        onClick={() => {
                                                                            if (
                                                                                challenge.isChallengeRepublished &&
                                                                                selectedTab ===
                                                                                    'completed'
                                                                            ) {
                                                                                setSelectedChallengeId(
                                                                                    challenge.challengeId
                                                                                );
                                                                                setSelectTrack(
                                                                                    selectedTrack
                                                                                );
                                                                                setSelectedChallenge(
                                                                                    challenge
                                                                                );
                                                                                setModal(true);
                                                                            } else {
                                                                                routerHistory.push({
                                                                                    pathname: `/dashBoard/learn/Challenges/${challenge.challengeId}`,
                                                                                    state: {
                                                                                        path: `/dashBoard/myProgress`,
                                                                                        track: selectedTrack,
                                                                                        challenges:
                                                                                            challenge,
                                                                                    },
                                                                                });
                                                                            }
                                                                        }}
                                                                        className={`rounded border-2 font-bold h-10 px-11 text-xs ${
                                                                            challenge.isChallengeRepublished &&
                                                                            selectedTab ===
                                                                                'completed'
                                                                                ? 'border-orange-550 text-orange-550'
                                                                                : 'border-purple-950 text-purple-950'
                                                                        }`}
                                                                    >
                                                                        {selectedTab === 'onGoing'
                                                                            ? 'Continue'
                                                                            : 'Try Again'}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div>
                                <div className="bg-purple-85 rounded flex justify-between">
                                    <div className="flex items-center py-9 px-6">
                                        <div className="space-y-5">
                                            <div className="text-base font-bold">
                                                {selectedTab === 'onGoing'
                                                    ? tracksData?.completed.length > 0 &&
                                                      tracksData?.onGoing.length === 0
                                                        ? null
                                                        : 'On Going'
                                                    : 'Completed'}
                                            </div>
                                            {tracksData &&
                                            tracksData.completed.length > 0 &&
                                            selectedTab === 'onGoing' ? (
                                                <div className="text-base font-normal">
                                                    You have no ongoing challenges available.
                                                </div>
                                            ) : (
                                                <div className="text-base font-normal">
                                                    You don&apos;t have any{' '}
                                                    {selectedTab === 'onGoing'
                                                        ? 'ongoing'
                                                        : 'completed'}{' '}
                                                    challenges. This is will be a great time{' '}
                                                    <br></br> to start working on the challenges of
                                                    your interest.
                                                </div>
                                            )}

                                            <div className="flex space-x-4">
                                                <div
                                                    onClick={() => {
                                                        routerHistory.push(`/dashBoard/learn`);
                                                    }}
                                                    className="border-1 rounded bg-purple-950 text-base text-white px-9 h-11 flex justify-center items-center font-bold cursor-pointer"
                                                >
                                                    Start challenges
                                                </div>
                                                {tracksData &&
                                                tracksData.completed.length > 0 &&
                                                selectedTab === 'onGoing' ? null : (
                                                    <div
                                                        onClick={() => {
                                                            routerHistory.push({
                                                                pathname: `/dashBoard/help`,
                                                            });
                                                        }}
                                                        className=" underline text-base text-purple-950 flex justify-center items-center font-normal cursor-pointer whitespace-nowrap"
                                                    >
                                                        How to start?
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex pr-6 py-2">
                                        <div>
                                            <img
                                                className="h-full w-full"
                                                src={`/assets/emptyOnGoingTracks.png`}
                                                alt="logo"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="mb-8">
                    <div className="font-bold font-manrope mb-3">My Progression</div>
                    <div
                        className={`${
                            tracksData && tracksData[selectedTab].length > 0
                                ? 'bg-purple-85 px-8 py-6 rounded'
                                : 'bg-transparent'
                        }`}
                    >
                        <div className="flex flex-row mb-4">
                            <div
                                className={`${
                                    selectedTab === 'onGoing'
                                        ? 'border-b-2 text-purple-950 border-purple-950 font-bold pb-1'
                                        : ' text-gray-500'
                                }  mr-4 cursor-pointer text-xs`}
                                onClick={() => {
                                    setSelectedTrack(null);
                                    setChallenges(null);
                                    setSelectedTab('onGoing');
                                }}
                            >
                                On going
                            </div>
                            <div
                                className={`${
                                    selectedTab === 'completed'
                                        ? 'border-b-2 text-purple-950 border-purple-950 font-bold pb-1'
                                        : ' text-gray-500'
                                }  mr-4 cursor-pointer text-xs`}
                                onClick={() => {
                                    setSelectedTrack(null);
                                    setChallenges(null);
                                    setSelectedTab('completed');
                                }}
                            >
                                Completed
                            </div>
                        </div>
                        <div className="flex flex-wrap gap-3">
                            {tracksData &&
                                tracksData[selectedTab].map((track: TracksType) => (
                                    <div
                                        key={track.id}
                                        onClick={() => getChallenges(track)}
                                        style={{ borderWidth: 1 }}
                                        className={`rounded flex items-center h-11 px-3 text-xs font-normal border-purple-950 cursor-pointer ${
                                            selectedTrack && selectedTrack?.id === track.id
                                                ? 'bg-purple-950 text-white'
                                                : 'bg-white text-purple-950'
                                        }`}
                                    >
                                        {track.trackName}
                                    </div>
                                ))}
                        </div>
                    </div>
                    {tracksData && tracksData[selectedTab].length > 0 ? (
                        <>
                            {selectedTrack && (
                                <div className="mt-6">
                                    <div className="text-lg font-bold mb-3">
                                        {selectedTrack && selectedTrack?.trackName}
                                    </div>
                                    <div className="flex flex-col gap-11">
                                        {studentTracksChallengesLoading ? (
                                            <Loader />
                                        ) : (
                                            challanges &&
                                            challanges[selectedTab].map(
                                                (challenge: ChallengesType) => (
                                                    <div
                                                        key={challenge.id}
                                                        className="flex gap-x-4 my-5"
                                                    >
                                                        <img
                                                            style={{
                                                                width: '60px',
                                                                height: '60px',
                                                            }}
                                                            className="rounded-lg"
                                                            src={
                                                                challenge.imageLink
                                                                    ? `${s3UrlPrefix}${challenge.imageLink}`
                                                                    : `/assets/challenge-image-skeleton-square.png`
                                                            }
                                                        />
                                                        <div className="flex flex-col gap-y-4">
                                                            <div className="font-bold text-xs font-manrope">
                                                                {challenge?.challengeName}
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className="challengeDescription font-normal mr-2 w-11/12"
                                                                    style={{ fontSize: 10 }}
                                                                >
                                                                    {challenge.challengeDescription}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="flex flex-col w-full">
                                                                    <div className="challenge-progress-line w-2/3 py-2">
                                                                        <Line
                                                                            percent={
                                                                                isNaN(
                                                                                    (challenge.earnedMarks /
                                                                                        challenge.totalMarks) *
                                                                                        100
                                                                                )
                                                                                    ? 0
                                                                                    : (challenge.earnedMarks /
                                                                                          challenge.totalMarks) *
                                                                                      100
                                                                            }
                                                                            strokeWidth={1}
                                                                            strokeColor={`${
                                                                                challenge.isChallengeRepublished &&
                                                                                selectedTab ===
                                                                                    'completed'
                                                                                    ? '#F16341'
                                                                                    : '#4C2EC2'
                                                                            }`}
                                                                        />
                                                                    </div>
                                                                    <div className="flex flex-row gap-x-2">
                                                                        <div
                                                                            className="font-bold"
                                                                            style={{ fontSize: 10 }}
                                                                        >
                                                                            {`${
                                                                                challenge.earnedMarks
                                                                                    ? challenge.earnedMarks
                                                                                    : 0
                                                                            } XP`}
                                                                        </div>
                                                                        {challenge.totalMarks -
                                                                            challenge.earnedMarks ===
                                                                        0 ? (
                                                                            <div
                                                                                style={{
                                                                                    fontSize: 10,
                                                                                }}
                                                                            >
                                                                                You have completed
                                                                                this challenge with
                                                                                100% marks
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                style={{
                                                                                    fontSize: 10,
                                                                                }}
                                                                            >
                                                                                Earn
                                                                                {` ${
                                                                                    challenge.totalMarks &&
                                                                                    challenge.earnedMarks
                                                                                        ? (challenge.totalMarks -
                                                                                              challenge.earnedMarks <
                                                                                          0
                                                                                              ? 0
                                                                                              : challenge.totalMarks -
                                                                                                challenge.earnedMarks
                                                                                          ).toFixed(
                                                                                              2
                                                                                          )
                                                                                        : challenge.earnedMarks.toFixed(
                                                                                              2
                                                                                          )
                                                                                } `}
                                                                                XP points more to
                                                                                climb up your
                                                                                position in the
                                                                                leaderboard
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex">
                                                                {selectedTab === 'completed' && (
                                                                    <div className="text-xs text-purple-950 font-bold h-10 pr-5 flex items-center">
                                                                        Completed
                                                                    </div>
                                                                )}
                                                                <button
                                                                    onClick={() => {
                                                                        if (
                                                                            challenge.isChallengeRepublished &&
                                                                            selectedTab ===
                                                                                'completed'
                                                                        ) {
                                                                            setSelectedChallengeId(
                                                                                challenge.challengeId
                                                                            );
                                                                            setSelectTrack(
                                                                                selectedTrack
                                                                            );
                                                                            setSelectedChallenge(
                                                                                challenge
                                                                            );
                                                                            setModal(true);
                                                                        } else {
                                                                            routerHistory.push({
                                                                                pathname: `/dashBoard/learn/Challenges/${challenge.challengeId}`,
                                                                                state: {
                                                                                    path: `/dashBoard/myProgress`,
                                                                                    track: selectedTrack,
                                                                                    challenges:
                                                                                        challenge,
                                                                                },
                                                                            });
                                                                        }
                                                                    }}
                                                                    className={`rounded border-2 text-sm font-bold h-10 px-5 ${
                                                                        challenge.isChallengeRepublished &&
                                                                        selectedTab === 'completed'
                                                                            ? 'border-orange-550 text-orange-550'
                                                                            : 'border-purple-950 text-purple-950'
                                                                    }`}
                                                                >
                                                                    {selectedTab === 'onGoing'
                                                                        ? 'Continue'
                                                                        : 'Try Again'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div>
                            <div className="bg-purple-85 rounded flex justify-between">
                                <div className="flex flex-col gap-y-4 p-8">
                                    <div>
                                        <img
                                            className="w-44"
                                            src={`/assets/emptyOnGoingTracks.png`}
                                            alt="logo"
                                        />
                                    </div>
                                    <div className="text-xs font-bold">
                                        {selectedTab === 'onGoing'
                                            ? tracksData?.completed.length > 0 &&
                                              tracksData?.onGoing.length === 0
                                                ? null
                                                : 'On Going'
                                            : 'Completed'}
                                    </div>
                                    {tracksData &&
                                    tracksData.completed.length > 0 &&
                                    selectedTab === 'onGoing' ? (
                                        <div className="text-xs font-normal">
                                            You have no ongoing challenges available.
                                        </div>
                                    ) : (
                                        <div className="text-xs font-normal">
                                            You don&apos;t have any{' '}
                                            {selectedTab === 'onGoing' ? 'ongoing' : 'completed'}{' '}
                                            challenges. This is will be a great time to start
                                            working on the challenges of your interest.
                                        </div>
                                    )}

                                    <div className="flex justify-between">
                                        <div
                                            onClick={() => {
                                                routerHistory.push(`/dashBoard/learn`);
                                            }}
                                            className="border-1 rounded bg-purple-950 text-white px-3 h-11 flex justify-center items-center font-bold cursor-pointer"
                                        >
                                            Start challenges
                                        </div>
                                        {tracksData &&
                                        tracksData.completed.length > 0 &&
                                        selectedTab === 'onGoing' ? null : (
                                            <div
                                                onClick={() => {
                                                    routerHistory.push({
                                                        pathname: `/dashBoard/help`,
                                                    });
                                                }}
                                                className="ml-2 underline text-purple-950 flex justify-center items-center font-normal cursor-pointer whitespace-nowrap"
                                            >
                                                How to start?
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Challenges;
