import axios from 'axios';
import { API_URL } from '../../../constants';
import { axiosConfig } from '../../../utils/axiosApi';
import { getErrorMessage } from '../../../utils/axiosErrorHandler';

const PREFIX = '@WEB_PUBLIC_PROFILE';

export const GET_PUBLIC_PROFILE = PREFIX + 'GET_PUBLIC_PROFILE';
export const GET_PUBLIC_PROFILE_SUCCESS = PREFIX + 'GET_PUBLIC_PROFILE_SUCCESS';
export const GET_PUBLIC_PROFILE_FAILED = PREFIX + 'GET_PUBLIC_PROFILE_FAILED';

export const getPublicProfileData = studentId => async (dispatch: any) => {
    try {
        dispatch({ type: GET_PUBLIC_PROFILE });
        const config = await axiosConfig(false);
        const res = await axios.get(`${API_URL}/users/public-profile/${studentId}`, config);
        if (res.data?.status) {
            dispatch({ type: GET_PUBLIC_PROFILE_SUCCESS, payload: res.data.responseDto });
        } else {
            dispatch({ type: GET_PUBLIC_PROFILE_FAILED, payload: res.data.errorDescription });
        }
    } catch (error) {
        dispatch({ type: GET_PUBLIC_PROFILE_FAILED, payload: getErrorMessage(error) });
    }
};
