import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { useTable, useRowSelect, usePagination } from 'react-table';
import * as BsIcons from 'react-icons/bs';
import * as HiIcons from 'react-icons/hi';
import { IconContext } from 'react-icons';
import TableTabView from './TableTabView';
import { EventType } from '../../../redux/events/reducers';
import { useHistory } from 'react-router-dom';

interface Props {
    data: EventType[];
    columns: any;
    fetchData;
    pageCount: number;
    selectedTab: number;
    setSelectedTab: (value: number) => void;
    backToFirstRef: any;
}

const NotificationsTable = ({
    columns,
    data,
    fetchData,
    pageCount: controlledPageCount,
    selectedTab,
    setSelectedTab,
    backToFirstRef,
}: Props) => {
    const history = useHistory();
    const [clickedColumnId, setClickedColumnId] = useState();
    const [showRecordsDropdown, setShowRecordsDropdown] = useState(false);
    const [goto, setGoto] = useState<any>();
    const tabCounts = useSelector((state: RootState) => state.notifications.count);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        gotoPage,
        allColumns,
        setHiddenColumns,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
            manualPagination: true,
            pageCount: controlledPageCount,
        },
        usePagination,
        useRowSelect
    );
    const VisibleColumns = allColumns.filter(
        col => col.isVisible === true && col.id !== 'selection' && col.id !== 'actionColumn'
    );

    useEffect(() => {
        gotoPage(0);
    }, [selectedTab, gotoPage]);

    useImperativeHandle(backToFirstRef, () => ({
        goToFirst() {
            gotoPage(0);
        },
    }));

    useEffect(() => {
        fetchData({ pageIndex, pageSize });
    }, [fetchData, pageIndex, pageSize]);

    const viewNotification = (notification: any) => {
        history.push({
            pathname: `/admin/notifications/${notification.id}/view`,
            state: { notification: notification },
        });
    };
    return (
        <section className="h-full">
            <div className="flex justify-between w-full items-end mb-4">
                <div className="w-5/6 md:w-4/5">
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        tabCounts={tabCounts}
                    />
                </div>
                <div className="mt-4 ml-1 md:ml-4 w-12 md:w-32 flex justify-end items-center">
                    <div className="hidden md:flex md:w-1/2">Records</div>
                    <div
                        className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-1/2 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                    >
                        <div
                            className="flex justify-between w-full"
                            onClick={() => setShowRecordsDropdown(true)}
                        >
                            <div className="text-gray-700 font-medium pl-2">{pageSize}</div>
                            <div className="">
                                <div className="flex flex-col">
                                    <HiIcons.HiChevronUp className="-mb-2" />
                                    <HiIcons.HiChevronDown className="" />
                                </div>
                            </div>
                        </div>
                        {showRecordsDropdown ? (
                            <ul
                                className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                role="menu"
                                onMouseLeave={() => setShowRecordsDropdown(false)}
                            >
                                {[10, 25, 50, 100].map(value => (
                                    <div
                                        className="p-2 hover:bg-gray-200 "
                                        key={value}
                                        onClick={() => {
                                            setPageSize(value);
                                            setShowRecordsDropdown(false);
                                        }}
                                    >
                                        {value}
                                    </div>
                                ))}
                            </ul>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="w-full mb-8 overflow-hidden h-full">
                <div className="w-full overflow-x-auto pl-1 h-full" style={{ minHeight: '50vh' }}>
                    <table className="w-full" {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr
                                    key={headerGroup}
                                    {...headerGroup.getHeaderGroupProps()}
                                    className="text-sm xl:text-base font-medium tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-600"
                                >
                                    {headerGroup.headers.map(column => (
                                        <th
                                            key={column}
                                            {...column.getHeaderProps()}
                                            className={`px-2 py-3 border ${
                                                column.id === clickedColumnId ? 'relative' : ''
                                            } `}
                                        >
                                            <div className="flex justify-between">
                                                <div className="whitespace-nowrap">
                                                    {column.render('Header')}
                                                </div>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="bg-white" {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <tr key={row} {...row.getRowProps()} className="text-gray-700">
                                        {row.cells.map(cell => {
                                            return (
                                                <td
                                                    key={cell}
                                                    {...cell.getCellProps({})}
                                                    className={`py-3 text-sm font-semibold border px-2 cursor-pointer `}
                                                    onClick={() => {
                                                        viewNotification(row.original);
                                                    }}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="pb-4">
                <div className="flex justify-end  mb-2">
                    <IconContext.Provider
                        value={{ color: !canPreviousPage ? '#bbbcbc' : '#707a92' }}
                    >
                        <button
                            className="text-xl font-medium text-gray-900"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            <BsIcons.BsChevronLeft className="" />
                        </button>
                    </IconContext.Provider>
                    <span className="flex items-center font-semibold text-gray-700">
                        <p className="border px-2 mx-2  my-auto">{pageIndex + 1}</p>
                        of
                        <p className="border px-2 mx-2  my-auto">
                            {pageOptions.length === 0 ? '1' : pageOptions.length}
                        </p>
                    </span>
                    <IconContext.Provider value={{ color: !canNextPage ? '#bbbcbc' : '#707a92' }}>
                        <button
                            className="text-xl font-medium text-gray-900"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        >
                            <BsIcons.BsChevronRight className="" />
                        </button>
                    </IconContext.Provider>
                </div>
                <div className="flex justify-end font-bold">
                    <div
                        className="bg-gray-200 border-gray-400 border mr-1 rounded px-1 cursor-pointer"
                        onClick={() => {
                            gotoPage(goto - 1);
                        }}
                    >
                        Go
                    </div>
                    to
                    <input
                        className="border font-bold px-2 mr-7 ml-2 w-8 focus:outline-none rounded"
                        value={goto}
                        type="number"
                        onChange={e => setGoto(e.target.value)}
                    />
                </div>
            </div>
        </section>
    );
};

export default NotificationsTable;
