import React, { ReactElement, useEffect, useState } from 'react';
import * as HiIcons from 'react-icons/hi';
import { months } from '../../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { getTags, getTracks } from '../../../redux/rootActions';

interface Props {
    homeTown: string;
    setHomeTown: (value: string) => void;
    average: string;
    setAverage: (value: string) => void;
    tags: any[];
    setTags: (value: any[]) => void;
    gender: string;
    setGender: (value: string) => void;
    tracks: any[];
    setTracks: (value: any[]) => void;
    institue: any[];
    setInstitue: (value: any[]) => void;
    numberOfChallenges: string;
    setNumberOfChallenges: (value: string) => void;
}

export default function AddTabModal({
    homeTown,
    setHomeTown,
    average,
    setAverage,
    tags,
    setTags,
    tracks,
    setTracks,
    gender,
    setGender,
    institue,
    setInstitue,
    numberOfChallenges,
    setNumberOfChallenges,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const trackData = useSelector((state: RootState) => state.tracks.trackData);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [trackFilterAction, setTrackFilterAction] = useState<string>('Select a column');
    const instituteData = useSelector((state: RootState) => state.institutes.institutesData);

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const month = months[new Date().getMonth()];
    const day = new Date().getDate();
    const year = new Date().getFullYear();

    const EndMonth = months[new Date().getMonth()];
    const EndDay = new Date().getDate();
    const EndYear = new Date().getFullYear();

    const date = new Date(year, new Date().getMonth(), 1);
    const days = [];
    while (date.getMonth() === new Date().getMonth()) {
        days.push(new Date(date).getDate());
        date.setDate(date.getDate() + 1);
    }

    const secondDate = new Date(year, months.indexOf(EndMonth), 1);
    const Enddays = [];
    while (secondDate.getMonth() === months.indexOf(EndMonth)) {
        Enddays.push(new Date(secondDate).getDate());
        secondDate.setDate(secondDate.getDate() + 1);
    }

    const years = [];
    let startYear = new Date().getFullYear();
    const maxYear = startYear + 10;
    for (let i = startYear; i <= maxYear; i++) {
        years.push(startYear);
        startYear++;
    }

    const TrackFilter = [
        { id: 0, val: 'Hometown' },
        { id: 1, val: 'Tracks' },
        { id: 3, val: 'Average' },
        { id: 4, val: 'Gender' },
        { id: 4, val: 'Tag' },
        { id: 5, val: 'Institute' },
        { id: 5, val: 'Number of challenges' },
    ];

    const GenderFilter = [
        { id: 0, val: 'Male' },
        { id: 1, val: 'Female' },
        { id: 2, val: 'Other' },
    ];

    const AverageFilter = [
        { id: 0, val: '0% - 35%' },
        { id: 1, val: '36% - 55%' },
        { id: 2, val: '56% - 80%' },
        { id: 2, val: '81% - 100%' },
    ];

    const HometownFilter = [
        { id: 0, val: 'Colombo' },
        { id: 1, val: 'Kandy' },
        { id: 2, val: 'Galle' },
        { id: 2, val: 'Trincomalee' },
    ];

    useEffect(() => {
        dispatch(getTags(true));
        dispatch(getTracks(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={`flex flex-col my-1.5 ${dropdownVisible && 'h-60'}`}>
                <div className="flex flex-col my-1.5">Select filter column</div>
                <div
                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                >
                    <div
                        className="flex justify-between w-full"
                        onClick={() => setDropdownVisible(true)}
                    >
                        <div className="text-gray-700 font-medium pl-2">{trackFilterAction}</div>
                        <div className="">
                            <div className="flex flex-col">
                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                <HiIcons.HiChevronDown className="mr-2" />
                            </div>
                        </div>
                    </div>
                    {dropdownVisible ? (
                        <ul
                            className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            onMouseLeave={() => setDropdownVisible(false)}
                        >
                            {TrackFilter.map(action => (
                                <div
                                    className="p-2 pl-2 hover:bg-gray-200 "
                                    key={action.id}
                                    onClick={() => {
                                        setTrackFilterAction(action.val);
                                        setDropdownVisible(false);
                                    }}
                                >
                                    {action.val}
                                </div>
                            ))}
                        </ul>
                    ) : null}
                </div>
            </div>
            {trackFilterAction === 'Hometown' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select your home town</label>
                        {HometownFilter.map(avgData => (
                            // eslint-disable-next-line react/jsx-key
                            <div className="my-1">
                                <label>
                                    <input
                                        className="mr-1"
                                        type="radio"
                                        name="checkboxHomeTown"
                                        value={avgData.val}
                                        onClick={e => {
                                            setHomeTown(e.currentTarget.value);
                                        }}
                                    />
                                    {avgData.val}
                                </label>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}

            {trackFilterAction === 'Tracks' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select the set of tracks</label>
                        {trackData.map(track => (
                            // eslint-disable-next-line react/jsx-key
                            <div className="my-1">
                                <label>
                                    <input
                                        className="mr-1"
                                        type="checkbox"
                                        name="checkbox"
                                        value={track.id}
                                        onClick={e => {
                                            if (tracks.includes(parseInt(e.currentTarget.value))) {
                                                setTracks(
                                                    tracks.filter(
                                                        item =>
                                                            item !== parseInt(e.currentTarget.value)
                                                    )
                                                );
                                            } else {
                                                setTracks([
                                                    ...tracks,
                                                    parseInt(e.currentTarget.value),
                                                ]);
                                            }
                                        }}
                                    />
                                    {track.trackName}
                                </label>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}

            {trackFilterAction === 'Average' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select the range of average</label>
                        {AverageFilter.map(avgData => (
                            // eslint-disable-next-line react/jsx-key
                            <div className="my-1">
                                <label>
                                    <input
                                        className="mr-1"
                                        type="radio"
                                        name="checkbox"
                                        value={avgData.val}
                                        onClick={e => {
                                            setAverage(e.currentTarget.value);
                                        }}
                                    />
                                    {avgData.val}
                                </label>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}

            {trackFilterAction === 'Gender' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select your gender</label>
                        {GenderFilter.map(genderData => (
                            // eslint-disable-next-line react/jsx-key
                            <div className="my-1">
                                <label>
                                    <input
                                        className="mr-1"
                                        type="radio"
                                        name="checkboxgender"
                                        value={genderData.val}
                                        onClick={e => {
                                            setGender(e.currentTarget.value);
                                        }}
                                    />
                                    {genderData.val}
                                </label>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}

            {trackFilterAction === 'Tag' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select the tag</label>
                        {tagData.map(tag => (
                            // eslint-disable-next-line react/jsx-key
                            <div className="my-1">
                                <label>
                                    <input
                                        className="mr-1"
                                        type="checkbox"
                                        name="checkbox"
                                        value={tag.id}
                                        onClick={e => {
                                            if (tags.includes(parseInt(e.currentTarget.value))) {
                                                setTags(
                                                    tags.filter(
                                                        item =>
                                                            item !== parseInt(e.currentTarget.value)
                                                    )
                                                );
                                            } else {
                                                setTags([...tags, parseInt(e.currentTarget.value)]);
                                            }
                                        }}
                                    />
                                    {tag.tagName}
                                </label>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}
            {trackFilterAction === 'Institute' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select the Institue</label>
                        {instituteData?.map(track => (
                            // eslint-disable-next-line react/jsx-key
                            <div className="my-1">
                                <label>
                                    <input
                                        className="mr-1"
                                        type="checkbox"
                                        name="checkbox"
                                        value={track.id}
                                        onClick={e => {
                                            if (
                                                institue.includes(parseInt(e.currentTarget.value))
                                            ) {
                                                setInstitue(
                                                    institue.filter(
                                                        item =>
                                                            item !== parseInt(e.currentTarget.value)
                                                    )
                                                );
                                            } else {
                                                setInstitue([
                                                    ...institue,
                                                    parseInt(e.currentTarget.value),
                                                ]);
                                            }
                                        }}
                                    />
                                    {track.instName}
                                </label>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}
            {trackFilterAction === 'Number of challenges' ? (
                <>
                    <div className="flex flex-col my-1.5 w-full md:w-2/4">
                        <label className="flex flex-col my-1.5" htmlFor="tab_1">
                            Enter the number of challenges
                        </label>
                        <input
                            className="border-2 border-gray-300 rounded pl-2 py-1"
                            type="text"
                            id="tab_1"
                            value={numberOfChallenges}
                            placeholder="number of challenges"
                            onChange={e => {
                                setNumberOfChallenges(e.target.value);
                            }}
                        />
                    </div>
                </>
            ) : null}
        </>
    );
}
