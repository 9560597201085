import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import { CertificateType, EarnCertificatesType } from '../../../../redux/certificates/reducers';

import CloseIcon from '../../../../assets/svg/CloseIcon';
import EarnCertificateModal from '../../../modals/EarnCertificateModal';
import useWidth from '../../../hooks/useWidth';

interface Props {
    allCertificates: CertificateType[];
    earnedCertificates: EarnCertificatesType[];
    studentsCertificateData: any;
}

const CertificateEarned: React.FC<Props> = ({
    allCertificates,
    earnedCertificates,
    studentsCertificateData,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showCertificateModal, setShowCertificateModal] = useState<boolean>(false);
    const [selectedCertificateData, setSelectedCertificateData] = useState<CertificateType>();
    const [earnedCertificateData, setEarnedCertificateData] = useState<EarnCertificatesType>();
    const [earnedCertificatesIds, setEarnedCertificatesIds] = useState([]);
    const viewPortWidth = useWidth();
    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(239, 235, 255, 0.7)',
            backdropFilter: 'blur(7px)',
            overflow: 'auto',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    const handleSelectIncompleteCertificate = certificateData => {
        setSelectedCertificateData(certificateData);
        setShowModal(true);
    };
    const handleSelectCompleteCertificate = certificateData => {
        setSelectedCertificateData(certificateData);
        setShowCertificateModal(true);
    };

    useEffect(() => {
        if (earnedCertificates?.length > 0) {
            const newArr = earnedCertificates.map(item => {
                return item.certificate.certId;
            });
            setEarnedCertificatesIds(newArr);
        } else {
            setEarnedCertificatesIds([]);
        }
    }, [earnedCertificates]);

    useEffect(() => {
        if (earnedCertificates) {
            const cert = earnedCertificates?.find(
                certificate => certificate?.certificate?.id === selectedCertificateData?.id
            );
            setEarnedCertificateData(cert);
        }
    }, [dispatch, earnedCertificates, selectedCertificateData]);

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div className="mb-8">
                    <div className="hidden xl:block">
                        <div className="flex flex-row justify-between mb-3 ">
                            <div className="font-bold font-manrope text-xl">
                                Certificates ({studentsCertificateData?.earned?.length} earned)
                            </div>
                            <div className="flex flex-row justify-end items-center">
                                <p
                                    onClick={() => {
                                        history.push(`/dashBoard/myProgress/certificates`);
                                    }}
                                    className="mr-4 my-1 text-purple-600 hover:underline cursor-pointer"
                                >
                                    View All ({allCertificates?.length})
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="trackCardContainer overflow-hidden"
                        style={{ maxHeight: '420px' }}
                    >
                        {allCertificates?.map((certificate: CertificateType) => (
                            <div
                                key={certificate.id}
                                className={`rounded-lg trackCard mt-3 ${
                                    certificate?.challengeProgress?.progressStatus === 0
                                        ? 'opacity-100'
                                        : 'opacity-20 cursor-pointer'
                                }`}
                                onClick={() => {
                                    certificate?.challengeProgress?.progressStatus !== 0 &&
                                        handleSelectIncompleteCertificate(certificate);
                                }}
                            >
                                <img
                                    className="w-full rounded-t-lg"
                                    src="/assets/certificate.png"
                                    alt="profile"
                                />
                                <div className="mx-2 mt-6 pb-3">
                                    <div className="font-bold font-manrope text-xl mb-3 h-8 overflow-hidden">
                                        {certificate.certificateName?.length < 19
                                            ? certificate.certificateName
                                            : certificate.certificateName?.substring(0, 19) + '...'}
                                    </div>
                                    <div className="my-3">
                                        <div
                                            style={{ height: '104px' }}
                                            className="text-gray-500 text-xs mb-1 pr-2 text-justify"
                                        >
                                            <div className="font-semibold">
                                                You have now gathered your expertise on{' '}
                                                {certificate.track.trackName}.
                                            </div>
                                            <div>
                                                {certificate.description
                                                    ? certificate.description?.length > 200
                                                        ? certificate.description?.substring(
                                                              0,
                                                              200
                                                          ) + '.....'
                                                        : certificate.description
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => handleSelectCompleteCertificate(certificate)}
                                        className={`border-2 border-purple-950 text-purple-950 rounded font-semibold h-10 px-6 xl:px-12 ml-1 ${
                                            certificate?.challengeProgress?.progressStatus === 0
                                                ? 'visible'
                                                : 'invisible'
                                        }`}
                                    >
                                        View Certificate
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <EarnCertificateModal
                        showModal={showCertificateModal}
                        setShowModal={setShowCertificateModal}
                        certificateData={selectedCertificateData}
                        earnedCertificateData={earnedCertificateData}
                    />
                    <Modal isOpen={showModal} style={customStyles} ariaHideApp={false}>
                        <div className="flex-shrink justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
                            <div style={{ width: '650px' }} className="relative">
                                <div
                                    className="absolute z-50 top-6 right-6 cursor-pointer"
                                    onClick={() => setShowModal(false)}
                                >
                                    <CloseIcon size={24} color="rgba(17, 17, 17, 0.48)" />
                                </div>
                                <div className="border-2 rounded-lg shadow-lg bg-white outline-none focus:outline-none flex flex-col items-center px-16 pt-10 pb-7">
                                    <img
                                        src={'/assets/certificateVector.png'}
                                        alt={`${selectedCertificateData?.track.trackName} certificate`}
                                        className="mb-8 object-contain"
                                        style={{ width: '140px', height: '90px' }}
                                    />
                                    <h1 className="text-xl font-bold mb-3 text-center">
                                        {selectedCertificateData?.challengeProgress
                                            ?.progressStatus === 1 ||
                                        selectedCertificateData?.challengeProgress
                                            .progressStatus === 2
                                            ? `You are close to earning a ${selectedCertificateData?.track.trackName} Certificate!`
                                            : selectedCertificateData?.track.trackName}
                                    </h1>
                                    <p className="text-base font-normal text-purple-950 text-center">
                                        {selectedCertificateData?.challengeProgress
                                            ?.progressStatus === 1 ||
                                        selectedCertificateData?.challengeProgress
                                            .progressStatus === 2
                                            ? `You are ${
                                                  selectedCertificateData?.challengeProgress
                                                      ?.totalCount -
                                                  selectedCertificateData?.challengeProgress
                                                      ?.completedCount
                                              } challenges away from earning the ${
                                                  selectedCertificateData?.track.trackName
                                              } Certificate. Start on the challenges now to earn the certificate!`
                                            : selectedCertificateData?.track.trackDescription}
                                    </p>
                                    <button
                                        onClick={() => history.push(`/dashBoard/learn`)}
                                        className="bg-purple-950 text-base text-white font-bold rounded px-3 h-12 mt-1"
                                    >
                                        Continue Learning
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            ) : (
                <div className="mb-8">
                    <div>
                        <div className="flex flex-row justify-between mb-3">
                            <div className="font-bold font-manrope">
                                Certificates ({studentsCertificateData?.earned?.length} earned)
                            </div>
                            <div className="flex flex-row justify-end items-center">
                                <p
                                    onClick={() => {
                                        history.push(`/dashBoard/myProgress/certificates`);
                                    }}
                                    className="mr-4 my-1 text-purple-600 hover:underline cursor-pointer text-sm"
                                >
                                    View All ({allCertificates?.length})
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="trackCardContainer overflow-hidden"
                        style={{ maxHeight: '300px' }}
                    >
                        {allCertificates &&
                            allCertificates.map((certificate: CertificateType) => (
                                <div
                                    key={certificate.id}
                                    style={{ width: '140px' }}
                                    className={`rounded ${
                                        certificate.challengeProgress.progressStatus === 0
                                            ? 'opacity-100'
                                            : 'opacity-20 cursor-pointer'
                                    }`}
                                    onClick={() => {
                                        certificate.challengeProgress.progressStatus !== 0 &&
                                            handleSelectIncompleteCertificate(certificate);
                                    }}
                                >
                                    <img
                                        className="w-full rounded-t"
                                        src="/assets/certificate.png"
                                    />
                                    <div className="ml-3">
                                        <div className="my-3 text-lg font-bold">
                                            {certificate.certificateName}
                                        </div>
                                        <div className="text-xs font-normal">
                                            <div>
                                                You have now gathered your expertise on{' '}
                                                {certificate.track.trackName}.
                                            </div>
                                            <div>{certificate.description}</div>
                                        </div>
                                        <button
                                            onClick={() =>
                                                handleSelectCompleteCertificate(certificate)
                                            }
                                            className={`mt-3 rounded border-2 border-purple-950 px-2 h-10 text-sm font-bold text-purple-950 focus:outline-none
                        ${
                            certificate.challengeProgress.progressStatus === 0
                                ? 'visible'
                                : 'invisible'
                        }`}
                                        >
                                            View Certificate
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <EarnCertificateModal
                        showModal={showCertificateModal}
                        setShowModal={setShowCertificateModal}
                        certificateData={selectedCertificateData}
                        earnedCertificateData={earnedCertificateData}
                    />
                    <Modal isOpen={showModal} style={customStyles} ariaHideApp={false}>
                        <div className="flex-shrink justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
                            <div style={{ width: '650px' }} className="relative">
                                <div
                                    className="absolute z-50 top-6 right-6 cursor-pointer"
                                    onClick={() => setShowModal(false)}
                                >
                                    <CloseIcon size={24} color="rgba(17, 17, 17, 0.48)" />
                                </div>
                                <div className="border-2 rounded-lg shadow-lg bg-white outline-none focus:outline-none flex flex-col items-center px-16 pt-10 pb-7">
                                    <img
                                        src={'/assets/certificateVector.png'}
                                        alt={`${selectedCertificateData?.track.trackName} certificate`}
                                        className="mb-8 object-contain"
                                        style={{ width: '140px', height: '90px' }}
                                    />
                                    <h1 className="text-xl font-bold mb-3 text-center">
                                        {selectedCertificateData?.challengeProgress
                                            .progressStatus === 1 ||
                                        selectedCertificateData?.challengeProgress
                                            .progressStatus === 2
                                            ? `You are close to earning a ${selectedCertificateData?.track.trackName} Certificate!`
                                            : selectedCertificateData?.track.trackName}
                                    </h1>
                                    <p className="text-base font-normal text-purple-950 text-center">
                                        {selectedCertificateData?.challengeProgress
                                            .progressStatus === 1 ||
                                        selectedCertificateData?.challengeProgress
                                            .progressStatus === 2
                                            ? `You are ${
                                                  selectedCertificateData?.challengeProgress
                                                      .totalCount -
                                                  selectedCertificateData?.challengeProgress
                                                      .completedCount
                                              } challenges away from earning the ${
                                                  selectedCertificateData?.track.trackName
                                              } Certificate. Start on the challenges now to earn the certificate!`
                                            : selectedCertificateData?.track.trackDescription}
                                    </p>
                                    <button
                                        onClick={() => history.push(`/dashBoard/learn`)}
                                        className="bg-purple-950 text-base text-white font-bold rounded px-3 h-12 mt-1"
                                    >
                                        Continue Learning
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            )}
        </>
    );
};

export default CertificateEarned;
