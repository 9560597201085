import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBadgeById, getChallenges } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { s3UrlPrefix } from '../../../constants';
import { BadgeType } from '../../../redux/badges/reducers';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroupsView from '../../TagGroupsView';

interface CustomState {
    badge: BadgeType;
}
interface paramsType {
    id: string;
}
export default function ViewBadge() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const params = useParams<paramsType>();
    const badgeState = location.state as CustomState;
    const badge = useSelector((state: RootState) => state.badges.Badge);
    const challenges = useSelector((state: RootState) => state.challenges.challengesData);
    const [tags, setTags] = useState(categorizeTags(badgeState?.badge?.tags));
    const [imageUrl] = useState(`${s3UrlPrefix}${badgeState?.badge?.imageUrl}`);
    const [optionSelected, setOptionSelected] = useState<any>();
    const [idList] = useState(badgeState?.badge?.criteria?.idList);

    useEffect(() => {
        const result = idList
            ?.map(id => {
                const challenge = challenges.find(item => item.id === id);
                return challenge;
            })
            .filter(item => item !== undefined);
        setOptionSelected(result);
    }, [challenges, idList]);

    useEffect(() => {
        dispatch(getChallenges(0, 100, 'PUBLISHED'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getBadgeById(params.id));
    }, [dispatch, params.id]);

    return (
        <div className="mx-6 lg:ml-2 h-full">
            <div className="mt-12 bg-snow-600">
                <div className=" mt-12">
                    <div className="relative">
                        <div className="flex justify-between items-center mb-4 md:mb-6">
                            <p className="text-xl font-semibold text-left">View Badge</p>
                            <div
                                className={`flex h-14 md:h-16 ${
                                    badgeState?.badge?.badgeStatus !== 'DELETED'
                                        ? 'w-1/2 md:w-1/4 lg:pl-8'
                                        : 'w-1/4 md:w-1/6 lg:pl-16'
                                }`}
                            >
                                <button
                                    type="submit"
                                    className={`md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm ${
                                        badgeState?.badge?.badgeStatus !== 'DELETED'
                                            ? 'w-full md:w-1/2'
                                            : 'w-full hover:bg-blue-700 hover:text-white'
                                    } my-2 focus:outline-none`}
                                    onClick={() => {
                                        history.push('/admin/achievements/badges');
                                    }}
                                >
                                    OK
                                </button>
                                {badgeState?.badge?.badgeStatus !== 'DELETED' && (
                                    <button
                                        type="submit"
                                        className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-normal rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                        onClick={() => {
                                            history.push({
                                                pathname: `/admin/achievements/badges/${badgeState?.badge?.id}/edit`,
                                                state: { badge: badge },
                                            });
                                        }}
                                    >
                                        Edit
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2 pb-8 mb-6">
                            <div className="col-span-12 md:col-span-4 mb-2 md:mb-4">
                                <div className="text-lg font-medium mb-6">Badge Details</div>
                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        htmlFor="badgeId"
                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Badge ID
                                    </label>

                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center border-gray-300 focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            value={badge?.badgeId}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        htmlFor="badgeName"
                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Badge Name
                                    </label>

                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center border-gray-300 focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            value={badge?.badgeName}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Badge Status
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center border-gray-300 focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            value={badge?.badgeStatus}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Challenges Applicable
                                    </label>
                                    <div className="flex flex-col w-full">
                                        {optionSelected?.map((challenge, i) => {
                                            return (
                                                <div className="flex mt-2" key={i}>
                                                    <input
                                                        className={`rounded-sm flex items-center border-gray-300 focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                        value={challenge?.challengeName}
                                                        disabled
                                                    />
                                                    <div className="flex"></div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex h-32">
                                    <label
                                        htmlFor="badgeDescription"
                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Description
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <textarea
                                            className={`resize-none border-gray-300 focus:outline-none p-2 w-4/5 md:w-3/4 border-2 text-sm h-32 focus:border-blue-900 `}
                                            id="description"
                                            value={badge?.badgeDescription}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        htmlFor="image"
                                        className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Badge Image
                                    </label>
                                    <div
                                        className={`bg-snow-600 w-3/4 md:w-3/5 mb-2 md:mb-4 relative ${
                                            imageUrl ? 'w-fit' : ''
                                        }`}
                                    >
                                        <div
                                            className={`text-base border-dashed border-black-600 border-opacity-100 border-2 justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64`}
                                        >
                                            {imageUrl && badgeState?.badge?.imageUrl && (
                                                <img
                                                    src={imageUrl}
                                                    alt={'name'}
                                                    className="h-44 lg:h-64"
                                                    style={{ maxHeight: 300 }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                <div className="flex border-2 flex-col ml-2 justify-items-center">
                                    <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                        <div className="sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                            Tags
                                        </div>
                                    </div>
                                    <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                        <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                            <TagGroupsView selectedTags={tags} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
