import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearCertificatesMessages,
    editCertificate,
    getCerificateById,
    getPdfUrl,
    getPresignedUrl,
    getTags,
    getTracks,
} from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as HiIcons from 'react-icons/hi';
import * as FiIcons from 'react-icons/fi';
import CertificatesSuccessfullModal from '../../modals/CertificatesSuccessfullModal';
import { CertificateType } from '../../../redux/certificates/reducers';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import { moduleNames } from '../../../constants';
import { downloadFromExternalLink } from '../../../utils/downloadContent';

interface CustomState {
    certificate: CertificateType;
}
interface paramsType {
    id: string;
}
export default function EditCertificate() {
    const maxChars = 200;
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const params = useParams<paramsType>();
    const certificateState = location.state as CustomState;
    const tracks = useSelector((state: RootState) => state.tracks.trackData);
    const certificate = useSelector((state: RootState) => state.certificates.certificate);
    const isEditLoading = useSelector((state: RootState) => state.certificates.isEditLoading);
    const editCertificateError = useSelector(
        (state: RootState) => state.certificates.editCertificateError
    );
    const certificateUpdateSuccessMessage = useSelector(
        (state: RootState) => state.certificates.certificateUpdateSuccessMessage
    );
    const deleteError = useSelector(
        (state: RootState) => state.certificates.deleteCertificateError
    );
    const deleteSuccessMessage = useSelector(
        (state: RootState) => state.certificates.certificateDeleteSuccessMessage
    );
    const isImageUploading = useSelector((state: RootState) => state.certificates.isImageUploading);
    const imageError = useSelector((state: RootState) => state.certificates.imageError);
    const pdfLink = useSelector((state: RootState) => state.certificates.pdfLink);
    const fileName = certificateState?.certificate?.contentDocUrl?.split('/', 4);
    const formRef = useRef<any>();
    const [fileLocation, setFileLocation] = useState(certificateState?.certificate?.contentDocUrl);
    const [editSuccessVisible, setEditSuccessVisible] = useState(false);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [category, setCategory] = useState<string>(
        certificateState?.certificate?.track?.trackName
    );
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [tags, setTags] = useState(certificateState?.certificate?.tags);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResultsVisible, setSearchResultsVisible] = useState(false);
    const handleClearMessages = useCallback(() => {
        if (
            certificateUpdateSuccessMessage ||
            editCertificateError ||
            deleteError ||
            deleteSuccessMessage
        ) {
            setEditSuccessVisible(true);
        }
    }, [
        dispatch,
        certificateUpdateSuccessMessage,
        editCertificateError,
        history,
        deleteSuccessMessage,
        deleteError,
    ]);
    const handleClose = () => {
        dispatch(clearCertificatesMessages());
        setEditSuccessVisible(false);
        if (!editCertificateError) {
            history.push('/admin/achievements/certificates');
        }
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);
    useEffect(() => {
        dispatch(getTracks(true, 0, 100, 'PUBLISHED'));
        dispatch(getPdfUrl(fileName?.[3]));
    }, [dispatch]);
    useEffect(() => {
        dispatch(getCerificateById(params.id));
    }, [dispatch, params.id]);
    const handleFile = (e: any) => {
        const _URL = window.URL || window.webkitURL;
        let file: File, img;
        if ((file = e.target.files[0])) {
            img = new Image();
            img.src = _URL.createObjectURL(file);
        }
    };
    useEffect(() => {
        setTags(certificateState?.certificate?.tags);
    }, [certificateState]);
    const uploadFileToS3 = async e => {
        const locationURL = await dispatch(
            getPresignedUrl(e.target.files[0]?.name, e.target.files[0])
        );
        const locationArray = locationURL?.toString().split('/', 6);
        setFileLocation(`tracks/certificates/${locationArray?.[5]}/${locationArray?.[5]}.html`);
    };
    const serchTagNameOrId = e => {
        setSearchTerm(e.target.value);
    };
    useEffect(() => {
        if (searchTerm.length >= 3) {
            dispatch(getTags(true, moduleNames.Certificates, searchTerm));
            setSearchResultsVisible(true);
        } else {
            setSearchResultsVisible(false);
        }
    }, [dispatch, searchTerm]);
    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    <div className="relative">
                        <Formik
                            enableReinitialize={true}
                            innerRef={formRef}
                            initialValues={{
                                certificateName: certificate?.certificateName,
                                contentDocUrl: certificate?.contentDocUrl,
                                contentPdfUrl: certificate?.contentPdfUrl,
                                file: undefined,
                                certStatus: certificate?.certStatus,
                                track: certificate?.track.id,
                                description: certificate?.description,
                                fileName: fileName?.[2],
                                fileSize: certificate?.certificateSize,
                            }}
                            validationSchema={Yup.object({
                                certificateName: Yup.string()
                                    .max(100, 'Name should be less than 100 characters')
                                    .required('Name is required'),
                                description: Yup.string()
                                    .nullable()
                                    .max(
                                        maxChars,
                                        'Description should be less than 500 characters'
                                    ),

                                fileName: Yup.string().required('Template is required'),
                            })}
                            onSubmit={async values => {
                                dispatch(
                                    editCertificate(certificate.id, {
                                        certificateName: values.certificateName,
                                        description: values.description,
                                        contentDocUrl: fileLocation,
                                        track: { id: values.track },
                                        certStatus: values.certStatus,
                                        tags: tags,
                                        certificateSize: values.fileSize,
                                    })
                                );
                            }}
                        >
                            {({
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                values,
                                errors,
                                touched,
                            }) => (
                                <>
                                    <div className="flex justify-between items-center mb-4 md:mb-6">
                                        <p className="text-xl font-semibold text-left">
                                            Edit Certificate
                                        </p>

                                        <div className="flex w-1/2 md:w-1/4 lg:pl-8 h-16">
                                            <button
                                                className="md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() =>
                                                    history.push(
                                                        '/admin/achievements/certificates/'
                                                    )
                                                }
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => handleSubmit()}
                                            >
                                                {isEditLoading ? <Loader /> : 'Save'}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="border-2 border-gray-300 px-6  pt-2 pb-4 mb-6">
                                        <div className="grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8">
                                            <div className="col-span-12 md:col-span-4 mb-2 md:mb-4">
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="id"
                                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Certificate ID
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded-sm border-gray-300 flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                            id="id"
                                                            type="text"
                                                            value={certificate?.certId}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="title"
                                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Certificate Name
                                                        <div className="text-red-600">*</div>
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                errors.certificateName &&
                                                                touched.certificateName
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="name"
                                                            type="text"
                                                            value={values.certificateName}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'certificateName',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                        {errors.certificateName &&
                                                        touched.certificateName ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.certificateName}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="my-6 w-full md:mb-0 flex">
                                                    <label
                                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Related Track
                                                        <div className="text-red-600">*</div>
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <div className="w-full flex">
                                                            <div
                                                                className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-4/5 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                                            >
                                                                <div
                                                                    className="flex justify-between w-full"
                                                                    onClick={() =>
                                                                        setDropdownVisible(true)
                                                                    }
                                                                >
                                                                    <div className="text-gray-700 font-medium pl-2 text-base">
                                                                        {category}
                                                                    </div>

                                                                    <div className="flex flex-col">
                                                                        <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                        <HiIcons.HiChevronDown className="mr-2" />
                                                                    </div>
                                                                </div>
                                                                {dropdownVisible ? (
                                                                    <ul
                                                                        className="absolute w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                        role="menu"
                                                                        onMouseLeave={() =>
                                                                            setDropdownVisible(
                                                                                false
                                                                            )
                                                                        }
                                                                    >
                                                                        {tracks.map(track => (
                                                                            <div
                                                                                className="p-2 hover:bg-gray-200 text-base"
                                                                                key={track.id}
                                                                                onClick={() => {
                                                                                    setCategory(
                                                                                        track.trackName
                                                                                    );
                                                                                    setFieldValue(
                                                                                        'track',
                                                                                        track.id
                                                                                    );
                                                                                    setDropdownVisible(
                                                                                        false
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {track.trackName}
                                                                            </div>
                                                                        ))}
                                                                    </ul>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        {errors.track && touched.track ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.track}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="my-6 w-full md:mb-0 flex">
                                                    <label
                                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Certifcate Status
                                                        <div className="text-red-600">*</div>
                                                    </label>
                                                    <div className="flex text-sm md:text-base items-center w-full">
                                                        <input
                                                            className="mr-1"
                                                            type="radio"
                                                            value="ACTIVE"
                                                            id="active"
                                                            name="status"
                                                            checked={values.certStatus === 'ACTIVE'}
                                                            onChange={handleChange('certStatus')}
                                                        />
                                                        <label className="mr-4 md:mr-8">
                                                            Active
                                                        </label>
                                                        <input
                                                            className="mr-1"
                                                            type="radio"
                                                            value="INACTIVE"
                                                            id="inactive"
                                                            checked={
                                                                values.certStatus === 'INACTIVE'
                                                            }
                                                            name="status"
                                                            onChange={handleChange('certStatus')}
                                                        />
                                                        <label className="">Inactive</label>
                                                    </div>
                                                </div>
                                                <div className="my-6 w-full md:mb-0 flex h-24">
                                                    <label
                                                        htmlFor="description"
                                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Certificate Description
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <textarea
                                                            className={`resize-none focus:outline-none p-2 w-4/5 border-2 md:text-base h-20 focus:border-blue-900 ${
                                                                errors.description &&
                                                                touched.description
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            }`}
                                                            id="description"
                                                            value={values.description}
                                                            placeholder=""
                                                            onChange={handleChange('description')}
                                                        />
                                                        <div className="w-4/5">
                                                            <p className="text-gray-500 text-sm text-right">
                                                                ({values.description?.length} /{' '}
                                                                {maxChars})
                                                            </p>
                                                        </div>

                                                        {errors.description &&
                                                        touched.description ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.description}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="my-6 w-full md:mb-0 flex">
                                                    <label
                                                        htmlFor="description"
                                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Certificate Template
                                                        <div className="text-red-600">*</div>
                                                    </label>
                                                    <div
                                                        className={` w-full mb-2 md:mb-4  relative ${
                                                            fileLocation ? 'w-fit' : ''
                                                        }  `}
                                                    >
                                                        <div className="bg-gray-100 border w-4/5 border-opacity-100 border-2 border-black-600 text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-20 md:h-32">
                                                            {!fileLocation && (
                                                                <div className="w-full">
                                                                    <div className="flex justify-center">
                                                                        <FiIcons.FiUpload
                                                                            className=""
                                                                            size="30px"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <label
                                                                            className="text-sm font-medium leading-149 md:text-normal text-gray-600 cursor-pointer underline"
                                                                            htmlFor="imageLink"
                                                                        >
                                                                            Upload Certificate
                                                                            Template
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {fileLocation && (
                                                                <div>
                                                                    {fileName?.[2] ===
                                                                        values.fileName && (
                                                                        <div
                                                                            className="text-blue-700 underline cursor-pointer"
                                                                            onClick={() =>
                                                                                downloadFromExternalLink(
                                                                                    pdfLink,
                                                                                    'UploadedCertificate'
                                                                                )
                                                                            }
                                                                        >
                                                                            Download Here
                                                                        </div>
                                                                    )}
                                                                    <p>
                                                                        File name :{' '}
                                                                        {values.fileName}
                                                                    </p>
                                                                    <p>
                                                                        File size :{' '}
                                                                        {Math.round(
                                                                            values.fileSize
                                                                        ) + 'KB'}
                                                                    </p>
                                                                    <div className="mt-1 text-red-400 text-sm flex justify-center">
                                                                        {imageError}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="mt-1 text-gray-400 text-sm font-medium leading-149">
                                                            Upload format (html)
                                                        </div>

                                                        <div
                                                            className="absolute md:mt-0 mb-4 text-center"
                                                            style={{
                                                                left: '40%',
                                                                transform: 'translate(-50%,-50%)',
                                                            }}
                                                        >
                                                            {fileLocation && !isImageUploading && (
                                                                <div>
                                                                    <label
                                                                        className="underline cursor-pointer mt-4"
                                                                        htmlFor="imageLink"
                                                                    >
                                                                        Change Certificate Template
                                                                    </label>
                                                                </div>
                                                            )}
                                                            {isImageUploading && (
                                                                <div className="flex items-center">
                                                                    <div className="mr-2">
                                                                        Uploading
                                                                    </div>
                                                                    <div>
                                                                        <Loader />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {errors.fileName && touched.fileName ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.fileName}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <input
                                                        className={`focus:outline-none w-full focus:border-blue-900`}
                                                        id="imageLink"
                                                        type="file"
                                                        accept=".html,"
                                                        onChange={async e => {
                                                            const file = [];
                                                            file.push(e.target.files[0]);
                                                            setFieldValue('file', file);
                                                            setFieldValue('fileName', file[0].name);
                                                            setFieldValue(
                                                                'fileSize',
                                                                Math.floor(file[0].size / 1024)
                                                            );
                                                            handleFile(e);
                                                            uploadFileToS3(e);
                                                        }}
                                                        style={{ display: 'none' }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {certificate?.certStatus !== 'ACTIVE' && (
                                            <div className="my-6 md:mb-0 flex justify-end">
                                                <button
                                                    type="submit"
                                                    className="sm:py-1 md:py-3 ml-2 lg:ml-8 hover:bg-red-500 text-red-500 hover:text-white border-2 font-medium border-red-600 border-opacity-40 text-sm rounded-sm w-1/2 md:w-1/6 my-2 focus:outline-none"
                                                    onClick={() =>
                                                        setDeleteConfirmationVisible(true)
                                                    }
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    {deleteConfirmationVisible ? (
                                        <DeleteConfirmationModal
                                            modalVisible={deleteConfirmationVisible}
                                            setModalVisible={setDeleteConfirmationVisible}
                                            certificateId={certificate?.id}
                                        />
                                    ) : null}
                                    {(certificateUpdateSuccessMessage ||
                                        editCertificateError ||
                                        deleteError ||
                                        deleteSuccessMessage) && (
                                        <CertificatesSuccessfullModal
                                            modalVisible={editSuccessVisible}
                                            handleClose={handleClose}
                                        />
                                    )}
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
