import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
interface Props {
    selectedTab: number;
    setSelectedTab: (value: number) => void;
}

export default function TabView({ selectedTab, setSelectedTab }: Props): ReactElement {
    const history = useHistory();
    return (
        <>
            <div className="flex items-center">
                <div className={`flex w-full`}>
                    <div
                        className={`border-b-4 relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/6  ${
                            selectedTab === 1 ? `border-blue-700` : 'border-snow-500'
                        }`}
                        role="presentation"
                        onClick={() => setSelectedTab(1)}
                    >
                        <div
                            className={`py-2 text-xs sm:text-lg absolute bottom-0 flex w-full justify-center ${
                                selectedTab === 1 ? `text-blue-700 font-semibold` : 'text-black-300'
                            }`}
                        >
                            All Users
                        </div>
                    </div>
                    <div className="border-b-4 text-sm border-snow-500 w-2/4 md:w-full lg:w-full">
                        <div
                            className="text-right right-0 flex justify-end text-blue-900 py-1"
                            role="presentation"
                        >
                            <button
                                className="py-1 px-2 md:px-4 w-32 bg-blue-700 text-white text-sm rounded-sm focus:outline-none h-12"
                                onClick={() => history.push('/admin/users/new')}
                            >
                                Add New User
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
