import React, { ReactElement, useEffect, useState } from 'react';
import Add from '../../../assets/svg/Add';
import {
    addAnalyticsTab,
    Average,
    downloadCSVAnalytics,
    downloadCSVStudent,
    DOWNLOAD_CSV_ANALYTICS_RESET,
    getAnalyticsTabs,
} from '../../../redux/rootActions';
import { useDispatch, useSelector } from 'react-redux';
import AddTabs from './AddTabs';
import { USER_PROFILE } from '../../../utils/storage';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { API_URL } from '../../../constants';
import CSVEmailedSuccessModal from '../../modals/CSVEmailedSuccessModal';
interface Props {
    selectedTab: number;
    setSelectedTab: (value: number) => void;
    selectedTrackId: number | '';
}

export default function TabView({
    selectedTab,
    setSelectedTab,
    selectedTrackId,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = React.useState(false);
    const [tab, setTab] = useState([]);
    const [tabName, setTabName] = useState<string>();
    const [selectedTabFilteration, setSelectedTabFilteration] = useState<any>();

    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const analyticsTabData = useSelector(
        (state: RootState) => state.filteredAnalytics.analyticsTabData
    );
    const isAnalyticsCSVLoading = useSelector(
        (state: RootState) => state.analytics.isAnalyticsCSVLoading
    );

    const analyticsEmailMessage = useSelector(
        (state: RootState) => state.analytics.analyticsEmailMessage
    );

    const LeaderboardCSVSuccess = useSelector(
        (state: RootState) => state.analytics.LeaderboardCSVSuccess
    );

    const [tags, setTags] = useState([]);
    const [gender, setGender] = useState<string>();
    const [tracks, setTracks] = useState([]);
    const [average, setAverage] = useState<string>();
    const [homeTown, setHomeTown] = useState<string>();
    const [numberOfChallenges, setNumberOfChallenges] = useState<string>();

    const [averageFrom, setAverageFrom] = useState<number>();
    const [averageTo, setAverageTo] = useState<number>();
    const [institue, setInstitue] = useState([]);
    const [modalVisible, setModalVisible] = useState<boolean>();
    const [message, setMessage] = useState<string>();

    useEffect(() => {
        if (LeaderboardCSVSuccess) {
            setMessage(analyticsEmailMessage);
            setModalVisible(true);
            dispatch({ type: DOWNLOAD_CSV_ANALYTICS_RESET });
        }
    }, [analyticsEmailMessage, LeaderboardCSVSuccess]);

    useEffect(() => {
        if (selectedTab !== 1) {
            setSelectedTabFilteration(
                tab?.filter(action => action.id === selectedTab - 2).map(data => data?.filters)[0]
            );
        }
    }, [analyticsTabData, selectedTab]);

    useEffect(() => {
        dispatch(getAnalyticsTabs(profile?.userId, 'ANALYTIC'));
    }, []);

    useEffect(() => {
        if (selectedTab === selectedTabFilteration?.id + 2) {
            dispatch(
                addAnalyticsTab({
                    id: selectedTabFilteration?.id,
                    tabName: selectedTabFilteration?.tabName,
                    tracks: selectedTabFilteration?.tracks,
                    gender: selectedTabFilteration?.gender,
                    average: selectedTabFilteration?.average,
                    homeTown: selectedTabFilteration?.homeTown,
                    institutes: selectedTabFilteration?.institutes,
                })
            );
        }
    }, [selectedTab, selectedTabFilteration]);
    // }, [selectedTab, selectedTabFilteration, analyticsTabData]);

    useEffect(() => {
        setTab(analyticsTabData);
    }, [analyticsTabData]);

    useEffect(() => {
        if (average === '0% - 35%') {
            setAverageFrom(0);
            setAverageTo(35);
        } else if (average === '36% - 55%') {
            setAverageFrom(36);
            setAverageTo(55);
        } else if (average === '56% - 80%') {
            setAverageFrom(56);
            setAverageTo(80);
        } else if (average === '81% - 100%') {
            setAverageFrom(81);
            setAverageTo(100);
        }
    }, [average]);

    const onAdd = () => {
        if (!tab.includes(tabName)) {
            setTab([...tab, tabName]);
        }

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const average: Average = {
            from: averageFrom,
            to: averageTo,
        };
        dispatch(
            addAnalyticsTab({
                tabName: tabName,
                tags: tags,
                tracks: tracks,
                gender: gender,
                average: average,
                homeTown: homeTown,
                institutes: institue,
            })
        );
    };
    return (
        <>
            <div className="flex items-center pt-8">
                <div className={`flex w-full`}>
                    <div
                        className={`border-b-4 relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/6  ${
                            selectedTab === 1 ? `border-blue-700` : 'border-snow-500'
                        }`}
                        role="presentation"
                        onClick={() => setSelectedTab(1)}
                    >
                        <div
                            className={`py-2 text-xs sm:text-lg absolute bottom-0 flex w-full text-center ${
                                selectedTab === 1 ? `text-blue-700 font-semibold` : 'text-black-300'
                            }`}
                        >
                            All Leaderboard
                        </div>
                    </div>
                    {/* {tab?.length > 0
                        ? tab?.map(action => (
                              <div
                                  className={`border-b-4 relative cursor-pointer w-1/2 md:w-1/6  ${
                                      selectedTab === action?.filters?.id + 2
                                          ? `border-blue-700`
                                          : 'border-snow-500'
                                  }`}
                                  role="presentation"
                                  key={action?.filters?.id}
                                  onClick={() => {
                                      setSelectedTab(action?.filters?.id + 2);
                                  }}
                              >
                                  <div
                                      className={`py-2 text-xs sm:text-lg absolute bottom-0 flex w-full text-center justify-center ${
                                          selectedTab === 2
                                              ? `text-blue-700 font-semibold`
                                              : 'text-black-300'
                                      }`}
                                  >
                                      {action?.filters?.tabName}
                                  </div>
                              </div>
                          ))
                        : null} */}
                    <div
                        className={`border-b-4 relative cursor-pointer pl-4 ${
                            selectedTab === 2 ? `` : ''
                        }`}
                        role="presentation"
                    >
                        <div className="py-2 text-xs sm:text-lg absolute bottom-0 text-center flex justify-center w-full">
                            {/* <button onClick={() => setShowModal(true)}>
                                <Add size={35} />
                            </button> */}

                            <AddTabs
                                isSelected={showModal}
                                setIsSelected={setShowModal}
                                onAdd={onAdd}
                                tabName={tabName}
                                setTabName={setTabName}
                                average={average}
                                setAverage={setAverage}
                                tags={tags}
                                setTags={setTags}
                                gender={gender}
                                setGender={setGender}
                                tracks={tracks}
                                setTracks={setTracks}
                                homeTown={homeTown}
                                setHomeTown={setHomeTown}
                                institue={institue}
                                setInstitue={setInstitue}
                                numberOfChallenges={numberOfChallenges}
                                setNumberOfChallenges={setNumberOfChallenges}
                            />
                        </div>
                    </div>

                    <div className="border-b-4 text-sm border-snow-500 w-2/4 md:w-full lg:w-full">
                        <div
                            className="text-right right-0 flex justify-end text-blue-900 py-2"
                            role="presentation"
                        >
                            <button
                                onClick={() => {
                                    dispatch(downloadCSVAnalytics(selectedTrackId));
                                }}
                                className="bg-snow-900 border-2 border-blue-700 border-opacity-40 text-blue-700 hover:text-white hover:bg-blue-700 text-sm md:font-semibold rounded-sm w-1/2 md:w-1/3 xl:w-1/5 h-11 focus:outline-none"
                            >
                                {/* <a href={`${API_URL}/csv/leaderboard`}>
                                    {isAnalyticsCSVLoading ? <Loader /> : 'Email the CSV'}
                                </a> */}
                                {isAnalyticsCSVLoading ? <Loader /> : 'Email the CSV'}
                            </button>
                        </div>
                    </div>
                    <CSVEmailedSuccessModal
                        setModalVisible={setModalVisible}
                        modalVisible={modalVisible}
                        message={message}
                        setMessage={setMessage}
                    />
                </div>
            </div>
        </>
    );
}
