import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../../assets/svg/Loader';
import { clearWebAuth, logoutWeb } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { confirmEmailVerification } from '../../../redux/webRedux/webUsers/actions';

const VerifyEmail = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const confirmEmailVerificationSuccess = useSelector(
        (state: RootState) => state.webUser.confirmEmailVerificationSuccessMessage
    );
    const loading = useSelector((state: RootState) => state.webUser.isLoading);

    const verifyToken = location?.search.slice(7);

    useEffect(() => {
        dispatch(confirmEmailVerification(verifyToken));
    }, [dispatch, verifyToken]);

    useEffect(() => {
        if (confirmEmailVerificationSuccess) {
            dispatch(logoutWeb());
            dispatch(clearWebAuth());
        }
    }, [confirmEmailVerificationSuccess, dispatch, history]);

    return (
        <>
            <div className="flex w-full h-screen items-center justify-center">
                <div className="flex flex-col p-4 w-1/3">
                    <div className="flex text-2xl font-semibold justify-center">
                        Email verification success
                    </div>
                    <div className="flex mt-4 justify-center">Your email verified successfully</div>
                    <div className="flex mt-4 justify-center">
                        <button
                            type="button"
                            className="py-2.5 px-4 w-full rounded bg-purple-950 text-white"
                            onClick={() => history.push('/admin/signin')}
                        >
                            {loading ? <Loader /> : 'Continue'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VerifyEmail;
