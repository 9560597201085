import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import {
    clearUsersMessages,
    editPrivacyPolicy,
    createPrivacyPolicy,
    getPrivacyPolicy,
} from '../../../redux/rootActions';
import { Formik } from 'formik';
import Loader from '../../../assets/svg/Loader';
import UsersSuccessModal from '../../modals/UsersSuccessModal';
import { SLASSCOM_ADMIN, SUPER_ADMIN } from '../../../redux/authAdmin/reducers';
import { PrivacyPolicyDataType } from '../../../redux/users/reducers';

const PrivacyPolicy = () => {
    const dispatch = useDispatch();
    const formRef = useRef<any>();

    const isPrivacyPolicyLoading = useSelector(
        (state: RootState) => state.users.isPrivacyPolicyLoading
    );
    const isPrivacyPolicyEditLoading = useSelector(
        (state: RootState) => state.users.isPrivacyPolicyEditLoading
    );
    const user = useSelector((state: RootState) => state.users.User);
    const privacyPolicy = useSelector((state: RootState) => state.users.privacyPolicy);
    const privacyPolicyEditSuccessMessage = useSelector(
        (state: RootState) => state.users.privacyPolicyEditSuccessMessage
    );
    const privacyPolicyEditError = useSelector(
        (state: RootState) => state.users.privacyPolicyEditError
    );

    const isAuthorized =
        user?.roles[0]?.roleName === SUPER_ADMIN || user?.roles[0]?.roleName === SLASSCOM_ADMIN;
    const [editSuccessVisible, setEditSuccessVisible] = useState(false);
    const [editview, setEditView] = useState(false);

    const handleClearMessages = useCallback(() => {
        if (privacyPolicyEditSuccessMessage || privacyPolicyEditError) {
            setEditSuccessVisible(true);
        }
    }, [privacyPolicyEditSuccessMessage, privacyPolicyEditError, dispatch]);
    const handleClose = () => {
        dispatch(clearUsersMessages());
        setEditSuccessVisible(false);
    };
    useEffect(() => {
        if (!isPrivacyPolicyLoading && !isPrivacyPolicyEditLoading) {
            handleClearMessages();
        }
    }, [handleClearMessages, isPrivacyPolicyEditLoading, isPrivacyPolicyLoading]);

    useEffect(() => {
        dispatch(getPrivacyPolicy());
    }, [dispatch]);

    return (
        <div className="mx-6 lg:ml-2 h-full">
            <div className="bg-snow-600">
                <div className="relative">
                    {!isPrivacyPolicyLoading && (
                        <Formik
                            enableReinitialize={true}
                            innerRef={formRef}
                            initialValues={{
                                privacyPolicy: privacyPolicy?.description,
                            }}
                            onSubmit={async values => {
                                const data: PrivacyPolicyDataType = {
                                    description: values?.privacyPolicy,
                                };
                                if (privacyPolicy) {
                                    await dispatch(editPrivacyPolicy(privacyPolicy?.id, data));
                                } else {
                                    await dispatch(createPrivacyPolicy(data));
                                }
                                setEditView(false);
                            }}
                        >
                            {({
                                handleChange,
                                setFieldValue,
                                handleSubmit,
                                errors,
                                touched,
                                values,
                            }) => (
                                <div className="grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2 pb-8 mb-6">
                                    <div className="col-span-12 md:col-span-6 mb-2 md:mb-4">
                                        <div className="mb-6 w-full md:mb-0 flex flex-col my-4">
                                            <p className="font-medium leading-149 text-sm md:text-lg text-gray-700">
                                                Privacy Settings
                                            </p>
                                            <div className="font-medium text-gray-700 mb-3">
                                                This section controls the display of your website
                                                privacy policy.
                                            </div>

                                            <div className="flex flex-col w-full mb-6">
                                                <textarea
                                                    className={` focus:outline-none p-2 w-full ${
                                                        isAuthorized && 'border-2'
                                                    } font-medium text-gray-600 h-60 min-h-full focus:border-blue-900 ${
                                                        errors.privacyPolicy &&
                                                        touched.privacyPolicy
                                                            ? 'border-red-500'
                                                            : 'border-gray-300'
                                                    }`}
                                                    id="description"
                                                    value={values.privacyPolicy}
                                                    onChange={handleChange('privacyPolicy')}
                                                    disabled={!isAuthorized || !editview}
                                                />
                                                {errors.privacyPolicy && touched.privacyPolicy ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {errors.privacyPolicy}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {isAuthorized && (
                                            <div className="flex w-full justify-end items-center mb-4 md:mb-6">
                                                <div className="flex w-1/2 lg:w-1/3 justify-end">
                                                    {!editview ? (
                                                        <button
                                                            className="bg-blue-700 text-white text h-11 rounded focus:outline-none  w-full md:w-1/3"
                                                            onClick={() => setEditView(true)}
                                                        >
                                                            Edit
                                                        </button>
                                                    ) : (
                                                        <>
                                                            <button
                                                                className="bg-snow-900 border-2 border-blue-800 md:mr-4 border-opacity-40 h-11  w-full md:w-1/3 text-blue-900 text-sm rounded focus:outline-none"
                                                                onClick={() => {
                                                                    setFieldValue(
                                                                        'privacyPolicy',
                                                                        privacyPolicy?.description
                                                                    );
                                                                    setEditView(false);
                                                                }}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="bg-blue-700 text-white text h-11 rounded focus:outline-none  w-full md:w-1/3"
                                                                onClick={() => handleSubmit()}
                                                            >
                                                                {isPrivacyPolicyEditLoading ? (
                                                                    <Loader />
                                                                ) : (
                                                                    'Save'
                                                                )}
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {editSuccessVisible && (
                                        <UsersSuccessModal
                                            modalVisible={editSuccessVisible}
                                            handleClose={handleClose}
                                        />
                                    )}
                                </div>
                            )}
                        </Formik>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
