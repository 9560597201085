import {
    GET_DOCUMENT_PRESIGNED,
    GET_DOCUMENT_PRESIGNED_SUCCESS,
    GET_DOCUMENT_PRESIGNED_FAILED,
} from './../mentors/actions';
import { getPresignedUrl } from './../certificates/actions';
import {
    ADD_CHALLENGE,
    ADD_CHALLENGE_FAILED,
    ADD_CHALLENGE_SUCCESS,
    ADD_MATERIAL,
    ADD_MATERIAL_FAILED,
    ADD_MATERIAL_SUCCESS,
    CHALLENGE_BULK_UPDATE,
    CHALLENGE_BULK_UPDATE_FAILED,
    CHALLENGE_BULK_UPDATE_SUCCESS,
    CLEAR_CHALLENGE_MESSAGES,
    DELETE_CHALLENGE,
    DELETE_CHALLENGE_FAILED,
    DELETE_CHALLENGE_SUCCESS,
    EDIT_CHALLENGE,
    EDIT_CHALLENGE_FAILED,
    EDIT_CHALLENGE_SUCCESS,
    GET_CHALLENGEBYID,
    GET_CHALLENGEBYID_FAILED,
    GET_CHALLENGEBYID_SUCCESS,
    GET_CHALLENGE_BY_ID_BY_STUDENT,
    GET_CHALLENGE_BY_ID_BY_STUDENT_SUCCESS,
    GET_CHALLENGE_BY_ID_BY_STUDENT_FAILED,
    GET_CHALLENGES,
    GET_CHALLENGES_FAILED,
    GET_CHALLENGES_SUCCESS,
    GET_STUDENT_TRACKS_CHALLENGES,
    GET_STUDENT_TRACKS_CHALLENGES_SUCCESS,
    GET_STUDENT_TRACKS_CHALLENGES_FAILED,
} from './actions';

export interface MaterialType {
    description: string;
    questionnaire: any;
    encryptQuestionnaire: any;
}

export interface CountType {
    PUBLISHED: number;
    UNPUBLISHED: number;
    DELETED: number;
    ALL: number;
}

export interface LearnChallengesType {
    id: number;
    challengeCategory: string;
    challengeId: string;
    challengeName: string;
    tag: string;
    url: string;
    challengeDescription: string;
}

export interface ChallengesType {
    id: string;
    challengeId: string;
    challengeName: string;
    challengeDescription: string;
    challengeStatus: string;
    challengeCreatedBy: string;
    challengeCreatedDate: string;
    challengeLastUpdatedBy: string;
    challengeLastUpdatedDate: string;
    pointsAwarded: number;
    prerequisitePoints: number;
    imageLink: string;
    introLink: string;
    material: MaterialType;
    users: any;
    submissions: any;
    tags: any;
    feedbacks?: any;
    earnedMarks?: number;
    totalMarks?: number;
    StatusValue?: number;
    statusName?: string;
    trackIds: number[];
    lastSubmissions?: any;
    isChallengeRepublished?: boolean;
}

export interface ChallengeCreateType {
    challengeName: string;
    challengeStatus: string;
    challengeCreatedBy?: string;
    challengeLastUpdatedBy?: string;
    pointsAwarded: number;
    prerequisitePoints: number;
    imageLink: string;
    introLink: string;
    tags?: any[];
    challengeDescription?: string;
}

export interface StudentTracksChallengesType {
    studentId: string | number;
    completed: ChallengesType[];
    notStarted: ChallengesType[];
    onGoing: ChallengesType[];
}

export interface ChallengesInitialState {
    challengesData: ChallengesType[];
    challenge: ChallengesType;
    challengeByIdByStudentData: ChallengesType;
    challengeByIdByStudentLoading: boolean;
    challengeByIdByStudentError: string | null;
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    challengeByIdLoading: boolean;
    challengeByIdError: string | null;
    challengesError: string | null;
    createChallengeError: string | null;
    challengeCreateSuccessMessage: string | null;
    isDeleteLoading: boolean;
    deleteChallengeError: string | null;
    challengeDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editChallengeError: string | null;
    challengeUpdateSuccessMessage: string | null;
    isAddMaterialLoading: boolean;
    materialError: string | null;
    materialSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    challengeBulkSuccessMessage: string | null;
    studentTracksChallengesData: StudentTracksChallengesType;
    studentTracksChallengesLoading: boolean;
    studentTracksChallengesError: string | null;
    getPresignedLoading: string | null;
    getPresignedError: string | null;
    getPresignedSuccess: string | null;
}

const initialState: ChallengesInitialState = {
    challengesData: [],
    challenge: null,
    challengeByIdByStudentData: null,
    challengeByIdByStudentLoading: false,
    challengeByIdByStudentError: null,
    pageCount: null,
    count: null,
    isLoading: false,
    challengeByIdLoading: false,
    challengeByIdError: null,
    challengesError: null,
    createChallengeError: null,
    challengeCreateSuccessMessage: null,
    isDeleteLoading: false,
    deleteChallengeError: null,
    challengeDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editChallengeError: null,
    challengeUpdateSuccessMessage: null,
    isAddMaterialLoading: false,
    materialError: null,
    materialSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    challengeBulkSuccessMessage: null,
    studentTracksChallengesData: null,
    studentTracksChallengesLoading: false,
    studentTracksChallengesError: null,
    getPresignedLoading: null,
    getPresignedError: null,
    getPresignedSuccess: null,
};

const challengesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_CHALLENGES:
            return { ...state, isLoading: true, challengesError: null };
        case GET_CHALLENGES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                challengesData: action.payload.items,
                pageCount: action.payload.totalPages,
            };

        case GET_CHALLENGES_FAILED:
            return { ...state, isLoading: false, challengesError: action.payload };
        case GET_STUDENT_TRACKS_CHALLENGES:
            return {
                ...state,
                studentTracksChallengesLoading: true,
                studentTracksChallengesError: null,
            };
        case GET_STUDENT_TRACKS_CHALLENGES_SUCCESS:
            return {
                ...state,
                studentTracksChallengesLoading: false,
                studentTracksChallengesData: action.payload,
            };
        case GET_STUDENT_TRACKS_CHALLENGES_FAILED:
            return {
                ...state,
                studentTracksChallengesLoading: false,
                studentTracksChallengesError: action.payload,
            };
        case GET_CHALLENGEBYID:
            return { ...state, challengeByIdLoading: true, challengeByIdError: null };
        case GET_CHALLENGEBYID_SUCCESS:
            return {
                ...state,
                challengeByIdLoading: false,
                challenge: action.payload,
            };
        case GET_CHALLENGEBYID_FAILED:
            return { ...state, challengeByIdLoading: false, challengeByIdError: action.payload };

        case GET_CHALLENGE_BY_ID_BY_STUDENT:
            return {
                ...state,
                challengeByIdByStudentLoading: true,
                challengeByIdByStudentError: null,
                challengeByIdByStudentData: null,
            };
        case GET_CHALLENGE_BY_ID_BY_STUDENT_SUCCESS:
            return {
                ...state,
                challengeByIdByStudentLoading: false,
                challengeByIdByStudentData: action.payload,
            };
        case GET_CHALLENGE_BY_ID_BY_STUDENT_FAILED:
            return {
                ...state,
                challengeByIdByStudentLoading: false,
                challengeByIdByStudentError: action.payload,
                challengeByIdByStudentData: null,
            };

        case ADD_CHALLENGE:
            return { ...state, isCreateLoading: true, createChallengeError: null };
        case ADD_CHALLENGE_SUCCESS:
            return {
                ...state,
                isCreateLoading: false,
                challengeCreateSuccessMessage: action.payload,
            };
        case ADD_CHALLENGE_FAILED:
            return { ...state, isCreateLoading: false, createChallengeError: action.payload };
        case DELETE_CHALLENGE:
            return { ...state, isDeleteLoading: true, deleteChallengeError: null };
        case DELETE_CHALLENGE_SUCCESS:
            return {
                ...state,
                isDeleteLoading: false,
                challengeDeleteSuccessMessage: action.payload,
            };
        case DELETE_CHALLENGE_FAILED:
            return { ...state, isDeleteLoading: false, deleteChallengeError: action.payload };
        case EDIT_CHALLENGE:
            return { ...state, isEditLoading: true, editChallengeError: null };
        case EDIT_CHALLENGE_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                challengeUpdateSuccessMessage: action.payload,
            };
        case EDIT_CHALLENGE_FAILED:
            return { ...state, isEditLoading: false, editChallengeError: action.payload };
        case ADD_MATERIAL:
            return { ...state, isAddMaterialLoading: true, materialError: null };
        case ADD_MATERIAL_SUCCESS:
            return {
                ...state,
                isAddMaterialLoading: false,
                materialSuccessMessage: action.payload,
            };
        case ADD_MATERIAL_FAILED:
            return { ...state, isAddMaterialLoading: false, materialError: action.payload };
        case CHALLENGE_BULK_UPDATE:
            return { ...state, isBulkUpdateLoading: true, bulkError: null };
        case CHALLENGE_BULK_UPDATE_SUCCESS:
            return {
                ...state,
                isBulkUpdateLoading: false,
                challengeBulkSuccessMessage: action.payload,
            };
        case CHALLENGE_BULK_UPDATE_FAILED:
            return { ...state, isBulkUpdateLoading: false, bulkError: action.payload };
        case GET_DOCUMENT_PRESIGNED:
            return { ...state, getPresignedLoading: true, getPresignedUrlError: null };
        case GET_DOCUMENT_PRESIGNED_SUCCESS:
            return {
                ...state,
                getPresignedLoading: false,
                getPresignedSuccess: action.payload,
            };
        case GET_DOCUMENT_PRESIGNED_FAILED:
            return { ...state, getPresignedLoading: false, getPresignedError: action.payload };
        case CLEAR_CHALLENGE_MESSAGES:
            return {
                ...state,
                challengeCreateSuccessMessage: null,
                createChallengeError: null,
                challengeUpdateSuccessMessage: null,
                editChallengeError: null,
                deleteChallengeError: null,
                challengeDeleteSuccessMessage: null,
                challengeBulkSuccessMessage: null,
                bulkError: null,
            };
        default:
            return state;
    }
};

export default challengesReducer;
