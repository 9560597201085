import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import Loader from '../../assets/svg/Loader';
import { RootState } from '../../redux/rootReducer';

interface Props {
    modalVisible: boolean;
    templateTitle: string;
    setTemplateTitle: any;
    setModalVisible: (value: boolean) => void;
    setOnClickYesBtn?: (value: boolean) => void;
    setOnClickNoBtn?: (value: boolean) => void;
}

export default function NotificationTemplateSaveFeedback({
    modalVisible,
    templateTitle,
    setTemplateTitle,
    setModalVisible,
    setOnClickYesBtn,
    setOnClickNoBtn,
}: Props): ReactElement {
    const createNotificationTemplateSuccess = useSelector(
        (state: RootState) => state.notifications.createNotificationTemplateSuccessMessage
    );

    const createNotificationTemplateError = useSelector(
        (state: RootState) => state.notifications.createNotificationTemplateError
    );

    const createNotificationTemplateLoading = useSelector(
        (state: RootState) => state.notifications.isTemplateCreateLoading
    );

    const [templateTitleModal, setTemplateTitleModal] = useState<any>();

    useEffect(() => {
        if (createNotificationTemplateSuccess || createNotificationTemplateError) {
            setModalVisible(false);
            setTemplateTitleModal(false);
        }
    }, [createNotificationTemplateError, createNotificationTemplateSuccess, setModalVisible]);

    return (
        <>
            <Modal
                open={modalVisible}
                onClose={() => {
                    setModalVisible(false);
                    // setOnClickNoBtn(true);
                }}
                center
                showCloseIcon={false}
                styles={{
                    modal: {
                        borderRadius: 5,
                        maxWidth: '628px',
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        boxShadow: '0 12px 15px 0 rgb(0 0 0 / 10%)',
                    },
                    overlay: { background: 'none' },
                }}
            >
                <div className="">
                    <div className="text-base text-center text-gray-800 px-4 pt-6 pb-4">
                        {`Do you want to save this notification as a template, this may help you to reuse it.`}
                    </div>
                    <div className="flex justify-end w-full gap-4">
                        <button
                            className="bg-white text-blue-700 border-2 border-gray-300 rounded-sm focus:outline-none mt-4 px-8 mr-2 md:mr-0 py-2"
                            onClick={() => {
                                // setOnClickNoBtn(true);
                                setModalVisible(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="bg-blue-700 text-white rounded-sm focus:outline-none mt-4 px-8 mr-2 md:mr-0 py-2"
                            onClick={() => {
                                // setOnClickYesBtn(true);
                                setTemplateTitleModal(true);
                            }}
                        >
                            yes
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={templateTitleModal}
                onClose={() => {
                    setTemplateTitleModal(false);
                    setOnClickNoBtn(true);
                }}
                center
                showCloseIcon={false}
                styles={{
                    modal: {
                        borderRadius: 5,
                        maxWidth: '628px',
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        boxShadow: '0 12px 15px 0 rgb(0 0 0 / 10%)',
                    },
                    overlay: { background: 'none' },
                }}
            >
                <div className="">
                    <div className="mb-6 w-full md:mb-0 flex my-4">
                        <label
                            htmlFor="notificationTitle"
                            className={`flex items-start font-medium leading-149 text-sm md:text-base text-gray-700`}
                        >
                            Template Title
                        </label>

                        <div className="flex flex-col w-full">
                            <input
                                className={`rounded-sm flex items-center focus:outline-none  p-2 border-2 text-base focus:border-blue-900 border-gray-300 md:text-base`}
                                id="notificationTitle"
                                type="text"
                                value={templateTitle}
                                onChange={e => {
                                    setTemplateTitle(e.target.value);
                                }}
                                autoComplete="off"
                            />
                            {/* {errors.notificationTitle &&
                                                    touched.notificationTitle ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.notificationTitle}
                                                        </div>
                                                    ) : null} */}
                        </div>
                    </div>
                    <div className="flex justify-end w-full gap-4">
                        <button
                            className="bg-white text-blue-700 border-2 border-gray-300 rounded-sm focus:outline-none mt-4 px-8 mr-2 md:mr-0 py-2"
                            onClick={() => {
                                setTemplateTitleModal(true);
                                setModalVisible(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="bg-blue-700 text-white rounded-sm focus:outline-none mt-4 px-8 mr-2 md:mr-0 py-2"
                            onClick={() => {
                                setOnClickYesBtn(true);
                            }}
                        >
                            {createNotificationTemplateLoading ? <Loader /> : 'Ok'}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
