/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import AddNewTag from '../../AddNewTag';
import { useDispatch, useSelector } from 'react-redux';
import { getInstituteById } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { instituteTypes, s3UrlPrefix } from '../../../constants';
import InstituteSuccessfullModal from '../../modals/InstituteSuccesfullModal';
import { InstitutesType } from '../../../redux/institutes/reducers';
import * as HiIcons from 'react-icons/hi';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroupsView from '../../TagGroupsView';
interface CustomState {
    institute: InstitutesType;
}
interface paramsType {
    id: string;
}
export default function AddNewInstitute() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const params = useParams<paramsType>();
    const instituteState = location.state as CustomState;

    const editInstituteError = useSelector(
        (state: RootState) => state.institutes.editInstituteError
    );
    const instituteByIdLoading = useSelector(
        (state: RootState) => state.institutes.instituteByIdLoading
    );

    const institute = useSelector((state: RootState) => state.institutes.institute);
    const instituteUpdateSuccessMessage = useSelector(
        (state: RootState) => state.institutes.instituteUpdateSuccessMessage
    );
    const [tags, setTags] = useState(categorizeTags(instituteState?.institute?.tags));
    const [imageUrl] = useState(`${s3UrlPrefix}${instituteState?.institute?.bannerImage}`);
    const [imageLocation] = useState(instituteState?.institute?.bannerImage);
    const [logoUrl] = useState(`${s3UrlPrefix}${instituteState?.institute?.logoImage}`);
    const [logoLocation] = useState(instituteState?.institute?.logoImage);
    const [instituteEditSuccessVisible, setInstituteEditSuccessVisible] = useState(false);
    const [instituteErrorVisible, setInstituteErrorVisible] = useState(false);
    const [helpTxt, setHelpTxt] = useState(false);
    const category1Array = instituteState?.institute?.instCategory1?.options?.map(elm => {
        return { option1: elm };
    });
    const category2Array = instituteState?.institute?.instCategory2?.options?.map(elm => {
        return { option2: elm };
    });
    const category3Array = instituteState?.institute?.instCategory3?.options?.map(elm => {
        return { option3: elm };
    });
    const [category1List] = useState<any>(category1Array || [{ option1: '' }]);
    const [category2List] = useState<any>(category2Array || [{ option2: '' }]);
    const [category3List] = useState<any>(category3Array || [{ option3: '' }]);
    const categoryOptions = [
        { id: 0, val: 'Vocational training institute' },
        { id: 1, val: 'Government University' },
        { id: 2, val: 'Private University' },
        { id: 3, val: 'Other' },
    ];
    useEffect(() => {
        dispatch(getInstituteById(params.id));
    }, [dispatch, params.id]);

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    {instituteByIdLoading ? (
                        <Loader />
                    ) : (
                        <div className="relative">
                            <>
                                <div className="flex justify-between items-center mb-4 md:mb-6">
                                    <p className="text-xl font-semibold text-left">
                                        View Institute
                                    </p>
                                    <div
                                        className={`flex h-14 md:h-16 ${
                                            instituteState?.institute?.instStatus !== 'DELETED'
                                                ? 'w-1/2 md:w-1/4 lg:pl-8'
                                                : 'w-1/4 md:w-1/6 lg:pl-16'
                                        }`}
                                    >
                                        <button
                                            type="submit"
                                            className={`md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm ${
                                                instituteState?.institute?.instStatus !== 'DELETED'
                                                    ? 'w-full md:w-1/2'
                                                    : 'w-full hover:bg-blue-700 hover:text-white'
                                            } my-2 focus:outline-none`}
                                            onClick={() => {
                                                history.push('/admin/institutes');
                                            }}
                                        >
                                            OK
                                        </button>
                                        {instituteState?.institute?.instStatus !== 'DELETED' && (
                                            <button
                                                type="submit"
                                                className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-normal rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => {
                                                    history.push({
                                                        pathname: `/admin/institutes/${instituteState?.institute?.id}/edit`,
                                                        state: { institute: institute },
                                                    });
                                                }}
                                            >
                                                Edit
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 pb-4 mb-6">
                                    <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="title"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Institute ID
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={institute?.instId}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="title"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Institute Name
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={institute?.instName}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="my-6 w-full md:mb-0 flex">
                                            <label
                                                className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                            >
                                                Institute Type
                                            </label>
                                            <div className="w-full flex">
                                                <input
                                                    className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={
                                                        institute?.category ===
                                                        instituteTypes.VOCATIONAL_INSTITUTE
                                                            ? categoryOptions[0].val
                                                            : institute?.category ===
                                                              instituteTypes.GOVERNMENT_UNIVERSITY
                                                            ? categoryOptions[1].val
                                                            : institute?.category ===
                                                              instituteTypes.PRIVATE_UNIVERSITY
                                                            ? categoryOptions[2].val
                                                            : categoryOptions[3].val
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="instLocation"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Institute Address
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm flex items-center border-gray-300 focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={institute?.location}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-2">
                                            <label
                                                htmlFor="instCategory1"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Category 1
                                            </label>
                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={institute?.instCategory1?.name}
                                                    disabled
                                                />

                                                <div className="mb-6 w-3/4 md:w-4/5 md:mb-0 flex mt-2 mb-4">
                                                    <label
                                                        htmlFor="instCategory1"
                                                        className={`flex items-start md:mt-2 w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Options
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        {category1List?.map((option1, i) => {
                                                            return (
                                                                <div className="flex mt-2" key={i}>
                                                                    <input
                                                                        className={`border-2 w-full p-1 border-gray-300`}
                                                                        value={option1.option1}
                                                                        disabled
                                                                    />
                                                                    <div className="flex"></div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-2">
                                            <label
                                                htmlFor="instCategory1"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Category 2
                                            </label>
                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={institute?.instCategory2?.name}
                                                    disabled
                                                />

                                                <div className="mb-6 w-3/4 md:w-4/5 md:mb-0 flex mt-2 mb-4">
                                                    <label
                                                        htmlFor="instCategory2"
                                                        className={`flex items-start md:mt-2 w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Options
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        {category2List?.map((option2, i) => {
                                                            return (
                                                                <div className="flex mt-2" key={i}>
                                                                    <input
                                                                        className={`border-2 w-full p-1 border-gray-300`}
                                                                        value={option2.option2}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-2">
                                            <label
                                                htmlFor="instCategory3"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Category 3
                                            </label>
                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={institute?.instCategory3?.name}
                                                    disabled
                                                />

                                                <div className="mb-6 w-3/4 md:w-4/5 md:mb-0 flex mt-2 mb-4">
                                                    <label
                                                        htmlFor="instCategory3"
                                                        className={`flex items-start md:mt-2 w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Options
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        {category3List?.map((option3, i) => {
                                                            return (
                                                                <div className="flex mt-2" key={i}>
                                                                    <input
                                                                        className={`border-2 w-full p-1 border-gray-300`}
                                                                        value={option3.option3}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="repName"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Representative Name
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm flex items-center border-gray-300 focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={institute?.repName}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="instEmail"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Email
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm border-gray-300 flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={institute?.instEmail}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="contactNo"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Contact Number
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm border-gray-300 flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={institute?.contactNo}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="siteUrl"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Website URL
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <a
                                                    className={`rounded-sm flex items-center h-11 focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base`}
                                                    href={`https://${institute?.siteUrl}`}
                                                    target={'_blank'}
                                                    rel="noopener noreferrer"
                                                >
                                                    {institute?.siteUrl}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="my-6 w-full md:mb-0 flex h-24">
                                            <label
                                                htmlFor="description"
                                                className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                            >
                                                Institute Description
                                            </label>
                                            <div className="flex flex-col w-full">
                                                <textarea
                                                    className={`resize-none border-gray-300 focus:outline-none p-2 w-4/5 border-2 text-sm h-24 focus:border-blue-900`}
                                                    id="description"
                                                    value={institute?.instDescription}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="title"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                MOU Signed?
                                                <div className="w-1/4">
                                                    <HiIcons.HiQuestionMarkCircle
                                                        onClick={() => {
                                                            setHelpTxt(!helpTxt);
                                                        }}
                                                        className="ml-2 mt-1 cursor-pointer"
                                                    />
                                                    {helpTxt ? (
                                                        <div
                                                            onMouseOverCapture={() => {
                                                                setHelpTxt(true);
                                                            }}
                                                            onMouseLeave={() => setHelpTxt(false)}
                                                            className="border-2 text-sm rounded-md absolute z-50 px-2 py-3 mt-3 bg-white"
                                                            style={{
                                                                boxShadow:
                                                                    '0px 0px 10px 1px rgba(197, 203, 220, 0.32)',
                                                                width: '360px',
                                                            }}
                                                        >
                                                            Please click Yes if the institute has
                                                            signed a MOU with SLASSCOM and the
                                                            institute will then appear on the
                                                            Institute Homepage of the FCB website.
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </label>
                                            <div className="flex text-sm md:text-base items-center w-full">
                                                <input
                                                    className="mr-1"
                                                    type="radio"
                                                    id="true"
                                                    name="mouSigned"
                                                    checked={institute?.mouSigned === true}
                                                    disabled
                                                />
                                                <label className="mr-4 w-1/4">Yes</label>
                                                <input
                                                    className="mr-1"
                                                    type="radio"
                                                    id="false"
                                                    name="mouSigned"
                                                    checked={institute?.mouSigned === false}
                                                    disabled
                                                />
                                                <label className="">No</label>
                                            </div>
                                        </div>
                                        <div className="my-6 w-full md:mb-0 flex">
                                            <label
                                                htmlFor="description"
                                                className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                            >
                                                Banner Image
                                            </label>
                                            <div
                                                className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                                    imageUrl ? 'w-fit' : ''
                                                }  border-opacity-100 border-2 border-black-600`}
                                            >
                                                <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64">
                                                    {imageUrl && imageLocation && (
                                                        <img
                                                            src={imageUrl}
                                                            alt={'name'}
                                                            className="h-44 lg:h-64"
                                                            style={{ maxHeight: 300 }}
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    className="absolute mt-4 md:mt-0 my-4 text-center"
                                                    style={{
                                                        left: '50%',
                                                        transform: 'translate(-50%,-50%)',
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                        <div className="my-6 w-full md:mb-0 flex">
                                            <label
                                                htmlFor="logo"
                                                className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                            >
                                                Logo Image
                                            </label>
                                            <div
                                                className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                                    logoUrl ? 'w-fit' : ''
                                                }  border-opacity-100 border-2 border-black-600`}
                                            >
                                                <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64">
                                                    {logoUrl && logoLocation && (
                                                        <img
                                                            src={logoUrl}
                                                            alt={'name'}
                                                            className="h-44 lg:h-64"
                                                            style={{ maxHeight: 300 }}
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    className="absolute mt-4 md:mt-0 my-4 text-center"
                                                    style={{
                                                        left: '50%',
                                                        transform: 'translate(-50%,-50%)',
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                        <div className="flex border-2 flex-col ml-2 justify-items-center">
                                            <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                                <div className="sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                    Tags
                                                </div>
                                            </div>
                                            <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                                <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                    <TagGroupsView selectedTags={tags} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {instituteUpdateSuccessMessage && (
                                    <InstituteSuccessfullModal
                                        modalVisible={instituteEditSuccessVisible}
                                        setModalVisible={setInstituteEditSuccessVisible}
                                    />
                                )}
                                {editInstituteError && (
                                    <InstituteSuccessfullModal
                                        modalVisible={instituteErrorVisible}
                                        setModalVisible={setInstituteErrorVisible}
                                    />
                                )} */}
                            </>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
