import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as HiIcons from 'react-icons/hi';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Camera from '../../../assets/svg/Camera';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
    accountType,
    error,
    logoResolutionErrorMsg,
    moduleNames,
    phoneRegExp,
    resolutionError,
    s3Config,
    s3UrlPrefix,
} from '../../../constants';
import S3 from 'react-aws-s3';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import {
    clearCompaniesMessages,
    editCompany,
    getCompanyById,
    getTags,
} from '../../../redux/rootActions';
import { CompanyType } from '../../../redux/companies/reducers';
import CompanySuccessModal from '../../modals/CompanySusscessModal';
import moment from 'moment';
import CropModal from '../../modals/cropModal';
import PhoneInput from 'react-phone-input-2';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroups from '../../TagGroups';
interface CustomState {
    company: CompanyType;
}
interface paramsType {
    id: string;
}
export default function EditCompany() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const params = useParams<paramsType>();
    const companyState = location.state as CustomState;

    const editCompanyError = useSelector((state: RootState) => state.companies.editCompanyError);
    const isEditLoading = useSelector((state: RootState) => state.companies.isEditLoading);
    const companyByIdLoading = useSelector(
        (state: RootState) => state.companies.companyByIdLoading
    );

    const company = useSelector((state: RootState) => state.companies.company);
    const deleteCompanyError = useSelector(
        (state: RootState) => state.companies.deleteCompanyError
    );
    const companyDeleteSuccessMessage = useSelector(
        (state: RootState) => state.companies.companyDeleteSuccessMessage
    );
    const companyUpdateSuccessMessage = useSelector(
        (state: RootState) => state.companies.companyUpdateSuccessMessage
    );
    const formRef = useRef<any>();
    const [imageUrl, setImageUrl] = useState(`${s3UrlPrefix + companyState?.company?.bannerImage}`);
    const [imageLocation, setImageLocation] = useState(companyState?.company?.bannerImage);
    const [isUploading, setIsUploading] = useState(false);
    const [logoUrl, setLogoUrl] = useState(`${s3UrlPrefix + companyState?.company?.comImage}`);
    const [logoLocation, setLogoLocation] = useState(companyState?.company?.comImage);
    const [isLogoUploading, setIsLogoUploading] = useState(false);
    const [instituteSuccessVisible, setInstituteSuccessVisible] = useState(false);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [tags, setTags] = useState(companyState?.company?.tags);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [logoResolutionError, setLogoResolutionError] = useState(false);
    const [bannerResolutionError, setBannerResolutionError] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [uploadLogoError, setUploadLogoError] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [openCrop, setOpenCrop] = useState(false);
    const [openLogoCrop, setLogoOpenCrop] = useState(false);

    const [tagsHelpText, setTagsHelpText] = useState<boolean>(false);

    const serchTagNameOrId = e => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        setSelectedTags(categorizeTags(tags));
    }, [tags]);

    useEffect(() => {
        if (showDropdown) {
            dispatch(getTags(true, moduleNames.Companies, searchTerm));
        }
    }, [dispatch, searchTerm, showDropdown]);

    useEffect(() => {
        setTags(companyState?.company?.tags);
    }, [companyState]);
    useEffect(() => {
        dispatch(getCompanyById(params.id));
    }, [dispatch, params.id]);

    const handleClearMessages = useCallback(() => {
        if (
            companyUpdateSuccessMessage ||
            editCompanyError ||
            companyDeleteSuccessMessage ||
            deleteCompanyError
        ) {
            setInstituteSuccessVisible(true);
        }
    }, [
        dispatch,
        companyUpdateSuccessMessage,
        editCompanyError,
        history,
        companyDeleteSuccessMessage,
        deleteCompanyError,
    ]);
    const handleClose = () => {
        dispatch(clearCompaniesMessages());
        if (!editCompanyError) {
            history.push('/admin/companies');
        }
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    const uploadImageToS3 = async croppedImg => {
        setIsUploading(true);
        const imageName = Date.now().toString();
        s3Config.dirName = `companies/${imageName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(croppedImg, imageName)
            .then(data => {
                setImageLocation(data.key);
                setIsUploading(false);
                setUploadError(false);
            })
            .catch(err => {
                console.error(err);
                setIsUploading(false);
                setUploadError(true);
            });
    };
    const uploadLogoToS3 = async croppedImg => {
        setIsLogoUploading(true);
        const imageName = Date.now().toString();
        s3Config.dirName = `companies/${imageName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(croppedImg, imageName)
            .then(data => {
                setLogoLocation(data.key);
                setIsLogoUploading(false);
                setUploadLogoError(false);
            })
            .catch(err => {
                console.error(err);
                setIsLogoUploading(false);
                setUploadLogoError(true);
            });
    };
    const handleImage = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setImageUrl(URL.createObjectURL(file));
            setOpenCrop(true);
        }
    };

    const handleLogo = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setLogoUrl(URL.createObjectURL(file));
            setLogoOpenCrop(true);
        }
    };
    const handleCloseCropModal = () => {
        setOpenCrop(false);
        setImageUrl(`${s3UrlPrefix + companyState?.company?.bannerImage}`);
    };
    const handleCloseLogoCropModal = () => {
        setLogoOpenCrop(false);
        setLogoUrl(`${s3UrlPrefix + companyState?.company?.comImage}`);
    };
    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    {companyByIdLoading ? (
                        <Loader />
                    ) : (
                        <div className="relative">
                            <Formik
                                enableReinitialize={true}
                                innerRef={formRef}
                                initialValues={{
                                    comName: company?.comName,
                                    comEmail: company?.comEmail,
                                    contactNo: company?.contactNo || '',
                                    repName: company?.repName || '',
                                    accountType: company?.accountType,
                                    slassMember: company?.slassMember,
                                    comDescription: company?.comDescription,
                                    comJoinedDate: company?.comJoinedDate,
                                    image: imageLocation,
                                    logo: logoLocation,
                                    comImage: company?.comImage,
                                    subStartDate: company?.subStartDate || '',
                                    subEndDate: company?.subEndDate || '',
                                    siteUrl: company?.siteUrl,
                                    careerOpportunities: company?.careerOpportunities,
                                    applyURL: company?.applyURL,
                                }}
                                validationSchema={Yup.object({
                                    comName: Yup.string()
                                        .max(100, 'Name should be less than 100 characters')
                                        .required('Name is required'),
                                    comDescription: Yup.string()
                                        .nullable()
                                        .max(500, 'Description should be less than 500 characters')
                                        .required('Description is required'),
                                    comEmail: Yup.string()
                                        .email('Invalid email')
                                        .required('Email is required'),
                                    repName: Yup.string().required(
                                        'Representative name is required'
                                    ),
                                    image: Yup.string()
                                        .nullable()
                                        .required('Company banner is required'),
                                    logo: Yup.string()
                                        .nullable()
                                        .required('Company logo is required'),

                                    contactNo: Yup.string()
                                        .matches(phoneRegExp, 'Contact number is not valid')
                                        .required('Contact number is required'),
                                    slassMember: Yup.boolean(),
                                    subStartDate: Yup.date().when('slassMember', slassMember => {
                                        if (slassMember)
                                            return Yup.date().required(
                                                'Subscription start date is required'
                                            );
                                    }),
                                    subEndDate: Yup.date().when('slassMember', slassMember => {
                                        if (slassMember)
                                            return Yup.date().required(
                                                'Subscription end date is required'
                                            );
                                    }),
                                    siteUrl: Yup.string()
                                        .nullable()
                                        .matches(
                                            // eslint-disable-next-line no-useless-escape
                                            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                                            'Enter correct url!'
                                        ),
                                    careerOpportunities: Yup.string()
                                        .nullable()
                                        .max(
                                            1000,
                                            'Career Oppotunities should be less than 1000 characters'
                                        ),
                                    applyURL: Yup.string()
                                        .nullable()
                                        .matches(
                                            // eslint-disable-next-line no-useless-escape
                                            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                                            'Enter correct url!'
                                        ),
                                })}
                                onSubmit={async values => {
                                    dispatch(
                                        editCompany(
                                            company?.id,
                                            {
                                                comName: values.comName.trim(),
                                                comEmail: values.comEmail,
                                                contactNo: values.contactNo,
                                                repName: values.repName.trim(),
                                                accountType: values.accountType,
                                                comDescription: values.comDescription.trim(),
                                                slassMember: values.slassMember,
                                                subStartDate: values.subStartDate,
                                                subEndDate: values.subEndDate,
                                                comImage: logoLocation,
                                                bannerImage: imageLocation,
                                                siteUrl: values.siteUrl,
                                                careerOpportunities: values.careerOpportunities,
                                                applyURL: values.applyURL,
                                                comStatus:
                                                    moment(values.subStartDate, 'YYYY-MM-DD').diff(
                                                        moment(
                                                            moment().format('YYYY-MM-DD'),
                                                            'YYYY-MM-DD'
                                                        ),
                                                        'days'
                                                    ) <= 0
                                                        ? 'ACTIVE'
                                                        : 'INACTIVE',
                                                tags: tags,
                                            },
                                            null
                                        )
                                    );
                                }}
                            >
                                {({
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    values,
                                    errors,
                                    touched,
                                }) => (
                                    <>
                                        <div className="flex justify-between items-center mb-4 md:mb-6">
                                            <p className="text-xl font-semibold text-left">
                                                Edit Company
                                            </p>
                                            <div className="flex w-1/2 md:w-1/4 lg:pl-8 h-16">
                                                <button
                                                    className="md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                    onClick={() =>
                                                        history.push('/admin/companies/')
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                    onClick={() => handleSubmit()}
                                                >
                                                    {isEditLoading ? <Loader /> : 'Save'}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="border-2 border-gray-300 px-6 pt-2 pb-4 mb-6">
                                            <div className="grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8">
                                                <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="title"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Company ID
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                                value={company?.comId}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="title"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Company Name
                                                            <div className="text-red-600">*</div>
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.comName &&
                                                                    touched.comName
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="name"
                                                                type="text"
                                                                value={values.comName}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'comName',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.comName && touched.comName ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.comName}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex">
                                                        <label
                                                            className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Type of Account
                                                        </label>
                                                        <div className="flex text-sm md:text-base items-center w-full">
                                                            <input
                                                                className="mr-1"
                                                                type="radio"
                                                                value={accountType.CORPORATE}
                                                                id="corporate"
                                                                defaultChecked={
                                                                    company?.accountType ===
                                                                    accountType.CORPORATE
                                                                }
                                                                name="accountType"
                                                                onChange={handleChange(
                                                                    'accountType'
                                                                )}
                                                            />
                                                            <label className="mr-4 w-1/4">
                                                                Corporate
                                                            </label>
                                                            <input
                                                                className="mr-1"
                                                                type="radio"
                                                                value={
                                                                    accountType.PREMIUM_CORPORATE
                                                                }
                                                                id="Premium"
                                                                defaultChecked={
                                                                    company?.accountType ===
                                                                    accountType.PREMIUM_CORPORATE
                                                                }
                                                                name="accountType"
                                                                onChange={handleChange(
                                                                    'accountType'
                                                                )}
                                                            />
                                                            <label>Premium Corporate</label>
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex">
                                                        <label
                                                            className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            SLASSCOM Member
                                                        </label>
                                                        <div className="flex text-sm md:text-base items-center w-full">
                                                            <input
                                                                className="mr-1"
                                                                type="radio"
                                                                id="true"
                                                                name="slassMember"
                                                                defaultChecked={
                                                                    company?.slassMember === true
                                                                }
                                                                onChange={() =>
                                                                    setFieldValue(
                                                                        'slassMember',
                                                                        true
                                                                    )
                                                                }
                                                            />
                                                            <label className="mr-4 w-1/4">
                                                                Yes
                                                            </label>
                                                            <input
                                                                className="mr-1"
                                                                type="radio"
                                                                id="false"
                                                                name="slassMember"
                                                                defaultChecked={
                                                                    company?.slassMember === false
                                                                }
                                                                onChange={() =>
                                                                    setFieldValue(
                                                                        'slassMember',
                                                                        false
                                                                    )
                                                                }
                                                            />
                                                            <label className="">No</label>
                                                        </div>
                                                    </div>
                                                    {values.slassMember && (
                                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                                            <label
                                                                htmlFor="repName"
                                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                            >
                                                                Subscription Start Date
                                                                <div className="text-red-600">
                                                                    *
                                                                </div>
                                                            </label>

                                                            <div className="flex flex-col w-full">
                                                                <input
                                                                    style={{
                                                                        minWidth: '80%',
                                                                    }}
                                                                    className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                        errors.subStartDate &&
                                                                        touched.subStartDate
                                                                            ? 'border-red-500'
                                                                            : 'border-gray-300'
                                                                    } md:text-base`}
                                                                    id="subStartDate"
                                                                    type="date"
                                                                    value={moment(
                                                                        values.subStartDate
                                                                    )
                                                                        .local()
                                                                        .format('YYYY-MM-DD')}
                                                                    onChange={e => {
                                                                        setFieldValue(
                                                                            'subStartDate',
                                                                            moment(
                                                                                e.target.value
                                                                            ).toISOString()
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                />
                                                                {errors.subStartDate &&
                                                                touched.subStartDate ? (
                                                                    <div className="text-red-500 text-xs my-1">
                                                                        {errors.subStartDate}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {values.slassMember && (
                                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                                            <label
                                                                htmlFor="repName"
                                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                            >
                                                                Subscription End Date
                                                                <div className="text-red-600">
                                                                    *
                                                                </div>
                                                            </label>

                                                            <div className="flex flex-col w-full">
                                                                <input
                                                                    style={{
                                                                        minWidth: '80%',
                                                                    }}
                                                                    className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                        errors.subEndDate &&
                                                                        touched.subEndDate
                                                                            ? 'border-red-500'
                                                                            : 'border-gray-300'
                                                                    } md:text-base`}
                                                                    id="subEndDate"
                                                                    type="date"
                                                                    value={moment(values.subEndDate)
                                                                        .local()
                                                                        .format('YYYY-MM-DD')}
                                                                    onChange={e => {
                                                                        setFieldValue(
                                                                            'subEndDate',
                                                                            moment(
                                                                                e.target.value
                                                                            ).toISOString()
                                                                        );
                                                                    }}
                                                                    min={values.subStartDate}
                                                                    autoComplete="off"
                                                                />
                                                                {errors.subEndDate &&
                                                                touched.subEndDate ? (
                                                                    <div className="text-red-500 text-xs my-1">
                                                                        {errors.subEndDate}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="repName"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Representative Name
                                                            <div className="text-red-600">*</div>
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.repName &&
                                                                    touched.repName
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="repName"
                                                                type="text"
                                                                value={values.repName}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'repName',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.repName && touched.repName ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.repName}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="instEmail"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Email
                                                            <div className="text-red-600">*</div>
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.comEmail &&
                                                                    touched.comEmail
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="name"
                                                                type="text"
                                                                value={values.comEmail}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'comEmail',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.comEmail && touched.comEmail ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.comEmail}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="contactNo"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Contact Number
                                                            <div className="text-red-600">*</div>
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <label
                                                                htmlFor=""
                                                                className={`rounded-sm rounded-tl rounded-bl flex items-center focus:outline-none w-4/5 md:w-3/4  border-2 text-base focus:border-blue-900 ${
                                                                    errors.contactNo &&
                                                                    touched.contactNo
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                            >
                                                                <PhoneInput
                                                                    inputStyle={{
                                                                        width: '100%',
                                                                        height: '42px',
                                                                        fontSize: '14px',
                                                                        paddingLeft: '48px',
                                                                        borderRadius: '4px',
                                                                        border: 0,
                                                                        fontFamily: 'Lato',
                                                                    }}
                                                                    dropdownClass={
                                                                        'mobileNumberCountryDropdown'
                                                                    }
                                                                    country="lk"
                                                                    specialLabel={''}
                                                                    value={values.contactNo?.toString()}
                                                                    placeholder="Enter your Mobile Number (Eg: 94712345678)"
                                                                    onChange={e => {
                                                                        setFieldValue(
                                                                            'contactNo',
                                                                            e
                                                                        );
                                                                    }}
                                                                />
                                                            </label>
                                                            {errors.contactNo &&
                                                            touched.contactNo ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.contactNo}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="siteUrl"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Website URL
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                                id="name"
                                                                type="text"
                                                                value={values.siteUrl}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'siteUrl',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.siteUrl && touched.siteUrl ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.siteUrl}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex h-24">
                                                        <label
                                                            htmlFor="description"
                                                            className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Company Description
                                                            <div className="text-red-600">*</div>
                                                        </label>
                                                        <div className="flex flex-col w-full">
                                                            <textarea
                                                                className={`resize-none focus:outline-none p-2 w-4/5 border-2 text-sm md:text-base h-24 focus:border-blue-900 ${
                                                                    errors.comDescription &&
                                                                    touched.comDescription
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                }`}
                                                                id="description"
                                                                value={values.comDescription}
                                                                placeholder=""
                                                                onChange={handleChange(
                                                                    'comDescription'
                                                                )}
                                                            />
                                                            {errors.comDescription &&
                                                            touched.comDescription ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.comDescription}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex h-24">
                                                        <label
                                                            htmlFor="opportunities"
                                                            className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Career Opportunities
                                                        </label>
                                                        <div className="flex flex-col w-full">
                                                            <textarea
                                                                className={`resize-none focus:outline-none p-2 w-4/5 border-2 text-sm md:text-base h-24 focus:border-blue-900 ${
                                                                    errors.careerOpportunities &&
                                                                    touched.careerOpportunities
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                }`}
                                                                id="opportunities"
                                                                value={values.careerOpportunities}
                                                                placeholder=""
                                                                onChange={handleChange(
                                                                    'careerOpportunities'
                                                                )}
                                                            />
                                                            {errors.careerOpportunities &&
                                                            touched.careerOpportunities ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.careerOpportunities}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="applNowURL"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Apply Now URL
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                                id="applNowURL"
                                                                type="text"
                                                                value={values.applyURL}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'applyURL',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.applyURL && touched.applyURL ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.applyURL}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex">
                                                        <label
                                                            htmlFor="description"
                                                            className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Company Banner Image
                                                            <div className="text-red-600">*</div>
                                                        </label>
                                                        <div
                                                            className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                                                imageUrl ? 'w-fit' : ''
                                                            }  border-opacity-100 border-2 border-black-600`}
                                                        >
                                                            <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64">
                                                                {!imageLocation && !isUploading && (
                                                                    <div className="w-full">
                                                                        <div className="flex justify-center">
                                                                            <Camera />
                                                                        </div>
                                                                        <div>
                                                                            <label
                                                                                className="underline cursor-pointer text-sm"
                                                                                htmlFor="imageLink"
                                                                            >
                                                                                Upload Image
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {imageUrl && imageLocation && (
                                                                    <img
                                                                        src={imageUrl}
                                                                        alt={'name'}
                                                                        className="h-44 lg:h-64"
                                                                        style={{ maxHeight: 300 }}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="absolute mt-1 text-gray-400 text-sm font-medium leading-149">
                                                                Image size 1520*422
                                                            </div>
                                                            <div
                                                                className="absolute mt-8 w-max text-center"
                                                                style={{
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%,-50%)',
                                                                }}
                                                            >
                                                                {imageUrl &&
                                                                    imageLocation &&
                                                                    !isUploading && (
                                                                        <div>
                                                                            <label
                                                                                className="underline cursor-pointer text-sm"
                                                                                htmlFor="imageLink"
                                                                            >
                                                                                Change Image
                                                                            </label>
                                                                        </div>
                                                                    )}
                                                                {isUploading && (
                                                                    <div className="flex items-center text-sm">
                                                                        <div className="mr-2">
                                                                            Uploading
                                                                        </div>
                                                                        <div>
                                                                            <Loader />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div
                                                                    className={`text-red-500 text-xs ${
                                                                        imageLocation
                                                                            ? 'my-1'
                                                                            : 'my-2'
                                                                    }`}
                                                                >
                                                                    {errors.image && touched.image
                                                                        ? errors.image
                                                                        : bannerResolutionError
                                                                        ? resolutionError
                                                                        : uploadError
                                                                        ? error
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <input
                                                            className={`focus:outline-none w-full focus:border-blue-900`}
                                                            id="imageLink"
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async e => {
                                                                const file = [];
                                                                file.push(e.target.files[0]);
                                                                setFieldValue(
                                                                    'image',
                                                                    file[0].name
                                                                );
                                                                handleImage(e);
                                                            }}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={`my-6 w-full md:mb-0 flex ${
                                                            bannerResolutionError ? 'pt-10' : 'pt-6'
                                                        }`}
                                                    >
                                                        <label
                                                            htmlFor="logo"
                                                            className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Company Logo
                                                            <div className="text-red-600">*</div>
                                                        </label>
                                                        <div
                                                            className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                                                logoUrl ? 'w-fit' : ''
                                                            }  border-opacity-100 border-2 border-black-600`}
                                                        >
                                                            <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64">
                                                                {!logoLocation && !isLogoUploading && (
                                                                    <div className="w-full">
                                                                        <div className="flex justify-center">
                                                                            <Camera />
                                                                        </div>
                                                                        <div>
                                                                            <label
                                                                                className="underline cursor-pointer text-sm"
                                                                                htmlFor="logoLink"
                                                                            >
                                                                                Upload Logo
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {logoUrl && logoLocation && (
                                                                    <img
                                                                        src={logoUrl}
                                                                        alt={'name'}
                                                                        className="h-44 lg:h-64"
                                                                        style={{ maxHeight: 300 }}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="absolute mt-1 text-gray-400 text-sm font-medium leading-149">
                                                                Image size 275*127
                                                            </div>
                                                            <div
                                                                className="absolute mt-8 w-max text-center"
                                                                style={{
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%,-50%)',
                                                                }}
                                                            >
                                                                {logoUrl &&
                                                                    logoLocation &&
                                                                    !isLogoUploading && (
                                                                        <div>
                                                                            <label
                                                                                className="underline cursor-pointer text-sm"
                                                                                htmlFor="logoLink"
                                                                            >
                                                                                Change Logo
                                                                            </label>
                                                                        </div>
                                                                    )}
                                                                {isLogoUploading && (
                                                                    <div className="flex items-center text-sm">
                                                                        <div className="mr-2">
                                                                            Uploading
                                                                        </div>
                                                                        <div>
                                                                            <Loader />
                                                                        </div>
                                                                    </div>
                                                                )}{' '}
                                                                <div className="text-red-500 text-xs mt-1">
                                                                    {errors.logo && touched.logo
                                                                        ? errors.logo
                                                                        : logoResolutionError
                                                                        ? logoResolutionErrorMsg
                                                                        : uploadLogoError
                                                                        ? error
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <input
                                                            className={`focus:outline-none w-full focus:border-blue-900`}
                                                            id="logoLink"
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async e => {
                                                                const file = [];
                                                                file.push(e.target.files[0]);
                                                                setFieldValue('logo', file[0].name);
                                                                handleLogo(e);
                                                            }}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                                    <div className="flex border-2 flex-col ml-2 justify-items-center">
                                                        <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                                            <div className="flex sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                                <div>Assign Tags</div>
                                                                <div>
                                                                    <HiIcons.HiQuestionMarkCircle
                                                                        onClick={() => {
                                                                            setTagsHelpText(true);
                                                                        }}
                                                                        className="ml-2 mt-1 cursor-pointer"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {tagsHelpText ? (
                                                                <div
                                                                    onMouseOverCapture={() => {
                                                                        setTagsHelpText(true);
                                                                        // setProfileDropdownVisible(false);
                                                                    }}
                                                                    onMouseLeave={() =>
                                                                        setTagsHelpText(false)
                                                                    }
                                                                    className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-6 right-12"
                                                                >
                                                                    Assign already created tags to
                                                                    the new record created.
                                                                </div>
                                                            ) : null}
                                                            <div
                                                                className="sm:text-sm md:text-base text-gray-400 underline pr-2 cursor-pointer"
                                                                onClick={() => {
                                                                    setTags([]);
                                                                }}
                                                            >
                                                                Clear All
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                                            <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                                <TagGroups
                                                                    selectedTags={selectedTags}
                                                                    tags={tags}
                                                                    setTags={setTags}
                                                                />

                                                                <div
                                                                    onMouseLeave={() => {
                                                                        setShowDropdown(false);
                                                                    }}
                                                                >
                                                                    <input
                                                                        className={`rounded w-full text-gray-500 font-medium focus:outline-none mt-2 p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                                                        id="id"
                                                                        type="text"
                                                                        placeholder="Search tags"
                                                                        onChange={e =>
                                                                            serchTagNameOrId(e)
                                                                        }
                                                                        onMouseEnter={() => {
                                                                            setShowDropdown(true);
                                                                        }}
                                                                    />
                                                                    {showDropdown &&
                                                                    tagData?.length ? (
                                                                        <ul
                                                                            className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                            role="menu"
                                                                            onMouseLeave={() => {
                                                                                setShowDropdown(
                                                                                    false
                                                                                );
                                                                            }}
                                                                        >
                                                                            {tagData?.map(tag => (
                                                                                <div
                                                                                    className="p-2 hover:bg-gray-200 cursor-pointer"
                                                                                    key={tag.id}
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            tags
                                                                                                .map(
                                                                                                    checktag =>
                                                                                                        checktag.tagName
                                                                                                )
                                                                                                .includes(
                                                                                                    tag.tagName
                                                                                                ) ===
                                                                                            false
                                                                                        ) {
                                                                                            setTags(
                                                                                                [
                                                                                                    ...tags,
                                                                                                    tag,
                                                                                                ]
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {tag.tagCategory +
                                                                                        ': ' +
                                                                                        tag.tagName}
                                                                                </div>
                                                                            ))}
                                                                        </ul>
                                                                    ) : showDropdown &&
                                                                      tagData.length === 0 ? (
                                                                        <ul
                                                                            className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                            role="menu"
                                                                            onMouseLeave={() =>
                                                                                setShowDropdown(
                                                                                    false
                                                                                )
                                                                            }
                                                                        >
                                                                            <div className="p-2 flex justify-center font-medium text-gray-400">
                                                                                No records found
                                                                            </div>
                                                                        </ul>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" ml-2 justify-items-center">
                                                        <div className="flex flex-col text-sm pl-2 lg:pl-4 mt-6 space-y-4 text-gray-400 ">
                                                            <div>
                                                                Created tags can be retrieved by
                                                                typing the text in the search bar.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {company?.comStatus !== 'ACTIVE' && (
                                                <div className="my-6 md:mb-0 flex justify-end">
                                                    <button
                                                        type="submit"
                                                        className="sm:py-1 md:py-3 ml-2 lg:ml-8 hover:bg-red-500 text-red-500 hover:text-white border-2 font-medium border-red-600 border-opacity-40 text-sm rounded-sm w-1/2 md:w-1/6 my-2 focus:outline-none"
                                                        onClick={() =>
                                                            setDeleteConfirmationVisible(true)
                                                        }
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        {deleteConfirmationVisible ? (
                                            <DeleteConfirmationModal
                                                modalVisible={deleteConfirmationVisible}
                                                setModalVisible={setDeleteConfirmationVisible}
                                                companyId={company?.id}
                                            />
                                        ) : null}
                                        {(companyUpdateSuccessMessage ||
                                            editCompanyError ||
                                            companyDeleteSuccessMessage ||
                                            deleteCompanyError) && (
                                            <CompanySuccessModal
                                                modalVisible={instituteSuccessVisible}
                                                handleClose={handleClose}
                                            />
                                        )}
                                        <CropModal
                                            ratio={3.6}
                                            photoURL={imageUrl}
                                            setPhotoURL={setImageUrl}
                                            setOpenCrop={setOpenCrop}
                                            openCrop={openCrop}
                                            handleCloseCropModal={handleCloseCropModal}
                                            uploadImageToS3={uploadImageToS3}
                                        />
                                        <CropModal
                                            ratio={2.2}
                                            photoURL={logoUrl}
                                            setPhotoURL={setLogoUrl}
                                            setOpenCrop={setLogoOpenCrop}
                                            openCrop={openLogoCrop}
                                            handleCloseCropModal={handleCloseLogoCropModal}
                                            uploadImageToS3={uploadLogoToS3}
                                        />
                                    </>
                                )}
                            </Formik>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
