import React, { useState } from 'react';
import * as HiIcons from 'react-icons/hi';

interface Props {
    tip: string;
}

export default function ToolTip({ tip }: Props) {
    const [showToolTip, setShowToolTip] = useState<boolean>(false);

    return (
        <div>
            <HiIcons.HiQuestionMarkCircle
                onClick={() => {
                    setShowToolTip(!showToolTip);
                }}
                className="ml-2 mt-1 cursor-pointer"
            />
            {showToolTip && (
                <div
                    onMouseOverCapture={() => {
                        setShowToolTip(true);
                    }}
                    onMouseLeave={() => setShowToolTip(false)}
                    className="bg-white inputFieldThickness absolute z-10 px-4 py-2 text-xs font-bold"
                >
                    <div>{tip}</div>
                </div>
            )}
        </div>
    );
}
