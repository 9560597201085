import React from 'react';

function DownArrow() {
    return (
        <svg
            width="15"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.515137 8.53501L9.00014 0.0500116L17.4851 8.53501L16.0701 9.95001L9.00014 2.87801L1.92914 9.95001L0.515137 8.53501Z"
                fill="#4C2EC2"
            />
        </svg>
    );
}

export default DownArrow;
