import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getWebStudentById, setNotificationType } from '../../../../redux/rootActions';
import { RootState } from '../../../../redux/rootReducer';
import useWidth from '../../../hooks/useWidth';

const NotificationTab = () => {
    const dispatch = useDispatch();
    const studentData = useSelector((state: RootState) => state.webUser.student);
    const userProfile = useSelector((state: RootState) => state.authStudent.userProfile);

    const [dailyReminders, setDailyReminders] = useState<boolean>(false);
    const [progressNotification, setProgressNotification] = useState<boolean>(false);
    const [encourageNotification, setEncourageNotification] = useState<boolean>(false);

    const viewPortWidth = useWidth();
    const [initially, setInititally] = useState(true);

    useEffect(() => {
        setDailyReminders(studentData?.userSettings?.notifications?.dailyReminders);
        setProgressNotification(studentData?.userSettings?.notifications?.progressNotification);
        setEncourageNotification(studentData?.userSettings?.notifications?.encourageNotification);
    }, [studentData]);

    useEffect(() => {
        dispatch(getWebStudentById(userProfile.userId));
    }, []);
    useEffect(() => {
        if (!initially)
            dispatch(
                setNotificationType({
                    dailyReminders: dailyReminders,
                    progressNotification: progressNotification,
                    encourageNotification: encourageNotification,
                })
            );
    }, [dispatch, initially, dailyReminders, encourageNotification, progressNotification]);

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div className="mt-8 mb-4 mr-20 flex flex-col">
                    <ul>
                        <li className="rounded-sm bg-purple-100 mb-3 py-4 pl-6  text-xs">
                            <input
                                className="styled-checkbox"
                                id={`styled-checkbox-1`}
                                type="checkbox"
                                value="daily-remainder"
                                checked={dailyReminders}
                                onChange={() => {
                                    if (dailyReminders) {
                                        setDailyReminders(false);
                                    } else {
                                        setDailyReminders(true);
                                    }
                                    if (initially) setInititally(prev => !prev);
                                }}
                            />
                            <label
                                // className="mt-10"
                                htmlFor={`styled-checkbox-1`}
                            >
                                Receive weekly reminders to work on challenges.
                            </label>
                        </li>
                        <li className="rounded-sm bg-purple-100 mb-3 py-4 pl-6 text-xs">
                            <input
                                className="styled-checkbox"
                                id={`styled-checkbox-2`}
                                type="checkbox"
                                value="progress-notification"
                                checked={progressNotification}
                                onChange={() => {
                                    if (progressNotification) {
                                        setProgressNotification(false);
                                    } else {
                                        setProgressNotification(true);
                                    }
                                    if (initially) setInititally(prev => !prev);
                                }}
                            />
                            <label
                                // className="mt-10"
                                htmlFor={`styled-checkbox-2`}
                            >
                                Receive notifications on my progress through the Future Career
                                BRIDGE program.
                            </label>
                        </li>
                        <li className="rounded-sm bg-purple-100 mb-3 py-4 pl-6 text-xs">
                            <input
                                className="styled-checkbox"
                                id={`styled-checkbox-3`}
                                type="checkbox"
                                value="encourage-notification"
                                checked={encourageNotification}
                                onChange={() => {
                                    if (encourageNotification) {
                                        setEncourageNotification(false);
                                    } else {
                                        setEncourageNotification(true);
                                    }
                                    if (initially) setInititally(prev => !prev);
                                }}
                            />
                            <label
                                // className="mt-10"
                                htmlFor={`styled-checkbox-3`}
                            >
                                Receive encouraging notifications to earn achievements.
                            </label>
                        </li>
                    </ul>
                </div>
            ) : (
                <div className="mt-5 flex flex-col">
                    <ul>
                        <li
                            className="rounded-sm bg-purple-100 mb-1 py-4 px-2"
                            style={{ fontSize: 8 }}
                        >
                            <input
                                className="styled-checkbox"
                                id={`styled-checkbox-1`}
                                type="checkbox"
                                value="daily-remainder"
                                checked={dailyReminders}
                                onChange={() => {
                                    if (dailyReminders) {
                                        setDailyReminders(false);
                                    } else {
                                        setDailyReminders(true);
                                    }
                                    if (initially) setInititally(prev => !prev);
                                }}
                            />
                            <label
                                // className="mt-10"
                                htmlFor={`styled-checkbox-1`}
                            >
                                Receive daily reminders to work on challenges.
                            </label>
                        </li>
                        <li
                            className="rounded-sm bg-purple-100 mb-1 py-4 px-2"
                            style={{ fontSize: 8 }}
                        >
                            <input
                                className="styled-checkbox"
                                id={`styled-checkbox-2`}
                                type="checkbox"
                                value="progress-notification"
                                checked={progressNotification}
                                onChange={() => {
                                    if (progressNotification) {
                                        setProgressNotification(false);
                                    } else {
                                        setProgressNotification(true);
                                    }
                                    if (initially) setInititally(prev => !prev);
                                }}
                            />
                            <label
                                // className="mt-10"
                                htmlFor={`styled-checkbox-2`}
                            >
                                Receive notifications on my progress through the Future Career
                                BRIDGE program.
                            </label>
                        </li>
                        <li
                            className="rounded-sm bg-purple-100 mb-1 py-4 px-2"
                            style={{ fontSize: 8 }}
                        >
                            <input
                                className="styled-checkbox"
                                id={`styled-checkbox-3`}
                                type="checkbox"
                                value="encourage-notification"
                                checked={encourageNotification}
                                onChange={() => {
                                    if (encourageNotification) {
                                        setEncourageNotification(false);
                                    } else {
                                        setEncourageNotification(true);
                                    }
                                    if (initially) setInititally(prev => !prev);
                                }}
                            />
                            <label
                                // className="mt-10"
                                htmlFor={`styled-checkbox-3`}
                            >
                                Receive encouraging notifications to earn achievements.
                            </label>
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
};

export default NotificationTab;
