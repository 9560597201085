import axios from 'axios';
import { API_URL } from '../../../constants';
import { axiosConfig } from '../../../utils/axiosApi';
import { getErrorMessage } from '../../../utils/axiosErrorHandler';
import { getWebStudentById } from '../../rootActions';

const PREFIX = '@SUCCESS_STORIES';

export const ADD_SUCCESS_STORY = PREFIX + 'ADD_SUCCESS_STORY';
export const ADD_SUCCESS_STORY_SUCCESS = PREFIX + 'ADD_SUCCESS_STORY_SUCCESS';
export const ADD_SUCCESS_STORY_FAILED = PREFIX + 'ADD_SUCCESS_STORY_FAILED';

export const addSuccessStories = information => async (dispatch: any) => {
    try {
        dispatch({ type: ADD_SUCCESS_STORY });
        const config = await axiosConfig(false);
        const res = await axios.post(`${API_URL}/stories`, information, config);
        dispatch({ type: ADD_SUCCESS_STORY_SUCCESS, payload: res.data.responseDto });
        dispatch(getWebStudentById(information?.userProfileId));
    } catch (error) {
        dispatch({ type: ADD_SUCCESS_STORY_FAILED, payload: getErrorMessage(error) });
    }
};
