import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'react-modal';

import {
    getStudentSpecificCertificateData,
    certificateUrlFromCode,
    getStudentEarnedCertificateData,
} from '../../../../redux/rootActions';

import { RootState } from '../../../../redux/rootReducer';
import { CertificateType, EarnCertificatesType } from '../../../../redux/certificates/reducers';

import BackArrow from '../../../../assets/svg/BackArrow';
import CloseIcon from '../../../../assets/svg/CloseIcon';
import EarnCertificateModal from '../../../modals/EarnCertificateModal';

const CertificateEarned: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userProfile = useSelector((state: RootState) => state.authStudent.userProfile);

    const studentsCertificateData = useSelector(
        (state: RootState) => state.certificates.studentCertificatesData
    );
    const earnedCertificates = useSelector(
        (state: RootState) => state.certificates.studentEarnedCertificatesData
    );

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showCertificateModal, setShowCertificateModal] = useState<boolean>(false);
    const [studentsCertificates, setStudentsCertificates] = useState<CertificateType[]>([]);
    const [selectedCertificateData, setSelectedCertificateData] = useState<CertificateType>();
    const [earnedCertificateData, setEarnedCertificateData] = useState<EarnCertificatesType>();
    const [earnedCertificatesIds, setEarnedCertificatesIds] = useState([]);

    useEffect(() => {
        dispatch(getStudentSpecificCertificateData(userProfile.userId));
        dispatch(getStudentEarnedCertificateData(userProfile.userId));
    }, [dispatch, userProfile.userId]);

    useEffect(() => {
        const allCertificates = studentsCertificateData?.earned.concat(
            studentsCertificateData?.unearned?.inProgress,
            studentsCertificateData?.unearned?.notStarted
        );
        setStudentsCertificates(allCertificates);
    }, [studentsCertificateData]);

    useEffect(() => {
        if (earnedCertificates?.length > 0) {
            const newArr = earnedCertificates.map(item => {
                return item.certificate.certId;
            });
            setEarnedCertificatesIds(newArr);
        }
    }, [earnedCertificates]);

    const handleSelectIncompleteCertificate = certificateData => {
        setSelectedCertificateData(certificateData);
        setShowModal(true);
    };
    const handleSelectCompleteCertificate = certificateData => {
        setSelectedCertificateData(certificateData);
        setShowCertificateModal(true);
    };
    useEffect(() => {
        if (earnedCertificates) {
            const cert = earnedCertificates?.find(
                certificate => certificate?.certificate?.id === selectedCertificateData?.id
            );
            if (cert && cert?.certificateCode) {
                dispatch(certificateUrlFromCode(cert?.certificateCode));
            }

            setEarnedCertificateData(cert);
        }
    }, [dispatch, earnedCertificates, selectedCertificateData]);

    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(239, 235, 255, 0.7)',
            backdropFilter: 'blur(7px)',
            overflow: 'auto',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    return (
        <div className="pl-4 lg:pl-0 lg:ml-72 md:mr-10 mt-6 md:mt-14">
            <div
                className="flex mb-3 mt-1 cursor-pointer"
                onClick={() => history.push(`/dashBoard/myProgress`)}
            >
                <BackArrow size="27" />
                <p className="text-base ml-2 font-semibold">Back</p>
            </div>
            <div className="font-bold text-xl mb-3">Certificate</div>

            <div className="trackCardContainer">
                {studentsCertificates &&
                    studentsCertificates.map((certificate: CertificateType) => (
                        <div
                            key={certificate.id}
                            className={`rounded-lg trackCard mt-3 ${
                                certificate?.challengeProgress?.progressStatus === 0
                                    ? 'opacity-100'
                                    : 'opacity-20 cursor-pointer'
                            }`}
                            onClick={() => {
                                certificate?.challengeProgress?.progressStatus !== 0 &&
                                    handleSelectIncompleteCertificate(certificate);
                            }}
                        >
                            <img
                                className="w-full rounded-t-lg"
                                src="/assets/certificate.png"
                                alt="profile"
                            />
                            <div className="mx-2 mt-6 pb-3">
                                <div className="font-bold font-manrope text-xl mb-3 h-6 overflow-hidden">
                                    {certificate.certificateName?.length < 19
                                        ? certificate.certificateName
                                        : certificate.certificateName?.substring(0, 19) + '...'}
                                </div>
                                <div className="my-3">
                                    <div
                                        style={{ height: '104px' }}
                                        className="text-gray-500 text-xs mb-1 pr-2 text-justify"
                                    >
                                        <div className="font-semibold">
                                            You have now gathered your expertise on{' '}
                                            {certificate.track.trackName}.
                                        </div>
                                        <div>
                                            {certificate.description
                                                ? certificate.description?.length > 200
                                                    ? certificate.description?.substring(0, 200) +
                                                      '.....'
                                                    : certificate.description
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                {certificate?.challengeProgress?.progressStatus === 0 && (
                                    <button
                                        onClick={() => handleSelectCompleteCertificate(certificate)}
                                        className="border-2 border-purple-950 text-purple-950 rounded font-semibold h-10 px-6 xl:px-12 ml-1"
                                    >
                                        View Certificate
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
            </div>
            <EarnCertificateModal
                showModal={showCertificateModal}
                setShowModal={setShowCertificateModal}
                certificateData={selectedCertificateData}
                earnedCertificateData={earnedCertificateData}
            />
            <Modal isOpen={showModal} style={customStyles} ariaHideApp={false}>
                <div className="flex-shrink justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
                    <div style={{ width: '650px' }} className="relative">
                        <div
                            className="absolute z-50 top-6 right-6 cursor-pointer"
                            onClick={() => setShowModal(false)}
                        >
                            <CloseIcon size={24} color="rgba(17, 17, 17, 0.48)" />
                        </div>
                        <div className="border-2 rounded-lg shadow-lg bg-white outline-none focus:outline-none flex flex-col items-center px-16 pt-10 pb-7">
                            <img
                                src={'/assets/certificateVector.png'}
                                alt={`${selectedCertificateData?.track.trackName} certificate`}
                                className="mb-8 object-contain"
                                style={{ width: '140px', height: '90px' }}
                            />
                            <h1 className="text-xl font-bold mb-3 text-center">
                                {selectedCertificateData?.challengeProgress.progressStatus === 1 ||
                                selectedCertificateData?.challengeProgress.progressStatus === 2
                                    ? `You are close to earning a ${selectedCertificateData?.track.trackName} Certificate!`
                                    : selectedCertificateData?.track.trackName}
                            </h1>
                            <p className="text-base font-normal text-purple-950 text-center">
                                {selectedCertificateData?.challengeProgress.progressStatus === 1 ||
                                selectedCertificateData?.challengeProgress.progressStatus === 2
                                    ? `You are ${
                                          selectedCertificateData?.challengeProgress.totalCount -
                                          selectedCertificateData?.challengeProgress.completedCount
                                      } challenges away from earning the ${
                                          selectedCertificateData?.track.trackName
                                      } Certificate. Start on the challenges now to earn the certificate!`
                                    : selectedCertificateData?.track.trackDescription}
                            </p>
                            <button
                                onClick={() => history.push(`/dashBoard/learn`)}
                                className="bg-purple-950 text-base text-white font-bold rounded px-3 h-12 mt-1"
                            >
                                Continue Learning
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CertificateEarned;
