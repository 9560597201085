import React, { ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { editStudent, StudentEditType, clearStudentMessages } from '../../redux/students/actions';

interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    student: any;
    progressID: string | null;
    setCareerProgressDeleted: (value: boolean) => void;
}

export default function CareerProgressDeleteConfirmationModal({
    modalVisible,
    setModalVisible,
    student,
    progressID,
    setCareerProgressDeleted,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const handleSubmit = useCallback(() => {
        setCareerProgressDeleted(true);
        const careerStatusArray: any = student.careerStatus.filter(data => data.id !== progressID);
        const studentEditType: StudentEditType = {
            careerStatus: careerStatusArray,
        };

        dispatch(editStudent(student?.id, studentEditType));
    }, [progressID]);

    return (
        <Modal
            open={modalVisible}
            onClose={() => {
                setModalVisible(false);
            }}
            center
            showCloseIcon={false}
            styles={{
                modal: {
                    borderRadius: 5,
                    maxWidth: '628px',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    boxShadow: '0 12px 15px 0 rgb(0 0 0 / 10%)',
                },
                overlay: { background: 'none' },
            }}
        >
            <div className="">
                <div className="text-base text-center text-gray-800 px-4 pt-6 pb-4">
                    Are you sure you want to delete this note?
                </div>
                <div className="mt-8 flex justify-end">
                    <button
                        className="border rounded-md border-blue-600 text-blue-600 text-sm h-10 px-8 mr-2 focus:outline-none"
                        onClick={() => {
                            dispatch(clearStudentMessages());
                            setModalVisible(false);
                        }}
                    >
                        No
                    </button>
                    <button
                        className="border bg-blue-600 rounded-md text-white text-sm h-10 leading-4 px-8 ml-2 focus:outline-none hover:text-white hover:bg-blue-800"
                        onClick={() => {
                            dispatch(clearStudentMessages());
                            handleSubmit();
                        }}
                    >
                        Yes
                    </button>
                </div>
            </div>
        </Modal>
    );
}
