import React, { ReactElement } from 'react';
import Modal from 'react-modal';
import FilterDropdown from '../../FilterDropdown';
import FilterDropdownByNames from '../../FilterDropdownByNames';
import FilterDropdownOneChoice from '../../FilterDropdownOneChoice';

interface Props {
    isSelected: boolean;
    onRequestClose: () => void;
    setNotificationTypesSelected: any;
    notificationTypesSelected: any;
    setTagsSelected: any;
    tagsSelected: any;
    // setInstitutesSelected: any;
    // institutesSelected: any;
    // setCompaniesSelected: any;
    // companiesSelected: any;
    filterNotificationTypes: any;
    filterTags: any;
    // filterInstitutes: any;
    // filterCompanies: any;
}

export default function AddFilter({
    isSelected,
    onRequestClose,
    setNotificationTypesSelected,
    notificationTypesSelected,
    setTagsSelected,
    tagsSelected,
    // setInstitutesSelected,
    // institutesSelected,
    // setCompaniesSelected,
    // companiesSelected,
    filterNotificationTypes,
    filterTags,
}: // filterInstitutes,
// filterCompanies,
Props): ReactElement {
    const customStyles: Modal.Styles = {
        overlay: {
            position: 'absolute',
            left: '200px',
            top: '220px',
            width: '100%',
            backgroundColor: 'transparent',
            zIndex: 49,
        },
        content: {
            left: '10%',
            padding: '0px',
            border: '0px',
            width: '30%',
            backgroundColor: 'transparent',
            zIndex: 48,
            overflow: 'visible',
            maxHeight: '20px',
            borderRadius: '20px',
        },
    };

    Modal.setAppElement('#root');

    return (
        <Modal isOpen={isSelected} style={customStyles} onRequestClose={onRequestClose}>
            <>
                <div className="relative w-full cursor-pointer z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="border-0 rounded-lg bg-white outline-none focus:outline-none max-h-40 overflow-auto">
                        <FilterDropdownOneChoice
                            data={filterNotificationTypes}
                            title={'Notification Types'}
                            setSelectItems={setNotificationTypesSelected}
                            selectItems={notificationTypesSelected}
                        />
                        <FilterDropdown
                            data={filterTags}
                            title={'Tags'}
                            setSelectItems={setTagsSelected}
                            selectItems={tagsSelected}
                        />
                        {/* <FilterDropdown
                            data={filterInstitutes}
                            title={'Institutes'}
                            setSelectItems={setInstitutesSelected}
                            selectItems={institutesSelected}
                        />
                        <FilterDropdown
                            data={filterCompanies}
                            title={'Companies'}
                            setSelectItems={setCompaniesSelected}
                            selectItems={companiesSelected}
                        /> */}
                    </div>
                </div>
            </>
        </Modal>
    );
}
