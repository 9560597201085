import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';

const PREFIX = '@BADGES';

export const GET_BADGES = PREFIX + 'GET_BADGES';
export const GET_BADGES_SUCCESS = PREFIX + 'GET_BADGES_SUCCESS';
export const GET_BADGES_FAILED = PREFIX + 'GET_BADGES_FAILED';
export const GET_STUDENT_BADGES = PREFIX + 'GET_STUDENT_BADGES';
export const GET_STUDENT_BADGES_SUCCESS = PREFIX + 'GET_STUDENT_BADGES_SUCCESS';
export const GET_STUDENT_BADGES_FAILED = PREFIX + 'GET_STUDENT_BADGES_FAILED';
export const GET_BADGE_BY_ID = PREFIX + 'GET_BADGE_BY_ID';
export const GET_BADGE_BY_ID_SUCCESS = PREFIX + 'GET_BADGE_BY_ID_SUCCESS';
export const GET_BADGE_BY_ID_FAILED = PREFIX + 'GET_BADGE_BY_ID_FAILED';
export const ADD_BADGE = PREFIX + 'ADD_BADGE';
export const ADD_BADGE_SUCCESS = PREFIX + 'ADD_BADGE_SUCCESS';
export const ADD_BADGE_FAILED = PREFIX + 'ADD_BADGE_FAILED';
export const EDIT_BADGE = PREFIX + 'EDIT_BADGE';
export const EDIT_BADGE_SUCCESS = PREFIX + 'EDIT_BADGE_SUCCESS';
export const EDIT_BADGE_FAILED = PREFIX + 'EDIT_BADGE_FAILED';
export const DELETE_BADGE = PREFIX + 'DELETE_BADGE';
export const DELETE_BADGE_SUCCESS = PREFIX + 'DELETE_BADGE_SUCCESS';
export const DELETE_BADGE_FAILED = PREFIX + 'DELETE_BADGE_FAILED';
export const BADGES_BULK_UPDATE = PREFIX + 'BADGES_BULK_UPDATE';
export const BADGES_BULK_UPDATE_SUCCESS = PREFIX + 'BADGES_BULK_UPDATE_SUCCESS';
export const BADGES_BULK_UPDATE_FAILED = PREFIX + 'BADGES_BULK_UPDATE_FAILED';
export const CLEAR_BADGES_MESSAGES = PREFIX + 'CLEAR_BADGES_MESSAGES';

export const getBadges =
    (
        page?: number,
        size?: number,
        status?: string,
        tags?: string,
        searchTerm?: string,
        challenges?: string
    ) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_BADGES });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/badges/?page=${page || 0}&size=${size || 10}${
                    status ? `&status=${status}` : ''
                }${searchTerm ? `&searchText=${searchTerm}` : ''}${tags ? `&tags=${tags}` : ''}${
                    challenges ? `&challenges=${challenges}` : ''
                }`,
                config
            );
            dispatch({
                type: GET_BADGES_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_BADGES_FAILED, payload: getErrorMessage(error) });
        }
    };

export const getStudentSpecificBadgesData = (studentId: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_STUDENT_BADGES });
        const config = await axiosConfig(false);
        const response = await axios.get(
            `${API_URL}/badges/student?studentId=${studentId}`,
            config
        );
        dispatch({ type: GET_STUDENT_BADGES_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_STUDENT_BADGES_FAILED, payload: getErrorMessage(error) });
    }
};

export const getBadgeById = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_BADGE_BY_ID });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/badges/${id}`, config);
        dispatch({ type: GET_BADGE_BY_ID_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_BADGE_BY_ID_FAILED, payload: getErrorMessage(error) });
    }
};

export const addBadge = (badge: any) => async (dispatch: any) => {
    try {
        dispatch({ type: ADD_BADGE });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/badges/`, badge, config);
        if (res?.data?.status) {
            if (res?.data?.errorCode === 222) {
                await dispatch(getBadges());
                await dispatch({
                    type: ADD_BADGE_SUCCESS,
                    payload: res.data.responseDto,
                });
            }
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 222) {
                dispatch({
                    type: ADD_BADGE_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: ADD_BADGE_FAILED, payload: getErrorMessage(error) });
    }
};
export const editBadge = (id: string, badge: any, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: EDIT_BADGE });
        const config = await axiosConfig(true);
        const res = await axios.put(`${API_URL}/badges/${id}`, badge, config);
        if (res?.data?.status) {
            await dispatch(getBadges(0, 10, status));
            dispatch({ type: EDIT_BADGE_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 214) {
                dispatch({
                    type: EDIT_BADGE_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: EDIT_BADGE_FAILED, payload: getErrorMessage(error) });
    }
};
export const deleteBadge = (id: string, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: DELETE_BADGE });
        const config = await axiosConfig(true);
        const res = await axios.delete(`${API_URL}/badges/${id}`, config);
        await dispatch(getBadges(0, 10, status));
        dispatch({ type: DELETE_BADGE_SUCCESS, payload: res.data.responseDto });
    } catch (error) {
        dispatch({ type: DELETE_BADGE_FAILED, payload: getErrorMessage(error) });
    }
};
export const bulkUpdateBadges = (badges, tabStatus) => async (dispatch: any) => {
    try {
        dispatch({ type: BADGES_BULK_UPDATE });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/badges/bulk`, badges, config);
        await dispatch(getBadges(0, 10, tabStatus));
        if (res?.data?.status) {
            if (res?.data?.errorCode === 202)
                dispatch({ type: BADGES_BULK_UPDATE_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 202) {
                dispatch({
                    type: BADGES_BULK_UPDATE_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: BADGES_BULK_UPDATE_FAILED, payload: getErrorMessage(error) });
    }
};
export const clearBadgesMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_BADGES_MESSAGES });
};
