import {
    ADD_EVENT_TAB,
    ADD_EVENT_TAB_FAILED,
    ADD_EVENT_TAB_SUCCESS,
    GET_EVENT_TAB,
    GET_EVENT_TAB_FAILED,
    GET_EVENT_TAB_SUCCESS,
} from './actions';

export interface EventEditType {
    eventId: string;
    eventLink: string;
    eventName: string;
    eventStatus: string;
    eventImage: string;
    eventDescription: string;
    eventStartTime: string;
    eventEndTime: string;
    location: string;
    tags: [];
    users: [];
}
export interface EventType {
    id: string;
    eventId: string;
    eventLink: string;
    eventName: string;
    eventStatus: string;
    eventType: string;
    eventImage: string;
    eventDescription: string;
    eventStartTime: string;
    eventEndTime: string;
    location: string;
    registerLink: string;
    tags: [];
    users: [];
}
export interface CountType {
    ALL: number;
    ACTIVE: number;
    INACTIVE: number;
    DELETED: number;
}
export interface EventsTabInitialState {
    eventsData: EventType[];
    event: EventType;
    eventTabData: [];
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    eventByIdLoading: boolean;
    eventByIdError: string | null;
    eventError: string | null;
    createEventError: string | null;
    eventCreateSuccessMessage: string | null;
    isDeleteLoading: boolean;
    deleteEventError: string | null;
    eventDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editEventError: string | null;
    eventUpdateSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    eventBulkSuccessMessage: string | null;
}

const initialState: EventsTabInitialState = {
    eventsData: [],
    event: null,
    eventTabData: [],
    pageCount: null,
    count: null,
    isLoading: false,
    eventByIdLoading: false,
    eventByIdError: null,
    eventError: null,
    createEventError: null,
    eventCreateSuccessMessage: null,
    isDeleteLoading: false,
    deleteEventError: null,
    eventDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editEventError: null,
    eventUpdateSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    eventBulkSuccessMessage: null,
};

const eventsTabReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_EVENT_TAB:
            return { ...state, isLoading: true, eventsError: null };
        case ADD_EVENT_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                eventsData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case ADD_EVENT_TAB_FAILED:
            return { ...state, isLoading: false, eventsError: action.payload };
        case GET_EVENT_TAB:
            return { ...state, isLoading: true, trackError: null };
        case GET_EVENT_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.payload.counts,
                eventTabData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case GET_EVENT_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        default:
            return state;
    }
};

export default eventsTabReducer;
