import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';

const PREFIX = '@SUCCESS_STORIES';

export const GET_SUCCESS_STORIES = PREFIX + 'GET_SUCCESS_STORIES';
export const GET_SUCCESS_STORIES_SUCCESS = PREFIX + 'GET_SUCCESS_STORIES_SUCCESS';
export const GET_SUCCESS_STORIES_FAILED = PREFIX + 'GET_SUCCESS_STORIES_FAILED';

export const GET_PUBLIC_API_SUCCESS_STORIES = PREFIX + 'GET_PUBLIC_API_SUCCESS_STORIES';
export const GET_PUBLIC_API_SUCCESS_STORIES_SUCCESS =
    PREFIX + 'GET_PUBLIC_API_SUCCESS_STORIES_SUCCESS';
export const GET_PUBLIC_API_SUCCESS_STORIES_FAILED =
    PREFIX + 'GET_PUBLIC_API_SUCCESS_STORIES_FAILED';

export const EDIT_SUCCESS_STORIES = PREFIX + 'EDIT_SUCCESS_STORIES';
export const EDIT_SUCCESS_STORIES_SUCCESS = PREFIX + 'EDIT_SUCCESS_STORIES_SUCCESS';
export const EDIT_SUCCESS_STORIES_FAILED = PREFIX + 'EDIT_SUCCESS_STORIES_FAILED';

export const CLEAR_SUCCESS_STORIES_MESSAGES = PREFIX + 'CLEAR_SUCCESS_STORIES_MESSAGES';

export const getSuccessStories =
    (page?: number, size?: number, status?: string) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_SUCCESS_STORIES });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/stories/${status}?page=${page || 0}&size=${size || 10}`,
                config
            );
            dispatch({
                type: GET_SUCCESS_STORIES_SUCCESS,
                payload: response?.data?.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_SUCCESS_STORIES_FAILED, payload: getErrorMessage(error) });
        }
    };

export const getSuccessStoriesPublicAPI = (status?: string) => async (dispatch: any) => {
    // Public API
    try {
        dispatch({ type: GET_PUBLIC_API_SUCCESS_STORIES });
        const response = await axios.get(`${API_URL}/open/stories/${status}`);
        dispatch({
            type: GET_PUBLIC_API_SUCCESS_STORIES_SUCCESS,
            payload: response?.data?.responseDto,
        });
    } catch (error) {
        dispatch({
            type: GET_PUBLIC_API_SUCCESS_STORIES_FAILED,
            payload: getErrorMessage(error),
        });
    }
};

export const editSuccessStory =
    (id: string, data: any, tabStatus?: string, page?: number, size?: number) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: EDIT_SUCCESS_STORIES });
            const config = await axiosConfig(true);
            const res = await axios.put(`${API_URL}/stories/${id}`, data, config);
            await dispatch(getSuccessStories(page || 0, size || 10, tabStatus));
            if (res?.data?.status) {
                dispatch({ type: EDIT_SUCCESS_STORIES_SUCCESS, payload: res.data.responseDto });
            }
            if (!res?.data?.status) {
                if (res?.data?.errorCode !== 205) {
                    dispatch({
                        type: EDIT_SUCCESS_STORIES_FAILED,
                        payload: res.data.errorDescription,
                    });
                }
            }
        } catch (error) {
            dispatch({ type: EDIT_SUCCESS_STORIES_FAILED, payload: getErrorMessage(error) });
        }
    };

export const clearSuccessStoryMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_SUCCESS_STORIES_MESSAGES });
};
