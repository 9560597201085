import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { s3UrlPrefix } from '../../../constants';
import { getCompanyById } from '../../../redux/rootActions';
import { CompanyType } from '../../../redux/companies/reducers';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroupsView from '../../TagGroupsView';
import moment from 'moment';
interface CustomState {
    company: CompanyType;
}
interface paramsType {
    id: string;
}
export default function ViewCompany() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const params = useParams<paramsType>();
    const companyState = location.state as CustomState;
    const companyByIdLoading = useSelector(
        (state: RootState) => state.companies.companyByIdLoading
    );
    const company = useSelector((state: RootState) => state.companies.company);
    const [imageUrl] = useState(`${s3UrlPrefix}${companyState?.company?.bannerImage}`);
    const [logoUrl] = useState(`${s3UrlPrefix}${companyState?.company?.comImage}`);
    const [tags, setTags] = useState(categorizeTags(companyState?.company?.tags));
    useEffect(() => {
        dispatch(getCompanyById(params.id));
    }, [dispatch, params.id]);

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    {companyByIdLoading ? (
                        <Loader />
                    ) : (
                        <div className="relative">
                            <>
                                <div className="flex justify-between items-center mb-4 md:mb-6">
                                    <p className="text-xl font-semibold text-left">View Company</p>
                                    <div
                                        className={`flex h-14 md:h-16 ${
                                            companyState?.company?.comStatus !== 'DELETED'
                                                ? 'w-1/2 md:w-1/4 lg:pl-8'
                                                : 'w-1/4 md:w-1/6 lg:pl-16'
                                        }`}
                                    >
                                        <button
                                            type="submit"
                                            className={`md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm ${
                                                companyState?.company?.comStatus !== 'DELETED'
                                                    ? 'w-full md:w-1/2'
                                                    : 'w-full hover:bg-blue-700 hover:text-white'
                                            } my-2 focus:outline-none`}
                                            onClick={() => {
                                                history.push('/admin/companies');
                                            }}
                                        >
                                            OK
                                        </button>
                                        {companyState?.company?.comStatus !== 'DELETED' && (
                                            <button
                                                type="submit"
                                                className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-normal rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => {
                                                    history.push({
                                                        pathname: `/admin/companies/${companyState?.company?.id}/edit`,
                                                        state: { company: company },
                                                    });
                                                }}
                                            >
                                                Edit
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 pb-4 mb-6">
                                    <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="title"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Company ID
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={company?.comId}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="title"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Company Name
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={company?.comName}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="my-6 w-full md:mb-0 flex my-4">
                                            <label
                                                className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                            >
                                                Type of Account
                                            </label>
                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={company?.accountType}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="my-6 w-full md:mb-0 flex">
                                            <label
                                                className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                            >
                                                SLASSCOM Member
                                            </label>
                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={company?.slassMember ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        {company?.slassMember && (
                                            <>
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="repName"
                                                        className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Subscription Start Date
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            style={{
                                                                minWidth: '80%',
                                                            }}
                                                            className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                            value={moment(company?.subStartDate)
                                                                .local()
                                                                .format('YYYY-MM-DD')}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="repName"
                                                        className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Subscription End Date
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            style={{
                                                                minWidth: '80%',
                                                            }}
                                                            className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 border-gray-300 md:text-base`}
                                                            value={moment(company?.subEndDate)
                                                                .local()
                                                                .format('YYYY-MM-DD')}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="repName"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Representative Name
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm border-gray-300 flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900
                                                       md:text-base`}
                                                    value={company?.repName}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="instEmail"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Email
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={company?.comEmail}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="contactNo"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Contact Number
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded-sm border-gray-300 flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                    value={company?.contactNo}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="siteUrl"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Website URL
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <a
                                                    className={`rounded-sm flex items-center h-11 focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base`}
                                                    href={`https://${company?.siteUrl}`}
                                                    target={'_blank'}
                                                    rel="noopener noreferrer"
                                                >
                                                    {company?.siteUrl}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="my-6 w-full md:mb-0 flex h-24">
                                            <label
                                                htmlFor="description"
                                                className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                            >
                                                Company Description
                                            </label>
                                            <div className="flex flex-col w-full">
                                                <textarea
                                                    className={`resize-none border-gray-300 focus:outline-none p-2 w-4/5 border-2 text-sm h-24 focus:border-blue-900
                                                      `}
                                                    id="description"
                                                    value={company?.comDescription}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="my-6 w-full md:mb-0 flex h-24">
                                            <label
                                                htmlFor="opportunities"
                                                className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                            >
                                                Career Opportunities
                                            </label>
                                            <div className="flex flex-col w-full">
                                                <textarea
                                                    className={`resize-none border-gray-300 focus:outline-none p-2 w-4/5 border-2 text-sm h-24 focus:border-blue-900
                                                      `}
                                                    id="description"
                                                    value={company?.careerOpportunities}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="applyNowURL"
                                                className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Apply Now URL
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <a
                                                    className={`rounded-sm flex h-11 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base md:text-base`}
                                                    href={`https://${company?.applyURL}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {company?.applyURL}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="my-6 w-full md:mb-0 flex">
                                            <label
                                                htmlFor="description"
                                                className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                            >
                                                Company Image
                                            </label>
                                            <div
                                                className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                                    imageUrl ? 'w-fit' : ''
                                                }  border-opacity-100 border-black-600
                                               border-2`}
                                            >
                                                <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64">
                                                    {imageUrl &&
                                                        companyState?.company?.bannerImage && (
                                                            <img
                                                                src={imageUrl}
                                                                alt={'name'}
                                                                className="h-44 lg:h-64"
                                                                style={{ maxHeight: 300 }}
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my-6 w-full md:mb-0 flex">
                                            <label
                                                htmlFor="logo"
                                                className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                            >
                                                Company Logo
                                            </label>
                                            <div
                                                className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                                    logoUrl ? 'w-fit' : ''
                                                }  border-opacity-100 border-black-600
                                               border-2`}
                                            >
                                                <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64">
                                                    {logoUrl && companyState?.company?.comImage && (
                                                        <img
                                                            src={logoUrl}
                                                            alt={'name'}
                                                            className="h-44 lg:h-64"
                                                            style={{ maxHeight: 300 }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                        <div className="flex border-2 flex-col ml-2 justify-items-center">
                                            <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                                <div className="sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                    Tags
                                                </div>
                                            </div>
                                            <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                                <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                    <TagGroupsView selectedTags={tags} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
