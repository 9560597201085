import axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { USER_PROFILE } from '../../utils/storage';

const PREFIX = '@INSTITUTES_';

export const GET_INSTITUTES = PREFIX + 'GET_INSTITUTES';
export const GET_INSTITUTES_SUCCESS = PREFIX + 'GET_INSTITUTES_SUCCESS';
export const GET_INSTITUTES_FAILED = PREFIX + 'GET_INSTITUTES_FAILED';
export const GET_INSTITUTES_PUBLIC = PREFIX + 'GET_INSTITUTES_PUBLIC';
export const GET_INSTITUTES_PUBLIC_SUCCESS = PREFIX + 'GET_INSTITUTES_PUBLIC_SUCCESS';
export const GET_INSTITUTES_PUBLIC_FAILED = PREFIX + 'GET_INSTITUTES_PUBLIC_FAILED';
export const GET_INSTITUTEBYID = PREFIX + 'GET_INSTITUTEBYID';
export const GET_INSTITUTEBYID_SUCCESS = PREFIX + 'GET_INSTITUTEBYID_SUCCESS';
export const GET_INSTITUTEBYID_FAILED = PREFIX + 'GET_INSTITUTEBYID_FAILED';
export const GET_INSTITUTEBYID_PUBLIC = PREFIX + 'GET_INSTITUTEBYID_PUBLIC';
export const GET_INSTITUTEBYID_SUCCESS_PUBLIC = PREFIX + 'GET_INSTITUTEBYID_SUCCESS_PUBLIC';
export const GET_INSTITUTEBYID_FAILED_PUBLIC = PREFIX + 'GET_INSTITUTEBYID_FAILED_PUBLIC';
export const ADD_INSTITUTE = PREFIX + 'ADD_INSTITUTE';
export const ADD_INSTITUTE_SUCCESS = PREFIX + 'ADD_INSTITUTE_SUCCESS';
export const ADD_INSTITUTE_FAILED = PREFIX + 'ADD_INSTITUTE_FAILED';
export const EDIT_INSTITUTE = PREFIX + 'EDIT_INSTITUTE';
export const EDIT_INSTITUTE_SUCCESS = PREFIX + 'EDIT_INSTITUTE_SUCCESS';
export const EDIT_INSTITUTE_FAILED = PREFIX + 'EDIT_INSTITUTE_FAILED';
export const DELETE_INSTITUTE = PREFIX + 'DELETE_INSTITUTE';
export const DELETE_INSTITUTE_SUCCESS = PREFIX + 'DELETE_INSTITUTE_SUCCESS';
export const DELETE_INSTITUTE_FAILED = PREFIX + 'DELETE_INSTITUTE_FAILED';
export const INSTITUTE_BULK_UPDATE = PREFIX + 'INSTITUTE_BULK_UPDATE';
export const INSTITUTE_BULK_UPDATE_SUCCESS = PREFIX + 'INSTITUTE_BULK_UPDATE_SUCCESS';
export const INSTITUTE_BULK_UPDATE_FAILED = PREFIX + 'INSTITUTE_BULK_UPDATE_FAILED';
export const CLEAR_INSTITUTE_MESSAGES = PREFIX + 'CLEAR_INSTITUTE_MESSAGES';

export const getInstitutes =
    (
        page?: number,
        size?: number,
        status?: string,
        tags?: string,
        searchTerm?: string,
        type?: string,
        joinedDateFrom?: string,
        joinedDateTo?: string
    ) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_INSTITUTES });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/organizations/institutes?page=${page || 0}&size=${size || 10}${
                    status ? `&status=${status}` : ''
                }${searchTerm ? `&searchText=${searchTerm}` : ''}${tags ? `&tags=${tags}` : ''}${
                    type ? `&category=${type}` : ''
                }${
                    joinedDateFrom
                        ? `&joinedDateFrom=${moment(joinedDateFrom).format('DD-MM-yyyy')}`
                        : ''
                }${
                    joinedDateTo ? `&joinedDateTo=${moment(joinedDateTo).format('DD-MM-yyyy')}` : ''
                }`,
                config
            );
            dispatch({
                type: GET_INSTITUTES_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_INSTITUTES_FAILED, payload: getErrorMessage(error) });
        }
    };

export const getInstitutesPublicData =
    (page?: number, size?: number, status?: string) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_INSTITUTES_PUBLIC });
            const response = await axios.get(
                `${API_URL}/open/institutes?page=${page || 0}&size=${size || 10}${
                    status ? `&status=${status}` : ''
                }`
            );
            dispatch({
                type: GET_INSTITUTES_PUBLIC_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_INSTITUTES_PUBLIC_FAILED, payload: getErrorMessage(error) });
        }
    };
export const getPublicInstituteById = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_INSTITUTEBYID_PUBLIC });
        const response = await axios.get(`${API_URL}/open/institute/${id}`);
        dispatch({ type: GET_INSTITUTEBYID_SUCCESS_PUBLIC, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_INSTITUTEBYID_FAILED_PUBLIC, payload: getErrorMessage(error) });
    }
};
export const getInstituteById = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_INSTITUTEBYID });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/organizations/institutes/${id}`, config);
        dispatch({ type: GET_INSTITUTEBYID_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_INSTITUTEBYID_FAILED, payload: getErrorMessage(error) });
    }
};

export const addInstitute = (Institute: any) => async (dispatch: any) => {
    try {
        dispatch({ type: ADD_INSTITUTE });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/organizations/institutes/`, Institute, config);
        if (res?.data?.status) {
            if (res?.data?.errorCode === 217) {
                await dispatch(getInstitutes());
                await dispatch({
                    type: ADD_INSTITUTE_SUCCESS,
                    payload: res.data.responseDto,
                });
            }
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 217) {
                dispatch({
                    type: ADD_INSTITUTE_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: ADD_INSTITUTE_FAILED, payload: getErrorMessage(error) });
    }
};
export const editInstitute =
    (id: string, Institute: any, status?: string) => async (dispatch: any) => {
        try {
            const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
            dispatch({ type: EDIT_INSTITUTE });
            const isINSAdmin = ['INSTITUTE_ADMIN'].includes(profile.roles[0]);
            const config = await axiosConfig(true);
            const res = await axios.put(
                `${API_URL}/organizations/institutes/${id}`,
                Institute,
                config
            );
            dispatch({ type: EDIT_INSTITUTE_SUCCESS, payload: res.data.responseDto });
            if (res?.data?.status && !isINSAdmin) {
                await dispatch(getInstitutes(0, 10, status));
            }
            if (!res?.data?.status) {
                if (res?.data?.errorCode !== 212) {
                    dispatch({
                        type: EDIT_INSTITUTE_FAILED,
                        payload: res.data.errorDescription,
                    });
                }
            }
        } catch (error) {
            dispatch({ type: EDIT_INSTITUTE_FAILED, payload: getErrorMessage(error) });
        }
    };
export const deleteInstitute = (id: string, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: DELETE_INSTITUTE });
        const config = await axiosConfig(true);
        const res = await axios.delete(`${API_URL}/organizations/institutes/${id}`, config);
        await dispatch(getInstitutes(0, 10, status));
        dispatch({ type: DELETE_INSTITUTE_SUCCESS, payload: res.data.responseDto });
    } catch (error) {
        dispatch({ type: DELETE_INSTITUTE_FAILED, payload: getErrorMessage(error) });
    }
};
export const bulkUpdateInstitute = (institutes, tabStatus) => async (dispatch: any) => {
    try {
        dispatch({ type: INSTITUTE_BULK_UPDATE });
        const config = await axiosConfig(true);
        const res = await axios.post(
            `${API_URL}/organizations/institutes/bulk`,
            institutes,
            config
        );
        await dispatch(getInstitutes(0, 10, tabStatus));
        if (res?.data?.status) {
            if (res?.data?.errorCode === 202)
                dispatch({ type: INSTITUTE_BULK_UPDATE_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 202) {
                dispatch({
                    type: INSTITUTE_BULK_UPDATE_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: INSTITUTE_BULK_UPDATE_FAILED, payload: getErrorMessage(error) });
    }
};
export const clearInstitutesMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_INSTITUTE_MESSAGES });
};
