import React, { ReactElement } from 'react';

interface Props {
    size?: number;
}

export default function EventLocation({ size }: Props): ReactElement {
    return (
        <svg
            width={size || '24'}
            height={size || '30'}
            viewBox="0 0 24 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 16.334C14.2091 16.334 16 14.5431 16 12.334C16 10.1248 14.2091 8.33398 12 8.33398C9.79086 8.33398 8 10.1248 8 12.334C8 14.5431 9.79086 16.334 12 16.334Z"
                stroke="#4C2EC2"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.0002 1.66699C9.17119 1.66699 6.45808 2.7908 4.45769 4.79119C2.4573 6.79157 1.3335 9.50468 1.3335 12.3337C1.3335 14.8563 1.8695 16.507 3.3335 18.3337L12.0002 28.3337L20.6668 18.3337C22.1308 16.507 22.6668 14.8563 22.6668 12.3337C22.6668 9.50468 21.543 6.79157 19.5426 4.79119C17.5422 2.7908 14.8291 1.66699 12.0002 1.66699V1.66699Z"
                stroke="#4C2EC2"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
