import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import Loader from '../../assets/svg/Loader';
import { restoreConfirmationMessage } from '../../constants';
import {
    bulkUpdate,
    bulkUpdateBadges,
    bulkUpdateCertificates,
    bulkUpdateChallenges,
    bulkUpdateCompany,
    bulkUpdateEvents,
    bulkUpdateInstitute,
    bulkUpdateMentors,
    bulkUpdateStudent,
    bulkUpdateUsers,
} from '../../redux/rootActions';
import { RootState } from '../../redux/rootReducer';
interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    institutes?: any;
    tracks?: any;
    challenges?: any;
    companies?: any;
    students?: any;
    certificates?: any;
    events?: any;
    badges?: any;
    mentors?: any;
    users?: any;
    tabStatus: string;
}

export default function BulkRestoreConfirmationModal({
    modalVisible,
    setModalVisible,
    students,
    institutes,
    tracks,
    companies,
    certificates,
    challenges,
    events,
    badges,
    mentors,
    users,
    tabStatus,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const isStudentBulkUpdateLoading = useSelector(
        (state: RootState) => state.students.isBulkUpdateLoading
    );
    const isInstituteBulkUpdateLoading = useSelector(
        (state: RootState) => state.institutes.isBulkUpdateLoading
    );
    const isTracksBulkUpdateLoading = useSelector(
        (state: RootState) => state.tracks.isBulkUpdateLoading
    );
    const isChallengeBulkUpdateLoading = useSelector(
        (state: RootState) => state.challenges.isBulkUpdateLoading
    );
    const isCompanyBulkUpdateLoading = useSelector(
        (state: RootState) => state.companies.isBulkUpdateLoading
    );
    const isCertificateBulkUpdateLoading = useSelector(
        (state: RootState) => state.companies.isBulkUpdateLoading
    );
    const isEventsBulkUpdateLoading = useSelector(
        (state: RootState) => state.events.isBulkUpdateLoading
    );
    const isBadgesBulkUpdateLoading = useSelector(
        (state: RootState) => state.badges.isBulkUpdateLoading
    );
    const isMentorsBulkUpdateLoading = useSelector(
        (state: RootState) => state.mentors.isBulkUpdateLoading
    );
    const isUsersBulkUpdateLoading = useSelector(
        (state: RootState) => state.users.isBulkUpdateLoading
    );
    return (
        <Modal
            open={modalVisible}
            onClose={() => {
                setModalVisible(false);
            }}
            center
            styles={{ modal: { borderRadius: 12, maxWidth: 555 } }}
            focusTrapped={false}
        >
            <div className="px-2 mt-10">
                <div className="text-lg ml-4" style={{ color: '#9698A9' }}>
                    {`${restoreConfirmationMessage} ${
                        institutes
                            ? 'institutes?'
                            : students
                            ? 'students?'
                            : tracks
                            ? 'tracks?'
                            : companies
                            ? 'companies?'
                            : certificates
                            ? 'certificates?'
                            : events
                            ? 'events?'
                            : badges
                            ? 'badges?'
                            : mentors
                            ? 'mentors?'
                            : users
                            ? 'users?'
                            : 'Challenges?'
                    }`}
                </div>
                <div className="mt-8 flex justify-end">
                    <button
                        className="border rounded-md border-blue-600 text-blue-600 text-sm h-10 px-8 mr-2 focus:outline-none"
                        onClick={() => {
                            setModalVisible(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="border bg-blue-600 rounded-md text-white text-sm h-10 leading-4 px-8 ml-2 focus:outline-none hover:text-white hover:bg-blue-800"
                        onClick={async () => {
                            if (institutes) {
                                dispatch(bulkUpdateInstitute(institutes, tabStatus));
                            } else if (students) {
                                dispatch(bulkUpdateStudent(students, tabStatus));
                            } else if (tracks) {
                                dispatch(bulkUpdate(tracks, tabStatus));
                            }
                            // else if (challenges) {
                            //     dispatch(bulkUpdateChallenges(challenges, tabStatus));
                            // }
                            else if (companies) {
                                dispatch(bulkUpdateCompany(companies, tabStatus));
                            } else if (certificates) {
                                dispatch(bulkUpdateCertificates(certificates, tabStatus));
                            } else if (events) {
                                dispatch(bulkUpdateEvents(events, tabStatus));
                            } else if (badges) {
                                dispatch(bulkUpdateBadges(badges, tabStatus));
                            } else if (mentors) {
                                dispatch(bulkUpdateMentors(mentors, tabStatus));
                            } else if (users) {
                                dispatch(bulkUpdateUsers(users, tabStatus));
                            }

                            setModalVisible(false);
                        }}
                    >
                        {isStudentBulkUpdateLoading ? (
                            <Loader />
                        ) : isInstituteBulkUpdateLoading ? (
                            <Loader />
                        ) : isTracksBulkUpdateLoading ? (
                            <Loader />
                        ) : isChallengeBulkUpdateLoading ? (
                            <Loader />
                        ) : isCompanyBulkUpdateLoading ? (
                            <Loader />
                        ) : isCertificateBulkUpdateLoading ? (
                            <Loader />
                        ) : isEventsBulkUpdateLoading ? (
                            <Loader />
                        ) : isBadgesBulkUpdateLoading ? (
                            <Loader />
                        ) : isMentorsBulkUpdateLoading ? (
                            <Loader />
                        ) : isUsersBulkUpdateLoading ? (
                            <Loader />
                        ) : (
                            'Restore'
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
