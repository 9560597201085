import React, { ReactElement, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import * as HiIcons from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import { InstitutesType } from '../../../../redux/institutes/reducers';
import { INSTITUTE_ADMIN } from '../../../../redux/authAdmin/reducers';
ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Home town',
                font: {
                    size: 14,
                },
            },
            grid: {
                display: false,
            },
            ticks: {
                autoSkip: false,
            },
        },
        y: {
            title: {
                display: true,
                text: 'Number of Students',
                font: {
                    size: 14,
                },
            },
            grid: {
                display: false,
            },
            beginAtZero: true,
            ticks: {
                callback: function (value) {
                    if (Math.floor(value) === value) {
                        return value;
                    }
                },
            },
        },
    },
};
interface Props {
    institute: InstitutesType;
    setInstitute: (value: InstitutesType) => void;
}
export default function InstitutesRegistration({ institute, setInstitute }: Props): ReactElement {
    const registrationData = useSelector(
        (state: RootState) => state.dashboard.instituteRegistrationData
    );
    const institutes = useSelector((state: RootState) => state.institutes.publicInstitutData);
    const user = useSelector((state: RootState) => state.users.User);
    const [showInstitutesDropdown, setShowInstitutesDropdown] = useState(false);

    const labels = registrationData?.labels;
    const data = {
        labels,
        datasets: [
            {
                label: 'Institutes',
                data: registrationData?.values,
                borderColor: 'rgb(45 212 191)',
                backgroundColor: 'rgb(45 212 191)',
            },
        ],
    };
    return (
        <>
            <div className="flex w-1/2 lg:w-1/3 mb-2">
                <div className="mb-4 w-full md:mb-0 flex">
                    <div className="flex flex-col w-full">
                        {user?.roles[0]?.roleName !== INSTITUTE_ADMIN ? (
                            <div className="w-full flex items-center">
                                <label className="mx-2">Institute : </label>
                                <div
                                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none md:w-2/3 lg:w-3/5 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className="flex justify-between w-full"
                                        onClick={() => setShowInstitutesDropdown(true)}
                                    >
                                        <div className="text-gray-700 font-medium pl-2">
                                            {institute ? institute?.instName : 'All'}
                                        </div>
                                        <div className="flex flex-col">
                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                            <HiIcons.HiChevronDown className="mr-2" />
                                        </div>
                                    </div>
                                    {showInstitutesDropdown ? (
                                        <ul
                                            className="absolute max-h-48 overflow-y-auto w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setShowInstitutesDropdown(false)}
                                        >
                                            <div
                                                className={`p-2 hover:bg-gray-200 ${
                                                    !institute && 'bg-gray-300'
                                                }`}
                                                key="0"
                                                // value=""
                                                onClick={() => {
                                                    setInstitute(null);
                                                    setShowInstitutesDropdown(false);
                                                }}
                                            >
                                                All
                                            </div>
                                            {institutes?.map(inst => (
                                                <div
                                                    className={`p-2 hover:bg-gray-200 ${
                                                        institute?.instName === inst?.instName &&
                                                        'bg-gray-300'
                                                    }`}
                                                    key={inst?.id}
                                                    // value={inst?.id}
                                                    onClick={() => {
                                                        setInstitute(inst);

                                                        setShowInstitutesDropdown(false);
                                                    }}
                                                >
                                                    {inst?.instName}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="w-full overflow-hidden pt-4">
                <div className="w-full overflow-x-auto pr-8 pb-6" style={{ height: '400px' }}>
                    <Bar options={options} data={data} />
                </div>
            </div>
            <div className="flex flex-col items-center justify-center pb-6">
                <div className="text-gray-700 text-sm md:text-base md:font-semibold mb-2">
                    Total Students Registered - {registrationData?.items?.Total}
                </div>
                <div className="text-blue-700 text-sm md:text-base md:font-semibold">
                    Male Students - {registrationData?.items?.Male} &nbsp; &nbsp; Female Students -{' '}
                    {registrationData?.items?.Female}
                </div>
            </div>
        </>
    );
}
