import React, { ReactElement } from 'react';

interface Props {
    color: string;
    size?: number;
}

export default function Support({ color, size }: Props): ReactElement {
    return (
        <svg
            width={size || '22'}
            height={size || '20'}
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.5 14.5V15.5H9.5V14.5H10.5ZM6.36451 1.22314C7.5171 0.745725 8.75244 0.5 10 0.5C11.2476 0.5 12.4829 0.745725 13.6355 1.22314C14.7881 1.70056 15.8354 2.40033 16.7175 3.28249C17.5997 4.16464 18.2994 5.21192 18.7769 6.36451C19.2543 7.5171 19.5 8.75244 19.5 10C19.5 12.5196 18.4991 14.9359 16.7175 16.7175C14.9359 18.4991 12.5196 19.5 10 19.5C8.75244 19.5 7.5171 19.2543 6.36451 18.7769C5.21191 18.2994 4.16464 17.5997 3.28249 16.7175C1.50089 14.9359 0.5 12.5196 0.5 10C0.5 7.48044 1.50089 5.06408 3.28249 3.28249C4.16464 2.40033 5.21191 1.70056 6.36451 1.22314ZM1.5 10C1.5 14.6861 5.31386 18.5 10 18.5C14.6861 18.5 18.5 14.6861 18.5 10C18.5 5.31386 14.6861 1.5 10 1.5C5.31386 1.5 1.5 5.31386 1.5 10ZM7.52513 5.52513C8.1815 4.86875 9.07174 4.5 10 4.5C10.9283 4.5 11.8185 4.86875 12.4749 5.52513C13.1313 6.1815 13.5 7.07174 13.5 8C13.5 9.00296 12.9268 9.55593 12.1707 10.2175C12.1398 10.2446 12.1083 10.272 12.0765 10.2997C11.4679 10.8297 10.7294 11.4731 10.5439 12.5H9.52886C9.58606 12.0299 9.72337 11.6739 9.89654 11.3877C10.131 11.0002 10.4498 10.7087 10.8104 10.4233C10.8856 10.3637 10.966 10.3022 11.0489 10.2387C11.3361 10.0188 11.6543 9.77525 11.9008 9.50477C12.2477 9.12415 12.5 8.64932 12.5 8C12.5 7.33696 12.2366 6.70107 11.7678 6.23223C11.2989 5.76339 10.663 5.5 10 5.5C9.33696 5.5 8.70107 5.76339 8.23223 6.23223C7.88304 6.58143 7.64782 7.02328 7.5505 7.5H6.53588C6.64317 6.75661 6.98776 6.06249 7.52513 5.52513Z"
                fill={color}
                stroke={color}
            />
        </svg>
    );
}
