import axios from 'axios';
import { API_URL } from '../../../constants';
import { axiosConfig } from '../../../utils/axiosApi';
import { getErrorMessage } from '../../../utils/axiosErrorHandler';

const PREFIX = '@WE_MENTORS';

export const REQUEST_A_MENTOR = PREFIX + 'REQUEST_A_MENTOR';
export const REQUEST_A_MENTOR_SUCCESS = PREFIX + 'REQUEST_A_MENTOR_SUCCESS';
export const REQUEST_A_MENTOR_FAILED = PREFIX + 'REQUEST_A_MENTOR_FAILED';
export const CLEAR_MENTOR_REQUEST_MESSAGES = PREFIX + 'CLEAR_MENTOR_REQUEST_MESSAGES';

export const requestAMentor = (details?: any) => async (dispatch: any) => {
    try {
        dispatch({ type: REQUEST_A_MENTOR });
        const config = await axiosConfig(false);
        const response = await axios.post(`${API_URL}/mentors/request`, details, config);
        if (response?.data?.errorCode === 521) {
            dispatch({ type: REQUEST_A_MENTOR_SUCCESS, payload: response.data });
        } else {
            dispatch({ type: REQUEST_A_MENTOR_SUCCESS, payload: response.data.responseDto });
        }
    } catch (error) {
        dispatch({ type: REQUEST_A_MENTOR_FAILED, payload: getErrorMessage(error) });
    }
};

export const clearMentorRequestMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_MENTOR_REQUEST_MESSAGES });
};
