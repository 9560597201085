import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
    addCertificate,
    clearCertificatesMessages,
    getPresignedUrl,
    getTags,
    getTracks,
} from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { useHistory } from 'react-router-dom';
import * as HiIcons from 'react-icons/hi';
import * as FiIcons from 'react-icons/fi';
import CertificatesSuccessfullModal from '../../modals/CertificatesSuccessfullModal';
export default function AddCertificate() {
    const maxChars = 200;
    const dispatch = useDispatch();
    const history = useHistory();
    const tracks = useSelector((state: RootState) => state.tracks.trackData);
    const isCreateLoading = useSelector((state: RootState) => state.certificates.isCreateLoading);
    const createCertificateError = useSelector(
        (state: RootState) => state.certificates.createCertificateError
    );
    const certificateCreateSuccessMessage = useSelector(
        (state: RootState) => state.certificates.certificateCreateSuccessMessage
    );
    const imageError = useSelector((state: RootState) => state.certificates.imageError);
    const isImageUploading = useSelector((state: RootState) => state.certificates.isImageUploading);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const formRef = useRef<any>();
    const [imageUrl, setImageUrl] = useState('');
    const [fileLocation, setFileLocation] = useState('');
    const [createSuccessVisible, setCreateSuccessVisible] = useState(false);
    const [category, setCategory] = useState<string>('Select a track');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [tags, setTags] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResultsVisible, setSearchResultsVisible] = useState(false);

    const [tagsHelpText, setTagsHelpText] = useState<boolean>(false);

    const serchTagNameOrId = e => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        if (searchTerm.length >= 3) {
            dispatch(getTags(true, searchTerm));
            setSearchResultsVisible(true);
        } else {
            setSearchResultsVisible(false);
        }
    }, [dispatch, searchTerm]);

    const handleClearMessages = useCallback(() => {
        if (certificateCreateSuccessMessage || createCertificateError) {
            setCreateSuccessVisible(true);
        }
    }, [dispatch, certificateCreateSuccessMessage, createCertificateError, history]);
    const handleClose = () => {
        dispatch(clearCertificatesMessages());
        setCreateSuccessVisible(false);
        if (!createCertificateError) {
            history.push('/admin/achievements/certificates');
        }
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);
    useEffect(() => {
        dispatch(getTracks(true, 0, 100, 'PUBLISHED'));
    }, [dispatch]);
    const handleImage = (e: any) => {
        const _URL = window.URL || window.webkitURL;
        let file: File, img;
        if ((file = e.target.files[0])) {
            img = new Image();
            img.src = _URL.createObjectURL(file);
            setImageUrl(img.src);
        }
    };

    const uploadImageToS3 = async e => {
        const location = await dispatch(
            getPresignedUrl(e.target.files[0]?.name, e.target.files[0])
        );
        const locationArray = location?.toString().split('/', 6);
        setFileLocation(`tracks/certificates/${locationArray?.[5]}/${locationArray?.[5]}.html`);
    };

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    <div className="relative">
                        <Formik
                            innerRef={formRef}
                            initialValues={{
                                certificateName: '',
                                contentDocUrl: '',
                                file: undefined,
                                certStatus: 'ACTIVE',
                                track: '',
                                description: '',
                            }}
                            validationSchema={Yup.object({
                                certificateName: Yup.string()
                                    .max(100, 'Name should be less than 100 characters')
                                    .required('Name is required'),
                                description: Yup.string().max(
                                    maxChars,
                                    'Description should be less than 500 characters'
                                ),
                                track: Yup.string().required('Track is required'),
                                file: Yup.array().required('Template is required'),
                            })}
                            onSubmit={async values => {
                                dispatch(
                                    addCertificate({
                                        certificateName: values.certificateName,
                                        description: values.description,
                                        contentDocUrl: fileLocation,
                                        track: { id: values.track },
                                        certStatus: values.certStatus,
                                        tags: tags,
                                    })
                                );
                            }}
                        >
                            {({
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                values,
                                errors,
                                touched,
                            }) => (
                                <>
                                    <div className="flex justify-between items-center mb-4 md:mb-6">
                                        <p className="text-xl font-semibold text-left">
                                            Add New Certificate
                                        </p>

                                        <div className="flex w-1/2 md:w-1/4 lg:pl-8 h-16">
                                            <button
                                                className="md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() =>
                                                    history.push(
                                                        '/admin/achievements/certificates/'
                                                    )
                                                }
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => handleSubmit()}
                                            >
                                                {isCreateLoading ? <Loader /> : 'Save'}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2 pb-4 mb-6">
                                        <div className="col-span-12 md:col-span-4 mb-2 md:mb-4">
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="title"
                                                    className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Certificate Name
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.certificateName &&
                                                            touched.certificateName
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="name"
                                                        type="text"
                                                        value={values.certificateName}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'certificateName',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.certificateName &&
                                                    touched.certificateName ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.certificateName}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex">
                                                <label
                                                    className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Related Track
                                                    <div className="text-red-600">*</div>
                                                </label>
                                                <div className="flex flex-col w-full">
                                                    <div className="w-full flex">
                                                        <div
                                                            className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-4/5 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                                        >
                                                            <div
                                                                className="flex justify-between w-full"
                                                                onClick={() =>
                                                                    setDropdownVisible(true)
                                                                }
                                                            >
                                                                <div className="text-gray-700 font-medium pl-2 text-base">
                                                                    {category}
                                                                </div>

                                                                <div className="flex flex-col">
                                                                    <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                    <HiIcons.HiChevronDown className="mr-2" />
                                                                </div>
                                                            </div>
                                                            {dropdownVisible ? (
                                                                <ul
                                                                    className="absolute w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() =>
                                                                        setDropdownVisible(false)
                                                                    }
                                                                >
                                                                    {tracks.map(track => (
                                                                        <div
                                                                            className="p-2 hover:bg-gray-200 text-base"
                                                                            key={track.id}
                                                                            onClick={() => {
                                                                                setCategory(
                                                                                    track.trackName
                                                                                );
                                                                                setFieldValue(
                                                                                    'track',
                                                                                    track.id
                                                                                );
                                                                                setDropdownVisible(
                                                                                    false
                                                                                );
                                                                            }}
                                                                        >
                                                                            {track.trackName}
                                                                        </div>
                                                                    ))}
                                                                </ul>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    {errors.track && touched.track ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.track}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="my-6 w-full md:mb-0 flex">
                                                <label
                                                    className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Certifcate Status
                                                    <div className="text-red-600">*</div>
                                                </label>
                                                <div className="flex text-sm md:text-base items-center w-full">
                                                    <input
                                                        className="mr-1"
                                                        type="radio"
                                                        value="ACTIVE"
                                                        id="active"
                                                        name="status"
                                                        defaultChecked={true}
                                                        onChange={handleChange('certStatus')}
                                                    />
                                                    <label className="mr-4 md:mr-8">Active</label>
                                                    <input
                                                        className="mr-1"
                                                        type="radio"
                                                        value="INACTIVE"
                                                        id="inactive"
                                                        name="status"
                                                        onChange={handleChange('certStatus')}
                                                    />
                                                    <label className="">Inactive</label>
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex h-24">
                                                <label
                                                    htmlFor="description"
                                                    className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Certificate Description
                                                </label>
                                                <div className="flex flex-col w-full">
                                                    <textarea
                                                        className={`resize-none focus:outline-none p-2 w-4/5 border-2 md:text-base h-20 focus:border-blue-900 ${
                                                            errors.description &&
                                                            touched.description
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        }`}
                                                        id="description"
                                                        value={values.description}
                                                        placeholder=""
                                                        onChange={handleChange('description')}
                                                    />
                                                    <div className="w-4/5">
                                                        <p className="text-gray-500 text-sm text-right">
                                                            ({values.description?.length} /{' '}
                                                            {maxChars})
                                                        </p>
                                                    </div>
                                                    {errors.description && touched.description ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.description}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex">
                                                <label
                                                    htmlFor="description"
                                                    className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Certificate Template
                                                    <div className="text-red-600">*</div>
                                                </label>
                                                <div
                                                    className={` w-full mb-2 md:mb-4  relative ${
                                                        imageUrl ? 'w-fit' : ''
                                                    }  `}
                                                >
                                                    <div className="bg-gray-100 border w-4/5 border-opacity-100 border-2 border-black-600 text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-20 md:h-32">
                                                        {!imageUrl && (
                                                            <div className="w-full">
                                                                <div className="flex justify-center">
                                                                    <FiIcons.FiUpload
                                                                        className=""
                                                                        size="30px"
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <label
                                                                        className="text-sm font-medium leading-149 md:text-normal text-gray-600 cursor-pointer underline"
                                                                        htmlFor="imageLink"
                                                                    >
                                                                        Upload Certificate Template
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {imageUrl && (
                                                            <div>
                                                                <p>
                                                                    File name :{' '}
                                                                    {values.file[0]?.name}
                                                                </p>
                                                                <p>
                                                                    File size :{' '}
                                                                    {Math.floor(
                                                                        values.file[0]?.size / 1024
                                                                    ) + 'KB'}
                                                                </p>
                                                                <div className="mt-1 text-red-400 text-sm flex justify-center">
                                                                    {imageError}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="mt-1 text-gray-400 text-sm font-medium leading-149">
                                                        Upload format (html)
                                                    </div>

                                                    <div
                                                        className="absolute md:mt-0 mb-4 text-center"
                                                        style={{
                                                            left: '40%',
                                                            transform: 'translate(-50%,-50%)',
                                                        }}
                                                    >
                                                        {' '}
                                                        {imageUrl && !isImageUploading && (
                                                            <div>
                                                                <label
                                                                    className="underline cursor-pointer mt-4"
                                                                    htmlFor="imageLink"
                                                                >
                                                                    Change Certificate Template
                                                                </label>
                                                            </div>
                                                        )}
                                                        {isImageUploading && (
                                                            <div className="flex items-center">
                                                                <div className="mr-2">
                                                                    Uploading
                                                                </div>
                                                                <div>
                                                                    <Loader />
                                                                </div>
                                                            </div>
                                                        )}{' '}
                                                        {touched.file ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.file}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <input
                                                    className={`focus:outline-none w-full focus:border-blue-900`}
                                                    id="imageLink"
                                                    type="file"
                                                    accept=".html,"
                                                    onChange={async e => {
                                                        const file = [];
                                                        file.push(e.target.files[0]);
                                                        setFieldValue('file', file);
                                                        handleImage(e);
                                                        uploadImageToS3(e);
                                                    }}
                                                    style={{ display: 'none' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                            <div className="flex border-2 flex-col ml-2 justify-items-center">
                                                <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                                    <div className="flex sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                        <div>Assign Tags</div>
                                                        <div>
                                                            <HiIcons.HiQuestionMarkCircle
                                                                onClick={() => {
                                                                    setTagsHelpText(true);
                                                                }}
                                                                className="ml-2 mt-1 cursor-pointer"
                                                            />
                                                        </div>
                                                    </div>
                                                    {tagsHelpText ? (
                                                        <div
                                                            onMouseOverCapture={() => {
                                                                setTagsHelpText(true);
                                                                // setProfileDropdownVisible(false);
                                                            }}
                                                            onMouseLeave={() =>
                                                                setTagsHelpText(false)
                                                            }
                                                            className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-6 right-12"
                                                        >
                                                            Assign already created tags to the new
                                                            record created.
                                                        </div>
                                                    ) : null}
                                                    <div
                                                        className="sm:text-sm md:text-base text-gray-400 underline pr-2 cursor-pointer"
                                                        onClick={() => {
                                                            setTags([]);
                                                        }}
                                                    >
                                                        Clear All
                                                    </div>
                                                </div>
                                                <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                                    <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                        <div className="mb-8 w-min">
                                                            {tags
                                                                ? tags?.map(tag => (
                                                                      <p
                                                                          style={{
                                                                              maxHeight: '10px',
                                                                              maxWidth: 'inherit',
                                                                              display: 'flex',
                                                                              alignItems: 'center',
                                                                          }}
                                                                          key={tag.id}
                                                                          className={`rounded-sm border-2 my-1 mr-4 text-gray-500 border-gray-400 font-medium py-3 px-1 whitespace-nowrap`}
                                                                      >
                                                                          {tag.tagName}
                                                                          <HiIcons.HiOutlineXCircle
                                                                              size={23}
                                                                              className="ml-2 text-gray-500 cursor-pointer"
                                                                              onClick={() => {
                                                                                  setTags(
                                                                                      tags?.filter(
                                                                                          item =>
                                                                                              item.tagName !==
                                                                                              tag.tagName
                                                                                      )
                                                                                  );
                                                                              }}
                                                                          />
                                                                      </p>
                                                                  ))
                                                                : null}
                                                        </div>

                                                        <div>
                                                            <input
                                                                className={`rounded w-full text-gray-500 font-medium focus:outline-none mt-2 p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                                                id="id"
                                                                type="text"
                                                                placeholder="Search tags"
                                                                onChange={e => serchTagNameOrId(e)}
                                                                autoComplete="off"
                                                            />
                                                            {searchResultsVisible &&
                                                            tagData?.length ? (
                                                                <ul
                                                                    className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() =>
                                                                        setSearchResultsVisible(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    {tagData?.map(tag => (
                                                                        <div
                                                                            className="p-2 hover:bg-gray-200 cursor-pointer"
                                                                            key={tag.id}
                                                                            onClick={() => {
                                                                                if (
                                                                                    tags
                                                                                        ?.map(
                                                                                            checktag =>
                                                                                                checktag.tagName
                                                                                        )
                                                                                        .includes(
                                                                                            tag.tagName
                                                                                        ) === false
                                                                                ) {
                                                                                    setTags([
                                                                                        ...tags,
                                                                                        tag,
                                                                                    ]);
                                                                                }
                                                                            }}
                                                                        >
                                                                            {tag.tagCategory +
                                                                                ': ' +
                                                                                tag.tagName}
                                                                        </div>
                                                                    ))}
                                                                </ul>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" ml-2 justify-items-center">
                                                <div className="flex flex-col text-sm pl-2 lg:pl-4 mt-6 space-y-4 text-gray-400 ">
                                                    <div>
                                                        Created tags can be retrieved by typing the
                                                        text in the search bar.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(certificateCreateSuccessMessage ||
                                        createCertificateError) && (
                                        <CertificatesSuccessfullModal
                                            modalVisible={createSuccessVisible}
                                            handleClose={handleClose}
                                        />
                                    )}
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
