import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { ReactElement, useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL, CLIENT_ID_CALENDAR } from '../../../../../../constants';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import {
    generateOTP,
    registerMobileWeb,
    registerWeb,
    resendEmailVerificationWeb,
    signUpGenerateOTP,
} from '../../../../../../redux/webRedux/register/actions';
import { RootState } from '../../../../../../redux/rootReducer';
import { USER_PROFILE } from '../../../../../utils/storageWeb';
import Loader from '../../../../../../assets/svg/Loader';
import * as HiIcons from 'react-icons/hi';
import useCountDown from 'react-countdown-hook';
import { loginLinkedIn } from '../../../../../../redux/rootActions';
import PhoneInput from 'react-phone-input-2';
import { mobielNumberVerification } from '../../../../../utils';
import useWidth from '../../../../../hooks/useWidth';
import styled from 'styled-components';

const SmallFontLabel = styled.label`
    font-size: 10px;
`;

interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    setSignUpModalVisible: (value: boolean) => void;
    setEmailVerifyModalVisible: (value: boolean) => void;
    setAlreadySignedUpModalVisible: (value: boolean) => void;
    setApplicationRejectedModalVisible: (value: boolean) => void;
    setApplicationReceivedModalVisible: (value: boolean) => void;
    setIsPendingExpert?: (value: boolean) => void;
    nextPath?: { href: string; as?: string };
}

export default function SignUp({ setModalVisible }: Props): ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();
    const location: any = useLocation();

    const isLoading = useSelector((state: RootState) => state.newWebUser.isLoading);
    const registerResponse = useSelector((state: RootState) => state?.newWebUser?.registerResponse);
    const registerResendResponse = useSelector(
        (state: RootState) => state?.newWebUser?.registerResendResponse
    );

    const ErrorMsg = 'Oops!! something went wrong';
    const emailResent = useSelector((state: RootState) => state?.newWebUser?.emailResent);
    // const registerMobileResponse = useSelector(
    //     (state: RootState) => state.newWebUser.registerMobileResponse
    // );
    const registerMobileResponse = useSelector(
        (state: RootState) => state?.newWebUser?.registerMobileResponse
    );
    const registerErrorMessage = useSelector(
        (state: RootState) => state?.newWebUser?.registerErrorMessage
    );
    const registerError = useSelector(
        (state: RootState) => state?.newWebUser?.registerErrorMessage
    );

    const mobileRegisterError = useSelector(
        (state: RootState) => state.newWebUser.mobileRegisterError
    );
    const otpResponse = useSelector((state: RootState) => state.newWebUser.otpSignUpResponse);
    const isOtpSent = useSelector((state: RootState) => state.newWebUser.otpSignUpSent);

    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const [selectedTab, setSelectedTab] = useState('byEmail');
    const [hasLoggedIn, setHasLoggedIn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState<string>();
    const [successEmail, setSuccessEmail] = useState(false);
    const [isMobileError, setIsMobileError] = useState<boolean>(false);
    const [mobileNumber, setMobileNumber] = useState<any>(null);
    const [mobileCodeSent, setMobileCodeSent] = useState(false);
    const [countDown, setCountDown] = useState<string>();
    const [timerTigger, setTimerTigger] = useState(false);
    const [OTPCode, setOTPCode] = useState<any>();
    const [OTPError, setOTPError] = useState<boolean>(false);
    const [termCondition, setTermCondition] = useState(false);
    const [termConditionReg, setTermConditionReg] = useState(false);

    const [emailResentSuccess, setEmailResentSuccess] = useState(false);
    const [emailResentError, setEmailResentError] = useState(false);

    const [invalidLogin, setInvalidLogin] = useState(false);
    const [emailHelpText, setEmailHelpText] = useState<boolean>(false);
    const [resendEmailCheck, setResendEmailCheck] = useState<boolean>(false);
    const viewPortWidth = useWidth();
    const initialTime = 300000;
    const interval = 1000;

    const [timeLeft, { start, pause, resume, reset }] = useCountDown(initialTime, interval);

    const emailCreationURL = 'https://www.youtube.com/watch?v=WkUGuBpedfI';

    const Code = '1234';

    const passwordShow = () => {
        setShowPassword(!showPassword);
    };

    const loginWithFacebook = response => {
        if (response.accessToken) {
            // dispatch(loginFacebook(response.accessToken));
        }
    };

    // let timeleft = 300;

    useEffect(() => {
        if (emailResentSuccess) {
            setTimeout(() => {
                setEmailResentSuccess(false);
            }, 3000);
        }
        if (emailResentError) {
            setTimeout(() => {
                setEmailResentError(false);
            }, 3000);
        }
    }, [emailResentError, emailResentSuccess]);

    useEffect(() => {
        if (registerResendResponse) {
            setEmailResentSuccess(true);
        }
        if (registerErrorMessage) {
            setEmailResentError(true);
        }
    }, [registerErrorMessage, registerResendResponse, resendEmailCheck]);

    useEffect(() => {
        if (timerTigger === true) {
            // const downloadTimer = setInterval(function () {
            //     if (timeleft <= 0) {
            //         clearInterval(downloadTimer);
            //         setCountDown('Expired');
            //     } else if (timeleft < 10) {
            //         setCountDown('00:0' + timeleft);
            //     } else {
            //         setCountDown('00:' + timeleft);
            //     }
            //     // eslint-disable-next-line react-hooks/exhaustive-deps
            //     timeleft -= 1;
            // }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timerTigger]);

    const loginWithGoogle = async response => {
        if (response.accessToken) {
            // dispatch(loginGoogle(response.accessToken));
            setModalVisible(false);
        }
    };

    const validationSchema = Yup.object({
        emailAddress: Yup.string()
            .trim()
            .required('Email address is required')
            .matches(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                'Enter valid Email address'
            ),
        firstName: Yup.string().trim().required('First name is required'),
        password: Yup.string()
            .required('Password cannot be blank')
            .min(
                8,
                'Password not strong. Enter at least 8 characters. Must Contain One Uppercase, One Lowercase, One Number and one special case Character'
            )
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
            ),
    });

    useEffect(() => {
        if (mobielNumberVerification(mobileNumber)) {
            setIsMobileError(false);
        }
    }, [mobileNumber]);

    useEffect(() => {
        if (registerResponse?.token) {
            setEmailSent(true);
        }
        if (registerError) {
            setInvalidLogin(true);
        }
    }, [registerResponse, registerError]);

    useEffect(() => {
        if (registerMobileResponse?.token) {
            history.push(`/onboard`);
        }
        if (registerError) {
            setInvalidLogin(true);
        }
    }, [history, registerError, registerMobileResponse?.token]);

    useEffect(() => {
        if (location.state != null) {
            if (location.state.isEmailSent === true) {
                setEmailSent(true);
                setVerifyEmail(location.state.username);
            }
        } else {
            setEmailSent(false);
        }
    }, [location]);

    const submitMobileLoggin = () => {
        // setMobileCodeSent(true);
        // setTimerTigger(true);
        dispatch(signUpGenerateOTP(mobileNumber));
        // start();
    };

    useEffect(() => {
        if (otpResponse) {
            setMobileCodeSent(true);
        } else if (mobileRegisterError) {
            setIsMobileError(true);
        }
    }, [isOtpSent, otpResponse, mobileRegisterError]);

    useEffect(() => {
        setTimeout(() => {
            setIsMobileError(false);
        }, 4000);
    }, [isMobileError]);

    const restart = () => {
        const newTime = 300 * 1000;
        start(300 * 1000);
    };

    const createAccountMobie = () => {
        // eslint-disable-next-line eqeqeq
        // if (OTPCode == Code) {
        dispatch(registerMobileWeb(parseInt(OTPCode), mobileNumber.toString()));
        // alert('success');
        // setOTPError(false);
        // } else {
        //     setOTPError(true);
        // }
    };

    useEffect(() => {
        if (profile) {
            history.push(`/dashBoard/learn`);
        }
    }, [profile]);

    return (
        <>
            {viewPortWidth > 1023 ? (
                <>
                    {emailSent === false ? (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <div>
                                    <div className="text-3xl-2 font-semibold">Create Account</div>
                                </div>
                            </div>
                            <div className="signInContainer">
                                <div className="flex flex-row mt-4">
                                    <div
                                        className={`${
                                            selectedTab === 'byEmail'
                                                ? 'border-b-2 text-purple-950 border-purple-950'
                                                : ''
                                        } mr-6 cursor-pointer`}
                                        onClick={() => {
                                            setSelectedTab('byEmail');
                                        }}
                                    >
                                        By Email
                                    </div>
                                    <div
                                        className={`${
                                            selectedTab === 'byPhone'
                                                ? 'border-b-2 text-purple-950 border-purple-950'
                                                : ''
                                        } mr-4 cursor-pointer`}
                                        onClick={() => {
                                            setSelectedTab('byPhone');
                                        }}
                                    >
                                        By Phone
                                    </div>
                                </div>

                                <div>
                                    <div className="pb-8 pt-2">
                                        {selectedTab === 'byEmail' ? (
                                            <div>
                                                <Formik
                                                    initialValues={{
                                                        emailAddress: '',
                                                        password: '',
                                                        firstName: '',
                                                    }}
                                                    validationSchema={validationSchema}
                                                    onSubmit={async ({
                                                        emailAddress,
                                                        password,
                                                        firstName,
                                                    }) => {
                                                        setHasLoggedIn(true);
                                                        dispatch(
                                                            registerWeb(
                                                                emailAddress,
                                                                password,
                                                                firstName
                                                            )
                                                        );
                                                        setVerifyEmail(emailAddress);
                                                    }}
                                                >
                                                    {({
                                                        handleChange,
                                                        handleSubmit,
                                                        values,
                                                        errors,
                                                        touched,
                                                    }) => (
                                                        <form
                                                            onSubmit={event => {
                                                                event.preventDefault();
                                                                handleSubmit();
                                                            }}
                                                        >
                                                            <div className="mb-6 md:mb-4 mt-6 md:mt-4">
                                                                <div className="flex flex-row">
                                                                    <label
                                                                        className={`mb-2 text-sm ${
                                                                            errors.emailAddress &&
                                                                            touched.emailAddress
                                                                                ? 'text-red-500'
                                                                                : emailHelpText
                                                                                ? ''
                                                                                : ''
                                                                        }`}
                                                                        htmlFor={'email'}
                                                                    >
                                                                        Email Address
                                                                    </label>
                                                                    <HiIcons.HiQuestionMarkCircle
                                                                        onClick={() => {
                                                                            setEmailHelpText(
                                                                                !emailHelpText
                                                                            );
                                                                        }}
                                                                        className="ml-2 mt-1 cursor-pointer"
                                                                    />
                                                                    {emailHelpText ? (
                                                                        <div
                                                                            onMouseOverCapture={() => {
                                                                                setEmailHelpText(
                                                                                    true
                                                                                );
                                                                                // setProfileDropdownVisible(false);
                                                                            }}
                                                                            onMouseLeave={() =>
                                                                                setEmailHelpText(
                                                                                    false
                                                                                )
                                                                            }
                                                                            className="border-2 rounded-md absolute z-50 px-4 py-2  ml-28 bg-white"
                                                                            style={{
                                                                                boxShadow:
                                                                                    '0px 0px 10px 1px rgba(197, 203, 220, 0.32)',
                                                                                width: '375px',
                                                                            }}
                                                                        >
                                                                            If you don’t have an
                                                                            email already,{' '}
                                                                            <span className="border-b-2 border-gray-400 cursor-pointer">
                                                                                <a
                                                                                    target="_blank"
                                                                                    href={
                                                                                        emailCreationURL
                                                                                    }
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    Click Here
                                                                                </a>
                                                                            </span>{' '}
                                                                            to learn more.
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                                <input
                                                                    id="email"
                                                                    type="email"
                                                                    placeholder="Enter Email"
                                                                    className={`focus:outline-none w-full pb-2 md:pb-3 border-b text-sm focus:border-blue-900 ${
                                                                        errors.emailAddress &&
                                                                        touched.emailAddress
                                                                            ? 'border-red-600'
                                                                            : ''
                                                                    }`}
                                                                    onChange={handleChange(
                                                                        'emailAddress'
                                                                    )}
                                                                    value={values.emailAddress}
                                                                />
                                                                {errors.emailAddress &&
                                                                touched.emailAddress ? (
                                                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                                        {errors.emailAddress}
                                                                    </div>
                                                                ) : null}
                                                            </div>

                                                            <div className="mb-6 md:mb-4">
                                                                <label
                                                                    className={`text-sm mb-2 ${
                                                                        errors.firstName &&
                                                                        touched.firstName
                                                                            ? 'text-red-500'
                                                                            : ''
                                                                    }`}
                                                                    htmlFor={'firstName'}
                                                                >
                                                                    First Name
                                                                </label>
                                                                <input
                                                                    id="firstName"
                                                                    type="text"
                                                                    placeholder="Enter First Name"
                                                                    className={`focus:outline-none w-full pb-2 md:pb-3 border-b text-sm focus:border-blue-900 ${
                                                                        errors.firstName &&
                                                                        touched.firstName
                                                                            ? 'border-red-600'
                                                                            : ''
                                                                    }`}
                                                                    onChange={handleChange(
                                                                        'firstName'
                                                                    )}
                                                                    value={values.firstName}
                                                                />
                                                                {errors.firstName &&
                                                                touched.firstName ? (
                                                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                                        {errors.firstName}
                                                                    </div>
                                                                ) : null}
                                                            </div>

                                                            <div>
                                                                <div>
                                                                    <label
                                                                        className={`text-sm mb-2 ${
                                                                            errors.password &&
                                                                            touched.password
                                                                                ? 'text-red-500'
                                                                                : ''
                                                                        }`}
                                                                        htmlFor={'password'}
                                                                    >
                                                                        Password
                                                                    </label>
                                                                    <div className="relative">
                                                                        <input
                                                                            className={`focus:outline-none w-full pb-2 md:pb-3 border-b focus:border-blue-900 ${
                                                                                errors.password &&
                                                                                touched.password
                                                                                    ? 'border-red-600'
                                                                                    : ''
                                                                            } text-sm`}
                                                                            id="password"
                                                                            type={
                                                                                showPassword
                                                                                    ? 'text'
                                                                                    : 'password'
                                                                            }
                                                                            placeholder="Enter Password"
                                                                            onChange={handleChange(
                                                                                'password'
                                                                            )}
                                                                            value={values.password}
                                                                        />
                                                                        <div className="absolute inset-y-0 right-0 pt-1 pr-3 flex text-base item-center">
                                                                            {values.password
                                                                                .length > 0 ? (
                                                                                <>
                                                                                    <img
                                                                                        src="/assets/passwordShowIcon.png"
                                                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                                            showPassword
                                                                                                ? 'block'
                                                                                                : 'hidden'
                                                                                        }`}
                                                                                        onClick={
                                                                                            passwordShow
                                                                                        }
                                                                                        alt="show-password"
                                                                                        role="presentation"
                                                                                        draggable="false"
                                                                                    />
                                                                                    <img
                                                                                        src="/assets/passwordHideIcon.png"
                                                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                                            showPassword
                                                                                                ? 'hidden'
                                                                                                : 'block'
                                                                                        }`}
                                                                                        alt="hide-password"
                                                                                        role="presentation"
                                                                                        onClick={
                                                                                            passwordShow
                                                                                        }
                                                                                        draggable="false"
                                                                                    />
                                                                                </>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                    {errors.password &&
                                                                    touched.password ? (
                                                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                                            {errors.password}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>

                                                            <div
                                                                className={`mx-12 my-4 md:my-8 flex justify-center`}
                                                            >
                                                                <input
                                                                    className="styled-checkbox"
                                                                    id={`styled-checkbox-1`}
                                                                    type="checkbox"
                                                                    checked={termCondition}
                                                                    onClick={() => {
                                                                        if (
                                                                            termCondition === false
                                                                        ) {
                                                                            setTermCondition(true);
                                                                        } else {
                                                                            setTermCondition(false);
                                                                        }
                                                                    }}
                                                                />
                                                                <label
                                                                    className="whitespace-nowrap"
                                                                    htmlFor={`styled-checkbox-1`}
                                                                ></label>
                                                                I agree to the
                                                                <span
                                                                    className="pl-1 underline cursor-pointer"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            '/termsAndConditions',
                                                                            '_blank'
                                                                        );
                                                                    }}
                                                                >
                                                                    Terms and Conditions
                                                                </span>
                                                            </div>

                                                            {invalidLogin && registerError ? (
                                                                <div
                                                                    className="sm:w-1/2 md:w-full sm:mt-0 bg-red-100 shadow-xs mb-2"
                                                                    style={{
                                                                        borderRadius: 2,
                                                                        minWidth: 242,
                                                                    }}
                                                                >
                                                                    <div className="flex flex-row py-2 justify-center">
                                                                        <span className="ml-2 font-semibold text-sm">
                                                                            {registerError}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ) : null}

                                                            <div className="mb-4 md:mb-8 text-center">
                                                                <button
                                                                    type="submit"
                                                                    className={`text-white font-semibold rounded border w-40 h-10 sm:w-80 md:w-full sm:h-12 ${
                                                                        termCondition === true
                                                                            ? 'bg-purple-950 hover:border-transparent'
                                                                            : 'bg-purple-400 cursor-not-allowed'
                                                                    }`}
                                                                    style={{
                                                                        boxShadow:
                                                                            '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                                    }}
                                                                    disabled={
                                                                        termCondition === false
                                                                    }
                                                                >
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        'Register'
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    )}
                                                </Formik>
                                            </div>
                                        ) : selectedTab === 'byPhone' ? (
                                            <div>
                                                {mobileCodeSent === false ? (
                                                    <div>
                                                        <div className="mb-6 mt-6 md:mt-4">
                                                            <label
                                                                className={`block text-sm font-medium leading-149 mb-2 ${
                                                                    isMobileError
                                                                        ? 'text-red-500'
                                                                        : ''
                                                                }`}
                                                                htmlFor={'phoneNumber'}
                                                            >
                                                                Phone Number
                                                            </label>
                                                            <div
                                                                className={`border-b flex flex-row ${
                                                                    isMobileError
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-600'
                                                                }`}
                                                            >
                                                                <PhoneInput
                                                                    inputStyle={{
                                                                        width: '100%',
                                                                        height: '48px',
                                                                        fontSize: '14px',
                                                                        paddingLeft: '48px',
                                                                        borderRadius: '4px',
                                                                        border: 0,
                                                                        fontFamily: 'Lato',
                                                                    }}
                                                                    dropdownClass={
                                                                        'mobileNumberCountryDropdown'
                                                                    }
                                                                    country="lk"
                                                                    specialLabel={''}
                                                                    value={mobileNumber}
                                                                    countryCodeEditable={false}
                                                                    enableSearch={true}
                                                                    placeholder="Enter your Mobile Number (Eg: +94712345678)"
                                                                    onChange={(e, country: any) => {
                                                                        if (!isNaN(Number(e))) {
                                                                            const Index =
                                                                                country.dialCode
                                                                                    .length;

                                                                            if (e[Index] === '0') {
                                                                                const modifiedNumber =
                                                                                    e.slice(
                                                                                        0,
                                                                                        Index
                                                                                    ) +
                                                                                    e.slice(
                                                                                        Index + 1
                                                                                    );

                                                                                setMobileNumber(
                                                                                    modifiedNumber
                                                                                );
                                                                            } else {
                                                                                setMobileNumber(e);
                                                                            }
                                                                            setIsMobileError(false);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="mb-2 md:mb-4 text-center">
                                                            {isMobileError ? (
                                                                <div className="text-red-500 text-xs">
                                                                    {registerMobileResponse ||
                                                                        'Invalid Phone number!'}
                                                                </div>
                                                            ) : null}

                                                            <div
                                                                className={`mx-12 my-2 ${
                                                                    isMobileError
                                                                        ? 'mb-2 md:mb-2'
                                                                        : 'mb-4 md:mb-8'
                                                                }`}
                                                            >
                                                                <input
                                                                    className="styled-checkbox"
                                                                    id={`styled-checkbox-2`}
                                                                    type="checkbox"
                                                                    checked={termConditionReg}
                                                                    onClick={() => {
                                                                        if (
                                                                            termConditionReg ===
                                                                            false
                                                                        ) {
                                                                            setTermConditionReg(
                                                                                true
                                                                            );
                                                                        } else {
                                                                            setTermConditionReg(
                                                                                false
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                                <label
                                                                    className="whitespace-nowrap"
                                                                    htmlFor={`styled-checkbox-2`}
                                                                ></label>
                                                                I agree to the
                                                                <span
                                                                    className="pl-1 underline cursor-pointer"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            '/termsAndConditions',
                                                                            '_blank'
                                                                        );
                                                                    }}
                                                                >
                                                                    Terms and Conditions
                                                                </span>
                                                            </div>
                                                            <button
                                                                type="submit"
                                                                className={`focus:outline-none bg-purple-400 text-white text-snow-900 text-base rounded border w-40 h-10 sm:w-80 md:w-full sm:h-12 mb-4 md:mb-8 ${
                                                                    termConditionReg === true
                                                                        ? 'bg-purple-950 hover:border-transparent'
                                                                        : 'bg-purple-400 cursor-not-allowed'
                                                                }`}
                                                                style={{
                                                                    boxShadow:
                                                                        '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                                }}
                                                                disabled={
                                                                    isNaN(mobileNumber) ||
                                                                    termConditionReg === false
                                                                }
                                                                onClick={() => {
                                                                    submitMobileLoggin();
                                                                }}
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="my-3 md:my-6">
                                                            <label
                                                                className={`block text-sm font-medium leading-149 mb-2 ${
                                                                    isMobileError
                                                                        ? 'text-red-500'
                                                                        : ''
                                                                }`}
                                                                htmlFor={'phoneNumber'}
                                                            >
                                                                OTP Verification code
                                                            </label>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                                className={`border-b flex flex-row ${
                                                                    isMobileError
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-600'
                                                                }`}
                                                            >
                                                                {/* <OtpInput
                                                            inputStyle={{
                                                                width: '3rem',
                                                                height: '3rem',
                                                                margin: '0 1rem',
                                                                fontSize: '2rem',
                                                                borderRadius: 4,
                                                                border: '0px',
                                                            }}
                                                            value={OTPCode}
                                                            onChange={e => {
                                                                setOTPCode(e.target.value);
                                                            }}
                                                            numInputs={4}
                                                            separator={<span>-</span>}
                                                        /> */}
                                                                <OtpInput
                                                                    value={OTPCode}
                                                                    onChange={otp => {
                                                                        if (!isNaN(Number(otp))) {
                                                                            setOTPCode(otp);
                                                                        }
                                                                    }}
                                                                    separator={
                                                                        <span>
                                                                            <strong>-</strong>
                                                                        </span>
                                                                    }
                                                                    shouldAutoFocus={true}
                                                                    inputStyle={{
                                                                        width: '3rem',
                                                                        height: '3rem',
                                                                        margin: '0 1.5rem 0.5rem',
                                                                        borderRadius: 4,
                                                                        border: '1px solid rgba(0,0,0,1)',
                                                                    }}
                                                                />
                                                            </div>
                                                            {
                                                                // eslint-disable-next-line eqeqeq
                                                                OTPError === true ? (
                                                                    <div
                                                                        className="sm:w-1/2 md:w-full bg-red-100 shadow-xs mt-2"
                                                                        style={{
                                                                            borderRadius: 2,
                                                                            minWidth: 242,
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="flex flex-row "
                                                                            style={{
                                                                                height: 40,
                                                                                padding: 9,
                                                                            }}
                                                                        >
                                                                            <span className="ml-2 font-semibold text-sm">
                                                                                Invalid OTP
                                                                                verification codes
                                                                            </span>
                                                                            <span
                                                                                onClick={() => {
                                                                                    submitMobileLoggin();
                                                                                }}
                                                                                className="ml-2 font-semibold text-red-500 text-sm underline cursor-pointer"
                                                                            >
                                                                                Resend code
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <p className="mt-2 text-sm text-center">
                                                                        {/* <span
                                                                    id="countdown"
                                                                    className="text-purple-950 mx-1"
                                                                >
                                                                    {timeLeft.toString()} seconds
                                                                </span>{' '} */}
                                                                        Did not receive the
                                                                        verification yet?
                                                                        <span
                                                                            onClick={() => {
                                                                                // createAccountMobie();
                                                                                // dispatch(
                                                                                //     generateOTP(
                                                                                //         mobileNumber
                                                                                //     )
                                                                                // );
                                                                                submitMobileLoggin();
                                                                            }}
                                                                            className="ml-2 text-purple-950 hover:underline cursor-pointer"
                                                                        >
                                                                            Resend code
                                                                        </span>
                                                                    </p>
                                                                )
                                                            }
                                                        </div>

                                                        {registerMobileResponse ? (
                                                            <div
                                                                className="sm:w-1/2 md:w-full sm:mt-0 bg-red-100 shadow-xs mb-2"
                                                                style={{
                                                                    borderRadius: 2,
                                                                    minWidth: 242,
                                                                }}
                                                            >
                                                                <div
                                                                    className="text-center"
                                                                    style={{ padding: 9 }}
                                                                >
                                                                    <span className="font-semibold text-sm ">
                                                                        {ErrorMsg}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : null}

                                                        <div className="text-center">
                                                            <div
                                                                className={`mx-12 ${
                                                                    OTPError
                                                                        ? 'mb-4 md:mb-8'
                                                                        : 'mb-4 md:mb-8'
                                                                }`}
                                                            >
                                                                <input
                                                                    className="styled-checkbox"
                                                                    id={`styled-checkbox-3`}
                                                                    type="checkbox"
                                                                    checked={termConditionReg}
                                                                    onClick={() => {
                                                                        if (
                                                                            termConditionReg ===
                                                                            false
                                                                        ) {
                                                                            setTermConditionReg(
                                                                                true
                                                                            );
                                                                        } else {
                                                                            setTermConditionReg(
                                                                                false
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                                <label
                                                                    className="whitespace-nowrap"
                                                                    htmlFor={`styled-checkbox-3`}
                                                                ></label>
                                                                I agree to the
                                                                <span
                                                                    className="pl-1 underline cursor-pointer"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            '/termsAndConditions',
                                                                            '_blank'
                                                                        );
                                                                    }}
                                                                >
                                                                    Terms and Conditions
                                                                </span>
                                                            </div>
                                                            <button
                                                                type="submit"
                                                                className={`focus:outline-none bg-purple-400 text-white text-snow-900 text-base rounded border hover:border-transparent w-40 h-10 sm:w-80 md:w-full sm:h-12 mb-4 md:mb-8 ${
                                                                    !isNaN(mobileNumber)
                                                                        ? 'bg-purple-950'
                                                                        : ''
                                                                }`}
                                                                style={{
                                                                    boxShadow:
                                                                        '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                                }}
                                                                disabled={isNaN(mobileNumber)}
                                                                onClick={() => {
                                                                    // eslint-disable-next-line eqeqeq
                                                                    createAccountMobie();
                                                                }}
                                                            >
                                                                Create Account
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : null}
                                        <div className="text-purple-950 text-sm font-semibold text-center mb-3 md:text-base md:mb-4">
                                            Already have an account?{' '}
                                            <span
                                                className="text-purple-950 cursor-pointer font-semibold"
                                                role="presentation"
                                                onClick={() => {
                                                    history.push('/signin');
                                                }}
                                            >
                                                <u> Sign In</u>
                                            </span>
                                        </div>
                                        <div className="flex-col sm:flex w-full mb-6 md:mb-4 mt-8 md:mt-6">
                                            <a
                                                className="sm:w-1/2 md:w-full border shadow-xs cursor-pointer"
                                                style={{
                                                    borderRadius: '4px',
                                                    // minWidth: 242,
                                                    background: '#DE4A39',
                                                }}
                                                href={`${API_URL}/oauth2/authorize/google?redirect_uri=${window.location.origin}/oauth2/redirect`}
                                            >
                                                <div
                                                    role="presentation"
                                                    className="flex flex-row items-center"
                                                    style={{ height: 50, padding: '27px' }}
                                                >
                                                    <img
                                                        src="/assets/signupGoogleIcon.png"
                                                        alt="signup-Google-icon"
                                                        className="w-5 h-5 mr-2 "
                                                    />
                                                    <span className="text-white text-sm ml-4">
                                                        Continue with Google
                                                    </span>
                                                </div>
                                            </a>

                                            <a
                                                className="sm:w-1/2 md:w-full mt-4 border shadow-xs cursor-pointer"
                                                style={{
                                                    borderRadius: '4px',
                                                    // minWidth: 242,
                                                    background: '#4267B2',
                                                }}
                                                href={`${API_URL}/oauth2/authorize/facebook?redirect_uri=${window.location.origin}/oauth2/redirect`}
                                            >
                                                <div
                                                    role="presentation"
                                                    className="flex flex-row items-center"
                                                    style={{ height: 50, padding: '27px' }}
                                                >
                                                    <img
                                                        src="/assets/signupFacebookIcon.png"
                                                        alt="signup-Google-icon"
                                                        className="w-5 h-5 mr-2 "
                                                    />
                                                    <span className="text-white text-sm ml-4">
                                                        Continue with Facebook
                                                    </span>
                                                </div>
                                            </a>

                                            <a
                                                className="sm:w-1/2 mt-4 md:w-full border shadow-xs cursor-pointer"
                                                style={{
                                                    borderRadius: '4px',
                                                    // minWidth: 242,
                                                    background: '#2867B2',
                                                }}
                                                href={`${API_URL}/oauth2/authorize/linkedin?redirect_uri=${window.location.origin}/oauth2/redirect`}
                                            >
                                                <div
                                                    role="presentation"
                                                    className="flex flex-row items-center"
                                                    style={{ height: 50, padding: '27px' }}
                                                >
                                                    <img
                                                        src="/assets/signupLinkedInIcon.png"
                                                        alt="signup-Google-icon"
                                                        className="w-5 h-5 mr-2 "
                                                    />
                                                    <span className="text-white text-sm ml-4">
                                                        Continue with LinkedIn
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // Confirm email text
                        <div>
                            <>
                                <div
                                    className="md:mt-11"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div>
                                        <div className="text-3xl-2 font-semibold">
                                            Confirm your email address
                                        </div>
                                    </div>
                                </div>
                                <div className="forgotContainer mt-2 md:mb-11">
                                    <p>
                                        We have sent a mail to “
                                        <span className="text-purple-950 font-semibold">
                                            {verifyEmail}
                                        </span>
                                        ”. To continue your account creation, please click on the
                                        link sent to you and confirm your email.
                                    </p>
                                    <div
                                        className="mt-2 text-purple-950 hover:underline cursor-pointer text-center"
                                        onClick={() => {
                                            dispatch(resendEmailVerificationWeb(verifyEmail));
                                            setResendEmailCheck(!resendEmailCheck);
                                        }}
                                    >
                                        {emailResent ? <Loader /> : 'Resend confirm email address'}
                                    </div>

                                    {emailResentSuccess ? (
                                        <div className="mt-2 flex justify-center text-center text-green-400">
                                            <img
                                                src="/assets/tick.png"
                                                className="mr-2 w-4 h-4 mt-1"
                                                alt=""
                                            />
                                            Confirmation email is resent!
                                        </div>
                                    ) : null}
                                    {emailResentError ? (
                                        <div className="mt-2 text-center text-red-400">
                                            {registerErrorMessage}
                                        </div>
                                    ) : null}
                                    {/* <button
                                className="focus:outline-none bg-purple-950 text-white text-snow-900 text-base rounded border hover:border-transparent w-40 h-10 sm:w-80 md:w-full sm:h-12 mb-4"
                                style={{
                                    boxShadow: '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                }}
                                onClick={() => {
                                    dispatch(resendEmailVerificationWeb(verifyEmail));
                                }}
                            >
                                {EmailResent ? <Loader /> : 'Resend confirm email address'}
                            </button> */}
                                </div>
                            </>
                        </div>
                    )}
                </>
            ) : (
                <>
                    {emailSent === false ? (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <div>
                                    <div className="text-lg font-bold font-manrope">
                                        Create Account
                                    </div>
                                </div>
                            </div>
                            <div className="signInContainer">
                                <div className="flex flex-row mt-4">
                                    <div
                                        className={`${
                                            selectedTab === 'byEmail'
                                                ? 'border-b-2 text-purple-950 border-purple-950'
                                                : ''
                                        } mr-6 cursor-pointer text-xs`}
                                        onClick={() => {
                                            setSelectedTab('byEmail');
                                        }}
                                    >
                                        By Email
                                    </div>
                                    <div
                                        className={`${
                                            selectedTab === 'byPhone'
                                                ? 'border-b-2 text-purple-950 border-purple-950'
                                                : ''
                                        } mr-4 cursor-pointer text-xs`}
                                        onClick={() => {
                                            setSelectedTab('byPhone');
                                        }}
                                    >
                                        By Phone
                                    </div>
                                </div>

                                <div>
                                    <div className="pb-8 pt-2">
                                        {selectedTab === 'byEmail' ? (
                                            <div>
                                                <Formik
                                                    initialValues={{
                                                        emailAddress: '',
                                                        password: '',
                                                        firstName: '',
                                                    }}
                                                    validationSchema={validationSchema}
                                                    onSubmit={async ({
                                                        emailAddress,
                                                        password,
                                                        firstName,
                                                    }) => {
                                                        setHasLoggedIn(true);
                                                        dispatch(
                                                            registerWeb(
                                                                emailAddress,
                                                                password,
                                                                firstName
                                                            )
                                                        );
                                                        setVerifyEmail(emailAddress);
                                                    }}
                                                >
                                                    {({
                                                        handleChange,
                                                        handleSubmit,
                                                        values,
                                                        errors,
                                                        touched,
                                                    }) => (
                                                        <form
                                                            onSubmit={event => {
                                                                event.preventDefault();
                                                                handleSubmit();
                                                            }}
                                                        >
                                                            <div className="mb-6 md:mb-4 mt-6 md:mt-4">
                                                                <div className="flex flex-row items-center gap-x-2 mb-2">
                                                                    <SmallFontLabel
                                                                        className={`${
                                                                            errors.emailAddress &&
                                                                            touched.emailAddress
                                                                                ? 'text-red-500'
                                                                                : emailHelpText
                                                                                ? ''
                                                                                : ''
                                                                        }`}
                                                                        htmlFor={'email'}
                                                                    >
                                                                        Email Address
                                                                    </SmallFontLabel>
                                                                    <HiIcons.HiQuestionMarkCircle
                                                                        onClick={() => {
                                                                            setEmailHelpText(
                                                                                !emailHelpText
                                                                            );
                                                                        }}
                                                                        className="cursor-pointer"
                                                                    />
                                                                    {emailHelpText ? (
                                                                        <div
                                                                            onMouseOverCapture={() => {
                                                                                setEmailHelpText(
                                                                                    true
                                                                                );
                                                                                // setProfileDropdownVisible(false);
                                                                            }}
                                                                            onMouseLeave={() =>
                                                                                setEmailHelpText(
                                                                                    false
                                                                                )
                                                                            }
                                                                            className="border-2 rounded-md absolute z-50 px-4 w-56 py-2 ml-24 text-xs bg-white"
                                                                            style={{
                                                                                boxShadow:
                                                                                    '0px 0px 10px 1px rgba(197, 203, 220, 0.32)',
                                                                            }}
                                                                        >
                                                                            If you don’t have an
                                                                            email already,{' '}
                                                                            <span className="border-b-2 border-gray-400 cursor-pointer">
                                                                                <a
                                                                                    target="_blank"
                                                                                    href={
                                                                                        emailCreationURL
                                                                                    }
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    Click Here
                                                                                </a>
                                                                            </span>{' '}
                                                                            to learn more.
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                                <input
                                                                    id="email"
                                                                    type="email"
                                                                    placeholder="Enter Email"
                                                                    className={`focus:outline-none w-full pb-2 md:pb-3 border-b text-sm focus:border-blue-900 ${
                                                                        errors.emailAddress &&
                                                                        touched.emailAddress
                                                                            ? 'border-red-600'
                                                                            : ''
                                                                    }`}
                                                                    onChange={handleChange(
                                                                        'emailAddress'
                                                                    )}
                                                                    value={values.emailAddress}
                                                                />
                                                                {errors.emailAddress &&
                                                                touched.emailAddress ? (
                                                                    <div className="text-red-500 text-xs mt-1">
                                                                        {errors.emailAddress}
                                                                    </div>
                                                                ) : null}
                                                            </div>

                                                            <div className="mb-6 md:mb-4">
                                                                <div className="mb-2">
                                                                    <SmallFontLabel
                                                                        className={`${
                                                                            errors.firstName &&
                                                                            touched.firstName
                                                                                ? 'text-red-500'
                                                                                : ''
                                                                        }`}
                                                                        htmlFor={'firstName'}
                                                                    >
                                                                        First Name
                                                                    </SmallFontLabel>
                                                                </div>
                                                                <input
                                                                    id="firstName"
                                                                    type="text"
                                                                    placeholder="Enter First Name"
                                                                    className={`focus:outline-none w-full pb-2 md:pb-3 border-b text-sm focus:border-blue-900 ${
                                                                        errors.firstName &&
                                                                        touched.firstName
                                                                            ? 'border-red-600'
                                                                            : ''
                                                                    }`}
                                                                    onChange={handleChange(
                                                                        'firstName'
                                                                    )}
                                                                    value={values.firstName}
                                                                />
                                                                {errors.firstName &&
                                                                touched.firstName ? (
                                                                    <div className="text-red-500 text-xs mt-1">
                                                                        {errors.firstName}
                                                                    </div>
                                                                ) : null}
                                                            </div>

                                                            <div>
                                                                <div>
                                                                    <div className="mb-2">
                                                                        <SmallFontLabel
                                                                            className={`${
                                                                                errors.password &&
                                                                                touched.password
                                                                                    ? 'text-red-500'
                                                                                    : ''
                                                                            }`}
                                                                            htmlFor={'password'}
                                                                        >
                                                                            Password
                                                                        </SmallFontLabel>
                                                                    </div>

                                                                    <div className="relative">
                                                                        <input
                                                                            className={`focus:outline-none w-full pb-2 md:pb-3 border-b focus:border-blue-900 ${
                                                                                errors.password &&
                                                                                touched.password
                                                                                    ? 'border-red-600'
                                                                                    : ''
                                                                            } text-sm`}
                                                                            id="password"
                                                                            type={
                                                                                showPassword
                                                                                    ? 'text'
                                                                                    : 'password'
                                                                            }
                                                                            placeholder="Enter Password"
                                                                            onChange={handleChange(
                                                                                'password'
                                                                            )}
                                                                            value={values.password}
                                                                        />
                                                                        <div className="absolute inset-y-0 right-0 pt-1 pr-3 flex text-base item-center">
                                                                            {values.password
                                                                                .length > 0 ? (
                                                                                <>
                                                                                    <img
                                                                                        src="/assets/passwordShowIcon.png"
                                                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                                            showPassword
                                                                                                ? 'block'
                                                                                                : 'hidden'
                                                                                        }`}
                                                                                        onClick={
                                                                                            passwordShow
                                                                                        }
                                                                                        alt="show-password"
                                                                                        role="presentation"
                                                                                        draggable="false"
                                                                                    />
                                                                                    <img
                                                                                        src="/assets/passwordHideIcon.png"
                                                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                                            showPassword
                                                                                                ? 'hidden'
                                                                                                : 'block'
                                                                                        }`}
                                                                                        alt="hide-password"
                                                                                        role="presentation"
                                                                                        onClick={
                                                                                            passwordShow
                                                                                        }
                                                                                        draggable="false"
                                                                                    />
                                                                                </>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                    {errors.password &&
                                                                    touched.password ? (
                                                                        <div className="text-red-500 text-xs mt-1">
                                                                            {errors.password}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>

                                                            <div
                                                                className={`my-8 flex items-center text-xs`}
                                                            >
                                                                <input
                                                                    className="styled-checkbox"
                                                                    id={`styled-checkbox-1`}
                                                                    type="checkbox"
                                                                    checked={termCondition}
                                                                    onClick={() => {
                                                                        if (
                                                                            termCondition === false
                                                                        ) {
                                                                            setTermCondition(true);
                                                                        } else {
                                                                            setTermCondition(false);
                                                                        }
                                                                    }}
                                                                />
                                                                <label
                                                                    className="whitespace-nowrap"
                                                                    htmlFor={`styled-checkbox-1`}
                                                                ></label>
                                                                I agree to the
                                                                <span
                                                                    className="pl-1 underline cursor-pointer"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            '/termsAndConditions',
                                                                            '_blank'
                                                                        );
                                                                    }}
                                                                >
                                                                    Terms and Conditions
                                                                </span>
                                                            </div>

                                                            {invalidLogin && registerError ? (
                                                                <div
                                                                    className="w-full  bg-red-100 shadow-xs mb-4"
                                                                    style={{
                                                                        borderRadius: 2,
                                                                    }}
                                                                >
                                                                    <div className="flex flex-row py-2 px-3 justify-center">
                                                                        <span className="font-semibold text-xs">
                                                                            {registerError}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ) : null}

                                                            <div className="mb-8 text-center">
                                                                <button
                                                                    type="submit"
                                                                    className={`text-white font-semibold rounded border w-full h-12 ${
                                                                        termCondition === true
                                                                            ? 'bg-purple-950 hover:border-transparent'
                                                                            : 'bg-purple-400 cursor-not-allowed'
                                                                    }`}
                                                                    style={{
                                                                        boxShadow:
                                                                            '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                                    }}
                                                                    disabled={
                                                                        termCondition === false
                                                                    }
                                                                >
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        'Register'
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    )}
                                                </Formik>
                                            </div>
                                        ) : selectedTab === 'byPhone' ? (
                                            <div>
                                                {mobileCodeSent === false ? (
                                                    <div>
                                                        <div className="mb-6 mt-6 md:mt-4">
                                                            <SmallFontLabel
                                                                className={`block font-medium leading-149 mb-2 ${
                                                                    isMobileError
                                                                        ? 'text-red-500'
                                                                        : ''
                                                                }`}
                                                                htmlFor={'phoneNumber'}
                                                            >
                                                                Phone Number
                                                            </SmallFontLabel>
                                                            <div
                                                                className={`border-b flex flex-row ${
                                                                    isMobileError
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-600'
                                                                }`}
                                                            >
                                                                <PhoneInput
                                                                    inputStyle={{
                                                                        width: '100%',
                                                                        height: '48px',
                                                                        fontSize: '14px',
                                                                        paddingLeft: '48px',
                                                                        borderRadius: '4px',
                                                                        border: 0,
                                                                        fontFamily: 'Lato',
                                                                    }}
                                                                    dropdownClass={
                                                                        'mobileNumberCountryDropdown'
                                                                    }
                                                                    country="lk"
                                                                    specialLabel={''}
                                                                    value={mobileNumber}
                                                                    countryCodeEditable={false}
                                                                    enableSearch={true}
                                                                    placeholder="Enter your Mobile Number (Eg: +94712345678)"
                                                                    onChange={(e, country: any) => {
                                                                        if (!isNaN(Number(e))) {
                                                                            const Index =
                                                                                country.dialCode
                                                                                    .length;

                                                                            if (e[Index] === '0') {
                                                                                const modifiedNumber =
                                                                                    e.slice(
                                                                                        0,
                                                                                        Index
                                                                                    ) +
                                                                                    e.slice(
                                                                                        Index + 1
                                                                                    );

                                                                                setMobileNumber(
                                                                                    modifiedNumber
                                                                                );
                                                                            } else {
                                                                                setMobileNumber(e);
                                                                            }
                                                                            setIsMobileError(false);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="mb-4">
                                                            {isMobileError ? (
                                                                <div className="text-red-500 text-xs mb-4">
                                                                    {registerMobileResponse ||
                                                                        'Invalid Phone number!'}
                                                                </div>
                                                            ) : null}

                                                            <div
                                                                className={`my-2 ${
                                                                    isMobileError ? 'mb-4' : 'mb-6'
                                                                } text-xs flex items-center`}
                                                            >
                                                                <input
                                                                    className="styled-checkbox"
                                                                    id={`styled-checkbox-2`}
                                                                    type="checkbox"
                                                                    checked={termConditionReg}
                                                                    onClick={() => {
                                                                        if (
                                                                            termConditionReg ===
                                                                            false
                                                                        ) {
                                                                            setTermConditionReg(
                                                                                true
                                                                            );
                                                                        } else {
                                                                            setTermConditionReg(
                                                                                false
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                                <label
                                                                    className="whitespace-nowrap"
                                                                    htmlFor={`styled-checkbox-2`}
                                                                ></label>
                                                                I agree to the
                                                                <span
                                                                    className="pl-1 underline cursor-pointer"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            '/termsAndConditions',
                                                                            '_blank'
                                                                        );
                                                                    }}
                                                                >
                                                                    Terms and Conditions
                                                                </span>
                                                            </div>
                                                            <button
                                                                type="submit"
                                                                className={`focus:outline-none bg-purple-400 text-white text-snow-900 text-base rounded border w-full h-12 mb-6 ${
                                                                    termConditionReg === true
                                                                        ? 'bg-purple-950 hover:border-transparent'
                                                                        : 'bg-purple-400 cursor-not-allowed'
                                                                }`}
                                                                style={{
                                                                    boxShadow:
                                                                        '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                                }}
                                                                disabled={
                                                                    isNaN(mobileNumber) ||
                                                                    termConditionReg === false
                                                                }
                                                                onClick={() => {
                                                                    submitMobileLoggin();
                                                                }}
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="my-6">
                                                            <SmallFontLabel
                                                                className={`block font-medium leading-149 mb-2 ${
                                                                    isMobileError
                                                                        ? 'text-red-500'
                                                                        : ''
                                                                }`}
                                                                htmlFor={'phoneNumber'}
                                                            >
                                                                OTP Verification code
                                                            </SmallFontLabel>
                                                            <div
                                                                className={`border-b flex flex-row ${
                                                                    isMobileError
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-600'
                                                                }`}
                                                            >
                                                                <OtpInput
                                                                    value={OTPCode}
                                                                    onChange={otp => {
                                                                        if (!isNaN(Number(otp))) {
                                                                            setOTPCode(otp);
                                                                        }
                                                                    }}
                                                                    separator={
                                                                        <span>
                                                                            <strong>-</strong>
                                                                        </span>
                                                                    }
                                                                    shouldAutoFocus={true}
                                                                    inputStyle={{
                                                                        width: '2rem',
                                                                        height: '3rem',
                                                                        margin: '0 1.5rem 0.5rem',
                                                                        borderRadius: 4,
                                                                        border: '0px solid rgba(0,0,0,0.3)',
                                                                    }}
                                                                />
                                                            </div>
                                                            {
                                                                // eslint-disable-next-line eqeqeq
                                                                OTPError === true ? (
                                                                    <div
                                                                        className="w-full bg-red-100 shadow-xs mt-2"
                                                                        style={{
                                                                            borderRadius: 2,
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="flex flex-row justify-between"
                                                                            style={{
                                                                                height: 40,
                                                                                padding: 9,
                                                                            }}
                                                                        >
                                                                            <span className="ml-2 font-semibold text-sm">
                                                                                Invalid OTP
                                                                                verification codes
                                                                            </span>
                                                                            <span
                                                                                onClick={() => {
                                                                                    submitMobileLoggin();
                                                                                }}
                                                                                className="ml-2 font-semibold text-red-500 text-sm underline cursor-pointer"
                                                                            >
                                                                                Resend code
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <p className="mt-4 text-xs">
                                                                        Did not receive the
                                                                        verification yet?
                                                                        <span
                                                                            onClick={() => {
                                                                                submitMobileLoggin();
                                                                            }}
                                                                            className="ml-2 text-purple-950 hover:underline cursor-pointer"
                                                                        >
                                                                            Resend code
                                                                        </span>
                                                                    </p>
                                                                )
                                                            }
                                                        </div>

                                                        {registerMobileResponse ? (
                                                            <div
                                                                className="w-full bg-red-100 shadow-xs mb-2"
                                                                style={{
                                                                    borderRadius: 2,
                                                                }}
                                                            >
                                                                <div
                                                                    className="text-center"
                                                                    style={{ padding: 9 }}
                                                                >
                                                                    <span className="font-semibold text-sm ">
                                                                        {ErrorMsg}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : null}

                                                        <div className="text-center">
                                                            <div
                                                                className={`flex items-center text-xs mb-6`}
                                                            >
                                                                <input
                                                                    className="styled-checkbox"
                                                                    id={`styled-checkbox-3`}
                                                                    type="checkbox"
                                                                    checked={termConditionReg}
                                                                    onClick={() => {
                                                                        if (
                                                                            termConditionReg ===
                                                                            false
                                                                        ) {
                                                                            setTermConditionReg(
                                                                                true
                                                                            );
                                                                        } else {
                                                                            setTermConditionReg(
                                                                                false
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                                <label
                                                                    className="whitespace-nowrap"
                                                                    htmlFor={`styled-checkbox-3`}
                                                                ></label>
                                                                I agree to the
                                                                <span
                                                                    className="pl-1 underline cursor-pointer"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            '/termsAndConditions',
                                                                            '_blank'
                                                                        );
                                                                    }}
                                                                >
                                                                    Terms and Conditions
                                                                </span>
                                                            </div>
                                                            <button
                                                                type="submit"
                                                                className={`focus:outline-none bg-purple-400 text-white text-snow-900 text-base rounded border hover:border-transparent w-full h-12 mb-6 ${
                                                                    !isNaN(mobileNumber)
                                                                        ? 'bg-purple-950'
                                                                        : ''
                                                                }`}
                                                                style={{
                                                                    boxShadow:
                                                                        '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                                }}
                                                                disabled={isNaN(mobileNumber)}
                                                                onClick={() => {
                                                                    // eslint-disable-next-line eqeqeq
                                                                    createAccountMobie();
                                                                }}
                                                            >
                                                                Create Account
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : null}
                                        <div className="text-purple-950 text-sm font-semibold text-center mb-3 md:text-base md:mb-4">
                                            Already have an account?{' '}
                                            <span
                                                className="text-purple-950 cursor-pointer font-semibold"
                                                role="presentation"
                                                onClick={() => {
                                                    history.push('/signin');
                                                }}
                                            >
                                                <u> Sign In</u>
                                            </span>
                                        </div>
                                        <div className="flex flex-col w-full mb-6 mt-8">
                                            <a
                                                className="sm:w-1/2 md:w-full border shadow-xs cursor-pointer"
                                                style={{
                                                    borderRadius: '4px',

                                                    background: '#DE4A39',
                                                }}
                                                href={`${API_URL}/oauth2/authorize/google?redirect_uri=${window.location.origin}/oauth2/redirect`}
                                            >
                                                <div
                                                    role="presentation"
                                                    className="flex flex-row items-center"
                                                    style={{ height: 50, padding: '27px' }}
                                                >
                                                    <img
                                                        src="/assets/signupGoogleIcon.png"
                                                        alt="signup-Google-icon"
                                                        className="w-5 h-5 mr-2 "
                                                    />
                                                    <span className="text-white text-sm ml-4">
                                                        Continue with Google
                                                    </span>
                                                </div>
                                            </a>

                                            <a
                                                className="sm:w-1/2 md:w-full mt-4 border shadow-xs cursor-pointer"
                                                style={{
                                                    borderRadius: '4px',

                                                    background: '#4267B2',
                                                }}
                                                href={`${API_URL}/oauth2/authorize/facebook?redirect_uri=${window.location.origin}/oauth2/redirect`}
                                            >
                                                <div
                                                    role="presentation"
                                                    className="flex flex-row items-center"
                                                    style={{ height: 50, padding: '27px' }}
                                                >
                                                    <img
                                                        src="/assets/signupFacebookIcon.png"
                                                        alt="signup-Google-icon"
                                                        className="w-5 h-5 mr-2 "
                                                    />
                                                    <span className="text-white text-sm ml-4">
                                                        Continue with Facebook
                                                    </span>
                                                </div>
                                            </a>

                                            <a
                                                className="sm:w-1/2 mt-4 md:w-full border shadow-xs cursor-pointer"
                                                style={{
                                                    borderRadius: '4px',

                                                    background: '#2867B2',
                                                }}
                                                href={`${API_URL}/oauth2/authorize/linkedin?redirect_uri=${window.location.origin}/oauth2/redirect`}
                                            >
                                                <div
                                                    role="presentation"
                                                    className="flex flex-row items-center"
                                                    style={{ height: 50, padding: '27px' }}
                                                >
                                                    <img
                                                        src="/assets/signupLinkedInIcon.png"
                                                        alt="signup-Google-icon"
                                                        className="w-5 h-5 mr-2 "
                                                    />
                                                    <span className="text-white text-sm ml-4">
                                                        Continue with LinkedIn
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // Confirm email text
                        <div>
                            <>
                                <div
                                    className="mt-6"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div>
                                        <div className="text-lg font-semibold">
                                            Confirm your email address
                                        </div>
                                    </div>
                                </div>
                                <div className="forgotContainer mt-4 text-sm">
                                    <p>
                                        We have sent a mail to “
                                        <span className="text-purple-950 font-semibold">
                                            {verifyEmail}
                                        </span>
                                        ”. To continue your account creation, please click on the
                                        link sent to you and confirm your email.
                                    </p>
                                    <div
                                        className="mt-4 text-purple-950 hover:underline cursor-pointer text-center"
                                        onClick={() => {
                                            dispatch(resendEmailVerificationWeb(verifyEmail));
                                            setResendEmailCheck(!resendEmailCheck);
                                        }}
                                    >
                                        {emailResent ? <Loader /> : 'Resend confirm email address'}
                                    </div>

                                    {emailResentSuccess ? (
                                        <div className="mt-4 flex justify-center text-center text-green-400 text-xs">
                                            <img
                                                src="/assets/tick.png"
                                                className="mr-2 w-4 h-4 mt-1"
                                                alt=""
                                            />
                                            Confirmation email is resent!
                                        </div>
                                    ) : null}
                                    {emailResentError ? (
                                        <div className="mt-4 text-center text-red-400">
                                            {registerErrorMessage}
                                        </div>
                                    ) : null}
                                </div>
                            </>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
