import React, { ReactElement } from 'react';

export default function CheckedCircle(): ReactElement {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="20" cy="20" r="15" fill="white" />
            <path
                d="M20.4167 3.75C11.2167 3.75 3.75 11.2167 3.75 20.4167C3.75 29.6167 11.2167 37.0833 20.4167 37.0833C29.6167 37.0833 37.0833 29.6167 37.0833 20.4167C37.0833 11.2167 29.6167 3.75 20.4167 3.75ZM20.4167 33.75C13.0667 33.75 7.08333 27.7667 7.08333 20.4167C7.08333 13.0667 13.0667 7.08333 20.4167 7.08333C27.7667 7.08333 33.75 13.0667 33.75 20.4167C33.75 27.7667 27.7667 33.75 20.4167 33.75Z"
                fill="#4C2EC2"
            />
            <path
                d="M28 20.5C28 24.6421 24.6421 28 20.5 28C16.3579 28 13 24.6421 13 20.5C13 16.3579 16.3579 13 20.5 13C24.6421 13 28 16.3579 28 20.5Z"
                fill="#4C2EC2"
            />
        </svg>
    );
}
