import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/rootReducer';
import * as HiIcons from 'react-icons/hi';
import { clearJoinFCBMessages, joinFCBInstitute } from '../../../../../redux/rootActions';
import Loader from '../../../../../assets/svg/Loader';

import Modal from 'react-modal';
import { mobielNumberVerification } from '../../../../utils';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import useWidth from '../../../../hooks/useWidth';
import styled from 'styled-components';

const DarkenedImage = styled.img`
    --tw-brightness: brightness(0.7);
`;

const JoinSlasscomInstitute = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const JoinFCBResponce = useSelector((state: RootState) => state.joinFCB.requestResponce);
    const isSubmitLoading = useSelector((state: RootState) => state.joinFCB.isLoading);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [employeeCount, setEmployeeCount] = useState<string>('Choose');

    const [isSlasscomMember, setIsSlasscomMember] = useState<boolean>(false);

    const [showSuccessModal, setShowSuccessModal] = React.useState(false);
    const viewPortWidth = useWidth();

    const TrackEmployeeCountFilter = [
        { id: 0, val: '0-100' },
        { id: 1, val: '100-1000' },
        { id: 2, val: '1000-5000' },
        { id: 3, val: 'More than 5000' },
    ];

    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(239, 235, 255, 0.7)',
            backdropFilter: 'blur(4px)',
            overflow: 'auto',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    const customStylesMobileScreen: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(239, 235, 255, 0.7)',
            backdropFilter: 'blur(4px)',
            overflow: 'auto',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    useEffect(() => {
        if (JoinFCBResponce === 'SUCCESS') {
            setShowSuccessModal(true);
            // setTrackFilterspecializedAreaAction('Select your specialized area');
            // setDescirption('');
            dispatch(clearJoinFCBMessages());
        }
    }, [JoinFCBResponce, dispatch]);

    return (
        <>
            {viewPortWidth > 1023 ? (
                <>
                    <Modal isOpen={showSuccessModal} style={customStyles}>
                        <>
                            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-1/3 my-6 mx-auto">
                                    {/* content */}
                                    <div
                                        style={{ maxHeight: '270px' }}
                                        className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-6"
                                    >
                                        <div
                                            style={{
                                                position: 'relative',
                                                top: '-90px',
                                            }}
                                            className="flex flex-col -bottom-2"
                                        >
                                            <span className="successTick self-center"></span>
                                            <h1 className="mb-2 mt-3 text-3xl text-center font-bold">
                                                Successful
                                            </h1>
                                            <p className="text-center my-2 font-semibold">
                                                You have successfully submitted your application.
                                                Our team will review your application and will reach
                                                out to you.
                                            </p>
                                            <div className="flex justify-center mt-3">
                                                <button
                                                    onClick={() => {
                                                        setShowSuccessModal(false);
                                                        history.push('/institutes');
                                                    }}
                                                    className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                                >
                                                    Ok
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Modal>
                    <div className="flex justify-center relative">
                        <img
                            style={{
                                maxWidth: 'none',
                                width: '100%',
                            }}
                            className="w-full"
                            src={`/assets/joinSlasscomBanner2.jpg`}
                            alt="logo"
                        />
                        <div className="absolute opacity-50 bg-black w-full h-full"></div>
                        <div className="absolute text-white font-bold text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl top-20 md:top-30 lg:top-40 xl:top-50 2xl:top-60">
                            Request to partner with BRIDGE program
                        </div>
                    </div>

                    <div className="mx-44">
                        <Formik
                            initialValues={{
                                email: '',
                                companyName: '',
                                mobileNumber: '',
                                companyWebsite: null,
                                contactPerson: '',
                                employeeCount: '',
                            }}
                            validationSchema={Yup.object({
                                email: Yup.string()
                                    .trim()
                                    .email('Email must be a valid email')
                                    .required('Email address is required'),
                                companyName: Yup.string().required('Institute name is required'),
                                mobileNumber: Yup.string()
                                    .required('Mobile number is required')
                                    .min(10, 'Not a valid mobile number'),
                                companyWebsite: Yup.string()
                                    .nullable()
                                    .matches(
                                        // eslint-disable-next-line no-useless-escape
                                        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                                        'Enter correct url!'
                                    ),
                                contactPerson: Yup.string().required('Contact person is required'),
                                employeeCount: Yup.string().required('Choose employee count'),
                            })}
                            onSubmit={async values => {
                                dispatch(
                                    joinFCBInstitute({
                                        companyID: 1,
                                        companyName: values.companyName,
                                        companyWebsite: values.companyWebsite,
                                        contactPersonName: values.contactPerson,
                                        contactPersonEmail: values.email,
                                        employeeCount: values.employeeCount,
                                        phoneNumber: values.mobileNumber,
                                        isSlasscomMember: isSlasscomMember.toString(),
                                    })
                                );
                            }}
                        >
                            {({
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                values,
                                errors,
                                touched,
                            }) => (
                                <div className="flex justify-center items-center w-full">
                                    <div className="mt-12 flex flex-col ">
                                        <div className="bg-purple-85 rounded  flex justify-between">
                                            <div className="flex items-center  py-3 px-16">
                                                <div className=" text-justify text-purple-950 font-semibold">
                                                    If you are not a SLASSCOM member institute,
                                                    please request here to become a partner. We will
                                                    evaluate your institute profile and contact you.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-row mt-4">
                                            <div className="flex flex-col w-full ">
                                                <label
                                                    className="flex flex-row my-1"
                                                    htmlFor="tab_1"
                                                >
                                                    Institute Name
                                                    <span className="text-red-600">&nbsp;*</span>
                                                </label>
                                                <input
                                                    className={`pl-2 py-1 h-12 ${
                                                        errors.companyName && touched.companyName
                                                            ? 'inputFieldErrorThickness'
                                                            : 'inputFieldThickness'
                                                    }`}
                                                    type="text"
                                                    id="tab_1"
                                                    value={values.companyName}
                                                    placeholder="Institute Name"
                                                    onChange={e => {
                                                        setFieldValue(
                                                            'companyName',
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                                {errors.companyName && touched.companyName ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {errors.companyName}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="flex flex-col w-full ml-8">
                                                <label
                                                    className="flex flex-row my-1"
                                                    htmlFor="tab_1"
                                                >
                                                    Contact Person Email
                                                    <span className="text-red-600">&nbsp;*</span>
                                                </label>
                                                <input
                                                    className={`pl-2 py-1 h-12 ${
                                                        errors.email && touched.email
                                                            ? 'inputFieldErrorThickness'
                                                            : 'inputFieldThickness'
                                                    }`}
                                                    type="text"
                                                    id="tab_1"
                                                    value={values.email}
                                                    placeholder="Contact Person Email"
                                                    onChange={e => {
                                                        setFieldValue('email', e.target.value);
                                                    }}
                                                />
                                                {errors.email && touched.email ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {errors.email}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="flex flex-row mt-4">
                                            <div className="flex flex-col my-1 w-full">
                                                <label
                                                    className="flex flex-col my-1"
                                                    htmlFor="tab_1"
                                                >
                                                    Institute Website URL
                                                </label>
                                                <input
                                                    className="inputFieldThickness rounded pl-2 py-1 h-12"
                                                    type="text"
                                                    id="tab_1"
                                                    value={values.companyWebsite}
                                                    placeholder="Institute Website"
                                                    onChange={e => {
                                                        setFieldValue(
                                                            'companyWebsite',
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                                {errors.companyWebsite && touched.companyWebsite ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {errors.companyWebsite}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="flex flex-col my-1 ml-8 w-full">
                                                <label
                                                    className="flex flex-row my-1"
                                                    htmlFor="tab_1"
                                                >
                                                    Institute Student Count
                                                    <span className="text-red-600">&nbsp;*</span>
                                                </label>
                                                <div
                                                    className={`flex relative cursor-pointer items-center focus:outline-none h-12 md:p-2 text-sm focus:border-blue-900 ${
                                                        errors.employeeCount &&
                                                        touched.employeeCount
                                                            ? 'inputFieldErrorThickness'
                                                            : 'inputFieldThickness'
                                                    } `}
                                                >
                                                    <div
                                                        className="flex justify-between w-full"
                                                        onClick={() => setDropdownVisible(true)}
                                                    >
                                                        <div
                                                            className={` font-medium pl-2 ${
                                                                errors.employeeCount &&
                                                                touched.employeeCount
                                                                    ? 'text-gray-400'
                                                                    : 'text-gray-700 font-medium'
                                                            }`}
                                                        >
                                                            {employeeCount}
                                                        </div>
                                                        <div className="">
                                                            <div className="flex flex-row">
                                                                <div className="flex flex-col">
                                                                    <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                    <HiIcons.HiChevronDown className="mr-2" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {dropdownVisible ? (
                                                        <ul
                                                            className="max-h-44 overflow-y-auto absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                            role="menu"
                                                            onMouseLeave={() =>
                                                                setDropdownVisible(false)
                                                            }
                                                        >
                                                            {TrackEmployeeCountFilter.map(
                                                                action => (
                                                                    <div
                                                                        className="p-2 pl-2 hover:bg-gray-200 "
                                                                        key={action.id}
                                                                        onClick={() => {
                                                                            setFieldValue(
                                                                                'employeeCount',
                                                                                action.val
                                                                            );
                                                                            setEmployeeCount(
                                                                                action.val
                                                                            );
                                                                            setDropdownVisible(
                                                                                false
                                                                            );
                                                                        }}
                                                                    >
                                                                        {action.val}
                                                                    </div>
                                                                )
                                                            )}
                                                        </ul>
                                                    ) : null}
                                                </div>
                                                {errors.employeeCount && touched.employeeCount ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {errors.employeeCount}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="flex flex-row mt-4">
                                            <div className="flex flex-col w-full ">
                                                <label
                                                    className="flex flex-row my-1"
                                                    htmlFor="tab_1"
                                                >
                                                    Contact Person Name
                                                    <span className="text-red-600">&nbsp;*</span>
                                                </label>
                                                <input
                                                    className={`pl-2 py-1 h-12 ${
                                                        errors.contactPerson &&
                                                        touched.contactPerson
                                                            ? 'inputFieldErrorThickness'
                                                            : 'inputFieldThickness'
                                                    }`}
                                                    type="text"
                                                    id="tab_1"
                                                    value={values.contactPerson}
                                                    placeholder="Contact Person Name"
                                                    onChange={e => {
                                                        setFieldValue(
                                                            'contactPerson',
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                                {errors.contactPerson && touched.contactPerson ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {errors.contactPerson}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="flex flex-col my-1 ml-8 w-full">
                                                <label
                                                    className="flex flex-row my-1"
                                                    htmlFor="tab_1"
                                                >
                                                    Contact Person Phone Number
                                                    <span className="text-red-600">&nbsp;*</span>
                                                </label>
                                                <div
                                                    className={`${
                                                        errors.mobileNumber && touched.mobileNumber
                                                            ? 'inputFieldErrorThickness'
                                                            : 'inputFieldThickness'
                                                    }`}
                                                >
                                                    <PhoneInput
                                                        inputStyle={{
                                                            width: '100%',
                                                            height: '48px',
                                                            fontSize: '14px',
                                                            paddingLeft: '48px',
                                                            borderRadius: '4px',
                                                            border: 0,
                                                            fontFamily: 'Lato',
                                                        }}
                                                        dropdownClass={
                                                            'mobileNumberCountryDropdown'
                                                        }
                                                        specialLabel={''}
                                                        country="lk"
                                                        value={values.mobileNumber}
                                                        placeholder="Enter your Mobile Number (Eg: 94712345678)"
                                                        onChange={e => {
                                                            setFieldValue('mobileNumber', e);
                                                        }}
                                                    />
                                                </div>
                                                {errors.mobileNumber && touched.mobileNumber ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {errors.mobileNumber}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <button
                                                onClick={() => {
                                                    history.push('/institutes');
                                                }}
                                                className={` my-10 rounded font-semibold w-48 h-10 px-3 ml-1 border-2 hover:text-white hover:bg-purple-950 cursor-pointer text-purple-950 border-purple-950`}
                                            >
                                                Back
                                            </button>
                                            <button
                                                type="submit"
                                                onClick={() => {
                                                    handleSubmit();
                                                }}
                                                className={` my-10 rounded font-semibold w-48 h-10 px-3 ml-1 border-2 text-white bg-purple-950 cursor-pointer hover:text-purple-950 hover:bg-white border-purple-950`}
                                            >
                                                {isSubmitLoading ? <Loader /> : 'Submit'}
                                            </button>
                                        </div>
                                    </div>

                                    <div></div>
                                </div>
                            )}
                        </Formik>
                    </div>
                </>
            ) : (
                <>
                    <Modal isOpen={showSuccessModal} style={customStylesMobileScreen}>
                        <>
                            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-full my-6 mx-auto">
                                    {/* content */}
                                    <div
                                        style={{ maxHeight: '300px' }}
                                        className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-6"
                                    >
                                        <div
                                            style={{
                                                position: 'relative',
                                                top: '-90px',
                                            }}
                                            className="flex flex-col -bottom-2"
                                        >
                                            <span className="successTick self-center"></span>
                                            <h1 className="mb-2 mt-3 text-3xl text-center font-bold">
                                                Successful
                                            </h1>
                                            <p className="text-center my-2 font-semibold">
                                                You have successfully submitted your application.
                                                Our team will review your application and will reach
                                                out to you.
                                            </p>
                                            <div className="flex justify-center mt-3">
                                                <button
                                                    onClick={() => {
                                                        setShowSuccessModal(false);
                                                        history.push('/institutes');
                                                    }}
                                                    className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                                >
                                                    Ok
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Modal>
                    <div className="flex justify-center relative">
                        <DarkenedImage
                            style={{
                                height: '320px',
                            }}
                            className="w-full object-cover object-center filter"
                            src={`/assets/joinSlasscomBanner2.jpg`}
                            alt="logo"
                        />
                        <div
                            className="px-5 absolute text-center text-white font-bold text-lg font-manrope"
                            style={{ top: '20%' }}
                        >
                            <div>Request to partner with BRIDGE program</div>
                            <div className="text-xs font-semibold">
                                <div className="mt-4">
                                    If you have not Partnered up with Future Careers BRIDGE yet,
                                    please request here to become a partner and train your students
                                    under the guidance of experienced IT field experts in the
                                    country.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <Formik
                            initialValues={{
                                email: '',
                                companyName: '',
                                mobileNumber: '',
                                companyWebsite: null,
                                contactPerson: '',
                                employeeCount: '',
                            }}
                            validationSchema={Yup.object({
                                email: Yup.string()
                                    .trim()
                                    .email('Email must be a valid email')
                                    .required('Email address is required'),
                                companyName: Yup.string().required('Institute name is required'),
                                mobileNumber: Yup.string()
                                    .required('Mobile number is required')
                                    .min(10, 'Not a valid mobile number'),
                                companyWebsite: Yup.string()
                                    .nullable()
                                    .matches(
                                        // eslint-disable-next-line no-useless-escape
                                        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                                        'Enter correct url!'
                                    ),
                                contactPerson: Yup.string().required('Contact person is required'),
                                employeeCount: Yup.string().required('Choose employee count'),
                            })}
                            onSubmit={async values => {
                                dispatch(
                                    joinFCBInstitute({
                                        companyID: 1,
                                        companyName: values.companyName,
                                        companyWebsite: values.companyWebsite,
                                        contactPersonName: values.contactPerson,
                                        contactPersonEmail: values.email,
                                        employeeCount: values.employeeCount,
                                        phoneNumber: values.mobileNumber,
                                        isSlasscomMember: isSlasscomMember.toString(),
                                    })
                                );
                            }}
                        >
                            {({
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                values,
                                errors,
                                touched,
                            }) => (
                                <div className="justify-center items-center w-full mt-6 flex flex-col px-4">
                                    <div className="bg-purple-85 rounded flex justify-between">
                                        <div className="flex items-center py-3 pl-4 pr-8">
                                            <div className=" text-justify text-purple-950 text-xs">
                                                If you are not a SLASSCOM member institute, please
                                                request here to become a partner. We will evaluate
                                                your institute profile and contact you.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col mt-4 w-full gap-y-2">
                                        <div className="flex flex-col w-full gap-y-1">
                                            <label
                                                className="flex flex-row my-1 text-xs font-manrope"
                                                htmlFor="tab_1"
                                            >
                                                Institute Name
                                                <span className="text-red-600">&nbsp;*</span>
                                            </label>
                                            <input
                                                className={`pl-2 py-1 h-12 ${
                                                    errors.companyName && touched.companyName
                                                        ? 'inputFieldErrorThickness'
                                                        : 'inputFieldThickness'
                                                }`}
                                                type="text"
                                                id="tab_1"
                                                value={values.companyName}
                                                placeholder="Institute Name"
                                                onChange={e => {
                                                    setFieldValue('companyName', e.target.value);
                                                }}
                                            />
                                            {errors.companyName && touched.companyName ? (
                                                <div className="text-red-500 text-xs my-1">
                                                    {errors.companyName}
                                                </div>
                                            ) : null}
                                        </div>

                                        <div className="flex flex-col w-full">
                                            <label
                                                className="flex flex-row my-1 text-xs font-manrope"
                                                htmlFor="tab_1"
                                            >
                                                Contact Person Email
                                                <span className="text-red-600">&nbsp;*</span>
                                            </label>
                                            <input
                                                className={`pl-2 py-1 h-12 ${
                                                    errors.email && touched.email
                                                        ? 'inputFieldErrorThickness'
                                                        : 'inputFieldThickness'
                                                }`}
                                                type="text"
                                                id="tab_1"
                                                value={values.email}
                                                placeholder="Contact Person Email"
                                                onChange={e => {
                                                    setFieldValue('email', e.target.value);
                                                }}
                                            />
                                            {errors.email && touched.email ? (
                                                <div className="text-red-500 text-xs my-1">
                                                    {errors.email}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="flex flex-col mt-2 w-full gap-y-2">
                                        <div className="flex flex-col my-1 w-full gap-y-1">
                                            <label
                                                className="flex flex-col text-xs font-manrope"
                                                htmlFor="tab_1"
                                            >
                                                Institute Website
                                            </label>
                                            <input
                                                className="inputFieldThickness rounded pl-2 py-1 h-12"
                                                type="text"
                                                id="tab_1"
                                                value={values.companyWebsite}
                                                placeholder="Institute Website"
                                                onChange={e => {
                                                    setFieldValue('companyWebsite', e.target.value);
                                                }}
                                            />
                                            {errors.companyWebsite && touched.companyWebsite ? (
                                                <div className="text-red-500 text-xs my-1">
                                                    {errors.companyWebsite}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="flex flex-col my-1 w-full gap-y-1">
                                            <label
                                                className="flex flex-row  text-xs font-manrope"
                                                htmlFor="tab_1"
                                            >
                                                Institute Student Count
                                                <span className="text-red-600">&nbsp;*</span>
                                            </label>
                                            <div
                                                className={`flex relative cursor-pointer items-center focus:outline-none h-12 md:p-2 text-sm focus:border-blue-900 ${
                                                    errors.employeeCount && touched.employeeCount
                                                        ? 'inputFieldErrorThickness'
                                                        : 'inputFieldThickness'
                                                } `}
                                            >
                                                <div
                                                    className="flex justify-between w-full"
                                                    onClick={() => setDropdownVisible(true)}
                                                >
                                                    <div
                                                        className={` font-medium pl-2 ${
                                                            errors.employeeCount &&
                                                            touched.employeeCount
                                                                ? 'text-gray-400'
                                                                : 'text-gray-700 font-medium'
                                                        }`}
                                                    >
                                                        {employeeCount}
                                                    </div>
                                                    <div className="">
                                                        <div className="flex flex-row">
                                                            <div className="flex flex-col">
                                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                <HiIcons.HiChevronDown className="mr-2" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {dropdownVisible ? (
                                                    <ul
                                                        className="max-h-44 overflow-y-auto absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                        role="menu"
                                                        onMouseLeave={() =>
                                                            setDropdownVisible(false)
                                                        }
                                                    >
                                                        {TrackEmployeeCountFilter.map(action => (
                                                            <div
                                                                className="p-2 pl-2 hover:bg-gray-200 "
                                                                key={action.id}
                                                                onClick={() => {
                                                                    setFieldValue(
                                                                        'employeeCount',
                                                                        action.val
                                                                    );
                                                                    setEmployeeCount(action.val);
                                                                    setDropdownVisible(false);
                                                                }}
                                                            >
                                                                {action.val}
                                                            </div>
                                                        ))}
                                                    </ul>
                                                ) : null}
                                            </div>
                                            {errors.employeeCount && touched.employeeCount ? (
                                                <div className="text-red-500 text-xs my-1">
                                                    {errors.employeeCount}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="flex flex-col mt-2 w-full gap-y-2">
                                        <div className="flex flex-col w-full gap-y-1">
                                            <label
                                                className="flex flex-row my-1 text-xs font-manrope"
                                                htmlFor="tab_1"
                                            >
                                                Contact Person Name
                                                <span className="text-red-600">&nbsp;*</span>
                                            </label>
                                            <input
                                                className={`pl-2 py-1 h-12 ${
                                                    errors.contactPerson && touched.contactPerson
                                                        ? 'inputFieldErrorThickness'
                                                        : 'inputFieldThickness'
                                                }`}
                                                type="text"
                                                id="tab_1"
                                                value={values.contactPerson}
                                                placeholder="Contact Person Name"
                                                onChange={e => {
                                                    setFieldValue('contactPerson', e.target.value);
                                                }}
                                            />
                                            {errors.contactPerson && touched.contactPerson ? (
                                                <div className="text-red-500 text-xs my-1">
                                                    {errors.contactPerson}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="flex flex-col my-1 w-full gap-y-1">
                                            <label
                                                className="flex flex-row my-1 text-xs font-manrope"
                                                htmlFor="tab_1"
                                            >
                                                Contact Person Phone Number
                                                <span className="text-red-600">&nbsp;*</span>
                                            </label>
                                            <div
                                                className={`${
                                                    errors.mobileNumber && touched.mobileNumber
                                                        ? 'inputFieldErrorThickness'
                                                        : 'inputFieldThickness'
                                                }`}
                                            >
                                                <PhoneInput
                                                    inputStyle={{
                                                        width: '100%',
                                                        height: '48px',
                                                        fontSize: '14px',
                                                        paddingLeft: '48px',
                                                        borderRadius: '4px',
                                                        border: 0,
                                                        fontFamily: 'Lato',
                                                    }}
                                                    dropdownClass={'mobileNumberCountryDropdown'}
                                                    specialLabel={''}
                                                    country="lk"
                                                    value={values.mobileNumber}
                                                    placeholder="Enter your Mobile Number (Eg: 94712345678)"
                                                    onChange={e => {
                                                        setFieldValue('mobileNumber', e);
                                                    }}
                                                />
                                            </div>
                                            {errors.mobileNumber && touched.mobileNumber ? (
                                                <div className="text-red-500 text-xs my-1">
                                                    {errors.mobileNumber}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-between w-full gap-y-3 mt-6">
                                        <button
                                            onClick={() => {
                                                history.goBack();
                                            }}
                                            className={` rounded font-semibold h-10 w-full border-2 hover:text-white hover:bg-purple-950 cursor-pointer text-purple-950 border-purple-950`}
                                        >
                                            Back
                                        </button>
                                        <button
                                            type="submit"
                                            onClick={() => {
                                                handleSubmit();
                                            }}
                                            className={` rounded font-semibold h-10 w-full border-2 text-white bg-purple-950 cursor-pointer hover:text-purple-950 hover:bg-white border-purple-950`}
                                        >
                                            {isSubmitLoading ? <Loader /> : 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Formik>
                    </div>
                </>
            )}
        </>
    );
};

export default JoinSlasscomInstitute;
