import React, { useEffect, useState } from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { s3UrlPrefix } from '../../../../../constants';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../../../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getChallenges, getTracks } from '../../../../../redux/rootActions';
import { TracksType } from '../../../../../redux/tracks/reducers';
import BackArrow from '../../../../../assets/svg/BackArrow';

interface CustomState {
    tracks: TracksType;
}

const SolveChallengeChallenges = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const trackState = location.state as CustomState;
    const trackData = useSelector((state: RootState) => state.tracks.trackData);
    const challengesData = useSelector((state: RootState) => state.challenges.challengesData);

    const [currentPage, setCurrentPage] = useState<any>(1);
    const [postPerPage, setPostPerPage] = useState<any>(10);
    const [pageNumber1, setpageNumber1] = useState<any>([]);
    const [currentPosts, setCurrentPosts] = useState<any>();
    const [selectedTrack, setSelectedTrack] = useState<any>(trackState?.tracks);
    const [filteredChallengesData, setFilteredChallengesData] = useState<any>();

    const pageNumber = [];
    let numberOfPage = 0;

    for (let i = 1; i <= Math.ceil(filteredChallengesData?.length / postPerPage); i++) {
        pageNumber.push(i);
        numberOfPage = numberOfPage + 1;
    }

    const paginate = number => {
        setCurrentPage(number);
    };

    useEffect(() => {
        setpageNumber1([]);
        for (let i = 1; i <= Math.ceil(filteredChallengesData?.length / postPerPage); i++) {
            setpageNumber1(oldArray => [...oldArray, i]);
            numberOfPage = numberOfPage + 1;
        }
    }, [currentPosts]);

    useEffect(() => {
        for (let i = 1; i <= Math.ceil(filteredChallengesData?.length / postPerPage); i++) {
            setpageNumber1([...pageNumber1, i]);
            pageNumber.push(i);
            numberOfPage = numberOfPage + 1;
        }
    }, [filteredChallengesData]);

    useEffect(() => {
        const indexOfTheLastPost = currentPage * postPerPage;
        const indexOfTheFirstPost = indexOfTheLastPost - postPerPage;
        setCurrentPosts(filteredChallengesData?.slice(indexOfTheFirstPost, indexOfTheLastPost));
    }, [challengesData, currentPage, filteredChallengesData]);

    useEffect(() => {
        if (selectedTrack === null || selectedTrack === undefined) {
            setSelectedTrack(trackState?.tracks);
        }
    }, [trackState]);

    useEffect(() => {
        if (selectedTrack === null || selectedTrack === undefined) {
            setFilteredChallengesData(challengesData);
        } else {
            setFilteredChallengesData(selectedTrack?.challenges);
        }
    }, [selectedTrack, challengesData]);
    useEffect(() => {
        dispatch(getTracks(false, 0, 100, 'PUBLISHED'));
        dispatch(getChallenges());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedTrack]);

    return (
        <div>
            <div className={`my-8 px-28`}>
                <div
                    className="flex mb-3 mt-1 cursor-pointer"
                    onClick={() => history.push(`/solve-challenges`)}
                >
                    <BackArrow size="27" />
                    <p className="text-base ml-2 font-semibold">Back</p>
                </div>
                <div className="bg-purple-85 flex justify-between rounded">
                    <div className="flex items-center py-8 px-8">
                        <div className="space-y-3">
                            <div className=" text-xl font-bold">Explore All Tracks</div>
                            <div className="text-justify ">Select a track from the list below.</div>
                            <div
                                style={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexWrap: 'wrap',
                                    gap: '16px',
                                    marginRight: '1em',
                                    flexDirection: 'row',
                                    alignContent: 'flex-start',
                                }}
                            >
                                {trackData?.map(track => (
                                    <div
                                        key={track?.trackId}
                                        onClick={() => {
                                            setSelectedTrack(track);
                                        }}
                                        className={`border-2 rounded  p-3 flex justify-center items-center text-xs cursor-pointer whitespace-nowrap ${
                                            track?.trackId === selectedTrack?.trackId
                                                ? 'bg-purple-950 text-white border-purple-950'
                                                : 'bg-white  border-purple-950 text-purple-950'
                                        }`}
                                    >
                                        {track?.trackName}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex-row justify-between mb-2 mt-6 mx-9">
                    <div className="mt-4 flex items-center">
                        <span className="font-manrope text-xl font-bold text-purple-950">
                            Selected challenges for&nbsp;
                            {selectedTrack?.trackName}
                        </span>
                        <div className="ml-2 text-xs font-normal bg-purple-100 text-purple-950 py-1 px-2 rounded-full">
                            {
                                selectedTrack?.challenges.filter(
                                    val => val.challengeStatus === 'PUBLISHED'
                                ).length
                            }
                        </div>
                    </div>
                    <div className="mt-2 pb-6">
                        {selectedTrack && selectedTrack.trackDescription}
                    </div>
                    <div
                        style={{
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            gap: '25px',
                            marginRight: '1em',
                            flexDirection: 'column',
                            alignContent: 'flex-start',
                        }}
                    >
                        {currentPosts?.map(challenge => (
                            <div key={challenge.id} className=" ">
                                <div className="flex">
                                    <img
                                        style={{ width: '180px', height: '180px' }}
                                        className="rounded-lg"
                                        src={
                                            challenge.imageLink
                                                ? `${s3UrlPrefix}${challenge.imageLink}`
                                                : `/assets/challenge-image-skeleton-square.png`
                                        }
                                        alt="profile"
                                    />
                                    <div className="ml-4 mt-2 pb-3">
                                        <div className="font-semibold text-lg ">
                                            {challenge?.challengeName}
                                        </div>
                                        <p className="bg-purple-200 text-purple-950 text-xs rounded-full px-2.5 py-2 my-2.5 w-max">
                                            {`${
                                                challenge.tags.length > 0
                                                    ? challenge?.tags?.map(tag => tag.tagName)
                                                    : 'no tags'
                                            }`}
                                        </p>
                                        <div className="">
                                            <p className="text-gray-500 text-xs mb-4 text-justify ">
                                                {/* {challenge.trackDescription} */}
                                                Description
                                            </p>
                                        </div>
                                        <button
                                            onClick={() => {
                                                history.push({
                                                    pathname: `/signin`,
                                                    state: { challenges: challenge },
                                                });
                                            }}
                                            className="border-2 border-purple-950 text-purple-950 rounded font-bold text-sm h-10 px-6 xl:px-12 ml-1"
                                        >
                                            Start learning
                                        </button>
                                    </div>
                                </div>
                                <div className=" ml-5 border-b-2 mt-3 "></div>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className={`my-6  w-full ${
                        filteredChallengesData?.length === 0 ? 'invisible' : ''
                    }`}
                >
                    <div className=" bg-purple-85 rounded-md flex justify-center py-6">
                        <div
                            onClick={() => {
                                if (currentPage !== 1) {
                                    setCurrentPage(currentPage - 1);
                                }
                            }}
                            className={`text-purple-950 font-semibold cursor-pointer mr-1 ${
                                currentPage === 1 ? 'invisible' : ''
                            }`}
                        >
                            Previous
                        </div>
                        {pageNumber1?.map(number => (
                            // eslint-disable-next-line react/jsx-key
                            <div
                                onClick={() => {
                                    paginate(number);
                                }}
                                className={`mx-2.5 rounded-md border-2 border-purple-950  font-semibold w-8 flex justify-center cursor-pointer ${
                                    number === currentPage
                                        ? 'text-white bg-purple-950'
                                        : 'text-purple-950 '
                                }`}
                            >
                                {number}
                            </div>
                        ))}
                        <div
                            onClick={() => {
                                if (currentPage !== numberOfPage) {
                                    setCurrentPage(currentPage + 1);
                                }
                            }}
                            className={`text-purple-950 font-semibold cursor-pointer ml-1 ${
                                currentPage === numberOfPage || pageNumber1?.length === 1
                                    ? 'invisible'
                                    : ''
                            }`}
                        >
                            Next
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SolveChallengeChallenges;
