import React from 'react';
import { useHistory } from 'react-router-dom';

const TermsAndConditions = () => {
    const history = useHistory();
    return (
        <div className="px-32 my-24">
            <div className="font-manrope font-bold text-2xl-2 text-purple-950 mb-14">
                Welcome to SLASSCOM! 
            </div>
            <div className="mb-16">
                These Terms and Conditions, Privacy Policy, and Job Opportunities Disclaimer (collectively, the "Agreements") govern your access and use of our platform, services, and resources (collectively, the "Platform"). By accessing or using the Platform, you agree to be bound by these Agreements. 
            </div>
            <div className="font-manrope font-bold text-2xl-2 text-purple-950 mb-14">
                1. Terms and Conditions
            </div>
            <div className="mb-16">
                <ul style={{ listStyleType: 'disc' }} className="text-xl space-y-8 ml-6">
                    <li>
                        Use of Platform: You agree to use the Platform for lawful purposes only and in accordance with these Agreements. You are responsible for maintaining the confidentiality of your account information and for restricting access to your computer.
                    </li>
                    <li>
                        User Content: You may be permitted to submit content (including learning details) to the Platform ("User Content"). You retain all ownership rights to your User Content, but by submitting it, you grant SLASSCOM a non-exclusive, royalty-free license to use, reproduce, modify, publish, and distribute your User Content on the Platform. 
                    </li>
                    <li>
                        Leaderboard: Your student ID and earned marks will be displayed publicly on the leaderboard. You can choose to make your personal information visible on your profile by enabling the option in your settings.   
                    </li>
                    <li>
                        Termination: SLASSCOM reserves the right to terminate your access to the Platform for any reason, at any time, and without notice. 
                    </li>
                </ul>
            </div>


            <div className="font-manrope font-bold text-2xl-2 text-purple-950 mb-14">
                2. Privacy Policy
            </div>
            <div className="mb-16">
                <ul style={{ listStyleType: 'disc' }} className="text-xl space-y-8 ml-6">
                    <li>
                        Information Collection: We collect information from you, including personal information, such as your learning details, when you use the Platform. We may also collect additional information from third-party sources, such as partnered companies and educational institutes. 
                    </li>
                    <li>
                        Information Use: We use your information to provide, maintain, and improve the Platform, analyze user behavior, and personalize your experience. We may also share your information (including personal information) with partnered companies and educational institutes for analysis purposes.   
                    </li>
                    <li>
                        Data Security: We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no internet transmission is completely secure, and we cannot guarantee the security of your information. 
                    </li>
                    <li>
                        Your Rights: You have certain rights regarding your information, such as the right to access, correct, and delete your personal information. You can learn more about these rights in the FAQ section (accessible on a computer). 
                    </li>
                </ul>
            </div>


            <div className="font-manrope font-bold text-2xl-2 text-purple-950 mb-14">
                3. Job Opportunities Disclaimer 
            </div>
            <div className="mb-16">
                <ul style={{ listStyleType: 'disc' }} className="text-xl space-y-8 ml-6">
                    <li>
                        Trainee Jobs: While we connect you with potential job opportunities after you complete challenges, Bootcamps, and interviews we cannot guarantee a trainee job placement. The availability of positions depends on the needs of our partnered companies at the time of your completion. 
                    </li>
                   
                </ul>
            </div>

            <div className="font-manrope font-bold text-2xl-2 text-purple-950 mb-14">
                4. FAQ Section  
            </div>
            <div className="mb-16">
                <ul style={{ listStyleType: 'disc' }} className="text-xl space-y-8 ml-6">
                    <li>
                        The FAQ section provides additional details about our policies and procedures. Please access it on a computer for a comprehensive understanding. 
                    </li>
                </ul>
            </div>

            <div className="font-manrope font-bold text-2xl-2 text-purple-950 mb-14">
                5. Updates to Agreements   
            </div>
            <div className="mb-16">
                <ul style={{ listStyleType: 'disc' }} className="text-xl space-y-8 ml-6">
                    <li>
                        We may update these Agreements at any time by posting the revised terms on the Platform. You agree to be bound by the revised terms upon their posting. We encourage you to periodically review the Agreements for changes. 
                    </li>
                </ul>
            </div>
            
            <div className="font-manrope font-bold text-2xl-2 text-purple-950 mb-14">
                6. Contact Us   
            </div>
            <div className="mb-16">
                <ul style={{ listStyleType: 'disc' }} className="text-xl space-y-8 ml-6">
                    <li>
                        If you have any questions about these Agreements, please contact us at fcb@slasscom.lk 
                        These Agreements constitute the entire agreement between you and SLASSCOM regarding your use of the Platform. If any provision of these Agreements is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall remain in full force and effect. 
                    </li>
               </ul>
            </div>


            <div
                className="rounded ml-1 mb-2 font-semibold text-white bg-purple-800 cursor-pointer h-12 w-36 flex justify-center items-center"
                onClick={() => history.push('/home')}
            >
                Go Home
            </div>
        </div>
    );
};

export default TermsAndConditions;
