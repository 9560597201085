import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import EventCalender from '../../../../../assets/svg/EventCalender';
import EventDuration from '../../../../../assets/svg/EventDuration';
import EventLocation from '../../../../../assets/svg/EventLocation';
import EventTime from '../../../../../assets/svg/EventTime';
import EventTypeSvg from '../../../../../assets/svg/EventType';
import { getPublicEventById } from '../../../../../redux/rootActions';
import { RootState } from '../../../../../redux/rootReducer';
import moment from 'moment';
import { AUTH_TOKENS } from '../../../../utils/storageWeb';
import { s3UrlPrefix } from '../../../../../constants';
import BackArrow from '../../../../../assets/svg/BackArrow';

import styled from 'styled-components';

const SmallFontDiv = styled.div`
    font-size: 10px;
    margin-bottom: 5px;
`;
interface EventIdParam {
    id: string;
}

const Event = () => {
    const eventId: EventIdParam = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const loggedIn = localStorage.getItem(AUTH_TOKENS);

    const event = useSelector((state: RootState) => state.events.publicEvent);
    const eventByIdLoading = useSelector((state: RootState) => state.events.eventByIdLoading);

    const [completedEvent, setCompletedEvent] = useState<boolean>(false);
    const [duration, setDuration] = useState<any>();

    const [joinNowEnabled, setJoinNowEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (eventId?.id) {
            dispatch(getPublicEventById(eventId.id));
        }
    }, [dispatch, eventId.id]);

    useEffect(() => {
        const newCurrentTime = new Date().getTime(); // current time
        const startTime = new Date(event?.eventStartTime).getTime();
        const endTime = new Date(event?.eventEndTime).getTime();

        if (newCurrentTime > startTime && newCurrentTime < endTime) {
            setJoinNowEnabled(true);
        } else {
            setJoinNowEnabled(false);
        }
    }, [event?.eventEndTime, event?.eventStartTime]);

    useEffect(() => {
        if (event && !eventByIdLoading) {
            let time;
            let fullDuration;
            const currentDate = new Date().getTime();
            const startTime = moment(event?.eventStartTime);
            const endTime = moment(event?.eventEndTime);
            if (startTime && endTime) {
                fullDuration = endTime.diff(startTime, 'minutes');
                if (fullDuration > 1440) {
                    const minutes = fullDuration % 60;
                    const hours = Math.round((fullDuration % 1440) / 60);
                    if (hours > 0) {
                        time = endTime.diff(startTime, 'days') + ' Days and ' + hours + ' Hours';
                        setDuration(time);
                        if (minutes > 0) {
                            time =
                                endTime.diff(startTime, 'days') +
                                ' Days, ' +
                                hours +
                                ' Hours and ' +
                                minutes +
                                ' Minutes';
                            setDuration(time);
                        }
                    } else {
                        if (minutes > 0) {
                            time =
                                endTime.diff(startTime, 'days') +
                                ' Days, ' +
                                minutes +
                                ' and Minutes';
                            setDuration(time);
                        } else {
                            time = endTime.diff(startTime, 'days') + ' Day/s';
                            setDuration(time);
                        }
                    }
                } else if (fullDuration > 60) {
                    const minutes = fullDuration % 60;
                    if (minutes > 0) {
                        time =
                            endTime.diff(startTime, 'hours') + ' Hours and ' + minutes + ' Minutes';
                        setDuration(time);
                    } else {
                        time = endTime.diff(startTime, 'hours') + ' Hour/s';
                        setDuration(time);
                    }
                } else {
                    setDuration(endTime.diff(startTime, 'minutes') + ' Minute/s');
                }
            }
            if (currentDate > new Date(event?.eventStartTime).getTime()) {
                setCompletedEvent(true);
            } else setCompletedEvent(false);
        }
    }, [event, eventByIdLoading]);

    return (
        <>
            <div className="hidden lg:block">
                <div className="flex justify-center">
                    <img
                        style={{
                            maxWidth: 'none',
                            width: '100%',
                        }}
                        className="w-full"
                        src={s3UrlPrefix + event?.eventImage || `/assets/eventbanner.png`}
                        alt="logo"
                    />
                </div>

                {event && !eventByIdLoading ? (
                    <div className="px-44">
                        <div className="mt-10">
                            <div
                                className="flex mb-5 mt-1 cursor-pointer"
                                onClick={() => history.push(`/events`)}
                            >
                                <BackArrow size="27" />
                                <p className="text-base ml-2 font-semibold  hover:underline">
                                    Back
                                </p>
                            </div>
                            <div className="text-4xl font-semibold">
                                {event?.eventName || 'Event Name'}
                            </div>
                            <div className="pl-0.5 pt-1">{event?.location || 'Event Location'}</div>
                        </div>

                        <div className="">
                            <div className="mt-4 text-xl font-semibold">Event details</div>
                            <div className="border-b-2 my-2 "></div>

                            <div key={event?.id} className="pt-2">
                                <div className="flex my-3 justify-between">
                                    <div className="flex">
                                        <EventCalender />
                                        <div className="flex py-1.5 pl-2">
                                            <div>
                                                <div className="text-purple-950">
                                                    Event Start Date/Time
                                                </div>
                                                <div className="font-semibold">
                                                    {moment(event.eventStartTime).format(
                                                        'MMM Do YYYY, hh:mm A'
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <EventCalender />
                                        <div className="flex py-1.5 pl-2">
                                            <div>
                                                <div className="text-purple-950">
                                                    Event End Date/Time
                                                </div>
                                                <div className="font-semibold">
                                                    {moment(event.eventEndTime).format(
                                                        'MMM Do YYYY, hh:mm A'
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <EventDuration />
                                        <div className="flex py-1.5 pl-2">
                                            <div>
                                                <div className="text-purple-950">Duration</div>
                                                <div className="font-semibold">
                                                    {duration || null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <EventTypeSvg />
                                        <div className="flex py-1.5 pl-2">
                                            <div>
                                                <div className="text-purple-950">Event Type</div>
                                                <div className="font-semibold">
                                                    {event?.eventType}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {event?.eventType !== 'VIRTUAL' && (
                                        <div className="flex">
                                            <EventLocation />
                                            <div className="flex py-1.5 pl-2">
                                                <div>
                                                    <div className="text-purple-950">Location</div>
                                                    <div className="font-semibold">
                                                        {event?.location}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {!completedEvent ? (
                            <div>
                                {event?.registerLink ? (
                                    <button
                                        className="w-32 my-8 mr-4 font-bold bg-purple-950 text-white rounded h-12"
                                        onClick={() => {
                                            loggedIn
                                                ? window.open(`${event?.registerLink}`, '_blank')
                                                : history.push('/signin');
                                        }}
                                    >
                                        Register Now
                                    </button>
                                ) : null}
                                {event?.eventLink ? (
                                    <button
                                        className={`w-32 my-8 mr-4 font-bold rounded h-12 border-2 ${
                                            joinNowEnabled
                                                ? 'border-purple-950 text-purple-950'
                                                : 'border-gray-300 text-gray-300 cursor-default'
                                        }`}
                                        disabled={!joinNowEnabled}
                                        onClick={() => {
                                            loggedIn
                                                ? window.open(`${event?.eventLink}`, '_blank')
                                                : history.push('/signin');
                                        }}
                                    >
                                        Join Now
                                    </button>
                                ) : null}
                            </div>
                        ) : null}

                        <div className="mb-20">
                            <div className="mt-2 text-xl font-semibold">Description</div>
                            <div className="border-b-2 my-2 "></div>
                            <div className="text-justify">{event?.eventDescription}</div>
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="lg:hidden">
                <img
                    className="w-full h-24 object-cover object-center"
                    src={s3UrlPrefix + event?.eventImage || `/assets/eventbanner.png`}
                    alt="logo"
                />

                {event && !eventByIdLoading ? (
                    <div className="px-4">
                        <div className="mt-6">
                            <div
                                className="flex mb-5 mt-1 cursor-pointer"
                                onClick={() => history.push(`/events`)}
                            >
                                <BackArrow size="27" />
                                <p className="text-base ml-2 font-semibold  hover:underline">
                                    Back
                                </p>
                            </div>
                            <div className="text-xl font-semibold">
                                {event?.eventName || 'Event Name'}
                            </div>
                            <div className="pl-0.5 pt-1 text-sm">
                                {event?.location || 'Event Location'}
                            </div>
                        </div>

                        <div>
                            <div className="mt-4 text-lg font-semibold">Event details</div>
                            <div className="border-b-2 my-2"></div>

                            <div key={event?.id} className="pt-1">
                                <div className="flex flex-col flex-wrap mt-1 mb-3 justify-between w-full">
                                    <div className="flex items-center gap-x-2">
                                        <EventCalender size={30} />
                                        <div className="flex py-1.5 pl-2">
                                            <div>
                                                <div className="text-purple-950 text-xs">
                                                    Event Start Date/Time
                                                </div>
                                                <div className="font-semibold text-sm">
                                                    {moment(event.eventStartTime).format(
                                                        'MMM Do YYYY, hh:mm A'
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-x-2">
                                        <EventCalender size={30} />
                                        <div className="flex py-1.5 pl-2">
                                            <div>
                                                <div className="text-purple-950 text-xs">
                                                    Event End Date/Time
                                                </div>
                                                <div className="font-semibold text-sm">
                                                    {moment(event.eventEndTime).format(
                                                        'MMM Do YYYY, hh:mm A'
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-x-2">
                                        <EventDuration height="30" width="30" />
                                        <div className="flex py-1.5 pl-2">
                                            <div>
                                                <div className="text-purple-950 text-xs">
                                                    Duration
                                                </div>
                                                <div className="font-semibold text-sm">
                                                    {duration || null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-x-2">
                                        <EventTypeSvg height="30" width="30" />
                                        <div className="flex py-1.5 pl-2">
                                            <div>
                                                <div className="text-purple-950 text-xs">
                                                    Event Type
                                                </div>
                                                <div className="font-semibold text-sm">
                                                    {event?.eventType}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {event?.eventType !== 'VIRTUAL' && (
                                        <div className="flex items-center gap-x-2">
                                            <EventLocation size={30} />
                                            <div className="flex py-1.5 pl-2">
                                                <div>
                                                    <div className="text-purple-950 text-xs">
                                                        Location
                                                    </div>
                                                    <div className="font-semibold text-sm">
                                                        {event?.location}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {!completedEvent ? (
                            <div className="flex w-full gap-x-4 justify-between my-2">
                                {event?.registerLink ? (
                                    <button
                                        className="font-bold bg-purple-950 text-white rounded h-12"
                                        onClick={() => {
                                            loggedIn
                                                ? window.open(`${event?.registerLink}`, '_blank')
                                                : history.push('/signin');
                                        }}
                                        style={{ width: '47%' }}
                                    >
                                        Register Now
                                    </button>
                                ) : null}

                                {event?.eventLink ? (
                                    <button
                                        className={`font-bold rounded h-12 border-2 ${
                                            joinNowEnabled
                                                ? 'border-purple-950 text-purple-950'
                                                : 'border-gray-300 text-gray-300 cursor-default'
                                        }`}
                                        disabled={!joinNowEnabled}
                                        onClick={() => {
                                            loggedIn
                                                ? window.open(`${event?.eventLink}`, '_blank')
                                                : history.push('/signin');
                                        }}
                                        style={{ width: '47%' }}
                                    >
                                        Join Now
                                    </button>
                                ) : null}
                            </div>
                        ) : null}

                        <div className="mt-6 mb-12">
                            <div className="mt-2 text-lg font-semibold">Description</div>
                            <div className="border-b-2 my-2 "></div>
                            <div className="text-justify text-sm">{event?.eventDescription}</div>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default Event;
