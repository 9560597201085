import { ContactType } from '../students/reducers';
import { TagsType } from '../tags/reducers';
import {
    ADD_NOTIFICATION_TAB,
    ADD_NOTIFICATION_TAB_FAILED,
    ADD_NOTIFICATION_TAB_SUCCESS,
    GET_NOTIFICATION_TAB,
    GET_NOTIFICATION_TAB_FAILED,
    GET_NOTIFICATION_TAB_SUCCESS,
} from './actions';

export interface UsersType {
    id: string;
    userId: string;
    userName: string;
    contact: ContactType;
    tags: TagsType;
}
export interface NotificationType {
    id: string;
    notificationId: string;
    type: string;
    sentType: string;
    title: string;
    content: string;
    tags: [];
    users: [];
}
export interface CountType {
    ALL: number;
    ACTIVE: number;
    INACTIVE: number;
    DELETED: number;
}
export interface NotificationsTabInitialState {
    notificationsData: NotificationType[];
    pageCount: number;
    notificationTabData: [];
    count: CountType;
    isLoading: boolean;
    isLoadingUsers: boolean;
    notificationError: string | null;
    notifyUsersError: string | null;
    notifyUsers: UsersType[];
    createNotificationError: string | null;
    notificationCreateSuccessMessage: string | null;
    isCreateLoading: boolean;
}

const initialState: NotificationsTabInitialState = {
    notificationsData: [],
    pageCount: null,
    notificationTabData: [],
    count: null,
    isLoading: false,
    isLoadingUsers: false,
    notificationError: null,
    notifyUsersError: null,
    notifyUsers: [],
    createNotificationError: null,
    notificationCreateSuccessMessage: null,
    isCreateLoading: false,
};

const notificationsTabReducer = (state = initialState, action: any) => {
    const newTab = action?.payload?.tag?.map(tagss => tagss.id);

    let filterdnewDAta;
    if (action?.payload?.tag !== undefined) {
        filterdnewDAta = action?.payload?.payload?.items?.filter(track =>
            newTab.every(s => track.tags.map(trackid => trackid.id).includes(parseInt(s)))
        );
    }
    switch (action.type) {
        case ADD_NOTIFICATION_TAB:
            return { ...state, isLoading: true, notificationsError: null };
        case ADD_NOTIFICATION_TAB_SUCCESS:
            if (newTab !== undefined || newTab) {
                return {
                    ...state,
                    isLoading: false,
                    count: action.payload.payload.counts,
                    notificationsData: filterdnewDAta,
                    pageCount: action.payload.payload.totalPages,
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    count: action.payload.counts,
                    notificationsData: action.payload,
                    pageCount: action.payload.totalPages,
                };
            }
        case ADD_NOTIFICATION_TAB_FAILED:
            return { ...state, isLoading: false, notificationsError: action.payload };
        case GET_NOTIFICATION_TAB:
            return { ...state, isLoading: true, trackError: null };
        case GET_NOTIFICATION_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                notificationTabData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case GET_NOTIFICATION_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        default:
            return state;
    }
};

export default notificationsTabReducer;
