import { ContactType } from '../students/reducers';
import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAILED,
    GET_USER_BY_ID,
    GET_USER_BY_ID_SUCCESS,
    GET_USER_BY_ID_FAILED,
    ADD_USER,
    ADD_USER_SUCCESS,
    ADD_USER_FAILED,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,
    EDIT_USER,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAILED,
    USERS_BULK_UPDATE,
    USERS_BULK_UPDATE_SUCCESS,
    USERS_BULK_UPDATE_FAILED,
    CLEAR_USERS_MESSAGES,
    GET_BRIDGE_INVITATION_CODES,
    GET_BRIDGE_INVITATION_CODES_FAILED,
    GET_BRIDGE_INVITATION_CODES_SUCCESS,
    GET_PRIVACY_POLICY,
    GET_PRIVACY_POLICY_FAILED,
    GET_PRIVACY_POLICY_SUCCESS,
    CREATE_PRIVACY_POLICY,
    CREATE_PRIVACY_POLICY_FAILED,
    CREATE_PRIVACY_POLICY_SUCCESS,
    EDIT_PRIVACY_POLICY,
    EDIT_PRIVACY_POLICY_FAILED,
    EDIT_PRIVACY_POLICY_SUCCESS,
} from './actions';

export interface PrivacyPolicyDataType {
    description: string;
}
export interface roleType {
    id: string;
    roleName: string;
}
export interface ActivateUserType {
    id: string;
    userId: string;
    status: string;
}
export interface OrganizationType {
    id: string;
    name: string;
}
export interface UserType {
    id: string;
    userId: string;
    userName: string;
    status: string;
    firstName: string;
    lastName: string;
    imageUrl: string;
    gender: string;
    language: string;
    password: string;
    timeFormat: string;
    timeZone: string;
    roles: roleType[];
    organizations: OrganizationType[];
    privilege: any[];
    notificationList: any[];
    contact: ContactType;
    emailId: string;
}
export interface CountType {
    ALL: number;
    DELETED: number;
    ACTIVE: number;
    DRAFT: number;
}
export interface UsersInitialState {
    UserData: UserType[];
    User: UserType;
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    UserByIdLoading: boolean;
    UserByIdError: string | null;
    UsersError: string | null;
    createUserError: string | null;
    UserCreateSuccessMessage: string | null;
    isDeleteLoading: boolean;
    deleteUserError: string | null;
    UserDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editUserError: string | null;
    UserUpdateSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    UserBulkSuccessMessage: string | null;
    isPdfLoading: boolean;
    pdfError: string | null;
    pdfLink: string | null;
    isInvitationCodesLoading: boolean;
    invitationCodes: [];
    invitationCodesError: string | null;
    isPrivacyPolicyLoading: boolean;
    privacyPolicy: any;
    privacyPolicyError: string | null;
    isPrivacyPolicyEditLoading: boolean;
    privacyPolicyEditSuccessMessage: PrivacyPolicyDataType | null;
    privacyPolicyEditError: boolean;
}

const initialState: UsersInitialState = {
    UserData: [],
    User: null,
    pageCount: null,
    count: null,
    isLoading: false,
    UserByIdLoading: false,
    UserByIdError: null,
    UsersError: null,
    createUserError: null,
    UserCreateSuccessMessage: null,
    isDeleteLoading: false,
    deleteUserError: null,
    UserDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editUserError: null,
    UserUpdateSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    UserBulkSuccessMessage: null,
    isPdfLoading: false,
    pdfError: null,
    pdfLink: null,
    isInvitationCodesLoading: false,
    invitationCodes: null,
    invitationCodesError: null,
    isPrivacyPolicyLoading: false,
    privacyPolicy: null,
    privacyPolicyError: null,
    isPrivacyPolicyEditLoading: false,
    privacyPolicyEditSuccessMessage: null,
    privacyPolicyEditError: null,
};

const UsersReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_USERS:
            return { ...state, isLoading: true, UsersError: null };
        case GET_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                UserData: action.payload.items,
                pageCount: action.payload.totalPages,
            };
        case GET_USERS_FAILED:
            return { ...state, isLoading: false, UsersError: action.payload };
        case GET_USER_BY_ID:
            return { ...state, UserByIdLoading: true, USERByIdError: null };
        case GET_USER_BY_ID_SUCCESS:
            return {
                ...state,
                UserByIdLoading: false,
                User: action.payload,
            };
        case GET_USER_BY_ID_FAILED:
            return { ...state, UserByIdLoading: false, UserByIdError: action.payload };

        case ADD_USER:
            return { ...state, isCreateLoading: true, createUserError: null };
        case ADD_USER_SUCCESS:
            return {
                ...state,
                isCreateLoading: false,
                UserCreateSuccessMessage: action.payload,
            };
        case ADD_USER_FAILED:
            return { ...state, isCreateLoading: false, createUserError: action.payload };
        case DELETE_USER:
            return { ...state, isDeleteLoading: true, deleteUserError: null };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                isDeleteLoading: false,
                UserDeleteSuccessMessage: action.payload,
            };
        case DELETE_USER_FAILED:
            return { ...state, isDeleteLoading: false, deleteUserError: action.payload };
        case EDIT_USER:
            return { ...state, isEditLoading: true, editUserError: null };
        case EDIT_USER_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                UserUpdateSuccessMessage: action.payload,
            };
        case EDIT_USER_FAILED:
            return { ...state, isEditLoading: false, editUserError: action.payload };
        case USERS_BULK_UPDATE:
            return { ...state, isBulkUpdateLoading: true, bulkError: null };
        case USERS_BULK_UPDATE_SUCCESS:
            return {
                ...state,
                isBulkUpdateLoading: false,
                UserBulkSuccessMessage: action.payload,
            };
        case USERS_BULK_UPDATE_FAILED:
            return { ...state, isBulkUpdateLoading: false, bulkError: action.payload };
        case GET_BRIDGE_INVITATION_CODES:
            return { ...state, isInvitationCodesLoading: true, invitationCodesError: null };
        case GET_BRIDGE_INVITATION_CODES_SUCCESS:
            return {
                ...state,
                isInvitationCodesLoading: false,
                invitationCodes: action.payload,
            };
        case GET_BRIDGE_INVITATION_CODES_FAILED:
            return {
                ...state,
                isInvitationCodesLoading: false,
                invitationCodesError: action.payload,
            };
        case GET_PRIVACY_POLICY:
            return { ...state, isPrivacyPolicyLoading: true, privacyPolicyError: null };
        case GET_PRIVACY_POLICY_SUCCESS:
            return {
                ...state,
                isPrivacyPolicyLoading: false,
                privacyPolicy: action.payload,
                privacyPolicyError: null,
            };
        case GET_PRIVACY_POLICY_FAILED:
            return {
                ...state,
                isPrivacyPolicyLoading: false,
                privacyPolicy: null,
                privacyPolicyError: action.payload,
            };
        case CREATE_PRIVACY_POLICY:
            return { ...state, isPrivacyPolicyEditLoading: true, privacyPolicyEditError: null };
        case CREATE_PRIVACY_POLICY_SUCCESS:
            return {
                ...state,
                isPrivacyPolicyEditLoading: false,
                privacyPolicyEditSuccessMessage: action.payload,
                privacyPolicyEditError: null,
            };
        case CREATE_PRIVACY_POLICY_FAILED:
            return {
                ...state,
                isPrivacyPolicyEditLoading: false,
                privacyPolicyEditSuccessMessage: null,
                privacyPolicyEditError: action.payload,
            };
        case EDIT_PRIVACY_POLICY:
            return { ...state, isPrivacyPolicyEditLoading: true, privacyPolicyEditError: null };
        case EDIT_PRIVACY_POLICY_SUCCESS:
            return {
                ...state,
                isPrivacyPolicyEditLoading: false,
                privacyPolicyEditSuccessMessage: action.payload,
                privacyPolicyEditError: null,
            };
        case EDIT_PRIVACY_POLICY_FAILED:
            return {
                ...state,
                isPrivacyPolicyEditLoading: false,
                privacyPolicyEditSuccessMessage: null,
                privacyPolicyEditError: action.payload,
            };
        case CLEAR_USERS_MESSAGES:
            return {
                ...state,
                UserCreateSuccessMessage: null,
                createUserError: null,
                UserUpdateSuccessMessage: null,
                editUserError: null,
                deleteUserError: null,
                UserDeleteSuccessMessage: null,
                UserBulkSuccessMessage: null,
                bulkError: null,
                privacyPolicyError: null,
                privacyPolicyEditError: null,
                privacyPolicyEditSuccessMessage: null,
            };
        default:
            return state;
    }
};

export default UsersReducer;
