import React, { ReactElement, useEffect } from 'react';
import Slider from 'react-slick';
import NextArrow from '../../../../assets/svg/NextArrow';
import { getCertificates, getMentors } from '../../../../redux/rootActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import { s3UrlPrefix } from '../../../../constants';

interface Props {
    isLargeScreen?: boolean;
}

export default function MostPopularMentors({ isLargeScreen = true }: Props): ReactElement {
    const dispatch = useDispatch();
    const mentorsData = useSelector((state: RootState) => state.mentors.mentorsData);

    useEffect(() => {
        dispatch(getMentors(false));
    }, [dispatch]);

    const mentorsDate = [
        {
            id: 0,
            mentorName: 'John Deo',
            designation: 'CEO of Rocky johns',
            imagePath: '/assets/MentorPic.png',
        },
        {
            id: 1,
            mentorName: 'John Deo',
            designation: 'Senior Software Engineer',
            imagePath: '/assets/MentorPic.png',
        },
        {
            id: 2,
            mentorName: 'John Deo',
            designation: 'UI UX Designer',
            imagePath: '/assets/MentorPic.png',
        },
        {
            id: 3,
            mentorName: 'John Deo',
            designation: 'Software Engineer',
            imagePath: '/assets/MentorPic.png',
        },
        {
            id: 4,
            mentorName: 'John Deo',
            designation: 'Tech Lead',
            imagePath: '/assets/MentorPic.png',
        },
        {
            id: 5,
            mentorName: 'John Deo',
            designation: 'Senior Software Engineer',
            imagePath: '/assets/MentorPic.png',
        },
    ];

    const settings = {
        // dots: false,
        // infinite: false,
        // speed: 500,
        // slidesToShow: 3,
        // slidesToScroll: 1,
        variableWidth: true,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
    };

    return (
        <div className="mb-28">
            <div className="flex flex-row justify-between mb-3 mt-8">
                <div className="font-bold text-xl font-manrope">Our Mentors</div>
            </div>

            <div
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    gap: '35px',
                    marginRight: '1em',
                    flexDirection: 'row',
                    alignContent: 'flex-start',
                }}
            >
                {mentorsData?.length > 0 ? (
                    mentorsData?.map(mentor => (
                        <div
                            style={
                                isLargeScreen
                                    ? {
                                          width: '203px',
                                      }
                                    : { width: '130px' }
                            }
                            className="rounded-lg "
                            key={mentor.id}
                        >
                            <img
                                style={
                                    isLargeScreen
                                        ? { width: '203px', height: '186px', borderRadius: '4px' }
                                        : { width: '130px', height: '120px', borderRadius: '4px' }
                                }
                                className="w-full rounded"
                                src={`${
                                    mentor?.imagePath
                                        ? `${s3UrlPrefix}${mentor?.imagePath}`
                                        : '/assets/MentorPic.png '
                                }`}
                                alt="profile"
                            />
                            <div className="mt-3 flex flex-col justify-center items-center space-y-1 gap-x-4">
                                <div className="font-bold text-sm lg:text-base">
                                    {mentor.mentorName}
                                </div>
                                <div className="pt-0 text-xs lg:text-sm">{mentor.designation}</div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="flex flex-row justify-between mb-3 mt-8">
                        Currently no mentors available
                    </div>
                )}
            </div>
        </div>
    );
}
