import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import FooterAdmin from '../components/Footer';
import NavBar from '../components/NavBar';
import Sidebar from '../components/SideBar/Sidebar';
import {
    COMPANY_ADMIN,
    INSTITUTE_ADMIN,
    SLASSCOM_ADMIN,
    SUPER_ADMIN,
    UserProfileType,
} from '../redux/authAdmin/reducers';
import { RootState } from '../redux/rootReducer';
import { getUserProfile } from '../utils/storage';

const ScreensWithNavAndSideBar = ({ children, sidebar, setSidebar }) => (
    <div>
        <NavBar sidebar={sidebar} setSidebar={setSidebar} />
        <div className="w-full" style={{ minHeight: '92vh' }}>
            <div className="flex flex-row w-full">
                <div className={`${sidebar === false ? 'hidden' : 'w-1/3 md:w-1/5 xl:w-1/6'}`}>
                    <Sidebar sidebar={sidebar} setSidebar={setSidebar} />
                </div>
                <div
                    className={`md:pl-4 2xl:pl-6 w-full mt-10 ${
                        sidebar === false ? 'md:w-full' : 'md:w-4/5 lg:w-5/6 xl:w-5/6'
                    }`}
                >
                    {children}
                </div>
            </div>
        </div>
        <FooterAdmin />
    </div>
);
const ScreensWithNavBar = ({ children }) => (
    <div>
        <NavBar />
        <div className="w-full" style={{ minHeight: '92vh' }}>
            <div className="flex flex-row w-full">
                <div className={`md:pl-4 2xl:pl-6 w-full mt-10 md:w-full`}>{children}</div>
            </div>
        </div>
        <FooterAdmin />
    </div>
);
const ScreensWithOutNavBar = ({ children }) => (
    <div>
        <div className="w-full">
            <div className="flex flex-row w-full">
                <div className={`md:pl-4 2xl:pl-6 w-full mt-10 md:w-full`}>{children}</div>
            </div>
        </div>
    </div>
);
export function ProtectedSuperAdminRoutesWithLayout({
    component: Component,
    setSidebar,
    sidebar,
    ...restOfProps
}) {
    const loginResponse: UserProfileType | any = useSelector(
        (state: RootState) => state.auth.loginResponse
    );
    const user: UserProfileType | any = getUserProfile();
    const isSuperAdmin = user?.roles?.[0] === SUPER_ADMIN;
    const isSlasscomAdmin = user?.roles?.[0] === SLASSCOM_ADMIN;

    return (
        <Route
            {...restOfProps}
            render={props =>
                isSuperAdmin ||
                loginResponse?.roles[0] === SUPER_ADMIN ||
                isSlasscomAdmin ||
                loginResponse?.roles[0] === SLASSCOM_ADMIN ? (
                    <ScreensWithNavAndSideBar setSidebar={setSidebar} sidebar={sidebar}>
                        <Component {...props} />
                    </ScreensWithNavAndSideBar>
                ) : (
                    <Redirect to="/admin/" />
                )
            }
        />
    );
}
export function ProtectedAllAdminRoutesWithLayout({
    component: Component,
    setSidebar,
    sidebar,
    ...restOfProps
}) {
    const loginResponse: UserProfileType | any = useSelector(
        (state: RootState) => state.auth.loginResponse
    );
    const user: UserProfileType | any = getUserProfile();
    const isSuperAdmin = user?.roles?.[0] === SUPER_ADMIN;
    const isSlasscomAdmin = user?.roles?.[0] === SLASSCOM_ADMIN;
    const isCompanyAdmin = user?.roles?.[0] === COMPANY_ADMIN;
    const isInstituteAdmin = user?.roles?.[0] === INSTITUTE_ADMIN;

    return (
        <Route
            {...restOfProps}
            render={props =>
                isSuperAdmin ||
                loginResponse?.roles[0] === SUPER_ADMIN ||
                isSlasscomAdmin ||
                loginResponse?.roles[0] === SLASSCOM_ADMIN ||
                isCompanyAdmin ||
                loginResponse?.roles[0] === COMPANY_ADMIN ||
                isInstituteAdmin ||
                loginResponse?.roles[0] === INSTITUTE_ADMIN ? (
                    <ScreensWithNavAndSideBar setSidebar={setSidebar} sidebar={sidebar}>
                        <Component {...props} />
                    </ScreensWithNavAndSideBar>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/admin/signIn',
                            state: { redirect: window.location.pathname },
                        }}
                    />
                )
            }
        />
    );
}
export function AdminRoutesWithLayout({ component: Component, ...restOfProps }) {
    return (
        <Route
            {...restOfProps}
            render={props => (
                <ScreensWithNavBar>
                    <Component {...props} />
                </ScreensWithNavBar>
            )}
        />
    );
}
export function AdminRoutesWithOutLayout({ component: Component, ...restOfProps }) {
    return (
        <Route
            {...restOfProps}
            render={props => (
                <ScreensWithOutNavBar>
                    <Component {...props} />
                </ScreensWithOutNavBar>
            )}
        />
    );
}
