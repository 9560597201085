import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { COMPANY_ADMIN, INSTITUTE_ADMIN } from '../../../redux/authAdmin/reducers';
import { RootState } from '../../../redux/rootReducer';

interface Props {
    selectedTab: number;
    setSelectedTab: (value: number) => void;
}

export default function TableTabView({ selectedTab, setSelectedTab }: Props): ReactElement {
    const user = useSelector((state: RootState) => state.users.User);

    return (
        <div className="flex flex-row">
            <div
                className={`${
                    selectedTab === 1
                        ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                        : 'text-black-300 border-snow-500'
                } text-sm lg:text-base xl:text-lg   flex justify-center cursor-pointer w-48`}
                onClick={() => {
                    setSelectedTab(1);
                }}
            >
                {user?.roles[0]?.roleName === COMPANY_ADMIN
                    ? 'Company Profile'
                    : user?.roles[0]?.roleName === INSTITUTE_ADMIN
                    ? 'Institute Profile'
                    : 'My Profile'}
            </div>

            {(user?.roles[0]?.roleName === COMPANY_ADMIN ||
                user?.roles[0]?.roleName === INSTITUTE_ADMIN) && (
                <div
                    className={`${
                        selectedTab === 2
                            ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                            : 'text-black-300 border-snow-500'
                    } text-sm lg:text-base xl:text-lg  flex justify-center cursor-pointer w-48`}
                    onClick={() => {
                        setSelectedTab(2);
                    }}
                >
                    Notifications
                </div>
            )}
            <div
                className={`${
                    selectedTab === 3
                        ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                        : 'text-black-300 border-snow-500'
                } text-sm lg:text-base xl:text-lg  flex justify-center cursor-pointer w-48`}
                onClick={() => {
                    setSelectedTab(3);
                }}
            >
                Account Security
            </div>
            <div
                className={`${
                    selectedTab === 4
                        ? ' border-blue-700 text-blue-700 border-b-4 font-semibold'
                        : 'text-black-300 border-snow-500'
                } text-sm lg:text-base xl:text-lg  flex justify-center cursor-pointer w-48`}
                onClick={() => {
                    setSelectedTab(4);
                }}
            >
                {'Privacy & Policy'}
            </div>
        </div>
    );
}
