import { decryptKey } from '../constants';
import CryptoJS from 'crypto-js';

export const encryptData = (strToEncrypt: string) => {
    try {
        const parsedBase64Key = CryptoJS.enc.Base64.parse(decryptKey);
        const encryptedData = CryptoJS.AES.encrypt(strToEncrypt, parsedBase64Key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        const encryptedText = encryptedData.toString();

        return encryptedText;
    } catch (error) {
        console.log('Encrypted Data error', error);
    }
};
