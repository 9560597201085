import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { CreateTagsType } from './reducers';

const PREFIX = '@TAGS_';

export const GET_TAGS = PREFIX + 'GET_TAGS';
export const GET_TAGS_SUCCESS = PREFIX + 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAILED = PREFIX + 'GET_TAGS_FAILED';
export const ADD_TAG = PREFIX + 'ADD_TAG';
export const ADD_TAG_SUCCESS = PREFIX + 'ADD_TAG_SUCCESS';
export const ADD_TAG_FAILED = PREFIX + 'ADD_TAG_FAILED';
export const DELETE_TAG = PREFIX + 'DELETE_TAG';
export const DELETE_TAG_SUCCESS = PREFIX + 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILED = PREFIX + 'DELETE_TAG_FAILED';

export const GET_FILTERED_DATA = PREFIX + 'GET_FILTERED_DATA';
export const GET_FILTERED_DATA_SUCCESS = PREFIX + 'GET_FILTERED_DATA_SUCCESS';
export const GET_FILTERED_DATA_FAILED = PREFIX + 'GET_FILTERED_DATA_FAILED';
export const CLEAR_TAGS_MESSAGES = PREFIX + 'CLEAR_TAGS_MESSAGES';

export const getTags =
    (isAdmin: boolean, moduleName?: string, searchTerm?: string) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_TAGS });
            const config = await axiosConfig(isAdmin);
            const response = await axios.get(
                `${API_URL}/tags/?searchText=${searchTerm || ''}${
                    moduleName ? `&module=${moduleName}` : ''
                }`,
                config
            );
            dispatch({
                type: GET_TAGS_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_TAGS_FAILED, payload: getErrorMessage(error) });
        }
    };

export const getTagFilteredData = () => async (dispatch: any) => {
    try {
        dispatch({ type: GET_FILTERED_DATA });
        const response = await axios.get(`${API_URL}/tags/}`);
        dispatch({
            type: GET_FILTERED_DATA_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_FILTERED_DATA_FAILED, payload: getErrorMessage(error) });
    }
};
export const addTag = (tag: CreateTagsType) => async (dispatch: any) => {
    try {
        dispatch({ type: ADD_TAG });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/tags/`, tag, config);
        if (res?.data?.status) {
            if (res?.data?.errorCode === 0) {
                dispatch({ type: ADD_TAG_SUCCESS, payload: 'Tag has been addedd successfully!' });
            }
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode === 118) {
                dispatch({
                    type: ADD_TAG_FAILED,
                    payload: res?.data?.errorDescription,
                });
            } else {
                dispatch({
                    type: ADD_TAG_FAILED,
                    payload: 'Something went wrong!',
                });
            }
        }
    } catch (error) {
        dispatch({ type: ADD_TAG_FAILED, payload: getErrorMessage(error) });
    }
};
export const deleteTag = (tagId: number) => async (dispatch: any) => {
    try {
        dispatch({ type: DELETE_TAG });
        const config = await axiosConfig(true);
        const res = await axios.delete(`${API_URL}/tags/${tagId}`, config);

        if (res?.data?.status) {
            dispatch({
                type: DELETE_TAG_SUCCESS,
                payload: res.data.responseDto,
            });
        }
        if (!res?.data?.status) {
            dispatch({
                type: DELETE_TAG_FAILED,
                payload: res.data.errorDescription,
            });
        }
    } catch (error) {
        dispatch({ type: DELETE_TAG_FAILED, payload: getErrorMessage(error) });
    }
};
export const clearTagsMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_TAGS_MESSAGES });
};
