import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as HiIcons from 'react-icons/hi';
import { useHistory, useLocation } from 'react-router-dom';
import MostPopularMentors from './MostPopularMentors';
import { USER_PROFILE } from '../../../utils/storageWeb';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import CloseIcon from '../../../../assets/svg/CloseIcon';
import {
    clearMentorRequestMessages,
    requestAMentor,
} from '../../../../redux/webRedux/webMentors/actions';
import Loader from '../../../../assets/svg/Loader';
import RequestMentorModal from './RequestMentorModal';
import { getChallengesStatus } from '../../../../redux/webRedux/challenges/actions';
import ClockIcon from '../../../../assets/svg/ClockIcon';
import useWidth from '../../../hooks/useWidth';
import styled from 'styled-components';
import { editWebUser, getTags } from '../../../../redux/rootActions';
import { moduleNames } from '../../../../constants';

const Paragraph = styled.div`
    font-size: 10px;
    line-height: 14.9px;
`;
interface CustomState {
    fromChallenge: boolean;
}
const RequestMentor = () => {
    const history = useHistory();
    const location = useLocation();
    const modalState = location.state as CustomState;
    const dispatch = useDispatch();
    const userProfile = useSelector((state: RootState) => state.authStudent.userProfile);
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const studentData = useSelector((state: RootState) => state.webUser.student);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const isMentorRequestLoading = useSelector((state: RootState) => state.webMentor.isLoading);
    const mentorRequestResponce = useSelector(
        (state: RootState) => state.webMentor.requestResponce
    );
    const challengesStatus = useSelector((state: RootState) => state.webChallenge.challengesData);
    const [isErrorSpecializedArea, setIsErrorSpecializedArea] = useState<boolean>(false);
    const [isErrorPublicProfile, setIsErrorPublicProfile] = useState<boolean>(false);
    const [specializedAreaDropdownVisible, setSpecializedAreaDropdownVisible] = useState(false);

    const [trackFilterspecializedAreaAction, setTrackFilterspecializedAreaAction] =
        useState<string>('Select your specialized area');

    const [isErrorDescription, setIsErrorDescription] = useState<boolean>(false);
    const [isPublic, setIsPublic] = useState(studentData?.isPublic);
    const [description, setDescription] = useState<string>('');

    const [showModal, setShowModal] = React.useState(false);
    const [showSuccessModal, setShowSuccessModal] = React.useState(false);
    const [showAbleToRequestModal, setShowAbleToRequestModal] = React.useState(false);
    const [challengesNeedForMentor, setChallengesNeedForMentor] = React.useState(25);

    const [popupContent, setPopopContent] = React.useState('');
    const viewPortWidth = useWidth();
    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(239, 235, 255, 0.7)',
            backdropFilter: 'blur(4px)',
            overflow: 'auto',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    useEffect(() => {
        dispatch(getChallengesStatus(userProfile?.userId));
    }, [dispatch, userProfile?.userId]);

    useEffect(() => {
        dispatch(getTags(false, moduleNames.Mentors));
    }, [dispatch]);

    useEffect(() => {
        if (challengesStatus?.completed) {
            setChallengesNeedForMentor(25 - challengesStatus?.completed.length);
        } else {
            setChallengesNeedForMentor(0);
        }
    }, [challengesStatus]);

    useEffect(() => {
        if (
            challengesStatus?.completed?.length >= 25 &&
            !modalState?.fromChallenge &&
            !studentData?.mentorRequestId
        ) {
            setShowAbleToRequestModal(true);
        }
    }, []);
    useEffect(() => {
        setIsPublic(studentData?.isPublic);
    }, [studentData]);

    useEffect(() => {
        if (isPublic !== studentData?.isPublic) {
            const updatedFileds = { isPublic: isPublic };
            dispatch(editWebUser(userProfile?.userId, updatedFileds));
        }
        setIsErrorPublicProfile(false);
    }, [isPublic]);

    const submit = () => {
        if (challengesStatus?.completed?.length < 25) {
            setShowModal(true);
        } else if (!isPublic) {
            setIsErrorPublicProfile(true);
        } else if (trackFilterspecializedAreaAction === 'Select your specialized area') {
            setIsErrorSpecializedArea(true);
        } else if (
            [null, undefined, ''].includes(description) ||
            description.split(' ').length > 500
        ) {
            setIsErrorDescription(true);
        } else {
            dispatch(
                requestAMentor({
                    studentId: profile?.userId,
                    area: trackFilterspecializedAreaAction,
                    description: description,
                })
            );
        }
    };

    useEffect(() => {
        if (mentorRequestResponce?.errorCode === 521) {
            setPopopContent(mentorRequestResponce.errorDescription);
            setShowSuccessModal(true);
            setTrackFilterspecializedAreaAction('Select your specialized area');
            setDescription('');
            dispatch(clearMentorRequestMessages());
        } else {
            if (mentorRequestResponce != null) {
                setPopopContent('Congratulations, you successfully requested for a mentor!');
                setShowSuccessModal(true);
                setTrackFilterspecializedAreaAction('Select your specialized area');
                setDescription('');
                dispatch(clearMentorRequestMessages());
            }
        }
    }, [dispatch, mentorRequestResponce]);

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div className="mx-10 md:mx-5 md:ml-14 lg:ml-72 lg:pl-3.5 lg:pt-2 mt-6">
                    <RequestMentorModal
                        showAbleToRequestModal={showAbleToRequestModal}
                        setShowAbleToRequestModal={setShowAbleToRequestModal}
                        customStyles={customStyles}
                    />

                    <Modal isOpen={showModal} style={customStyles}>
                        <>
                            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                                <div
                                    style={{ width: '678px', height: '314px' }}
                                    className="relative my-6 mx-auto"
                                >
                                    {/* content */}
                                    <div
                                        style={{ maxHeight: '400px' }}
                                        className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none "
                                    >
                                        <div
                                            className="flex justify-end cursor-pointer mr-6 mt-3"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <CloseIcon size={20} color={'gray'} />
                                        </div>
                                        <div
                                            style={{
                                                position: 'relative',
                                                // top: '-50px',
                                            }}
                                            className="flex flex-col -bottom-2 px-12 pb-6"
                                        >
                                            {/* <span className="successTick self-center"></span> */}
                                            <h1 className="font-manrope text-xl text-center font-bold">
                                                Complete {challengesNeedForMentor} more challenges
                                                to Request a Mentor!
                                            </h1>
                                            {/* <p className="text-center text-purple-950 my-2">
                                                Now you can view details about your assigned mentor
                                                through your profile and contact him/her to get
                                                active mentorship.
                                            </p> */}
                                            <div className="flex justify-center mt-3 mb-3">
                                                <button
                                                    onClick={() => {
                                                        setShowModal(false);
                                                        history.push(`/dashBoard/learn`);
                                                    }}
                                                    className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                                >
                                                    View Challenges
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Modal>

                    <Modal isOpen={showSuccessModal} style={customStyles}>
                        <>
                            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-1/3 my-6 mx-auto">
                                    {/* content */}
                                    <div
                                        style={{ maxHeight: '270px' }}
                                        className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-6"
                                    >
                                        <div
                                            style={{
                                                position: 'relative',
                                                top: '-90px',
                                            }}
                                            className="flex flex-col -bottom-2"
                                        >
                                            <span className="successTick self-center"></span>
                                            <h1 className="mb-2 mt-3 text-xl text-center font-bold font-manrope">
                                                Successful
                                            </h1>
                                            <p className="text-center my-2 ">{popupContent}</p>
                                            <div className="flex justify-center mt-3">
                                                <button
                                                    onClick={() => {
                                                        setShowSuccessModal(false);
                                                    }}
                                                    className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                                >
                                                    Ok
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Modal>

                    {studentData?.mentorRequestId !== null ? (
                        <div>
                            <div className="rounded mb-3 py-6 px-14 bg-purple-100 flex flex-col text-purple-950 ">
                                <div className="flex">
                                    <ClockIcon size={59} />
                                    <div className="font-bold text-2xl-2 font-manrope ml-4 mb-3 mt-1">
                                        {studentData?.mentor !== null
                                            ? 'You have been assigned a mentor.'
                                            : 'You have Requested for a mentor.'}
                                    </div>
                                </div>
                                {studentData?.mentor !== null ? (
                                    <div className="text-sm ml-20">
                                        Now you can view details about your assigned mentor through
                                        your profile and contact him/her to get active mentorship.
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div
                                className="rounded mb-8 py-6 text-white pl-8 pr-64"
                                style={{
                                    background:
                                        'linear-gradient(93.16deg, #6E41E2 1.57%, #0C247A 98.41%)',
                                }}
                            >
                                <div className="font-bold font-manrope text-2xl-2 mb-3 ">
                                    Request a Mentor
                                </div>
                                <p>
                                    Mentoring can provide valuable support for students at critical
                                    points in their student life, it offers a host of other benefits
                                    for both mentor and mentee. Get mentoring support through IT
                                    Field specialists in Sri Lanka and grow a bright career for
                                    yourself.
                                </p>

                                {challengesStatus?.completed?.length < 25 ? (
                                    <>
                                        <div className="font-bold font-manrope text-xl mt-6 mb-3 ">
                                            Complete {challengesNeedForMentor} more challenges to
                                            Request a Mentor!
                                        </div>
                                    </>
                                ) : null}
                            </div>

                            <div>
                                <div className="font-bold text-xl font-manrope mb-3">Note</div>
                                <div className="rounded mb-3 p-3 bg-purple-100 flex flex-col text-purple-950 text-sm">
                                    Upon requesting a mentor Future Careers BRIDGE is trying to
                                    connect the most suitable mentor available to the mentee in
                                    order to ensure that the mentee gets the required guidance to be
                                    successful in their career. Therefore, we will do the mentor
                                    matching offline and send you an email with the details of the
                                    mentor assigned to you so that you can carry on the
                                    communication with the assigned mentor through any of the
                                    communication channels you and your mentor prefers.
                                </div>
                            </div>
                            <div>
                                <input
                                    className="mr-1 mt-1"
                                    id="public_profile_consent"
                                    type="checkbox"
                                    checked={isPublic}
                                    onChange={() => {
                                        setIsPublic(!isPublic);
                                    }}
                                    disabled={challengesStatus?.completed?.length < 25}
                                />

                                <label
                                    className="font-manrope text-sm"
                                    htmlFor="public_profile_consent"
                                >
                                    Keep my profile publicly linked and accessible through the
                                    BRIDGE website leaderboard and via QR code on the certificates.
                                </label>
                            </div>
                            {isErrorPublicProfile ? (
                                <div className="text-red-500 font-manrope text-sm my-1">
                                    You must enable public profile in order to request a mentor
                                </div>
                            ) : null}
                            <div className="flex flex-row  mb-3">
                                <div className="flex flex-col my-1  w-full md:w-2/5">
                                    <label
                                        className="flex flex-row my-1 font-manrope text-sm"
                                        htmlFor="tab_1"
                                    >
                                        Select Specialization area
                                    </label>
                                    {challengesStatus?.completed?.length >= 25 ? (
                                        <div
                                            className={`flex relative cursor-pointer items-center focus:outline-none h-12 md:p-2 text-sm focus:border-blue-900 ${
                                                isErrorSpecializedArea
                                                    ? 'inputFieldErrorThickness'
                                                    : 'inputFieldThickness'
                                            } `}
                                        >
                                            <div
                                                className="flex justify-between w-full"
                                                onClick={() =>
                                                    setSpecializedAreaDropdownVisible(true)
                                                }
                                            >
                                                <div
                                                    className={` font-medium pl-2 ${
                                                        trackFilterspecializedAreaAction !==
                                                        'Select your specialized area'
                                                            ? 'text-gray-700 font-medium'
                                                            : 'text-gray-400'
                                                    }`}
                                                >
                                                    {trackFilterspecializedAreaAction}
                                                </div>
                                                <div className="">
                                                    <div className="flex flex-row">
                                                        <div className="flex flex-col">
                                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                            <HiIcons.HiChevronDown className="mr-2" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {specializedAreaDropdownVisible ? (
                                                <ul
                                                    className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                    role="menu"
                                                    onMouseLeave={() =>
                                                        setSpecializedAreaDropdownVisible(false)
                                                    }
                                                >
                                                    {tagData.map(tag => (
                                                        <div
                                                            className="p-2 pl-2 hover:bg-gray-200 "
                                                            key={tag.id}
                                                            onClick={() => {
                                                                setIsErrorSpecializedArea(false);
                                                                setTrackFilterspecializedAreaAction(
                                                                    tag.tagName
                                                                );
                                                                setSpecializedAreaDropdownVisible(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            {tag.tagName}
                                                        </div>
                                                    ))}
                                                </ul>
                                            ) : null}
                                        </div>
                                    ) : (
                                        <input
                                            className={`pl-4 py-1 h-12 inputFieldThickness`}
                                            type="text"
                                            id="tab_1"
                                            value={''}
                                            disabled
                                        />
                                    )}
                                    {isErrorSpecializedArea ? (
                                        <div className="text-red-500 font-manrope text-sm my-1">
                                            Select a specialized area
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex flex-col my-1 w-full md:w-5/6 pr-1 mt-2">
                                <label
                                    className="flex flex-row my-1 font-manrope text-sm"
                                    htmlFor="tab_1"
                                >
                                    Describe what you are looking for?
                                </label>
                                <textarea
                                    disabled={challengesStatus?.completed?.length < 25}
                                    className={`pl-2  pt-3 pb-28 h-12 ${
                                        isErrorDescription
                                            ? 'inputFieldErrorThickness'
                                            : 'inputFieldThickness'
                                    }`}
                                    id="tab_1"
                                    value={description}
                                    placeholder="Description ..."
                                    onChange={e => {
                                        setDescription(e.target.value);
                                        if (e.target.value) {
                                            setIsErrorDescription(false);
                                        }
                                    }}
                                />
                                <div className="text-gray-400 font-manrope text-sm">
                                    Max 500 words
                                </div>
                                {isErrorDescription ? (
                                    <div className="text-red-500 text-xs my-1">
                                        {description.length > 500
                                            ? 'Maximum word count exceeded'
                                            : 'Description is mandatory'}
                                    </div>
                                ) : null}
                            </div>

                            <div className="flex mt-3">
                                <button
                                    // disabled={mcq.some(answer => answer.selectedAnswer === null)}
                                    onClick={() => {
                                        submit();
                                    }}
                                    className="mb-3 mr-16 rounded border-2 font-semibold w-44 h-10 justify-center items-center flex text-white bg-purple-950 border-purple-950"
                                >
                                    {isMentorRequestLoading ? <Loader /> : 'Request a mentor'}
                                </button>
                            </div>
                        </>
                    )}

                    <MostPopularMentors />
                    {/* <Testimonies /> */}
                </div>
            ) : (
                <div className="rounded-lg ml-1">
                    <RequestMentorModal
                        showAbleToRequestModal={showAbleToRequestModal}
                        setShowAbleToRequestModal={setShowAbleToRequestModal}
                        customStyles={customStyles}
                        isLargeScreen={viewPortWidth > 1023}
                    />

                    <Modal isOpen={showModal} style={customStyles}>
                        <>
                            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                                <div
                                    style={{ width: '678px', height: '314px' }}
                                    className="relative my-6 mx-auto"
                                >
                                    {/* content */}
                                    <div
                                        style={{ maxHeight: '400px' }}
                                        className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none "
                                    >
                                        <div
                                            className="flex justify-end cursor-pointer mr-6 mt-3"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <CloseIcon size={20} color={'gray'} />
                                        </div>
                                        <div
                                            style={{
                                                position: 'relative',
                                                // top: '-50px',
                                            }}
                                            className="flex flex-col -bottom-2 px-12 pb-6"
                                        >
                                            {/* <span className="successTick self-center"></span> */}
                                            <h1 className="font-manrope text-lg text-center font-bold">
                                                Complete {challengesNeedForMentor} more challenges
                                                to Request a Mentor!
                                            </h1>
                                            {/* <Paragraph className="text-center text-purple-950 my-2">
                                                Now you can view details about your assigned mentor
                                                through your profile and contact him/her to get
                                                active mentorship.
                                            </Paragraph> */}
                                            <div className="flex justify-center mt-3 mb-3">
                                                <button
                                                    onClick={() => {
                                                        setShowModal(false);
                                                        history.push(`/dashBoard/learn`);
                                                    }}
                                                    className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                                >
                                                    View Challenges
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Modal>

                    <Modal isOpen={showSuccessModal} style={customStyles}>
                        <>
                            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-full md:w-1/3 my-6 mx-auto">
                                    {/* content */}
                                    <div
                                        style={{ maxHeight: '270px' }}
                                        className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-6"
                                    >
                                        <div
                                            style={{
                                                position: 'relative',
                                                top: '-90px',
                                            }}
                                            className="flex flex-col -bottom-2"
                                        >
                                            <span className="successTick self-center"></span>
                                            <h1 className="mb-2 mt-3 text-lg text-center font-bold font-manrope">
                                                Successful
                                            </h1>
                                            <p className="text-center my-2">{popupContent}</p>
                                            <div className="flex justify-center mt-3">
                                                <button
                                                    onClick={() => {
                                                        setShowSuccessModal(false);
                                                    }}
                                                    className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                                >
                                                    Ok
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Modal>

                    {studentData?.mentorRequestId !== null ? (
                        <div>
                            <div className="rounded mb-3 py-6 px-4 bg-purple-100 flex text-purple-950 ">
                                <div className="mt-3">
                                    <ClockIcon size={40} />
                                </div>

                                <div className="flex flex-col ml-4">
                                    <div className="font-bold text-lg font-manrope mb-3 mt-1">
                                        {studentData?.mentor !== null
                                            ? 'You have been assigned a mentor.'
                                            : 'You have Requested for a mentor.'}
                                    </div>
                                    {studentData?.mentor !== null ? (
                                        <Paragraph>
                                            Now you can view details about your assigned mentor
                                            through your profile and contact him/her to get active
                                            mentorship.
                                        </Paragraph>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div
                                className="rounded mb-8 py-6 text-white p-5"
                                style={{
                                    background:
                                        'linear-gradient(93.16deg, #6E41E2 1.57%, #0C247A 98.41%)',
                                }}
                            >
                                <div className="font-bold font-manrope text-lg mb-3 ">
                                    Request a Mentor
                                </div>
                                <Paragraph>
                                    Mentoring can provide valuable support for students at critical
                                    points in their student life, it offers a host of other benefits
                                    for both mentor and mentee. Get mentoring support through IT
                                    Field specialists in Sri Lanka and grow a bright career for
                                    yourself.
                                </Paragraph>

                                {challengesStatus?.completed?.length < 25 ? (
                                    <>
                                        <div className="font-bold font-manrope text-lg mt-6 mb-3 ">
                                            Complete {challengesNeedForMentor} more challenges to
                                            Request a Mentor!
                                        </div>
                                    </>
                                ) : null}
                            </div>

                            <div>
                                <div className="font-bold text-lg font-manrope mb-3 pl-5">Note</div>
                                <Paragraph className="rounded mb-3 p-5 bg-purple-100 flex flex-col text-purple-950">
                                    Upon requesting a mentor Future Careers BRIDGE is trying to
                                    connect the most suitable mentor available to the mentee in
                                    order to ensure that the mentee gets the required guidance to be
                                    successful in their career. Therefore, we will do the mentor
                                    matching offline and send you an email with the details of the
                                    mentor assigned to you so that you can carry on the
                                    communication with the assigned mentor through any of the
                                    communication channels you and your mentor prefers.
                                </Paragraph>
                            </div>
                            <div>
                                <input
                                    className="mr-1 mt-1"
                                    id="public_profile_consent"
                                    type="checkbox"
                                    checked={isPublic}
                                    onChange={() => {
                                        setIsPublic(!isPublic);
                                    }}
                                    disabled={challengesStatus?.completed?.length < 25}
                                />
                                <label
                                    className="font-manrope text-xs"
                                    htmlFor="public_profile_consent"
                                >
                                    Keep my profile publicly linked and accessible through the
                                    BRIDGE website leaderboard and via QR code on the certificates.
                                </label>
                            </div>
                            {isErrorPublicProfile ? (
                                <div className="text-red-500 font-manrope text-sm my-1">
                                    You must enable public profile in order to request a mentor
                                </div>
                            ) : null}
                            <div className="px-3">
                                {' '}
                                <div className="flex flex-row  mb-3">
                                    <div className="flex flex-col my-1  w-full md:w-2/5">
                                        <label
                                            className="flex flex-row my-1 font-manrope text-xs"
                                            htmlFor="tab_1"
                                        >
                                            Select Specialization area
                                        </label>
                                        {challengesStatus?.completed?.length >= 25 ? (
                                            <div
                                                className={`flex relative cursor-pointer items-center focus:outline-none h-12 md:p-2 focus:border-blue-900 ${
                                                    isErrorSpecializedArea
                                                        ? 'inputFieldErrorThickness'
                                                        : 'inputFieldThickness'
                                                } `}
                                            >
                                                <div
                                                    className="flex justify-between w-full"
                                                    onClick={() =>
                                                        setSpecializedAreaDropdownVisible(true)
                                                    }
                                                >
                                                    <div
                                                        className={` font-medium pl-2 ${
                                                            trackFilterspecializedAreaAction !==
                                                            'Select your specialized area'
                                                                ? 'text-gray-700 font-medium'
                                                                : 'text-gray-400'
                                                        }`}
                                                    >
                                                        {trackFilterspecializedAreaAction}
                                                    </div>
                                                    <div className="">
                                                        <div className="flex flex-row">
                                                            <div className="flex flex-col">
                                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                <HiIcons.HiChevronDown className="mr-2" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {specializedAreaDropdownVisible ? (
                                                    <ul
                                                        className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                        role="menu"
                                                        onMouseLeave={() =>
                                                            setSpecializedAreaDropdownVisible(false)
                                                        }
                                                    >
                                                        {tagData.map(tag => (
                                                            <div
                                                                className="p-2 pl-2 hover:bg-gray-200 "
                                                                key={tag.id}
                                                                onClick={() => {
                                                                    setIsErrorSpecializedArea(
                                                                        false
                                                                    );
                                                                    setTrackFilterspecializedAreaAction(
                                                                        tag.tagName
                                                                    );
                                                                    setSpecializedAreaDropdownVisible(
                                                                        false
                                                                    );
                                                                }}
                                                            >
                                                                {tag.tagName}
                                                            </div>
                                                        ))}
                                                    </ul>
                                                ) : null}
                                            </div>
                                        ) : (
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                type="text"
                                                id="tab_1"
                                                value={''}
                                                disabled
                                            />
                                        )}
                                        {isErrorSpecializedArea ? (
                                            <div className="text-red-500 font-manrope text-sm my-1">
                                                Select a specialized area
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="flex flex-col my-1 w-full md:w-5/6 mt-2">
                                    <label
                                        className="flex flex-row my-1 font-manrope text-xs"
                                        htmlFor="tab_1"
                                    >
                                        Describe what you are looking for?
                                    </label>
                                    <textarea
                                        className={`pl-2  pt-3 pb-28 h-12 ${
                                            isErrorDescription
                                                ? 'inputFieldErrorThickness'
                                                : 'inputFieldThickness'
                                        }`}
                                        disabled={challengesStatus?.completed?.length < 25}
                                        id="tab_1"
                                        value={description}
                                        placeholder="Description ..."
                                        onChange={e => {
                                            setDescription(e.target.value);
                                            if (e.target.value) {
                                                setIsErrorDescription(false);
                                            }
                                        }}
                                    />
                                    <div className="text-gray-400 font-manrope text-xs">
                                        Max 500 words
                                    </div>
                                    {isErrorDescription ? (
                                        <div className="text-red-500 text-xs my-1">
                                            {description.length > 500
                                                ? 'Maximum word count exceeded'
                                                : 'Description is mandatory'}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="flex mt-3">
                                    <button
                                        // disabled={mcq.some(answer => answer.selectedAnswer === null)}
                                        onClick={() => {
                                            submit();
                                        }}
                                        className="mb-3 mr-16 rounded border-2 font-semibold w-44 h-10 justify-center items-center flex text-white bg-purple-950 border-purple-950"
                                    >
                                        {isMentorRequestLoading ? <Loader /> : 'Request a mentor'}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="pl-2 lg:pl-0">
                        {' '}
                        <MostPopularMentors isLargeScreen={viewPortWidth > 1023} />
                    </div>
                    {/* <Testimonies /> */}
                </div>
            )}
        </>
    );
};

export default RequestMentor;
