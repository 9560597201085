import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import Loader from '../../assets/svg/Loader';
import { restoreConfirmationMessage } from '../../constants';
import { BadgeActivateType } from '../../redux/badges/reducers';
import { CertificateType } from '../../redux/certificates/reducers';
import { CompanyEditType } from '../../redux/companies/reducers';
import { EventEditType } from '../../redux/events/reducers';
import { InstitutesEditType } from '../../redux/institutes/reducers';
import { MentorActivateType } from '../../redux/mentors/reducers';
import {
    editBadge,
    editCertificate,
    editCompany,
    editEvent,
    editInstitute,
    editMentor,
    editStudent,
    editUser,
    StudentEditType,
} from '../../redux/rootActions';
import { RootState } from '../../redux/rootReducer';
import { ActivateUserType } from '../../redux/users/reducers';
interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    studentId?: string;
    instituteId?: string;
    institute?: InstitutesEditType;
    student?: StudentEditType;
    company?: CompanyEditType;
    certificate?: CertificateType;
    certificateId?: string;
    companyId?: string;
    eventId?: string;
    event?: EventEditType;
    badgeId?: string;
    badge?: BadgeActivateType;
    mentorId?: string;
    mentor?: MentorActivateType;
    setshowMenu: (value: boolean) => void;
    tabStatus: string;
    userId?: string;
    user?: ActivateUserType;
}

export default function RestoreConfirmationModal({
    modalVisible,
    setModalVisible,
    studentId,
    student,
    certificate,
    instituteId,
    certificateId,
    institute,
    companyId,
    company,
    eventId,
    event,
    badgeId,
    badge,
    mentorId,
    mentor,
    userId,
    user,
    setshowMenu,
    tabStatus,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const isStudentEditLoading = useSelector((state: RootState) => state.students.isEditLoading);
    const isInstituteEditLoading = useSelector(
        (state: RootState) => state.institutes.isEditLoading
    );
    const isCompanyEditLoading = useSelector((state: RootState) => state.companies.isEditLoading);
    const isCertificateEditLoading = useSelector(
        (state: RootState) => state.certificates.isEditLoading
    );
    const isEventEditLoading = useSelector((state: RootState) => state.events.isEditLoading);
    const isBadgeEditLoading = useSelector((state: RootState) => state.badges.isEditLoading);
    const isMentorEditLoading = useSelector((state: RootState) => state.mentors.isEditLoading);
    const isUserEditLoading = useSelector((state: RootState) => state.users.isDeleteLoading);

    return (
        <Modal
            open={modalVisible}
            onClose={() => {
                setModalVisible(false);
            }}
            center
            styles={{ modal: { borderRadius: 12, maxWidth: 555 } }}
            focusTrapped={false}
        >
            <div className="px-2 mt-10">
                <div className="text-lg ml-4" style={{ color: '#9698A9' }}>
                    {`${restoreConfirmationMessage} ${
                        studentId
                            ? 'student?'
                            : companyId
                            ? 'company?'
                            : certificateId
                            ? 'certificate?'
                            : eventId
                            ? 'event?'
                            : badgeId
                            ? 'badge?'
                            : mentorId
                            ? 'mentor?'
                            : userId
                            ? ' user?'
                            : 'institute?'
                    }`}
                </div>
                <div className="mt-8 flex justify-end">
                    <button
                        className="border rounded-md border-blue-600 text-blue-600 text-sm h-10 px-8 mr-2 focus:outline-none"
                        onClick={() => {
                            setModalVisible(false);
                            setshowMenu(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="border bg-blue-600 rounded-md text-white text-sm h-10 leading-4 px-8 ml-2 focus:outline-none hover:text-white hover:bg-blue-800"
                        onClick={async () => {
                            if (studentId) {
                                dispatch(editStudent(studentId, student, tabStatus));
                            } else if (instituteId) {
                                dispatch(editInstitute(instituteId, institute, tabStatus));
                            } else if (companyId) {
                                dispatch(editCompany(companyId, company, tabStatus));
                            } else if (certificateId) {
                                dispatch(editCertificate(certificateId, certificate, tabStatus));
                            } else if (eventId) {
                                dispatch(editEvent(eventId, event, tabStatus));
                            } else if (badgeId) {
                                dispatch(editBadge(badgeId, badge, tabStatus));
                            } else if (mentorId) {
                                dispatch(editMentor(mentorId, mentor, tabStatus));
                            } else if (userId) {
                                dispatch(editUser(userId, user, tabStatus));
                            }
                            setModalVisible(false);
                            setshowMenu(false);
                        }}
                    >
                        {isStudentEditLoading ? (
                            <Loader />
                        ) : isInstituteEditLoading ? (
                            <Loader />
                        ) : isCompanyEditLoading ? (
                            <Loader />
                        ) : isCertificateEditLoading ? (
                            <Loader />
                        ) : isEventEditLoading ? (
                            <Loader />
                        ) : isBadgeEditLoading ? (
                            <Loader />
                        ) : isMentorEditLoading ? (
                            <Loader />
                        ) : isUserEditLoading ? (
                            <Loader />
                        ) : (
                            'Restore'
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
