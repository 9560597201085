import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AddTabs from './AddTabs';
import Add from '../../../assets/svg/Add';
import { addChallengeTab, getChallengesTabs, students } from '../../../redux/rootActions';
import moment from 'moment';
import { USER_PROFILE } from '../../../utils/storage';
import { RootState } from '../../../redux/rootReducer';
interface Props {
    selectedTab: number;
    setSelectedTab: (value: number) => void;
}

export default function TabView({ selectedTab, setSelectedTab }: Props): ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showModal, setShowModal] = React.useState(false);
    const [tab, setTab] = useState([]);
    const [tabName, setTabName] = useState<string>();
    const [selectedTabFilteration, setSelectedTabFilteration] = useState<any>();

    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const challengeTabData = useSelector(
        (state: RootState) => state.filteredChallenge.challengeTabData
    );

    const [studentLessThan, setStudentLessThan] = useState<string>();
    const [studentMoreThan, setStudentMoreThan] = useState<string>();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    // eslint-disable-next-line
    const [newStartDate, setNewStartDate] = useState<any>();
    // eslint-disable-next-line
    const [newEndDAte, setNEWEndDate] = useState<any>();
    const [tags, setTags] = useState([]);
    const [tracks, setTracks] = useState([]);
    const [program, setProgram] = useState([]);
    const [status, setStatus] = useState<string>();

    useEffect(() => {
        if (selectedTab !== 1) {
            setSelectedTabFilteration(
                tab?.filter(action => action.id === selectedTab - 2).map(data => data?.filters)[0]
            );
        }
    }, [challengeTabData, selectedTab]);

    useEffect(() => {
        dispatch(getChallengesTabs(profile?.userId, 'CHALLENGE'));
    }, []);

    useEffect(() => {
        if (selectedTab === selectedTabFilteration?.id + 2) {
            dispatch(
                addChallengeTab({
                    id: selectedTabFilteration?.id,
                    tabName: selectedTabFilteration?.tabName,
                    program: selectedTabFilteration?.program?.length,
                    tags: selectedTabFilteration?.tags,
                    tracks: selectedTabFilteration?.tracks,
                    students: selectedTabFilteration?.students,
                })
            );
        }
    }, [selectedTab, selectedTabFilteration]);
    // }, [selectedTab, selectedTabFilteration, challengeTabData]);

    useEffect(() => {
        setTab(challengeTabData);
    }, [challengeTabData]);

    const onAdd = () => {
        if (!tab.includes(tabName)) {
            setTab([...tab, tabName]);
        }

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const students: students = {
            from: studentLessThan,
            to: studentMoreThan,
        };
        dispatch(
            addChallengeTab({
                tabName: tabName,
                program: program.length,
                tags: tags,
                tracks: tracks,
                students: students,
                trackLastUpdatedBy: 'Hasith Perera',
                trackCreatedBy: 'Hasith Perera',
            })
        );
    };

    useEffect(() => {
        setNewStartDate(moment(startDate).format('YYYY-MM-DD'));
        setNEWEndDate(moment(endDate).format('YYYY-MM-DD'));
    }, [endDate, startDate]);
    return (
        <>
            <div className="flex items-center">
                <div className={`flex w-full`}>
                    <div
                        className={`border-b-4 relative cursor-pointer w-1/2 md:w-1/3 lg:w-1/6  ${
                            selectedTab === 1 ? `border-blue-700` : 'border-snow-500'
                        }`}
                        role="presentation"
                        onClick={() => setSelectedTab(1)}
                    >
                        <div
                            className={`py-2 text-xs sm:text-lg absolute bottom-0 flex w-full justify-center ${
                                selectedTab === 1 ? `text-blue-700 font-semibold` : 'text-black-300'
                            }`}
                        >
                            All Challenges
                        </div>
                    </div>
                    {/* {tab?.length > 0
                        ? tab?.map(action => (
                              <div
                                  className={`border-b-4 relative cursor-pointer w-1/2 md:w-1/6  ${
                                      selectedTab === action?.filters?.id + 2
                                          ? `border-blue-700`
                                          : 'border-snow-500'
                                  }`}
                                  role="presentation"
                                  key={action?.filters?.id}
                                  onClick={() => {
                                      setSelectedTab(action?.filters?.id + 2);
                                  }}
                              >
                                  <div
                                      className={`py-2 text-xs sm:text-lg absolute bottom-0 flex w-full text-center justify-center ${
                                          selectedTab === 2
                                              ? `text-blue-700 font-semibold`
                                              : 'text-black-300'
                                      }`}
                                  >
                                      {action?.filters?.tabName}
                                  </div>
                              </div>
                          ))
                        : null} */}
                    <div
                        className={`border-b-4 relative cursor-pointer pl-4 ${
                            selectedTab === 2 ? `` : ''
                        }`}
                        role="presentation"
                    >
                        <div className="py-2 text-xs sm:text-lg absolute bottom-0 text-center flex justify-center w-full">
                            {/* <button onClick={() => setShowModal(true)}>
                                <Add size={35} />
                            </button> */}

                            <AddTabs
                                isSelected={showModal}
                                setIsSelected={setShowModal}
                                onAdd={onAdd}
                                tabName={tabName}
                                setTabName={setTabName}
                                studentLessThan={studentLessThan}
                                setStudentLessThan={setStudentLessThan}
                                studentMoreThan={studentMoreThan}
                                setStudentMoreThan={setStudentMoreThan}
                                status={status}
                                setStatus={setStatus}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDAte={endDate}
                                setEndDate={setEndDate}
                                tracks={tracks}
                                setTracks={setTracks}
                                tags={tags}
                                setTags={setTags}
                                program={program}
                                setProgram={setProgram}
                            />
                        </div>
                    </div>
                    <div className="border-b-4 text-sm border-snow-500 w-2/4 md:w-full lg:w-full">
                        <div
                            className="text-right right-0 flex justify-end text-blue-900 py-1"
                            role="presentation"
                        >
                            <button
                                className="py-1 px-2 md:px-4 bg-blue-700 text-white text-sm rounded-sm focus:outline-none h-12"
                                onClick={() => history.push('/admin/challenges/new')}
                            >
                                Add New Challenge
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
