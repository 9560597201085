import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
// import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';

const PREFIX = '@ANALYTICS';

export const GET_ANALYTICS = PREFIX + 'GET_ANALYTICS';
export const GET_ANALYTICS_SUCCESS = PREFIX + 'GET_ANALYTICS_SUCCESS';
export const GET_ANALYTICS_FAILED = PREFIX + 'GET_ANALYTICS_FAILED';
export const CLEAR_ANALYTICS_MESSAGES = PREFIX + 'CLEAR_ANALYTICS_MESSAGES';

export const DOWNLOAD_CSV_ANALYTICS = PREFIX + 'DOWNLOAD_CSV_ANALYTICS';
export const DOWNLOAD_CSV_ANALYTICS_SUCCESS = PREFIX + 'DOWNLOAD_CSV_ANALYTICS_SUCCESS';
export const DOWNLOAD_CSV_ANALYTICS_FAILED = PREFIX + 'DOWNLOAD_CSV_ANALYTICS_FAILED';
export const DOWNLOAD_CSV_ANALYTICS_RESET = PREFIX + 'DOWNLOAD_CSV_ANALYTICS_RESET';

export const getAnalytics =
    (
        page: number,
        size: number,
        trackId?: number | '',
        searchTerm?: string,
        hometown?: string,
        avgScoreMin?: string,
        avgScoreMax?: string,
        gender?: string
    ) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_ANALYTICS });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/leaderboard/?trackId=${trackId || ''}${`&page=${page | 0}`}${
                    size ? `&size=${size}` : ''
                }${searchTerm ? `&searchTerm=${searchTerm}` : ''}${
                    hometown ? `&hometown=${hometown}` : ''
                }${avgScoreMin ? `&avgScoreMin=${avgScoreMin}` : ''}${
                    avgScoreMax ? `&avgScoreMax=${avgScoreMax}` : ''
                }${gender ? `&gender=${gender}` : ''}`,
                config
            );
            dispatch({
                type: GET_ANALYTICS_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_ANALYTICS_FAILED, payload: getErrorMessage(error) });
        }
    };

export const clearAnalyticsMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_ANALYTICS_MESSAGES });
};

export const downloadCSVAnalytics = (trackId?: number | '') => async (dispatch: any) => {
    try {
        dispatch({ type: DOWNLOAD_CSV_ANALYTICS });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/csv/leaderboard?trackId=${trackId || ''}`,
            config
        );
        dispatch({ type: DOWNLOAD_CSV_ANALYTICS_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: DOWNLOAD_CSV_ANALYTICS_FAILED, payload: getErrorMessage(error) });
    }
};
