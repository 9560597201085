import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Add from '../../../assets/svg/Add';
import { addInstitueTab, getInstituteTabs, joinedDate } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { USER_PROFILE } from '../../../utils/storage';
import AddTabs from './AddTabs';
interface Props {
    selectedTab: number;
    setSelectedTab: (value: number) => void;
}

export default function TabView({ selectedTab, setSelectedTab }: Props): ReactElement {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = React.useState(false);
    const [tab, setTab] = useState([]);
    const [tabName, setTabName] = useState<string>();
    const [selectedTabFilteration, setSelectedTabFilteration] = useState<any>();

    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const instituteTabData = useSelector(
        (state: RootState) => state.filteredInstitutes.instituteTabData
    );

    const [studentLessThan, setStudentLessThan] = useState<string>();
    const [studentMoreThan, setStudentMoreThan] = useState<string>();
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [joinStartDate, setJoinStartDate] = useState<any>();
    const [joinEndDAte, setJoinEndDate] = useState<any>();
    const [dateSelected, setDateSelected] = useState(false);
    const [joinDateSelected, setJoinDateSelected] = useState(false);
    const [challenge, setChallenge] = useState([]);
    const [tags, setTags] = useState([]);
    const [category, setCategory] = useState([]);
    const [status, setStatus] = useState<string>();
    const [tracks, setTracks] = useState([]);
    const [institue, setInstitue] = useState([]);
    const [homeTown, setHomeTown] = useState<string>();

    useEffect(() => {
        if (selectedTab !== 1) {
            setSelectedTabFilteration(
                tab?.filter(action => action.id === selectedTab - 2).map(data => data?.filters)[0]
            );
        }
    }, [instituteTabData, selectedTab]);

    useEffect(() => {
        dispatch(getInstituteTabs(profile?.userId, 'INSTITUTE'));
    }, [dispatch]);

    useEffect(() => {
        if (selectedTab === selectedTabFilteration?.id + 2) {
            dispatch(
                addInstitueTab({
                    id: selectedTabFilteration?.id,
                    tabName: selectedTabFilteration?.tabName,
                    joinedDate: selectedTabFilteration?.joinedDate,
                    institutes: selectedTabFilteration?.institutes,
                    categories: selectedTabFilteration?.categories,
                })
            );
        }
    }, [dispatch, selectedTab, selectedTabFilteration]);

    useEffect(() => {
        setTab(instituteTabData);
    }, [instituteTabData]);

    const onAdd = () => {
        if (!tab.includes(tabName)) {
            setTab([...tab, tabName]);
        }

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const joinedDate: joinedDate = {
            from: moment(joinStartDate)?.format('YYYY-MM-DD')?.toString(),
            to: moment(joinEndDAte)?.format('YYYY-MM-DD')?.toString(),
        };

        dispatch(
            addInstitueTab({
                tabName: tabName,
                joinedDate: joinedDate,
                institutes: institue,
                categories: category,
                dateSelected: dateSelected,
                joinDateSelected: joinDateSelected,
            })
        );
    };

    return (
        <>
            <div className="flex items-center">
                <div className={`flex w-full`}>
                    <div
                        className={`border-b-4 relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/6   ${
                            selectedTab === 1 ? `border-blue-700` : 'border-snow-500'
                        }`}
                        role="presentation"
                        onClick={() => setSelectedTab(1)}
                    >
                        <div
                            className={`py-2 text-xs sm:text-lg absolute bottom-0 flex w-full justify-center ${
                                selectedTab === 1 ? `text-blue-700 font-semibold` : 'text-black-300'
                            }`}
                        >
                            All Institutes
                        </div>
                    </div>
                    {/* {tab?.length > 0
                        ? tab?.map(action => (
                              <div
                                  className={`border-b-4 relative cursor-pointer w-1/2 md:w-1/6  ${
                                      selectedTab === action?.filters?.id + 2
                                          ? `border-blue-700`
                                          : 'border-snow-500'
                                  }`}
                                  role="presentation"
                                  key={action?.filters?.id}
                                  onClick={() => {
                                      setSelectedTab(action?.filters?.id + 2);
                                  }}
                              >
                                  <div
                                      className={`py-2 text-xs sm:text-lg absolute bottom-0 flex w-full text-center justify-center ${
                                          selectedTab === 2
                                              ? `text-blue-700 font-semibold`
                                              : 'text-black-300'
                                      }`}
                                  >
                                      {action?.filters?.tabName}
                                  </div>
                              </div>
                          ))
                        : null} */}
                    <div
                        className={`border-b-4 relative cursor-pointer pl-4 ${
                            selectedTab === 2 ? `` : ''
                        }`}
                        role="presentation"
                    >
                        <div className="py-2 text-xs sm:text-lg absolute bottom-0 text-center flex justify-center w-full">
                            <button onClick={() => setShowModal(true)}>
                                {/* <Add size={35} /> */}
                                <AddTabs
                                    isSelected={showModal}
                                    setIsSelected={setShowModal}
                                    onAdd={onAdd}
                                    tabName={tabName}
                                    setTabName={setTabName}
                                    studentLessThan={studentLessThan}
                                    setStudentLessThan={setStudentLessThan}
                                    studentMoreThan={studentMoreThan}
                                    setStudentMoreThan={setStudentMoreThan}
                                    status={status}
                                    setStatus={setStatus}
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDAte={endDate}
                                    setEndDate={setEndDate}
                                    challenges={challenge}
                                    setChallenges={setChallenge}
                                    tags={tags}
                                    setTags={setTags}
                                    category={category}
                                    setCategory={setCategory}
                                    tracks={tracks}
                                    setTracks={setTracks}
                                    institue={institue}
                                    setInstitue={setInstitue}
                                    homeTown={homeTown}
                                    setHomeTown={setHomeTown}
                                    joinStartDate={joinStartDate}
                                    setJoinStartDate={setJoinStartDate}
                                    joinEndDAte={joinEndDAte}
                                    setJoinEndDate={setJoinEndDate}
                                    joinDateSelected={joinDateSelected}
                                    setJoinDateSelected={setJoinDateSelected}
                                    dateSelected={dateSelected}
                                    setDateSelected={setDateSelected}
                                />
                            </button>
                        </div>
                    </div>
                    <div className="border-b-4 text-sm border-snow-500 w-2/4 md:w-full lg:w-full">
                        <div
                            className="text-right right-0 flex justify-end text-blue-900 py-1"
                            role="presentation"
                        >
                            <button
                                className="py-1 px-3 md:px-4 bg-blue-700 text-white text-sm rounded-sm focus:outline-none h-12"
                                onClick={() => history.push('/admin/institutes/new')}
                            >
                                Add New Institute
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
