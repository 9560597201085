import React, { useEffect, useState } from 'react';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { months } from '../constants';

interface Props {
    startDate?: string;
    endDate?: string;
    setStartDate?: (value: string) => void;
    setEndDate?: (value: string) => void;
}

export default function DateTimePicker({ setStartDate, setEndDate, startDate, endDate }: Props) {
    const [pickerStartDate, setPickerStartDate] = useState<string>(
        moment(startDate).format('YYYY-MM-DD')
    );
    const [pickerEndDate, setPickerEndDate] = useState<string>(
        moment(endDate).format('YYYY-MM-DD')
    );
    const format = 'hh:mm A';
    const isViewOnly = window.location.pathname.includes('/view');
    const [startTime, setStartTime] = useState(moment(startDate).format('HH:mm'));
    const [endTime, setEndTime] = useState(moment(endDate).format('HH:mm'));
    const onChangeStartTime = value => {
        setStartTime(value && value.format('HH:mm'));
    };
    const onChangeEndTime = value => {
        setEndTime(value && value.format('HH:mm'));
    };
    useEffect(() => {
        const start = startTime.split(':');
        const end = endTime.split(':');
        const newStartdate = pickerStartDate.split('-', 3);
        const newEndDate = pickerEndDate.split('-', 3);
        const newdate = new Date(
            parseInt(newStartdate[0]),
            parseInt(newStartdate[1]) - 1,
            parseInt(newStartdate[2]),
            parseInt(start[0]),
            parseInt(start[1])
        );
        const endDateObject = new Date(
            parseInt(newEndDate[0]),
            parseInt(newEndDate[1]) - 1,
            parseInt(newEndDate[2]),
            parseInt(end[0]),
            parseInt(end[1])
        );
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        setStartDate && setStartDate(moment(newdate).toISOString());
        setEndDate && setEndDate(moment(endDateObject).toISOString());
    }, [pickerStartDate, pickerEndDate, startTime, endTime, setStartDate, setEndDate]);

    return (
        <div className="flex flex-col my-1.5 w-3/4 md:w-4/5 text-sm 2xl:text-base text-gray-700">
            <label className="my-1 font-medium">Start Date and Time</label>
            <div className="flex flex-row mb-2 lg:mb-3">
                <input
                    className={`rounded flex items-center border-gray-300 border-gray-300 focus:outline-none w-2/3 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                    style={{ height: '44px' }}
                    type="date"
                    defaultValue={
                        startDate
                            ? moment(startDate).format('YYYY-MM-DD')
                            : moment().format('YYYY-MM-DD')
                    }
                    onChange={e => {
                        setPickerStartDate(e.target.value);
                    }}
                    autoComplete="off"
                    disabled={isViewOnly}
                />
                <label className="mr-2 md:ml-5 xl:ml-12 hidden xl:flex items-center mt-0.5">
                    Time
                </label>
                <div className="flex flex-col w-1/2 lg:w-1/5 2xl:w-1/4">
                    <TimePicker
                        showSecond={false}
                        defaultValue={startDate ? moment(startDate) : moment()}
                        className="cursor-pointer"
                        onChange={onChangeStartTime}
                        format={format}
                        use12Hours
                        inputReadOnly
                        disabled={isViewOnly}
                    />
                </div>
            </div>

            <label className="my-1 font-medium">End Date and Time</label>
            <div className="flex flex-row">
                <input
                    className={`rounded flex items-center border-gray-300 border-gray-300 focus:outline-none w-2/3 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                    type="date"
                    style={{ height: '44px' }}
                    defaultValue={
                        startDate
                            ? moment(endDate).format('YYYY-MM-DD')
                            : moment().format('YYYY-MM-DD')
                    }
                    onChange={e => {
                        setPickerEndDate(e.target.value);
                    }}
                    autoComplete="off"
                    disabled={isViewOnly}
                    min={pickerStartDate}
                />
                <label className="mr-2 md:ml-5 xl:ml-12 hidden xl:flex items-center mt-0.5">
                    Time
                </label>
                <div className="flex flex-col w-1/2 lg:w-1/5 2xl:w-1/4">
                    <TimePicker
                        showSecond={false}
                        defaultValue={endDate ? moment(endDate) : moment()}
                        className="cursor-pointer"
                        onChange={onChangeEndTime}
                        format={format}
                        use12Hours
                        inputReadOnly
                        disabled={isViewOnly}
                    />
                </div>
            </div>
        </div>
    );
}
