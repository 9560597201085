import { ContactType } from '../students/reducers';
import { TracksType } from '../tracktab/reducers';
import {
    ADD_ANALYTICS_TAB,
    ADD_ANALYTICS_TAB_FAILED,
    ADD_ANALYTICS_TAB_SUCCESS,
    GET_ANALYTICS_TAB,
    GET_ANALYTICS_TAB_FAILED,
    GET_ANALYTICS_TAB_SUCCESS,
} from './actions';

export interface analyticsType {
    id: string;
    userId: string;
    userName: string;
    gender: string;
    userCreatedDate: string;
    track: TracksType;
    analysedSubmissions: any[];
    emailId: string;
    averageScore: number;
    noOfChallenges: number;
    contact: ContactType;
}
export interface CountType {
    ALL: number;
    DELETED: number;
    ACTIVE: number;
    DRAFT: number;
}
export interface AnalyticsTabInitialState {
    analyticsData: analyticsType[];
    pageCount: number;
    analyticsTabData: [];
    count: any;
    isLoading: boolean;
    analyticsError: string | null;
}

const initialState: AnalyticsTabInitialState = {
    analyticsData: [],
    pageCount: null,
    analyticsTabData: [],
    count: null,
    isLoading: false,
    analyticsError: null,
};

const analyticsTabReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_ANALYTICS_TAB:
            return { ...state, isLoading: true, trackError: null };
        case ADD_ANALYTICS_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                analyticsData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case ADD_ANALYTICS_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        case GET_ANALYTICS_TAB:
            return { ...state, isLoading: true, trackError: null };
        case GET_ANALYTICS_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                analyticsTabData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case GET_ANALYTICS_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        default:
            return state;
    }
};

export default analyticsTabReducer;
