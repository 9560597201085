import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import * as BiIcons from 'react-icons/bi';
import { clearUsersMessages, editUser } from '../../../redux/rootActions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { phoneRegExp, s3Config, s3UrlPrefix } from '../../../constants';
import S3 from 'react-aws-s3';
import Loader from '../../../assets/svg/Loader';
import UsersSuccessModal from '../../modals/UsersSuccessModal';
import { SUPER_ADMIN, SLASSCOM_ADMIN, COMPANY_ADMIN } from '../../../redux/authAdmin/reducers';

const MyProfile = () => {
    const dispatch = useDispatch();
    const formRef = useRef<any>();
    const user = useSelector((state: RootState) => state.users.User);
    const isEditLoading = useSelector((state: RootState) => state.users.isEditLoading);
    const updateError = useSelector((state: RootState) => state.users.editUserError);
    const updateSuccessMessage = useSelector(
        (state: RootState) => state.users.UserUpdateSuccessMessage
    );
    const [imageLocation, setImageLocation] = useState(user?.imageUrl);
    const [imageUrl, setImageUrl] = useState(`${s3UrlPrefix}${user?.imageUrl}`);
    const [editProfileClicked, setEditProfileClicked] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [editSuccessVisible, setEditSuccessVisible] = useState(false);

    const newFileName = Date.now().toString();

    const handleImage = (e: any) => {
        const _URL = window.URL || window.webkitURL;
        let file: File, img;
        if ((file = e.target.files[0])) {
            img = new Image();
            img.src = _URL.createObjectURL(file);
            setImageUrl(img.src);
        }
    };
    const uploadImageToS3 = async e => {
        setIsUploading(true);
        s3Config.dirName = `admin/users/${newFileName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(e.target.files[0], newFileName)
            .then(data => {
                setImageLocation(data.key);
                setIsUploading(false);
            })
            .catch(err => {
                console.error(err);
                setIsUploading(false);
            });
    };

    const handleClearMessages = useCallback(() => {
        if (updateError || updateSuccessMessage) {
            setEditSuccessVisible(true);
        }
    }, [dispatch, updateSuccessMessage, updateError]);
    const handleClose = () => {
        dispatch(clearUsersMessages());
        setEditSuccessVisible(false);
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    return (
        <div className="mx-6 lg:ml-2 h-full">
            <div className="bg-snow-600">
                <div className="relative">
                    <Formik
                        enableReinitialize={true}
                        innerRef={formRef}
                        initialValues={{
                            firstName: user?.firstName,
                            lastName: user?.lastName,
                            mobileNo: user?.contact?.mobileNo,
                            imageUrl: imageUrl,
                            emailId: user?.emailId,
                        }}
                        validationSchema={Yup.object({
                            firstName: Yup.string()
                                .nullable()
                                .max(100, 'First Name should be less than 100 characters')
                                .required('First Name is required'),
                            lastName: Yup.string()
                                .nullable()
                                .max(100, 'Last Name should be less than 100 characters')
                                .required('Last Name is required'),

                            mobileNo: Yup.string()
                                .nullable()
                                .matches(phoneRegExp, 'Contact number is not valid')
                                .required('Contact number is required'),
                            imageUrl: Yup.string().required('Image is required'),
                        })}
                        onSubmit={async values => {
                            dispatch(
                                editUser(user?.id, {
                                    firstName: values.firstName.trim(),
                                    lastName: values.lastName.trim(),
                                    imageUrl: imageLocation,
                                    emailId: values.emailId,
                                    contact: { mobileNo: values.mobileNo },
                                })
                            );
                            values.firstName = values.firstName.trim();
                            values.lastName = values.lastName.trim();
                            setEditProfileClicked(false);
                        }}
                    >
                        {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                            <div className="grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2 pb-8 mb-6">
                                <div className="col-span-12 md:col-span-6 mb-2 md:mb-4">
                                    <div className="mb-8 mt-6 flex flex-row justify-between">
                                        <div className="flex flex-row">
                                            <div className="flex flex-col justify-center items-center ">
                                                <img
                                                    className={`blur-3xl rounded-full object-cover h-36 w-36 cursor-pointer`}
                                                    src={`${
                                                        imageLocation
                                                            ? imageUrl
                                                            : user?.imageUrl
                                                            ? `${s3UrlPrefix}${user?.imageUrl}`
                                                            : '/assets/user.png'
                                                    }`}
                                                    alt="profile"
                                                />

                                                {editProfileClicked && (
                                                    <label
                                                        htmlFor="imageUrl"
                                                        className={`bottom-16 block px-3 py-2 text-base font-medium text-gray-700 no-underline bg-white cursor-pointer`}
                                                    >
                                                        {isUploading ? (
                                                            <Loader />
                                                        ) : (
                                                            <BiIcons.BiEditAlt
                                                                className="mt-2"
                                                                style={{
                                                                    height: '1.5em',
                                                                    width: '1.5em',
                                                                }}
                                                            />
                                                        )}
                                                    </label>
                                                )}
                                                {errors.imageUrl && touched.imageUrl ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {errors.imageUrl}
                                                    </div>
                                                ) : null}
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="imageUrl"
                                                    type="file"
                                                    accept="image/png, image/jpeg"
                                                    onChange={async e => {
                                                        const file = [];
                                                        file.push(e.target.files[0]);
                                                        setFieldValue('imageUrl', e);
                                                        handleImage(e);
                                                        uploadImageToS3(e);
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className={`flex flex-col justify-center items-start ${
                                                    editProfileClicked ? 'mb-12' : 'mt-4 mb-2'
                                                } ml-6`}
                                            >
                                                <p className="text-lg font-bold mb-2">
                                                    {user?.firstName}
                                                </p>
                                                <p className="text-sm text-gray-500 mb-2">
                                                    {user?.roles[0]?.roleName === SUPER_ADMIN
                                                        ? 'Super Admin'
                                                        : user?.roles[0]?.roleName ===
                                                          SLASSCOM_ADMIN
                                                        ? 'SLASSCOM Admin'
                                                        : user?.roles[0]?.roleName === COMPANY_ADMIN
                                                        ? 'Company Admin'
                                                        : 'Institute Admin'}
                                                </p>
                                                {!editProfileClicked && (
                                                    <p
                                                        className="border-b-2 border-blue-700 text-blue-700 cursor-pointer"
                                                        onClick={() => setEditProfileClicked(true)}
                                                    >
                                                        Edit Profile
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <div className="flex flex-col w-1/2">
                                            <label
                                                htmlFor="firstName"
                                                className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                First Name
                                                <div className="text-red-600">*</div>
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                        errors.firstName && touched.firstName
                                                            ? 'border-red-500'
                                                            : 'border-gray-300'
                                                    } md:text-base`}
                                                    id="firstName"
                                                    type="text"
                                                    value={values.firstName}
                                                    onChange={e => {
                                                        setFieldValue('firstName', e.target.value);
                                                    }}
                                                    autoComplete="off"
                                                    disabled={!editProfileClicked}
                                                />
                                                {errors.firstName && touched.firstName ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {errors.firstName}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-1/2">
                                            <label
                                                htmlFor="lastName"
                                                className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Last Name
                                                <div className="text-red-600">*</div>
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                        errors.lastName && touched.lastName
                                                            ? 'border-red-500'
                                                            : 'border-gray-300'
                                                    } md:text-base`}
                                                    id="badgeName"
                                                    type="text"
                                                    value={values.lastName}
                                                    onChange={e => {
                                                        setFieldValue('lastName', e.target.value);
                                                    }}
                                                    autoComplete="off"
                                                    disabled={!editProfileClicked}
                                                />
                                                {errors.lastName && touched.lastName ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {errors.lastName}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <div className="flex flex-col w-1/2">
                                            <label
                                                htmlFor="mobileNo"
                                                className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Phone Number
                                                <div className="text-red-600">*</div>
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                        errors.mobileNo && touched.mobileNo
                                                            ? 'border-red-500'
                                                            : 'border-gray-300'
                                                    } md:text-base`}
                                                    id="name"
                                                    type="text"
                                                    value={values.mobileNo}
                                                    onChange={e => {
                                                        if (!isNaN(Number(e.target.value))) {
                                                            setFieldValue(
                                                                'mobileNo',
                                                                e.target.value
                                                            );
                                                        }
                                                    }}
                                                    autoComplete="off"
                                                    disabled={!editProfileClicked}
                                                />
                                                {errors.mobileNo && touched.mobileNo ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {errors.mobileNo}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-1/2">
                                            <label
                                                htmlFor="emailId"
                                                className={`flex items-start w-1/3 mb-2 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                Email
                                                <div className="text-red-600">*</div>
                                            </label>

                                            <div className="flex flex-col w-full">
                                                <input
                                                    className={`rounded flex items-center border-gray-300 focus:outline-none w-4/5 md:w-3/4 p-2 border-2 focus:border-blue-900 md:text-base`}
                                                    id="emailId"
                                                    type="text"
                                                    value={values.emailId}
                                                    autoComplete="off"
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {editProfileClicked && (
                                        <div className="flex w-full justify-end items-center mt-4 md:mt-12">
                                            <div className="flex w-1/2 lg:w-1/3 justify-end">
                                                <button
                                                    className="bg-snow-900 border-2 border-blue-800 md:mr-4 border-opacity-40 h-11  w-full md:w-1/3 text-blue-900 text-sm rounded focus:outline-none"
                                                    onClick={() => setEditProfileClicked(false)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="bg-blue-700 text-white text h-11 rounded focus:outline-none  w-full md:w-1/3"
                                                    onClick={() => handleSubmit()}
                                                >
                                                    {isEditLoading ? <Loader /> : 'Save'}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {editSuccessVisible && (
                                    <UsersSuccessModal
                                        modalVisible={editSuccessVisible}
                                        handleClose={handleClose}
                                    />
                                )}
                            </div>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default MyProfile;
