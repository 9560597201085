import axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import {
    GET_WEB_USER_BY_ID,
    GET_WEB_USER_BY_ID_FAILED,
    GET_WEB_USER_BY_ID_SUCCESS,
} from '../rootActions';

const PREFIX = '@STUDENTS';

export const GET_STUDENTS = PREFIX + 'GET_STUDENTS';
export const GET_STUDENTS_SUCCESS = PREFIX + 'GET_STUDENTS_SUCCESS';
export const GET_STUDENTS_FAILED = PREFIX + 'GET_STUDENTS_FAILED';
export const GET_STUDENTBYID = PREFIX + 'GET_STUDENTBYID';
export const GET_STUDENTBYID_SUCCESS = PREFIX + 'GET_STUDENTBYID_SUCCESS';
export const GET_STUDENTBYID_FAILED = PREFIX + 'GET_STUDENTBYID_FAILED';
export const RESET_STUDENTBYID = PREFIX + 'RESET_STUDENTBYID';
export const EDIT_STUDENT = PREFIX + 'EDIT_STUDENT';
export const EDIT_STUDENT_SUCCESS = PREFIX + 'EDIT_STUDENT_SUCCESS';
export const EDIT_STUDENT_FAILED = PREFIX + 'EDIT_STUDENT_FAILED';
export const DELETE_STUDENT = PREFIX + 'DELETE_STUDENT';
export const DELETE_STUDENT_SUCCESS = PREFIX + 'DELETE_STUDENT_SUCCESS';
export const DELETE_STUDENT_FAILED = PREFIX + 'DELETE_STUDENT_FAILED';
export const STUDENT_BULK_UPDATE = PREFIX + 'STUDENT_BULK_UPDATE';
export const STUDENT_BULK_UPDATE_SUCCESS = PREFIX + 'STUDENT_BULK_UPDATE_SUCCESS';
export const STUDENT_BULK_UPDATE_FAILED = PREFIX + 'STUDENT_BULK_UPDATE_FAILED';
export const CLEAR_STUDENT_MESSAGES = PREFIX + 'CLEAR_STUDENT_MESSAGES';
export const GET_DOWNLOAD_FILE_PRESIGNED_URL = PREFIX + 'GET_DOWNLOAD_FILE_PRESIGNED_URL';
export const GET_DOWNLOAD_FILE_PRESIGNED_URL_SUCCESS =
    PREFIX + 'GET_DOWNLOAD_FILE_PRESIGNED_URL_SUCCESS';
export const GET_DOWNLOAD_FILE_PRESIGNED_URL_FAILED =
    PREFIX + 'GET_DOWNLOAD_FILE_PRESIGNED_URL_FAILED';

export const DOWNLOAD_CSV_STUDENT = PREFIX + 'DOWNLOAD_CSV_STUDENT';
export const DOWNLOAD_CSV_STUDENT_SUCCESS = PREFIX + 'DOWNLOAD_CSV_STUDENT_SUCCESS';
export const DOWNLOAD_CSV_STUDENT_FAILED = PREFIX + 'DOWNLOAD_CSV_STUDENT_FAILED';
export const DOWNLOAD_CSV_STUDENT_RESET = PREFIX + 'DOWNLOAD_CSV_STUDENT_RESET';

export interface StudentCreateType {
    id: string;
    userId: string;
    userName: string;
    gender: string;
    birthDate: string;
    institute: Institute;
    lastActive: string;
    firstName: string;
    lastName: string;
    userPoints: number;
    userLastUpdatedBy?: string;
    userLastUpdatedDate?: string;
    nic: string;
    tags?: any;
    status: string;
}

export interface StudentType {
    userName: string;
    gender: string;
    birthDate: string;
    nic: string;
    firstName: string;
    lastName: string;
    middleName: string;
    school: string;
    cvLink: null;
    studentId: string;
    faculty: string;
    secondaryEduLevel: string;
    higherEduStatus: string;
    course: string;
    contact: contact;
    institute: Institute;
}

export interface Institute {
    id?: number;
    orgName: string;
    orgEmail: string;
    contactNo: number;
}

export interface contact {
    id: 3;
    emailId: string;
    address1: null;
    address2: null;
    city: string;
    mobileNo: string;
    landlineNo: null;
}

export interface CareerStatusType {
    id: any;
    entityId: string;
    entityName: string;
    type: string;
    message: string;
    logCreatedBy?: string;
    logCreatedDate?: string;
}

export interface StudentEditType {
    careerStatus?: [CareerStatusType];
    hiredByFCB?: boolean;
    tags?: any[];
    type?: string;
}

export const getStudents =
    (
        page?: number,
        size?: number,
        status?: string,
        tags?: string,
        searchTerm?: string,
        hometown?: string,
        tracks?: string,
        institutes?: string,
        joinedDateFrom?: string,
        joinedDateTo?: string,
        lastActiveDateFrom?: string,
        lastActiveDateTo?: string
    ) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_STUDENTS });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/users?page=${page || 0}&size=${size || 10}${
                    status ? `&status=${status}` : ''
                }&role=STUDENT${searchTerm ? `&searchText=${searchTerm}` : ''}${
                    tags ? `&tags=${tags}` : ''
                }${hometown ? `&hometown=${hometown}` : ''}${tracks ? `&tracks=${tracks}` : ''}${
                    institutes ? `&institutes=${institutes}` : ''
                }${
                    joinedDateFrom
                        ? `&joinedDateFrom=${moment(joinedDateFrom).format('DD-MM-yyyy')}`
                        : ''
                }${
                    joinedDateTo ? `&joinedDateTo=${moment(joinedDateTo).format('DD-MM-yyyy')}` : ''
                }${
                    lastActiveDateFrom
                        ? `&lastActiveDateFrom=${moment(lastActiveDateFrom).format('DD-MM-yyyy')}`
                        : ''
                }${
                    lastActiveDateTo
                        ? `&lastActiveDateTo=${moment(lastActiveDateTo).format('DD-MM-yyyy')}`
                        : ''
                }`,
                config
            );
            dispatch({
                type: GET_STUDENTS_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_STUDENTS_FAILED, payload: getErrorMessage(error) });
        }
    };
export const getStudentById = (id: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_STUDENTBYID });
        dispatch({ type: GET_WEB_USER_BY_ID });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/users/${id}`, config);
        dispatch({ type: GET_STUDENTBYID_SUCCESS, payload: response.data.responseDto });
        dispatch({ type: GET_WEB_USER_BY_ID_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_STUDENTBYID_FAILED, payload: getErrorMessage(error) });
        dispatch({ type: GET_WEB_USER_BY_ID_FAILED, payload: getErrorMessage(error) });
    }
};

export const editStudent =
    (id: string, student: StudentEditType, status?: string) => async (dispatch: any) => {
        try {
            dispatch({ type: EDIT_STUDENT });
            const config = await axiosConfig(true);
            const res = await axios.put(`${API_URL}/users/${id}`, student, config);
            await dispatch(getStudents(0, 10, status));
            if (res?.data?.status) {
                dispatch({ type: EDIT_STUDENT_SUCCESS, payload: res.data.responseDto });
            }
            if (!res?.data?.status) {
                if (res?.data?.errorCode !== 214) {
                    dispatch({
                        type: EDIT_STUDENT_FAILED,
                        payload: res.data.errorDescription,
                    });
                }
            }
        } catch (error) {
            dispatch({ type: EDIT_STUDENT_FAILED, payload: getErrorMessage(error) });
        }
    };
export const deleteStudent = (id: string, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: DELETE_STUDENT });
        const config = await axiosConfig(true);
        const res = await axios.delete(`${API_URL}/users/${id}`, config);
        await dispatch(getStudents(0, 10, status));
        if (res?.data?.status) {
            dispatch({ type: DELETE_STUDENT_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 215) {
                dispatch({
                    type: DELETE_STUDENT_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: DELETE_STUDENT_FAILED, payload: getErrorMessage(error) });
    }
};

export const clearStudentMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_STUDENT_MESSAGES });
};

export const bulkUpdateStudent = (students, tabStatus) => async (dispatch: any) => {
    try {
        dispatch({ type: STUDENT_BULK_UPDATE });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/users/bulk`, students, config);
        await dispatch(getStudents(0, 10, tabStatus));
        if (res?.data?.status) {
            if (res?.data?.errorCode === 202)
                dispatch({ type: STUDENT_BULK_UPDATE_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 202) {
                dispatch({
                    type: STUDENT_BULK_UPDATE_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: STUDENT_BULK_UPDATE_FAILED, payload: getErrorMessage(error) });
    }
};

export const getFileDownloadPresignedURL = fileName => async (dispatch: any) => {
    try {
        dispatch({ type: GET_DOWNLOAD_FILE_PRESIGNED_URL });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/files?fileName=${fileName}&path=assessment`,
            config
        );
        dispatch({
            type: GET_DOWNLOAD_FILE_PRESIGNED_URL_SUCCESS,
            payload: response.data,
        });
        return response.data;
    } catch (error) {
        dispatch({ type: GET_DOWNLOAD_FILE_PRESIGNED_URL_FAILED, payload: getErrorMessage(error) });
    }
};

export const downloadCSVStudent =
    (period?: any, startDate?: any, endDate?: any) => async (dispatch: any) => {
        try {
            dispatch({ type: DOWNLOAD_CSV_STUDENT });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/csv/student${startDate ? `?startDate=${startDate}` : ''}${
                    endDate ? `&endDate=${endDate}` : ''
                }${period ? `&period=${period}` : ''}`,
                config
            );
            dispatch({ type: DOWNLOAD_CSV_STUDENT_SUCCESS, payload: response.data.responseDto });
        } catch (error) {
            dispatch({ type: DOWNLOAD_CSV_STUDENT_FAILED, payload: getErrorMessage(error) });
        }
    };
