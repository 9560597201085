import React, { ReactElement } from 'react';
import Modal from 'react-modal';

interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    setOnClickSuccess: () => void;
}

export default function AbondanChallengeModal({
    modalVisible,
    setModalVisible,
    setOnClickSuccess,
}: Props): ReactElement {
    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    const handleOnClickSubmit = () => {
        setOnClickSuccess();
        setModalVisible(false);
    };

    const handleOnClickCancel = () => {
        setModalVisible(false);
    };

    return (
        <div>
            <Modal isOpen={modalVisible} style={customStyles} ariaHideApp={false}>
                <>
                    <div className="flex-shrink justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
                        <div style={{ width: '531px' }} className="relative my-6  mx-auto">
                            <div
                                className="rounded-lg shadow-lg relative flex flex-col items-center w-full bg-white outline-none focus:outline-none px-4 py-3"
                                style={{ maxHeight: '324px' }}
                            >
                                <div
                                    style={{
                                        top: '-54px',
                                    }}
                                    className="flex flex-col items-center justify-center relative border-2 border-solid h-20 w-20 bg-white border-purple-700 rounded-full"
                                >
                                    <span className="challengeSumbit self-center pl-1"></span>
                                </div>

                                <div className="mb-2">
                                    <h1 className="text-xl font-bold text-center pb-3">
                                        Are you sure you want to abandon the previous task you were
                                        working on?
                                    </h1>
                                </div>
                                <div className="flex flex-row gap-2 mb-6">
                                    <button
                                        className="w-40 px-2 text-purple-700 h-12 bg-white border border-purple-700 rounded font-bold"
                                        onClick={() => handleOnClickCancel()}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="w-40 px-2 bg-purple-700 h-12 text-white rounded font-bold"
                                        onClick={() => handleOnClickSubmit()}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        </div>
    );
}
