import axios from 'axios';
import { API_URL } from '../../../constants';
import { getErrorMessage } from '../../../utils/axiosErrorHandler';
import { storeWebAuthCreds } from '../../../web/utils/storageWeb';
import { registerResponseType } from './reducers';

const PREFIX = '@REG_';

export const REGISTER = PREFIX + 'REGISTER';
export const REGISTER_SUCCESS = PREFIX + 'REGISTER_SUCCESS';
export const REGISTER_FAILED = PREFIX + 'REGISTER_FAILED';
export const REGISTER_FAILED_2 = PREFIX + 'REGISTER_FAILED_2';

export const REGISTER_MOBILE = PREFIX + 'REGISTER_MOBILE';
export const REGISTER_MOBILE_SUCCESS = PREFIX + 'REGISTER_MOBILE_SUCCESS';
export const REGISTER_MOBILE_FAILED = PREFIX + 'REGISTER_MOBILE_FAILED';
export const REGISTER_MOBILE_FAILED_2 = PREFIX + 'REGISTER_MOBILE_FAILED_2';

export const RESEND_EMAIL_WEB = PREFIX + 'RESEND_EMAIL_WEB';
export const RESEND_EMAIL_WEB_SUCCESS = PREFIX + 'RESEND_EMAIL_WEB_SUCCESS';
export const RESEND_EMAIL_WEB_FAILED = PREFIX + 'RESEND_EMAIL_WEB_FAILED';

export const GENERATE_OTP_WEB = PREFIX + 'GENERATE_OTP_WEB';
export const GENERATE_OTP_WEB_SUCCESS = PREFIX + 'GENERATE_OTP_WEB_SUCCESS';
export const GENERATE_OTP_WEB_FAILED = PREFIX + 'GENERATE_OTP_WEB_FAILED';

export const GENERATE_OTP_SIGNUP_WEB = PREFIX + 'GENERATE_OTP_SIGNUP_WEB';
export const GENERATE_OTP_SIGNUP_WEB_SUCCESS = PREFIX + 'GENERATE_OTP_SIGNUP_WEB_SUCCESS';
export const GENERATE_OTP_SIGNUP_WEB_FAILED = PREFIX + 'GENERATE_OTP_SIGNUP_WEB_FAILED';

export const GENERATE_OTP_SIGNIN_WEB = PREFIX + 'GENERATE_OTP_SIGNIN_WEB';
export const GENERATE_OTP_SIGNIN_WEB_SUCCESS = PREFIX + 'GENERATE_OTP_SIGNIN_WEB_SUCCESS';
export const GENERATE_OTP_SIGNIN_WEB_FAILED = PREFIX + 'GENERATE_OTP_SIGNIN_WEB_FAILED';

export const handleRegTokens = (response: registerResponseType) => {
    const user = {
        token: response.token,
        refreshToken: response.refreshToken,
        username: response.username,
        userId: response.userId,
        email: response.email,
        roles: response.roles,
    };
    storeWebAuthCreds(user);
};

export const resendEmailVerificationWeb = (email?: any) => async (dispatch: any) => {
    try {
        dispatch({ type: RESEND_EMAIL_WEB });
        const response = await axios.post(`${API_URL}/users/resend/email/verification`, {
            email: email,
            userName: email,
        });

        if (response?.data?.status) {
            dispatch({ type: RESEND_EMAIL_WEB_SUCCESS, payload: response.data.responseDto });
        } else {
            dispatch({ type: RESEND_EMAIL_WEB_FAILED, payload: response.data.errorDescription });
        }
    } catch (error) {
        dispatch({ type: RESEND_EMAIL_WEB_FAILED, payload: getErrorMessage(error) });
    }
};

export const registerWeb =
    (userName: string, password: string, firstName: string) => async (dispatch: any) => {
        const roles = [{ roleName: 'STUDENT' }];
        try {
            dispatch({ type: REGISTER });
            const response = await axios.post(`${API_URL}/users/student`, {
                userName: userName,
                firstName: firstName,
                status: 'ACTIVE',
                password: password,
                emailId: userName,
                roles: roles,
            });
            if (!response?.data?.status) {
                if (response?.data?.errorCode !== 222) {
                    dispatch({
                        type: REGISTER_FAILED,
                        payload: response.data.errorDescription,
                    });
                }
            }
            if (response.data.responseDto?.isEmailVerified) {
                handleRegTokens(response.data.responseDto);
            }
            dispatch({ type: REGISTER_SUCCESS, payload: response.data.responseDto });
        } catch (error) {
            dispatch({ type: REGISTER_FAILED_2, payload: getErrorMessage(error) });
        }
    };

export const generateOTP = (phoneNumber?: any) => async (dispatch: any) => {
    try {
        dispatch({ type: GENERATE_OTP_WEB });
        const response = await axios.get(`${API_URL}/otp/generateOtp?mobileNumber=${phoneNumber}`);

        if (response?.data?.status) {
            dispatch({ type: GENERATE_OTP_WEB_SUCCESS, payload: response.data.responseDto });
        } else {
            dispatch({ type: GENERATE_OTP_WEB_FAILED, payload: response.data.errorDescription });
        }
    } catch (error) {
        dispatch({ type: GENERATE_OTP_WEB_FAILED, payload: getErrorMessage(error) });
    }
};

export const signUpGenerateOTP = (phoneNumber?: any) => async (dispatch: any) => {
    try {
        dispatch({ type: GENERATE_OTP_SIGNUP_WEB });
        const response = await axios.get(
            `${API_URL}/otp/sign-up/generate-otp?mobileNumber=${phoneNumber}`
        );

        if (response?.data?.status) {
            dispatch({ type: GENERATE_OTP_SIGNUP_WEB_SUCCESS, payload: response.data.responseDto });
        } else {
            dispatch({
                type: GENERATE_OTP_SIGNUP_WEB_FAILED,
                payload: response.data.errorDescription,
            });
        }
    } catch (error) {
        dispatch({ type: GENERATE_OTP_SIGNUP_WEB_FAILED, payload: getErrorMessage(error) });
    }
};

export const signInGenerateOTP = (phoneNumber?: any) => async (dispatch: any) => {
    try {
        dispatch({ type: GENERATE_OTP_SIGNIN_WEB });
        const response = await axios.get(
            `${API_URL}/otp/sign-in/generate-otp?mobileNumber=${phoneNumber}`
        );

        if (response?.data?.status) {
            dispatch({ type: GENERATE_OTP_SIGNIN_WEB_SUCCESS, payload: response.data.responseDto });
        } else {
            dispatch({
                type: GENERATE_OTP_SIGNIN_WEB_FAILED,
                payload: response.data.errorDescription,
            });
        }
    } catch (error) {
        dispatch({ type: GENERATE_OTP_SIGNIN_WEB_FAILED, payload: getErrorMessage(error) });
    }
};

export const registerMobileWeb =
    (OTPCode: number, mobileNumber: string) => async (dispatch: any) => {
        const roles = [{ roleName: 'STUDENT' }];
        try {
            dispatch({ type: REGISTER_MOBILE });
            const response = await axios.post(`${API_URL}/users/student/mobile`, {
                // firstName: firstName,
                contact: {
                    mobileNo: mobileNumber,
                },
                otpNum: OTPCode,
                roles: roles,
            });
            if (!response?.data?.status) {
                if (response?.data?.errorCode !== 222) {
                    dispatch({
                        type: REGISTER_MOBILE_FAILED,
                        payload: response.data.errorDescription,
                    });
                }
            }
            if (response.data.responseDto?.isMobileVerified) {
                handleRegTokens(response.data.responseDto);
            }
            if (response?.data?.status) {
                dispatch({ type: REGISTER_MOBILE_SUCCESS, payload: response.data.responseDto });
            }
        } catch (error) {
            dispatch({ type: REGISTER_MOBILE_FAILED, payload: getErrorMessage(error) });
        }
    };
