import React from 'react';

interface Props {
    size?: number;
}
export default function Education({ size }: Props) {
    return (
        <svg
            width={size || '50'}
            height={size || '50'}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M34.3755 35.1563C34.2231 35.1564 34.0741 35.112 33.9467 35.0285C33.8193 34.945 33.7191 34.826 33.6584 34.6863C33.5977 34.5466 33.5792 34.3922 33.6052 34.2421C33.6311 34.092 33.7003 33.9527 33.8044 33.8415L44.7419 22.1227C44.8834 21.9713 45.0793 21.8822 45.2864 21.8752C45.4936 21.8682 45.6951 21.9437 45.8466 22.0852C45.998 22.2268 46.0871 22.4227 46.0941 22.6298C46.1011 22.837 46.0256 23.0385 45.8841 23.1899L34.9465 34.9087C34.8734 34.9869 34.785 35.0492 34.6867 35.0918C34.5885 35.1344 34.4825 35.1564 34.3755 35.1563Z"
                fill="#98C38B"
            />
            <path
                d="M15.6253 35.1563C15.5182 35.1564 15.4123 35.1344 15.3141 35.0918C15.2158 35.0492 15.1274 34.9869 15.0542 34.9087L4.11674 23.1899C3.97522 23.0385 3.89967 22.837 3.9067 22.6298C3.91373 22.4227 4.00278 22.2268 4.15424 22.0852C4.3057 21.9437 4.50718 21.8682 4.71435 21.8752C4.92152 21.8822 5.11741 21.9713 5.25893 22.1227L16.1964 33.8415C16.3005 33.9527 16.3697 34.092 16.3956 34.2421C16.4216 34.3922 16.403 34.5466 16.3424 34.6863C16.2817 34.826 16.1815 34.945 16.0541 35.0285C15.9267 35.112 15.7777 35.1564 15.6253 35.1563Z"
                fill="#98C38B"
            />
            <path
                d="M39.8446 35.9377C39.6901 35.9376 39.5391 35.8918 39.4106 35.8059C39.2822 35.7201 39.1821 35.5981 39.1229 35.4554C39.0638 35.3126 39.0483 35.1556 39.0785 35.004C39.1086 34.8525 39.183 34.7133 39.2922 34.6041L43.1985 30.6978C43.3458 30.5555 43.5432 30.4768 43.748 30.4785C43.9528 30.4803 44.1488 30.5625 44.2936 30.7073C44.4385 30.8522 44.5207 31.0481 44.5224 31.253C44.5242 31.4578 44.4455 31.6552 44.3032 31.8025L40.3969 35.7088C40.2504 35.8553 40.0517 35.9376 39.8446 35.9377Z"
                fill="#98C38B"
            />
            <path
                d="M10.1561 35.9376C9.9489 35.9375 9.75022 35.8552 9.60374 35.7087L5.69749 31.8024C5.62287 31.7304 5.56336 31.6441 5.52241 31.5488C5.48147 31.4535 5.45992 31.351 5.45901 31.2473C5.45811 31.1435 5.47788 31.0407 5.51716 30.9446C5.55644 30.8486 5.61445 30.7614 5.68781 30.6881C5.76116 30.6147 5.84839 30.5567 5.9444 30.5174C6.04041 30.4781 6.14329 30.4584 6.24702 30.4593C6.35076 30.4602 6.45327 30.4817 6.54859 30.5227C6.6439 30.5636 6.73011 30.6231 6.80218 30.6977L10.7084 34.604C10.8177 34.7132 10.892 34.8524 10.9222 35.004C10.9523 35.1555 10.9368 35.3125 10.8777 35.4553C10.8186 35.598 10.7185 35.72 10.59 35.8059C10.4616 35.8917 10.3106 35.9375 10.1561 35.9376Z"
                fill="#98C38B"
            />
            <path
                d="M40.625 12.5V18.75C40.625 18.9572 40.5427 19.1559 40.3962 19.3024C40.2497 19.4489 40.051 19.5312 39.8438 19.5312C39.6365 19.5312 39.4378 19.4489 39.2913 19.3024C39.1448 19.1559 39.0625 18.9572 39.0625 18.75V12.5H40.625Z"
                fill="#977CFF"
            />
            <path
                d="M25 33.5938C24.7928 33.5938 24.5941 33.5114 24.4476 33.3649C24.3011 33.2184 24.2188 33.0197 24.2188 32.8125V31.25C24.2188 31.0428 24.3011 30.8441 24.4476 30.6976C24.5941 30.5511 24.7928 30.4688 25 30.4688C25.2072 30.4688 25.4059 30.5511 25.5524 30.6976C25.6989 30.8441 25.7812 31.0428 25.7812 31.25V32.8125C25.7812 33.0197 25.6989 33.2184 25.5524 33.3649C25.4059 33.5114 25.2072 33.5938 25 33.5938Z"
                fill="#FFC569"
            />
            <path
                d="M28.9061 33.5937C28.7516 33.5936 28.6006 33.5478 28.4721 33.462C28.3437 33.3761 28.2436 33.2541 28.1845 33.1114C28.1253 32.9686 28.1099 32.8116 28.14 32.6601C28.1701 32.5085 28.2445 32.3693 28.3537 32.2601L29.9162 30.6976C30.0636 30.5553 30.2609 30.4765 30.4658 30.4783C30.6706 30.4801 30.8666 30.5622 31.0114 30.7071C31.1563 30.8519 31.2384 31.0479 31.2402 31.2527C31.242 31.4576 31.1632 31.6549 31.0209 31.8023L29.4584 33.3648C29.312 33.5113 29.1133 33.5936 28.9061 33.5937Z"
                fill="#FFC569"
            />
            <path
                d="M21.0939 33.5937C20.8867 33.5936 20.688 33.5113 20.5416 33.3648L18.9791 31.8023C18.8368 31.6549 18.758 31.4576 18.7598 31.2527C18.7616 31.0479 18.8437 30.8519 18.9886 30.7071C19.1334 30.5622 19.3294 30.4801 19.5342 30.4783C19.7391 30.4765 19.9364 30.5553 20.0838 30.6976L21.6463 32.2601C21.7555 32.3693 21.8299 32.5085 21.86 32.6601C21.8901 32.8116 21.8747 32.9686 21.8155 33.1114C21.7564 33.2541 21.6563 33.3761 21.5279 33.462C21.3994 33.5478 21.2484 33.5936 21.0939 33.5937Z"
                fill="#FFC569"
            />
            <path
                d="M34.375 26.5625C28.2895 28.5907 21.7105 28.5907 15.625 26.5625V12.5H34.375V26.5625Z"
                fill="#977CFF"
            />
            <path
                d="M34.375 17.857V12.5H15.625V26.5625C21.7105 28.5907 28.2895 28.5907 34.375 26.5625V24.2188C31.3091 25.2408 28.0966 25.7543 24.8648 25.7391C23.0484 25.7129 21.3135 24.9806 20.0277 23.6973C18.7419 22.414 18.0062 20.6805 17.9766 18.8641L23.1531 21.0828C23.7367 21.333 24.365 21.462 25 21.462C25.635 21.462 26.2633 21.333 26.8469 21.0828L34.375 17.857Z"
                fill="#0C2A3D"
            />
            <path d="M25 19.5312L41.4062 12.5L25 5.46875L8.59375 12.5L25 19.5312Z" fill="#4C2EC2" />
            <path
                d="M39.8438 21.4844C40.9224 21.4844 41.7969 20.6099 41.7969 19.5312C41.7969 18.4526 40.9224 17.5781 39.8438 17.5781C38.7651 17.5781 37.8906 18.4526 37.8906 19.5312C37.8906 20.6099 38.7651 21.4844 39.8438 21.4844Z"
                fill="#68CBF2"
            />
            <path
                d="M2.34375 44.5312V47.6562L21.875 45.6359V46.0937C21.875 46.5081 22.0396 46.9056 22.3326 47.1986C22.6257 47.4916 23.0231 47.6562 23.4375 47.6562H25V40.8031C24.9999 40.6873 24.9741 40.573 24.9244 40.4684C24.8747 40.3638 24.8023 40.2716 24.7126 40.1984C24.6229 40.1252 24.518 40.0728 24.4056 40.0451C24.2931 40.0174 24.176 40.0151 24.0625 40.0382L2.34375 44.5312Z"
                fill="#98C38B"
            />
            <path
                d="M25 40.8031V47.6562H26.5625C26.9769 47.6562 27.3743 47.4916 27.6674 47.1986C27.9604 46.9056 28.125 46.5081 28.125 46.0937V45.6359L47.6562 47.6562V44.5312L25.9375 40.0382C25.824 40.0151 25.7069 40.0174 25.5944 40.0451C25.482 40.0728 25.3771 40.1252 25.2874 40.1984C25.1977 40.2716 25.1253 40.3638 25.0756 40.4684C25.0259 40.573 25.0001 40.6873 25 40.8031Z"
                fill="#98C38B"
            />
            <path
                d="M2.34375 46.0938V47.6563L21.875 45.636V46.0938C21.875 46.5082 22.0396 46.9056 22.3326 47.1987C22.6257 47.4917 23.0231 47.6563 23.4375 47.6563H25V45.2438C24.9994 44.7801 24.8956 44.3223 24.6961 43.9036C24.4967 43.485 24.2065 43.116 23.8468 42.8234C23.487 42.5308 23.0666 42.3219 22.6161 42.212C22.1656 42.102 21.6963 42.0936 21.2422 42.1875L2.34375 46.0938Z"
                fill="#7DA770"
            />
            <path
                d="M25 45.2438V47.6563H26.5625C26.9769 47.6563 27.3743 47.4917 27.6674 47.1987C27.9604 46.9056 28.125 46.5082 28.125 46.0938V45.636L47.6562 47.6563V46.0938L28.7578 42.1875C28.3037 42.0936 27.8344 42.102 27.3839 42.212C26.9334 42.3219 26.513 42.5308 26.1532 42.8234C25.7935 43.116 25.5033 43.485 25.3039 43.9036C25.1044 44.3223 25.0006 44.7801 25 45.2438Z"
                fill="#7DA770"
            />
            <path
                d="M45.3125 44.5312L25 40.6249V39.2335C25.0001 38.7539 25.1105 38.2807 25.3228 37.8506C25.5351 37.4206 25.8436 37.0451 26.2243 36.7534C26.605 36.4617 27.0477 36.2615 27.5182 36.1684C27.9887 36.0752 28.4744 36.0917 28.9375 36.2164L43.5781 40.1562C44.0763 40.2903 44.5162 40.5851 44.8298 40.9947C45.1434 41.4043 45.3131 41.9059 45.3125 42.4218V44.5312Z"
                fill="#4C2EC2"
            />
            <path
                d="M4.6875 44.5312L25 40.6249V39.2335C25 38.7539 24.8895 38.2807 24.6772 37.8506C24.4649 37.4206 24.1564 37.0451 23.7757 36.7534C23.395 36.4617 22.9523 36.2615 22.4818 36.1684C22.0113 36.0752 21.5256 36.0917 21.0625 36.2164L6.42188 40.1562C5.92374 40.2903 5.48378 40.5851 5.1702 40.9947C4.85663 41.4043 4.68696 41.9059 4.6875 42.4218V44.5312Z"
                fill="#4C2EC2"
            />
            <path
                d="M28.9373 38.5602C28.1571 38.3511 27.3263 38.4521 26.619 38.8422C25.9117 39.2322 25.383 39.881 25.1436 40.6524L45.2928 44.5313C45.2449 44.0594 45.055 43.6131 44.7481 43.2514C44.4413 42.8897 44.0319 42.6296 43.574 42.5055L28.9373 38.5602Z"
                fill="#977CFF"
            />
            <path
                d="M21.0625 38.5601L6.42187 42.5C5.96397 42.6251 5.55483 42.8863 5.24862 43.249C4.9424 43.6117 4.75354 44.0588 4.70703 44.5312L24.8562 40.6562C24.6176 39.8841 24.0892 39.2344 23.3818 38.8436C22.6744 38.4528 21.8432 38.3512 21.0625 38.5601Z"
                fill="#977CFF"
            />
            <path
                d="M34.7297 11.678C32.3958 12.6785 29.883 13.1944 27.3438 13.1944C24.8045 13.1944 22.2917 12.6785 19.9578 11.678L15.2344 9.65381L8.59375 12.4999L25 19.5312L41.4062 12.4999L37.1094 10.6585L34.7297 11.678Z"
                fill="#7152EC"
            />
            <path
                d="M40.8199 20.5078C40.3019 20.5078 39.8051 20.302 39.4388 19.9358C39.0725 19.5695 38.8667 19.0727 38.8667 18.5547C38.8674 18.2783 38.9273 18.0052 39.0425 17.7539C38.7529 17.8827 38.4989 18.08 38.3026 18.3288C38.1063 18.5776 37.9735 18.8705 37.9156 19.1822C37.8578 19.4938 37.8766 19.8148 37.9706 20.1175C38.0645 20.4203 38.2307 20.6956 38.4549 20.9197C38.679 21.1438 38.9543 21.31 39.257 21.404C39.5597 21.4979 39.8808 21.5168 40.1924 21.4589C40.504 21.4011 40.7969 21.2683 41.0457 21.0719C41.2946 20.8756 41.4919 20.6217 41.6206 20.332C41.3694 20.4472 41.0963 20.5072 40.8199 20.5078Z"
                fill="#977CFF"
            />
        </svg>
    );
}
