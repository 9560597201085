import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getInstitutesPublicData } from '../../../../../redux/rootActions';
import { RootState } from '../../../../../redux/rootReducer';
import { USER_PROFILE } from '../../../../utils/storageWeb';
import InstituteCard from './InstituteCard';

const Institutes = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const instituteOpenData = useSelector(
        (state: RootState) => state.institutes.publicInstitutData
    );
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));

    useEffect(() => {
        dispatch(getInstitutesPublicData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="hidden lg:block">
                <div className="bg-purple-85 pl-10 xl:pl-32 4xl:pl-56 flex justify-between">
                    <div className="flex items-center py-10 md:pr-16">
                        <div>
                            <div className="text-purple-950 xl:text-2xl-2 font-manrope font-bold">
                                Connect your ICT education institute with BRIDGE program.
                            </div>
                            <div className="mt-4 xl:text-xl publicBannerImage">
                                Partner with BRIDGE for free of charge, we will assist your students
                                to obtain industry ready skills.
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <div>
                            <picture>
                                <img
                                    className="hidden md:block h-full"
                                    width="679px"
                                    src={`/assets/local/Institutes/Institutes1.jpg`}
                                    alt="logo"
                                />
                            </picture>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="flex flex-col justify-center items-center whitespace-nowrap">
                        <div className="mt-14 text-2xl-2 font-manrope font-bold">
                            Do you want to train your students under the guidance of the IT experts
                            in Sri Lanka?
                        </div>
                        <div
                            onClick={() => {
                                history.push('/institutes/joinSlasscom');
                            }}
                            className="mt-8 border-1 rounded border-2 border-purple-950 text-sm lg:text-base text-purple-950 hover:bg-purple-950 hover:text-white w-36 h-8 lg:w-48 lg:h-12 flex justify-center items-center font-bold cursor-pointer"
                        >
                            Request to Join
                        </div>
                    </div>
                    <div className="flex justify-center whitespace-nowrap">
                        <div className="mt-14 text-2xl-2 font-manrope font-bold">
                            Our Partnered Institutes
                        </div>
                    </div>

                    <div className="mb-6 flex flex-wrap justify-center">
                        {instituteOpenData?.map(institute => {
                            if (institute?.mouSigned) {
                                return <InstituteCard key={institute.id} institute={institute} />;
                            } else return null;
                        })}
                        <div
                            style={{ width: '303px', height: '229px' }}
                            className="bg-purple-85 rounded-md w-72 4xl:w-80 h-40 4xl:h-52 flex items-center justify-center  m-4 cursor-pointer"
                            onClick={() => {
                                history.push(`/view-all/institute`);
                            }}
                        >
                            <div className="text-purple-950 font-semibold border-b-2 border-purple-950">
                                View more
                            </div>
                            <img className="ml-4" src={`/assets/ViewMoreArrow.png`} alt="logo" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:hidden">
                <img
                    className="object-cover object-center h-56 w-full"
                    src={`/assets/local/Institutes/Institutes1.jpg`}
                    alt="logo"
                />
                <div className="bg-purple-85 px-4 flex justify-between">
                    <div className="flex flex-col items-center py-8 gap-y-3">
                        <div className="text-purple-950 text-lg font-manrope font-bold">
                            Connect your ICT education institute with BRIDGE program.
                        </div>
                        <div className="text-xs">
                            Partner with BRIDGE for free of charge, we will assist your students to
                            obtain industry ready skills.
                        </div>
                    </div>
                </div>

                <div>
                    <div className="my-6 text-center text-lg font-manrope font-bold">
                        Our Partnered Institutes
                    </div>

                    <div className="mb-6 flex flex-col flex-wrap justify-center items-center gap-y-4">
                        {instituteOpenData?.map(institute => {
                            if (institute?.mouSigned) {
                                return (
                                    <InstituteCard
                                        key={institute.id}
                                        institute={institute}
                                        isLargeScreen={false}
                                    />
                                );
                            } else return null;
                        })}
                        <div
                            style={{ width: '280px', height: '170px' }}
                            className="bg-purple-85 rounded-md flex items-center justify-center cursor-pointer"
                            onClick={() => {
                                history.push(`/view-all/institute`);
                            }}
                        >
                            <div className="text-purple-950 font-semibold border-b-2 border-purple-950">
                                View more
                            </div>
                            <img className="ml-4" src={`/assets/ViewMoreArrow.png`} alt="logo" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Institutes;
