import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import BackArrow from '../../../../../assets/svg/BackArrow';
import { s3UrlPrefix } from '../../../../../constants';
import { InstitutesType } from '../../../../../redux/institutes/reducers';
import { getPublicInstituteById } from '../../../../../redux/rootActions';
import { RootState } from '../../../../../redux/rootReducer';
interface CustomState {
    institute: InstitutesType;
}
interface paramsType {
    id: string;
}

const Institute = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const params = useParams<paramsType>();
    const instituteState = location.state as CustomState;

    const publicInstitute = useSelector((state: RootState) => state.institutes.publicInstitute);

    useEffect(() => {
        dispatch(getPublicInstituteById(params.id));
    }, [dispatch, params.id]);
    return (
        <>
            <div className="hidden lg:block">
                <img
                    className="absolute border-2 border-gray-300"
                    style={
                        publicInstitute?.bannerImage !== null
                            ? {
                                  top: '280px',
                                  left: '126px',
                              }
                            : {}
                    }
                    width="275px"
                    src={
                        publicInstitute?.bannerImage !== null
                            ? `${s3UrlPrefix}${publicInstitute?.logoImage}`
                            : '/assets/BlankImage.png'
                    }
                />
                <div className="flex justify-center">
                    <img
                        style={
                            publicInstitute?.bannerImage !== null
                                ? {
                                      maxWidth: 'none',
                                      width: '100%',
                                  }
                                : {}
                        }
                        className={`${publicInstitute?.bannerImage !== null ? 'w-full' : 'mt-10'}`}
                        src={
                            publicInstitute?.bannerImage !== null
                                ? `${s3UrlPrefix}${publicInstitute?.bannerImage}`
                                : '/assets/BlankImage.png'
                        }
                        alt="logo"
                    />
                </div>

                <div key={publicInstitute?.id} className="px-44">
                    <div
                        className="flex mb-5 mt-10 cursor-pointer"
                        onClick={() => history.push(`/view-all/institute`)}
                    >
                        <BackArrow size="27" />
                        <p className="text-base ml-2 font-semibold  hover:underline">Back</p>
                    </div>
                    <div className="flex justify-between ">
                        <div className="">
                            <div className=" text-2xl-2 font-bold font-manrope">
                                {publicInstitute?.instName}
                            </div>
                            <div className="pl-0.5">{publicInstitute?.location}</div>
                        </div>
                        {publicInstitute?.siteUrl && (
                            <button
                                className="px-4 font-bold bg-purple-950 text-white rounded h-12"
                                onClick={() => {
                                    const url = publicInstitute.siteUrl.split('.');
                                    if (url[0] === 'www') {
                                        window.open(`//${publicInstitute?.siteUrl}`, '_blank');
                                    } else {
                                        window.open(publicInstitute?.siteUrl, '_blank');
                                    }
                                }}
                            >
                                Visit Website
                            </button>
                        )}
                    </div>

                    {publicInstitute?.instDescription ? (
                        <div className="mt-8">
                            <div className="text-xl font-bold font-manrope">Description</div>
                            <div className="border-b-2 my-2 "></div>
                            <div className="text-justify">{publicInstitute?.instDescription}</div>
                        </div>
                    ) : null}

                    <div className="mb-20 ">
                        {publicInstitute?.instCategory1?.name ? (
                            <>
                                <div className="mt-12 text-xl font-bold font-manrope">
                                    {publicInstitute?.instCategory1?.name}
                                </div>
                                <div className="border-b-2 my-2 "></div>
                                {publicInstitute?.instCategory1?.options?.map(
                                    faculty =>
                                        faculty && (
                                            <div
                                                key={faculty.id}
                                                className="text-justify bg-purple-85 py-4 pl-6 space-y-3"
                                            >
                                                <div>{faculty}</div>
                                            </div>
                                        )
                                )}
                            </>
                        ) : null}

                        {publicInstitute?.instCategory2?.name ? (
                            <div>
                                <div className="mt-4 text-xl font-bold font-manrope">
                                    {publicInstitute?.instCategory2?.name}
                                </div>
                                <div className="border-b-2 my-2 "></div>
                                {publicInstitute?.instCategory2?.options?.map(
                                    faculty =>
                                        faculty && (
                                            <div
                                                key={faculty.id}
                                                className="text-justify bg-purple-85 py-4 pl-6 space-y-3"
                                            >
                                                <div>{faculty}</div>
                                            </div>
                                        )
                                )}
                            </div>
                        ) : null}

                        {publicInstitute?.instCategory3?.name ? (
                            <div>
                                {publicInstitute?.instCategory3?.name && (
                                    <>
                                        <div className="mt-4 text-xl font-bold font-manrope">
                                            {publicInstitute?.instCategory3?.name}
                                        </div>
                                        <div className="border-b-2 my-2 "></div>
                                        {publicInstitute?.instCategory3?.options?.map(
                                            faculty =>
                                                faculty && (
                                                    <div
                                                        key={faculty.id}
                                                        className="text-justify bg-purple-85 py-4 pl-6 space-y-3"
                                                    >
                                                        <div>{faculty}</div>
                                                    </div>
                                                )
                                        )}
                                    </>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="lg:hidden">
                <div className="relative">
                    <img
                        className="w-full object-cover object-center"
                        src={
                            publicInstitute?.bannerImage !== null
                                ? `${s3UrlPrefix}${publicInstitute?.bannerImage}`
                                : '/assets/BlankImage.png'
                        }
                        style={{ height: '400px' }}
                        alt="logo"
                    />
                    <img
                        className="absolute border-2 border-gray-300 object-cover object-center"
                        style={{ bottom: '10%', left: '5%', width: '275px' }}
                        src={
                            publicInstitute?.bannerImage !== null
                                ? `${s3UrlPrefix}${publicInstitute?.logoImage}`
                                : '/assets/BlankImage.png'
                        }
                    />
                </div>

                <div key={publicInstitute?.id} className="px-8 mt-4">
                    <div className="flex mb-5 cursor-pointer" onClick={() => history.goBack()}>
                        <BackArrow size="27" />
                        <p className="text-base ml-2 font-semibold  hover:underline">Back</p>
                    </div>
                    <div className="flex flex-col gap-y-3 justify-between mb-9">
                        <div className="text-lg font-bold font-manrope">
                            {publicInstitute?.instName}
                        </div>
                        <div className="text-xs font-manrope">{publicInstitute?.location}</div>
                        {publicInstitute?.siteUrl && (
                            <div className="flex">
                                <button
                                    className="px-4 font-bold bg-purple-950 text-white rounded h-12"
                                    onClick={() => {
                                        const url = publicInstitute.siteUrl.split('.');
                                        if (url[0] === 'www') {
                                            window.open(`//${publicInstitute?.siteUrl}`, '_blank');
                                        } else {
                                            window.open(publicInstitute?.siteUrl, '_blank');
                                        }
                                    }}
                                >
                                    Visit Website
                                </button>
                            </div>
                        )}
                    </div>

                    {publicInstitute?.instDescription ? (
                        <div className="mt-8">
                            <div className="text-lg font-bold font-manrope">Description</div>
                            <div className="border-b-2 my-2"></div>
                            <div className="text-justify text-xs">
                                {publicInstitute?.instDescription}
                            </div>
                        </div>
                    ) : null}

                    <div className="mb-6">
                        {publicInstitute?.instCategory1 ? (
                            <>
                                <div className="mt-6 text-lg font-bold font-manrope">
                                    {publicInstitute?.instCategory1?.name}
                                </div>
                                <div className="border-b-2 my-2 "></div>
                                <div className="text-justify text-xs bg-purple-85 py-4 pl-6 space-y-3">
                                    {publicInstitute?.instCategory1?.options?.map(faculty => (
                                        <div key={faculty.id}>
                                            <div>{faculty}</div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : null}

                        {publicInstitute?.instCategory2 ? (
                            <div>
                                <div className="mt-6 text-lg font-bold font-manrope">
                                    {publicInstitute?.instCategory2?.name}
                                </div>
                                <div className="border-b-2 my-2 "></div>
                                <div className="text-justify text-xs bg-purple-85 py-4 pl-6 space-y-3">
                                    {publicInstitute?.instCategory2?.options?.map(faculty => (
                                        <div key={faculty.id}>
                                            <div>{faculty}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}

                        {publicInstitute?.instCategory3 ? (
                            <div>
                                {publicInstitute?.instCategory3?.name && (
                                    <>
                                        <div className="mt-6 text-lg font-bold font-manrope">
                                            {publicInstitute?.instCategory3?.name}
                                        </div>
                                        <div className="border-b-2 my-2 "></div>
                                        <div className="text-justify text-xs bg-purple-85 py-4 pl-6 space-y-3">
                                            {publicInstitute?.instCategory3?.options?.map(
                                                faculty => (
                                                    <div key={faculty.id}>
                                                        <div>{faculty}</div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Institute;
