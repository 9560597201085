import { OrganizationType } from '../../notifications/reducers';
import { TagsType } from '../../tags/reducers';
import { ContactType } from '../webUsers/reducers';
import {
    GET_WEB_NOTIFICATIONS,
    GET_WEB_NOTIFICATIONS_SUCCESS,
    GET_WEB_NOTIFICATIONS_FAILED,
    SET_WEB_NOTIFICATIONS_TYPE,
    SET_WEB_NOTIFICATIONS_TYPE_SUCCESS,
    SET_WEB_NOTIFICATIONS_TYPE_FAILED,
} from './actions';

export interface UsersType {
    id: string;
    userId: string;
    userName: string;
    contact: ContactType;
    emailId: string;
    tags: TagsType;
    organizations: OrganizationType;
}

export interface WebNotificationType {
    id: string;
    notId: string;
    type: string;
    notificationDate: string;
    sentType: string;
    campaignTitle: string;
    sentTypeList: any;
    title: string;
    content: string;
    tags: [];
    users: UsersType[];
}

export interface WebNotificationState {
    webNotificationsData: WebNotificationType[];
    webNotificationsTypeData: string;
    requestResponce: any;
    isLoading: boolean;
}

const initialState: WebNotificationState = {
    webNotificationsData: [],
    webNotificationsTypeData: '',
    requestResponce: null,
    isLoading: false,
};

const webNotificationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_WEB_NOTIFICATIONS:
            return { ...state, isLoading: true, mentorsError: null };
        case GET_WEB_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                webNotificationsData: action.payload,
            };

        case GET_WEB_NOTIFICATIONS_FAILED:
            return { ...state, isLoading: false, mentorsError: action.payload };

        case SET_WEB_NOTIFICATIONS_TYPE:
            return { ...state, isLoading: true, mentorsError: null };
        case SET_WEB_NOTIFICATIONS_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                webNotificationsTypeData: action.payload,
            };

        case SET_WEB_NOTIFICATIONS_TYPE_FAILED:
            return { ...state, isLoading: false, mentorsError: action.payload };

        default:
            return state;
    }
};

export default webNotificationReducer;
