import React, { ReactElement } from 'react';
import Modal from 'react-modal';
import FilterDropdown from '../../FilterDropdown';
import FilterDropdownByDate from '../../FilterDropdownByDate';
import FilterDropdownByNames from '../../FilterDropdownByNames';

interface Props {
    isSelected: boolean;
    onRequestClose: () => void;
    setEventTypesSelected: any;
    eventTypesSelected: any;
    filterEventTypes: any;
    joinedDateFromSelected: any;
    setJoinedDateFromSelected: any;
    joinedDateToSelected: any;
    setJoinedDateToSelected: any;
    setTagsSelected?: any;
    tagsSelected?: any;
    filterTags: any;
}

export default function AddFilter({
    isSelected,
    onRequestClose,
    setEventTypesSelected,
    eventTypesSelected,
    filterEventTypes,
    joinedDateFromSelected,
    setJoinedDateFromSelected,
    joinedDateToSelected,
    setJoinedDateToSelected,
    setTagsSelected,
    tagsSelected,
    filterTags,
}: Props): ReactElement {
    const customStyles: Modal.Styles = {
        overlay: {
            position: 'absolute',
            left: '200px',
            top: '220px',
            width: '100%',
            backgroundColor: 'transparent',
            zIndex: 49,
        },
        content: {
            left: '10%',
            padding: '0px',
            border: '0px',
            width: '30%',
            backgroundColor: 'transparent',
            zIndex: 48,
            overflow: 'visible',
            maxHeight: '20px',
            borderRadius: '20px',
        },
    };

    Modal.setAppElement('#root');

    return (
        <Modal isOpen={isSelected} style={customStyles} onRequestClose={onRequestClose}>
            <>
                <div className="relative w-full cursor-pointer z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="border-0 rounded-lg bg-white outline-none focus:outline-none max-h-40 overflow-auto">
                        <FilterDropdownByNames
                            data={filterEventTypes}
                            title={'Event Types'}
                            setSelectItems={setEventTypesSelected}
                            selectItems={eventTypesSelected}
                        />
                        <FilterDropdownByDate
                            title={'Event Start Date'}
                            setDateFrom={setJoinedDateFromSelected}
                            setDateTo={setJoinedDateToSelected}
                            dateFrom={joinedDateFromSelected}
                            DateTo={joinedDateToSelected}
                            label1={'From :'}
                            label2={'To :'}
                        />
                        <FilterDropdown
                            data={filterTags}
                            title={'Tags'}
                            setSelectItems={setTagsSelected}
                            selectItems={tagsSelected}
                        />
                    </div>
                </div>
            </>
        </Modal>
    );
}
