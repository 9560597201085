import React, { ReactElement, useEffect, useState } from 'react';
import { s3UrlPrefix } from '../../../../constants';
import { useHistory } from 'react-router-dom';
import { USER_PROFILE } from '../../../utils/storageWeb';
import { RootState } from '../../../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getRecommandedTracks, getTracks } from '../../../../redux/rootActions';
import Loader from '../../../../assets/svg/Loader';

export default function RecommandedTracks(): ReactElement {
    const history = useHistory();
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const isAllTrackLoading = useSelector((state: RootState) => state.tracks.isAllTrackLoading);
    const recommandedTrackData = useSelector(
        (state: RootState) => state.tracks.recommandedTrackData
    );

    const [tagHeight, setTagHeight] = useState<any>();

    useEffect(() => {
        dispatch(getRecommandedTracks(profile?.userId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isAllTrackLoading) {
            setTagHeight(document?.getElementById('tagHeight')?.offsetWidth);
        }
    }, [isAllTrackLoading]);

    return (
        <div>
            {recommandedTrackData?.length > 0 ? (
                <>
                    <div className="hidden lg:block lg:flex-row justify-between mb-2 ">
                        <div></div>
                        <div className="font-bold text-xl font-manrope">Recommended Tracks</div>
                        <div className="mt-2 flex justify-between">
                            Explore the areas of interest and find the right tracks for you.
                        </div>
                    </div>
                    {/* Mobile UI */}
                    <div className="flex flex-col mb-2 gap-y-2 lg:hidden">
                        <div className="font-bold text-xl font-manrope">Recommended Tracks</div>
                        <div>Explore the areas of interest and find the right tracks for you.</div>
                    </div>

                    {isAllTrackLoading ? (
                        <div className="py-10">
                            <Loader />
                        </div>
                    ) : (
                        <div className="trackCardContainer justify-center lg:justify-start">
                            {recommandedTrackData?.map(track => (
                                <div key={track.id} className="rounded-lg trackCard mt-3">
                                    <img
                                        className="trackCardImage rounded-t-lg"
                                        src={
                                            track.imageLink
                                                ? `${s3UrlPrefix}${track.imageLink}`
                                                : `/assets/challenge-image-skeleton-rectangle.png`
                                        }
                                    />
                                    <div className="mx-2 mt-6">
                                        <div className="font-bold font-manrope text-xl mb-3 h-7 overflow-hidden">
                                            {track.trackName?.length < 19
                                                ? track.trackName
                                                : track.trackName?.substring(0, 19) + '...'}
                                        </div>
                                        {/* <div className="flex flex-wrap">
                                        <p className="bg-purple-200 text-purple-950 text-xs rounded-full px-3 py-1 w-max">
                                            test 1
                                        </p>
                                        <p className="bg-purple-200 text-purple-950 text-xs rounded-full px-3 py-1 w-max">
                                            test 2
                                        </p>
                                    </div> */}

                                        <div className="flex flex-wrap h-8 overflow-hidden">
                                            {track.tags?.map(tag => (
                                                <div
                                                    key={tag.id}
                                                    className="mr-2 mt-2 bg-purple-200 text-purple-950 text-xs rounded-full px-3 py-1 w-max"
                                                >
                                                    {tag.tagName}
                                                </div>
                                            ))}
                                        </div>

                                        <div className="my-3">
                                            <p className="challengeDescription lg:h-16 max-h-16">
                                                {track.trackDescription}
                                            </p>
                                        </div>
                                        <button
                                            onClick={() => {
                                                history.push({
                                                    pathname: `/dashBoard/learn/RecommandedChallenges`,
                                                    state: { tracks: track },
                                                });
                                            }}
                                            className="border-2 border-purple-950 text-purple-950 rounded font-semibold h-10 px-6 xl:px-12"
                                        >
                                            Get Started
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            ) : null}
        </div>
    );
}
