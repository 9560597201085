import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { ReactElement, useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL, CLIENT_ID_CALENDAR } from '../../../../../../constants';
import OtpInput from 'react-otp-input';
import {
    loginMobileWeb,
    loginWeb,
    loginFacebook,
    loginLinkedIn,
} from '../../../../../../redux/webRedux/authWeb/actions';
import { RootState } from '../../../../../../redux/rootReducer';
import Loader from '../../../../../../assets/svg/Loader';
import {
    getCurrentStudent,
    removeCurrentStudent,
    setCurrentStudent,
    USER_PROFILE,
} from '../../../../../utils/storageWeb';
import {
    WEB_LOGIN_RESET,
    generateOTP,
    getWebStudentById,
    signInGenerateOTP,
} from '../../../../../../redux/rootActions';
import ForgotPassword from './ForgotPassword';
import axios from 'axios';
import queryString from 'query-string';
import { mobielNumberVerification } from '../../../../../utils';
import PhoneInput from 'react-phone-input-2';
import { useHistory, useLocation } from 'react-router-dom';
import { TracksType } from '../../../../../../redux/tracks/reducers';
import useWidth from '../../../../../hooks/useWidth';
import styled from 'styled-components';

const SmallFontLabel = styled.label`
    font-size: 10px;
`;

interface CustomState {
    tracks: TracksType;
    redirect: string;
    tab: string;
}
export default function SignIn(): ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const routerState = location.state as CustomState;
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const studentData = useSelector((state: RootState) => state.webUser.student);
    const isLoading = useSelector((state: RootState) => state.webAuth.isLoading);
    const loginResponse = useSelector((state: RootState) => state.webAuth.loginResponse);
    const loginMobileResponse = useSelector(
        (state: RootState) => state.webAuth.loginMobileResponse
    );
    const ErrorMsg = 'Oops!! something went wrong';
    const loginErrorMessage = useSelector((state: RootState) => state.webAuth.loginErrorMessage);
    const loginMobileErrorMessage = useSelector(
        (state: RootState) => state.webAuth.loginMobileErrorMessage
    );
    const ForgetPasswordError = useSelector((state: RootState) => state.webAuth.emailSentError);
    const ForgetPasswordemailSentResponse = useSelector(
        (state: RootState) => state.webAuth.emailSentResponse
    );
    const isEmailSending = useSelector((state: RootState) => state.webAuth.isWebEmailSending);
    const isOtpSent = useSelector((state: RootState) => state.newWebUser.otpSent);
    const mobileLoginError = useSelector((state: RootState) => state.newWebUser.mobileLoginError);
    const mobileLoginErrorMessage = useSelector(
        (state: RootState) => state.newWebUser.mobileLoginErrorMessage
    );
    const otpResponse = useSelector((state: RootState) => state.newWebUser.otpResponse);
    const [selectedTab, setSelectedTab] = useState('byPhone');
    const [invalidLogin, setInvalidLogin] = useState(false);
    const [invalidMobileLogin, setInvalidMobileLogin] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [forgotPassword, setforgotPassword] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState<string>();
    const [successEmail, setSuccessEmail] = useState(false);
    const [isMobileError, setIsMobileError] = useState<boolean>(false);
    const [mobileNumber, setMobileNumber] = useState<any>(null);
    const [emailAddress, setEmailAddress] = useState<string>(null);
    const [mobileCodeSent, setMobileCodeSent] = useState(false);
    const [countDown, setCountDown] = useState<string>();
    const [timerTigger, setTimerTigger] = useState(false);
    const [OTPCode, setOTPCode] = useState<any>();
    const [OTPError, setOTPError] = useState<boolean>(false);
    const [rememberUser, setRememberUser] = useState<boolean>(!!getCurrentStudent());
    const viewPortWidth = useWidth();

    const [otpResentSuccess, setOtpResentSuccess] = useState(false);
    useEffect(() => {
        if (otpResentSuccess) {
            setTimeout(() => {
                setOtpResentSuccess(false);
            }, 3000);
        }
    }, [otpResentSuccess]);

    const [isErrorForgetPassword, setIsErrorForgetPassword] = useState<boolean>(false);
    const [errorForgetPassword, setErrorForgetPassword] = useState(null);

    const passwordShow = () => {
        setShowPassword(!showPassword);
    };

    const loginWithFacebook = response => {
        if (response) {
            dispatch(loginFacebook(response));
        }
    };

    const onRememberMeClick = () => {
        setRememberUser(!rememberUser);
        if (rememberUser) {
            removeCurrentStudent();
        }
    };

    let timeleft = 60;
    useEffect(() => {
        if (timerTigger === true) {
            const downloadTimer = setInterval(function () {
                if (timeleft <= 0) {
                    clearInterval(downloadTimer);
                    setCountDown('Expired');
                } else if (timeleft < 10) {
                    setCountDown('00:0' + timeleft);
                } else {
                    setCountDown('00:' + timeleft);
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
                timeleft -= 1;
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeleft, timerTigger]);

    const validationSchema = Yup.object({
        email: Yup.string()
            .trim()
            .email('Email must be a valid email')
            .required('Email address is required'),
        password: Yup.string().required('Password cannot be blank'),
    });

    useEffect(() => {
        if (mobielNumberVerification(mobileNumber)) {
            setIsMobileError(false);
        }
    }, [mobileNumber]);

    useEffect(() => {
        if (loginResponse) {
            if (loginResponse?.isEmailVerified) {
                if (
                    loginResponse?.onBoardingCompleted === null ||
                    loginResponse?.onBoardingCompleted === false
                ) {
                    history.push(`/onboard`);
                } else {
                    if (routerState?.redirect) {
                        history.push(routerState.redirect);
                    } else if (routerState) {
                        history.push({
                            pathname: `/dashBoard/learn/RecommandedChallenges`,
                            state: { tracks: routerState.tracks },
                        });
                    } else {
                        history.push(`/dashBoard/learn`);
                    }
                }
            } else {
                history.push({
                    pathname: `/register`,
                    state: {
                        username: selectedTab === 'byEmail' ? emailAddress : mobileNumber,
                        isEmailSent: true,
                    },
                });
            }
        } else if (loginMobileResponse) {
            if (loginMobileResponse?.isMobileVerified) {
                if (
                    loginMobileResponse?.onBoardingCompleted === null ||
                    loginMobileResponse?.onBoardingCompleted === false
                ) {
                    history.push(`/onboard`);
                } else {
                    if (routerState?.redirect) {
                        history.push(routerState.redirect);
                    } else if (routerState) {
                        history.push({
                            pathname: `/dashBoard/learn/RecommandedChallenges`,
                            state: { tracks: routerState.tracks },
                        });
                    } else {
                        history.push(`/dashBoard/learn`);
                    }
                }
            } else {
                history.push({
                    pathname: `/register`,
                    state: {
                        username: selectedTab === 'byEmail' ? emailAddress : mobileNumber,
                        isEmailSent: true,
                    },
                });
            }
        }
        if (loginErrorMessage) {
            setInvalidLogin(true);
        }
        if (loginMobileErrorMessage) {
            setInvalidMobileLogin(true);
        }
    }, [loginResponse, loginErrorMessage, loginMobileResponse, loginMobileErrorMessage]);

    useEffect(() => {
        setTimeout(() => {
            setInvalidLogin(false);
            setInvalidMobileLogin(false);
        }, 3000);
    }, [invalidLogin, invalidMobileLogin]);

    useEffect(() => {
        if (routerState?.tab) {
            setSelectedTab(routerState.tab);
        }
        return () => {
            dispatch({ type: WEB_LOGIN_RESET });
        };
    }, []);

    useEffect(() => {
        if (ForgetPasswordemailSentResponse && verifyEmail !== null) {
            setSuccessEmail(true);
            setIsErrorForgetPassword(false);
        }
    }, [ForgetPasswordemailSentResponse, verifyEmail]);

    useEffect(() => {
        if (ForgetPasswordError) {
            setIsErrorForgetPassword(true);
            setErrorForgetPassword(ForgetPasswordError);
        }
    }, [ForgetPasswordError]);

    const submitMobileLoggin = () => {
        dispatch(signInGenerateOTP(mobileNumber));
    };

    useEffect(() => {
        if (otpResponse) {
            setMobileCodeSent(true);
        } else if (mobileLoginError) {
            setIsMobileError(true);
        }
    }, [isOtpSent, otpResponse, mobileLoginError]);

    useEffect(() => {
        setTimeout(() => {
            setIsMobileError(false);
        }, 4000);
    }, [isMobileError]);

    useEffect(() => {
        if (profile?.userId) {
            dispatch(getWebStudentById(profile.userId));
        }
    }, [dispatch]);

    useEffect(() => {
        if (profile) {
            if (studentData?.isMobileVerified || studentData?.isEmailVerified) {
                if (
                    studentData?.onBoardingCompleted === null ||
                    studentData?.onBoardingCompleted === false
                ) {
                    history.push(`/onboard`);
                } else {
                    if (routerState?.redirect) {
                        history.push(routerState.redirect);
                    } else if (routerState) {
                        history.push({
                            pathname: `/dashBoard/learn/RecommandedChallenges`,
                            state: { tracks: routerState.tracks },
                        });
                    } else {
                        history.push(`/dashBoard/learn`);
                    }
                }
            }
        }
    }, [profile]);

    return (
        <>
            {forgotPassword === false ? (
                <>
                    {viewPortWidth > 1023 ? (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <div>
                                    <div className="text-3xl-2 font-semibold">Sign In</div>
                                </div>
                            </div>
                            <div className="signInContainer">
                                <div className="flex flex-row mt-4">
                                    <div
                                        className={`${
                                            selectedTab === 'byEmail'
                                                ? 'border-b-2 text-purple-950 border-purple-950'
                                                : ''
                                        }  mr-6 cursor-pointer`}
                                        onClick={() => {
                                            setSelectedTab('byEmail');
                                        }}
                                    >
                                        By Email
                                    </div>
                                    <div
                                        className={`${
                                            selectedTab === 'byPhone'
                                                ? 'border-b-2 text-purple-950 border-purple-950'
                                                : ''
                                        }  mr-4 cursor-pointer`}
                                        onClick={() => {
                                            setSelectedTab('byPhone');
                                        }}
                                    >
                                        By Phone
                                    </div>
                                </div>

                                <div className="">
                                    <div className=" pb-8">
                                        {selectedTab === 'byEmail' ? (
                                            <div>
                                                <Formik
                                                    initialValues={{
                                                        email: getCurrentStudent()
                                                            ? getCurrentStudent().replaceAll(
                                                                  '"',
                                                                  ''
                                                              )
                                                            : '',
                                                        password: '',
                                                    }}
                                                    validationSchema={validationSchema}
                                                    onSubmit={async ({ email, password }) => {
                                                        setEmailAddress(email);
                                                        rememberUser && setCurrentStudent(email);
                                                        dispatch(loginWeb(email, password));
                                                    }}
                                                >
                                                    {({
                                                        handleChange,
                                                        handleSubmit,
                                                        values,
                                                        errors,
                                                        touched,
                                                    }) => (
                                                        <form
                                                            onSubmit={event => {
                                                                event.preventDefault();
                                                                handleSubmit();
                                                            }}
                                                        >
                                                            <div className="mb-6 md:mb-4 mt-8 md:mt-6">
                                                                <div className="flex flex-row">
                                                                    <label
                                                                        className={`block text-sm  ${
                                                                            errors.email &&
                                                                            touched.email
                                                                                ? ''
                                                                                : 'mb-2'
                                                                        }`}
                                                                        htmlFor={'email'}
                                                                    >
                                                                        Email Address
                                                                    </label>
                                                                </div>
                                                                <input
                                                                    id="email"
                                                                    type="text"
                                                                    placeholder="Enter Email"
                                                                    className={`focus:outline-none w-full pb-2 md:pb-3 border-b text-sm focus:border-blue-900`}
                                                                    onChange={handleChange('email')}
                                                                    value={values.email}
                                                                />
                                                                {errors.email && touched.email ? (
                                                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                                        {errors.email}
                                                                    </div>
                                                                ) : null}
                                                            </div>

                                                            <div className="">
                                                                <div>
                                                                    <label
                                                                        className={`text-sm mb-2 ${
                                                                            errors.password &&
                                                                            touched.password
                                                                                ? ''
                                                                                : ''
                                                                        }`}
                                                                        htmlFor={'password'}
                                                                    >
                                                                        Password
                                                                    </label>
                                                                    <div className="relative">
                                                                        <input
                                                                            className={`focus:outline-none w-full pb-2 md:pb-3 border-b focus:border-blue-900 ${
                                                                                errors.password &&
                                                                                touched.password
                                                                                    ? 'border-gray-600'
                                                                                    : ''
                                                                            } text-sm`}
                                                                            id="password"
                                                                            type={
                                                                                showPassword
                                                                                    ? 'text'
                                                                                    : 'password'
                                                                            }
                                                                            placeholder="Enter Password"
                                                                            onChange={handleChange(
                                                                                'password'
                                                                            )}
                                                                            value={values.password}
                                                                        />
                                                                        {errors.password &&
                                                                        touched.password ? (
                                                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                                                {errors.password}
                                                                            </div>
                                                                        ) : null}
                                                                        <div className="absolute inset-y-0 right-0 pt-1 pr-3 flex text-base item-center">
                                                                            {values.password
                                                                                .length > 0 ? (
                                                                                <>
                                                                                    <img
                                                                                        src="/assets/passwordShowIcon.png"
                                                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                                            showPassword
                                                                                                ? 'block'
                                                                                                : 'hidden'
                                                                                        }`}
                                                                                        onClick={
                                                                                            passwordShow
                                                                                        }
                                                                                        alt="show-password"
                                                                                        role="presentation"
                                                                                        draggable="false"
                                                                                    />
                                                                                    <img
                                                                                        src="/assets/passwordHideIcon.png"
                                                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                                            showPassword
                                                                                                ? 'hidden'
                                                                                                : 'block'
                                                                                        }`}
                                                                                        alt="hide-password"
                                                                                        role="presentation"
                                                                                        onClick={
                                                                                            passwordShow
                                                                                        }
                                                                                        draggable="false"
                                                                                    />
                                                                                </>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div
                                                                className={`flex justify-between ${
                                                                    invalidLogin
                                                                        ? 'mt-10 mb-3'
                                                                        : 'my-10'
                                                                }`}
                                                            >
                                                                <div>
                                                                    <input
                                                                        className="styled-checkbox"
                                                                        id={`remeber-me`}
                                                                        type="checkbox"
                                                                        checked={rememberUser}
                                                                        onChange={onRememberMeClick}
                                                                    />
                                                                    <label htmlFor={`remeber-me`}>
                                                                        Remember me
                                                                    </label>
                                                                </div>
                                                                <div
                                                                    onClick={() => {
                                                                        setforgotPassword(true);
                                                                        setIsErrorForgetPassword(
                                                                            false
                                                                        );
                                                                    }}
                                                                    className="text-purple-950 cursor-pointer font-semibold underline"
                                                                >
                                                                    Forgot Password?
                                                                </div>
                                                            </div>

                                                            {invalidLogin ? (
                                                                <div
                                                                    className="sm:w-1/2 md:w-full sm:mt-0 bg-red-100 shadow-xs mb-2"
                                                                    style={{
                                                                        borderRadius: 2,
                                                                        minWidth: 242,
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="text-center"
                                                                        style={{
                                                                            height: 40,
                                                                            padding: 9,
                                                                        }}
                                                                    >
                                                                        <span className="font-semibold text-sm ">
                                                                            {loginErrorMessage}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ) : null}

                                                            <div className="mb-4 md:mb-8 text-center">
                                                                <button
                                                                    type="submit"
                                                                    className="bg-purple-950 text-white font-semibold rounded border hover:border-transparent w-40 h-10 sm:w-80 md:w-full sm:h-12"
                                                                    style={{
                                                                        boxShadow:
                                                                            '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                                    }}
                                                                >
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        'Sign In'
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    )}
                                                </Formik>
                                            </div>
                                        ) : selectedTab === 'byPhone' ? (
                                            <div>
                                                {mobileCodeSent === false ? (
                                                    <div>
                                                        <div className="my-6 md:mb-10">
                                                            <label
                                                                className={`block text-sm font-medium leading-149 mb-2 ${
                                                                    isMobileError
                                                                        ? 'text-red-500'
                                                                        : ''
                                                                }`}
                                                                htmlFor={'phoneNumber'}
                                                            >
                                                                Phone Number
                                                            </label>
                                                            <div
                                                                className={`border-b flex flex-row ${
                                                                    isMobileError
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-600'
                                                                }`}
                                                            >
                                                                <PhoneInput
                                                                    inputStyle={{
                                                                        width: '100%',
                                                                        height: '48px',
                                                                        fontSize: '14px',
                                                                        paddingLeft: '48px',
                                                                        borderRadius: '4px',
                                                                        border: 0,
                                                                        fontFamily: 'Lato',
                                                                    }}
                                                                    country="lk"
                                                                    dropdownClass={
                                                                        'mobileNumberCountryDropdown'
                                                                    }
                                                                    specialLabel={''}
                                                                    value={mobileNumber}
                                                                    countryCodeEditable={false}
                                                                    enableSearch={true}
                                                                    placeholder="Enter your Mobile Number (Eg: +94712345678)"
                                                                    onChange={(e, country: any) => {
                                                                        if (!isNaN(Number(e))) {
                                                                            const Index =
                                                                                country.dialCode
                                                                                    .length;

                                                                            if (e[Index] === '0') {
                                                                                const modifiedNumber =
                                                                                    e.slice(
                                                                                        0,
                                                                                        Index
                                                                                    ) +
                                                                                    e.slice(
                                                                                        Index + 1
                                                                                    );

                                                                                setMobileNumber(
                                                                                    modifiedNumber
                                                                                );
                                                                            } else {
                                                                                setMobileNumber(e);
                                                                            }
                                                                            setIsMobileError(false);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            {isMobileError ? (
                                                                <div className="text-red-500 text-center text-xs mt-4 md:text-sm">
                                                                    {mobileLoginErrorMessage ||
                                                                        'Invalid Phone number!'}
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-4 md:mb-8 text-center">
                                                            <button
                                                                type="submit"
                                                                className={`focus:outline-none bg-purple-400 text-white text-base rounded border hover:border-transparent w-40 h-10 sm:w-80 md:w-full sm:h-12 ${
                                                                    !isNaN(mobileNumber)
                                                                        ? 'bg-purple-950'
                                                                        : ''
                                                                }`}
                                                                style={{
                                                                    boxShadow:
                                                                        '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                                }}
                                                                disabled={isNaN(mobileNumber)}
                                                                onClick={() => {
                                                                    submitMobileLoggin();
                                                                }}
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="my-3 md:my-6">
                                                            <label
                                                                className={`block text-sm font-medium leading-149 mb-2 ${
                                                                    isMobileError
                                                                        ? 'text-red-500'
                                                                        : ''
                                                                }`}
                                                                htmlFor={'phoneNumber'}
                                                            >
                                                                OTP Verification code
                                                            </label>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                                className={`border-b flex flex-row ${
                                                                    isMobileError
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-600'
                                                                }`}
                                                            >
                                                                <OtpInput
                                                                    isInputNum={true}
                                                                    value={OTPCode}
                                                                    onChange={otp => {
                                                                        if (!isNaN(Number(otp))) {
                                                                            setOTPCode(otp);
                                                                        }
                                                                    }}
                                                                    separator={
                                                                        <span>
                                                                            <strong>-</strong>
                                                                        </span>
                                                                    }
                                                                    shouldAutoFocus={true}
                                                                    inputStyle={{
                                                                        width: '3rem',
                                                                        height: '3rem',
                                                                        margin: '0 1.5rem 0.5rem',
                                                                        borderRadius: 4,
                                                                        border: '1px solid rgba(0,0,0,1)',
                                                                    }}
                                                                />
                                                            </div>
                                                            {
                                                                // eslint-disable-next-line eqeqeq
                                                                OTPError === true ? (
                                                                    <div
                                                                        className="sm:w-1/2 md:w-full sm:mt-0 bg-red-100 shadow-xs mt-2"
                                                                        style={{
                                                                            borderRadius: 2,
                                                                            minWidth: 242,
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="flex flex-row "
                                                                            style={{
                                                                                height: 40,
                                                                                padding: 9,
                                                                            }}
                                                                        >
                                                                            <span className="ml-2 font-semibold text-sm">
                                                                                Invalid OTP
                                                                                verification code
                                                                            </span>
                                                                            <span
                                                                                onClick={() => {
                                                                                    submitMobileLoggin();
                                                                                    setOtpResentSuccess(
                                                                                        true
                                                                                    );
                                                                                }}
                                                                                className="ml-2 font-semibold text-red-500 text-sm underline cursor-pointer"
                                                                            >
                                                                                Resend code
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <p className="mt-2 text-sm">
                                                                        <span
                                                                            id="countdown"
                                                                            className="text-purple-950 mx-1"
                                                                        >
                                                                            {countDown}
                                                                        </span>{' '}
                                                                        Did not receive the
                                                                        verification yet?
                                                                        <span
                                                                            onClick={() => {
                                                                                timeleft = 60;
                                                                                submitMobileLoggin();
                                                                                setOtpResentSuccess(
                                                                                    true
                                                                                );
                                                                            }}
                                                                            className="ml-2 text-purple-950 hover:underline cursor-pointer"
                                                                        >
                                                                            Resend code
                                                                        </span>
                                                                    </p>
                                                                )
                                                            }
                                                            {otpResentSuccess ? (
                                                                <div className="mt-2 flex justify-center text-center text-green-400">
                                                                    <img
                                                                        src="/assets/tick.png"
                                                                        className="mr-2 w-4 h-4 mt-1"
                                                                        alt=""
                                                                    />
                                                                    OTP code resent!
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        {invalidMobileLogin ? (
                                                            <div
                                                                className="sm:w-1/2 md:w-full sm:mt-0 bg-red-100 shadow-xs mb-2"
                                                                style={{
                                                                    borderRadius: 2,
                                                                    minWidth: 242,
                                                                }}
                                                            >
                                                                <div
                                                                    className="text-center"
                                                                    style={{
                                                                        height: 40,
                                                                        padding: 9,
                                                                    }}
                                                                >
                                                                    <span className="font-semibold text-sm ">
                                                                        {ErrorMsg}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : null}

                                                        <div className="mb-4 md:mb-8 text-center">
                                                            <button
                                                                type="submit"
                                                                className={`focus:outline-none bg-purple-400 text-white font-semibold rounded border hover:border-transparent w-40 h-10 sm:w-80 md:w-full sm:h-12 ${
                                                                    !isNaN(mobileNumber)
                                                                        ? 'bg-purple-950'
                                                                        : ''
                                                                }`}
                                                                style={{
                                                                    boxShadow:
                                                                        '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                                }}
                                                                disabled={isNaN(mobileNumber)}
                                                                onClick={() => {
                                                                    dispatch(
                                                                        loginMobileWeb(
                                                                            parseInt(OTPCode),
                                                                            mobileNumber.toString()
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                Sign In
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : null}

                                        <div className="text-purple-950 text-sm font-semibold text-center mb-1 md:text-base md:mb-2">
                                            I don’t have an account?{' '}
                                            <span
                                                className="text-purple-950 cursor-pointer font-semibold"
                                                role="presentation"
                                                onClick={() => {
                                                    history.push('/register');
                                                }}
                                            >
                                                <u> Register</u>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-col sm:flex w-full mb-5">
                                    <a
                                        className="sm:w-1/2 md:w-full border shadow-xs cursor-pointer"
                                        style={{
                                            borderRadius: '4px',

                                            background: '#DE4A39',
                                        }}
                                        href={`${API_URL}/oauth2/authorize/google?redirect_uri=${window.location.origin}/oauth2/redirect`}
                                    >
                                        <div
                                            role="presentation"
                                            className="flex flex-row items-center"
                                            style={{ height: 50, padding: '27px' }}
                                        >
                                            <img
                                                src="/assets/signupGoogleIcon.png"
                                                alt="signup-Google-icon"
                                                className="w-5 h-5 mr-2 "
                                            />
                                            <span className="text-white text-sm ml-4">
                                                Continue with Google
                                            </span>
                                        </div>
                                    </a>

                                    <a
                                        className="sm:w-1/2 md:w-full mt-4 border shadow-xs cursor-pointer"
                                        style={{
                                            borderRadius: '4px',

                                            background: '#4267B2',
                                        }}
                                        href={`${API_URL}/oauth2/authorize/facebook?redirect_uri=${window.location.origin}/oauth2/redirect`}
                                    >
                                        <div
                                            role="presentation"
                                            className="flex flex-row items-center"
                                            style={{ height: 50, padding: '27px' }}
                                        >
                                            <img
                                                src="/assets/signupFacebookIcon.png"
                                                alt="signup-Google-icon"
                                                className="w-5 h-5 mr-2 "
                                            />
                                            <span className="text-white text-sm ml-4">
                                                Continue with Facebook
                                            </span>
                                        </div>
                                    </a>

                                    <a
                                        className="sm:w-1/2 mt-4 md:w-full border shadow-xs cursor-pointer"
                                        style={{
                                            borderRadius: '4px',

                                            background: '#2867B2',
                                        }}
                                        href={`${API_URL}/oauth2/authorize/linkedin?redirect_uri=${window.location.origin}/oauth2/redirect`}
                                    >
                                        <div
                                            role="presentation"
                                            className="flex flex-row items-center"
                                            style={{ height: 50, padding: '27px' }}
                                        >
                                            <img
                                                src="/assets/signupLinkedInIcon.png"
                                                alt="signup-Google-icon"
                                                className="w-5 h-5 mr-2 "
                                            />
                                            <span className="text-white text-sm ml-4">
                                                Continue with LinkedIn
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <div>
                                    <div className="text-lg font-bold font-manrope">Sign In</div>
                                </div>
                            </div>
                            <div className="signInContainer">
                                <div className="flex flex-row mt-4">
                                    <div
                                        className={`${
                                            selectedTab === 'byEmail'
                                                ? 'border-b-2 text-purple-950 border-purple-950'
                                                : ''
                                        }  mr-6 cursor-pointer text-xs`}
                                        onClick={() => {
                                            setSelectedTab('byEmail');
                                        }}
                                    >
                                        By Email
                                    </div>
                                    <div
                                        className={`${
                                            selectedTab === 'byPhone'
                                                ? 'border-b-2 text-purple-950 border-purple-950'
                                                : ''
                                        }  mr-4 cursor-pointer text-xs`}
                                        onClick={() => {
                                            setSelectedTab('byPhone');
                                        }}
                                    >
                                        By Phone
                                    </div>
                                </div>

                                <div className="">
                                    <div className=" pb-8">
                                        {selectedTab === 'byEmail' ? (
                                            <div>
                                                <Formik
                                                    initialValues={{ email: '', password: '' }}
                                                    validationSchema={validationSchema}
                                                    onSubmit={async ({ email, password }) => {
                                                        setEmailAddress(email);
                                                        dispatch(loginWeb(email, password));
                                                    }}
                                                >
                                                    {({
                                                        handleChange,
                                                        handleSubmit,
                                                        values,
                                                        errors,
                                                        touched,
                                                    }) => (
                                                        <form
                                                            onSubmit={event => {
                                                                event.preventDefault();
                                                                handleSubmit();
                                                            }}
                                                        >
                                                            <div className="mb-6 md:mb-4 mt-8 md:mt-6">
                                                                <div className="flex flex-row">
                                                                    <SmallFontLabel
                                                                        className={`block  ${
                                                                            errors.email &&
                                                                            touched.email
                                                                                ? ''
                                                                                : 'mb-2'
                                                                        }`}
                                                                        htmlFor={'email'}
                                                                    >
                                                                        Email Address
                                                                    </SmallFontLabel>
                                                                </div>
                                                                <input
                                                                    id="email"
                                                                    type="email"
                                                                    placeholder="Enter Email"
                                                                    className={`focus:outline-none w-full pb-2 md:pb-3 border-b text-sm focus:border-blue-900`}
                                                                    onChange={handleChange('email')}
                                                                    value={values.email}
                                                                />
                                                                {errors.email && touched.email ? (
                                                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                                        {errors.email}
                                                                    </div>
                                                                ) : null}
                                                            </div>

                                                            <div className="">
                                                                <div>
                                                                    <div className="flex flex-row">
                                                                        <SmallFontLabel
                                                                            className={` mb-2 ${
                                                                                errors.password &&
                                                                                touched.password
                                                                                    ? ''
                                                                                    : ''
                                                                            }`}
                                                                            htmlFor={'password'}
                                                                        >
                                                                            Password
                                                                        </SmallFontLabel>
                                                                    </div>
                                                                    <div className="relative">
                                                                        <input
                                                                            className={`focus:outline-none w-full pb-2 md:pb-3 border-b focus:border-blue-900 ${
                                                                                errors.password &&
                                                                                touched.password
                                                                                    ? 'border-gray-600'
                                                                                    : ''
                                                                            } text-sm`}
                                                                            id="password"
                                                                            type={
                                                                                showPassword
                                                                                    ? 'text'
                                                                                    : 'password'
                                                                            }
                                                                            placeholder="Enter Password"
                                                                            onChange={handleChange(
                                                                                'password'
                                                                            )}
                                                                            value={values.password}
                                                                        />
                                                                        {errors.password &&
                                                                        touched.password ? (
                                                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                                                {errors.password}
                                                                            </div>
                                                                        ) : null}
                                                                        <div className="absolute inset-y-0 right-0 pt-1 pr-3 flex text-base item-center">
                                                                            {values.password
                                                                                .length > 0 ? (
                                                                                <>
                                                                                    <img
                                                                                        src="/assets/passwordShowIcon.png"
                                                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                                            showPassword
                                                                                                ? 'block'
                                                                                                : 'hidden'
                                                                                        }`}
                                                                                        onClick={
                                                                                            passwordShow
                                                                                        }
                                                                                        alt="show-password"
                                                                                        role="presentation"
                                                                                        draggable="false"
                                                                                    />
                                                                                    <img
                                                                                        src="/assets/passwordHideIcon.png"
                                                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                                                            showPassword
                                                                                                ? 'hidden'
                                                                                                : 'block'
                                                                                        }`}
                                                                                        alt="hide-password"
                                                                                        role="presentation"
                                                                                        onClick={
                                                                                            passwordShow
                                                                                        }
                                                                                        draggable="false"
                                                                                    />
                                                                                </>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div
                                                                className={`flex justify-between items-center ${
                                                                    invalidLogin
                                                                        ? 'mt-10 mb-3'
                                                                        : 'my-10'
                                                                }`}
                                                            >
                                                                <div>
                                                                    <input
                                                                        className="styled-checkbox"
                                                                        id={`remeber-me`}
                                                                        type="checkbox"
                                                                    />
                                                                    <label
                                                                        className="text-xs"
                                                                        htmlFor={`remeber-me`}
                                                                    >
                                                                        Remember me
                                                                    </label>
                                                                </div>
                                                                <div
                                                                    onClick={() => {
                                                                        setforgotPassword(true);
                                                                        setIsErrorForgetPassword(
                                                                            false
                                                                        );
                                                                    }}
                                                                    className="text-purple-950 cursor-pointer font-semibold underline text-xs"
                                                                >
                                                                    Forgot Password?
                                                                </div>
                                                            </div>

                                                            {invalidLogin ? (
                                                                <div
                                                                    className="sm:w-1/2 md:w-full sm:mt-0 bg-red-100 shadow-xs mb-2"
                                                                    style={{
                                                                        borderRadius: 2,
                                                                        minWidth: 242,
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="text-center"
                                                                        style={{
                                                                            height: 40,
                                                                            padding: 9,
                                                                        }}
                                                                    >
                                                                        <span className="font-semibold text-sm ">
                                                                            {loginErrorMessage}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ) : null}

                                                            <div className="mb-8 text-center w-full">
                                                                <button
                                                                    type="submit"
                                                                    className="bg-purple-950 text-white font-semibold rounded border hover:border-transparent  w-full h-12"
                                                                    style={{
                                                                        boxShadow:
                                                                            '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                                    }}
                                                                >
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        'Sign In'
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    )}
                                                </Formik>
                                            </div>
                                        ) : selectedTab === 'byPhone' ? (
                                            <div>
                                                {mobileCodeSent === false ? (
                                                    <div>
                                                        <div className="mt-6 mb-8">
                                                            <SmallFontLabel
                                                                className={`block leading-149 mb-2 ${
                                                                    isMobileError
                                                                        ? 'text-red-500'
                                                                        : ''
                                                                }`}
                                                                htmlFor={'phoneNumber'}
                                                            >
                                                                Phone Number
                                                            </SmallFontLabel>
                                                            <div
                                                                className={`border-b flex flex-row ${
                                                                    isMobileError
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-600'
                                                                }`}
                                                            >
                                                                <PhoneInput
                                                                    inputStyle={{
                                                                        width: '100%',
                                                                        height: '48px',
                                                                        fontSize: '14px',
                                                                        paddingLeft: '48px',
                                                                        borderRadius: '4px',
                                                                        border: 0,
                                                                        fontFamily: 'Lato',
                                                                    }}
                                                                    country="lk"
                                                                    dropdownClass={
                                                                        'mobileNumberCountryDropdown'
                                                                    }
                                                                    specialLabel={''}
                                                                    value={mobileNumber}
                                                                    countryCodeEditable={false}
                                                                    enableSearch={true}
                                                                    placeholder="Enter your Mobile Number (Eg: +94712345678)"
                                                                    onChange={(e, country: any) => {
                                                                        if (!isNaN(Number(e))) {
                                                                            const Index =
                                                                                country.dialCode
                                                                                    .length;

                                                                            if (e[Index] === '0') {
                                                                                const modifiedNumber =
                                                                                    e.slice(
                                                                                        0,
                                                                                        Index
                                                                                    ) +
                                                                                    e.slice(
                                                                                        Index + 1
                                                                                    );

                                                                                setMobileNumber(
                                                                                    modifiedNumber
                                                                                );
                                                                            } else {
                                                                                setMobileNumber(e);
                                                                            }
                                                                            setIsMobileError(false);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            {isMobileError ? (
                                                                <div className="text-red-500 text-center text-xs mt-4 md:text-sm">
                                                                    {mobileLoginErrorMessage ||
                                                                        'Invalid Phone number!'}
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-8 text-center">
                                                            <button
                                                                type="submit"
                                                                className={`focus:outline-none bg-purple-400 text-white text-base rounded border hover:border-transparent w-full h-12 ${
                                                                    !isNaN(mobileNumber)
                                                                        ? 'bg-purple-950'
                                                                        : ''
                                                                }`}
                                                                style={{
                                                                    boxShadow:
                                                                        '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                                }}
                                                                disabled={isNaN(mobileNumber)}
                                                                onClick={() => {
                                                                    submitMobileLoggin();
                                                                }}
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="my-6">
                                                            <div className="flex">
                                                                <SmallFontLabel
                                                                    className={`block font-medium leading-149 mb-4 ${
                                                                        isMobileError
                                                                            ? 'text-red-500'
                                                                            : ''
                                                                    }`}
                                                                    htmlFor={'phoneNumber'}
                                                                >
                                                                    OTP Verification code
                                                                </SmallFontLabel>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                                className={`border-b flex flex-row ${
                                                                    isMobileError
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-600'
                                                                }`}
                                                            >
                                                                <OtpInput
                                                                    isInputNum={true}
                                                                    value={OTPCode}
                                                                    onChange={otp => {
                                                                        if (!isNaN(Number(otp))) {
                                                                            setOTPCode(otp);
                                                                        }
                                                                    }}
                                                                    shouldAutoFocus={true}
                                                                    separator={
                                                                        <span>
                                                                            <strong>-</strong>
                                                                        </span>
                                                                    }
                                                                    inputStyle={{
                                                                        width: '2rem',
                                                                        height: '3rem',
                                                                        margin: '0 1.5rem 0.5rem',
                                                                        borderRadius: 4,
                                                                        border: '1px solid rgba(0,0,0,1)',
                                                                    }}
                                                                />
                                                            </div>

                                                            {
                                                                // eslint-disable-next-line eqeqeq
                                                                OTPError === true ? (
                                                                    <div
                                                                        className="w-full bg-red-100 shadow-xs mt-4"
                                                                        style={{
                                                                            borderRadius: 2,
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="flex flex-row "
                                                                            style={{
                                                                                height: 40,
                                                                                padding: 9,
                                                                            }}
                                                                        >
                                                                            <span className="ml-2 font-semibold text-sm">
                                                                                Invalid OTP
                                                                                verification code
                                                                            </span>
                                                                            <span
                                                                                onClick={() => {
                                                                                    submitMobileLoggin();
                                                                                    setOtpResentSuccess(
                                                                                        true
                                                                                    );
                                                                                }}
                                                                                className="ml-2 font-semibold text-red-500 text-sm underline cursor-pointer"
                                                                            >
                                                                                Resend code
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <p className="mt-4 text-xs">
                                                                        <span
                                                                            id="countdown"
                                                                            className="text-purple-950"
                                                                        >
                                                                            {countDown}
                                                                        </span>{' '}
                                                                        Did not receive the
                                                                        verification yet?
                                                                        <span
                                                                            onClick={() => {
                                                                                timeleft = 60;
                                                                                submitMobileLoggin();
                                                                                setOtpResentSuccess(
                                                                                    true
                                                                                );
                                                                            }}
                                                                            className="ml-2 text-purple-950 hover:underline cursor-pointer"
                                                                        >
                                                                            Resend code
                                                                        </span>
                                                                    </p>
                                                                )
                                                            }
                                                            {otpResentSuccess ? (
                                                                <div className="mt-4 flex justify-center text-center text-green-400 text-xs">
                                                                    <img
                                                                        src="/assets/tick.png"
                                                                        className="mr-2 w-4 h-4 mt-1"
                                                                        alt=""
                                                                    />
                                                                    OTP code resent!
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        {invalidMobileLogin ? (
                                                            <div
                                                                className="w-full sm:mt-0 bg-red-100 shadow-xs mb-2"
                                                                style={{
                                                                    borderRadius: 2,
                                                                }}
                                                            >
                                                                <div
                                                                    className="flex items-center justify-center text-center"
                                                                    style={{
                                                                        height: 40,
                                                                        padding: 9,
                                                                    }}
                                                                >
                                                                    <span className="font-semibold text-xs ">
                                                                        {ErrorMsg}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : null}

                                                        <div className="mb-8 text-center">
                                                            <button
                                                                type="submit"
                                                                className={`focus:outline-none bg-purple-400 text-white font-semibold rounded border hover:border-transparent w-full h-12 ${
                                                                    !isNaN(mobileNumber)
                                                                        ? 'bg-purple-950'
                                                                        : ''
                                                                }`}
                                                                style={{
                                                                    boxShadow:
                                                                        '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                                                }}
                                                                disabled={isNaN(mobileNumber)}
                                                                onClick={() => {
                                                                    dispatch(
                                                                        loginMobileWeb(
                                                                            parseInt(OTPCode),
                                                                            mobileNumber.toString()
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                Sign In
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : null}

                                        <div className="text-purple-950 text-sm font-semibold text-center mb-1 md:text-base md:mb-2">
                                            I don’t have an account?{' '}
                                            <span
                                                className="text-purple-950 cursor-pointer font-semibold"
                                                role="presentation"
                                                onClick={() => {
                                                    history.push('/register');
                                                }}
                                            >
                                                <u> Register</u>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full mb-5 gap-4">
                                    <a
                                        className="w-full border shadow-xs cursor-pointer"
                                        style={{
                                            borderRadius: '4px',

                                            background: '#DE4A39',
                                        }}
                                        href={`${API_URL}/oauth2/authorize/google?redirect_uri=${window.location.origin}/oauth2/redirect`}
                                    >
                                        <div
                                            role="presentation"
                                            className="flex flex-row items-center"
                                            style={{ height: 50, padding: '27px' }}
                                        >
                                            <img
                                                src="/assets/signupGoogleIcon.png"
                                                alt="signup-Google-icon"
                                                className="w-5 h-5 mr-2 "
                                            />
                                            <span className="text-white text-sm ml-4">
                                                Continue with Google
                                            </span>
                                        </div>
                                    </a>

                                    <a
                                        className="w-full border shadow-xs cursor-pointer"
                                        style={{
                                            borderRadius: '4px',
                                            background: '#4267B2',
                                        }}
                                        href={`${API_URL}/oauth2/authorize/facebook?redirect_uri=${window.location.origin}/oauth2/redirect`}
                                    >
                                        <div
                                            role="presentation"
                                            className="flex flex-row items-center"
                                            style={{ height: 50, padding: '27px' }}
                                        >
                                            <img
                                                src="/assets/signupFacebookIcon.png"
                                                alt="signup-Google-icon"
                                                className="w-5 h-5 mr-2 "
                                            />
                                            <span className="text-white text-sm ml-4">
                                                Continue with Facebook
                                            </span>
                                        </div>
                                    </a>

                                    <a
                                        className="w-full border shadow-xs cursor-pointer"
                                        style={{
                                            borderRadius: '4px',
                                            background: '#2867B2',
                                        }}
                                        href={`${API_URL}/oauth2/authorize/linkedin?redirect_uri=${window.location.origin}/oauth2/redirect`}
                                    >
                                        <div
                                            role="presentation"
                                            className="flex flex-row items-center"
                                            style={{ height: 50, padding: '27px' }}
                                        >
                                            <img
                                                src="/assets/signupLinkedInIcon.png"
                                                alt="signup-Google-icon"
                                                className="w-5 h-5 mr-2 "
                                            />
                                            <span className="text-white text-sm ml-4">
                                                Continue with LinkedIn
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div>
                    <ForgotPassword
                        successEmail={successEmail}
                        verifyEmail={verifyEmail}
                        setVerifyEmail={setVerifyEmail}
                        setIsErrorForgetPassword={setIsErrorForgetPassword}
                        isErrorForgetPassword={isErrorForgetPassword}
                        setErrorForgetPassword={setErrorForgetPassword}
                        errorForgetPassword={errorForgetPassword}
                        isEmailSending={isEmailSending}
                        setforgotPassword={setforgotPassword}
                        setSuccessEmail={setSuccessEmail}
                    />
                </div>
            )}
        </>
    );
}
