import React, { ReactElement, useEffect, useState } from 'react';
import AddTabs from './AddTabs';
import Add from '../../../assets/svg/Add';
import {
    lastActiveDate,
    joinedDate,
    getStudentTabs,
    downloadCSVStudent,
    DOWNLOAD_CSV_STUDENT_RESET,
} from '../../../redux/rootActions';
import { useDispatch, useSelector } from 'react-redux';
import { addStudentTab } from '../../../redux/studenttab/actions';
import moment from 'moment';
import { USER_PROFILE } from '../../../utils/storage';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { API_URL } from '../../../constants';
import CSVEmailedSuccessModal from '../../modals/CSVEmailedSuccessModal';
interface Props {
    selectedTab: number;
    setSelectedTab: (value: number) => void;
}

export default function TabView({ selectedTab, setSelectedTab }: Props): ReactElement {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = React.useState(false);
    const [tab, setTab] = useState([]);
    const [selectedTabFilteration, setSelectedTabFilteration] = useState<any>();
    const [tabName, setTabName] = useState<string>();

    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const studentTabData = useSelector((state: RootState) => state.filteredStudent.studentTabData);
    const isStudentCSVLoading = useSelector(
        (state: RootState) => state.students.isStudentCSVLoading
    );
    const studentEmailMessage = useSelector(
        (state: RootState) => state.students.studentEmailMessage
    );
    const studentCSVSuccess = useSelector((state: RootState) => state.students.studentCSVSuccess);

    const [studentLessThan, setStudentLessThan] = useState<string>();
    const [studentMoreThan, setStudentMoreThan] = useState<string>();
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [joinStartDate, setJoinStartDate] = useState<any>();
    const [joinEndDAte, setJoinEndDate] = useState<any>();
    // eslint-disable-next-line
    const [newStartDate, setNewStartDate] = useState<any>();
    // eslint-disable-next-line
    const [newEndDAte, setNEWEndDate] = useState<any>();
    const [dateSelected, setDateSelected] = useState(false);
    const [joinDateSelected, setJoinDateSelected] = useState(false);
    const [challenge, setChallenge] = useState([]);
    const [tags, setTags] = useState([]);
    const [program, setProgram] = useState([]);
    const [status, setStatus] = useState<string>();
    const [tracks, setTracks] = useState([]);
    const [institue, setInstitue] = useState<string>();
    const [homeTown, setHomeTown] = useState<string>();

    const [modalVisible, setModalVisible] = useState<boolean>();
    const [message, setMessage] = useState<string>();

    useEffect(() => {
        if (studentCSVSuccess) {
            setMessage(studentEmailMessage);
            setModalVisible(true);
            dispatch({ type: DOWNLOAD_CSV_STUDENT_RESET });
        }
    }, [studentCSVSuccess, studentEmailMessage]);

    useEffect(() => {
        if (selectedTab !== 1) {
            setSelectedTabFilteration(
                tab?.filter(action => action.id === selectedTab - 2).map(data => data?.filters)[0]
            );
        }
    }, [studentTabData, selectedTab]);

    useEffect(() => {
        dispatch(getStudentTabs(profile?.userId, 'STUDENT'));
    }, []);

    useEffect(() => {
        if (selectedTab === selectedTabFilteration?.id + 2) {
            if (selectedTabFilteration?.institutes !== null) {
                dispatch(
                    addStudentTab({
                        id: selectedTabFilteration?.id,
                        tabName: selectedTabFilteration?.tabName,
                        tracks: selectedTabFilteration?.tracks,
                        joinedDate: selectedTabFilteration?.joinedDate,
                        status: selectedTabFilteration?.status,
                        lastActiveDate: selectedTabFilteration?.lastactiveDate,
                        homeTowns: selectedTabFilteration?.homeTown,
                        institutes: [parseInt(selectedTabFilteration?.institutes[0])],
                        dateSelected: dateSelected,
                        joinDateSelected: joinDateSelected,
                    })
                );
            } else {
                dispatch(
                    addStudentTab({
                        id: selectedTabFilteration?.id,
                        tabName: selectedTabFilteration?.tabName,
                        tracks: selectedTabFilteration?.tracks,
                        joinedDate: selectedTabFilteration?.joinedDate,
                        status: selectedTabFilteration?.status,
                        lastActiveDate: selectedTabFilteration?.lastactiveDate,
                        homeTowns: selectedTabFilteration?.homeTown,
                        institutes: [parseInt(selectedTabFilteration?.institutes)],
                        dateSelected: dateSelected,
                        joinDateSelected: joinDateSelected,
                    })
                );
            }
        }
    }, [selectedTab, selectedTabFilteration]);
    // }, [selectedTab, selectedTabFilteration, studentTabData]);

    useEffect(() => {
        setTab(studentTabData);
    }, [studentTabData]);

    useEffect(() => {
        setNewStartDate(moment(startDate).format('YYYY-MM-DD'));
        setNEWEndDate(moment(endDate).format('YYYY-MM-DD'));
    }, [endDate, startDate]);

    const onAdd = () => {
        if (!tab.includes(tabName)) {
            setTab([...tab, tabName]);
        }

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const joinedDate: joinedDate = {
            from: moment(joinStartDate)?.format('YYYY-MM-DD')?.toString(),
            to: moment(joinEndDAte)?.format('YYYY-MM-DD')?.toString(),
        };

        const lastactiveDate: lastActiveDate = {
            from: moment(startDate).format('YYYY-MM-DD')?.toString(),
            to: moment(endDate).format('YYYY-MM-DD')?.toString(),
        };

        dispatch(
            addStudentTab({
                // id: '2',
                tabName: tabName,
                tracks: tracks,
                joinedDate: joinedDate,
                status: status,
                lastActiveDate: lastactiveDate,
                homeTowns: [homeTown],
                institutes: [parseInt(institue)],
                dateSelected: dateSelected,
                joinDateSelected: joinDateSelected,
            })
        );
    };

    return (
        <>
            <div className="flex items-center pt-8">
                <div className={`flex w-full`}>
                    <CSVEmailedSuccessModal
                        setModalVisible={setModalVisible}
                        modalVisible={modalVisible}
                        message={message}
                        setMessage={setMessage}
                    />
                    <div
                        className={`border-b-4 relative cursor-pointer w-1/2 md:w-1/4 lg:w-1/6  ${
                            selectedTab === 1 ? `border-blue-700` : 'border-snow-500'
                        }`}
                        role="presentation"
                        onClick={() => setSelectedTab(1)}
                    >
                        <div
                            className={`py-2 text-xs sm:text-lg absolute bottom-0 flex w-full justify-center ${
                                selectedTab === 1 ? `text-blue-700 font-semibold` : 'text-black-300'
                            }`}
                        >
                            All Students
                        </div>
                    </div>
                    {/* {tab?.length > 0
                        ? tab?.map(action => (
                              <div
                                  className={`border-b-4 relative cursor-pointer w-1/2 md:w-1/6  ${
                                      selectedTab === action?.filters?.id + 2
                                          ? `border-blue-700`
                                          : 'border-snow-500'
                                  }`}
                                  role="presentation"
                                  key={action?.filters?.id}
                                  onClick={() => {
                                      setSelectedTab(action?.filters?.id + 2);
                                  }}
                              >
                                  <div
                                      className={`py-2 text-xs sm:text-lg absolute bottom-0 flex w-full text-center justify-center ${
                                          selectedTab === 2
                                              ? `text-blue-700 font-semibold`
                                              : 'text-black-300'
                                      }`}
                                  >
                                      {action?.filters?.tabName}
                                  </div>
                              </div>
                          ))
                        : null} */}
                    <div
                        className={`border-b-4 relative cursor-pointer pl-4 ${
                            selectedTab === 2 ? `` : ''
                        }`}
                        role="presentation"
                    >
                        <div className="py-2 text-xs sm:text-lg absolute bottom-0 text-center flex justify-center w-full">
                            {/* <button onClick={() => setShowModal(true)}>
                                <Add size={35} />
                            </button> */}

                            <AddTabs
                                isSelected={showModal}
                                setIsSelected={setShowModal}
                                onAdd={onAdd}
                                tabName={tabName}
                                setTabName={setTabName}
                                studentLessThan={studentLessThan}
                                setStudentLessThan={setStudentLessThan}
                                studentMoreThan={studentMoreThan}
                                setStudentMoreThan={setStudentMoreThan}
                                status={status}
                                setStatus={setStatus}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDAte={endDate}
                                setEndDate={setEndDate}
                                challenges={challenge}
                                setChallenges={setChallenge}
                                tags={tags}
                                setTags={setTags}
                                program={program}
                                setProgram={setProgram}
                                tracks={tracks}
                                setTracks={setTracks}
                                institue={institue}
                                setInstitue={setInstitue}
                                homeTown={homeTown}
                                setHomeTown={setHomeTown}
                                joinStartDate={joinStartDate}
                                setJoinStartDate={setJoinStartDate}
                                joinEndDAte={joinEndDAte}
                                setJoinEndDate={setJoinEndDate}
                                joinDateSelected={joinDateSelected}
                                setJoinDateSelected={setJoinDateSelected}
                                dateSelected={dateSelected}
                                setDateSelected={setDateSelected}
                            />
                        </div>
                    </div>
                    <div className="border-b-4 text-sm border-snow-500 w-2/4 md:w-full lg:w-full">
                        <div
                            className="text-right right-0 flex justify-end text-blue-900 py-2"
                            role="presentation"
                        >
                            {/* <button
                                onClick={() => {
                                    dispatch(downloadCSVStudent());
                                }}
                                className="bg-snow-900 border-2 border-blue-700 border-opacity-40 text-blue-700 hover:text-white hover:bg-blue-700 text-sm md:font-semibold rounded-sm w-1/2 md:w-1/3 xl:w-1/5 h-11 focus:outline-none"
                            >
                                {isStudentCSVLoading ? <Loader /> : 'Download CSV'}
                            </button> */}
                            <button
                                onClick={() => {
                                    dispatch(downloadCSVStudent());
                                }}
                                className="bg-snow-900 border-2 border-blue-700 border-opacity-40 text-blue-700 hover:text-white hover:bg-blue-700 text-sm md:font-semibold rounded-sm w-1/2 md:w-1/3 xl:w-1/5 h-11 focus:outline-none"
                            >
                                {/* <a
                                    href={`${API_URL}/csv/student?fields=Student ID, Student Name, Email,Contact No,Date of Birth,Gender,NIC/Passport Number, Hometown,Address,Hired through FCB,Student's status,Last Active,BRIDGE invitation Code,Secondary Educational level completed,Stream of study in Advanced level,Are you pursuing Higher Education?,Institute Name,Category 1,Category 2,Category 3, Student Registration Number,Reason of joining FCB,Employment status,Company Name,Track Names (attempted), Challenges (attempted),Badges earned,Certificates earned,Total points earned,Average score,Mentor Name, Facebook link,Linked in link,Twitter link,Areas of Interest,Latest Career Progress,Updated/date-time of the career progress,Updated by of the career progress,Joined Date`}
                                >
                                    {isStudentCSVLoading ? <Loader /> : 'Email the CSV'}
                                </a> */}
                                {isStudentCSVLoading ? <Loader /> : 'Email the CSV'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
