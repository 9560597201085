import React, { useEffect } from 'react';
import { BiInfoCircle } from 'react-icons/bi';

interface Props {
    message: string;
    showToast: any;
    error?: boolean;
    selfDestruct?: boolean;
    selfDestructTimer?: any;
}

const MessageToaster = ({
    message,
    showToast,
    error = false,
    selfDestruct,
    selfDestructTimer,
}: Props) => {
    useEffect(() => {
        if (selfDestruct) {
            setTimeout(() => {
                showToast(false);
            }, selfDestructTimer);
        }
    }, [selfDestructTimer, selfDestruct, showToast]);

    return (
        <div
            className={`z-50 px-6 py-3 mt-4 rounded-md ${
                error ? `text-red-700 bg-red-300` : `text-purple-500 bg-purple-75`
            } ${showToast ? `flex flex-row items-center justify-center` : `hidden`}`}
        >
            <BiInfoCircle
                className="hidden lg:block"
                color="rgba(139, 92, 246, var(--tw-text-opacity))"
                size={25}
            />
            <BiInfoCircle
                className="lg:hidden"
                color="rgba(139, 92, 246, var(--tw-text-opacity))"
                size={50}
            />

            <div className={`text-sm lg:text-lg font-bold ml-2`}>{message}</div>
        </div>
    );
};

export default MessageToaster;
