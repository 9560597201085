import React, { useEffect } from 'react';

import { USER_PROFILE } from '../../../utils/storageWeb';
import { RootState } from '../../../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentActivityLog } from '../../../../redux/rootActions';
import useWidth from '../../../hooks/useWidth';
import moment from 'moment';

const ActivityLogTab = () => {
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const activityLogData = useSelector((state: RootState) => state.webUser.studentActivityLog);
    const viewPortWidth = useWidth();

    useEffect(() => {
        dispatch(getStudentActivityLog(false, profile?.userId));
    }, [dispatch]);

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div className="inputFieldThickness mt-4 mb-4  flex flex-col">
                    <div className="flex flex-row py-2.5 pl-8 text-gray-500">
                        <div className="ml-10 mr-64">Date</div>
                        <div className="mr-48">Time</div>
                        <div className="">Activity</div>
                    </div>
                    {activityLogData?.map(activity => (
                        <div
                            style={{ borderTop: '1px solid #D6D8E7' }}
                            className="flex flex-row py-2.5 pl-8 text-gray-500 "
                            key={activity.id}
                        >
                            <div className="ml-10 mr-52">
                                {moment(activity.activityDate).local().format('YYYY-MM-DD')}
                            </div>
                            <div className="mr-40">{activity.activityTime}</div>
                            <div className="">{activity.userAction}</div>
                        </div>
                    ))}
                </div>
            ) : (
                <div
                    className=" mt-8 mb-4  flex flex-col w-11/12 overflow-x-scroll horizontal-scrollable-leaderboard"
                    style={{ fontSize: 10 }}
                >
                    <table className="w-full mb-4 table-fixed text-center inputFieldThickness">
                        <thead className="text-gray-500 gap-x-20">
                            <tr className="h-10">
                                <th className="w-28">Date</th>
                                <th className="w-28">Time</th>
                                <th className="w-40">Activity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {activityLogData?.map(activity => (
                                <tr
                                    style={{ borderTop: '1px solid #D6D8E7' }}
                                    className=" text-gray-500 h-10"
                                    key={activity.id}
                                >
                                    <td className="w-28">{activity.activityDate}</td>
                                    <td className="w-28">{activity.activityTime}</td>
                                    <td className="w-40">{activity.userAction}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default ActivityLogTab;
