import { ADD_SUCCESS_STORY, ADD_SUCCESS_STORY_SUCCESS, ADD_SUCCESS_STORY_FAILED } from './actions';

export interface successStoryInitialState {
    isLoading: boolean;
    addStoryUpdate: string | null;
    error: string | null;
    success: boolean | null;
}

const initialState: successStoryInitialState = {
    isLoading: false,
    addStoryUpdate: null,
    error: null,
    success: null,
};

const webSuccessStoryReducer = (state = initialState, action: any): successStoryInitialState => {
    switch (action.type) {
        case ADD_SUCCESS_STORY:
            return { ...state, isLoading: true };
        case ADD_SUCCESS_STORY_SUCCESS:
            return { ...state, isLoading: false, success: true, addStoryUpdate: action.payload };
        case ADD_SUCCESS_STORY_FAILED:
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
};

export default webSuccessStoryReducer;
