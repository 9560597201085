import React, { ReactElement } from 'react';
import Modal from 'react-responsive-modal';
import { useHistory } from 'react-router-dom';

interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    changeEmailFeedbackUrl: string;
    newEmailAddress: string;
}

export default function EmailVerificationSuccessModal({
    modalVisible,
    setModalVisible,
    changeEmailFeedbackUrl,
    newEmailAddress,
}: Props): ReactElement {
    const history = useHistory();

    return (
        <>
            <Modal
                open={modalVisible}
                onClose={() => {
                    setModalVisible(false);
                }}
                center
                showCloseIcon={false}
                styles={{
                    modal: {
                        borderRadius: 5,
                        maxWidth: '628px',
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        boxShadow: '0 12px 15px 0 rgb(0 0 0 / 10%)',
                    },
                    overlay: { background: 'none' },
                }}
            >
                <div className="">
                    <div className="text-base text-center text-gray-800 px-4 pt-6 pb-4">
                        Your request for change email have been processed successfully. We have sent
                        you a verification email to your new email address {newEmailAddress}.
                    </div>
                    <div className="flex justify-end w-full">
                        <button
                            className="bg-blue-700 text-white rounded-sm focus:outline-none mt-4 px-8 mr-2 md:mr-0 py-2"
                            onClick={() => {
                                setModalVisible(false);
                                history.push({
                                    pathname: changeEmailFeedbackUrl,
                                    state: {
                                        email: newEmailAddress,
                                    },
                                });
                            }}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
