import axios from 'axios';
import { API_URL } from '../../../constants';
import { axiosConfig } from '../../../utils/axiosApi';
import { getErrorMessage } from '../../../utils/axiosErrorHandler';

const PREFIX = '@WEB_MENTORS';

export const REQUEST_JOIN_FCB = PREFIX + 'REQUEST_JOIN_FCB';
export const REQUEST_JOIN_FCB_SUCCESS = PREFIX + 'REQUEST_JOIN_FCB_SUCCESS';
export const REQUEST_JOIN_FCB_FAILED = PREFIX + 'REQUEST_JOIN_FCB_FAILED';
export const CLEAR_JOIN_FCB_MESSAGES = PREFIX + 'CLEAR_JOIN_FCB_MESSAGES';

export const joinFCBCompany = (details?: any) => async (dispatch: any) => {
    try {
        dispatch({ type: REQUEST_JOIN_FCB });
        const config = await axiosConfig(false);
        const response = await axios.post(`${API_URL}/fcb/join/company`, details, config);
        dispatch({ type: REQUEST_JOIN_FCB_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: REQUEST_JOIN_FCB_FAILED, payload: getErrorMessage(error) });
    }
};

export const joinFCBInstitute = (details?: any) => async (dispatch: any) => {
    try {
        dispatch({ type: REQUEST_JOIN_FCB });
        const config = await axiosConfig(false);
        const response = await axios.post(`${API_URL}/fcb/join/institute`, details, config);
        dispatch({ type: REQUEST_JOIN_FCB_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: REQUEST_JOIN_FCB_FAILED, payload: getErrorMessage(error) });
    }
};

export const clearJoinFCBMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_JOIN_FCB_MESSAGES });
};
