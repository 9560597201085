import { Formik } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import diff from 'object-diff';
import * as HiIcons from 'react-icons/hi';
import S3 from 'react-aws-s3';
import { USER_PROFILE } from '../../../utils/storageWeb';
import { useDispatch, useSelector } from 'react-redux';
import {
    editWebUser,
    getInstitutesPublicData,
    getTags,
    getCompanyPublicData,
} from '../../../../redux/rootActions';
import { RootState } from '../../../../redux/rootReducer';
import moment from 'moment';
import { InstitutesType } from '../../../../redux/institutes/reducers';
import Loader from '../../../../assets/svg/Loader';
import { CityFilter, moduleNames, s3Config, s3UrlPrefix } from '../../../../constants';
import { mobielNumberVerification } from '../../../utils';
import { useHistory } from 'react-router-dom';
import useWidth from '../../../hooks/useWidth';
import styled from 'styled-components';
import BackArrow from '../../../../assets/svg/BackArrow';
import Modal from 'react-modal';

const SmallFontDiv = styled.div`
    font-size: 10px;
`;

const SmallFontLabel = styled.label`
    font-size: 10px;
`;

const ModalContent = styled.div`
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
`;

const customStyles: Modal.Styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(239, 235, 255, 0.7)',
        backdropFilter: 'blur(4px)',
        overflow: 'hidden',
        zIndex: 998,
    },
    content: {
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        top: 'auto',
        padding: '0px',
        border: '0px',
        backgroundColor: 'transparent',
        zIndex: 999,
    },
};

export default function EditProfile(): ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const isLoading = useSelector((state: RootState) => state.webUser.isEditLoading);
    const studentUpdateSuccessPopup = useSelector(
        (state: RootState) => state.webUser.studentUpdateSuccessPopup
    );

    const studentData = useSelector((state: RootState) => state.webUser.student);
    const instituteData = useSelector((state: RootState) => state.institutes.publicInstitutData);
    const companyData = useSelector((state: RootState) => state.companies.companyPublicData);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [interestTag, setInterestTag] = useState<any>();
    const [institue, setInstitute] = useState<string>(studentData?.InstituteName);
    const [institueID, setInstituteID] = useState<string>(
        studentData?.StudentInstitute?.instituteId
    );
    const [educationStatus, setEducationStatus] = useState<any>(null);
    const [hometownDropdownVisible, setHometownDropdownVisible] = useState(false);
    const [instituteDropdownVisible, setInstituteDropdownVisible] = useState(false);
    const [educationStatusDropdownVisible, setEducationStatusDropdownVisible] = useState(false);
    const [genderDropdownVisible, setGenderDropdownVisible] = useState(false);
    const [InstituteFilterAction, setInstituteFilterAction] =
        useState<string>('Select your institute');
    const [educationStatusFilterAction, setEducationStatusFilterAction] = useState<string>(
        'Select your Education Status'
    );
    const [trackFilterAddressAction, setTrackFilterAddressAction] =
        useState<string>('Select your home town');
    const [trackFilterGenderAction, setTrackFilterGenderAction] =
        useState<string>('Select your gender');
    const [batchNumber, setBatchNumber] = useState<any>(studentData?.StudentInstitute?.studentId);
    const [trackingCode, setTrackingCode] = useState<any>(studentData?.bridgeInvitationCode);
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    const [firstName, setFirstName] = useState<string>(studentData?.firstName);
    const [fullName, setFullName] = useState<string>(null);
    const [lastName, setLastName] = useState<string>(studentData?.lastName);
    const [nicNumber, setNICNumber] = useState<string>(studentData?.nic);
    const [mobileNumber, setMobileNumber] = useState<any>(studentData?.contact?.mobileNo);
    const [email, setEmail] = useState<string>(studentData?.emailId);
    const [address, setAddress] = useState<string>(studentData?.contact?.address1);
    const [facebook, setFacebook] = useState<string>('');
    const [linkedin, setLinkedin] = useState<string>('');
    const [twitter, setTwitter] = useState<string>('');
    const [interestedTags, setInterestedTags] = useState([]);
    const [selectedTags, setselectedTags] = useState([]);
    const [isHiredThroughFCB, setIsHiredThroughFCB] = useState<boolean>(studentData?.hiredByFCB);
    const [careerStatus, setCareerStatus] = useState([]);
    const [addCareerStatus, setAddCareerStatus] = useState<boolean>(false);

    const [uploadedFile, setUploadedFile] = useState<string>(null);

    const [selectedInstitue, setSelectedInstitue] = useState<InstitutesType[]>([]);

    const [isCat1, setIsCat1] = useState(false);
    const [isCat2, setIsCat2] = useState(false);
    const [isCat3, setIsCat3] = useState(false);

    const [institueCat1DropDownVisible, setInstitueCat1DropDownVisible] = useState(false);
    const [cat1FilterAction, setcat1FilterAction] = useState<string>('Select');
    const [institueCat2DropDownVisible, setInstitueCat2DropDownVisible] = useState(false);
    const [cat2FilterAction, setcat2FilterAction] = useState<string>('Select');
    const [institueCat3DropDownVisible, setInstitueCat3DropDownVisible] = useState(false);
    const [cat3FilterAction, setcat3FilterAction] = useState<string>('Select');

    const [category1, setCategory1] = useState<any>(null);
    const [category2, setCategory2] = useState<any>(null);
    const [category3, setCategory3] = useState<any>(null);

    const [categoryName1, setCategoryName1] = useState<string[]>(null);
    const [categoryName2, setCategoryName2] = useState<string[]>(null);
    const [categoryName3, setCategoryName3] = useState<string[]>(null);

    const [instCategory1, setInstCategory1] = useState<any>(null);
    const [instCategory2, setInstCategory2] = useState<any>(null);
    const [instCategory3, setInstCategory3] = useState<any>(null);

    const [isErrorFirstName, setIsErrorFirstName] = useState<boolean>(false);
    const [isErrorLastName, setIsErrorLastName] = useState<boolean>(false);
    const [isErrorDOB, setIsErrorDOB] = useState<boolean>(false);
    const [isErrorGender, setIsErrorGender] = useState<boolean>(false);
    const [isErrorMobileNo, setIsErrorMobileNo] = useState<boolean>(false);
    const [isErrorEmail, setIsErrorEmail] = useState<boolean>(false);
    const [isErrorHomeTown, setIsErrorHomeTown] = useState<boolean>(false);
    const [isErrorAddress, setIsErrorAddress] = useState<boolean>(false);
    const [isErrorInstitue, setIsErrorInstitue] = useState<boolean>(false);
    const [isErrorCompany, setIsErrorCompany] = useState<boolean>(false);
    const [isErrorInterestedField, setIsErrorInterestedField] = useState<boolean>(false);
    const [isErrorOtherCompany, setIsErrorOtherCompany] = useState<boolean>(false);
    const [isErrorBatchNumber, setIsErrorBatchNumber] = useState<boolean>(false);
    const [isErrorLinkeIN, setIsErrorLinkeIN] = useState<boolean>(false);
    const [isErrorFacebook, setIsErrorFacebook] = useState<boolean>(false);
    const [isErrorTwitter, setIsErrorTwitter] = useState<boolean>(false);
    const [belowHometown, setBelowHometown] = useState<number>();
    const [isErrorStream, setIsErrorStream] = useState<boolean>(false);
    const [streamDropdownVisible, setStreamDropdownVisible] = useState<boolean>(false);
    const [AlStreamFilterAction, setAlStreamFilterAction] = useState<any>('Science');

    // const [completeEducation, setCompleteEducation] = useState(false);
    const [uploadHover, setUploadHover] = useState(false);
    // const [uploadIcon, setUploadIcon] = useState(false);
    const [emailHelpText, setEmailHelpText] = useState<boolean>(false);
    const [firstNameHelpText, setFirstNameHelpText] = useState<boolean>(false);
    const [lastNameHelpText, setLastNameHelpText] = useState<boolean>(false);
    const emailCreationURL = 'https://www.youtube.com/watch?v=WkUGuBpedfI';
    // const [cvUrl, setCvUrl] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageLocation, setImageLocation] = useState('');

    const [isPublic, setIsPublic] = useState(false);
    const [enablePublicProfile, setEnablePublicProfile] = useState(false);

    const [stream, setStream] = useState<any>();
    const [isPursuingHigherEducation, setIsPursuingHigherEducation] = useState('Yes');
    const [pursuingHigherStudies, setPursuingHigherStudies] = useState<any>();
    const [joinedFutureCareerBridgeReason, setJoinedFutureCareerBridgeReason] = useState(
        'I want to find an internship'
    );
    const [isEmployed, setIsEmployed] = useState('No');
    const [companyDropdownVisible, setCompanyDropdownVisible] = useState(false);
    const [companyFilterAction, setCompanyFilterAction] = useState<string>('Select your company');
    const [company, setCompany] = useState<string>(null);
    const [otherCompany, setOtherCompany] = useState<string>(null);
    const [showProfilePicModal, setShowProfilePicModal] = useState(false);
    const viewPortWidth = useWidth();
    const inputFile = useRef(null);
    const EducationStatusFilter = [
        { id: 0, val: `I have not done O/Ls yet` },
        { id: 1, val: `I have completed O/Ls` },
        {
            id: 3,
            val: `I have not done A/Ls yet`,
        },
        {
            id: 4,
            val: `I have completed A/Ls`,
        },
    ];

    const TrackGenderFilter = [
        { id: 0, val: 'Male' },
        { id: 1, val: 'Female' },
        { id: 2, val: 'Other' },
    ];

    const validationSchemaDet = Yup.object({
        email: Yup.string()
            .trim()
            .email('Email must be a valid email')
            .required('Email address is required'),
    });

    const AlStreamFilter = [
        { id: 0, val: 'Science' },
        { id: 1, val: 'Mathematics' },
        { id: 2, val: 'Commerce' },
        { id: 2, val: 'Arts' },
        { id: 2, val: 'Technical' },
    ];

    useEffect(() => {
        setSelectedInstitue(instituteData?.filter(data => data.instName === institue));
    }, [institue, instituteData]);

    useEffect(() => {
        if (
            selectedInstitue
                ?.map(
                    data =>
                        data?.instCategory1 !== null &&
                        data?.instCategory1?.name !== '' &&
                        data?.instCategory1?.name !== undefined
                )
                .includes(true)
        ) {
            setCategoryName1(selectedInstitue.map(data => data?.instCategory1?.name));
            setIsCat1(true);
        } else {
            setIsCat1(false);
        }
        if (
            selectedInstitue
                ?.map(
                    data =>
                        data?.instCategory2 !== null &&
                        data?.instCategory2?.name !== '' &&
                        data?.instCategory2?.name !== undefined
                )
                .includes(true)
        ) {
            setCategoryName2(selectedInstitue.map(data => data?.instCategory2?.name));
            setIsCat2(true);
        } else {
            setIsCat2(false);
        }
        if (
            selectedInstitue
                ?.map(
                    data =>
                        data?.instCategory3 !== null &&
                        data?.instCategory3?.name !== '' &&
                        data?.instCategory3?.name !== undefined
                )
                .includes(true)
        ) {
            setCategoryName3(selectedInstitue.map(data => data?.instCategory3?.name));
            setIsCat3(true);
        } else {
            setIsCat3(false);
        }
    }, [selectedInstitue]);

    useEffect(() => {
        dispatch(getInstitutesPublicData());
        dispatch(getCompanyPublicData());
        dispatch(getTags(false, moduleNames.Tracks));
    }, [dispatch]);

    useEffect(() => {
        if (interestedTags?.length > 2) {
            setIsErrorInterestedField(false);
        }
        setselectedTags(interestedTags?.map(action => action.tagName));
    }, [interestedTags]);

    useEffect(() => {
        if (tagData?.length > 0) {
            setInterestTag(
                tagData?.filter(
                    tag =>
                        tag.tagCategory.toLowerCase() === 'interest' ||
                        tag.tagCategory.toLowerCase() === 'interests' ||
                        tag.tagCategory.toLowerCase() === 'interest '
                )
            );
        }
    }, [tagData]);

    useEffect(() => {
        if (studentUpdateSuccessPopup) {
            history.goBack();
        }
    }, [studentUpdateSuccessPopup]);

    useEffect(() => {
        if (cat1FilterAction !== 'Select') {
            setCategory1(cat1FilterAction);
        }

        if (cat2FilterAction !== 'Select') {
            setCategory2(cat2FilterAction);
        }

        if (cat3FilterAction !== 'Select') {
            setCategory3(cat3FilterAction);
        }
    }, [cat1FilterAction, cat2FilterAction, cat3FilterAction]);

    useEffect(() => {
        if (studentData?.cvLink) {
            setUploadedFile(studentData?.cvLink);
        }
        if (studentData?.InstituteName) {
            setEducationStatusFilterAction(studentData?.InstituteName);
        }
        if (studentData?.instituteCategories?.category1) {
            setcat1FilterAction(studentData?.instituteCategories?.category1?.option);
        }
        if (studentData?.instituteCategories?.category2) {
            setcat2FilterAction(studentData?.instituteCategories?.category2?.option);
        }
        if (studentData?.instituteCategories?.category3) {
            setcat3FilterAction(studentData?.instituteCategories?.category3?.option);
        }
        if (studentData?.alStream) {
            setStream(studentData?.alStream);
        }
        if (studentData?.pursuingHigherStudies !== null) {
            if (studentData?.pursuingHigherStudies === false) {
                setIsPursuingHigherEducation('No');
                setPursuingHigherStudies(false);
            } else {
                setIsPursuingHigherEducation('Yes');
                setPursuingHigherStudies(true);
            }
        }
        if (studentData?.reasonForJoin) {
            setJoinedFutureCareerBridgeReason(studentData?.reasonForJoin);
        }
        if (studentData?.empStatus) {
            setIsEmployed(studentData?.empStatus);
        }
        if (studentData?.companyName) {
            setCompany(studentData?.companyName);
            setCompanyFilterAction(studentData?.companyName);
        }

        if (studentData?.imageUrl) {
            setImageUrl(studentData?.imageUrl);
            setImageLocation(studentData?.imageUrl);
        }
        if (studentData?.enablePublicProfile) {
            setEnablePublicProfile(studentData?.enablePublicProfile);
        }
        if (studentData?.isPublic) {
            setIsPublic(studentData?.isPublic);
        }
        if (studentData?.socialMediaUrls.length > 0) {
            setLinkedin(studentData.socialMediaUrls[0].url);
            setFacebook(studentData.socialMediaUrls[1].url);
            setTwitter(studentData.socialMediaUrls[2].url);
        }
        if (studentData?.educationStatus) {
            setEducationStatusFilterAction(studentData?.educationStatus);
            setEducationStatus(studentData?.educationStatus);
        }
        if (studentData?.bridgeInvitationCode) {
            setTrackingCode(studentData?.bridgeInvitationCode);
        }
        if (studentData?.hiredByFCB) {
            setIsHiredThroughFCB(studentData?.hiredByFCB);
        }
        if (studentData?.careerStatus?.length > 0) {
            setCareerStatus(studentData?.careerStatus);
        }

        if (studentData?.tags) {
            setInterestedTags(studentData?.tags);
        }

        setFirstName(studentData?.firstName);
        if (studentData?.StudentInstitute?.studentId) {
            setBatchNumber(studentData?.StudentInstitute?.studentId);
        }

        if (studentData?.birthDate !== null && studentData?.birthDate !== undefined) {
            setDateOfBirth(new Date(studentData?.birthDate));
        } else {
            setDateOfBirth(new Date('1999-01-01'));
        }
        setLastName(studentData?.lastName);
        setNICNumber(studentData?.nic);
        setMobileNumber(studentData?.contact?.mobileNo);
        setEmail(studentData?.emailId);
        setAddress(studentData?.contact?.address1);
        if (studentData?.contact?.city) {
            setTrackFilterAddressAction(studentData?.contact?.city);
        }
        if (studentData?.alStream) {
            setAlStreamFilterAction(studentData?.alStream);
        }
        if (studentData?.InstituteName) {
            setInstituteFilterAction(studentData?.InstituteName);
        }
        if (studentData?.gender) {
            setTrackFilterGenderAction(studentData?.gender);
        }
    }, [studentData]);

    useEffect(() => {
        if (hometownDropdownVisible === true) {
            const height = document.getElementById('hometwonId')?.clientHeight;
            setBelowHometown(height + 20);
        } else {
            setBelowHometown(15);
        }
    }, [hometownDropdownVisible]);

    useEffect(() => {
        if (categoryName1 !== null) {
            setInstCategory1({
                name: categoryName1[0],
                option: category1,
            });
        } else {
            setInstCategory1(null);
        }

        if (categoryName2 !== null) {
            setInstCategory2({
                name: categoryName2[0],
                option: category2,
            });
        } else {
            setInstCategory2(null);
        }

        if (categoryName3 !== null) {
            setInstCategory3({
                name: categoryName3[0],
                option: category3,
            });
        } else {
            setInstCategory3(null);
        }
    }, [category1, category2, category3, categoryName1, categoryName2, categoryName3]);

    useEffect(() => {
        if (linkedin?.match(/^https?:\/\//i)) {
            setIsErrorLinkeIN(false);
        }
        if (facebook?.match(/^https?:\/\//i)) {
            setIsErrorFacebook(false);
        }
        if (twitter?.match(/^https?:\/\//i)) {
            setIsErrorTwitter(false);
        }
    }, [facebook, linkedin, twitter]);

    const PersonalDetailsCheck = values => {
        if (firstName === null || firstName === undefined || firstName === '') {
            setIsErrorFirstName(true);
        } else if (lastName === null || lastName === undefined || lastName === '') {
            setIsErrorLastName(true);
        } else if (dateOfBirth === undefined || dateOfBirth === null) {
            setIsErrorDOB(true);
        } else if (
            trackFilterGenderAction === 'Select your gender' ||
            trackFilterGenderAction === undefined
        ) {
            setIsErrorGender(true);
        } else if (mobielNumberVerification(mobileNumber)) {
            setIsErrorMobileNo(true);
        } else if (email === null || email === undefined) {
            setIsErrorEmail(true);
        } else if (
            trackFilterAddressAction === 'Select your home town' ||
            trackFilterAddressAction === undefined
        ) {
            setIsErrorHomeTown(true);
        } else if (address === null || address === undefined || address === '') {
            setIsErrorAddress(true);
        } else if (
            educationStatus !== 'I have not done O/Ls yet' &&
            isPursuingHigherEducation === 'Yes' &&
            (InstituteFilterAction === 'Select a institute' || InstituteFilterAction === null)
        ) {
            setIsErrorInstitue(true);
        } else if (
            educationStatus !== 'I have not done O/Ls yet' &&
            isPursuingHigherEducation === 'Yes' &&
            (batchNumber === null || batchNumber === undefined || batchNumber === '')
        ) {
            setIsErrorBatchNumber(true);
        } else if (
            educationStatus === 'I have completed A/Ls' &&
            (stream === null || stream === undefined || stream === '')
        ) {
            setIsErrorStream(true);
        } else if (
            isEmployed === 'Yes, at a company' &&
            (companyFilterAction === 'Select your company' ||
                companyFilterAction === 'Select a company' ||
                companyFilterAction === null)
        ) {
            setIsErrorCompany(true);
        } else if (
            isEmployed === 'Yes, at a company' &&
            companyFilterAction === 'Others' &&
            (otherCompany === '' || otherCompany === null || otherCompany === undefined)
        ) {
            setIsErrorOtherCompany(true);
        } else if (interestedTags?.length < 3) {
            setIsErrorInterestedField(true);
        } else {
            let updatedFileds = diff(studentData, {
                bridgeInvitationCode: trackingCode,
                userName: values.email,
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                birthDate: moment(dateOfBirth).format('YYYY-MM-DD'),
                gender: trackFilterGenderAction,
                nic: nicNumber?.trim(),
                status: 'ACTIVE',
                emailId: values.email,
                tags: interestedTags,
                hiredByFCB: isHiredThroughFCB,
                careerStatus: careerStatus,
                educationStatus: educationStatus,
                alStream: stream,
                pursuingHigherStudies: pursuingHigherStudies,
                reasonForJoin: joinedFutureCareerBridgeReason,
                empStatus: isEmployed,
                companyName: company,
                enablePublicProfile: enablePublicProfile,
                isPublic: isPublic,
                cvLink: uploadedFile,
                imageUrl: imageLocation,
                institute: institueID
                    ? studentData?.StudentInstitute
                        ? diff(studentData.StudentInstitute, {
                              instituteId: institueID,
                              studentId: batchNumber,
                              instCategory1: instCategory1,
                              instCategory2: instCategory2,
                              instCategory3: instCategory3,
                          })
                        : {
                              id: institueID,
                              studentId: batchNumber,
                              instCategory1: instCategory1,
                              instCategory2: instCategory2,
                              instCategory3: instCategory3,
                          }
                    : null,
                contact: diff(studentData?.contact, {
                    mobileNo: mobileNumber,
                    city: trackFilterAddressAction,
                    address1: address?.trim(),
                }),
            });
            if (
                updatedFileds.institute !== undefined &&
                updatedFileds.institute !== null &&
                Object.keys(updatedFileds.institute).length > 0
            ) {
                updatedFileds.institute.id = institueID;
            }
            if (
                studentData?.socialMediaUrls[0]?.url !== linkedin.trim() ||
                studentData?.socialMediaUrls[1]?.url !== facebook.trim() ||
                studentData?.socialMediaUrls[2]?.url !== twitter.trim()
            ) {
                updatedFileds = Object.assign(updatedFileds, {
                    socialMediaUrls: [
                        {
                            platform: 'LINKEDIN',
                            url: linkedin,
                        },
                        {
                            platform: 'FACEBOOK',
                            url: facebook,
                        },
                        {
                            platform: 'TWITTER',
                            url: twitter,
                        },
                    ],
                });
            }

            dispatch(editWebUser(profile?.userId, updatedFileds));
            // window.location.href = `/dashBoard/settings`;
        }
    };

    const dropHandler = ev => {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            // If dropped items aren't files, reject them
            if (ev.dataTransfer.items[0].kind === 'file') {
                // setCvUrl(URL.createObjectURL(ev.dataTransfer.files[0]));
                setUploadedFile(ev.dataTransfer.files[0].name);
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            // setCvUrl(URL.createObjectURL(ev.dataTransfer.files[0]));
            setUploadedFile(ev.dataTransfer.files[0].name);
        }
    };

    const dragOverHandler = ev => {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
    };

    const subYears = years => {
        return new Date().setFullYear(new Date().getFullYear() - years);
    };

    useEffect(() => {
        let newFullName;
        if (studentData?.firstName !== null) {
            newFullName = studentData?.firstName;
            if (lastName !== null) {
                newFullName = studentData?.firstName + ' ' + studentData?.lastName;
            }
        }
        setFullName(newFullName);
    }, [lastName, studentData]);

    const handleImage = (e: any) => {
        const _URL = window.URL || window.webkitURL;
        let file: File, img;
        if ((file = e?.target?.files[0])) {
            img = new Image();
            img.src = _URL.createObjectURL(file);
            setImageUrl(img.src);
        }
    };

    const uploadImageToS3 = async e => {
        const imageName = Date.now().toString();
        s3Config.dirName = `webUsers/${imageName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(e.target.files[0], imageName)
            .then(data => {
                setImageLocation(data.key);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const deleteImageFromS3 = async () => {
        const imageName = imageUrl;
        // s3Config.dirName = `webUsers/${imageUrl}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.deleteFile(imageName)
            .then(() => {
                setImageUrl('');
                setImageLocation('');
            })
            .catch(err => {
                setImageUrl('');
                setImageLocation('');
                console.error(err);
            });
    };

    const uploadCVToS3 = async e => {
        // setIsUploading(true);
        const cvName = Date.now().toString();
        s3Config.dirName = `webUsers/cv/${cvName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(e.target.files[0], cvName)
            .then(data => {
                setUploadedFile(data.key);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const handleUploadPhotoBtnClickMobile = () => {
        inputFile.current.click();
    };

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div className="mx-10 md:mx-5 md:ml-14 lg:ml-72 lg:pl-3.5 lg:pt-2">
                    <div className="md:mt-14">
                        <Formik
                            enableReinitialize
                            validationSchema={validationSchemaDet}
                            initialValues={{
                                email: studentData?.emailId,
                                password: '',
                                description: '',
                            }}
                            onSubmit={async values => {
                                PersonalDetailsCheck(values);
                            }}
                        >
                            {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
                                <>
                                    <>
                                        <div className="font-bold font-manrope text-xl">
                                            Edit Profile
                                        </div>
                                        <div className="mb-8 mt-6 flex flex-row justify-between">
                                            <div className="flex flex-row ">
                                                {uploadHover ? (
                                                    <ul
                                                        className="origin-top-left absolute  pl-0 mt-14 max-h-20 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                        onMouseOverCapture={() =>
                                                            setUploadHover(true)
                                                        }
                                                        onMouseLeave={() => setUploadHover(false)}
                                                    >
                                                        <label
                                                            htmlFor="image"
                                                            className="block px-4 py-2 font-manrope text-sm text-gray-700 no-underline hover:bg-gray-200 cursor-pointer whitespace-nowrap"
                                                        >
                                                            Change profile picture
                                                        </label>
                                                        {imageUrl && (
                                                            <label
                                                                onClick={() => {
                                                                    deleteImageFromS3();
                                                                    setImageUrl('');
                                                                    setImageLocation('');
                                                                }}
                                                                className="block px-4 py-2 font-manrope text-sm text-red-500 no-underline hover:bg-gray-200 cursor-pointer"
                                                            >
                                                                Remove
                                                            </label>
                                                        )}
                                                    </ul>
                                                ) : null}

                                                <input
                                                    id="image"
                                                    className="hidden"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={e => {
                                                        const file = [];
                                                        file.push(e);
                                                        handleImage(e);
                                                        uploadImageToS3(e);
                                                    }}
                                                />
                                                <img
                                                    className={`blur-3xl rounded-full h-32 w-32 cursor-pointer`}
                                                    src={`${
                                                        imageUrl === '' || imageUrl === null
                                                            ? '/assets/user.png'
                                                            : imageUrl.includes('webUsers')
                                                            ? s3UrlPrefix + imageUrl
                                                            : imageUrl
                                                    }`}
                                                    alt="profile"
                                                    onClick={() => {
                                                        setUploadHover(true);
                                                    }}
                                                    onMouseOver={() => {
                                                        // setUploadIcon(true);
                                                    }}
                                                />
                                                <div
                                                    className="flex flex-col ml-6 pt-1"
                                                    style={{
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <p className="text-lg font-bold font-manrope mb-1.5">
                                                        {fullName}
                                                    </p>
                                                    <p className="text-xs text-gray-500 mb-2.5">
                                                        {/* {studentData?.contact?.city +
                                                        ', ' +
                                                        studentData?.contact?.province} */}
                                                        {studentData?.contact?.city}
                                                    </p>
                                                    <div className="font-medium text-gray-600 text-sm flex py-1 mt-4">
                                                        <input
                                                            className="mr-1 mt-1"
                                                            id="consent"
                                                            type="checkbox"
                                                            checked={enablePublicProfile}
                                                            onChange={() => {
                                                                setEnablePublicProfile(
                                                                    !enablePublicProfile
                                                                );
                                                            }}
                                                        />
                                                        <label
                                                            className="cursor-pointer"
                                                            htmlFor="consent"
                                                        >
                                                            I consent that my profile picture can be
                                                            shown in the leaderboards.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="font-bold font-manrope text-xl mb-3">
                                            Public Profile
                                        </div>
                                        <div className="flex flex-row">
                                            <div className="flex flex-col mb-1 w-full md:w-2/5">
                                                <label
                                                    className="flex flex-row font-manrope text-sm my-1"
                                                    htmlFor="tab_1"
                                                >
                                                    My Public Profile URL
                                                </label>
                                                <div
                                                    className={`pl-4 flex items-center h-12 inputFieldThickness`}
                                                    id="tab_1"
                                                >{`${window.location.origin}/public-profile/${studentData?.id}`}</div>
                                            </div>
                                            <div className="flex flex-col mb-1 ml-8 w-full md:w-2/5">
                                                <div className="font-medium text-gray-600 text-sm align-top items-start flex py-2 mt-5">
                                                    <div
                                                        onClick={() => {
                                                            window.open(
                                                                `${window.location.origin}/public-profile/${studentData?.id}`,
                                                                '_blank'
                                                            );
                                                        }}
                                                        className={`underline text-purple-950 cursor-pointer whitespace-nowrap pr-2`}
                                                    >
                                                        Open link
                                                    </div>
                                                    <input
                                                        className="mr-1 mt-1"
                                                        id="public_profile_consent"
                                                        type="checkbox"
                                                        checked={isPublic}
                                                        onChange={() => {
                                                            setIsPublic(!isPublic);
                                                        }}
                                                    />
                                                    <label
                                                        className="cursor-pointer"
                                                        htmlFor="public_profile_consent"
                                                    >
                                                        Keep my profile publicly linked and
                                                        accessible through the BRIDGE website
                                                        leaderboard and via QR code on the
                                                        certificates.
                                                        {isPublic && (
                                                            <span className=" block font-bold text-gray-700">
                                                                Your personal information and BRIDGE
                                                                progress information will now be
                                                                shared publicly.
                                                            </span>
                                                        )}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="font-bold font-manrope text-xl mt-4 mb-2">
                                            Personal details
                                        </p>
                                        <div className="flex flex-row">
                                            <div className="flex flex-col my-1 w-full md:w-2/5">
                                                <div className="flex flex-row">
                                                    <label
                                                        className="flex flex-row my-1 font-manrope text-sm"
                                                        htmlFor="tab_1"
                                                    >
                                                        First Name{' '}
                                                        <span className="text-red-600">*</span>
                                                    </label>
                                                    <HiIcons.HiQuestionMarkCircle
                                                        onClick={() => {
                                                            setFirstNameHelpText(true);
                                                        }}
                                                        className="ml-2 mt-1 cursor-pointer"
                                                    />
                                                    {firstNameHelpText ? (
                                                        <div
                                                            onMouseOverCapture={() => {
                                                                setFirstNameHelpText(true);
                                                                // setProfileDropdownVisible(false);
                                                            }}
                                                            onMouseLeave={() =>
                                                                setFirstNameHelpText(false)
                                                            }
                                                            className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-7"
                                                            style={{
                                                                width: '375px',
                                                            }}
                                                        >
                                                            Please enter your First Name accurately,
                                                            as it will appear on your certificates.
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <input
                                                    className={`pl-4 py-1 h-12 ${
                                                        isErrorFirstName
                                                            ? 'inputFieldErrorThickness'
                                                            : 'inputFieldThickness'
                                                    }`}
                                                    type="text"
                                                    id="tab_1"
                                                    value={firstName || ''}
                                                    placeholder="Enter your first name"
                                                    onChange={e => {
                                                        setFirstName(e.target.value);
                                                        if (e.target.value.length !== 0) {
                                                            setIsErrorFirstName(false);
                                                        }
                                                    }}
                                                />
                                                {isErrorFirstName ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        First Name is mandatory
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="flex flex-col my-1 ml-8 w-full md:w-2/5">
                                                <div className="flex flex-row">
                                                    <label
                                                        className="flex flex-row my-1 font-manrope text-sm"
                                                        htmlFor="tab_1"
                                                    >
                                                        Last Name
                                                        <span className="text-red-600">*</span>
                                                    </label>
                                                    <HiIcons.HiQuestionMarkCircle
                                                        onClick={() => {
                                                            setLastNameHelpText(true);
                                                        }}
                                                        className="ml-2 mt-1 cursor-pointer"
                                                    />
                                                    {lastNameHelpText ? (
                                                        <div
                                                            onMouseOverCapture={() => {
                                                                setLastNameHelpText(true);
                                                                // setProfileDropdownVisible(false);
                                                            }}
                                                            onMouseLeave={() =>
                                                                setLastNameHelpText(false)
                                                            }
                                                            className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-7"
                                                            style={{
                                                                width: '375px',
                                                            }}
                                                        >
                                                            Please enter your Last Name accurately,
                                                            as it will appear on your certificates.
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <input
                                                    className={`pl-4 py-1 h-12 ${
                                                        isErrorLastName
                                                            ? 'inputFieldErrorThickness'
                                                            : 'inputFieldThickness'
                                                    }`}
                                                    type="text"
                                                    id="tab_1"
                                                    value={lastName || ''}
                                                    placeholder="Enter your last name"
                                                    onChange={e => {
                                                        setLastName(e.target.value);
                                                        if (e.target.value) {
                                                            setIsErrorLastName(false);
                                                        }
                                                    }}
                                                />
                                                {isErrorLastName ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        Last Name is mandatory
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="flex flex-row mt-2">
                                            <div className="flex flex-col my-1 w-full md:w-2/5">
                                                <label
                                                    className="flex flex-row my-1 font-manrope text-sm"
                                                    htmlFor="tab_1"
                                                >
                                                    Date of birth
                                                    <span className="text-red-600">*</span>
                                                </label>
                                                <div className=" leading-normal">
                                                    <label
                                                        htmlFor="set_date"
                                                        className="inputFieldThickness h-12 flex flex-row w-full justify-between"
                                                    >
                                                        <form autoComplete="off">
                                                            <DatePicker
                                                                required
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                yearDropdownItemNumber={30}
                                                                scrollableYearDropdown
                                                                id="set_date"
                                                                className="text-sm p-1 h-7 ml-2 mt-2"
                                                                selected={dateOfBirth}
                                                                autoComplete="new-password"
                                                                onChange={date => {
                                                                    if (date != null) {
                                                                        setDateOfBirth(date);
                                                                    }
                                                                }}
                                                                maxDate={new Date()}
                                                                dateFormat="do MMMM yyyy"
                                                                placeholderText="select your date of birth"
                                                            />
                                                        </form>
                                                        <label className="pr-2" htmlFor="set_date">
                                                            <HiIcons.HiCalendar className="mr-2 mt-3" />
                                                        </label>
                                                    </label>
                                                    {isErrorDOB ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            Select your date of birth
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="flex flex-col my-1 ml-8 w-full md:w-2/5">
                                                <label
                                                    className="flex flex-row my-1 font-manrope text-sm"
                                                    htmlFor="tab_1"
                                                >
                                                    Gender<span className="text-red-600">*</span>
                                                </label>
                                                <div
                                                    className={`flex relative cursor-pointer items-center focus:outline-none h-12 md:p-2 text-sm focus:border-blue-900 ${
                                                        isErrorGender
                                                            ? 'inputFieldErrorThickness'
                                                            : 'inputFieldThickness'
                                                    } `}
                                                >
                                                    <div
                                                        className="flex justify-between w-full"
                                                        onClick={() =>
                                                            setGenderDropdownVisible(true)
                                                        }
                                                    >
                                                        <div
                                                            className={` font-medium pl-4 ${
                                                                trackFilterGenderAction !==
                                                                'Select your gender'
                                                                    ? 'text-gray-700 font-medium'
                                                                    : 'text-gray-400'
                                                            }`}
                                                        >
                                                            {trackFilterGenderAction}
                                                        </div>
                                                        <div className="">
                                                            <div className="flex flex-row">
                                                                <div className="flex flex-col">
                                                                    <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                    <HiIcons.HiChevronDown className="mr-2" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {genderDropdownVisible ? (
                                                        <ul
                                                            className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                            role="menu"
                                                            onMouseLeave={() =>
                                                                setGenderDropdownVisible(false)
                                                            }
                                                        >
                                                            {TrackGenderFilter?.map(action => (
                                                                <div
                                                                    className="p-2 pl-4 hover:bg-gray-200 "
                                                                    key={action.id}
                                                                    onClick={() => {
                                                                        setIsErrorGender(false);
                                                                        setTrackFilterGenderAction(
                                                                            action.val
                                                                        );
                                                                        setGenderDropdownVisible(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    {action.val}
                                                                </div>
                                                            ))}
                                                        </ul>
                                                    ) : null}
                                                </div>
                                                {isErrorGender ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        Select a gender
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="flex flex-row">
                                            <div className="flex flex-col my-1 w-full md:w-2/5">
                                                <label
                                                    className="flex flex-col my-1 font-manrope text-sm"
                                                    htmlFor="tab_1"
                                                >
                                                    NIC / Passport Number
                                                </label>
                                                <input
                                                    className="inputFieldThickness pl-4 py-1 h-12"
                                                    type="text"
                                                    id="tab_1"
                                                    value={nicNumber || ''}
                                                    placeholder="Enter your NIC number"
                                                    onChange={e => {
                                                        setNICNumber(e.target.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="flex flex-col my-1 ml-8 w-full md:w-2/5">
                                                <label
                                                    className="flex flex-row my-1 font-manrope text-sm"
                                                    htmlFor="tab_1"
                                                >
                                                    Mobile<span className="text-red-600">*</span>
                                                </label>
                                                <input
                                                    className={`pl-4 py-1 h-12 ${
                                                        isErrorMobileNo
                                                            ? 'inputFieldErrorThickness'
                                                            : 'inputFieldThickness'
                                                    } ${
                                                        studentData?.contact?.mobileNo !== null &&
                                                        studentData?.contact?.mobileNo !==
                                                            undefined &&
                                                        'pointer-events-none opacity-50'
                                                    }`}
                                                    type="text"
                                                    id="tab_1"
                                                    value={mobileNumber || ''}
                                                    placeholder="Enter your Mobile Number (Eg: 94712345678)"
                                                    onChange={e => {
                                                        if (
                                                            !isNaN(Number(e.target.value)) &&
                                                            e.target.value.length <= 10
                                                        ) {
                                                            setMobileNumber(e.target.value);
                                                        }

                                                        if (e.target.value) {
                                                            setIsErrorMobileNo(false);
                                                        }
                                                    }}
                                                    // disabled
                                                />
                                                {isErrorMobileNo ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        Enter valid Mobile number
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="flex flex-col my-1 w-full md:w-2/5">
                                            <div className="flex flex-row">
                                                <label
                                                    className="flex flex-row my-1 font-manrope text-sm"
                                                    htmlFor="tab_1"
                                                >
                                                    Email<span className="text-red-600">*</span>
                                                </label>
                                                <HiIcons.HiQuestionMarkCircle
                                                    onClick={() => {
                                                        setEmailHelpText(true);
                                                    }}
                                                    className="ml-2 mt-1 cursor-pointer"
                                                />
                                                {emailHelpText ? (
                                                    <div
                                                        onMouseOverCapture={() => {
                                                            setEmailHelpText(true);
                                                            // setProfileDropdownVisible(false);
                                                        }}
                                                        onMouseLeave={() => setEmailHelpText(false)}
                                                        className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-7"
                                                        style={{
                                                            width: '375px',
                                                        }}
                                                    >
                                                        If you don’t have an email already,{' '}
                                                        <span className="underline cursor-pointer">
                                                            <a
                                                                target="_blank"
                                                                href={emailCreationURL}
                                                                rel="noreferrer"
                                                            >
                                                                click here
                                                            </a>
                                                        </span>{' '}
                                                        to learn more.
                                                    </div>
                                                ) : null}
                                            </div>
                                            <input
                                                className={`pl-4 py-1 h-12 ${
                                                    isErrorEmail
                                                        ? 'inputFieldErrorThickness'
                                                        : 'inputFieldThickness'
                                                } ${
                                                    studentData?.emailId !== null &&
                                                    studentData?.emailId !== undefined &&
                                                    'pointer-events-none opacity-50'
                                                }`}
                                                type="email"
                                                id="tab_1"
                                                value={values.email || ''}
                                                placeholder="Enter your email address"
                                                onChange={e => {
                                                    setFieldValue('email', e.target.value);
                                                    setEmail(e.target.value);
                                                    if (e.target.value) {
                                                        setIsErrorEmail(false);
                                                    }
                                                }}
                                                // disabled
                                            />
                                            {errors.email ? (
                                                <div className="text-red-500 text-xs my-1">
                                                    {errors.email}
                                                </div>
                                            ) : null}
                                            {/* {isErrorEmail ? (
                                            <div className="text-red-500 text-xs my-1">
                                                Email is mandatory
                                            </div>
                                        ) : null} */}
                                        </div>
                                        <p className="font-bold font-manrope text-xl mt-4 mb-2">
                                            Address Information
                                        </p>
                                        <div className="flex flex-col my-1 ">
                                            <div className="flex flex-row my-1">
                                                Home town (District)
                                                <span className="text-red-600">*</span>
                                            </div>
                                            <div
                                                className={`flex relative cursor-pointer items-center focus:outline-none w-full md:w-2/5 md:p-2 text-sm focus:border-blue-900 h-12  ${
                                                    isErrorHomeTown
                                                        ? 'inputFieldErrorThickness'
                                                        : 'inputFieldThickness'
                                                }`}
                                            >
                                                <div
                                                    className="flex justify-between w-full"
                                                    onClick={() => setHometownDropdownVisible(true)}
                                                >
                                                    <div className="text-gray-700 font-medium pl-4">
                                                        {trackFilterAddressAction}
                                                    </div>
                                                    <div className="">
                                                        <div className="flex flex-row">
                                                            {trackFilterAddressAction !==
                                                            'Select your home town' ? (
                                                                <HiIcons.HiOutlineX
                                                                    className="mr-2 mt-1"
                                                                    onClick={() =>
                                                                        setTrackFilterAddressAction(
                                                                            'Select your home town'
                                                                        )
                                                                    }
                                                                />
                                                            ) : null}
                                                            <div className="flex flex-col">
                                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                <HiIcons.HiChevronDown className="mr-2" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {hometownDropdownVisible ? (
                                                    <ul
                                                        id="hometwonId"
                                                        className="absolute w-full cursor-pointer overflow-y-scroll h-48 pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                        role="menu"
                                                        onMouseLeave={() =>
                                                            setHometownDropdownVisible(false)
                                                        }
                                                    >
                                                        {CityFilter?.map(action => (
                                                            <div
                                                                className={`p-2 hover:bg-gray-200 `}
                                                                key={action.id}
                                                                // value={inst?.id}
                                                                onClick={() => {
                                                                    setIsErrorHomeTown(false);
                                                                    setTrackFilterAddressAction(
                                                                        action.val
                                                                    );
                                                                    setHometownDropdownVisible(
                                                                        false
                                                                    );
                                                                }}
                                                            >
                                                                {action.val}
                                                            </div>
                                                        ))}
                                                    </ul>
                                                ) : null}
                                            </div>
                                            {isErrorHomeTown ? (
                                                <div className="text-red-500 text-xs my-1">
                                                    Home town is mandatory
                                                </div>
                                            ) : null}
                                        </div>
                                        <div
                                            style={{ marginTop: `${belowHometown}px` }}
                                            className="flex flex-col  w-full md:w-4/7"
                                        >
                                            <label
                                                className="flex flex-row font-manrope text-sm"
                                                htmlFor="tab_1"
                                            >
                                                Postal Address
                                                <span className="text-red-600">*</span>
                                            </label>
                                            <input
                                                className={`pl-4 py-1 h-12 ${
                                                    isErrorAddress
                                                        ? 'inputFieldErrorThickness'
                                                        : 'inputFieldThickness'
                                                }`}
                                                type="text"
                                                id="tab_1"
                                                value={address || ''}
                                                placeholder="Enter the postal address"
                                                onChange={e => {
                                                    setAddress(e.target.value);
                                                    if (e.target.value) {
                                                        setIsErrorAddress(false);
                                                    }
                                                }}
                                            />
                                            {isErrorAddress ? (
                                                <div className="text-red-500 text-xs my-1">
                                                    Postal address is mandatory
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="font-bold font-manrope text-xl mt-4 mb-2">
                                            Education details
                                        </div>

                                        <div className="flex flex-col my-1 w-full md:w-3/5">
                                            <div className="flex flex-row my-1">
                                                Enter your Bridge Invitation Code given by your
                                                Institute (Optional)
                                            </div>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                type="text"
                                                id="tab_1"
                                                value={trackingCode || ''}
                                                placeholder="Enter your batch number"
                                                onChange={e => {
                                                    setTrackingCode(e.target.value);
                                                }}
                                            />
                                        </div>

                                        <div className="flex flex-col my-1">
                                            <div className="flex flex-row my-1">
                                                Select your Education status
                                                <span className="text-red-600">*</span>
                                            </div>
                                            <div
                                                className={`flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 text-sm focus:border-blue-900 inputFieldThickness h-12`}
                                            >
                                                <div
                                                    className="flex justify-between w-full"
                                                    onClick={() =>
                                                        setEducationStatusDropdownVisible(true)
                                                    }
                                                >
                                                    <div className="text-gray-700 font-medium pl-4">
                                                        {educationStatusFilterAction}
                                                    </div>
                                                    <div className="">
                                                        <div className="flex flex-row">
                                                            <div className="flex flex-col">
                                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                <HiIcons.HiChevronDown className="mr-2" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {educationStatusDropdownVisible ? (
                                                    <ul
                                                        className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                        role="menu"
                                                        onMouseLeave={() =>
                                                            setEducationStatusDropdownVisible(false)
                                                        }
                                                    >
                                                        {EducationStatusFilter?.map(action => (
                                                            <div
                                                                className="p-2 pl-4 hover:bg-gray-200 "
                                                                key={action.id}
                                                                onClick={() => {
                                                                    setIsErrorInstitue(false);
                                                                    setEducationStatusFilterAction(
                                                                        action.val
                                                                    );
                                                                    setEducationStatusDropdownVisible(
                                                                        false
                                                                    );
                                                                    setEducationStatus(action.val);
                                                                }}
                                                            >
                                                                {action.val}
                                                            </div>
                                                        ))}
                                                    </ul>
                                                ) : null}
                                            </div>
                                        </div>
                                        {educationStatus !== 'I have not done O/Ls yet' ? (
                                            <>
                                                {educationStatus === 'I have not done A/Ls yet' ||
                                                educationStatus === 'I have completed A/Ls' ? (
                                                    <>
                                                        <div>
                                                            <div className="font-bold font-manrope text-lg mt-8 mb-5">
                                                                Stream of study in Advanced Level
                                                            </div>

                                                            <div className="flex flex-col mt-3 mb-1 w-full md:w-3/4">
                                                                <label
                                                                    className="flex flex-row my-1"
                                                                    htmlFor="tab_1"
                                                                >
                                                                    Advanced Level Stream
                                                                    <span className="text-red-600">
                                                                        {' '}
                                                                        *
                                                                    </span>
                                                                </label>

                                                                <div
                                                                    className={`flex relative cursor-pointer items-center focus:outline-none h-12 md:p-2 text-sm focus:border-blue-900 ${
                                                                        isErrorStream
                                                                            ? 'inputFieldErrorThickness'
                                                                            : 'inputFieldThickness'
                                                                    } `}
                                                                >
                                                                    <div
                                                                        className="flex justify-between w-full"
                                                                        onClick={() =>
                                                                            setStreamDropdownVisible(
                                                                                true
                                                                            )
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`text-sm pl-2 ${
                                                                                AlStreamFilterAction !==
                                                                                'Select your gender'
                                                                                    ? 'text-gray-700'
                                                                                    : 'text-gray-400'
                                                                            }`}
                                                                        >
                                                                            {AlStreamFilterAction}
                                                                        </div>
                                                                        <div className="">
                                                                            <div className="flex flex-row">
                                                                                <div className="flex flex-col">
                                                                                    <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                    <HiIcons.HiChevronDown className="mr-2" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {streamDropdownVisible ? (
                                                                        <ul
                                                                            className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                            role="menu"
                                                                            onMouseLeave={() =>
                                                                                setStreamDropdownVisible(
                                                                                    false
                                                                                )
                                                                            }
                                                                        >
                                                                            {AlStreamFilter.map(
                                                                                action => (
                                                                                    <div
                                                                                        className="p-2 pl-2 hover:bg-gray-200 "
                                                                                        key={
                                                                                            action.id
                                                                                        }
                                                                                        onClick={() => {
                                                                                            setIsErrorStream(
                                                                                                false
                                                                                            );
                                                                                            setAlStreamFilterAction(
                                                                                                action.val
                                                                                            );
                                                                                            setStream(
                                                                                                action.val
                                                                                            );
                                                                                            setStreamDropdownVisible(
                                                                                                false
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {action.val}
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : null}
                                                <div>
                                                    <div className="font-bold font-manrope text-lg mt-8">
                                                        Are you currently pursuing Higher Education?
                                                        <span className="text-red-600"> *</span>
                                                    </div>
                                                    <div className="mt-5">
                                                        <div className="mb-2">
                                                            <input
                                                                id="higherEd1"
                                                                className="newone"
                                                                type="radio"
                                                                value="Yes"
                                                                checked={
                                                                    isPursuingHigherEducation ===
                                                                    'Yes'
                                                                }
                                                                onChange={e => {
                                                                    setIsPursuingHigherEducation(
                                                                        e.target.value
                                                                    );
                                                                    setPursuingHigherStudies(true);
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-3"
                                                                htmlFor="higherEd1"
                                                            >
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                id="higherEd2"
                                                                className="newone"
                                                                type="radio"
                                                                value="No"
                                                                checked={
                                                                    isPursuingHigherEducation ===
                                                                    'No'
                                                                }
                                                                onChange={e => {
                                                                    setIsPursuingHigherEducation(
                                                                        e.target.value
                                                                    );
                                                                    setPursuingHigherStudies(false);
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-3"
                                                                htmlFor="higherEd2"
                                                            >
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {isPursuingHigherEducation === 'Yes' ? (
                                                    <>
                                                        <div className="flex flex-col mt-8">
                                                            <div className="flex flex-row mb-1">
                                                                Higher Education Institute
                                                                <span className="text-red-600">
                                                                    *
                                                                </span>
                                                            </div>
                                                            <div
                                                                className={`flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 text-sm focus:border-blue-900 h-12 ${
                                                                    isErrorInstitue
                                                                        ? 'inputFieldErrorThickness'
                                                                        : 'inputFieldThickness'
                                                                }`}
                                                            >
                                                                <div
                                                                    className="flex justify-between w-full"
                                                                    onClick={() =>
                                                                        setInstituteDropdownVisible(
                                                                            true
                                                                        )
                                                                    }
                                                                >
                                                                    <div className="text-gray-700 font-medium pl-4">
                                                                        {InstituteFilterAction}
                                                                    </div>
                                                                    <div className="">
                                                                        <div className="flex flex-row">
                                                                            {InstituteFilterAction !==
                                                                            'Select your institute' ? (
                                                                                <HiIcons.HiOutlineX
                                                                                    className="mr-2 mt-1"
                                                                                    onClick={() =>
                                                                                        setInstituteFilterAction(
                                                                                            'Select a institute'
                                                                                        )
                                                                                    }
                                                                                />
                                                                            ) : null}
                                                                            <div className="flex flex-col">
                                                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                <HiIcons.HiChevronDown className="mr-2" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {instituteDropdownVisible ? (
                                                                    <ul
                                                                        className="absolute w-full cursor-pointer pl-0 overflow-y-scroll h-48  top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                        role="menu"
                                                                        onMouseLeave={() =>
                                                                            setInstituteDropdownVisible(
                                                                                false
                                                                            )
                                                                        }
                                                                    >
                                                                        {instituteData?.map(
                                                                            action => (
                                                                                <div
                                                                                    className="p-2 pl-4 hover:bg-gray-200 "
                                                                                    key={action.id}
                                                                                    onClick={() => {
                                                                                        setIsErrorInstitue(
                                                                                            false
                                                                                        );
                                                                                        setInstituteFilterAction(
                                                                                            action.instName
                                                                                        );
                                                                                        setInstituteDropdownVisible(
                                                                                            false
                                                                                        );
                                                                                        setInstitute(
                                                                                            action.instName
                                                                                        );
                                                                                        setInstituteID(
                                                                                            action.id
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        action.instName
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                ) : null}
                                                            </div>
                                                            {isErrorInstitue ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    Enter your Institute
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        {institue !== null
                                                            ? selectedInstitue?.map(data => (
                                                                  <div key={data?.id}>
                                                                      {isCat1 ? (
                                                                          <div className="flex flex-col mt-1 ">
                                                                              <div className="flex flex-row mb-1">
                                                                                  {
                                                                                      data
                                                                                          ?.instCategory1
                                                                                          ?.name
                                                                                  }
                                                                              </div>
                                                                              <div
                                                                                  className={`flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 text-sm focus:border-blue-900 inputFieldThickness `}
                                                                              >
                                                                                  <div
                                                                                      className="flex justify-between w-full"
                                                                                      onClick={() =>
                                                                                          setInstitueCat1DropDownVisible(
                                                                                              true
                                                                                          )
                                                                                      }
                                                                                  >
                                                                                      <div className="text-gray-700 font-medium pl-2">
                                                                                          {
                                                                                              cat1FilterAction
                                                                                          }
                                                                                      </div>
                                                                                      <div className="">
                                                                                          <div className="flex flex-row">
                                                                                              {cat1FilterAction !==
                                                                                              'Select' ? (
                                                                                                  <HiIcons.HiOutlineX
                                                                                                      className="mr-2 mt-1"
                                                                                                      onClick={() =>
                                                                                                          setcat1FilterAction(
                                                                                                              'Select'
                                                                                                          )
                                                                                                      }
                                                                                                  />
                                                                                              ) : null}
                                                                                              <div className="flex flex-col">
                                                                                                  <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                                  <HiIcons.HiChevronDown className="mr-2" />
                                                                                              </div>
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                                  {institueCat1DropDownVisible ? (
                                                                                      <ul
                                                                                          className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                          role="menu"
                                                                                          onMouseLeave={() =>
                                                                                              setInstitueCat1DropDownVisible(
                                                                                                  false
                                                                                              )
                                                                                          }
                                                                                      >
                                                                                          {data?.instCategory1?.options.map(
                                                                                              action => (
                                                                                                  <div
                                                                                                      className="p-2 pl-2 hover:bg-gray-200 "
                                                                                                      key={
                                                                                                          action
                                                                                                      }
                                                                                                      onClick={() => {
                                                                                                          setcat1FilterAction(
                                                                                                              action
                                                                                                          );
                                                                                                          setInstitueCat1DropDownVisible(
                                                                                                              false
                                                                                                          );
                                                                                                      }}
                                                                                                  >
                                                                                                      {
                                                                                                          action
                                                                                                      }
                                                                                                  </div>
                                                                                              )
                                                                                          )}
                                                                                      </ul>
                                                                                  ) : null}
                                                                              </div>
                                                                          </div>
                                                                      ) : null}

                                                                      {isCat2 ? (
                                                                          <div className="flex flex-col mt-1">
                                                                              <div className="flex flex-row mb-1">
                                                                                  {
                                                                                      data
                                                                                          ?.instCategory2
                                                                                          ?.name
                                                                                  }
                                                                              </div>
                                                                              <div
                                                                                  className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 border-2 text-sm focus:border-blue-900 inputFieldThickness`}
                                                                              >
                                                                                  <div
                                                                                      className="flex justify-between w-full"
                                                                                      onClick={() =>
                                                                                          setInstitueCat2DropDownVisible(
                                                                                              true
                                                                                          )
                                                                                      }
                                                                                  >
                                                                                      <div className="text-gray-700 font-medium pl-2">
                                                                                          {
                                                                                              cat2FilterAction
                                                                                          }
                                                                                      </div>
                                                                                      <div className="">
                                                                                          <div className="flex flex-row">
                                                                                              {cat2FilterAction !==
                                                                                              'Select' ? (
                                                                                                  <HiIcons.HiOutlineX
                                                                                                      className="mr-2 mt-1"
                                                                                                      onClick={() =>
                                                                                                          setcat2FilterAction(
                                                                                                              'Select'
                                                                                                          )
                                                                                                      }
                                                                                                  />
                                                                                              ) : null}
                                                                                              <div className="flex flex-col">
                                                                                                  <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                                  <HiIcons.HiChevronDown className="mr-2" />
                                                                                              </div>
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                                  {institueCat2DropDownVisible ? (
                                                                                      <ul
                                                                                          className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                          role="menu"
                                                                                          onMouseLeave={() =>
                                                                                              setInstitueCat2DropDownVisible(
                                                                                                  false
                                                                                              )
                                                                                          }
                                                                                      >
                                                                                          {data?.instCategory2?.options.map(
                                                                                              action => (
                                                                                                  <div
                                                                                                      className="p-2 pl-2 hover:bg-gray-200 "
                                                                                                      key={
                                                                                                          action
                                                                                                      }
                                                                                                      onClick={() => {
                                                                                                          setcat2FilterAction(
                                                                                                              action
                                                                                                          );
                                                                                                          setInstitueCat2DropDownVisible(
                                                                                                              false
                                                                                                          );
                                                                                                      }}
                                                                                                  >
                                                                                                      {
                                                                                                          action
                                                                                                      }
                                                                                                  </div>
                                                                                              )
                                                                                          )}
                                                                                      </ul>
                                                                                  ) : null}
                                                                              </div>
                                                                          </div>
                                                                      ) : null}

                                                                      {isCat3 ? (
                                                                          <div className="flex flex-col mt-1">
                                                                              <div className="flex flex-row mb-1">
                                                                                  {
                                                                                      data
                                                                                          ?.instCategory3
                                                                                          ?.name
                                                                                  }
                                                                              </div>
                                                                              <div
                                                                                  className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 border-2 text-sm focus:border-blue-900 inputFieldThickness`}
                                                                              >
                                                                                  <div
                                                                                      className="flex justify-between w-full"
                                                                                      onClick={() =>
                                                                                          setInstitueCat3DropDownVisible(
                                                                                              true
                                                                                          )
                                                                                      }
                                                                                  >
                                                                                      <div className="text-gray-700 font-medium pl-2">
                                                                                          {
                                                                                              cat3FilterAction
                                                                                          }
                                                                                      </div>
                                                                                      <div className="">
                                                                                          <div className="flex flex-row">
                                                                                              {cat3FilterAction !==
                                                                                              'Select' ? (
                                                                                                  <HiIcons.HiOutlineX
                                                                                                      className="mr-2 mt-1"
                                                                                                      onClick={() =>
                                                                                                          setcat3FilterAction(
                                                                                                              'Select'
                                                                                                          )
                                                                                                      }
                                                                                                  />
                                                                                              ) : null}
                                                                                              <div className="flex flex-col">
                                                                                                  <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                                  <HiIcons.HiChevronDown className="mr-2" />
                                                                                              </div>
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                                  {institueCat3DropDownVisible ? (
                                                                                      <ul
                                                                                          className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                          role="menu"
                                                                                          onMouseLeave={() =>
                                                                                              setInstitueCat3DropDownVisible(
                                                                                                  false
                                                                                              )
                                                                                          }
                                                                                      >
                                                                                          {data?.instCategory3?.options.map(
                                                                                              action => (
                                                                                                  <div
                                                                                                      className="p-2 pl-2 hover:bg-gray-200 "
                                                                                                      key={
                                                                                                          action
                                                                                                      }
                                                                                                      onClick={() => {
                                                                                                          setcat3FilterAction(
                                                                                                              action
                                                                                                          );
                                                                                                          setInstitueCat3DropDownVisible(
                                                                                                              false
                                                                                                          );
                                                                                                      }}
                                                                                                  >
                                                                                                      {
                                                                                                          action
                                                                                                      }
                                                                                                  </div>
                                                                                              )
                                                                                          )}
                                                                                      </ul>
                                                                                  ) : null}
                                                                              </div>
                                                                          </div>
                                                                      ) : null}
                                                                  </div>
                                                              ))
                                                            : null}

                                                        <div className="flex flex-col my-1 w-full md:w-2/5">
                                                            <label
                                                                className="flex flex-col my-1 font-manrope text-sm"
                                                                htmlFor="tab_1"
                                                            >
                                                                <div>
                                                                    Enter your student registration
                                                                    number/student ID given by your
                                                                    institute
                                                                    <span className="text-red-600">
                                                                        *
                                                                    </span>
                                                                </div>
                                                            </label>
                                                            <input
                                                                className={` rounded pl-4 py-1 h-12 ${
                                                                    isErrorBatchNumber
                                                                        ? 'inputFieldErrorThickness'
                                                                        : 'inputFieldThickness'
                                                                }`}
                                                                type="text"
                                                                id="tab_1"
                                                                value={batchNumber || ''}
                                                                placeholder="Enter your batch number"
                                                                onChange={e => {
                                                                    setBatchNumber(e.target.value);
                                                                    if (
                                                                        e.target.value.length !== 0
                                                                    ) {
                                                                        setIsErrorBatchNumber(
                                                                            false
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                            {isErrorBatchNumber && (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    Enter your student registration
                                                                    number/student ID
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                ) : null}
                                            </>
                                        ) : null}

                                        <div className="flex flex-col my-1 w-full md:w-2/5">
                                            <label
                                                className="flex flex-col my-1 font-manrope text-sm"
                                                htmlFor="tab_1"
                                            >
                                                Enter your Bridge Invitation Code given by your
                                                Institute
                                            </label>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                type="text"
                                                id="tab_1"
                                                value={trackingCode || ''}
                                                placeholder="Enter your batch number"
                                                onChange={e => {
                                                    setTrackingCode(e.target.value);
                                                }}
                                            />
                                        </div>

                                        <div>
                                            <div className="font-bold font-manrope text-lg mt-8">
                                                I joined Future Careers BRIDGE program because,
                                                <span className="text-red-600"> *</span>
                                            </div>
                                            <div className="mt-5">
                                                <div className="mb-2">
                                                    <input
                                                        id="futurecar1"
                                                        className="newone"
                                                        type="radio"
                                                        value="Only interested in studying at this time"
                                                        checked={
                                                            joinedFutureCareerBridgeReason ===
                                                            'Only interested in studying at this time'
                                                        }
                                                        onChange={e => {
                                                            setJoinedFutureCareerBridgeReason(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <label className="ml-3" htmlFor="futurecar1">
                                                        Only interested in studying at this
                                                        time(Your profile will not be visible to
                                                        companies for recruitment)
                                                    </label>
                                                </div>
                                                <div className="mb-2">
                                                    <input
                                                        id="futurecar2"
                                                        className="newone"
                                                        type="radio"
                                                        value="Looking for an industry internship"
                                                        checked={
                                                            joinedFutureCareerBridgeReason ===
                                                            'Looking for an industry internship'
                                                        }
                                                        onChange={e => {
                                                            setJoinedFutureCareerBridgeReason(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <label className="ml-3" htmlFor="futurecar2">
                                                        Looking for an industry internship
                                                    </label>
                                                </div>
                                                <div className="mb-2">
                                                    <input
                                                        id="futurecar3"
                                                        className="newone"
                                                        type="radio"
                                                        value="Already in employment but looking for new Tech career"
                                                        checked={
                                                            joinedFutureCareerBridgeReason ===
                                                            'Already in employment but looking for new Tech career'
                                                        }
                                                        onChange={e => {
                                                            setJoinedFutureCareerBridgeReason(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <label className="ml-3" htmlFor="futurecar3">
                                                        Already in employment but looking for new
                                                        Tech career
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="font-bold font-manrope text-lg mt-8">
                                                Are you presently employed (full time or part time)?
                                                <span className="text-red-600"> *</span>
                                            </div>
                                            <div className="mt-5">
                                                <div className="mb-2">
                                                    <input
                                                        id="employed1"
                                                        className="newone"
                                                        type="radio"
                                                        value="No"
                                                        checked={isEmployed === 'No'}
                                                        onChange={e => {
                                                            setIsEmployed(e.target.value);
                                                        }}
                                                    />
                                                    <label className="ml-3" htmlFor="employed1">
                                                        No
                                                    </label>
                                                </div>
                                                <div className="mb-2">
                                                    <input
                                                        id="employed2"
                                                        className="newone"
                                                        type="radio"
                                                        value="Yes, at a company"
                                                        checked={isEmployed === 'Yes, at a company'}
                                                        onChange={e => {
                                                            setIsEmployed(e.target.value);
                                                        }}
                                                    />
                                                    <label className="ml-3" htmlFor="employed2">
                                                        Yes, at a company
                                                    </label>
                                                </div>
                                                <div className="mb-2">
                                                    <input
                                                        id="employed3"
                                                        className="newone"
                                                        type="radio"
                                                        value="Yes, self-employed"
                                                        checked={
                                                            isEmployed === 'Yes, self-employed'
                                                        }
                                                        onChange={e => {
                                                            setIsEmployed(e.target.value);
                                                        }}
                                                    />
                                                    <label className="ml-3" htmlFor="employed3">
                                                        Yes, self-employed
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        {isEmployed === 'Yes, at a company' ? (
                                            <>
                                                <div className="font-bold font-manrope text-lg mt-8 mb-5">
                                                    Company Details
                                                </div>
                                                {/* <p className="my-3">
                                            If you are joining BRIDGE through an education
                                            institute, please mention the institute name. In doubt,
                                            keep this empty.
                                        </p> */}
                                                <div className="flex flex-col">
                                                    <div className="flex flex-row mb-1">
                                                        Company name
                                                        <span className="text-red-600">*</span>
                                                    </div>
                                                    <div className="flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 text-sm focus:border-blue-900  inputFieldThickness">
                                                        <div
                                                            className="flex justify-between w-full "
                                                            onClick={() =>
                                                                setCompanyDropdownVisible(true)
                                                            }
                                                        >
                                                            <div className="text-gray-700 font-medium pl-2">
                                                                {companyFilterAction}
                                                            </div>
                                                            <div className="">
                                                                <div className="flex flex-row">
                                                                    {companyFilterAction !==
                                                                    'Select your company' ? (
                                                                        <HiIcons.HiOutlineX
                                                                            className="mr-2 mt-1"
                                                                            onClick={() => {
                                                                                setCompanyFilterAction(
                                                                                    'Select a company'
                                                                                );
                                                                                setCompany(null);
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                    <div className="flex flex-col">
                                                                        <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                        <HiIcons.HiChevronDown className="mr-2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {companyDropdownVisible ? (
                                                            <ul
                                                                className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                role="menu"
                                                                onMouseLeave={() =>
                                                                    setCompanyDropdownVisible(false)
                                                                }
                                                            >
                                                                {companyData?.map(action => (
                                                                    <div
                                                                        className="p-2 pl-2 hover:bg-gray-200 "
                                                                        key={action.id}
                                                                        onClick={() => {
                                                                            setIsErrorCompany(
                                                                                false
                                                                            );
                                                                            setCompanyFilterAction(
                                                                                action.comName
                                                                            );
                                                                            setCompanyDropdownVisible(
                                                                                false
                                                                            );
                                                                            setCompany(
                                                                                action.comName
                                                                            );
                                                                        }}
                                                                    >
                                                                        {action.comName}
                                                                    </div>
                                                                ))}
                                                                <div
                                                                    className="p-2 pl-2 hover:bg-gray-200 "
                                                                    onClick={() => {
                                                                        setIsErrorCompany(false);
                                                                        setCompanyFilterAction(
                                                                            'Others'
                                                                        );
                                                                        setCompanyDropdownVisible(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    Others
                                                                </div>
                                                            </ul>
                                                        ) : null}
                                                    </div>
                                                    {isErrorCompany && (
                                                        <div className="text-red-500 text-xs my-1">
                                                            Select your company name
                                                        </div>
                                                    )}
                                                </div>
                                                {companyFilterAction === 'Others' ? (
                                                    <div>
                                                        <div className="flex flex-col mt-5 mb-1 w-full md:w-3/4">
                                                            <label
                                                                className="flex flex-row my-1"
                                                                htmlFor="tab_1"
                                                            >
                                                                Enter your Company name
                                                                <span className="text-red-600">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                className={`text-sm pl-2 py-1 h-12 ${
                                                                    isErrorFirstName
                                                                        ? 'inputFieldErrorThickness'
                                                                        : 'inputFieldThickness'
                                                                }`}
                                                                type="text"
                                                                id="tab_1"
                                                                value={otherCompany || ''}
                                                                placeholder="Company name"
                                                                onChange={e => {
                                                                    setIsErrorOtherCompany(false);
                                                                    setCompany(e.target.value);
                                                                    setOtherCompany(e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        {isErrorOtherCompany && (
                                                            <div className="text-red-500 text-xs my-1">
                                                                Enter your company name
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : null}
                                            </>
                                        ) : null}
                                    </>

                                    <div
                                        // style={{
                                        //     marginTop: `${dataHeight}px`,
                                        // }}
                                        className={`font-bold font-manrope text-xl mb-2 mt-4`}
                                    >
                                        Upload CV
                                    </div>
                                    <p className=" py-1 w-full md:w-2/3 text-justify">
                                        Your CV will be visible to the BRIDGE partner companies once
                                        you update your status as &quot;looking for internship or
                                        looking for for career change&quot; Keep your CV up to date
                                        with your latest achievements and experiences to earn better
                                        chances in getting selected.
                                    </p>
                                    <div className="border-dashed border-2 border-purple-950 bg-purple-100 w-full md:w-2/3">
                                        <div
                                            id="drop_zone"
                                            onDrop={dropHandler}
                                            onDragOver={dragOverHandler}
                                            className="flex justify-center items-center flex-col pb-4 pt-20"
                                        >
                                            <img
                                                className="h-9 w-10"
                                                src={'/assets/upload.png'}
                                                alt="cv"
                                            />
                                            <p className="text-sm text-gray-400 mb-2 pt-2">
                                                {uploadedFile || '.pdf .doc'}
                                            </p>
                                            <label
                                                htmlFor="files"
                                                className="text-purple-950 font-semibold cursor-pointer hover:underline font-manrope text-sm"
                                            >
                                                Drag and drop or Browse your files
                                            </label>
                                            <input
                                                id="files"
                                                style={{ visibility: 'hidden' }}
                                                accept=".pdf,.doc,.docx"
                                                type="file"
                                                onChange={e => {
                                                    const file = [];
                                                    file.push(e);
                                                    // setCvUrl(URL.createObjectURL(e.target.files[0]));
                                                    uploadCVToS3(e);
                                                    setUploadedFile(e.target.files[0].name);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="font-bold font-manrope text-xl mt-4 mb-2">
                                        Social Media
                                    </div>

                                    <div className="flex flex-col my-1 w-full md:w-5/6">
                                        <label
                                            className="flex flex-col my-1 font-manrope text-sm"
                                            htmlFor="tab_1"
                                        >
                                            LinkedIn
                                        </label>
                                        <input
                                            className={`pl-4 py-1 h-12 inputFieldThickness`}
                                            type="text"
                                            id="tab_1"
                                            value={linkedin || ''}
                                            placeholder="https://www.linkedin.com/"
                                            onChange={e => {
                                                setLinkedin(e.target.value);
                                                if (!linkedin?.match(/^https?:\/\//i)) {
                                                    setIsErrorLinkeIN(true);
                                                }
                                            }}
                                        />
                                    </div>
                                    {isErrorLinkeIN ? (
                                        <div className="text-red-500 text-xs my-1">
                                            Enter a valid URL
                                        </div>
                                    ) : null}

                                    <div className="flex flex-col my-1 w-full md:w-5/6">
                                        <label
                                            className="flex flex-col my-1 font-manrope text-sm"
                                            htmlFor="tab_1"
                                        >
                                            Facebook
                                        </label>
                                        <input
                                            className={`pl-4 py-1 h-12 inputFieldThickness`}
                                            type="text"
                                            id="tab_1"
                                            value={facebook || ''}
                                            placeholder="https://www.facebook.com/"
                                            onChange={e => {
                                                setFacebook(e.target.value);
                                                if (!facebook?.match(/^https?:\/\//i)) {
                                                    setIsErrorFacebook(true);
                                                }
                                            }}
                                        />
                                    </div>
                                    {isErrorFacebook ? (
                                        <div className="text-red-500 text-xs my-1">
                                            Enter a valid URL
                                        </div>
                                    ) : null}

                                    <div className="flex flex-col my-1 w-full md:w-5/6">
                                        <label
                                            className="flex flex-col my-1 font-manrope text-sm"
                                            htmlFor="tab_1"
                                        >
                                            Twitter
                                        </label>
                                        <input
                                            className={`pl-4 py-1 h-12 inputFieldThickness`}
                                            type="text"
                                            id="tab_1"
                                            value={twitter || ''}
                                            placeholder="https://www.twitter.com/"
                                            onChange={e => {
                                                setTwitter(e.target.value);
                                                if (!twitter?.match(/^https?:\/\//i)) {
                                                    setIsErrorTwitter(true);
                                                }
                                            }}
                                        />
                                    </div>
                                    {isErrorTwitter ? (
                                        <div className="text-red-500 text-xs my-1">
                                            Enter a valid URL
                                        </div>
                                    ) : null}

                                    <div className="flex flex-col">
                                        <div className="font-bold font-manrope text-xl mt-4 mb-2">
                                            Areas of Interest<span className="text-red-600">*</span>
                                        </div>
                                        <div className="flex flex-wrap  mr-20">
                                            {interestTag?.map(action => (
                                                <div
                                                    style={{
                                                        maxHeight: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                    key={action.id}
                                                    className={`rounded-full border-2 my-1 mr-4 cursor-pointer ${
                                                        selectedTags?.includes(action.tagName)
                                                            ? ' text-white bg-purple-950 border-purple-950'
                                                            : 'text-purple-950 border-purple-950 font-medium'
                                                    }  py-3 px-2  hover:bg-purple-950 hover:text-white`}
                                                    onClick={() => {
                                                        if (
                                                            !selectedTags?.includes(action.tagName)
                                                        ) {
                                                            setInterestedTags([
                                                                ...interestedTags,
                                                                action,
                                                            ]);
                                                        }
                                                    }}
                                                >
                                                    {action.tagName}
                                                    {selectedTags?.includes(action.tagName) ? (
                                                        <HiIcons.HiOutlineX
                                                            style={{ color: 'white' }}
                                                            className="ml-2"
                                                            onClick={() =>
                                                                setInterestedTags(
                                                                    interestedTags?.filter(
                                                                        item =>
                                                                            item.tagName !==
                                                                            action.tagName
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    ) : null}
                                                </div>
                                            ))}
                                        </div>
                                        {isErrorInterestedField ? (
                                            <div className="text-red-500 text-xs my-1">
                                                Select at least 3 from above to complete the
                                                registration process
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="flex flex-col">
                                        <div className="font-bold font-manrope text-xl mt-4 mb-2">
                                            Employment Status
                                        </div>
                                        <p className=" pt-1 w-full md:w-2/3 text-justify">
                                            Did you get employment at a company after you started
                                            BRIDGE program?
                                        </p>
                                        <div className="flex flex-col">
                                            <div className="mb-2">
                                                <input
                                                    id="1"
                                                    className="newone"
                                                    type="radio"
                                                    // value="one"
                                                    checked={isHiredThroughFCB === true}
                                                    onChange={() => {
                                                        setIsHiredThroughFCB(true);
                                                    }}
                                                />
                                                <label
                                                    className="ml-3 font-manrope text-sm"
                                                    htmlFor="1"
                                                >
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="mb-2">
                                                <input
                                                    id="2"
                                                    className="newone"
                                                    type="radio"
                                                    // value="two"
                                                    checked={isHiredThroughFCB === false}
                                                    onChange={() => {
                                                        setIsHiredThroughFCB(false);
                                                    }}
                                                />
                                                <label
                                                    className="ml-3 font-manrope text-sm"
                                                    htmlFor="2"
                                                >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col mb-6">
                                        <div className="flex mt-4 mb-1">
                                            <div className="font-bold font-manrope text-xl">
                                                My Industry Highlights
                                            </div>
                                            <div
                                                onClick={() => {
                                                    setAddCareerStatus(true);
                                                }}
                                                className="underline text-purple-950 cursor-pointer ml-3 pt-0.5"
                                            >
                                                Add new
                                            </div>
                                        </div>
                                        <p className="mb-2 pt-1 w-full md:w-2/3 text-justify">
                                            The students can update details on their interviews,
                                            internships, trainings and BRIDGE events they joined
                                        </p>

                                        {careerStatus?.length > 0 || addCareerStatus ? (
                                            <table className="inputFieldThickness w-full  text-center">
                                                <thead className="inputFieldThickness">
                                                    <tr className="text-sm">
                                                        <th className="w-1/4 px-2">Description</th>
                                                        <th className="w-1/4 px-2">
                                                            Updated Date/Time
                                                        </th>
                                                        <th className="w-1/3 px-2">Updated By</th>
                                                        <th className="w-1/6 px-2"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {addCareerStatus ? (
                                                        <tr className="text-md">
                                                            <td className="w-1/4 px-2">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Type description"
                                                                    className={`resize-none focus:outline-none p-2 text-sm focus:border-blue-900border-gray-300`}
                                                                    id="trackDescription"
                                                                    // value={values.description}
                                                                    onChange={handleChange(
                                                                        'description'
                                                                    )}
                                                                />
                                                            </td>
                                                            <td className="w-1/4 px-2">
                                                                {moment(new Date())
                                                                    .format('DD/MM/YYYY hh:mm:mm A')
                                                                    .toString()}
                                                            </td>
                                                            <td className="w-1/3 px-2">
                                                                {studentData?.emailId}
                                                            </td>
                                                            <td
                                                                onClick={() => {
                                                                    setCareerStatus([
                                                                        ...careerStatus,
                                                                        {
                                                                            message:
                                                                                values.description,
                                                                            logCreatedBy:
                                                                                studentData.emailId,
                                                                        },
                                                                    ]);
                                                                    setAddCareerStatus(false);
                                                                }}
                                                                className="underline text-purple-950 cursor-pointer w-1/6 px-2"
                                                            >
                                                                Save
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                    {careerStatus.map(status => (
                                                        <tr key={status?.id}>
                                                            <td className="w-1/4 px-2">
                                                                {status.message}
                                                            </td>
                                                            <td className="w-1/4 px-2">
                                                                {moment(status.logUpdatedDate)
                                                                    .local()
                                                                    .format(
                                                                        'YYYY-MM-DD , h:mm:ss a'
                                                                    )}
                                                            </td>
                                                            <td className="w-1/3 px-2">
                                                                {status.logCreatedBy}
                                                            </td>
                                                            {status.logCreatedBy ===
                                                                studentData.emailId && (
                                                                <td
                                                                    onClick={() => {
                                                                        setCareerStatus(
                                                                            careerStatus.filter(
                                                                                item =>
                                                                                    item.id !==
                                                                                    status.id
                                                                            )
                                                                        );
                                                                    }}
                                                                    className="underline text-red-500 cursor-pointer w-1/6 px-2"
                                                                >
                                                                    Remove
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <b className="text-gray-500">No records available</b>
                                        )}
                                    </div>
                                    <div className="flex flex-row justify-end mb-6">
                                        <button
                                            onClick={() => history.push(`/dashBoard/settings`)}
                                            className="border-2 border-purple-950 text-purple-950 rounded font-semibold h-10 w-36 m-2"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            onClick={() => {
                                                handleSubmit();
                                            }}
                                            className="border-2 border-purple-950 text-purple-950 rounded font-semibold h-10 w-36 m-2"
                                        >
                                            {isLoading ? <Loader /> : 'Save changes'}
                                        </button>
                                    </div>
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="px-4 mt-4">
                        <div
                            className="flex mb-3 mt-1 cursor-pointer"
                            onClick={() => history.goBack()}
                        >
                            <BackArrow size="27" />
                            <p className="text-base ml-2 font-semibold">Back</p>
                        </div>
                        <div>
                            <Formik
                                enableReinitialize
                                validationSchema={validationSchemaDet}
                                initialValues={{
                                    email: studentData?.emailId,
                                    password: '',
                                    description: '',
                                }}
                                onSubmit={async values => {
                                    PersonalDetailsCheck(values);
                                }}
                            >
                                {({
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    values,
                                    errors,
                                }) => (
                                    <>
                                        <>
                                            <div className="font-bold font-manrope text-lg">
                                                Edit Profile
                                            </div>
                                            <div className="mb-8 mt-6 flex flex-row justify-between flex-wrap">
                                                <div className="flex flex-row">
                                                    {/* {uploadHover ? (
                                                        <ul
                                                            className="origin-top-left absolute  pl-0 mt-14 max-h-20 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                            onMouseOverCapture={() =>
                                                                setUploadHover(true)
                                                            }
                                                            onMouseLeave={() =>
                                                                setUploadHover(false)
                                                            }
                                                        >
                                                            <label
                                                                htmlFor="image"
                                                                className="block px-4 py-2 font-manrope text-sm text-gray-700 no-underline hover:bg-gray-200 cursor-pointer whitespace-nowrap"
                                                            >
                                                                Change profile picture
                                                            </label>
                                                            {imageUrl && (
                                                                <label
                                                                    onClick={() => {
                                                                        deleteImageFromS3();
                                                                        setImageUrl(null);
                                                                        setImageLocation(null);
                                                                    }}
                                                                    className="block px-4 py-2 font-manrope text-sm text-red-500 no-underline hover:bg-gray-200 cursor-pointer"
                                                                >
                                                                    Remove
                                                                </label>
                                                            )}
                                                        </ul>
                                                    ) : null} */}
                                                    <div className="flex flex-col w-1/3 gap-y-3">
                                                        <input
                                                            id="image"
                                                            ref={inputFile}
                                                            className="hidden"
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={e => {
                                                                const file = [];
                                                                file.push(e);
                                                                handleImage(e);
                                                                uploadImageToS3(e);
                                                                setShowProfilePicModal(false);
                                                            }}
                                                        />
                                                        <img
                                                            className={`blur-3xl rounded-full h-16 w-16 cursor-pointer`}
                                                            src={`${
                                                                imageUrl === '' || imageUrl === null
                                                                    ? '/assets/user.png'
                                                                    : imageUrl.includes('webUsers')
                                                                    ? s3UrlPrefix + imageUrl
                                                                    : imageUrl
                                                            }`}
                                                            alt="profile"
                                                        />
                                                        <SmallFontDiv
                                                            className="underline text-purple-950 font-bold cursor-pointer"
                                                            onClick={() =>
                                                                setShowProfilePicModal(true)
                                                            }
                                                        >
                                                            Change profile photo
                                                        </SmallFontDiv>
                                                    </div>

                                                    <div
                                                        className="pl-2 flex flex-col pt-1 w-2/3"
                                                        style={{
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <p className="text-lg font-bold font-manrope mb-1.5">
                                                            {fullName}
                                                        </p>
                                                        <p className="text-xs text-gray-500 mb-2.5">
                                                            {/* {studentData?.contact?.city +
                                                        ', ' +
                                                        studentData?.contact?.province} */}
                                                            {studentData?.contact?.city}
                                                        </p>
                                                        <div className="font-medium text-gray-600 text-xs flex py-1">
                                                            <input
                                                                className="mr-1 mt-1"
                                                                id="consent"
                                                                type="checkbox"
                                                                checked={enablePublicProfile}
                                                                onChange={() => {
                                                                    setEnablePublicProfile(
                                                                        !enablePublicProfile
                                                                    );
                                                                }}
                                                            />
                                                            <label
                                                                className="cursor-pointer"
                                                                htmlFor="consent"
                                                            >
                                                                I consent that my profile picture
                                                                can be shown in the leaderboards.
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex flex-col mb-1 w-full">
                                                <SmallFontLabel
                                                    className="flex flex-row font-manrope my-1"
                                                    htmlFor="tab_1"
                                                >
                                                    My Public Profile URL
                                                </SmallFontLabel>
                                                <div
                                                    className={` pl-4 py-1 h-12 pt-3 whitespace-nowrap overflow-x-hidden inputFieldThickness`}
                                                    id="tab_1"
                                                >{`${window.location.origin}/public-profile/${studentData?.id}`}</div>
                                            </div>
                                            <div className="flex flex-col mb-1 w-full">
                                                <div
                                                    onClick={() => {
                                                        studentData?.isPublic &&
                                                            window.open(
                                                                `${window.location.origin}/public-profile/${studentData?.id}`,
                                                                '_blank'
                                                            );
                                                    }}
                                                    className={`underline ${
                                                        studentData?.isPublic
                                                            ? 'text-purple-950 cursor-pointer'
                                                            : ' text-gray-600 cursor-not-allowed'
                                                    } cursor-pointer whitespace-nowrap pr-2`}
                                                >
                                                    Open link
                                                </div>
                                                <div>
                                                    <input
                                                        className="mr-1 mt-1"
                                                        id="public_profile_consent"
                                                        type="checkbox"
                                                        checked={isPublic}
                                                        onChange={() => {
                                                            setIsPublic(!isPublic);
                                                        }}
                                                    />
                                                    <label htmlFor="public_profile_consent">
                                                        Keep my profile publicly linked and
                                                        accessible through the BRIDGE website
                                                        leaderboard and via QR code on the
                                                        certificates.
                                                        {isPublic && (
                                                            <span className=" block font-bold text-gray-700">
                                                                Your personal information and BRIDGE
                                                                progress information will now be
                                                                shared publicly.
                                                            </span>
                                                        )}
                                                    </label>
                                                </div>
                                            </div>

                                            <p className="font-bold font-manrope text-lg mt-4 mb-2">
                                                Personal details
                                            </p>
                                            <div className="flex flex-col">
                                                <div className="flex flex-col my-1 w-full">
                                                    <SmallFontLabel
                                                        className="flex flex-row my-1 font-manrope"
                                                        htmlFor="tab_1"
                                                    >
                                                        First Name{' '}
                                                        <span className="text-red-600">*</span>
                                                    </SmallFontLabel>
                                                    <input
                                                        className={`pl-4 py-1 h-12 ${
                                                            isErrorFirstName
                                                                ? 'inputFieldErrorThickness'
                                                                : 'inputFieldThickness'
                                                        }`}
                                                        type="text"
                                                        id="tab_1"
                                                        value={firstName || ''}
                                                        placeholder="Enter your first name"
                                                        onChange={e => {
                                                            setFirstName(e.target.value);
                                                            if (e.target.value.length !== 0) {
                                                                setIsErrorFirstName(false);
                                                            }
                                                        }}
                                                    />
                                                    {isErrorFirstName ? (
                                                        <SmallFontDiv className="text-red-500 my-1">
                                                            First Name is mandatory
                                                        </SmallFontDiv>
                                                    ) : null}
                                                </div>
                                                <div className="flex flex-col my-1 w-full">
                                                    <SmallFontLabel
                                                        className="flex flex-row my-1 font-manrope text-sm"
                                                        htmlFor="tab_1"
                                                    >
                                                        Last Name
                                                        <span className="text-red-600">*</span>
                                                    </SmallFontLabel>
                                                    <input
                                                        className={`pl-4 py-1 h-12 ${
                                                            isErrorLastName
                                                                ? 'inputFieldErrorThickness'
                                                                : 'inputFieldThickness'
                                                        }`}
                                                        type="text"
                                                        id="tab_1"
                                                        value={lastName || ''}
                                                        placeholder="Enter your last name"
                                                        onChange={e => {
                                                            setLastName(e.target.value);
                                                            if (e.target.value) {
                                                                setIsErrorLastName(false);
                                                            }
                                                        }}
                                                    />
                                                    {isErrorLastName ? (
                                                        <SmallFontDiv className="text-red-500 my-1">
                                                            Last Name is mandatory
                                                        </SmallFontDiv>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="flex flex-col mt-2">
                                                <div className="flex flex-col my-1 w-full">
                                                    <SmallFontLabel
                                                        className="flex flex-row my-1 font-manrope text-sm"
                                                        htmlFor="tab_1"
                                                    >
                                                        Date of birth
                                                        <span className="text-red-600">*</span>
                                                    </SmallFontLabel>
                                                    <div className=" leading-normal">
                                                        <SmallFontLabel
                                                            htmlFor="set_date"
                                                            className="inputFieldThickness h-12 flex flex-row w-full justify-between"
                                                        >
                                                            <form autoComplete="off">
                                                                <DatePicker
                                                                    required
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    yearDropdownItemNumber={30}
                                                                    scrollableYearDropdown
                                                                    id="set_date"
                                                                    className="text-sm p-1 h-7 ml-2 mt-2"
                                                                    selected={dateOfBirth}
                                                                    autoComplete="new-password"
                                                                    onChange={date => {
                                                                        if (date != null) {
                                                                            setDateOfBirth(date);
                                                                        }
                                                                    }}
                                                                    maxDate={new Date()}
                                                                    dateFormat="do MMMM yyyy"
                                                                    placeholderText="select your date of birth"
                                                                />
                                                            </form>
                                                            <label
                                                                className="pr-2"
                                                                htmlFor="set_date"
                                                            >
                                                                <HiIcons.HiCalendar className="mr-2 mt-3" />
                                                            </label>
                                                        </SmallFontLabel>
                                                        {isErrorDOB ? (
                                                            <SmallFontDiv className="text-red-500 my-1">
                                                                Select your date of birth
                                                            </SmallFontDiv>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col my-1 w-full">
                                                    <SmallFontLabel
                                                        className="flex flex-row my-1 font-manrope"
                                                        htmlFor="tab_1"
                                                    >
                                                        Gender
                                                        <span className="text-red-600">*</span>
                                                    </SmallFontLabel>
                                                    <div
                                                        className={`flex relative cursor-pointer items-center focus:outline-none h-12 md:p-2 text-sm focus:border-blue-900 ${
                                                            isErrorGender
                                                                ? 'inputFieldErrorThickness'
                                                                : 'inputFieldThickness'
                                                        } `}
                                                    >
                                                        <div
                                                            className="flex justify-between w-full"
                                                            onClick={() =>
                                                                setGenderDropdownVisible(true)
                                                            }
                                                        >
                                                            <div
                                                                className={` font-medium pl-4 ${
                                                                    trackFilterGenderAction !==
                                                                    'Select your gender'
                                                                        ? 'text-gray-700 font-medium'
                                                                        : 'text-gray-400'
                                                                }`}
                                                            >
                                                                {trackFilterGenderAction}
                                                            </div>
                                                            <div className="">
                                                                <div className="flex flex-row">
                                                                    <div className="flex flex-col">
                                                                        <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                        <HiIcons.HiChevronDown className="mr-2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {genderDropdownVisible ? (
                                                            <ul
                                                                className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                role="menu"
                                                                onMouseLeave={() =>
                                                                    setGenderDropdownVisible(false)
                                                                }
                                                            >
                                                                {TrackGenderFilter?.map(action => (
                                                                    <div
                                                                        className="p-2 pl-4 hover:bg-gray-200 "
                                                                        key={action.id}
                                                                        onClick={() => {
                                                                            setIsErrorGender(false);
                                                                            setTrackFilterGenderAction(
                                                                                action.val
                                                                            );
                                                                            setGenderDropdownVisible(
                                                                                false
                                                                            );
                                                                        }}
                                                                    >
                                                                        {action.val}
                                                                    </div>
                                                                ))}
                                                            </ul>
                                                        ) : null}
                                                    </div>
                                                    {isErrorGender ? (
                                                        <SmallFontDiv className="text-red-500 my-1">
                                                            Select a gender
                                                        </SmallFontDiv>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="flex flex-col">
                                                <div className="flex flex-col my-1 w-full">
                                                    <SmallFontLabel
                                                        className="flex flex-col my-1 font-manrope"
                                                        htmlFor="tab_1"
                                                    >
                                                        NIC / Passport Number
                                                    </SmallFontLabel>
                                                    <input
                                                        className="inputFieldThickness pl-4 py-1 h-12"
                                                        type="text"
                                                        id="tab_1"
                                                        value={nicNumber || ''}
                                                        placeholder="Enter your NIC number"
                                                        onChange={e => {
                                                            setNICNumber(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex flex-col my-1 w-full">
                                                    <SmallFontLabel
                                                        className="flex flex-row my-1 font-manrope"
                                                        htmlFor="tab_1"
                                                    >
                                                        Mobile
                                                        <span className="text-red-600">*</span>
                                                    </SmallFontLabel>
                                                    <input
                                                        className={`pl-4 py-1 h-12 ${
                                                            isErrorMobileNo
                                                                ? 'inputFieldErrorThickness'
                                                                : 'inputFieldThickness'
                                                        } ${
                                                            studentData?.contact?.mobileNo !==
                                                                null &&
                                                            studentData?.contact?.mobileNo !==
                                                                undefined &&
                                                            'pointer-events-none opacity-50'
                                                        }`}
                                                        type="text"
                                                        id="tab_1"
                                                        value={mobileNumber || ''}
                                                        placeholder="Enter your Mobile Number (Eg: 94712345678)"
                                                        onChange={e => {
                                                            if (
                                                                !isNaN(Number(e.target.value)) &&
                                                                e.target.value.length <= 10
                                                            ) {
                                                                setMobileNumber(e.target.value);
                                                            }

                                                            if (e.target.value) {
                                                                setIsErrorMobileNo(false);
                                                            }
                                                        }}
                                                        // disabled
                                                    />
                                                    {isErrorMobileNo ? (
                                                        <SmallFontDiv className="text-red-500 my-1">
                                                            Enter valid Mobile number
                                                        </SmallFontDiv>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="flex flex-col my-1 w-full">
                                                <div className="flex flex-row">
                                                    <SmallFontLabel
                                                        className="flex flex-row my-1 font-manrope"
                                                        htmlFor="tab_1"
                                                    >
                                                        Email<span className="text-red-600">*</span>
                                                    </SmallFontLabel>
                                                    <HiIcons.HiQuestionMarkCircle
                                                        onClick={() => {
                                                            setEmailHelpText(true);
                                                        }}
                                                        className="ml-2 mt-1 cursor-pointer"
                                                    />
                                                    {emailHelpText ? (
                                                        <div
                                                            onMouseOverCapture={() => {
                                                                setEmailHelpText(true);
                                                                // setProfileDropdownVisible(false);
                                                            }}
                                                            onMouseLeave={() =>
                                                                setEmailHelpText(false)
                                                            }
                                                            className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-7"
                                                            style={{
                                                                width: '375px',
                                                            }}
                                                        >
                                                            If you don’t have an email already,{' '}
                                                            <span className="underline cursor-pointer">
                                                                <a
                                                                    target="_blank"
                                                                    href={emailCreationURL}
                                                                    rel="noreferrer"
                                                                >
                                                                    click here
                                                                </a>
                                                            </span>{' '}
                                                            to learn more.
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <input
                                                    className={`pl-4 py-1 h-12 ${
                                                        isErrorEmail
                                                            ? 'inputFieldErrorThickness'
                                                            : 'inputFieldThickness'
                                                    } ${
                                                        studentData?.emailId !== null &&
                                                        studentData?.emailId !== undefined &&
                                                        'pointer-events-none opacity-50'
                                                    }`}
                                                    type="email"
                                                    id="tab_1"
                                                    value={values.email || ''}
                                                    placeholder="Enter your email address"
                                                    onChange={e => {
                                                        setFieldValue('email', e.target.value);
                                                        setEmail(e.target.value);
                                                        if (e.target.value) {
                                                            setIsErrorEmail(false);
                                                        }
                                                    }}
                                                    // disabled
                                                />
                                                {errors.email ? (
                                                    <SmallFontDiv className="text-red-500 my-1">
                                                        {errors.email}
                                                    </SmallFontDiv>
                                                ) : null}
                                                {/* {isErrorEmail ? (
                                            <div className="text-red-500 text-xs my-1">
                                                Email is mandatory
                                            </div>
                                        ) : null} */}
                                            </div>

                                            <p className="font-bold font-manrope text-lg mt-4 mb-2">
                                                Address Information
                                            </p>
                                            <div className="flex flex-col my-1 ">
                                                <SmallFontDiv className="flex flex-row my-1 font-manrope">
                                                    Home town (District)
                                                    <span className="text-red-600">*</span>
                                                </SmallFontDiv>
                                                <div
                                                    className={`flex relative cursor-pointer items-center focus:outline-none w-full md:w-2/5 md:p-2 text-sm focus:border-blue-900 h-12  ${
                                                        isErrorHomeTown
                                                            ? 'inputFieldErrorThickness'
                                                            : 'inputFieldThickness'
                                                    }`}
                                                >
                                                    <div
                                                        className="flex justify-between w-full"
                                                        onClick={() =>
                                                            setHometownDropdownVisible(true)
                                                        }
                                                    >
                                                        <div className="text-gray-700 font-medium pl-4">
                                                            {trackFilterAddressAction}
                                                        </div>
                                                        <div className="">
                                                            <div className="flex flex-row">
                                                                {trackFilterAddressAction !==
                                                                'Select your home town' ? (
                                                                    <HiIcons.HiOutlineX
                                                                        className="mr-2 mt-1"
                                                                        onClick={() =>
                                                                            setTrackFilterAddressAction(
                                                                                'Select your home town'
                                                                            )
                                                                        }
                                                                    />
                                                                ) : null}
                                                                <div className="flex flex-col">
                                                                    <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                    <HiIcons.HiChevronDown className="mr-2" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {hometownDropdownVisible ? (
                                                        <ul
                                                            id="hometwonId"
                                                            className="absolute w-full cursor-pointer overflow-y-scroll h-48  pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                            role="menu"
                                                            onMouseLeave={() =>
                                                                setHometownDropdownVisible(false)
                                                            }
                                                        >
                                                            {CityFilter?.map(action => (
                                                                <div
                                                                    className="p-2 pl-4 hover:bg-gray-200 "
                                                                    key={action.id}
                                                                    onClick={() => {
                                                                        setIsErrorHomeTown(false);
                                                                        setTrackFilterAddressAction(
                                                                            action.val
                                                                        );
                                                                        setHometownDropdownVisible(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    {action.val}
                                                                </div>
                                                            ))}
                                                        </ul>
                                                    ) : null}
                                                </div>
                                                {isErrorHomeTown ? (
                                                    <SmallFontDiv className="text-red-500 my-1">
                                                        Home town is mandatory
                                                    </SmallFontDiv>
                                                ) : null}
                                            </div>
                                            <div
                                                style={{ marginTop: `${belowHometown}px` }}
                                                className="flex flex-col  w-full md:w-4/7"
                                            >
                                                <SmallFontLabel
                                                    className="flex flex-row font-manrope"
                                                    htmlFor="tab_1"
                                                >
                                                    Postal Address
                                                    <span className="text-red-600">*</span>
                                                </SmallFontLabel>
                                                <input
                                                    className={`pl-4 py-1 h-12 ${
                                                        isErrorAddress
                                                            ? 'inputFieldErrorThickness'
                                                            : 'inputFieldThickness'
                                                    }`}
                                                    type="text"
                                                    id="tab_1"
                                                    value={address || ''}
                                                    placeholder="Enter the postal address"
                                                    onChange={e => {
                                                        setAddress(e.target.value);
                                                        if (e.target.value) {
                                                            setIsErrorAddress(false);
                                                        }
                                                    }}
                                                />
                                                {isErrorAddress ? (
                                                    <SmallFontDiv className="text-red-500 my-1">
                                                        Postal address is mandatory
                                                    </SmallFontDiv>
                                                ) : null}
                                            </div>
                                            <div className="font-bold font-manrope text-lg mt-4 mb-2">
                                                Education details
                                            </div>

                                            <div className="flex flex-col my-1 w-full">
                                                <SmallFontDiv className="flex flex-row my-1 font-manrope">
                                                    Enter your Bridge Invitation Code given by your
                                                    Institute (Optional)
                                                </SmallFontDiv>
                                                <input
                                                    className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                    type="text"
                                                    id="tab_1"
                                                    value={trackingCode || ''}
                                                    placeholder="Enter your batch number"
                                                    onChange={e => {
                                                        setTrackingCode(e.target.value);
                                                    }}
                                                />
                                            </div>

                                            <div className="flex flex-col my-1">
                                                <SmallFontDiv className="flex flex-row my-1 font-manrope">
                                                    Select your Education status
                                                    <span className="text-red-600">*</span>
                                                </SmallFontDiv>
                                                <div
                                                    className={`flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 text-sm focus:border-blue-900 inputFieldThickness h-12`}
                                                >
                                                    <div
                                                        className="flex justify-between w-full"
                                                        onClick={() =>
                                                            setEducationStatusDropdownVisible(true)
                                                        }
                                                    >
                                                        <div className="text-gray-700 font-medium pl-4">
                                                            {educationStatusFilterAction}
                                                        </div>
                                                        <div className="">
                                                            <div className="flex flex-row">
                                                                <div className="flex flex-col">
                                                                    <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                    <HiIcons.HiChevronDown className="mr-2" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {educationStatusDropdownVisible ? (
                                                        <ul
                                                            className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                            role="menu"
                                                            onMouseLeave={() =>
                                                                setEducationStatusDropdownVisible(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            {EducationStatusFilter?.map(action => (
                                                                <div
                                                                    className="p-2 pl-4 hover:bg-gray-200 "
                                                                    key={action.id}
                                                                    onClick={() => {
                                                                        setIsErrorInstitue(false);
                                                                        setEducationStatusFilterAction(
                                                                            action.val
                                                                        );
                                                                        setEducationStatusDropdownVisible(
                                                                            false
                                                                        );
                                                                        setEducationStatus(
                                                                            action.val
                                                                        );
                                                                    }}
                                                                >
                                                                    {action.val}
                                                                </div>
                                                            ))}
                                                        </ul>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {educationStatus !== 'I have not done O/Ls yet' ? (
                                                <>
                                                    {educationStatus ===
                                                        'I have not done A/Ls yet' ||
                                                    educationStatus === 'I have completed A/Ls' ? (
                                                        <>
                                                            <div>
                                                                <div className="font-bold font-manrope text-lg mt-8 mb-2">
                                                                    Stream of study in Advanced
                                                                    Level
                                                                </div>

                                                                <div className="flex flex-col mt-3 mb-1 w-full">
                                                                    <SmallFontLabel
                                                                        className="flex flex-row my-1"
                                                                        htmlFor="tab_1"
                                                                    >
                                                                        Advanced Level Stream
                                                                        <span className="text-red-600">
                                                                            {' '}
                                                                            *
                                                                        </span>
                                                                    </SmallFontLabel>

                                                                    <div
                                                                        className={`flex relative cursor-pointer items-center focus:outline-none h-12 md:p-2 text-sm focus:border-blue-900 ${
                                                                            isErrorStream
                                                                                ? 'inputFieldErrorThickness'
                                                                                : 'inputFieldThickness'
                                                                        } `}
                                                                    >
                                                                        <div
                                                                            className="flex justify-between w-full"
                                                                            onClick={() =>
                                                                                setStreamDropdownVisible(
                                                                                    true
                                                                                )
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={`text-sm pl-2 ${
                                                                                    AlStreamFilterAction !==
                                                                                    'Select your gender'
                                                                                        ? 'text-gray-700'
                                                                                        : 'text-gray-400'
                                                                                }`}
                                                                            >
                                                                                {
                                                                                    AlStreamFilterAction
                                                                                }
                                                                            </div>
                                                                            <div className="">
                                                                                <div className="flex flex-row">
                                                                                    <div className="flex flex-col">
                                                                                        <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                        <HiIcons.HiChevronDown className="mr-2" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {streamDropdownVisible ? (
                                                                            <ul
                                                                                className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                role="menu"
                                                                                onMouseLeave={() =>
                                                                                    setStreamDropdownVisible(
                                                                                        false
                                                                                    )
                                                                                }
                                                                            >
                                                                                {AlStreamFilter.map(
                                                                                    action => (
                                                                                        <div
                                                                                            className="p-2 pl-2 hover:bg-gray-200 "
                                                                                            key={
                                                                                                action.id
                                                                                            }
                                                                                            onClick={() => {
                                                                                                setIsErrorStream(
                                                                                                    false
                                                                                                );
                                                                                                setAlStreamFilterAction(
                                                                                                    action.val
                                                                                                );
                                                                                                setStream(
                                                                                                    action.val
                                                                                                );
                                                                                                setStreamDropdownVisible(
                                                                                                    false
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                action.val
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                {isErrorStream && (
                                                                    <div className="text-red-500 text-xs my-1">
                                                                        Select your A/L Stream
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    ) : null}

                                                    <div>
                                                        <div className="font-bold font-manrope text-lg mt-8">
                                                            Are you currently pursuing Higher
                                                            Education?
                                                            <span className="text-red-600"> *</span>
                                                        </div>
                                                        <div className="mt-3">
                                                            <div className="mb-2">
                                                                <input
                                                                    id="higherEd1"
                                                                    className="newone"
                                                                    type="radio"
                                                                    value="Yes"
                                                                    checked={
                                                                        isPursuingHigherEducation ===
                                                                        'Yes'
                                                                    }
                                                                    onChange={e => {
                                                                        setIsPursuingHigherEducation(
                                                                            e.target.value
                                                                        );
                                                                        setPursuingHigherStudies(
                                                                            true
                                                                        );
                                                                    }}
                                                                />
                                                                <label
                                                                    className="ml-3 text-sm"
                                                                    htmlFor="higherEd1"
                                                                >
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="mb-2">
                                                                <input
                                                                    id="higherEd2"
                                                                    className="newone"
                                                                    type="radio"
                                                                    value="No"
                                                                    checked={
                                                                        isPursuingHigherEducation ===
                                                                        'No'
                                                                    }
                                                                    onChange={e => {
                                                                        setIsPursuingHigherEducation(
                                                                            e.target.value
                                                                        );
                                                                        setPursuingHigherStudies(
                                                                            false
                                                                        );
                                                                    }}
                                                                />
                                                                <label
                                                                    className="ml-3 text-sm"
                                                                    htmlFor="higherEd2"
                                                                >
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {isPursuingHigherEducation === 'Yes' ? (
                                                        <>
                                                            <div className="flex flex-col mt-4 mb-2">
                                                                <SmallFontDiv className="flex flex-row mb-1 font-manrope">
                                                                    Higher Education Institute
                                                                    <span className="text-red-600">
                                                                        *
                                                                    </span>
                                                                </SmallFontDiv>
                                                                <div
                                                                    className={`flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 text-sm focus:border-blue-900 h-12 ${
                                                                        isErrorInstitue
                                                                            ? 'inputFieldErrorThickness'
                                                                            : 'inputFieldThickness'
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className="flex justify-between w-full"
                                                                        onClick={() =>
                                                                            setInstituteDropdownVisible(
                                                                                true
                                                                            )
                                                                        }
                                                                    >
                                                                        <div className="text-gray-700 font-medium pl-4">
                                                                            {InstituteFilterAction}
                                                                        </div>
                                                                        <div className="">
                                                                            <div className="flex flex-row">
                                                                                {InstituteFilterAction !==
                                                                                'Select your institute' ? (
                                                                                    <HiIcons.HiOutlineX
                                                                                        className="mr-2 mt-1"
                                                                                        onClick={() =>
                                                                                            setInstituteFilterAction(
                                                                                                'Select your institute'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                ) : null}
                                                                                <div className="flex flex-col">
                                                                                    <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                    <HiIcons.HiChevronDown className="mr-2" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {instituteDropdownVisible ? (
                                                                        <ul
                                                                            className="absolute w-full overflow-y-scroll h-48 cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                            role="menu"
                                                                            onMouseLeave={() =>
                                                                                setInstituteDropdownVisible(
                                                                                    false
                                                                                )
                                                                            }
                                                                        >
                                                                            {instituteData?.map(
                                                                                action => (
                                                                                    <div
                                                                                        className="p-2 pl-4 hover:bg-gray-200 "
                                                                                        key={
                                                                                            action.id
                                                                                        }
                                                                                        onClick={() => {
                                                                                            setIsErrorInstitue(
                                                                                                false
                                                                                            );
                                                                                            setInstituteFilterAction(
                                                                                                action.instName
                                                                                            );
                                                                                            setInstituteDropdownVisible(
                                                                                                false
                                                                                            );
                                                                                            setInstitute(
                                                                                                action.instName
                                                                                            );
                                                                                            setInstituteID(
                                                                                                action.id
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            action.instName
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    ) : null}
                                                                </div>
                                                                {isErrorInstitue ? (
                                                                    <SmallFontDiv className="text-red-500 my-1">
                                                                        Enter your Institute
                                                                    </SmallFontDiv>
                                                                ) : null}
                                                            </div>

                                                            {institue !== null
                                                                ? selectedInstitue?.map(data => (
                                                                      <div key={data?.id}>
                                                                          {isCat1 ? (
                                                                              <div className="flex flex-col my-2">
                                                                                  <SmallFontDiv className="flex flex-row mb-1 font-manrope">
                                                                                      {
                                                                                          data
                                                                                              ?.instCategory1
                                                                                              ?.name
                                                                                      }
                                                                                  </SmallFontDiv>
                                                                                  <div
                                                                                      className={`flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 text-sm focus:border-blue-900 inputFieldThickness `}
                                                                                  >
                                                                                      <div
                                                                                          className="flex justify-between w-full"
                                                                                          onClick={() =>
                                                                                              setInstitueCat1DropDownVisible(
                                                                                                  true
                                                                                              )
                                                                                          }
                                                                                      >
                                                                                          <div className="text-gray-700 font-medium pl-4">
                                                                                              {
                                                                                                  cat1FilterAction
                                                                                              }
                                                                                          </div>
                                                                                          <div className="">
                                                                                              <div className="flex flex-row">
                                                                                                  {cat1FilterAction !==
                                                                                                  'Select' ? (
                                                                                                      <HiIcons.HiOutlineX
                                                                                                          className="mr-2 mt-1"
                                                                                                          onClick={() =>
                                                                                                              setcat1FilterAction(
                                                                                                                  'Select'
                                                                                                              )
                                                                                                          }
                                                                                                      />
                                                                                                  ) : null}
                                                                                                  <div className="flex flex-col">
                                                                                                      <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                                      <HiIcons.HiChevronDown className="mr-2" />
                                                                                                  </div>
                                                                                              </div>
                                                                                          </div>
                                                                                      </div>
                                                                                      {institueCat1DropDownVisible ? (
                                                                                          <ul
                                                                                              className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                              role="menu"
                                                                                              onMouseLeave={() =>
                                                                                                  setInstitueCat1DropDownVisible(
                                                                                                      false
                                                                                                  )
                                                                                              }
                                                                                          >
                                                                                              {data?.instCategory1?.options.map(
                                                                                                  action => (
                                                                                                      <div
                                                                                                          className="p-2 pl-2 hover:bg-gray-200 "
                                                                                                          key={
                                                                                                              action
                                                                                                          }
                                                                                                          onClick={() => {
                                                                                                              setcat1FilterAction(
                                                                                                                  action
                                                                                                              );
                                                                                                              setInstitueCat1DropDownVisible(
                                                                                                                  false
                                                                                                              );
                                                                                                          }}
                                                                                                      >
                                                                                                          {
                                                                                                              action
                                                                                                          }
                                                                                                      </div>
                                                                                                  )
                                                                                              )}
                                                                                          </ul>
                                                                                      ) : null}
                                                                                  </div>
                                                                              </div>
                                                                          ) : null}

                                                                          {isCat2 ? (
                                                                              <div className="flex flex-col my-2">
                                                                                  <SmallFontDiv className="flex flex-row mb-1 font-manrope">
                                                                                      {
                                                                                          data
                                                                                              ?.instCategory2
                                                                                              ?.name
                                                                                      }
                                                                                  </SmallFontDiv>
                                                                                  <div
                                                                                      className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 border-2 text-sm focus:border-blue-900 inputFieldThickness`}
                                                                                  >
                                                                                      <div
                                                                                          className="flex justify-between w-full"
                                                                                          onClick={() =>
                                                                                              setInstitueCat2DropDownVisible(
                                                                                                  true
                                                                                              )
                                                                                          }
                                                                                      >
                                                                                          <div className="text-gray-700 font-medium pl-4">
                                                                                              {
                                                                                                  cat2FilterAction
                                                                                              }
                                                                                          </div>
                                                                                          <div className="">
                                                                                              <div className="flex flex-row">
                                                                                                  {cat2FilterAction !==
                                                                                                  'Select' ? (
                                                                                                      <HiIcons.HiOutlineX
                                                                                                          className="mr-2 mt-1"
                                                                                                          onClick={() =>
                                                                                                              setcat2FilterAction(
                                                                                                                  'Select'
                                                                                                              )
                                                                                                          }
                                                                                                      />
                                                                                                  ) : null}
                                                                                                  <div className="flex flex-col">
                                                                                                      <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                                      <HiIcons.HiChevronDown className="mr-2" />
                                                                                                  </div>
                                                                                              </div>
                                                                                          </div>
                                                                                      </div>
                                                                                      {institueCat2DropDownVisible ? (
                                                                                          <ul
                                                                                              className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                              role="menu"
                                                                                              onMouseLeave={() =>
                                                                                                  setInstitueCat2DropDownVisible(
                                                                                                      false
                                                                                                  )
                                                                                              }
                                                                                          >
                                                                                              {data?.instCategory2?.options.map(
                                                                                                  action => (
                                                                                                      <div
                                                                                                          className="p-2 pl-2 hover:bg-gray-200 "
                                                                                                          key={
                                                                                                              action
                                                                                                          }
                                                                                                          onClick={() => {
                                                                                                              setcat2FilterAction(
                                                                                                                  action
                                                                                                              );
                                                                                                              setInstitueCat2DropDownVisible(
                                                                                                                  false
                                                                                                              );
                                                                                                          }}
                                                                                                      >
                                                                                                          {
                                                                                                              action
                                                                                                          }
                                                                                                      </div>
                                                                                                  )
                                                                                              )}
                                                                                          </ul>
                                                                                      ) : null}
                                                                                  </div>
                                                                              </div>
                                                                          ) : null}

                                                                          {isCat3 ? (
                                                                              <div className="flex flex-col my-2">
                                                                                  <SmallFontDiv className="flex flex-row mb-1 font-manrope">
                                                                                      {
                                                                                          data
                                                                                              ?.instCategory3
                                                                                              ?.name
                                                                                      }
                                                                                  </SmallFontDiv>
                                                                                  <div
                                                                                      className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 border-2 text-sm focus:border-blue-900 inputFieldThickness`}
                                                                                  >
                                                                                      <div
                                                                                          className="flex justify-between w-full"
                                                                                          onClick={() =>
                                                                                              setInstitueCat3DropDownVisible(
                                                                                                  true
                                                                                              )
                                                                                          }
                                                                                      >
                                                                                          <div className="text-gray-700 font-medium pl-4">
                                                                                              {
                                                                                                  cat3FilterAction
                                                                                              }
                                                                                          </div>
                                                                                          <div className="">
                                                                                              <div className="flex flex-row">
                                                                                                  {cat3FilterAction !==
                                                                                                  'Select' ? (
                                                                                                      <HiIcons.HiOutlineX
                                                                                                          className="mr-2 mt-1"
                                                                                                          onClick={() =>
                                                                                                              setcat3FilterAction(
                                                                                                                  'Select'
                                                                                                              )
                                                                                                          }
                                                                                                      />
                                                                                                  ) : null}
                                                                                                  <div className="flex flex-col">
                                                                                                      <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                                      <HiIcons.HiChevronDown className="mr-2" />
                                                                                                  </div>
                                                                                              </div>
                                                                                          </div>
                                                                                      </div>
                                                                                      {institueCat3DropDownVisible ? (
                                                                                          <ul
                                                                                              className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                              role="menu"
                                                                                              onMouseLeave={() =>
                                                                                                  setInstitueCat3DropDownVisible(
                                                                                                      false
                                                                                                  )
                                                                                              }
                                                                                          >
                                                                                              {data?.instCategory3?.options.map(
                                                                                                  action => (
                                                                                                      <div
                                                                                                          className="p-2 pl-2 hover:bg-gray-200 "
                                                                                                          key={
                                                                                                              action
                                                                                                          }
                                                                                                          onClick={() => {
                                                                                                              setcat3FilterAction(
                                                                                                                  action
                                                                                                              );
                                                                                                              setInstitueCat3DropDownVisible(
                                                                                                                  false
                                                                                                              );
                                                                                                          }}
                                                                                                      >
                                                                                                          {
                                                                                                              action
                                                                                                          }
                                                                                                      </div>
                                                                                                  )
                                                                                              )}
                                                                                          </ul>
                                                                                      ) : null}
                                                                                  </div>
                                                                              </div>
                                                                          ) : null}
                                                                      </div>
                                                                  ))
                                                                : null}

                                                            <div className="flex flex-col my-1 w-full">
                                                                <SmallFontLabel
                                                                    className="flex flex-col my-1 font-manrope"
                                                                    htmlFor="tab_1"
                                                                >
                                                                    Enter your student registration
                                                                    number/student ID given by your
                                                                    institute
                                                                    <span className="text-red-600">
                                                                        *
                                                                    </span>
                                                                </SmallFontLabel>
                                                                <input
                                                                    className={` rounded pl-4 py-1 h-12 ${
                                                                        isErrorBatchNumber
                                                                            ? 'inputFieldErrorThickness'
                                                                            : 'inputFieldThickness'
                                                                    }`}
                                                                    type="text"
                                                                    id="tab_1"
                                                                    value={batchNumber || ''}
                                                                    placeholder="Enter your batch number"
                                                                    onChange={e => {
                                                                        setBatchNumber(
                                                                            e.target.value
                                                                        );
                                                                        if (
                                                                            e.target.value
                                                                                .length !== 0
                                                                        ) {
                                                                            setIsErrorBatchNumber(
                                                                                false
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                                {isErrorBatchNumber && (
                                                                    <div className="text-red-500 text-xs my-1">
                                                                        Enter your student
                                                                        registration number/student
                                                                        ID
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    ) : null}
                                                </>
                                            ) : null}

                                            <div>
                                                <div className="font-bold font-manrope text-lg mt-8">
                                                    I joined Future Careers BRIDGE program because,
                                                    <span className="text-red-600"> *</span>
                                                </div>
                                                <div className="mt-5">
                                                    <div className="mb-2">
                                                        <input
                                                            id="futurecar1"
                                                            className="newone"
                                                            type="radio"
                                                            value="Only interested in studying at this time"
                                                            checked={
                                                                joinedFutureCareerBridgeReason ===
                                                                'Only interested in studying at this time'
                                                            }
                                                            onChange={e => {
                                                                setJoinedFutureCareerBridgeReason(
                                                                    e.target.value
                                                                );
                                                            }}
                                                        />
                                                        <label
                                                            className="ml-3 text-sm"
                                                            htmlFor="futurecar1"
                                                        >
                                                            Only interested in studying at this time
                                                        </label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            id="futurecar2"
                                                            className="newone"
                                                            type="radio"
                                                            value="Looking for an industry internship"
                                                            checked={
                                                                joinedFutureCareerBridgeReason ===
                                                                'Looking for an industry internship'
                                                            }
                                                            onChange={e => {
                                                                setJoinedFutureCareerBridgeReason(
                                                                    e.target.value
                                                                );
                                                            }}
                                                        />
                                                        <label
                                                            className="ml-3 text-sm"
                                                            htmlFor="futurecar2"
                                                        >
                                                            Looking for an industry internship
                                                        </label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            id="futurecar3"
                                                            className="newone"
                                                            type="radio"
                                                            value="Already in employment but looking for  new Tech career"
                                                            checked={
                                                                joinedFutureCareerBridgeReason ===
                                                                'Already in employment but looking for  new Tech career'
                                                            }
                                                            onChange={e => {
                                                                setJoinedFutureCareerBridgeReason(
                                                                    e.target.value
                                                                );
                                                            }}
                                                        />
                                                        <label
                                                            className="ml-3 text-sm"
                                                            htmlFor="futurecar3"
                                                        >
                                                            Already in employment but looking for
                                                            new Tech career
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="font-bold font-manrope text-lg mt-8">
                                                    Are you presently employed (full time or part
                                                    time)?
                                                    <span className="text-red-600"> *</span>
                                                </div>
                                                <div className="mt-5">
                                                    <div className="mb-2">
                                                        <input
                                                            id="employed1"
                                                            className="newone"
                                                            type="radio"
                                                            value="No"
                                                            checked={isEmployed === 'No'}
                                                            onChange={e => {
                                                                setIsEmployed(e.target.value);
                                                            }}
                                                        />
                                                        <label
                                                            className="ml-3 text-sm"
                                                            htmlFor="employed1"
                                                        >
                                                            No
                                                        </label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            id="employed2"
                                                            className="newone"
                                                            type="radio"
                                                            value="Yes, at a company"
                                                            checked={
                                                                isEmployed === 'Yes, at a company'
                                                            }
                                                            onChange={e => {
                                                                setIsEmployed(e.target.value);
                                                            }}
                                                        />
                                                        <label
                                                            className="ml-3 text-sm"
                                                            htmlFor="employed2"
                                                        >
                                                            Yes, at a company
                                                        </label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            id="employed3"
                                                            className="newone"
                                                            type="radio"
                                                            value="Yes, self-employed"
                                                            checked={
                                                                isEmployed === 'Yes, self-employed'
                                                            }
                                                            onChange={e => {
                                                                setIsEmployed(e.target.value);
                                                            }}
                                                        />
                                                        <label
                                                            className="ml-3 text-sm"
                                                            htmlFor="employed3"
                                                        >
                                                            Yes, self-employed
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {isEmployed === 'Yes, at a company' ? (
                                                <>
                                                    <div className="font-bold font-manrope text-lg mt-8 mb-5">
                                                        Company Details
                                                    </div>

                                                    {/* <p className="my-3">
                                            If you are joining BRIDGE through an education
                                            institute, please mention the institute name. In doubt,
                                            keep this empty.
                                        </p> */}
                                                    <div className="flex flex-col">
                                                        <SmallFontDiv className="flex flex-row mb-1">
                                                            Company name
                                                            <span className="text-red-600">*</span>
                                                        </SmallFontDiv>
                                                        <div className="flex relative cursor-pointer items-center focus:outline-none w-full md:p-2 h-12 text-sm focus:border-blue-900  inputFieldThickness">
                                                            <div
                                                                className="flex justify-between w-full "
                                                                onClick={() =>
                                                                    setCompanyDropdownVisible(true)
                                                                }
                                                            >
                                                                <div className="text-gray-700 font-medium pl-4">
                                                                    {companyFilterAction}
                                                                </div>
                                                                <div className="">
                                                                    <div className="flex flex-row">
                                                                        {companyFilterAction !==
                                                                        'Select your company' ? (
                                                                            <HiIcons.HiOutlineX
                                                                                className="mr-2 mt-1"
                                                                                onClick={() => {
                                                                                    setCompanyFilterAction(
                                                                                        'Select your company'
                                                                                    );
                                                                                    setCompany(
                                                                                        null
                                                                                    );
                                                                                }}
                                                                            />
                                                                        ) : null}
                                                                        <div className="flex flex-col">
                                                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                            <HiIcons.HiChevronDown className="mr-2" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {companyDropdownVisible ? (
                                                                <ul
                                                                    className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() =>
                                                                        setCompanyDropdownVisible(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    {companyData?.map(action => (
                                                                        <div
                                                                            className="p-2 pl-2 hover:bg-gray-200 "
                                                                            key={action.id}
                                                                            onClick={() => {
                                                                                setIsErrorCompany(
                                                                                    false
                                                                                );
                                                                                setCompanyFilterAction(
                                                                                    action.comName
                                                                                );
                                                                                setCompanyDropdownVisible(
                                                                                    false
                                                                                );
                                                                                setCompany(
                                                                                    action.comName
                                                                                );
                                                                            }}
                                                                        >
                                                                            {action.comName}
                                                                        </div>
                                                                    ))}
                                                                    <div
                                                                        className="p-2 pl-2 hover:bg-gray-200 "
                                                                        onClick={() => {
                                                                            setIsErrorCompany(
                                                                                false
                                                                            );
                                                                            setCompanyFilterAction(
                                                                                'Others'
                                                                            );
                                                                            setCompanyDropdownVisible(
                                                                                false
                                                                            );
                                                                        }}
                                                                    >
                                                                        Others
                                                                    </div>
                                                                </ul>
                                                            ) : null}
                                                        </div>
                                                        {isErrorCompany && (
                                                            <div className="text-red-500 text-xs my-1">
                                                                Select your company name
                                                            </div>
                                                        )}
                                                    </div>

                                                    {companyFilterAction === 'Others' ? (
                                                        <div>
                                                            <div className="flex flex-col mt-5 mb-1 w-full md:w-3/4">
                                                                <SmallFontLabel
                                                                    className="flex flex-row my-1"
                                                                    htmlFor="tab_1"
                                                                >
                                                                    Enter your Company name
                                                                    <span className="text-red-600">
                                                                        *
                                                                    </span>
                                                                </SmallFontLabel>
                                                                <input
                                                                    className={`pl-2 py-1 h-12 ${
                                                                        isErrorFirstName
                                                                            ? 'inputFieldErrorThickness'
                                                                            : 'inputFieldThickness'
                                                                    }`}
                                                                    type="text"
                                                                    id="tab_1"
                                                                    value={otherCompany || ''}
                                                                    placeholder="Company name"
                                                                    onChange={e => {
                                                                        setIsErrorOtherCompany(
                                                                            false
                                                                        );
                                                                        setCompany(e.target.value);
                                                                        setOtherCompany(
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                            {isErrorOtherCompany && (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    Enter your company name
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </>
                                            ) : null}
                                        </>

                                        <div
                                            // style={{
                                            //     marginTop: `${dataHeight}px`,
                                            // }}
                                            className={`font-bold font-manrope text-lg mb-2 mt-4`}
                                        >
                                            Upload CV
                                        </div>
                                        <p className=" py-1 w-full text-sm text-justify">
                                            Your CV will be visible to the BRIDGE partner companies
                                            once you update your status as &quot;looking for
                                            internship or looking for for career change&quot; Keep
                                            your CV up to date with your latest achievements and
                                            experiences to earn better chances in getting selected.
                                        </p>
                                        <div className="border-dashed border-2 border-purple-950 bg-purple-100 w-full">
                                            <div
                                                id="drop_zone"
                                                onDrop={dropHandler}
                                                onDragOver={dragOverHandler}
                                                className="flex justify-center items-center flex-col pb-4 pt-20"
                                            >
                                                <img
                                                    className="h-9 w-10"
                                                    src={'/assets/upload.png'}
                                                    alt="cv"
                                                />
                                                <p className="text-xs text-gray-400 mb-2 pt-2">
                                                    {uploadedFile || '.pdf .doc'}
                                                </p>
                                                <label
                                                    htmlFor="files"
                                                    className="text-purple-950 font-semibold cursor-pointer hover:underline font-manrope text-sm"
                                                >
                                                    Drag and drop or Browse your files
                                                </label>
                                                <input
                                                    id="files"
                                                    accept=".pdf,.doc,.docx"
                                                    style={{ visibility: 'hidden' }}
                                                    type="file"
                                                    onChange={e => {
                                                        const file = [];
                                                        file.push(e);
                                                        // setCvUrl(URL.createObjectURL(e.target.files[0]));
                                                        uploadCVToS3(e);
                                                        setUploadedFile(e.target.files[0].name);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="font-bold font-manrope text-lg mt-4 mb-2">
                                            Social Media
                                        </div>

                                        <div className="flex flex-col my-1 w-full">
                                            <SmallFontLabel
                                                className="flex flex-col my-1 font-manrope"
                                                htmlFor="tab_1"
                                            >
                                                LinkedIn
                                            </SmallFontLabel>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                type="text"
                                                id="tab_1"
                                                value={linkedin || ''}
                                                placeholder="https://www.linkedin.com/"
                                                onChange={e => {
                                                    setLinkedin(e.target.value);
                                                    if (!linkedin?.match(/^https?:\/\//i)) {
                                                        setIsErrorLinkeIN(true);
                                                    }
                                                }}
                                            />
                                        </div>
                                        {isErrorLinkeIN ? (
                                            <SmallFontDiv className="text-red-500 my-1">
                                                Enter a valid URL
                                            </SmallFontDiv>
                                        ) : null}

                                        <div className="flex flex-col my-1 w-full">
                                            <SmallFontLabel
                                                className="flex flex-col my-1 font-manrope"
                                                htmlFor="tab_1"
                                            >
                                                Facebook
                                            </SmallFontLabel>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                type="text"
                                                id="tab_1"
                                                value={facebook || ''}
                                                placeholder="https://www.facebook.com/"
                                                onChange={e => {
                                                    setFacebook(e.target.value);
                                                    if (!facebook?.match(/^https?:\/\//i)) {
                                                        setIsErrorFacebook(true);
                                                    }
                                                }}
                                            />
                                        </div>
                                        {isErrorFacebook ? (
                                            <SmallFontDiv className="text-red-500 my-1">
                                                Enter a valid URL
                                            </SmallFontDiv>
                                        ) : null}

                                        <div className="flex flex-col my-1 w-full md:w-5/6">
                                            <SmallFontLabel
                                                className="flex flex-col my-1 font-manrope"
                                                htmlFor="tab_1"
                                            >
                                                Twitter
                                            </SmallFontLabel>
                                            <input
                                                className={`pl-4 py-1 h-12 inputFieldThickness`}
                                                type="text"
                                                id="tab_1"
                                                value={twitter || ''}
                                                placeholder="https://www.twitter.com/"
                                                onChange={e => {
                                                    setTwitter(e.target.value);
                                                    if (!twitter?.match(/^https?:\/\//i)) {
                                                        setIsErrorTwitter(true);
                                                    }
                                                }}
                                            />
                                        </div>
                                        {isErrorTwitter ? (
                                            <SmallFontDiv className="text-red-500 my-1">
                                                Enter a valid URL
                                            </SmallFontDiv>
                                        ) : null}

                                        <div className="flex flex-col">
                                            <div className="font-bold font-manrope text-lg mt-4 mb-2">
                                                Areas of Interest
                                                <span className="text-red-600">*</span>
                                            </div>
                                            <div className="flex flex-wrap">
                                                {interestTag?.map(action => (
                                                    <div
                                                        style={{
                                                            maxHeight: '10px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                        key={action.id}
                                                        className={`rounded-full border-2 my-1 mr-4 cursor-pointer ${
                                                            selectedTags?.includes(action.tagName)
                                                                ? ' text-white bg-purple-950 border-purple-950'
                                                                : 'text-purple-950 border-purple-950 font-medium'
                                                        }  py-3 px-2  hover:bg-purple-950 hover:text-white`}
                                                        onClick={() => {
                                                            if (
                                                                !selectedTags?.includes(
                                                                    action.tagName
                                                                )
                                                            ) {
                                                                setInterestedTags([
                                                                    ...interestedTags,
                                                                    action,
                                                                ]);
                                                            }
                                                        }}
                                                    >
                                                        {action.tagName}
                                                        {selectedTags?.includes(action.tagName) ? (
                                                            <HiIcons.HiOutlineX
                                                                style={{ color: 'white' }}
                                                                className="ml-2"
                                                                onClick={() =>
                                                                    setInterestedTags(
                                                                        interestedTags?.filter(
                                                                            item =>
                                                                                item.tagName !==
                                                                                action.tagName
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        ) : null}
                                                    </div>
                                                ))}
                                            </div>
                                            {isErrorInterestedField ? (
                                                <div className="text-red-500 text-xs my-1">
                                                    Select at least 3 from above to complete the
                                                    registration process
                                                </div>
                                            ) : null}
                                        </div>

                                        <div className="flex flex-col">
                                            <div className="font-bold font-manrope text-lg mt-4 mb-2">
                                                Employment Status
                                            </div>
                                            <p className=" pt-1 w-full text-xs text-justify">
                                                Did you get employment at a company after you
                                                started BRIDGE program?
                                            </p>
                                            <div className="flex flex-col mt-3">
                                                <div className="mb-2">
                                                    <input
                                                        id="1"
                                                        className="newone"
                                                        type="radio"
                                                        // value="one"
                                                        checked={isHiredThroughFCB === true}
                                                        onChange={() => {
                                                            setIsHiredThroughFCB(true);
                                                        }}
                                                    />
                                                    <label
                                                        className="ml-3 font-manrope text-sm"
                                                        htmlFor="1"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="mb-2">
                                                    <input
                                                        id="2"
                                                        className="newone"
                                                        type="radio"
                                                        // value="two"
                                                        checked={isHiredThroughFCB === false}
                                                        onChange={() => {
                                                            setIsHiredThroughFCB(false);
                                                        }}
                                                    />
                                                    <label
                                                        className="ml-3 font-manrope text-sm"
                                                        htmlFor="2"
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col mb-6">
                                            <div className="flex mt-4 mb-1 items-center">
                                                <div className="font-bold font-manrope text-lg">
                                                    My Industry Highlights
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setAddCareerStatus(true);
                                                    }}
                                                    className="text-sm underline text-purple-950 cursor-pointer ml-3 pt-0.5"
                                                >
                                                    Add new
                                                </div>
                                            </div>
                                            <p className="mb-5 pt-1 w-full text-justify text-sm">
                                                The students can update details on their interviews,
                                                internships, trainings and BRIDGE events they joined
                                            </p>
                                            <div
                                                className="w-11/12 overflow-x-scroll horizontal-scrollable-leaderboard"
                                                style={{ fontSize: 10 }}
                                            >
                                                {careerStatus?.length > 0 || addCareerStatus ? (
                                                    <table className="inputFieldThickness w-full mb-2 table-fixed text-center">
                                                        <thead className="inputFieldThickness">
                                                            <tr className="h-10">
                                                                <th className="w-36 px-2">
                                                                    Description
                                                                </th>
                                                                <th className="w-36 px-2">
                                                                    Updated Date/Time
                                                                </th>
                                                                <th className="w-64 px-2">
                                                                    Updated By
                                                                </th>
                                                                <th className="w-24 px-2"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {addCareerStatus ? (
                                                                <tr className="h-10">
                                                                    <td className=" p-2">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Type description"
                                                                            className={`resize-none focus:outline-none p-2 text-xs focus:border-blue-900 border-gray-300 `}
                                                                            id="trackDescription"
                                                                            // value={values.description}
                                                                            onChange={handleChange(
                                                                                'description'
                                                                            )}
                                                                        />
                                                                    </td>
                                                                    <td className=" p-2">
                                                                        {moment(new Date())
                                                                            .format(
                                                                                'DD/MM/YYYY hh:mm:mm A'
                                                                            )
                                                                            .toString()}
                                                                    </td>
                                                                    <td className=" p-2">
                                                                        {studentData?.emailId}
                                                                    </td>
                                                                    <td
                                                                        onClick={() => {
                                                                            setCareerStatus([
                                                                                ...careerStatus,
                                                                                {
                                                                                    message:
                                                                                        values.description,
                                                                                    logCreatedBy:
                                                                                        studentData.emailId,
                                                                                },
                                                                            ]);
                                                                            setAddCareerStatus(
                                                                                false
                                                                            );
                                                                        }}
                                                                        className="underline text-purple-950 cursor-pointer p-2"
                                                                    >
                                                                        Save
                                                                    </td>
                                                                </tr>
                                                            ) : null}
                                                            {careerStatus.map(status => (
                                                                <tr key={status?.id}>
                                                                    <td className="w-36 px-2">
                                                                        {status.message}
                                                                    </td>
                                                                    <td className="w-36 px-2">
                                                                        {moment(
                                                                            status.logUpdatedDate
                                                                        )
                                                                            .local()
                                                                            .format(
                                                                                'YYYY-MM-DD , h:mm:ss a'
                                                                            )}
                                                                    </td>
                                                                    <td className="w-64 px-2">
                                                                        {status.logCreatedBy}
                                                                    </td>
                                                                    {status.logCreatedBy ===
                                                                        studentData.emailId && (
                                                                        <td
                                                                            onClick={() => {
                                                                                setCareerStatus(
                                                                                    careerStatus?.filter(
                                                                                        item =>
                                                                                            item.id !==
                                                                                            status.id
                                                                                    )
                                                                                );
                                                                            }}
                                                                            className="underline text-red-500 cursor-pointer p-2"
                                                                        >
                                                                            Remove
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <b className="text-gray-500">
                                                        No records available
                                                    </b>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-end mb-6">
                                            <button
                                                onClick={() => history.push(`/dashBoard/settings`)}
                                                className="border-2 border-purple-950 text-purple-950 rounded font-semibold h-10 w-36 m-2"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                onClick={() => {
                                                    handleSubmit();
                                                }}
                                                className="border-2 border-purple-950 text-purple-950 rounded font-semibold h-10 w-36 m-2"
                                            >
                                                {isLoading ? <Loader /> : 'Save changes'}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                        <Modal
                            isOpen={showProfilePicModal}
                            style={customStyles}
                            htmlOpenClassName="overflow-hidden"
                        >
                            <ModalContent className="flex flex-col items-center justify-center h-screen w-screen">
                                <div className="flex flex-col mt-auto mb-3 w-full gap-y-2 items-center justify-center">
                                    {imageUrl ? (
                                        <div className="bg-white text-center w-11/12 rounded-lg text-black-400">
                                            <div
                                                className="border-b border-black-400 py-3"
                                                onClick={() => {
                                                    deleteImageFromS3();
                                                    setImageUrl('');
                                                    setImageLocation('');
                                                    setShowProfilePicModal(false);
                                                }}
                                            >
                                                Remove Current photo
                                            </div>
                                            <div
                                                className="py-3"
                                                onClick={handleUploadPhotoBtnClickMobile}
                                            >
                                                Upload a Photo
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="text-black-400 rounded-lg py-3 w-11/12 bg-white text-center"
                                            onClick={handleUploadPhotoBtnClickMobile}
                                        >
                                            Upload a Photo
                                        </div>
                                    )}

                                    <div
                                        className=" text-red-600 rounded-lg py-3 w-11/12 bg-white text-center"
                                        onClick={() => setShowProfilePicModal(false)}
                                    >
                                        Cancel
                                    </div>
                                </div>
                            </ModalContent>
                        </Modal>
                    </div>
                </div>
            )}
        </>
    );
}
