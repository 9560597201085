import { TracksType } from '../../tracks/reducers';
import {
    GET_WEB_USERS,
    GET_WEB_USERS_SUCCESS,
    GET_WEB_USERS_FAILED,
    GET_WEB_USER_BY_ID,
    GET_WEB_USER_BY_ID_SUCCESS,
    GET_WEB_USER_BY_ID_FAILED,
    GET_STUDENT_ACTIVITY_LOG,
    GET_STUDENT_ACTIVITY_LOG_SUCCESS,
    GET_STUDENT_ACTIVITY_LOG_FAILED,
    DELETE_WEB_USER,
    DELETE_WEB_USER_SUCCESS,
    DELETE_WEB_USER_FAILED,
    EDIT_WEB_USER,
    EDIT_WEB_USER_SUCCESS,
    EDIT_WEB_USER_FAILED,
    CLEAR_WEB_USERS_MESSAGES,
    SEND_EMAIL_VERIFICATION,
    SEND_EMAIL_VERIFICATION_SUCCESS,
    SEND_EMAIL_VERIFICATION_FAILED,
    SEND_EMAIL_VERIFICATION_RESET,
    CONFIRM_EMAIL_VERIFICATION,
    CONFIRM_EMAIL_VERIFICATION_FAILED,
    CONFIRM_EMAIL_VERIFICATION_SUCCESS,
    RESET_PASSWORD_WEB,
    RESET_PASSWORD_WEB_SUCCESS,
    RESET_PASSWORD_WEB_FAILED,
    SEND_MOBILE_VERIFICATION,
    SEND_MOBILE_VERIFICATION_FAILED,
    SEND_MOBILE_VERIFICATION_SUCCESS,
    CONFIRM_MOBILE_VERIFICATION,
    CONFIRM_MOBILE_VERIFICATION_FAILED,
    CONFIRM_MOBILE_VERIFICATION_SUCCESS,
    RESET_SEND_MOBILE_VERIFICATION,
    RESET_CONFIRM_MOBILE_VERIFICATION,
    CHANGE_USER_PASSWORD,
    CHANGE_USER_PASSWORD_FAILED,
    CHANGE_USER_PASSWORD_SUCCESS,
    VERIFY_EMAIL,
    VERIFY_EMAIL_FAILED,
    VERIFY_EMAIL_SUCCESS,
} from './actions';
export interface CountType {
    DEACTIVATED: number;
    ACTIVE: number;
    DELETED: number;
    ALL: number;
}
export interface InstituteType {
    id: any;
    orgName: string;
    orgEmail: string;
    contactNo: number;
    location: string;
    faculty: string;
    studentId: string;
    instCategory1?: any;
    instCategory2?: any;
    instCategory3?: any;
}

export interface StudentInstituteType {
    id: any;
    instituteId: any;
    invitationCode: any;
    orgName: string;
    orgEmail: string;
    contactNo: number;
    location: string;
    faculty: string;
    studentId: string;
    instCategory1: any;
    instCategory2: any;
    instCategory3: any;
}
export interface ContactType {
    address1: string;
    address2: string;
    city: string;
    district: string;
    landlineNo: number;
    mobileNo: number;
    province: string;
}
export interface MentorType {
    id: string;
    mentorName: string;
    companyName: number;
    designation: string;
    mentorEmail: string;
    mentorStatus: string;
    imagePath?: string;
    linkedinProfile?: string;
}
export interface StudentType {
    id: string;
    imageUrl?: string;
    userId: string;
    userName: string;
    gender: string;
    birthDate: string;
    StudentInstitute: StudentInstituteType;
    instituteCategories?: any;
    institute: InstituteType;
    contact: ContactType;
    emailId: string;
    // password: string | null;
    InstituteName: string;
    educationStatus?: string;
    empStatus: string;
    reasonForJoin: string;
    pursuingHigherStudies: boolean;
    alStream: string;
    companyName: string;
    bridgeInvitationCode: string;
    mentor: MentorType;
    lastActive: string;
    firstName: string;
    lastName: string;
    tracks: TracksType;
    userPoints: number;
    userLastUpdatedBy: string;
    userLastUpdatedDate: string;
    joinedDate: string;
    nic: string;
    tags: any;
    status: string;
    secondaryEduLevel: string;
    cvLink: string;
    higherEduStatus: string;
    careerStatus: any;
    certificates: any[];
    badges: any[];
    hiredByFCB: any;
    Submissions: any[];
    onBoardingCompleted?: boolean;
    SuccessStoryPublished: boolean;
    socialMediaUrls?: any;
    bridgeProgresses: any[];
    completedChallengeCount: number;
    trackChallengeWiseSubmissions: any[];
    totalScore: number;
    SuccessStoryStatus: string;
    enablePublicProfile?: boolean;
    isPublic?: boolean;
    welcomeTour?: boolean;
    userSettings?: any;
    isMobileVerified: boolean;
    isEmailVerified: boolean;
    mentorRequestId: number;
    authType: string;
}

export interface webStudentInitialState {
    studentData: StudentType[];
    student: StudentType;
    studentActivityLog: any;
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    studentByIdLoading: boolean;
    studentActivityLogLoading: boolean;
    studentByIdError: string | null;
    studentsError: string | null;
    studentActivityLogError: string | null;
    isStudentByIdLoading: boolean;
    isDeleteLoading: boolean;
    deleteStudentError: string | null;
    studentDeleteSuccessMessage: string | null;
    isEditLoading: boolean;
    editStudentError: string | null;
    StudentUpdateSuccessMessage: string | null;
    studentUpdateSuccessPopup: boolean;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    studentBulkSuccessMessage: string | null;
    sendEmailVerificationSuccessMessage: string | null;
    sendEmailVerificationError: string | null;
    confirmEmailVerificationSuccessMessage: string | null;
    confirmEmailVerificationError: string | null;
    emailVerificationSuccessMessage: string | null;
    emailVerificationFailMessage: string | null;
    isResetPassLoading: boolean;
    WebResetPasswordSuccessMessage: string | null;
    sendMobileVerificationSuccess: string | null;
    sendMobileVerificationError: string | null;
    sendMobileVerificationLoading: boolean;
    confirmMobileVerificationSuccess: string | null;
    confirmMobileVerificationError: string | null;
    confirmMobileVerificationLoading: boolean;
    changeUserPasswordSuccess: string | null;
    changeUserPasswordError: string | null;
    changeUserPasswordLoading: boolean;
}

const initialState: webStudentInitialState = {
    studentData: [],
    student: null,
    studentActivityLog: null,
    pageCount: null,
    isLoading: false,
    count: null,
    studentByIdLoading: false,
    studentActivityLogLoading: false,
    studentByIdError: null,
    isStudentByIdLoading: false,
    studentsError: null,
    studentActivityLogError: null,
    isDeleteLoading: false,
    deleteStudentError: null,
    studentDeleteSuccessMessage: null,
    isEditLoading: false,
    editStudentError: null,
    StudentUpdateSuccessMessage: null,
    studentUpdateSuccessPopup: false,
    isBulkUpdateLoading: null,
    bulkError: null,
    studentBulkSuccessMessage: null,
    sendEmailVerificationSuccessMessage: null,
    sendEmailVerificationError: null,
    confirmEmailVerificationSuccessMessage: null,
    confirmEmailVerificationError: null,
    emailVerificationSuccessMessage: null,
    emailVerificationFailMessage: null,
    isResetPassLoading: null,
    WebResetPasswordSuccessMessage: null,
    sendMobileVerificationSuccess: null,
    sendMobileVerificationError: null,
    sendMobileVerificationLoading: false,
    confirmMobileVerificationSuccess: null,
    confirmMobileVerificationError: null,
    confirmMobileVerificationLoading: false,
    changeUserPasswordSuccess: null,
    changeUserPasswordError: null,
    changeUserPasswordLoading: false,
};

const WebUsersReducer = (state = initialState, action: any): webStudentInitialState => {
    switch (action.type) {
        case GET_WEB_USERS:
            return { ...state, isLoading: true, studentsError: null };
        case GET_WEB_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                studentData: action.payload.items,
                pageCount: action.payload.totalPages,
            };
        case GET_WEB_USERS_FAILED:
            return { ...state, isLoading: false, studentsError: action.payload };
        case GET_WEB_USER_BY_ID:
            return { ...state, studentByIdLoading: true, studentByIdError: null };
        case GET_WEB_USER_BY_ID_SUCCESS:
            return {
                ...state,
                studentByIdLoading: false,
                student: action.payload,
            };
        case GET_WEB_USER_BY_ID_FAILED:
            return { ...state, studentByIdLoading: false, student: action.payload };
        case GET_STUDENT_ACTIVITY_LOG:
            return { ...state, studentActivityLogLoading: true, studentActivityLogError: null };
        case GET_STUDENT_ACTIVITY_LOG_SUCCESS:
            return {
                ...state,
                studentActivityLogLoading: false,
                studentActivityLog: action.payload.items,
            };
        case GET_STUDENT_ACTIVITY_LOG_FAILED:
            return {
                ...state,
                studentActivityLogLoading: false,
                studentActivityLog: action.payload,
            };

        case DELETE_WEB_USER:
            return { ...state, isDeleteLoading: true, deleteStudentError: null };
        case DELETE_WEB_USER_SUCCESS:
            return {
                ...state,
                isDeleteLoading: false,
                studentDeleteSuccessMessage: action.payload,
            };
        case DELETE_WEB_USER_FAILED:
            return { ...state, isDeleteLoading: false, deleteStudentError: action.payload };
        case EDIT_WEB_USER:
            return { ...state, isEditLoading: true, editStudentError: null };
        case EDIT_WEB_USER_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                StudentUpdateSuccessMessage: action.payload,
                studentUpdateSuccessPopup: true,
            };
        case EDIT_WEB_USER_FAILED:
            return {
                ...state,
                isEditLoading: false,
                editStudentError: action.payload,
                studentUpdateSuccessPopup: false,
            };
        case CLEAR_WEB_USERS_MESSAGES:
            return {
                ...state,
                StudentUpdateSuccessMessage: null,
                studentUpdateSuccessPopup: false,
                editStudentError: null,
                deleteStudentError: null,
                studentDeleteSuccessMessage: null,
                studentBulkSuccessMessage: null,
                bulkError: null,
            };
        case SEND_EMAIL_VERIFICATION:
            return { ...state, isLoading: true, sendEmailVerificationError: null };
        case SEND_EMAIL_VERIFICATION_FAILED:
            return { ...state, isLoading: false, sendEmailVerificationError: action.payload };
        case SEND_EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                sendEmailVerificationSuccessMessage: action.payload,
            };
        case SEND_EMAIL_VERIFICATION_RESET:
            return {
                ...state,
                isLoading: false,
                sendEmailVerificationSuccessMessage: null,
                sendMobileVerificationSuccess: null,
            };

        case CONFIRM_EMAIL_VERIFICATION:
            return { ...state, isLoading: true, confirmEmailVerificationError: null };
        case CONFIRM_EMAIL_VERIFICATION_FAILED:
            return { ...state, isLoading: false, confirmEmailVerificationError: action.payload };
        case CONFIRM_EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                confirmEmailVerificationSuccessMessage: action.payload,
            };
        case VERIFY_EMAIL:
            return { ...state, isLoading: true, emailVerificationFailMessage: null };
        case VERIFY_EMAIL_FAILED:
            return { ...state, isLoading: false, emailVerificationFailMessage: action.payload };
        case VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                emailVerificationSuccessMessage: action.payload,
            };
        case RESET_PASSWORD_WEB:
            return { ...state, isResetPassLoading: true, editStudentError: null };
        case RESET_PASSWORD_WEB_SUCCESS:
            return {
                ...state,
                isResetPassLoading: false,
                WebResetPasswordSuccessMessage: action.payload,
            };
        case RESET_PASSWORD_WEB_FAILED:
            return { ...state, isResetPassLoading: false };
        case SEND_MOBILE_VERIFICATION:
            return {
                ...state,
                sendMobileVerificationError: null,
                sendMobileVerificationLoading: true,
            };
        case SEND_MOBILE_VERIFICATION_FAILED:
            return {
                ...state,
                sendMobileVerificationError: action.payload,
                sendMobileVerificationLoading: false,
            };
        case SEND_MOBILE_VERIFICATION_SUCCESS:
            return {
                ...state,
                sendMobileVerificationSuccess: action.payload,
                sendMobileVerificationLoading: false,
            };
        case CONFIRM_MOBILE_VERIFICATION:
            return {
                ...state,
                confirmMobileVerificationError: null,
                confirmMobileVerificationLoading: true,
            };
        case CONFIRM_MOBILE_VERIFICATION_FAILED:
            return {
                ...state,
                confirmMobileVerificationError: action.payload,
                confirmMobileVerificationLoading: false,
            };
        case CONFIRM_MOBILE_VERIFICATION_SUCCESS:
            return {
                ...state,
                confirmMobileVerificationSuccess: action.payload,
                confirmMobileVerificationLoading: false,
            };
        case RESET_SEND_MOBILE_VERIFICATION:
            return {
                ...state,
                sendMobileVerificationError: null,
                sendMobileVerificationLoading: false,
                sendMobileVerificationSuccess: null,
            };
        case RESET_CONFIRM_MOBILE_VERIFICATION:
            return {
                ...state,
                confirmMobileVerificationError: null,
                confirmMobileVerificationSuccess: null,
                confirmMobileVerificationLoading: false,
            };
        case CHANGE_USER_PASSWORD:
            return {
                ...state,
                changeUserPasswordError: null,
                changeUserPasswordLoading: true,
            };
        case CHANGE_USER_PASSWORD_FAILED:
            return {
                ...state,
                changeUserPasswordError: action.payload,
                changeUserPasswordLoading: false,
            };
        case CHANGE_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                changeUserPasswordSuccess: action.payload,
                changeUserPasswordLoading: false,
            };
        default:
            return state;
    }
};

export default WebUsersReducer;
