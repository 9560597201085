import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearEventsMessages } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import { useTable, useRowSelect, usePagination } from 'react-table';
import * as BsIcons from 'react-icons/bs';
import * as HiIcons from 'react-icons/hi';
import { IconContext } from 'react-icons';
import IndeterminateCheckbox from '../Tracks/IndeterminateCheckBox';
import TableTabView from './TableTabView';
import RestoreConfirmationModal from '../../modals/RestoreConfirmationModal';
import { EventEditType, EventType } from '../../../redux/events/reducers';
import InactivateConfirmationModal from '../../modals/InactivateConfirmationModal';
import ActivateConfirmationModal from '../../modals/ActivateConfirmationModal';
import EventsSuccessModal from '../../modals/EventsSuccessModal';
interface Props {
    data: EventType[];
    columns: any;
    fetchData;
    pageCount: number;
    setSlectedChallenges;
    setBulkAction: (value: string) => void;
    selectedTab: number;
    setSelectedTab: (value: number) => void;
    backToFirstRef: any;
}

const EventsTable = ({
    columns,
    data,
    fetchData,
    pageCount: controlledPageCount,
    setSlectedChallenges,
    selectedTab,
    setSelectedTab,
    setBulkAction,
    backToFirstRef,
}: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showMenu, setshowMenu] = useState(false);
    const [showColumnDropdown, setShowColumnDropdown] = useState(false);
    const [clickedEvent, setClickedEvent] = useState<EventType>();
    const [clickedColumnId, setClickedColumnId] = useState();
    const [selectedEvent, setSelectedEvent] = useState<EventEditType>();
    const [selectedEventForActivate, setSelectedEventForActivate] = useState<EventEditType>();
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [succesVisible, setSuccesVisible] = useState(false);
    const [restoreConfirmationVisible, setRestoreConfirmationVisible] = useState(false);
    const [showRecordsDropdown, setShowRecordsDropdown] = useState(false);
    const [activateConfirmationVisible, setActivateConfirmationVisible] = useState(false);
    const [inactivateConfirmationVisible, setInactivateConfirmationVisible] = useState(false);
    const [viewAllClicked, setViewAllClicked] = useState(false);
    const [goto, setGoto] = useState<any>();
    const tabCounts = useSelector((state: RootState) => state.events.count);
    const eventBulkSuccessMessage = useSelector(
        (state: RootState) => state.events.eventBulkSuccessMessage
    );
    const editEventError = useSelector((state: RootState) => state.events.editEventError);
    const bulkError = useSelector((state: RootState) => state.events.bulkError);
    const deleteEventError = useSelector((state: RootState) => state.events.deleteEventError);
    const eventDeleteSuccessMessage = useSelector(
        (state: RootState) => state.events.eventDeleteSuccessMessage
    );
    const eventUpdateSuccessMessage = useSelector(
        (state: RootState) => state.events.eventUpdateSuccessMessage
    );
    const tabStatus =
        selectedTab === 1
            ? 'ACTIVE'
            : selectedTab === 2
            ? 'INACTIVE'
            : selectedTab === 3
            ? 'DELETED'
            : '';
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        gotoPage,
        selectedFlatRows,
        allColumns,
        setHiddenColumns,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
            manualPagination: true,
            pageCount: controlledPageCount,
        },
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(column => [
                {
                    id: 'selection',
                    // eslint-disable-next-line react/display-name
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div className="flex justify-center">
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // eslint-disable-next-line react/display-name
                    Cell: ({ row }) => (
                        // eslint-disable-next-line react/display-name
                        <div className="flex justify-center">
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...column,
            ]);
        }
    );
    const VisibleColumns = allColumns.filter(
        col => col.isVisible === true && col.id !== 'selection' && col.id !== 'actionColumn'
    );

    useEffect(() => {
        gotoPage(0);
    }, [selectedTab, gotoPage]);

    useEffect(() => {
        selectedFlatRows.map(a => {
            return a.original;
        });
        setSlectedChallenges(
            selectedFlatRows.map(a => {
                return a.original;
            })
        );
    }, [selectedFlatRows, setSlectedChallenges]);

    useEffect(() => {
        fetchData({ pageIndex, pageSize });
    }, [fetchData, pageIndex, pageSize]);

    useImperativeHandle(backToFirstRef, () => ({
        goToFirst() {
            gotoPage(0);
        },
    }));

    const handleClearMessages = useCallback(() => {
        if (
            eventUpdateSuccessMessage ||
            editEventError ||
            deleteEventError ||
            eventDeleteSuccessMessage ||
            eventBulkSuccessMessage ||
            bulkError
        ) {
            setSuccesVisible(true);
            setBulkAction('Bulk Action');
        }
    }, [
        dispatch,
        eventDeleteSuccessMessage,
        deleteEventError,
        eventUpdateSuccessMessage,
        editEventError,
        eventBulkSuccessMessage,
        bulkError,
    ]);
    const handleClose = () => {
        dispatch(clearEventsMessages());
        setSuccesVisible(false);
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    useEffect(() => {
        if (viewAllClicked) {
            setHiddenColumns([]);
        } else {
            setHiddenColumns(['eventStartTime', 'registerLink']);
        }
    }, [viewAllClicked, setHiddenColumns]);

    const showOptions = (event: any) => {
        setshowMenu(true);
        setClickedEvent(event);
        setSelectedEvent({
            ...event,
            eventStatus: 'INACTIVE',
        });
        setSelectedEventForActivate({
            ...event,
            eventStatus: 'ACTIVE',
        });
    };

    const columnClickHandler = columnId => {
        setClickedColumnId(columnId);
        columnId !== 'selection' && columnId !== 'actionColumn'
            ? setShowColumnDropdown(true)
            : setShowColumnDropdown(false);
    };

    const viewEvent = (event: any) => {
        history.push({
            pathname: `/admin/events/${event.id}/view`,
            state: { event: event },
        });
    };
    const editEvent = async event => {
        history.push({
            pathname: `/admin/events/${event.id}/edit`,
            state: { event: event },
        });
    };
    return (
        <section className="py-6 h-full pr-4">
            <div className="flex justify-between w-full items-end mb-4">
                <div className="w-5/6 md:w-4/5">
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        tabCounts={tabCounts}
                    />
                </div>
                <div className="mt-4 ml-1 md:ml-4 w-12 md:w-32 flex justify-end items-center">
                    <div className="hidden md:flex md:w-1/2">Records</div>
                    <div
                        className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-1/2 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                    >
                        <div
                            className="flex justify-between w-full"
                            onClick={() => setShowRecordsDropdown(true)}
                        >
                            <div className="text-gray-700 font-medium pl-2">{pageSize}</div>
                            <div className="">
                                <div className="flex flex-col">
                                    <HiIcons.HiChevronUp className="-mb-2" />
                                    <HiIcons.HiChevronDown className="" />
                                </div>
                            </div>
                        </div>
                        {showRecordsDropdown ? (
                            <ul
                                className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                role="menu"
                                onMouseLeave={() => setShowRecordsDropdown(false)}
                            >
                                {[10, 25, 50, 100].map(value => (
                                    <div
                                        className="p-2 hover:bg-gray-200 "
                                        key={value}
                                        onClick={() => {
                                            setPageSize(value);
                                            setShowRecordsDropdown(false);
                                        }}
                                    >
                                        {value}
                                    </div>
                                ))}
                            </ul>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="w-full mb-8 overflow-hidden h-full">
                <div className="w-full overflow-x-auto pl-1 h-full" style={{ minHeight: '50vh' }}>
                    <table className="w-full" {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr
                                    key={headerGroup}
                                    {...headerGroup.getHeaderGroupProps()}
                                    className="text-sm xl:text-base font-medium tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-600"
                                    onMouseLeave={() => {
                                        setShowColumnDropdown(false);
                                        setshowMenu(false);
                                    }}
                                >
                                    {headerGroup.headers.map(column => (
                                        <th
                                            key={column}
                                            {...column.getHeaderProps()}
                                            className={`px-2 py-3 border align-top ${
                                                column.id === clickedColumnId ? 'relative' : ''
                                            } ${
                                                column.id !== 'selection' &&
                                                column.id !== 'actionColumn'
                                                    ? 'cursor-pointer'
                                                    : ''
                                            }`}
                                            onClick={() => columnClickHandler(column.id)}
                                        >
                                            <div
                                                className={`flex ${
                                                    column.id !== 'selection'
                                                        ? 'justify-between'
                                                        : 'justify-center'
                                                }`}
                                            >
                                                <div className="whitespace-nowrap">
                                                    {column.render('Header')}
                                                </div>
                                                {column.id !== 'selection' &&
                                                    column.id !== 'actionColumn' && (
                                                        <div className="flex flex-col justify-start">
                                                            <HiIcons.HiChevronDown
                                                                className="ml-2"
                                                                style={{
                                                                    color: '#374151',
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                            </div>

                                            {column.id === clickedColumnId && showColumnDropdown && (
                                                <div className="mt-3 absolute left-0 shadow-md z-10 w-44 cursor-default">
                                                    <ul
                                                        className="bg-gray-200 shadow border-2 border-gray-400 p-2 mb-0"
                                                        onMouseLeave={() =>
                                                            setShowColumnDropdown(false)
                                                        }
                                                    >
                                                        <div className="pb-2">
                                                            <p className="text-sm mb-2">
                                                                Show Column
                                                            </p>
                                                            <div className="font-medium text-gray-600 text-sm flex py-1 py-0.5">
                                                                <input
                                                                    className="mr-1 mt-1"
                                                                    type="checkbox"
                                                                    defaultChecked={viewAllClicked}
                                                                    onClick={() => {
                                                                        setViewAllClicked(
                                                                            !viewAllClicked
                                                                        );
                                                                    }}
                                                                />
                                                                <div>View All</div>
                                                            </div>
                                                            {allColumns.map(col => (
                                                                <div key={col.id}>
                                                                    {col.id !== 'selection' &&
                                                                        col.id !==
                                                                            'actionColumn' && (
                                                                            <label className="font-medium text-gray-600 text-sm flex py-0.5">
                                                                                <input
                                                                                    className="mr-1 mt-1"
                                                                                    type="checkbox"
                                                                                    {...col.getToggleHiddenProps()}
                                                                                    disabled={
                                                                                        col.isVisible &&
                                                                                        VisibleColumns.length <=
                                                                                            6
                                                                                    }
                                                                                />
                                                                                <div>
                                                                                    {col.Header}
                                                                                </div>
                                                                            </label>
                                                                        )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </ul>
                                                </div>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="bg-white" {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <tr key={row} {...row.getRowProps()} className="text-gray-700">
                                        {row.cells.map(cell => {
                                            return (
                                                <td
                                                    key={cell}
                                                    {...cell.getCellProps({})}
                                                    className={`py-3 text-sm font-semibold border ${
                                                        cell.column.id === 'actionColumn'
                                                            ? 'relative'
                                                            : cell.column.id !== 'selection'
                                                            ? 'px-2 cursor-pointer'
                                                            : ''
                                                    }`}
                                                    onClick={() => {
                                                        if (cell.column.id === 'actionColumn') {
                                                            showOptions(row.original);
                                                        } else if (cell.column.id !== 'selection') {
                                                            viewEvent(row.original);
                                                        }
                                                    }}
                                                >
                                                    {cell.render('Cell')}
                                                    {cell.column.id === 'actionColumn' &&
                                                        showMenu &&
                                                        clickedEvent === row.original && (
                                                            <div className="dropdown-content bottom-3 absolute left-0 shadow-md z-10 w-18">
                                                                <ul
                                                                    className="bg-gray-600 shadow rounded py-1 pl-0 mb-0"
                                                                    onMouseLeave={() =>
                                                                        setshowMenu(false)
                                                                    }
                                                                >
                                                                    {selectedTab === 2 && (
                                                                        <li
                                                                            className="cursor-pointer text-sm leading-3 tracking-normal py-2 hover:bg-gray-400 text-white pl-2 pr-5 font-normal"
                                                                            onClick={() => {
                                                                                setDeleteConfirmationVisible(
                                                                                    true
                                                                                );
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </li>
                                                                    )}
                                                                    {selectedTab !== 3 && (
                                                                        <li
                                                                            className="cursor-pointer text-sm leading-3 tracking-normal py-2 hover:bg-gray-400 text-white pl-2 pr-5 font-normal"
                                                                            onClick={() => {
                                                                                editEvent(
                                                                                    clickedEvent
                                                                                );
                                                                            }}
                                                                        >
                                                                            Edit
                                                                        </li>
                                                                    )}

                                                                    {selectedTab === 3 && (
                                                                        <li
                                                                            className="cursor-pointer text-sm leading-3 tracking-normal py-2 hover:bg-gray-400 text-white pr-5 pl-3 font-normal"
                                                                            onClick={() =>
                                                                                setRestoreConfirmationVisible(
                                                                                    true
                                                                                )
                                                                            }
                                                                        >
                                                                            Restore
                                                                        </li>
                                                                    )}

                                                                    {(selectedTab === 4 ||
                                                                        selectedTab === 1) &&
                                                                        clickedEvent.eventStatus ===
                                                                            'ACTIVE' && (
                                                                            <li
                                                                                className="cursor-pointer text-sm leading-3 tracking-normal py-2 hover:bg-gray-400 text-white pl-2 pr-5 font-normal"
                                                                                onClick={() =>
                                                                                    setInactivateConfirmationVisible(
                                                                                        true
                                                                                    )
                                                                                }
                                                                            >
                                                                                Inactivate
                                                                            </li>
                                                                        )}
                                                                    {(selectedTab === 4 ||
                                                                        selectedTab === 2) &&
                                                                        clickedEvent.eventStatus ===
                                                                            'INACTIVE' && (
                                                                            <li
                                                                                className="cursor-pointer text-sm leading-3 tracking-normal py-2 hover:bg-gray-400 text-white pl-2 pr-5 font-normal"
                                                                                onClick={() =>
                                                                                    setActivateConfirmationVisible(
                                                                                        true
                                                                                    )
                                                                                }
                                                                            >
                                                                                Activate
                                                                            </li>
                                                                        )}
                                                                </ul>
                                                            </div>
                                                        )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="pb-4">
                <div className="flex justify-end  mb-2">
                    <IconContext.Provider
                        value={{ color: !canPreviousPage ? '#bbbcbc' : '#707a92' }}
                    >
                        <button
                            className="text-xl font-medium text-gray-900"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            <BsIcons.BsChevronLeft className="" />
                        </button>
                    </IconContext.Provider>
                    <span className="flex items-center font-semibold text-gray-700">
                        <p className="border px-2 mx-2  my-auto">{pageIndex + 1}</p>
                        of
                        <p className="border px-2 mx-2  my-auto">
                            {pageOptions.length === 0 ? '1' : pageOptions.length}
                        </p>
                    </span>
                    <IconContext.Provider value={{ color: !canNextPage ? '#bbbcbc' : '#707a92' }}>
                        <button
                            className="text-xl font-medium text-gray-900"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        >
                            <BsIcons.BsChevronRight className="" />
                        </button>
                    </IconContext.Provider>
                </div>
                <div className="flex justify-end font-bold">
                    <div
                        className="bg-gray-200 border-gray-400 border mr-1 rounded px-1 cursor-pointer"
                        onClick={() => {
                            gotoPage(goto - 1);
                        }}
                    >
                        Go
                    </div>
                    to
                    <input
                        className="border font-bold px-2 mr-7 ml-2 w-8 focus:outline-none rounded"
                        value={goto}
                        type="number"
                        onChange={e => setGoto(e.target.value)}
                    />
                </div>
            </div>
            {deleteConfirmationVisible ? (
                <DeleteConfirmationModal
                    modalVisible={deleteConfirmationVisible}
                    setModalVisible={setDeleteConfirmationVisible}
                    eventId={clickedEvent?.id}
                    setshowMenu={setshowMenu}
                    tabStatus={tabStatus}
                />
            ) : null}
            {restoreConfirmationVisible ? (
                <RestoreConfirmationModal
                    modalVisible={restoreConfirmationVisible}
                    setModalVisible={setRestoreConfirmationVisible}
                    eventId={clickedEvent?.id}
                    event={selectedEvent}
                    setshowMenu={setshowMenu}
                    tabStatus={tabStatus}
                />
            ) : null}

            {succesVisible ? (
                <EventsSuccessModal modalVisible={succesVisible} handleClose={handleClose} />
            ) : null}
            {activateConfirmationVisible ? (
                <ActivateConfirmationModal
                    modalVisible={activateConfirmationVisible}
                    setModalVisible={setActivateConfirmationVisible}
                    eventId={clickedEvent.id}
                    event={selectedEventForActivate}
                    tabStatus={tabStatus}
                />
            ) : null}
            {inactivateConfirmationVisible ? (
                <InactivateConfirmationModal
                    modalVisible={inactivateConfirmationVisible}
                    setModalVisible={setInactivateConfirmationVisible}
                    eventId={clickedEvent.id}
                    event={selectedEvent}
                    tabStatus={tabStatus}
                />
            ) : null}
        </section>
    );
};

export default EventsTable;
