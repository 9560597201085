/* eslint-disable prettier/prettier */
export const isProd = false;

export const API_URL = isProd ? '' : process.env.REACT_APP_DEV_URL_ENDPOINT;

export const deleteConfirmationMessage = ' Are you sure you want to delete the selected';
export const activateConfirmationMessage = ' Are you sure you want to activate the selected';
export const inactivateConfirmationMessage = ' Are you sure you want to inactivate the selected';
export const disableConfirmationMessage = ' Are you sure you want to unpublish the selected';
export const publishConfirmationMessage = ' Are you sure you want to publish the selected';
export const restoreConfirmationMessage = ' Are you sure you want to restore the selected';
export const deactivateBeforeDeleteStudent =
    'Selected students should be in deactivated status first,to be Deleted.Please make sure selected students are in deactivated status to proceed with the Deletion.';
export const deleteTrackSuccessMessage = 'Selected track has been successfully deleted!';
export const deleteChallengeSuccessMessage = 'Selected challenge has been successfully deleted!';
export const resolutionError = 'Banner image minimum resolution size is 1440 X 400';
export const logoResolutionErrorMsg = 'Logo image minimum resolution size is 446 X 125';
export const trackImageResolutionError = 'Image width and height should be equal';
export const OTHERS = 'OTHERS';
export const error = 'Someting went wrong';
export const signinError = 'You have entered wrong credentials.';
export const DOCUMENT_DOWNLOAD_ERROR = 'Error occurred when downloading the file!';
export const DOCUMENT_NOT_FOUND_ERROR = 'File does not exist';
export const DOCUMENT_LINK_ERROR = 'Invalid URL';
export const PASSWORD_CHANGE_SUCCESS_MESSAGE = 'Your password has been successfully updated!';
export const s3Config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: '',
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_s3_ACCESS_KEY,
};
export const s3UrlPrefix =
    'https://' +
    process.env.REACT_APP_BUCKET_NAME.trim() +
    '.s3-' +
    process.env.REACT_APP_REGION.trim() +
    '.amazonaws.com/'; 

export const instituteTypes = {
    VOCATIONAL_INSTITUTE: 'VOCATIONAL_INSTITUTE',
    GOVERNMENT_UNIVERSITY: 'GOVERNMENT_UNIVERSITY',
    PRIVATE_UNIVERSITY: 'PRIVATE_UNIVERSITY',
    OTHER: 'OTHERS',
    VocationalTrainingInstitute: 'Vocational training institute',
    GovernmentUniversity: 'Government University',
    PrivateUniversity: 'Private University',
    Other: 'Other',
};

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export const disabledFields = [
    'autocomplete',
    'hidden',
    'number',
    'radio-group',
    'textarea',
    'text',
    'checkbox-group',
    'date',
    'select',
    'button',
    'span',
    'br',
];
export const disabledAttr = [
    'access',
    'inline',
    'other',
    'className',
    'toggle',
    'required',
    'rows',
    'maxlength',
    'helptext',
    'subtype',
    'name',
    'multiple',
];
export const fields = [
    {
        label: 'MCQ',
        attrs: {
            type: 'radio-group',
        },
    },
    {
        label: 'Short Answer',
        attrs: {
            type: 'text',
        },
    },
    {
        label: 'Long Answer',
        attrs: {
            type: 'textarea',
        },
    },
];
export const disabledAllFields = [
    'radio-group',
    'checkbox-group',
    'textarea',
    'text',
    'autocomplete',
    'hidden',
    'number',
    'paragraph',
    'header',
    'date',
    'select',
    'button',
    'file',
    'span',
    'br',
];

export const CLIENT_ID_CALENDAR =
    '1046588341265-145qrb3t1qaoajjgbnml2g5oh3nskljs.apps.googleusercontent.com';
export const mcqData = [
    {
        id: 0,
        question: 'What is a variable in software software programming?',
        score: 'Score 3 XP',
        answers: [
            {
                id: 0,
                answer: 'Value that automatically vary',
            },
            {
                id: 1,
                answer: 'A command to run your program',
            },
            {
                id: 3,
                answer: 'Memory location to store some value',
            },
            {
                id: 4,
                answer: 'None of the above',
            },
        ],
        correctAnswer: 'Value that automatically vary',
        selectedAnswer: null,
    },
    {
        id: 1,
        question: 'What is the answer to this expression, 22 % 3 is?',
        score: 'Score 5 XP',
        answers: [
            {
                id: 0,
                answer: '7',
            },
            {
                id: 1,
                answer: '5',
            },
            {
                id: 3,
                answer: '0',
            },
            {
                id: 4,
                answer: '1',
            },
        ],
        correctAnswer: '5',
        selectedAnswer: null,
    },
    {
        id: 2,
        question: 'Which of the following will run without errors?',
        score: 'Score 5 XP',
        answers: [
            {
                id: 0,
                answer: '7',
            },
            {
                id: 1,
                answer: '5',
            },
            {
                id: 3,
                answer: '0',
            },
            {
                id: 4,
                answer: '1',
            },
        ],
        correctAnswer: '0',
        selectedAnswer: null,
    },
];

export const decryptKey = 'R1RGYW5mcXRiaGVSUGRPQw==';

export const completeChallenge = {
    status: true,
    errorCode: 0,
    errorDescription: null,
    responseDto: {
        id: null,
        submittedDate: null,
        user: null,
        challengeId: 2,
        taskId: null,
        earnedMarks: 0.0,
        totalMarks: 10.0,
        earnedCertificate: [],
        earnedBadges: [
            {
                id: 2,
                badgeId: 'bdg002',
                badgeName: 'UX master',
                badgeDescription: 'UX designing',
                badgeType: 'CHALLENGE_BASED',
                badgeStatus: 'ACTIVE',
                imageUrl: null,
                badgeCreatedDate: '2022-02-23',
                badgeCreatedBy: 'Admin',
                badgeLastUpdatedDate: null,
                badgeLastUpdatedBy: null,
                criteria: null,
                tags: [
                    {
                        id: 3,
                        tagName: 'Leisure',
                        tagCreatedBy: 'Admin',
                        tagCreatedDate: '2022-02-23',
                        tagLastUpdatedBy: 'Admin',
                        tagLastUpdatedDate: '2022-02-23',
                        systemTag: false,
                        tagCategory: 'Industry',
                        module: 'COMPANY',
                    },
                    {
                        id: 4,
                        tagName: 'IT',
                        tagCreatedBy: 'Admin',
                        tagCreatedDate: '2022-02-23',
                        tagLastUpdatedBy: 'Admin',
                        tagLastUpdatedDate: '2022-02-23',
                        systemTag: false,
                        tagCategory: 'Beginner',
                        module: 'STUDENT',
                    },
                ],
            },
            {
                id: 3,
                badgeId: 'bdg003',
                badgeName: 'Coding master',
                badgeDescription: 'Coding designing',
                badgeType: 'CHALLENGE_BASED',
                badgeStatus: 'ACTIVE',
                imageUrl: null,
                badgeCreatedDate: '2022-02-23',
                badgeCreatedBy: 'Admin',
                badgeLastUpdatedDate: null,
                badgeLastUpdatedBy: null,
                criteria: null,
                tags: [
                    {
                        id: 3,
                        tagName: 'Leisure',
                        tagCreatedBy: 'Admin',
                        tagCreatedDate: '2022-02-23',
                        tagLastUpdatedBy: 'Admin',
                        tagLastUpdatedDate: '2022-02-23',
                        systemTag: false,
                        tagCategory: 'Industry',
                        module: 'COMPANY',
                    },
                    {
                        id: 4,
                        tagName: 'IT',
                        tagCreatedBy: 'Admin',
                        tagCreatedDate: '2022-02-23',
                        tagLastUpdatedBy: 'Admin',
                        tagLastUpdatedDate: '2022-02-23',
                        systemTag: false,
                        tagCategory: 'Beginner',
                        module: 'STUDENT',
                    },
                ],
            },
        ],
    },
};

export const learnChallenges = {
    featuredChallenges: [
        {
            id: 0,
            challengeCategory: 'featuredChallenges',
            challengeID: 'learnPython',
            challengeName: 'Learn Python Programming',
            tag: 'Frontend development',
            url: '/assets/htmlcss.png',
            description:
                'Coding and programming allow better work performance, across diverse professions. If you want to discuss ideas with programming professionals or develop the code yourself, you need to know the basics.Python is a powerful programming language, and mastering Python basics is the ideal way to understand and use programming concepts. With this exceptional four-week course from the University of Michigan, you can get started on doing just that.',
        },
        {
            id: 1,
            challengeCategory: 'featuredChallenges',
            challengeID: 'learnhtml&css',
            challengeName: 'Learn HTML & CSS',
            tag: 'Frontend development',
            url: '/assets/learnJS.png',
            description:
                'Coding and programming allow better work performance, across diverse professions. If you want to discuss ideas with programming professionals or develop the code yourself, you need to know the basics.Python is a powerful programming language, and mastering Python basics is the ideal way to understand and use programming concepts. With this exceptional four-week course from the University of Michigan, you can get started on doing just that.',
        },
        {
            id: 2,
            challengeCategory: 'featuredChallenges',
            challengeID: 'interactiveWeb',
            challengeName: 'Interative Web Pages with JS',
            tag: 'Frontend development',
            url: '/assets/learnInteractiveweb.png',
            description:
                'Coding and programming allow better work performance, across diverse professions. If you want to discuss ideas with programming professionals or develop the code yourself, you need to know the basics.Python is a powerful programming language, and mastering Python basics is the ideal way to understand and use programming concepts. With this exceptional four-week course from the University of Michigan, you can get started on doing just that.',
        },
    ],
    SoftwareDevChallenges: [
        {
            id: 0,
            challengeCategory: 'softwareDevChallenges',
            challengeID: 'learnDatabase',
            challengeName: 'Learn Databases and SQL',
            tag: 'Frontend development',
            url: '/assets/learnSQL.png',
            description:
                'Coding and programming allow better work performance, across diverse professions. If you want to discuss ideas with programming professionals or develop the code yourself, you need to know the basics.Python is a powerful programming language, and mastering Python basics is the ideal way to understand and use programming concepts. With this exceptional four-week course from the University of Michigan, you can get started on doing just that.',
        },
        {
            id: 1,
            challengeCategory: 'softwareDevChallenges',
            challengeID: 'learnhtml',
            challengeName: 'Learn HTML & CSS',
            tag: 'Frontend development',
            url: '/assets/htmlcss2.png',
            description:
                'Coding and programming allow better work performance, across diverse professions. If you want to discuss ideas with programming professionals or develop the code yourself, you need to know the basics.Python is a powerful programming language, and mastering Python basics is the ideal way to understand and use programming concepts. With this exceptional four-week course from the University of Michigan, you can get started on doing just that.',
        },
        {
            id: 2,
            challengeCategory: 'softwareDevChallenges',
            challengeID: 'pythonClass&Object',
            challengeName: `Python's Classes and Objects`,
            tag: 'Frontend development',
            url: '/assets/learnPython.png',
            description:
                'Coding and programming allow better work performance, across diverse professions. If you want to discuss ideas with programming professionals or develop the code yourself, you need to know the basics.Python is a powerful programming language, and mastering Python basics is the ideal way to understand and use programming concepts. With this exceptional four-week course from the University of Michigan, you can get started on doing just that.',
        },
    ],
    LearnData: [
        {
            id: 0,
            challengeCategory: 'mainChallenges',
            challengeID: 'softwareDevelopment',
            challengeName: 'Software Development',
            description:
                'Software Development courses to acquire useful skills and open up employment opportunities in a wide range of profitable industries. Software developer are predicted to be some of the most in-demand employees worldwide in the next decade. Wanna be one of them? Explore our software development sessions and boost your resume with some core software development skills. Start your learning journey with Future Careers BRIDGE, today!',
        },
        {
            id: 1,
            challengeCategory: 'mainChallenges',
            challengeID: 'uiux',
            challengeName: 'UI/UX',
            description:
                'Software Development courses to acquire useful skills and open up employment opportunities in a wide range of profitable industries. Software developer are predicted to be some of the most in-demand employees worldwide in the next decade. Wanna be one of them? Explore our software development sessions and boost your resume with some core software development skills. Start your learning journey with Future Careers BRIDGE, today!',
        },
        {
            id: 2,
            challengeCategory: 'mainChallenges',
            challengeID: 'buisness',
            challengeName: 'Buisness',
            description:
                'Software Development courses to acquire useful skills and open up employment opportunities in a wide range of profitable industries. Software developer are predicted to be some of the most in-demand employees worldwide in the next decade. Wanna be one of them? Explore our software development sessions and boost your resume with some core software development skills. Start your learning journey with Future Careers BRIDGE, today!',
        },
    ],
};
export const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const youTubeUrlRegExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/; //eslint-disable-line
export const AlphebtecFilter = [
    { id: 0, val: 'A' },
    { id: 1, val: 'B' },
    { id: 2, val: 'C' },
    { id: 3, val: 'D' },
    { id: 4, val: 'E' },
    { id: 5, val: 'F' },
    { id: 6, val: 'G' },
    { id: 7, val: 'H' },
    { id: 8, val: 'I' },
    { id: 9, val: 'J' },
    { id: 10, val: 'K' },
    { id: 11, val: 'L' },
    { id: 12, val: 'M' },
    { id: 13, val: 'N' },
    { id: 14, val: 'O' },
    { id: 15, val: 'P' },
    { id: 16, val: 'Q' },
    { id: 17, val: 'R' },
    { id: 18, val: 'S' },
    { id: 19, val: 'T' },
    { id: 20, val: 'U' },
    { id: 21, val: 'V' },
    { id: 22, val: 'W' },
    { id: 23, val: 'X' },
    { id: 24, val: 'Y' },
    { id: 25, val: 'Z' },
];

export const CityFilter = [
    { id: 0, val: 'Ampara' },
    { id: 1, val: 'Anuradhapura' },
    { id: 2, val: 'Batticaloa' },
    { id: 3, val: 'Badulla' },
    { id: 4, val: 'Colombo' },
    { id: 5, val: 'Gampaha' },
    { id: 6, val: 'Galle' },
    { id: 7, val: 'Hambantota' },
    { id: 8, val: 'Jaffna' },
    { id: 9, val: 'Kalutara' },
    { id: 10, val: 'Kurunegala' },
    { id: 11, val: 'Kilinochchi' },
    { id: 12, val: 'Kandy' },
    { id: 13, val: 'Kegalle' },
    { id: 14, val: 'Polonnaruwa' },
    { id: 15, val: 'Mannar' },
    { id: 16, val: 'Mullaitivu' },
    { id: 17, val: 'Matale' },
    { id: 18, val: 'Matara' },
    { id: 19, val: 'Monaragala' },
    { id: 20, val: 'Nuwara Eliya' },
    { id: 21, val: 'Puttalam' },
    { id: 22, val: 'Ratnapura' },
    { id: 23, val: 'Trincomalee' },
    { id: 24, val: 'Vavuniya' },
];

export const AverageScore = [
    { id: 0, val: '0 - 35 %' },
    { id: 1, val: '36 - 55 %' },
    { id: 3, val: '56 - 75 %' },
    { id: 4, val: '76 - 100 %' },
];

export const Gender = [
    { id: 0, val: 'MALE' },
    { id: 1, val: 'FEMALE' },
    { id: 2, val: 'OTHER' },
];

export const NotificationTypes = [
    { id: 'EMAIL', name: 'EMAIL' },
    { id: 'MOBILE', name: 'MOBILE' },
];

export const YOUTUBE_VIDEO_URL_REGEX =
    // eslint-disable-next-line no-useless-escape
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

export const moduleNames = {
    Student: 'Student',
    Tracks: 'Tracks',
    Challenges: 'Challenges',
    Events: 'Events',
    Badges: 'Badges',
    Certificates: 'Certificates',
    Institute: 'Institute',
    Mentors: 'Mentors',
    Companies: 'Companies',
    Notifications: 'Notifications',
    Analytics: 'Analytics',
};

// in hours
export const webAuthTimeout = 28;
export const adminAuthTimeout = 28;
// certificate base template key
export const baseCertificateTemplatePath =
    'certificates/base-certificate-template/base-certificate-template.html';
export const TrackPeriodFilter = [
    { id: 0, val: 'Current Year', key: 'ONE_YEAR_BACK' },
    { id: 1, val: 'Current Month', key: 'ONE_MONTH_BACK' },
    { id: 2, val: 'Current Week', key: 'ONE_WEEK_BACK' },
    { id: 3, val: 'Custom', key: 'CUSTOM' },
];

export const accountType = {
    PREMIUM_CORPORATE: 'PREMIUM_CORPORATE',
    CORPORATE: 'CORPORATE',
};

export const FCBHomeURL = 'https://www.futurecareersbridge.net';
