import { logout } from './../redux/authAdmin/actions';
import { store } from '..';
import { UserProfileType } from '../redux/authAdmin/reducers';

export const AUTH_TOKENS = 'AUTH_TOKENS_ADMIN';
export const USER_PROFILE = 'USER_PROFILE_ADMIN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN_ADMIN';

export const getAdminAuthCreds = () => {
    try {
        const tokens = localStorage.getItem(AUTH_TOKENS);
        return JSON.parse(tokens);
    } catch (error) {
        console.log('getAuthTokens error', error);
    }
};
export const getUserProfile = () => {
    try {
        const user = localStorage.getItem(USER_PROFILE);
        return JSON.parse(user);
    } catch (error) {
        console.log('getUserProfille error', error);
    }
};
export const storeAuthCreds = async (response: UserProfileType) => {
    try {
        localStorage.setItem(AUTH_TOKENS, JSON.stringify(response.token));
        localStorage.setItem(REFRESH_TOKEN, JSON.stringify(response?.refreshToken));
        localStorage.setItem(
            USER_PROFILE,
            JSON.stringify({
                userName: response.username,
                userId: response.userId,
                userEmail: response.email,
                roles: response.roles,
                companyId: response.companyId,
                companyName: response.companyName,
                instituteId: response.instituteId,
                instituteName: response.instituteName,
            })
        );
    } catch (error) {
        console.log('storeAuthCreds error', error);
    }
};
export const clearAdminLocalStorage = () => {
    try {
        localStorage.removeItem(AUTH_TOKENS);
        localStorage.removeItem(USER_PROFILE);
        localStorage.removeItem(REFRESH_TOKEN);
    } catch (error) {
        console.log('clearAdminLocalStorage error', error);
    }
};

export const getRefreshToken = () => {
    try {
        const tokens = localStorage.getItem(REFRESH_TOKEN);
        return JSON.parse(tokens);
    } catch (error) {
        console.error('getRefreshToken error', error);
    }
};

export const setAccessToken = (token: string) => {
    try {
        if (token) {
            localStorage.setItem(AUTH_TOKENS, JSON.stringify(token));
        } else {
            store.dispatch(logout());
        }
    } catch (error) {
        console.error('setAccessToken error', error);
    }
};
