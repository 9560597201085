// Categorize tags
export const categorizeTags = tags => {
    let i = 0;
    let val;
    let index;
    const values = [];
    const result = [];
    for (; i < tags.length; i++) {
        val = tags[i].tagCategory;
        index = values.indexOf(val);
        if (index > -1) result[index].push(tags[i]);
        else {
            values.push(val);
            result.push([tags[i]]);
        }
    }
    return result;
};
