import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import MetaTags from 'react-meta-tags';
import { s3UrlPrefix } from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import { certificateUrlFromCode } from '../../../../redux/rootActions';
import useWidth from '../../../hooks/useWidth';

interface paramsType {
    code: string;
}
export default function PublicCertificate() {
    const dispatch = useDispatch();
    const params = useParams<paramsType>();
    const viewPortWidth = useWidth();

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const certificateKey = useSelector(
        (state: RootState) => state.certificates.certificateUrlFromCode
    );

    function onDocumentLoadSuccess() {
        setNumPages(numPages);
        setPageNumber(1);
    }
    useEffect(() => {
        dispatch(certificateUrlFromCode(params.code));
    }, [dispatch]);

    return (
        <div className="flex justify-center w-full mt-4">
            <MetaTags>
                <title>Earned Certificate</title>
            </MetaTags>
            <Document
                file={`${s3UrlPrefix + certificateKey}`}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page height={viewPortWidth / 2.2} pageNumber={pageNumber} />
            </Document>
        </div>
    );
}
