import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import draftToMarkdown from 'draftjs-to-markdown';
import { convertToRaw } from 'draft-js';

interface Question {
    className: string;
    label: string;
    name: string;
    points: string;
    type: string;
}

interface Props {
    questionIndex: number;
    question: Question;
    handleLongAnswers: (questionIndex, question, answer, type) => void;
}

const LongAnswerField: React.FC<Props> = ({ questionIndex, question, handleLongAnswers }) => {
    const [editorState, setEditorState] = useState<any>('');
    const [markdownText, setMarkdownText] = useState('');
    const [isChangeMade, setIsChangeMade] = useState<boolean>(false);

    const toolbarCustomizations = {
        options: ['inline'],
        inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
        },
    };

    useEffect(() => {
        if (editorState) {
            setMarkdownText(draftToMarkdown(convertToRaw(editorState.getCurrentContent())));
        }
    }, [editorState]);

    useEffect(() => {
        if (markdownText.length > 1) {
            handleLongAnswers(questionIndex, question, markdownText, 'l');
            setIsChangeMade(true);
        }
    }, [markdownText]);

    return (
        <>
            <div key={questionIndex} className="space-x-2 w-full border-2 border-gray-300 rounded">
                <Editor
                    toolbar={toolbarCustomizations}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    toolbarClassName="toolbar-class"
                    type="text"
                    id={`${questionIndex}`}
                    name={question?.name}
                    editorStyle={{
                        backgroundColor: 'white',
                        border: '1.5px solid',
                        borderColor: '#A9A9A9',
                        maxHeight: '175px',
                        minHeight: '150px',
                    }}
                    toolbarStyle={{
                        borderWidth: '1px',
                        border: '1.5px solid',
                        borderColor: '#A9A9A9',
                        margin: '0',
                    }}
                    onEditorStateChange={setEditorState}
                />
            </div>
            {/* {isChangeMade && (
                <div>
                    <button
                        className={`px-4 py-1 rounded border-2 font-semibold justify-center items-center flex text-purple-950 border-purple-950 ${
                            !isChangeMade
                                ? `cursor-not-allowed`
                                : `cursor-pointer hover:text-white hover:bg-purple-950`
                        }`}
                        onClick={() => {
                            handleLongAnswers(questionIndex, question, markdownText);
                            setIsChangeMade(false);
                        }}
                    >
                        Confirm
                    </button>
                </div>
            )} */}
        </>
    );
};

export default LongAnswerField;
