import {
    WEB_CLEAR_AUTH,
    WEB_LOGIN,
    WEB_LOGIN_FAILED,
    WEB_LOGIN_SUCCESS,
    WEB_MOBILE_LOGIN,
    WEB_MOBILE_LOGIN_FAILED,
    WEB_MOBILE_LOGIN_SUCCESS,
    WEB_LOGIN_RESET,
    WEB_LOGOUT,
    WEB_SEND_EMAIL_TO_RESET_PASSWORD,
    WEB_SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS,
    WEB_SEND_EMAIL_TO_RESET_PASSWORD_FAILED,
} from './actions';
export interface signInResponseType {
    username: string;
    token: string;
    refreshToken: string;
    userId: string;
    email: string;
    roles: [];
    onBoardingCompleted?: boolean;
    isMobileVerified?: boolean;
    isEmailVerified?: boolean;
}
export interface googleOAUthResponseType {
    accessToken: string;
    username: string;
    userId: number;
    email: string;
    roles: string[];
}
export interface AuthWebInitialState {
    isLoading: boolean;
    loginResponse: signInResponseType | null;
    loginMobileResponse: signInResponseType | null;
    loginErrorMessage: string | null;
    loginMobileErrorMessage: string | null;
    isLogout: boolean;
    isWebEmailSending: boolean;
    emailSentResponse: any | null;
    emailSentError: string | null;
}

export const initialState: AuthWebInitialState = {
    isLoading: false,
    loginResponse: null,
    loginMobileResponse: null,
    loginErrorMessage: null,
    loginMobileErrorMessage: null,
    isLogout: false,
    isWebEmailSending: false,
    emailSentResponse: null,
    emailSentError: null,
};
const authWebReducer = (state = initialState, action: any): AuthWebInitialState => {
    switch (action.type) {
        case WEB_LOGIN:
            return { ...state, isLoading: true, loginErrorMessage: null };
        case WEB_LOGIN_SUCCESS:
            return { ...state, isLoading: false, loginResponse: action.payload, isLogout: false };
        case WEB_LOGIN_FAILED:
            return { ...state, isLoading: false, loginErrorMessage: action.payload };

        case WEB_MOBILE_LOGIN:
            return { ...state, isLoading: true, loginMobileErrorMessage: null };
        case WEB_MOBILE_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                loginMobileResponse: action.payload,
                isLogout: false,
            };
        case WEB_MOBILE_LOGIN_FAILED:
            return { ...state, isLoading: false, loginMobileErrorMessage: action.payload };
        case WEB_LOGIN_RESET:
            return { ...state, loginResponse: null };
        case WEB_SEND_EMAIL_TO_RESET_PASSWORD:
            return {
                ...state,
                isWebEmailSending: true,
                emailSentError: null,
                emailSentResponse: null,
            };
        case WEB_SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isWebEmailSending: false,
                emailSentResponse: action.payload,
            };
        case WEB_SEND_EMAIL_TO_RESET_PASSWORD_FAILED:
            return { ...state, isWebEmailSending: false, emailSentError: action.payload };

        case WEB_CLEAR_AUTH:
            return {
                ...state,
                loginErrorMessage: null,
                loginResponse: null,
                loginMobileErrorMessage: null,
                loginMobileResponse: null,
            };

        case WEB_LOGOUT:
            return {
                ...state,
                isLogout: true,
            };

        default:
            return state;
    }
};

export default authWebReducer;
