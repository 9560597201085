import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';

const PREFIX = '@MENTORS';

export const GET_MENTORS = PREFIX + 'GET_MENTORS';
export const GET_MENTORS_SUCCESS = PREFIX + 'GET_MENTORS_SUCCESS';
export const GET_MENTORS_FAILED = PREFIX + 'GET_MENTORS_FAILED';
export const GET_MENTOR_BY_ID = PREFIX + 'GET_MENTOR_BY_ID';
export const GET_MENTOR_BY_ID_SUCCESS = PREFIX + 'GET_MENTOR_BY_ID_SUCCESS';
export const GET_MENTOR_BY_ID_FAILED = PREFIX + 'GET_MENTOR_BY_ID_FAILED';
export const ADD_MENTOR = PREFIX + 'ADD_MENTOR';
export const ADD_MENTOR_SUCCESS = PREFIX + 'ADD_MENTOR_SUCCESS';
export const ADD_MENTOR_FAILED = PREFIX + 'ADD_MENTOR_FAILED';
export const EDIT_MENTOR = PREFIX + 'EDIT_MENTOR';
export const EDIT_MENTOR_SUCCESS = PREFIX + 'EDIT_MENTOR_SUCCESS';
export const EDIT_MENTOR_FAILED = PREFIX + 'EDIT_MENTOR_FAILED';
export const DELETE_MENTOR = PREFIX + 'DELETE_MENTOR';
export const DELETE_MENTOR_SUCCESS = PREFIX + 'DELETE_MENTOR_SUCCESS';
export const DELETE_MENTOR_FAILED = PREFIX + 'DELETE_MENTOR_FAILED';
export const MENTORS_BULK_UPDATE = PREFIX + 'MENTORS_BULK_UPDATE';
export const MENTORS_BULK_UPDATE_SUCCESS = PREFIX + 'MENTORS_BULK_UPDATE_SUCCESS';
export const MENTORS_BULK_UPDATE_FAILED = PREFIX + 'MENTORS_BULK_UPDATE_FAILED';
export const GET_DOCUMENT_PRESIGNED = PREFIX + 'GET_DOCUMENT_PRESIGNED';
export const GET_DOCUMENT_PRESIGNED_SUCCESS = PREFIX + 'GET_DOCUMENT_PRESIGNED_SUCCESS';
export const GET_DOCUMENT_PRESIGNED_FAILED = PREFIX + 'GET_DOCUMENT_PRESIGNED_FAILED';
export const UPLOAD_DOCUMENT = PREFIX + 'UPLOAD_DOCUMENT';
export const UPLOAD_DOCUMENT_SUCCESS = PREFIX + 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILED = PREFIX + 'UPLOAD_DOCUMENT_FAILED';
export const GET_PDF = PREFIX + 'GET_PDF';
export const GET_PDF_SUCCESS = PREFIX + 'GET_PDF_SUCCESS';
export const GET_PDF_FAILED = PREFIX + 'GET_PDF_FAILED';
export const GET_MENTEES = PREFIX + 'GET_MENTEES';
export const GET_MENTEES_SUCCESS = PREFIX + 'GET_MENTEES_SUCCESS';
export const GET_MENTEES_FAILED = PREFIX + 'GET_MENTEES_FAILED';
export const ASSIGN_MENTEES = PREFIX + 'ASSIGN_MENTEES';
export const ASSIGN_MENTEES_SUCCESS = PREFIX + 'ASSIGN_MENTEES_SUCCESS';
export const ASSIGN_MENTEES_FAILED = PREFIX + 'ASSIGN_MENTEES_FAILED';
export const CLEAR_MENTORS_MESSAGES = PREFIX + 'CLEAR_MENTORS_MESSAGES';
export const GET_MENTOR_COMPANIES = PREFIX + 'GET_MENTOR_COMPANIES';
export const GET_MENTOR_COMPANIES_SUCCESS = PREFIX + 'GET_MENTOR_COMPANIES_SUCCESS';
export const GET_MENTOR_COMPANIES_FAILED = PREFIX + 'GET_MENTOR_COMPANIES_FAILED';

export const getMentors =
    (
        isAdmin: boolean,
        page?: number,
        size?: number,
        status?: string,
        tags?: string,
        searchTerm?: string,
        company?: string
    ) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_MENTORS });
            const config = await axiosConfig(isAdmin);
            const response = await axios.get(
                `${API_URL}/mentors/?page=${page || 0}&size=${size || 10}${
                    status ? `&status=${status}` : ''
                }${searchTerm ? `&searchText=${searchTerm}` : ''}${tags ? `&tags=${tags}` : ''}${
                    company ? `&company=${company}` : ''
                }`,
                config
            );
            dispatch({
                type: GET_MENTORS_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_MENTORS_FAILED, payload: getErrorMessage(error) });
        }
    };

export const getMentorCompanies = (isAdmin: boolean) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_MENTOR_COMPANIES });
        const config = await axiosConfig(isAdmin);
        const response = await axios.get(`${API_URL}/mentors/companies`, config);
        dispatch({
            type: GET_MENTOR_COMPANIES_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_MENTOR_COMPANIES_FAILED, payload: getErrorMessage(error) });
    }
};
export const getMentorById = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_MENTOR_BY_ID });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/mentors/${id}`, config);
        dispatch({ type: GET_MENTOR_BY_ID_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_MENTOR_BY_ID_FAILED, payload: getErrorMessage(error) });
    }
};
export const getMentorPdfUrl = (fileName: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_PDF });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/files?fileName=${fileName}&path=mentors/docs`,
            config
        );
        dispatch({
            type: GET_PDF_SUCCESS,
            payload: response.data,
        });
        return response.data;
    } catch (error) {
        dispatch({ type: GET_PDF_FAILED, payload: getErrorMessage(error) });
    }
};
export const uploadDocument = (url: string, file: any) => async (dispatch: any) => {
    try {
        dispatch({ type: UPLOAD_DOCUMENT });
        const headers = {
            'Content-Type':
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        };
        const res = await axios({
            method: 'put',
            url: url,
            data: file,
            headers,
        });
        dispatch({ type: UPLOAD_DOCUMENT_SUCCESS, payload: res.data });
    } catch (error) {
        dispatch({ type: UPLOAD_DOCUMENT_FAILED, payload: getErrorMessage(error) });
    }
};
export const getPresignedUrlMentor = (fileName: string, file: any) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_DOCUMENT_PRESIGNED });
        const config = await axiosConfig(true);
        const response = await axios.post(
            `${API_URL}/files?fileName=${fileName}&path=mentors/docs`,
            null,
            config
        );
        if (response.status === 200) {
            dispatch(uploadDocument(response.data, file));
            dispatch({
                type: GET_DOCUMENT_PRESIGNED_SUCCESS,
                payload: response.data.responseDto,
            });
        }
        return response.data;
    } catch (error) {
        dispatch({ type: GET_DOCUMENT_PRESIGNED_FAILED, payload: getErrorMessage(error) });
    }
};
export const addMentor = (mentor: any) => async (dispatch: any) => {
    try {
        dispatch({ type: ADD_MENTOR });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/mentors/`, mentor, config);
        if (res?.data?.status) {
            if (res?.data?.errorCode === 222) {
                await dispatch(getMentors(true));
                await dispatch({
                    type: ADD_MENTOR_SUCCESS,
                    payload: res.data.responseDto,
                });
            }
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 222) {
                dispatch({
                    type: ADD_MENTOR_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: ADD_MENTOR_FAILED, payload: getErrorMessage(error) });
    }
};
export const editMentor = (id: string, mentor: any, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: EDIT_MENTOR });
        const config = await axiosConfig(true);
        const res = await axios.put(`${API_URL}/mentors/${id}`, mentor, config);
        await dispatch(getMentors(true, 0, 10, status));
        if (res?.data?.status) {
            dispatch({ type: EDIT_MENTOR_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 214) {
                dispatch({
                    type: EDIT_MENTOR_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: EDIT_MENTOR_FAILED, payload: getErrorMessage(error) });
    }
};
export const deleteMentor = (id: string, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: DELETE_MENTOR });
        const config = await axiosConfig(true);
        const res = await axios.delete(`${API_URL}/mentors/${id}`, config);
        await dispatch(getMentors(true, 0, 10, status));
        dispatch({ type: DELETE_MENTOR_SUCCESS, payload: res.data.responseDto });
    } catch (error) {
        dispatch({ type: DELETE_MENTOR_FAILED, payload: getErrorMessage(error) });
    }
};
export const bulkUpdateMentors = (MENTORS, tabStatus) => async (dispatch: any) => {
    try {
        dispatch({ type: MENTORS_BULK_UPDATE });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/mentors/bulk`, MENTORS, config);
        await dispatch(getMentors(true, 0, 10, tabStatus));
        if (res?.data?.status) {
            if (res?.data?.errorCode === 202)
                dispatch({ type: MENTORS_BULK_UPDATE_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 202) {
                dispatch({
                    type: MENTORS_BULK_UPDATE_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: MENTORS_BULK_UPDATE_FAILED, payload: getErrorMessage(error) });
    }
};

export const getMentees =
    (page?: number, status?: boolean, studentName?: string, mentorId?: string) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_MENTEES });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/users/admins/student?page=${page || 0}&size=10${
                    studentName ? `&studentName=${studentName}` : ''
                }&mentorId=${mentorId}&selected=${status}`,
                config
            );

            dispatch({
                type: GET_MENTEES_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_MENTEES_FAILED, payload: getErrorMessage(error) });
        }
    };

export const assignMentees = (id: string, mentees: any) => async (dispatch: any) => {
    try {
        dispatch({ type: ASSIGN_MENTEES });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/mentors/assign/${id}`, mentees, config);
        if (res?.data?.status) {
            dispatch({ type: ASSIGN_MENTEES_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 214) {
                dispatch({
                    type: ASSIGN_MENTEES_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: ASSIGN_MENTEES_FAILED, payload: getErrorMessage(error) });
    }
};
export const clearMentorsMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_MENTORS_MESSAGES });
};
