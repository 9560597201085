import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getPublicEvents } from '../../../../../redux/rootActions';
import { RootState } from '../../../../../redux/rootReducer';
import styled from 'styled-components';
import { EventType } from '../../../../../redux/events/reducers';

const SmallFontDiv = styled.div`
    font-size: 10px;
    margin-bottom: 5px;
`;
const Events = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const publicEventsData = useSelector((state: RootState) => state.events.publicEventsData);

    const [selectedTab, setSelectedTab] = useState<number>(1);
    const currentDate = new Date().getTime();

    const [upcomingEvents, setUpcomingEvents] = useState<EventType[]>();
    const [onGoingEvents, setOnGoingEvents] = useState<EventType[]>();
    const [completedEvents, setCompletedEvents] = useState<EventType[]>();
    const [currentPageEvents, setCurrentPageEvents] = useState<EventType[]>();

    useEffect(() => {
        dispatch(getPublicEvents());
    }, [dispatch]);

    useEffect(() => {
        setUpcomingEvents(
            publicEventsData.filter(event => currentDate < new Date(event.eventStartTime).getTime())
        );
        setOnGoingEvents(
            publicEventsData.filter(
                event =>
                    currentDate < new Date(event.eventEndTime).getTime() &&
                    currentDate > new Date(event.eventStartTime).getTime()
            )
        );
        setCompletedEvents(
            publicEventsData.filter(event => currentDate > new Date(event.eventEndTime).getTime())
        );
    }, [publicEventsData]);

    useEffect(() => {
        if (selectedTab === 1) {
            setCurrentPageEvents(upcomingEvents);
        }
    }, [upcomingEvents]);

    useEffect(() => {
        if (selectedTab === 1) {
            setCurrentPageEvents(upcomingEvents);
        } else if (selectedTab === 2) {
            setCurrentPageEvents(onGoingEvents);
        } else {
            setCurrentPageEvents(completedEvents);
        }
    }, [selectedTab]);

    // const durationCalc = (start, end) => {
    //     const fullDuration = end.diff(start, 'minutes');
    //     if (fullDuration > 1440) {
    //         return end.diff(start, 'days') + 'Day/s';
    //     } else if (fullDuration > 60) {
    //         return end.diff(start, 'hours') + 'Hour/s';
    //     } else {
    //         return end.diff(start, 'minutes') + 'Minute/s';
    //     }
    // };

    const durationCalc = (start, end) => {
        let time;
        let fullDuration;
        const startTime = start;
        const endTime = end;
        if (startTime && endTime) {
            fullDuration = endTime.diff(startTime, 'minutes');
            if (fullDuration > 1440) {
                const minutes = fullDuration % 60;
                const hours = Math.round((fullDuration % 1440) / 60);
                if (hours > 0) {
                    time = endTime.diff(startTime, 'days') + ' D and ' + hours + ' H';

                    if (minutes > 0) {
                        time =
                            endTime.diff(startTime, 'days') +
                            ' D, ' +
                            hours +
                            ' H and ' +
                            minutes +
                            ' M';
                        return time;
                    } else {
                        return time;
                    }
                } else {
                    if (minutes > 0) {
                        time = endTime.diff(startTime, 'days') + ' D, ' + minutes + ' and M';
                        return time;
                    } else {
                        time = endTime.diff(startTime, 'days') + ' D';
                        return time;
                    }
                }
            } else if (fullDuration > 60) {
                const minutes = fullDuration % 60;
                if (minutes > 0) {
                    time = endTime.diff(startTime, 'hours') + ' H and ' + minutes + ' M';
                    return time;
                } else {
                    time = endTime.diff(startTime, 'hours') + ' H';
                    return time;
                }
            } else {
                return endTime.diff(startTime, 'minutes') + ' M';
            }
        }
    };
    return (
        <>
            <div className="hidden lg:block">
                <div className="bg-purple-85  lg:px-28 flex justify-between">
                    <div className="flex items-center  py-10 px-16">
                        <div className="space-y-4 ">
                            <div className="font-manrope text-2xl-2 font-bold text-purple-950">
                                BRIDGE Events
                            </div>
                            <div style={{ width: '510px' }} className="text-lg">
                                BRIDGE Events enable students to gain more knowledge of the industry
                                and also get opportunities to meet and speak with professionals.
                                Join BRIDGE events to hear about the IT, BPM career opportunities
                                and what a typical work day is like and also to tour companies and
                                work facilities.
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="">
                            <img
                                className="h-full hidden lg:block"
                                src={`/assets/EventsImage.png`}
                                alt="logo"
                            />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center lg:px-28 mb-10">
                    <div className="flex flex-col px-16">
                        <div className="mt-12 text-2xl-2 font-bold font-manrope mb-8">
                            Stay connected with us
                        </div>
                        <div className="flex flex-row mb-10">
                            <div
                                className={`${
                                    selectedTab === 1
                                        ? ' border-purple-950 text-purple-950 border-b-2 font-bold'
                                        : 'text-black-300 border-snow-500'
                                } flex text-sm md:text-base xl:text-lg mr-6 cursor-pointer items-center`}
                                onClick={() => {
                                    setSelectedTab(1);
                                }}
                            >
                                Upcoming
                            </div>
                            <div
                                className={`${
                                    selectedTab === 2
                                        ? ' border-purple-950 text-purple-950 border-b-2 font-bold'
                                        : 'text-black-300 border-snow-500'
                                } flex text-sm md:text-base xl:text-lg mr-6 cursor-pointer items-center`}
                                onClick={() => {
                                    setSelectedTab(2);
                                }}
                            >
                                Ongoing
                            </div>
                            <div
                                className={`${
                                    selectedTab === 3
                                        ? ' border-purple-950 text-purple-950 border-b-2 font-bold'
                                        : 'text-black-300 border-snow-500'
                                } flex text-sm md:text-base xl:text-lg mr-6 cursor-pointer items-center`}
                                onClick={() => {
                                    setSelectedTab(3);
                                }}
                            >
                                Completed
                            </div>
                        </div>
                        {currentPageEvents?.length === 0 ? (
                            <div className="flex px-4 py-3 my-2">
                                There are no{' '}
                                {selectedTab === 1
                                    ? 'upcoming'
                                    : selectedTab === 2
                                    ? 'ongoing'
                                    : 'completed'}{' '}
                                events available.
                            </div>
                        ) : (
                            currentPageEvents?.map(event => (
                                <div
                                    key={event.id}
                                    className="flex border-2 border-gray-100 px-4 py-3 rounded my-2 justify-between"
                                >
                                    <div className="w-24 flex-shrink-0">
                                        <div className="text-purple-950 font-semibold">
                                            Event Name
                                        </div>
                                        <div>
                                            {event.eventName.length < 20
                                                ? event.eventName
                                                : event.eventName.substring(0, 17) + '...'}
                                        </div>
                                    </div>
                                    <div className="w-24 flex-shrink-0">
                                        <div className="text-purple-950 font-semibold">
                                            Event Type
                                        </div>
                                        <div>{event.eventType}</div>
                                    </div>
                                    <div className="w-24 flex-shrink-0">
                                        <div className="text-purple-950 font-semibold">Date</div>
                                        <div>
                                            {moment(event.eventStartTime).format('YYYY/MM/DD')}
                                        </div>
                                    </div>
                                    <div className="w-24 flex-shrink-0">
                                        <div className="text-purple-950 font-semibold">Time</div>
                                        <div>{moment(event.eventStartTime).format('hh:mm A')}</div>
                                    </div>
                                    <div className="w-24 flex-shrink-0">
                                        <div className="text-purple-950 font-semibold">
                                            Duration
                                        </div>
                                        <div>
                                            {durationCalc(
                                                moment(event.eventStartTime),
                                                moment(event.eventEndTime)
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => {
                                            history.push(`/event/${event.id}`);
                                        }}
                                        className="border-1 rounded border-2 border-purple-950 text-purple-950 w-48 h-12 flex justify-center items-center font-semibold cursor-pointer"
                                    >
                                        View more
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <div className="lg:hidden">
                <img className="h-full" src={`/assets/EventsImage.png`} alt="logo" />
                <div className="bg-purple-85  lg:px-28 flex justify-between">
                    <div className="flex items-center py-10 px-4">
                        <div className="space-y-4">
                            <div className="font-manrope text-lg font-bold text-purple-950">
                                BRIDGE Events
                            </div>
                            <div className="text-xs">
                                BRIDGE Events enable students to gain more knowledge of the industry
                                and also get opportunities to meet and speak with professionals.
                                Join BRIDGE events to hear about the IT, BPM career opportunities
                                and what a typical work day is like and also to tour companies and
                                work facilities.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center lg:px-28 mb-10">
                    <div className="flex flex-col px-4">
                        <div className="mt-12 text-lg font-bold font-manrope mb-8">
                            Stay connected with us
                        </div>
                        <div className="flex flex-row mb-6">
                            <div
                                className={`${
                                    selectedTab === 1
                                        ? ' border-purple-950 text-purple-950 border-b-2 font-bold'
                                        : 'text-black-300 border-snow-500'
                                } flex text-xs mr-4 cursor-pointer items-center`}
                                onClick={() => {
                                    setSelectedTab(1);
                                }}
                            >
                                Upcoming
                            </div>
                            <div
                                className={`${
                                    selectedTab === 2
                                        ? ' border-purple-950 text-purple-950 border-b-2 font-bold'
                                        : 'text-black-300 border-snow-500'
                                } flex text-xs mr-6 cursor-pointer items-center`}
                                onClick={() => {
                                    setSelectedTab(2);
                                }}
                            >
                                Ongoing
                            </div>
                            <div
                                className={`${
                                    selectedTab === 3
                                        ? ' border-purple-950 text-purple-950 border-b-2 font-bold'
                                        : 'text-black-300 border-snow-500'
                                } flex text-xs mr-6 cursor-pointer items-center`}
                                onClick={() => {
                                    setSelectedTab(3);
                                }}
                            >
                                Completed
                            </div>
                        </div>
                        <div className="w-11/12 overflow-x-scroll horizontal-scrollable-leaderboard">
                            {publicEventsData.map(event => {
                                if (
                                    selectedTab === 1 &&
                                    currentDate < new Date(event.eventStartTime).getTime()
                                ) {
                                    return (
                                        <div
                                            key={event.id}
                                            className="flex w-max border-2 border-gray-100 px-4 py-3 rounded my-2 justify-between"
                                        >
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Event Name
                                                </SmallFontDiv>
                                                <div className="text-xs">
                                                    {event.eventName.length < 10
                                                        ? event.eventName
                                                        : event.eventName.substring(0, 7) + '...'}
                                                </div>
                                            </div>
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Event Type
                                                </SmallFontDiv>
                                                <div className="text-xs">{event.eventType}</div>
                                            </div>
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Date
                                                </SmallFontDiv>
                                                <div className="text-xs">
                                                    {moment(event.eventStartTime).format(
                                                        'YYYY/MM/DD'
                                                    )}
                                                </div>
                                            </div>
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Time
                                                </SmallFontDiv>
                                                <div className="text-xs">
                                                    {moment(event.eventStartTime).format('hh:mm A')}
                                                </div>
                                            </div>
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Duration
                                                </SmallFontDiv>
                                                <div className="text-xs">
                                                    {durationCalc(
                                                        moment(event.eventStartTime),
                                                        moment(event.eventEndTime)
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    history.push(`/event/${event.id}`);
                                                }}
                                                className="text-xs border-1 rounded border-2 border-purple-950 text-purple-950 px-3 py-2 flex justify-center items-center font-semibold cursor-pointer"
                                            >
                                                View more
                                            </div>
                                        </div>
                                    );
                                } else if (
                                    selectedTab === 3 &&
                                    currentDate > new Date(event.eventEndTime).getTime()
                                ) {
                                    return (
                                        <div
                                            key={event.id}
                                            className="flex w-max border-2 border-gray-100 px-4 py-3 rounded my-2 justify-between font-semibold"
                                        >
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Event Name
                                                </SmallFontDiv>
                                                <div className="text-xs">
                                                    {event.eventName.length < 10
                                                        ? event.eventName
                                                        : event.eventName.substring(0, 7) + '...'}
                                                </div>
                                            </div>
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Event Type
                                                </SmallFontDiv>
                                                <div className="text-xs">{event.eventType}</div>
                                            </div>
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Date
                                                </SmallFontDiv>
                                                <div className="text-xs">
                                                    {moment(event.eventStartTime).format(
                                                        'YYYY/MM/DD'
                                                    )}
                                                </div>
                                            </div>
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Time
                                                </SmallFontDiv>
                                                <div className="text-xs">
                                                    {moment(event.eventStartTime).format('hh:mm A')}
                                                </div>
                                            </div>
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Duration
                                                </SmallFontDiv>
                                                <div className="text-xs">
                                                    {durationCalc(
                                                        moment(event.eventStartTime),
                                                        moment(event.eventEndTime)
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    history.push(`/event/${event.id}`);
                                                }}
                                                className="text-xs border-1 rounded border-2 border-purple-950 text-purple-950 px-3 py-2 flex justify-center items-center font-semibold cursor-pointer"
                                            >
                                                View more
                                            </div>
                                        </div>
                                    );
                                } else if (
                                    selectedTab === 2 &&
                                    currentDate < new Date(event.eventEndTime).getTime() &&
                                    currentDate > new Date(event.eventStartTime).getTime()
                                ) {
                                    return (
                                        <div
                                            key={event.id}
                                            className="flex w-max border-2 border-gray-100 px-4 py-3 rounded my-2 justify-between font-semibold"
                                        >
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Event Name
                                                </SmallFontDiv>
                                                <div className="text-xs">
                                                    {event.eventName.length < 10
                                                        ? event.eventName
                                                        : event.eventName.substring(0, 7) + '...'}
                                                </div>
                                            </div>
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Event Type
                                                </SmallFontDiv>
                                                <div className="text-xs">{event.eventType}</div>
                                            </div>
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Date
                                                </SmallFontDiv>
                                                <div className="text-xs">
                                                    {moment(event.eventStartTime).format(
                                                        'YYYY/MM/DD'
                                                    )}
                                                </div>
                                            </div>
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Time
                                                </SmallFontDiv>
                                                <div className="text-xs">
                                                    {moment(event.eventStartTime).format('hh:mm A')}
                                                </div>
                                            </div>
                                            <div className="w-24 flex-shrink-0">
                                                <SmallFontDiv className="text-purple-950 font-semibold">
                                                    Duration
                                                </SmallFontDiv>
                                                <div className="text-xs">
                                                    {durationCalc(
                                                        moment(event.eventStartTime),
                                                        moment(event.eventEndTime)
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    history.push(`/event/${event.id}`);
                                                }}
                                                className="text-xs border-1 rounded border-2 border-purple-950 text-purple-950 px-3 py-2 flex justify-center items-center font-semibold cursor-pointer"
                                            >
                                                View more
                                            </div>
                                        </div>
                                    );
                                } else return null;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Events;
