import React, { ReactElement } from 'react';
import { Modal } from 'react-responsive-modal';

interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    setMenteeDeleteCommand: (value: boolean) => void;
    clickedStudent: any;
}

export default function RemoveMenteeConfirmation({
    modalVisible,
    setModalVisible,
    setMenteeDeleteCommand,
    clickedStudent,
}: Props): ReactElement {
    return (
        <Modal
            open={modalVisible}
            onClose={() => {
                setModalVisible(false);
            }}
            center
            showCloseIcon={false}
            styles={{
                modal: {
                    borderRadius: 5,
                    maxWidth: '628px',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    boxShadow: '0 12px 15px 0 rgb(0 0 0 / 10%)',
                },
                overlay: { background: 'none' },
            }}
        >
            <div className="">
                <div className="text-base text-center text-gray-800 px-4 pt-6 pb-4">
                    {`Are you sure you want to unassign the mentor assigned to ${
                        clickedStudent?.firstName
                    } ${clickedStudent?.lastName ? clickedStudent?.lastName : ''}?`}
                </div>
                <div className="mt-8 flex justify-end">
                    <button
                        className="border rounded-md border-blue-600 text-blue-600 text-sm h-10 px-8 mr-2 focus:outline-none"
                        onClick={() => {
                            setModalVisible(false);
                            setMenteeDeleteCommand(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="border bg-blue-600 rounded-md text-white text-sm h-10 leading-4 px-8 ml-2 focus:outline-none hover:text-white hover:bg-blue-800"
                        onClick={() => {
                            setMenteeDeleteCommand(true);
                            setModalVisible(false);
                        }}
                    >
                        Unassign
                    </button>
                </div>
            </div>
        </Modal>
    );
}
