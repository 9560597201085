import React, { useState } from 'react';
import JsxParser from 'react-jsx-parser';

import UpArrow from '../../../../../assets/svg/UpArrow';
import DownArrow from '../../../../../assets/svg/DownArrow';
import useWidth from '../../../../hooks/useWidth';
import BackArrow from '../../../../../assets/svg/BackArrow';
import { useHistory } from 'react-router-dom';
import { USER_PROFILE } from '../../../../utils/storageWeb';

const topicData = [
    {
        id: 1,
        title: 'Register with Future Careers BRIDGE',
        sections: [
            {
                id: 1,
                title: 'How to register with an email and password?',
                subSections: [
                    {
                        description: 'Click on register button',
                        image: '/assets/help/gettingStarted/gs_01.png',
                    },
                    {
                        description:
                            'Select by email or Select continue with Google, Facebook or LinkedIn.',
                        image: '/assets/help/gettingStarted/gs_02.png',
                    },
                    {
                        description:
                            'Enter email address & password. Agree to the terms and conditions',
                        image: '/assets/help/gettingStarted/gs_03.png',
                    },
                    {
                        description: 'Confirm your email address',
                        image: '/assets/help/gettingStarted/gs_04.png',
                    },
                ],
            },
            {
                id: 2,
                title: 'How to register with phone number?',
                subSections: [
                    {
                        description: 'Click on register button',
                        image: '/assets/help/gettingStarted/gs_01.png',
                    },
                    {
                        description: 'Select by phone',
                        image: '/assets/help/gettingStarted/gs_05.png',
                    },
                    {
                        description: 'Enter phone number & Agree to the terms and conditions',
                        image: '/assets/help/gettingStarted/gs_06.png',
                    },
                    {
                        description:
                            'OTP verification code will be sent to the number you entered. Enter the number and click verify',
                        image: '/assets/help/gettingStarted/gs_07.png',
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        title: 'Sign in to Future Careers BRIDGE',
        sections: [
            {
                id: 3,
                title: 'How to sign in with an email and password?',
                subSections: [
                    {
                        description: 'Click on Sign in button',
                        image: '/assets/help/gettingStarted/gs_16.png',
                    },
                    {
                        description: 'Enter your email address and password',
                        image: '/assets/help/gettingStarted/gs_08.png',
                    },
                    {
                        description:
                            'Click on sign in and also you can tick on remember me, Remember me can help you to sign in quickly next time',
                        image: '/assets/help/gettingStarted/gs_09.png',
                    },
                    {
                        description:
                            'If you forgot your password click to forgot password link and change your password',
                        image: '/assets/help/gettingStarted/gs_10.png',
                    },
                    {
                        description:
                            'Once your click on forgot password you will be directed to a page forgot password enter the email address or phone number to send the reset password Instructions.',
                        image: '/assets/help/gettingStarted/gs_11.png',
                    },
                    {
                        description:
                            'A message will be shown in your screen to check you email if have entered email address.',
                        image: '/assets/help/gettingStarted/gs_12.png',
                    },
                    {
                        description:
                            'From the email you received, you will be directed to change password screen. And enter you new password',
                        image: '/assets/help/gettingStarted/gs_13.png',
                    },
                ],
            },
            {
                id: 4,
                title: 'How to log in with phone number?',
                subSections: [
                    {
                        description: 'Click on Sign in button',
                        image: '/assets/help/gettingStarted/gs_01.png',
                    },
                    {
                        description: 'Enter your phone number',
                        image: '/assets/help/gettingStarted/gs_14.png',
                    },
                    {
                        description:
                            'OTP verification code will be sent to the number you have registered. Enter the OTP verification code and click sign in',
                        image: '/assets/help/gettingStarted/gs_15.png',
                    },
                    {
                        description:
                            'If you have still login issue or changed your number please contact us through email we will help you to login. <a class="text-purple-950" href="mailto: futurecareersbridge@gmail.com">futurecareersbridge@gmail.com</a>',
                        image: null,
                    },
                ],
            },
        ],
    },
];

const Index = () => {
    const [selectedSection, setSelectedSection] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const viewPortWidth = useWidth();
    const history = useHistory();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const handleAccordion = (index: number) => {
        if (selectedSection === index) {
            setSelectedSection(null);
            setIsExpanded(false);
        } else {
            setSelectedSection(index);
            setIsExpanded(true);
        }
    };

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div className="mx-10 md:mx-5 md:ml-14 lg:ml-72 lg:pl-3.5 lg:pt-2 md:mt-14 lg:mb-28">
                    <div
                        className="flex mb-5 cursor-pointer"
                        onClick={() => history.push(`/dashBoard/help`)}
                    >
                        <BackArrow size="27" />
                        <p className="text-base ml-2 font-semibold  hover:underline">Back</p>
                    </div>
                    <div className="mb-6 border-b-2 border-purple-75">
                        <div className="text-2xl-2 leading-10 font-manrope font-bold mb-2">
                            Getting Started
                        </div>
                        <div className="text-base font-normal mb-7">
                            Learn how Future Careers BRIDGE works and how to start learning.
                        </div>
                    </div>
                    {topicData.map(section => (
                        <div key={section.id} className="mb-4">
                            <div className="text-xl font-bold font-manrope mb-3">
                                {section.title}
                            </div>
                            {section.sections.map(subSection => (
                                <div
                                    key={subSection.id}
                                    style={{ borderWidth: 1 }}
                                    className={`rounded mb-3 
                            ${
                                selectedSection === subSection.id && isExpanded
                                    ? 'border-purple-50 bg-purple-85'
                                    : 'border-gray-300 bg-transparent'
                            }`}
                                >
                                    <div
                                        className={`px-11 py-8 relative flex items-center text-base cursor-pointer 
                                ${
                                    selectedSection === subSection.id && isExpanded
                                        ? 'font-bold text-purple-950'
                                        : 'font-normal'
                                }`}
                                        onClick={() => handleAccordion(subSection.id)}
                                    >
                                        {subSection.title}
                                        <div className="absolute right-7">
                                            {selectedSection === subSection.id && isExpanded ? (
                                                <UpArrow />
                                            ) : (
                                                <DownArrow />
                                            )}
                                        </div>
                                    </div>
                                    {selectedSection === subSection.id &&
                                        isExpanded &&
                                        subSection.subSections.map((item, subIndex) => (
                                            <div
                                                key={subIndex}
                                                className="flex justify-between items-center gap-10 pl-11 p-6 bg-purple-85 border-t-2 border-purple-75"
                                            >
                                                <div
                                                    className={`${
                                                        item.image ? `w-8/12` : `w-full`
                                                    } text-sm font-normal`}
                                                >
                                                    <JsxParser jsx={item.description} />
                                                </div>
                                                {item.image && (
                                                    <img
                                                        className="max-w-sm h-full object-contain"
                                                        src={item.image}
                                                        alt="FAQ step's image"
                                                    />
                                                )}
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ) : (
                <div className="px-4 mt-4">
                    <div className="flex mb-3 mt-1 cursor-pointer" onClick={() => history.goBack()}>
                        <BackArrow size="27" />
                        <p className="text-base ml-2 font-semibold">Back</p>
                    </div>
                    <div className="mb-6 border-b-2 border-purple-75">
                        <div className="text-xl leading-10 font-manrope font-bold mb-2">
                            Getting Started
                        </div>
                        <div className="text-xs font-normal mb-7">
                            Learn how Future Careers BRIDGE works and how to start learning.
                        </div>
                    </div>
                    {topicData.map(section => (
                        <div key={section.id} className="mb-8">
                            <div className="text-lg font-bold font-manrope mb-3">
                                {section.title}
                            </div>
                            {section.sections.map(subSection => (
                                <div
                                    key={subSection.id}
                                    style={{ borderWidth: 1 }}
                                    className={`rounded mb-3 
                            ${
                                selectedSection === subSection.id && isExpanded
                                    ? 'border-purple-50 bg-purple-85'
                                    : 'border-gray-300 bg-transparent'
                            }`}
                                >
                                    <div
                                        className={`px-11 py-8 w-full relative flex items-center text-xs cursor-pointer 
                                ${
                                    selectedSection === subSection.id && isExpanded
                                        ? 'font-bold text-purple-950'
                                        : 'font-normal'
                                }`}
                                        onClick={() => handleAccordion(subSection.id)}
                                    >
                                        <span className="w-11/12">{subSection.title}</span>
                                        <div className="absolute right-7">
                                            {selectedSection === subSection.id && isExpanded ? (
                                                <UpArrow />
                                            ) : (
                                                <DownArrow />
                                            )}
                                        </div>
                                    </div>
                                    {selectedSection === subSection.id &&
                                        isExpanded &&
                                        subSection.subSections.map((item, subIndex) => (
                                            <div
                                                key={subIndex}
                                                className="flex flex-col justify-between items-center gap-y-4 py-5 px-11 bg-purple-85 border-t-2 border-purple-75"
                                            >
                                                {item.image && (
                                                    <img
                                                        className="w-full h-full object-contain"
                                                        src={item.image}
                                                        alt="FAQ step's image"
                                                    />
                                                )}
                                                <div className={`w-full text-xs font-normal`}>
                                                    <JsxParser jsx={item.description} />
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default Index;
