import React, { ReactElement } from 'react';

interface Props {
    size: number;
    color?: string;
    thickness?: number;
}

export default function CloseIcon({ size, color, thickness }: Props): ReactElement {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.7635 11.9743L23.7115 2.05167C23.908 1.82269 24.0108 1.52815 23.9991 1.22691C23.9874 0.925668 23.8622 0.639912 23.6485 0.426744C23.4348 0.213575 23.1483 0.0886948 22.8463 0.0770592C22.5443 0.0654236 22.249 0.167889 22.0195 0.36398L12.0715 10.2866L2.12345 0.35201C1.89749 0.126622 1.59101 0 1.27145 0C0.95189 0 0.645417 0.126622 0.419452 0.35201C0.193488 0.577398 0.0665421 0.88309 0.0665421 1.20184C0.0665421 1.52058 0.193488 1.82628 0.419452 2.05167L10.3795 11.9743L0.419452 21.8969C0.293834 22.0042 0.19181 22.1363 0.119782 22.2848C0.0477538 22.4333 0.00727762 22.595 0.000894229 22.7599C-0.00548916 22.9247 0.0223574 23.0891 0.0826869 23.2427C0.143016 23.3963 0.234526 23.5358 0.351472 23.6524C0.468418 23.7691 0.608274 23.8603 0.762265 23.9205C0.916255 23.9807 1.08105 24.0085 1.24632 24.0021C1.41158 23.9957 1.57374 23.9554 1.72262 23.8835C1.87149 23.8117 2.00388 23.7099 2.11145 23.5846L12.0715 13.662L22.0195 23.5846C22.249 23.7807 22.5443 23.8832 22.8463 23.8715C23.1483 23.8599 23.4348 23.735 23.6485 23.5219C23.8622 23.3087 23.9874 23.0229 23.9991 22.7217C24.0108 22.4204 23.908 22.1259 23.7115 21.8969L13.7635 11.9743Z"
                fill={color || 'white'}
                stroke={color || 'white'}
                strokeWidth={thickness || 1}
            />
        </svg>
    );
}
