import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import TrackSuccessfullModal from '../../modals/TrackSuccessfullModal';
import {
    getStudentActivityLog,
    getStudentById,
    RESET_STUDENTBYID,
} from '../../../redux/rootActions';
import moment from 'moment';
import ViewSubmissionAnswers from './ViewSubmissionAnswersModal';
import { s3UrlPrefix } from '../../../constants';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroupsView from '../../TagGroupsView';

interface paramsType {
    id: string;
}
interface CustomState {
    successStory: boolean;
}
export default function EditStudent() {
    const params = useParams<paramsType>();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const redirectLink = location.state as CustomState;
    const student = useSelector((state: RootState) => state.students.student);
    const editTrackError = useSelector((state: RootState) => state.tracks.editTrackError);
    const trackUpdateSuccess = useSelector(
        (state: RootState) => state.tracks.trackUpdateSuccessMessage
    );
    const activityLogData = useSelector((state: RootState) => state.webUser.studentActivityLog);
    const [trackUpdateSuccessVisible, setTrackUpdateSuccessVisible] = useState(false);
    const [submissionAnswerModalVisible, setSubmissionAnswerModalVisible] = useState(false);
    const [interestedTags, setInterestedTags] = useState([]);
    const [submissionsData, setSubmissionsData] = useState();
    const [trackErrorVisible, setTrackErrorVisible] = useState(false);
    const challengesForMentor = 10;
    const [tags, setTags] = useState([]);
    const firstName = student?.firstName ? student?.firstName : '';
    const lastName = student?.lastName ? student?.lastName : '';
    const fullName = firstName + ' ' + lastName;
    useEffect(() => {
        if (student?.tags) {
            setTags(
                categorizeTags(student?.tags).filter(
                    tagGroup =>
                        !['interest', 'interests'].includes(tagGroup[0].tagCategory?.toLowerCase())
                )
            );
            setInterestedTags(
                student.tags.filter(
                    tag =>
                        tag.tagCategory?.toLowerCase() === 'interest' ||
                        tag.tagCategory?.toLowerCase() === 'interests'
                )
            );
        }
    }, [student]);

    const handleOnClickSubmission = submissions => {
        setSubmissionAnswerModalVisible(true);
        setSubmissionsData(submissions);
    };

    useEffect(() => {
        dispatch(getStudentById(params.id));
    }, [dispatch, params.id]);

    useEffect(() => {
        dispatch(getStudentActivityLog(true, params.id));
    }, [dispatch, params.id]);
    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    <div className="relative">
                        <>
                            <div className="flex justify-between items-center mb-4 md:mb-6">
                                <p className="text-xl font-semibold text-left">View Student</p>
                                <div
                                    className={`flex h-14 md:h-16 ${
                                        student?.status !== 'DELETED'
                                            ? 'w-1/2 md:w-1/4 lg:pl-8'
                                            : 'w-1/4 md:w-1/6 lg:pl-16'
                                    }`}
                                >
                                    <button
                                        type="submit"
                                        className={`md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm ${
                                            student?.status !== 'DELETED'
                                                ? 'w-full md:w-1/2'
                                                : 'w-full hover:bg-blue-700 hover:text-white'
                                        } my-2 focus:outline-none`}
                                        onClick={() => {
                                            if (redirectLink?.successStory) {
                                                history.push('/admin/students/successStories');
                                            } else {
                                                history.push('/admin/students');
                                            }
                                            dispatch({ type: RESET_STUDENTBYID });
                                        }}
                                    >
                                        OK
                                    </button>
                                    {student?.status !== 'DELETED' && (
                                        <button
                                            type="submit"
                                            className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                            onClick={() => {
                                                history.push({
                                                    pathname: `/admin/students/${student?.id}/edit`,
                                                    state: {
                                                        student: student,
                                                        successStory: redirectLink?.successStory,
                                                    },
                                                });
                                            }}
                                        >
                                            Edit
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6">
                                <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                    <div className="text-lg font-medium mb-6">Student Details</div>
                                    <div className="mb-8 flex flex-row ">
                                        <img
                                            className="rounded-full h-36 w-36"
                                            src={
                                                student?.imageUrl
                                                    ? s3UrlPrefix + student.imageUrl
                                                    : `/assets/user.png`
                                            }
                                            alt="profile"
                                        />
                                        <div
                                            className="flex flex-col ml-6"
                                            style={{
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <p className="font-semibold">{fullName}</p>
                                            <div className="flex justify-start">
                                                {![undefined, null, ''].includes(
                                                    student?.socialMediaUrls[0]?.url
                                                ) && (
                                                    <img
                                                        className="h-8 w-8 cursor-pointer mr-2"
                                                        src={`/assets/linkedin.png`}
                                                        alt="profile"
                                                        onClick={() => {
                                                            window.open(
                                                                student?.socialMediaUrls[0]?.url,
                                                                '_blank'
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {![undefined, null, ''].includes(
                                                    student?.socialMediaUrls[1]?.url
                                                ) && (
                                                    <img
                                                        style={{ background: '#808080' }}
                                                        className="h-8 w-8 cursor-pointer mr-2 rounded"
                                                        src={`/assets/facebookLogo.png`}
                                                        alt="profile"
                                                        onClick={() => {
                                                            window.open(
                                                                student?.socialMediaUrls[1]?.url,
                                                                '_blank'
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {![undefined, null, ''].includes(
                                                    student?.socialMediaUrls[2]?.url
                                                ) && (
                                                    <img
                                                        style={{ background: '#808080' }}
                                                        className="h-8 w-8 cursor-pointer mr-2 rounded"
                                                        src={`/assets/twitterLogo.png`}
                                                        alt="profile"
                                                        onClick={() => {
                                                            window.open(
                                                                student?.socialMediaUrls[2]?.url,
                                                                '_blank'
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 text-gray-700 font-medium leading-149 text-sm md:text-base `}
                                        >
                                            Student ID
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="id"
                                            type="text"
                                            value={student?.id}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Student Name
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={fullName}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Gender
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.gender}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Date of Birth
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.birthDate}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Postal Address
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.contact?.address1}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Hometown
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.contact?.city}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Email
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.emailId}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Contact Number
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.contact?.mobileNo}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            NIC number
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.nic}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Account Status
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.status}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Resume
                                        </label>
                                        <label
                                            onClick={() =>
                                                window.open(s3UrlPrefix + student?.cvLink, '_blank')
                                            }
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700 ml-2 ${
                                                student?.cvLink && 'underline'
                                            }`}
                                        >
                                            {student?.cvLink
                                                ? `${firstName}_${lastName}_Resume`
                                                : 'Not Uploaded'}
                                        </label>
                                    </div>
                                </div>

                                <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                    <div className="flex border-2 flex-col ml-2 justify-items-center">
                                        <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                            <div className="sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                Tags
                                            </div>
                                        </div>
                                        <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                            <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                <TagGroupsView selectedTags={tags} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex border-2 flex-row justify-items-center p-2 pl-4 ml-2 mt-2">
                                        <label
                                            className={`flex items-center w-full block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                        >
                                            Hired through FCB
                                        </label>
                                        <div className="flex text-sm md:text-base items-center w-full">
                                            <input
                                                className="mr-1"
                                                type="radio"
                                                value="true"
                                                id="true"
                                                defaultChecked={student?.hiredByFCB}
                                                name="hiredByFCB"
                                                disabled
                                            />
                                            <label className="mr-4 w-1/4">Yes</label>
                                            <input
                                                className="mr-1"
                                                type="radio"
                                                value="false"
                                                id="false"
                                                name="hiredByFCB"
                                                defaultChecked={!student?.hiredByFCB}
                                                disabled
                                            />
                                            <label className="">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6">
                                <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                    <div className="text-lg font-medium mb-6">
                                        Educational Qualifications
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Secondary Educational level
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.educationStatus}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Stream of study in Advanced Level
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.alStream}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Reason to join Future Careers BRIDGE program
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.reasonForJoin}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Pursuing Higher Education
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.pursuingHigherStudies}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Present Employment Status
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.empStatus}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Company Name
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.companyName}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Higher Education Status
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.higherEduStatus}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Higher Education Institute
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.InstituteName}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    {student?.StudentInstitute?.instCategory1 && (
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="title"
                                                className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                {student?.StudentInstitute?.instCategory1?.name}
                                            </label>
                                            <input
                                                className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                id="name"
                                                type="text"
                                                value={
                                                    student?.StudentInstitute?.instCategory1?.option
                                                }
                                                autoComplete="off"
                                                disabled
                                            />
                                        </div>
                                    )}
                                    {student?.StudentInstitute?.instCategory2 && (
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="title"
                                                className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                {student?.StudentInstitute?.instCategory2?.name}
                                            </label>
                                            <input
                                                className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                id="name"
                                                type="text"
                                                value={
                                                    student?.StudentInstitute?.instCategory2?.option
                                                }
                                                autoComplete="off"
                                                disabled
                                            />
                                        </div>
                                    )}
                                    {student?.StudentInstitute?.instCategory3 && (
                                        <div className="mb-6 w-full md:mb-0 flex my-4">
                                            <label
                                                htmlFor="title"
                                                className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                            >
                                                {student?.StudentInstitute?.instCategory3?.name}
                                            </label>
                                            <input
                                                className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                id="name"
                                                type="text"
                                                value={
                                                    student?.StudentInstitute?.instCategory3?.option
                                                }
                                                autoComplete="off"
                                                disabled
                                            />
                                        </div>
                                    )}
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Institute Address
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.StudentInstitute?.location}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Faculty Name
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.StudentInstitute?.faculty}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Bridge Invitation Code
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.bridgeInvitationCode}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Student ID given by the institute
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={student?.StudentInstitute?.studentId}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6">
                                <div className="col-span-12 md:col-span-5 mb-2">
                                    <div className="text-lg font-medium mb-2">
                                        Areas of Interest
                                    </div>
                                    <div className="flex flex-wrap  mr-20">
                                        {interestedTags?.map(action => (
                                            <div
                                                style={{
                                                    maxHeight: '10px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                                key={action.id}
                                                className={`rounded-full border-2 my-1 mr-4 text-white bg-purple-400 border-purple-400 py-3 px-2 `}
                                            >
                                                {action.tagName}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6">
                                <div className="col-span-12 md:col-span-5 mb-2">
                                    <div className="text-lg font-medium mb-6">Bridge Progress</div>
                                    <table className="border-2 w-full">
                                        <thead className="border-2">
                                            <tr className="border-2">
                                                <th className="border-2 p-2">Track Name</th>
                                                <th className="border-2 p-2">
                                                    Challenges Attempted
                                                </th>
                                                <th className="border-2 p-2">
                                                    Last Attempted Challenge
                                                </th>
                                                <th className="border-2 p-2">
                                                    Last Attempted Date/Time
                                                </th>
                                                <th className="border-2 p-2">
                                                    Average Score for Attempted Challenges(%)
                                                </th>
                                            </tr>
                                        </thead>
                                        {student?.bridgeProgresses?.map(bridgeProgress => (
                                            <tbody key={bridgeProgress?.latestSubmissionDate}>
                                                <tr>
                                                    <td className="border-2 p-2">
                                                        {bridgeProgress?.trackName}
                                                    </td>
                                                    <td className="border-2 p-2 text-center">
                                                        {bridgeProgress?.studentAttemptChallengeCount +
                                                            '/' +
                                                            bridgeProgress?.trackChallengesCount}
                                                    </td>
                                                    <td className="border-2 p-2">
                                                        {bridgeProgress?.lastAttemptChallengeName}
                                                    </td>
                                                    <td className="border-2 p-2 text-center">
                                                        {moment(
                                                            bridgeProgress?.latestSubmissionDate
                                                        ).format('DD/MM/YYYY hh:mm A')}
                                                    </td>
                                                    <td className="border-2 p-2 text-center">
                                                        {bridgeProgress?.averageScorePercentage
                                                            ? Math.round(
                                                                  bridgeProgress?.averageScorePercentage
                                                              )
                                                            : 0}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </div>
                                <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Total Points Earned
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            type="text"
                                            value={
                                                student?.totalScore
                                                    ? Math.round(student?.totalScore * 100)
                                                    : 0
                                            }
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            No. of Challenges remaining to earn a mentor
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={
                                                challengesForMentor -
                                                    student?.completedChallengeCount <
                                                0
                                                    ? 0
                                                    : challengesForMentor -
                                                      student?.completedChallengeCount
                                            }
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                        <label
                                            htmlFor="title"
                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                        >
                                            Assigned Mentor
                                        </label>
                                        <input
                                            className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                            id="name"
                                            type="text"
                                            value={
                                                student?.mentor
                                                    ? student?.mentor?.mentorName
                                                    : 'Not Assigned'
                                            }
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6"
                                style={{ height: '650px', overflow: 'auto' }}
                            >
                                <div className="col-span-12 md:col-span-5 mb-2">
                                    <div className="text-lg font-medium mb-6">
                                        Challenge assignment submissions
                                    </div>
                                    <table className="border-2 w-full">
                                        <thead className="border-2">
                                            <tr className="border-2">
                                                <th className="border-2 p-2">Track Name</th>
                                                <th className="border-2 p-2">Challenge Name</th>
                                                <th className="border-2 p-2">
                                                    Submitted Date/Time
                                                </th>
                                                <th className="border-2 p-2">Submissions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {student?.trackChallengeWiseSubmissions?.map(
                                                (submission, key) => (
                                                    <tr key={`key${key}`}>
                                                        <td className="border-2 p-2">
                                                            {submission?.trackName}
                                                        </td>
                                                        <td className="border-2 p-2">
                                                            {submission?.challengeName}
                                                        </td>
                                                        <td className="border-2 p-2">
                                                            {moment(
                                                                submission?.lastSubmitDate
                                                            ).format('DD/MM/YYYY hh:mm A')}
                                                        </td>
                                                        <td
                                                            className="border-2 p-2 underline cursor-pointer text-blue-700"
                                                            role="presentation"
                                                            onClick={() => {
                                                                handleOnClickSubmission(submission);
                                                            }}
                                                        >
                                                            view
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6">
                                <div className="col-span-12 md:col-span-5 mb-2 md:mb-4">
                                    <div className="text-lg font-medium mb-6">Achievements</div>
                                    <div className="flex items-start">
                                        <table className="border-2 w-1/2 md:mb-4">
                                            <thead className="border-2">
                                                <tr className="border-2">
                                                    <th className="border-2 p-2">
                                                        Certificates Earned
                                                    </th>
                                                </tr>
                                            </thead>
                                            {student?.certificates.length > 0 ? (
                                                student?.certificates?.map((certificate, id) => (
                                                    <tbody key={id}>
                                                        <tr>
                                                            <td
                                                                key={certificate.id}
                                                                className="border-2 p-2"
                                                            >
                                                                {certificate.certificateName}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ))
                                            ) : (
                                                <tbody>
                                                    <tr>
                                                        <td className="border-2 p-2">
                                                            No records exist
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </table>
                                        <table className="border-b-2 border-t-2 border-r-2 w-1/2 md:mb-4">
                                            <thead className="border-b-2 border-t-2 border-r-2">
                                                <tr className="border-b-2 border-t-2 border-r-2">
                                                    <th className="border-b-2 border-t-2 border-r-2 p-2">
                                                        Badges Earned
                                                    </th>
                                                </tr>
                                            </thead>
                                            {student?.badges.length > 0 ? (
                                                student?.badges?.map((badge, id) => (
                                                    <tbody key={id}>
                                                        <tr>
                                                            <td
                                                                key={badge.id}
                                                                className="border-b-2 border-t-2 border-r-2 p-2"
                                                            >
                                                                {badge.badgeName}
                                                            </td>
                                                        </tr>
                                                        <tr></tr>
                                                    </tbody>
                                                ))
                                            ) : (
                                                <tbody>
                                                    <tr>
                                                        <td className="border-b-2 border-t-2 border-r-2 p-2">
                                                            No records exist
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 pb-3 mb-6">
                                <div className="col-span-12 md:col-span-5">
                                    <div className="text-lg font-medium mb-6">
                                        Industry Highlights
                                    </div>
                                    <table className="border-2 w-full">
                                        <thead className="border-2">
                                            <tr className="border-2">
                                                <th className="border-2 p-2">Description</th>
                                                <th className="border-2 p-2">Type</th>
                                                <th className="border-2 p-2">Updated Date/Time</th>
                                                <th className="border-2 p-2">Updated By</th>
                                            </tr>
                                        </thead>
                                        {student?.careerStatus?.map(data => (
                                            <tbody key={data.id}>
                                                <tr>
                                                    <td className="border-2 p-2">
                                                        {data?.message}
                                                    </td>
                                                    <td className="border-2 p-2">{data?.type}</td>
                                                    <td className="border-2 p-2">
                                                        {moment(data?.logUpdatedDate)
                                                            .local()
                                                            .format('YYYY-MM-DD , h:mm:ss a')}
                                                    </td>
                                                    <td className="border-2 p-2">
                                                        {data?.logCreatedBy}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </div>
                            </div>
                            <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 pb-3 mb-6">
                                <div className="col-span-12 md:col-span-5">
                                    <div className="text-lg font-medium mb-6">Activity Log</div>
                                    <div id="table-wrapper">
                                        <div id="table-scroll">
                                            <table className="border-2 w-full">
                                                <thead className="border-2">
                                                    <tr className="border-2">
                                                        <th className="border-2 p-2">Date</th>
                                                        <th className="border-2 p-2">Time</th>
                                                        <th className="border-2 p-2">Activity</th>
                                                    </tr>
                                                </thead>
                                                {activityLogData?.map(data => (
                                                    <tbody key={data.id} className="ml-20">
                                                        <tr>
                                                            <td className="border-2 p-2">
                                                                {data?.activityDate}
                                                            </td>
                                                            <td className="border-2 p-2">
                                                                {data?.activityTime}
                                                            </td>
                                                            <td className="border-2 p-2">
                                                                {data?.userAction}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ))}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
                {/* {trackUpdateSuccess && (
                    <TrackSuccessfullModal
                        modalVisible={trackUpdateSuccessVisible}
                        setModalVisible={setTrackUpdateSuccessVisible}
                    />
                )}
                {editTrackError && (
                    <TrackSuccessfullModal
                        modalVisible={trackErrorVisible}
                        setModalVisible={setTrackErrorVisible}
                    />
                )} */}
                {submissionAnswerModalVisible && (
                    <ViewSubmissionAnswers
                        modalVisible={submissionAnswerModalVisible}
                        setModalVisible={setSubmissionAnswerModalVisible}
                        submissionsData={submissionsData}
                    />
                )}
            </div>
        </div>
    );
}
