import {
    REQUEST_A_MENTOR,
    REQUEST_A_MENTOR_SUCCESS,
    REQUEST_A_MENTOR_FAILED,
    CLEAR_MENTOR_REQUEST_MESSAGES,
} from './actions';

export interface WebMentorsInitialState {
    requestResponce: any;
    isLoading: boolean;
}

const initialState: WebMentorsInitialState = {
    requestResponce: null,
    isLoading: false,
};

const webMentorsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case REQUEST_A_MENTOR:
            return { ...state, isLoading: true, mentorsError: null };
        case REQUEST_A_MENTOR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                requestResponce: action.payload,
            };

        case REQUEST_A_MENTOR_FAILED:
            return { ...state, isLoading: false, mentorsError: action.payload };

        case CLEAR_MENTOR_REQUEST_MESSAGES:
            return {
                ...state,
                requestResponce: null,
                isLoading: false,
            };

        default:
            return state;
    }
};

export default webMentorsReducer;
