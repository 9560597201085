import React, { useState, useMemo, useEffect, useRef } from 'react';
import Tab from './Tab';
import StudentTable from './StudentTable';
import * as RiIcons from 'react-icons/ri';
import RightSideContent from '../../RightSideContent';
import * as HiIcons from 'react-icons/hi';
import * as BsIcons from 'react-icons/bs';
import * as BiIcons from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { getStudents } from '../../../redux/students/actions';
import AssignTagModal from '../../modals/AssignTagModal';
import { StudentType } from '../../../redux/students/reducers';
import BulkRestoreConfirmationModal from '../../modals/BulkRestoreConfirmation';
import BulkDeleteConfirmationModal from '../../modals/BulkDeleteConfirmation';
import _ from 'lodash';
import { getUserProfile } from '../../../utils/storage';
import { COMPANY_ADMIN, UserProfileType } from '../../../redux/authAdmin/reducers';
import { moduleNames, CityFilter } from '../../../constants';
import Loader from '../../../assets/svg/Loader';
import Add from '../../../assets/svg/Add';
import AddFilter from './AddFilter';
import { getInstitutes, getTags, getTracks } from '../../../redux/rootActions';
import { useQuery } from '../../../web/hooks/useQuery';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export const Students = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const searchTermRef = useRef();
    const backToFirstRef = useRef(null);
    const user: UserProfileType | any = getUserProfile();
    const isCompanyAdmin = user?.roles?.[0] === COMPANY_ADMIN;
    const studentData = useSelector((state: RootState) => state.students.studentData);
    const isLoading = useSelector((state: RootState) => state.students.isLoading);
    const filteredStudentData = useSelector(
        (state: RootState) => state.filteredStudent.studentData
    );
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const instituteData = useSelector((state: RootState) => state.institutes.institutesData);
    const trackData = useSelector((state: RootState) => state.tracks.trackData);

    const pageCount = useSelector((state: RootState) => state.students.pageCount);
    const [tags, setTags] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [sidebar, setSidebar] = useState(true);
    const showSidebar = () => setSidebar(!sidebar);
    const [bulkAction, setBulkAction] = useState<string>('Bulk Action');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [searchResultsVisible, setSearchResultsVisible] = useState(false);
    const [selectedStudents, setSlectedStudents] = useState([]);
    const [selectedMainTab, setSelectedMainTab] = useState(1);
    const [clickedStudent, setClickedStudent] = useState<StudentType[]>();
    const [assignTagsModalVisible, setAssignTagsModalVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [restoreBulkConfirmationVisible, setRestoreBulkConfirmationVisible] = useState(false);
    const [deleteBulkConfirmationVisible, setDeleteBulkConfirmationVisible] = useState(false);
    const fetchIdRef = React.useRef(0);

    const [pageSizeS, setPageSizeS] = useState(0);
    const [pageIndexS, setPageIndexS] = useState(0);
    const [tagIds, setTagIds] = useState('');

    const [hometownsSelected, setHometownsSelected] = useState([]);
    const [filterHometowns, setFilterHometowns] = useState([]);

    const [tracksSelected, setTracksSelected] = useState([]);
    const [filterTracks, setFilterTracks] = useState([]);

    const [institutesSelected, setInstitutesSelected] = useState([]);
    const [filterInstitutes, setFilterInstitutes] = useState([]);

    const [joinedDateFromSelected, setJoinedDateFromSelected] = useState('');
    const [joinedDateToSelected, setJoinedDateToSelected] = useState('');

    const [lastActiveDateFromSelected, setLastActiveDateFromSelected] = useState('');
    const [lastActiveDateToSelected, setLastActiveDateToSelected] = useState('');

    const [tagsSelected, setTagsSelected] = useState([]);
    const [filterTags, setFilterTags] = useState([]);

    const [showModal, setShowModal] = React.useState(false);
    const hometownsQS = query.get('hometowns');
    const tracksQS = query.get('tracks');
    const institutesQS = query.get('institutes');
    const joinedDateFromQS = query.get('joinedDateFrom');
    const joinedDateToQS = query.get('joinedDateTo');
    const lastActiveDateFromQS = query.get('lastActiveDateFrom');
    const lastActiveDateToQS = query.get('lastActiveDateTo');
    const tagIdsQS = query.get('tagIds');

    const initialRender = useRef(true);

    const tabStatus =
        selectedTab === 1
            ? 'ACTIVE'
            : selectedTab === 2
            ? 'DEACTIVATED'
            : selectedTab === 3
            ? 'DELETED'
            : '';
    const bulkOptions = [
        { id: 0, val: 'Restore' },
        { id: 1, val: 'Assign tags' },
        { id: 2, val: 'Delete' },
    ];
    useEffect(() => {
        setBulkAction('Bulk Action');
    }, [selectedTab]);
    const studentBulk = {
        listToUpdate: selectedStudents.map(elem => {
            if (bulkAction === 'Assign tags') {
                return {
                    tagId: '',
                    id: elem.id,
                };
            } else {
                return {
                    action: bulkAction === 'Restore' ? 'DEACTIVATED' : 'DELETED',
                    id: elem.id,
                };
            }
        }),
    };

    const applyBulkAction = async () => {
        if (bulkAction === 'Assign tags') {
            setAssignTagsModalVisible(true);
        } else if (bulkAction === 'Restore') {
            setRestoreBulkConfirmationVisible(true);
        } else if (bulkAction === 'Delete') {
            setDeleteBulkConfirmationVisible(true);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: '',
                id: 'actionColumn',
                disableSortBy: true,
                width: 5,
                // eslint-disable-next-line react/display-name
                Cell: (
                    <div className="cursor__pointer">
                        <button
                            value="menu cursor-pointer"
                            className="text-gray-500 rounded cursor-pointer focus:outline-none flex mx-auto px-1"
                        >
                            <BsIcons.BsThreeDotsVertical className="mx-auto" />
                        </button>
                    </div>
                ),
            },
            {
                Header: 'Student ID',
                accessor: 'userId',
            },
            {
                Header: 'Student Name',
                accessor: data => {
                    if (data?.lastName) return data?.firstName + ' ' + data?.lastName;
                    return data?.firstName;
                },
            },
            {
                Header: 'Email',
                accessor: 'emailId',
            },
            {
                Header: 'Contact No',
                accessor: 'contact.mobileNo',
            },
            {
                Header: 'Gender',
                accessor: 'gender',
            },
            {
                Header: 'Date of birth',
                accessor: 'birthDate',
            },
            {
                Header: 'NIC',
                accessor: 'nic',
            },
            {
                Header: 'Hometown',
                accessor: 'contact.city',
            },
            {
                Header: 'Hired through FCB',
                id: 'hiredByFCB',
                accessor: data => {
                    const status = data.hiredByFCB ? 'Yes' : 'No';
                    return status;
                },
            },
            {
                Header: 'Career Status',
                id: 'careerStatus',
                accessor: data => {
                    const output = [];
                    _.map(data.careerStatus, career => {
                        output.push(career.message);
                    });
                    return output.join(', ');
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Last Active',
                accessor: data => {
                    return data?.lastActive != null
                        ? moment(data?.lastActive).local().format('YYYY-MM-DD')
                        : null;
                },
            },
            {
                Header: 'Bridge Invitation Code',
                accessor: 'bridgeInvitationCode',
            },
            {
                Header: 'Institute',
                accessor: 'InstituteName',
            },
            {
                Header: 'Category 1',
                id: 'instituteCategories.category1.name',
                accessor: data => {
                    const name = data?.instituteCategories?.category1?.name;
                    const option = data?.instituteCategories?.category1?.option;
                    if (name && option) return name + ' : ' + option;
                },
            },
            {
                Header: 'Category 2',
                id: 'instituteCategories.category2.name',
                accessor: data => {
                    const name = data?.instituteCategories?.category2?.name;
                    const option = data?.instituteCategories?.category2?.option;
                    if (name && option) return name + ' : ' + option;
                },
            },
            {
                Header: 'Category 3',
                id: 'instituteCategories.category3.name',
                accessor: data => {
                    const name = data?.instituteCategories?.category3?.name;
                    const option = data?.instituteCategories?.category3?.option;
                    if (name && option) return name + ' : ' + option;
                },
            },
            {
                Header: 'Registration Number',
                accessor: 'StudentInstitute.studentId',
            },
            {
                Header: 'Institute Location',
                accessor: 'StudentInstitute.location',
            },
            {
                Header: 'Address',
                accessor: 'contact.address1',
            },
            {
                Header: 'No. of Tracks Attempted',
                accessor: 'numOfAttemptTracks',
            },
            {
                Header: 'No. of Challenges Completed',
                accessor: 'numOfAttemptChallenges',
            },
            {
                Header: 'No. of Badges Earned',
                accessor: 'numOfBadges',
            },
            {
                Header: 'Certificates Earned',
                id: 'certificates',
                accessor: 'numOfCertificates',
            },
            {
                Header: 'Total Points',
                accessor: data => {
                    return Math.round(data?.totalScore);
                },
            },
            {
                Header: 'Average Points',
                accessor: data => {
                    return Math.round(data?.averageScore);
                },
            },
            {
                Header: 'Mentor Name',
                accessor: 'mentor.mentorName',
            },
            {
                Header: 'Joined Date',
                accessor: data => {
                    return data?.joinedDate != null
                        ? moment(data?.joinedDate).local().format('YYYY-MM-DD')
                        : null;
                },
            },
        ],
        []
    );

    const fetchData = React.useCallback(
        ({ pageSize, pageIndex }) => {
            const fetchId = ++fetchIdRef.current;
            setPageSizeS(pageSize);
            setPageIndexS(pageIndex);
            const tagIdsT = tags?.map(tagss => tagss.id).toString();
            setTagIds(tagIdsT);
            if (fetchId === fetchIdRef.current) {
                dispatch(
                    getStudents(
                        pageIndex,
                        pageSize,
                        tabStatus,
                        tagIdsT || tagIdsQS?.split(',').toString(),
                        searchTermRef.current,
                        hometownsSelected.toString() || hometownsQS?.split(',').toString(),
                        tracksSelected.toString() || tracksQS?.split(',').toString(),
                        institutesSelected.toString() || institutesQS?.split(',').toString(),
                        joinedDateFromSelected.toString() ||
                            joinedDateFromQS?.split(',').toString(),
                        joinedDateToSelected.toString() || joinedDateToQS?.split(',').toString(),
                        lastActiveDateFromSelected.toString() ||
                            lastActiveDateFromQS?.split(',').toString(),
                        lastActiveDateToSelected.toString() ||
                            lastActiveDateToQS?.split(',').toString()
                    )
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            dispatch,
            tabStatus,
            tags,
            tagIdsQS,
            hometownsQS,
            tracksQS,
            institutesQS,
            joinedDateFromQS,
            joinedDateToQS,
            lastActiveDateFromQS,
            lastActiveDateToQS,
        ]
    );

    const searchByNameOrId = async () => {
        await backToFirstRef?.current?.goToFirst();
        dispatch(
            getStudents(
                0,
                pageSizeS,
                tabStatus,
                tagIdsQS?.split(',').toString(),
                searchTerm,
                hometownsSelected.toString(),
                tracksSelected.toString(),
                institutesSelected.toString(),
                joinedDateFromSelected.toString(),
                joinedDateToSelected.toString(),
                lastActiveDateFromSelected.toString(),
                lastActiveDateToSelected.toString()
            )
        );
    };

    const searchInputHandler = e => {
        searchTermRef.current = e.target.value;
        setSearchTerm(e.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            searchByNameOrId();
        }
    };

    const onRequestClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (hometownsQS) {
            setHometownsSelected(hometownsQS.split(','));
        }
        if (tracksQS) {
            setTracksSelected(tracksQS.split(','));
        }
        if (institutesQS) {
            setInstitutesSelected(institutesQS.split(','));
        }
        if (tagIdsQS) {
            setTagsSelected(tagIdsQS.split(','));
        }
        if (joinedDateFromQS) {
            setJoinedDateFromSelected(joinedDateFromQS);
        }
        if (joinedDateToQS) {
            setJoinedDateToSelected(joinedDateToQS);
        }
        if (lastActiveDateFromQS) {
            setLastActiveDateFromSelected(lastActiveDateFromQS);
        }
        if (lastActiveDateToQS) {
            setLastActiveDateToSelected(lastActiveDateToQS);
        }
        dispatch(getTracks(true, 0, 100));
        dispatch(getInstitutes(0, 100));
        dispatch(getTags(true, moduleNames.Student));
    }, []);

    useEffect(() => {
        if (CityFilter.length > 0) {
            const newHometownsArr = CityFilter.map(item => {
                return { name: item.val, id: item.id };
            });
            setFilterHometowns(newHometownsArr);
        } else {
            setFilterHometowns([]);
        }
        if (trackData.length > 0) {
            const newTracksArr = trackData.map(item => {
                return { name: item.trackName, id: item.id };
            });
            setFilterTracks(newTracksArr);
        } else {
            setFilterTracks([]);
        }
        if (tagData.length > 0) {
            const newTagsArr = tagData.map(item => {
                return { name: item.tagName, category: item.tagCategory, id: item.id };
            });
            setFilterTags(newTagsArr);
        } else {
            setFilterTags([]);
        }
        if (instituteData.length > 0) {
            const newTagsArr = instituteData.map(item => {
                return { name: item.instName, id: item.id };
            });
            setFilterInstitutes(newTagsArr);
        } else {
            setFilterInstitutes([]);
        }
    }, [CityFilter, instituteData, trackData, tagData]);

    useEffect(() => {
        const goBack = async () => {
            backToFirstRef?.current?.goToFirst();
        };
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            goBack().then(() => {
                history.replace({
                    search: `?hometowns=${hometownsSelected.toString()}&tracks=${tracksSelected.toString()}&institutes=${institutesSelected.toString()}&joinedDateFrom=${joinedDateFromSelected.toString()}&joinedDateTo=${joinedDateToSelected.toString()}&lastActiveDateFrom=${lastActiveDateFromSelected.toString()}&lastActiveDateTo=${lastActiveDateToSelected.toString()}&tagIds=${tagsSelected.toString()}`,
                });
                dispatch(
                    getStudents(
                        pageIndexS,
                        pageSizeS,
                        tabStatus,
                        tagsSelected.toString(),
                        searchTerm,
                        hometownsSelected.toString(),
                        tracksSelected.toString(),
                        institutesSelected.toString(),
                        joinedDateFromSelected.toString(),
                        joinedDateToSelected.toString(),
                        lastActiveDateFromSelected.toString(),
                        lastActiveDateToSelected.toString()
                    )
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        tagsSelected,
        hometownsSelected,
        tracksSelected,
        institutesSelected,
        joinedDateFromSelected,
        joinedDateToSelected,
        lastActiveDateFromSelected,
        lastActiveDateToSelected,
    ]);

    useEffect(() => {
        if (searchTerm.length === 0) {
            dispatch(
                getStudents(
                    pageIndexS,
                    pageSizeS,
                    tabStatus,
                    tagsSelected.toString(),
                    searchTerm,
                    hometownsSelected.toString(),
                    tracksSelected.toString(),
                    institutesSelected.toString(),
                    joinedDateFromSelected.toString(),
                    joinedDateToSelected.toString(),
                    lastActiveDateFromSelected.toString(),
                    lastActiveDateToSelected.toString()
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    const openFilter = () => {
        setShowModal(true);
    };

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <Tab selectedTab={selectedMainTab} setSelectedTab={setSelectedMainTab} />
                <div className="flex w-full">
                    <div
                        style={{
                            width: sidebar === false ? '100%' : '75%',
                        }}
                    >
                        <div className="flex h-12 md:h-14 justify-between md:justify-start">
                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex">
                                <input
                                    className={`rounded-sm w-full h-full text-gray-500 font-medium focus:outline-none p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                    id="id"
                                    type="text"
                                    placeholder="Search by ID or Name"
                                    onChange={e => searchInputHandler(e)}
                                    autoComplete="off"
                                    onKeyDown={handleKeyDown}
                                />
                                <button
                                    className={`ml-2 px-5 border-2 font-medium text-sm rounded-md focus:outline-none ${
                                        searchTerm.length > 0
                                            ? ' text-white border-blue-700 bg-blue-700 cursor-pointer'
                                            : 'cursor-not-allowed border-gray-300'
                                    }`}
                                    disabled={searchTerm.length === 0}
                                    onClick={searchByNameOrId}
                                >
                                    <BsIcons.BsSearch
                                        style={searchTerm.length > 0 && { color: '#ffffff' }}
                                    />
                                </button>
                            </div>

                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex">
                                <div
                                    className={`rounded-md flex relative items-center focus:outline-none w-3/4 md:w-3/4 lg:w-3/4 xl:w-2/3 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className={`flex justify-between w-full md:p-2 ${
                                            (isCompanyAdmin && selectedTab === 2) ||
                                            (isCompanyAdmin && selectedTab === 3) ||
                                            selectedStudents.length <= 1
                                                ? 'cursor-not-allowed'
                                                : 'cursor-pointer'
                                        }`}
                                        onClick={() =>
                                            selectedStudents.length > 1
                                                ? setDropdownVisible(true)
                                                : null
                                        }
                                    >
                                        <div className="text-gray-700 font-medium pl-2">
                                            {bulkAction}
                                        </div>
                                        <div className="">
                                            <div className="flex flex-col">
                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                <HiIcons.HiChevronDown className="mr-2" />
                                            </div>
                                        </div>
                                    </div>
                                    {dropdownVisible &&
                                    !(isCompanyAdmin && selectedTab === 2) &&
                                    !(isCompanyAdmin && selectedTab === 3) ? (
                                        <ul
                                            className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setDropdownVisible(false)}
                                        >
                                            {bulkOptions
                                                .filter(option => {
                                                    if (selectedTab === 4 || selectedTab === 1) {
                                                        return option.val === 'Assign tags';
                                                    } else if (
                                                        selectedTab === 2 &&
                                                        !isCompanyAdmin
                                                    ) {
                                                        return option.val !== 'Restore';
                                                    } else if (
                                                        selectedTab === 3 &&
                                                        !isCompanyAdmin
                                                    ) {
                                                        return option.val === 'Restore';
                                                    } else {
                                                        return null;
                                                    }
                                                })
                                                .map(action => (
                                                    <div
                                                        className="p-2 hover:bg-gray-200 "
                                                        key={action.id}
                                                        onClick={() => {
                                                            setBulkAction(action.val);
                                                            setDropdownVisible(false);
                                                        }}
                                                    >
                                                        {action.val}
                                                    </div>
                                                ))}
                                        </ul>
                                    ) : null}
                                </div>
                                <button
                                    className={`ml-2 px-2 border-2 ${
                                        bulkAction === 'Bulk Action'
                                            ? 'text-gray-400 border-gray-300'
                                            : 'text-white border-blue-700 bg-blue-700'
                                    } font-medium text-sm rounded-md focus:outline-none  hover:border-blue-700 hover:text-white hover:bg-blue-700`}
                                    onClick={applyBulkAction}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                        <div className="flex h-12 md:h-14 justify-between md:justify-start align-center p-5">
                            <BiIcons.BiFilterAlt
                                style={{
                                    color: 'rgba(29, 78, 216)',
                                    height: '20px',
                                    width: '20px',
                                    marginRight: '10px',
                                    alignSelf: 'baseline',
                                }}
                            />
                            <div
                                className="sm:text-sm md:text-base text-gray-400 pr-2 cursor-pointer"
                                onClick={openFilter}
                            >
                                Filter
                            </div>
                            <button onClick={openFilter}>
                                <Add size={25} color={'rgba(29, 78, 216)'} />
                            </button>
                            <AddFilter
                                setHometownsSelected={setHometownsSelected}
                                hometownsSelected={hometownsSelected}
                                setTracksSelected={setTracksSelected}
                                tracksSelected={tracksSelected}
                                setInstitutesSelected={setInstitutesSelected}
                                institutesSelected={institutesSelected}
                                joinedDateFromSelected={joinedDateFromSelected}
                                setJoinedDateFromSelected={setJoinedDateFromSelected}
                                joinedDateToSelected={joinedDateToSelected}
                                setJoinedDateToSelected={setJoinedDateToSelected}
                                lastActiveDateFromSelected={lastActiveDateFromSelected}
                                setLastActiveDateFromSelected={setLastActiveDateFromSelected}
                                lastActiveDateToSelected={lastActiveDateToSelected}
                                setLastActiveDateToSelected={setLastActiveDateToSelected}
                                isSelected={showModal}
                                onRequestClose={onRequestClose}
                                filterHometowns={filterHometowns}
                                filterTracks={filterTracks}
                                filterInstitutes={filterInstitutes}
                                setTagsSelected={setTagsSelected}
                                tagsSelected={tagsSelected}
                                filterTags={filterTags}
                            />
                            {hometownsSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Hometown filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setHometownsSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                            {tracksSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Tracks filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setTracksSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                            {institutesSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Institutes filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setInstitutesSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                            {(joinedDateFromSelected || joinedDateToSelected) && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Joined Date filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => {
                                            setJoinedDateFromSelected('');
                                            setJoinedDateToSelected('');
                                        }}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                            {(lastActiveDateFromSelected || lastActiveDateToSelected) && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 pr-2 ml-2 cursor-pointer">
                                        Last Active Date filter
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => {
                                            setLastActiveDateFromSelected('');
                                            setLastActiveDateToSelected('');
                                        }}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                            {tagsSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 ml-2 mr-1">
                                        Tags
                                    </div>
                                    <div className="rounded-full text-base mr-2 h-6 text-blue-800">
                                        {tagsSelected.length}
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setTagsSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        {isLoading && (
                            <div className=" w-full h-98  flex items-center">
                                <Loader />
                            </div>
                        )}
                        <div className={`${isLoading && 'hidden'}`}>
                            <StudentTable
                                columns={columns}
                                data={
                                    selectedMainTab !== 1
                                        ? filteredStudentData
                                        : clickedStudent?.length > 0
                                        ? clickedStudent
                                        : studentData
                                }
                                fetchData={fetchData}
                                pageCount={pageCount}
                                setSlectedTracks={setSlectedStudents}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                backToFirstRef={backToFirstRef}
                            />
                        </div>
                    </div>
                    <div
                        className={`relative border-l-2 ${
                            sidebar === false ? '' : 'right-auto w-1/4'
                        }`}
                    >
                        {sidebar === false && (
                            <div className=" ">
                                <RiIcons.RiArrowDropLeftLine
                                    style={{
                                        borderRadius: 50,
                                        borderWidth: 1,
                                        borderColor: 'rgba(0,0,0,0.2)',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        height: '25px',
                                        width: '25px',
                                        top: '100px',
                                        left: '-13px',
                                        backgroundColor: 'white',
                                    }}
                                    onClick={showSidebar}
                                />
                            </div>
                        )}

                        {sidebar === true && (
                            <div className="h-full">
                                <RiIcons.RiArrowDropRightLine
                                    style={{
                                        borderRadius: 50,
                                        borderWidth: 1,
                                        borderColor: 'rgba(0,0,0,0.2)',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        height: '25px',
                                        width: '25px',
                                        top: '100px',
                                        left: '-13px',
                                        backgroundColor: 'white',
                                    }}
                                    onClick={showSidebar}
                                />
                                <div className="h-96 ml-2 w-full mt-10">
                                    <RightSideContent
                                        tags={tags}
                                        setTags={setTags}
                                        moduleName={moduleNames.Student}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {restoreBulkConfirmationVisible ? (
                <BulkRestoreConfirmationModal
                    modalVisible={restoreBulkConfirmationVisible}
                    setModalVisible={setRestoreBulkConfirmationVisible}
                    students={studentBulk}
                    tabStatus={tabStatus}
                />
            ) : null}
            {deleteBulkConfirmationVisible ? (
                <BulkDeleteConfirmationModal
                    modalVisible={deleteBulkConfirmationVisible}
                    setModalVisible={setDeleteBulkConfirmationVisible}
                    students={studentBulk}
                    tabStatus={tabStatus}
                />
            ) : null}
            {assignTagsModalVisible ? (
                <AssignTagModal
                    modalVisible={assignTagsModalVisible}
                    setModalVisible={setAssignTagsModalVisible}
                    tabStatus={tabStatus}
                    selectedStudents={selectedStudents}
                    moduleName={moduleNames.Student}
                />
            ) : null}
        </div>
    );
};
