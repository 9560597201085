import React, { useEffect, useState } from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as HiIcons from 'react-icons/hi';
import { s3UrlPrefix } from '../../../../constants';
import {
    getLeaderboardData,
    getTracks,
    getCurrentStudentRank,
} from '../../../../redux/rootActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import useWidth from '../../../hooks/useWidth';
import styled from 'styled-components';
import { USER_PROFILE } from '../../../utils/storageWeb';

const HorizontalScrollableLeaderboard = styled.div``;

const Leaderboard = () => {
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const studentRank = useSelector((state: RootState) => state.dashboard.studentRank);
    const student = useSelector((state: RootState) => state.students.student);
    const tracks = useSelector((state: RootState) => state.tracks.trackData);
    const leaderboard = useSelector((state: RootState) => state.dashboard.leaderboard);
    const pageCount = useSelector((state: RootState) => state.dashboard.pageCount);

    const [trackDropdownVisible, setTrackDropdownVisible] = useState(false);
    const [trackFilterAreaAction, setTrackFilterAction] = useState<string>('Select');
    const [trackFilterAreaActionId, setTrackFilterActionId] = useState<any>(null);

    // current student leaderboard data
    const [currentStudent, setCurrentStudent] = useState<any>();
    const viewPortWidth = useWidth();
    // pagination states
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [postPerPage, setPostPerPage] = useState<any>(10);
    const [pageSizesVisible, setpageSizesVisible] = useState(false);
    const [goto, setGoto] = useState<any>();
    const pageTrayMaker = () => {
        const Arr = Array.from({ length: pageCount }, (_, i) => i + 1);
        if (pageCount <= 6) {
            return Arr;
        } else if ([pageCount, pageCount - 1, pageCount - 2].includes(currentPage)) {
            return Arr.filter(number => number > pageCount - 6);
        } else if ([1, 2].includes(currentPage)) {
            return Arr.filter(number => number <= 6);
        } else {
            return Arr.filter(number => currentPage - 3 < number && number < currentPage + 4);
        }
    };
    const pageTray = pageTrayMaker();

    // Make Goto button trigger on Enter
    const input = document?.getElementById('goto');
    input?.addEventListener('keypress', function (event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById('gotoButton').click();
        }
    });

    useEffect(() => {
        setCurrentPage(1);
    }, [trackFilterAreaActionId, postPerPage]);

    useEffect(() => {
        setCurrentStudent(leaderboard?.filter(data => data?.studentId === student?.userId));
    }, [leaderboard]);

    useEffect(() => {
        dispatch(getTracks(false, null, 100, 'PUBLISHED'));
        dispatch(getCurrentStudentRank(profile?.userId, trackFilterAreaActionId));
    }, [trackFilterAreaActionId]);

    useEffect(() => {
        if (['Select', 'All'].includes(trackFilterAreaAction)) {
            dispatch(getLeaderboardData(null, currentPage - 1, postPerPage));
        } else {
            dispatch(getLeaderboardData(trackFilterAreaActionId, currentPage - 1, postPerPage));
        }
    }, [trackFilterAreaAction, currentPage, postPerPage, trackFilterAreaActionId]);

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div className="mx-10 md:mx-5 md:ml-14 lg:ml-72 lg:pl-3.5 lg:pt-2 mt-6">
                    <div>
                        <div className="font-bold font-manrope text-2xl-2 mb-2.5 ">Leaderboard</div>
                        <div className=" pb-1 flex flex-col">
                            <p className="">
                                Progress of the active students working on BRIDGE Challenges will be
                                shown below.
                            </p>
                            <p className="">
                                The ranking is calculated considering the total score the student
                                have earned and then by the number of challenges the student has
                                attempted.
                            </p>
                        </div>
                        {currentStudent?.map(data => data.score)[0] === 0 ? (
                            <div
                                className="rounded mb-6 py-4 text-white pl-8 pr-14 xl:pr-64"
                                style={{
                                    background:
                                        'linear-gradient(93.16deg, #6E41E2 1.57%, #0C247A 98.41%)',
                                }}
                            >
                                <div className="font-bold font-manrope text-2xl-2 mb-3 ">
                                    Do you need your profile to shine on our Leaderboard?
                                </div>
                                <p className="">
                                    While Peer the leaderboard can provide valuable support for
                                    students at critical points in their student life, it offers a
                                    host of other benefits for both knowledge and experience.
                                    Leaderboard is a way of learning and allowing participants to
                                    develop transferable skills that will help them during their
                                    time at university and beyond.
                                </p>
                            </div>
                        ) : null}
                    </div>

                    <div className="flex flex-row mb-4 text-sm">
                        <div className="flex flex-col my-1  w-full md:w-1/2">
                            <label className="flex flex-row my-1 font-manrope" htmlFor="tab_1">
                                Choose a Track
                            </label>
                            <div className="flex relative cursor-pointer items-center focus:outline-none h-12 md:p-2 focus:border-blue-900 inputFieldThickness">
                                <div
                                    className="flex justify-between w-full"
                                    onClick={() => setTrackDropdownVisible(true)}
                                >
                                    <div
                                        className={`pl-2 ${
                                            trackFilterAreaAction !== 'Select'
                                                ? 'text-gray-700'
                                                : 'text-gray-400'
                                        }`}
                                    >
                                        {trackFilterAreaAction}
                                    </div>
                                    <div className="">
                                        <div className="flex flex-row">
                                            <div className="flex flex-col">
                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                <HiIcons.HiChevronDown className="mr-2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {trackDropdownVisible && (
                                    <ul
                                        className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        role="menu"
                                        onMouseLeave={() => setTrackDropdownVisible(false)}
                                    >
                                        {tracks.map(action => (
                                            <div
                                                className="p-2 pl-2 hover:bg-gray-200 "
                                                key={action.id}
                                                onClick={() => {
                                                    setTrackFilterAction(action.trackName);
                                                    setTrackFilterActionId(action.id);
                                                    setTrackDropdownVisible(false);
                                                }}
                                            >
                                                {action.trackName}
                                            </div>
                                        ))}
                                        <div
                                            className="p-2 pl-2 hover:bg-gray-200 "
                                            onClick={() => {
                                                setTrackFilterAction('All');
                                                setTrackFilterActionId(null);
                                                setTrackDropdownVisible(false);
                                            }}
                                        >
                                            All
                                        </div>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="font-bold text-xl font-manrope mb-2">Your Rank</div>
                        <table
                            style={{ borderCollapse: 'separate', borderSpacing: ' 0 1em' }}
                            className=" w-full"
                        >
                            <thead>
                                <tr className="text-sm">
                                    <th className="p-2 text-center">Rank</th>
                                    <th className="p-2 text-center">Student ID</th>
                                    <th className="p-2 text-center">Gender</th>
                                    <th className="p-2 text-center">Hometown</th>
                                    <th className="p-2 text-center">Challenges Attempted</th>
                                    <th className="p-2 text-center">Task Attempts</th>
                                    <th className="p-2 text-center">Total Score</th>
                                    <th className=""></th>
                                </tr>
                            </thead>
                            {studentRank ? (
                                <tbody
                                    className="border-2 text-sm lg:text-base cursor-pointer"
                                    onClick={() =>
                                        window.open(
                                            `${window.location.origin}/public-profile/${profile?.userId}`,
                                            '_blank'
                                        )
                                    }
                                >
                                    <tr className="mb-4 py-6 border-2 border-gray-200 bg-purple-85 rounded">
                                        <td className=" py-3 ">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                                className="flex flex-row"
                                            >
                                                <div
                                                    style={{
                                                        borderRadius: '100%',
                                                    }}
                                                    className={`flex items-center justify-center border-2 ${
                                                        studentRank.rank.toString().length > 4
                                                            ? `w-10 h-10 text-xxs`
                                                            : studentRank.rank.toString().length > 3
                                                            ? `w-9 h-9 text-xxs`
                                                            : studentRank.rank.toString().length > 2
                                                            ? `w-8 h-8 text-xs`
                                                            : `w-8 h-8 text-sm`
                                                    } border-yellow-400`}
                                                >
                                                    {studentRank.rank}
                                                </div>
                                            </div>
                                        </td>
                                        <td className=" py-3 w-64">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'left',
                                                }}
                                                className="flex flex-row ml-20"
                                            >
                                                <img
                                                    className="w-8 h-8 rounded-full mr-2"
                                                    src={`${
                                                        !studentRank.imageUrl
                                                            ? '/assets/user.png'
                                                            : studentRank?.imageUrl.includes(
                                                                  'webUsers'
                                                              )
                                                            ? s3UrlPrefix + studentRank?.imageUrl
                                                            : studentRank?.imageUrl
                                                    }`}
                                                    alt="profile"
                                                />
                                                <div className="flex items-center">
                                                    {studentRank.studentId}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            {studentRank.gender
                                                ? studentRank.gender
                                                : 'NOT SPECIFIED	'}
                                        </td>
                                        <td className=" w-64 overflow-hidden text-center">
                                            {studentRank.hometown}
                                        </td>
                                        <td className="text-center">
                                            {studentRank.completedChallengeCount}
                                        </td>
                                        <td className="text-center">{studentRank.attempts}</td>
                                        <td className="text-purple-950 font-bold ">
                                            <div className="text-center text-xl">
                                                {`${Math.round(studentRank?.totalScore * 100)} `}
                                            </div>
                                        </td>
                                        <td className="text-purple-950 font-bold">
                                            <div className="flex flex-row">
                                                {studentRank.studentId === student?.userId ? (
                                                    <div className="text-gray-500 text-sm">you</div>
                                                ) : null}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tr>
                                    <td className="text-center" colSpan={7}>
                                        Start working on the challenges now to see your rank on the
                                        leaderboard.
                                    </td>
                                </tr>
                            )}
                        </table>
                    </div>

                    <div>
                        <div className="font-bold text-xl font-manrope mb-2">Students</div>
                        <table
                            style={{ borderCollapse: 'separate', borderSpacing: ' 0 1em' }}
                            className=" w-full"
                        >
                            <thead>
                                <tr className="text-sm">
                                    <th className="p-2 text-center">Rank</th>
                                    <th className="p-2 text-center">Student ID</th>
                                    <th className="p-2 text-center">Gender</th>
                                    <th className="p-2 text-center">Hometown</th>
                                    <th className="p-2 text-center">Challenges Attempted</th>
                                    <th className="p-2 text-center">Task Attempts</th>
                                    <th className="p-2 text-center">Total Score</th>
                                    <th className=""></th>
                                </tr>
                            </thead>
                            {leaderboard?.map((leaderboard1, index) => (
                                <tbody
                                    key={leaderboard1.studentId}
                                    className="border-2 text-sm lg:text-base"
                                >
                                    <tr
                                        className="mb-4 py-6 rounded  border-2 border-gray-200 cursor-pointer"
                                        onClick={() => {
                                            window.open(
                                                `${window.location.origin}/public-profile/${leaderboard1.studentId}`,
                                                '_blank'
                                            );
                                        }}
                                    >
                                        <td className=" py-3 ">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                                className="flex flex-row"
                                            >
                                                <div
                                                    style={{
                                                        borderRadius: '100%',
                                                    }}
                                                    className={`flex items-center justify-center border-2 ${
                                                        (
                                                            (currentPage - 1) * postPerPage +
                                                            index +
                                                            1
                                                        ).toString().length > 4
                                                            ? `w-10 h-10 text-xxs`
                                                            : (
                                                                  (currentPage - 1) * postPerPage +
                                                                  index +
                                                                  1
                                                              ).toString().length > 3
                                                            ? `w-9 h-9 text-xxs`
                                                            : (
                                                                  (currentPage - 1) * postPerPage +
                                                                  index +
                                                                  1
                                                              ).toString().length > 2
                                                            ? `w-8 h-8 text-xs`
                                                            : `w-8 h-8 text-sm`
                                                    } border-yellow-400`}
                                                >
                                                    {(currentPage - 1) * postPerPage + index + 1}
                                                </div>
                                            </div>
                                        </td>
                                        <td className=" py-3 w-64">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'left',
                                                }}
                                                className="flex flex-row ml-20"
                                            >
                                                <img
                                                    className="w-8 h-8 rounded-full mr-2"
                                                    src={`${
                                                        !leaderboard1.imageUrl ||
                                                        !leaderboard1.enablePublicProfile
                                                            ? '/assets/user.png'
                                                            : leaderboard1?.imageUrl.includes(
                                                                  'webUsers'
                                                              )
                                                            ? s3UrlPrefix + leaderboard1.imageUrl
                                                            : leaderboard1.imageUrl
                                                    }`}
                                                    alt="profile"
                                                />
                                                <div className="flex items-center">
                                                    {leaderboard1.studentId}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            {leaderboard1.gender
                                                ? leaderboard1.gender
                                                : 'NOT SPECIFIED	'}
                                        </td>
                                        <td className="w-64 overflow-hidden text-center">
                                            {leaderboard1.hometown}
                                        </td>
                                        <td className="text-center">
                                            {leaderboard1.completedChallengeCount}
                                        </td>
                                        <td className="text-center">{leaderboard1.attempts}</td>
                                        <td className="text-purple-950 font-bold ">
                                            <div className="text-center text-xl">
                                                {`${Math.round(leaderboard1?.totalScore * 100)} `}
                                            </div>
                                        </td>
                                        <td className="text-purple-950 font-bold">
                                            <div className="flex flex-row">
                                                {leaderboard1.studentId === student?.userId ? (
                                                    <div className="text-gray-500 text-sm">you</div>
                                                ) : null}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>

                    <div className={`my-8 text-sm w-full`}>
                        <div className="items-center bg-purple-85 rounded-md flex justify-between py-6 pl-4">
                            <div className="relative text-purple-700 flex flex-row">
                                {pageSizesVisible && (
                                    <ul
                                        className="absolute w-full cursor-pointer pl-0 bottom-8 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        role="menu"
                                        onMouseLeave={() => setpageSizesVisible(false)}
                                    >
                                        {[100, 50, 25, 10].map(action => (
                                            <div
                                                className="p-2 pl-2 hover:bg-gray-200 "
                                                key={action}
                                                onClick={() => {
                                                    setPostPerPage(action);
                                                    setpageSizesVisible(false);
                                                }}
                                            >
                                                {action}
                                            </div>
                                        ))}
                                    </ul>
                                )}
                                <div className="font-bold text-base  ">
                                    Show records {postPerPage}
                                </div>
                                <HiIcons.HiChevronUp
                                    className="ml-2 mt-1 text-lg border-2 border-purple-700 rounded cursor-pointer"
                                    onClick={() => setpageSizesVisible(true)}
                                />
                            </div>
                            <div className="flex justify-between items-center">
                                <div
                                    onClick={() => {
                                        if (currentPage !== 1) {
                                            setCurrentPage(currentPage - 1);
                                        }
                                    }}
                                    className={`text-purple-950 font-semibold cursor-pointer mr-1 ${
                                        currentPage === 1 ? 'invisible' : ''
                                    }`}
                                >
                                    Previous
                                </div>
                                {pageTray.map((number, index) => (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            setCurrentPage(number);
                                        }}
                                        className={`mx-2 items-center rounded-md border-2 border-purple-950  font-semibold w-8 h-8 flex justify-center cursor-pointer ${
                                            number === currentPage
                                                ? 'text-white bg-purple-950'
                                                : 'text-purple-950 '
                                        }`}
                                    >
                                        {number}
                                    </div>
                                ))}
                                <div
                                    onClick={() => {
                                        if (currentPage !== pageCount) {
                                            setCurrentPage(currentPage + 1);
                                        }
                                    }}
                                    className={`text-purple-950 font-semibold cursor-pointer ml-1 ${
                                        currentPage === pageCount ? 'invisible' : ''
                                    }`}
                                >
                                    Next
                                </div>
                            </div>
                            <div className="items-center flex flex-row text-purple-700 mr-7">
                                <div
                                    id="gotoButton"
                                    className="font-bold bg-gray-200 border-purple-400 border mr-1 rounded p-1 cursor-pointer"
                                    onClick={() => {
                                        if (goto) {
                                            setCurrentPage(parseInt(goto));
                                        }
                                    }}
                                >
                                    Go
                                </div>
                                <b>to</b>
                                <input
                                    className="border border-purple-400 font-bold p-1 m-2 w-14 focus:outline-none rounded"
                                    value={goto}
                                    id="goto"
                                    type="number"
                                    onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
                                    onChange={e => {
                                        setGoto(e.target.value);
                                    }}
                                />
                                <b>of {pageCount}</b>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mt-6">
                    <div className="px-4">
                        <div>
                            <div className="font-bold font-manrope text-lg mb-2.5 ">
                                Leaderboard
                            </div>
                            <div className=" pb-1 flex flex-col">
                                <p className="text-xs">
                                    Progress of the active students working on BRIDGE Challenges
                                    will be shown below.
                                </p>
                                <p className="text-xs">
                                    The ranking is calculated considering the total score the
                                    student have earned and then by the number of challenges the
                                    student has attempted.
                                </p>
                            </div>
                            {currentStudent?.map(data => data.score)[0] === 0 ? (
                                <div
                                    className="rounded mb-6 py-4 text-white pl-8 pr-14 xl:pr-64"
                                    style={{
                                        background:
                                            'linear-gradient(93.16deg, #6E41E2 1.57%, #0C247A 98.41%)',
                                    }}
                                >
                                    <div className="font-bold font-manrope text-2xl-2 mb-3 ">
                                        Do you need your profile to shine on our Leaderboard?
                                    </div>
                                    <p className="">
                                        While Peer the leaderboard can provide valuable support for
                                        students at critical points in their student life, it offers
                                        a host of other benefits for both knowledge and experience.
                                        Leaderboard is a way of learning and allowing participants
                                        to develop transferable skills that will help them during
                                        their time at university and beyond.
                                    </p>
                                </div>
                            ) : null}
                        </div>

                        <div className="flex flex-row mb-4">
                            <div className="flex flex-col my-1  w-full md:w-1/2">
                                <label
                                    className="flex flex-row my-1 font-manrope text-sm"
                                    htmlFor="tab_1"
                                >
                                    Choose a Track
                                </label>
                                <div className="flex relative cursor-pointer items-center focus:outline-none h-12 md:p-2 focus:border-blue-900 inputFieldThickness">
                                    <div
                                        className="flex justify-between w-full"
                                        onClick={() => setTrackDropdownVisible(true)}
                                    >
                                        <div
                                            className={`pl-2 ${
                                                trackFilterAreaAction !== 'Select'
                                                    ? 'text-gray-700'
                                                    : 'text-gray-400'
                                            }`}
                                        >
                                            {trackFilterAreaAction}
                                        </div>
                                        <div className="">
                                            <div className="flex flex-row">
                                                <div className="flex flex-col">
                                                    <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                    <HiIcons.HiChevronDown className="mr-2" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {trackDropdownVisible && (
                                        <ul
                                            className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setTrackDropdownVisible(false)}
                                        >
                                            {tracks.map(action => (
                                                <div
                                                    className="p-2 pl-2 hover:bg-gray-200 "
                                                    key={action.id}
                                                    onClick={() => {
                                                        setTrackFilterAction(action.trackName);
                                                        setTrackFilterActionId(action.id);
                                                        setTrackDropdownVisible(false);
                                                    }}
                                                >
                                                    {action.trackName}
                                                </div>
                                            ))}
                                            <div
                                                className="p-2 pl-2 hover:bg-gray-200 "
                                                onClick={() => {
                                                    setTrackFilterAction('All');
                                                    setTrackFilterActionId(null);
                                                    setTrackDropdownVisible(false);
                                                }}
                                            >
                                                All
                                            </div>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>

                        {currentStudent?.map(data => data.score)[0] !== 0 ? (
                            leaderboard?.some(data => data.studentId === student?.userId) ? (
                                <div>
                                    <table
                                        style={{
                                            borderCollapse: 'separate',
                                            borderSpacing: ' 0 1em',
                                        }}
                                        className=" w-full"
                                    >
                                        <thead>
                                            <tr className="text-sm">
                                                <th className="p-2 text-center">Rank</th>
                                                <th className="p-2 text-center">Student ID</th>
                                                <th className="p-2 text-center">Gender</th>
                                                <th className="p-2 text-center">Hometown</th>
                                                <th className="p-2 text-center">
                                                    Challenges Attempted
                                                </th>
                                                <th className="p-2 text-center">Task Attempts</th>
                                                <th className="p-2 text-center">Total Score</th>
                                                <th className=""></th>
                                            </tr>
                                        </thead>
                                        {leaderboard?.slice(0, 10).map(leaderboard1 => (
                                            <tbody
                                                key={leaderboard1.studentId}
                                                className="border-2 text-sm lg:text-base"
                                            >
                                                <tr
                                                    style={{
                                                        background:
                                                            leaderboard1?.rank === 1
                                                                ? '#FEF9DC'
                                                                : '',
                                                    }}
                                                    className="mb-4 py-6 rounded  border-2 border-gray-200"
                                                >
                                                    <td
                                                        className="flex items-center justify-center border-2 w-8 h-8 border-yellow-400 mt-3 ml-8"
                                                        style={{
                                                            borderRadius: '100%',
                                                            background:
                                                                leaderboard1.rank === 1
                                                                    ? '#FAD514'
                                                                    : '',
                                                        }}
                                                    >
                                                        {leaderboard1.rank}
                                                    </td>
                                                    <td className=" py-3">
                                                        <div className="flex flex-row">
                                                            <img
                                                                className="w-8 h-8 rounded-full mr-2"
                                                                src={`${
                                                                    !studentRank.imageUrl
                                                                        ? '/assets/user.png'
                                                                        : studentRank?.imageUrl.includes(
                                                                              'webUsers'
                                                                          )
                                                                        ? s3UrlPrefix +
                                                                          studentRank?.imageUrl
                                                                        : studentRank?.imageUrl
                                                                }`}
                                                                alt="profile"
                                                            />
                                                            <div className="flex items-center">
                                                                {leaderboard1.studentName}
                                                            </div>
                                                            {leaderboard1.rank === 1 ? (
                                                                <img
                                                                    className="w-8 h-8 rounded mr-2"
                                                                    src="/assets/rankedOneBatch.png"
                                                                    alt="profile"
                                                                />
                                                            ) : null}
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        {leaderboard1.hometown}
                                                    </td>
                                                    <td className="text-center">
                                                        {leaderboard1.completedChallengeCount}
                                                    </td>
                                                    <td className="text-center">
                                                        {leaderboard1.attempts}
                                                    </td>
                                                    <td className="text-purple-950 font-bold ">
                                                        <div className="text-center text-xl">
                                                            {`${Math.round(
                                                                leaderboard1?.totalScore * 100
                                                            )} `}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </div>
                            ) : null
                        ) : null}

                        <div>
                            <div className="font-bold text-lg font-manrope">Your Rank</div>
                            <HorizontalScrollableLeaderboard className="overflow-x-scroll w-11/12 horizontal-scrollable-leaderboard">
                                <table
                                    style={{ borderCollapse: 'separate', borderSpacing: ' 0 1em' }}
                                    className="w-full table-fixed"
                                >
                                    <thead>
                                        <tr className="text-xs">
                                            <th className="p-2 text-center w-10">Rank</th>
                                            <th className="p-2 text-center w-32">Student ID</th>
                                            <th className="p-2 text-center w-20">Gender</th>
                                            <th className="p-2 text-center w-32">Hometown</th>
                                            <th className="p-2 text-center w-20">
                                                Challenges Attempted
                                            </th>
                                            <th className="p-2 text-center w-20">Task Attempts</th>
                                            <th className="p-2 text-center w-20">Total Score</th>
                                            <th className="p-2 text-center w-8" />
                                        </tr>
                                    </thead>
                                    {studentRank ? (
                                        <tbody
                                            className="border-2 text-xs cursor-pointer bg-gray-100"
                                            onClick={() =>
                                                window.open(
                                                    `${window.location.origin}/public-profile/${profile?.userId}`,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            <tr className="h-16">
                                                <td className="text-center">
                                                    <div
                                                        className={`flex items-center rounded-full justify-center border-2 ${
                                                            studentRank.rank.toString().length > 4
                                                                ? `w-10 h-10 text-xxs`
                                                                : studentRank.rank.toString()
                                                                      .length > 3
                                                                ? `w-9 h-9 text-xxs`
                                                                : studentRank.rank.toString()
                                                                      .length > 2
                                                                ? `w-8 h-8 text-xs`
                                                                : `w-8 h-8 text-sm`
                                                        } border-yellow-400`}
                                                    >
                                                        {studentRank.rank}
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="flex flex-row justify-center items-center">
                                                        <img
                                                            className="w-8 h-8 rounded-full mr-2"
                                                            src={`${
                                                                !studentRank.imageUrl
                                                                    ? '/assets/user.png'
                                                                    : studentRank?.imageUrl.includes(
                                                                          'webUsers'
                                                                      )
                                                                    ? s3UrlPrefix +
                                                                      studentRank?.imageUrl
                                                                    : studentRank?.imageUrl
                                                            }`}
                                                            alt="profile"
                                                        />
                                                        <div className="flex items-center">
                                                            {studentRank.studentId}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    {studentRank.gender
                                                        ? studentRank.gender
                                                        : 'NOT SPECIFIED	'}
                                                </td>
                                                <td className="text-center">
                                                    {studentRank.hometown}
                                                </td>
                                                <td className="text-center">
                                                    {studentRank.completedChallengeCount}
                                                </td>
                                                <td className="text-center">
                                                    {studentRank.attempts}
                                                </td>
                                                <td className="text-center text-purple-950 font-bold">{`${Math.round(
                                                    studentRank?.totalScore * 100
                                                )} `}</td>
                                                <td className="text-center text-purple-950 font-bold">
                                                    {studentRank.studentId === student?.userId ? (
                                                        <div className="text-gray-500 text-sm">
                                                            you
                                                        </div>
                                                    ) : null}
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : null}
                                </table>
                            </HorizontalScrollableLeaderboard>
                        </div>

                        <div>
                            <div className="font-bold text-lg font-manrope">Students</div>
                            <HorizontalScrollableLeaderboard className="overflow-x-scroll w-11/12 horizontal-scrollable-leaderboard">
                                <table
                                    style={{ borderCollapse: 'separate', borderSpacing: ' 0 1em' }}
                                    className="w-full table-fixed"
                                >
                                    <thead>
                                        <tr className="text-xs">
                                            <th className="p-2 text-center w-10">Rank</th>
                                            <th className="p-2 text-center w-32">Student ID</th>
                                            <th className="p-2 text-center w-20">Gender</th>
                                            <th className="p-2 text-center w-32">Hometown</th>
                                            <th className="p-2 text-center w-20">
                                                Challenges Attempted
                                            </th>
                                            <th className="p-2 text-center w-20">Task Attempts</th>
                                            <th className="p-2 text-center w-20">Total Score</th>
                                            <th className="p-2 text-center w-8" />
                                        </tr>
                                    </thead>
                                    <tbody className="border-2 text-xs">
                                        {leaderboard?.map((leaderboard1, index) => (
                                            <tr
                                                key={leaderboard1.studentId}
                                                className="h-16 cursor-pointer"
                                                onClick={() => {
                                                    window.open(
                                                        `${window.location.origin}/public-profile/${leaderboard1.studentId}`,
                                                        '_blank'
                                                    );
                                                }}
                                            >
                                                <td className="text-center">
                                                    <div
                                                        className={`flex items-center rounded-full justify-center border-2 ${
                                                            (
                                                                (currentPage - 1) * postPerPage +
                                                                index +
                                                                1
                                                            ).toString().length > 4
                                                                ? `w-10 h-10 text-xxs`
                                                                : (
                                                                      (currentPage - 1) *
                                                                          postPerPage +
                                                                      index +
                                                                      1
                                                                  ).toString().length > 3
                                                                ? `w-9 h-9 text-xxs`
                                                                : (
                                                                      (currentPage - 1) *
                                                                          postPerPage +
                                                                      index +
                                                                      1
                                                                  ).toString().length > 2
                                                                ? `w-8 h-8 text-xs`
                                                                : `w-8 h-8 text-sm`
                                                        } border-yellow-400`}
                                                    >
                                                        {(currentPage - 1) * postPerPage +
                                                            index +
                                                            1}
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="flex flex-row justify-center items-center">
                                                        <img
                                                            className="w-8 h-8 rounded-full mr-2"
                                                            src={`${
                                                                !leaderboard1.imageUrl ||
                                                                !leaderboard1.enablePublicProfile
                                                                    ? '/assets/user.png'
                                                                    : leaderboard1?.imageUrl.includes(
                                                                          'webUsers'
                                                                      )
                                                                    ? s3UrlPrefix +
                                                                      leaderboard1?.imageUrl
                                                                    : leaderboard1?.imageUrl
                                                            }`}
                                                            alt="profile"
                                                        />
                                                        <div className="flex items-center">
                                                            {leaderboard1.studentId}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    {leaderboard1.gender
                                                        ? leaderboard1.gender
                                                        : 'NOT SPECIFIED	'}
                                                </td>
                                                <td className="text-center">
                                                    {leaderboard1.hometown}
                                                </td>
                                                <td className="text-center">
                                                    {leaderboard1.completedChallengeCount}
                                                </td>
                                                <td className="text-center">
                                                    {leaderboard1.attempts}
                                                </td>
                                                <td className="text-center text-purple-950 font-bold">{`${Math.round(
                                                    leaderboard1?.totalScore * 100
                                                )} `}</td>
                                                <td className="text-center text-purple-950 font-bold">
                                                    {leaderboard1.studentId === student?.userId ? (
                                                        <div className="text-gray-500 text-sm">
                                                            you
                                                        </div>
                                                    ) : null}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </HorizontalScrollableLeaderboard>
                        </div>
                    </div>
                    <div className={`my-8 text-sm w-full`}>
                        <div className="flex flex-col items-center bg-purple-85 rounded-md justify-between py-6 gap-x-6 gap-y-4">
                            <div className="px-4 flex justify-between items-center w-full">
                                <div className="relative text-purple-700 flex flex-row">
                                    {pageSizesVisible && (
                                        <ul
                                            className="absolute w-full cursor-pointer pl-0 bottom-8 left-0 z-30 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setpageSizesVisible(false)}
                                        >
                                            {[100, 50, 25, 10].map(action => (
                                                <div
                                                    className="p-2 pl-2 hover:bg-gray-200 "
                                                    key={action}
                                                    onClick={() => {
                                                        setPostPerPage(action);
                                                        setpageSizesVisible(false);
                                                    }}
                                                >
                                                    {action}
                                                </div>
                                            ))}
                                        </ul>
                                    )}
                                    <div className="font-bold" style={{ fontSize: 10 }}>
                                        Show records {postPerPage}
                                    </div>
                                    <HiIcons.HiChevronUp
                                        className="ml-2 mt-1 text-lg border-2 border-purple-700 rounded cursor-pointer"
                                        onClick={() => setpageSizesVisible(true)}
                                    />
                                </div>
                                <div
                                    className="items-center flex flex-row text-purple-700"
                                    style={{ fontSize: 10 }}
                                >
                                    <div
                                        id="gotoButton"
                                        className="font-bold bg-gray-200 border-purple-400 border mr-1 rounded p-1 cursor-pointer"
                                        onClick={() => {
                                            if (goto) {
                                                setCurrentPage(parseInt(goto));
                                            }
                                        }}
                                    >
                                        Go
                                    </div>
                                    <b>to</b>
                                    <input
                                        className="border border-purple-400 font-bold p-1 m-2 w-14 focus:outline-none rounded"
                                        value={goto}
                                        id="goto"
                                        type="number"
                                        onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
                                        onChange={e => {
                                            setGoto(e.target.value);
                                        }}
                                    />
                                    <b>of {pageCount}</b>
                                </div>
                            </div>
                            <div className="flex justify-between items-center w-full px-2">
                                <div
                                    onClick={() => {
                                        if (currentPage !== 1) {
                                            setCurrentPage(currentPage - 1);
                                        }
                                    }}
                                    className={`text-purple-950 font-semibold cursor-pointer ${
                                        currentPage === 1 ? 'invisible' : ''
                                    }`}
                                    style={{ fontSize: 10 }}
                                >
                                    Previous
                                </div>
                                <div className="flex">
                                    {pageTray.map((number, index) => (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                setCurrentPage(number);
                                            }}
                                            className={`rounded-md border-2 border-purple-950  font-semibold ${
                                                number === currentPage
                                                    ? 'text-white bg-purple-950'
                                                    : 'text-purple-950 '
                                            }`}
                                            style={{
                                                fontSize: 10,
                                                padding: '0 6px',
                                                margin: '0 2px',
                                            }}
                                        >
                                            {number}
                                        </div>
                                    ))}
                                </div>
                                <div
                                    onClick={() => {
                                        if (currentPage !== pageCount) {
                                            setCurrentPage(currentPage + 1);
                                        }
                                    }}
                                    className={`text-purple-950 font-semibold cursor-pointer ${
                                        currentPage === pageCount ? 'invisible' : ''
                                    }`}
                                    style={{ fontSize: 10 }}
                                >
                                    Next
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Leaderboard;
