import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { useHistory } from 'react-router-dom';
import Loader from '../../assets/svg/Loader';
import { deleteConfirmationMessage } from '../../constants';
import {
    deleteBadge,
    deleteCertificate,
    deleteChallenge,
    deleteCompany,
    deleteEvent,
    deleteInstitute,
    deleteMentor,
    deleteStudent,
    deleteTrack,
    deleteUser,
    editSuccessStory,
} from '../../redux/rootActions';
import { RootState } from '../../redux/rootReducer';

interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    trackId?: number;
    storyId?: string;
    studentId?: string;
    tabStatus?: string;
    challengeId?: string;
    instituteId?: string;
    companyId?: string;
    certificateId?: string;
    eventId?: string;
    badgeId?: string;
    mentorId?: string;
    userId?: string;
    setshowMenu?: (value: boolean) => void;
}

export default function DeleteConfirmationModal({
    modalVisible,
    setModalVisible,
    trackId,
    storyId,
    tabStatus,
    challengeId,
    instituteId,
    certificateId,
    companyId,
    studentId,
    eventId,
    badgeId,
    mentorId,
    userId,
    setshowMenu,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();
    const isTrackDeleteLoading = useSelector((state: RootState) => state.tracks.isDeleteLoading);
    const isChallengeDeleteLoading = useSelector(
        (state: RootState) => state.challenges.isDeleteLoading
    );
    const isStudentDeleteLoading = useSelector(
        (state: RootState) => state.students.isDeleteLoading
    );
    const isInstituteDeleteLoading = useSelector(
        (state: RootState) => state.institutes.isDeleteLoading
    );
    const isCompanyDeleteLoading = useSelector(
        (state: RootState) => state.companies.isDeleteLoading
    );
    const isCertificatesDeleteLoading = useSelector(
        (state: RootState) => state.certificates.isDeleteLoading
    );
    const isEventsDeleteLoading = useSelector((state: RootState) => state.events.isDeleteLoading);
    const isBadgeDeleteLoading = useSelector((state: RootState) => state.badges.isDeleteLoading);
    const isMentorDeleteLoading = useSelector((state: RootState) => state.mentors.isDeleteLoading);
    const isUserDeleteLoading = useSelector((state: RootState) => state.users.isDeleteLoading);

    return (
        <Modal
            open={modalVisible}
            onClose={() => {
                setModalVisible(false);
            }}
            center
            styles={{ modal: { borderRadius: 12, maxWidth: 555 } }}
            focusTrapped={false}
        >
            <div className="px-4 mt-10 md:px-5">
                <div className="text-lg ml-4" style={{ color: '#9698A9' }}>
                    {`${deleteConfirmationMessage}${
                        challengeId
                            ? ' challenge?'
                            : trackId
                            ? ' track?'
                            : studentId
                            ? ' student?'
                            : instituteId
                            ? ' institute?'
                            : companyId
                            ? ' company?'
                            : certificateId
                            ? ' certificate?'
                            : eventId
                            ? ' event?'
                            : badgeId
                            ? ' badge?'
                            : mentorId
                            ? ' mentor?'
                            : userId
                            ? ' user?'
                            : ' success story?'
                    }`}
                </div>
                <div className="mt-8 flex justify-end">
                    <button
                        className="border rounded-md border-blue-600 text-blue-600 text-sm h-10 px-8 mr-2 focus:outline-none"
                        onClick={() => {
                            setModalVisible(false);
                            setshowMenu && setshowMenu(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="border bg-blue-600 rounded-md text-white text-sm h-10 leading-4 px-8 ml-2 focus:outline-none hover:text-white hover:bg-blue-800"
                        onClick={async () => {
                            if (trackId) {
                                dispatch(deleteTrack(trackId, tabStatus));
                                history.push('/admin/tracks/');
                            } else if (challengeId) {
                                dispatch(deleteChallenge(challengeId, tabStatus));
                                history.push('/admin/challenges/');
                            } else if (studentId) {
                                dispatch(deleteStudent(studentId, tabStatus));
                            } else if (storyId) {
                                dispatch(
                                    editSuccessStory(storyId, { status: 'DELETED' }, tabStatus)
                                );
                            } else if (instituteId) {
                                dispatch(deleteInstitute(instituteId, tabStatus));
                            } else if (companyId) {
                                dispatch(deleteCompany(companyId, tabStatus));
                            } else if (certificateId) {
                                dispatch(deleteCertificate(certificateId, tabStatus));
                            } else if (eventId) {
                                dispatch(deleteEvent(eventId, tabStatus));
                            } else if (badgeId) {
                                dispatch(deleteBadge(badgeId, tabStatus));
                            } else if (mentorId) {
                                dispatch(deleteMentor(mentorId, tabStatus));
                            } else if (userId) {
                                dispatch(deleteUser(userId, tabStatus));
                            }
                            setModalVisible(false);
                            setshowMenu && setshowMenu(false);
                        }}
                    >
                        {challengeId && isChallengeDeleteLoading ? (
                            <Loader />
                        ) : trackId && isTrackDeleteLoading ? (
                            <Loader />
                        ) : studentId && isStudentDeleteLoading ? (
                            <Loader />
                        ) : storyId && isStudentDeleteLoading ? (
                            <Loader />
                        ) : instituteId && isInstituteDeleteLoading ? (
                            <Loader />
                        ) : companyId && isCompanyDeleteLoading ? (
                            <Loader />
                        ) : certificateId && isCertificatesDeleteLoading ? (
                            <Loader />
                        ) : eventId && isEventsDeleteLoading ? (
                            <Loader />
                        ) : badgeId && isBadgeDeleteLoading ? (
                            <Loader />
                        ) : mentorId && isMentorDeleteLoading ? (
                            <Loader />
                        ) : userId && isUserDeleteLoading ? (
                            <Loader />
                        ) : (
                            'Delete'
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
