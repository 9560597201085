import { TracksType } from '../tracks/reducers';
import {
    GET_CERTIFICATES,
    GET_CERTIFICATES_SUCCESS,
    GET_CERTIFICATES_FAILED,
    GET_EARNED_CERTIFICATES,
    GET_EARNED_CERTIFICATES_SUCCESS,
    GET_EARNED_CERTIFICATES_FAILED,
    CERTIFICATEURL_FROM_CODE,
    CERTIFICATEURL_FROM_CODE_SUCCESS,
    CERTIFICATEURL_FROM_CODE_FAILED,
    GET_STUDENT_CERTIFICATES,
    GET_STUDENT_CERTIFICATES_SUCCESS,
    GET_STUDENT_CERTIFICATES_FAILED,
    GET_CERTIFICATE_BY_ID,
    GET_CERTIFICATE_BY_ID_SUCCESS,
    GET_CERTIFICATE_BY_ID_FAILED,
    ADD_CERTIFICATE,
    ADD_CERTIFICATE_SUCCESS,
    ADD_CERTIFICATE_FAILED,
    DELETE_CERTIFICATE,
    DELETE_CERTIFICATE_SUCCESS,
    DELETE_CERTIFICATE_FAILED,
    EDIT_CERTIFICATE,
    EDIT_CERTIFICATE_SUCCESS,
    EDIT_CERTIFICATE_FAILED,
    CLEAR_CERTIFICATES_MESSAGES,
    CERTIFICATES_BULK_UPDATE,
    CERTIFICATES_BULK_UPDATE_FAILED,
    CERTIFICATES_BULK_UPDATE_SUCCESS,
    GET_CERTIFICATE_PRESIGNED,
    GET_CERTIFICATE_PRESIGNED_FAILED,
    GET_CERTIFICATE_PRESIGNED_SUCCESS,
    UPLOAD_CERTIFICATE,
    UPLOAD_CERTIFICATE_FAILED,
    UPLOAD_CERTIFICATE_SUCCESS,
    GET_CERTIFICATE_PDF,
    GET_CERTIFICATE_PDF_FAILED,
    GET_CERTIFICATE_PDF_SUCCESS,
} from './actions';

export interface ChallengeProgressType {
    completedCount: number;
    progressStatus: number;
    remainingCount: number;
    totalCount: number;
}

export interface CertificateEditType {
    certStatus: string;
    certificateName: string;
    contentDocUrl: string;
    contentPdfUrl: string;
    tags: [];
    track: TracksType;
}

export interface CertificateType {
    id: string;
    certCreatedDate: string;
    certId: string;
    certStatus: string;
    certificateName: string;
    contentDocUrl: string;
    certificateSize: number;
    contentPdfUrl: string;
    description: string;
    tags: any;
    track: TracksType;
    earnedMarks?: number;
    totalMarks?: number;
    certificateUrl?: string;
    certificateCode?: string;
    challengeProgress?: ChallengeProgressType;
}

export interface StudentCertificatesType {
    studentId: string | number;
    earned: CertificateType[];
    unearned: UnearnedStudentCertificatesType;
}

export interface UnearnedStudentCertificatesType {
    notStarted: CertificateType[];
    inProgress: CertificateType[];
}

export interface EarnCertificatesType {
    certificate: CertificateType;
    certificateCode: string | null;
    certificateUrl: string | null;
    issuedDate: string;
}
export interface CountType {
    ALL: number;
    ACTIVE: number;
    INACTIVE: number;
    DELETED: number;
}

export interface CertificateInitialState {
    certificatesData: CertificateType[];
    certificate: CertificateType;
    studentEarnedCertificatesData: EarnCertificatesType[];
    studentEarnedCertificatesError: string | null;
    studentEarnedCertificatesLoading: boolean;
    certificateUrlFromCode: string | null;
    certificateUrlFromCodeError: string | null;
    certificateUrlFromCodeLoading: boolean;
    studentCertificatesData: StudentCertificatesType;
    studentCertificatesError: string | null;
    studentCertificatesLoading: boolean;
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    certificateByIdLoading: boolean;
    certificateByIdError: string | null;
    certificatesError: string | null;
    createCertificateError: string | null;
    certificateCreateSuccessMessage: string | null;
    isDeleteLoading: boolean;
    deleteCertificateError: string | null;
    certificateDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editCertificateError: string | null;
    certificateUpdateSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    certificatesBulkSuccessMessage: string | null;
    isImageUploading: boolean;
    imageError: string | null;
    isPdfLoading: boolean;
    pdfError: string | null;
    pdfLink: string | null;
}

const initialState: CertificateInitialState = {
    certificatesData: [],
    certificate: null,
    studentEarnedCertificatesData: null,
    studentEarnedCertificatesError: null,
    studentEarnedCertificatesLoading: false,
    certificateUrlFromCode: null,
    certificateUrlFromCodeError: null,
    certificateUrlFromCodeLoading: false,
    studentCertificatesData: null,
    studentCertificatesError: null,
    studentCertificatesLoading: false,
    pageCount: null,
    count: null,
    isLoading: false,
    certificateByIdLoading: false,
    certificateByIdError: null,
    certificatesError: null,
    createCertificateError: null,
    certificateCreateSuccessMessage: null,
    isDeleteLoading: false,
    deleteCertificateError: null,
    certificateDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editCertificateError: null,
    certificateUpdateSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    certificatesBulkSuccessMessage: null,
    isImageUploading: false,
    imageError: null,
    isPdfLoading: false,
    pdfError: null,
    pdfLink: null,
};

const CertificatesReducer = (state = initialState, action: any) => {
    const newTab = action?.payload?.tag?.map(tagss => tagss.id);

    let filterdnewDAta;
    if (action?.payload?.tag !== undefined) {
        filterdnewDAta = action?.payload?.payload?.items?.filter(track =>
            newTab.every(s => track.tags.map(trackid => trackid.id).includes(parseInt(s)))
        );
    }
    switch (action.type) {
        case GET_CERTIFICATES:
            return { ...state, isLoading: true, certificatesError: null };
        case GET_CERTIFICATES_SUCCESS:
            if (newTab !== undefined || newTab) {
                return {
                    ...state,
                    isLoading: false,
                    count: action.payload.payload.counts,
                    certificatesData: filterdnewDAta,
                    pageCount: action.payload.payload.totalPages,
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    count: action.payload.counts,
                    certificatesData: action.payload.items,
                    pageCount: action.payload.totalPages,
                };
            }
        case GET_CERTIFICATES_FAILED:
            return { ...state, isLoading: false, certificatesError: action.payload };

        case GET_EARNED_CERTIFICATES:
            return {
                ...state,
                studentEarnedCertificatesLoading: true,
                studentEarnedCertificatesError: null,
            };

        case GET_EARNED_CERTIFICATES_SUCCESS:
            return {
                ...state,
                studentEarnedCertificatesLoading: false,
                studentEarnedCertificatesData: action.payload,
            };
        case GET_EARNED_CERTIFICATES_FAILED:
            return {
                ...state,
                studentEarnedCertificatesLoading: false,
                studentEarnedCertificatesError: action.payload,
            };

        case CERTIFICATEURL_FROM_CODE:
            return {
                ...state,
                certificateUrlFromCodeLoading: true,
                certificateUrlFromCodeError: null,
            };
        case CERTIFICATEURL_FROM_CODE_SUCCESS:
            return {
                ...state,
                certificateUrlFromCodeLoading: false,
                certificateUrlFromCode: action.payload,
            };
        case CERTIFICATEURL_FROM_CODE_FAILED:
            return {
                ...state,
                certificateUrlFromCodeLoading: false,
                certificateUrlFromCodeError: action.payload,
            };

        case GET_STUDENT_CERTIFICATES:
            return { ...state, studentCertificatesLoading: true, studentCertificatesError: null };
        case GET_STUDENT_CERTIFICATES_SUCCESS:
            return {
                ...state,
                studentCertificatesLoading: false,
                studentCertificatesData: action.payload,
            };
        case GET_STUDENT_CERTIFICATES_FAILED:
            return {
                ...state,
                studentCertificatesLoading: false,
                studentCertificatesError: action.payload,
            };
        case GET_CERTIFICATE_BY_ID:
            return { ...state, certificateByIdLoading: true, certificateByIdError: null };
        case GET_CERTIFICATE_BY_ID_SUCCESS:
            return {
                ...state,
                certificateByIdLoading: false,
                certificate: action.payload,
            };
        case GET_CERTIFICATE_BY_ID_FAILED:
            return {
                ...state,
                certificateByIdLoading: false,
                certificateByIdError: action.payload,
            };

        case ADD_CERTIFICATE:
            return { ...state, isCreateLoading: true, createCertificateError: null };
        case ADD_CERTIFICATE_SUCCESS:
            return {
                ...state,
                isCreateLoading: false,
                certificateCreateSuccessMessage: action.payload,
            };
        case ADD_CERTIFICATE_FAILED:
            return { ...state, isCreateLoading: false, createCertificateError: action.payload };
        case DELETE_CERTIFICATE:
            return { ...state, isDeleteLoading: true, deleteCertificateError: null };
        case DELETE_CERTIFICATE_SUCCESS:
            return {
                ...state,
                isDeleteLoading: false,
                certificateDeleteSuccessMessage: action.payload,
            };
        case DELETE_CERTIFICATE_FAILED:
            return { ...state, isDeleteLoading: false, deleteCertificateError: action.payload };
        case EDIT_CERTIFICATE:
            return { ...state, isEditLoading: true, editCertificateError: null };
        case EDIT_CERTIFICATE_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                certificateUpdateSuccessMessage: action.payload,
            };
        case EDIT_CERTIFICATE_FAILED:
            return { ...state, isEditLoading: false, editCertificateError: action.payload };
        case CERTIFICATES_BULK_UPDATE:
            return { ...state, isBulkUpdateLoading: true, bulkError: null };
        case CERTIFICATES_BULK_UPDATE_SUCCESS:
            return {
                ...state,
                isBulkUpdateLoading: false,
                certificatesBulkSuccessMessage: action.payload,
            };
        case CERTIFICATES_BULK_UPDATE_FAILED:
            return { ...state, isBulkUpdateLoading: false, bulkError: action.payload };

        case GET_CERTIFICATE_PRESIGNED:
            return { ...state, isImageUploading: true, imageError: null };
        case GET_CERTIFICATE_PRESIGNED_SUCCESS:
            return {
                ...state,
                isImageUploading: false,
                presignedSuccess: action.payload,
            };
        case GET_CERTIFICATE_PRESIGNED_FAILED:
            return { ...state, isImageUploading: false, imageError: action.payload };
        case UPLOAD_CERTIFICATE:
            return { ...state, isImageUploading: true, imageError: null };
        case UPLOAD_CERTIFICATE_SUCCESS:
            return {
                ...state,
                isImageUploading: false,
                imageUploadSuccess: action.payload,
            };
        case UPLOAD_CERTIFICATE_FAILED:
            return { ...state, isImageUploading: false, imageError: action.payload };
        case GET_CERTIFICATE_PDF:
            return { ...state, isPdfLoading: true, pdfError: null };
        case GET_CERTIFICATE_PDF_SUCCESS:
            return {
                ...state,
                isPdfLoading: false,
                pdfLink: action.payload,
            };
        case GET_CERTIFICATE_PDF_FAILED:
            return { ...state, isPdfLoading: false, pdfError: action.payload };

        case CLEAR_CERTIFICATES_MESSAGES:
            return {
                ...state,
                certificateCreateSuccessMessage: null,
                createCertificateError: null,
                certificateUpdateSuccessMessage: null,
                editCertificateError: null,
                deleteCertificateError: null,
                certificateDeleteSuccessMessage: null,
                certificatesBulkSuccessMessage: null,
                bulkError: null,
            };
        default:
            return state;
    }
};

export default CertificatesReducer;
