import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';

const PREFIX = '@NOTIFICATIONS';

export const GET_NOTIFICATIONS = PREFIX + 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = PREFIX + 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILED = PREFIX + 'GET_NOTIFICATIONS_FAILED';
export const GET_NOTIFICATION_BYID = PREFIX + 'GET_NOTIFICATION_BYID';
export const GET_NOTIFICATION_BYID_SUCCESS = PREFIX + 'GET_NOTIFICATION_BYID_SUCCESS';
export const GET_NOTIFICATION_BYID_FAILED = PREFIX + 'GET_NOTIFICATION_BYID_FAILED';
export const GET_NOTIFY_USERS = PREFIX + 'GET_NOTIFY_USERS';
export const GET_NOTIFY_USERS_SUCCESS = PREFIX + 'GET_NOTIFY_USERS_SUCCESS';
export const GET_NOTIFY_USERS_FAILED = PREFIX + 'GET_NOTIFY_USERS_FAILED';
export const SEND_NOTIFICATION = PREFIX + 'SEND_NOTIFICATION';
export const SEND_NOTIFICATION_SUCCESS = PREFIX + 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAILED = PREFIX + 'SEND_NOTIFICATION_FAILED';
export const CLEAR_NOTIFICATIONS_MESSAGES = PREFIX + 'CLEAR_NOTIFICATIONS_MESSAGES';
export const GET_NOTIFICATION_TEMPLATES = PREFIX + 'GET_NOTIFICATION_TEMPLATES';
export const GET_NOTIFICATION_TEMPLATES_SUCCESS = PREFIX + 'GET_NOTIFICATION_TEMPLATES_SUCCESS';
export const GET_NOTIFICATION_TEMPLATES_FAILED = PREFIX + 'GET_NOTIFICATION_TEMPLATES_FAILED';
export const GET_NOTIFICATION_TEMPLATE_BY_ID = PREFIX + 'GET_NOTIFICATION_TEMPLATE_BY_ID';
export const GET_NOTIFICATION_TEMPLATE_BY_ID_SUCCESS =
    PREFIX + 'GET_NOTIFICATION_TEMPLATE_BY_ID_SUCCESS';
export const GET_NOTIFICATION_TEMPLATE_BY_ID_FAILED =
    PREFIX + 'GET_NOTIFICATION_TEMPLATE_BY_ID_FAILED';
export const SAVE_NOTIFICATION_TEMPLATE = PREFIX + 'SAVE_NOTIFICATION_TEMPLATE';
export const SAVE_NOTIFICATION_TEMPLATE_SUCCESS = PREFIX + 'SAVE_NOTIFICATION_TEMPLATE_SUCCESS';
export const SAVE_NOTIFICATION_TEMPLATE_FAILED = PREFIX + 'SAVE_NOTIFICATION_TEMPLATE_FAILED';

export interface Companies {
    from: string | null;
    to: string | null;
}

export interface NotificationsTabCreateType {
    id?: string | null;
    tabName?: string | null;
    institutes?: any[];
    companies?: Companies;
    tags?: any[];
    notificationType?: string | null;
}

export interface NotificationTemplate {
    id?: any | null;
    sentTypeList?: any[];
    notificationTitle?: string | null;
    campaignTitle?: string | null;
    content?: string | null;
}

export const getNotifications =
    (
        page?: number,
        size?: number,
        status?: string,
        tag?: string,
        searchTerm?: string,
        sentType?: string
    ) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_NOTIFICATIONS });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/notifications/?page=${page || 0}&size=${size || 10}${
                    status ? `&sentType=${status}` : ''
                }${searchTerm ? `&searchText=${searchTerm}` : ''}${
                    sentType ? `&sentType=${sentType}` : ''
                }`,
                config
            );

            if (tag?.length === 0 || isNaN(tag?.length)) {
                dispatch({
                    type: GET_NOTIFICATIONS_SUCCESS,
                    payload: response.data.responseDto,
                });
            } else {
                const newPayLoad = {
                    payload: response.data.responseDto,
                    tag: tag,
                };
                dispatch({
                    type: GET_NOTIFICATIONS_SUCCESS,
                    payload: newPayLoad,
                });
            }
        } catch (error) {
            dispatch({ type: GET_NOTIFICATIONS_FAILED, payload: getErrorMessage(error) });
        }
    };
export const getUsersToNotify =
    (page?: number, size?: number, data?: any) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_NOTIFY_USERS });
            const config = await axiosConfig(true);
            const res = await axios.post(
                `${API_URL}/users/notify?page=${page || 0}&size=${size || 10}`,
                data,
                config
            );
            dispatch({
                type: GET_NOTIFY_USERS_SUCCESS,
                payload: res.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: SEND_NOTIFICATION_FAILED, payload: getErrorMessage(error) });
        }
    };
export const getNotificationById = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_NOTIFICATION_BYID });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/notifications/${id}`, config);
        dispatch({ type: GET_NOTIFICATION_BYID_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_NOTIFICATION_BYID_FAILED, payload: getErrorMessage(error) });
    }
};
export const sendNotification = (notification: any) => async (dispatch: any) => {
    try {
        dispatch({ type: SEND_NOTIFICATION });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/notifications/`, notification, config);
        if (res?.data?.errorCode === 222) {
            await dispatch(getNotifications());
            await dispatch({
                type: SEND_NOTIFICATION_SUCCESS,
                payload: res.data.responseDto,
            });
        } else {
            dispatch({
                type: SEND_NOTIFICATION_FAILED,
                payload: res.data.errorDescription,
            });
        }
    } catch (error) {
        dispatch({ type: SEND_NOTIFICATION_FAILED, payload: getErrorMessage(error) });
    }
};

export const clearNotificationsMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_NOTIFICATIONS_MESSAGES });
};

export const getNotificationTemplates = (searchTerm?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_NOTIFICATION_TEMPLATES });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/notifications/template/?searchText=${searchTerm || ''}`,
            config
        );

        if (response?.data?.errorCode === 0) {
            await dispatch({
                type: GET_NOTIFICATION_TEMPLATES_SUCCESS,
                payload: response.data.responseDto,
            });
        } else {
            dispatch({
                type: GET_NOTIFICATION_TEMPLATES_FAILED,
                payload: response.data.errorMessage,
            });
        }
    } catch (error) {
        dispatch({ type: GET_NOTIFICATIONS_FAILED, payload: getErrorMessage(error) });
    }
};

export const getNotificationTemplateById = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_NOTIFICATION_TEMPLATE_BY_ID });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/notifications/template${id}`, config);
        dispatch({
            type: GET_NOTIFICATION_TEMPLATE_BY_ID_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_NOTIFICATION_TEMPLATE_BY_ID_FAILED, payload: getErrorMessage(error) });
    }
};

export const saveNotificationTemplate = (notificationTemplate: any) => async (dispatch: any) => {
    try {
        dispatch({ type: SAVE_NOTIFICATION_TEMPLATE });
        const config = await axiosConfig(true);
        const res = await axios.post(
            `${API_URL}/notifications/template`,
            notificationTemplate,
            config
        );
        if (res?.data?.errorCode === 222) {
            await dispatch({
                type: SAVE_NOTIFICATION_TEMPLATE_SUCCESS,
                payload: res.data.responseDto,
            });
        } else {
            dispatch({
                type: SAVE_NOTIFICATION_TEMPLATE_FAILED,
                payload: res.data.errorDescription,
            });
        }
    } catch (error) {
        dispatch({ type: SAVE_NOTIFICATION_TEMPLATE_FAILED, payload: getErrorMessage(error) });
    }
};
