import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Add from '../../../assets/svg/Add';
import AddTabs from './AddTabs';
import { joinedDate } from '../../../redux/rootActions';
import { addCompaniesTab, getCompanyTabs } from '../../../redux/companiesTab/actions';
import { USER_PROFILE } from '../../../utils/storage';
import { RootState } from '../../../redux/rootReducer';
interface Props {
    selectedTab: number;
    setSelectedTab: (value: number) => void;
}

export default function TabView({ selectedTab, setSelectedTab }: Props): ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showModal, setShowModal] = React.useState(false);
    const [tab, setTab] = useState([]);
    const [tabName, setTabName] = useState<string>();
    const [selectedTabFilteration, setSelectedTabFilteration] = useState<any>();

    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const companyTabData = useSelector(
        (state: RootState) => state.filteredCompanies.companyTabData
    );

    const [studentLessThan, setStudentLessThan] = useState<string>();
    const [studentMoreThan, setStudentMoreThan] = useState<string>();
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [joinStartDate, setJoinStartDate] = useState<any>();
    const [joinEndDAte, setJoinEndDate] = useState<any>();
    const [challenge, setChallenge] = useState([]);
    const [tags, setTags] = useState([]);
    const [program, setProgram] = useState([]);
    const [accountType, setAccountType] = useState<string>();
    const [tracks, setTracks] = useState([]);
    const [institue, setInstitue] = useState<string>();
    const [homeTown, setHomeTown] = useState<string>();

    useEffect(() => {
        if (selectedTab !== 1) {
            setSelectedTabFilteration(
                tab?.filter(action => action.id === selectedTab - 2).map(data => data?.filters)[0]
            );
        }
    }, [companyTabData, selectedTab]);

    useEffect(() => {
        dispatch(getCompanyTabs(profile?.userId, 'COMPANY'));
    }, []);

    useEffect(() => {
        if (selectedTab === selectedTabFilteration?.id + 2) {
            dispatch(
                addCompaniesTab({
                    id: selectedTabFilteration?.id,
                    tabName: selectedTabFilteration?.tabName,
                    accountType: selectedTabFilteration?.accountType,
                    joinedDate: selectedTabFilteration?.JoinedDate,
                })
            );
        }
    }, [selectedTab, selectedTabFilteration]);
    // }, [selectedTab, selectedTabFilteration, companyTabData]);

    useEffect(() => {
        setTab(companyTabData);
    }, [companyTabData]);

    const onAdd = () => {
        if (!tab.includes(tabName)) {
            setTab([...tab, tabName]);
        }

        const JoinedDate: joinedDate = {
            from: moment(joinStartDate)?.format('YYYY-MM-DD')?.toString(),
            to: moment(joinEndDAte)?.format('YYYY-MM-DD')?.toString(),
        };

        dispatch(
            addCompaniesTab({
                tabName: tabName,
                accountType: accountType,
                joinedDate: JoinedDate,
            })
        );
    };

    return (
        <>
            <div className="flex items-center">
                <div className={`flex w-full`}>
                    <div
                        className={`border-b-4 relative cursor-pointer w-1/2 md:w-1/3 lg:w-1/6  ${
                            selectedTab === 1 ? `border-blue-700` : 'border-snow-500'
                        }`}
                        role="presentation"
                        onClick={() => setSelectedTab(1)}
                    >
                        <div
                            className={`py-2 text-xs sm:text-lg absolute bottom-0 flex w-full justify-center ${
                                selectedTab === 1 ? `text-blue-700 font-semibold` : 'text-black-300'
                            }`}
                        >
                            All Companies
                        </div>
                    </div>
                    {/* {tab?.length > 0
                        ? tab?.map(action => (
                              <div
                                  className={`border-b-4 relative cursor-pointer w-1/2 md:w-1/6  ${
                                      selectedTab === action?.filters?.id + 2
                                          ? `border-blue-700`
                                          : 'border-snow-500'
                                  }`}
                                  role="presentation"
                                  key={action?.filters?.id}
                                  onClick={() => {
                                      setSelectedTab(action?.filters?.id + 2);
                                  }}
                              >
                                  <div
                                      className={`py-2 text-xs sm:text-lg absolute bottom-0 flex w-full text-center justify-center ${
                                          selectedTab === 2
                                              ? `text-blue-700 font-semibold`
                                              : 'text-black-300'
                                      }`}
                                  >
                                      {action?.filters?.tabName}
                                  </div>
                              </div>
                          ))
                        : null} */}
                    <div
                        className={`border-b-4 relative cursor-pointer pl-4 ${
                            selectedTab === 2 ? `` : ''
                        }`}
                        role="presentation"
                    >
                        <div className="py-2 text-xs sm:text-lg absolute bottom-0 text-center flex justify-center w-full">
                            {/* <button onClick={() => setShowModal(true)}>
                                <Add size={35} />
                            </button> */}

                            <AddTabs
                                isSelected={showModal}
                                setIsSelected={setShowModal}
                                onAdd={onAdd}
                                tabName={tabName}
                                setTabName={setTabName}
                                studentLessThan={studentLessThan}
                                setStudentLessThan={setStudentLessThan}
                                studentMoreThan={studentMoreThan}
                                setStudentMoreThan={setStudentMoreThan}
                                accountType={accountType}
                                setAccountType={setAccountType}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDAte={endDate}
                                setEndDate={setEndDate}
                                challenges={challenge}
                                setChallenges={setChallenge}
                                tags={tags}
                                setTags={setTags}
                                program={program}
                                setProgram={setProgram}
                                tracks={tracks}
                                setTracks={setTracks}
                                institue={institue}
                                setInstitue={setInstitue}
                                homeTown={homeTown}
                                setHomeTown={setHomeTown}
                                joinStartDate={joinStartDate}
                                setJoinStartDate={setJoinStartDate}
                                joinEndDAte={joinEndDAte}
                                setJoinEndDate={setJoinEndDate}
                            />
                        </div>
                    </div>
                    <div className="border-b-4 text-sm border-snow-500 w-2/4 md:w-full lg:w-full">
                        <div
                            className="text-right right-0 flex justify-end text-blue-900 py-1"
                            role="presentation"
                        >
                            <button
                                className="py-1 px-2 md:px-4 bg-blue-700 text-white text-sm rounded-sm focus:outline-none h-12"
                                onClick={() => history.push('/admin/companies/new')}
                            >
                                Add New Company
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
