import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { RootState } from '../../redux/rootReducer';

interface Props {
    modalVisible: boolean;
    handleClose: () => void;
}

export default function CertificatesSuccessfullModal({
    modalVisible,
    handleClose,
}: Props): ReactElement {
    const createCertificateError = useSelector(
        (state: RootState) => state.certificates.createCertificateError
    );
    const certificateCreateSuccessMessage = useSelector(
        (state: RootState) => state.certificates.certificateCreateSuccessMessage
    );
    const editCertificateError = useSelector(
        (state: RootState) => state.certificates.editCertificateError
    );
    const certificateUpdateSuccessMessage = useSelector(
        (state: RootState) => state.certificates.certificateUpdateSuccessMessage
    );
    const certificateDeleteSuccessMessage = useSelector(
        (state: RootState) => state.certificates.certificateDeleteSuccessMessage
    );
    const deleteCertificateError = useSelector(
        (state: RootState) => state.certificates.deleteCertificateError
    );
    const bulkError = useSelector((state: RootState) => state.certificates.bulkError);
    const certificatesBulkSuccessMessage = useSelector(
        (state: RootState) => state.certificates.certificatesBulkSuccessMessage
    );
    return (
        <Modal
            open={modalVisible}
            onClose={handleClose}
            center
            showCloseIcon={false}
            styles={{
                modal: {
                    borderRadius: 5,
                    maxWidth: '628px',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    boxShadow: '0 12px 15px 0 rgb(0 0 0 / 10%)',
                },
                overlay: { background: 'none' },
            }}
        >
            <div className="">
                <div className="text-base text-center text-gray-800 px-4 pt-6 pb-4">
                    {createCertificateError ||
                        certificateCreateSuccessMessage ||
                        certificateUpdateSuccessMessage ||
                        certificateDeleteSuccessMessage ||
                        editCertificateError ||
                        deleteCertificateError ||
                        certificatesBulkSuccessMessage ||
                        bulkError}
                </div>
                <div className="flex justify-end w-full">
                    <button
                        className="bg-blue-700 text-white rounded-sm focus:outline-none mt-4 px-8 mr-2 md:mr-0 py-2"
                        onClick={handleClose}
                    >
                        OK
                    </button>
                </div>
            </div>
        </Modal>
    );
}
