import React, { ReactElement } from 'react';

interface Props {
    color: string;
    size?: number;
}

export default function RequestMentor({ color, size }: Props): ReactElement {
    return (
        <svg
            width={size || '21'}
            height={size || '20'}
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.5 2V1.5H19H9.47143C9.43238 1.15849 9.35378 0.822998 9.23873 0.5H19C19.3978 0.5 19.7794 0.658035 20.0607 0.93934C20.342 1.22064 20.5 1.60218 20.5 2V13C20.5 13.3978 20.342 13.7794 20.0607 14.0607C19.7794 14.342 19.3978 14.5 19 14.5H10.5V13.5H19H19.5V13V2ZM2 11.5H1V7C1 6.60218 1.15804 6.22064 1.43934 5.93934C1.72064 5.65804 2.10218 5.5 2.5 5.5H13.5V6.5H8H7.5V7V19.5H6.5V14V13.5H6H4H3.5V14V19.5H2.5V12V11.5H2ZM6.5 2C6.5 2.39783 6.34196 2.77936 6.06066 3.06066C5.77936 3.34197 5.39782 3.5 5 3.5C4.60218 3.5 4.22064 3.34197 3.93934 3.06066C3.65804 2.77936 3.5 2.39783 3.5 2C3.5 1.60218 3.65804 1.22064 3.93934 0.93934C4.22064 0.658035 4.60218 0.5 5 0.5C5.39782 0.5 5.77936 0.658035 6.06066 0.93934C6.34196 1.22064 6.5 1.60218 6.5 2Z"
                fill={color}
                stroke={color}
            />
        </svg>
    );
}
