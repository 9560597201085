import React from 'react';
import Confetti from 'react-confetti';

export default function Confeti() {
    return (
        <div>
            <Confetti />
        </div>
    );
}
