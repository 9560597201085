import {
    ADD_COMPANY,
    ADD_COMPANY_FAILED,
    ADD_COMPANY_SUCCESS,
    CLEAR_COMPANIES_MESSAGES,
    COMPANY_BULK_UPDATE,
    COMPANY_BULK_UPDATE_FAILED,
    COMPANY_BULK_UPDATE_SUCCESS,
    DELETE_COMPANY,
    DELETE_COMPANY_FAILED,
    DELETE_COMPANY_SUCCESS,
    EDIT_COMPANY,
    EDIT_COMPANY_FAILED,
    EDIT_COMPANY_SUCCESS,
    GET_COMPANIES,
    GET_COMPANIES_FAILED,
    GET_COMPANIES_SUCCESS,
    GET_COMPANY_BY_ID,
    GET_COMPANY_BY_ID_FAILED,
    GET_COMPANY_BY_ID_SUCCESS,
    GET_PUBLIC_COMPANY_BY_ID,
    GET_PUBLIC_COMPANY_BY_ID_FAILED,
    GET_PUBLIC_COMPANY_BY_ID_SUCCESS,
    GET_COMPANY_PUBLIC,
    GET_COMPANY_PUBLIC_FAILED,
    GET_COMPANY_PUBLIC_SUCCESS,
} from './actions';

export interface CompanyEditType {
    accountType: string;
    comEmail: string;
    comId: string;
    comName: string;
    contactNo: number;
    repName: string;
    image: string;
    slassMember: boolean;
    comDescription: string;
    subStartDate: string;
    subEndDate: string;
}
export interface CompanyType {
    id: string;
    accountType: string;
    comEmail: string;
    comLocation: string;
    comId: string;
    comJoinedDate: string;
    comName: string;
    comStatus: string;
    contactNo: number;
    repName: string;
    comImage: string;
    bannerImage: string;
    slassMember: boolean;
    comDescription: string;
    subStartDate: string;
    subEndDate: string;
    siteUrl: string;
    careerOpportunities: string[];
    applyURL: string;
    tags: any;
}
export interface CountType {
    ALL: number;
    ACTIVE: number;
    INACTIVE: number;
    DELETED: number;
}
export interface CompaniesInitialState {
    companiesData: CompanyType[];
    companyPublicData: CompanyType[];
    company: CompanyType;
    publicCompany: CompanyType;
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    isLoadingPublicData: boolean;
    companyByIdLoading: boolean;
    companyByIdError: string | null;
    companyPublicError: string | null;
    companyError: string | null;
    createCompanyError: string | null;
    companyCreateSuccessMessage: string | null;
    isDeleteLoading: boolean;
    deleteCompanyError: string | null;
    companyDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editCompanyError: string | null;
    companyUpdateSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    companyBulkSuccessMessage: string | null;
}

const initialState: CompaniesInitialState = {
    companiesData: [],
    companyPublicData: [],
    company: null,
    publicCompany: null,
    pageCount: null,
    count: null,
    isLoading: false,
    isLoadingPublicData: null,
    companyByIdLoading: false,
    companyByIdError: null,
    companyPublicError: null,
    companyError: null,
    createCompanyError: null,
    companyCreateSuccessMessage: null,
    isDeleteLoading: false,
    deleteCompanyError: null,
    companyDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editCompanyError: null,
    companyUpdateSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    companyBulkSuccessMessage: null,
};

const companiesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_COMPANIES:
            return { ...state, isLoading: true, companyError: null };
        case GET_COMPANIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                companiesData: action.payload.items,
                pageCount: action.payload.totalPages,
            };
        case GET_COMPANIES_FAILED:
            return { ...state, isLoading: false, companyError: action.payload };
        case GET_COMPANY_PUBLIC:
            return { ...state, isLoadingPublicData: true, companyPublicError: null };
        case GET_COMPANY_PUBLIC_SUCCESS:
            return {
                ...state,
                isLoadingPublicData: false,
                companyPublicData: action.payload,
            };
        case GET_COMPANY_PUBLIC_FAILED:
            return { ...state, isLoadingPublicData: false, companyPublicError: action.payload };
        case GET_COMPANY_BY_ID:
            return { ...state, companyByIdLoading: true, companyByIdError: null };
        case GET_COMPANY_BY_ID_SUCCESS:
            return {
                ...state,
                companyByIdLoading: false,
                company: action.payload,
            };
        case GET_COMPANY_BY_ID_FAILED:
            return { ...state, companyByIdLoading: false, companyByIdError: action.payload };
        case GET_PUBLIC_COMPANY_BY_ID:
            return { ...state, companyByIdLoading: true, companyByIdError: null };
        case GET_PUBLIC_COMPANY_BY_ID_SUCCESS:
            return {
                ...state,
                companyByIdLoading: false,
                publicCompany: action.payload,
            };
        case GET_PUBLIC_COMPANY_BY_ID_FAILED:
            return { ...state, companyByIdLoading: false, companyByIdError: action.payload };

        case ADD_COMPANY:
            return { ...state, isCreateLoading: true, createCompanyError: null };
        case ADD_COMPANY_SUCCESS:
            return {
                ...state,
                isCreateLoading: false,
                companyCreateSuccessMessage: action.payload,
            };
        case ADD_COMPANY_FAILED:
            return { ...state, isCreateLoading: false, createCompanyError: action.payload };
        case DELETE_COMPANY:
            return { ...state, isDeleteLoading: true, deleteCompanyError: null };
        case DELETE_COMPANY_SUCCESS:
            return {
                ...state,
                isDeleteLoading: false,
                companyDeleteSuccessMessage: action.payload,
            };
        case DELETE_COMPANY_FAILED:
            return { ...state, isDeleteLoading: false, deleteCompanyError: action.payload };
        case EDIT_COMPANY:
            return { ...state, isEditLoading: true, editCompanyError: null };
        case EDIT_COMPANY_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                companyUpdateSuccessMessage: action.payload,
            };
        case EDIT_COMPANY_FAILED:
            return { ...state, isEditLoading: false, editCompanyError: action.payload };
        case COMPANY_BULK_UPDATE:
            return { ...state, isBulkUpdateLoading: true, bulkError: null };
        case COMPANY_BULK_UPDATE_SUCCESS:
            return {
                ...state,
                isBulkUpdateLoading: false,
                companyBulkSuccessMessage: action.payload,
            };
        case COMPANY_BULK_UPDATE_FAILED:
            return { ...state, isBulkUpdateLoading: false, bulkError: action.payload };
        case CLEAR_COMPANIES_MESSAGES:
            return {
                ...state,
                companyCreateSuccessMessage: null,
                createCompanyError: null,
                companyUpdateSuccessMessage: null,
                editCompanyError: null,
                deleteCompanyError: null,
                companyDeleteSuccessMessage: null,
                companyBulkSuccessMessage: null,
                bulkError: null,
            };
        default:
            return state;
    }
};

export default companiesReducer;
