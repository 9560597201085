import {
    ADD_CHALLENGE_TAB,
    ADD_CHALLENGE_TAB_FAILED,
    ADD_CHALLENGE_TAB_SUCCESS,
    GET_CHALLENGE_TAB,
    GET_CHALLENGE_TAB_FAILED,
    GET_CHALLENGE_TAB_SUCCESS,
} from './actions';

export interface MaterialType {
    description: string;
    questionnaire: any;
    encryptQuestionnaire: any;
}
export interface ChallengesType {
    id: string;
    challengeId: string;
    challengeName: string;
    challengeDescription: string;
    challengeStatus: string;
    challengeCreatedBy: string;
    challengeCreatedDate: string;
    challengeLastUpdatedBy: string;
    challengeLastUpdatedDate: string;
    pointsAwarded: number;
    prerequisitePoints: number;
    imageLink: string;
    introLink: string;
    material: MaterialType;
    users: any;
    submissions: any;
    tags: any;
    trackIds: [];
}
export interface ChallengeCreateType {
    challengeName: string;
    challengeStatus: string;
    challengeCreatedBy?: string;
    challengeLastUpdatedBy?: string;
    pointsAwarded: number;
    prerequisitePoints: number;
    imageLink: string;
    introLink: string;
}

export interface CountType {
    PUBLISHED: number;
    UNPUBLISHED: number;
    DELETED: number;
    ALL: number;
}

export interface ChallengesTabInitialState {
    challengesData: ChallengesType[];
    challenge: ChallengesType;
    challengeTabData: [];
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    challengeByIdLoading: boolean;
    challengeByIdError: string | null;
    challengesError: string | null;
    createChallengeError: string | null;
    challengeCreateSuccessMessage: string | null;
    isDeleteLoading: boolean;
    deleteChallengeError: string | null;
    challengeDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editChallengeError: string | null;
    challengeUpdateSuccessMessage: string | null;
    isAddMaterialLoading: boolean;
    materialError: string | null;
    materialSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    challengeBulkSuccessMessage: string | null;
}

const initialState: ChallengesTabInitialState = {
    challengesData: [],
    challenge: null,
    challengeTabData: [],
    pageCount: null,
    count: null,
    isLoading: false,
    challengeByIdLoading: false,
    challengeByIdError: null,
    challengesError: null,
    createChallengeError: null,
    challengeCreateSuccessMessage: null,
    isDeleteLoading: false,
    deleteChallengeError: null,
    challengeDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editChallengeError: null,
    challengeUpdateSuccessMessage: null,
    isAddMaterialLoading: false,
    materialError: null,
    materialSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    challengeBulkSuccessMessage: null,
};

const challengesTabReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_CHALLENGE_TAB:
            return { ...state, isLoading: true, trackError: null };
        case ADD_CHALLENGE_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                challengesData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case ADD_CHALLENGE_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        case GET_CHALLENGE_TAB:
            return { ...state, isLoading: true, trackError: null };
        case GET_CHALLENGE_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                challengeTabData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case GET_CHALLENGE_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        default:
            return state;
    }
};

export default challengesTabReducer;
