import React, { ReactElement } from 'react';

export default function Footer(): ReactElement {
    return (
        <>
            <div
                className="hidden lg:flex flex-col xl:flex-row justify-between items-center z-50 border-t-2 px-10 lg:border-l-2 border-gray-100 py-4 bg-white rounded-sm w-full"
                style={{
                    marginTop: 'auto',
                    // height: '68px',
                }}
            >
                <div
                    className="font-manrope"
                    style={{
                        color: '#636668',
                    }}
                >
                    &copy; {new Date().getFullYear()} An initiative by{' '}
                    <span className="inline-flex items-baseline">
                        <img
                            src="/assets/slasscom_logo.png"
                            alt="SLASSCOM logo"
                            className="self-center  px-2"
                            onClick={() => window.open('https://slasscom.lk/', '_blank')}
                        />

                        <span>, facilitated by</span>
                    </span>
                    <span className="inline-flex items-baseline">
                        <img
                            src="/assets/USAID_logo.png"
                            alt="USAID logo"
                            className="self-center px-2"
                            onClick={() => window.open('https://www.usaid.gov/', '_blank')}
                        />
                        <img
                            src="/assets/youlead_logo.png"
                            alt="YouLead logo"
                            className="self-center px-2"
                            onClick={() => window.open('https://www.youlead.lk/', '_blank')}
                        />
                        <img
                            src="/assets/RNE_logo.png"
                            alt="RNE logo"
                            className="self-center px-2"
                            onClick={() =>
                                window.open('https://www.norway.no/en/sri-lanka/', '_blank')
                            }
                        />
                        <span>All Rights Reserved.</span>
                    </span>
                </div>

                <div className="flex space-x-14">
                    <a
                        href="/termsAndConditions"
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                    >
                        Privacy policy
                    </a>
                    <a
                        href="/termsAndConditions"
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                    >
                        Terms
                    </a>
                    <div className="flex space-x-9 items-center justify-center">
                        <a
                            href="https://www.instagram.com/futurecareersbridge/?hl=en"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src="/assets/instagram.png"
                                alt="signup-Google-icon-b"
                                className="mr-1 cursor-pointer"
                            />
                        </a>
                        <a
                            href="https://www.facebook.com/futurecareersbridge/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src="/assets/fa-facebook-square.png"
                                alt="signup-facebook-icon-b"
                                className="mr-1 cursor-pointer"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div
                className="flex lg:hidden justify-center items-center z-50 border-t-2 lg:border-l-2 border-gray-100 py-4 bg-white rounded-sm"
                style={
                    window.location.pathname.includes('/dashBoard')
                        ? {
                              marginTop: 'auto',
                              marginLeft: '55px',
                              marginRight: '40px',
                              height: '68px',
                              width: 'calc(100vw - 50px)',
                          }
                        : {
                              marginTop: 'auto',
                              height: '68px',
                              width: 'calc(100vw)',
                          }
                }
            >
                <div
                    className="font-manrope text-center"
                    style={{
                        color: '#636668',
                        fontSize: '0.625rem',
                    }}
                >
                    <div>
                        &copy; {new Date().getFullYear()} An initiative by{' '}
                        <span className="inline-flex items-baseline">
                            <img
                                src="/assets/slasscom_logo.png"
                                alt="SLASSCOM logo"
                                className="self-center w-auto h-3 px-1"
                                onClick={() => window.open('https://slasscom.lk/', '_blank')}
                            />
                        </span>
                        <br />
                        <span className="inline-flex items-baseline">
                            <span>, facilitated by</span>
                            <img
                                src="/assets/USAID_logo.png"
                                alt="USAID logo"
                                className="self-center w-auto h-3 px-2"
                                onClick={() => window.open('https://www.usaid.gov/', '_blank')}
                            />
                            <img
                                src="/assets/youlead_logo.png"
                                alt="YouLead logo"
                                className="self-center w-auto h-4 px-1"
                                onClick={() => window.open('https://www.youlead.lk/', '_blank')}
                            />
                            <img
                                src="/assets/RNE_logo.png"
                                alt="RNE logo"
                                className="self-center w-auto h-3 px-2"
                                onClick={() =>
                                    window.open('https://www.norway.no/en/sri-lanka/', '_blank')
                                }
                            />
                            <span>All Rights Reserved.</span>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}
