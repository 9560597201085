import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../../../assets/svg/Loader';
import { webSendEmailToResetPassword } from '../../../../../../redux/rootActions';
import { RootState } from '../../../../../../redux/rootReducer';

interface Props {
    successEmail: boolean;
    verifyEmail: string;
    setVerifyEmail: (value: string) => void;
    setforgotPassword: (value: boolean) => void;
    setSuccessEmail: (value: boolean) => void;
    setIsErrorForgetPassword: (value: boolean) => void;
    isErrorForgetPassword: boolean;
    setErrorForgetPassword: (value: boolean) => void;
    errorForgetPassword: boolean;
    isEmailSending: boolean;
}

export default function ForgotPassword({
    successEmail,
    isErrorForgetPassword,
    setIsErrorForgetPassword,
    errorForgetPassword,
    setErrorForgetPassword,
    isEmailSending,
    setforgotPassword,
    setSuccessEmail,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const ForgotEmailSent = useSelector((state: RootState) => state?.webAuth?.isWebEmailSending);
    const ForgotEmailError = useSelector((state: RootState) => state?.webAuth?.emailSentError);

    const [emailResentSuccess, setEmailResentSuccess] = useState(false);
    const [emailResentError, setEmailResentError] = useState(false);

    const [verifyEmail, setVerifyEmail] = useState<string>();

    useEffect(() => {
        if (emailResentSuccess) {
            setTimeout(() => {
                setEmailResentSuccess(false);
            }, 3000);
        }
        if (emailResentError) {
            setTimeout(() => {
                setEmailResentError(false);
            }, 3000);
        }
    }, [emailResentError, emailResentSuccess]);

    useEffect(() => {
        if (ForgotEmailSent) {
            setEmailResentSuccess(true);
        }
        if (ForgotEmailError) {
            setEmailResentError(true);
        }
    }, [ForgotEmailError, ForgotEmailSent]);

    return (
        <>
            <div className="hidden lg:block">
                {successEmail === false ? (
                    <>
                        <div
                            className="md:mt-11"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <div>
                                <div className="text-3xl-2 font-semibold">Forgot Password</div>
                            </div>
                        </div>
                        <div className="forgotContainer mt-2">
                            <p>
                                Enter the email address you used to register in order to reset your
                                password. The instructions will be sent to the email address you
                                have entered upon registration.
                            </p>
                            <div className="my-4 md:my-6">
                                <label
                                    className="block text-sm  leading-149 mb-2"
                                    htmlFor={'email'}
                                >
                                    Email Address
                                </label>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="Email Address"
                                    className="w-full pb-2 md:pb-3 border-b text-base focus:border-blue-900 border-gray-600"
                                    value={verifyEmail}
                                    onChange={e => {
                                        setVerifyEmail(e.target.value);
                                        setIsErrorForgetPassword(false);
                                    }}
                                />
                            </div>
                            {isErrorForgetPassword ? (
                                <div className="text-red-500 text-xs my-1 text-center">
                                    {errorForgetPassword}
                                </div>
                            ) : null}
                            <button
                                className={`text-white text-snow-900 text-base rounded border hover:border-transparent w-40 h-10 sm:w-80 md:w-full sm:h-12 mb-4 ${
                                    verifyEmail?.length === 0 ? 'bg-purple-400' : 'bg-purple-950'
                                }`}
                                style={{
                                    boxShadow: '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                }}
                                onClick={() => {
                                    dispatch(webSendEmailToResetPassword(verifyEmail));
                                }}
                                disabled={verifyEmail?.length <= 0}
                            >
                                {isEmailSending ? <Loader /> : 'Send Email'}
                            </button>
                            <div className="text-sm text-center mb-10 md:text-base md:mb-20">
                                <span
                                    className="text-purple-950 cursor-pointer font-semibold"
                                    role="presentation"
                                    onClick={() => {
                                        setforgotPassword(false);
                                        setIsErrorForgetPassword(false);
                                    }}
                                >
                                    Return to Sign In
                                </span>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className="md:mt-11"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <div>
                                <h2 className="text-3xl-2 font-semibold">Check your Mail</h2>
                            </div>
                        </div>
                        <div className="forgotContainer mt-2">
                            <p>
                                Please refer the password reset instructions sent to your email “
                                <a
                                    className="text-purple-950 font-semibold"
                                    href={`mailto: ${verifyEmail}`}
                                >
                                    {verifyEmail}
                                </a>
                                ”
                            </p>

                            <div className="text-sm text-center my-3 md:text-base md:mb-6 hover:underline">
                                <span
                                    className="text-purple-950 cursor-pointer font-semibold"
                                    onClick={() => {
                                        dispatch(webSendEmailToResetPassword(verifyEmail));
                                    }}
                                >
                                    {isEmailSending ? <Loader /> : 'Resend'}
                                </span>
                            </div>
                            {emailResentSuccess ? (
                                <div className="mt-2 flex justify-center text-center text-green-400">
                                    <img
                                        src="/assets/tick.png"
                                        className="mr-2 w-4 h-4 mt-1"
                                        alt=""
                                    />
                                    Confirmation email is resent!
                                </div>
                            ) : null}
                            {emailResentError ? (
                                <div className="mt-2 text-center text-red-400">
                                    {ForgotEmailError}
                                </div>
                            ) : null}
                            <div className="text-sm text-center mb-3 md:text-base md:mb-8">
                                <span
                                    className="text-purple-950 cursor-pointer font-semibold"
                                    role="presentation"
                                    onClick={() => {
                                        setforgotPassword(false);
                                        setSuccessEmail(false);
                                    }}
                                >
                                    Return to Sign In
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="lg:hidden">
                {successEmail === false ? (
                    <>
                        <div
                            className="mt-6"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <div>
                                <div className="text-lg font-semibold">Forgot Password</div>
                            </div>
                        </div>
                        <div className="forgotContainer mt-4 text-sm">
                            <p>
                                Enter the email address you used to register in order to reset your
                                password. The instructions will be sent to the email address you
                                have entered upon registration.
                            </p>
                            <div className="my-4 md:my-6">
                                <label className="block text-xs leading-149 mb-2" htmlFor={'email'}>
                                    Email Address
                                </label>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="Email Address"
                                    className="w-full pb-2 md:pb-3 border-b text-base focus:border-blue-900 border-gray-600"
                                    value={verifyEmail}
                                    onChange={e => {
                                        setVerifyEmail(e.target.value);
                                        setIsErrorForgetPassword(false);
                                    }}
                                />
                            </div>
                            {isErrorForgetPassword ? (
                                <div className="text-red-500 text-xs my-1 text-center">
                                    {errorForgetPassword}
                                </div>
                            ) : null}
                            <button
                                className={`text-white text-snow-900 text-base rounded border hover:border-transparent w-full h-12 mb-6 ${
                                    verifyEmail?.length === 0 ? 'bg-purple-400' : 'bg-purple-950'
                                }`}
                                style={{
                                    boxShadow: '0px 10px 15px rgba(3, 17, 86, 0.25)',
                                }}
                                onClick={() => {
                                    dispatch(webSendEmailToResetPassword(verifyEmail));
                                }}
                                disabled={verifyEmail?.length <= 0}
                            >
                                {isEmailSending ? <Loader /> : 'Send Email'}
                            </button>
                            <div className="text-sm text-center mb-10">
                                <span
                                    className="text-purple-950 cursor-pointer font-semibold"
                                    role="presentation"
                                    onClick={() => {
                                        setforgotPassword(false);
                                        setIsErrorForgetPassword(false);
                                    }}
                                >
                                    Return to Sign In
                                </span>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className="mt-6"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <div>
                                <h2 className="text-lg font-semibold">Check your Mail</h2>
                            </div>
                        </div>
                        <div className="forgotContainer mt-4 text-sm">
                            <p>
                                Please refer the password reset instructions sent to your email “
                                <a
                                    className="text-purple-950 font-semibold"
                                    href={`mailto: ${verifyEmail}`}
                                >
                                    {verifyEmail}
                                </a>
                                ”
                            </p>

                            <div className="text-sm text-center my-8 hover:underline">
                                <span
                                    className="text-purple-950 cursor-pointer font-semibold"
                                    onClick={() => {
                                        dispatch(webSendEmailToResetPassword(verifyEmail));
                                    }}
                                >
                                    {isEmailSending ? <Loader /> : 'Resend'}
                                </span>
                            </div>
                            {emailResentSuccess ? (
                                <div className="mt-4 flex justify-center text-center text-green-400 text-xs">
                                    <img
                                        src="/assets/tick.png"
                                        className="mr-2 w-4 h-4 mt-1"
                                        alt=""
                                    />
                                    Confirmation email is resent!
                                </div>
                            ) : null}
                            {emailResentError ? (
                                <div className="mt-4 text-center text-red-400">
                                    {ForgotEmailError}
                                </div>
                            ) : null}
                            <div className="text-sm text-center mb-3 md:text-base md:mb-8">
                                <span
                                    className="text-purple-950 cursor-pointer font-semibold"
                                    role="presentation"
                                    onClick={() => {
                                        setforgotPassword(false);
                                        setSuccessEmail(false);
                                    }}
                                >
                                    Return to Sign In
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
