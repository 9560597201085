import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTable, useRowSelect, usePagination } from 'react-table';
import * as BsIcons from 'react-icons/bs';
import { IconContext } from 'react-icons';
import { RootState } from '../../../redux/rootReducer';
import { StudentType } from '../../../redux/students/reducers';
import MenteeTableTabView from './MenteeTableTabView';
import RemoveMenteeConfirmation from '../../modals/RemoveMenteeConfirmation';
import AddMenteeConfirmation from '../../modals/AddMenteeConfirmation';
import SaveBeforeChangeAlertModal from '../../modals/SaveBeforeChangeAlertModal';
import CloseIcon from '../../../assets/svg/CloseIcon';

interface Props {
    data: StudentType[];
    columns: any;
    fetchData;
    pageCount: number;
    selectedTab?: number;
    setSelectedTab?: (value: number) => void;
    menteeArray?: any[];
    setMenteeArray?: (value: any[]) => void;
}

const MenteeTable = ({
    columns,
    data,
    fetchData,
    pageCount: controlledPageCount,
    selectedTab,
    setSelectedTab,
    setMenteeArray,
    menteeArray,
}: Props) => {
    const [clickedColumnId, setClickedColumnId] = useState();
    const [clickedStudent, setClickedStudent] = useState<any>();
    const [showsDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showsAddConfirmation, setShowAddConfirmation] = useState(false);
    const [showsAlreadyModified, setShowsAlreadyModified] = useState(false);
    const [menteeDeleteCommand, setMenteeDeleteCommand] = useState(false);
    const [menteeAddCommand, setMenteeAddCommand] = useState(false);

    const tabCounts = useSelector((state: RootState) => state.students.count);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
            manualPagination: true,
            pageCount: controlledPageCount,
        },
        usePagination,
        useRowSelect
    );

    useEffect(() => {
        fetchData({ pageIndex });
    }, [fetchData, pageIndex]);

    const addMentee = record => {
        if (!record?.mentor) {
            setMenteeArray([...menteeArray, { userId: record.id, command: 'ASSIGN' }]);
            setMenteeAddCommand(false);
            setClickedStudent({});
        }
    };

    const removeMentee = record => {
        if (record?.mentor) {
            setMenteeArray([...menteeArray, { userId: record.id, command: 'UNASSIGN' }]);
            setMenteeDeleteCommand(false);
            setClickedStudent({});
        }
    };

    useEffect(() => {
        if (menteeAddCommand && clickedStudent) {
            addMentee(clickedStudent);
        } else if (menteeDeleteCommand && clickedStudent) {
            removeMentee(clickedStudent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menteeDeleteCommand, menteeAddCommand]);

    const handleMentee = record => {
        const isOnMenteeArray = menteeArray.find(obj => {
            return obj.userId === record.id;
        });
        if (!isOnMenteeArray) {
            if (record?.mentor) {
                setShowDeleteConfirmation(true);
            } else {
                setShowAddConfirmation(true);
            }
        } else {
            setShowsAlreadyModified(true);
        }
    };

    return (
        <section className="py-1 h-full">
            {window.location.pathname.includes('/edit') && (
                <div className="flex justify-between w-full items-end mb-4">
                    <div className="w-5/6 md:w-full">
                        <MenteeTableTabView
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            tabCounts={tabCounts}
                        />
                    </div>
                </div>
            )}
            <div className="w-full mb-8">
                <div className="w-full overflow-x-auto pl-1 h-full" style={{ minHeight: '50vh' }}>
                    <table className="w-full border-b-2 border-gray-300" {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr
                                    key={headerGroup}
                                    {...headerGroup.getHeaderGroupProps()}
                                    className="text-sm xl:text-base font-medium tracking-wide text-start text-gray-700 bg-gray-100"
                                >
                                    {headerGroup.headers.map(column => (
                                        <th
                                            key={column}
                                            {...column.getHeaderProps()}
                                            className={`align-top px-2 py-1 border-2 border-gray-300 ${
                                                column.id === clickedColumnId ? 'relative' : ''
                                            }`}
                                        >
                                            <div className="whitespace-nowrap">
                                                {column.render('Header')}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="bg-white" {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <tr
                                        key={row}
                                        {...row.getRowProps()}
                                        className="text-gray-700 border-l-2"
                                    >
                                        {row.cells.map(cell => {
                                            return (
                                                <td
                                                    key={cell}
                                                    {...cell.getCellProps({})}
                                                    className={`py-1 text-sm font-semibold px-2 border-r-2 border-gray-300`}
                                                    onClick={() => {
                                                        if (cell.column.id === 'actionColumn') {
                                                            handleMentee(row.original);
                                                            setClickedStudent(row.original);
                                                        }
                                                    }}
                                                >
                                                    {cell.column.id !== 'actionColumn' ? (
                                                        cell.render('Cell')
                                                    ) : cell.value === 'Remove' ? (
                                                        <CloseIcon size={15} color="red" />
                                                    ) : (
                                                        <div className="text-blue-700 underline cursor-pointer">
                                                            Add
                                                        </div>
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-start my-8">
                    <IconContext.Provider
                        value={{ color: !canPreviousPage ? '#bbbcbc' : '#707a92' }}
                    >
                        <button
                            className="text-xl font-medium text-gray-900"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            <BsIcons.BsChevronLeft className="" />
                        </button>
                    </IconContext.Provider>
                    <span className="flex items-center font-semibold text-gray-700">
                        <p className="border px-2 mx-2 my-auto">{pageIndex + 1}</p>
                        of
                        <p className="border px-2 mx-2 my-auto">
                            {pageOptions.length === 0 ? '1' : pageOptions.length}
                        </p>
                    </span>
                    <IconContext.Provider value={{ color: !canNextPage ? '#bbbcbc' : '#707a92' }}>
                        <button
                            className="text-xl font-medium text-gray-900"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        >
                            <BsIcons.BsChevronRight className="" />
                        </button>
                    </IconContext.Provider>
                </div>
            </div>
            {showsDeleteConfirmation && (
                <RemoveMenteeConfirmation
                    modalVisible={showsDeleteConfirmation}
                    setModalVisible={setShowDeleteConfirmation}
                    setMenteeDeleteCommand={setMenteeDeleteCommand}
                    clickedStudent={clickedStudent}
                />
            )}
            {showsAddConfirmation && (
                <AddMenteeConfirmation
                    modalVisible={showsAddConfirmation}
                    setModalVisible={setShowAddConfirmation}
                    setMenteeAddCommand={setMenteeAddCommand}
                    clickedStudent={clickedStudent}
                />
            )}
            {showsAlreadyModified && (
                <SaveBeforeChangeAlertModal
                    modalVisible={showsAlreadyModified}
                    setModalVisible={setShowsAlreadyModified}
                />
            )}
        </section>
    );
};

export default MenteeTable;
