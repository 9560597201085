import { useEffect, useState } from 'react';

export default function useWidth() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const [width, setWidth] = useState(
        typeof window !== 'undefined'
            ? window.innerWidth
            : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              100 * (typeof window !== 'undefined' ? window.visualViewport?.width : 1)
    );

    useEffect(() => {
        const onResize = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const widthUpdate =
                typeof window !== 'undefined'
                    ? window.innerWidth
                    : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      100 * (typeof window !== 'undefined' ? window.visualViewport?.width : 1);

            setWidth(widthUpdate);
        };

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', onResize);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', onResize);
            }
        };
    });

    return width;
}
