/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import moment from 'moment';
import { NOTIFICATION_COUNT } from '../../../utils/storageWeb';

const Learn = () => {
    const notifications = useSelector(
        (state: RootState) => state.WebNotification.webNotificationsData
    );
    const currentTime = new Date();
    const [todayNotifcations, setTodayNotifcations] = useState<any>(null);
    const [olderNotifcations, setOlderNotifcations] = useState<any>(null);

    useEffect(() => {
        if (Array.isArray(notifications) && notifications.length !== 0) {
            setOlderNotifcations(
                notifications.filter(
                    notifi =>
                        moment(notifi.notificationDate).format('YYYY-MM-DD') <
                        moment(currentTime).format('YYYY-MM-DD')
                )
            );
            const today = notifications.filter(
                notifi =>
                    moment(notifi.notificationDate).format('YYYY-MM-DD') ===
                    moment(currentTime).format('YYYY-MM-DD')
            );
            setTodayNotifcations(today);
            localStorage.setItem(
                NOTIFICATION_COUNT,
                JSON.stringify({ count: today.length, date: new Date() })
            );
        }
    }, [notifications]);

    const timeTif = date => {
        let finalTime: string;
        let minutesDif = moment(currentTime).diff(moment(new Date(date)), 'minutes');
        if (minutesDif >= 1440) {
            minutesDif = Math.floor(minutesDif / 1440);
            finalTime = minutesDif.toString() + ` day${minutesDif > 1 ? 's' : ''} ago`;
        } else if (minutesDif >= 60) {
            minutesDif = Math.floor(minutesDif / 60);
            finalTime = minutesDif.toString() + ` hour${minutesDif > 1 ? 's' : ''} ago`;
        } else {
            finalTime = minutesDif.toString() + ` minute${minutesDif > 1 ? 's' : ''} ago`;
        }
        return finalTime;
    };

    return (
        <>
            <div
                className={`mx-10 md:mx-5 md:ml-14 lg:ml-72 lg:pl-3.5 lg:pt-2 mt-6 hidden lg:flex ${
                    notifications.length === 0 ? 'justify-center items-center' : ''
                } `}
            >
                {notifications.length > 0 ? (
                    <div className={`w-full`}>
                        {todayNotifcations && (
                            <div
                                className={` ${todayNotifcations.length > 0 ? 'mb-12' : 'hidden'}`}
                            >
                                <div className="text-lg font-semibold font-manrope mb-2.5">
                                    Today
                                </div>
                                {todayNotifcations.map(notification => (
                                    <>
                                        {notification.content && (
                                            <div
                                                key={notification.id}
                                                className="font-manrope inputFieldThickness py-2 px-3.5 mb-2"
                                            >
                                                <div className="flex justify-between">
                                                    <div className="text-lg font-semibold font-manrope">
                                                        {notification.campaignTitle}
                                                    </div>
                                                    <div className="text-sm mt-1.5">
                                                        {timeTif(notification.notificationDate)}
                                                    </div>
                                                </div>
                                                <div className="mt-2">{notification.content}</div>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        )}
                        {olderNotifcations && (
                            <div>
                                <div className="text-lg font-semibold font-manrope mb-7">Older</div>
                                {olderNotifcations.map(notification => (
                                    <>
                                        {notification.content && (
                                            <div
                                                key={notification.id}
                                                className="font-manrope py-4 px-8 mb-6 "
                                                style={{
                                                    border: '1px solid #D6D8E7',
                                                    borderRadius: '5px',
                                                }}
                                            >
                                                <div className="flex justify-between">
                                                    <div className="text-lg font-semibold font-manrope text-purple-800">
                                                        {notification.campaignTitle}
                                                    </div>
                                                    <div className="text-sm font-manrope mt-1.5">
                                                        {timeTif(notification.notificationDate)}
                                                    </div>
                                                </div>
                                                <div className="mt-3 text-lg font-manrope">
                                                    {notification.content}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="text-lg font-semibold font-manrope text-center">
                        You dont have any notifications.
                    </div>
                )}
            </div>
            <div
                className={`mx-10 md:mx-5 md:ml-14 lg:ml-72 lg:pl-3.5 lg:pt-2 mt-6 flex lg:hidden ${
                    notifications.length === 0 ? 'justify-center items-center' : ''
                } `}
            >
                {notifications.length > 0 ? (
                    <div className={`w-full`}>
                        {todayNotifcations && (
                            <div
                                className={` ${todayNotifcations.length > 0 ? 'mb-12' : 'hidden'}`}
                            >
                                <div className="text-lg font-semibold font-manrope mb-2.5">
                                    Today
                                </div>
                                {todayNotifcations.map(notification => (
                                    <>
                                        {notification.content && (
                                            <div
                                                key={notification.id}
                                                className="font-manrope inputFieldThickness py-4 px-3.5 mb-2"
                                            >
                                                <div className="flex justify-between items-center">
                                                    <div className="text-lg font-medium font-manrope">
                                                        {notification.campaignTitle}
                                                    </div>
                                                    <div
                                                        className="mt-1.5 font-manrope"
                                                        style={{ fontSize: 10 }}
                                                    >
                                                        {timeTif(notification.notificationDate)}
                                                    </div>
                                                </div>
                                                <div
                                                    className="mt-2 font-manrope font-medium"
                                                    style={{ fontSize: 8 }}
                                                >
                                                    {notification.content}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        )}
                        {olderNotifcations && (
                            <div>
                                <div className="text-lg font-semibold font-manrope mb-7">Older</div>
                                {olderNotifcations.map(notification => (
                                    <>
                                        {notification.content && (
                                            <div
                                                key={notification.id}
                                                className="inputFieldThickness font-manrope py-4 px-3.5 mb-6 "
                                            >
                                                <div className="flex justify-between items-center">
                                                    <div className="text-lg font-medium font-manrope text-purple-800">
                                                        {notification.campaignTitle}
                                                    </div>
                                                    <div
                                                        className="font-manrope mt-1.5"
                                                        style={{ fontSize: 10 }}
                                                    >
                                                        {timeTif(notification.notificationDate)}
                                                    </div>
                                                </div>
                                                <div
                                                    className="mt-2 font-manrope font-medium"
                                                    style={{ fontSize: 8 }}
                                                >
                                                    {notification.content}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="text-lg font-semibold font-manrope text-center">
                        You dont have any notifications.
                    </div>
                )}
            </div>
        </>
    );
};

export default Learn;
