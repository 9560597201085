import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import Slider from 'react-slick';
import Modal from 'react-modal';
import CloseIcon from '../../assets/svg/CloseIcon';
import getCroppedImg from '../../utils/cropImage/cropImage';

const CropModal = ({
    ratio,
    photoURL,
    setPhotoURL,
    setOpenCrop,
    openCrop,
    uploadImageToS3,
    handleCloseCropModal,
}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const cropComplete = (croppedArea, croppedPixels) => {
        setCroppedAreaPixels(croppedPixels);
    };
    const zoomPercent = value => {
        return `${Math.round(value * 100)}%`;
    };
    const cropImage = async () => {
        try {
            const { file, url }: any = await getCroppedImg(photoURL, croppedAreaPixels);
            setPhotoURL(url);
            uploadImageToS3(file);
            setOpenCrop(false);
        } catch (error) {
            // setAlert({
            //     isAlert: true,
            //     severity: 'error',
            //     message: error.message,
            //     timeout: 5000,
            //     location: 'modal',
            // });
            console.log(error);
        }
    };
    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };
    return (
        <Modal isOpen={openCrop} style={customStyles} ariaHideApp={false}>
            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                <div
                    style={{ width: '643px' }}
                    className="rounded-lg relative my-6  mx-auto bg-white border-2 border-blue-700"
                >
                    <div
                        className="absolute z-50 top-6 right-6 cursor-pointer"
                        onClick={handleCloseCropModal}
                    >
                        <CloseIcon size={24} color="#333" />
                    </div>
                    <div className="font-bold px-6 py-6 text-lg">Crop Image</div>
                    <div className="z-auto relative h-96">
                        <Cropper
                            image={photoURL}
                            crop={crop}
                            zoom={zoom}
                            aspect={ratio}
                            maxZoom={4}
                            onZoomChange={setZoom}
                            onCropChange={setCrop}
                            onCropComplete={cropComplete}
                        />
                    </div>
                    <div className="flex-col mx-3 my-2">
                        <div className="w-full font-bold">Zoom:{zoomPercent(zoom)}</div>
                        <div className="flex justify-end">
                            <button
                                className="rounded-lg md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm  md:w-1/4 my-2 focus:outline-none"
                                onClick={handleCloseCropModal}
                            >
                                Cancel
                            </button>
                            <button
                                className="rounded-lg md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm  md:w-1/4 my-2 focus:outline-none"
                                onClick={cropImage}
                            >
                                Crop
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default CropModal;
