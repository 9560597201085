import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { accountType } from '../../../../../constants';
import { getCompanyPublicData } from '../../../../../redux/rootActions';
import { RootState } from '../../../../../redux/rootReducer';
import { USER_PROFILE } from '../../../../utils/storageWeb';
import CompanyCard from './CompanyCard';

const CompanyAll = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const companyOpenData = useSelector((state: RootState) => state.companies.companyPublicData);

    useEffect(() => {
        dispatch(getCompanyPublicData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="hidden lg:block">
                <div className="bg-purple-85 ">
                    <div className="flex justify-center bg-purple-85">
                        <div className="space-y-5 my-20">
                            <div className=" text-4xl flex justify-center font-semibold">
                                Explore Our Companies
                            </div>
                            <div className=" text-purple-950 flex justify-center text-xl font-semibold">
                                Explore pioneers in Sri Lankan Tech Biz
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-12">
                    {
                        // accounttype is to be completed
                    }
                    <div className="font-bold text-xl text-center mb-2">
                        Premium Corporate Partners
                    </div>
                    <div
                        style={{
                            flexGrow: 1,
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}
                        className="premDiv flex justify-center "
                    >
                        {companyOpenData
                            ?.filter(
                                company => company.accountType === accountType.PREMIUM_CORPORATE
                            )
                            ?.sort((a, b) => (a.comName > b.comName ? 1 : -1))
                            .map(company => (
                                <CompanyCard key={company.id} company={company} />
                            ))}
                    </div>
                    <div className="font-bold text-xl text-center mt-8 mb-2">
                        Corporate Partners
                    </div>
                    <div
                        style={{
                            flexGrow: 1,
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}
                        className=" flex justify-center "
                    >
                        {companyOpenData
                            ?.filter(company => company.accountType === accountType.CORPORATE)
                            .sort((a, b) => (a.comName > b.comName ? 1 : -1))
                            .map(company => (
                                <CompanyCard key={company.id} company={company} />
                            ))}
                    </div>
                </div>
            </div>
            <div className="lg:hidden">
                <div className="bg-purple-85 p-8 ">
                    <div className="flex flex-col bg-purple-85 gap-y-3">
                        <div className=" text-lg font-semibold">Explore Our Companies</div>
                        <div className=" text-purple-950 text-xs font-semibold">
                            Explore pioneers in Sri Lankan Tech Biz
                        </div>
                    </div>
                </div>

                <div className="my-12 px-8">
                    {companyOpenData?.filter(
                        company => company.accountType === accountType.PREMIUM_CORPORATE
                    ).length > 0 && (
                        <>
                            <div className="font-bold text-lg mb-10">
                                Premium Corporate Partners
                            </div>
                            <div className="flex flex-col items-center justify-center">
                                {companyOpenData
                                    ?.filter(
                                        company =>
                                            company.accountType === accountType.PREMIUM_CORPORATE
                                    )
                                    ?.sort((a, b) => (a.comName > b.comName ? 1 : -1))
                                    .map(company => (
                                        <CompanyCard key={company.id} company={company} />
                                    ))}
                            </div>
                        </>
                    )}

                    {companyOpenData?.filter(
                        company => company.accountType === accountType.CORPORATE
                    ).length > 0 && (
                        <>
                            <div className="font-bold text-lg mb-2">Corporate Partners</div>
                            <div className="flex flex-col items-center justify-center">
                                {companyOpenData
                                    ?.filter(
                                        company => company.accountType === accountType.CORPORATE
                                    )
                                    .sort((a, b) => (a.comName > b.comName ? 1 : -1))
                                    .map(company => (
                                        <CompanyCard key={company.id} company={company} />
                                    ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default CompanyAll;
