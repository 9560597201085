import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';

const PREFIX = '@CERTIFICATES';

export const GET_CERTIFICATES = PREFIX + 'GET_CERTIFICATES';
export const GET_CERTIFICATES_SUCCESS = PREFIX + 'GET_CERTIFICATES_SUCCESS';
export const GET_CERTIFICATES_FAILED = PREFIX + 'GET_CERTIFICATES_FAILED';
export const GET_EARNED_CERTIFICATES = PREFIX + 'GET_EARNED_CERTIFICATES';
export const GET_EARNED_CERTIFICATES_SUCCESS = PREFIX + 'GET_EARNED_CERTIFICATES_SUCCESS';
export const GET_EARNED_CERTIFICATES_FAILED = PREFIX + 'GET_EARNED_CERTIFICATES_FAILED';
export const CERTIFICATEURL_FROM_CODE = PREFIX + 'CERTIFICATEURL_FROM_CODE';
export const CERTIFICATEURL_FROM_CODE_SUCCESS = PREFIX + 'CERTIFICATEURL_FROM_CODE_SUCCESS';
export const CERTIFICATEURL_FROM_CODE_FAILED = PREFIX + 'CERTIFICATEURL_FROM_CODE_FAILED';
export const GET_STUDENT_CERTIFICATES = PREFIX + 'GET_STUDENT_CERTIFICATES';
export const GET_STUDENT_CERTIFICATES_SUCCESS = PREFIX + 'GET_STUDENT_CERTIFICATES_SUCCESS';
export const GET_STUDENT_CERTIFICATES_FAILED = PREFIX + 'GET_STUDENT_CERTIFICATES_FAILED';
export const GET_CERTIFICATE_BY_ID = PREFIX + 'GET_CERTIFICATE_BY_ID';
export const GET_CERTIFICATE_BY_ID_SUCCESS = PREFIX + 'GET_CERTIFICATE_BY_ID_SUCCESS';
export const GET_CERTIFICATE_BY_ID_FAILED = PREFIX + 'GET_CERTIFICATE_BY_ID_FAILED';
export const ADD_CERTIFICATE = PREFIX + 'ADD_CERTIFICATE';
export const ADD_CERTIFICATE_SUCCESS = PREFIX + 'ADD_CERTIFICATE_SUCCESS';
export const ADD_CERTIFICATE_FAILED = PREFIX + 'ADD_CERTIFICATE_FAILED';
export const EDIT_CERTIFICATE = PREFIX + 'EDIT_CERTIFICATE';
export const EDIT_CERTIFICATE_SUCCESS = PREFIX + 'EDIT_CERTIFICATE_SUCCESS';
export const EDIT_CERTIFICATE_FAILED = PREFIX + 'EDIT_CERTIFICATE_FAILED';
export const DELETE_CERTIFICATE = PREFIX + 'DELETE_CERTIFICATE';
export const DELETE_CERTIFICATE_SUCCESS = PREFIX + 'DELETE_CERTIFICATE_SUCCESS';
export const DELETE_CERTIFICATE_FAILED = PREFIX + 'DELETE_CERTIFICATE_FAILED';
export const CERTIFICATES_BULK_UPDATE = PREFIX + 'CERTIFICATES_BULK_UPDATE';
export const CERTIFICATES_BULK_UPDATE_SUCCESS = PREFIX + 'CERTIFICATES_BULK_UPDATE_SUCCESS';
export const CERTIFICATES_BULK_UPDATE_FAILED = PREFIX + 'CERTIFICATES_BULK_UPDATE_FAILED';
export const CLEAR_CERTIFICATES_MESSAGES = PREFIX + 'CLEAR_CERTIFICATES_MESSAGES';
export const GET_CERTIFICATE_PRESIGNED = PREFIX + 'GET_CERTIFICATE_PRESIGNED';
export const GET_CERTIFICATE_PRESIGNED_SUCCESS = PREFIX + 'GET_CERTIFICATE_PRESIGNED_SUCCESS';
export const GET_CERTIFICATE_PRESIGNED_FAILED = PREFIX + 'GET_CERTIFICATE_PRESIGNED_FAILED';
export const UPLOAD_CERTIFICATE = PREFIX + 'UPLOAD_CERTIFICATE';
export const UPLOAD_CERTIFICATE_SUCCESS = PREFIX + 'UPLOAD_CERTIFICATE_SUCCESS';
export const UPLOAD_CERTIFICATE_FAILED = PREFIX + 'UPLOAD_CERTIFICATE_FAILED';
export const GET_CERTIFICATE_PDF = PREFIX + 'GET_CERTIFICATE_PDF';
export const GET_CERTIFICATE_PDF_SUCCESS = PREFIX + 'GET_CERTIFICATE_PDF_SUCCESS';
export const GET_CERTIFICATE_PDF_FAILED = PREFIX + 'GET_CERTIFICATE_PDF_FAILED';

export const getCertificates =
    (page?: number, size?: number, status?: string, tag?: any[], searchTerm?: string) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_CERTIFICATES });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/certificates?page=${page || 0}&size=${size || 10}${
                    status ? `&status=${status}` : ''
                }${searchTerm ? `&searchText=${searchTerm}` : ''}`,
                config
            );

            if (tag?.length === 0 || isNaN(tag?.length)) {
                dispatch({
                    type: GET_CERTIFICATES_SUCCESS,
                    payload: response.data.responseDto,
                });
            } else {
                const newPayLoad = {
                    payload: response.data.responseDto,
                    tag: tag,
                };
                dispatch({
                    type: GET_CERTIFICATES_SUCCESS,
                    payload: newPayLoad,
                });
            }
        } catch (error) {
            dispatch({ type: GET_CERTIFICATES_FAILED, payload: getErrorMessage(error) });
        }
    };

export const getStudentSpecificCertificateData = (studentId: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_STUDENT_CERTIFICATES });
        const config = await axiosConfig(false);
        const response = await axios.get(
            `${API_URL}/certificates/student?studentId=${studentId}`,
            config
        );
        dispatch({ type: GET_STUDENT_CERTIFICATES_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_STUDENT_CERTIFICATES_FAILED, payload: getErrorMessage(error) });
    }
};

export const getStudentEarnedCertificateData = (studentId: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_EARNED_CERTIFICATES });
        const config = await axiosConfig(false);
        const response = await axios.get(
            `${API_URL}/certificates/student/${studentId}/earned`,
            config
        );
        dispatch({ type: GET_EARNED_CERTIFICATES_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_EARNED_CERTIFICATES_FAILED, payload: getErrorMessage(error) });
    }
};
export const certificateUrlFromCode = (code: string) => async (dispatch: any) => {
    try {
        dispatch({ type: CERTIFICATEURL_FROM_CODE });
        const response = await axios.get(`${API_URL}/certificates/accomplishments/${code}`);
        dispatch({ type: CERTIFICATEURL_FROM_CODE_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: CERTIFICATEURL_FROM_CODE_FAILED, payload: getErrorMessage(error) });
    }
};

export const getCerificateById = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_CERTIFICATE_BY_ID });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/certificates/${id}`, config);
        dispatch({ type: GET_CERTIFICATE_BY_ID_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_CERTIFICATE_BY_ID_FAILED, payload: getErrorMessage(error) });
    }
};
export const uploadCertificateTemplate = (url: string, file: any) => async (dispatch: any) => {
    try {
        dispatch({ type: UPLOAD_CERTIFICATE });
        const headers = {
            'Content-Type':
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        };
        const res = await axios({
            method: 'put',
            url: url,
            data: file,
            headers,
        });
        dispatch({ type: UPLOAD_CERTIFICATE_SUCCESS, payload: res.data });
    } catch (error) {
        dispatch({ type: UPLOAD_CERTIFICATE_FAILED, payload: getErrorMessage(error) });
    }
};
export const getPresignedUrl = (fileName: string, file: any) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_CERTIFICATE_PRESIGNED });
        const config = await axiosConfig(true);
        const response = await axios.post(
            `${API_URL}/files?fileName=${fileName}&path=tracks/certificates`,
            null,
            config
        );
        if (response.status === 200) {
            dispatch(uploadCertificateTemplate(response.data, file));
            dispatch({
                type: GET_CERTIFICATE_PRESIGNED_SUCCESS,
                payload: response.data.responseDto,
            });
        }
        return response.data;
    } catch (error) {
        dispatch({ type: GET_CERTIFICATE_PRESIGNED_FAILED, payload: getErrorMessage(error) });
    }
};
export const getPdfUrl = (fileName: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_CERTIFICATE_PDF });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/files?fileName=${fileName}&path=tracks/certificates`,
            config
        );
        dispatch({
            type: GET_CERTIFICATE_PDF_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({ type: GET_CERTIFICATE_PDF_FAILED, payload: getErrorMessage(error) });
    }
};

export const addCertificate = (certificate: any) => async (dispatch: any) => {
    try {
        dispatch({ type: ADD_CERTIFICATE });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/certificates/`, certificate, config);
        if (res?.data?.status) {
            if (res?.data?.errorCode === 222) {
                await dispatch(getCertificates());
                await dispatch({
                    type: ADD_CERTIFICATE_SUCCESS,
                    payload: res.data.responseDto,
                });
            }
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 222) {
                dispatch({
                    type: ADD_CERTIFICATE_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: ADD_CERTIFICATE_FAILED, payload: getErrorMessage(error) });
    }
};
export const editCertificate =
    (id: string, certificate: any, status?: string) => async (dispatch: any) => {
        try {
            dispatch({ type: EDIT_CERTIFICATE });
            const config = await axiosConfig(true);
            const res = await axios.put(`${API_URL}/certificates/${id}`, certificate, config);
            if (res?.data?.status) {
                await dispatch(getCertificates(0, 10, status));
                dispatch({ type: EDIT_CERTIFICATE_SUCCESS, payload: res.data.responseDto });
            }
            if (!res?.data?.status) {
                if (res?.data?.errorCode !== 214) {
                    dispatch({
                        type: EDIT_CERTIFICATE_FAILED,
                        payload: res.data.errorDescription,
                    });
                }
            }
        } catch (error) {
            dispatch({ type: EDIT_CERTIFICATE_FAILED, payload: getErrorMessage(error) });
        }
    };
export const deleteCertificate = (id: string, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: DELETE_CERTIFICATE });
        const config = await axiosConfig(true);
        const res = await axios.delete(`${API_URL}/certificates/${id}`, config);
        await dispatch(getCertificates(0, 10, status));
        dispatch({ type: DELETE_CERTIFICATE_SUCCESS, payload: res.data.responseDto });
    } catch (error) {
        dispatch({ type: DELETE_CERTIFICATE_FAILED, payload: getErrorMessage(error) });
    }
};
export const bulkUpdateCertificates = (certificates, tabStatus) => async (dispatch: any) => {
    try {
        dispatch({ type: CERTIFICATES_BULK_UPDATE });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/certificates/bulk`, certificates, config);
        await dispatch(getCertificates(0, 10, tabStatus));
        if (res?.data?.status) {
            if (res?.data?.errorCode === 202)
                dispatch({ type: CERTIFICATES_BULK_UPDATE_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 202) {
                dispatch({
                    type: CERTIFICATES_BULK_UPDATE_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: CERTIFICATES_BULK_UPDATE_FAILED, payload: getErrorMessage(error) });
    }
};
export const clearCertificatesMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_CERTIFICATES_MESSAGES });
};
