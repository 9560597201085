import React, { ReactElement, useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { RootState } from '../../../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import * as HiIcons from 'react-icons/hi';
import { CompanyType } from '../../../../redux/companies/reducers';
import { getCompanyPublicData } from '../../../../redux/rootActions';

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Home town',
                font: {
                    size: 14,
                },
            },
            grid: {
                display: false,
            },
            ticks: {
                autoSkip: false,
            },
        },
        y: {
            title: {
                display: true,
                text: 'Number of Students',
                font: {
                    size: 14,
                },
            },
            grid: {
                display: false,
            },
            beginAtZero: true,
            ticks: {
                callback: function (value) {
                    if (Math.floor(value) === value) {
                        return value;
                    }
                },
            },
        },
    },
};
interface Props {
    company: CompanyType;
    setCompany: (value: CompanyType) => void;
}
export default function CompanyRegistration({ company, setCompany }: Props): ReactElement {
    const dispatch = useDispatch();
    const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);
    const companies = useSelector((state: RootState) => state.companies.companyPublicData);

    const registrationData: any = useSelector(
        (state: RootState) => state.dashboard.companyRegistrationData
    );
    const labels = registrationData?.labels;
    const data = {
        labels,
        datasets: [
            {
                label: 'Companies',
                data: registrationData?.values,
                borderColor: 'rgb(45 212 191)',
                backgroundColor: 'rgb(45 212 191)',
            },
        ],
    };

    useEffect(() => {
        dispatch(getCompanyPublicData());
    }, [dispatch]);
    return (
        <>
            <div className="flex items-center ml-2 justify-between lg:w-2/3 mb-8">
                <div className="flex w-1/2 items-center">
                    <div className="w-full md:mb-0 flex">
                        <div className="flex flex-col w-full">
                            <div className="w-full flex ">
                                <label className="mx-2 flex items-center">Company : </label>
                                <div
                                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none md:w-2/3 lg:w-3/5 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className="flex justify-between w-full"
                                        onClick={() => setShowCompanyDropdown(true)}
                                    >
                                        <div className="text-gray-700 font-medium pl-2">
                                            {company ? company?.comName : 'All'}
                                        </div>
                                        <div className="flex flex-col">
                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                            <HiIcons.HiChevronDown className="mr-2" />
                                        </div>
                                    </div>
                                    {showCompanyDropdown ? (
                                        <ul
                                            className="absolute w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setShowCompanyDropdown(false)}
                                        >
                                            <div
                                                className={`p-2 hover:bg-gray-200 ${
                                                    !company && 'bg-gray-300'
                                                }`}
                                                key="0"
                                                onClick={() => {
                                                    setCompany(null);
                                                    setShowCompanyDropdown(false);
                                                }}
                                            >
                                                All
                                            </div>
                                            {companies?.map(com => (
                                                <div
                                                    className={`p-2 hover:bg-gray-200 ${
                                                        company?.comName === com?.comName &&
                                                        'bg-gray-300'
                                                    }`}
                                                    key={com?.id}
                                                    onClick={() => {
                                                        setCompany(com);
                                                        setShowCompanyDropdown(false);
                                                    }}
                                                >
                                                    {com?.comName}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full overflow-hidden">
                <div className="w-full overflow-x-auto pr-8 pb-6" style={{ height: '400px' }}>
                    <Bar options={options} data={data} />
                </div>
            </div>
            <div className="flex flex-col items-center justify-center pb-6">
                <div className="text-gray-700 text-sm md:text-base md:font-semibold mb-2">
                    Total Students Registered - {registrationData?.items?.Total}
                </div>
                <div className="text-blue-700 text-sm md:text-base md:font-semibold">
                    Male Students - {registrationData?.items?.Male} &nbsp; &nbsp; Female Students -{' '}
                    {registrationData?.items?.Female}
                </div>
            </div>
        </>
    );
}
