import React, { useState, useMemo, useEffect, useRef } from 'react';
import Tab from './Tab';
import TracksTable from './TracksTable';
import * as RiIcons from 'react-icons/ri';
import RightSideContent from '../../RightSideContent';
import * as HiIcons from 'react-icons/hi';
import * as BsIcons from 'react-icons/bs';
import * as BiIcons from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { getTracks } from '../../../redux/tracks/actions';
import { RootState } from '../../../redux/rootReducer';
import { TracksType } from '../../../redux/tracks/reducers';
import BulkRestoreConfirmationModal from '../../modals/BulkRestoreConfirmation';
import BulkDeleteConfirmationModal from '../../modals/BulkDeleteConfirmation';
import PublishConfirmationModal from '../../modals/PublishConfirmationModal';
import DisableRestoreConfirmationModal from '../../modals/DisableRestoreConfirmationModal';
import AssignTagModal from '../../modals/AssignTagModal';
import { moduleNames } from '../../../constants';
import Add from '../../../assets/svg/Add';
import AddFilter from './AddFilter';
import { getChallenges, getTags } from '../../../redux/rootActions';
import Loader from '../../../assets/svg/Loader';
import { useQuery } from '../../../web/hooks/useQuery';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export const Tracks = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const searchTermRef = useRef();
    const backToFirstRef = useRef(null);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const challengesData = useSelector((state: RootState) => state.challenges.challengesData);
    const trackData = useSelector((state: RootState) => state.tracks.trackData);
    const isAllTrackLoading = useSelector((state: RootState) => state.tracks.isAllTrackLoading);
    const filteredTrackData = useSelector((state: RootState) => state.filteredTrack.trackData);
    const pageCount = useSelector((state: RootState) => state.tracks.pageCount);
    const [tags, setTags] = useState([]);
    const [tagsSelected, setTagsSelected] = useState([]);
    const [challengesSelected, setChallengesSelected] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [sidebar, setSidebar] = useState(true);
    const showSidebar = () => setSidebar(!sidebar);
    const [bulkAction, setBulkAction] = useState<string>('Bulk Action');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selectedTracks, setSlectedTracks] = useState([]);
    const [clickedTrack, setClickedTrack] = useState<TracksType[]>();
    const [selectedMainTab, setSelectedMainTab] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [restoreBulkConfirmationVisible, setRestoreBulkConfirmationVisible] = useState(false);
    const [deleteBulkConfirmationVisible, setDeleteBulkConfirmationVisible] = useState(false);
    const [publishConfirmationVisible, setPublishConfirmationVisible] = useState(false);
    const [unpublishConfirmationVisible, setUnpublishConfirmationVisible] = useState(false);
    const [assignTagsModalVisible, setAssignTagsModalVisible] = useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [filterTags, setFilterTags] = useState([]);
    const [filterChallenges, setFilterChallenges] = useState([]);

    const [pageSizeS, setPageSizeS] = useState(0);
    const [pageIndexS, setPageIndexS] = useState(0);
    const [tagIds, setTagIds] = useState('');
    const fetchIdRef = React.useRef(0);
    const tagIdsQS = query.get('tagIds');
    const challengeIdsQS = query.get('challengeIds');
    const initialRender = useRef(true);
    const tabStatus =
        selectedTab === 1
            ? 'PUBLISHED'
            : selectedTab === 2
            ? 'UNPUBLISHED'
            : selectedTab === 3
            ? 'DELETED'
            : '';
    const bulkOptions = [
        { id: 0, val: 'Publish' },
        { id: 1, val: 'Unpublish' },
        { id: 2, val: 'Delete' },
        { id: 3, val: 'Restore' },
        { id: 4, val: 'Assign tags' },
    ];
    useEffect(() => {
        setBulkAction('Bulk Action');
    }, [selectedTab]);

    const tracksBulk = {
        listToUpdate: selectedTracks.map(elem => {
            if (bulkAction === 'Assign tags') {
                return {
                    tagId: '',
                    id: elem.id,
                };
            } else {
                return {
                    action:
                        bulkAction === 'Publish'
                            ? 'PUBLISHED'
                            : bulkAction === 'Unpublish' || bulkAction === 'Restore'
                            ? 'UNPUBLISHED'
                            : 'DELETED',
                    id: elem.id,
                };
            }
        }),
    };

    const applyBulkAction = async () => {
        if (bulkAction === 'Restore') {
            setRestoreBulkConfirmationVisible(true);
        } else if (bulkAction === 'Delete') {
            setDeleteBulkConfirmationVisible(true);
        } else if (bulkAction === 'Publish') {
            setPublishConfirmationVisible(true);
        } else if (bulkAction === 'Assign tags') {
            setAssignTagsModalVisible(true);
        } else {
            setUnpublishConfirmationVisible(true);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: '',
                id: 'actionColumn',
                disableSortBy: true,
                width: 5,
                // eslint-disable-next-line react/display-name
                Cell: (
                    <div className="cursor__pointer">
                        <button
                            value="menu cursor-pointer"
                            className="text-gray-500 rounded cursor-pointer focus:outline-none lg:ml-2"
                        >
                            <BsIcons.BsThreeDotsVertical className="mx-auto" />
                        </button>
                    </div>
                ),
            },
            {
                Header: 'Track ID',
                accessor: 'trackId',
            },
            {
                Header: 'Track Name',
                accessor: 'trackName',
            },
            {
                Header: 'Challenges',
                accessor: 'challenges.length',
            },
            {
                Header: 'Students',
                accessor: data => {
                    let total = 0;
                    if (data.totalStudent !== null) {
                        total = data.totalStudent;
                    }
                    return total;
                },
            },
            {
                Header: 'Last Updated',
                accessor: data => {
                    return data?.trackLastUpdatedDate != null
                        ? moment(data?.trackLastUpdatedDate).local().format('YYYY-MM-DD')
                        : null;
                },
            },
            {
                Header: 'Track Status',
                accessor: 'trackStatus',
            },
        ],
        []
    );

    const fetchData = React.useCallback(
        ({ pageSize, pageIndex }) => {
            setPageSizeS(pageSize);
            setPageIndexS(pageIndex);
            const tagIdsT = tags?.map(tagss => tagss.id).toString();
            setTagIds(tagIdsT);
            const fetchId = ++fetchIdRef.current;
            if (fetchId === fetchIdRef.current) {
                dispatch(
                    getTracks(
                        true,
                        pageIndex,
                        pageSize,
                        tabStatus,
                        tagIdsT || tagIdsQS?.split(',').toString(),
                        searchTermRef.current,
                        challengesSelected.toString() || challengeIdsQS?.split(',').toString()
                    )
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, tabStatus, tags, tagIdsQS, challengesSelected, challengeIdsQS]
    );
    useEffect(() => {
        if (tagIdsQS) {
            setTagsSelected(tagIdsQS.split(','));
        }
        if (challengeIdsQS) {
            setChallengesSelected(challengeIdsQS.split(','));
        }
        dispatch(getTags(true, moduleNames.Tracks));
        dispatch(getChallenges(0, 100, 'PUBLISHED'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchByNameOrId = async () => {
        await backToFirstRef?.current?.goToFirst();
        dispatch(
            getTracks(
                true,
                0,
                pageSizeS,
                tabStatus,
                tagsSelected.toString(),
                searchTerm,
                challengesSelected.toString()
            )
        );
    };

    const searchInputHandler = e => {
        searchTermRef.current = e.target.value;
        setSearchTerm(e.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            searchByNameOrId();
        }
    };

    const onRequestClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        const goBack = async () => {
            backToFirstRef?.current?.goToFirst();
        };
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            goBack().then(() => {
                history.replace({
                    search: `?tagIds=${tagsSelected.toString()}&challengeIds=${challengesSelected.toString()}`,
                });
                dispatch(
                    getTracks(
                        true,
                        pageIndexS,
                        pageSizeS,
                        tabStatus,
                        tagsSelected.toString(),
                        searchTerm,
                        challengesSelected.toString()
                    )
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagsSelected, challengesSelected]);

    useEffect(() => {
        if (tagData.length > 0) {
            const newTagsArr = tagData.map(item => {
                return { name: item.tagName, category: item.tagCategory, id: item.id };
            });
            setFilterTags(newTagsArr);
        } else {
            setFilterTags([]);
        }
        if (challengesData.length > 0) {
            const newChallengesArr = challengesData.map(item => {
                return { name: item.challengeName, id: item.id };
            });
            setFilterChallenges(newChallengesArr);
        } else {
            setFilterChallenges([]);
        }
    }, [tagData, challengesData]);

    useEffect(() => {
        if (searchTerm.length === 0) {
            dispatch(
                getTracks(
                    true,
                    pageIndexS,
                    pageSizeS,
                    tabStatus,
                    tagsSelected.toString(),
                    searchTerm,
                    challengesSelected.toString()
                )
            );
        }
    }, [searchTerm]);

    const openFilter = () => {
        dispatch(getTags(true, moduleNames.Tracks));
        setShowModal(true);
    };

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <Tab selectedTab={selectedMainTab} setSelectedTab={setSelectedMainTab} />
                <div className="flex w-full">
                    <div
                        style={{
                            width: sidebar === false ? '100%' : '75%',
                        }}
                    >
                        <div className="flex h-12 md:h-14 justify-between md:justify-start">
                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex">
                                <input
                                    className={`rounded-sm w-full h-full text-gray-500 font-medium focus:outline-none p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                    id="id"
                                    type="text"
                                    placeholder="Search by ID or Name"
                                    onChange={e => searchInputHandler(e)}
                                    autoComplete="off"
                                    onKeyDown={handleKeyDown}
                                />
                                <button
                                    className={`ml-2 px-5 border-2 font-medium text-sm rounded-md focus:outline-none ${
                                        searchTerm.length > 0
                                            ? ' text-white border-blue-700 bg-blue-700 cursor-pointer'
                                            : 'cursor-not-allowed border-gray-300'
                                    }`}
                                    disabled={searchTerm.length === 0}
                                    onClick={searchByNameOrId}
                                >
                                    <BsIcons.BsSearch
                                        style={searchTerm.length > 0 && { color: '#ffffff' }}
                                    />
                                </button>
                            </div>

                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex">
                                <div
                                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-3/4 md:w-3/4 lg:w-3/4 xl:w-2/3 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className={`flex justify-between w-full md:p-2 ${
                                            selectedTracks.length <= 1
                                                ? 'cursor-not-allowed'
                                                : 'cursor-pointer'
                                        }  `}
                                        onClick={() =>
                                            selectedTracks.length > 1
                                                ? setDropdownVisible(true)
                                                : null
                                        }
                                    >
                                        <div className="text-gray-700 font-medium pl-2">
                                            {bulkAction}
                                        </div>
                                        <div className="">
                                            <div className="flex flex-col">
                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                <HiIcons.HiChevronDown className="mr-2" />
                                            </div>
                                        </div>
                                    </div>
                                    {dropdownVisible ? (
                                        <ul
                                            className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setDropdownVisible(false)}
                                        >
                                            {bulkOptions
                                                .filter(option => {
                                                    if (selectedTab === 1) {
                                                        return (
                                                            option.val === 'Unpublish' ||
                                                            option.val === 'Assign tags'
                                                        );
                                                    } else if (selectedTab === 2) {
                                                        return (
                                                            option.val === 'Publish' ||
                                                            option.val === 'Delete' ||
                                                            option.val === 'Assign tags'
                                                        );
                                                    } else if (selectedTab === 3) {
                                                        return option.val === 'Restore';
                                                    } else
                                                        return (
                                                            option.val === 'Unpublish' ||
                                                            option.val === 'Publish' ||
                                                            option.val === 'Assign tags'
                                                        );
                                                })
                                                .map(action => (
                                                    <div
                                                        className="p-2 hover:bg-gray-200 "
                                                        key={action.id}
                                                        onClick={() => {
                                                            setBulkAction(action.val);
                                                            setDropdownVisible(false);
                                                        }}
                                                    >
                                                        {action.val}
                                                    </div>
                                                ))}
                                        </ul>
                                    ) : null}
                                </div>
                                <button
                                    className={`ml-2 px-2 border-2 ${
                                        bulkAction === 'Bulk Action'
                                            ? 'text-gray-400 border-gray-300'
                                            : 'text-white border-blue-700 bg-blue-700'
                                    } font-medium text-sm rounded-md focus:outline-none  hover:border-blue-700 hover:text-white hover:bg-blue-700`}
                                    onClick={applyBulkAction}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                        <div className="flex h-12 md:h-14 justify-between md:justify-start align-center p-5">
                            <BiIcons.BiFilterAlt
                                style={{
                                    color: 'rgba(29, 78, 216)',
                                    height: '20px',
                                    width: '20px',
                                    marginRight: '10px',
                                    alignSelf: 'baseline',
                                }}
                            />
                            <div
                                className="sm:text-sm md:text-base text-gray-400 pr-2 cursor-pointer"
                                onClick={openFilter}
                            >
                                Filter
                            </div>
                            <button onClick={openFilter}>
                                <Add size={25} color={'rgba(29, 78, 216)'} />
                            </button>
                            <AddFilter
                                setChallengesSelected={setChallengesSelected}
                                challengesSelected={challengesSelected}
                                setTagsSelected={setTagsSelected}
                                tagsSelected={tagsSelected}
                                isSelected={showModal}
                                onRequestClose={onRequestClose}
                                filterTags={filterTags}
                                filterChallenges={filterChallenges}
                            />
                            {tagsSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 ml-2 mr-1">
                                        Tags
                                    </div>
                                    <div className="rounded-full text-base mr-2 h-6 text-blue-800">
                                        {tagsSelected.length}
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setTagsSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                            {challengesSelected.length > 0 && (
                                <>
                                    <div className="sm:text-sm md:text-base text-gray-400 mr-1 ml-2">
                                        Challenges
                                    </div>
                                    <div className="rounded-full text-base mr-2 h-6 text-blue-800">
                                        {challengesSelected.length}
                                    </div>
                                    <RiIcons.RiCloseCircleLine
                                        onClick={() => setChallengesSelected([])}
                                        style={{
                                            alignSelf: 'baseline',
                                            marginRight: '8px',
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        {isAllTrackLoading && (
                            <div className=" w-full h-98  flex items-center">
                                <Loader />
                            </div>
                        )}
                        <div className={`${isAllTrackLoading && 'hidden'}`}>
                            <TracksTable
                                columns={columns}
                                data={
                                    selectedMainTab !== 1
                                        ? filteredTrackData
                                        : clickedTrack?.length > 0
                                        ? clickedTrack
                                        : trackData
                                }
                                fetchData={fetchData}
                                pageCount={pageCount}
                                setSlectedTracks={setSlectedTracks}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                backToFirstRef={backToFirstRef}
                            />
                        </div>
                    </div>
                    <div
                        className={`relative border-l-2 ${
                            sidebar === false ? '' : 'right-auto w-1/4'
                        }`}
                    >
                        {sidebar === false && (
                            <div className=" ">
                                <RiIcons.RiArrowDropLeftLine
                                    style={{
                                        borderRadius: 50,
                                        borderWidth: 1,
                                        borderColor: 'rgba(0,0,0,0.2)',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        height: '25px',
                                        width: '25px',
                                        top: '100px',
                                        left: '-13px',
                                        backgroundColor: 'white',
                                    }}
                                    onClick={showSidebar}
                                />
                            </div>
                        )}

                        {sidebar === true && (
                            <div className="h-full">
                                <RiIcons.RiArrowDropRightLine
                                    style={{
                                        borderRadius: 50,
                                        borderWidth: 1,
                                        borderColor: 'rgba(0,0,0,0.2)',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        height: '25px',
                                        width: '25px',
                                        top: '100px',
                                        left: '-13px',
                                        backgroundColor: 'white',
                                    }}
                                    onClick={showSidebar}
                                />
                                <div className="h-96 ml-2 w-full mt-10">
                                    <RightSideContent
                                        tags={tags}
                                        setTags={setTags}
                                        moduleName={moduleNames.Tracks}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {restoreBulkConfirmationVisible ? (
                <BulkRestoreConfirmationModal
                    modalVisible={restoreBulkConfirmationVisible}
                    setModalVisible={setRestoreBulkConfirmationVisible}
                    tracks={tracksBulk}
                    tabStatus={tabStatus}
                />
            ) : null}
            {deleteBulkConfirmationVisible ? (
                <BulkDeleteConfirmationModal
                    modalVisible={deleteBulkConfirmationVisible}
                    setModalVisible={setDeleteBulkConfirmationVisible}
                    tracks={tracksBulk}
                    tabStatus={tabStatus}
                />
            ) : null}
            {publishConfirmationVisible ? (
                <PublishConfirmationModal
                    modalVisible={publishConfirmationVisible}
                    setModalVisible={setPublishConfirmationVisible}
                    tracks={tracksBulk}
                    tabStatus={tabStatus}
                    selectedTracks={selectedTracks}
                />
            ) : null}
            {unpublishConfirmationVisible ? (
                <DisableRestoreConfirmationModal
                    modalVisible={unpublishConfirmationVisible}
                    setModalVisible={setUnpublishConfirmationVisible}
                    tracks={tracksBulk}
                    tabStatus={tabStatus}
                    selectedTracks={selectedTracks}
                />
            ) : null}
            {assignTagsModalVisible ? (
                <AssignTagModal
                    modalVisible={assignTagsModalVisible}
                    setModalVisible={setAssignTagsModalVisible}
                    tabStatus={tabStatus}
                    selectedTracks={selectedTracks}
                    moduleName={moduleNames.Tracks}
                />
            ) : null}
        </div>
    );
};
