import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTag, clearTagsMessages, getTags } from '../redux/rootActions';
import { RootState } from '../redux/rootReducer';
import * as HiIcons from 'react-icons/hi';
import TagSuccessModal from './modals/TagSuccessModal';
import RemoveBin from '../assets/svg/RemoveBin';
import DeleteTagConfirmationModal from './modals/DeleteTagConfirmationModal';
import { TagsType } from '../redux/tags/reducers';
import { getUserProfile } from '../utils/storage';
import { SLASSCOM_ADMIN, SUPER_ADMIN, UserProfileType } from '../redux/authAdmin/reducers';
import { categorizeTags } from '../utils/tagCategorize';
import TagGroups from './TagGroups';

interface Props {
    tags?: any[];
    setTags?: (value: any[]) => void;
    moduleName?: string;
}

export default function RightSideContent({ tags, setTags, moduleName }: Props) {
    const dispatch = useDispatch();
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [succesVisible, setSuccesVisible] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedTagToDelete, setSelectedTagToDelete] = useState<TagsType>();
    const [showsDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const createError = useSelector((state: RootState) => state.tags.createTagError);
    const createSuccessMessage = useSelector(
        (state: RootState) => state.tags.tagCreateSuccessMessage
    );
    const deleteTagError = useSelector((state: RootState) => state.tags.deleteTagError);
    const tagDeleteSuccessMessage = useSelector(
        (state: RootState) => state.tags.tagDeleteSuccessMessage
    );
    const [tagsHelpText, setTagsHelpText] = useState<boolean>(false);
    const [formatError, setformatError] = useState('');
    const user: UserProfileType | any = getUserProfile();

    useEffect(() => {
        dispatch(getTags(true, moduleName));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const serchTagNameOrId = e => {
        setSearchTerm(e.target.value);
    };
    useEffect(() => {
        if (showDropdown) {
            dispatch(getTags(true, moduleName, searchTerm));
        }
    }, [dispatch, searchTerm, showDropdown]);
    const addNewTag = () => {
        if (searchTerm.includes(':')) {
            const tagName = searchTerm.split(':', 2)[1]?.trim();
            const tagCategory = searchTerm.split(':', 2)[0]?.trim();

            if (!tagName || !tagCategory) {
                setformatError(
                    `Type the parent category first and child value next separated by ":" symbol`
                );
                setTagsHelpText(true);
            } else {
                dispatch(
                    addTag({
                        tagName: tagName,
                        tagCategory: tagCategory,
                        tagCreatedBy: '',
                        module: moduleName,
                    })
                );
            }
        } else {
            setformatError(
                `Type the parent category first and child value next separated by ":" symbol`
            );
            setTagsHelpText(true);
        }
    };
    const handleClearMessages = useCallback(() => {
        if (
            createSuccessMessage ||
            createError ||
            tagDeleteSuccessMessage ||
            deleteTagError ||
            formatError
        ) {
            if (!formatError) {
                setSuccesVisible(true);
            }
        }
    }, [
        dispatch,
        createSuccessMessage,
        createError,
        deleteTagError,
        tagDeleteSuccessMessage,
        formatError,
    ]);
    const handleClose = () => {
        dispatch(clearTagsMessages());
        setSuccesVisible(false);
        setSearchTerm('');
        setformatError('');
        setTagsHelpText(false);
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    useEffect(() => {
        setSelectedTags(categorizeTags(tags));
    }, [tags]);

    useEffect(() => {
        if (createError) setTagsHelpText(true);
        else setTagsHelpText(false);
    }, [createError]);
    return (
        <>
            <div className="flex border-2 flex-col ml-2 justify-items-center">
                <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                    <div className="flex sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                        <div>Tags</div>
                        <div>
                            <HiIcons.HiQuestionMarkCircle
                                onClick={() => {
                                    setTagsHelpText(true);
                                }}
                                className="ml-2 mt-1 cursor-pointer"
                            />
                        </div>
                    </div>
                    {tagsHelpText ? (
                        <div
                            onMouseOverCapture={() => {
                                setTagsHelpText(true);
                                // setProfileDropdownVisible(false);
                            }}
                            onMouseLeave={() => setTagsHelpText(false)}
                            className="bg-white inputFieldThickness absolute z-10 px-4 py-2 text-xs left-24 top-6 font-bold"
                        >
                            <div>
                                Create new tags and apply it to the grid records selected. Filter
                                grid data using the tags created.
                            </div>
                            {createError && (
                                <div className="font-bold text-red-500">{createError}</div>
                            )}
                            {formatError && (
                                <div className="font-bold text-red-500">{formatError}</div>
                            )}
                        </div>
                    ) : null}

                    <div
                        className="sm:text-sm md:text-base text-gray-400 underline pr-2 cursor-pointer"
                        onClick={() => {
                            setTags([]);
                        }}
                    >
                        Clear All
                    </div>
                </div>
                <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                    <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                        <TagGroups selectedTags={selectedTags} tags={tags} setTags={setTags} />
                        <div
                            onMouseLeave={() => {
                                setShowDropdown(false);
                            }}
                        >
                            <input
                                className={`rounded w-full text-gray-500 font-medium focus:outline-none mt-2 p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                id="id"
                                type="text"
                                placeholder="Search tags"
                                value={searchTerm}
                                onChange={e => serchTagNameOrId(e)}
                                onMouseEnter={() => {
                                    setShowDropdown(true);
                                }}
                                autoComplete="off"
                            />
                            {showDropdown && tagData.length ? (
                                <ul
                                    className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    role="menu"
                                    onMouseLeave={() => setShowDropdown(false)}
                                >
                                    {tagData?.map(tag => (
                                        <div
                                            key={tag.id}
                                            className="hover:bg-gray-200 flex justify-between"
                                        >
                                            <div
                                                className="p-2 w-11/12"
                                                onClick={() => {
                                                    if (
                                                        tags
                                                            ?.map(checktag => checktag.tagName)
                                                            .includes(tag.tagName) === false
                                                    ) {
                                                        setTags([...tags, tag]);
                                                    }
                                                }}
                                            >
                                                <div className="cursor-pointer overflow-hidden">
                                                    {tag.tagCategory + ': ' + tag.tagName}
                                                </div>
                                            </div>
                                            <div
                                                className="cursor-pointer w-1/12 pt-2"
                                                onClick={() => {
                                                    setShowDeleteConfirmation(true);
                                                    setSelectedTagToDelete(tag);
                                                }}
                                            >
                                                <RemoveBin width={18} height={22} />
                                            </div>
                                        </div>
                                    ))}
                                </ul>
                            ) : showDropdown && tagData.length === 0 && searchTerm ? (
                                <ul
                                    className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    role="menu"
                                >
                                    <div className="p-2 flex justify-center font-medium text-gray-400">
                                        No matching result
                                    </div>
                                    <div className="flex align items-center justify-between w-full">
                                        {(user.roles?.[0] === SUPER_ADMIN ||
                                            user.roles?.[0] === SLASSCOM_ADMIN) && (
                                            <>
                                                <input
                                                    className="mx-2 w-5/6 pl-2 py-1 focus:outline-none"
                                                    value={searchTerm}
                                                    disabled
                                                />
                                                <div
                                                    className="p-2 hover:font-semibold font-normal text-blue-700 cursor-pointer"
                                                    onClick={() => {
                                                        addNewTag();
                                                    }}
                                                >
                                                    Create
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </ul>
                            ) : showDropdown && tagData.length === 0 ? (
                                <ul
                                    className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    role="menu"
                                    onMouseLeave={() => setShowDropdown(false)}
                                >
                                    <div className="p-2 flex justify-center font-medium text-gray-400">
                                        No records found
                                    </div>
                                </ul>
                            ) : null}
                        </div>
                    </div>
                </div>
                {succesVisible ? (
                    <TagSuccessModal modalVisible={succesVisible} handleClose={handleClose} />
                ) : null}
                {showsDeleteConfirmation && (
                    <DeleteTagConfirmationModal
                        modalVisible={showsDeleteConfirmation}
                        setModalVisible={setShowDeleteConfirmation}
                        selectedTagToDelete={selectedTagToDelete}
                    />
                )}
            </div>
            <div className=" ml-2 justify-items-center">
                <div className="flex flex-col text-sm pl-2 lg:pl-4 mt-6 space-y-4 text-gray-400 ">
                    <div>
                        Tags should be added by mentioning the parent category first followed by
                        it&apos;s relevant child value. Type the parent category first and child
                        value next separated by &quot;:&quot; symbol. (Ex- Skills:Coding Skills).
                    </div>

                    <div>
                        Tags can only be created here. You can create a brand-new tag by clicking on
                        the create button that appears next to the tag. Tag deletion can be done by
                        clicking on the delete icon next to the tag. Created tags can be retrieved
                        by typing the text in the search bar.
                    </div>

                    <div>Added tags will only be available for this module.</div>
                </div>
            </div>
        </>
    );
}
