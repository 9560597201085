// get video ID from any youtube url
export function youtubeParser(url) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/; //eslint-disable-line
    const match = url?.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
}

// check NIC
export function checkNIC(nic) {
    const regExp = /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/m; //eslint-disable-line
    const match = nic?.match(regExp);
    if (match) return true;
    return false;
}

// check Address
export function checkAddress(address) {
    const regExp = /[-!@#$%^&*()?":_={}|<>+|\\[\]]/g; //eslint-disable-line
    const match = address?.match(regExp);
    if (match) return true;
    return false;
}

// mobile phone number verification
export const mobielNumberVerification = number => {
    if (
        number === null ||
        number === undefined ||
        !number?.match(/^[0-9]{9,13}$/)
        // ||  mobileNumber?.toString()[1] !== '7' ||
        // [0, 1, 2, 4, 5, 6, 7, 8].indexOf(parseInt(mobileNumber?.toString()[1])) === -1
    ) {
        return true;
    } else {
        return false;
    }
};
