import axios from 'axios';
import { API_URL } from '../../../constants';
import { axiosConfig } from '../../../utils/axiosApi';
import { getErrorMessage } from '../../../utils/axiosErrorHandler';
import { logoutWeb } from '../authWeb/actions';

const PREFIX = '@WEB_USERS';

export const GET_WEB_USERS = PREFIX + 'GET_WEB_USERS';
export const GET_WEB_USERS_SUCCESS = PREFIX + 'GET_WEB_USERS_SUCCESS';
export const GET_WEB_USERS_FAILED = PREFIX + 'GET_WEB_USERS_FAILED';
export const GET_WEB_USER_BY_ID = PREFIX + 'GET_USER_BY_ID';
export const GET_WEB_USER_BY_ID_SUCCESS = PREFIX + 'GET_USER_BY_ID_SUCCESS';
export const GET_WEB_USER_BY_ID_FAILED = PREFIX + 'GET_USER_BY_ID_FAILED';

export const GET_STUDENT_ACTIVITY_LOG = PREFIX + 'GET_STUDENT_ACTIVITY_LOG';
export const GET_STUDENT_ACTIVITY_LOG_SUCCESS = PREFIX + 'GET_STUDENT_ACTIVITY_LOG_SUCCESS';
export const GET_STUDENT_ACTIVITY_LOG_FAILED = PREFIX + 'GET_STUDENT_ACTIVITY_LOG_FAILED';

export const ADD_WEB_USER = PREFIX + 'ADD_USER';
export const ADD_WEB_USER_SUCCESS = PREFIX + 'ADD_USER_SUCCESS';
export const ADD_WEB_USER_FAILED = PREFIX + 'ADD_USER_FAILED';
export const EDIT_WEB_USER = PREFIX + 'EDIT_USER';
export const EDIT_WEB_USER_SUCCESS = PREFIX + 'EDIT_USER_SUCCESS';
export const EDIT_WEB_USER_FAILED = PREFIX + 'EDIT_USER_FAILED';
export const DELETE_WEB_USER = PREFIX + 'DELETE_USER';
export const DELETE_WEB_USER_SUCCESS = PREFIX + 'DELETE_USER_SUCCESS';
export const DELETE_WEB_USER_FAILED = PREFIX + 'DELETE_USER_FAILED';
export const CLEAR_WEB_USERS_MESSAGES = PREFIX + 'CLEAR_WEB_USERS_MESSAGES';
export const SEND_EMAIL_VERIFICATION = PREFIX + 'SEND_EMAIL_VERIFICATION';
export const SEND_EMAIL_VERIFICATION_SUCCESS = PREFIX + 'SEND_EMAIL_VERIFICATION_SUCCESS';
export const SEND_EMAIL_VERIFICATION_FAILED = PREFIX + 'SEND_EMAIL_VERIFICATION_FAILED';
export const SEND_EMAIL_VERIFICATION_RESET = PREFIX + 'SEND_EMAIL_VERIFICATION_RESET';
export const CONFIRM_EMAIL_VERIFICATION = PREFIX + 'CONFIRM_EMAIL_VERIFICATION';
export const CONFIRM_EMAIL_VERIFICATION_SUCCESS = PREFIX + 'CONFIRM_EMAIL_VERIFICATION_SUCCESS';
export const CONFIRM_EMAIL_VERIFICATION_FAILED = PREFIX + 'CONFIRM_EMAIL_VERIFICATION_FAILED';
export const RESET_PASSWORD_WEB = PREFIX + 'RESET_PASSWORD_WEB';
export const RESET_PASSWORD_WEB_SUCCESS = PREFIX + 'RESET_PASSWORD_WEB_SUCCESS';
export const RESET_PASSWORD_WEB_FAILED = PREFIX + 'RESET_PASSWORD_WEB_FAILED';
export const SEND_MOBILE_VERIFICATION = PREFIX + 'SEND_MOBILE_VERIFICATION';
export const SEND_MOBILE_VERIFICATION_SUCCESS = PREFIX + 'SEND_MOBILE_VERIFICATION_SUCCESS';
export const SEND_MOBILE_VERIFICATION_FAILED = PREFIX + 'SEND_MOBILE_VERIFICATION_FAILED';
export const CONFIRM_MOBILE_VERIFICATION = PREFIX + 'CONFIRM_MOBILE_VERIFICATION';
export const CONFIRM_MOBILE_VERIFICATION_SUCCESS = PREFIX + 'CONFIRM_MOBILE_VERIFICATION_SUCCESS';
export const CONFIRM_MOBILE_VERIFICATION_FAILED = PREFIX + 'CONFIRM_MOBILE_VERIFICATION_FAILED';
export const RESET_SEND_MOBILE_VERIFICATION = PREFIX + 'RESET_SEND_MOBILE_VERIFICATION';
export const RESET_CONFIRM_MOBILE_VERIFICATION = PREFIX + 'RESET_CONFIRM_MOBILE_VERIFICATION';
export const CHANGE_USER_PASSWORD = PREFIX + 'CHANGE_USER_PASSWORD';
export const CHANGE_USER_PASSWORD_SUCCESS = PREFIX + 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_FAILED = PREFIX + 'CHANGE_USER_PASSWORD_FAILED';
export const VERIFY_EMAIL = PREFIX + 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = PREFIX + 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILED = PREFIX + 'VERIFY_EMAIL_FAILED';

export const getWebStudentById = (id: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_WEB_USER_BY_ID });
        const config = await axiosConfig(false);
        const response = await axios.get(`${API_URL}/users/${id}`, config);
        dispatch({ type: GET_WEB_USER_BY_ID_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch(logoutWeb());
        dispatch({ type: GET_WEB_USER_BY_ID_FAILED, payload: getErrorMessage(error) });
    }
};

export const getStudentActivityLog = (isAdmin: boolean, id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_STUDENT_ACTIVITY_LOG });
        const config = await axiosConfig(isAdmin);
        const response = await axios.get(`${API_URL}/users/student/logs/${id}`, config);
        dispatch({ type: GET_STUDENT_ACTIVITY_LOG_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_STUDENT_ACTIVITY_LOG_FAILED, payload: getErrorMessage(error) });
    }
};

export const verifyEmailOnboard = (email: string) => async (dispatch: any) => {
    try {
        dispatch({ type: VERIFY_EMAIL });
        const config = await axiosConfig(false);
        const response = await axios.get(
            `${API_URL}/users/email/user/exist?emailId=${email}`,
            config
        );
        dispatch({ type: VERIFY_EMAIL_SUCCESS, payload: response.data.responseDto });
        if (response.data.errorCode === 518) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        dispatch({ type: VERIFY_EMAIL_FAILED, payload: getErrorMessage(error) });
    }
};

export const editWebUser = (id: string, user: any, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: EDIT_WEB_USER });
        const config = await axiosConfig(false);
        const res = await axios.put(`${API_URL}/users/student/${id}`, user, config);
        await dispatch(getWebStudentById(id));

        if (res?.data?.status) {
            dispatch({ type: EDIT_WEB_USER_SUCCESS, payload: res.data.responseDto });
            if (id) {
                await dispatch(getWebStudentById(id));
            }
        }

        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 214) {
                dispatch({
                    type: EDIT_WEB_USER_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: EDIT_WEB_USER_FAILED, payload: getErrorMessage(error) });
    }
};
export const deleteWebUser = (id: string, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: DELETE_WEB_USER });
        const config = await axiosConfig(false);
        const res = await axios.delete(`${API_URL}/users/admin/${id}`, config);
        await dispatch(getWebStudentById(id));
        dispatch({ type: DELETE_WEB_USER_SUCCESS, payload: res.data.responseDto });
    } catch (error) {
        dispatch({ type: DELETE_WEB_USER_FAILED, payload: getErrorMessage(error) });
    }
};
export const clearWebUsersMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_WEB_USERS_MESSAGES });
};
export const sendEmailVerification =
    (id: string, userName: string, emailId: string) => async (dispatch: any) => {
        try {
            dispatch({ type: SEND_EMAIL_VERIFICATION });
            const config = await axiosConfig(false);
            const res = await axios.post(
                `${API_URL}/users/sendEmailVerification`,
                { userId: id, userName: userName, email: emailId },
                config
            );
            if (res.data.responseDto === null) {
                dispatch({
                    type: SEND_EMAIL_VERIFICATION_FAILED,
                    payload: res.data.errorDescription,
                });
            } else {
                dispatch({ type: SEND_EMAIL_VERIFICATION_SUCCESS, payload: res.data.responseDto });
            }
        } catch (error) {
            dispatch({ type: SEND_EMAIL_VERIFICATION_FAILED, payload: getErrorMessage(error) });
        }
    };

export const confirmEmailVerification = (token: string) => async (dispatch: any) => {
    try {
        dispatch({ type: CONFIRM_EMAIL_VERIFICATION });
        const res = await axios.get(`${API_URL}/users/confirmEmailVerification/${token}`);
        dispatch({ type: CONFIRM_EMAIL_VERIFICATION_SUCCESS, payload: res.data.responseDto });
    } catch (error) {
        dispatch({ type: CONFIRM_EMAIL_VERIFICATION_FAILED, payload: getErrorMessage(error) });
    }
};

export const resetPasswordConfimationWeb =
    (password: string, verifyToken: any) => async (dispatch: any) => {
        try {
            dispatch({ type: RESET_PASSWORD_WEB });
            const config = await axiosConfig(false);
            const res = await axios.post(`${API_URL}/users/student/reset?token=${verifyToken}`, {
                password: password,
            });
            dispatch({ type: RESET_PASSWORD_WEB_SUCCESS, payload: res.data.responseDto });
        } catch (error) {
            dispatch({ type: RESET_PASSWORD_WEB_FAILED, payload: getErrorMessage(error) });
        }
    };

export const sendMobileVerification = (userId, mobileNumber) => async (dispatch: any) => {
    try {
        dispatch({ type: SEND_MOBILE_VERIFICATION });
        const config = await axiosConfig(false);
        const response = await axios.get(
            `${API_URL}/otp/mobile/verify?userId=${userId || null}&mobileNumber=${
                mobileNumber || null
            }`,
            config
        );

        if (response && response.data) {
            if (response.data?.status) {
                if (response.data?.errorCode !== 230) {
                    dispatch({
                        type: SEND_MOBILE_VERIFICATION_FAILED,
                        payload: response.data.errorDescription,
                    });
                } else {
                    dispatch({
                        type: SEND_MOBILE_VERIFICATION_SUCCESS,
                        payload: response.data.responseDto,
                    });
                }
            } else {
                dispatch({
                    type: SEND_MOBILE_VERIFICATION_FAILED,
                    payload: response.data.errorDescription,
                });
            }
        } else {
            dispatch({
                type: SEND_MOBILE_VERIFICATION_FAILED,
                payload: response.data.errorDescription,
            });
        }
    } catch (error) {
        dispatch({ type: SEND_MOBILE_VERIFICATION_FAILED, payload: getErrorMessage(error) });
    }
};

export const verifyMobileNumber = (otpNumber, mobileNumber, userId) => async (dispatch: any) => {
    try {
        dispatch({ type: CONFIRM_MOBILE_VERIFICATION });
        const body = {
            mobileNumber: mobileNumber,
            otpNumber: otpNumber,
        };
        const config = await axiosConfig(false);
        const response = await axios.put(
            `${API_URL}/users/student/mobile/${userId || null}`,
            body,
            config
        );

        if (response && response.data) {
            dispatch({
                type: CONFIRM_MOBILE_VERIFICATION_SUCCESS,
                payload: response.data.responseDto,
            });
        } else {
            dispatch({
                type: CONFIRM_MOBILE_VERIFICATION_FAILED,
                payload: response.data.errorDescription,
            });
        }
    } catch (error) {
        dispatch({ type: CONFIRM_MOBILE_VERIFICATION_FAILED, payload: getErrorMessage(error) });
    }
};

export const changePassword = (currentPassword, newPassword, userId) => async (dispatch: any) => {
    try {
        dispatch({ type: CHANGE_USER_PASSWORD });
        const body = {
            currentPassword: currentPassword,
            newPassword: newPassword,
        };
        const config = await axiosConfig(false);
        const response = await axios.put(
            `${API_URL}/users/change/password/${userId || null}`,
            body,
            config
        );

        if (response && response.data) {
            if (response.data.errorCode !== 214) {
                dispatch({
                    type: CHANGE_USER_PASSWORD_FAILED,
                    payload: response.data.errorDescription,
                });
            } else {
                dispatch({
                    type: CHANGE_USER_PASSWORD_SUCCESS,
                    payload: response.data.responseDto,
                });
            }
        } else {
            dispatch({
                type: CHANGE_USER_PASSWORD_FAILED,
                payload: response.data.errorDescription,
            });
        }
    } catch (error) {
        dispatch({ type: CHANGE_USER_PASSWORD_FAILED, payload: getErrorMessage(error) });
    }
};
