import React, { useState, useMemo, useEffect, useRef } from 'react';
import * as HiIcons from 'react-icons/hi';
import * as BsIcons from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { getUsers } from '../../../redux/rootActions';
import Tab from './Tab';
import BulkRestoreConfirmationModal from '../../modals/BulkRestoreConfirmation';
import BulkDeleteConfirmationModal from '../../modals/BulkDeleteConfirmation';
import UsersTable from './UsersTable';
import _ from 'lodash';
import Loader from '../../../assets/svg/Loader';

export const Users = () => {
    const dispatch = useDispatch();
    const searchTermRef = useRef();
    const backToFirstRef = useRef(null);
    const UserData = useSelector((state: RootState) => state.users.UserData);
    const isLoading = useSelector((state: RootState) => state.users.isLoading);
    const pageCount = useSelector((state: RootState) => state.users.pageCount);
    const [tags, setTags] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [searchResultsVisible, setSearchResultsVisible] = useState(false);
    const [bulkAction, setBulkAction] = useState<string>('Bulk Action');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selectedInstitutes, setSlectedInstitutes] = useState([]);
    const [selectedMainTab, setSelectedMainTab] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [restoreBulkConfirmationVisible, setRestoreBulkConfirmationVisible] = useState(false);
    const [deleteBulkConfirmationVisible, setDeleteBulkConfirmationVisible] = useState(false);
    const [clickedChallenge, setClickedChallenge] = useState<any[]>();
    const fetchIdRef = React.useRef(0);
    const tabStatus = selectedTab === 1 ? '' : 'DELETED';
    const [pageSizeS, setPageSizeS] = useState(0);
    const [pageIndexS, setPageIndexS] = useState(0);
    const bulkOptions = [
        { id: 0, val: 'Delete' },
        { id: 1, val: 'Restore' },
    ];
    const usersBulk = {
        listToUpdate: selectedInstitutes.map(elem => {
            return {
                action: bulkAction === 'Restore' ? 'ACTIVE' : 'DELETED',
                id: elem.id,
            };
        }),
    };

    const applyBulkAction = async () => {
        if (bulkAction === 'Restore') {
            setRestoreBulkConfirmationVisible(true);
        } else if (bulkAction === 'Delete') {
            setDeleteBulkConfirmationVisible(true);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: '',
                id: 'actionColumn',
                disableSortBy: true,
                width: 5,
                // eslint-disable-next-line react/display-name
                Cell: (
                    <div className="cursor__pointer">
                        <button
                            value="menu cursor-pointer"
                            className="text-gray-500 rounded cursor-pointer focus:outline-none flex mx-auto px-1"
                        >
                            <BsIcons.BsThreeDotsVertical className="mx-auto" />
                        </button>
                    </div>
                ),
            },
            {
                Header: 'First Name',
                accessor: 'firstName',
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
            },
            {
                Header: 'Contact number',
                accessor: 'contact.mobileNo',
            },
            {
                Header: 'Email',
                accessor: 'userName',
            },
            {
                Header: 'User Role',
                id: 'userRole',
                accessor: data => {
                    const output = [];
                    _.map(data.roles, role => {
                        output.push(role.roleName);
                    });
                    return output.join(', ');
                },
            },
            {
                Header: 'Organization',
                id: 'organizations',
                accessor: data => {
                    const output = [];
                    _.map(data.organizations, org => {
                        output.push(org.name);
                    });
                    return output.join(', ');
                },
            },
        ],
        []
    );

    const fetchData = React.useCallback(
        ({ pageSize, pageIndex }) => {
            setPageSizeS(pageSize);
            setPageIndexS(pageIndex);
            const fetchId = ++fetchIdRef.current;
            if (fetchId === fetchIdRef.current) {
                dispatch(getUsers(pageIndex, pageSize, tabStatus, tags, searchTermRef.current));
            }
        },
        [dispatch, tabStatus, tags]
    );

    useEffect(() => {
        if (searchTerm.length === 0) {
            dispatch(getUsers(pageIndexS, pageSizeS, tabStatus, tags));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    const serchByNameOrId = async () => {
        await backToFirstRef?.current?.goToFirst();
        dispatch(getUsers(0, pageSizeS, tabStatus, tags, searchTerm));
    };

    const searchInputHandler = e => {
        searchTermRef.current = e.target.value;
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        setBulkAction('Bulk Action');
    }, [selectedTab]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            serchByNameOrId();
        }
    };

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <Tab selectedTab={selectedMainTab} setSelectedTab={setSelectedMainTab} />
                <div className="flex w-full">
                    <div className={'mr-4 w-full'}>
                        <div className="flex h-12 md:h-14 justify-between md:justify-start">
                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex">
                                <input
                                    className={`rounded-sm w-full h-full text-gray-500 font-medium focus:outline-none p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                    id="id"
                                    type="text"
                                    placeholder="Search by Organisation"
                                    onChange={e => searchInputHandler(e)}
                                    autoComplete="off"
                                    onKeyDown={handleKeyDown}
                                />
                                <button
                                    className={`ml-2 px-5 border-2 font-medium text-sm rounded-md focus:outline-none ${
                                        searchTerm.length > 0
                                            ? ' text-white border-blue-700 bg-blue-700 cursor-pointer'
                                            : 'cursor-not-allowed border-gray-300'
                                    }`}
                                    disabled={searchTerm.length === 0}
                                    onClick={serchByNameOrId}
                                >
                                    <BsIcons.BsSearch
                                        style={searchTerm.length > 0 && { color: '#ffffff' }}
                                    />
                                </button>
                            </div>
                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex">
                                <div
                                    className={`rounded-md flex relative items-center focus:outline-none w-3/4 md:w-3/4 lg:w-3/4 xl:w-2/3 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className={`flex justify-between w-full md:p-2 ${
                                            selectedInstitutes.length <= 1
                                                ? 'cursor-not-allowed'
                                                : 'cursor-pointer'
                                        }  `}
                                        onClick={() =>
                                            selectedInstitutes.length > 1
                                                ? setDropdownVisible(true)
                                                : null
                                        }
                                    >
                                        <div className="text-gray-700 font-medium pl-2">
                                            {bulkAction}
                                        </div>
                                        <div className="">
                                            <div className="flex flex-col">
                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                <HiIcons.HiChevronDown className="mr-2" />
                                            </div>
                                        </div>
                                    </div>
                                    {dropdownVisible ? (
                                        <ul
                                            className="absolute w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            onMouseLeave={() => setDropdownVisible(false)}
                                        >
                                            {bulkOptions
                                                .filter(option => {
                                                    if (selectedTab === 1) {
                                                        return option.val === 'Delete';
                                                    } else return option.val === 'Restore';
                                                })
                                                .map(action => (
                                                    <div
                                                        className="p-2 hover:bg-gray-200 "
                                                        key={action.id}
                                                        onClick={() => {
                                                            setBulkAction(action.val);
                                                            setDropdownVisible(false);
                                                        }}
                                                    >
                                                        {action.val}
                                                    </div>
                                                ))}
                                        </ul>
                                    ) : null}
                                </div>
                                <button
                                    className={`ml-2 px-2 border-2 ${
                                        bulkAction === 'Bulk Action'
                                            ? 'text-gray-400 border-gray-300'
                                            : 'text-white border-blue-700 bg-blue-700'
                                    } font-medium text-sm rounded-md focus:outline-none  hover:border-blue-700 hover:text-white hover:bg-blue-700`}
                                    onClick={applyBulkAction}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                        {isLoading && (
                            <div className=" w-full h-98  flex items-center">
                                <Loader />
                            </div>
                        )}
                        <div className={`${isLoading && 'hidden'}`}>
                            <UsersTable
                                columns={columns}
                                data={clickedChallenge?.length > 0 ? clickedChallenge : UserData}
                                fetchData={fetchData}
                                pageCount={pageCount}
                                setSlectedChallenges={setSlectedInstitutes}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                setBulkAction={setBulkAction}
                                backToFirstRef={backToFirstRef}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {restoreBulkConfirmationVisible ? (
                <BulkRestoreConfirmationModal
                    modalVisible={restoreBulkConfirmationVisible}
                    setModalVisible={setRestoreBulkConfirmationVisible}
                    users={usersBulk}
                    tabStatus={tabStatus}
                />
            ) : null}
            {deleteBulkConfirmationVisible ? (
                <BulkDeleteConfirmationModal
                    modalVisible={deleteBulkConfirmationVisible}
                    setModalVisible={setDeleteBulkConfirmationVisible}
                    users={usersBulk}
                    tabStatus={tabStatus}
                />
            ) : null}
        </div>
    );
};
