import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../../../assets/svg/Loader';
import { s3UrlPrefix } from '../../../../../constants';
import { RootState } from '../../../../../redux/rootReducer';
import { getPublicProfileData } from '../../../../../redux/webRedux/publicProfile/actions';
import Modal from 'react-modal';

interface paramsType {
    userId: string;
}

export const PublicProfile = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isLoading, publicProfileData, publicProfileDataFailMessage } = useSelector(
        (state: RootState) => state.publicProfile
    );
    const { userId } = useParams<paramsType>();
    const [openPopup, setOpenPopup] = useState(true);

    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(239, 235, 255, 0.7)',
            backdropFilter: 'blur(4px)',
            overflow: 'auto',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    useEffect(() => {
        if (userId) dispatch(getPublicProfileData(userId));
    }, [dispatch, userId]);

    return (
        <div className="px-8 lg:px-60 xl:px-72">
            {publicProfileDataFailMessage && (
                <Modal isOpen={openPopup} style={customStyles} ariaHideApp={false}>
                    <>
                        <div className="flex-shrink justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
                            <div style={{ width: '531px' }} className="relative my-6  mx-auto">
                                <div
                                    className="rounded-lg shadow-lg relative flex flex-col items-center w-full bg-white outline-none focus:outline-none px-4 py-3"
                                    style={{ maxHeight: '324px' }}
                                >
                                    <div className="flex flex-col items-center justify-center relative h-20 w-20 ">
                                        <span className="exclamation self-center pl-1"></span>
                                    </div>

                                    <div className="mb-2">
                                        <h1 className="text-xl font-bold">Alert</h1>
                                    </div>
                                    <div className=" text-black mb-8 text-center">
                                        {publicProfileDataFailMessage}
                                    </div>
                                    <div className="flex flex-row gap-2 mb-6">
                                        <button
                                            onClick={() => {
                                                setOpenPopup(false);
                                                window.close();
                                            }}
                                            className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                        >
                                            Go Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            )}
            {isLoading ? (
                <div className=" h-98 flex items-center text-center">
                    <Loader />
                </div>
            ) : (
                !publicProfileDataFailMessage && (
                    <>
                        <>
                            <div className="my-8 flex flex-row justify-between">
                                <div className="flex flex-row ">
                                    <img
                                        className={`blur-3xl rounded-full h-32 w-32`}
                                        src={`${
                                            publicProfileData?.imageUrl === '' ||
                                            publicProfileData?.imageUrl === null
                                                ? '/assets/user.png'
                                                : publicProfileData?.imageUrl.includes('webUsers')
                                                ? s3UrlPrefix + publicProfileData?.imageUrl
                                                : publicProfileData?.imageUrl
                                        }`}
                                        alt="profile"
                                    />
                                    <div className="flex flex-col ml-6 pt-1 justify-center">
                                        <p className="text-lg font-bold font-manrope mb-1.5">
                                            {`${publicProfileData?.firstName} ${publicProfileData?.lastName}`}
                                        </p>
                                        <p className="text-xs text-gray-500 mb-2.5">
                                            {publicProfileData?.hometown}
                                        </p>
                                        {publicProfileData?.socialMediaUrls?.length > 0 ? (
                                            <div className="flex space-x-2">
                                                {publicProfileData?.socialMediaUrls.map(
                                                    (social, key) =>
                                                        social.url !== '' && (
                                                            <img
                                                                className="cursor-pointer"
                                                                src={`/assets/${
                                                                    social.platform === 'LINKEDIN'
                                                                        ? 'profileLinkedinIcon.png'
                                                                        : social.platform ===
                                                                          'FACEBOOK'
                                                                        ? 'profileFacebookIcon.png'
                                                                        : social.platform ===
                                                                          'TWITTER'
                                                                        ? 'profileTwitterIcon.png'
                                                                        : ''
                                                                }`}
                                                                alt="profile"
                                                                key={key}
                                                                onClick={() => {
                                                                    window.open(
                                                                        `${social.url}`,
                                                                        '_blank'
                                                                    );
                                                                }}
                                                            />
                                                        )
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="font-bold font-manrope text-xl mb-3">
                                Personal details
                            </div>
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                <div className="flex flex-col mb-1 w-full">
                                    <label
                                        className="flex flex-row font-manrope text-sm my-1"
                                        htmlFor="tab_1"
                                    >
                                        First Name
                                    </label>
                                    <div
                                        className={`pl-4 flex items-center h-12 inputFieldThickness`}
                                        id="tab_1"
                                    >
                                        {publicProfileData?.firstName}
                                    </div>
                                </div>
                                <div className="flex flex-col mb-1 w-full">
                                    <label
                                        className="flex flex-row font-manrope text-sm my-1"
                                        htmlFor="tab_1"
                                    >
                                        Last Name
                                    </label>
                                    <div
                                        className={`pl-4 flex items-center h-12 inputFieldThickness`}
                                        id="tab_1"
                                    >
                                        {publicProfileData?.lastName}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                <div className="flex flex-col mb-1 w-full">
                                    <label
                                        className="flex flex-row font-manrope text-sm my-1"
                                        htmlFor="tab_1"
                                    >
                                        Student ID
                                    </label>
                                    <div
                                        className={`pl-4 flex items-center h-12 inputFieldThickness`}
                                        id="tab_1"
                                    >
                                        {publicProfileData?.id}
                                    </div>
                                </div>
                                <div className="flex flex-col mb-1 w-full">
                                    <label
                                        className="flex flex-row font-manrope text-sm my-1"
                                        htmlFor="tab_1"
                                    >
                                        Gender
                                    </label>
                                    <div
                                        className={`pl-4 flex items-center h-12 inputFieldThickness`}
                                        id="tab_1"
                                    >
                                        {publicProfileData?.gender}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                <div className="flex flex-col mb-1 w-full">
                                    <label
                                        className="flex flex-row font-manrope text-sm my-1"
                                        htmlFor="tab_1"
                                    >
                                        Hometown
                                    </label>
                                    <div
                                        className={`pl-4 flex items-center py-1 h-12 inputFieldThickness`}
                                        id="tab_1"
                                    >
                                        {publicProfileData?.hometown}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                <div className="flex flex-col mb-1 w-full">
                                    <label
                                        className="flex flex-row font-manrope text-sm my-1"
                                        htmlFor="tab_1"
                                    >
                                        Pursuing Higher Education
                                    </label>
                                    <div
                                        className={`pl-4 flex items-center py-1 h-12 inputFieldThickness`}
                                        id="tab_1"
                                    >
                                        {publicProfileData?.isPursuingHigherStudies ? 'yes' : 'no'}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                <div className="flex flex-col mb-1 w-full">
                                    <label
                                        className="flex flex-row font-manrope text-sm my-1"
                                        htmlFor="tab_1"
                                    >
                                        Present Employment Status
                                    </label>
                                    <div
                                        className={`pl-4 flex items-center py-1 h-12 inputFieldThickness`}
                                        id="tab_1"
                                    >
                                        {publicProfileData?.empStatus}
                                    </div>
                                </div>
                            </div>
                        </>

                        <div className="flex flex-col">
                            <h4 className="font-bold font-manrope text-xl mt-4 mb-2">
                                Areas of Interest
                            </h4>
                            <div className="flex flex-wrap  mr-20">
                                {publicProfileData?.tags
                                    ?.filter(tag =>
                                        ['interest', 'interests', 'interest '].includes(
                                            tag.tagCategory.toLowerCase()
                                        )
                                    )
                                    .map(action => (
                                        <div
                                            style={{
                                                maxHeight: '10px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            key={action.id}
                                            className={`rounded-full border-2 my-1 mr-4 text-white bg-purple-400 border-purple-400 py-3 px-2 `}
                                        >
                                            {action.tagName}
                                        </div>
                                    ))}
                            </div>
                        </div>

                        <div className="flex flex-col mb-5">
                            <div className="flex mt-4 mb-2">
                                <div className="font-bold font-manrope text-xl ">
                                    Bridge Progress
                                </div>
                            </div>

                            <table className={`border-0 w-full block max-h-96`}>
                                <thead className="border-0 table w-full table-fixed">
                                    <th className="border-0 p-2">Track Name</th>
                                    <th className="border-0 p-2">Challenge Name</th>
                                    <th className="border-0 p-2">Score</th>
                                </thead>
                                <tbody
                                    className={`block max-h-72 ${
                                        publicProfileData?.bridgeProgress.length > 0 &&
                                        'overflow-y-scroll'
                                    }`}
                                >
                                    {publicProfileData?.bridgeProgress.length > 0 ? (
                                        publicProfileData?.bridgeProgress.map((submission, key) => (
                                            <tr
                                                className="table w-full table-fixed"
                                                key={`key${key}`}
                                            >
                                                <td className="border-2 p-2">
                                                    {submission.trackName}
                                                </td>
                                                <td className="border-2 p-2">
                                                    {submission.challengeName}
                                                </td>
                                                <td className="border-2 p-2">{submission.score}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tbody>
                                            <tr className=" border-2">
                                                <td className="table w-full table-fixed text-center h-10">
                                                    No records exist
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className="flex flex-col mb-32">
                            <div className="flex mt-4 mb-2">
                                <div className="font-bold font-manrope text-xl ">Achievements</div>
                            </div>
                            <div className="flex items-start">
                                <table className="border-0 w-1/2 md:mb-4">
                                    <thead className="border-0">
                                        <tr className="border-0">
                                            <th className="border-0 p-2">Certificates Earned</th>
                                        </tr>
                                    </thead>
                                    {publicProfileData?.certificates.length > 0 ? (
                                        publicProfileData?.certificates?.map((certificate, id) => (
                                            <tbody key={id}>
                                                <tr>
                                                    <td
                                                        key={certificate.id}
                                                        className="border-b-2 border-t-2 border-l-2 p-2"
                                                    >
                                                        {certificate.certificateName}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td className="border-b-2 border-t-2 border-l-2  p-2">
                                                    No certificates exist
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                                <table className="border-0 w-1/2 md:mb-4">
                                    <thead className="border-0">
                                        <tr className="border-0">
                                            <th className="border-0 p-2">Badges Earned</th>
                                        </tr>
                                    </thead>
                                    {publicProfileData?.badges.length > 0 ? (
                                        publicProfileData?.badges?.map((badge, id) => (
                                            <tbody key={id}>
                                                <tr>
                                                    <td key={badge.id} className="border-2 p-2">
                                                        {badge.badgeName}
                                                    </td>
                                                </tr>
                                                <tr></tr>
                                            </tbody>
                                        ))
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td className="border-2 p-2">No badges exist</td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                    </>
                )
            )}
        </div>
    );
};
