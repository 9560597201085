import React, { useState, useMemo, useEffect, useRef } from 'react';
import * as BsIcons from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { getCertificates, getPdfUrl } from '../../../redux/rootActions';
import Tab from './Tab';
import CertificateTable from './CertificateTable';
import { baseCertificateTemplatePath } from '../../../constants';
import { downloadFromExternalLink } from '../../../utils/downloadContent';
import Loader from '../../../assets/svg/Loader';

export const Certificates = () => {
    const dispatch = useDispatch();
    const searchTermRef = useRef();
    const certificatesData = useSelector((state: RootState) => state.certificates.certificatesData);
    const isLoading = useSelector((state: RootState) => state.certificates.isLoading);
    const pageCount = useSelector((state: RootState) => state.certificates.pageCount);
    const certificateTemplateLink = useSelector((state: RootState) => state.certificates.pdfLink);
    const [tags, setTags] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [searchResultsVisible, setSearchResultsVisible] = useState(false);
    const [selectedCertificates, setSelectedCertificates] = useState([]);
    const [selectedMainTab, setSelectedMainTab] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [clickedCertificate, setClickedCertificate] = useState<any[]>();
    const fetchIdRef = React.useRef(0);
    const tabStatus = selectedTab === 1 ? 'ACTIVE' : selectedTab === 2 ? 'INACTIVE' : '';
    const fileName = baseCertificateTemplatePath.split('/', 3);
    const [pageSizeS, setPageSizeS] = useState(0);
    const [pageIndexS, setPageIndexS] = useState(0);
    const [tagIds, setTagIds] = useState('');
    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: '',
                id: 'actionColumn',
                disableSortBy: true,
                width: 5,
                // eslint-disable-next-line react/display-name
                Cell: (
                    <div className="cursor__pointer">
                        <button
                            value="menu cursor-pointer"
                            className="text-gray-500 rounded cursor-pointer focus:outline-none mx-auto flex justify-center"
                        >
                            <BsIcons.BsThreeDotsVertical className="mx-auto" />
                        </button>
                    </div>
                ),
            },
            {
                Header: 'Certificate ID',
                accessor: 'certId',
            },
            {
                Header: 'Certificate Name',
                accessor: 'certificateName',
            },

            {
                Header: 'Track',
                accessor: 'track.trackName',
            },
            {
                Header: 'Created Date',
                accessor: 'certCreatedDate',
            },
            {
                Header: 'Certificate Status',
                accessor: 'certStatus',
            },
        ],
        []
    );

    const fetchData = React.useCallback(
        ({ pageSize, pageIndex }) => {
            setPageSizeS(pageSize);
            setPageIndexS(pageIndex);
            const fetchId = ++fetchIdRef.current;
            if (fetchId === fetchIdRef.current) {
                dispatch(
                    getCertificates(pageIndex, pageSize, tabStatus, tags, searchTermRef.current)
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, tabStatus, tags]
    );

    useEffect(() => {
        if (searchTerm.length === 0) {
            dispatch(getCertificates(pageIndexS, pageSizeS, tabStatus, tags, searchTerm));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    const serchByNameOrId = () => {
        dispatch(getCertificates(pageIndexS, pageSizeS, tabStatus, tags, searchTerm));
    };

    const searchInputHandler = e => {
        searchTermRef.current = e.target.value;
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        dispatch(getPdfUrl(fileName?.[2]));
    }, [dispatch]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            serchByNameOrId();
        }
    };

    return (
        <div className="mx-6 lg:ml-0">
            <div className="mt-12 bg-snow-600">
                <Tab selectedTab={selectedMainTab} setSelectedTab={setSelectedMainTab} />
                <div className="flex w-full">
                    <div className={`mr-4 w-full`}>
                        <div className="flex h-12 md:h-14 justify-between  items-center">
                            <div className="mt-3 ml-4 w-1/2 lg:w-1/3 flex">
                                <input
                                    className={`rounded-sm w-full h-full text-gray-500 font-medium focus:outline-none p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                    id="id"
                                    type="text"
                                    placeholder="Search by ID or Name"
                                    onChange={e => searchInputHandler(e)}
                                    autoComplete="off"
                                    onKeyDown={handleKeyDown}
                                />
                                <button
                                    className={`ml-2 px-5 border-2 font-medium text-sm rounded-md focus:outline-none ${
                                        searchTerm.length > 0
                                            ? ' text-white border-blue-700 bg-blue-700 cursor-pointer'
                                            : 'cursor-not-allowed border-gray-300'
                                    }`}
                                    disabled={searchTerm.length === 0}
                                    onClick={serchByNameOrId}
                                >
                                    <BsIcons.BsSearch
                                        style={searchTerm.length > 0 && { color: '#ffffff' }}
                                    />
                                </button>
                            </div>
                            <div className="flex justify-between">
                                <button
                                    className="hidden md:block w-60 mr-2 mt-1 p-2 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-800 text-sm font-medium leading-149"
                                    onClick={() => {
                                        window.open(certificateTemplateLink, '_blank');
                                    }}
                                >
                                    View base certificate template
                                </button>

                                <button
                                    className="hidden md:block w-60 mt-1 p-2 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-800 text-sm font-medium leading-149"
                                    onClick={() =>
                                        downloadFromExternalLink(
                                            certificateTemplateLink,
                                            'BaseCertificate'
                                        )
                                    }
                                >
                                    Download base certificate template
                                </button>
                            </div>
                        </div>
                        {isLoading && (
                            <div className=" w-full h-98  flex items-center">
                                <Loader />
                            </div>
                        )}
                        <div className={`${isLoading && 'hidden'}`}>
                            <CertificateTable
                                columns={columns}
                                data={
                                    clickedCertificate?.length > 0
                                        ? clickedCertificate
                                        : certificatesData
                                }
                                fetchData={fetchData}
                                pageCount={pageCount}
                                setSlectedChallenges={setSelectedCertificates}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                hideSelection={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
