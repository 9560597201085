import React, { useEffect, useState } from 'react';
import { RootState } from '../../../../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ModalVideo from 'react-modal-video';
import FeaturedSuccessStory from './FeaturedSuccessStory';
import { getSuccessStoriesPublicAPI } from '../../../../../redux/rootActions';
import '../../../../../../node_modules/react-modal-video/scss/modal-video.scss';
import { StoryType } from '../../../../../redux/successStories/reducers';
import { USER_PROFILE } from '../../../../utils/storageWeb';
import { s3UrlPrefix } from '../../../../../constants';
import { youtubeParser } from '../../../../utils';
import useWidth from '../../../../hooks/useWidth';

const SuccessStory = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const successStories = useSelector(
        (state: RootState) => state.successStories.publicAPISuccessStories
    );

    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));

    const [featuredStories, setFeaturedStories] = useState([]);
    const [nonFeaturedStories, setNonFeaturedStories] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [selectedUrl, setSelectedUrl] = useState('');
    const viewPortWidth = useWidth();

    useEffect(() => {
        dispatch(getSuccessStoriesPublicAPI('published'));
    }, [dispatch]);

    useEffect(() => {
        if (successStories.length > 0) {
            const fStories = successStories.filter((story: StoryType) => {
                return story.isFeatured === true;
            });
            const normalStories = successStories.filter((story: StoryType) => {
                return story.isFeatured === false || story.isFeatured === null;
            });
            setFeaturedStories(fStories);
            setNonFeaturedStories(normalStories);
        }
    }, [successStories]);

    const getId = url => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return match && match[2].length === 11 ? match[2] : null;
    };

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div>
                    <div className="bg-purple-85 lg:pt-12 px-10 xl:px-20 flex justify-between">
                        <div className="flex items-center  py-10 xl:px-16">
                            <div className="space-y-5 xl:ml-4">
                                <div className=" text-2xl-2 font-manrope font-bold text-purple-950">
                                    Get mentored by the best of IT industry in Sri Lanka
                                </div>
                                <div className="text-xl  xl:pr-40">
                                    Solve the challenges and pursue ICT career opportunities by
                                    showcasing your skills to the IT/BPM companies in Sri Lanka.
                                </div>
                                <div>
                                    <div
                                        onClick={() => {
                                            if (profile !== null) {
                                                history.push(`/dashBoard/learn/Challenges`);
                                            } else {
                                                history.push('/signin');
                                            }
                                        }}
                                        className="border-1 rounded bg-purple-950 text-white w-36 h-12 flex justify-center items-center font-semibold cursor-pointer"
                                    >
                                        Get started now
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pl-4 xl:pl-20 hidden lg:flex">
                            <div className="flex flex-col justify-center items-center ">
                                <img
                                    className=""
                                    src={`/assets/successStoryImage.png`}
                                    alt="logo"
                                />
                                <div className="rounded-t-xl flex flex-col whitespace-nowrap justify-center items-center bg-purple-400 px-8 xl:px-16 py-3 font-semibold">
                                    <div className="text-white">Thathsarani Bandara</div>
                                    <div className="text-sm">Trainee Software Engineer</div>
                                    <div className="text-sm">Team Maturify at 99X</div>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center pl-4 xl:ml-12">
                                <div className="mb-2">
                                    <img
                                        className=""
                                        src={`/assets/successStoryQuote.png`}
                                        alt="logo"
                                    />
                                </div>

                                <div className="font-manrope">
                                    I got the opportunity to join the IT industry through the BRIDGE
                                    program. And my company is offering a scholarship for me to
                                    follow a part-time degree program while I am working.
                                </div>
                                <div className="font-manrope mt-2">August 2022</div>
                            </div>
                        </div>
                    </div>

                    {featuredStories.length > 0 && (
                        <div className="flex flex-col justify-center items-center bg-black mt-10">
                            <div className="flex justify-center mt-8 text-2xl-2 font-manrope font-bold">
                                Explore What Our Alumni Say
                            </div>
                            <FeaturedSuccessStory stories={featuredStories} />
                        </div>
                    )}

                    <div className="flex flex-col justify-center my-20 px-28">
                        <div className="flex ">
                            <div className="text-2xl-2 font-manrope font-bold">
                                Read More Success Stories Of Student Like You.
                            </div>
                        </div>

                        <div className="mt-6 grid grid-cols-1 xl:grid-cols-3 gap-8">
                            {nonFeaturedStories?.map((story: StoryType) => (
                                <div
                                    key={story.id}
                                    className="flex"
                                    style={{
                                        backgroundColor: '#F5F3FC',
                                        paddingTop: 15,
                                        paddingBottom: 15,
                                        paddingRight: 15,
                                        borderRadius: 4,
                                    }}
                                >
                                    <div className="flex">
                                        <div className="ml-3 flex justify-center items-center">
                                            <div>
                                                <div className="flex pb-2">
                                                    <img
                                                        className="w-10 h-10 rounded-full"
                                                        src={`${
                                                            story.imageUrl !== null
                                                                ? `${s3UrlPrefix}${story.imageUrl}`
                                                                : '/assets/placeholder-user.png'
                                                        }`}
                                                        alt="profile"
                                                    />
                                                    <div className="ml-4 text-xs flex flex-col justify-center font-bold">
                                                        {story.firstName}&nbsp;{story.lastName}
                                                    </div>
                                                </div>
                                                <div className="my-2 font-normal text-xs">
                                                    {story.content}
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setSelectedUrl(
                                                            youtubeParser(story.storyLink)
                                                        );
                                                        setShowModal(true);
                                                    }}
                                                    className="text-purple-950 border-2 border-purple-950 hover:bg-purple-950 hover:text-white rounded flex items-center justify-center w-36 h-10 cursor-pointer"
                                                >
                                                    Watch Now
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <ModalVideo
                            channel="youtube"
                            autoplay
                            isOpen={showModal}
                            videoId={selectedUrl}
                            onClose={() => setShowModal(false)}
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <div className="bg-purple-85 flex justify-between">
                        <div className="flex flex-col py-10 px-8 gap-y-5">
                            <div className=" text-lg font-manrope font-bold text-purple-950">
                                Get mentored by the best of IT industry in Sri Lanka
                            </div>
                            <div className="text-xs">
                                Solve the challenges and pursue ICT career opportunities by
                                showcasing your skills to the IT/BPM companies in Sri Lanka.
                            </div>
                            <div
                                onClick={() => {
                                    if (profile !== null) {
                                        history.push(`/dashBoard/learn/Challenges`);
                                    } else {
                                        history.push('/signin');
                                    }
                                }}
                                className="border-1 rounded bg-purple-950 text-white w-36 h-12 flex justify-center items-center font-semibold cursor-pointer"
                            >
                                Get started now
                            </div>
                            <div className="flex flex-col justify-center items-center ">
                                <img
                                    className=""
                                    src={`/assets/successStoryImage.png`}
                                    alt="logo"
                                />
                                <div className="rounded-t-xl flex flex-col whitespace-nowrap justify-center items-center bg-purple-400 px-16 py-3">
                                    <div className="text-white font-bold">Thathsarani Bandara</div>
                                    <div className="text-sm font-semibold">
                                        Trainee Software Engineer
                                    </div>
                                    <div className="text-sm font-semibold">
                                        Team Maturify at 99X
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center pl-4 mt-2">
                                <div className="mb-2">
                                    <img
                                        className=""
                                        src={`/assets/successStoryQuote.png`}
                                        alt="logo"
                                    />
                                </div>

                                <div className="font-manrope">
                                    I got the opportunity to join the IT industry through the BRIDGE
                                    program. And my company is offering a scholarship for me to
                                    follow a part-time degree program while I am working.
                                </div>
                                <div className="font-manrope mt-2">August 2022</div>
                            </div>
                        </div>
                    </div>

                    {featuredStories.length > 0 && (
                        <div className="flex flex-col justify-center items-center bg-black mt-6">
                            <div className="flex justify-center mt-8 text-lg font-manrope font-bold">
                                Explore What Our Alumni Say
                            </div>
                            <FeaturedSuccessStory stories={featuredStories} />
                        </div>
                    )}

                    <div className="flex flex-col justify-center mt-4 mb-6 px-8">
                        <div className="flex ">
                            <div className="text-lg font-manrope font-bold">
                                Read More Success Stories Of Student Like You.
                            </div>
                        </div>

                        <div className="mt-6 grid grid-cols-1 xl:grid-cols-3 gap-8">
                            {nonFeaturedStories?.map((story: StoryType) => (
                                <div
                                    key={story.id}
                                    className="flex"
                                    style={{
                                        backgroundColor: '#F5F3FC',
                                        paddingTop: 15,
                                        paddingBottom: 15,
                                        paddingRight: 15,
                                        borderRadius: 4,
                                    }}
                                >
                                    <div className="flex w-full">
                                        <div className="ml-3 flex flex-col w-full gap-y-2">
                                            <div className="flex pb-2 items-center">
                                                <img
                                                    className="w-8 h-8 rounded-full"
                                                    src={`${
                                                        story.imageUrl !== null
                                                            ? `${s3UrlPrefix}${story.imageUrl}`
                                                            : '/assets/placeholder-user.png'
                                                    }`}
                                                    alt="profile"
                                                />
                                                <div className="ml-4 text-xs font-bold">
                                                    {story.firstName}&nbsp;{story.lastName}
                                                </div>
                                            </div>
                                            <div className="my-2 font-normal text-xs">
                                                {story.content}
                                            </div>
                                            <div
                                                onClick={() => {
                                                    setSelectedUrl(youtubeParser(story.storyLink));
                                                    setShowModal(true);
                                                }}
                                                className="text-purple-950 border-2 border-purple-950 hover:bg-purple-950 hover:text-white rounded flex items-center justify-center w-36 h-10 cursor-pointer"
                                            >
                                                Watch Now
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <ModalVideo
                            channel="youtube"
                            autoplay
                            isOpen={showModal}
                            videoId={selectedUrl}
                            onClose={() => setShowModal(false)}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default SuccessStory;
