import React from 'react';

interface Props {
    selectedTags: any;
}
export default function TagGroupsView({ selectedTags }: Props) {
    return (
        <div className="mb-8 w-min">
            {selectedTags
                ? selectedTags?.map(tagGroup => (
                      <>
                          <div>{tagGroup[0].tagCategory}</div>
                          {tagGroup.map(group => (
                              <>
                                  <p
                                      style={{
                                          maxHeight: '10px',
                                          maxWidth: 'inherit',
                                          display: 'flex',
                                          alignItems: 'center',
                                      }}
                                      key={tagGroup.id}
                                      className={`rounded-sm border-2 my-1 mr-4 text-gray-500 border-gray-400 font-medium py-3 px-1 w-max`}
                                  >
                                      {group?.tagName}
                                  </p>
                              </>
                          ))}
                      </>
                  ))
                : null}
        </div>
    );
}
