import React, { ReactElement } from 'react';

interface Props {
    color: string;
}

export default function ProgressMobile({ color }: Props): ReactElement {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.4559 13.0327L20.4561 13.0311C21.0115 8.51521 17.9308 4.3965 13.4998 3.6192V2.5983C17.7335 3.26867 21.0423 6.72256 21.452 11.0472L21.4523 11.0498C21.9563 16.0894 18.4395 20.5808 13.4998 21.3617V20.3626C17.1602 19.7324 19.9864 16.7479 20.4559 13.0327ZM11.4998 12.7169V7.50003H11.9998V12.25V12.5347L12.2447 12.68L16.3094 15.0918L16.0814 15.4659L11.4998 12.7169ZM6.41551 4.31224C7.62249 3.42729 9.0247 2.85674 10.4998 2.62702V3.63633C9.29165 3.86858 8.14766 4.35848 7.15103 5.07347L6.41551 4.31224ZM3.62346 10.5H2.61711C2.84853 9.02658 3.42793 7.62929 4.3071 6.42443L5.03068 7.14801C4.32291 8.15246 3.84677 9.29779 3.62346 10.5ZM5.0326 16.8523L4.31774 17.5722C3.44609 16.3667 2.86715 14.9736 2.62964 13.5H3.63183C3.85175 14.7053 4.32962 15.8489 5.0326 16.8523ZM10.4998 21.4268C9.02709 21.18 7.63316 20.5892 6.43167 19.7026L7.14123 19.0228C8.14613 19.7282 9.29201 20.2076 10.4998 20.428V21.4268Z"
                fill={color}
                stroke={color}
            />
        </svg>
    );
}
