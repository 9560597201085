import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React from 'react';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import TermsAndConditions from '../web/components/Pages/PublicSite/Termandconditions/TermsAndConditions';
import Companies from '../web/components/Pages/PublicSite/Company/Companies';
import Company from '../web/components/Pages/PublicSite/Company/Company';
import CompanyAll from '../web/components/Pages/PublicSite/Company/CompanyAll';
import JoinSlasscom from '../web/components/Pages/PublicSite/Company/JoinSlasscom';
import JoinSlasscomInstitute from '../web/components/Pages/PublicSite/Institute/JoinSlasscomInstitute';
import Events from '../web/components/Pages/PublicSite/Events/Events';
import Event from '../web/components/Pages/PublicSite/Events/Event';
import WebInstitutes from '../web/components/Pages/PublicSite/Institute/Institutes';
import WebInstitute from '../web/components/Pages/PublicSite/Institute/Institute';
import Leaderboard from '../web/components/Pages/PublicSite/LeaderBoard/Leaderboard';
import WebSuccessStories from '../web/components/Pages/PublicSite/SuccessStory/SuccessStories';
import SolveChallenges from '../web/components/Pages/PublicSite/SolveChallenge/SolveChallenges';
import solveChallengesTracks from '../web/components/Pages/PublicSite/SolveChallenge/SolveChallengeTracks';
import solveChallengesCertificates from '../web/components/Pages/PublicSite/SolveChallenge/SolveChallengeCertificates';
import solveChallengeChallenges from '../web/components/Pages/PublicSite/SolveChallenge/SolveChallengeChallenges';

import Signup from '../web/components/Pages/PublicSite/LoginRegister/Register/Signup';
import SocialMediaRedirect from '../web/components/Pages/PublicSite/LoginRegister/SocialMediaRedirect';
import SignIn from '../web/components/Pages/PublicSite/LoginRegister/Login/SignIn';
import ForgotPassword from '../web/components/Pages/PublicSite/LoginRegister/Login/ForgotPassword';
import OnBoard from '../web/components/Pages/PublicSite/LoginRegister/Register/Onboard';
import MyProgress from '../web/components/Pages/MyProgress/MyProgress';
import AllBadges from '../web/components/Pages/MyProgress/AllBadges';
import AllCertificates from '../web/components/Pages/MyProgress/AllCertificates';
import EditProfile from '../web/components/Pages/Profile/EditProfile';
import Learn from '../web/components/Pages/Learn/Learn';
import RecommandedTracks from '../web/components/Pages/Learn/RecommandedAllTracks';
import AllChallenges from '../web/components/Pages/Learn/Challenges';
import RecommandedChallenges from '../web/components/Pages/Learn/RecommandedChallenges';
import WebLeaderboard from '../web/components/Pages/Leaderboard/Leaderboard';
import LearnChallenge from '../web/components/Pages/Learn/Challenge/Challenge';
import ProfileSetting from '../web/components/Pages/Profile/ProfileSettings';
import RequestMentor from '../web/components/Pages/RequestMentors/RequestMentor';
import Help from '../web/components/Pages/Help';
import HelpGettingStarted from '../web/components/Pages/Help/Topics/GettingStarted';
import HelpRequestMentor from '../web/components/Pages/Help/Topics/RequestMentor';
import HelpSettings from '../web/components/Pages/Help/Topics/Settings';
import HelpStartTrack from '../web/components/Pages/Help/Topics/StartTrack';
import HelpTroubleshooting from '../web/components/Pages/Help/Topics/Troubleshooting';
import RoutesWithLayoutWeb from './ProtectedRoutesWithLayoutWeb';
import InstituteAll from '../web/components/Pages/PublicSite/Institute/InstituteAll';
import ChangeEmailFeedback from '../web/components/Pages/Profile/ChangeEmailFeedback';
import VerifyEmail from '../web/components/Pages/Profile/VerifyEmail';
import WebVerifyEmail from '../web/components/Pages/PublicSite/LoginRegister/EmailRequest/WebVerifyEmail';
import WebNotifications from '../web/components/Pages/Notification/WebNotifications';
import WebResetPassword from '../web/components/Pages/PublicSite/LoginRegister/EmailRequest/WebResetPassword';
import PublicCertificate from '../web/components/Pages/MyProgress/PublicCertificate';
import ChangeMobileFeedback from '../web/components/Pages/Profile/ChangeMobileFeedback';
import PageNotFound from '../web/components/Pages/Common/PageNotFound';
import { PublicProfile } from '../web/components/Pages/PublicSite/PublicProfile/PublicProfile';
import { FCBHomeURL } from '../constants';

interface Props {
    sidebar: boolean;
    setSidebar: (value: boolean) => void;
}

function WebRoutes({ sidebar }: Props) {
    return (
        <Router>
            <Switch>
                <Route exact path="/linkedin" component={LinkedInCallback} />
                <Route path="/oauth2/redirect" component={SocialMediaRedirect} />
                <Route
                    path="/"
                    exact
                    component={() => {
                        window.location.href = FCBHomeURL;
                        return null;
                    }}
                />
                <Route
                    path="/home"
                    exact
                    component={() => {
                        window.location.href = FCBHomeURL;
                        return null;
                    }}
                />
                <RoutesWithLayoutWeb
                    path="/solve-challenges"
                    exact
                    component={SolveChallenges}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/termsAndConditions"
                    exact
                    component={TermsAndConditions}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/solve-challenges/tracks"
                    exact
                    component={solveChallengesTracks}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/solve-challenges/certificates"
                    exact
                    component={solveChallengesCertificates}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/solve-challenges/tracks/challenges"
                    exact
                    component={solveChallengeChallenges}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/leaderboard"
                    exact
                    component={Leaderboard}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/companies"
                    exact
                    component={Companies}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/company/:id"
                    exact
                    component={Company}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/view-all/company"
                    exact
                    component={CompanyAll}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/companies/joinSlasscom"
                    exact
                    component={JoinSlasscom}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/institutes/joinSlasscom"
                    exact
                    component={JoinSlasscomInstitute}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/success-stories"
                    exact
                    component={WebSuccessStories}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb path="/events" exact component={Events} sidebar={sidebar} />
                <RoutesWithLayoutWeb path="/event/:id" exact component={Event} sidebar={sidebar} />
                <RoutesWithLayoutWeb
                    path="/institutes"
                    exact
                    component={WebInstitutes}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/institute/:id"
                    exact
                    component={WebInstitute}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/public-profile/:userId"
                    exact
                    component={PublicProfile}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/view-all/institute"
                    exact
                    component={InstituteAll}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb path="/register" exact component={Signup} sidebar={sidebar} />
                <RoutesWithLayoutWeb path="/signin" exact component={SignIn} sidebar={sidebar} />
                <RoutesWithLayoutWeb
                    path="/student/register/verification"
                    component={WebVerifyEmail}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/student/reset-password"
                    component={WebResetPassword}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb path="/onboard" exact component={OnBoard} sidebar={sidebar} />
                <RoutesWithLayoutWeb
                    path="/dashBoard/learn"
                    exact
                    component={Learn}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/notifications"
                    exact
                    component={WebNotifications}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/learn/RecommandedTracks"
                    exact
                    component={RecommandedTracks}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/learn/RecommandedChallenges"
                    exact
                    component={RecommandedChallenges}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/learn/Challenges"
                    exact
                    component={AllChallenges}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/myProgress"
                    exact
                    component={MyProgress}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/myProgress/badges"
                    exact
                    component={AllBadges}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/myProgress/certificates"
                    exact
                    component={AllCertificates}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/leaderboard"
                    exact
                    component={WebLeaderboard}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/requestMentor"
                    exact
                    component={RequestMentor}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/help"
                    exact
                    component={Help}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/help/getting-started"
                    exact
                    component={HelpGettingStarted}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/help/settings"
                    exact
                    component={HelpSettings}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/help/troubleshooting"
                    exact
                    component={HelpTroubleshooting}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/help/start-track"
                    exact
                    component={HelpStartTrack}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/help/request-mentor"
                    exact
                    component={HelpRequestMentor}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/profile/edit"
                    exact
                    component={EditProfile}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/settings"
                    exact
                    component={ProfileSetting}
                    sidebar={sidebar}
                />
                <RoutesWithLayoutWeb
                    path="/dashBoard/learn/Challenges/:challengeID"
                    exact
                    component={LearnChallenge}
                    sidebar={sidebar}
                />
                <Route
                    path="/dashBoard/profile/changeEmail"
                    component={ChangeEmailFeedback}
                    exact
                />
                <Route
                    path="/dashBoard/profile/changeMobile"
                    component={ChangeMobileFeedback}
                    exact
                />
                <RoutesWithLayoutWeb
                    path="/email/change"
                    sidebar={sidebar}
                    component={VerifyEmail}
                />
                <Route path="/certificate/public/:code" component={PublicCertificate} />
                <Route path="*" component={PageNotFound} />
            </Switch>
        </Router>
    );
}

export default WebRoutes;
