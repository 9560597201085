import { TagsType } from '../tags/reducers';
import {
    ADD_INSTITUTE,
    ADD_INSTITUTE_FAILED,
    ADD_INSTITUTE_SUCCESS,
    CLEAR_INSTITUTE_MESSAGES,
    DELETE_INSTITUTE,
    DELETE_INSTITUTE_FAILED,
    DELETE_INSTITUTE_SUCCESS,
    EDIT_INSTITUTE,
    EDIT_INSTITUTE_FAILED,
    EDIT_INSTITUTE_SUCCESS,
    GET_INSTITUTEBYID,
    GET_INSTITUTEBYID_FAILED,
    GET_INSTITUTEBYID_SUCCESS,
    GET_INSTITUTES,
    GET_INSTITUTES_FAILED,
    GET_INSTITUTES_PUBLIC,
    GET_INSTITUTES_PUBLIC_FAILED,
    GET_INSTITUTES_PUBLIC_SUCCESS,
    GET_INSTITUTES_SUCCESS,
    INSTITUTE_BULK_UPDATE,
    INSTITUTE_BULK_UPDATE_FAILED,
    INSTITUTE_BULK_UPDATE_SUCCESS,
    GET_INSTITUTEBYID_PUBLIC,
    GET_INSTITUTEBYID_SUCCESS_PUBLIC,
    GET_INSTITUTEBYID_FAILED_PUBLIC,
} from './actions';

export interface InstitutesEditType {
    instName: string;
    instEmail: string;
    contactNo: number;
    location: string;
    logoImage: string;
    bannerImage: string;
    repName: string;
    instDescription: string;
    instCreatedBy: string;
    category: string;
    faculties: [];
    courses: [];
}
export interface InstitutesType {
    id: string;
    instId: string;
    instEmail: string;
    category: string;
    contactNo: number;
    instCategory1: any;
    instCategory2: any;
    instCategory3: any;
    location: string;
    instCreatedBy: string;
    instDescription: string;
    instJoinedDate: string;
    repName: string;
    instLastUpdatedBy: string;
    instLastUpdatedDate: string;
    instName: string;
    instStatus: string;
    logoImage: string;
    bannerImage: string;
    siteUrl: string;
    tags: TagsType[];
    mouSigned: boolean;
}
export interface CountType {
    ALL: number;
    ACTIVE: number;
    DELETED: number;
}
export interface InstitutesInitialState {
    institutesData: InstitutesType[];
    publicInstitutData: InstitutesType[];
    institute: InstitutesType;
    publicInstitute: InstitutesType;
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    isLoadingPublicData: boolean;
    instituteByIdLoading: boolean;
    instituteByIdError: string | null;
    instituteError: string | null;
    institutePublicError: string | null;
    createInstituteError: string | null;
    instituteCreateSuccessMessage: string | null;
    isDeleteLoading: boolean;
    deleteInstituteError: string | null;
    instituteDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editInstituteError: string | null;
    instituteUpdateSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    instituteBulkSuccessMessage: string | null;
}

const initialState: InstitutesInitialState = {
    institutesData: [],
    publicInstitutData: [],
    institute: null,
    publicInstitute: null,
    pageCount: null,
    count: null,
    isLoading: false,
    isLoadingPublicData: false,
    instituteByIdLoading: false,
    instituteByIdError: null,
    instituteError: null,
    institutePublicError: null,
    createInstituteError: null,
    instituteCreateSuccessMessage: null,
    isDeleteLoading: false,
    deleteInstituteError: null,
    instituteDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editInstituteError: null,
    instituteUpdateSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    instituteBulkSuccessMessage: null,
};

const institutesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_INSTITUTES:
            return { ...state, isLoading: true, instituteError: null };
        case GET_INSTITUTES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                institutesData: action.payload.items,
                pageCount: action.payload.totalPages,
            };

        case GET_INSTITUTES_FAILED:
            return { ...state, isLoading: false, instituteError: action.payload };

        case GET_INSTITUTES_PUBLIC:
            return { ...state, isLoadingPublicData: true, institutePublicError: null };
        case GET_INSTITUTES_PUBLIC_SUCCESS:
            return {
                ...state,
                isLoadingPublicData: false,
                publicInstitutData: action.payload,
            };
        case GET_INSTITUTES_PUBLIC_FAILED:
            return { ...state, isLoadingPublicData: false, institutePublicError: action.payload };
        case GET_INSTITUTEBYID:
            return { ...state, instituteByIdLoading: true, instituteByIdError: null };
        case GET_INSTITUTEBYID_SUCCESS:
            return {
                ...state,
                instituteByIdLoading: false,
                institute: action.payload,
            };
        case GET_INSTITUTEBYID_FAILED:
            return { ...state, instituteByIdLoading: false, instituteByIdError: action.payload };

        case GET_INSTITUTEBYID_PUBLIC:
            return { ...state, companyByIdLoading: true, companyByIdError: null };
        case GET_INSTITUTEBYID_SUCCESS_PUBLIC:
            return {
                ...state,
                companyByIdLoading: false,
                publicInstitute: action.payload,
            };
        case GET_INSTITUTEBYID_FAILED_PUBLIC:
            return { ...state, companyByIdLoading: false, companyByIdError: action.payload };
        case ADD_INSTITUTE:
            return { ...state, isCreateLoading: true, createInstituteError: null };
        case ADD_INSTITUTE_SUCCESS:
            return {
                ...state,
                isCreateLoading: false,
                instituteCreateSuccessMessage: action.payload,
            };
        case ADD_INSTITUTE_FAILED:
            return { ...state, isCreateLoading: false, createInstituteError: action.payload };
        case DELETE_INSTITUTE:
            return { ...state, isDeleteLoading: true, deleteInstituteError: null };
        case DELETE_INSTITUTE_SUCCESS:
            return {
                ...state,
                isDeleteLoading: false,
                instituteDeleteSuccessMessage: action.payload,
            };
        case DELETE_INSTITUTE_FAILED:
            return { ...state, isDeleteLoading: false, deleteInstituteError: action.payload };
        case EDIT_INSTITUTE:
            return { ...state, isEditLoading: true, editInstituteError: null };
        case EDIT_INSTITUTE_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                instituteUpdateSuccessMessage: action.payload,
            };
        case EDIT_INSTITUTE_FAILED:
            return { ...state, isEditLoading: false, editInstituteError: action.payload };
        case INSTITUTE_BULK_UPDATE:
            return { ...state, isBulkUpdateLoading: true, bulkError: null };
        case INSTITUTE_BULK_UPDATE_SUCCESS:
            return {
                ...state,
                isBulkUpdateLoading: false,
                instituteBulkSuccessMessage: action.payload,
            };
        case INSTITUTE_BULK_UPDATE_FAILED:
            return { ...state, isBulkUpdateLoading: false, bulkError: action.payload };
        case CLEAR_INSTITUTE_MESSAGES:
            return {
                ...state,
                instituteCreateSuccessMessage: null,
                createInstituteError: null,
                instituteUpdateSuccessMessage: null,
                editInstituteError: null,
                deleteInstituteError: null,
                instituteDeleteSuccessMessage: null,
                instituteBulkSuccessMessage: null,
                bulkError: null,
            };
        default:
            return state;
    }
};

export default institutesReducer;
