import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Camera from '../../../assets/svg/Camera';
import { useDispatch, useSelector } from 'react-redux';
import { addBadge, clearBadgesMessages, getChallenges, getTags } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { useHistory } from 'react-router-dom';
import { error, moduleNames, s3Config } from '../../../constants';
import S3 from 'react-aws-s3';
import ReactSelect, { components } from 'react-select';
import BadgesSuccessModal from '../../modals/BadgesSuccessModal';
import * as HiIcons from 'react-icons/hi';
import CropModal from '../../modals/cropModal';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroups from '../../TagGroups';

export const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};
export default function AddNewBadge() {
    const dispatch = useDispatch();
    const history = useHistory();
    const newFileName = Date.now().toString();
    const isCreateLoading = useSelector((state: RootState) => state.badges.isCreateLoading);
    const createError = useSelector((state: RootState) => state.badges.createBadgeError);
    const BadgeCreateSuccessMessage = useSelector(
        (state: RootState) => state.badges.BadgeCreateSuccessMessage
    );
    const challenges = useSelector((state: RootState) => state.challenges.challengesData);

    const formRef = useRef<any>();
    const [imageUrl, setImageUrl] = useState('');
    const [imageLocation, setImageLocation] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [createSuccessVisible, setCreateSuccessVisible] = useState(false);
    const [optionSelected, setOptionSelected] = useState<any>();
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [tags, setTags] = useState<any>([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [resolutionError, setResolutionError] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [openCrop, setOpenCrop] = useState(false);

    const [tagsHelpText, setTagsHelpText] = useState<boolean>(false);

    const serchTagNameOrId = e => {
        setSearchTerm(e.target.value);
        // setTags([]);
    };

    useEffect(() => {
        if (showDropdown) {
            dispatch(getTags(true, moduleNames.Badges, searchTerm));
        }
    }, [dispatch, searchTerm, showDropdown]);

    useEffect(() => {
        setSelectedTags(categorizeTags(tags));
    }, [tags]);

    const style = {
        control: base => ({
            ...base,
            '&:hover': { borderColor: '#1e3a8a', opacity: 1 },
            border: '2px solid rgba(209, 213, 219, 1)',
            borderRadius: '0.125rem',
            boxShadow: 'none',
        }),
        option: (styles, { isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused
                    ? '#e5e7eb'
                    : isSelected
                    ? '#e5e7eb'
                    : isDisabled
                    ? '#d1d5db'
                    : '#ffffff',
                color: '#333333',
            };
        },
    };
    const selectedChallengeIds = {
        idList: optionSelected?.map(elem => {
            return elem.value;
        }),
    };
    const challengeArray = [
        challenges?.map(elem => {
            return {
                value: elem.id,
                label: elem.challengeName,
            };
        }),
    ];
    const handleChangeChallenges = selected => {
        setOptionSelected(selected);
    };
    const handleClearMessages = useCallback(() => {
        if (BadgeCreateSuccessMessage || createError) {
            setCreateSuccessVisible(true);
        }
    }, [dispatch, BadgeCreateSuccessMessage, createError, history]);
    const handleClose = () => {
        dispatch(clearBadgesMessages());
        if (!createError) {
            history.push('/admin/achievements/badges');
        }
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    useEffect(() => {
        dispatch(getChallenges(0, 100, 'PUBLISHED'));
    }, [dispatch]);

    const uploadImageToS3 = async croppedImg => {
        setIsUploading(true);
        s3Config.dirName = `badges/${newFileName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(croppedImg, newFileName)
            .then(data => {
                setImageLocation(data.key);
                setIsUploading(false);
                setUploadError(false);
            })
            .catch(err => {
                console.error(err);
                setIsUploading(false);
                setUploadError(true);
            });
    };
    const handleImage = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setImageUrl(URL.createObjectURL(file));
            setOpenCrop(true);
        }
    };
    const handleCloseCropModal = () => {
        setOpenCrop(false);
        setImageUrl('');
    };
    return (
        <div className="mx-6 lg:ml-2 h-full">
            <div className="mt-12 bg-snow-600">
                <div className=" mt-12">
                    <div className="relative">
                        <Formik
                            innerRef={formRef}
                            initialValues={{
                                badgeName: '',
                                badgeStatus: 'ACTIVE',
                                imageUrl: imageLocation,
                                image: undefined,
                                badgeDescription: '',
                                challenges: undefined,
                                eventType: 'VIRTUAL',
                            }}
                            validationSchema={Yup.object({
                                badgeName: Yup.string()
                                    .max(100, 'Name should be less than 100 characters')
                                    .required('Name is required'),
                                badgeDescription: Yup.string()
                                    .max(500, 'Description should be less than 500 characters')
                                    .required('Description is required'),
                                challenges: Yup.array().min(1).required('Challenge is required'),
                                image: Yup.array().required('Badge image is required'),
                            })}
                            onSubmit={async values => {
                                dispatch(
                                    addBadge({
                                        badgeName: values.badgeName.trim(),
                                        badgeStatus: values.badgeStatus,
                                        imageUrl: imageLocation,
                                        badgeType: 'CHALLENGE_BASED',
                                        criteria: selectedChallengeIds,
                                        badgeDescription: values.badgeDescription.trim(),
                                        badgeCreatedBy: 'ADMIN',
                                        tags: tags,
                                    })
                                );
                            }}
                        >
                            {({
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                values,
                                errors,
                                touched,
                            }) => (
                                <>
                                    <div className="flex justify-between items-center mb-4 md:mb-6">
                                        <p className="text-xl font-semibold text-left">
                                            Add New Badge
                                        </p>
                                        <div className="flex w-1/2 md:w-1/4 lg:pl-8 h-16">
                                            <button
                                                className="md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() =>
                                                    history.push('/admin/achievements/badges')
                                                }
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => handleSubmit()}
                                            >
                                                {isCreateLoading ? <Loader /> : 'Save'}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2 pb-8 mb-6">
                                        <div className="col-span-12 md:col-span-4 mb-2 md:mb-4">
                                            <div className="text-lg font-medium mb-6">
                                                Badge Details
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="badgeName"
                                                    className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Badge Name
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.badgeName && touched.badgeName
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="badgeName"
                                                        type="text"
                                                        value={values.badgeName}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'badgeName',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.badgeName && touched.badgeName ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.badgeName}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex">
                                                <label
                                                    className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Badge Status
                                                    <div className="text-red-600">*</div>
                                                </label>
                                                <div className="flex text-sm md:text-base items-center w-full">
                                                    <input
                                                        className="mr-1"
                                                        type="radio"
                                                        value="ACTIVE"
                                                        id="active"
                                                        name="status"
                                                        defaultChecked={true}
                                                        onChange={handleChange('badgeStatus')}
                                                    />
                                                    <label className="mr-4 md:mr-8">Active</label>
                                                    <input
                                                        className="mr-1"
                                                        type="radio"
                                                        value="INACTIVE"
                                                        id="inactive"
                                                        name="status"
                                                        onChange={handleChange('badgeStatus')}
                                                    />
                                                    <label className="">Inactive</label>
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex">
                                                <label
                                                    className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Challenges Applicable
                                                    <div className="text-red-600">*</div>
                                                </label>
                                                <div className="flex flex-col w-full">
                                                    <div className="w-3/4 flex">
                                                        <ReactSelect
                                                            options={challengeArray[0]}
                                                            isMulti
                                                            isSearchable={false}
                                                            className={'w-full'}
                                                            styles={style}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            components={{ Option }}
                                                            onChange={e => {
                                                                handleChangeChallenges(e);
                                                                setFieldValue('challenges', e);
                                                            }}
                                                            value={optionSelected}
                                                        />
                                                    </div>
                                                    {errors.challenges && touched.challenges ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.challenges}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex h-32">
                                                <label
                                                    htmlFor="badgeDescription"
                                                    className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Description
                                                    <div className="text-red-600">*</div>
                                                </label>
                                                <div className="flex flex-col w-full">
                                                    <textarea
                                                        className={`resize-none focus:outline-none p-2 w-4/5 md:w-3/4 border-2 text-sm h-32 focus:border-blue-900 ${
                                                            errors.badgeDescription &&
                                                            touched.badgeDescription
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        }`}
                                                        id="description"
                                                        value={values.badgeDescription}
                                                        placeholder=""
                                                        onChange={handleChange('badgeDescription')}
                                                    />
                                                    {errors.badgeDescription &&
                                                    touched.badgeDescription ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.badgeDescription}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex">
                                                <label
                                                    htmlFor="image"
                                                    className={`flex items-start w-1/4 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Badge Image
                                                    <div className="text-red-600">*</div>
                                                </label>
                                                <div
                                                    className={`bg-snow-600 w-3/4 md:w-3/5 mb-2 md:mb-4 relative ${
                                                        imageUrl ? 'w-fit' : ''
                                                    }`}
                                                >
                                                    <div
                                                        className={`text-base border-dashed border-opacity-100 border-2 ${
                                                            errors.image && touched.image
                                                                ? 'border-red-500'
                                                                : 'border-black-600'
                                                        } justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64`}
                                                    >
                                                        {!imageUrl && (
                                                            <div className="w-full">
                                                                <div className="flex justify-center">
                                                                    <Camera />
                                                                </div>
                                                                <div>
                                                                    <label
                                                                        className="underline cursor-pointer"
                                                                        htmlFor="image"
                                                                    >
                                                                        Upload Image
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {imageUrl && (
                                                            <img
                                                                src={imageUrl}
                                                                alt={'name'}
                                                                className="h-44 lg:h-64"
                                                                style={{ maxHeight: 300 }}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="absolute mt-1 text-gray-400 text-sm font-medium leading-149">
                                                        Image size 80*80
                                                    </div>
                                                    <div
                                                        className="absolute mt-8 text-center w-max"
                                                        style={{
                                                            left: '50%',
                                                            transform: 'translate(-50%,-50%)',
                                                        }}
                                                    >
                                                        {imageUrl && !isUploading && (
                                                            <div>
                                                                <label
                                                                    className="underline cursor-pointer"
                                                                    htmlFor="image"
                                                                >
                                                                    Change Image
                                                                </label>
                                                            </div>
                                                        )}
                                                        {isUploading && (
                                                            <div className="flex items-center">
                                                                <div className="mr-2">
                                                                    Uploading
                                                                </div>
                                                                <div>
                                                                    <Loader />
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="text-red-500 text-xs mt-1">
                                                            {errors.image && touched.image
                                                                ? errors.image
                                                                : resolutionError
                                                                ? 'Image minimum resolution size is 100 X 100'
                                                                : uploadError
                                                                ? error
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <input
                                                    className={`focus:outline-none w-full focus:border-blue-900`}
                                                    id="image"
                                                    type="file"
                                                    accept="image/png, image/jpeg"
                                                    onChange={async e => {
                                                        const file = [];
                                                        file.push(e.target.files[0]);
                                                        setFieldValue('image', file);
                                                        handleImage(e);
                                                    }}
                                                    style={{ display: 'none' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                            <div className="flex border-2 flex-col ml-2 justify-items-center">
                                                <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                                    <div className="flex sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                        <div>Assign Tags</div>
                                                        <div>
                                                            <HiIcons.HiQuestionMarkCircle
                                                                onClick={() => {
                                                                    setTagsHelpText(true);
                                                                }}
                                                                className="ml-2 mt-1 cursor-pointer"
                                                            />
                                                        </div>
                                                    </div>
                                                    {tagsHelpText ? (
                                                        <div
                                                            onMouseOverCapture={() => {
                                                                setTagsHelpText(true);
                                                                // setProfileDropdownVisible(false);
                                                            }}
                                                            onMouseLeave={() =>
                                                                setTagsHelpText(false)
                                                            }
                                                            className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-6 right-12"
                                                        >
                                                            Assign already created tags to the new
                                                            record created.
                                                        </div>
                                                    ) : null}
                                                    <div
                                                        className="sm:text-sm md:text-base text-gray-400 underline pr-2 cursor-pointer"
                                                        onClick={() => {
                                                            setTags([]);
                                                        }}
                                                    >
                                                        Clear All
                                                    </div>
                                                </div>
                                                <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                                    <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                        <TagGroups
                                                            selectedTags={selectedTags}
                                                            tags={tags}
                                                            setTags={setTags}
                                                        />
                                                        <div
                                                            onMouseLeave={() => {
                                                                setShowDropdown(false);
                                                            }}
                                                        >
                                                            <input
                                                                className={`rounded w-full text-gray-500 font-medium focus:outline-none mt-2 p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                                                id="id"
                                                                type="text"
                                                                placeholder="Search tags"
                                                                onChange={e => serchTagNameOrId(e)}
                                                                onMouseEnter={() => {
                                                                    setShowDropdown(true);
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {showDropdown && tagData?.length ? (
                                                                <ul
                                                                    className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() => {
                                                                        setShowDropdown(false);
                                                                    }}
                                                                >
                                                                    {tagData?.map(tag => (
                                                                        <div
                                                                            className="p-2 hover:bg-gray-200 cursor-pointer"
                                                                            key={tag.id}
                                                                            onClick={() => {
                                                                                if (
                                                                                    tags
                                                                                        ?.map(
                                                                                            checktag =>
                                                                                                checktag.tagName
                                                                                        )
                                                                                        .includes(
                                                                                            tag.tagName
                                                                                        ) === false
                                                                                ) {
                                                                                    setTags([
                                                                                        ...tags,
                                                                                        tag,
                                                                                    ]);
                                                                                }
                                                                            }}
                                                                        >
                                                                            {tag.tagCategory +
                                                                                ': ' +
                                                                                tag.tagName}
                                                                        </div>
                                                                    ))}
                                                                </ul>
                                                            ) : showDropdown &&
                                                              tagData.length === 0 ? (
                                                                <ul
                                                                    className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() =>
                                                                        setShowDropdown(false)
                                                                    }
                                                                >
                                                                    <div className="p-2 flex justify-center font-medium text-gray-400">
                                                                        No records found
                                                                    </div>
                                                                </ul>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" ml-2 justify-items-center">
                                                <div className="flex flex-col text-sm pl-2 lg:pl-4 mt-6 space-y-4 text-gray-400 ">
                                                    <div>
                                                        Created tags can be retrieved by typing the
                                                        text in the search bar.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {createSuccessVisible && (
                                        <BadgesSuccessModal
                                            modalVisible={createSuccessVisible}
                                            handleClose={handleClose}
                                        />
                                    )}
                                    <CropModal
                                        ratio={1}
                                        photoURL={imageUrl}
                                        setPhotoURL={setImageUrl}
                                        setOpenCrop={setOpenCrop}
                                        openCrop={openCrop}
                                        handleCloseCropModal={handleCloseCropModal}
                                        uploadImageToS3={uploadImageToS3}
                                    />
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
