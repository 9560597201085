import React, { ReactElement, useEffect, useState } from 'react';

import Notification from '../../../assets/svg/Notification';
import DownArrow from '../../../assets/svg/DownArrow';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ProfileSideBar from './ProfileSideBar';
import { useDispatch, useSelector } from 'react-redux';
import { getWebStudentById, logoutWeb } from '../../../redux/rootActions';
import { EXPIRE_TIME, NOTIFICATION_COUNT, USER_PROFILE } from '../../utils/storageWeb';
import { RootState } from '../../../redux/rootReducer';
import { NavLink, NavMenu } from './NavbarElements';
import { getWebNotifications } from '../../../redux/webRedux/webNotifications/actions';
import { WebNotificationType } from '../../../redux/webRedux/webNotifications/reducers';
import moment from 'moment';
import { s3UrlPrefix } from '../../../constants';
import useWidth from '../../hooks/useWidth';
import { FaBars, FaTimes } from 'react-icons/fa';
import ProfileNavBarMobileExpandable from './ProfileNavBarMobileExpandable';

interface paramsType {
    id: string;
}

interface Props {
    isNavBarExpanded: boolean;
    setIsNavBarExpanded: (value: boolean) => void;
    setIsSideBarExpanded: (value: boolean) => void;
}

export default function ProfileNavBar({
    isNavBarExpanded,
    setIsNavBarExpanded,
    setIsSideBarExpanded,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<paramsType>();
    const location = useLocation<paramsType>();

    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));

    const studentData = useSelector((state: RootState) => state.webUser.student);
    const notifications = useSelector(
        (state: RootState) => state.WebNotification.webNotificationsData
    );
    const expirationDate = useSelector((state: RootState) => state.authStudent.expireTime);

    const [notificationDropdownVisible, setNotificationDropdownVisible] = useState(false);
    const [profileDropdownVisible, setProfileDropdownVisible] = useState(false);
    const [sidebar, setSidebar] = useState(true);
    const [newNotifiCount, setNewNotifiCount] = useState(0);

    const viewWidth = useWidth();

    const settingeURL = '/dashBoard/settings';

    const logOut = () => {
        dispatch(logoutWeb());
        history.push('/signin');
    };
    const authCheckState = () => {
        if (expirationDate) {
            if (!profile || new Date(expirationDate) <= new Date()) {
                logOut();
            }
        } else if (
            !profile ||
            new Date(JSON.parse(localStorage.getItem(EXPIRE_TIME))) <= new Date()
        ) {
            logOut();
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        authCheckState();
    }, [params]);

    useEffect(() => {
        if (profile?.userId) {
            dispatch(getWebStudentById(profile.userId));
            dispatch(getWebNotifications(profile.userId));
        }
    }, [dispatch]);

    useEffect(() => {
        const currentDate = new Date();
        const notifiCount = JSON.parse(localStorage.getItem(NOTIFICATION_COUNT));
        const isToday =
            moment(currentDate).format('YYYY-MM-DD') ===
            moment(notifiCount?.date).format('YYYY-MM-DD');
        if (!isToday) {
            localStorage.setItem(
                NOTIFICATION_COUNT,
                JSON.stringify({ count: 0, date: currentDate })
            );
        }
        const todayNotifiCount = notifications?.filter(
            notifi =>
                moment(notifi.notificationDate).format('YYYY-MM-DD') ===
                moment(currentDate).format('YYYY-MM-DD')
        ).length;
        const todaySeenNotifiCount = isToday ? notifiCount?.count : 0;

        setNewNotifiCount(todayNotifiCount - todaySeenNotifiCount);
    }, [notifications]);
    useEffect(() => {
        if (studentData?.onBoardingCompleted === null) {
            history.push(`/onboard`);
        }
    }, [studentData]);
    return (
        <>
            <div style={{ height: !window.location.pathname.includes('/dashBoard') && 70 }}>
                <nav
                    style={{ borderBottom: '1px solid #F4EDE5', height: 70, zIndex: 100 }}
                    className={`bg-white w-full  fixed`}
                    onMouseLeave={() => {
                        setProfileDropdownVisible(false);
                    }}
                >
                    <div className="hidden lg:block xl:pl-10 4xl:pl-20 pr-10 lg:pr-5 md:pl-4">
                        {window.location.pathname.includes('/onboard') ? (
                            <div className="relative flex items-center justify-between">
                                <div className="flex sm:items-stretch sm:justify-start w-1/4 my-4 md:pl-3">
                                    <img
                                        onClick={() => {
                                            history.push('/');
                                        }}
                                        className="cursor-pointer md:pl-3 lg:pl-0"
                                        src={`/assets/NewWeb.png`}
                                        width="200"
                                        alt="logo"
                                    />
                                </div>
                                <div>
                                    <div className="ml-1 sm:ml-3 relative">
                                        <div
                                            className="p-3 rounded hover:bg-purple-100 hover:text-blue-900  cursor-pointer mr-6"
                                            onClick={() => {
                                                setProfileDropdownVisible(true);
                                            }}
                                        >
                                            <DownArrow />
                                        </div>
                                        {profileDropdownVisible ? (
                                            <ul
                                                className="origin-top-right absolute -right-6 pl-0 mt-5 mr-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                role="menu"
                                                aria-orientation="vertical"
                                                aria-labelledby="user-menu-button"
                                                onMouseOverCapture={() =>
                                                    setProfileDropdownVisible(true)
                                                }
                                                onMouseLeave={() =>
                                                    setProfileDropdownVisible(false)
                                                }
                                            >
                                                <div
                                                    onClick={logOut}
                                                    className="px-6 py-2 cursor-pointer hover:bg-blue-100 hover:text-blue-900 hover:font-semibold"
                                                    role="presentation"
                                                >
                                                    Logout
                                                </div>
                                            </ul>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="relative flex items-center justify-between">
                                <div className="flex sm:items-stretch sm:justify-start w-1/4">
                                    <img
                                        onClick={() => {
                                            history.push('/');
                                        }}
                                        className="cursor-pointer"
                                        src={`/assets/NewWeb.png`}
                                        width="200"
                                        alt="logo"
                                    />
                                </div>
                                <div className="flex justify-center xl:px-4 h-10 md:pl-3 lg:pl-0">
                                    <NavMenu>
                                        <NavLink to="/home">Home</NavLink>
                                        <NavLink to={`/dashBoard/learn`}>Dashboard</NavLink>
                                        <NavLink to="/companies">Companies</NavLink>
                                        <NavLink
                                            className="whitespace-nowrap"
                                            to="/success-stories"
                                        >
                                            Success Stories
                                        </NavLink>
                                        <NavLink to="/events">Events</NavLink>
                                        <NavLink to="/institutes">Institutes</NavLink>
                                    </NavMenu>
                                </div>
                                <div className="flex justify-end absolute inset-y-0 right-0 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 xl:w-1/4">
                                    {studentData?.onBoardingCompleted && (
                                        <>
                                            <div className="py-1 xl:px-4">
                                                {location.pathname.split('/')[2] !== 'learn' && (
                                                    <div
                                                        className="rounded ml-1 mt-1 font-semibold text-purple-800 border-2 border-purple-800 cursor-pointer h-10 w-40 flex justify-center items-center"
                                                        onClick={() => {
                                                            history.push({
                                                                pathname: `/dashBoard/learn`,
                                                            });
                                                        }}
                                                    >
                                                        Start learning
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                style={{
                                                    borderRight: '2px solid #F5F6F9',
                                                    borderLeft: '2px solid #F5F6F9',
                                                }}
                                                className="relative"
                                            >
                                                <div
                                                    className={`bg-snow-600  py-3 px-4  hover:bg-purple-100 hover:text-blue-900 hover:underline hover:font-semibold cursor-pointer ${
                                                        location?.pathname.includes(
                                                            'dashBoard/notifications'
                                                        )
                                                            ? 'border-b-2 border-purple-700'
                                                            : ''
                                                    }`}
                                                    role="presentation"
                                                    onMouseOverCapture={() => {
                                                        setNotificationDropdownVisible(true);
                                                    }}
                                                    onMouseLeave={() =>
                                                        setNotificationDropdownVisible(false)
                                                    }
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: `/dashBoard/notifications`,
                                                        });
                                                        setNewNotifiCount(0);
                                                    }}
                                                >
                                                    {newNotifiCount > 0 && (
                                                        <>
                                                            <span className="absolute top-1 right-1 animate-ping bg-red-300 h-4 w-4 rounded-full"></span>
                                                            <span className="absolute top-1 right-1 bg-red-500 text-white rounded-full px-1 h-4 text-xs text-center">
                                                                {newNotifiCount}
                                                            </span>
                                                        </>
                                                    )}
                                                    <Notification />
                                                </div>
                                                {notificationDropdownVisible ? (
                                                    <div
                                                        className={`font-semibold bg-white rounded-md absolute right-0 top-12 z-10  ${
                                                            notifications.length > 0
                                                                ? 'px-2 py-2'
                                                                : 'px-10 py-10'
                                                        }`}
                                                        style={{
                                                            boxShadow:
                                                                '0px 0px 10px 1px rgba(197, 203, 220, 0.72)',
                                                            width: '20vw',
                                                            fontSize: '0.8vw',
                                                        }}
                                                    >
                                                        {notifications.length > 0
                                                            ? notifications
                                                                  ?.slice(0, 5)
                                                                  .map(notification => (
                                                                      <div
                                                                          key={notification.id}
                                                                          className="py-2 border-b border-purple-300"
                                                                      >
                                                                          {notification.content
                                                                              .length > 90
                                                                              ? notification.content.substring(
                                                                                    0,
                                                                                    90
                                                                                ) + ' ...'
                                                                              : notification.content}
                                                                      </div>
                                                                  ))
                                                            : ' You dont have any notifications.'}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </>
                                    )}
                                    {/* <!-- Profile dropdown --> */}
                                    <div className="flex flex-row py-3 pl-4">
                                        <img
                                            className="rounded-full h-10 w-10 mr-2"
                                            src={`${
                                                [null, ''].includes(studentData?.imageUrl)
                                                    ? '/assets/user.png'
                                                    : studentData?.imageUrl?.includes('webUsers')
                                                    ? s3UrlPrefix + studentData?.imageUrl
                                                    : studentData?.imageUrl
                                            }`}
                                            alt="profile"
                                        />
                                        <div
                                            style={{ color: '#4C2EC2' }}
                                            className=" font-semibold mr-2 mt-2"
                                        >
                                            {studentData?.firstName?.substring(0, 13)}
                                        </div>
                                        <div
                                            className="p-3 rounded hover:bg-purple-100 hover:text-blue-900  cursor-pointer mr-6"
                                            onClick={() => {
                                                setProfileDropdownVisible(true);
                                            }}
                                        >
                                            <DownArrow />
                                        </div>
                                        {profileDropdownVisible ? (
                                            <ul
                                                style={{ marginTop: '52px' }}
                                                className="origin-top-right absolute -right-6 pl-0 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                role="menu"
                                                aria-orientation="vertical"
                                                aria-labelledby="user-menu-button"
                                                onMouseOverCapture={() =>
                                                    setProfileDropdownVisible(true)
                                                }
                                                onMouseLeave={() =>
                                                    setProfileDropdownVisible(false)
                                                }
                                            >
                                                {studentData?.onBoardingCompleted && (
                                                    <>
                                                        <a
                                                            href={settingeURL}
                                                            className="block px-4 py-2 text-sm text-gray-700 no-underline hover:text-purple-950 cursor-pointer"
                                                            role="menuitem"
                                                            id="user-menu-item-1"
                                                        >
                                                            Settings
                                                        </a>
                                                    </>
                                                )}
                                                <a
                                                    // href="/signin"
                                                    className="block px-4 py-2 text-sm text-gray-700 no-underline hover:text-purple-950 cursor-pointer"
                                                    role="menuitem"
                                                    id="user-menu-item-2"
                                                    onClick={logOut}
                                                >
                                                    Sign out
                                                </a>
                                            </ul>
                                        ) : null}
                                    </div>
                                    {/* <NavIcon className={`${sidebar === true ? 'hidden' : 'lg:flex'}`} to="#">
                            <FaIcons.FaBars onClick={showSidebar} />
                        </NavIcon> */}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* <!-- Mobile menu, show/hide based on menu state. --> */}
                    <div className="lg:hidden pr-8 pl-5 flex items-center justify-between h-full w-full">
                        <div>
                            <img
                                onClick={() => {
                                    history.push('/');
                                }}
                                className="cursor-pointer"
                                src={`/assets/NewWeb.png`}
                                width="110"
                                alt="logo"
                            />
                        </div>
                        <div>
                            {!isNavBarExpanded ? (
                                <FaBars
                                    color="#000000"
                                    width={16}
                                    onClick={() => setIsNavBarExpanded(true)}
                                />
                            ) : (
                                <FaTimes
                                    color="#000000"
                                    width={12}
                                    onClick={() => setIsNavBarExpanded(false)}
                                />
                            )}
                        </div>
                    </div>
                </nav>
            </div>
            <ProfileNavBarMobileExpandable
                isShowExpandable={isNavBarExpanded}
                setIsNavBarExpanded={setIsNavBarExpanded}
            />
            {window.location.pathname.includes('/dashBoard') && (
                <ProfileSideBar
                    sidebar={sidebar}
                    setSidebar={setSidebar}
                    setIsSideBarExpanded={setIsSideBarExpanded}
                />
            )}
            {/* <Footer /> */}
        </>
    );
}
