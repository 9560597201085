import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOverviewData } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';

export default function Overview(): ReactElement {
    const dispatch = useDispatch();
    const overviewData = useSelector((state: RootState) => state.dashboard.overviewData);

    useEffect(() => {
        dispatch(getOverviewData());
    }, [dispatch]);
    return (
        <>
            <div className={'w-full pb-6'}>
                <div
                    className={`py-4 md:mb-4 text-xl md:text-3xl flex w-full text-center text-black-700 font-bold`}
                >
                    Overview
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4 md:gap-x-16 gap-y-4 md:gap-y-8 md:mr-4 pb-4">
                    <div className="flex flex-col justify-center items-center rounded sm:py-2 xl:py-3 border-2 border-gray-300 text-sm md:text-base xl:text-lg font-medium text-gray-700">
                        Total Companies
                        <div className="flex justify-center  items-center">
                            {overviewData?.companies}
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center rounded sm:py-2 xl:py-3 border-2 border-gray-300 text-sm md:text-base xl:text-lg font-medium text-gray-700">
                        Total Institutes
                        <div className="flex justify-center  items-center">
                            {overviewData?.institutes}
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center rounded sm:py-2 xl:py-3 border-2 border-gray-300 text-sm md:text-base xl:text-lg font-medium text-gray-700">
                        Total Mentors
                        <div className="flex justify-center items-center">
                            {overviewData?.mentors}
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center rounded sm:py-2 xl:py-3 border-2 border-gray-300 text-sm md:text-base xl:text-lg font-medium text-gray-700">
                        Total Students
                        <div className="flex justify-center items-center">
                            {overviewData?.students}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
