import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../../redux/rootReducer';
import { getTracks } from '../../../../../redux/rootActions';

import { s3UrlPrefix } from '../../../../../constants';

import BackArrow from '../../../../../assets/svg/BackArrow';

export default function RecommandedTracks(): ReactElement {
    const history = useHistory();
    const dispatch = useDispatch();

    const trackData = useSelector((state: RootState) => state.tracks.trackData);

    useEffect(() => {
        dispatch(getTracks(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="my-10 px-28">
            <div
                className="flex mb-3 mt-1 cursor-pointer"
                onClick={() => history.push(`/solve-challenges`)}
            >
                <BackArrow size="27" />
                <p className="text-base ml-2 font-semibold">Back</p>
            </div>
            <div className="flex flex-col justify-between mb-3 ">
                <div className="font-semibold text-2xl ">All Tracks</div>
                <div>Explore the tracks available in Future Career Brdidge.</div>
            </div>
            <div
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    gap: '25px',
                    marginRight: '1em',
                    flexDirection: 'row',
                    alignContent: 'flex-start',
                }}
            >
                {trackData?.map(track => (
                    <div key={track.id} className="rounded-lg  mt-3">
                        <img
                            style={{ width: '345px' }}
                            className="  rounded-t-lg"
                            src={`${s3UrlPrefix}${track.imageLink}`}
                            alt="profile"
                        />
                        <div className="ml-4 mt-6 pb-3">
                            <div className="font-semibold text-xl mb-3">{track.trackName}</div>

                            <p className="bg-purple-200 text-purple-950 rounded-full px-3 py-1  w-max">
                                {`${
                                    track.tags.length > 0
                                        ? track?.tags?.map(tag => tag.tagName)
                                        : 'no tags'
                                }`}
                            </p>
                            <div className="my-3">
                                <p className="text-gray-500 text-sm mb-1 text-justify ">
                                    {track.trackDescription}
                                </p>
                            </div>
                            <button
                                onClick={() => {
                                    history.push({
                                        pathname: `/solve-challenges/tracks/challenges`,
                                        state: { tracks: track },
                                    });
                                }}
                                className="border-2 border-purple-950 text-purple-950 rounded font-semibold h-10 px-6 xl:px-12 ml-1"
                            >
                                Start learning
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
