import React, { useState } from 'react';

import UpArrow from '../../../../../assets/svg/UpArrow';
import DownArrow from '../../../../../assets/svg/DownArrow';
import useWidth from '../../../../hooks/useWidth';
import { useHistory } from 'react-router-dom';
import BackArrow from '../../../../../assets/svg/BackArrow';
import { USER_PROFILE } from '../../../../utils/storageWeb';

const topicData = [
    {
        id: 1,
        title: 'Profile',
        sections: [
            {
                id: 1,
                title: 'How to create a profile',
                subSections: [
                    {
                        description:
                            'Enter your personal details and upload a profile picture. If you want to show your profile picture publicly click on Enable public profile or Not now',
                        image: '/assets/help/settings/s_01.png',
                    },
                    {
                        description:
                            'Enter your education details.Select the institute details and enter required fields ',
                        image: '/assets/help/settings/s_02.png',
                    },
                    {
                        description: 'Select your areas of interests',
                        image: '/assets/help/settings/s_03.png',
                    },
                    {
                        description:
                            'You will be getting a success message that your BRIDGE account has been successfully created.',
                        image: '/assets/help/settings/s_04.png',
                    },
                ],
            },
            {
                id: 2,
                title: 'How to edit a profile',
                subSections: [
                    {
                        description: 'Click on Settings tab ',
                        image: '/assets/help/settings/s_05.png',
                    },
                    {
                        description: 'View my profile section',
                        image: '/assets/help/settings/s_06.png',
                    },
                    {
                        description:
                            'Click on edit profile and you can edit your personal detail and education details',
                        image: '/assets/help/settings/s_07.png',
                    },
                ],
            },
            {
                id: 3,
                title: 'How to enable and disable public view profile',
                subSections: [
                    {
                        description: 'Click on edit profile',
                        image: '/assets/help/settings/s_08.png',
                    },
                    {
                        description: 'Under your user name click on the Enable public profile',
                        image: '/assets/help/settings/s_09.png',
                    },
                    {
                        description:
                            'Once you clicked on Enable public profile, Success message will be shown that your profile has been enabled public profile.And button will be change to disable public profile. If you need to disable the public profile',
                        image: '/assets/help/settings/s_10.png',
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        title: 'Account & Security',
        sections: [
            {
                id: 4,
                title: 'How to turn on and off notifications',
                subSections: [
                    {
                        description: 'Click on Settings tab ',
                        image: '/assets/help/settings/s_11.png',
                    },
                    {
                        description: 'Click on notifications tab',
                        image: '/assets/help/settings/s_12.png',
                    },
                    {
                        description: 'Here you can turn on and turn off your notifications',
                        image: '/assets/help/settings/s_13.png',
                    },
                ],
            },
            {
                id: 5,
                title: 'How to change your email address',
                subSections: [
                    {
                        description: 'Click on Settings tab ',
                        image: '/assets/help/settings/s_14.png',
                    },
                    {
                        description: 'Click on account security tab',
                        image: '/assets/help/settings/s_15.png',
                    },
                    {
                        description:
                            'To change your email you need to enter your new email address. Once you type your new email address Change email address button get active',
                        image: '/assets/help/settings/s_16.png',
                    },
                    {
                        description: 'Confirm your email address and verify your new email address',
                        image: '/assets/help/settings/s_17.png',
                    },
                    {
                        description:
                            'Once you confirm your email address.Get a success message that your email has been updated.',
                        image: '/assets/help/settings/s_18.png',
                    },
                ],
            },
            {
                id: 6,
                title: 'How to change your password',
                subSections: [
                    {
                        description: 'Click on Settings tab ',
                        image: '/assets/help/settings/s_19.png',
                    },
                    {
                        description: 'Click on account security tab',
                        image: '/assets/help/settings/s_20.png',
                    },
                    {
                        description:
                            'To change your password you need to enter your current password.And enter your new password',
                        image: '/assets/help/settings/s_21.png',
                    },
                    {
                        description:
                            'Once you enter all the fields click change password. You will get a success message, Your password has been updated.',
                        image: '/assets/help/settings/s_22.png',
                    },
                ],
            },
            {
                id: 7,
                title: 'How to change your phone number',
                subSections: [
                    {
                        description: 'Click on Settings tab',
                        image: '/assets/help/settings/s_23.png',
                    },
                    {
                        description: 'Click on account security tab',
                        image: '/assets/help/settings/s_24.png',
                    },
                    {
                        description:
                            'To change your phone number you need to enter your new phone number.',
                        image: '/assets/help/settings/s_25.png',
                    },
                    {
                        description:
                            'Once you enter the new phone number, the button will get active to change phone number. Click on it.',
                        image: '/assets/help/settings/s_26.png',
                    },
                    {
                        description:
                            'To the new number you entered, you will receive a code. You have to enter that code in the given space.',
                        image: '/assets/help/settings/s_27.png',
                    },
                    {
                        description: 'Once you entered the code. click on change phone number.',
                        image: '/assets/help/settings/s_28.png',
                    },
                    {
                        description:
                            'You will get a success message that your phone number has been successfully updated.',
                        image: '/assets/help/settings/s_29.png',
                    },
                ],
            },
            {
                id: 8,
                title: 'How to check ypur activity log',
                subSections: [
                    {
                        description: 'Click on Settings tab ',
                        image: '/assets/help/settings/s_30.png',
                    },
                    {
                        description: 'Click on activity logs tab',
                        image: '/assets/help/settings/s_31.png',
                    },
                    {
                        description: 'Here you can view your activity logs.',
                        image: '/assets/help/settings/s_32.png',
                    },
                ],
            },
        ],
    },
];

const Index = () => {
    const [selectedSection, setSelectedSection] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const viewPortWidth = useWidth();
    const history = useHistory();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));

    const handleAccordion = (index: number) => {
        if (selectedSection === index) {
            setSelectedSection(null);
            setIsExpanded(false);
        } else {
            setSelectedSection(index);
            setIsExpanded(true);
        }
    };

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div className="mx-10 md:mx-5 md:ml-14 lg:ml-72 lg:pl-3.5 lg:pt-2 md:mt-14 lg:mb-28">
                    <div
                        className="flex mb-5 cursor-pointer"
                        onClick={() => history.push(`/dashBoard/help`)}
                    >
                        <BackArrow size="27" />
                        <p className="text-base ml-2 font-semibold  hover:underline">Back</p>
                    </div>
                    <div className="mb-6 border-b-2 border-purple-75">
                        <div className="text-2xl-2 leading-10 font-manrope font-bold mb-2">
                            Settings
                        </div>
                        <div className="text-base font-normal mb-7">
                            Manage account settings in Future Careers BRIDGE
                        </div>
                    </div>
                    {topicData.map(section => (
                        <div key={section.id} className="mb-4">
                            <div className="text-xl font-bold font-manrope mb-3">
                                {section.title}
                            </div>
                            {section.sections.map(subSection => (
                                <div
                                    key={subSection.id}
                                    style={{ borderWidth: 1 }}
                                    className={`rounded mb-3 
                        ${
                            selectedSection === subSection.id && isExpanded
                                ? 'border-purple-50 bg-purple-85'
                                : 'border-gray-300 bg-transparent'
                        }`}
                                >
                                    <div
                                        className={`px-11 py-8 relative flex items-center text-base cursor-pointer 
                            ${
                                selectedSection === subSection.id && isExpanded
                                    ? 'font-bold text-purple-950'
                                    : 'font-normal'
                            }`}
                                        onClick={() => handleAccordion(subSection.id)}
                                    >
                                        {subSection.title}
                                        <div className="absolute right-7">
                                            {selectedSection === subSection.id && isExpanded ? (
                                                <UpArrow />
                                            ) : (
                                                <DownArrow />
                                            )}
                                        </div>
                                    </div>
                                    {selectedSection === subSection.id &&
                                        isExpanded &&
                                        subSection.subSections.map((item, subIndex) => (
                                            <div
                                                key={subIndex}
                                                className="flex justify-between items-center gap-10 p-6 pl-11 bg-purple-85 border-t-2 border-purple-75"
                                            >
                                                <div
                                                    className={`${
                                                        item.image ? `w-8/12` : `w-full`
                                                    } text-sm font-normal`}
                                                >
                                                    {item.description}
                                                </div>
                                                {item.image && (
                                                    <img
                                                        className="max-w-sm h-full object-contain"
                                                        src={item.image}
                                                        alt="FAQ step's image"
                                                    />
                                                )}
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ) : (
                <div className="px-4 mt-4">
                    <div className="flex mb-3 mt-1 cursor-pointer" onClick={() => history.goBack()}>
                        <BackArrow size="27" />
                        <p className="text-base ml-2 font-semibold">Back</p>
                    </div>
                    <div className="mb-6 border-b-2 border-purple-75">
                        <div className="text-xl leading-10 font-manrope font-bold mb-2">
                            Settings
                        </div>
                        <div className="text-xs font-normal mb-7">
                            Manage account settings in Future Careers BRIDGE
                        </div>
                    </div>
                    {topicData.map(section => (
                        <div key={section.id} className="mb-8">
                            <div className="text-lg font-bold font-manrope mb-3">
                                {section.title}
                            </div>
                            {section.sections.map(subSection => (
                                <div
                                    key={subSection.id}
                                    style={{ borderWidth: 1 }}
                                    className={`rounded mb-3 
                            ${
                                selectedSection === subSection.id && isExpanded
                                    ? 'border-purple-50 bg-purple-85'
                                    : 'border-gray-300 bg-transparent'
                            }`}
                                >
                                    <div
                                        className={`px-11 py-8 w-full relative flex items-center text-xs cursor-pointer 
                                ${
                                    selectedSection === subSection.id && isExpanded
                                        ? 'font-bold text-purple-950'
                                        : 'font-normal'
                                }`}
                                        onClick={() => handleAccordion(subSection.id)}
                                    >
                                        <span className="w-11/12">{subSection.title}</span>
                                        <div className="absolute right-7">
                                            {selectedSection === subSection.id && isExpanded ? (
                                                <UpArrow />
                                            ) : (
                                                <DownArrow />
                                            )}
                                        </div>
                                    </div>
                                    {selectedSection === subSection.id &&
                                        isExpanded &&
                                        subSection.subSections.map((item, subIndex) => (
                                            <div
                                                key={subIndex}
                                                className="flex flex-col justify-between items-center gap-y-4 py-5 px-11 bg-purple-85 border-t-2 border-purple-75"
                                            >
                                                {item.image && (
                                                    <img
                                                        className="w-full h-full object-contain"
                                                        src={item.image}
                                                        alt="FAQ step's image"
                                                    />
                                                )}
                                                <div className={`w-full text-xs font-normal`}>
                                                    {item.description}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default Index;
