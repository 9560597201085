import { ContactType } from '../students/reducers';
import { TagsType } from '../tags/reducers';
import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAILED,
    CLEAR_NOTIFICATIONS_MESSAGES,
    SEND_NOTIFICATION,
    SEND_NOTIFICATION_FAILED,
    SEND_NOTIFICATION_SUCCESS,
    GET_NOTIFY_USERS,
    GET_NOTIFY_USERS_FAILED,
    GET_NOTIFY_USERS_SUCCESS,
    GET_NOTIFICATION_BYID,
    GET_NOTIFICATION_BYID_FAILED,
    GET_NOTIFICATION_BYID_SUCCESS,
    GET_NOTIFICATION_TEMPLATES,
    GET_NOTIFICATION_TEMPLATES_SUCCESS,
    GET_NOTIFICATION_TEMPLATES_FAILED,
    SAVE_NOTIFICATION_TEMPLATE,
    SAVE_NOTIFICATION_TEMPLATE_SUCCESS,
    SAVE_NOTIFICATION_TEMPLATE_FAILED,
} from './actions';
export interface UsersType {
    id: string;
    userId: string;
    firstName: string;
    lastName: string;
    mobileNo: string;
    emailId: string;
}
export interface NotifyUsers {
    counts: number;
    currentPage: number;
    items: any[];
    tabName: string;
    totalItems: number;
    totalPages: number;
}
export interface OrganizationType {
    orgName: string;
    faculty: string;
    accountType: string;
    batchName: string;
    category: string;
    contactNo: number;
    course: string;
    id: string;
    location: string;
    orgCreatedBy: string;
    orgCreatedDate: string;
    orgEmail: string;
    orgLastUpdatedBy: string;
    orgLastUpdatedDate: string;
    repName: string;
    slassMember: string;
    studentId: string;
    subEndDate: string;
}
export interface NotificationType {
    id: string;
    notId: string;
    type: string;
    notificationDate: string;
    sentType: string;
    campaignTitle: string;
    sentTypeList: any;
    title: string;
    content: string;
    tags: [];
    users: UsersType[];
    studentCount: number;
}
export interface CountType {
    ALL: number;
    SITE: number;
    EMAIL: number;
    MOBILE: number;
}
export interface NotificationTemplateType {
    id: any;
    sentTypeList: any[];
    notificationTitle: string;
    campaignTitle: string;
    content: string;
}
export interface NotificationsInitialState {
    notificationsData: NotificationType[];
    notification: NotificationType;
    notificationTemplateData: NotificationTemplateType[];
    notificationTemplate: NotificationTemplateType;
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    isLoadingTemplates: boolean;
    isLoadingUsers: boolean;
    notificationByIdLoading: boolean;
    notificationByIdError: string | null;
    notificationError: string | null;
    notificationTemplateError: string | null;
    notifyUsersError: string | null;
    notifyUsers: NotifyUsers;
    createNotificationError: string | null;
    notificationCreateSuccessMessage: string | null;
    isCreateLoading: boolean;
    createNotificationTemplateError: string | null;
    createNotificationTemplateSuccessMessage: string | null;
    isTemplateCreateLoading: boolean;
}

const initialState: NotificationsInitialState = {
    notificationsData: [],
    notification: null,
    notificationTemplateData: [],
    notificationTemplate: null,
    pageCount: null,
    count: null,
    isLoading: false,
    isLoadingTemplates: false,
    isLoadingUsers: false,
    notificationByIdLoading: false,
    notificationByIdError: null,
    notificationError: null,
    notificationTemplateError: null,
    notifyUsersError: null,
    notifyUsers: null,
    createNotificationError: null,
    notificationCreateSuccessMessage: null,
    isCreateLoading: false,
    createNotificationTemplateError: null,
    createNotificationTemplateSuccessMessage: null,
    isTemplateCreateLoading: false,
};

const notificationsReducer = (state = initialState, action: any) => {
    const newTab = action?.payload?.tag?.map(tagss => tagss.id);

    let filterdnewDAta;
    if (action?.payload?.tag !== undefined) {
        filterdnewDAta = action?.payload?.payload?.items?.filter(track =>
            newTab.every(s => track.tags.map(trackid => trackid.id).includes(parseInt(s)))
        );
    }
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return { ...state, isLoading: true, notificationsError: null };
        case GET_NOTIFICATIONS_SUCCESS:
            if (newTab !== undefined || newTab) {
                return {
                    ...state,
                    isLoading: false,
                    count: action.payload.payload.counts,
                    notificationsData: filterdnewDAta,
                    pageCount: action.payload.payload.totalPages,
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    count: action.payload.counts,
                    notificationsData: action.payload.items,
                    pageCount: action.payload.totalPages,
                };
            }

        case GET_NOTIFICATION_BYID:
            return { ...state, notificationByIdLoading: true, notificationByIdError: null };
        case GET_NOTIFICATION_BYID_SUCCESS:
            return {
                ...state,
                notificationByIdLoading: false,
                notification: action.payload,
            };
        case GET_NOTIFICATION_BYID_FAILED:
            return {
                ...state,
                notificationByIdLoading: false,
                notificationByIdError: action.payload,
            };
        case GET_NOTIFICATIONS_FAILED:
            return { ...state, isLoading: false, notificationsError: action.payload };
        case GET_NOTIFY_USERS:
            return { ...state, isLoadingUsers: true, notifyUsersError: null };
        case GET_NOTIFY_USERS_SUCCESS:
            return {
                ...state,
                isLoadingUsers: false,
                notifyUsers: action.payload,
            };

        case GET_NOTIFY_USERS_FAILED:
            return { ...state, isLoadingUsers: false, notifyUsersError: action.payload };

        case SEND_NOTIFICATION:
            return { ...state, isCreateLoading: true, createnotificationError: null };
        case SEND_NOTIFICATION_SUCCESS:
            return {
                ...state,
                isCreateLoading: false,
                notificationCreateSuccessMessage: action.payload,
            };
        case SEND_NOTIFICATION_FAILED:
            return { ...state, isCreateLoading: false, createNotificationError: action.payload };
        case CLEAR_NOTIFICATIONS_MESSAGES:
            return {
                ...state,
                notificationCreateSuccessMessage: null,
                createnotificationError: null,
                createNotificationTemplateError: null,
                createNotificationTemplateSuccessMessage: null,
            };
        case GET_NOTIFICATION_TEMPLATES:
            return { ...state, isLoadingTemplates: true, notificationTemplateError: null };
        case GET_NOTIFICATION_TEMPLATES_SUCCESS:
            return {
                ...state,
                isLoadingTemplates: false,
                notificationTemplateData: action.payload,
            };
        case GET_NOTIFICATION_TEMPLATES_FAILED:
            return {
                ...state,
                isLoadingTemplates: false,
                notificationTemplateError: action.payload,
            };
        case SAVE_NOTIFICATION_TEMPLATE:
            return { ...state, isTemplateCreateLoading: true, notificationTemplateError: null };
        case SAVE_NOTIFICATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                isTemplateCreateLoading: false,
                createNotificationTemplateSuccessMessage: action.payload,
            };
        case SAVE_NOTIFICATION_TEMPLATE_FAILED:
            return {
                ...state,
                isTemplateCreateLoading: false,
                createNotificationTemplateError: action.payload,
            };
        default:
            return state;
    }
};

export default notificationsReducer;
