import React, { useState, useEffect } from 'react';

interface Question {
    className: string;
    label: string;
    name: string;
    points: string;
    type: string;
}

interface Props {
    questionIndex: number;
    question: Question;
    handleShortAnswers: (questionIndex, question, answer, type) => void;
}

const ShortAnswerField: React.FC<Props> = ({ questionIndex, question, handleShortAnswers }) => {
    return (
        <>
            <div className="ml-4 lg:ml-14 space-y-2">
                <div key={questionIndex} className="space-x-2">
                    <input
                        className="w-full h-16 px-4 py-3 border-2 border-gray-300 rounded bg-white outline-none"
                        type="text"
                        id={`${questionIndex}`}
                        name={question?.name}
                        autoComplete="off"
                        onChange={e => {
                            handleShortAnswers(questionIndex, question, e.target.value, 's');
                        }}
                    />
                </div>
                {/* {isChangeMade && (
                    <div>
                        <button
                            className={`px-4 py-1 rounded border-2 font-semibold justify-center items-center flex text-purple-950 border-purple-950 ${
                                !isChangeMade
                                    ? `cursor-not-allowed`
                                    : `cursor-pointer hover:text-white hover:bg-purple-950`
                            }`}
                            onClick={() => {
                                handleShortAnswers(questionIndex, question, text);
                                setIsChangeMade(false);
                            }}
                        >
                            Confirm
                        </button>
                    </div>
                )} */}
            </div>
        </>
    );
};

export default ShortAnswerField;
