import {
    CLEAR_SUCCESS_STORIES_MESSAGES,
    EDIT_SUCCESS_STORIES,
    EDIT_SUCCESS_STORIES_FAILED,
    EDIT_SUCCESS_STORIES_SUCCESS,
    GET_SUCCESS_STORIES,
    GET_SUCCESS_STORIES_FAILED,
    GET_SUCCESS_STORIES_SUCCESS,
    GET_PUBLIC_API_SUCCESS_STORIES,
    GET_PUBLIC_API_SUCCESS_STORIES_SUCCESS,
    GET_PUBLIC_API_SUCCESS_STORIES_FAILED,
} from './actions';

export interface StoryType {
    id: string;
    content: string;
    createdDate: string;
    instituteName: string;
    status: string;
    studentEmail: string;
    storyLink: string;
    userProfileId: string;
    title: string;
    userId: string;
    isFeatured: boolean;
    firstName: string;
    lastName: string;
    imageUrl: string;
}

export interface StoriesInitialState {
    successStories: StoryType[];
    isLoading: boolean;
    pageCount: number;
    storiesError: string | null;
    StoryUpdateSuccessMessage: string | null;
    editStoryError: string | null;
    isEditLoading: boolean;
    publicAPISuccessStories: StoryType[];
    isPublicAPILoading: boolean;
    publicAPIStoriesError: string | null;
}

const storiesInitialState: StoriesInitialState = {
    successStories: [],
    isLoading: false,
    pageCount: null,
    storiesError: null,
    StoryUpdateSuccessMessage: null,
    editStoryError: null,
    isEditLoading: false,
    publicAPISuccessStories: [],
    isPublicAPILoading: false,
    publicAPIStoriesError: null,
};

const storiesReducer = (state = storiesInitialState, action: any) => {
    switch (action.type) {
        case GET_SUCCESS_STORIES:
            return { ...state, isLoading: true, storiesError: null };
        case GET_SUCCESS_STORIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                successStories: action.payload.items,
                pageCount: action.payload.totalPages,
            };
        case GET_SUCCESS_STORIES_FAILED:
            return { ...state, isLoading: false, storiesError: action.payload };
        case GET_PUBLIC_API_SUCCESS_STORIES:
            return { ...state, isPublicAPILoading: true, publicAPIStoriesError: null };
        case GET_PUBLIC_API_SUCCESS_STORIES_SUCCESS:
            return {
                ...state,
                isPublicAPILoading: false,
                publicAPISuccessStories: action.payload.items,
            };
        case GET_PUBLIC_API_SUCCESS_STORIES_FAILED:
            return { ...state, isPublicAPILoading: false, publicAPIStoriesError: action.payload };
        case EDIT_SUCCESS_STORIES:
            return { ...state, isEditLoading: true, editStoryError: null };
        case EDIT_SUCCESS_STORIES_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                StoryUpdateSuccessMessage: action.payload,
            };
        case EDIT_SUCCESS_STORIES_FAILED:
            return { ...state, isEditLoading: false, editStoryError: action.payload };
        case CLEAR_SUCCESS_STORIES_MESSAGES:
            return {
                ...state,
                StoryUpdateSuccessMessage: null,
                editStoryError: null,
                storiesError: null,
            };
        default:
            return state;
    }
};

export default storiesReducer;
