import React, { useEffect, useState } from 'react';
import TableTabView from './SettingsTabView';
import General from './General';
import PrivacyPolicy from './PrivacyPolicy';
import Security from './Security';
import Privilages from './Privilages';
import MyProfile from './MyProfile';
import { useDispatch, useSelector } from 'react-redux';
import { getUserById } from '../../../redux/rootActions';
import { USER_PROFILE } from '../../../utils/storage';
import { RootState } from '../../../redux/rootReducer';
import {
    COMPANY_ADMIN,
    INSTITUTE_ADMIN,
    SLASSCOM_ADMIN,
    SUPER_ADMIN,
} from '../../../redux/authAdmin/reducers';
import EditCompanyProfile from './EditCompanyProfile';
import EditInstituteProfile from './EditInstituteProfile';

interface Props {
    selectedTab: number;
    setSelectedTab: (value: number) => void;
}

const SettingsPages = ({ selectedTab, setSelectedTab }: Props) => {
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const user = useSelector((state: RootState) => state.users.User);
    const [editProfileClicked, setEditProfileClicked] = useState(false);

    useEffect(() => {
        dispatch(getUserById(profile.userId));
    }, [dispatch, profile.userId]);
    return (
        <section className="py-6 h-full">
            <div className="flex justify-between w-full items-end mb-4">
                <div className="w-5/6 md:w-4/5 ">
                    <TableTabView selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                </div>
                {!editProfileClicked &&
                    selectedTab === 1 &&
                    (user?.roles[0]?.roleName === COMPANY_ADMIN ||
                        user?.roles[0]?.roleName === INSTITUTE_ADMIN) && (
                        <button
                            type="submit"
                            className="bg-blue-700 text-white text h-11 rounded focus:outline-none w-32"
                            onClick={() => setEditProfileClicked(true)}
                        >
                            Edit
                        </button>
                    )}
            </div>
            {selectedTab === 1 &&
            (user?.roles[0]?.roleName === SUPER_ADMIN ||
                user?.roles[0]?.roleName === SLASSCOM_ADMIN) ? (
                <MyProfile />
            ) : selectedTab === 1 && user?.roles[0]?.roleName === COMPANY_ADMIN ? (
                <EditCompanyProfile
                    editProfileClicked={editProfileClicked}
                    setEditProfileClicked={setEditProfileClicked}
                />
            ) : selectedTab === 1 && user?.roles[0]?.roleName === INSTITUTE_ADMIN ? (
                <EditInstituteProfile
                    editProfileClicked={editProfileClicked}
                    setEditProfileClicked={setEditProfileClicked}
                />
            ) : selectedTab === 2 &&
              (user?.roles[0]?.roleName === INSTITUTE_ADMIN ||
                  user?.roles[0]?.roleName === COMPANY_ADMIN) ? (
                <Privilages />
            ) : selectedTab === 3 ? (
                <Security />
            ) : selectedTab === 4 ? (
                <PrivacyPolicy />
            ) : null}
        </section>
    );
};

export default SettingsPages;
