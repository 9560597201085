import React, { ReactElement, useEffect, useState } from 'react';
import * as HiIcons from 'react-icons/hi';
import { accountType, months } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { getCompanies, getInstitutes, getTags } from '../../../redux/rootActions';

interface Props {
    notificationType: string;
    setNotificationType: (value: string) => void;
    companies: any[];
    setCompanies: (value: any[]) => void;
    institue: any[];
    setInstitue: (value: any[]) => void;
    tags: any[];
    setTags: (value: any[]) => void;
}

export default function AddTabModal({
    setNotificationType,
    companies,
    setCompanies,
    institue,
    setInstitue,
    tags,
    setTags,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const tagData = useSelector((state: RootState) => state.tags.tagData);

    const companyData = useSelector((state: RootState) => state.companies.companiesData);
    const instituteData = useSelector((state: RootState) => state.institutes.institutesData);
    const [trackFilterAction, setTrackFilterAction] = useState<string>('Select a column');

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const year = new Date().getFullYear();

    const EndMonth = months[new Date().getMonth()];

    const date = new Date(year, new Date().getMonth(), 1);
    const days = [];
    while (date.getMonth() === new Date().getMonth()) {
        days.push(new Date(date).getDate());
        date.setDate(date.getDate() + 1);
    }

    const secondDate = new Date(year, months.indexOf(EndMonth), 1);
    const Enddays = [];
    while (secondDate.getMonth() === months.indexOf(EndMonth)) {
        Enddays.push(new Date(secondDate).getDate());
        secondDate.setDate(secondDate.getDate() + 1);
    }

    const years = [];
    let startYear = new Date().getFullYear();
    const maxYear = startYear + 10;
    for (let i = startYear; i <= maxYear; i++) {
        years.push(startYear);
        startYear++;
    }

    const JoinEndMonth = months[new Date().getMonth()];

    const Joindate = new Date(year, new Date().getMonth(), 1);
    const Joindays = [];
    while (Joindate.getMonth() === new Date().getMonth()) {
        Joindays.push(new Date(Joindate).getDate());
        Joindate.setDate(Joindate.getDate() + 1);
    }

    const JoinsecondDate = new Date(year, months.indexOf(JoinEndMonth), 1);
    const JoinEnddays = [];
    while (JoinsecondDate.getMonth() === months.indexOf(JoinEndMonth)) {
        JoinEnddays.push(new Date(JoinsecondDate).getDate());
        JoinsecondDate.setDate(JoinsecondDate.getDate() + 1);
    }

    const Joinyears = [];
    let JoinstartYear = new Date().getFullYear();
    const JoinmaxYear = startYear + 10;
    for (let i = startYear; i <= JoinmaxYear; i++) {
        Joinyears.push(JoinstartYear);
        JoinstartYear++;
    }

    const TrackFilter = [
        { id: 0, val: 'Institues' },
        { id: 0, val: 'Companies' },
        { id: 0, val: 'Tags' },
        { id: 1, val: 'Notification Type' },
    ];

    useEffect(() => {
        dispatch(getTags(true));
        dispatch(getCompanies());
        dispatch(getInstitutes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={`flex flex-col my-1.5 ${dropdownVisible && 'h-60'}`}>
                <div className="flex flex-col my-1.5">Select filter column</div>
                <div
                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                >
                    <div
                        className="flex justify-between w-full"
                        onClick={() => setDropdownVisible(true)}
                    >
                        <div className="text-gray-700 font-medium pl-2">{trackFilterAction}</div>
                        <div className="">
                            <div className="flex flex-col">
                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                <HiIcons.HiChevronDown className="mr-2" />
                            </div>
                        </div>
                    </div>
                    {dropdownVisible ? (
                        <ul
                            className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            onMouseLeave={() => setDropdownVisible(false)}
                        >
                            {TrackFilter.map(action => (
                                <div
                                    className="p-2 pl-2 hover:bg-gray-200 "
                                    key={action.id}
                                    onClick={() => {
                                        setTrackFilterAction(action.val);
                                        setDropdownVisible(false);
                                    }}
                                >
                                    {action.val}
                                </div>
                            ))}
                        </ul>
                    ) : null}
                </div>
            </div>

            {trackFilterAction === 'Institues' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select the set of tracks</label>
                        {instituteData.map(institues => (
                            // eslint-disable-next-line react/jsx-key
                            <div className="my-1">
                                <label>
                                    <input
                                        className="mr-1"
                                        type="checkbox"
                                        name="checkbox"
                                        value={institues.id}
                                        onClick={e => {
                                            if (institue.includes(e.currentTarget.value)) {
                                                setInstitue(
                                                    institue.filter(
                                                        item => item !== e.currentTarget.value
                                                    )
                                                );
                                            } else {
                                                setInstitue([...institue, e.currentTarget.value]);
                                            }
                                        }}
                                    />
                                    {institues.instName}
                                </label>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}
            {trackFilterAction === 'Companies' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select the set of tracks</label>
                        {companyData.map(company => (
                            // eslint-disable-next-line react/jsx-key
                            <div className="my-1">
                                <label>
                                    <input
                                        className="mr-1"
                                        type="checkbox"
                                        name="checkbox"
                                        value={company.id}
                                        onClick={e => {
                                            if (companies.includes(e.currentTarget.value)) {
                                                setCompanies(
                                                    companies.filter(
                                                        item => item !== e.currentTarget.value
                                                    )
                                                );
                                            } else {
                                                setCompanies([...companies, e.currentTarget.value]);
                                            }
                                        }}
                                    />
                                    {company.comName}
                                </label>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}
            {trackFilterAction === 'Tags' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select the desired tags</label>
                        {tagData.map(tag => (
                            // eslint-disable-next-line react/jsx-key
                            <div className="my-1">
                                <label>
                                    <input
                                        className="mr-1"
                                        type="checkbox"
                                        name="checkbox"
                                        value={tag.id}
                                        onClick={e => {
                                            if (tags.includes(e.currentTarget.value)) {
                                                setTags(
                                                    tags.filter(
                                                        item => item !== e.currentTarget.value
                                                    )
                                                );
                                            } else {
                                                setTags([...tags, e.currentTarget.value]);
                                            }
                                        }}
                                    />
                                    {tag.tagName}
                                </label>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}
            {trackFilterAction === 'Notification Type' ? (
                <>
                    <div className="flex flex-col my-1.5">
                        <label className="my-1">Select the desired Account Type</label>
                        <div className="my-1">
                            <label>
                                <input
                                    className="mr-1"
                                    type="radio"
                                    name="checkbox"
                                    value={accountType.PREMIUM_CORPORATE}
                                    onClick={e => {
                                        setNotificationType(e.currentTarget.value);
                                    }}
                                />
                                PREMIUM CORPORATE
                            </label>
                        </div>
                        <div className="my-1">
                            <label>
                                <input
                                    className="mr-1"
                                    type="radio"
                                    name="checkbox"
                                    value={accountType.CORPORATE}
                                    onClick={e => {
                                        setNotificationType(e.currentTarget.value);
                                    }}
                                />
                                CORPORATE
                            </label>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
}
