import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Camera from '../../../assets/svg/Camera';
import { useDispatch, useSelector } from 'react-redux';
import { addCompany, clearCompaniesMessages, getTags } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { useHistory } from 'react-router-dom';
import {
    s3Config,
    phoneRegExp,
    resolutionError,
    error,
    logoResolutionErrorMsg,
    moduleNames,
    accountType,
} from '../../../constants';
import S3 from 'react-aws-s3';
import CompanySuccessModal from '../../modals/CompanySusscessModal';
import * as HiIcons from 'react-icons/hi';
import moment from 'moment';
import CropModal from '../../modals/cropModal';
import PhoneInput from 'react-phone-input-2';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroups from '../../TagGroups';

export default function AddNewInstitute() {
    const dispatch = useDispatch();
    const history = useHistory();
    const newFileName = Date.now().toString();
    const isCreateLoading = useSelector((state: RootState) => state.companies.isCreateLoading);
    const createCompanyError = useSelector(
        (state: RootState) => state.companies.createCompanyError
    );
    const companyCreateSuccessMessage = useSelector(
        (state: RootState) => state.companies.companyCreateSuccessMessage
    );
    const formRef = useRef<any>();
    const [imageUrl, setImageUrl] = useState('');
    const [imageLocation, setImageLocation] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [logoUrl, setLogoUrl] = useState('');
    const [logoLocation, setLogoLocation] = useState('');
    const [isLogoUploading, setIsLogoUploading] = useState(false);
    const [instituteCreateSuccessVisible, setInstituteCreateSuccessVisible] = useState(false);
    const [instituteErrorVisible, setInstituteErrorVisible] = useState(false);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [tags, setTags] = useState<any>([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [logoResolutionError, setLogoResolutionError] = useState(false);
    const [bannerResolutionError, setBannerResolutionError] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [uploadLogoError, setUploadLogoError] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [openCrop, setOpenCrop] = useState(false);
    const [openLogoCrop, setLogoOpenCrop] = useState(false);

    const [tagsHelpText, setTagsHelpText] = useState<boolean>(false);

    const serchTagNameOrId = e => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        if (showDropdown) {
            dispatch(getTags(true, moduleNames.Companies, searchTerm));
        }
    }, [dispatch, searchTerm, showDropdown]);

    useEffect(() => {
        setSelectedTags(categorizeTags(tags));
    }, [tags]);

    const handleClearMessages = useCallback(() => {
        if (companyCreateSuccessMessage) {
            setInstituteCreateSuccessVisible(true);
        }
        if (createCompanyError) {
            setInstituteErrorVisible(true);
        }
    }, [dispatch, companyCreateSuccessMessage, createCompanyError, history]);
    const handleClose = () => {
        dispatch(clearCompaniesMessages());
        if (!createCompanyError) {
            history.push('/admin/companies');
        }
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    const uploadImageToS3 = async croppedImg => {
        setIsUploading(true);
        s3Config.dirName = `companies/${newFileName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(croppedImg, newFileName)
            .then(data => {
                setImageLocation(data.key);
                setIsUploading(false);
                setUploadError(false);
            })
            .catch(err => {
                console.error(err);
                setIsUploading(false);
                setUploadError(true);
            });
    };

    const uploadLogoToS3 = async croppedImg => {
        setIsLogoUploading(true);
        s3Config.dirName = `companies/${newFileName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(croppedImg, newFileName)
            .then(data => {
                setLogoLocation(data.key);
                setIsLogoUploading(false);
                setUploadLogoError(false);
            })
            .catch(err => {
                console.error(err);
                setIsLogoUploading(false);
                setUploadLogoError(true);
            });
    };
    const handleImage = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setImageUrl(URL.createObjectURL(file));
            setOpenCrop(true);
        }
    };

    const handleLogoImage = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setLogoUrl(URL.createObjectURL(file));
            setLogoOpenCrop(true);
        }
    };
    const handleCloseCropModal = () => {
        setOpenCrop(false);
        setImageUrl('');
    };
    const handleCloseLogoCropModal = () => {
        setLogoOpenCrop(false);
        setLogoUrl('');
    };
    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    <div className="relative">
                        <Formik
                            innerRef={formRef}
                            initialValues={{
                                comName: '',
                                comEmail: '',
                                contactNo: '',
                                repName: '',
                                accountType: accountType.CORPORATE,
                                slassMember: true,
                                comDescription: '',
                                image: undefined,
                                logo: undefined,
                                comImage: '',
                                subStartDate: '',
                                subEndDate: '',
                                siteUrl: '',
                                careerOpportunities: '',
                                applyURL: '',
                            }}
                            validationSchema={Yup.object({
                                comName: Yup.string()
                                    .max(100, 'Name should be less than 100 characters')
                                    .required('Name is required'),
                                comDescription: Yup.string()
                                    .max(500, 'Description should be less than 500 characters')
                                    .required('Description is required'),
                                comEmail: Yup.string()
                                    .email('Invalid email')
                                    .required('Email is required'),
                                repName: Yup.string().required('Representative name is required'),
                                contactNo: Yup.string()
                                    .matches(phoneRegExp, 'Contact number is not valid')
                                    .required('Contact number is required'),
                                slassMember: Yup.boolean(),
                                subStartDate: Yup.date().when('slassMember', slassMember => {
                                    if (slassMember)
                                        return Yup.date().required(
                                            'Subscription start date is required'
                                        );
                                }),
                                subEndDate: Yup.date().when('slassMember', slassMember => {
                                    if (slassMember)
                                        return Yup.date().required(
                                            'Subscription end date is required'
                                        );
                                }),
                                image: Yup.array().required('Company banner is required'),
                                logo: Yup.array().required('Company logo is required'),
                                siteUrl: Yup.string().matches(
                                    // eslint-disable-next-line no-useless-escape
                                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                                    'Enter correct url!'
                                ),
                                careerOpportunities: Yup.string().max(
                                    1000,
                                    'Career Oppotunities should be less than 1000 characters'
                                ),
                                applyURL: Yup.string().matches(
                                    // eslint-disable-next-line no-useless-escape
                                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                                    'Enter correct url!'
                                ),
                            })}
                            onSubmit={async values => {
                                dispatch(
                                    addCompany({
                                        comName: values.comName.trim(),
                                        comEmail: values.comEmail,
                                        contactNo: values.contactNo,
                                        accountType: values.accountType,
                                        slassMember: values.slassMember,
                                        repName: values.repName.trim(),
                                        comImage: logoLocation,
                                        bannerImage: imageLocation,
                                        comDescription: values.comDescription.trim(),
                                        subStartDate: moment(values.subStartDate).toISOString(),
                                        subEndDate: moment(values.subEndDate).toISOString(),
                                        siteUrl: values.siteUrl,
                                        careerOpportunities: values.careerOpportunities?.trim(),
                                        applyURL: values.applyURL,
                                        comStatus:
                                            moment(values.subStartDate, 'YYYY-MM-DD').diff(
                                                moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD'),
                                                'days'
                                            ) <= 0
                                                ? 'ACTIVE'
                                                : 'INACTIVE',
                                        tags: tags,
                                    })
                                );
                            }}
                        >
                            {({
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                values,
                                errors,
                                touched,
                            }) => (
                                <>
                                    <div className="flex justify-between items-center mb-4 md:mb-6">
                                        <p className="text-xl font-semibold text-left">
                                            Add New Company
                                        </p>
                                        <div className="flex w-1/2 md:w-1/4 lg:pl-8 h-16">
                                            <button
                                                className="md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => history.push('/admin/companies/')}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => handleSubmit()}
                                            >
                                                {isCreateLoading ? <Loader /> : 'Save'}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 pb-14 mb-6">
                                        <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="title"
                                                    className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Company Name
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.comName && touched.comName
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="name"
                                                        type="text"
                                                        value={values.comName}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'comName',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.comName && touched.comName ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.comName}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex">
                                                <label
                                                    className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Type of Account
                                                </label>
                                                <div className="flex text-sm md:text-base items-center w-full">
                                                    <input
                                                        className="mr-1"
                                                        type="radio"
                                                        value={accountType.CORPORATE}
                                                        id="corporate"
                                                        name="accountType"
                                                        defaultChecked={true}
                                                        onChange={handleChange('accountType')}
                                                    />
                                                    <label className="mr-4 w-1/4">Corporate</label>
                                                    <input
                                                        className="mr-1"
                                                        type="radio"
                                                        value={accountType.PREMIUM_CORPORATE}
                                                        id="Premium"
                                                        name="accountType"
                                                        onChange={handleChange('accountType')}
                                                    />
                                                    <label className="">Premium corporate</label>
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex">
                                                <label
                                                    className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    SLASSCOM Member
                                                </label>
                                                <div className="flex text-sm md:text-base items-center w-full">
                                                    <input
                                                        className="mr-1"
                                                        type="radio"
                                                        value="true"
                                                        id="true"
                                                        defaultChecked={true}
                                                        name="slassMember"
                                                        onChange={() => {
                                                            setFieldValue('slassMember', true);
                                                        }}
                                                    />
                                                    <label className="mr-4 w-1/4">Yes</label>
                                                    <input
                                                        className="mr-1"
                                                        type="radio"
                                                        value="false"
                                                        id="false"
                                                        name="slassMember"
                                                        onChange={() => {
                                                            setFieldValue('slassMember', false);
                                                        }}
                                                    />
                                                    <label className="">No</label>
                                                </div>
                                            </div>
                                            {values.slassMember && (
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="repName"
                                                        className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Subscription Start Date
                                                        <div className="text-red-600">*</div>
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            style={{
                                                                minWidth: '80%',
                                                            }}
                                                            className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                errors.subStartDate &&
                                                                touched.subStartDate
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="subStartDate"
                                                            type="date"
                                                            value={values.subStartDate}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'subStartDate',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                        {errors.subStartDate &&
                                                        touched.subStartDate ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.subStartDate}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            )}
                                            {values.slassMember && (
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="repName"
                                                        className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Subscription End Date
                                                        <div className="text-red-600">*</div>
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            style={{
                                                                minWidth: '80%',
                                                            }}
                                                            className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                errors.subEndDate &&
                                                                touched.subEndDate
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="subEndDate"
                                                            type="date"
                                                            value={values.subEndDate}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'subEndDate',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            min={values.subStartDate}
                                                            autoComplete="off"
                                                        />
                                                        {errors.subEndDate && touched.subEndDate ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.subEndDate}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="repName"
                                                    className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Representative Name
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.repName && touched.repName
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="repName"
                                                        type="text"
                                                        value={values.repName}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'repName',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.repName && touched.repName ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.repName}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="instEmail"
                                                    className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Email
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.comEmail && touched.comEmail
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="name"
                                                        type="text"
                                                        value={values.comEmail}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'comEmail',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.comEmail && touched.comEmail ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.comEmail}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="contactNo"
                                                    className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Contact Number
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <label
                                                        htmlFor=""
                                                        className={`rounded-sm rounded-tl rounded-bl flex items-center focus:outline-none w-3/4 md:w-4/5  border-2 text-base focus:border-blue-900 ${
                                                            errors.contactNo && touched.contactNo
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                    >
                                                        <PhoneInput
                                                            inputStyle={{
                                                                width: '100%',
                                                                height: '42px',
                                                                fontSize: '14px',
                                                                paddingLeft: '48px',
                                                                borderRadius: '4px',
                                                                border: 0,
                                                                fontFamily: 'Lato',
                                                            }}
                                                            dropdownClass={
                                                                'mobileNumberCountryDropdown'
                                                            }
                                                            country="lk"
                                                            specialLabel={''}
                                                            value={values.contactNo}
                                                            placeholder="Enter your Mobile Number (Eg: 94712345678)"
                                                            onChange={e => {
                                                                if (!isNaN(Number(e))) {
                                                                    setFieldValue('contactNo', e);
                                                                }
                                                            }}
                                                        />
                                                    </label>
                                                    {errors.contactNo && touched.contactNo ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.contactNo}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="siteUrl"
                                                    className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Website URL
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                        id="name"
                                                        type="text"
                                                        value={values.siteUrl}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'siteUrl',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.siteUrl && touched.siteUrl ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.siteUrl}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex h-24">
                                                <label
                                                    htmlFor="description"
                                                    className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Company Description
                                                    <div className="text-red-600">*</div>
                                                </label>
                                                <div className="flex flex-col w-full">
                                                    <textarea
                                                        className={`resize-none focus:outline-none p-2 w-4/5 border-2 text-sm md:text-base h-24 focus:border-blue-900 ${
                                                            errors.comDescription &&
                                                            touched.comDescription
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        }`}
                                                        id="description"
                                                        value={values.comDescription}
                                                        placeholder=""
                                                        onChange={handleChange('comDescription')}
                                                    />
                                                    {errors.comDescription &&
                                                    touched.comDescription ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.comDescription}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex h-24">
                                                <label
                                                    htmlFor="opportunities"
                                                    className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Career Opportunities
                                                </label>
                                                <div className="flex flex-col w-full">
                                                    <textarea
                                                        className={`resize-none focus:outline-none p-2 w-4/5 border-2 text-sm md:text-base h-24 focus:border-blue-900 ${
                                                            errors.careerOpportunities &&
                                                            touched.careerOpportunities
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        }`}
                                                        id="opportunities"
                                                        value={values.careerOpportunities}
                                                        placeholder=""
                                                        onChange={handleChange(
                                                            'careerOpportunities'
                                                        )}
                                                    />
                                                    {errors.careerOpportunities &&
                                                    touched.careerOpportunities ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.careerOpportunities}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="applNowURL"
                                                    className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Apply Now URL
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                        id="applNowURL"
                                                        type="text"
                                                        value={values.applyURL}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'applyURL',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    {errors.applyURL && touched.applyURL ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.applyURL}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="my-6 w-full md:mb-0 flex">
                                                <label
                                                    htmlFor="image"
                                                    className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Company Banner Image
                                                    <div className="text-red-600">*</div>
                                                </label>
                                                <div
                                                    className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                                        imageUrl ? 'w-fit' : ''
                                                    } border-opacity-100 border-2 ${
                                                        errors.image && touched.image
                                                            ? 'border-red-500'
                                                            : 'border-black-600'
                                                    }`}
                                                >
                                                    <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64">
                                                        {!imageUrl && (
                                                            <div className="w-full">
                                                                <div className="flex justify-center">
                                                                    <Camera />
                                                                </div>
                                                                <div>
                                                                    <label
                                                                        className="underline cursor-pointer text-sm"
                                                                        htmlFor="image"
                                                                    >
                                                                        Upload Image
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {imageUrl && (
                                                            <img
                                                                src={imageUrl}
                                                                alt={'name'}
                                                                className="h-44 lg:h-64"
                                                                style={{ maxHeight: 300 }}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="absolute mt-1 text-gray-400 text-sm font-medium leading-149">
                                                        Image size 1520*422
                                                    </div>
                                                    <div
                                                        className="absolute mt-8 text-center w-max"
                                                        style={{
                                                            left: '50%',
                                                            transform: 'translate(-50%,-50%)',
                                                        }}
                                                    >
                                                        {imageUrl && !isUploading && (
                                                            <div>
                                                                <label
                                                                    className="underline cursor-pointer text-sm"
                                                                    htmlFor="image"
                                                                >
                                                                    Change Image
                                                                </label>
                                                            </div>
                                                        )}
                                                        {isUploading && (
                                                            <div className="flex items-center text-sm">
                                                                <div className="mr-2">
                                                                    Uploading
                                                                </div>
                                                                <div>
                                                                    <Loader />
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div
                                                            className={`text-red-500 text-xs ${
                                                                imageLocation ? 'my-1' : 'my-2'
                                                            }`}
                                                        >
                                                            {errors.image && touched.image
                                                                ? errors.image
                                                                : bannerResolutionError
                                                                ? resolutionError
                                                                : uploadError
                                                                ? error
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <input
                                                    className={`focus:outline-none w-full focus:border-blue-900`}
                                                    id="image"
                                                    type="file"
                                                    accept="image/png, image/jpeg"
                                                    onChange={async e => {
                                                        const file = [];
                                                        file.push(e.target.files[0]);
                                                        setFieldValue('image', file);
                                                        handleImage(e);
                                                    }}
                                                    style={{ display: 'none' }}
                                                />
                                            </div>

                                            <div
                                                className={`my-6 w-full md:mb-0 flex ${
                                                    bannerResolutionError ? 'pt-10' : 'pt-6'
                                                }`}
                                            >
                                                <label
                                                    htmlFor="logo"
                                                    className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Company Logo
                                                    <div className="text-red-600">*</div>
                                                </label>
                                                <div
                                                    className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                                        logoUrl ? 'w-fit' : ''
                                                    } border-opacity-100 border-2 ${
                                                        errors.image && touched.image
                                                            ? 'border-red-500'
                                                            : 'border-black-600'
                                                    }`}
                                                >
                                                    <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64">
                                                        {!logoUrl && (
                                                            <div className="w-full">
                                                                <div className="flex justify-center">
                                                                    <Camera />
                                                                </div>
                                                                <div>
                                                                    <label
                                                                        className="underline cursor-pointer text-sm"
                                                                        htmlFor="logo"
                                                                    >
                                                                        Upload Logo
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {logoUrl && (
                                                            <img
                                                                src={logoUrl}
                                                                alt={'name'}
                                                                className="h-44 lg:h-64"
                                                                style={{ maxHeight: 300 }}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="absolute mt-1 text-gray-400 text-sm font-medium leading-149">
                                                        Image size 275*127
                                                    </div>
                                                    <div
                                                        className="absolute mt-8 text-center w-max"
                                                        style={{
                                                            left: '50%',
                                                            transform: 'translate(-50%,-50%)',
                                                        }}
                                                    >
                                                        {logoUrl && !isLogoUploading && (
                                                            <div>
                                                                <label
                                                                    className="underline cursor-pointer text-sm"
                                                                    htmlFor="logo"
                                                                >
                                                                    Change Logo
                                                                </label>
                                                            </div>
                                                        )}
                                                        {isLogoUploading && (
                                                            <div className="flex items-center text-sm">
                                                                <div className="mr-2">
                                                                    Uploading
                                                                </div>
                                                                <div>
                                                                    <Loader />
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="text-red-500 text-xs mt-1">
                                                            {errors.logo && touched.logo
                                                                ? errors.logo
                                                                : logoResolutionError
                                                                ? logoResolutionErrorMsg
                                                                : uploadLogoError
                                                                ? error
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <input
                                                    className={`focus:outline-none w-full focus:border-blue-900`}
                                                    id="logo"
                                                    type="file"
                                                    accept="image/png, image/jpeg"
                                                    onChange={async e => {
                                                        const file = [];
                                                        file.push(e.target.files[0]);
                                                        setFieldValue('logo', file);
                                                        handleLogoImage(e);
                                                    }}
                                                    style={{ display: 'none' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                            <div className="flex border-2 flex-col ml-2 justify-items-center">
                                                <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                                    <div className="flex sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                        <div>Assign Tags</div>
                                                        <div>
                                                            <HiIcons.HiQuestionMarkCircle
                                                                onClick={() => {
                                                                    setTagsHelpText(true);
                                                                }}
                                                                className="ml-2 mt-1 cursor-pointer"
                                                            />
                                                        </div>
                                                    </div>
                                                    {tagsHelpText ? (
                                                        <div
                                                            onMouseOverCapture={() => {
                                                                setTagsHelpText(true);
                                                                // setProfileDropdownVisible(false);
                                                            }}
                                                            onMouseLeave={() =>
                                                                setTagsHelpText(false)
                                                            }
                                                            className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-6 right-12"
                                                        >
                                                            Assign already created tags to the new
                                                            record created.
                                                        </div>
                                                    ) : null}
                                                    <div
                                                        className="sm:text-sm md:text-base text-gray-400 underline pr-2 cursor-pointer"
                                                        onClick={() => {
                                                            setTags([]);
                                                        }}
                                                    >
                                                        Clear All
                                                    </div>
                                                </div>
                                                <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                                    <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                        <TagGroups
                                                            selectedTags={selectedTags}
                                                            tags={tags}
                                                            setTags={setTags}
                                                        />

                                                        <div
                                                            onMouseLeave={() => {
                                                                setShowDropdown(false);
                                                            }}
                                                        >
                                                            <input
                                                                className={`rounded w-full text-gray-500 font-medium focus:outline-none mt-2 p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                                                id="id"
                                                                type="text"
                                                                placeholder="Search tags"
                                                                onChange={e => serchTagNameOrId(e)}
                                                                onMouseEnter={() => {
                                                                    setShowDropdown(true);
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {showDropdown && tagData?.length ? (
                                                                <ul
                                                                    className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() => {
                                                                        setShowDropdown(false);
                                                                    }}
                                                                >
                                                                    {tagData?.map(tag => (
                                                                        <div
                                                                            className="p-2 hover:bg-gray-200 cursor-pointer"
                                                                            key={tag.id}
                                                                            onClick={() => {
                                                                                if (
                                                                                    tags
                                                                                        ?.map(
                                                                                            checktag =>
                                                                                                checktag.tagName
                                                                                        )
                                                                                        .includes(
                                                                                            tag.tagName
                                                                                        ) === false
                                                                                ) {
                                                                                    setTags([
                                                                                        ...tags,
                                                                                        tag,
                                                                                    ]);
                                                                                }
                                                                            }}
                                                                        >
                                                                            {tag.tagCategory +
                                                                                ': ' +
                                                                                tag.tagName}
                                                                        </div>
                                                                    ))}
                                                                </ul>
                                                            ) : showDropdown &&
                                                              tagData.length === 0 ? (
                                                                <ul
                                                                    className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() =>
                                                                        setShowDropdown(false)
                                                                    }
                                                                >
                                                                    <div className="p-2 flex justify-center font-medium text-gray-400">
                                                                        No records found
                                                                    </div>
                                                                </ul>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" ml-2 justify-items-center">
                                                <div className="flex flex-col text-sm pl-2 lg:pl-4 mt-6 space-y-4 text-gray-400 ">
                                                    <div>
                                                        Created tags can be retrieved by typing the
                                                        text in the search bar.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {companyCreateSuccessMessage && (
                                        <CompanySuccessModal
                                            modalVisible={instituteCreateSuccessVisible}
                                            handleClose={handleClose}
                                        />
                                    )}
                                    {createCompanyError && (
                                        <CompanySuccessModal
                                            modalVisible={instituteErrorVisible}
                                            handleClose={handleClose}
                                        />
                                    )}
                                    <CropModal
                                        ratio={3.6}
                                        photoURL={imageUrl}
                                        setPhotoURL={setImageUrl}
                                        setOpenCrop={setOpenCrop}
                                        openCrop={openCrop}
                                        handleCloseCropModal={handleCloseCropModal}
                                        uploadImageToS3={uploadImageToS3}
                                    />
                                    <CropModal
                                        ratio={2.2}
                                        photoURL={logoUrl}
                                        setPhotoURL={setLogoUrl}
                                        setOpenCrop={setLogoOpenCrop}
                                        openCrop={openLogoCrop}
                                        handleCloseCropModal={handleCloseLogoCropModal}
                                        uploadImageToS3={uploadLogoToS3}
                                    />
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
