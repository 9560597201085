import axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';

const PREFIX = '@EVENTS';

export const GET_EVENTS = PREFIX + 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = PREFIX + 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILED = PREFIX + 'GET_EVENTS_FAILED';
export const GET_EVENT_BY_ID = PREFIX + 'GET_EVENT_BY_ID';
export const GET_EVENT_BY_ID_SUCCESS = PREFIX + 'GET_EVENT_BY_ID_SUCCESS';
export const GET_EVENT_BY_ID_FAILED = PREFIX + 'GET_EVENT_BY_ID_FAILED';
export const GET_PUBLIC_EVENTS = PREFIX + 'GET_PUBLIC_EVENTS';
export const GET_PUBLIC_EVENTS_SUCCESS = PREFIX + 'GET_PUBLIC_EVENTS_SUCCESS';
export const GET_PUBLIC_EVENTS_FAILED = PREFIX + 'GET_PUBLIC_EVENTS_FAILED';
export const GET_PUBLIC_EVENT_BY_ID = PREFIX + 'GET_PUBLIC_EVENT_BY_ID';
export const GET_PUBLIC_EVENT_BY_ID_SUCCESS = PREFIX + 'GET_PUBLIC_EVENT_BY_ID_SUCCESS';
export const GET_PUBLIC_EVENT_BY_ID_FAILED = PREFIX + 'GET_PUBLIC_EVENT_BY_ID_FAILED';
export const ADD_EVENT = PREFIX + 'ADD_EVENT';
export const ADD_EVENT_SUCCESS = PREFIX + 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAILED = PREFIX + 'ADD_EVENT_FAILED';
export const EDIT_EVENT = PREFIX + 'EDIT_EVENT';
export const EDIT_EVENT_SUCCESS = PREFIX + 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_FAILED = PREFIX + 'EDIT_EVENT_FAILED';
export const DELETE_EVENT = PREFIX + 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = PREFIX + 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILED = PREFIX + 'DELETE_EVENT_FAILED';
export const EVENT_BULK_UPDATE = PREFIX + 'EVENT_BULK_UPDATE';
export const EVENT_BULK_UPDATE_SUCCESS = PREFIX + 'EVENT_BULK_UPDATE_SUCCESS';
export const EVENT_BULK_UPDATE_FAILED = PREFIX + 'EVENT_BULK_UPDATE_FAILED';
export const CLEAR_EVENTS_MESSAGES = PREFIX + 'CLEAR_EVENTS_MESSAGES';

export interface StartDate {
    from?: string | null;
    to?: string | null;
}

export interface EventsTabCreateType {
    id?: string | null;
    tabName?: string | null;
    eventTypes?: any[];
    startDate?: StartDate;
}

export const getEvents =
    (
        page?: number,
        size?: number,
        status?: string,
        tags?: string,
        searchTerm?: string,
        category?: string,
        startDateFrom?: string,
        startDateTo?: string
    ) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_EVENTS });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/events/?page=${page || 0}&size=${size || 10}${
                    status ? `&status=${status}` : ''
                }${searchTerm ? `&searchText=${searchTerm}` : ''}${tags ? `&tags=${tags}` : ''}${
                    category ? `&type=${category}` : ''
                }${
                    startDateFrom
                        ? `&startDateFrom=${moment(startDateFrom).format('DD-MM-yyyy')}`
                        : ''
                }${startDateTo ? `&startDateTo=${moment(startDateTo).format('DD-MM-yyyy')}` : ''}`,
                config
            );

            dispatch({
                type: GET_EVENTS_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_EVENTS_FAILED, payload: getErrorMessage(error) });
        }
    };
export const getPublicEvents = () => async (dispatch: any) => {
    try {
        dispatch({ type: GET_PUBLIC_EVENTS });
        const response = await axios.get(`${API_URL}/open/events`);

        dispatch({
            type: GET_PUBLIC_EVENTS_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_PUBLIC_EVENTS_FAILED, payload: getErrorMessage(error) });
    }
};
export const getEventById = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_EVENT_BY_ID });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/events/${id}`, config);
        dispatch({ type: GET_EVENT_BY_ID_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_EVENT_BY_ID_FAILED, payload: getErrorMessage(error) });
    }
};
export const getPublicEventById = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_PUBLIC_EVENT_BY_ID });
        const response = await axios.get(`${API_URL}/open/event/${id}`);
        dispatch({ type: GET_PUBLIC_EVENT_BY_ID_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_PUBLIC_EVENT_BY_ID_FAILED, payload: getErrorMessage(error) });
    }
};

export const addEvent = (event: any) => async (dispatch: any) => {
    try {
        dispatch({ type: ADD_EVENT });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/events/`, event, config);
        if (res?.data?.status) {
            if (res?.data?.errorCode === 222) {
                await dispatch(getEvents());
                await dispatch({
                    type: ADD_EVENT_SUCCESS,
                    payload: res.data.responseDto,
                });
            }
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 222) {
                dispatch({
                    type: ADD_EVENT_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: ADD_EVENT_FAILED, payload: getErrorMessage(error) });
    }
};
export const editEvent = (id: string, event: any, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: EDIT_EVENT });
        const config = await axiosConfig(true);
        const res = await axios.put(`${API_URL}/events/${id}`, event, config);
        if (res?.data?.status) {
            await dispatch(getEvents(0, 10, status));
            dispatch({ type: EDIT_EVENT_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 214) {
                dispatch({
                    type: EDIT_EVENT_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: EDIT_EVENT_FAILED, payload: getErrorMessage(error) });
    }
};
export const deleteEvent = (id: string, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: DELETE_EVENT });
        const config = await axiosConfig(true);
        const res = await axios.delete(`${API_URL}/events/${id}`, config);
        await dispatch(getEvents(0, 10, status));
        dispatch({ type: DELETE_EVENT_SUCCESS, payload: res.data.responseDto });
    } catch (error) {
        dispatch({ type: DELETE_EVENT_FAILED, payload: getErrorMessage(error) });
    }
};
export const bulkUpdateEvents = (events, tabStatus) => async (dispatch: any) => {
    try {
        dispatch({ type: EVENT_BULK_UPDATE });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/events/bulk`, events, config);
        await dispatch(getEvents(0, 10, tabStatus));
        if (res?.data?.status) {
            if (res?.data?.errorCode === 202)
                dispatch({ type: EVENT_BULK_UPDATE_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 202) {
                dispatch({
                    type: EVENT_BULK_UPDATE_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: EVENT_BULK_UPDATE_FAILED, payload: getErrorMessage(error) });
    }
};
export const clearEventsMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_EVENTS_MESSAGES });
};
