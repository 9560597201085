import React, { ReactElement } from 'react';

export default function TickCircle(): ReactElement {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="20.625" cy="20.625" r="15.625" fill="white" />
            <path
                d="M20.4172 3.75C11.2172 3.75 3.75049 11.2167 3.75049 20.4167C3.75049 29.6167 11.2172 37.0833 20.4172 37.0833C29.6172 37.0833 37.0838 29.6167 37.0838 20.4167C37.0838 11.2167 29.6172 3.75 20.4172 3.75ZM20.4172 33.75C13.0672 33.75 7.08382 27.7667 7.08382 20.4167C7.08382 13.0667 13.0672 7.08333 20.4172 7.08333C27.7672 7.08333 33.7505 13.0667 33.7505 20.4167C33.7505 27.7667 27.7672 33.75 20.4172 33.75ZM28.0672 13.05L17.0838 24.0333L12.7672 19.7333L10.4172 22.0833L17.0838 28.75L30.4172 15.4167L28.0672 13.05Z"
                fill="#4C2EC2"
            />
        </svg>
    );
}
