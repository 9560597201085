import { InstitutesType } from '../institutes/reducers';
import { TagsType } from '../tags/reducers';
import {
    ADD_INSTITUE_TAB,
    ADD_INSTITUE_TAB_FAILED,
    ADD_INSTITUE_TAB_SUCCESS,
    GET_INSTITUTE_TAB,
    GET_INSTITUTE_TAB_FAILED,
    GET_INSTITUTE_TAB_SUCCESS,
} from './actions';

export interface InstitutesEditType {
    instName: string;
    instEmail: string;
    contactNo: number;
    location: string;
    logoImage: string;
    repName: string;
    instDescription: string;
    instCreatedBy: string;
    category: string;
    faculties: [];
    courses: [];
}
export interface CountType {
    ALL: number;
    ACTIVE: number;
    DELETED: number;
}
export interface InstitutesTabInitialState {
    institutesData: InstitutesType[];
    institute: InstitutesType;
    instituteTabData: [];
    pageCount: number;
    count: CountType;
    isLoading: boolean;
    instituteByIdLoading: boolean;
    instituteByIdError: string | null;
    instituteError: string | null;
    createInstituteError: string | null;
    instituteCreateSuccessMessage: string | null;
    isDeleteLoading: boolean;
    deleteInstituteError: string | null;
    instituteDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editInstituteError: string | null;
    instituteUpdateSuccessMessage: string | null;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    instituteBulkSuccessMessage: string | null;
}

const initialState: InstitutesTabInitialState = {
    institutesData: [],
    institute: null,
    instituteTabData: [],
    pageCount: null,
    count: null,
    isLoading: false,
    instituteByIdLoading: false,
    instituteByIdError: null,
    instituteError: null,
    createInstituteError: null,
    instituteCreateSuccessMessage: null,
    isDeleteLoading: false,
    deleteInstituteError: null,
    instituteDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editInstituteError: null,
    instituteUpdateSuccessMessage: null,
    isBulkUpdateLoading: null,
    bulkError: null,
    instituteBulkSuccessMessage: null,
};

const InstituteTabReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_INSTITUE_TAB:
            return { ...state, isLoading: true, trackError: null };
        case ADD_INSTITUE_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                institutesData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case ADD_INSTITUE_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        case GET_INSTITUTE_TAB:
            return { ...state, isLoading: true, trackError: null };
        case GET_INSTITUTE_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                instituteTabData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case GET_INSTITUTE_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        default:
            return state;
    }
};

export default InstituteTabReducer;
