import React, { useEffect, useState } from 'react';
import TableTabView from './TableTabView';
import * as HiIcons from 'react-icons/hi';
import DatePicker from 'react-datepicker';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import HiredFCBProgression from './HiredFCBProgression';
import StudentProgression from './StudentProgression';
import { useDispatch, useSelector } from 'react-redux';
import { InstitutesType } from '../../../../redux/institutes/reducers';
import {
    downloadCSVHiredByFCB,
    downloadCSVStudentProgression,
    getProgressionData,
    getHiredFcbData,
    DOWNLOAD_CSV_HIRED_BY_FCB_RESET,
    DOWNLOAD_CSV_STUDENNT_PROGRESSION_RESET,
} from '../../../../redux/rootActions';
import Loader from '../../../../assets/svg/Loader';
import { RootState } from '../../../../redux/rootReducer';
import { API_URL, TrackPeriodFilter } from '../../../../constants';
import { INSTITUTE_ADMIN } from '../../../../redux/authAdmin/reducers';
import { USER_PROFILE } from '../../../../utils/storage';
import NoData from '../../../../assets/svg/NoData';
import CSVEmailedSuccessModal from '../../../modals/CSVEmailedSuccessModal';
import moment from 'moment';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
                drawBorder: false,
            },
        },
        y: {
            grid: {
                display: false,
                drawBorder: false,
            },
        },
    },
};

const ProgressionOverview = () => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(1);
    const isStuProgCSVLoading = useSelector(
        (state: RootState) => state.dashboard.isStuProgCSVLoading
    );
    const studentProgressionCSVMessage = useSelector(
        (state: RootState) => state.dashboard.studentProgressionCSVMessage
    );
    const isHiredByFCBCSVLoading = useSelector(
        (state: RootState) => state.dashboard.isHiredByFCBCSVLoading
    );

    const hiredbyFCBEmailMessage = useSelector(
        (state: RootState) => state.dashboard.hiredbyFCBEmailMessage
    );

    const isLoadingProgessionData = useSelector(
        (state: RootState) => state.dashboard.isLoadingProgessionData
    );

    const studentProgressionSuccess = useSelector(
        (state: RootState) => state.dashboard.studentProgressionSuccess
    );

    const isHiredByFCBCSVSuccess = useSelector(
        (state: RootState) => state.dashboard.isHiredByFCBCSVSuccess
    );
    const user = useSelector((state: RootState) => state.users.User);
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const [institute, setInstitute] = useState<InstitutesType>();
    const [onlyInstitute, setOnlyInstitute] = useState(false);
    const [period, setPeriod] = useState({ id: 0, val: 'Current Year', key: 'ONE_YEAR_BACK' });
    const [periodDropdownVisible, setPeriodDropdownVisible] = useState(false);

    const [modalVisible, setModalVisible] = useState<boolean>();
    const [message, setMessage] = useState<string>();

    const [fromDate, setFromDate] = useState<any>(new Date());
    const [toDate, setToDate] = useState(new Date());

    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;

    useEffect(() => {
        if (studentProgressionSuccess) {
            setMessage(studentProgressionCSVMessage);
            setModalVisible(true);
            dispatch({ type: DOWNLOAD_CSV_STUDENNT_PROGRESSION_RESET });
        } else if (isHiredByFCBCSVSuccess) {
            setMessage(hiredbyFCBEmailMessage);
            setModalVisible(true);
            dispatch({ type: DOWNLOAD_CSV_HIRED_BY_FCB_RESET });
        }
    }, [
        hiredbyFCBEmailMessage,
        studentProgressionCSVMessage,
        studentProgressionSuccess,
        isHiredByFCBCSVSuccess,
    ]);

    useEffect(() => {
        if (period.key !== 'CUSTOM') {
            if (user?.roles[0]?.roleName === INSTITUTE_ADMIN) {
                if (selectedTab === 1)
                    dispatch(getProgressionData(period.key, profile?.instituteId, onlyInstitute));
                else dispatch(getHiredFcbData(period.key));
            } else {
                if (selectedTab === 1)
                    dispatch(getProgressionData(period.key, institute?.id, onlyInstitute));
                else dispatch(getHiredFcbData(period.key));
            }
        } else {
            if (user?.roles[0]?.roleName === INSTITUTE_ADMIN) {
                if (selectedTab === 1)
                    dispatch(
                        getProgressionData(
                            period.key,
                            profile?.instituteId,
                            onlyInstitute,
                            moment(fromDate).format('YYYY-MM-DD'),
                            moment(toDate).format('YYYY-MM-DD')
                        )
                    );
                else
                    dispatch(
                        getHiredFcbData(
                            period.key,
                            moment(fromDate).format('YYYY-MM-DD'),
                            moment(toDate).format('YYYY-MM-DD')
                        )
                    );
            } else {
                if (selectedTab === 1)
                    dispatch(
                        getProgressionData(
                            period.key,
                            institute?.id,
                            onlyInstitute,
                            moment(fromDate).format('YYYY-MM-DD'),
                            moment(toDate).format('YYYY-MM-DD')
                        )
                    );
                else
                    dispatch(
                        getHiredFcbData(
                            period.key,
                            moment(fromDate).format('YYYY-MM-DD'),
                            moment(toDate).format('YYYY-MM-DD')
                        )
                    );
            }
        }
    }, [dispatch, period, institute, user, onlyInstitute]);

    useEffect(() => {
        // const date = new Date();
        if (toDate < fromDate) {
            // add a day
            // date.setDate(fromDate.getDate() + 1);
            setToDate(fromDate);
        }
    }, [fromDate, toDate]);

    return (
        <section className="pb-6">
            <CSVEmailedSuccessModal
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                message={message}
                setMessage={setMessage}
            />
            <div className="flex justify-between items-center mb-4 md:mb-8 pt-12 border-t-2">
                <div
                    className={` text-xl md:text-3xl w-1/2 flex text-center text-black-700 font-bold`}
                >
                    Progression Overview
                </div>
                <div
                    className={`relative w-1/2 md:w-1/3 xl:w-1/5 cursor-pointer items-center focus:outline-none focus:border-blue-900 mx-2`}
                >
                    <div className="inputFieldThickness">
                        <div
                            className="flex justify-between w-full px-2 h-11 items-center "
                            onClick={() => setPeriodDropdownVisible(!periodDropdownVisible)}
                        >
                            <div className={`text-md  text-gray-700`}>{period.val}</div>
                            <div className="flex flex-row">
                                <div className="flex flex-col">
                                    <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                    <HiIcons.HiChevronDown className="mr-2" />
                                </div>
                            </div>
                        </div>
                        {periodDropdownVisible && (
                            <ul
                                className="absolute w-full  cursor-pointer pl-0 top-12 right-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                role="menu"
                            >
                                {TrackPeriodFilter.map(action => (
                                    <div
                                        className="p-2 pl-2 hover:bg-gray-200 "
                                        key={action.id}
                                        onClick={() => {
                                            setPeriod(action);
                                            setPeriodDropdownVisible(false);
                                        }}
                                    >
                                        {action.val}
                                    </div>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
                <button
                    onClick={() => {
                        if (period.key !== 'CUSTOM') {
                            if (selectedTab === 1) {
                                dispatch(
                                    downloadCSVStudentProgression(
                                        institute?.id,
                                        onlyInstitute,
                                        period.key
                                    )
                                );
                            } else {
                                console.log('test1');
                                dispatch(downloadCSVHiredByFCB(period.key));
                            }
                        } else {
                            if (selectedTab === 1) {
                                dispatch(
                                    downloadCSVStudentProgression(
                                        institute?.id,
                                        onlyInstitute,
                                        period.key,
                                        moment(fromDate).format('YYYY-MM-DD'),
                                        moment(toDate).format('YYYY-MM-DD')
                                    )
                                );
                            } else {
                                dispatch(
                                    downloadCSVHiredByFCB(
                                        period.key,
                                        moment(fromDate).format('YYYY-MM-DD'),
                                        moment(toDate).format('YYYY-MM-DD')
                                    )
                                );
                            }
                        }
                    }}
                    className="bg-snow-900 border-2 border-blue-700 border-opacity-40 text-blue-700 hover:text-white hover:bg-blue-700 text-sm md:font-semibold rounded-sm w-1/2 md:w-1/3 xl:w-1/5 h-11 focus:outline-none"
                >
                    {selectedTab === 1 ? (
                        // <a href={`${API_URL}/csv/progression/student`}>
                        //     {isStuProgCSVLoading ? <Loader /> : 'Email the CSV'}
                        // </a>
                        isStuProgCSVLoading ? (
                            <Loader />
                        ) : (
                            'Email the CSV'
                        )
                    ) : // <a href={`${API_URL}/csv/progression/hiredThroughFcb`}>
                    //     {isHiredByFCBCSVLoading ? <Loader /> : 'Email the CSV'}
                    // </a>
                    isHiredByFCBCSVLoading ? (
                        <Loader />
                    ) : (
                        'Email the CSV'
                    )}
                </button>
            </div>
            <div className="flex w-full items-end mb-4">
                <div className="w-5/6 md:w-4/5">
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        setInstitute={setInstitute}
                    />
                </div>
                {period.val === 'Custom' ? (
                    <>
                        <DatePicker
                            required
                            showYearDropdown
                            showMonthDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            // maxDate={subYears(10)}
                            maxDate={new Date(lastDateOfYear)}
                            // maxDate={subYears(10)}
                            // id="set_date"
                            className="text-sm p-1 pl-2 h-10 mr-2 inputFieldThickness rounded"
                            selected={fromDate}
                            onChange={date => {
                                if (date != null) {
                                    setFromDate(date);
                                }
                                // if (isErrorDOB) {
                                //     setIsErrorDOB(false);
                                // }
                            }}
                            dateFormat="do MMMM yyyy"
                            placeholderText="From"
                        />
                        <DatePicker
                            required
                            showYearDropdown
                            showMonthDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            // maxDate={subYears(10)}
                            maxDate={new Date(lastDateOfYear)}
                            // maxDate={subYears(10)}
                            // id="set_date"
                            className="text-sm p-1 pl-2 h-10 mr-2 inputFieldThickness rounded"
                            selected={toDate}
                            onChange={date => {
                                if (date != null) {
                                    setToDate(date);
                                }
                                // if (isErrorDOB) {
                                //     setIsErrorDOB(false);
                                // }
                            }}
                            dateFormat="do MMMM yyyy"
                            placeholderText="To"
                        />
                        <button
                            onClick={() => {
                                if (user?.roles[0]?.roleName === INSTITUTE_ADMIN) {
                                    if (selectedTab === 1)
                                        dispatch(
                                            getProgressionData(
                                                period.key,
                                                profile?.instituteId,
                                                onlyInstitute,
                                                moment(fromDate).format('YYYY-MM-DD'),
                                                moment(toDate).format('YYYY-MM-DD')
                                            )
                                        );
                                    else
                                        dispatch(
                                            getHiredFcbData(
                                                period.key,
                                                moment(fromDate).format('YYYY-MM-DD'),
                                                moment(toDate).format('YYYY-MM-DD')
                                            )
                                        );
                                } else {
                                    if (selectedTab === 1)
                                        dispatch(
                                            getProgressionData(
                                                period.key,
                                                institute?.id,
                                                onlyInstitute,
                                                moment(fromDate).format('YYYY-MM-DD'),
                                                moment(toDate).format('YYYY-MM-DD')
                                            )
                                        );
                                    else
                                        dispatch(
                                            getHiredFcbData(
                                                period.key,
                                                moment(fromDate).format('YYYY-MM-DD'),
                                                moment(toDate).format('YYYY-MM-DD')
                                            )
                                        );
                                }
                            }}
                            className="bg-snow-900 border-2 border-blue-700 border-opacity-40 text-blue-700 hover:text-white hover:bg-blue-700 text-sm md:font-semibold rounded-sm w-1/2 md:w-1/3 xl:w-1/5 h-10 focus:outline-none"
                        >
                            Apply
                        </button>
                    </>
                ) : null}
            </div>
            {selectedTab === 1 ? (
                isLoadingProgessionData ? (
                    <div
                        style={{ backgroundColor: 'rgba(250, 250, 250, 0.95)' }}
                        className="rounded-2xl"
                    >
                        <div
                            style={{
                                backgroundImage: `url("/assets/newcentredGraph.png")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                            className="flex flex-col items-center justify-center py-24"
                        >
                            <NoData />
                            <div className="font-manrope font-bold text-2xl mt-6">No Data Yet</div>
                            <div className="font-manrope mt-3 xl:mx-96 lg:mx-56 text-center">
                                Unfortunately we don&apos;t have any data to be able to show you a
                                graph.
                            </div>
                        </div>
                    </div>
                ) : (
                    <StudentProgression
                        institute={institute}
                        setInstitute={setInstitute}
                        onlyInstitute={onlyInstitute}
                        setOnlyInstitute={setOnlyInstitute}
                    />
                )
            ) : isLoadingProgessionData ? (
                <div
                    style={{ backgroundColor: 'rgba(250, 250, 250, 0.95)' }}
                    className="rounded-2xl"
                >
                    <div
                        style={{
                            backgroundImage: `url("/assets/newcentredGraph.png")`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                        className="flex flex-col items-center justify-center py-24"
                    >
                        <NoData />
                        <div className="font-manrope font-bold text-2xl mt-6">No Data Yet</div>
                        <div className="font-manrope mt-3 xl:mx-96 lg:mx-56 text-center">
                            Unfortunately we don&apos;t have any data to be able to show you a
                            graph.
                        </div>
                    </div>
                </div>
            ) : (
                <HiredFCBProgression />
            )}
        </section>
    );
};

export default ProgressionOverview;
