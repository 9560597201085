import React, { ReactElement, useEffect, useState } from 'react';
import { InstitutesType } from '../../../../redux/institutes/reducers';
import * as HiIcons from 'react-icons/hi';
import { RootState } from '../../../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { INSTITUTE_ADMIN } from '../../../../redux/authAdmin/reducers';
import { USER_PROFILE } from '../../../../utils/storage';
import { TracksType } from '../../../../redux/tracktab/reducers';
import { ChallengesType } from '../../../../redux/challenges/reducers';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Home town',
                padding: { top: 10 },
                font: {
                    size: 14,
                },
            },
            grid: {
                display: false,
                drawBorder: false,
            },
        },
        y: {
            title: {
                display: true,
                text: 'Number of Students',
                font: {
                    size: 14,
                },
            },
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                suggestedMax: 10,
                callback: function (value) {
                    if (Math.floor(value) === value) {
                        return value;
                    }
                },
            },
        },
    },
};

interface Props {
    institute: InstitutesType;
    setInstitute: (value: InstitutesType) => void;
    setCategory1: (value: string) => void;
    setCategory2: (value: string) => void;
    setCategory3: (value: string) => void;
    track?: TracksType;
    setTrack?: (value: TracksType) => void;
    challenge?: ChallengesType;
    setChallenge?: (value: ChallengesType) => void;
    invitationCode: string;
    setInvitationCode: (value: string) => void;
    isProgress: boolean;
    datas: any;
}

export default function BarChart({
    institute,
    setInstitute,
    setCategory1,
    setCategory2,
    setCategory3,
    track,
    setTrack,
    challenge,
    setChallenge,
    invitationCode,
    setInvitationCode,
    isProgress,
    datas,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const [showInstitutesDropdown, setShowInstitutesDropdown] = useState(false);
    const [showCategory1Dropdown, setShowCategory1Dropdown] = useState(false);
    const [showCategory2Dropdown, setShowCategory2Dropdown] = useState(false);
    const [showCategory3Dropdown, setShowCategory3Dropdown] = useState(false);
    const [showCodeDropdown, setShowCodeDropdown] = useState(false);
    const [showTracksDropdown, setShowTracksDropdown] = useState(false);
    const [showChallengesDropdown, setShowChallengesDropdown] = useState(false);
    const [filteredInstitutes, setFilteredInstitutes] = useState([]);
    const user = useSelector((state: RootState) => state.users.User);
    const categoriesData = useSelector((state: RootState) => state.dashboard.categoriesData);
    const institutes = useSelector((state: RootState) => state.institutes.publicInstitutData);
    const invitationCodes = useSelector((state: RootState) => state.users.invitationCodes);
    const instituteWiseProgressData = useSelector(
        (state: RootState) => state.dashboard.instituteWiseProgressData
    );
    const trackData = useSelector((state: RootState) => state.tracks.trackData);
    const challengeData = useSelector((state: RootState) => state.challenges.challengesData);

    const [selectedCatogary1, setSelectedCatogary1] = useState([]);
    const [selectedCatogary2, setSelectedCatogary2] = useState([]);
    const [selectedCatogary3, setSelectedCatogary3] = useState([]);
    const [selectedInvitationCode, setSelectedInvitationCode] = useState([]);

    const labels = instituteWiseProgressData?.labels;

    const data = {
        labels,
        datasets: [
            {
                data: instituteWiseProgressData?.values,
                backgroundColor: ['rgba(196, 181, 253,1)'],
                maxBarThickness: 200,
            },
        ],
    };

    useEffect(() => {
        if (selectedCatogary1.length > 0) {
            let str1 = '';
            for (let i = 0; i < selectedCatogary1.length; i++) {
                if (i !== selectedCatogary1.length - 1) {
                    str1 += selectedCatogary1[i] + ',';
                } else {
                    str1 += selectedCatogary1[i];
                }
            }
            setCategory1(str1);
        }

        if (selectedCatogary2.length > 0) {
            let str2 = '';
            for (let i = 0; i < selectedCatogary2.length; i++) {
                if (i !== selectedCatogary2.length - 1) {
                    str2 += selectedCatogary2[i] + ',';
                } else {
                    str2 += selectedCatogary2[i];
                }
            }
            setCategory2(str2);
        }

        if (selectedCatogary3.length > 0) {
            let str3 = '';
            for (let i = 0; i < selectedCatogary3.length; i++) {
                if (i !== selectedCatogary3.length - 1) {
                    str3 += selectedCatogary3[i] + ',';
                } else {
                    str3 += selectedCatogary3[i];
                }
            }
            setCategory3(str3);
        }

        if (selectedInvitationCode.length > 0) {
            let str4 = '';
            for (let i = 0; i < selectedInvitationCode.length; i++) {
                if (i !== selectedInvitationCode.length - 1) {
                    str4 += selectedInvitationCode[i] + ',';
                } else {
                    str4 += selectedInvitationCode[i];
                }
            }
            setInvitationCode(str4);
        }
    }, [selectedCatogary1, selectedCatogary2, selectedCatogary3, selectedInvitationCode]);

    useEffect(() => {
        if (user?.roles[0]?.roleName === INSTITUTE_ADMIN) {
            const filteredList = institutes.filter(inst => inst?.id === profile?.instituteId);
            setFilteredInstitutes(filteredList);
        } else {
            setFilteredInstitutes(institutes);
        }
    }, [institutes, profile?.instituteId, setInstitute, user?.roles]);

    useEffect(() => {
        setInstitute(filteredInstitutes?.[0]);
    }, [filteredInstitutes, setInstitute]);

    return (
        <>
            {isProgress === true ? (
                <div className="flex w-full mb-2">
                    <div className="mb-2 w-full md:mb-0 flex">
                        <div className="flex flex-col w-full">
                            <div className="w-full flex items-center">
                                <label className="mx-2 w-1/4"> Track : </label>
                                <div
                                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none md:w-2/3 lg:w-3/5 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className="flex justify-between w-full md:p-2"
                                        onClick={() => {
                                            setShowTracksDropdown(!showTracksDropdown);
                                        }}
                                    >
                                        <div className="text-gray-700 font-medium pl-2">
                                            {track ? track?.trackName : 'All'}
                                        </div>
                                        <div className="flex flex-col">
                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                            <HiIcons.HiChevronDown className="mr-2" />
                                        </div>
                                    </div>
                                    {showTracksDropdown ? (
                                        <ul
                                            className="absolute max-h-48 overflow-y-auto w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                        >
                                            <div
                                                className={`p-2 hover:bg-gray-200 ${
                                                    !track && 'bg-gray-300'
                                                }`}
                                                key="0"
                                                // value=""
                                                onClick={() => {
                                                    setTrack(null);
                                                    setShowTracksDropdown(false);
                                                }}
                                            >
                                                All
                                            </div>
                                            {trackData?.map(trackItem => (
                                                <div
                                                    className={`p-2 hover:bg-gray-200 ${
                                                        track?.trackName === trackItem?.trackName &&
                                                        'bg-gray-300'
                                                    }`}
                                                    key={trackItem?.id}
                                                    // value={trackItem?.id}
                                                    onClick={() => {
                                                        setTrack(trackItem);
                                                        setShowTracksDropdown(false);
                                                    }}
                                                >
                                                    {trackItem?.trackName}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 w-full md:mb-0 flex">
                        <div className="flex flex-col w-full">
                            <div className="w-full flex items-center">
                                <label className="mx-2 w-1/4"> Challenge : </label>
                                <div
                                    className={`rounded-md flex relative cursor-pointer items-center focus:outline-none md:w-2/3 lg:w-3/5 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                >
                                    <div
                                        className="flex justify-between w-full md:p-2"
                                        onClick={() => {
                                            setShowChallengesDropdown(!showChallengesDropdown);
                                        }}
                                    >
                                        <div className="text-gray-700 font-medium pl-2">
                                            {challenge ? challenge?.challengeName : 'All'}
                                        </div>
                                        <div className="flex flex-col">
                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                            <HiIcons.HiChevronDown className="mr-2" />
                                        </div>
                                    </div>
                                    {showChallengesDropdown ? (
                                        <ul
                                            className="absolute h-48 overflow-y-auto w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                        >
                                            <div
                                                className={`p-2 hover:bg-gray-200 ${
                                                    !challenge && 'bg-gray-300'
                                                }`}
                                                key="0"
                                                // value=""
                                                onClick={() => {
                                                    setChallenge(null);
                                                    setShowChallengesDropdown(false);
                                                }}
                                            >
                                                All
                                            </div>
                                            {challengeData?.map(challengeItem => (
                                                <div
                                                    className={`p-2 hover:bg-gray-200 ${
                                                        challenge?.challengeName ===
                                                            challengeItem?.challengeName &&
                                                        'bg-gray-300'
                                                    }`}
                                                    key={challengeItem?.id}
                                                    // value={challengeItem?.id}
                                                    onClick={() => {
                                                        setChallenge(challengeItem);
                                                        setShowChallengesDropdown(false);
                                                    }}
                                                >
                                                    {challengeItem?.challengeName}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className="flex w-full mb-2">
                <div className="mb-4 w-full md:mb-0 flex">
                    <div className="flex flex-col w-full">
                        <div className="w-full flex items-center">
                            {categoriesData?.category1?.options && (
                                <>
                                    <label className="mx-2 w-1/4">
                                        {categoriesData?.category1?.name}:&nbsp;
                                    </label>
                                    <div
                                        className={`rounded-md flex relative cursor-pointer items-center focus:outline-none md:w-2/3 lg:w-3/5 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                    >
                                        <div
                                            className="flex justify-between w-full"
                                            onClick={() => setShowCategory1Dropdown(true)}
                                        >
                                            <div className="flex flex-wrap">
                                                {selectedCatogary1.length > 0
                                                    ? selectedCatogary1?.map(action => (
                                                          <div
                                                              style={{
                                                                  maxHeight: '10px',
                                                                  display: 'flex',
                                                                  justifyContent: 'center',
                                                                  alignItems: 'center',
                                                              }}
                                                              key={action}
                                                              className={`rounded-full border-2 my-1 mr-4 text-purple-950 border-purple-75 bg-purple-75 font-medium py-3 px-2`}
                                                          >
                                                              {action}
                                                              <HiIcons.HiOutlineX
                                                                  style={{ color: '#4C2EC2' }}
                                                                  className="ml-2  cursor-pointer"
                                                                  onClick={() =>
                                                                      setSelectedCatogary1(
                                                                          selectedCatogary1?.filter(
                                                                              item =>
                                                                                  item !== action
                                                                          )
                                                                      )
                                                                  }
                                                              />
                                                          </div>
                                                      ))
                                                    : categoriesData?.category1?.name}
                                            </div>
                                            <div className="flex flex-col">
                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                <HiIcons.HiChevronDown className="mr-2" />
                                            </div>
                                        </div>
                                        {showCategory1Dropdown ? (
                                            <ul
                                                className="absolute w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                role="menu"
                                                onMouseLeave={() => setShowCategory1Dropdown(false)}
                                            >
                                                {categoriesData?.category1?.options.map(option => (
                                                    <div
                                                        className="p-2 hover:bg-gray-200"
                                                        key={option}
                                                    >
                                                        <input
                                                            id={option}
                                                            className="styled-checkbox"
                                                            type="checkbox"
                                                            value="I have not done O/Ls yet"
                                                            checked={selectedCatogary1?.includes(
                                                                option
                                                            )}
                                                            onChange={() => {
                                                                if (
                                                                    !selectedCatogary1?.includes(
                                                                        option
                                                                    )
                                                                ) {
                                                                    setSelectedCatogary1([
                                                                        ...selectedCatogary1,
                                                                        option,
                                                                    ]);
                                                                }
                                                                setShowInstitutesDropdown(false);
                                                            }}
                                                        />
                                                        <label className="ml-3" htmlFor={option}>
                                                            {option}
                                                        </label>
                                                    </div>
                                                ))}
                                            </ul>
                                        ) : null}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="mb-4 w-full md:mb-0 flex">
                    <div className="flex flex-col w-full">
                        <div className="w-full flex items-center">
                            {categoriesData?.category2?.options && (
                                <>
                                    <label className="mx-2 w-1/4">
                                        {categoriesData?.category2?.name}:&nbsp;
                                    </label>
                                    <div
                                        className={`rounded-md flex relative cursor-pointer items-center focus:outline-none md:w-2/3 lg:w-3/5 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                    >
                                        <div
                                            className="flex justify-between w-full"
                                            onClick={() => setShowCategory2Dropdown(true)}
                                        >
                                            <div className="flex flex-wrap">
                                                {selectedCatogary2.length > 0
                                                    ? selectedCatogary2?.map(action => (
                                                          <div
                                                              style={{
                                                                  maxHeight: '10px',
                                                                  display: 'flex',
                                                                  justifyContent: 'center',
                                                                  alignItems: 'center',
                                                              }}
                                                              key={action}
                                                              className={`rounded-full border-2 my-1 mr-4 text-purple-950 border-purple-75 bg-purple-75 font-medium py-3 px-2`}
                                                          >
                                                              {action}
                                                              <HiIcons.HiOutlineX
                                                                  style={{ color: '#4C2EC2' }}
                                                                  className="ml-2  cursor-pointer"
                                                                  onClick={() =>
                                                                      setSelectedCatogary2(
                                                                          selectedCatogary2?.filter(
                                                                              item =>
                                                                                  item !== action
                                                                          )
                                                                      )
                                                                  }
                                                              />
                                                          </div>
                                                      ))
                                                    : categoriesData?.category2?.name}
                                            </div>
                                            <div className="flex flex-col">
                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                <HiIcons.HiChevronDown className="mr-2" />
                                            </div>
                                        </div>
                                        {showCategory2Dropdown ? (
                                            <ul
                                                className="absolute w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                role="menu"
                                                onMouseLeave={() => setShowCategory2Dropdown(false)}
                                            >
                                                {categoriesData?.category2?.options?.map(option => (
                                                    <div
                                                        className="p-2 hover:bg-gray-200"
                                                        key={option}
                                                    >
                                                        <input
                                                            id={option}
                                                            className="styled-checkbox"
                                                            type="checkbox"
                                                            value="I have not done O/Ls yet"
                                                            checked={selectedCatogary2?.includes(
                                                                option
                                                            )}
                                                            onChange={() => {
                                                                if (
                                                                    !selectedCatogary2?.includes(
                                                                        option
                                                                    )
                                                                ) {
                                                                    setSelectedCatogary2([
                                                                        ...selectedCatogary2,
                                                                        option,
                                                                    ]);
                                                                }
                                                                setShowCategory2Dropdown(false);
                                                            }}
                                                        />
                                                        <label className="ml-3" htmlFor={option}>
                                                            {option}
                                                        </label>
                                                    </div>
                                                ))}
                                            </ul>
                                        ) : null}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="mb-4 w-full md:mb-0 flex">
                    <div className="flex flex-col w-full">
                        <div className="w-full flex items-center">
                            {categoriesData?.category3?.options && (
                                <>
                                    <label className="mx-2 w-1/2">
                                        {categoriesData?.category3?.name}:&nbsp;
                                    </label>
                                    <div
                                        className={`rounded-md flex relative cursor-pointer items-center focus:outline-none md:w-2/3 lg:w-3/5 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                    >
                                        <div
                                            className="flex justify-between w-full"
                                            onClick={() => setShowCategory3Dropdown(true)}
                                        >
                                            <div className="flex flex-wrap">
                                                {selectedCatogary3.length > 0
                                                    ? selectedCatogary3?.map(action => (
                                                          <div
                                                              style={{
                                                                  maxHeight: '10px',
                                                                  display: 'flex',
                                                                  justifyContent: 'center',
                                                                  alignItems: 'center',
                                                              }}
                                                              key={action}
                                                              className={`rounded-full border-2 my-1 mr-4 text-purple-950 border-purple-75 bg-purple-75 font-medium py-3 px-2`}
                                                          >
                                                              {action}
                                                              <HiIcons.HiOutlineX
                                                                  style={{ color: '#4C2EC2' }}
                                                                  className="ml-2  cursor-pointer"
                                                                  onClick={() =>
                                                                      setSelectedCatogary3(
                                                                          selectedCatogary3?.filter(
                                                                              item =>
                                                                                  item !== action
                                                                          )
                                                                      )
                                                                  }
                                                              />
                                                          </div>
                                                      ))
                                                    : categoriesData?.category3?.name}
                                            </div>

                                            <div className="flex flex-col">
                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                <HiIcons.HiChevronDown className="mr-2" />
                                            </div>
                                        </div>
                                        {showCategory3Dropdown ? (
                                            <ul
                                                className="absolute w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                role="menu"
                                                onMouseLeave={() => setShowCategory3Dropdown(false)}
                                            >
                                                {categoriesData?.category3?.options?.map(option => (
                                                    <div
                                                        className="p-2 hover:bg-gray-200"
                                                        key={option}
                                                    >
                                                        <input
                                                            id={option}
                                                            className="styled-checkbox"
                                                            type="checkbox"
                                                            value="I have not done O/Ls yet"
                                                            checked={selectedCatogary3?.includes(
                                                                option
                                                            )}
                                                            onChange={() => {
                                                                if (
                                                                    !selectedCatogary3?.includes(
                                                                        option
                                                                    )
                                                                ) {
                                                                    setSelectedCatogary3([
                                                                        ...selectedCatogary3,
                                                                        option,
                                                                    ]);
                                                                }
                                                                setShowCategory3Dropdown(false);
                                                            }}
                                                        />
                                                        <label className="ml-3" htmlFor={option}>
                                                            {option}
                                                        </label>
                                                    </div>
                                                ))}
                                            </ul>
                                        ) : null}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full overflow-hidden pt-4">
                <div className="w-full overflow-x-auto pl-1 pb-6" style={{ height: '400px' }}>
                    <Bar options={options} data={datas} />
                </div>
            </div>
        </>
    );
}
