import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import {
    clearAuth,
    getCompanyById,
    getInstituteById,
    getUserById,
    logout,
} from '../redux/rootActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import { USER_PROFILE } from '../utils/storage';
import {
    SUPER_ADMIN,
    COMPANY_ADMIN,
    SLASSCOM_ADMIN,
    INSTITUTE_ADMIN,
} from '../redux/authAdmin/reducers';
import { s3UrlPrefix } from '../constants';

const NavIcon = styled(Link)`
    background: #ffffff;
    margin-left: 2rem;
    font-size: 2rem;
    height: 30px;
    justify-content: flex-start;
    align-items: center;
`;
interface Props {
    sidebar?: boolean;
    setSidebar?: (value: boolean) => void;
}
export default function NavBar({ sidebar, setSidebar }: Props): ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.users.User);
    const [profileDropdownVisible, setProfileDropdownVisible] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const company = useSelector((state: RootState) => state.companies.company);
    const institute = useSelector((state: RootState) => state.institutes.institute);

    useEffect(() => {
        if (profile?.companyId) {
            dispatch(getCompanyById(profile?.companyId));
        } else if (profile?.instituteId) {
            dispatch(getInstituteById(profile?.instituteId));
        }
        if (profile?.userId) {
            dispatch(getUserById(profile.userId));
        }
    }, [dispatch]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <nav
            className="bg-white border-b-2 w-full z-50 mb-4 fixed"
            onMouseLeave={() => {
                setProfileDropdownVisible(false);
            }}
        >
            <div className="mx-2 sm:px-6 sm:mx-4">
                <div className="relative flex items-center justify-between">
                    <div className="flex-1 flex sm:items-stretch sm:justify-start">
                        <img className="h-16" src={`/assets/New.png`} alt="logo" />
                    </div>
                    {window.location.pathname.toLowerCase().includes('signin') ||
                    window.location.pathname.includes('/admin/reset-password') ||
                    window.location.pathname.includes('/admin/forgot-password') ? null : (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <div className="hidden md:flex flex-col mx-2 sm:mx-4">
                                <div
                                    className="flex text-sm md:text-base text-gray-700 hover:text-gray-600 font-semibold cursor-pointer"
                                    onClick={() => {
                                        history.push('/admin/settings');
                                    }}
                                >
                                    {user ? user.firstName + ' ' + user.lastName : 'Loading...'}
                                </div>
                                <div className="flex sm:text-sm md:text-base font-bold text-gray-700">
                                    {user?.roles[0]?.roleName === SUPER_ADMIN
                                        ? 'Super Admin'
                                        : user?.roles[0]?.roleName === SLASSCOM_ADMIN
                                        ? 'SLASSCOM Admin'
                                        : user?.roles[0]?.roleName === COMPANY_ADMIN
                                        ? 'Company Admin'
                                        : user?.roles[0]?.roleName === INSTITUTE_ADMIN
                                        ? 'Institute Admin'
                                        : ''}
                                </div>
                            </div>
                            {/* <!-- Profile dropdown --> */}
                            <div className="ml-1 sm:ml-3 relative">
                                <div>
                                    <img
                                        className="h-10 w-10 rounded-full cursor-pointer"
                                        src={`${
                                            user?.roles[0]?.roleName === COMPANY_ADMIN &&
                                            company?.comImage
                                                ? `${s3UrlPrefix}${company?.comImage}`
                                                : user?.roles[0]?.roleName === INSTITUTE_ADMIN &&
                                                  institute?.logoImage
                                                ? `${s3UrlPrefix}${institute?.logoImage}`
                                                : (user?.roles[0]?.roleName === SUPER_ADMIN ||
                                                      user?.roles[0]?.roleName ===
                                                          SLASSCOM_ADMIN) &&
                                                  user?.imageUrl
                                                ? `${s3UrlPrefix}${user?.imageUrl}`
                                                : '/assets/user.png'
                                        }`}
                                        alt="profile"
                                        onMouseEnter={() => {
                                            setProfileDropdownVisible(true);
                                        }}
                                    />
                                </div>
                                {profileDropdownVisible ? (
                                    <ul
                                        className="origin-top-right absolute -right-6 pl-0 mt-3 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        role="menu"
                                        aria-orientation="vertical"
                                        aria-labelledby="user-menu-button"
                                        onMouseOverCapture={() => setProfileDropdownVisible(true)}
                                        onMouseLeave={() => setProfileDropdownVisible(false)}
                                    >
                                        <div
                                            className="px-6 py-2 cursor-pointer hover:bg-blue-100 hover:text-blue-900 hover:font-semibold"
                                            role="presentation"
                                            onClick={() => {
                                                setProfileDropdownVisible(false);
                                                history.push('/admin/settings');
                                            }}
                                        >
                                            Settings
                                        </div>
                                        <div
                                            onClick={() => {
                                                setProfileDropdownVisible(false);
                                                history.push('/admin/signIn');
                                                dispatch(logout());
                                                dispatch(clearAuth());
                                                window.location.reload();
                                            }}
                                            className="px-6 py-2 cursor-pointer hover:bg-blue-100 hover:text-blue-900 hover:font-semibold"
                                            role="presentation"
                                        >
                                            Logout
                                        </div>
                                    </ul>
                                ) : null}
                            </div>
                            <NavIcon
                                className={`${sidebar === true ? 'hidden' : 'lg:flex'}`}
                                to="#"
                            >
                                <FaIcons.FaBars onClick={showSidebar} />
                            </NavIcon>
                        </div>
                    )}
                </div>
            </div>

            {/* <!-- Mobile menu, show/hide based on menu state. --> */}
        </nav>
    );
}
