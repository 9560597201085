import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { socialMediaLogin } from '../../../../../redux/rootActions';
import { RootState } from '../../../../../redux/rootReducer';
import SocialMediaErrorMessage from '../../../../modals/SocialMediaErrorMessage';

export default function SocialMediaRedirect(): ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();

    const loginResponse = useSelector((state: RootState) => state.webAuth.loginResponse);
    const loginErrorMessage = useSelector((state: RootState) => state.webAuth.loginErrorMessage);

    const [URL, setURL] = useState<string>();
    const [accessToken, setAccessToken] = useState<string>(null);
    const [errorMessage, setErrorMessage] = useState<string>(null);
    const [invalidLogin, setInvalidLogin] = useState(false);

    const [showSuccessModal, setShowSuccessModal] = React.useState(false);

    useEffect(() => {
        setURL(window.location.href);
    }, [URL]);

    useEffect(() => {
        if (URL?.includes('token=')) {
            let token = new URLSearchParams(URL).get(
                `${window.location.origin}/oauth2/redirect?token`
            );
            if (token.includes('#_=_')) {
                token = token.replace('#_=_', '');
            } else if (token.includes('#')) {
                token = token.replace('#', '');
            }

            setAccessToken(token);
        } else if (URL?.includes('error=')) {
            let error = new URLSearchParams(URL).get(
                `${window.location.origin}/oauth2/redirect?error`
            );

            if (error.includes('#_=_')) {
                error = error.replace('#_=_', '');
            } else if (error.includes('#')) {
                error = error.replace('#', '');
            }
            setErrorMessage(error);
        }
    }, [URL]);

    useEffect(() => {
        if (accessToken !== null) {
            dispatch(socialMediaLogin(accessToken));
        }
    }, [accessToken, dispatch]);

    useEffect(() => {
        if (loginResponse) {
            if (loginResponse?.isEmailVerified) {
                if (
                    loginResponse?.onBoardingCompleted === null ||
                    loginResponse?.onBoardingCompleted === false
                ) {
                    history.push(`/onboard`);
                } else {
                    history.push(`/dashBoard/learn`);
                }
            } else {
                history.push({
                    pathname: `/register`,
                });
            }
        }
        if (loginErrorMessage) {
            setInvalidLogin(true);
        }
    }, [loginResponse, loginErrorMessage]);

    useEffect(() => {
        if (errorMessage !== null) {
            setShowSuccessModal(true);
        }
    }, [errorMessage]);

    return (
        <div>
            {errorMessage !== null ? (
                <SocialMediaErrorMessage
                    modalVisible={showSuccessModal}
                    setModalVisible={setShowSuccessModal}
                    errorMessage={errorMessage}
                />
            ) : null}
        </div>
    );
}
