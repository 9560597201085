import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearSuccessStoryMessages, editSuccessStory } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import { useTable, useRowSelect, usePagination } from 'react-table';
import * as BsIcons from 'react-icons/bs';
import { IconContext } from 'react-icons';
import ModalVideo from 'react-modal-video';
import TableTabView from './TableTabView';
import { StoryType } from '../../../redux/successStories/reducers';
import SuccessErrorStoriesModal from '../../modals/SuccessErrorStoriesModal';
import * as HiIcons from 'react-icons/hi';
import { youtubeParser } from '../../../web/utils';
import { s3UrlPrefix } from '../../../constants';

interface Props {
    data: StoryType[];
    columns: any;
    fetchData;
    pageCount: number;
    setSlectedTracks;
    selectedTab: number;
    setSelectedTab: (value: number) => void;
}

const SuccessStoriesTable = ({
    columns,
    data,
    fetchData,
    pageCount: controlledPageCount,
    setSlectedTracks,
    selectedTab,
    setSelectedTab,
}: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showMore, setshowMore] = useState(false);
    const [clickedStory, setClickedStory] = useState<StoryType>();
    const [checked, setChecked] = useState(false);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [editStory, setEditStory] = useState(false);
    const [updatedStoryLink, setUpdatedStoryLink] = useState(null);
    const [succesVisible, setSuccesVisible] = useState(false);
    const [showRecordsDropdown, setShowRecordsDropdown] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const editStoryError = useSelector((state: RootState) => state.successStories.editStoryError);
    const StoryUpdateSuccessMessage = useSelector(
        (state: RootState) => state.successStories.StoryUpdateSuccessMessage
    );
    const tabStatus = selectedTab === 1 ? 'published' : 'unpublished';

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        setHiddenColumns,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
            manualPagination: true,
            pageCount: controlledPageCount,
        },
        usePagination,
        useRowSelect
    );

    useEffect(() => {
        selectedFlatRows.map(a => {
            return a.original;
        });
        setSlectedTracks(
            selectedFlatRows.map(a => {
                return a.original;
            })
        );
    }, [selectedFlatRows, setSlectedTracks]);

    useEffect(() => {
        fetchData({ pageIndex, pageSize });
    }, [fetchData, pageIndex, pageSize]);

    const handleClearMessages = useCallback(() => {
        if (StoryUpdateSuccessMessage || editStoryError) {
            setSuccesVisible(true);
        }
    }, [dispatch, StoryUpdateSuccessMessage, editStoryError]);
    const handleClose = () => {
        dispatch(clearSuccessStoryMessages());
        setSuccesVisible(false);
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    const showOptions = (story: any) => {
        setshowMore(!showMore);
        setEditStory(false);
        setClickedStory(story);
        setChecked(story.isFeatured);
        setUpdatedStoryLink(story.storyLink);
    };

    const publishStory = () => {
        const body = {
            storyLink: updatedStoryLink,
            status: 'PUBLISHED',
            isFeatured: checked,
        };
        dispatch(editSuccessStory(clickedStory.id, body, tabStatus, pageIndex, pageSize));
        setEditStory(false);
        setChecked(false);
        setshowMore(false);
    };

    const handleSaveStory = () => {
        const body = {
            storyLink: updatedStoryLink,
            status: tabStatus.toUpperCase(),
            isFeatured: checked,
        };
        dispatch(editSuccessStory(clickedStory.id, body, tabStatus, pageIndex, pageSize));
        setEditStory(false);
        setChecked(false);
        setshowMore(false);
    };

    return (
        <section className="py-6 h-full">
            <div className="flex justify-between w-full items-end mb-4">
                <div className="w-5/6 md:w-4/5">
                    <TableTabView selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                </div>
                <div className="mt-4 ml-1 md:ml-4 w-12 md:w-32 flex justify-end items-center">
                    <div className="hidden md:flex md:w-1/2">Records</div>
                    <div
                        className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-1/2 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                    >
                        <div
                            className="flex justify-between w-full"
                            onClick={() => setShowRecordsDropdown(true)}
                        >
                            <div className="text-gray-700 font-medium pl-2">{pageSize}</div>
                            <div>
                                <div className="flex flex-col">
                                    <HiIcons.HiChevronUp className="-mb-2" />
                                    <HiIcons.HiChevronDown />
                                </div>
                            </div>
                        </div>
                        {showRecordsDropdown ? (
                            <ul
                                className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                role="menu"
                                onMouseLeave={() => setShowRecordsDropdown(false)}
                            >
                                {[10, 25, 50, 100].map(value => (
                                    <div
                                        className="p-2 hover:bg-gray-200 "
                                        key={value}
                                        onClick={() => {
                                            setPageSize(value);
                                            setShowRecordsDropdown(false);
                                        }}
                                    >
                                        {value}
                                    </div>
                                ))}
                            </ul>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="w-full mb-8">
                <div className="w-full pl-1">
                    <div className="w-full" {...getTableProps()}>
                        <div className="w-full flex">
                            {headerGroups.map(headerGroup => (
                                <div
                                    key={headerGroup}
                                    {...headerGroup.getHeaderGroupProps()}
                                    className="w-full flex text-sm xl:text-base font-medium tracking-wide text-left text-gray-700"
                                    onMouseLeave={() => {
                                        setshowMore(false);
                                    }}
                                >
                                    {headerGroup.headers.map(column => (
                                        <div
                                            key={column}
                                            {...column.getHeaderProps()}
                                            className={`flex pl-4 py-3 ${
                                                column.id === 'actionColumn' ? 'w-1/6' : 'w-1/5'
                                            }`}
                                        >
                                            <div className="flex justify-between">
                                                <div className="whitespace-nowrap">
                                                    {column.render('Header')}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className="bg-white w-full" {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <div
                                        key={row}
                                        {...row.getRowProps()}
                                        className={`flex flex-col text-gray-700 w-full my-2 border-2 ${
                                            showMore && clickedStory === row.original
                                                ? 'bg-gray-100'
                                                : ''
                                        }`}
                                    >
                                        <div className="flex flex-row">
                                            {row.cells.map(cell => {
                                                return (
                                                    <div
                                                        key={cell}
                                                        {...cell.getCellProps({})}
                                                        className={`flex py-4 font-semibold break-all ${
                                                            cell.column.id === 'actionColumn'
                                                                ? 'relative w-1/6'
                                                                : 'pl-4 w-1/5'
                                                        }`}
                                                        onClick={() => {
                                                            if (cell.column.id === 'actionColumn') {
                                                                showOptions(row.original);
                                                            }
                                                        }}
                                                    >
                                                        {cell.column.id === 'firstName' && (
                                                            <img
                                                                className="h-8 w-8 rounded-full mr-4 overflow-hidden"
                                                                src={
                                                                    // eslint-disable-next-line
                                                                    row.original['imageUrl'] !==
                                                                    null
                                                                        ? /* eslint-disable */
                                                                          `${
                                                                              s3UrlPrefix +
                                                                              row.original[
                                                                                  'imageUrl'
                                                                              ]
                                                                          }` /* eslint-enable */
                                                                        : '/assets/placeholder-user.png'
                                                                }
                                                                alt="student profile pic"
                                                            />
                                                        )}
                                                        {cell.column.id === 'actionColumn' && (
                                                            <div className="cursor__pointer">
                                                                <button
                                                                    value="menu cursor-pointer"
                                                                    className="rounded cursor-pointer focus:outline-none lg:ml-2 text-blue-700 text-medium underline"
                                                                >
                                                                    {showMore &&
                                                                    clickedStory === row.original
                                                                        ? 'View Less'
                                                                        : 'View More'}
                                                                </button>
                                                            </div>
                                                        )}

                                                        {cell.render('Cell')}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {showMore && clickedStory === row.original && (
                                            <div className="border-t-2 px-6 py-3">
                                                <div className="font-semibold">Success Story:</div>
                                                <div className="mb-3">{clickedStory.content}</div>
                                                {editStory && (
                                                    <div className="mb-3">
                                                        <span>Story Link:</span>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            value={updatedStoryLink}
                                                            onChange={e =>
                                                                setUpdatedStoryLink(e.target.value)
                                                            }
                                                            className="w-1/2 rounded-sm text-gray-500 font-medium focus:outline-none ml-2 px-2 py-1 border-2 text-base border-gray-300"
                                                        />
                                                    </div>
                                                )}
                                                <div
                                                    className={`w-full flex ${
                                                        selectedTab !== 1
                                                            ? 'justify-between'
                                                            : 'justify-end'
                                                    }`}
                                                >
                                                    {editStory && (
                                                        <div className="font-medium text-gray-700 flex items-center">
                                                            <input
                                                                type="checkbox"
                                                                className="mr-1"
                                                                name="notificationList"
                                                                id="notificationList"
                                                                checked={checked}
                                                                onChange={() =>
                                                                    setChecked(!checked)
                                                                }
                                                            />
                                                            Post as a featured Success story
                                                        </div>
                                                    )}

                                                    <div className={`flex items-center`}>
                                                        <button
                                                            onClick={() =>
                                                                // history.push(
                                                                //     `/admin/students/${clickedStory.userProfileId}/view`
                                                                // )

                                                                history.push({
                                                                    pathname: `/admin/students/${clickedStory.userProfileId}/view`,
                                                                    state: {
                                                                        successStory: true,
                                                                    },
                                                                })
                                                            }
                                                            className="underline bg-snow-900 font-medium hover:bg-white text-blue-700 cursor-pointer text-sm rounded-sm focus:outline-none h-8 mx-1 px-4"
                                                        >
                                                            Profile Link
                                                        </button>
                                                        {clickedStory.storyLink &&
                                                            clickedStory.storyLink.length > 0 && (
                                                                <button
                                                                    className="underline bg-snow-900 font-medium hover:bg-white text-blue-700 cursor-pointer text-sm rounded-sm focus:outline-none h-8 mx-1 px-4"
                                                                    onClick={() =>
                                                                        setShowModal(true)
                                                                    }
                                                                >
                                                                    Story Link
                                                                </button>
                                                            )}
                                                        {editStory ? (
                                                            <button
                                                                className="bg-snow-900 bg-green-500 text-white text-sm font-medium rounded-sm h-8 w-20 mx-1"
                                                                onClick={() => handleSaveStory()}
                                                            >
                                                                Save
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="bg-snow-900 bg-yellow-500 text-white text-sm font-medium rounded-sm h-8 w-20 mx-1"
                                                                onClick={() => setEditStory(true)}
                                                            >
                                                                Edit
                                                            </button>
                                                        )}
                                                        <button
                                                            className="bg-snow-900 bg-red-500 text-white text-sm font-medium rounded-sm h-8 w-20 mx-1"
                                                            onClick={() =>
                                                                setDeleteConfirmationVisible(true)
                                                            }
                                                        >
                                                            Remove
                                                        </button>
                                                        {selectedTab !== 1 && (
                                                            <button
                                                                type="submit"
                                                                className="bg-blue-500 text-white text-sm rounded-sm  focus:outline-none h-8 w-20 mx-1"
                                                                onClick={() => publishStory()}
                                                            >
                                                                Publish
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex justify-end my-8">
                        <IconContext.Provider
                            value={{ color: !canPreviousPage ? '#bbbcbc' : '#707a92' }}
                        >
                            <button
                                className="text-xl font-medium text-gray-900"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                <BsIcons.BsChevronLeft />
                            </button>
                        </IconContext.Provider>
                        <span className="flex items-center font-semibold text-gray-700">
                            <p className="border px-2 mx-2 my-auto">{pageIndex + 1}</p>
                            of
                            <p className="border px-2 mx-2 my-auto">
                                {pageOptions.length === 0 ? '1' : pageOptions.length}
                            </p>
                        </span>
                        <IconContext.Provider
                            value={{ color: !canNextPage ? '#bbbcbc' : '#707a92' }}
                        >
                            <button
                                className="text-xl font-medium text-gray-900"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                <BsIcons.BsChevronRight />
                            </button>
                        </IconContext.Provider>
                    </div>
                </div>
            </div>
            <div className="flex justify-center">
                <ModalVideo
                    channel="youtube"
                    autoplay
                    isOpen={showModal}
                    videoId={youtubeParser(clickedStory?.storyLink)}
                    onClose={() => setShowModal(false)}
                />
            </div>
            {deleteConfirmationVisible ? (
                <DeleteConfirmationModal
                    modalVisible={deleteConfirmationVisible}
                    setModalVisible={setDeleteConfirmationVisible}
                    storyId={clickedStory.id}
                    setshowMenu={setshowMore}
                    tabStatus={tabStatus}
                />
            ) : null}

            {succesVisible ? (
                <SuccessErrorStoriesModal modalVisible={succesVisible} handleClose={handleClose} />
            ) : null}
        </section>
    );
};

export default SuccessStoriesTable;
