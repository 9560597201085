import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import Loader from '../../assets/svg/Loader';
import { activateConfirmationMessage } from '../../constants';
import { BadgeActivateType } from '../../redux/badges/reducers';
import { CertificateEditType } from '../../redux/certificates/reducers';
import { EventEditType } from '../../redux/events/reducers';
import {
    bulkUpdateBadges,
    bulkUpdateCertificates,
    bulkUpdateEvents,
    editBadge,
    editCertificate,
    editEvent,
} from '../../redux/rootActions';
import { RootState } from '../../redux/rootReducer';
interface Props {
    modalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    certificates?: any;
    certificateId?: string;
    certificate?: CertificateEditType;
    eventId?: string;
    event?: EventEditType;
    events?: any;
    badgeId?: string;
    badges?: any;
    badge?: BadgeActivateType;
    tabStatus?: string;
    selectedBadges?: any;
    selectedEvents?: any;
}

export default function ActivateConfirmationModal({
    modalVisible,
    setModalVisible,
    certificateId,
    certificates,
    certificate,
    eventId,
    event,
    events,
    badgeId,
    badge,
    badges,
    tabStatus,
    selectedBadges,
    selectedEvents,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const isEditLoading = useSelector((state: RootState) => state.certificates.isEditLoading);
    const isCertificateBulkUpdateLoading = useSelector(
        (state: RootState) => state.certificates.isBulkUpdateLoading
    );
    const isEventEditLoading = useSelector((state: RootState) => state.events.isEditLoading);
    const isEventBulkUpdateLoading = useSelector(
        (state: RootState) => state.events.isBulkUpdateLoading
    );
    const isBadgeEditLoading = useSelector((state: RootState) => state.badges.isEditLoading);
    const isBadgeBulkUpdateLoading = useSelector(
        (state: RootState) => state.badges.isBulkUpdateLoading
    );
    // changeable content count
    const changeCount = (selectedEvents || selectedBadges)?.filter(
        item => (item.eventStatus || item.badgeStatus) !== 'ACTIVE'
    ).length;
    const messageMaker = () => {
        if (events || badges) {
            return changeCount === 0
                ? `The selected records are already in active state`
                : changeCount === (selectedEvents || selectedBadges).length
                ? `${activateConfirmationMessage} ${events ? 'events' : 'badges'}`
                : `Only ${changeCount} ${events ? 'event' : 'badge'}${
                      changeCount > 1 ? 's are' : ' is'
                  } in inactive state. Are you sure you want to activate ${
                      changeCount > 1 ? 'them' : 'it'
                  }?`;
        } else {
            const postFix = certificates
                ? ' certificates?'
                : certificateId
                ? ' certificate?'
                : badgeId
                ? ' badge?'
                : ' event?';
            return activateConfirmationMessage + postFix;
        }
    };
    return (
        <Modal
            open={modalVisible}
            onClose={() => {
                setModalVisible(false);
            }}
            center
            styles={{ modal: { borderRadius: 12, maxWidth: 555 } }}
            focusTrapped={false}
        >
            <div className="px-2 mt-10">
                <div className="text-lg ml-4" style={{ color: '#9698A9' }}>
                    {messageMaker()}
                </div>
                <div className="mt-8 flex justify-end">
                    <button
                        className="border rounded-md border-blue-600 text-blue-600 text-sm h-10 px-8 mr-2 focus:outline-none"
                        onClick={() => {
                            setModalVisible(false);
                        }}
                    >
                        Cancel
                    </button>
                    {changeCount !== 0 && (
                        <button
                            className="border bg-blue-600 rounded-md text-white text-sm h-10 leading-4 px-8 ml-2 focus:outline-none hover:text-white hover:bg-blue-800"
                            onClick={async () => {
                                if (certificateId) {
                                    dispatch(
                                        editCertificate(certificateId, certificate, tabStatus)
                                    );
                                } else if (certificates) {
                                    dispatch(bulkUpdateCertificates(certificates, tabStatus));
                                } else if (eventId) {
                                    dispatch(editEvent(eventId, event, tabStatus));
                                } else if (events) {
                                    dispatch(bulkUpdateEvents(events, tabStatus));
                                } else if (badges) {
                                    dispatch(bulkUpdateBadges(badges, tabStatus));
                                } else if (badgeId) {
                                    dispatch(editBadge(badgeId, badge, tabStatus));
                                }

                                setModalVisible(false);
                            }}
                        >
                            {isEditLoading ? (
                                <Loader />
                            ) : isCertificateBulkUpdateLoading ? (
                                <Loader />
                            ) : isEventEditLoading ? (
                                <Loader />
                            ) : isEventBulkUpdateLoading ? (
                                <Loader />
                            ) : isBadgeEditLoading ? (
                                <Loader />
                            ) : isBadgeBulkUpdateLoading ? (
                                <Loader />
                            ) : (
                                'Activate'
                            )}
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
}
