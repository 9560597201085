import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { s3UrlPrefix } from '../../../../../constants';
import { getLeaderboardData } from '../../../../../redux/rootActions';
import { RootState } from '../../../../../redux/rootReducer';
import { USER_PROFILE } from '../../../../utils/storageWeb';

const HorizontalScrollableLeaderboard = styled.div``;

const PublicLeaderBoard = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const leaderboard = useSelector((state: RootState) => state.dashboard.leaderboard);
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));

    useEffect(() => {
        dispatch(getLeaderboardData(null, 0, 100));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="hidden lg:block">
                <div className="bg-purple-85">
                    <div className="flex justify-between">
                        <img
                            className="h-ful"
                            src={`/assets/leaderboardBannerLeft.png`}
                            alt="logo"
                        />
                        <div className="flex justify-center items-center text-center text-2xl-2 font-manrope font-bold">
                            Leaderboard shows the top students who have completed the most <br />
                            number of challenges with a high completion score.
                        </div>
                        <img
                            className="h-full"
                            src={`/assets/leaderboardBannerRight.png`}
                            alt="logo"
                        />
                    </div>
                </div>

                <div className="px-20 xl:px-44 4xl:px-64 my-16">
                    <div className="flex justify-center whitespace-nowrap mb-20">
                        <div>
                            <div>
                                Do you want to see the progress of our students in Future Careers
                                BRIDGE?
                            </div>
                            <div className="flex justify-center mt-6 space-x-6">
                                <div
                                    style={{ backgroundColor: '#4C2EC2' }}
                                    className="rounded font-bold text-white  cursor-pointer h-12 w-56 flex justify-center items-center"
                                    onClick={() => {
                                        history.push(`/dashBoard/leaderboard`);
                                    }}
                                >
                                    {profile !== null ? 'View results' : 'Login to view results'}
                                </div>

                                <div
                                    className="rounded ml-1 font-bold text-purple-950 border-2 border-purple-950 cursor-pointer h-12 w-40 flex justify-center items-center"
                                    onClick={() => {
                                        history.push(`/dashBoard/learn`);
                                    }}
                                >
                                    Start Learning
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="font-semibold font-manrope text-2xl-2 mb-2">
                            Top 100 BRIDGE students
                        </div>
                        <div className="mb-4">
                            Tech companies can observe your progress to provide you with employment
                            opportunities at their organizations.
                        </div>
                        <table
                            style={{ borderCollapse: 'separate', borderSpacing: ' 0 1em' }}
                            className=" w-full"
                        >
                            <thead className="text-sm font-normal">
                                <tr>
                                    <th className="text-center">Rank</th>
                                    <th className=" px-2 text-center">Student ID</th>
                                    <th className=" px-2 text-center">Gender</th>
                                    <th className=" px-2 text-center">Hometown</th>
                                    <th className=" px-2 text-center">No of challenges</th>
                                    <th className=" px-2 text-center">Total Score</th>
                                </tr>
                            </thead>
                            {leaderboard?.map((leaderboard1, index) => (
                                <tbody key={leaderboard1.studentId} className="border-2">
                                    <tr
                                        className="mb-4 py-6 rounded  border-2 border-gray-200 cursor-pointer"
                                        onClick={() => {
                                            window.open(
                                                `${window.location.origin}/public-profile/${leaderboard1.studentId}`,
                                                '_blank'
                                            );
                                        }}
                                    >
                                        <td className=" py-3 ">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                                className="flex flex-row"
                                            >
                                                <div
                                                    style={{
                                                        borderRadius: '100%',
                                                    }}
                                                    className="flex items-center justify-center border-2 w-8 h-8 border-yellow-400 "
                                                >
                                                    {index + 1}
                                                </div>
                                            </div>
                                        </td>
                                        <td className=" py-3 w-64">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'left',
                                                }}
                                                className="flex flex-row ml-20"
                                            >
                                                <img
                                                    className="w-8 h-8 rounded-full mr-2"
                                                    src={`${
                                                        !leaderboard1.imageUrl ||
                                                        !leaderboard1.enablePublicProfile
                                                            ? '/assets/user.png'
                                                            : `${
                                                                  s3UrlPrefix +
                                                                  leaderboard1.imageUrl
                                                              }`
                                                    } `}
                                                    alt="profile"
                                                />
                                                <div className="flex items-center overflow-clip">
                                                    {leaderboard1.studentId}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            {leaderboard1.gender
                                                ? leaderboard1.gender
                                                : 'NOT SPECIFIED	'}
                                        </td>
                                        <td className="text-center">{leaderboard1.hometown}</td>
                                        <td className="text-center">
                                            {leaderboard1.completedChallengeCount}
                                        </td>

                                        <td className="text-purple-950 font-bold ">
                                            <div className="text-center text-xl">
                                                {`${Math.round(leaderboard1?.totalScore * 100)} `}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>
                </div>
            </div>
            <div className="lg:hidden">
                <div>
                    <div className="bg-purple-85 relative">
                        <div className="flex justify-between h-28">
                            <img
                                className="w-1/4 h-full"
                                src={`/assets/leaderboardBannerLeft.png`}
                                alt="logo"
                            />
                            <img
                                className="w-1/4 h-full"
                                src={`/assets/leaderboardBannerRight.png`}
                                alt="logo"
                            />
                        </div>
                        <div
                            className="text-center font-manrope font-bold absolute top-6"
                            style={{ fontSize: 15 }}
                        >
                            Leaderboard shows the top students who have completed the most number of
                            challenges with a high completion score.
                        </div>
                    </div>
                </div>

                <div>
                    <div className="mx-2 flex flex-col flex-wrap mt-5 mb-12">
                        <div className="text-xs text-center">
                            Do you want to see the progress of our students in Future Careers
                            BRIDGE?
                        </div>
                        <div className="flex flex-col items-center justify-center mt-6 gap-y-3 w-full">
                            <div
                                className="rounded text-white bg-purple-950 cursor-pointer h-12 px-4 flex justify-center items-center"
                                onClick={() => {
                                    history.push(`/dashBoard/leaderboard`);
                                }}
                            >
                                {profile !== null ? 'View results' : 'Login to view results'}
                            </div>

                            <div
                                className="rounded text-purple-950 border-2 border-purple-950 cursor-pointer h-12 px-4 flex justify-center items-center"
                                onClick={() => {
                                    history.push(`/dashBoard/learn`);
                                }}
                            >
                                Start Learning
                            </div>
                        </div>
                    </div>

                    <div className="px-6">
                        <div className="font-semibold font-manrope text-lg mb-2">
                            Top 100 BRIDGE students
                        </div>
                        <div className="mb-4 text-sm">
                            Tech companies can observe your progress to provide you with employment
                            opportunities at their organizations.
                        </div>
                        <HorizontalScrollableLeaderboard className="overflow-x-scroll w-11/12 horizontal-scrollable-leaderboard">
                            <table
                                style={{ borderCollapse: 'separate', borderSpacing: ' 0 1em' }}
                                className="w-full table-fixed"
                            >
                                <thead>
                                    <tr className="text-xs">
                                        <th className="p-2 text-center w-10">Rank</th>
                                        <th className="p-2 text-center w-32">Student ID</th>
                                        <th className="p-2 text-center w-20">Gender</th>
                                        <th className="p-2 text-center w-24">Hometown</th>
                                        <th className="p-2 text-center w-20">
                                            Challenges Attempted
                                        </th>
                                        <th className="p-2 text-center w-20">Total Score</th>
                                    </tr>
                                </thead>
                                <tbody className="border-2 text-xs">
                                    {leaderboard?.map((leaderboard1, index) => (
                                        <tr
                                            key={leaderboard1.studentId}
                                            className="h-16 cursor-pointer"
                                            onClick={() => {
                                                window.open(
                                                    `${window.location.origin}/public-profile/${leaderboard1.studentId}`,
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            <td className="text-center">
                                                <div className="flex items-center justify-center h-10 rounded-full border-2 border-yellow-400">
                                                    {index + 1}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className="flex flex-row justify-center items-center">
                                                    <img
                                                        className="w-8 h-8 rounded-full mr-2"
                                                        src={`${
                                                            !leaderboard1.imageUrl ||
                                                            !leaderboard1.enablePublicProfile
                                                                ? '/assets/user.png'
                                                                : `${
                                                                      s3UrlPrefix +
                                                                      leaderboard1.imageUrl
                                                                  }`
                                                        } `}
                                                        alt="profile"
                                                    />
                                                    <div className="flex items-center">
                                                        {leaderboard1.studentId}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                {leaderboard1.gender
                                                    ? leaderboard1.gender
                                                    : 'NOT SPECIFIED	'}
                                            </td>
                                            <td className="text-center">{leaderboard1.hometown}</td>
                                            <td className="text-center">
                                                {leaderboard1.completedChallengeCount}
                                            </td>
                                            <td className="text-center text-purple-950 font-bold">{`${Math.round(
                                                leaderboard1?.totalScore * 100
                                            )} `}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </HorizontalScrollableLeaderboard>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PublicLeaderBoard;
