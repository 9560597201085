import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Loader from '../../../../../assets/svg/Loader';
// import { data } from '../../../components/Pages/Dashboard/RegistrationOverview/RegistrationOverview';
import { getInstitutesPublicData } from '../../../../../redux/rootActions';
import { RootState } from '../../../../../redux/rootReducer';
import { USER_PROFILE } from '../../../../utils/storageWeb';
import InstituteCard from './InstituteCard';

const InstituteAll = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const instituteOpenData = useSelector(
        (state: RootState) => state.institutes.publicInstitutData
    );
    const [filteredInstituteData, setFilteredInstituteData] = useState<any>(instituteOpenData);
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [postPerPage, setPostPerPage] = useState<any>(4);
    const [currentPosts, setCurrentPosts] = useState<any>();
    const [pageNumber1, setpageNumber1] = useState<any>([]);
    const [viewButton, setViewButton] = useState(false);
    const [instituteFilter, setinstituteFilter] = useState<any>(null);

    const pageNumber = [];
    let numberOfPage = 0;

    for (let i = 1; i <= Math.ceil(instituteOpenData.length / postPerPage); i++) {
        pageNumber.push(i);
        numberOfPage = numberOfPage + 1;
    }

    useEffect(() => {
        setpageNumber1([]);
        if (instituteFilter === null) {
            for (let i = 1; i <= Math.ceil(instituteOpenData?.length / postPerPage); i++) {
                setpageNumber1(oldArray => [...oldArray, i]);
                numberOfPage = numberOfPage + 1;
            }
        } else {
            for (
                let i = 1;
                i <=
                Math.ceil(
                    instituteOpenData.filter(data3 => data3.instName[0] === instituteFilter)
                        ?.length / postPerPage
                );
                i++
            ) {
                setpageNumber1(oldArray => [...oldArray, i]);
                numberOfPage = numberOfPage + 1;
            }
        }
    }, [currentPosts]);

    useEffect(() => {
        dispatch(getInstitutesPublicData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (instituteFilter === null) {
            setFilteredInstituteData(instituteOpenData);
        } else {
            setFilteredInstituteData(
                instituteOpenData.filter(data3 => data3.instName[0] === instituteFilter)
            );
        }
    }, [instituteFilter, instituteOpenData]);

    useEffect(() => {
        // setpageNumber1([]);
        for (let i = 1; i <= Math.ceil(filteredInstituteData.length / postPerPage); i++) {
            setpageNumber1([...pageNumber1, i]);
            pageNumber.push(i);
            numberOfPage = numberOfPage + 1;
        }
    }, [filteredInstituteData]);

    const viewInspaginatetitute = (institute: any) => {
        history.push({
            pathname: `/institute/${institute.id}`,
            state: { institute: institute },
        });
    };

    const paginate = number => {
        setCurrentPage(number);
    };

    useEffect(() => {
        const indexOfTheLastPost = currentPage * postPerPage;
        const indexOfTheFirstPost = indexOfTheLastPost - postPerPage;
        setCurrentPosts(filteredInstituteData.slice(indexOfTheFirstPost, indexOfTheLastPost));
    }, [instituteOpenData, currentPage, filteredInstituteData, instituteOpenData]);
    return (
        <>
            <div className="hidden lg:block">
                <div className="bg-purple-85 ">
                    <div className="flex justify-center bg-purple-85">
                        <div className="space-y-5 my-20">
                            <div className=" text-4xl flex justify-center font-semibold">
                                Explore Our Institutes
                            </div>
                            <div className=" text-purple-950 flex justify-center text-xl font-semibold">
                                Explore the pioneers who mold the next generation of Tech Leaders
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-11 ">
                    {instituteOpenData.length > 0 ? (
                        <div
                            style={{
                                flexGrow: 1,
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                            className=" flex justify-center "
                        >
                            {instituteOpenData?.map(institute => (
                                <InstituteCard key={institute.id} institute={institute} />
                            ))}
                        </div>
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
            <div className="lg:hidden">
                <div className="bg-purple-85 ">
                    <div className="flex flex-col bg-purple-85 py-8 px-4 gap-y-3 flex-wrap">
                        <div className="text-lg font-semibold">Explore Our Institutes</div>
                        <div className=" text-purple-950 text-xs">
                            Explore the pioneers who mold the next generation of Tech Leaders
                        </div>
                    </div>
                </div>

                <div className="my-8">
                    {instituteOpenData.length > 0 ? (
                        <div className=" flex flex-col items-center justify-center flex-wrap">
                            {instituteOpenData?.map(institute => (
                                <InstituteCard
                                    key={institute.id}
                                    institute={institute}
                                    isLargeScreen={false}
                                />
                            ))}
                        </div>
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
        </>
    );
};

export default InstituteAll;
