import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { USER_PROFILE } from '../../utils/storage';
import { getEvents, EventsTabCreateType } from '../events/actions';

const PREFIX = '@EVENT_TAB_';

export const ADD_EVENT_TAB = PREFIX + 'ADD_EVENT_TAB';
export const ADD_EVENT_TAB_SUCCESS = PREFIX + 'ADD_EVENT_TAB_SUCCESS';
export const ADD_EVENT_TAB_FAILED = PREFIX + 'ADD_EVENT_TAB_FAILED';
export const GET_EVENT_TAB = PREFIX + 'GET_EVENT_TAB';
export const GET_EVENT_TAB_SUCCESS = PREFIX + 'GET_EVENT_TAB_SUCCESS';
export const GET_EVENT_TAB_FAILED = PREFIX + 'GET_EVENT_TAB_FAILED';

const profile = JSON.parse(localStorage.getItem(USER_PROFILE));

export const getEventTabs = (id?: string, association?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_EVENT_TAB });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/users/admins/filter?id=${id}&association=${association}`,
            config
        );
        dispatch({
            type: GET_EVENT_TAB_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_EVENT_TAB_FAILED, payload: getErrorMessage(error) });
    }
};

export const addEventsTab = (tab: EventsTabCreateType) => async (dispatch: any) => {
    try {
        if (tab?.startDate === null || tab?.startDate === undefined) {
            delete tab.startDate;
        }
        if (tab?.eventTypes === null || tab?.eventTypes === undefined) {
            delete tab.eventTypes;
        }
        dispatch({ type: ADD_EVENT_TAB });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/events/filter`, tab, config);
        await dispatch(getEventTabs(profile?.userId, 'EVENT'));
        dispatch({ type: ADD_EVENT_TAB_SUCCESS, payload: res.data.responseDto.data });

        if (!res?.data?.status) {
            dispatch({
                type: ADD_EVENT_TAB_FAILED,
                payload: res.data.errorDescription,
            });
        }
    } catch (error) {
        dispatch({ type: ADD_EVENT_TAB_FAILED, payload: getErrorMessage(error) });
    }
};
