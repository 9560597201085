import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { USER_PROFILE } from '../../../utils/storageWeb';
import { useDispatch, useSelector } from 'react-redux';
import {
    sendEmailVerification,
    SEND_EMAIL_VERIFICATION_RESET,
} from '../../../../redux/rootActions';
import { RootState } from '../../../../redux/rootReducer';

const ChangeEmailFeedback = props => {
    const history = useHistory();
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const { email } = (props.location && props.location.state) || {};
    const sendEmailVerificationSuccess = useSelector(
        (state: RootState) => state.webUser.sendEmailVerificationSuccessMessage
    );
    const [resent, setResent] = useState(false);

    const settingeURL = '/dashBoard/settings';
    const resend = useCallback(() => {
        if (!resent) {
            dispatch(sendEmailVerification(profile?.userId, profile?.userEmail, email));
            setResent(true);
            setTimeout(() => {
                setResent(false);
            }, 4000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resent]);
    useEffect(() => {
        if (sendEmailVerificationSuccess) {
            dispatch({ type: SEND_EMAIL_VERIFICATION_RESET });
        }
    }, [dispatch, sendEmailVerificationSuccess]);
    return (
        <>
            <div className="flex w-full h-screen items-center justify-center">
                <div className="flex flex-col p-4 w-full lg:w-1/3">
                    <div className="flex text-xl lg:text-2xl font-semibold justify-center">
                        Check your Mail
                    </div>
                    <div className="mt-4 text-center lg:text-left">
                        There is a pending change on your email. We have sent a verification to your
                        new email, <span className="text-purple-950">{`"${email || ''}"`}</span>
                    </div>
                    <div
                        className={`flex mt-4 ${
                            resent ? 'text-green-400' : 'text-blue-800'
                        } justify-center cursor-pointer`}
                        onClick={resend}
                    >
                        {resent ? 'Confirmation Email Resent !' : 'Resend Verification Email'}
                    </div>
                    <div
                        className={`flex mt-4 text-purple-950 justify-center cursor-pointer ${
                            resent && 'opacity-50'
                        }`}
                        onClick={() =>
                            !resent &&
                            history.push({
                                pathname: settingeURL,
                                state: {
                                    selectedTab: 'security',
                                },
                            })
                        }
                    >
                        Return to Account Security
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangeEmailFeedback;
