import React, { useState, useEffect } from 'react';

import UpArrow from '../../../../../assets/svg/UpArrow';
import DownArrow from '../../../../../assets/svg/DownArrow';
import ShortAnswerField from './ShortAnswerField';
import LongAnswerField from './LongAnswerField';
import { getPresignedUrlChallengeFile } from '../../../../../redux/rootActions';
import { useDispatch } from 'react-redux';
import Loader from '../../../../../assets/svg/Loader';
import StartChallengeModal from '../../../../../components/modals/StartChallengeModal';
import SubmitChallenge from '../../../../modals/SubmitChallengeModal';
import { useHistory } from 'react-router-dom';
import useExitPrompt from '../../../../hooks/useExitPrompt';
import useWidth from '../../../../hooks/useWidth';
import parse from 'html-react-parser';
import { getDecryptedData } from '../../../../../utils/decrypt';

interface Props {
    taskSectionList: any;
    section: any;
    sectionIndex: number;
    selectedSection: number;
    selectedSectionHandler: (index: number) => void;
    updateTaskSectionListHandler: (data) => void;
    submitTaskHandler: (taskData, isMcqType) => void;
    challengeStarted: boolean;
    setMessage: (value: string) => void;
    challengeData: any;
    setChallengeStarted: (value: any) => void;
    setHasChallengeStarted: (value: any) => void;
    setChallengeStartedMessage: (value: any) => void;
    setVideoSection: (value: any) => void;
    totalChallengePoints: any;
    setChallengeOngoing: (value: boolean) => void;
}

const Index: React.FC<Props> = ({
    taskSectionList,
    section,
    sectionIndex,
    selectedSection,
    selectedSectionHandler,
    updateTaskSectionListHandler,
    submitTaskHandler,
    challengeStarted,
    setMessage,
    challengeData,
    setChallengeStarted,
    setHasChallengeStarted,
    setChallengeStartedMessage,
    setVideoSection,
    totalChallengePoints,
    setChallengeOngoing,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [taskSectionRefList, setTaskSectionRefList] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [getPresignedUrlLoading, setGetPresignedUrlLoading] = useState(false);

    const [modalVisible, setModalVisible] = useState(false);
    const [submitModalVisible, setSubmitModalVisible] = useState(false);

    const [showExitPrompt, setShowExitPrompt] = useState(false);
    const [isMcq, setIsMcq] = useState(false);
    const [fileUploadError, setFileUploadError] = useState<boolean>(false);

    const [submittedAnswers, setSubmittedAnswers] = useState<any>(null);

    const [correctAnswers, setCorrectAnswers] = useState<any>();
    const [submittedOption, setSubmittedOption] = useState<any>();

    function preventReload(event) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
            e.returnValue = '';
        }
        return '';
    }

    useEffect(() => {
        if (showExitPrompt) {
            window.addEventListener('beforeunload', preventReload);
        }
    }, [showExitPrompt]);

    const viewPortWidth = useWidth();
    useEffect(() => {
        setTaskSectionRefList(taskSectionList);
    }, [taskSectionList]);

    const handleAnswerSelection = (questionIndex, answerIndex) => {
        setIsMcq(true);
        const tempRef = taskSectionRefList;
        const sec = tempRef[sectionIndex]; // Refers to section
        const allQuestions = sec?.subsections?.filter(quest => quest.type !== 'paragraph');
        const question = sec?.subsections[questionIndex];
        const allAnswers = question?.values;

        const updatedAnwers = [];
        allAnswers.forEach((answer, idx) => {
            let inputAnswer;
            if (answerIndex === idx) {
                inputAnswer = {
                    ...answer,
                    selected: true,
                };
            } else {
                inputAnswer = {
                    ...answer,
                    selected: false,
                };
            }
            updatedAnwers.push(inputAnswer);
        });

        const sectionToReplace = tempRef[sectionIndex]?.subsections[questionIndex]?.values;
        Object.assign(sectionToReplace, updatedAnwers);

        // Check if all of the questions under the section has been anwered to enable the submit button
        allQuestions?.forEach((quest, idx) => {
            quest?.values?.forEach(ans => {
                if (ans?.selected === true) {
                    // eslint-disable-next-line
                    allQuestions[idx]['answered'] = true;
                }
            });
        });

        const isSubmitReady = allQuestions.every(data => data?.answered || data?.fileSelected);
        setChallengeOngoing(true);
        setShowExitPrompt(true);
        if (isSubmitReady) {
            setIsSubmitDisabled(false);
        }

        updateTaskSectionListHandler(tempRef);
    };
    const handleSelectFile = async (ev, questionIndex, type) => {
        setFileUploadError(false);
        setIsMcq(false);
        let file;
        if (type === 'select') {
            if (ev?.target?.files[0]?.size > Math.pow(2, 20) * 100) {
                setFileUploadError(true);
                return;
            }
            uploadedFiles[questionIndex] = ev.target.files[0];
            file = ev.target.files[0];
        } else {
            if (ev.dataTransfer.files[0].size > Math.pow(2, 20) * 100) {
                setFileUploadError(true);
                return;
            }
            uploadedFiles[questionIndex] = ev.dataTransfer.files[0];
            file = ev.dataTransfer.files[0];
        }

        setGetPresignedUrlLoading(true);
        const location = await dispatch(getPresignedUrlChallengeFile(false, file?.name, file));

        const locationArray = location?.toString().split('/');
        const fileLocation = `assessment/${locationArray?.[4]}/${
            locationArray?.[5]?.split('?')?.[0]
        }`;

        const tempRef = taskSectionRefList;
        const allQuestions = tempRef[sectionIndex]?.subsections;

        // eslint-disable-next-line
        allQuestions[questionIndex]['fileSelected'] = true;
        // eslint-disable-next-line
        allQuestions[questionIndex]['answer'] = fileLocation;
        const sectionToReplace = tempRef[sectionIndex]?.subsections[questionIndex];
        Object.assign(sectionToReplace, allQuestions[questionIndex]);

        setGetPresignedUrlLoading(false);
        const isSubmitReady = allQuestions
            ?.filter(quest => quest.type !== 'paragraph')
            .every(data => data?.answered || data?.fileSelected);
        setChallengeOngoing(true);
        setShowExitPrompt(true);
        if (isSubmitReady) {
            setIsSubmitDisabled(false);
        }

        updateTaskSectionListHandler(tempRef);
    };

    const dropHandler = (ev, questionIndex) => {
        ev.preventDefault();
        if (ev.dataTransfer.items && ev.dataTransfer.items[0].kind === 'file') {
            handleSelectFile(ev, questionIndex, 'drop');
        }
    };

    const dragOverHandler = ev => {
        ev.stopPropagation();
        ev.preventDefault();
    };

    useEffect(() => {
        const unblock = history.block((): any => {
            if (showExitPrompt) {
                const res = window.confirm(
                    'Are you sure you want to abandon the task you are working on?'
                );
                if (res) {
                    setShowExitPrompt(false);
                    window.removeEventListener('beforeunload', preventReload);
                }
                return res;
            }
            return true;
        });

        return () => {
            unblock();
        };
    }, [showExitPrompt]);

    const handleTextAnswers = (questionIndex, question, answer, type) => {
        setIsMcq(false);

        const tempRef = taskSectionRefList;
        const updatedAnswers = question;
        const allQuestions = tempRef[sectionIndex]?.subsections?.filter(
            quest => quest.type !== 'paragraph'
        );

        // eslint-disable-next-line dot-notation
        updatedAnswers['answer'] = answer;
        // eslint-disable-next-line dot-notation
        updatedAnswers['answered'] = true;

        const sectionToReplace = tempRef[sectionIndex]?.subsections[questionIndex];
        Object.assign(sectionToReplace, updatedAnswers);

        const isSubmitReady = allQuestions.every(data => data?.answered || data?.fileSelected);
        setChallengeOngoing(true);
        setShowExitPrompt(true);
        if (type === 's' && answer.length > 0 && isSubmitReady) {
            setIsSubmitDisabled(false);
        } else if (type === 'l' && answer.length > 2 && isSubmitReady) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }

        updateTaskSectionListHandler(tempRef);
    };

    const submit = () => {
        if (taskSectionList && selectedSection !== -1) {
            const answers = taskSectionList[selectedSection].subsections;
            const taskNo = taskSectionList[selectedSection].taskNo;

            const taskData = {
                task_no: taskNo,
                answers: answers,
            };
            setChallengeOngoing(false);
            submitTaskHandler(taskData, isMcq);
            setIsSubmitDisabled(true);
        }
    };

    const openSubmitModal = () => {
        setSubmitModalVisible(true);
    };

    const setOnClickSuccess = () => {
        setChallengeStarted(true);
        setHasChallengeStarted(true);
        setChallengeStartedMessage(
            `Welcome to the ${challengeData?.challengeName} challenge . You just have to complete the below steps to score ${totalChallengePoints}XP points . Good luck!`
        );
        // setVideoSection(true); // open the video section
        selectedSectionHandler(sectionIndex);
    };

    useEffect(() => {
        if (
            challengeData?.lastSubmissions
                ?.map(
                    submission =>
                        getDecryptedData(submission?.encryptedAnswers)?.answers[0]?.type ===
                        'radio-group'
                )
                .includes(true)
        ) {
            const answersList = challengeData?.lastSubmissions?.map(submission =>
                getDecryptedData(submission?.encryptedAnswers)
            );

            setCorrectAnswers(
                answersList
                    ?.filter(submission => submission?.answers[0]?.type === 'radio-group')
                    ?.map(submission =>
                        submission?.answers
                            .map(value => value?.answer)
                            ?.map(answer => answer.slice(0, -13))
                    )
            );
            setSubmittedAnswers(
                answersList
                    ?.filter(submission => submission?.answers[0]?.type === 'radio-group')
                    ?.map(submission =>
                        submission?.answers
                            .map(value =>
                                value?.values
                                    ?.filter(select => select.selected === true)
                                    .map(label => label.label)
                            )
                            ?.map(answer => answer)
                    )
            );
            setSubmittedOption(
                answersList
                    ?.filter(submission => submission?.answers[0]?.type === 'radio-group')
                    ?.map(submission =>
                        submission?.answers
                            .map(value =>
                                value?.values
                                    ?.filter(select => select.selected === true)
                                    .map(label => label.value)
                            )
                            ?.map(answer => answer)
                    )
            );
        }
    }, [challengeData]);

    useEffect(() => {
        let questionNumber = 0;
        section?.subsections.forEach((subsection, index) => {
            if (subsection?.type === 'paragraph') {
                questionNumber = 0;
            } else {
                subsection = Object.assign(subsection, {
                    qNumber: questionNumber + 1,
                });
                questionNumber++;
            }
        });
    }, [section]);

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div
                    style={{
                        background: selectedSection === sectionIndex && '#F5F3FC',
                    }}
                    className={`mt-6 flex flex-col ${
                        selectedSection === sectionIndex ? 'bg-purple-100' : 'border-2 rounded'
                    }`}
                >
                    <SubmitChallenge
                        showModal={submitModalVisible}
                        setShowModal={setSubmitModalVisible}
                        setOnClickSubmit={() => submit()}
                    />
                    <StartChallengeModal
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                        challengeData={challengeData}
                        setOnClickSuccess={setOnClickSuccess}
                        setSubmittedAnswers={setSubmittedAnswers}
                        selectedSection={selectedSection}
                        selectedSectionHandler={selectedSectionHandler}
                    />
                    <div
                        className={`flex items-center h-14 lg:h-24 justify-between cursor-pointer ${
                            selectedSection === sectionIndex && `border-b-2`
                        }`}
                        onClick={e => {
                            if ((e.target as HTMLInputElement).id === 'challenge_try_again') {
                                return;
                            }
                            // removing if statement to enable expanding functionality by clicking header bar.
                            // if (challengeStarted) {
                            setIsSubmitDisabled(true);
                            if (selectedSection === sectionIndex) {
                                selectedSectionHandler(-1);
                            } else {
                                selectedSectionHandler(sectionIndex);
                            }
                            // } else {
                            // alert('Start the challenge by clicking continue!');
                            // setModalVisible(true);
                            // }
                        }}
                    >
                        <div className="flex items-center mx-8 w-full justify-between">
                            <div className={`text-xs lg:text-base font-bold`}>{section.title}</div>
                            {section.isCompleted && (
                                <div className="flex justify-center items-center">
                                    <div
                                        id="challenge_try_again"
                                        onClick={() => {
                                            if (challengeStarted) {
                                                selectedSectionHandler(sectionIndex);
                                                setSubmittedAnswers(null);
                                            } else {
                                                // alert('Start the challenge by clicking continue!');
                                                setModalVisible(true);
                                            }
                                        }}
                                        className="text-sm font-normal text-green-600 underline cursor-pointer mr-3"
                                    >
                                        Try Again
                                    </div>
                                    <div className="text-sm font-normal text-purple-950 py-1 px-2 rounded bg-purple-100">
                                        Completed
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            onClick={() => {
                                if (selectedSection === sectionIndex) {
                                    selectedSectionHandler(-1);
                                } else {
                                    selectedSectionHandler(sectionIndex);
                                }
                            }}
                            className="p-3 rounded-full hover:bg-purple-100 hover:text-blue-900 cursor-pointer mr-4"
                        >
                            <div>
                                {selectedSection === sectionIndex ? <UpArrow /> : <DownArrow />}
                            </div>
                        </div>
                    </div>
                    {selectedSection === sectionIndex &&
                        section &&
                        section?.subsections?.map((question, questionIndex) => {
                            if (question?.type === 'radio-group') {
                                setMessage('Successfully completed the questions!!');
                                return (
                                    <div key={questionIndex} className="mx-16 mb-4 mt-4">
                                        <div className="flex justify-between mb-4">
                                            <div className="flex font-semibold">
                                                <div className="mr-12">{question.qNumber}</div>
                                                <div>{parse(question?.label)}</div>
                                            </div>
                                            {question?.points && (
                                                <div className="text-purple-950 font-medium min-w-max pl-2">
                                                    {`${question?.points} XP`}
                                                </div>
                                            )}
                                        </div>

                                        <div className="ml-14 space-y-2">
                                            {question?.values?.map((answer, answerIndex) => (
                                                <div key={answerIndex} className="space-x-2">
                                                    {submittedAnswers ? (
                                                        section?.subsections?.length ===
                                                        submittedAnswers?.flat(2).length ? (
                                                            <input
                                                                className={`${
                                                                    correctAnswers?.flat(2)[
                                                                        questionIndex
                                                                    ] ===
                                                                    submittedOption?.flat(2)[
                                                                        questionIndex
                                                                    ]
                                                                        ? 'mqc-radio-botton'
                                                                        : 'mqc-wrong-radio-botton'
                                                                } cursor-pointer focus:outline-none`}
                                                                type="radio"
                                                                id={
                                                                    questionIndex + '' + answerIndex
                                                                }
                                                                name={question?.name}
                                                                value={answer?.value}
                                                                checked={
                                                                    answer?.label ===
                                                                    submittedAnswers?.flat(2)[
                                                                        questionIndex
                                                                    ]
                                                                }
                                                                onChange={() => {
                                                                    handleAnswerSelection(
                                                                        questionIndex,
                                                                        answerIndex
                                                                    );
                                                                }}
                                                            />
                                                        ) : (
                                                            <input
                                                                className={`${
                                                                    correctAnswers?.flat(2)[
                                                                        questionIndex -
                                                                            (section?.subsections
                                                                                ?.length -
                                                                                submittedAnswers?.flat(
                                                                                    2
                                                                                )?.length)
                                                                    ] ===
                                                                    submittedOption?.flat(2)[
                                                                        questionIndex -
                                                                            (section?.subsections
                                                                                ?.length -
                                                                                submittedAnswers?.flat(
                                                                                    2
                                                                                )?.length)
                                                                    ]
                                                                        ? 'mqc-radio-botton'
                                                                        : 'mqc-wrong-radio-botton'
                                                                } cursor-pointer focus:outline-none`}
                                                                type="radio"
                                                                id={
                                                                    questionIndex + '' + answerIndex
                                                                }
                                                                name={question?.name}
                                                                value={answer?.value}
                                                                checked={
                                                                    answer?.label ===
                                                                    submittedAnswers?.flat(2)[
                                                                        questionIndex -
                                                                            (section?.subsections
                                                                                ?.length -
                                                                                submittedAnswers?.flat(
                                                                                    2
                                                                                )?.length)
                                                                    ]
                                                                }
                                                                onChange={() => {
                                                                    handleAnswerSelection(
                                                                        questionIndex,
                                                                        answerIndex
                                                                    );
                                                                }}
                                                            />
                                                        )
                                                    ) : (
                                                        <>
                                                            <input
                                                                className="mqc-radio-botton cursor-pointer focus:outline-none"
                                                                type="radio"
                                                                id={
                                                                    questionIndex + '' + answerIndex
                                                                }
                                                                name={question?.name}
                                                                value={answer?.value}
                                                                onChange={() => {
                                                                    handleAnswerSelection(
                                                                        questionIndex,
                                                                        answerIndex
                                                                    );
                                                                }}
                                                            />
                                                        </>
                                                    )}

                                                    <label
                                                        htmlFor={questionIndex + '' + answerIndex}
                                                        className="pl-2.5"
                                                    >
                                                        {answer?.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            } else if (question?.type === 'file') {
                                setMessage('Your file has been successfully uploaded!');
                                return (
                                    <div key={questionIndex} className="mx-16 mb-4 mt-4">
                                        <div className="flex justify-between mb-4">
                                            <div className="flex font-semibold">
                                                <div className="mr-12">{question.qNumber}</div>
                                                <div>{parse(question?.label)}</div>
                                            </div>
                                            {question?.points && (
                                                <div className="text-purple-950 font-medium min-w-max pl-2">
                                                    {`${question?.points} XP`}
                                                </div>
                                            )}
                                        </div>
                                        <div className="ml-14 space-y-2">
                                            <label className="cursor-pointer" htmlFor="files2">
                                                <div className="border-dashed border-2 border-purple-950 bg-purple-100 flex items-center justify-center h-64 max-w-md">
                                                    <div
                                                        id="drop_zone"
                                                        onDrop={ev =>
                                                            dropHandler(ev, questionIndex)
                                                        }
                                                        onDragOver={dragOverHandler}
                                                        className="flex justify-center items-center flex-col"
                                                    >
                                                        <img
                                                            className={
                                                                uploadedFiles[questionIndex]
                                                                    ? 'h-8 w-7'
                                                                    : 'h-9 w-10'
                                                            }
                                                            src={
                                                                uploadedFiles[questionIndex]
                                                                    ? '/assets/uploaded.png'
                                                                    : '/assets/upload.png'
                                                            }
                                                            alt="upload vector"
                                                        />
                                                        {!uploadedFiles[questionIndex] && (
                                                            <div
                                                                className={`text-sm ${
                                                                    fileUploadError
                                                                        ? `text-red-700`
                                                                        : `text-purple-300`
                                                                } mb-1 mt-3`}
                                                            >
                                                                Max file size 100 MB
                                                            </div>
                                                        )}
                                                        <label
                                                            htmlFor={questionIndex}
                                                            className={`text-sm  mb-2 ${
                                                                uploadedFiles[questionIndex]
                                                                    ? `mt-3 text-purple-950 cursor-pointer hover:underline`
                                                                    : `text-purple-300`
                                                            }`}
                                                        >
                                                            {uploadedFiles[questionIndex]
                                                                ? uploadedFiles[questionIndex]?.name
                                                                : 'Any type of file'}
                                                        </label>
                                                        {!uploadedFiles[questionIndex] && (
                                                            <>
                                                                <label
                                                                    htmlFor={questionIndex}
                                                                    className="text-purple-950 font-semibold cursor-pointer hover:underline"
                                                                >
                                                                    Drag and drop or Browse your
                                                                    files
                                                                </label>
                                                            </>
                                                        )}
                                                        <input
                                                            id={questionIndex}
                                                            style={{ display: 'none' }}
                                                            name={question?.name}
                                                            type="file"
                                                            onChange={e => {
                                                                handleSelectFile(
                                                                    e,
                                                                    questionIndex,
                                                                    'select'
                                                                );
                                                            }}
                                                        />
                                                        {getPresignedUrlLoading && (
                                                            <div className="flex items-center">
                                                                <div className="mr-2">
                                                                    Uploading
                                                                </div>
                                                                <div>
                                                                    <Loader />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                );
                            } else if (question?.type === 'text') {
                                setMessage(
                                    'You have successfully submitted the answer to the question!'
                                );
                                return (
                                    <div key={questionIndex} className="mx-16 mb-4 mt-4">
                                        <div className="flex justify-between mb-4">
                                            <div className="flex font-semibold">
                                                <div className="mr-12">{question.qNumber}</div>
                                                <div>{parse(question?.label)}</div>
                                            </div>
                                            {question?.points && (
                                                <div className="text-purple-950 font-medium min-w-max pl-2">
                                                    {`${question?.points} XP`}
                                                </div>
                                            )}
                                        </div>
                                        <ShortAnswerField
                                            questionIndex={questionIndex}
                                            question={question}
                                            handleShortAnswers={handleTextAnswers}
                                        />
                                    </div>
                                );
                            } else if (question?.type === 'textarea') {
                                setMessage(
                                    'You have successfully submitted the answer to the question!'
                                );
                                return (
                                    <div key={questionIndex} className="mx-16 mb-4 mt-4">
                                        <div className="flex justify-between mb-4">
                                            <div className="flex font-semibold">
                                                <div className="mr-12">{question.qNumber}</div>
                                                <div>{parse(question?.label)}</div>
                                            </div>
                                            {question?.points && (
                                                <div className="text-purple-950 font-medium min-w-max pl-2">
                                                    {`${question?.points} XP`}
                                                </div>
                                            )}
                                        </div>
                                        <div className="ml-14 space-y-2">
                                            <LongAnswerField
                                                questionIndex={questionIndex}
                                                question={question}
                                                handleLongAnswers={handleTextAnswers}
                                            />
                                        </div>
                                    </div>
                                );
                            } else if (question?.type === 'paragraph') {
                                return (
                                    <div key={questionIndex} className="mx-16 mb-4 mt-4">
                                        <div className="flex justify-between mb-4">
                                            <div className="flex pb-2 border-black">
                                                <div>{parse(question?.label)}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })}
                    {selectedSection === sectionIndex && section && (
                        <div className="flex justify-end">
                            <button
                                disabled={isSubmitDisabled}
                                onClick={() => {
                                    openSubmitModal();
                                }}
                                className={`mb-4 mr-16 rounded border-2 font-semibold w-44 h-10 justify-center items-center flex ${
                                    isSubmitDisabled
                                        ? `cursor-not-allowed text-white bg-gray-400 `
                                        : `cursor-pointer hover:text-white hover:bg-purple-950 text-purple-950 border-purple-950`
                                }`}
                            >
                                Submit
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        background: selectedSection === sectionIndex && '#F5F3FC',
                    }}
                    className={`mt-6 flex flex-col ${
                        selectedSection === sectionIndex ? 'bg-purple-100' : 'border-2 rounded'
                    }`}
                >
                    <SubmitChallenge
                        showModal={submitModalVisible}
                        setShowModal={setSubmitModalVisible}
                        setOnClickSubmit={() => submit()}
                    />
                    <StartChallengeModal
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                        challengeData={challengeData}
                        setOnClickSuccess={setOnClickSuccess}
                        setSubmittedAnswers={setSubmittedAnswers}
                        selectedSection={selectedSection}
                        selectedSectionHandler={selectedSectionHandler}
                    />
                    <div
                        className={`flex items-center py-4 justify-between cursor-pointer ${
                            selectedSection === sectionIndex && `border-b-2`
                        }`}
                        onClick={e => {
                            if ((e.target as HTMLInputElement).id === 'challenge_try_again') {
                                return;
                            }
                            // removing if statement to enable expanding functionality by clicking header bar.
                            // if (challengeStarted) {
                            setIsSubmitDisabled(true);
                            if (selectedSection === sectionIndex) {
                                selectedSectionHandler(-1);
                            } else {
                                selectedSectionHandler(sectionIndex);
                            }
                            // } else {
                            // alert('Start the challenge by clicking continue!');
                            // setModalVisible(true);
                            // }
                        }}
                        style={{ minHeight: '14' }}
                    >
                        <div className="flex items-center ml-4 mr-2 w-full justify-between gap-x-2">
                            <div className={`text-xs lg:text-base font-bold`}>{section.title}</div>
                            {section.isCompleted && (
                                <div className="flex justify-center items-center">
                                    <div
                                        id="challenge_try_again"
                                        onClick={() => {
                                            if (challengeStarted) {
                                                selectedSectionHandler(sectionIndex);
                                                setSubmittedAnswers(null);
                                            } else {
                                                // alert('Start the challenge by clicking continue!');
                                                setModalVisible(true);
                                            }
                                        }}
                                        className="text-xs font-normal text-green-600 underline cursor-pointer mr-3"
                                    >
                                        Try Again
                                    </div>
                                    <div className="text-xs font-normal text-purple-950 py-1 px-2 rounded bg-purple-100">
                                        Completed
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            onClick={() => {
                                if (selectedSection === sectionIndex) {
                                    selectedSectionHandler(-1);
                                } else {
                                    selectedSectionHandler(sectionIndex);
                                }
                            }}
                            className="p-1 rounded-full hover:bg-purple-100 hover:text-blue-900 cursor-pointer mr-4"
                        >
                            <div>
                                {selectedSection === sectionIndex ? <UpArrow /> : <DownArrow />}
                            </div>
                        </div>
                    </div>
                    {selectedSection === sectionIndex &&
                        section &&
                        section?.subsections?.map((question, questionIndex) => {
                            if (question?.type === 'radio-group') {
                                setMessage('Successfully completed the questions!!');
                                return (
                                    <div key={questionIndex} className="mx-4 mb-4 mt-4">
                                        <div className="flex justify-between mb-4 text-sm">
                                            <div className="flex font-semibold">
                                                <div className="mr-6">{question.qNumber}</div>
                                                <div>{parse(question?.label)}</div>
                                            </div>
                                            {question?.points && (
                                                <div className="text-purple-950 font-medium min-w-max pl-2">
                                                    {`${question?.points} XP`}
                                                </div>
                                            )}
                                        </div>

                                        <div className="ml-4 space-y-2">
                                            {question?.values?.map((answer, answerIndex) => (
                                                <div key={answerIndex} className="space-x-2">
                                                    {submittedAnswers ? (
                                                        <input
                                                            // className="mqc-radio-botton cursor-pointer focus:outline-none"
                                                            className={`${
                                                                correctAnswers?.flat(2)[
                                                                    questionIndex
                                                                ] ===
                                                                submittedOption?.flat(2)[
                                                                    questionIndex
                                                                ]
                                                                    ? 'mqc-radio-botton'
                                                                    : 'mqc-wrong-radio-botton'
                                                            } cursor-pointer focus:outline-none`}
                                                            type="radio"
                                                            id={questionIndex + '' + answerIndex}
                                                            name={question?.name}
                                                            checked={
                                                                answer?.label ===
                                                                submittedAnswers?.flat(2)[
                                                                    questionIndex
                                                                ]
                                                            }
                                                            value={answer?.value}
                                                            onChange={() => {
                                                                handleAnswerSelection(
                                                                    questionIndex,
                                                                    answerIndex
                                                                );
                                                            }}
                                                        />
                                                    ) : (
                                                        <>
                                                            <input
                                                                className="mqc-radio-botton cursor-pointer focus:outline-none"
                                                                type="radio"
                                                                id={
                                                                    questionIndex + '' + answerIndex
                                                                }
                                                                name={question?.name}
                                                                value={answer?.value}
                                                                onChange={() => {
                                                                    handleAnswerSelection(
                                                                        questionIndex,
                                                                        answerIndex
                                                                    );
                                                                }}
                                                            />
                                                        </>
                                                    )}

                                                    <label
                                                        htmlFor={questionIndex + '' + answerIndex}
                                                        className="pl-2.5 text-sm"
                                                    >
                                                        {answer?.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            } else if (question?.type === 'file') {
                                setMessage('Your file has been successfully uploaded!');
                                return (
                                    <div key={questionIndex} className="mx-4 mb-4 mt-4">
                                        <div className="flex justify-between mb-4 text-sm">
                                            <div className="flex font-semibold">
                                                <div className="mr-4">{question.qNumber}</div>
                                                <div>{parse(question?.label)}</div>
                                            </div>
                                            {question?.points && (
                                                <div className="text-purple-950 font-medium min-w-max pl-2">
                                                    {`${question?.points} XP`}
                                                </div>
                                            )}
                                        </div>
                                        <div className="ml-4 space-y-2">
                                            <label className="cursor-pointer" htmlFor="files2">
                                                <div className="border-dashed border-2 border-purple-950 bg-purple-100 flex items-center justify-center h-36 max-w-md">
                                                    <div
                                                        id="drop_zone"
                                                        onDrop={ev =>
                                                            dropHandler(ev, questionIndex)
                                                        }
                                                        onDragOver={dragOverHandler}
                                                        className="flex justify-center items-center flex-col text-xs"
                                                    >
                                                        <img
                                                            className={
                                                                uploadedFiles[questionIndex]
                                                                    ? 'h-8 w-7'
                                                                    : 'h-9 w-10'
                                                            }
                                                            src={
                                                                uploadedFiles[questionIndex]
                                                                    ? '/assets/uploaded.png'
                                                                    : '/assets/upload.png'
                                                            }
                                                            alt="upload vector"
                                                        />
                                                        {!uploadedFiles[questionIndex] && (
                                                            <div
                                                                className={`text-sm ${
                                                                    fileUploadError
                                                                        ? `text-red-700`
                                                                        : `text-purple-300`
                                                                } mb-1 mt-3`}
                                                            >
                                                                Max file size 100 MB
                                                            </div>
                                                        )}
                                                        <label
                                                            htmlFor={questionIndex}
                                                            className={`mb-2 ${
                                                                uploadedFiles[questionIndex]
                                                                    ? `mt-3 text-purple-950 cursor-pointer hover:underline`
                                                                    : `text-purple-300`
                                                            }`}
                                                        >
                                                            {uploadedFiles[questionIndex]
                                                                ? uploadedFiles[questionIndex]?.name
                                                                : 'Any type of file'}
                                                        </label>
                                                        {!uploadedFiles[questionIndex] && (
                                                            <>
                                                                <label
                                                                    htmlFor={questionIndex}
                                                                    className="text-purple-950 font-semibold cursor-pointer hover:underline"
                                                                >
                                                                    Drag and drop or Browse your
                                                                    files
                                                                </label>
                                                            </>
                                                        )}
                                                        <input
                                                            id={questionIndex}
                                                            style={{ display: 'none' }}
                                                            name={question?.name}
                                                            type="file"
                                                            onChange={e => {
                                                                handleSelectFile(
                                                                    e,
                                                                    questionIndex,
                                                                    'select'
                                                                );
                                                            }}
                                                        />
                                                        {getPresignedUrlLoading && (
                                                            <div className="flex items-center">
                                                                <div className="mr-2">
                                                                    Uploading
                                                                </div>
                                                                <div>
                                                                    <Loader />
                                                                </div>
                                                            </div>
                                                        )}{' '}
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                );
                            } else if (question?.type === 'text') {
                                setMessage(
                                    'You have successfully submitted the answer to the question!'
                                );
                                return (
                                    <div key={questionIndex} className="mx-4 mb-4 mt-4">
                                        <div className="flex justify-between mb-4 text-sm">
                                            <div className="flex font-semibold">
                                                <div className="mr-6">{question.qNumber}</div>
                                                <div>{parse(question?.label)}</div>
                                            </div>
                                            {question?.points && (
                                                <div className="text-purple-950 font-medium min-w-max pl-2">
                                                    {`${question?.points} XP`}
                                                </div>
                                            )}
                                        </div>
                                        <ShortAnswerField
                                            questionIndex={questionIndex}
                                            question={question}
                                            handleShortAnswers={handleTextAnswers}
                                        />
                                    </div>
                                );
                            } else if (question?.type === 'textarea') {
                                setMessage(
                                    'You have successfully submitted the answer to the question!'
                                );
                                return (
                                    <div key={questionIndex} className="mx-4 mb-4 mt-4">
                                        <div className="flex justify-between mb-4 text-sm">
                                            <div className="flex font-semibold">
                                                <div className="mr-6">{question.qNumber}</div>
                                                <div>{parse(question?.label)}</div>
                                            </div>
                                            {question?.points && (
                                                <div className="text-purple-950 font-medium min-w-max pl-2">
                                                    {`${question?.points} XP`}
                                                </div>
                                            )}
                                        </div>
                                        <div className="ml-4 space-y-2">
                                            <LongAnswerField
                                                questionIndex={questionIndex}
                                                question={question}
                                                handleLongAnswers={handleTextAnswers}
                                            />
                                        </div>
                                    </div>
                                );
                            } else if (question?.type === 'paragraph') {
                                return (
                                    <div key={questionIndex} className="mx-16 mb-4 mt-4">
                                        <div className="flex justify-between mb-4">
                                            <div className="flex pb-2 border-black">
                                                <div>{parse(question?.label)}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })}
                    {selectedSection === sectionIndex && section && (
                        <div className="flex justify-end mt-4">
                            <button
                                disabled={isSubmitDisabled}
                                onClick={() => {
                                    openSubmitModal();
                                }}
                                className={`mb-4 mr-16 rounded border-2 font-semibold w-44 h-10 justify-center items-center flex ${
                                    isSubmitDisabled
                                        ? `cursor-not-allowed text-white bg-gray-400 `
                                        : `cursor-pointer hover:text-white hover:bg-purple-950 text-purple-950 border-purple-950`
                                }`}
                            >
                                Submit
                            </button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Index;
