import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as RiIcons from 'react-icons/ri';
import Camera from '../../../assets/svg/Camera';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearInstitutesMessages,
    editInstitute,
    getInstituteById,
    getTags,
} from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
    phoneRegExp,
    s3Config,
    s3UrlPrefix,
    moduleNames,
    resolutionError,
    error,
    logoResolutionErrorMsg,
    instituteTypes,
} from '../../../constants';
import S3 from 'react-aws-s3';
import AddGray from '../../../assets/svg/AddGray';
import * as HiIcons from 'react-icons/hi';
import InstituteSuccessfullModal from '../../modals/InstituteSuccesfullModal';
import { InstitutesType } from '../../../redux/institutes/reducers';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import CropModal from '../../modals/cropModal';
import PhoneInput from 'react-phone-input-2';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroups from '../../TagGroups';
import ToolTip from '../../modals/ToolTip';

interface CustomState {
    institute: InstitutesType;
}
interface paramsType {
    id: string;
}
export default function AddNewInstitute() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const params = useParams<paramsType>();
    const newFileName = Date.now().toString();
    const instituteState = location.state as CustomState;

    const editInstituteError = useSelector(
        (state: RootState) => state.institutes.editInstituteError
    );
    const isEditLoading = useSelector((state: RootState) => state.institutes.isEditLoading);
    const instituteByIdLoading = useSelector(
        (state: RootState) => state.institutes.instituteByIdLoading
    );

    const institute = useSelector((state: RootState) => state.institutes.institute);
    const deleteInstituteError = useSelector(
        (state: RootState) => state.institutes.deleteInstituteError
    );
    const instituteDeleteSuccessMessage = useSelector(
        (state: RootState) => state.institutes.instituteDeleteSuccessMessage
    );
    const instituteUpdateSuccessMessage = useSelector(
        (state: RootState) => state.institutes.instituteUpdateSuccessMessage
    );
    const formRef = useRef<any>();
    const [imageUrl, setImageUrl] = useState(
        `${s3UrlPrefix + instituteState?.institute?.bannerImage}`
    );
    const [imageLocation, setImageLocation] = useState(instituteState?.institute?.bannerImage);
    const [isUploading, setIsUploading] = useState(false);
    const [logoUrl, setLogoUrl] = useState(`${s3UrlPrefix + instituteState?.institute?.logoImage}`);
    const [logoLocation, setLogoLocation] = useState(instituteState?.institute?.logoImage);
    const [isLogoUploading, setIsLogoUploading] = useState(false);
    const [instituteSuccessVisible, setInstituteSuccessVisible] = useState(false);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const category1Array = instituteState?.institute?.instCategory1?.options?.map(elm => {
        return { option1: elm };
    });
    const category2Array = instituteState?.institute?.instCategory2?.options?.map(elm => {
        return { option2: elm };
    });
    const category3Array = instituteState?.institute?.instCategory3?.options?.map(elm => {
        return { option3: elm };
    });
    const [category1List, setCategory1List] = useState<any>(category1Array || [{ option1: '' }]);
    const [category2List, setCategory2List] = useState<any>(category2Array || [{ option2: '' }]);
    const [category3List, setCategory3List] = useState<any>(category3Array || [{ option3: '' }]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [tags, setTags] = useState(instituteState?.institute?.tags);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [logoResolutionError, setLogoResolutionError] = useState(false);
    const [bannerResolutionError, setBannerResolutionError] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [uploadLogoError, setUploadLogoError] = useState(false);
    const [helpTxt, setHelpTxt] = useState(false);
    const [openCrop, setOpenCrop] = useState(false);
    const [openLogoCrop, setLogoOpenCrop] = useState(false);

    const [tagsHelpText, setTagsHelpText] = useState<boolean>(false);

    const categoryOptions = [
        { id: 0, val: 'Vocational training institute' },
        { id: 1, val: 'Government University' },
        { id: 2, val: 'Private University' },
        { id: 3, val: 'Other' },
    ];
    const serchTagNameOrId = e => {
        setSearchTerm(e.target.value);
        // setTags([]);
    };

    useEffect(() => {
        if (showDropdown) {
            dispatch(getTags(true, moduleNames.Institute, searchTerm));
        }
    }, [dispatch, searchTerm, showDropdown]);

    useEffect(() => {
        setTags(instituteState?.institute?.tags);
    }, [instituteState]);

    useEffect(() => {
        setSelectedTags(categorizeTags(tags));
    }, [tags]);

    const handleCategory1InputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...category1List];
        list[index][name] = value;
        setCategory1List(list);
    };

    const handleCategory2InputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...category2List];
        list[index][name] = value;
        setCategory2List(list);
    };
    const handleCategory3InputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...category3List];
        list[index][name] = value;
        setCategory3List(list);
    };
    const handleRemoveCategory1Click = index => {
        const list = [...category1List];
        list.splice(index, 1);
        setCategory1List(list);
    };

    const handleRemoveCategory2Click = index => {
        const list = [...category2List];
        list.splice(index, 1);
        setCategory2List(list);
    };
    const handleRemoveCategory3Click = index => {
        const list = [...category3List];
        list.splice(index, 1);
        setCategory3List(list);
    };
    const handleAddCategory1Click = () => {
        setCategory1List([...category1List, { option1: '' }]);
    };

    const handleAddCategory2Click = () => {
        setCategory2List([...category2List, { option2: '' }]);
    };
    const handleAddCategory3Click = () => {
        setCategory3List([...category3List, { option3: '' }]);
    };
    useEffect(() => {
        dispatch(getInstituteById(params.id));
    }, [dispatch, params.id]);

    const handleClearMessages = useCallback(() => {
        if (
            instituteUpdateSuccessMessage ||
            editInstituteError ||
            instituteDeleteSuccessMessage ||
            deleteInstituteError
        ) {
            setInstituteSuccessVisible(true);
        }
    }, [
        dispatch,
        instituteUpdateSuccessMessage,
        editInstituteError,
        history,
        instituteDeleteSuccessMessage,
        deleteInstituteError,
    ]);
    const handleClose = () => {
        dispatch(clearInstitutesMessages());
        if (!editInstituteError) {
            history.push('/admin/institutes');
        }
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    const uploadImageToS3 = async croppedImg => {
        setIsUploading(true);
        s3Config.dirName = `institutes/${newFileName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(croppedImg, newFileName)
            .then(data => {
                setImageLocation(data.key);
                setIsUploading(false);
                setUploadError(false);
            })
            .catch(err => {
                console.error(err);
                setIsUploading(false);
                setUploadError(true);
            });
    };
    const uploadLogoToS3 = async croppedImg => {
        setIsLogoUploading(true);
        s3Config.dirName = `institutes/${newFileName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(croppedImg, newFileName)
            .then(data => {
                setLogoLocation(data.key);
                setIsLogoUploading(false);
                setUploadLogoError(false);
            })
            .catch(err => {
                console.error(err);
                setIsLogoUploading(false);
                setUploadLogoError(true);
            });
    };
    const handleImage = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setImageUrl(URL.createObjectURL(file));
            setOpenCrop(true);
        }
    };
    const handleLogoImage = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setLogoUrl(URL.createObjectURL(file));
            setLogoOpenCrop(true);
        }
    };
    const handleCloseCropModal = () => {
        setOpenCrop(false);
        setImageUrl(`${s3UrlPrefix + instituteState?.institute?.bannerImage}`);
    };
    const handleCloseLogoCropModal = () => {
        setLogoOpenCrop(false);
        setLogoUrl(`${s3UrlPrefix + instituteState?.institute?.logoImage}`);
    };
    const category1 = [
        category1List?.map(option1 => {
            return option1.option1;
        }),
    ];
    const category2 = [
        category2List?.map(option2 => {
            return option2.option2;
        }),
    ];
    const category3 = [
        category3List?.map(option3 => {
            return option3.option3;
        }),
    ];
    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    {instituteByIdLoading ? (
                        <Loader />
                    ) : (
                        <div className="relative">
                            <Formik
                                innerRef={formRef}
                                initialValues={{
                                    instName: institute?.instName,
                                    instEmail: institute?.instEmail,
                                    contactNo: institute?.contactNo,
                                    repName: institute?.repName,
                                    location: institute?.location,
                                    instDescription: institute?.instDescription,
                                    category: institute?.category,
                                    logo: logoLocation,
                                    image: imageLocation,
                                    instCourses: category1.join(),
                                    instFaculties: category2.join(),
                                    instOption3: category3.join(),
                                    instCategory1: institute?.instCategory1?.name,
                                    instCategory2: institute?.instCategory2?.name,
                                    instCategory3: institute?.instCategory3?.name,
                                    siteUrl: institute?.siteUrl,
                                    mouSigned: institute?.mouSigned,
                                }}
                                validationSchema={Yup.object({
                                    instName: Yup.string()
                                        .max(100, 'Name should be less than 100 characters')
                                        .required('Name is required'),
                                    instDescription: Yup.string()
                                        .nullable()
                                        .max(500, 'Description should be less than 500 characters')
                                        .required('Description is required'),
                                    instEmail: Yup.string()
                                        .nullable()
                                        .email('Invalid email')
                                        .required('Email is required'),
                                    location: Yup.string()
                                        .nullable()
                                        .required('Location is required'),
                                    repName: Yup.string()
                                        .nullable()
                                        .required('Representative name is required'),
                                    contactNo: Yup.string()
                                        .nullable()
                                        .matches(phoneRegExp, 'Contact number is not valid')
                                        .required('Contact number is required'),
                                    siteUrl: Yup.string()
                                        .nullable()
                                        .matches(
                                            // eslint-disable-next-line no-useless-escape
                                            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                                            'Enter correct url!'
                                        ),
                                    image: Yup.string()
                                        .nullable()
                                        .required('Institute banner is required'),
                                    logo: Yup.string()
                                        .nullable()
                                        .required('Institute logo is required'),
                                })}
                                onSubmit={async values => {
                                    dispatch(
                                        editInstitute(
                                            institute?.id,
                                            {
                                                instName: values.instName.trim(),
                                                instEmail: values.instEmail,
                                                contactNo: values.contactNo,
                                                location: values.location.trim(),
                                                logoImage: logoLocation,
                                                bannerImage: imageLocation,
                                                repName: values.repName.trim(),
                                                instDescription: values.instDescription.trim(),
                                                instCreatedBy: '',
                                                category: values.category,
                                                instCategory1: {
                                                    name: values.instCategory1,
                                                    options: category1?.[0],
                                                },
                                                instCategory2: {
                                                    name: values.instCategory2,
                                                    options: category2?.[0],
                                                },
                                                instCategory3: {
                                                    name: values.instCategory3,
                                                    options: category3?.[0],
                                                },
                                                siteUrl: values.siteUrl,
                                                tags: tags,
                                                mouSigned: values.mouSigned,
                                            },
                                            null
                                        )
                                    );
                                }}
                            >
                                {({
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    values,
                                    errors,
                                    touched,
                                }) => (
                                    <>
                                        <div className="flex justify-between items-center mb-4 md:mb-6">
                                            <p className="text-xl font-semibold text-left">
                                                Edit Institute
                                            </p>
                                            <div className="flex w-1/2 md:w-1/4 lg:pl-8 h-16">
                                                <button
                                                    className="md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                    onClick={() =>
                                                        history.push('/admin/institutes/')
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                    onClick={() => handleSubmit()}
                                                >
                                                    {isEditLoading ? <Loader /> : 'Save'}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="border-2 border-gray-300 px-6 pt-2 pb-4 mb-6">
                                            <div className="grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8">
                                                <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="title"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Institute ID
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                                value={institute?.instId}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="title"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Institute Name
                                                            <div className="text-red-600">*</div>
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.instName &&
                                                                    touched.instName
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="name"
                                                                type="text"
                                                                value={values.instName}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'instName',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.instName && touched.instName ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.instName}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex">
                                                        <label
                                                            className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Institute Type
                                                        </label>
                                                        <div className="w-full flex">
                                                            <div
                                                                className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-4/5 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                                                            >
                                                                <div
                                                                    className="flex justify-between w-full"
                                                                    onClick={() =>
                                                                        setDropdownVisible(true)
                                                                    }
                                                                >
                                                                    <div className="text-gray-700 font-medium pl-2">
                                                                        {values.category ===
                                                                        instituteTypes.VOCATIONAL_INSTITUTE
                                                                            ? categoryOptions[0].val
                                                                            : values.category ===
                                                                              instituteTypes.GOVERNMENT_UNIVERSITY
                                                                            ? categoryOptions[1].val
                                                                            : values.category ===
                                                                              instituteTypes.PRIVATE_UNIVERSITY
                                                                            ? categoryOptions[2].val
                                                                            : categoryOptions[3]
                                                                                  .val}
                                                                    </div>
                                                                    <div className="">
                                                                        <div className="flex flex-col">
                                                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                            <HiIcons.HiChevronDown className="mr-2" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {dropdownVisible ? (
                                                                    <ul
                                                                        className="absolute w-full cursor-pointer top-10 pl-0 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                        role="menu"
                                                                        onMouseLeave={() =>
                                                                            setDropdownVisible(
                                                                                false
                                                                            )
                                                                        }
                                                                    >
                                                                        {categoryOptions?.map(
                                                                            action => (
                                                                                <div
                                                                                    className="p-2 hover:bg-gray-200 "
                                                                                    key={action.id}
                                                                                    onClick={() => {
                                                                                        setFieldValue(
                                                                                            'category',
                                                                                            action.id ===
                                                                                                0
                                                                                                ? instituteTypes.VOCATIONAL_INSTITUTE
                                                                                                : action.id ===
                                                                                                  1
                                                                                                ? instituteTypes.GOVERNMENT_UNIVERSITY
                                                                                                : action.id ===
                                                                                                  2
                                                                                                ? instituteTypes.PRIVATE_UNIVERSITY
                                                                                                : instituteTypes.OTHER
                                                                                        );
                                                                                        setDropdownVisible(
                                                                                            false
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {action.val}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="instLocation"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Institute Address
                                                            <div className="text-red-600">*</div>
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.location &&
                                                                    touched.location
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="name"
                                                                type="text"
                                                                value={values.location}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'location',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.location && touched.location ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.location}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="mb-6 w-full md:mb-0 flex my-2">
                                                        <label
                                                            htmlFor="instCategory1"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Category 1
                                                            <span>
                                                                <ToolTip tip="Enter the name of the category value you want to define for the added Institute. EX: Faculty, Department, Degree Program etc." />
                                                            </span>
                                                        </label>
                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.instCategory1 &&
                                                                    touched.instCategory1
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="name"
                                                                type="text"
                                                                value={values.instCategory1}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'instCategory1',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            <div className="mb-6 w-3/4 md:w-4/5 md:mb-0 flex my-4">
                                                                <label
                                                                    htmlFor="instCategory1"
                                                                    className={`flex items-start md:mt-2 w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700 pr-2`}
                                                                >
                                                                    Options
                                                                    <span>
                                                                        <ToolTip tip="Enter the relevant values for the category defined." />
                                                                    </span>
                                                                </label>
                                                                <div className="flex flex-col w-full">
                                                                    {category1List?.map(
                                                                        (option1, i) => {
                                                                            return (
                                                                                <div
                                                                                    className="flex mt-2"
                                                                                    key={i}
                                                                                >
                                                                                    <input
                                                                                        className={`border-2 w-full p-1 ${
                                                                                            errors.instCourses &&
                                                                                            touched.instCourses
                                                                                                ? 'border-red-500'
                                                                                                : 'border-gray-300'
                                                                                        }`}
                                                                                        name="option1"
                                                                                        value={
                                                                                            option1.option1
                                                                                        }
                                                                                        autoComplete="off"
                                                                                        onChange={e => {
                                                                                            setFieldValue(
                                                                                                'instCourses',
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            );
                                                                                            handleCategory1InputChange(
                                                                                                e,
                                                                                                i
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    <div className="flex items-center">
                                                                                        {category1List.length >
                                                                                            1 && (
                                                                                            <RiIcons.RiCloseLine
                                                                                                className="ml-2 cursor-pointer"
                                                                                                onClick={() =>
                                                                                                    handleRemoveCategory1Click(
                                                                                                        i
                                                                                                    )
                                                                                                }
                                                                                                size={
                                                                                                    25
                                                                                                }
                                                                                                color={
                                                                                                    '#FF0000'
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                        {category1List.length -
                                                                                            1 ===
                                                                                            i &&
                                                                                            category1List[
                                                                                                category1List.length -
                                                                                                    1
                                                                                            ]
                                                                                                .option1 !==
                                                                                                '' && (
                                                                                                <button
                                                                                                    className="ml-2"
                                                                                                    onClick={
                                                                                                        handleAddCategory1Click
                                                                                                    }
                                                                                                >
                                                                                                    <AddGray
                                                                                                        size={
                                                                                                            25
                                                                                                        }
                                                                                                    />
                                                                                                </button>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                    {errors.instCourses &&
                                                                    touched.instCourses ? (
                                                                        <div className="text-red-500 text-xs my-1">
                                                                            {errors.instCourses}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mb-6 w-full md:mb-0 flex my-2">
                                                        <label
                                                            htmlFor="instCategory1"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Category 2
                                                            <span>
                                                                <ToolTip tip="Enter the name of the category value you want to define for the added Institute. EX: Faculty, Department, Degree Program etc." />
                                                            </span>
                                                        </label>
                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.instCategory2 &&
                                                                    touched.instCategory2
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="name"
                                                                type="text"
                                                                value={values.instCategory2}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'instCategory2',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.instCategory2 &&
                                                            touched.instCategory2 ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.instCategory2}
                                                                </div>
                                                            ) : null}

                                                            <div className="mb-6 w-3/4 md:w-4/5 md:mb-0 flex my-4">
                                                                <label
                                                                    htmlFor="instCategory2"
                                                                    className={`flex items-start md:mt-2 w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700 pr-2`}
                                                                >
                                                                    Options
                                                                    <span>
                                                                        <ToolTip tip="Enter the relevant values for the category defined." />
                                                                    </span>
                                                                </label>
                                                                <div className="flex flex-col w-full">
                                                                    {category2List?.map(
                                                                        (option2, i) => {
                                                                            return (
                                                                                <div
                                                                                    className="flex mt-2"
                                                                                    key={i}
                                                                                >
                                                                                    <input
                                                                                        className={`border-2 w-full p-1 ${
                                                                                            errors.instFaculties &&
                                                                                            touched.instFaculties
                                                                                                ? 'border-red-500'
                                                                                                : 'border-gray-300'
                                                                                        }`}
                                                                                        name="option2"
                                                                                        value={
                                                                                            option2.option2
                                                                                        }
                                                                                        autoComplete="off"
                                                                                        onChange={e => {
                                                                                            setFieldValue(
                                                                                                'instFaculties',
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            );
                                                                                            handleCategory2InputChange(
                                                                                                e,
                                                                                                i
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    <div className="flex items-center">
                                                                                        {category2List.length >
                                                                                            1 && (
                                                                                            <RiIcons.RiCloseLine
                                                                                                className="ml-2 cursor-pointer"
                                                                                                onClick={() =>
                                                                                                    handleRemoveCategory2Click(
                                                                                                        i
                                                                                                    )
                                                                                                }
                                                                                                size={
                                                                                                    25
                                                                                                }
                                                                                                color={
                                                                                                    '#FF0000'
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                        {category2List.length -
                                                                                            1 ===
                                                                                            i &&
                                                                                            category2List[
                                                                                                category2List.length -
                                                                                                    1
                                                                                            ]
                                                                                                .option2 !==
                                                                                                '' && (
                                                                                                <button
                                                                                                    className="ml-2"
                                                                                                    onClick={
                                                                                                        handleAddCategory2Click
                                                                                                    }
                                                                                                >
                                                                                                    <AddGray
                                                                                                        size={
                                                                                                            25
                                                                                                        }
                                                                                                    />
                                                                                                </button>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                    {errors.instFaculties &&
                                                                    touched.instFaculties ? (
                                                                        <div className="text-red-500 text-xs my-1">
                                                                            {errors.instFaculties}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mb-6 w-full md:mb-0 flex my-2">
                                                        <label
                                                            htmlFor="instCategory3"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Category 3
                                                            <span>
                                                                <ToolTip tip="Enter the name of the category value you want to define for the added Institute. EX: Faculty, Department, Degree Program etc." />
                                                            </span>
                                                        </label>
                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.instCategory3 &&
                                                                    touched.instCategory3
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="name"
                                                                type="text"
                                                                value={values.instCategory3}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'instCategory3',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.instCategory3 &&
                                                            touched.instCategory3 ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.instCategory3}
                                                                </div>
                                                            ) : null}

                                                            <div className="mb-6 w-3/4 md:w-4/5 md:mb-0 flex my-4">
                                                                <label
                                                                    htmlFor="instCategory3"
                                                                    className={`flex items-start md:mt-2 w-1/4 block font-medium leading-149 text-sm md:text-base text-gray-700 pr-2`}
                                                                >
                                                                    Options
                                                                    <span>
                                                                        <ToolTip tip="Enter the relevant values for the category defined." />
                                                                    </span>
                                                                </label>
                                                                <div className="flex flex-col w-full">
                                                                    {category3List?.map(
                                                                        (option3, i) => {
                                                                            return (
                                                                                <div
                                                                                    className="flex mt-2"
                                                                                    key={i}
                                                                                >
                                                                                    <input
                                                                                        className={`border-2 w-full p-1 ${
                                                                                            errors.instOption3 &&
                                                                                            touched.instOption3
                                                                                                ? 'border-red-500'
                                                                                                : 'border-gray-300'
                                                                                        }`}
                                                                                        name="option3"
                                                                                        value={
                                                                                            option3.option3
                                                                                        }
                                                                                        autoComplete="off"
                                                                                        onChange={e => {
                                                                                            setFieldValue(
                                                                                                'instOption3',
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            );
                                                                                            handleCategory3InputChange(
                                                                                                e,
                                                                                                i
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    <div className="flex items-center">
                                                                                        {category3List.length >
                                                                                            1 && (
                                                                                            <RiIcons.RiCloseLine
                                                                                                className="ml-2 cursor-pointer"
                                                                                                onClick={() =>
                                                                                                    handleRemoveCategory3Click(
                                                                                                        i
                                                                                                    )
                                                                                                }
                                                                                                size={
                                                                                                    25
                                                                                                }
                                                                                                color={
                                                                                                    '#FF0000'
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                        {category3List.length -
                                                                                            1 ===
                                                                                            i &&
                                                                                            category3List[
                                                                                                category3List.length -
                                                                                                    1
                                                                                            ]
                                                                                                .option3 !==
                                                                                                '' && (
                                                                                                <button
                                                                                                    className="ml-2"
                                                                                                    onClick={
                                                                                                        handleAddCategory3Click
                                                                                                    }
                                                                                                >
                                                                                                    <AddGray
                                                                                                        size={
                                                                                                            25
                                                                                                        }
                                                                                                    />
                                                                                                </button>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                    {errors.instOption3 &&
                                                                    touched.instOption3 ? (
                                                                        <div className="text-red-500 text-xs my-1">
                                                                            {errors.instOption3}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="repName"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Representative Name
                                                            <div className="text-red-600">*</div>
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.repName &&
                                                                    touched.repName
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="repName"
                                                                type="text"
                                                                value={values.repName}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'repName',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.repName && touched.repName ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.repName}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="instEmail"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Email
                                                            <div className="text-red-600">*</div>
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.instEmail &&
                                                                    touched.instEmail
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="name"
                                                                type="text"
                                                                value={values.instEmail}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'instEmail',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.instEmail &&
                                                            touched.instEmail ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.instEmail}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="contactNo"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Contact Number
                                                            <div className="text-red-600">*</div>
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <label
                                                                htmlFor=""
                                                                className={`rounded-sm rounded-tl rounded-bl flex items-center focus:outline-none w-4/5 md:w-3/4  border-2 text-base focus:border-blue-900 ${
                                                                    errors.contactNo &&
                                                                    touched.contactNo
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                            >
                                                                <PhoneInput
                                                                    inputStyle={{
                                                                        width: '100%',
                                                                        height: '42px',
                                                                        fontSize: '14px',
                                                                        paddingLeft: '48px',
                                                                        borderRadius: '4px',
                                                                        border: 0,
                                                                        fontFamily: 'Lato',
                                                                    }}
                                                                    dropdownClass={
                                                                        'mobileNumberCountryDropdown'
                                                                    }
                                                                    country="lk"
                                                                    specialLabel={''}
                                                                    value={values.contactNo?.toString()}
                                                                    placeholder="Enter your Mobile Number (Eg: 94712345678)"
                                                                    onChange={e => {
                                                                        if (!isNaN(Number(e))) {
                                                                            setFieldValue(
                                                                                'contactNo',
                                                                                e
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </label>
                                                            {errors.contactNo &&
                                                            touched.contactNo ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.contactNo}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="siteUrl"
                                                            className={`flex items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Institute website URL
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                                id="name"
                                                                type="text"
                                                                value={values.siteUrl}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'siteUrl',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.siteUrl && touched.siteUrl ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.siteUrl}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex h-24">
                                                        <label
                                                            htmlFor="description"
                                                            className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Institute Description
                                                            <div className="text-red-600">*</div>
                                                        </label>
                                                        <div className="flex flex-col w-full">
                                                            <textarea
                                                                className={`resize-none focus:outline-none p-2 w-4/5 border-2 text-sm h-24 focus:border-blue-900 ${
                                                                    errors.instDescription &&
                                                                    touched.instDescription
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                }`}
                                                                id="description"
                                                                value={values.instDescription}
                                                                placeholder=""
                                                                onChange={handleChange(
                                                                    'instDescription'
                                                                )}
                                                            />
                                                            {errors.instDescription &&
                                                            touched.instDescription ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.instDescription}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex">
                                                        <label
                                                            className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            MOU Signed?
                                                            <div className="w-1/4">
                                                                <HiIcons.HiQuestionMarkCircle
                                                                    onClick={() => {
                                                                        setHelpTxt(!helpTxt);
                                                                    }}
                                                                    className="ml-2 mt-1 cursor-pointer"
                                                                />
                                                                {helpTxt ? (
                                                                    <div
                                                                        onMouseOverCapture={() => {
                                                                            setHelpTxt(true);
                                                                        }}
                                                                        onMouseLeave={() =>
                                                                            setHelpTxt(false)
                                                                        }
                                                                        className="border-2 text-sm rounded-md absolute z-50 px-2 py-3 mt-3 bg-white"
                                                                        style={{
                                                                            boxShadow:
                                                                                '0px 0px 10px 1px rgba(197, 203, 220, 0.32)',
                                                                            width: '360px',
                                                                        }}
                                                                    >
                                                                        Please click Yes if the
                                                                        institute has signed a MOU
                                                                        with SLASSCOM and the
                                                                        institute will then appear
                                                                        on the Institute Homepage of
                                                                        the FCB website.
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </label>

                                                        <div className="flex text-sm md:text-base items-center w-full">
                                                            <input
                                                                className="mr-1"
                                                                type="radio"
                                                                id="true"
                                                                name="mouSigned"
                                                                defaultChecked={
                                                                    institute?.mouSigned === true
                                                                }
                                                                onChange={() =>
                                                                    setFieldValue('mouSigned', true)
                                                                }
                                                            />
                                                            <label className="mr-4 w-1/4">
                                                                Yes
                                                            </label>
                                                            <input
                                                                className="mr-1"
                                                                type="radio"
                                                                id="false"
                                                                name="mouSigned"
                                                                defaultChecked={
                                                                    institute?.mouSigned === false
                                                                }
                                                                onChange={() =>
                                                                    setFieldValue(
                                                                        'mouSigned',
                                                                        false
                                                                    )
                                                                }
                                                            />
                                                            <label className="">No</label>
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex">
                                                        <label
                                                            htmlFor="description"
                                                            className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Institute Banner Image
                                                            <div className="text-red-600">*</div>
                                                        </label>
                                                        <div
                                                            className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                                                imageUrl ? 'w-fit' : ''
                                                            }  border-opacity-100 border-2 border-black-600`}
                                                        >
                                                            <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64">
                                                                {!imageLocation && !isUploading && (
                                                                    <div className="w-full">
                                                                        <div className="flex justify-center">
                                                                            <Camera />
                                                                        </div>
                                                                        <div>
                                                                            <label
                                                                                className="underline cursor-pointer text-sm"
                                                                                htmlFor="imageLink"
                                                                            >
                                                                                Upload Image
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {imageUrl && imageLocation && (
                                                                    <img
                                                                        src={imageUrl}
                                                                        alt={'name'}
                                                                        className="h-44 lg:h-64"
                                                                        style={{ maxHeight: 300 }}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="absolute mt-1 text-gray-400 text-sm font-medium leading-149">
                                                                Image size 1520*422
                                                            </div>
                                                            <div
                                                                className="absolute mt-8 w-max text-center"
                                                                style={{
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%,-50%)',
                                                                }}
                                                            >
                                                                {imageUrl &&
                                                                    imageLocation &&
                                                                    !isUploading && (
                                                                        <div>
                                                                            <label
                                                                                className="underline cursor-pointer text-sm"
                                                                                htmlFor="imageLink"
                                                                            >
                                                                                Change Image
                                                                            </label>
                                                                        </div>
                                                                    )}
                                                                {isUploading && (
                                                                    <div className="flex items-center text-sm">
                                                                        <div className="mr-2">
                                                                            Uploading
                                                                        </div>
                                                                        <div>
                                                                            <Loader />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div
                                                                    className={`text-red-500 text-xs ${
                                                                        imageLocation
                                                                            ? 'my-1'
                                                                            : 'my-2'
                                                                    }`}
                                                                >
                                                                    {errors.image && touched.image
                                                                        ? errors.image
                                                                        : bannerResolutionError
                                                                        ? resolutionError
                                                                        : uploadError
                                                                        ? error
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <input
                                                            className={`focus:outline-none w-full focus:border-blue-900`}
                                                            id="imageLink"
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async e => {
                                                                const file = [];
                                                                file.push(e.target.files[0]);
                                                                setFieldValue(
                                                                    'image',
                                                                    file[0].name
                                                                );
                                                                handleImage(e);
                                                            }}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>

                                                    <div
                                                        className={`my-6 w-full md:mb-0 flex ${
                                                            bannerResolutionError ? 'pt-10' : 'pt-6'
                                                        }`}
                                                    >
                                                        <label
                                                            htmlFor="logo"
                                                            className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Institute Logo
                                                            <div className="text-red-600">*</div>
                                                        </label>
                                                        <div
                                                            className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                                                logoUrl ? 'w-fit' : ''
                                                            }  border-opacity-100 border-2 border-black-600`}
                                                        >
                                                            <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64">
                                                                {!logoLocation && !isLogoUploading && (
                                                                    <div className="w-full">
                                                                        <div className="flex justify-center">
                                                                            <Camera />
                                                                        </div>
                                                                        <div>
                                                                            <label
                                                                                className="underline cursor-pointer text-sm"
                                                                                htmlFor="logoLink"
                                                                            >
                                                                                Upload Logo
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {logoUrl && logoLocation && (
                                                                    <img
                                                                        src={logoUrl}
                                                                        alt={'name'}
                                                                        className="h-44 lg:h-64"
                                                                        style={{ maxHeight: 300 }}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="absolute mt-1 text-gray-400 text-sm font-medium leading-149">
                                                                Image size 275*127
                                                            </div>
                                                            <div
                                                                className="absolute mt-8 w-max text-center"
                                                                style={{
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%,-50%)',
                                                                }}
                                                            >
                                                                {logoUrl &&
                                                                    logoLocation &&
                                                                    !isLogoUploading && (
                                                                        <div>
                                                                            <label
                                                                                className="underline cursor-pointer text-sm"
                                                                                htmlFor="logoLink"
                                                                            >
                                                                                Change Logo
                                                                            </label>
                                                                        </div>
                                                                    )}
                                                                {isLogoUploading && (
                                                                    <div className="flex items-center text-sm">
                                                                        <div className="mr-2">
                                                                            Uploading
                                                                        </div>
                                                                        <div>
                                                                            <Loader />
                                                                        </div>
                                                                    </div>
                                                                )}{' '}
                                                                <div className="text-red-500 text-xs mt-1">
                                                                    {errors.logo && touched.logo
                                                                        ? errors.logo
                                                                        : logoResolutionError
                                                                        ? logoResolutionErrorMsg
                                                                        : uploadLogoError
                                                                        ? error
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <input
                                                            className={`focus:outline-none w-full focus:border-blue-900`}
                                                            id="logoLink"
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async e => {
                                                                const file = [];
                                                                file.push(e.target.files[0]);
                                                                setFieldValue('logo', file[0].name);
                                                                handleLogoImage(e);
                                                            }}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                                    <div className="flex border-2 flex-col ml-2 justify-items-center">
                                                        <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                                            <div className="flex sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                                <div>Assign Tags</div>
                                                                <div>
                                                                    <HiIcons.HiQuestionMarkCircle
                                                                        onClick={() => {
                                                                            setTagsHelpText(true);
                                                                        }}
                                                                        className="ml-2 mt-1 cursor-pointer"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {tagsHelpText ? (
                                                                <div
                                                                    onMouseOverCapture={() => {
                                                                        setTagsHelpText(true);
                                                                        // setProfileDropdownVisible(false);
                                                                    }}
                                                                    onMouseLeave={() =>
                                                                        setTagsHelpText(false)
                                                                    }
                                                                    className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-6 right-12"
                                                                >
                                                                    Assign already created tags to
                                                                    the new record created.
                                                                </div>
                                                            ) : null}
                                                            <div
                                                                className="sm:text-sm md:text-base text-gray-400 underline pr-2 cursor-pointer"
                                                                onClick={() => {
                                                                    setTags([]);
                                                                }}
                                                            >
                                                                Clear All
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                                            <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                                <TagGroups
                                                                    selectedTags={selectedTags}
                                                                    tags={tags}
                                                                    setTags={setTags}
                                                                />

                                                                <div
                                                                    onMouseLeave={() => {
                                                                        setShowDropdown(false);
                                                                    }}
                                                                >
                                                                    <input
                                                                        className={`rounded w-full text-gray-500 font-medium focus:outline-none mt-2 p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                                                        id="id"
                                                                        type="text"
                                                                        placeholder="Search tags"
                                                                        onChange={e =>
                                                                            serchTagNameOrId(e)
                                                                        }
                                                                        onMouseEnter={() => {
                                                                            setShowDropdown(true);
                                                                        }}
                                                                        autoComplete="off"
                                                                    />
                                                                    {showDropdown &&
                                                                    tagData?.length ? (
                                                                        <ul
                                                                            className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                            role="menu"
                                                                            onMouseLeave={() => {
                                                                                setShowDropdown(
                                                                                    false
                                                                                );
                                                                            }}
                                                                        >
                                                                            {tagData?.map(tag => (
                                                                                <div
                                                                                    className="p-2 hover:bg-gray-200 cursor-pointer"
                                                                                    key={tag.id}
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            tags
                                                                                                .map(
                                                                                                    checktag =>
                                                                                                        checktag.tagName
                                                                                                )
                                                                                                .includes(
                                                                                                    tag.tagName
                                                                                                ) ===
                                                                                            false
                                                                                        ) {
                                                                                            setTags(
                                                                                                [
                                                                                                    ...tags,
                                                                                                    tag,
                                                                                                ]
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {tag.tagCategory +
                                                                                        ': ' +
                                                                                        tag.tagName}
                                                                                </div>
                                                                            ))}
                                                                        </ul>
                                                                    ) : showDropdown &&
                                                                      tagData.length === 0 ? (
                                                                        <ul
                                                                            className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                            role="menu"
                                                                            onMouseLeave={() =>
                                                                                setShowDropdown(
                                                                                    false
                                                                                )
                                                                            }
                                                                        >
                                                                            <div className="p-2 flex justify-center font-medium text-gray-400">
                                                                                No records found
                                                                            </div>
                                                                        </ul>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" ml-2 justify-items-center">
                                                        <div className="flex flex-col text-sm pl-2 lg:pl-4 mt-6 space-y-4 text-gray-400 ">
                                                            <div>
                                                                Created tags can be retrieved by
                                                                typing the text in the search bar.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {institute?.instStatus !== 'ACTIVE' && (
                                                <div className="my-6 md:mb-0 flex justify-end">
                                                    <button
                                                        type="submit"
                                                        className="sm:py-1 md:py-3 ml-2 lg:ml-8 hover:bg-red-500 text-red-500 hover:text-white border-2 font-medium border-red-600 border-opacity-40 text-sm rounded-sm w-1/2 md:w-1/6 my-2 focus:outline-none"
                                                        onClick={() =>
                                                            setDeleteConfirmationVisible(true)
                                                        }
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        {deleteConfirmationVisible ? (
                                            <DeleteConfirmationModal
                                                modalVisible={deleteConfirmationVisible}
                                                setModalVisible={setDeleteConfirmationVisible}
                                                instituteId={institute?.id}
                                            />
                                        ) : null}
                                        {(instituteUpdateSuccessMessage ||
                                            editInstituteError ||
                                            instituteDeleteSuccessMessage ||
                                            deleteInstituteError) && (
                                            <InstituteSuccessfullModal
                                                modalVisible={instituteSuccessVisible}
                                                handleClose={handleClose}
                                            />
                                        )}
                                        <CropModal
                                            ratio={3.6}
                                            photoURL={imageUrl}
                                            setPhotoURL={setImageUrl}
                                            setOpenCrop={setOpenCrop}
                                            openCrop={openCrop}
                                            handleCloseCropModal={handleCloseCropModal}
                                            uploadImageToS3={uploadImageToS3}
                                        />
                                        <CropModal
                                            ratio={2.2}
                                            photoURL={logoUrl}
                                            setPhotoURL={setLogoUrl}
                                            setOpenCrop={setLogoOpenCrop}
                                            openCrop={openLogoCrop}
                                            handleCloseCropModal={handleCloseLogoCropModal}
                                            uploadImageToS3={uploadLogoToS3}
                                        />
                                    </>
                                )}
                            </Formik>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
