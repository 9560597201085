import React, { ReactElement } from 'react';

interface Props {
    size: number;
}

export default function NoData(): ReactElement {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.92969 2.83398V58.8816C2.92969 61.5843 4.00332 64.1763 5.91441 66.0874C7.82549 67.9984 10.4175 69.0721 13.1202 69.0721H69.1678"
                stroke="#BFBFBF"
                strokeWidth="5.09524"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.1206 48.6906L28.4063 33.4049L38.5968 43.5953L64.073 18.1191"
                stroke="#BFBFBF"
                strokeWidth="5.09524"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M64.0709 33.4049V18.1191H48.7852"
                stroke="#BFBFBF"
                strokeWidth="5.09524"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
