import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import UpArrow from '../../../../assets/svg/UpArrow';
import DownArrow from '../../../../assets/svg/DownArrow';
import useWidth from '../../../hooks/useWidth';
import styled from 'styled-components';

const faq = [
    {
        id: 1,
        title: 'How to start a challenge?',
        sections: [
            {
                description: 'Click on Learn tab to explore tracks and challenges',
                image: '/assets/help/faq/help_faq_01.png',
            },
            {
                description: `Choose a track and click on 'Start Learning'. Then you will be navigated to the track detail view page`,
                image: '/assets/help/faq/help_faq_02.png',
            },
            {
                description:
                    'Then you will see the details of the track page and get an idea about the track learning outcomes and challenges you have to face',
                image: '/assets/help/faq/help_faq_03.png',
            },
            {
                description: 'Click on start learning and enjoy learning your challenges',
                image: '/assets/help/faq/help_faq_04.png',
            },
        ],
    },
    {
        id: 2,
        title: 'What  comes under recommended?',
        sections: [
            {
                description:
                    'Recommended tracks are shown to you by analyzing the areas of Interest you have marked at registering with the program.',
                image: '/assets/help/faq/help_faq_05.png',
            },
            {
                description:
                    'By analyzing your interest we show all the tracks which suits your interest.',
                image: '/assets/help/faq/help_faq_06.png',
            },
        ],
    },
    {
        id: 3,
        title: 'How can I change my interest?',
        sections: [
            {
                description: 'Click on Settings tab',
                image: '/assets/help/faq/help_faq_07.png',
            },
            {
                description: 'View My profile section',
                image: '/assets/help/faq/help_faq_08.png',
            },
            {
                description: 'Areas of interest you selected already can be viewed',
                image: '/assets/help/faq/help_faq_09.png',
            },
            {
                description: 'Click on edit profile ',
                image: '/assets/help/faq/help_faq_10.png',
            },
            {
                description: 'In here you can change your interest areas.',
                image: '/assets/help/faq/help_faq_11.png',
            },
        ],
    },
    {
        id: 4,
        title: 'How to change my personal or educational details?',
        sections: [
            {
                description: 'Click on Settings tab',
                image: '/assets/help/faq/help_faq_07.png',
            },
            {
                description: 'View my profile section',
                image: '/assets/help/faq/help_faq_08.png',
            },
            {
                description: 'Click on edit profile',
                image: '/assets/help/faq/help_faq_10.png',
            },
            {
                description: 'Update your personal details and educational details.',
                image: '/assets/help/faq/help_faq_12.png',
            },
        ],
    },
    {
        id: 5,
        title: 'How to make my account visible to the public?',
        sections: [
            {
                description: 'Click on Settings tab',
                image: '/assets/help/faq/help_faq_07.png',
            },
            {
                description: 'View my profile section',
                image: '/assets/help/faq/help_faq_08.png',
            },
            {
                description: 'Click on edit profile',
                image: '/assets/help/faq/help_faq_10.png',
            },
            {
                description:
                    'Under "I joined Future Careers BRIDGE program because," select "Looking for an industry internship" or "Already in employment but looking for new Tech career"  to make your profile public.',
                image: '/assets/help/faq/help_faq_14.png',
            },
        ],
    },
    {
        id: 6,
        title: 'How to make my profile picture visible to the public?',
        sections: [
            {
                description: 'Click on settings tab',
                image: '/assets/help/faq/help_faq_07.png',
            },
            {
                description: 'View my profile section',
                image: '/assets/help/faq/help_faq_08.png',
            },
            {
                description: 'Click on edit profile',
                image: '/assets/help/faq/help_faq_10.png',
            },
            {
                description: 'Update your personal details and educational details.',
                image: '/assets/help/faq/help_faq_13.png',
            },
        ],
    },
];

const HelpContentParagraph = styled.div`
    font-size: 10px;
`;

const TopicCard = styled.div`
    width: 47%;
`;

const Help = () => {
    const history = useHistory();

    const [selectedFAQ, setSelectedFAQ] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const viewPortWidth = useWidth();
    return (
        <>
            {viewPortWidth > 1023 ? (
                <div className="mx-10 md:mx-5 md:ml-14 lg:ml-72 lg:pl-3.5 lg:pt-2 mt-6 lg:mb-28">
                    <div className="w-full flex justify-between rounded bg-purple-85 mb-8 overflow-hidden">
                        <div className="flex flex-col justify-center p-6">
                            <div className="text-2xl-2 leading-10 font-bold font-manrope mb-2">
                                How may we help you?
                            </div>
                            <div className="text-base font-normal mb-3">
                                Learn how the Future Careers BRIDGE program can improve your
                                knowledge
                                <br></br>
                                and career growth with the help of IT field experts in Sri Lanka
                            </div>
                        </div>
                        <img
                            className="h-full object-contain hidden xl:block"
                            width="437px"
                            src={`/assets/local/Help.jpg`}
                            alt="Banner Image"
                        />
                    </div>
                    <div className="mb-8">
                        <div className="text-xl font-manrope font-bold mb-3">
                            Frequently asked questions
                        </div>
                        <div className="flex flex-wrap gap-3 mb-8">
                            {faq.map(item => (
                                <div
                                    key={item.id}
                                    onClick={() => {
                                        setSelectedFAQ(item.id);
                                        setIsExpanded(false);
                                    }}
                                    style={{ borderWidth: 1 }}
                                    className={`rounded flex items-center h-11 px-3 text-xs font-normal border-purple-950 cursor-pointer ${
                                        selectedFAQ === item.id
                                            ? 'bg-purple-950 text-white'
                                            : 'bg-transparent text-purple-950'
                                    }`}
                                >
                                    {item.title}
                                </div>
                            ))}
                        </div>
                        <div
                            style={{ borderWidth: 1 }}
                            className={`rounded ${selectedFAQ ? 'block' : 'hidden'} ${
                                isExpanded
                                    ? 'border-purple-50 bg-purple-85'
                                    : 'border-gray-300 bg-transparent'
                            }`}
                        >
                            <div
                                className={`px-11 py-8 relative flex items-center text-base cursor-pointer ${
                                    isExpanded ? 'font-bold text-purple-950' : 'font-normal'
                                }`}
                                onClick={() => setIsExpanded(!isExpanded)}
                            >
                                {selectedFAQ && faq[selectedFAQ - 1]?.title}
                                <div className="absolute right-7">
                                    {isExpanded ? <UpArrow /> : <DownArrow />}
                                </div>
                            </div>
                            {isExpanded &&
                                faq.map(
                                    faqItem =>
                                        faqItem.id === selectedFAQ &&
                                        faqItem.sections.map((item, index) => (
                                            <div
                                                key={index}
                                                className="flex justify-between items-center gap-10 p-6 bg-purple-85 border-t-2 border-purple-75"
                                            >
                                                <p className="w-8/12 text-sm font-normal">
                                                    {item.description}
                                                </p>
                                                <img
                                                    className="max-w-sm h-full object-contain"
                                                    src={`${item.image}`}
                                                    alt="FAQ step's image"
                                                />
                                            </div>
                                        ))
                                )}
                        </div>
                    </div>
                    <div className="mb-8">
                        <div className="text-xl font-manrope font-bold mb-3">
                            Select a topic for help
                        </div>
                        <div className="flex flex-wrap gap-8 place-self-start">
                            <div
                                style={{ borderWidth: 1, borderColor: '#B2A4E9' }}
                                className="flex flex-col items-center justify-center w-67.5 h-44 rounded cursor-pointer"
                                onClick={() => history.push(`/dashBoard/help/getting-started`)}
                            >
                                <img
                                    className="object-contain w-10 h-10"
                                    src="/assets/help/help_gettingStarted.png"
                                />
                                <div className="text-base font-bold font-manrope mt-3 mb-2">
                                    Getting Started
                                </div>
                                <div className="text-xs font-normal text-gray-400 text-center px-4">
                                    Learn how Future Careers BRIDGE works and how to start learning
                                </div>
                            </div>
                            <div
                                style={{ borderWidth: 1, borderColor: '#B2A4E9' }}
                                className="flex flex-col items-center justify-center w-67.5 h-44 rounded cursor-pointer"
                                onClick={() => history.push(`/dashBoard/help/settings`)}
                            >
                                <img
                                    className="object-contain w-10 h-10"
                                    src="/assets/help/help_settings.png"
                                />
                                <div className="text-base font-bold font-manrope mt-3 mb-2">
                                    Settings
                                </div>
                                <div className="text-xs font-normal text-gray-400 text-center px-4">
                                    Manage account settings in Future Careers BRIDGE
                                </div>
                            </div>
                            <div
                                style={{ borderWidth: 1, borderColor: '#B2A4E9' }}
                                className="flex flex-col items-center justify-center w-67.5 h-44 rounded cursor-pointer"
                                onClick={() => history.push(`/dashBoard/help/troubleshooting`)}
                            >
                                <img
                                    className="object-contain w-10 h-10"
                                    src="/assets/help/help_troubleshooting.png"
                                />
                                <div className="text-base font-bold font-manrope mt-3 mb-2">
                                    Troubleshooting
                                </div>
                                <div className="text-xs font-normal text-gray-400 text-center px-4">
                                    Experiencing a technical issue? Click here
                                </div>
                            </div>
                            <div
                                style={{ borderWidth: 1, borderColor: '#B2A4E9' }}
                                className="flex flex-col items-center justify-center w-67.5 h-44 rounded cursor-pointer"
                                onClick={() => history.push(`/dashBoard/help/start-track`)}
                            >
                                <img
                                    className="object-contain w-10 h-10"
                                    src="/assets/help/help_startTracks.png"
                                />
                                <div className="text-base font-bold font-manrope mt-3 mb-2">
                                    Start Tracks
                                </div>
                                <div className="text-xs font-normal text-gray-400 text-center px-4">
                                    Everything about taking a track on Future Careers BRIDGE
                                </div>
                            </div>
                            <div
                                style={{ borderWidth: 1, borderColor: '#B2A4E9' }}
                                className="flex flex-col items-center justify-center w-67.5 h-44 rounded cursor-pointer"
                                onClick={() => history.push(`/dashBoard/help/request-mentor`)}
                            >
                                <img
                                    className="object-contain w-10 h-10"
                                    src="/assets/help/help_requestMentor.png"
                                />
                                <div className="text-base font-bold font-manrope mt-3 mb-2">
                                    Request a Mentor
                                </div>
                                <div className="text-xs font-normal text-gray-400 text-center px-4">
                                    How do you request a mentor?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="w-full flex justify-between rounded bg-purple-85 mb-8 overflow-hidden">
                        <div className="flex flex-col justify-center">
                            <img
                                className="h-full object-contain w-full"
                                src={`/assets/local/Help.jpg`}
                                alt="Banner Image"
                            />
                            <div className="leading-10 font-bold font-manrope mb-2 mt-5 px-6">
                                How may we help you?
                            </div>
                            <div className="text-xs mb-6 px-6">
                                Learn how the Future Careers BRIDGE program can improve your
                                knowledge and career growth with the help of IT field experts in Sri
                                Lanka
                            </div>
                        </div>
                    </div>
                    <div className="mb-8 px-5">
                        <div className="text-lg font-manrope font-bold mb-3">
                            Frequently asked questions
                        </div>
                        <div className="flex flex-wrap gap-2 mb-8">
                            {faq.map(item => (
                                <div
                                    key={item.id}
                                    onClick={() => {
                                        setSelectedFAQ(item.id);
                                        setIsExpanded(false);
                                    }}
                                    style={{ borderWidth: 1, fontSize: 10, height: 35 }}
                                    className={`rounded flex items-center px-3 font-normal border-purple-950 cursor-pointer ${
                                        selectedFAQ === item.id
                                            ? 'bg-purple-950 text-white'
                                            : 'bg-transparent text-purple-950'
                                    }`}
                                >
                                    {item.title}
                                </div>
                            ))}
                        </div>
                        <div
                            style={{ borderWidth: 1 }}
                            className={`rounded ${selectedFAQ ? 'block' : 'hidden'} ${
                                isExpanded
                                    ? 'border-purple-50 bg-purple-85'
                                    : 'border-gray-300 bg-transparent'
                            }`}
                        >
                            <div
                                className={`px-11 py-8 relative flex items-center text-xs cursor-pointer ${
                                    isExpanded ? 'font-bold text-purple-950' : 'font-normal'
                                }`}
                                onClick={() => setIsExpanded(!isExpanded)}
                            >
                                {selectedFAQ && faq[selectedFAQ - 1]?.title}
                                <div className="absolute right-7">
                                    {isExpanded ? <UpArrow /> : <DownArrow />}
                                </div>
                            </div>
                            {isExpanded &&
                                faq.map(
                                    faqItem =>
                                        faqItem.id === selectedFAQ &&
                                        faqItem.sections.map((item, index) => (
                                            <div
                                                key={index}
                                                className="flex flex-col justify-between items-center gap-y-4 py-5 px-11 bg-purple-85 border-t-2 border-purple-75"
                                            >
                                                <img
                                                    className="w-full h-full object-contain"
                                                    src={`${item.image}`}
                                                    alt="FAQ step's image"
                                                />
                                                <p className="w-full text-xs font-normal">
                                                    {item.description}
                                                </p>
                                            </div>
                                        ))
                                )}
                        </div>
                    </div>
                    <div className="mb-8 pl-5">
                        <div className="text-xl font-manrope font-bold mb-3">
                            Select a topic for help
                        </div>
                        <div className="flex flex-wrap gap-3 place-self-start">
                            <TopicCard
                                style={{ borderWidth: 1, borderColor: '#B2A4E9' }}
                                className="flex flex-col items-center justify-center h-40 rounded cursor-pointer"
                                onClick={() => history.push(`/dashBoard/help/getting-started`)}
                            >
                                <img
                                    className="object-contain w-10 h-10"
                                    src="/assets/help/help_gettingStarted.png"
                                />
                                <div className="text-xs font-bold font-manrope mt-3 mb-2">
                                    Getting Started
                                </div>
                                <HelpContentParagraph className="font-normal text-gray-400 text-center px-4">
                                    Learn how Future Careers BRIDGE works and how to start learning
                                </HelpContentParagraph>
                            </TopicCard>
                            <TopicCard
                                style={{ borderWidth: 1, borderColor: '#B2A4E9' }}
                                className="flex flex-col items-center justify-center h-40 rounded cursor-pointer"
                                onClick={() => history.push(`/dashBoard/help/settings`)}
                            >
                                <img
                                    className="object-contain w-10 h-10"
                                    src="/assets/help/help_settings.png"
                                />
                                <div className="text-xs font-bold font-manrope mt-3 mb-2">
                                    Settings
                                </div>
                                <HelpContentParagraph className="font-normal text-gray-400 text-center px-4">
                                    Manage account settings in Future Careers BRIDGE
                                </HelpContentParagraph>
                            </TopicCard>
                            <TopicCard
                                style={{ borderWidth: 1, borderColor: '#B2A4E9' }}
                                className="flex flex-col items-center justify-center h-40 rounded cursor-pointer"
                                onClick={() => history.push(`/dashBoard/help/troubleshooting`)}
                            >
                                <img
                                    className="object-contain w-10 h-10"
                                    src="/assets/help/help_troubleshooting.png"
                                />
                                <div className="text-xs font-bold font-manrope mt-3 mb-2">
                                    Troubleshooting
                                </div>
                                <HelpContentParagraph className="font-normal text-gray-400 text-center px-4">
                                    Experiencing a technical issue? Click here
                                </HelpContentParagraph>
                            </TopicCard>
                            <TopicCard
                                style={{ borderWidth: 1, borderColor: '#B2A4E9' }}
                                className="flex flex-col items-center justify-center h-40 rounded cursor-pointer"
                                onClick={() => history.push(`/dashBoard/help/start-track`)}
                            >
                                <img
                                    className="object-contain w-10 h-10"
                                    src="/assets/help/help_startTracks.png"
                                />
                                <div className="text-xs font-bold font-manrope mt-3 mb-2">
                                    Start Tracks
                                </div>
                                <HelpContentParagraph className="font-normal text-gray-400 text-center px-4">
                                    Everything about taking a track on Future Careers BRIDGE
                                </HelpContentParagraph>
                            </TopicCard>
                            <TopicCard
                                style={{ borderWidth: 1, borderColor: '#B2A4E9' }}
                                className="flex flex-col items-center justify-center h-40 rounded cursor-pointer"
                                onClick={() => history.push(`/dashBoard/help/request-mentor`)}
                            >
                                <img
                                    className="object-contain w-10 h-10"
                                    src="/assets/help/help_requestMentor.png"
                                />
                                <div className="text-xs font-bold font-manrope mt-3 mb-2">
                                    Request a Mentor
                                </div>
                                <HelpContentParagraph className="font-normal text-gray-400 text-center px-4">
                                    How do you request a mentor?
                                </HelpContentParagraph>
                            </TopicCard>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Help;
