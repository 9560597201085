import React, { ReactElement } from 'react';

export default function FooterAdmin(): ReactElement {
    return (
        <>
            <div
                className="hidden lg:flex flex-col xl:flex-row justify-center items-center border-t-2 px-10 lg:border-l-2 border-gray-100 bg-white rounded-sm w-full"
                style={{
                    minHeight: '8vh',
                    zIndex: 999,
                    position: 'absolute',
                }}
            >
                <div
                    className="font-manrope"
                    style={{
                        color: '#636668',
                    }}
                >
                    &copy; {new Date().getFullYear()} An initiative by{' '}
                    <span className="inline-flex items-baseline">
                        <img
                            src="/assets/slasscom_logo.png"
                            alt="SLASSCOM logo"
                            className="self-center w-auto h-5 px-2"
                            onClick={() => window.open('https://slasscom.lk/', '_blank')}
                        />

                        <span>, facilitated by</span>
                    </span>
                    <span className="inline-flex items-baseline">
                        <img
                            src="/assets/USAID_logo.png"
                            alt="USAID logo"
                            className="self-center w-auto h-6 px-2"
                            onClick={() => window.open('https://www.usaid.gov/', '_blank')}
                        />
                        <span>,</span>
                        <img
                            src="/assets/youlead_logo.png"
                            alt="YouLead logo"
                            className="self-center w-auto h-5 px-2"
                            onClick={() => window.open('https://www.youlead.lk/', '_blank')}
                        />
                        <img
                            src="/assets/RNE_logo.png"
                            alt="RNE logo"
                            className="self-center w-auto h-5 px-2"
                            onClick={() =>
                                window.open('https://www.norway.no/en/sri-lanka/', '_blank')
                            }
                        />
                        <span>All Rights Reserved.</span>
                    </span>
                </div>
            </div>
        </>
    );
}
