import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCerificateById, getPdfUrl, getTracks } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CertificateType } from '../../../redux/certificates/reducers';
import { downloadFromExternalLink } from '../../../utils/downloadContent';
interface CustomState {
    certificate: CertificateType;
}
interface paramsType {
    id: string;
}
export default function ViewCertificate() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const params = useParams<paramsType>();
    const certificateState = location.state as CustomState;
    const certificate = useSelector((state: RootState) => state.certificates.certificate);
    const pdfLink = useSelector((state: RootState) => state.certificates.pdfLink);
    const fileName = certificateState?.certificate?.contentDocUrl?.split('/', 4);
    const [tags] = useState(certificateState?.certificate?.tags);

    useEffect(() => {
        dispatch(getTracks(true, 0, 100, 'PUBLISHED'));
        dispatch(getPdfUrl(fileName?.[3]));
    }, [dispatch]);
    useEffect(() => {
        dispatch(getCerificateById(params.id));
    }, [dispatch, params.id]);
    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    <div className="relative">
                        <div className="flex justify-between items-center mb-4 md:mb-6">
                            <p className="text-xl font-semibold text-left">View Certificate</p>
                            <div
                                className={`flex h-14 md:h-16 ${
                                    certificateState?.certificate?.certStatus !== 'DELETED'
                                        ? 'w-1/2 md:w-1/4 lg:pl-8'
                                        : 'w-1/4 md:w-1/6 lg:pl-16'
                                }`}
                            >
                                <button
                                    type="submit"
                                    className={`md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm ${
                                        certificateState?.certificate?.certStatus !== 'DELETED'
                                            ? 'w-full md:w-1/2'
                                            : 'w-full hover:bg-blue-700 hover:text-white'
                                    } my-2 focus:outline-none`}
                                    onClick={() => {
                                        history.push('/admin/achievements/certificates/');
                                    }}
                                >
                                    OK
                                </button>
                                {certificateState?.certificate?.certStatus !== 'DELETED' && (
                                    <button
                                        type="submit"
                                        className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-normal rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                        onClick={() => {
                                            history.push({
                                                pathname: `/admin/achievements/certificates/${certificateState?.certificate?.id}/edit`,
                                                state: { certificate: certificate },
                                            });
                                        }}
                                    >
                                        Edit
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2 pb-4 mb-6">
                            <div className="col-span-12 md:col-span-4 mb-2 md:mb-4">
                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        htmlFor="id"
                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Certificate ID
                                    </label>

                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm border-gray-300 flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            id="id"
                                            type="text"
                                            value={certificate?.certId}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        htmlFor="title"
                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Certificate Name
                                    </label>

                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm border-gray-300 flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            id="name"
                                            type="text"
                                            value={certificate?.certificateName}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Related Track
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <div className="flex flex-col w-full">
                                            <input
                                                className={`rounded-sm  border-gray-300 flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                value={certificate?.track?.trackName}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Certifcate Status
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <div className="flex flex-col w-full">
                                            <input
                                                className={`rounded-sm border-gray-300 flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                value={certificate?.certStatus}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex h-24">
                                    <label
                                        htmlFor="description"
                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Certificate Description
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <textarea
                                            className={`resize-none border-gray-300 focus:outline-none p-2 w-4/5 border-2 text-sm h-20 focus:border-blue-900`}
                                            value={certificate?.description}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex">
                                    <label
                                        htmlFor="description"
                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Certificate Template
                                    </label>
                                    <div className={` w-full mb-2 md:mb-4 relative`}>
                                        <div className="bg-gray-100 border w-4/5 border-opacity-100 border-2 border-black-600 text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-20 md:h-32">
                                            {certificateState?.certificate?.contentDocUrl && (
                                                <div>
                                                    <div
                                                        className="text-blue-700 underline cursor-pointer"
                                                        onClick={() =>
                                                            downloadFromExternalLink(
                                                                pdfLink,
                                                                'UploadedCertificate'
                                                            )
                                                        }
                                                    >
                                                        Download Here
                                                    </div>

                                                    <p>File name : {fileName[3]}</p>

                                                    <p>
                                                        File size :{' '}
                                                        {certificate?.certificateSize + 'KB'}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <div className="mt-1 text-gray-400 text-sm font-medium leading-149">
                                            Upload format (html)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
