import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../../../../../assets/svg/Loader';
import { resetPasswordConfimationWeb } from '../../../../../../redux/rootActions';
import { RootState } from '../../../../../../redux/rootReducer';
import * as Yup from 'yup';
import { Formik } from 'formik';

const WebResetPassword = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const IsResetPassLoading = useSelector((state: RootState) => state.webUser.isResetPassLoading);
    const WebResetPasswordSuccessMessage = useSelector(
        (state: RootState) => state.webUser.WebResetPasswordSuccessMessage
    );

    const [isError, setIsError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const passwordShow = () => {
        setShowPassword(!showPassword);
    };

    const confirmPasswordShow = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const verifyToken = location?.search.slice(7);

    const validationSchema = Yup.object({
        password: Yup.string()
            .required('Password cannot be blank')
            .nullable()
            .min(
                8,
                'Password not strong. Enter at least 8 characters. Must Contain One Uppercase, One Lowercase, One Number and one special case Character'
            )
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
            ),
    });

    useEffect(() => {
        if (WebResetPasswordSuccessMessage) {
            history.push('/signin');
        }
    }, [WebResetPasswordSuccessMessage, history]);

    return (
        <div className="flex w-full items-center justify-center px-16">
            <div className="flex flex-col p-4 w-1/3">
                <div className="flex text-2xl font-semibold justify-center">Change Password</div>
                <div className="flex mt-4 justify-center">
                    In order to protect your account, make sure your password is atleast 8
                    characters long.
                </div>
                <div className="flex mt-1 justify-center">
                    To make it stronger, use upper and lower case letters, numbers and symbols like
                    !@#$%^*
                </div>
                <Formik
                    initialValues={{
                        password: '',
                        confirmPassword: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async ({ password, confirmPassword }) => {
                        if (password === confirmPassword) {
                            setIsError(false);
                            dispatch(resetPasswordConfimationWeb(confirmPassword, verifyToken));
                        } else {
                            setIsError(true);
                        }
                    }}
                >
                    {({ handleChange, handleSubmit, values, errors, touched }) => (
                        <form
                            onSubmit={event => {
                                event.preventDefault();
                                handleSubmit();
                            }}
                        >
                            <div className="flex flex-col">
                                <div className=" mt-3 flex flex-col relative">
                                    <label className="font-manrope text-sm" htmlFor="email">
                                        New Password
                                    </label>
                                    <input
                                        className="inputFieldThickness pl-2 py-1 h-12"
                                        type={showPassword ? 'text' : 'password'}
                                        id="email"
                                        placeholder="Enter Password"
                                        value={values.password}
                                        onChange={handleChange('password')}
                                    />
                                    <div className="absolute inset-y-0 right-0 pt-8 pr-4 flex text-base item-center">
                                        {values.password?.length > 0 ? (
                                            <>
                                                <img
                                                    src="/assets/passwordShowIcon.png"
                                                    className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                        showPassword ? 'block' : 'hidden'
                                                    }`}
                                                    onClick={passwordShow}
                                                    alt="show-password"
                                                    role="presentation"
                                                    draggable="false"
                                                />
                                                <img
                                                    src="/assets/passwordHideIcon.png"
                                                    className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                        showPassword ? 'hidden' : 'block'
                                                    }`}
                                                    alt="hide-password"
                                                    role="presentation"
                                                    onClick={passwordShow}
                                                    draggable="false"
                                                />
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                                <div className=" mt-3 flex flex-col relative">
                                    <label className="font-manrope text-sm" htmlFor="email">
                                        Confirm Password
                                    </label>
                                    <input
                                        className="inputFieldThickness pl-2 py-1 h-12"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        id="email"
                                        placeholder="Enter Password"
                                        value={values.confirmPassword}
                                        onChange={handleChange('confirmPassword')}
                                    />
                                    <div className="absolute inset-y-0 right-0 pt-8 pr-4 flex text-base item-center">
                                        {values.confirmPassword?.length > 0 ? (
                                            <>
                                                <img
                                                    src="/assets/passwordShowIcon.png"
                                                    className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                        showConfirmPassword ? 'block' : 'hidden'
                                                    }`}
                                                    onClick={confirmPasswordShow}
                                                    alt="show-password"
                                                    role="presentation"
                                                    draggable="false"
                                                />
                                                <img
                                                    src="/assets/passwordHideIcon.png"
                                                    className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                        showConfirmPassword ? 'hidden' : 'block'
                                                    }`}
                                                    alt="hide-password"
                                                    role="presentation"
                                                    onClick={confirmPasswordShow}
                                                    draggable="false"
                                                />
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            {errors.password && touched.password ? (
                                <div className="text-red-500 text-xs mt-4 text-center">
                                    {errors.password}
                                </div>
                            ) : null}
                            {isError ? (
                                <div className="text-red-500 text-xs mt-4 text-center">
                                    Password doesn&apos;t match
                                </div>
                            ) : null}

                            <div className={`flexjustify-center ${isError ? 'mt-1' : ' mt-4 '}`}>
                                <button
                                    type="submit"
                                    className="py-2.5 px-4 w-full rounded bg-purple-950 text-white"
                                >
                                    {IsResetPassLoading ? <Loader /> : 'Change password'}
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default WebResetPassword;
