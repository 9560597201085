import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { USER_PROFILE } from '../../utils/storage';
import { getNotifications, NotificationsTabCreateType } from '../notifications/actions';

const PREFIX = '@NOTIFICATION_TAB';

export const ADD_NOTIFICATION_TAB = PREFIX + 'ADD_NOTIFICATION_TAB';
export const ADD_NOTIFICATION_TAB_SUCCESS = PREFIX + 'ADD_NOTIFICATION_TAB_SUCCESS';
export const ADD_NOTIFICATION_TAB_FAILED = PREFIX + 'ADD_NOTIFICATION_TAB_FAILED';
export const GET_NOTIFICATION_TAB = PREFIX + 'GET_NOTIFICATION_TAB';
export const GET_NOTIFICATION_TAB_SUCCESS = PREFIX + 'GET_NOTIFICATION_TAB_SUCCESS';
export const GET_NOTIFICATION_TAB_FAILED = PREFIX + 'GET_NOTIFICATION_TAB_FAILED';

export const getNotificationtTabs =
    (id?: string, association?: string) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_NOTIFICATION_TAB });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/users/admins/filter?id=${id}&association=${association}`,
                config
            );
            dispatch({
                type: GET_NOTIFICATION_TAB_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_NOTIFICATION_TAB_FAILED, payload: getErrorMessage(error) });
        }
    };
export const addNotificationsTab = (tab: NotificationsTabCreateType) => async (dispatch: any) => {
    try {
        dispatch({ type: ADD_NOTIFICATION_TAB });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/notifications/filter`, tab, config);
        const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
        await dispatch(getNotificationtTabs(profile?.userId, 'NOTIFICATION'));
        dispatch({ type: ADD_NOTIFICATION_TAB_SUCCESS, payload: res.data.responseDto.data });
    } catch (error) {
        dispatch({ type: ADD_NOTIFICATION_TAB_FAILED, payload: getErrorMessage(error) });
    }
};
