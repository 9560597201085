import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { s3UrlPrefix } from '../../../../constants';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getTracks } from '../../../../redux/rootActions';
import { TracksType } from '../../../../redux/tracks/reducers';
import BackArrow from '../../../../assets/svg/BackArrow';
import { Line } from 'rc-progress';
import Loader from '../../../../assets/svg/Loader';
import RepublishedChallengeMessageModal from '../../../modals/RepublishedChallengeMessageModal';

interface CustomState {
    tracks: TracksType;
}

const Challenges = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const trackState = location.state as CustomState;

    const trackData = useSelector((state: RootState) => state.tracks.trackData);
    const isAllTrackLoading = useSelector((state: RootState) => state.tracks.isAllTrackLoading);

    const [selectedTrack, setSelectedTrack] = useState(null);

    const [republishedChallengeModal, setModal] = React.useState(false);
    const [challengeId, setSelectedChallengeId] = useState<any>();
    const [selectTrack, setSelectTrack] = useState<any>();
    const [selectedChallenge, setSelectedChallenge] = useState<any>();

    useEffect(() => {
        dispatch(getTracks(false, 0, 100, 'PUBLISHED'));
    }, []);

    useEffect(() => {
        if (trackState?.tracks) {
            if (trackData && trackData.length > 0) {
                trackData.forEach((track: TracksType) => {
                    if (trackState?.tracks?.id === track.id) {
                        setSelectedTrack(track);
                    }
                });
            }
        } else {
            setSelectedTrack(trackData[0]);
        }
    }, [trackData]);

    return (
        <div>
            <div
                className={`hidden lg:flex mx-10 md:mx-5 md:ml-14 lg:ml-72 lg:pl-3.5 lg:pt-2 md:mt-6 mb-10`}
            >
                <RepublishedChallengeMessageModal
                    setShowModal={setModal}
                    showModal={republishedChallengeModal}
                    challengeId={challengeId}
                    selectTrack={selectTrack}
                    selectedChallenge={selectedChallenge}
                />
                <div className="w-full">
                    <div
                        className="flex mb-3 mt-1 cursor-pointer"
                        onClick={() => history.push(`/dashBoard/learn`)}
                    >
                        <BackArrow size="27" />
                        <p className="text-base ml-2 font-semibold">Back</p>
                    </div>
                    <div className="bg-purple-85 flex justify-between rounded">
                        <div className=" flex-auto items-center py-8 px-8 w-full">
                            <div className="space-y-3">
                                <div className="text-xl font-bold">Explore All Tracks</div>
                                <div className="text-justify">
                                    Select a track from the list below.
                                </div>
                                {isAllTrackLoading ? (
                                    <div className="flex align-middle items-center content-center">
                                        <Loader />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            flexWrap: 'wrap',
                                            gap: '16px',
                                            marginRight: '1em',
                                            flexDirection: 'row',
                                            alignContent: 'flex-start',
                                        }}
                                    >
                                        {trackData?.map(track => (
                                            <div
                                                key={track?.trackId}
                                                onClick={() => {
                                                    setSelectedTrack(null);
                                                    setTimeout(() => {
                                                        setSelectedTrack(track);
                                                    }, 1);
                                                }}
                                                style={{ borderWidth: 1 }}
                                                className={`rounded  p-3 flex justify-center items-center text-xs cursor-pointer whitespace-nowrap ${
                                                    track?.trackId === selectedTrack?.trackId
                                                        ? 'bg-purple-950 text-white border-purple-950'
                                                        : 'bg-white border-purple-950 text-purple-950'
                                                }`}
                                            >
                                                {track?.trackName}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {selectedTrack ? (
                        <div className="flex-row justify-between mb-2 mt-6 mx-9">
                            <div className="mt-4 flex items-center">
                                <span className="font-manrope text-xl font-bold text-purple-950">
                                    Selected challenges for&nbsp;
                                    {selectedTrack?.trackName}
                                </span>
                                <div className="ml-2 text-xs font-normal bg-purple-100 text-purple-950 py-1 px-2 rounded-full">
                                    {
                                        selectedTrack?.challenges.filter(
                                            val => val.challengeStatus === 'PUBLISHED'
                                        ).length
                                    }
                                </div>
                            </div>
                            <div className="mt-2 pb-2">
                                {selectedTrack && selectedTrack.trackDescription}
                            </div>
                            <div className="flex flex-wrap pb-6">
                                {selectedTrack.tags?.map(tag => (
                                    <div
                                        key={tag.id}
                                        className="mr-2 my-2 bg-purple-200 text-purple-950 text-xs rounded-full px-3 py-1 w-max"
                                    >
                                        {tag.tagName}
                                    </div>
                                ))}
                            </div>

                            {selectedTrack?.challenges.filter(
                                val => val.challengeStatus === 'PUBLISHED'
                            ).length > 0 ? (
                                <div
                                    style={{
                                        flexGrow: 1,
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        gap: '25px',
                                        marginRight: '1em',
                                        flexDirection: 'column',
                                        alignContent: 'flex-start',
                                    }}
                                >
                                    {selectedTrack?.challenges?.map(
                                        challenge =>
                                            challenge.challengeStatus === 'PUBLISHED' && (
                                                <div key={challenge.id}>
                                                    <div className="pl-5 flex">
                                                        <img
                                                            style={{
                                                                width: '180px',
                                                                height: '180px',
                                                            }}
                                                            className="rounded-lg"
                                                            src={
                                                                challenge.imageLink
                                                                    ? `${s3UrlPrefix}${challenge.imageLink}`
                                                                    : `/assets/challenge-image-skeleton-square.png`
                                                            }
                                                        />
                                                        <div className="ml-4 mt-2 pb-3">
                                                            <div className="font-bold text-lg">
                                                                {challenge?.challengeName}
                                                            </div>
                                                            <div>
                                                                <p className="challengeDescription text-xs font-normal mt-3 mb-4 h-8">
                                                                    {challenge.challengeDescription}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                {challenge?.StatusValue === 1 && (
                                                                    <div className="flex flex-row w-2/3">
                                                                        <div>
                                                                            <div className=" flex-col">
                                                                                <div className="challenge-progress-line w-full py-2">
                                                                                    <Line
                                                                                        percent={
                                                                                            (challenge.earnedMarks /
                                                                                                challenge.totalMarks) *
                                                                                            100
                                                                                        }
                                                                                        strokeWidth={
                                                                                            1
                                                                                        }
                                                                                        strokeColor={`${
                                                                                            challenge.isChallengeRepublished
                                                                                                ? '#F16341'
                                                                                                : '#4C2EC2'
                                                                                        }`}
                                                                                    />
                                                                                    <div className="text-xs font-normal mt-1">
                                                                                        Earn{' '}
                                                                                        {`${(
                                                                                            challenge.totalMarks -
                                                                                            challenge.earnedMarks
                                                                                        ).toFixed(
                                                                                            2
                                                                                        )}`}{' '}
                                                                                        XP points
                                                                                        more to
                                                                                        climb up
                                                                                        your
                                                                                        position in
                                                                                        the
                                                                                        leaderboard
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-xs font-normal w-44 mx-1 mt-1">
                                                                            {`${challenge.earnedMarks} / ${challenge.totalMarks} XP`}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {(challenge?.StatusValue === 2 ||
                                                                    challenge?.StatusValue ===
                                                                        1) && (
                                                                    <div>
                                                                        <button
                                                                            onClick={() => {
                                                                                if (
                                                                                    challenge.isChallengeRepublished
                                                                                ) {
                                                                                    setSelectedChallengeId(
                                                                                        challenge.challengeId
                                                                                    );
                                                                                    setSelectTrack(
                                                                                        selectedTrack
                                                                                    );
                                                                                    setSelectedChallenge(
                                                                                        challenge
                                                                                    );
                                                                                    setModal(true);
                                                                                } else {
                                                                                    history.push({
                                                                                        pathname: `/dashBoard/learn/Challenges/${challenge.challengeId}`,
                                                                                        state: {
                                                                                            path: `/dashBoard/learn/Challenges`,
                                                                                            track: selectedTrack,
                                                                                            challenges:
                                                                                                challenge,
                                                                                        },
                                                                                    });
                                                                                }
                                                                            }}
                                                                            className={`border-2  rounded font-bold text-sm h-10 px-6 xl:px-12 
                                                    ${
                                                        challenge?.StatusValue === 1
                                                            ? challenge.isChallengeRepublished
                                                                ? 'bg-orange-550 text-white border-orange-550'
                                                                : 'bg-purple-950 text-white border-purple-950'
                                                            : 'bg-transparent text-purple-950 border-purple-950'
                                                    }`}
                                                                        >
                                                                            {challenge?.StatusValue ===
                                                                            1
                                                                                ? 'Continue'
                                                                                : 'Get Started'}
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {challenge?.StatusValue === 0 && (
                                                                <div className="flex">
                                                                    <div className="text-sm text-green-600 font-bold h-10 pr-5 flex items-center">
                                                                        Completed
                                                                    </div>
                                                                    <button
                                                                        onClick={() => {
                                                                            if (
                                                                                challenge.isChallengeRepublished
                                                                            ) {
                                                                                setSelectedChallengeId(
                                                                                    challenge.challengeId
                                                                                );
                                                                                setSelectTrack(
                                                                                    selectedTrack
                                                                                );
                                                                                setSelectedChallenge(
                                                                                    challenge
                                                                                );
                                                                                setModal(true);
                                                                            } else {
                                                                                history.push({
                                                                                    pathname: `/dashBoard/learn/Challenges/${challenge.challengeId}`,
                                                                                    state: {
                                                                                        path: `/dashBoard/learn/Challenges`,
                                                                                        track: selectedTrack,
                                                                                        challenges:
                                                                                            challenge,
                                                                                    },
                                                                                });
                                                                            }
                                                                        }}
                                                                        className={`border-2 rounded font-bold text-sm h-10 px-6 xl:px-12 ${
                                                                            challenge.isChallengeRepublished
                                                                                ? 'border-orange-550 text-orange-550'
                                                                                : 'border-purple-950 text-purple-950'
                                                                        }`}
                                                                    >
                                                                        Try Again
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{ borderBottomWidth: 1 }}
                                                        className="ml-5 mt-3"
                                                    />
                                                </div>
                                            )
                                    )}
                                </div>
                            ) : (
                                <div className="font-bold text-lg">
                                    Selected track doesn’t have any challenges
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="flex align-middle items-center content-center h-full">
                            <Loader />
                        </div>
                    )}
                </div>
            </div>
            <div className={`flex lg:hidden mb-10`}>
                <div className="w-full">
                    <div
                        className="flex mx-3 my-3 cursor-pointer"
                        onClick={() => history.push(`/dashBoard/learn`)}
                    >
                        <BackArrow size="27" />
                        <p className="text-base ml-2 font-semibold">Back</p>
                    </div>
                    <div className="bg-purple-85 flex justify-between rounded">
                        <div className=" flex-auto items-center py-8 px-8 w-full">
                            <div className="space-y-3">
                                <div className="text-xl font-bold">Explore All Tracks</div>
                                <div className="text-justify">
                                    Select a track from the list below.
                                </div>
                                {isAllTrackLoading ? (
                                    <div className="flex align-middle items-center content-center">
                                        <Loader />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            flexWrap: 'wrap',
                                            gap: '16px',
                                            marginRight: '1em',
                                            flexDirection: 'row',
                                            alignContent: 'flex-start',
                                        }}
                                    >
                                        {trackData?.map(track => (
                                            <div
                                                key={track?.trackId}
                                                onClick={() => {
                                                    setSelectedTrack(null);
                                                    setTimeout(() => {
                                                        setSelectedTrack(track);
                                                    }, 1);
                                                }}
                                                style={{ borderWidth: 1 }}
                                                className={`rounded  p-3 flex justify-center items-center text-xs cursor-pointer whitespace-nowrap ${
                                                    track?.trackId === selectedTrack?.trackId
                                                        ? 'bg-purple-950 text-white border-purple-950'
                                                        : 'bg-white border-purple-950 text-purple-950'
                                                }`}
                                            >
                                                {track?.trackName}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {selectedTrack ? (
                        <div className="flex flex-col mb-2 mt-6 ml-3 mr-3 gap-y-2">
                            <div className="flex flex-col justify-between gap-y-1">
                                <div className="font-manrope text-lg font-bold text-purple-950">
                                    Selected challenges for&nbsp;
                                    {selectedTrack?.trackName}
                                </div>
                                <div className="text-xs">
                                    {selectedTrack && selectedTrack.trackDescription}
                                </div>
                                <div className="flex flex-wrap pb-4 mt-2">
                                    {selectedTrack.tags?.map(tag => (
                                        <div
                                            key={tag.id}
                                            className="mr-2 my-2 bg-purple-200 text-purple-950 text-xs rounded-full px-3 py-1 w-max"
                                        >
                                            {tag.tagName}
                                        </div>
                                    ))}
                                </div>
                                <div className="flex mt-4 gap-x-6">
                                    <div className="font-bold font-manrope">
                                        Selected Challenges
                                    </div>
                                    <div className="text-xs font-normal bg-purple-100 text-purple-950 py-1 px-2 rounded-full">
                                        {
                                            selectedTrack?.challenges.filter(
                                                val => val.challengeStatus === 'PUBLISHED'
                                            ).length
                                        }
                                    </div>
                                </div>
                            </div>
                            {selectedTrack?.challenges.filter(
                                val => val.challengeStatus === 'PUBLISHED'
                            ).length > 0 ? (
                                <div className="w-full">
                                    {selectedTrack?.challenges?.map(
                                        challenge =>
                                            challenge.challengeStatus === 'PUBLISHED' && (
                                                <div
                                                    key={challenge.id}
                                                    className="flex gap-x-4 my-5"
                                                >
                                                    <img
                                                        style={{ width: '60px', height: '60px' }}
                                                        className="rounded-lg"
                                                        src={
                                                            challenge.imageLink
                                                                ? `${s3UrlPrefix}${challenge.imageLink}`
                                                                : `/assets/challenge-image-skeleton-square.png`
                                                        }
                                                    />
                                                    <div className="flex flex-col">
                                                        <div className="font-bold text-xs font-manrope">
                                                            {challenge?.challengeName}
                                                        </div>
                                                        <div>
                                                            <div
                                                                className="challengeDescription font-normal mt-3 mb-4 mr-2 w-11/12"
                                                                style={{ fontSize: 10 }}
                                                            >
                                                                {challenge.challengeDescription}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {challenge?.StatusValue === 1 && (
                                                                <div className="flex flex-col w-full">
                                                                    <div className="challenge-progress-line w-2/3 py-2">
                                                                        <Line
                                                                            percent={
                                                                                isNaN(
                                                                                    (challenge.earnedMarks /
                                                                                        challenge.totalMarks) *
                                                                                        100
                                                                                )
                                                                                    ? 0
                                                                                    : (challenge.earnedMarks /
                                                                                          challenge.totalMarks) *
                                                                                      100
                                                                            }
                                                                            strokeWidth={1}
                                                                            strokeColor={`${
                                                                                challenge.isChallengeRepublished
                                                                                    ? '#F16341'
                                                                                    : '#4C2EC2'
                                                                            }`}
                                                                        />
                                                                    </div>
                                                                    <div className="flex flex-row gap-x-2">
                                                                        <div
                                                                            className="font-bold"
                                                                            style={{ fontSize: 10 }}
                                                                        >
                                                                            {`${
                                                                                challenge.earnedMarks
                                                                                    ? challenge.earnedMarks
                                                                                    : 0
                                                                            } XP`}
                                                                        </div>
                                                                        {challenge.totalMarks -
                                                                            challenge.earnedMarks ===
                                                                        0 ? (
                                                                            <div
                                                                                style={{
                                                                                    fontSize: 10,
                                                                                }}
                                                                            >
                                                                                You have completed
                                                                                this challenge with
                                                                                100% marks
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                style={{
                                                                                    fontSize: 10,
                                                                                }}
                                                                            >
                                                                                Earn
                                                                                {` ${
                                                                                    challenge.totalMarks &&
                                                                                    challenge.earnedMarks
                                                                                        ? (challenge.totalMarks -
                                                                                              challenge.earnedMarks <
                                                                                          0
                                                                                              ? 0
                                                                                              : challenge.totalMarks -
                                                                                                challenge.earnedMarks
                                                                                          ).toFixed(
                                                                                              2
                                                                                          )
                                                                                        : challenge.earnedMarks.toFixed(
                                                                                              2
                                                                                          )
                                                                                } `}
                                                                                XP points more to
                                                                                climb up your
                                                                                position in the
                                                                                leaderboard
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {(challenge?.StatusValue === 2 ||
                                                                challenge?.StatusValue === 1) && (
                                                                <div>
                                                                    <button
                                                                        onClick={() => {
                                                                            if (
                                                                                challenge.isChallengeRepublished
                                                                            ) {
                                                                                setSelectedChallengeId(
                                                                                    challenge.challengeId
                                                                                );
                                                                                setSelectTrack(
                                                                                    selectedTrack
                                                                                );
                                                                                setSelectedChallenge(
                                                                                    challenge
                                                                                );
                                                                                setModal(true);
                                                                            } else {
                                                                                history.push({
                                                                                    pathname: `/dashBoard/learn/Challenges/${challenge.challengeId}`,
                                                                                    state: {
                                                                                        path: `/dashBoard/learn/Challenges`,
                                                                                        track: selectedTrack,
                                                                                        challenges:
                                                                                            challenge,
                                                                                    },
                                                                                });
                                                                            }
                                                                        }}
                                                                        className={`border-2 rounded font-bold text-sm h-10 px-5
                                                ${
                                                    challenge?.StatusValue === 1
                                                        ? challenge.isChallengeRepublished
                                                            ? 'bg-orange-550 text-white border-orange-550'
                                                            : 'bg-purple-950 text-white border-purple-950'
                                                        : 'bg-transparent text-purple-950 border-purple-950'
                                                }`}
                                                                    >
                                                                        {challenge?.StatusValue ===
                                                                        1
                                                                            ? 'Continue'
                                                                            : 'Get Started'}
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {challenge?.StatusValue === 0 && (
                                                            <div className="flex">
                                                                <div className="text-sm text-purple-950 font-bold h-10 pr-5 flex items-center">
                                                                    Completed
                                                                </div>
                                                                <button
                                                                    onClick={() => {
                                                                        if (
                                                                            challenge.isChallengeRepublished
                                                                        ) {
                                                                            setSelectedChallengeId(
                                                                                challenge.challengeId
                                                                            );
                                                                            setSelectTrack(
                                                                                selectedTrack
                                                                            );
                                                                            setSelectedChallenge(
                                                                                challenge
                                                                            );
                                                                            setModal(true);
                                                                        } else {
                                                                            history.push({
                                                                                pathname: `/dashBoard/learn/Challenges/${challenge.challengeId}`,
                                                                                state: {
                                                                                    path: `/dashBoard/learn/Challenges`,
                                                                                    track: selectedTrack,
                                                                                    challenges:
                                                                                        challenge,
                                                                                },
                                                                            });
                                                                        }
                                                                    }}
                                                                    className={`border-2 rounded font-bold text-sm h-10 px-5 ${
                                                                        challenge.isChallengeRepublished
                                                                            ? 'border-orange-550 text-orange-550'
                                                                            : 'border-purple-950 text-purple-950'
                                                                    }`}
                                                                >
                                                                    Try Again
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                    )}
                                </div>
                            ) : (
                                <div className="font-bold text-xs">
                                    Selected track doesn&#39;t have any challenges
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="flex align-middle items-center content-center mt-10">
                            <Loader />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Challenges;
