import {
    REQUEST_JOIN_FCB,
    REQUEST_JOIN_FCB_SUCCESS,
    REQUEST_JOIN_FCB_FAILED,
    CLEAR_JOIN_FCB_MESSAGES,
} from './actions';

export interface JoinFCBInitialState {
    requestResponce: any;
    isLoading: boolean;
}

const initialState: JoinFCBInitialState = {
    requestResponce: null,
    isLoading: false,
};

const joinFCBReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case REQUEST_JOIN_FCB:
            return { ...state, isLoading: true, mentorsError: null };
        case REQUEST_JOIN_FCB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                requestResponce: action.payload,
            };

        case REQUEST_JOIN_FCB_FAILED:
            return { ...state, isLoading: false, mentorsError: action.payload };

        case CLEAR_JOIN_FCB_MESSAGES:
            return {
                ...state,
                requestResponce: null,
                isLoading: false,
            };

        default:
            return state;
    }
};

export default joinFCBReducer;
