import React, { ReactElement } from 'react';

interface Props {
    width?: string;
    height?: string;
}

export default function EventType({ width, height }: Props): ReactElement {
    return (
        <svg
            width={width || '32'}
            height={height || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28 6C28 5.46957 27.7893 4.96086 27.4142 4.58579C27.0391 4.21071 26.5304 4 26 4H22V2H20V4H12V2H10V4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V26C4 26.5304 4.21071 27.0391 4.58579 27.4142C4.96086 27.7893 5.46957 28 6 28H10V26H6V6H10V8H12V6H20V8H22V6H26V12H28V6Z"
                fill="#4C2EC2"
            />
            <path
                d="M21 15L23.549 19.938L29 20.729L25 24.573L26 30L21 27.438L16 30L17 24.573L13 20.729L18.6 19.938L21 15Z"
                fill="#4C2EC2"
            />
        </svg>
    );
}
