import axios from 'axios';
import { API_URL } from '../../constants';
import { axiosConfig } from '../../utils/axiosApi';
import { getErrorMessage } from '../../utils/axiosErrorHandler';

const PREFIX = '@DASHBOARD';
export const GET_OVERVIEW_DATA = PREFIX + 'GET_OVERVIEW_DATA';
export const GET_OVERVIEW_DATA_SUCCESS = PREFIX + 'GET_OVERVIEW_DATA_SUCCESS';
export const GET_OVERVIEW_DATA_FAILED = PREFIX + 'GET_OVERVIEW_DATA_FAILED';
export const GET_STUDENT_REGISTRATION_DATA = PREFIX + 'GET_STUDENT_REGISTRATION_DATA';
export const GET_STUDENT_REGISTRATION_DATA_SUCCESS =
    PREFIX + 'GET_STUDENT_REGISTRATION_DATA_SUCCESS';
export const GET_STUDENT_REGISTRATION_DATA_FAILED = PREFIX + 'GET_STUDENT_REGISTRATION_DATA_FAILED';
export const GET_INSTITUTE_REGISTRATION_DATA = PREFIX + 'GET_INSTITUTE_REGISTRATION_DATA';
export const GET_INSTITUTE_REGISTRATION_DATA_SUCCESS =
    PREFIX + 'GET_INSTITUTE_REGISTRATION_DATA_SUCCESS';
export const GET_INSTITUTE_REGISTRATION_DATA_FAILED =
    PREFIX + 'GET_INSTITUTE_REGISTRATION_DATA_FAILED';
export const GET_COMPANY_REGISTRATION_DATA = PREFIX + 'GET_COMPANY_REGISTRATION_DATA';
export const GET_COMPANY_REGISTRATION_DATA_SUCCESS =
    PREFIX + 'GET_COMPANY_REGISTRATION_DATA_SUCCESS';
export const GET_COMPANY_REGISTRATION_DATA_FAILED = PREFIX + 'GET_COMPANY_REGISTRATION_DATA_FAILED';
export const GET_LEADERBOARD_DATA = PREFIX + 'GET_LEADERBOARD_DATA';
export const GET_LEADERBOARD_DATA_SUCCESS = PREFIX + 'GET_LEADERBOARD_DATA_SUCCESS';
export const GET_LEADERBOARD_DATA_FAILED = PREFIX + 'GET_LEADERBOARD_DATA_FAILED';
export const GET_STUDENT_RANK = PREFIX + 'GET_STUDENT_RANK';
export const GET_STUDENT_RANK_SUCCESS = PREFIX + 'GET_STUDENT_RANK_SUCCESS';
export const GET_STUDENT_RANK_FAILED = PREFIX + 'GET_STUDENT_RANK_FAILED';
export const GET_PROGRESSION_DATA = PREFIX + 'GET_PROGRESSION_DATA';
export const GET_PROGRESSION_DATA_SUCCESS = PREFIX + 'GET_PROGRESSION_DATA_SUCCESS';
export const GET_PROGRESSION_DATA_FAILED = PREFIX + 'GET_PROGRESSION_DATA_FAILED';
export const GET_HIREDFCB_DATA = PREFIX + 'GET_HIREDFCB_DATA';
export const GET_HIREDFCB_DATA_SUCCESS = PREFIX + 'GET_HIREDFCB_DATA_SUCCESS';
export const GET_HIREDFCB_DATA_FAILED = PREFIX + 'GET_HIREDFCB_DATA_FAILED';
export const GET_INSTITUTE_WISE_PROGRESSION_DATA = PREFIX + 'GET_INSTITUTE_WISE_PROGRESSION_DATA';
export const GET_INSTITUTE_WISE_PROGRESSION_DATA_SUCCESS =
    PREFIX + 'GET_INSTITUTE_WISE_PROGRESSION_DATA_SUCCESS';
export const GET_INSTITUTE_WISE_PROGRESSION_DATA_FAILED =
    PREFIX + 'GET_INSTITUTE_WISE_PROGRESSION_DATA_FAILED';

export const GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA =
    PREFIX + 'GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA';
export const GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA_SUCCESS =
    PREFIX + 'GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA_SUCCESS';
export const GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA_FAILED =
    PREFIX + 'GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA_FAILED';
export const GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA =
    PREFIX + 'GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA';
export const GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA_SUCCESS =
    PREFIX + 'GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA_SUCCESS';
export const GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA_FAILED =
    PREFIX + 'GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA_FAILED';

export const CLEAR_REGISTRATION_DATA_MESSAGES = PREFIX + 'CLEAR_REGISTRATION_DATA_MESSAGES';
export const GET_CATEGORIES = PREFIX + 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = PREFIX + 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILED = PREFIX + 'GET_CATEGORIES_FAILED';

export const DOWNLOAD_CSV_ALL_REGISTRATION = PREFIX + 'DOWNLOAD_CSV_ALL_REGISTRATION';
export const DOWNLOAD_CSV_ALL_REGISTRATION_SUCCESS =
    PREFIX + 'DOWNLOAD_CSV_ALL_REGISTRATION_SUCCESS';
export const DOWNLOAD_CSV_ALL_REGISTRATION_FAILED = PREFIX + 'DOWNLOAD_CSV_ALL_REGISTRATION_FAILED';
export const DOWNLOAD_CSV_ALL_REGISTRATION_RESET = PREFIX + 'DOWNLOAD_CSV_ALL_REGISTRATION_RESET';

export const DOWNLOAD_CSV_INSTITUTE_REGISTRATION = PREFIX + 'DOWNLOAD_CSV_INSTITUTE_REGISTRATION';
export const DOWNLOAD_CSV_INSTITUTE_REGISTRATION_SUCCESS =
    PREFIX + 'DOWNLOAD_CSV_INSTITUTE_REGISTRATION_SUCCESS';
export const DOWNLOAD_CSV_INSTITUTE_REGISTRATION_FAILED =
    PREFIX + 'DOWNLOAD_CSV_INSTITUTE_REGISTRATION_FAILED';
export const DOWNLOAD_CSV_INSTITUTE_REGISTRATION_RESET =
    PREFIX + 'DOWNLOAD_CSV_INSTITUTE_REGISTRATION_RESET';

export const DOWNLOAD_CSV_COMPANY_REGISTRATION = PREFIX + 'DOWNLOAD_CSV_COMPANY_REGISTRATION';
export const DOWNLOAD_CSV_COMPANY_REGISTRATION_SUCCESS =
    PREFIX + 'DOWNLOAD_CSV_COMPANY_REGISTRATION_SUCCESS';
export const DOWNLOAD_CSV_COMPANY_REGISTRATION_FAILED =
    PREFIX + 'DOWNLOAD_CSV_COMPANY_REGISTRATION_FAILED';
export const DOWNLOAD_CSV_COMPANY_REGISTRATION_RESET =
    PREFIX + 'DOWNLOAD_CSV_COMPANY_REGISTRATION_RESET';

export const DOWNLOAD_CSV_STUDENNT_PROGRESSION = PREFIX + 'DOWNLOAD_CSV_STUDENNT_PROGRESSION';
export const DOWNLOAD_CSV_STUDENNT_PROGRESSION_SUCCESS =
    PREFIX + 'DOWNLOAD_CSV_STUDENNT_PROGRESSION_SUCCESS';
export const DOWNLOAD_CSV_STUDENNT_PROGRESSION_FAILED =
    PREFIX + 'DOWNLOAD_CSV_STUDENNT_PROGRESSION_FAILED';
export const DOWNLOAD_CSV_STUDENNT_PROGRESSION_RESET =
    PREFIX + 'DOWNLOAD_CSV_STUDENNT_PROGRESSION_RESET';

export const DOWNLOAD_CSV_HIRED_BY_FCB = PREFIX + 'DOWNLOAD_CSV_HIRED_BY_FCB';
export const DOWNLOAD_CSV_HIRED_BY_FCB_SUCCESS = PREFIX + 'DOWNLOAD_CSV_HIRED_BY_FCB_SUCCESS';
export const DOWNLOAD_CSV_HIRED_BY_FCB_FAILED = PREFIX + 'DOWNLOAD_CSV_HIRED_BY_FCB_FAILED';
export const DOWNLOAD_CSV_HIRED_BY_FCB_RESET = PREFIX + 'DOWNLOAD_CSV_HIRED_BY_FCB_RESET';

export const DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION =
    PREFIX + 'DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION';
export const DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION_SUCCESS =
    PREFIX + 'DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION_SUCCESS';
export const DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION_FAILED =
    PREFIX + 'DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION_FAILED';

export const getOverviewData = () => async (dispatch: any) => {
    try {
        dispatch({ type: GET_OVERVIEW_DATA });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/dashboard/overview`, config);
        dispatch({
            type: GET_OVERVIEW_DATA_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_OVERVIEW_DATA_FAILED, payload: getErrorMessage(error) });
    }
};
export const getStudentRegistrationData =
    (period?: string, from?: any, to?: any) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_STUDENT_REGISTRATION_DATA });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/dashboard/registration/student?${period ? `period=${period}` : ''}${
                    from ? `&from=${from}` : ''
                }${to ? `&to=${to}` : ''}`,
                config
            );
            dispatch({
                type: GET_STUDENT_REGISTRATION_DATA_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({
                type: GET_STUDENT_REGISTRATION_DATA_FAILED,
                payload: getErrorMessage(error),
            });
        }
    };

export const getInstituteRegistrationData =
    (period?: string, institute?: string, from?: any, to?: any) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_INSTITUTE_REGISTRATION_DATA });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/dashboard/registration/institute?${
                    institute ? `institute=${institute}` : ''
                }${period ? `&period=${period}` : ''}${from ? `&from=${from}` : ''}${
                    to ? `&to=${to}` : ''
                }`,
                config
            );
            dispatch({
                type: GET_INSTITUTE_REGISTRATION_DATA_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({
                type: GET_INSTITUTE_REGISTRATION_DATA_FAILED,
                payload: getErrorMessage(error),
            });
        }
    };
export const getCompanyRegistrationData =
    (period?: string, company?: string, from?: any, to?: any) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_COMPANY_REGISTRATION_DATA });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/dashboard/registration/company?${company ? `company=${company}` : ''}${
                    period ? `&period=${period}` : ''
                }${from ? `&from=${from}` : ''}${to ? `&to=${to}` : ''}`,
                config
            );
            dispatch({
                type: GET_COMPANY_REGISTRATION_DATA_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({
                type: GET_COMPANY_REGISTRATION_DATA_FAILED,
                payload: getErrorMessage(error),
            });
        }
    };
export const getLeaderboardData =
    (trackId?: number, page?: number, size?: number) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_LEADERBOARD_DATA });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/leaderboard/?trackId=${trackId || ''}${`&page=${page | 0}`}${
                    size ? `&size=${size}` : ''
                }`,
                config
            );
            dispatch({
                type: GET_LEADERBOARD_DATA_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_LEADERBOARD_DATA_FAILED, payload: getErrorMessage(error) });
        }
    };

export const getCurrentStudentRank =
    (studentId: string, trackId?: number) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_STUDENT_RANK });
            const config = await axiosConfig(false);
            const response = await axios.get(
                `${API_URL}/leaderboard/rank?studentId=${studentId}${
                    trackId ? `&trackId=${trackId || ''}` : ''
                }`,
                config
            );
            dispatch({
                type: GET_STUDENT_RANK_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_STUDENT_RANK_FAILED, payload: getErrorMessage(error) });
        }
    };
export const getProgressionData =
    (period?: string, institute?: string, onlyInstitute?: boolean, from?: any, to?: any) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_PROGRESSION_DATA });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/dashboard/progress/student?${
                    institute ? `institute=${institute}` : ''
                }${period ? `&period=${period}` : ''}${from ? `&from=${from}` : ''}${
                    to ? `&to=${to}` : ''
                }${onlyInstitute ? `&onlyInstitute=${onlyInstitute}` : ''}`,
                config
            );
            dispatch({
                type: GET_PROGRESSION_DATA_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_PROGRESSION_DATA_FAILED, payload: getErrorMessage(error) });
        }
    };
export const getHiredFcbData = (period?: string, from?: any, to?: any) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_HIREDFCB_DATA });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/dashboard/progress/hired-fcb?${period ? `period=${period}` : ''}${
                from ? `&from=${from}` : ''
            }${to ? `&to=${to}` : ''}`,
            config
        );
        dispatch({
            type: GET_HIREDFCB_DATA_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_HIREDFCB_DATA_FAILED, payload: getErrorMessage(error) });
    }
};
export const getInstituteWiseProgressionData =
    (
        institute: string,
        trackId?: number,
        invitationCode?: string,
        category1?: string,
        category2?: string,
        category3?: string
    ) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_INSTITUTE_WISE_PROGRESSION_DATA });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/dashboard/progress/${
                    institute
                        ? `institute?${
                              trackId ? `trackId=${trackId}&` : ''
                          }instituteId=${institute}`
                        : ''
                }${category1 ? `&category1=${category1}` : ''}${
                    category2 ? `&category2=${category2}` : ''
                }${category3 ? `&category3=${category3}` : ''}${
                    invitationCode ? `&bridgeInvitationCode=${invitationCode}` : ''
                }    `,
                config
            );
            dispatch({
                type: GET_INSTITUTE_WISE_PROGRESSION_DATA_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({
                type: GET_INSTITUTE_WISE_PROGRESSION_DATA_FAILED,
                payload: getErrorMessage(error),
            });
        }
    };

export const getInstituteWiseProgressionRegisterData =
    (
        institute: string,
        trackId?: number,
        invitationCode?: string,
        category1?: string,
        category2?: string,
        category3?: string,
        chalengeIds?: string
    ) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/dashboard/progress/${
                    institute
                        ? `institute?${
                              trackId ? `trackId=${trackId}&` : ''
                          }instituteId=${institute}`
                        : ''
                }${category1 ? `&category1=${category1}` : ''}
                ${chalengeIds ? `&challengeIds=${chalengeIds}` : ''}&isProgressing=false${
                    category2 ? `&category2=${category2}` : ''
                }${category3 ? `&category3=${category3}` : ''}${
                    invitationCode ? `&bridgeInvitationCode=${invitationCode}` : ''
                }    `,
                config
            );
            dispatch({
                type: GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({
                type: GET_INSTITUTE_WISE_PROGRESSION_REGISTER_DATA_FAILED,
                payload: getErrorMessage(error),
            });
        }
    };

export const getInstituteWiseProgressionProgressData =
    (
        institute: string,
        trackId?: number,
        invitationCode?: string,
        category1?: string,
        category2?: string,
        category3?: string,
        chalengeIds?: string
    ) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/dashboard/progress/${
                    institute
                        ? `institute?${
                              trackId ? `trackId=${trackId}&` : ''
                          }instituteId=${institute}`
                        : ''
                }${category1 ? `&category1=${category1}` : ''}
                ${chalengeIds ? `&challengeIds=${chalengeIds}` : ''}&isProgressing=true${
                    category2 ? `&category2=${category2}` : ''
                }${category3 ? `&category3=${category3}` : ''}${
                    invitationCode ? `&bridgeInvitationCode=${invitationCode}` : ''
                }    `,
                config
            );
            dispatch({
                type: GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({
                type: GET_INSTITUTE_WISE_PROGRESSION_PROGRESS_DATA_FAILED,
                payload: getErrorMessage(error),
            });
        }
    };

export const getCategories = (instituteId?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_CATEGORIES });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/dashboard/progress/institute/categories${
                instituteId ? `?instituteId=${instituteId}` : ''
            }`,
            config
        );
        dispatch({
            type: GET_CATEGORIES_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({ type: GET_CATEGORIES_FAILED, payload: getErrorMessage(error) });
    }
};
export const clearDashboardMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_REGISTRATION_DATA_MESSAGES });
};

export const downloadCSVAllRegistration =
    (period?: string, from?: any, to?: any) => async (dispatch: any) => {
        try {
            dispatch({ type: DOWNLOAD_CSV_ALL_REGISTRATION });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/csv/registration/student?${from ? `startDate=${from}` : ''}${
                    to ? `&endDate=${to}` : ''
                }${period ? `&period=${period}` : ''}`,
                config
            );
            dispatch({
                type: DOWNLOAD_CSV_ALL_REGISTRATION_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({
                type: DOWNLOAD_CSV_ALL_REGISTRATION_FAILED,
                payload: getErrorMessage(error),
            });
        }
    };

export const downloadCSVInstituteRegistration =
    (instituteId?: any, period?: string, from?: any, to?: any) => async (dispatch: any) => {
        try {
            dispatch({ type: DOWNLOAD_CSV_INSTITUTE_REGISTRATION });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/csv/registration/institute/student?${from ? `startDate=${from}` : ''}${
                    to ? `&endDate=${to}` : ''
                }${period ? `&period=${period}` : ''}${
                    instituteId ? `&instituteId=${instituteId}` : ''
                }`,
                config
            );
            dispatch({
                type: DOWNLOAD_CSV_INSTITUTE_REGISTRATION_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({
                type: DOWNLOAD_CSV_INSTITUTE_REGISTRATION_FAILED,
                payload: getErrorMessage(error),
            });
        }
    };

export const downloadCSVCompanyRegistration =
    (instituteId?: any, period?: string, from?: any, to?: any) => async (dispatch: any) => {
        try {
            dispatch({ type: DOWNLOAD_CSV_COMPANY_REGISTRATION });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/csv/registration/company/student?${from ? `startDate=${from}` : ''}${
                    to ? `&endDate=${to}` : ''
                }${period ? `&period=${period}` : ''}${
                    instituteId ? `&instituteId=${instituteId}` : ''
                }`,
                config
            );
            dispatch({
                type: DOWNLOAD_CSV_COMPANY_REGISTRATION_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({
                type: DOWNLOAD_CSV_COMPANY_REGISTRATION_FAILED,
                payload: getErrorMessage(error),
            });
        }
    };

export const downloadCSVStudentProgression =
    (instituteId?: any, onlyInstitutes?: boolean, period?: string, from?: any, to?: any) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: DOWNLOAD_CSV_STUDENNT_PROGRESSION });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/csv/progression/student?${from ? `startDate=${from}` : ''}${
                    to ? `&endDate=${to}` : ''
                }${period ? `&period=${period}` : ''}${
                    instituteId ? `&instituteId=${instituteId}` : ''
                }${onlyInstitutes ? `&onlyInstitutes=${onlyInstitutes}` : ''}`,
                config
            );
            dispatch({
                type: DOWNLOAD_CSV_STUDENNT_PROGRESSION_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({
                type: DOWNLOAD_CSV_STUDENNT_PROGRESSION_FAILED,
                payload: getErrorMessage(error),
            });
        }
    };

export const downloadCSVHiredByFCB =
    (period?: string, from?: any, to?: any) => async (dispatch: any) => {
        try {
            dispatch({ type: DOWNLOAD_CSV_HIRED_BY_FCB });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/csv/progression/hiredThroughFcb?${from ? `startDate=${from}` : ''}${
                    to ? `&endDate=${to}` : ''
                }${period ? `&period=${period}` : ''}`,
                config
            );
            dispatch({
                type: DOWNLOAD_CSV_HIRED_BY_FCB_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: DOWNLOAD_CSV_HIRED_BY_FCB_FAILED, payload: getErrorMessage(error) });
        }
    };

export const downloadCSVInstitutewiseProgression = id => async (dispatch: any) => {
    try {
        dispatch({ type: DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION });
        const config = await axiosConfig(true);
        const response = await axios.get(
            `${API_URL}/csv/progression/instituteWise?instituteId=${id}`,
            config
        );
        dispatch({
            type: DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION_SUCCESS,
            payload: response.data.responseDto,
        });
    } catch (error) {
        dispatch({
            type: DOWNLOAD_CSV_INSTITUTEWISE_PROGRESSION_FAILED,
            payload: getErrorMessage(error),
        });
    }
};
