import axios from 'axios';
import { API_URL, deleteChallengeSuccessMessage } from '../../constants';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { ChallengeCreateType } from './reducers';
import { students, trackCreatedDate, trackLastUpdatedDate } from '../tracks/actions';
import { axiosConfig } from '../../utils/axiosApi';

const PREFIX = '@CHALLENGES_';

export const GET_CHALLENGES = PREFIX + 'GET_CHALLENGES';
export const GET_CHALLENGES_SUCCESS = PREFIX + 'GET_CHALLENGES_SUCCESS';
export const GET_CHALLENGES_FAILED = PREFIX + 'GET_CHALLENGES_FAILED';
export const GET_STUDENT_TRACKS_CHALLENGES = PREFIX + 'GET_STUDENT_TRACKS_CHALLENGES';
export const GET_STUDENT_TRACKS_CHALLENGES_SUCCESS =
    PREFIX + 'GET_STUDENT_TRACKS_CHALLENGES_SUCCESS';
export const GET_STUDENT_TRACKS_CHALLENGES_FAILED = PREFIX + 'GET_STUDENT_TRACKS_CHALLENGES_FAILED';
export const GET_CHALLENGEBYID = PREFIX + 'GET_CHALLENGEBYID';
export const GET_CHALLENGEBYID_SUCCESS = PREFIX + 'GET_CHALLENGEBYID_SUCCESS';
export const GET_CHALLENGEBYID_FAILED = PREFIX + 'GET_CHALLENGEBYID_FAILED';
export const GET_CHALLENGE_BY_ID_BY_STUDENT = PREFIX + 'GET_CHALLENGE_BY_ID_BY_STUDENT';
export const GET_CHALLENGE_BY_ID_BY_STUDENT_SUCCESS =
    PREFIX + 'GET_CHALLENGE_BY_ID_BY_STUDENT_SUCCESS';
export const GET_CHALLENGE_BY_ID_BY_STUDENT_FAILED =
    PREFIX + 'GET_CHALLENGE_BY_ID_BY_STUDENT_FAILED';
export const ADD_CHALLENGE = PREFIX + 'ADD_CHALLENGE';
export const ADD_CHALLENGE_SUCCESS = PREFIX + 'ADD_CHALLENGE_SUCCESS';
export const ADD_CHALLENGE_FAILED = PREFIX + 'ADD_CHALLENGE_FAILED';
export const ADD_MATERIAL = PREFIX + 'ADD_MATERIAL';
export const ADD_MATERIAL_SUCCESS = PREFIX + 'ADD_MATERIAL_SUCCESS';
export const ADD_MATERIAL_FAILED = PREFIX + 'ADD_MATERIAL_FAILED';
export const EDIT_CHALLENGE = PREFIX + 'EDIT_CHALLENGE';
export const EDIT_CHALLENGE_SUCCESS = PREFIX + 'EDIT_CHALLENGE_SUCCESS';
export const EDIT_CHALLENGE_FAILED = PREFIX + 'EDIT_CHALLENGE_FAILED';
export const DELETE_CHALLENGE = PREFIX + 'DELETE_CHALLENGE';
export const DELETE_CHALLENGE_SUCCESS = PREFIX + 'DELETE_CHALLENGE_SUCCESS';
export const DELETE_CHALLENGE_FAILED = PREFIX + 'DELETE_CHALLENGE_FAILED';
export const CHALLENGE_BULK_UPDATE = PREFIX + 'CHALLENGE_BULK_UPDATE';
export const CHALLENGE_BULK_UPDATE_SUCCESS = PREFIX + 'CHALLENGE_BULK_UPDATE_SUCCESS';
export const CHALLENGE_BULK_UPDATE_FAILED = PREFIX + 'CHALLENGE_BULK_UPDATE_FAILED';
export const GET_CHALLENGE_DOCUMENT_PRESIGNED = PREFIX + 'GET_CHALLENGE_DOCUMENT_PRESIGNED';
export const GET_CHALLENGE_DOCUMENT_PRESIGNED_SUCCESS =
    PREFIX + 'GET_CHALLENGE_DOCUMENT_PRESIGNED_SUCCESS';
export const GET_CHALLENGE_DOCUMENT_PRESIGNED_FAILED =
    PREFIX + 'GET_CHALLENGE_DOCUMENT_PRESIGNED_FAILED';
export const UPLOAD_ASSESSMENT = PREFIX + 'UPLOAD_ASSESSMENT';
export const UPLOAD_ASSESSMENT_SUCCESS = PREFIX + 'UPLOAD_ASSESSMENT_SUCCESS';
export const UPLOAD_ASSESSMENT_FAILED = PREFIX + 'UPLOAD_ASSESSMENT_FAILED';
export const CLEAR_CHALLENGE_MESSAGES = PREFIX + 'CLEAR_CHALLENGE_MESSAGES';

export interface ChallengeTabCreateType {
    id?: string | null;
    tabName?: string | null;
    tags?: any[];
    students?: students;
    tracks?: any[];
    trackLastUpdatedDate?: trackLastUpdatedDate;
    trackLastUpdatedBy?: string | null;
    trackCreatedBy?: string | null;
    trackCreatedDate?: trackCreatedDate;
    trackName?: string | null;
    program?: number;
}

export const getChallenges =
    (page?: number, size?: number, status?: string, tags?: string, searchTerm?: string) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: GET_CHALLENGES });
            const config = await axiosConfig(true);
            const response = await axios.get(
                `${API_URL}/challenges?page=${page || 0}&size=${size || 10}${
                    status ? `&status=${status}` : ''
                }${searchTerm ? `&searchText=${searchTerm}` : ''}${tags ? `&tags=${tags}` : ''}`,
                config
            );
            dispatch({
                type: GET_CHALLENGES_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({ type: GET_CHALLENGES_FAILED, payload: getErrorMessage(error) });
        }
    };

export const getChallengeById = (id?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_CHALLENGEBYID });
        const config = await axiosConfig(true);
        const response = await axios.get(`${API_URL}/challenges/${id}`, config);
        dispatch({ type: GET_CHALLENGEBYID_SUCCESS, payload: response.data.responseDto });
    } catch (error) {
        dispatch({ type: GET_CHALLENGEBYID_FAILED, payload: getErrorMessage(error) });
    }
};

export const getChallengeByIdForStudent =
    (studentId: number, challengeId: string) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_CHALLENGE_BY_ID_BY_STUDENT });
            const config = await axiosConfig(false);
            const response = await axios.get(
                `${API_URL}/challenges/${challengeId}/student?studentId=${studentId}`,
                config
            );
            dispatch({
                type: GET_CHALLENGE_BY_ID_BY_STUDENT_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({
                type: GET_CHALLENGE_BY_ID_BY_STUDENT_FAILED,
                payload: getErrorMessage(error),
            });
        }
    };

export const getStudentTracksChallenges =
    (studentId: number | string, trackId: number) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_STUDENT_TRACKS_CHALLENGES });
            const config = await axiosConfig(false);
            const response = await axios.get(
                `${API_URL}/challenges/track/student?studentId=${studentId}&trackId=${trackId}`,
                config
            );
            dispatch({
                type: GET_STUDENT_TRACKS_CHALLENGES_SUCCESS,
                payload: response.data.responseDto,
            });
        } catch (error) {
            dispatch({
                type: GET_STUDENT_TRACKS_CHALLENGES_FAILED,
                payload: getErrorMessage(error),
            });
        }
    };

export const addMaterial =
    (id: string, description?: string, questionnaire?: any) => async (dispatch: any) => {
        try {
            dispatch({ type: ADD_MATERIAL });
            const config = await axiosConfig(true);
            const res = await axios.put(
                `${API_URL}/challenges/${id}/material`,
                {
                    description: description,
                    questionnaire: questionnaire,
                },
                config
            );
            dispatch({ type: ADD_MATERIAL_SUCCESS, payload: res.data.responseDto });
        } catch (error) {
            dispatch({ type: ADD_MATERIAL_FAILED, payload: getErrorMessage(error) });
        }
    };

export const addChallenge =
    (challenge: any, description?: string, questionnaire?: any) => async (dispatch: any) => {
        try {
            dispatch({ type: ADD_CHALLENGE });
            const config = await axiosConfig(true);
            const res = await axios.post(`${API_URL}/challenges/`, challenge, config);
            if (res?.data?.status) {
                if (res?.data?.errorCode === 0) {
                    if (description || questionnaire) {
                        await dispatch(
                            addMaterial(res?.data?.responseDto?.meta, description, questionnaire)
                        );
                        await dispatch(getChallenges());
                    }
                    await dispatch({
                        type: ADD_CHALLENGE_SUCCESS,
                        payload: res.data.responseDto.responseString,
                    });
                }
            }
            if (!res?.data?.status) {
                if (res?.data?.errorCode !== 0) {
                    dispatch({
                        type: ADD_CHALLENGE_FAILED,
                        payload: res.data.errorDescription,
                    });
                }
            }
        } catch (error) {
            dispatch({ type: ADD_CHALLENGE_FAILED, payload: getErrorMessage(error) });
        }
    };

export const editChallenge =
    (id: string, challenge: any, status?: string, description?: string, questionnaire?: any) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: EDIT_CHALLENGE });
            const config = await axiosConfig(true);
            const res = await axios.put(`${API_URL}/challenges/${id}`, challenge, config);
            if (res?.data?.status) {
                if (description || questionnaire) {
                    await dispatch(addMaterial(id, description, questionnaire));
                }
                await dispatch(getChallenges(0, 10, status));
                dispatch({ type: EDIT_CHALLENGE_SUCCESS, payload: res.data.responseDto });
            }
            if (!res?.data?.status) {
                if (res?.data?.errorCode !== 212) {
                    dispatch({
                        type: EDIT_CHALLENGE_FAILED,
                        payload: res.data.errorDescription,
                    });
                }
            }
        } catch (error) {
            dispatch({ type: EDIT_CHALLENGE_FAILED, payload: getErrorMessage(error) });
        }
    };

export const deleteChallenge = (id: string, status?: string) => async (dispatch: any) => {
    try {
        dispatch({ type: DELETE_CHALLENGE });
        const config = await axiosConfig(true);
        await axios.delete(`${API_URL}/challenges/${id}`, config);
        await dispatch(getChallenges(0, 10, status));
        dispatch({ type: DELETE_CHALLENGE_SUCCESS, payload: deleteChallengeSuccessMessage });
    } catch (error) {
        dispatch({ type: DELETE_CHALLENGE_FAILED, payload: getErrorMessage(error) });
    }
};

export const bulkUpdateChallenges = (challenges, tabStatus) => async (dispatch: any) => {
    try {
        dispatch({ type: CHALLENGE_BULK_UPDATE });
        const config = await axiosConfig(true);
        const res = await axios.post(`${API_URL}/challenges/bulk`, challenges, config);
        await dispatch(getChallenges(0, 10, tabStatus));
        if (res?.data?.status) {
            if (res?.data?.errorCode === 202)
                dispatch({ type: CHALLENGE_BULK_UPDATE_SUCCESS, payload: res.data.responseDto });
        }
        if (!res?.data?.status) {
            if (res?.data?.errorCode !== 202) {
                dispatch({
                    type: CHALLENGE_BULK_UPDATE_FAILED,
                    payload: res.data.errorDescription,
                });
            }
        }
    } catch (error) {
        dispatch({ type: CHALLENGE_BULK_UPDATE_FAILED, payload: getErrorMessage(error) });
    }
};

export const uploadAssessmentDocument = (url: string, file: any) => async (dispatch: any) => {
    try {
        dispatch({ type: UPLOAD_ASSESSMENT });
        const headers = {
            'Content-Type': file?.type,
        };
        const res = await axios({
            method: 'put',
            url: url,
            data: file,
            headers,
        });
        dispatch({ type: UPLOAD_ASSESSMENT_SUCCESS, payload: res.data });
    } catch (error) {
        dispatch({ type: UPLOAD_ASSESSMENT_FAILED, payload: getErrorMessage(error) });
    }
};

export const getPresignedUrlChallengeFile =
    (isAdmin: boolean, fileName: string, file) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_CHALLENGE_DOCUMENT_PRESIGNED });
            const config = await axiosConfig(isAdmin);
            const response = await axios.post(
                `${API_URL}/files?fileName=${fileName}&path=assessment`,
                null,
                config
            );
            if (response.status === 200) {
                dispatch(uploadAssessmentDocument(response.data, file));
                dispatch({
                    type: GET_CHALLENGE_DOCUMENT_PRESIGNED_SUCCESS,
                    payload: response.data.responseDto,
                });
            }
            return response.data;
        } catch (error) {
            dispatch({
                type: GET_CHALLENGE_DOCUMENT_PRESIGNED_FAILED,
                payload: getErrorMessage(error),
            });
        }
    };

export const clearChallengeMessages = () => (dispatch: any) => {
    dispatch({ type: CLEAR_CHALLENGE_MESSAGES });
};
