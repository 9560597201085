import {
    CLEAR_AUTH,
    LOGIN,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    LOGOUT,
    PASSWORD_RESET,
    PASSWORD_RESET_FAILED,
    PASSWORD_RESET_SUCCESS,
    SEND_EMAIL_TO_RESET_PASSWORD,
    SEND_EMAIL_TO_RESET_PASSWORD_FAILED,
    SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS,
} from './actions';

export const SUPER_ADMIN = 'SUPER_ADMIN';
export const SLASSCOM_ADMIN = 'SLASSCOM_ADMIN';
export const COMPANY_ADMIN = 'COMPANY_ADMIN';
export const INSTITUTE_ADMIN = 'INSTITUTE_ADMIN';
export const STUDENT = 'STUDENT';
export interface UserProfileType {
    username: string;
    token: string;
    refreshToken: string;
    userId: string;
    email: string;
    roles: [];
    companyId: string;
    companyName: string;
    instituteId: string;
    instituteName: string;
}
export interface AuthInitialState {
    isLoading: boolean;
    loginResponse: UserProfileType | null;
    loginErrorMessage: string | null;
    isLogout: boolean;
    isResetLoading: boolean;
    resetResponse: any | null;
    resetError: string | null;
    isEmailSending: boolean;
    emailSentResponse: any | null;
    emailSentError: string | null;
}

export const initialState: AuthInitialState = {
    isLoading: false,
    loginResponse: null,
    loginErrorMessage: null,
    isLogout: false,
    isResetLoading: false,
    resetResponse: null,
    resetError: null,
    isEmailSending: false,
    emailSentResponse: null,
    emailSentError: null,
};
const authReducer = (state = initialState, action: any): AuthInitialState => {
    switch (action.type) {
        case LOGIN:
            return { ...state, isLoading: true, loginErrorMessage: null };
        case LOGIN_SUCCESS:
            return { ...state, isLoading: false, loginResponse: action.payload, isLogout: false };
        case LOGIN_FAILED:
            return { ...state, isLoading: false, loginErrorMessage: action.payload };
        case PASSWORD_RESET:
            return { ...state, isResetLoading: true, resetError: null };
        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                isResetLoading: false,
                resetResponse: action.payload,
            };
        case PASSWORD_RESET_FAILED:
            return { ...state, isResetLoading: false, resetError: action.payload };

        case SEND_EMAIL_TO_RESET_PASSWORD:
            return { ...state, isEmailSending: true, emailSentError: null };
        case SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isEmailSending: false,
                emailSentResponse: action.payload,
            };
        case SEND_EMAIL_TO_RESET_PASSWORD_FAILED:
            return { ...state, isEmailSending: false, emailSentError: action.payload };

        case CLEAR_AUTH:
            return {
                ...state,
                loginErrorMessage: null,
                loginResponse: null,
                resetResponse: null,
                resetError: null,
                emailSentResponse: null,
                emailSentError: null,
            };

        case LOGOUT:
            return {
                ...state,
                isLogout: true,
            };

        default:
            return state;
    }
};

export default authReducer;
