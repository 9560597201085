import React, { ReactElement, useEffect, useState } from 'react';
import Modal from 'react-modal';
import Add from '../../../assets/svg/Add';
import { months } from '../../../constants';
import AddTabModal from './AddTabModal';

interface Props {
    isSelected: boolean;
    setIsSelected: (value: boolean) => void;
    onAdd: () => void;
    tabName: string;
    setTabName: (tabName) => void;
    tags: any[];
    setTags: (value: any[]) => void;
    companies: string;
    setCompanies: (value: string) => void;
}

export default function AddTabs({
    isSelected,
    setIsSelected,
    onAdd,
    tabName,
    setTabName,
    tags,
    setTags,
    companies,
    setCompanies,
}: Props): ReactElement {
    const year = new Date().getFullYear();

    const EndMonth = months[new Date().getMonth()];

    const date = new Date(year, new Date().getMonth(), 1);
    const days = [];
    while (date.getMonth() === new Date().getMonth()) {
        days.push(new Date(date).getDate());
        date.setDate(date.getDate() + 1);
    }

    const secondDate = new Date(year, months.indexOf(EndMonth), 1);
    const Enddays = [];
    while (secondDate.getMonth() === months.indexOf(EndMonth)) {
        Enddays.push(new Date(secondDate).getDate());
        secondDate.setDate(secondDate.getDate() + 1);
    }

    const years = [];
    let startYear = new Date().getFullYear();
    const maxYear = startYear + 10;
    for (let i = startYear; i <= maxYear; i++) {
        years.push(startYear);
        startYear++;
    }

    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    useEffect(() => {
        if (isSelected === true) {
            document.body.style.overflow = 'hidden';
        } else if (isSelected === false) {
            document.body.style.overflow = 'auto';
        }
    }, [isSelected]);

    const [addedTab, setAddedTab] = useState([1]);

    const addTask = () => {
        let i = addedTab.length - 1;
        if (addedTab.length < 5) {
            setAddedTab([...addedTab, addedTab[i] + 1]);
            i += 1;
        }
    };

    return (
        <Modal isOpen={isSelected} style={customStyles}>
            <>
                <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-2/5 my-6 mx-auto">
                        {/* content */}
                        <div
                            className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
                            style={{ maxHeight: '656px' }}
                        >
                            {/* header */}
                            <div>
                                <h1 className="mb-2 text-xl font-medium">Add new tab</h1>
                            </div>
                            <div className="overflow-y-auto">
                                <div className="flex flex-col my-1.5 w-full md:w-3/4">
                                    <label className="flex flex-col mb-1.5" htmlFor="tab_1">
                                        Enter tab name
                                    </label>
                                    <input
                                        className="border-2 border-gray-300 rounded pl-2 py-1"
                                        type="text"
                                        id="tab_1"
                                        placeholder="Enter tab name"
                                        value={tabName}
                                        onChange={e => {
                                            setTabName(e.target.value);
                                        }}
                                    />
                                </div>
                                {/* body */}
                                <AddTabModal
                                    tags={tags}
                                    setTags={setTags}
                                    companies={companies}
                                    setCompanies={setCompanies}
                                />
                                {addedTab.includes(2) ? (
                                    <AddTabModal
                                        tags={tags}
                                        setTags={setTags}
                                        companies={companies}
                                        setCompanies={setCompanies}
                                    />
                                ) : null}
                            </div>

                            <div className="mt-1.5 ">
                                <button
                                    className=" text-purple-600 flex items-start whitespace-nowrap"
                                    onClick={addTask}
                                >
                                    <Add size={25} />
                                    <p className="border-b-2 border-purple-500 ml-2">
                                        Add another criteria
                                    </p>
                                </button>
                            </div>

                            {/* footer */}
                            <div className="flex items-center justify-end mb-4 rounded-b mt-8">
                                <input
                                    className="text-purple-500 border rounded border-purple-500 bg-white hover:shadow-2xl font-bold uppercase px-6 py-2 text-sm cursor-pointer outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    value="Close"
                                    onClick={() => setIsSelected(false)}
                                />
                                <input
                                    className="text-white border rounded border-white bg-purple-500 hover:shadow-2xl font-bold uppercase px-6 py-2 text-sm cursor-pointer outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="submit"
                                    value="Add"
                                    onClick={() => {
                                        onAdd();
                                        setIsSelected(false);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Modal>
    );
}
