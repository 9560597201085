import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as MdIcons from 'react-icons/md';

export const assessmentItems = [
    { id: 0, val: 'Header', type: 'header', icon: <FaIcons.FaHeading /> },
    { id: 1, val: 'Paragraph', type: 'paragraph', icon: <FaIcons.FaParagraph /> },
    { id: 2, val: 'MCQ', type: 'mcq', icon: <FaIcons.FaListOl /> },
    { id: 3, val: 'Short Answer', type: 'short', icon: <MdIcons.MdShortText /> },
    { id: 4, val: 'Long Answer', type: 'long', icon: <FaIcons.FaAlignJustify /> },
    { id: 5, val: 'File Upload', type: 'file', icon: <FaIcons.FaFileUpload /> },
];

export const assessmentBuilder = type => {
    switch (type) {
        case 'header':
            return {
                type: 'header',
                label: 'Header',
            };
            break;
        case 'paragraph':
            return {
                type: 'paragraph',
                label: 'Paragraph',
                name: `radio-group-${Math.floor(Math.random() * 10 ** 10)}`,
            };
            break;

        case 'mcq':
            return {
                type: 'radio-group',
                label: 'MCQ',
                name: `radio-group-${Math.floor(Math.random() * 10 ** 10)}`,
                answer: 'option-1 form-control',
                values: [
                    {
                        label: 'Option 1',
                        value: 'option-1',
                    },
                    {
                        label: 'Option 2',
                        value: 'option-2',
                    },
                ],
            };
            break;
        case 'short':
            return {
                type: 'text',
                label: 'Short Answer Question',
                name: `text-${Math.floor(Math.random() * 10 ** 10)}`,
            };

            break;
        case 'long':
            return {
                type: 'textarea',
                label: 'Long Answer Question',
                name: `textarea-${Math.floor(Math.random() * 10 ** 10)}`,
            };
            break;
        default:
            return {
                type: 'file',
                label: 'Upload File',
                name: `file-${Math.floor(Math.random() * 10 ** 10)}`,
            };
    }
};
