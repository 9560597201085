import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import * as BiIcons from 'react-icons/bi';
import {
    clearCompaniesMessages,
    editCompany,
    getCompanyById,
    editUser,
    getUserById,
    clearUsersMessages,
} from '../../../redux/rootActions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { error, phoneRegExp, s3Config, s3UrlPrefix } from '../../../constants';
import S3 from 'react-aws-s3';
import Loader from '../../../assets/svg/Loader';
import { COMPANY_ADMIN, UserProfileType } from '../../../redux/authAdmin/reducers';
import Camera from '../../../assets/svg/Camera';
import { getUserProfile } from '../../../utils/storage';
import CompanySuccessModal from '../../modals/CompanySusscessModal';
import CropModal from '../../modals/cropModal';
interface Props {
    editProfileClicked: boolean;
    setEditProfileClicked: (value: boolean) => void;
}
const EditCompanyProfile = ({ editProfileClicked, setEditProfileClicked }: Props) => {
    const dispatch = useDispatch();
    const formRef = useRef<any>();
    const company = useSelector((state: RootState) => state.companies.company);
    const isEditLoading = useSelector((state: RootState) => state.users.isEditLoading);
    const editCompanyError = useSelector((state: RootState) => state.companies.editCompanyError);
    const updateSuccessMessage = useSelector(
        (state: RootState) => state.companies.companyUpdateSuccessMessage
    );
    const updateUserSuccessMessage = useSelector(
        (state: RootState) => state.users.UserUpdateSuccessMessage
    );
    const user = useSelector((state: RootState) => state.users.User);
    const profile: UserProfileType | any = getUserProfile();

    const [imageLocation, setImageLocation] = useState(null);
    const [logoLocation, setLogoLocation] = useState(null);

    const [imageUrl, setImageUrl] = useState(null);
    const [logoUrl, setLogoUrl] = useState(null);

    const [isUploading, setIsUploading] = useState(false);
    const [isLogoUploading, setIsLogoUploading] = useState(false);

    const [editSuccessVisible, setEditSuccessVisible] = useState(false);

    const [uploadError, setUploadError] = useState(false);
    const [uploadLogoError, setUploadLogoError] = useState(false);

    const [openCrop, setOpenCrop] = useState(false);
    const [openLogoCrop, setLogoOpenCrop] = useState(false);

    const uploadImageToS3 = async croppedImg => {
        setIsUploading(true);
        const imageName = Date.now().toString();
        s3Config.dirName = `companies/${imageName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(croppedImg, imageName)
            .then(data => {
                setImageLocation(data.key);
                setIsUploading(false);
                setUploadError(false);
            })
            .catch(err => {
                console.error(err);
                setUploadError(true);
                setIsUploading(false);
            });
    };
    const handleImage = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setImageUrl(URL.createObjectURL(file));
            setOpenCrop(true);
        }
    };
    const uploadLogoToS3 = async croppedImg => {
        setIsLogoUploading(true);
        const imageName = Date.now().toString();
        s3Config.dirName = `companies/${imageName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(croppedImg, imageName)
            .then(data => {
                setLogoLocation(data.key);
                setIsLogoUploading(false);
                setUploadLogoError(false);
            })
            .catch(err => {
                console.error(err);
                setIsLogoUploading(false);
                setUploadLogoError(true);
            });
    };
    const handleLogo = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setLogoUrl(URL.createObjectURL(file));
            setLogoOpenCrop(true);
        }
    };
    const handleCloseCropModal = () => {
        setOpenCrop(false);
        setImageUrl(null);
    };
    const handleCloseLogoCropModal = () => {
        setLogoOpenCrop(false);
        setLogoUrl(null);
    };
    const handleClearMessages = useCallback(() => {
        if (editCompanyError || updateSuccessMessage || updateUserSuccessMessage) {
            setEditSuccessVisible(true);
        }
    }, [dispatch, updateSuccessMessage, editCompanyError, updateUserSuccessMessage]);
    const handleClose = () => {
        dispatch(clearCompaniesMessages());
        dispatch(clearUsersMessages());
        setEditSuccessVisible(false);
    };
    useEffect(() => {
        dispatch(getCompanyById(profile?.companyId));
    }, [dispatch]);

    useEffect(() => {
        setImageLocation(company?.bannerImage);
        setLogoLocation(company?.comImage);
    }, [company]);

    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    return (
        <div className="mx-6 lg:ml-2 h-full">
            <div className="bg-snow-600">
                <div className="relative">
                    <Formik
                        enableReinitialize={true}
                        innerRef={formRef}
                        initialValues={{
                            comName: company?.comName,
                            comEmail: company?.comEmail,
                            contactNo: company?.contactNo,
                            repName: company?.repName,
                            accountType: company?.accountType,
                            slassMember: company?.slassMember,
                            comDescription: company?.comDescription,
                            comJoinedDate: company?.comJoinedDate,
                            image: company?.bannerImage,
                            logo: company?.comImage,
                            subStartDate: company?.subStartDate,
                            subEndDate: company?.subEndDate,
                            siteUrl: company?.siteUrl,
                            careerOpportunities: company?.careerOpportunities,
                            applyURL: company?.applyURL,
                            adminFname: user?.firstName,
                            adminLname: user?.lastName,
                            adminEmail: user?.emailId,
                            adminContactNo: user?.contact?.mobileNo,
                        }}
                        validationSchema={Yup.object({
                            comName: Yup.string()
                                .max(100, 'Company Name should be less than 100 characters')
                                .required('Company Name is required'),
                            repName: Yup.string().required('Representative name is required'),
                            comEmail: Yup.string()
                                .email('Invalid email')
                                .required('Email is required'),
                            contactNo: Yup.string()
                                .nullable()
                                .matches(phoneRegExp, 'Contact number is not valid')
                                .required('Contact number is required'),
                            slassMember: Yup.boolean(),
                            // subStartDate: Yup.date().when('slassMember', slassMember => {
                            //     if (slassMember)
                            //         return Yup.date().required(
                            //             'Subscription start date is required'
                            //         );
                            // }),
                            // subEndDate: Yup.date().when('slassMember', slassMember => {
                            //     if (slassMember)
                            //         return Yup.date().required('Subscription end date is required');
                            // }),
                            comDescription: Yup.string()
                                .nullable()
                                .max(500, 'Description should be less than 500 characters')
                                .required('Description is required'),
                            siteUrl: Yup.string()
                                .nullable()
                                .matches(
                                    // eslint-disable-next-line no-useless-escape
                                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                                    'Enter correct url!'
                                ),
                            image: Yup.string()
                                .nullable()
                                .required('Company banner image is required'),
                            logo: Yup.string().nullable().required('Company Logo is required'),
                            careerOpportunities: Yup.string()
                                .nullable()
                                .max(
                                    1000,
                                    'Career Oppotunities should be less than 1000 characters'
                                ),
                            applyURL: Yup.string()
                                .nullable()
                                .matches(
                                    // eslint-disable-next-line no-useless-escape
                                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                                    'Enter correct url!'
                                ),
                            adminFname: Yup.string()
                                .nullable()
                                .required('Admin first name is required'),
                            adminLname: Yup.string()
                                .nullable()
                                .required('Admin last name is required'),
                            adminContactNo: Yup.string()
                                .nullable()
                                .required('Admin contact number is required'),
                        })}
                        onSubmit={async values => {
                            await dispatch(
                                editCompany(
                                    company?.id,
                                    {
                                        comName: values.comName,
                                        comEmail: values.comEmail,
                                        contactNo: values.contactNo,
                                        repName: values.repName,
                                        accountType: values.accountType,
                                        comDescription: values.comDescription,
                                        slassMember: values.slassMember,
                                        subStartDate: values.subStartDate,
                                        subEndDate: values.subEndDate,
                                        comImage: logoLocation,
                                        bannerImage: imageLocation,
                                        siteUrl: values.siteUrl,
                                        careerOpportunities: values.careerOpportunities,
                                        applyURL: values.applyURL,
                                    },
                                    null
                                )
                            );
                            await dispatch(
                                editUser(user?.id, {
                                    firstName: values.adminFname,
                                    lastName: values.adminLname,
                                    contact: {
                                        mobileNo: values.adminContactNo,
                                    },
                                })
                            );
                            dispatch(getCompanyById(profile?.companyId));
                            dispatch(getUserById(profile.userId));
                            setEditProfileClicked(false);
                        }}
                    >
                        {({
                            handleChange,
                            handleSubmit,
                            handleReset,
                            setFieldValue,
                            values,
                            errors,
                            touched,
                        }) => (
                            <div className="grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2 pb-8 mb-6">
                                <div className="col-span-12 md:col-span-6 mb-2 md:mb-4">
                                    <div className="mt-8 flex flex-row justify-between ">
                                        <div className="flex flex-row w-full">
                                            <div className="flex relative flex-col justify-center items-center w-full">
                                                <div className="w-full">
                                                    <div className="flex justify-center w-full">
                                                        <img
                                                            className={`w-full md:h-84 object-cover`}
                                                            src={
                                                                imageLocation
                                                                    ? s3UrlPrefix + imageLocation
                                                                    : '/assets/default-cover.png'
                                                            }
                                                            alt="profile"
                                                        />
                                                    </div>
                                                    {editProfileClicked && (
                                                        <label
                                                            htmlFor="image"
                                                            className={`absolute bottom-6 right-6 block text-base font-medium text-gray-700 no-underline bg-white cursor-pointer`}
                                                        >
                                                            {isUploading ? (
                                                                <Loader />
                                                            ) : (
                                                                <BiIcons.BiEditAlt
                                                                    className=""
                                                                    style={{
                                                                        height: '1.5em',
                                                                        width: '1.5em',
                                                                    }}
                                                                />
                                                            )}
                                                        </label>
                                                    )}

                                                    <div className="absolute top-86 flex w-4/5 justify-center text-red-500 text-xs my-1">
                                                        {errors.image && touched.image
                                                            ? errors.image
                                                            : uploadError
                                                            ? error
                                                            : null}
                                                    </div>

                                                    <input
                                                        style={{ display: 'none' }}
                                                        id="image"
                                                        type="file"
                                                        accept="image/png, image/jpeg"
                                                        onChange={async e => {
                                                            const file = [];
                                                            file.push(e.target.files[0]);
                                                            setFieldValue('image', file[0].name);
                                                            handleImage(e);
                                                        }}
                                                    />
                                                </div>

                                                <div className="absolute bottom-6 left-6 w-1/4 border-2 bg-white">
                                                    <div className="flex justify-center md:h-20 lg:h-28 items-center">
                                                        {logoLocation ? (
                                                            <img
                                                                className={`h-full object-cover`}
                                                                src={s3UrlPrefix + logoLocation}
                                                                alt="profile"
                                                            />
                                                        ) : (
                                                            <Camera />
                                                        )}
                                                    </div>
                                                    {editProfileClicked && (
                                                        <label
                                                            htmlFor="logo"
                                                            className={`absolute bottom-2 right-2 block text-base font-medium text-gray-700 no-underline bg-white cursor-pointer`}
                                                        >
                                                            {isLogoUploading ? (
                                                                <Loader />
                                                            ) : (
                                                                <BiIcons.BiEditAlt
                                                                    className=""
                                                                    style={{
                                                                        height: '1.5em',
                                                                        width: '1.5em',
                                                                        backgroundColor:
                                                                            'rgb(229 231 235)',
                                                                    }}
                                                                />
                                                            )}
                                                        </label>
                                                    )}

                                                    <div className="absolute top-28 flex w-full justify-center text-red-500 text-xs my-1">
                                                        {errors.logo && touched.logo
                                                            ? errors.logo
                                                            : uploadLogoError
                                                            ? error
                                                            : null}
                                                    </div>
                                                    <input
                                                        style={{ display: 'none' }}
                                                        id="logo"
                                                        type="file"
                                                        accept="image/png, image/jpeg"
                                                        onChange={async e => {
                                                            const file = [];
                                                            file.push(e.target.files[0]);
                                                            setFieldValue('logo', file[0].name);
                                                            handleLogo(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="mt-1 text-gray-400 text-sm font-medium leading-149">
                                            Image Ratio 11:5
                                        </div>
                                        <div className="mt-1 text-gray-400 text-sm font-medium leading-149">
                                            Image Ratio 18:5
                                        </div>
                                    </div>
                                    <div
                                        className={`flex flex-col justify-center items-start my-4 ml-6`}
                                    >
                                        <a
                                            href={`https://${values.siteUrl}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className=" border-blue-700 text-blue-700 cursor-pointer"
                                        >
                                            Visit website
                                        </a>
                                        <p className="text-lg font-bold">Company Admin Details</p>
                                    </div>
                                    <div className="ml-6">
                                        <div className="w-full flex my-6">
                                            <div className="flex flex-col w-1/2">
                                                <label
                                                    htmlFor="comName"
                                                    className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    First Name
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.adminFname && touched.adminFname
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="adminFname"
                                                        type="text"
                                                        value={values.adminFname}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'adminFname',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                        disabled={!editProfileClicked}
                                                    />
                                                    {errors.adminFname && touched.adminFname ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.adminFname}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="flex flex-col w-1/2">
                                                <label
                                                    htmlFor="repName"
                                                    className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Last Name
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.adminLname && touched.adminLname
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="adminLname"
                                                        type="text"
                                                        value={values.adminLname}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'adminLname',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                        disabled={!editProfileClicked}
                                                    />
                                                    {errors.adminLname && touched.adminLname ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.adminLname}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" w-full flex my-6">
                                            <div className="flex flex-col w-1/2">
                                                <label
                                                    htmlFor="comEmail"
                                                    className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Email
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.adminEmail && touched.adminEmail
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="adminEmail"
                                                        type="text"
                                                        value={values.adminEmail}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'adminEmail',
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoComplete="off"
                                                        disabled={true}
                                                    />
                                                    {errors.adminEmail && touched.adminEmail ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.adminEmail}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="flex flex-col w-1/2">
                                                <label
                                                    htmlFor="contactNo"
                                                    className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Contact Number
                                                    <div className="text-red-600">*</div>
                                                </label>

                                                <div className="flex flex-col w-full">
                                                    <input
                                                        className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                            errors.adminContactNo &&
                                                            touched.adminContactNo
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } md:text-base`}
                                                        id="adminContactNo"
                                                        type="text"
                                                        value={values.adminContactNo}
                                                        onChange={e => {
                                                            if (!isNaN(Number(e.target.value))) {
                                                                setFieldValue(
                                                                    'adminContactNo',
                                                                    e.target.value
                                                                );
                                                            }
                                                        }}
                                                        autoComplete="off"
                                                        disabled={!editProfileClicked}
                                                    />
                                                    {errors.adminContactNo &&
                                                    touched.adminContactNo ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.adminContactNo}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`flex flex-col justify-center items-start my-4 ml-6`}
                                    >
                                        <p className="text-lg font-bold">Representative Details </p>
                                    </div>
                                    {user?.roles[0]?.roleName === COMPANY_ADMIN && (
                                        <div className="ml-6">
                                            <div className="w-full flex my-6">
                                                <div className="flex flex-col w-1/2">
                                                    <label
                                                        htmlFor="comName"
                                                        className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Company Name
                                                        <div className="text-red-600">*</div>
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                                errors.comName && touched.comName
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="comName"
                                                            type="text"
                                                            value={values.comName}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'comName',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                            disabled={!editProfileClicked}
                                                        />
                                                        {errors.comName && touched.comName ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.comName}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col w-1/2">
                                                    <label
                                                        htmlFor="repName"
                                                        className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Representative Name
                                                        <div className="text-red-600">*</div>
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                                errors.repName && touched.repName
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="badgeName"
                                                            type="text"
                                                            value={values.repName}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'repName',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                            disabled={!editProfileClicked}
                                                        />
                                                        {errors.repName && touched.repName ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.repName}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" w-full flex my-6">
                                                <div className="flex flex-col w-1/2">
                                                    <label
                                                        htmlFor="comEmail"
                                                        className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        Representative Email
                                                        <div className="text-red-600">*</div>
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 focus:border-blue-900 ${
                                                                errors.comEmail && touched.comEmail
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="comEmail"
                                                            type="text"
                                                            value={values.comEmail}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'comEmail',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                            disabled={!editProfileClicked}
                                                        />
                                                        {errors.comEmail && touched.comEmail ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.comEmail}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col w-1/2">
                                                    <label
                                                        htmlFor="contactNo"
                                                        className={`flex items-start w-1/3 mb-2 block font-medium leading-149 md:text-base text-gray-700`}
                                                    >
                                                        Representative Contact Number
                                                        <div className="text-red-600">*</div>
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2  focus:border-blue-900 ${
                                                                errors.contactNo &&
                                                                touched.contactNo
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } md:text-base`}
                                                            id="contactNo"
                                                            type="text"
                                                            value={values.contactNo}
                                                            onChange={e => {
                                                                if (
                                                                    !isNaN(Number(e.target.value))
                                                                ) {
                                                                    setFieldValue(
                                                                        'contactNo',
                                                                        e.target.value
                                                                    );
                                                                }
                                                            }}
                                                            autoComplete="off"
                                                            disabled={!editProfileClicked}
                                                        />
                                                        {errors.contactNo && touched.contactNo ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.contactNo}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className=" w-full flex my-8">
                                                <div className="w-1/2 flex flex-col">
                                                    <label
                                                        className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700 mb-3`}
                                                    >
                                                        Type of Account
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 md:text-base focus:border-blue-900 ${
                                                                errors.accountType &&
                                                                touched.accountType
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            } `}
                                                            id="accountType"
                                                            type={'text'}
                                                            value={values.accountType}
                                                            autoComplete="off"
                                                            disabled={true}
                                                        />
                                                        {errors.accountType &&
                                                        touched.accountType ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.accountType}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="w-1/2 flex flex-col">
                                                    <label
                                                        className={`flex items-start w-1/2 block text-sm font-medium leading-149 md:text-base text-gray-700 mb-3`}
                                                    >
                                                        SLASSCOM Member
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 md:text-base focus:border-blue-900 ${
                                                                errors.slassMember &&
                                                                touched.slassMember
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            }`}
                                                            id="subStartDate"
                                                            type={'text'}
                                                            value={
                                                                values.slassMember ? 'Yes' : 'No'
                                                            }
                                                            autoComplete="off"
                                                            disabled={true}
                                                        />
                                                        {errors.slassMember &&
                                                        touched.slassMember ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.slassMember}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className=" w-full flex my-6">
                                                {values.slassMember && (
                                                    <div className="w-1/2 flex flex-col">
                                                        <label
                                                            htmlFor="repName"
                                                            className={`flex items-start w-1/3 mb-2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Subscription Start Date
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.subStartDate &&
                                                                    touched.subStartDate
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="subStartDate"
                                                                type={
                                                                    editProfileClicked
                                                                        ? 'date'
                                                                        : 'text'
                                                                }
                                                                value={values.subStartDate}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'subStartDate',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                                disabled={true}
                                                            />
                                                            {errors.subStartDate &&
                                                            touched.subStartDate ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.subStartDate}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                )}
                                                {values.slassMember && (
                                                    <div className="w-1/2 flex flex-col">
                                                        <label
                                                            htmlFor="repName"
                                                            className={`flex mb-2 items-start w-1/2 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Subscription End Date
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded flex items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.subStartDate &&
                                                                    touched.subStartDate
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="subEndDate"
                                                                type={
                                                                    editProfileClicked
                                                                        ? 'date'
                                                                        : 'text'
                                                                }
                                                                value={values.subEndDate}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'subEndDate',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                disabled={true}
                                                                autoComplete="off"
                                                            />
                                                            {errors.subEndDate &&
                                                            touched.subEndDate ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.subEndDate}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="w-full my-6 flex">
                                                <div className="w-1/2 flex flex-col">
                                                    <label
                                                        htmlFor="description"
                                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700 mb-2`}
                                                    >
                                                        Company Description
                                                        <div className="text-red-600">*</div>
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <textarea
                                                            className={`resize-none focus:outline-none rounded p-2 w-4/5 md:w-3/4 border-2 text-sm h-24 focus:border-blue-900 ${
                                                                errors.comDescription &&
                                                                touched.comDescription
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            }`}
                                                            id="description"
                                                            value={values.comDescription}
                                                            placeholder=""
                                                            onChange={handleChange(
                                                                'comDescription'
                                                            )}
                                                            disabled={!editProfileClicked}
                                                        />
                                                        {errors.comDescription &&
                                                        touched.comDescription ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.comDescription}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="w-1/2 flex flex-col">
                                                    <label
                                                        htmlFor="siteUrl"
                                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700 mb-2`}
                                                    >
                                                        Website URL
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded flex border-gray-300 items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                            id="name"
                                                            type="text"
                                                            value={values.siteUrl}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'siteUrl',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            disabled={!editProfileClicked}
                                                            autoComplete="off"
                                                        />
                                                        {errors.siteUrl && touched.siteUrl ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.siteUrl}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="my-6 w-full flex">
                                                <div className="w-1/2 flex flex-col">
                                                    <label
                                                        htmlFor="opportunities"
                                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700 mb-2`}
                                                    >
                                                        Career Opportunities
                                                    </label>
                                                    <div className="flex flex-col w-full">
                                                        <textarea
                                                            className={`resize-none focus:outline-none p-2 rounded w-4/5 md:w-3/4 border-2 text-sm h-24 focus:border-blue-900 ${
                                                                errors.careerOpportunities &&
                                                                touched.careerOpportunities
                                                                    ? 'border-red-500'
                                                                    : 'border-gray-300'
                                                            }`}
                                                            id="description"
                                                            value={values.careerOpportunities}
                                                            placeholder=""
                                                            onChange={handleChange(
                                                                'careerOpportunities'
                                                            )}
                                                            disabled={!editProfileClicked}
                                                        />
                                                        {errors.careerOpportunities &&
                                                        touched.careerOpportunities ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.careerOpportunities}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="w-1/2 flex flex-col">
                                                    <label
                                                        htmlFor="applyURL"
                                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700 mb-2`}
                                                    >
                                                        Apply Now URL
                                                    </label>

                                                    <div className="flex flex-col w-full">
                                                        <input
                                                            className={`rounded flex border-gray-300 items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                                            id="applyURL"
                                                            type="text"
                                                            value={values.applyURL}
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    'applyURL',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                            disabled={!editProfileClicked}
                                                        />
                                                        {errors.applyURL && touched.applyURL ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {errors.applyURL}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {editProfileClicked && (
                                        <div className="flex w-full justify-end items-center mt-4 md:mt-12">
                                            <div className="flex w-1/2 lg:w-1/3 justify-end">
                                                <button
                                                    className="bg-snow-900 border-2 border-blue-800 md:mr-4 border-opacity-40 h-11  w-full md:w-1/3 text-blue-900 text-sm rounded focus:outline-none"
                                                    onClick={() => {
                                                        setLogoLocation(company?.comImage);
                                                        setImageLocation(company?.bannerImage);
                                                        handleReset();
                                                        setEditProfileClicked(false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className={`bg-blue-700 text-white text h-11 rounded focus:outline-none  w-full md:w-1/3 ${
                                                        (isLogoUploading || isUploading) &&
                                                        'opacity-50 pointer-events-none'
                                                    }`}
                                                    onClick={() => handleSubmit()}
                                                >
                                                    {isEditLoading ? <Loader /> : 'Save'}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {editSuccessVisible && (
                                    <CompanySuccessModal
                                        modalVisible={editSuccessVisible}
                                        handleClose={handleClose}
                                    />
                                )}
                                <CropModal
                                    ratio={3.6}
                                    photoURL={imageUrl}
                                    setPhotoURL={setImageUrl}
                                    setOpenCrop={setOpenCrop}
                                    openCrop={openCrop}
                                    handleCloseCropModal={handleCloseCropModal}
                                    uploadImageToS3={uploadImageToS3}
                                />
                                <CropModal
                                    ratio={2.2}
                                    photoURL={logoUrl}
                                    setPhotoURL={setLogoUrl}
                                    setOpenCrop={setLogoOpenCrop}
                                    openCrop={openLogoCrop}
                                    handleCloseCropModal={handleCloseLogoCropModal}
                                    uploadImageToS3={uploadLogoToS3}
                                />
                            </div>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default EditCompanyProfile;
