import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearCertificatesMessages } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import { useTable, useRowSelect, usePagination } from 'react-table';
import * as BsIcons from 'react-icons/bs';
import * as HiIcons from 'react-icons/hi';
import { IconContext } from 'react-icons';
import IndeterminateCheckbox from '../Tracks/IndeterminateCheckBox';
import TableTabView from './TableTabView';
import RestoreConfirmationModal from '../../modals/RestoreConfirmationModal';
import { CertificateType } from '../../../redux/certificates/reducers';
import CertificatesSuccessfullModal from '../../modals/CertificatesSuccessfullModal';
import ActivateConfirmationModal from '../../modals/ActivateConfirmationModal';
import InactivateConfirmationModal from '../../modals/InactivateConfirmationModal';
interface Props {
    data: CertificateType[];
    columns: any;
    fetchData;
    pageCount: number;
    setSlectedChallenges;
    selectedTab: number;
    setSelectedTab: (value: number) => void;
    hideSelection?: boolean;
}

const CompaniesTable = ({
    columns,
    data,
    fetchData,
    pageCount: controlledPageCount,
    setSlectedChallenges,
    selectedTab,
    setSelectedTab,
    hideSelection,
}: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showMenu, setshowMenu] = useState(false);
    const [clickedCertificate, setClickedCertificate] = useState<CertificateType>();
    const [selectedCertificate, setSelectedCertificate] = useState<CertificateType>();
    const [selectedCertificateForActivate, setSelectedCertificateForActivate] =
        useState<CertificateType>();
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [succesVisible, setSuccesVisible] = useState(false);
    const [disableConfirmationVisible, setDisableConfirmationVisible] = useState(false);

    const [activateConfirmationVisible, setActivateConfirmationVisible] = useState(false);
    const [inactivateConfirmationVisible, setInactivateConfirmationVisible] = useState(false);

    const [showRecordsDropdown, setShowRecordsDropdown] = useState(false);
    const [goto, setGoto] = useState<any>();
    const tabCounts = useSelector((state: RootState) => state.certificates.count);
    const bulkSuccessMessage = useSelector(
        (state: RootState) => state.certificates.certificatesBulkSuccessMessage
    );
    const editError = useSelector((state: RootState) => state.certificates.editCertificateError);
    const bulkError = useSelector((state: RootState) => state.certificates.bulkError);
    const deleteError = useSelector(
        (state: RootState) => state.certificates.deleteCertificateError
    );
    const deleteSuccessMessage = useSelector(
        (state: RootState) => state.certificates.certificateDeleteSuccessMessage
    );
    const updateSuccessMessage = useSelector(
        (state: RootState) => state.certificates.certificateUpdateSuccessMessage
    );
    const tabStatus = selectedTab === 1 ? 'ACTIVE' : selectedTab === 2 ? 'INACTIVE' : '';
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        gotoPage,
        selectedFlatRows,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
            manualPagination: true,
            pageCount: controlledPageCount,
        },
        usePagination,
        useRowSelect,
        hooks => {
            if (!hideSelection) {
                hooks.visibleColumns.push(column => [
                    {
                        id: 'selection',
                        // eslint-disable-next-line react/display-name
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div className="flex justify-center">
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                            </div>
                        ),
                        // eslint-disable-next-line react/display-name
                        Cell: ({ row }) => (
                            // eslint-disable-next-line react/display-name
                            <div className="flex justify-center">
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                    },
                    ...column,
                ]);
            }
        }
    );

    useEffect(() => {
        gotoPage(0);
    }, [selectedTab, gotoPage]);

    useEffect(() => {
        selectedFlatRows.map(a => {
            return a.original;
        });
        setSlectedChallenges(
            selectedFlatRows.map(a => {
                return a.original;
            })
        );
    }, [selectedFlatRows, setSlectedChallenges]);

    useEffect(() => {
        fetchData({ pageIndex, pageSize });
    }, [fetchData, pageIndex, pageSize]);

    const handleClearMessages = useCallback(() => {
        if (
            updateSuccessMessage ||
            editError ||
            deleteError ||
            deleteSuccessMessage ||
            bulkSuccessMessage ||
            bulkError
        ) {
            setSuccesVisible(true);
        }
    }, [
        dispatch,
        deleteSuccessMessage,
        deleteSuccessMessage,
        updateSuccessMessage,
        editError,
        bulkSuccessMessage,
        bulkError,
    ]);
    const handleClose = () => {
        dispatch(clearCertificatesMessages());
        setSuccesVisible(false);
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    const showOptions = (certificate: any) => {
        setshowMenu(true);
        setClickedCertificate(certificate);
        setSelectedCertificate({
            ...certificate,
            certStatus: 'INACTIVE',
        });
        setSelectedCertificateForActivate({
            ...certificate,
            certStatus: 'ACTIVE',
        });
    };

    const viewCertificate = (certificate: any) => {
        history.push({
            pathname: `/admin/achievements/certificates/${certificate.id}/view`,
            state: { certificate: certificate },
        });
    };
    const editCertificate = async certificate => {
        history.push({
            pathname: `/admin/achievements/certificates/${certificate.id}/edit`,
            state: { certificate: certificate },
        });
    };
    return (
        <section className="py-6 h-full">
            <div className="flex justify-between w-full items-end mb-4">
                <div className="w-5/6 md:w-4/5">
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        tabCounts={tabCounts}
                    />
                </div>
                <div className="mt-4 ml-1 md:ml-4 w-12 md:w-32 flex justify-end items-center">
                    <div className="hidden md:flex md:w-1/2">Records</div>
                    <div
                        className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-1/2 md:p-2 border-2 text-sm focus:border-blue-900 border-gray-300`}
                    >
                        <div
                            className="flex justify-between w-full"
                            onClick={() => setShowRecordsDropdown(true)}
                        >
                            <div className="text-gray-700 font-medium pl-2">{pageSize}</div>
                            <div className="">
                                <div className="flex flex-col">
                                    <HiIcons.HiChevronUp className="-mb-2" />
                                    <HiIcons.HiChevronDown className="" />
                                </div>
                            </div>
                        </div>
                        {showRecordsDropdown ? (
                            <ul
                                className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                role="menu"
                                onMouseLeave={() => setShowRecordsDropdown(false)}
                            >
                                {[10, 25, 50, 100].map(value => (
                                    <div
                                        className="p-2 hover:bg-gray-200 "
                                        key={value}
                                        onClick={() => {
                                            setPageSize(value);
                                            setShowRecordsDropdown(false);
                                        }}
                                    >
                                        {value}
                                    </div>
                                ))}
                            </ul>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="w-full mb-8 overflow-hidden h-full">
                <div className="w-full overflow-x-auto pl-1 h-full" style={{ minHeight: '50vh' }}>
                    <table className="w-full" {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr
                                    key={headerGroup}
                                    {...headerGroup.getHeaderGroupProps()}
                                    className="text-sm xl:text-base font-medium tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-600"
                                    onMouseLeave={() => {
                                        setshowMenu(false);
                                    }}
                                >
                                    {headerGroup.headers.map(column => (
                                        <th
                                            key={column}
                                            {...column.getHeaderProps()}
                                            className={`px-2 py-3 border`}
                                        >
                                            <div
                                                className={`flex ${
                                                    column.id !== 'selection'
                                                        ? 'justify-between'
                                                        : 'justify-center'
                                                }`}
                                            >
                                                <div className="whitespace-nowrap">
                                                    {column.render('Header')}
                                                </div>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="bg-white" {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <tr key={row} {...row.getRowProps()} className="text-gray-700">
                                        {row.cells.map(cell => {
                                            return (
                                                <td
                                                    key={cell}
                                                    {...cell.getCellProps({})}
                                                    className={`py-3 text-sm font-semibold border ${
                                                        cell.column.id === 'actionColumn'
                                                            ? 'relative'
                                                            : cell.column.id !== 'selection'
                                                            ? 'px-2 cursor-pointer'
                                                            : ''
                                                    }`}
                                                    onClick={() => {
                                                        if (cell.column.id === 'actionColumn') {
                                                            showOptions(row.original);
                                                        } else if (cell.column.id !== 'selection') {
                                                            viewCertificate(row.original);
                                                        }
                                                    }}
                                                >
                                                    {cell.render('Cell')}
                                                    {cell.column.id === 'actionColumn' &&
                                                        showMenu &&
                                                        clickedCertificate === row.original && (
                                                            <div className="dropdown-content bottom-3 absolute left-0 shadow-md z-10 w-18">
                                                                <ul
                                                                    className="bg-gray-600 shadow rounded py-1 pl-0 mb-0"
                                                                    onMouseLeave={() =>
                                                                        setshowMenu(false)
                                                                    }
                                                                >
                                                                    {selectedTab !== 3 && (
                                                                        <>
                                                                            <li
                                                                                className="cursor-pointer text-sm leading-3 tracking-normal py-2 hover:bg-gray-400 text-white pl-2 pr-5 font-normal"
                                                                                onClick={() => {
                                                                                    editCertificate(
                                                                                        clickedCertificate
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </li>
                                                                            {/* {selectedTab !== 4 && (
                                                                                <li
                                                                                    className="cursor-pointer text-sm leading-3 tracking-normal py-2 hover:bg-gray-400 text-white pl-2 pr-5 font-normal"
                                                                                    onClick={() => {
                                                                                        setDeleteConfirmationVisible(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Delete
                                                                                </li>
                                                                            )} */}
                                                                        </>
                                                                    )}

                                                                    {selectedTab === 3 && (
                                                                        <li
                                                                            className="cursor-pointer text-sm leading-3 tracking-normal py-2 hover:bg-gray-400 text-white pr-5 pl-3 font-normal"
                                                                            onClick={() =>
                                                                                setDisableConfirmationVisible(
                                                                                    true
                                                                                )
                                                                            }
                                                                        >
                                                                            Restore
                                                                        </li>
                                                                    )}

                                                                    {(selectedTab === 4 ||
                                                                        selectedTab === 1) &&
                                                                        clickedCertificate.certStatus ===
                                                                            'ACTIVE' && (
                                                                            <li
                                                                                className="cursor-pointer text-sm leading-3 tracking-normal py-2 hover:bg-gray-400 text-white pl-2 pr-5 font-normal"
                                                                                onClick={() =>
                                                                                    setInactivateConfirmationVisible(
                                                                                        true
                                                                                    )
                                                                                }
                                                                            >
                                                                                Inactivate
                                                                            </li>
                                                                        )}
                                                                    {(selectedTab === 4 ||
                                                                        selectedTab === 2) &&
                                                                        clickedCertificate.certStatus ===
                                                                            'INACTIVE' && (
                                                                            <li
                                                                                className="cursor-pointer text-sm leading-3 tracking-normal py-2 hover:bg-gray-400 text-white pl-2 pr-5 font-normal"
                                                                                onClick={() =>
                                                                                    setActivateConfirmationVisible(
                                                                                        true
                                                                                    )
                                                                                }
                                                                            >
                                                                                Activate
                                                                            </li>
                                                                        )}
                                                                </ul>
                                                            </div>
                                                        )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="pb-4">
                <div className="flex justify-end  mb-2">
                    <IconContext.Provider
                        value={{ color: !canPreviousPage ? '#bbbcbc' : '#707a92' }}
                    >
                        <button
                            className="text-xl font-medium text-gray-900"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            <BsIcons.BsChevronLeft className="" />
                        </button>
                    </IconContext.Provider>
                    <span className="flex items-center font-semibold text-gray-700">
                        <p className="border px-2 mx-2  my-auto">{pageIndex + 1}</p>
                        of
                        <p className="border px-2 mx-2  my-auto">
                            {pageOptions.length === 0 ? '1' : pageOptions.length}
                        </p>
                    </span>
                    <IconContext.Provider value={{ color: !canNextPage ? '#bbbcbc' : '#707a92' }}>
                        <button
                            className="text-xl font-medium text-gray-900"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        >
                            <BsIcons.BsChevronRight className="" />
                        </button>
                    </IconContext.Provider>{' '}
                </div>
                <div className="flex justify-end font-bold">
                    <div
                        className="bg-gray-200 border-gray-400 border mr-1 rounded px-1 cursor-pointer"
                        onClick={() => {
                            gotoPage(goto - 1);
                        }}
                    >
                        Go
                    </div>
                    to
                    <input
                        className="border font-bold px-2 mr-7 ml-2 w-8 focus:outline-none rounded"
                        type="number"
                        onChange={e => setGoto(e.target.value)}
                    />
                </div>
            </div>
            {deleteConfirmationVisible ? (
                <DeleteConfirmationModal
                    modalVisible={deleteConfirmationVisible}
                    setModalVisible={setDeleteConfirmationVisible}
                    certificateId={clickedCertificate?.id}
                    setshowMenu={setshowMenu}
                    tabStatus={tabStatus}
                />
            ) : null}
            {disableConfirmationVisible ? (
                <RestoreConfirmationModal
                    modalVisible={disableConfirmationVisible}
                    setModalVisible={setDisableConfirmationVisible}
                    certificateId={clickedCertificate?.id}
                    certificate={selectedCertificate}
                    setshowMenu={setshowMenu}
                    tabStatus={tabStatus}
                />
            ) : null}

            {succesVisible ? (
                <CertificatesSuccessfullModal
                    modalVisible={succesVisible}
                    handleClose={handleClose}
                />
            ) : null}
            {activateConfirmationVisible ? (
                <ActivateConfirmationModal
                    modalVisible={activateConfirmationVisible}
                    setModalVisible={setActivateConfirmationVisible}
                    certificateId={clickedCertificate.id}
                    certificate={selectedCertificateForActivate}
                    tabStatus={tabStatus}
                />
            ) : null}
            {inactivateConfirmationVisible ? (
                <InactivateConfirmationModal
                    modalVisible={inactivateConfirmationVisible}
                    setModalVisible={setInactivateConfirmationVisible}
                    certificateId={clickedCertificate.id}
                    certificate={selectedCertificate}
                    tabStatus={tabStatus}
                />
            ) : null}
        </section>
    );
};

export default CompaniesTable;
