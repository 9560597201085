import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMentees, getMentorById, getMentorPdfUrl } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { s3UrlPrefix } from '../../../constants';
import RightSideContent from '../../RightSideContent';
import { MentorType } from '../../../redux/mentors/reducers';
import MenteeTable from './MenteeTable';
import { BiRightArrow } from 'react-icons/bi';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroupsView from '../../TagGroupsView';

interface CustomState {
    mentor: MentorType;
}
interface paramsType {
    id: string;
}
export default function ViewMentor() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const fetchIdRef = React.useRef(0);
    const params = useParams<paramsType>();
    const mentorState = location.state as CustomState;
    const [tags, setTags] = useState(categorizeTags(mentorState?.mentor?.tags));
    const mentor = useSelector((state: RootState) => state.mentors.mentor);
    const menteesData = useSelector((state: RootState) => state.mentors.menteesData);
    const pageCount = useSelector((state: RootState) => state.mentors.menteePageCount);
    const [imageUrl] = useState(`${s3UrlPrefix}${mentorState?.mentor?.imagePath}`);
    const [imageLocation] = useState(mentorState?.mentor?.imagePath);
    const columns = useMemo(
        () => [
            {
                Header: 'Student ID',
                accessor: 'userId',
            },
            {
                Header: 'Student Name',
                accessor: data => {
                    return `${data.firstName ? data.firstName : ''} ${
                        data.lastName ? data.lastName : ''
                    }`;
                },
            },

            {
                Header: 'LinkedIn Profile',
                accessor: data => {
                    return `${data.linkedInProfileUrl ? data.linkedInProfileUrl : ''}`;
                },
            },
        ],
        []
    );
    const gotoPdfUrl = async index => {
        const fileName = mentor?.filePath?.[index].toString().split('/', 4);
        const link = await dispatch(getMentorPdfUrl(fileName?.[3]));
        const linkToString = link.toString();
        window.open(linkToString, '_blank');
    };
    useEffect(() => {
        dispatch(getMentorById(params.id));
    }, [dispatch, params.id]);
    const fetchData = React.useCallback(
        ({ pageIndex }) => {
            const fetchId = ++fetchIdRef.current;
            if (fetchId === fetchIdRef.current) {
                dispatch(getMentees(pageIndex, true, null, params.id));
            }
        },
        [dispatch, params.id]
    );
    return (
        <div className="mx-6 lg:ml-2 h-full">
            <div className="mt-12 bg-snow-600">
                <div className=" mt-12">
                    <div className="relative">
                        <div className="flex justify-between items-center mb-2">
                            <p className="text-xl font-semibold text-left ml-2">View Mentor</p>
                            <div
                                className={`flex h-14 md:h-16 ${
                                    mentorState?.mentor?.mentorStatus !== 'DELETED'
                                        ? 'w-1/2 md:w-1/4 lg:pl-8'
                                        : 'w-1/4 md:w-1/6 lg:pl-16'
                                }`}
                            >
                                <button
                                    type="submit"
                                    className={`md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm ${
                                        mentorState?.mentor?.mentorStatus !== 'DELETED'
                                            ? 'w-full md:w-1/2'
                                            : 'w-full hover:bg-blue-700 hover:text-white'
                                    } my-2 focus:outline-none`}
                                    onClick={() => {
                                        history.push('/admin/mentors');
                                    }}
                                >
                                    OK
                                </button>
                                {mentorState?.mentor?.mentorStatus !== 'DELETED' && (
                                    <button
                                        type="submit"
                                        className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-normal rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                        onClick={() => {
                                            history.push({
                                                pathname: `/admin/mentors/${mentorState?.mentor?.id}/edit`,
                                                state: { mentor: mentor },
                                            });
                                        }}
                                    >
                                        Edit
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2 pb-8 mb-6">
                            <div className="col-span-12 md:col-span-4 mb-2 md:mb-4">
                                <div className="text-lg font-medium mb-6">Mentor Details</div>
                                <div className="mb-8 mt-6 flex flex-row justify-between">
                                    <div className="flex flex-row relative">
                                        <div className="flex justify-center mt-4 mb-4">
                                            <img
                                                className={`blur-3xl rounded-full object-cover h-36 w-36 cursor-pointer`}
                                                src={`${
                                                    imageLocation ? imageUrl : '/assets/user.png'
                                                }`}
                                                alt="profile"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        htmlFor="mentorName"
                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Mentor ID
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex border-gray-300 items-center focus:outline-none w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            value={mentor?.mentorId}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        htmlFor="mentorName"
                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Mentor Name
                                    </label>

                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center focus:outline-none border-gray-300 w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            value={mentor?.mentorName}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        htmlFor="mentorCompany"
                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Company
                                    </label>

                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center focus:outline-none border-gray-300 w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            value={mentor?.companyName}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        htmlFor="mentorDesignation"
                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Designation
                                    </label>

                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center focus:outline-none border-gray-300 w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            value={mentor?.designation}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        htmlFor="mentorEmail"
                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Email
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center focus:outline-none border-gray-300 w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            value={mentor?.mentorEmail}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        htmlFor="contactNumber"
                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Contact Number
                                    </label>

                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center focus:outline-none border-gray-300 w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            id="name"
                                            type="text"
                                            value={mentor?.contactNumber}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                    <label
                                        htmlFor="linkedinProfile"
                                        className={`flex items-start w-1/3 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Linked-In Profile
                                    </label>

                                    <div className="flex flex-col w-full">
                                        <input
                                            className={`rounded-sm flex items-center focus:outline-none border-gray-300 w-4/5 md:w-3/4 p-2 border-2 text-base focus:border-blue-900 md:text-base`}
                                            id="linkedinProfile"
                                            type="text"
                                            value={mentor?.linkedinProfile}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="my-6 w-full md:mb-0 flex h-32">
                                    <label
                                        htmlFor="mentorDescription"
                                        className={`flex items-start w-1/3 block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                    >
                                        Profile Description
                                    </label>
                                    <div className="flex flex-col w-full">
                                        <textarea
                                            className={`resize-none focus:outline-none border-gray-300 p-2 w-4/5 md:w-3/4 border-2 text-sm h-32 focus:border-blue-900`}
                                            value={mentor?.description}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                <div className="flex border-2 flex-col ml-2 justify-items-center">
                                    <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                        <div className="sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                            Tags
                                        </div>
                                    </div>
                                    <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                        <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                            <TagGroupsView selectedTags={tags} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border-2 border-gray-300 px-2 md:px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6">
                            <div className="col-span-12 md:col-span-4 mb-2 md:mb-4 ml-2">
                                <div className="text-lg font-medium mb-6">Qualifications</div>

                                <div className="mb-6 w-full md:mb-0 flex flex-col my-4 ml-4">
                                    <label
                                        htmlFor="mentorName"
                                        className={`flex items-start md:w-1/3 mb-4 block font-medium leading-149 text-sm md:text-base text-gray-700`}
                                    >
                                        Relevant Documentation
                                    </label>

                                    <div className="w-full">
                                        {mentor?.filePath?.length !== 0 ? (
                                            mentor?.filePath.map((doc, index) => (
                                                <div
                                                    key={index}
                                                    className="flex justify-between hover:bg-gray-100 rounded px-2"
                                                >
                                                    <div className="flex flex-row">
                                                        <BiRightArrow className="flex items-center mt-1" />
                                                        <div
                                                            onClick={() => gotoPdfUrl(index)}
                                                            className="underline text-blue-400 cursor-pointer"
                                                        >
                                                            {doc.split('/', 4)[2]}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-gray-700">No file found</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-6 gap-4 md:gap-8 pt-2 mb-6">
                            <div className="col-span-12 md:col-span-4 ">
                                <div className="text-lg font-medium">Assigned Mentees</div>
                            </div>
                            <div className="col-span-12 md:col-start-1 md:col-span-4">
                                <div className={`mr-4 w-full `}>
                                    <MenteeTable
                                        columns={columns}
                                        data={menteesData}
                                        fetchData={fetchData}
                                        pageCount={pageCount}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
