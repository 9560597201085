import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { editStudent, getStudentActivityLog, getTags } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import StudentSuccessfullModal from '../../modals/StudentSuccessfullModal';
import * as HiIcons from 'react-icons/hi';
import {
    clearStudentMessages,
    StudentEditType,
    getStudentById,
} from '../../../redux/students/actions';
import { CareerType } from '../../../redux/students/reducers';
import AddCareerProgressModal from './AddCareerProgressModal';
import CareerProgressUpdatedSuccessfullyModal from '../../modals/CareerProgressUpdatedSuccessfullyModal';
import RemoveBin from '../../../assets/svg/RemoveBin';
import CareerProgressDeleteConfirmationModal from '../../modals/CareerProgressDeleteConfirmationModal';
import { s3UrlPrefix, moduleNames } from '../../../constants';
import moment from 'moment';
import ViewSubmissionAnswers from './ViewSubmissionAnswersModal';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroups from '../../TagGroups';
import { USER_PROFILE } from '../../../utils/storage';
interface paramsType {
    id: string;
}
interface CustomState {
    successStory: boolean;
}
export default function EditStudent() {
    const params = useParams<paramsType>();
    const dispatch = useDispatch();
    const history = useHistory();
    const formRef = useRef<any>();
    const location = useLocation();

    const redirectLink = location.state as CustomState;
    const student = useSelector((state: RootState) => state.students.student);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const editStudentError = useSelector((state: RootState) => state.students.editStudentError);
    const studentUpdateSuccess = useSelector(
        (state: RootState) => state.students.StudentUpdateSuccessMessage
    );
    const activityLogData = useSelector((state: RootState) => state.webUser.studentActivityLog);

    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));

    const [tags, setTags] = useState([]);
    const [interestedTags, setInterestedTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [hiredByFCB, setHiredByFCB] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const [trackUpdateSuccessVisible, setTrackUpdateSuccessVisible] = useState(false);
    const [careerProgressUpdatedVisible, setCareerProgressUpdatedVisible] = useState(false);
    const [trackErrorVisible, setTrackErrorVisible] = useState(false);
    const [addCareerProgressVisible, setAddCareerProgressVisible] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    const [careerProgressAdded, setCareerProgressAdded] = useState(false);
    const [careerProgressDeleted, setCareerProgressDeleted] = useState(false);

    const [deleteProgressID, setDeleteProgressID] = useState(null);

    const [showDropdown, setShowDropdown] = useState(false);
    const [tagsHelpText, setTagsHelpText] = useState<boolean>(false);
    const serchTagNameOrId = e => {
        setSearchTerm(e.target.value);
    };
    const [submissionAnswerModalVisible, setSubmissionAnswerModalVisible] = useState(false);
    const [submissionsData, setSubmissionsData] = useState();
    const challengesForMentor = 10;
    const firstName = student?.firstName ? student?.firstName : '';
    const lastName = student?.lastName ? student?.lastName : '';
    const fullName = firstName + ' ' + lastName;

    const handleClearMessages = useCallback(() => {
        setDeleteConfirmation(false);
        setAddCareerProgressVisible(false);
        if (studentUpdateSuccess && (careerProgressAdded || careerProgressDeleted)) {
            setCareerProgressUpdatedVisible(true);
        }
        if (studentUpdateSuccess && !(careerProgressAdded || careerProgressDeleted)) {
            setTrackUpdateSuccessVisible(true);
        }
        if (editStudentError) {
            setTrackErrorVisible(true);
        }
        if (studentUpdateSuccess || editStudentError) {
            if (!(careerProgressAdded || careerProgressDeleted)) {
                setTimeout(() => {
                    dispatch(clearStudentMessages());
                    if (!editStudentError) {
                        if (redirectLink?.successStory) {
                            history.push({
                                pathname: `/admin/students/${student?.id}/view`,
                                state: {
                                    successStory: redirectLink?.successStory,
                                },
                            });
                        } else {
                            history.push(`/admin/students/${student?.id}/view`);
                        }
                    }
                }, 4000);
            } else {
                dispatch(getStudentById(params.id));
            }
        }
    }, [dispatch, editStudentError, history, studentUpdateSuccess]);

    const handleOnClickSubmission = submissions => {
        setSubmissionAnswerModalVisible(true);
        setSubmissionsData(submissions);
    };

    useEffect(() => {
        if (showDropdown) {
            dispatch(getTags(true, moduleNames.Student, searchTerm));
        }
    }, [dispatch, searchTerm, showDropdown]);

    useEffect(() => {
        setSelectedTags(categorizeTags(tags));
    }, [tags]);

    useEffect(() => {
        if (student?.tags) {
            setTags(student.tags);
            setInterestedTags(
                student?.tags?.filter(
                    tag =>
                        tag.tagCategory.toLowerCase() === 'interest' ||
                        tag.tagCategory.toLowerCase() === 'interests'
                )
            );
        }
        setHiredByFCB(student?.hiredByFCB);
    }, [student]);

    useEffect(() => {
        dispatch(getStudentById(params.id));
    }, [dispatch, params.id]);

    useEffect(() => {
        dispatch(getStudentActivityLog(true, params.id));
    }, [dispatch, params.id]);

    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    <div className="relative">
                        <Formik
                            enableReinitialize={true}
                            innerRef={formRef}
                            initialValues={{
                                trackName: student?.firstName + ' ' + student?.lastName,
                                firstName: student?.firstName,
                                lastName: student?.lastName,
                                status: student?.status,
                                gender: student?.gender,
                                birthOfDate: student?.birthDate,
                                nic: student?.nic,
                                institue: student?.InstituteName,
                                points: student?.userPoints,
                                trackCreatedBy: '',
                                faculty: student?.StudentInstitute?.faculty,
                                secondaryEduLevel: student?.educationStatus,
                                mentorName: student?.mentor?.mentorName,
                                cvLink: student?.cvLink,
                                address: student?.contact?.address1,
                                emailId: student?.emailId,
                                city: student?.contact?.city,
                                mobileNo: student?.contact?.mobileNo,
                                higherEduStatus: student?.higherEduStatus,
                                instituteLocation: student?.StudentInstitute?.location,
                                description: '',
                                studentId: student?.StudentInstitute?.studentId,
                                hiredByFCB: student?.hiredByFCB,
                                bridgeInvitationCode: student?.bridgeInvitationCode,
                            }}
                            validationSchema={Yup.object({
                                description: Yup.string()
                                    .max(500, 'Description should be less than 500 characters')
                                    .required('Description is required'),
                            })}
                            onSubmit={values => {
                                const careerStatusType: CareerType | any = {
                                    message: values.description,
                                };
                            }}
                        >
                            {({ values, errors, touched }) => (
                                <>
                                    <div className="flex justify-between items-center mb-4 md:mb-6">
                                        <p className="text-xl font-semibold text-left">
                                            Edit Student
                                        </p>
                                        <div className="flex w-1/2 md:w-2/5 lg:pl-6 h-14 md:h-16">
                                            <button
                                                className="md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => {
                                                    dispatch(clearStudentMessages());
                                                    if (redirectLink?.successStory) {
                                                        history.push(
                                                            '/admin/students/successStories'
                                                        );
                                                    } else {
                                                        history.push('/admin/students');
                                                    }
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="md:px-2 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-3/4 my-2 focus:outline-none"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setAddCareerProgressVisible(true);
                                                }}
                                            >
                                                Add Industry Highlight
                                            </button>
                                            <button
                                                className="md:px-2 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-3/4 my-2 focus:outline-none"
                                                onClick={() => {
                                                    const studentEditType: StudentEditType = {
                                                        tags: tags,
                                                        hiredByFCB: hiredByFCB,
                                                    };
                                                    dispatch(
                                                        editStudent(student?.id, studentEditType)
                                                    );
                                                }}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                    <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6">
                                        <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                            <div className="text-lg font-medium mb-6">
                                                Student Details
                                            </div>
                                            <div className="mb-8 flex flex-row ">
                                                <img
                                                    className="rounded-full h-36 w-36"
                                                    src={
                                                        student?.imageUrl
                                                            ? s3UrlPrefix + student.imageUrl
                                                            : `/assets/user.png`
                                                    }
                                                    alt="profile"
                                                />
                                                <div
                                                    className="flex flex-col ml-6"
                                                    style={{
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <p className="font-semibold">{fullName}</p>{' '}
                                                    <div className="flex justify-start">
                                                        {![undefined, null, ''].includes(
                                                            student?.socialMediaUrls[0]?.url
                                                        ) && (
                                                            <img
                                                                className="h-8 w-8 cursor-pointer mr-2"
                                                                src={`/assets/linkedin.png`}
                                                                alt="profile"
                                                                onClick={() => {
                                                                    window.open(
                                                                        student?.socialMediaUrls[0]
                                                                            ?.url,
                                                                        '_blank'
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                        {![undefined, null, ''].includes(
                                                            student?.socialMediaUrls[1]?.url
                                                        ) && (
                                                            <img
                                                                style={{ background: '#808080' }}
                                                                className="h-8 w-8 cursor-pointer mr-2 rounded"
                                                                src={`/assets/facebookLogo.png`}
                                                                alt="profile"
                                                                onClick={() => {
                                                                    window.open(
                                                                        student?.socialMediaUrls[1]
                                                                            ?.url,
                                                                        '_blank'
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                        {![undefined, null, ''].includes(
                                                            student?.socialMediaUrls[2]?.url
                                                        ) && (
                                                            <img
                                                                style={{ background: '#808080' }}
                                                                className="h-8 w-8 cursor-pointer mr-2 rounded"
                                                                src={`/assets/twitterLogo.png`}
                                                                alt="profile"
                                                                onClick={() => {
                                                                    window.open(
                                                                        student?.socialMediaUrls[2]
                                                                            ?.url,
                                                                        '_blank'
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex">
                                                <label
                                                    className={`flex items-start w-1/3 text-gray-700 block font-medium leading-149 text-sm md:text-base `}
                                                >
                                                    Student ID
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    id="id"
                                                    type="text"
                                                    value={student?.id}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Student First Name
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={values.firstName}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Student Last Name
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={values.lastName}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Gender
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={values.gender}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Date of Birth
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    id="name"
                                                    type="text"
                                                    value={values.birthOfDate}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Postal Address
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    id="name"
                                                    type="text"
                                                    value={values.address}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Hometown
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={values.city}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Email
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={values.emailId}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Contact Number
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={values.mobileNo}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    NIC number
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={values.nic}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Account Status
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={values.status}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="title"
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Resume
                                                </label>
                                                <label
                                                    onClick={() =>
                                                        window.open(
                                                            s3UrlPrefix + student?.cvLink,
                                                            '_blank'
                                                        )
                                                    }
                                                    htmlFor="title"
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700 ml-2 ${
                                                        student?.cvLink && 'underline'
                                                    }`}
                                                >
                                                    {student?.cvLink
                                                        ? `${firstName}_${lastName}_Resume`
                                                        : 'Not Uploaded'}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                            <div className="flex border-2 flex-col ml-2 justify-items-center">
                                                <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                                    <div className="flex sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                        <div>Tags</div>
                                                        <div>
                                                            <HiIcons.HiQuestionMarkCircle
                                                                onClick={() => {
                                                                    setTagsHelpText(true);
                                                                }}
                                                                className="ml-2 mt-1 cursor-pointer"
                                                            />
                                                        </div>
                                                    </div>
                                                    {tagsHelpText ? (
                                                        <div
                                                            onMouseOverCapture={() => {
                                                                setTagsHelpText(true);
                                                                // setProfileDropdownVisible(false);
                                                            }}
                                                            onMouseLeave={() =>
                                                                setTagsHelpText(false)
                                                            }
                                                            className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-6 right-12"
                                                        >
                                                            Assign already created tags to the new
                                                            record created.
                                                        </div>
                                                    ) : null}
                                                    <div
                                                        className="sm:text-sm md:text-base text-gray-400 underline pr-2 cursor-pointer"
                                                        onClick={() => {
                                                            setTags([]);
                                                        }}
                                                    >
                                                        Clear All
                                                    </div>
                                                </div>
                                                <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                                    <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                        <TagGroups
                                                            selectedTags={selectedTags}
                                                            tags={tags}
                                                            setTags={setTags}
                                                        />
                                                        <div
                                                            onMouseLeave={() => {
                                                                setShowDropdown(false);
                                                            }}
                                                        >
                                                            <input
                                                                className={`rounded w-full text-gray-500 font-medium focus:outline-none mt-2 p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                                                id="id"
                                                                type="text"
                                                                placeholder="Search tags"
                                                                onChange={e => serchTagNameOrId(e)}
                                                                onMouseEnter={() => {
                                                                    setShowDropdown(true);
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {showDropdown && tagData?.length ? (
                                                                <ul
                                                                    className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() => {
                                                                        setShowDropdown(false);
                                                                    }}
                                                                >
                                                                    {tagData?.map(tag => (
                                                                        <div
                                                                            className="p-2 hover:bg-gray-200 cursor-pointer"
                                                                            key={tag.id}
                                                                            onClick={() => {
                                                                                if (
                                                                                    tags
                                                                                        .map(
                                                                                            checktag =>
                                                                                                checktag.tagName
                                                                                        )
                                                                                        .includes(
                                                                                            tag.tagName
                                                                                        ) === false
                                                                                ) {
                                                                                    setTags([
                                                                                        ...tags,
                                                                                        tag,
                                                                                    ]);
                                                                                }
                                                                            }}
                                                                        >
                                                                            {tag.tagCategory +
                                                                                ': ' +
                                                                                tag.tagName}
                                                                        </div>
                                                                    ))}
                                                                </ul>
                                                            ) : showDropdown &&
                                                              tagData.length === 0 ? (
                                                                <ul
                                                                    className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() =>
                                                                        setShowDropdown(false)
                                                                    }
                                                                >
                                                                    <div className="p-2 flex justify-center font-medium text-gray-400">
                                                                        No records found
                                                                    </div>
                                                                </ul>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" ml-2 justify-items-center">
                                                <div className="flex flex-col text-sm pl-2 lg:pl-4 my-6 space-y-4 text-gray-400 ">
                                                    <div>
                                                        Created tags can be retrieved by typing the
                                                        text in the search bar.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex border-2 flex-row justify-items-center p-2 pl-4 ml-2 mt-2">
                                                <label
                                                    className={`flex items-center w-full block text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                >
                                                    Hired through FCB
                                                </label>
                                                <div className="flex text-sm md:text-base items-center w-full">
                                                    <input
                                                        className="mr-1"
                                                        type="radio"
                                                        value="true"
                                                        id="true"
                                                        checked={hiredByFCB}
                                                        name="hiredByFCB"
                                                        onChange={() => setHiredByFCB(true)}
                                                    />
                                                    <label className="mr-4 w-1/4">Yes</label>
                                                    <input
                                                        className="mr-1"
                                                        type="radio"
                                                        value="false"
                                                        id="false"
                                                        name="hiredByFCB"
                                                        checked={!hiredByFCB}
                                                        onChange={() => setHiredByFCB(false)}
                                                    />
                                                    <label className="">No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6">
                                        <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                            <div className="text-lg font-medium mb-6">
                                                Educational Qualifications
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="title"
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Secondary Educational level
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={values.secondaryEduLevel}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="title"
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Stream of study in Advanced Level
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    id="name"
                                                    type="text"
                                                    value={student?.alStream}
                                                    autoComplete="off"
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="title"
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Reason to join Future Careers BRIDGE program
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    id="name"
                                                    type="text"
                                                    value={student?.reasonForJoin}
                                                    autoComplete="off"
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="title"
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Pursuing Higher Education
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    id="name"
                                                    type="text"
                                                    value={student?.pursuingHigherStudies}
                                                    autoComplete="off"
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="title"
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Present Employment Status
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    id="name"
                                                    type="text"
                                                    value={student?.empStatus}
                                                    autoComplete="off"
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="title"
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Company Name
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    id="name"
                                                    type="text"
                                                    value={student?.companyName}
                                                    autoComplete="off"
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Higher Education Status
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={values.higherEduStatus}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Higher Education Institute
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={values.institue}
                                                    disabled
                                                />
                                            </div>
                                            {student?.StudentInstitute?.instCategory1 && (
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="title"
                                                        className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        {
                                                            student?.StudentInstitute?.instCategory1
                                                                ?.name
                                                        }
                                                    </label>
                                                    <input
                                                        className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                        id="name"
                                                        type="text"
                                                        value={
                                                            student?.StudentInstitute?.instCategory1
                                                                ?.option
                                                        }
                                                        autoComplete="off"
                                                        disabled
                                                    />
                                                </div>
                                            )}
                                            {student?.StudentInstitute?.instCategory2 && (
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="title"
                                                        className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        {
                                                            student?.StudentInstitute?.instCategory2
                                                                ?.name
                                                        }
                                                    </label>
                                                    <input
                                                        className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                        id="name"
                                                        type="text"
                                                        value={
                                                            student?.StudentInstitute?.instCategory2
                                                                ?.option
                                                        }
                                                        autoComplete="off"
                                                        disabled
                                                    />
                                                </div>
                                            )}
                                            {student?.StudentInstitute?.instCategory3 && (
                                                <div className="mb-6 w-full md:mb-0 flex my-4">
                                                    <label
                                                        htmlFor="title"
                                                        className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                    >
                                                        {
                                                            student?.StudentInstitute?.instCategory3
                                                                ?.name
                                                        }
                                                    </label>
                                                    <input
                                                        className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                        id="name"
                                                        type="text"
                                                        value={
                                                            student?.StudentInstitute?.instCategory3
                                                                ?.option
                                                        }
                                                        autoComplete="off"
                                                        disabled
                                                    />
                                                </div>
                                            )}
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Institute Address
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={values.instituteLocation}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Faculty Name
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={values.faculty}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Bridge Invitation Code
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={values.bridgeInvitationCode}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    htmlFor="title"
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Student ID given by the institute
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={student?.StudentInstitute?.studentId}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6">
                                        <div className="col-span-12 md:col-span-5 mb-2">
                                            <div className="text-lg font-medium mb-2">
                                                Areas of Interest
                                            </div>
                                            <div className="flex flex-wrap  mr-20">
                                                {interestedTags?.map(action => (
                                                    <div
                                                        style={{
                                                            maxHeight: '10px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                        key={action.id}
                                                        className={`rounded-full border-2 my-1 mr-4 text-white bg-purple-400 border-purple-400 py-3 px-2 `}
                                                    >
                                                        {action.tagName}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6">
                                        <div className="col-span-12 md:col-span-5 mb-2">
                                            <div className="text-lg font-medium mb-6">
                                                Bridge Progress
                                            </div>
                                            <table className="border-2 w-full">
                                                <thead className="border-2">
                                                    <tr className="border-2">
                                                        <th className="border-2 p-2">Track Name</th>
                                                        <th className="border-2 p-2">
                                                            Challenges Attempted
                                                        </th>
                                                        <th className="border-2 p-2">
                                                            Last Attempted Challenge
                                                        </th>
                                                        <th className="border-2 p-2">
                                                            Last Attempted Date/Time
                                                        </th>
                                                        <th className="border-2 p-2">
                                                            Average Score for Attempted
                                                            Challenges(%)
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {student?.bridgeProgresses?.map(bridgeProgress => (
                                                    <tbody
                                                        key={bridgeProgress?.latestSubmissionDate}
                                                    >
                                                        <tr>
                                                            <td className="border-2 p-2">
                                                                {bridgeProgress?.trackName}
                                                            </td>
                                                            <td className="border-2 p-2 text-center">
                                                                {bridgeProgress?.studentAttemptChallengeCount +
                                                                    '/' +
                                                                    bridgeProgress?.trackChallengesCount}
                                                            </td>
                                                            <td className="border-2 p-2">
                                                                {
                                                                    bridgeProgress?.lastAttemptChallengeName
                                                                }
                                                            </td>
                                                            <td className="border-2 p-2 text-center">
                                                                {moment(
                                                                    bridgeProgress?.latestSubmissionDate
                                                                ).format('DD/MM/YYYY hh:mm A')}
                                                            </td>
                                                            <td className="border-2 p-2 text-center">
                                                                {bridgeProgress?.averageScorePercentage
                                                                    ? Math.round(
                                                                          bridgeProgress?.averageScorePercentage
                                                                      )
                                                                    : 0}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ))}
                                            </table>
                                        </div>
                                        <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Total Points Earned
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={
                                                        student?.totalScore
                                                            ? Math.round(student?.totalScore * 100)
                                                            : 0
                                                    }
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    No. of Challenges remaining to earn a mentor
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={
                                                        challengesForMentor -
                                                            student?.completedChallengeCount <
                                                        0
                                                            ? 0
                                                            : challengesForMentor -
                                                              student?.completedChallengeCount
                                                    }
                                                    autoComplete="off"
                                                    disabled
                                                />
                                                {errors.trackName && touched.trackName ? (
                                                    <div className="text-red-500 text-xs my-1">
                                                        {errors.trackName}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="mb-6 w-full md:mb-0 flex my-4">
                                                <label
                                                    className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                >
                                                    Assigned Mentor
                                                </label>
                                                <input
                                                    className={`rounded-sm focus:outline-none h-8 w-5/6 md:w-2/3 p-2 border-2 text-base focus:border-blue-900 border-gray-200 ml-3 md:ml-0`}
                                                    type="text"
                                                    value={
                                                        student?.mentor
                                                            ? student?.mentor?.mentorName
                                                            : 'Not Assigned'
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6"
                                        style={{ height: '650px', overflow: 'auto' }}
                                    >
                                        <div className="col-span-12 md:col-span-5 mb-2">
                                            <div className="text-lg font-medium mb-6">
                                                Challenge assignment submissions
                                            </div>
                                            <table className="border-2 w-full">
                                                <thead className="border-2">
                                                    <tr className="border-2">
                                                        <th className="border-2 p-2">
                                                            Challenge Name
                                                        </th>
                                                        <th className="border-2 p-2">
                                                            Submitted Date/Time
                                                        </th>
                                                        <th className="border-2 p-2">Submission</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {student?.trackChallengeWiseSubmissions?.map(
                                                        (submission, key) => (
                                                            <tr key={`key${key}`}>
                                                                <td className="border-2 p-2">
                                                                    {submission?.trackName}
                                                                </td>
                                                                <td className="border-2 p-2">
                                                                    {submission?.challengeName}
                                                                </td>
                                                                <td className="border-2 p-2">
                                                                    {moment(
                                                                        submission?.lastSubmitDate
                                                                    ).format('DD/MM/YYYY hh:mm A')}
                                                                </td>
                                                                <td
                                                                    className="border-2 p-2 underline cursor-pointer text-blue-700"
                                                                    role="presentation"
                                                                    onClick={() => {
                                                                        handleOnClickSubmission(
                                                                            submission
                                                                        );
                                                                    }}
                                                                >
                                                                    view
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6">
                                        <div className="col-span-12 md:col-span-5 mb-2 md:mb-4">
                                            <div className="text-lg font-medium mb-6">
                                                Achievements
                                            </div>
                                            <div className="flex items-start">
                                                <table className="border-2 w-1/2 md:mb-4">
                                                    <thead className="border-2">
                                                        <tr className="border-2">
                                                            <th className="border-2 p-2">
                                                                Certificates Earned
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    {student?.certificates.length > 0 ? (
                                                        student?.certificates?.map(
                                                            (certificate, id) => (
                                                                <tbody key={id}>
                                                                    <tr>
                                                                        <td
                                                                            key={certificate.id}
                                                                            className="border-2 p-2"
                                                                        >
                                                                            {
                                                                                certificate.certificateName
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                        )
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                <td className="border-2 p-2">
                                                                    No records exist
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </table>
                                                <table className="border-b-2 border-t-2 border-r-2 w-1/2 md:mb-4">
                                                    <thead className="border-b-2 border-t-2 border-r-2">
                                                        <tr className="border-b-2 border-t-2 border-r-2">
                                                            <th className="border-b-2 border-t-2 border-r-2 p-2">
                                                                Badges Earned
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    {student?.badges.length > 0 ? (
                                                        student?.badges?.map((badge, id) => (
                                                            <tbody key={id}>
                                                                <tr>
                                                                    <td
                                                                        key={badge.id}
                                                                        className="border-b-2 border-t-2 border-r-2 p-2"
                                                                    >
                                                                        {badge.badgeName}
                                                                    </td>
                                                                </tr>
                                                                <tr></tr>
                                                            </tbody>
                                                        ))
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                <td className="border-b-2 border-t-2 border-r-2 p-2">
                                                                    No records exist
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 mb-6">
                                        <div className="col-span-12 md:col-span-5 pb-4">
                                            <div className="text-lg font-medium mb-6">
                                                Industry Highlights
                                            </div>
                                            <table className="border-0 w-full">
                                                <thead className="border-0">
                                                    <tr className="border-0">
                                                        <th className="border-2 border-gray-300 p-2">
                                                            Description
                                                        </th>
                                                        <th className="border-2 border-gray-300 p-2">
                                                            Type
                                                        </th>
                                                        <th className="border-2 border-gray-300 p-2">
                                                            Updated Date/Time
                                                        </th>
                                                        <th className="border-2 border-gray-300 p-2">
                                                            Updated By
                                                        </th>
                                                        <th className="border-white">{''}</th>
                                                    </tr>
                                                </thead>
                                                {student?.careerStatus?.map(data => (
                                                    <tbody key={data.id}>
                                                        <tr>
                                                            <td className="border-2 border-gray-300 p-2">
                                                                {data.message}
                                                            </td>
                                                            <td className="border-2 border-gray-300 p-2">
                                                                {data.type}
                                                            </td>
                                                            <td className="border-2 border-gray-300 p-2">
                                                                {moment(data.logUpdatedDate)
                                                                    .local()
                                                                    .format(
                                                                        'YYYY-MM-DD , h:mm:ss a'
                                                                    )}
                                                            </td>
                                                            <td className="border-2 border-gray-300 p-2">
                                                                {data.logCreatedBy}
                                                            </td>
                                                            {data.logCreatedBy ===
                                                                profile.userEmail && (
                                                                <td className="border-0">
                                                                    <button
                                                                        onClick={() => {
                                                                            setDeleteConfirmation(
                                                                                true
                                                                            );
                                                                            setDeleteProgressID(
                                                                                data.id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <RemoveBin
                                                                            width={20}
                                                                            height={25}
                                                                        />
                                                                    </button>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    </tbody>
                                                ))}
                                            </table>
                                        </div>
                                    </div>
                                    <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 pb-3 mb-6">
                                        <div className="col-span-12 md:col-span-5">
                                            <div className="text-lg font-medium mb-6">
                                                Activity Log
                                            </div>
                                            <div id="table-wrapper">
                                                <div id="table-scroll">
                                                    <table className="border-2 w-full">
                                                        <thead className="border-2">
                                                            <tr className="border-2">
                                                                <th className="border-2 p-2">
                                                                    Date
                                                                </th>
                                                                <th className="border-2 p-2">
                                                                    Time
                                                                </th>
                                                                <th className="border-2 p-2">
                                                                    Activity
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        {activityLogData?.map(data => (
                                                            <tbody key={data.id}>
                                                                <tr>
                                                                    <td className="border-2 p-2">
                                                                        {data?.activityDate}
                                                                    </td>
                                                                    <td className="border-2 p-2">
                                                                        {data?.activityTime}
                                                                    </td>
                                                                    <td className="border-2 p-2">
                                                                        {data?.userAction}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        ))}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
                {careerProgressUpdatedVisible && (
                    <CareerProgressUpdatedSuccessfullyModal
                        modalVisible={careerProgressUpdatedVisible}
                        setModalVisible={setCareerProgressUpdatedVisible}
                        setCareerProgressAdded={setCareerProgressAdded}
                        setCareerProgressDeleted={setCareerProgressDeleted}
                        careerProgressDeleted={careerProgressDeleted}
                    />
                )}
                {studentUpdateSuccess && (
                    <StudentSuccessfullModal
                        modalVisible={trackUpdateSuccessVisible}
                        setModalVisible={setTrackUpdateSuccessVisible}
                    />
                )}
                {editStudentError && (
                    <StudentSuccessfullModal
                        modalVisible={trackErrorVisible}
                        setModalVisible={setTrackErrorVisible}
                    />
                )}
                {deleteConfirmation && (
                    <CareerProgressDeleteConfirmationModal
                        student={student}
                        progressID={deleteProgressID}
                        modalVisible={deleteConfirmation}
                        setModalVisible={setDeleteConfirmation}
                        setCareerProgressDeleted={setCareerProgressDeleted}
                    />
                )}
                {addCareerProgressVisible && (
                    <AddCareerProgressModal
                        modalVisible={addCareerProgressVisible}
                        setModalVisible={setAddCareerProgressVisible}
                        student={student}
                        setCareerProgressAdded={setCareerProgressAdded}
                    />
                )}
                {submissionAnswerModalVisible && (
                    <ViewSubmissionAnswers
                        modalVisible={submissionAnswerModalVisible}
                        setModalVisible={setSubmissionAnswerModalVisible}
                        submissionsData={submissionsData}
                    />
                )}
            </div>
        </div>
    );
}
