import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { SLASSCOM_ADMIN, SUPER_ADMIN, UserProfileType } from '../../redux/authAdmin/reducers';
import { getUserProfile } from '../../utils/storage';

const SidebarLink = styled(Link)`
    display: flex;
    color: #4d4e52;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 10px;
    list-style: none;
    height: 5.5vh;
    text-decoration: none;
    font-size: 18px;
    &:hover {
        background: #d1d5db;
        cursor: pointer;
    }
`;
const DropdownLink = styled(Link)`
    height: 5.5vh;
    padding-left: 2rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #4d4e52;
    font-size: 18px;
    &:hover {
        background: #c4c1cc;
        cursor: pointer;
    }
`;
interface Props {
    item: any;
}
const SubMenu: React.FC<Props> = ({ item }) => {
    const location = useLocation();
    const [subnav, setSubnav] = useState(item.path === location.pathname);
    const user: UserProfileType | any = getUserProfile();
    const isSuperAdmin = user?.roles?.[0] === SUPER_ADMIN;
    const isSlasscomAdmin = user?.roles?.[0] === SLASSCOM_ADMIN;
    const showSubnav = () => setSubnav(!subnav);
    return (
        <>
            <SidebarLink
                to={item.path}
                onClick={item?.subNav && showSubnav}
                className={`${
                    item?.path === location?.pathname
                        ? 'border-l-4 border-blue-600 bg-gray-300'
                        : ''
                }`}
            >
                <div className="flex items-center">
                    {item.icon}
                    <span className="mx-6">{item.title}</span>
                </div>
                <div>
                    {(isSuperAdmin || isSlasscomAdmin) && item.subNav && subnav
                        ? item.iconOpened
                        : (isSuperAdmin || isSlasscomAdmin) && item.subNav
                        ? item.iconClosed
                        : null}
                </div>
            </SidebarLink>
            <div>
                {(item?.subNav?.[1]?.path === location?.pathname || subnav) &&
                    (isSuperAdmin || isSlasscomAdmin) &&
                    item?.subNav?.map(
                        (
                            object: { path: any; icon: any; title: any },
                            index: React.Key | null | undefined
                        ) => {
                            return (
                                <DropdownLink
                                    to={object.path}
                                    key={index}
                                    className={`${
                                        object?.path === location?.pathname
                                            ? 'border-l-4 border-blue-600 bg-gray-300'
                                            : ''
                                    }`}
                                >
                                    <div className="pl-8 flex items-center">
                                        {object.icon}
                                        <span className={`ml-4`}>{object.title}</span>
                                    </div>
                                </DropdownLink>
                            );
                        }
                    )}
            </div>
        </>
    );
};

export default SubMenu;
