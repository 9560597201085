import {
    ADD_TAB,
    ADD_TAB_FAILED,
    ADD_TAB_SUCCESS,
    GET_TRACK_TAB_SUCCESS,
    GET_TRACK_TAB,
    GET_TRACK_TAB_FAILED,
} from './actions';

export interface CountType {
    PUBLISHED: number;
    UNPUBLISHED: number;
    DELETED: number;
    ALL: number;
}

export interface TracksType {
    id: number;
    trackId: string;
    trackName: string;
    trackCreatedBy: string;
    trackCreatedDate: string;
    trackLastUpdatedBy: string;
    trackLastUpdatedDate: string;
    trackDescription: string;
    trackStatus: string;
    challenges: any;
    imageLink: string;
    users: any;
    acTemplate: string;
    feedback: string;
    certificate: any;
    tags?: Tags[];
}

export interface Tags {
    id: number;
    systemTag: boolean;
    tagCategory: string;
    tagCreatedBy: string;
    tagCreatedDate: string;
    tagLastUpdatedBy: string;
    tagLastUpdatedDate: string;
    tagName: string;
}
export interface TracksTabInitialState {
    trackData: TracksType[];
    pageCount: number;
    trackTabData: [];
    count: CountType;
    isLoading: boolean;
    trackError: string | null;
    createTrackError: string | null;
    trackCreateSuccessMessage: string | null;
    trackUpdateSuccessMessage: string | null;
    trackDeleteSuccessMessage: string | null;
    isCreateLoading: boolean;
    isEditLoading: boolean;
    editTrackError: string | null;
    isDeleteLoading: boolean;
    isBulkUpdateLoading: boolean;
    bulkError: string | null;
    trackBulkSuccessMessage: string | null;
    deleteTrackError: string | null;
}

const initialState: TracksTabInitialState = {
    trackData: [],
    pageCount: null,
    trackTabData: [],
    count: null,
    isLoading: false,
    trackError: null,
    createTrackError: null,
    trackCreateSuccessMessage: null,
    trackUpdateSuccessMessage: null,
    trackDeleteSuccessMessage: null,
    isCreateLoading: false,
    isEditLoading: false,
    editTrackError: null,
    isDeleteLoading: false,
    isBulkUpdateLoading: false,
    bulkError: null,
    trackBulkSuccessMessage: null,
    deleteTrackError: null,
};

const tracksTabReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_TAB:
            return { ...state, isLoading: true, trackError: null };
        case ADD_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                trackData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case ADD_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        case GET_TRACK_TAB:
            return { ...state, isLoading: true, trackError: null };
        case GET_TRACK_TAB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.counts,
                trackTabData: action.payload,
                pageCount: action.payload.totalPages,
            };
        case GET_TRACK_TAB_FAILED:
            return { ...state, isLoading: false, trackError: action.payload };
        default:
            return state;
    }
};

export default tracksTabReducer;
