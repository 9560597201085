import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Camera from '../../../assets/svg/Camera';
import { useDispatch, useSelector } from 'react-redux';
import {
    addTrackAndCertificate,
    clearTrackMessages,
    getPdfUrl,
    getPresignedUrl,
    getTags,
    TrackAndCertificateCreateType,
} from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../assets/svg/Loader';
import { useHistory } from 'react-router-dom';
import {
    trackImageResolutionError,
    baseCertificateTemplatePath,
    moduleNames,
    s3Config,
} from '../../../constants';
import { downloadFromExternalLink } from '../../../utils/downloadContent';
import S3 from 'react-aws-s3';
import TrackSuccessfullModal from '../../modals/TrackSuccessfullModal';
import * as HiIcons from 'react-icons/hi';
import * as FiIcons from 'react-icons/fi';
import CropModal from '../../modals/cropModal';
import { categorizeTags } from '../../../utils/tagCategorize';
import TagGroups from '../../TagGroups';

export default function AddNewTrack() {
    const maxChars = 200;
    const dispatch = useDispatch();
    const history = useHistory();
    const newFileName = Date.now().toString();
    const isCreateLoading = useSelector((state: RootState) => state.tracks.isCreateLoading);
    const createTrackError = useSelector((state: RootState) => state.tracks.createTrackError);
    const trackCreateSuccess = useSelector(
        (state: RootState) => state.tracks.trackCreateSuccessMessage
    );
    const isImageUploading = useSelector((state: RootState) => state.certificates.isImageUploading);
    const imageError = useSelector((state: RootState) => state.certificates.imageError);
    const certificateTemplateLink = useSelector((state: RootState) => state.certificates.pdfLink);

    const formRef = useRef<any>();
    const [imageUrl, setImageUrl] = useState('');
    const [imageLocation, setImageLocation] = useState('');
    const [resolutionError, setResolutionError] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [trackCreateSuccessVisible, setTrackCreateSuccessVisible] = useState(false);
    const [trackErrorVisible, setTrackErrorVisible] = useState(false);
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [tags, setTags] = useState<any>([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showAddTrackSection, setShowAddTrackSection] = useState(true);
    const [fileLocation, setFileLocation] = useState('');
    const [input, setInput] = useState<TrackAndCertificateCreateType>(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const fileName = baseCertificateTemplatePath.split('/', 3);
    const [openCrop, setOpenCrop] = useState(false);
    const [tagsHelpText, setTagsHelpText] = useState<boolean>(false);

    const [touchedCertificate, setTouchedCertificate] = useState(false);

    const validationSchemaTracks = Yup.object({
        trackName: Yup.string()
            .max(100, 'Name should be less than 100 characters')
            .required('Name is required'),
        trackDescription: Yup.string()
            .max(500, 'Description should be less than 500 characters')
            .required('Description is required'),
        image: Yup.array().required('Track image is required'),
    });

    const validationSchemaCertificate = Yup.object({
        certificateName: Yup.string()
            .max(100, 'Name should be less than 100 characters')
            .required('Name is required'),
        description: Yup.string().max(maxChars, 'Description should be less than 500 characters'),
        file: Yup.array().required('Template is required'),
    });

    const serchTagNameOrId = e => {
        setSearchTerm(e.target.value);
    };
    useEffect(() => {
        dispatch(getPdfUrl(fileName?.[2]));
    }, [dispatch]);

    useEffect(() => {
        if (showDropdown) {
            dispatch(getTags(true, moduleNames.Tracks, searchTerm));
        }
    }, [dispatch, searchTerm, showDropdown]);

    useEffect(() => {
        setSelectedTags(categorizeTags(tags));
    }, [tags]);

    const handleClearMessages = useCallback(() => {
        if (trackCreateSuccess) {
            setTrackCreateSuccessVisible(true);
        }
        if (createTrackError) {
            setTrackErrorVisible(true);
        }
    }, [dispatch, trackCreateSuccess, createTrackError, history]);
    const handleClose = () => {
        dispatch(clearTrackMessages());
        if (!createTrackError) {
            history.push('/admin/tracks/');
        }
    };
    useEffect(() => {
        handleClearMessages();
    }, [handleClearMessages]);

    const uploadImageToS3 = async croppedImg => {
        setIsUploading(true);
        s3Config.dirName = `tracks/${newFileName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(croppedImg, newFileName)
            .then(data => {
                setImageLocation(data.key);
                setIsUploading(false);
            })
            .catch(err => {
                console.error(err);
                setIsUploading(false);
            });
    };
    const handleImage = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setImageUrl(URL.createObjectURL(file));
            setOpenCrop(true);
        }
    };
    const handleCloseCropModal = () => {
        setOpenCrop(false);
        setImageUrl('');
    };
    const handleFile = (e: any) => {
        const _URL = window.URL || window.webkitURL;
        let file: File, img;
        if ((file = e.target.files[0])) {
            img = new Image();
            img.src = _URL.createObjectURL(file);
            setFileUrl(img.src);
        }
    };

    const uploadFileToS3 = async e => {
        const location = await dispatch(
            getPresignedUrl(e.target.files[0]?.name, e.target.files[0])
        );
        const locationArray = location?.toString().split('/', 6);
        setFileLocation(`tracks/certificates/${locationArray?.[5]}/${locationArray?.[5]}.html`);
    };

    const saveInput = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
            if (formRef.current.isValid) {
                setShowAddTrackSection(false);
            }
        }
    };

    const trackCreateSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
            if (formRef.current?.values?.certificateName && fileLocation) {
                dispatch(
                    addTrackAndCertificate({
                        trackName: input.trackName.trim(),
                        trackCreatedBy: '',
                        trackDescription: input.trackDescription.trim(),
                        imageLink: imageLocation,
                        trackStatus: input.trackStatus,
                        tags: tags,
                        certificate: {
                            certificateName: formRef.current?.values?.certificateName.trim(),
                            description: formRef.current?.values?.description.trim(),
                            contentDocUrl: fileLocation,
                            certStatus: input.trackStatus === 'UNPUBLISHED' ? 'INACTIVE' : 'ACTIVE',
                            certificateSize: Math.floor(
                                formRef.current?.values?.file[0]?.size / 1024
                            ),
                        },
                    })
                );
            }
        }
    };

    return (
        <div className="mx-6 lg:ml-0 h-full">
            <div className="mt-12 bg-snow-600">
                <div className="mt-12">
                    <div className="relative">
                        <Formik
                            innerRef={formRef}
                            initialValues={{
                                trackName: '',
                                trackCreatedBy: '',
                                trackDescription: '',
                                imageLink: '',
                                image: undefined,
                                trackStatus: 'UNPUBLISHED',
                                certificateName: '',
                                contentDocUrl: '',
                                file: undefined,
                                certStatus: 'ACTIVE',
                                track: '',
                                description: '',
                            }}
                            validationSchema={
                                showAddTrackSection
                                    ? validationSchemaTracks
                                    : validationSchemaCertificate
                            }
                            onSubmit={values => setInput(values)}
                            validateOnChange={true}
                            validateOnBlur={false}
                            validateOnMount
                        >
                            {({ handleChange, setFieldValue, values, errors, touched }) => (
                                <>
                                    <div className="flex justify-between items-center mb-4 md:mb-6">
                                        <p className="text-xl font-semibold text-left">
                                            Add New Track
                                        </p>
                                        <div className="flex w-1/2 md:w-1/4 lg:pl-8 h-16">
                                            <button
                                                className="md:py-3 ml-2 lg:ml-4 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-900 text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                onClick={() => history.push('/admin/tracks/')}
                                            >
                                                Cancel
                                            </button>
                                            {showAddTrackSection ? (
                                                <button
                                                    type="submit"
                                                    className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                    onClick={() => {
                                                        saveInput();
                                                    }}
                                                >
                                                    Next
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="md:py-3 ml-2 lg:ml-4 bg-blue-700 text-white text-sm rounded-sm w-full md:w-1/2 my-2 focus:outline-none"
                                                    onClick={() => {
                                                        trackCreateSubmit();
                                                        setTouchedCertificate(true);
                                                    }}
                                                >
                                                    {isCreateLoading ? <Loader /> : 'Save'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="border-2 border-gray-300 px-6 grid grid-cols-12 md:grid-cols-5 gap-4 md:gap-8 pt-2 pb-8">
                                        {showAddTrackSection ? (
                                            <>
                                                <div className="col-span-12 md:col-span-3 mb-2 md:mb-4">
                                                    <div className="text-lg font-medium mb-6">
                                                        Track Details
                                                    </div>

                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="title"
                                                            className={`flex items-start w-1/2 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Track Name
                                                            <div className="text-red-600">*</div>
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.trackName &&
                                                                    touched.trackName
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="name"
                                                                type="text"
                                                                value={values.trackName}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'trackName',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.trackName &&
                                                            touched.trackName ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.trackName}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex">
                                                        <label
                                                            className={`flex items-start w-1/2 text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Track status
                                                        </label>
                                                        <div className="flex text-sm md:text-base items-center w-full">
                                                            <input
                                                                className="mr-1"
                                                                type="radio"
                                                                value="PUBLISHED"
                                                                id="publish"
                                                                name="status"
                                                                onChange={handleChange(
                                                                    'trackStatus'
                                                                )}
                                                                disabled
                                                            />
                                                            <label className="mr-4 md:mr-8">
                                                                Published
                                                            </label>
                                                            <input
                                                                className="mr-1"
                                                                type="radio"
                                                                value="UNPUBLISHED"
                                                                defaultChecked={true}
                                                                id="unpublish"
                                                                name="status"
                                                                onChange={handleChange(
                                                                    'trackStatus'
                                                                )}
                                                                disabled
                                                            />
                                                            <label className="">Unpublished</label>
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex h-24">
                                                        <label
                                                            htmlFor="description"
                                                            className={`flex items-start w-1/2 text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Track description
                                                            <div className="text-red-600">*</div>
                                                        </label>
                                                        <div className="flex flex-col w-full">
                                                            <textarea
                                                                className={`resize-none focus:outline-none p-2 w-4/5 border-2 text-sm h-24 focus:border-blue-900 ${
                                                                    errors.trackDescription &&
                                                                    touched.trackDescription
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                }`}
                                                                id="description"
                                                                value={values.trackDescription}
                                                                placeholder=""
                                                                onChange={handleChange(
                                                                    'trackDescription'
                                                                )}
                                                            />
                                                            {errors.trackDescription &&
                                                            touched.trackDescription ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.trackDescription}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex">
                                                        <label
                                                            htmlFor="description"
                                                            className={`flex items-start w-1/2 text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Track Image
                                                            <div className="text-red-600">*</div>
                                                        </label>
                                                        <div
                                                            className={`bg-snow-600 w-full mb-2 md:mb-4 border-dashed relative ${
                                                                imageUrl ? 'w-fit' : ''
                                                            }  border-opacity-100 border-black-600 border-2 ${
                                                                errors.image && touched.image
                                                                    ? 'border-red-500'
                                                                    : 'border-black-600'
                                                            }`}
                                                        >
                                                            <div className="text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-44 md:h-64">
                                                                {!imageUrl && (
                                                                    <div className="w-full">
                                                                        <div className="flex justify-center">
                                                                            <Camera />
                                                                        </div>
                                                                        <div>
                                                                            <label
                                                                                className="underline cursor-pointer"
                                                                                htmlFor="imageLink"
                                                                            >
                                                                                Upload Image
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {imageUrl && (
                                                                    <img
                                                                        src={imageUrl}
                                                                        alt={'name'}
                                                                        className="h-44 lg:h-64"
                                                                        style={{ maxHeight: 300 }}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="absolute mt-1 text-gray-400 text-sm font-medium leading-149">
                                                                Image size 250*159
                                                            </div>
                                                            <div
                                                                className="absolute mt-8 text-center"
                                                                style={{
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%,-50%)',
                                                                }}
                                                            >
                                                                {imageUrl && !isUploading && (
                                                                    <div>
                                                                        <label
                                                                            className="underline cursor-pointer"
                                                                            htmlFor="imageLink"
                                                                        >
                                                                            Change Image
                                                                        </label>
                                                                    </div>
                                                                )}
                                                                {isUploading && (
                                                                    <div className="flex items-center">
                                                                        <div className="mr-2">
                                                                            Uploading
                                                                        </div>
                                                                        <div>
                                                                            <Loader />
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <div className="text-red-500 text-xs my-1">
                                                                    {resolutionError
                                                                        ? trackImageResolutionError
                                                                        : touched.image
                                                                        ? errors.image
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <input
                                                            className={`focus:outline-none w-full focus:border-blue-900`}
                                                            id="imageLink"
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            onChange={async e => {
                                                                const file = [];
                                                                file.push(e.target.files[0]);
                                                                setFieldValue('image', file);
                                                                handleImage(e);
                                                            }}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-span-12 md:col-span-2 my-4 md:mt-20 lg:mx-4 lg:pt-6">
                                                    <div className="flex border-2 flex-col ml-2 justify-items-center">
                                                        <div className="flex flex-row mt-2 border-b-2 pb-2 justify-between">
                                                            <div className="flex sm:text-sm md:text-base  pl-2 lg:pl-4 ">
                                                                <div>Assign Tags</div>
                                                                <div>
                                                                    <HiIcons.HiQuestionMarkCircle
                                                                        onClick={() => {
                                                                            setTagsHelpText(true);
                                                                        }}
                                                                        className="ml-2 mt-1 cursor-pointer"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {tagsHelpText ? (
                                                                <div
                                                                    onMouseOverCapture={() => {
                                                                        setTagsHelpText(true);
                                                                        // setProfileDropdownVisible(false);
                                                                    }}
                                                                    onMouseLeave={() =>
                                                                        setTagsHelpText(false)
                                                                    }
                                                                    className="bg-white inputFieldThickness absolute z-10 px-4 py-2 mt-6 right-12"
                                                                >
                                                                    Assign already created tags to
                                                                    the new record created.
                                                                </div>
                                                            ) : null}
                                                            <div
                                                                className="sm:text-sm md:text-base text-gray-400 underline pr-2 cursor-pointer"
                                                                onClick={() => {
                                                                    setTags([]);
                                                                }}
                                                            >
                                                                Clear All
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col my-1.5 px-2 lg:pl-4">
                                                            <div className="relative w-1/2 md:w-full lg:w-full flex flex-col">
                                                                <TagGroups
                                                                    selectedTags={selectedTags}
                                                                    tags={tags}
                                                                    setTags={setTags}
                                                                />

                                                                <div
                                                                    onMouseLeave={() => {
                                                                        setShowDropdown(false);
                                                                    }}
                                                                >
                                                                    <input
                                                                        className={`rounded w-full text-gray-500 font-medium focus:outline-none mt-2 p-2 border-2 text-base focus:border-blue-600 border-gray-300 md:text-base`}
                                                                        id="id"
                                                                        type="text"
                                                                        placeholder="Search tags"
                                                                        onChange={e =>
                                                                            serchTagNameOrId(e)
                                                                        }
                                                                        onMouseEnter={() => {
                                                                            setShowDropdown(true);
                                                                        }}
                                                                        autoComplete="off"
                                                                    />
                                                                    {showDropdown &&
                                                                    tagData?.length ? (
                                                                        <ul
                                                                            className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                            role="menu"
                                                                            onMouseLeave={() =>
                                                                                setShowDropdown(
                                                                                    false
                                                                                )
                                                                            }
                                                                        >
                                                                            {tagData?.map(tag => (
                                                                                <div
                                                                                    className="p-2 hover:bg-gray-200 cursor-pointer"
                                                                                    key={tag.id}
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            tags
                                                                                                ?.map(
                                                                                                    checktag =>
                                                                                                        checktag.tagName
                                                                                                )
                                                                                                .includes(
                                                                                                    tag.tagName
                                                                                                ) ===
                                                                                            false
                                                                                        ) {
                                                                                            setTags(
                                                                                                [
                                                                                                    ...tags,
                                                                                                    tag,
                                                                                                ]
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {tag.tagCategory +
                                                                                        ': ' +
                                                                                        tag.tagName}
                                                                                </div>
                                                                            ))}
                                                                        </ul>
                                                                    ) : showDropdown &&
                                                                      tagData.length === 0 ? (
                                                                        <ul
                                                                            className="absolute w-full pl-0 left-0 z-10 pr-0 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                            role="menu"
                                                                            onMouseLeave={() =>
                                                                                setShowDropdown(
                                                                                    false
                                                                                )
                                                                            }
                                                                        >
                                                                            <div className="p-2 flex justify-center font-medium text-gray-400">
                                                                                No records found
                                                                            </div>
                                                                        </ul>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" ml-2 justify-items-center">
                                                        <div className="flex flex-col text-sm pl-2 lg:pl-4 mt-6 space-y-4 text-gray-400 ">
                                                            <div>
                                                                Created tags can be retrieved by
                                                                typing the text in the search bar.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-span-12 md:col-span-4 mb-2 md:mb-4">
                                                    <div className="text-lg font-medium mb-6">
                                                        Certificate Details
                                                    </div>
                                                    <div className="mb-6 w-full md:mb-0 flex my-4">
                                                        <label
                                                            htmlFor="title"
                                                            className={`flex items-start w-1/3 font-medium leading-149 text-sm md:text-base text-gray-700`}
                                                        >
                                                            Certificate Name
                                                            <div className="text-red-600">*</div>
                                                        </label>

                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 ${
                                                                    errors.certificateName &&
                                                                    touchedCertificate
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                } md:text-base`}
                                                                id="name"
                                                                type="text"
                                                                value={values.certificateName}
                                                                onChange={e => {
                                                                    setFieldValue(
                                                                        'certificateName',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            {errors.certificateName &&
                                                            touchedCertificate ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    {errors.certificateName}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex">
                                                        <label
                                                            className={`flex items-start w-1/3 text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Related Track
                                                            <div className="text-red-600">*</div>
                                                        </label>
                                                        <div className="flex flex-col w-full">
                                                            <input
                                                                className={`rounded-sm flex items-center focus:outline-none w-3/4 md:w-4/5 p-2 border-2 text-base focus:border-blue-900 border-gray-300 md:text-base`}
                                                                id="name"
                                                                type="text"
                                                                value={values.trackName}
                                                                autoComplete="off"
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex h-24">
                                                        <label
                                                            htmlFor="description"
                                                            className={`flex items-start w-1/3 text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Certificate Description
                                                        </label>
                                                        <div className="flex flex-col w-full">
                                                            <textarea
                                                                className={`resize-none focus:outline-none p-2 w-4/5 border-2 md:text-base h-20 focus:border-blue-900 ${
                                                                    errors.description &&
                                                                    touchedCertificate
                                                                        ? 'border-red-500'
                                                                        : 'border-gray-300'
                                                                }`}
                                                                id="description"
                                                                value={values.description}
                                                                placeholder=""
                                                                onChange={handleChange(
                                                                    'description'
                                                                )}
                                                            />
                                                            <div className="w-4/5">
                                                                <p className="text-gray-500 text-sm text-right">
                                                                    ({values.description?.length} /{' '}
                                                                    {maxChars})
                                                                </p>
                                                            </div>
                                                            {errors.description &&
                                                                touchedCertificate && (
                                                                    <div className="text-red-500 text-xs my-1">
                                                                        {errors.description}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className="my-6 w-full md:mb-0 flex">
                                                        <label
                                                            htmlFor="description"
                                                            className={`flex items-start w-1/3 text-sm font-medium leading-149 md:text-base text-gray-700`}
                                                        >
                                                            Certificate Template
                                                            <div className="text-red-600">*</div>
                                                        </label>
                                                        <div
                                                            className={` w-full mb-2 md:mb-4  relative ${
                                                                fileUrl ? 'w-fit' : ''
                                                            }  `}
                                                        >
                                                            <div className="bg-gray-100 border w-4/5 border-opacity-100 border-black-600 text-base justify-center flex items-center font-normal text-black-600 text-center self-center h-20 md:h-32">
                                                                {!fileUrl && (
                                                                    <div className="w-full">
                                                                        <div className="flex justify-center">
                                                                            <FiIcons.FiUpload
                                                                                className=""
                                                                                size="30px"
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <label
                                                                                className="text-sm font-medium leading-149 md:text-normal text-gray-600 cursor-pointer underline"
                                                                                htmlFor="imageLink"
                                                                            >
                                                                                Upload Certificate
                                                                                Template
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {fileUrl && (
                                                                    <div>
                                                                        <p>
                                                                            File name :{' '}
                                                                            {values.file[0]?.name}
                                                                        </p>
                                                                        <p>
                                                                            File size :{' '}
                                                                            {Math.floor(
                                                                                values.file[0]
                                                                                    ?.size / 1024
                                                                            ) + 'KB'}
                                                                        </p>
                                                                        <div className="mt-1 text-red-400 text-sm flex justify-center">
                                                                            {imageError}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="flex justify-between w-4/5">
                                                                <div className="mt-1 text-gray-400 text-sm font-medium leading-149">
                                                                    Upload format (html)
                                                                </div>

                                                                <button
                                                                    className="mt-1 p-2 bg-snow-900 border-2 border-blue-800 border-opacity-40 text-blue-800 text-sm font-medium leading-149"
                                                                    onClick={() =>
                                                                        downloadFromExternalLink(
                                                                            certificateTemplateLink,
                                                                            'BaseCertificate'
                                                                        )
                                                                    }
                                                                >
                                                                    Download base certificate
                                                                    template
                                                                </button>
                                                            </div>

                                                            <div
                                                                className="absolute md:mt-0 mb-4 pt-8 text-center"
                                                                style={{
                                                                    left: '40%',
                                                                    top: '100%',
                                                                    transform:
                                                                        'translate(-50%,-50%)',
                                                                }}
                                                            >
                                                                {fileUrl && !isImageUploading && (
                                                                    <div>
                                                                        <label
                                                                            className="underline cursor-pointer mt-4"
                                                                            htmlFor="imageLink"
                                                                        >
                                                                            Change Certificate
                                                                            Template
                                                                        </label>
                                                                    </div>
                                                                )}
                                                                {isImageUploading && (
                                                                    <div className="flex items-center">
                                                                        <div className="mr-2">
                                                                            Uploading
                                                                        </div>
                                                                        <div>
                                                                            <Loader />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {errors.file &&
                                                                    touchedCertificate && (
                                                                        <div className="text-red-500 text-xs my-1">
                                                                            {errors.file}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </div>
                                                        <input
                                                            className={`focus:outline-none w-full focus:border-blue-900`}
                                                            id="imageLink"
                                                            type="file"
                                                            accept=".html,"
                                                            onChange={async e => {
                                                                const file = [];
                                                                file.push(e.target.files[0]);
                                                                setFieldValue('file', file);
                                                                handleFile(e);
                                                                uploadFileToS3(e);
                                                            }}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {trackCreateSuccess && (
                                        <TrackSuccessfullModal
                                            modalVisible={trackCreateSuccessVisible}
                                            handleClose={handleClose}
                                        />
                                    )}
                                    {createTrackError && (
                                        <TrackSuccessfullModal
                                            modalVisible={trackErrorVisible}
                                            handleClose={handleClose}
                                        />
                                    )}
                                    <CropModal
                                        ratio={1}
                                        photoURL={imageUrl}
                                        setPhotoURL={setImageUrl}
                                        setOpenCrop={setOpenCrop}
                                        openCrop={openCrop}
                                        handleCloseCropModal={handleCloseCropModal}
                                        uploadImageToS3={uploadImageToS3}
                                    />
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
