import axios from 'axios';
import { API_URL } from '../../constants';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { clearAdminLocalStorage, storeAuthCreds } from '../../utils/storage';
import { UserProfileType } from './reducers';

const PREFIX = '@AUTH_';

export const LOGIN = PREFIX + 'LOGIN';
export const LOGIN_SUCCESS = PREFIX + 'LOGIN_SUCCESS';
export const LOGIN_FAILED = PREFIX + 'LOGIN_FAILED';
export const PASSWORD_RESET = PREFIX + 'PASSWORD_RESET';
export const PASSWORD_RESET_SUCCESS = PREFIX + 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILED = PREFIX + 'PASSWORD_RESET_FAILED';
export const SEND_EMAIL_TO_RESET_PASSWORD = PREFIX + 'SEND_EMAIL_TO_RESET_PASSWORD';
export const SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS = PREFIX + 'SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS';
export const SEND_EMAIL_TO_RESET_PASSWORD_FAILED = PREFIX + 'SEND_EMAIL_TO_RESET_PASSWORD_FAILED';
export const LOGOUT = PREFIX + 'LOGOUT';

export const CLEAR_AUTH = PREFIX + 'CLEAR_AUTH';

export const handleTokens = (response: UserProfileType) => {
    const user = {
        token: response.token,
        refreshToken: response?.refreshToken,
        username: response.username,
        userId: response.userId,
        email: response.email,
        roles: response.roles,
        companyId: response.companyId,
        companyName: response.companyName,
        instituteId: response.instituteId,
        instituteName: response.instituteName,
    };
    storeAuthCreds(user);
};

export const loginAdmin = (email: string, password: string) => async (dispatch: any) => {
    try {
        dispatch({ type: LOGIN });
        const response = await axios.post(`${API_URL}/users/signin`, {
            userName: email,
            password: password,
        });

        if (response?.data?.errorCode === 0) {
            handleTokens(response.data.responseDto);
            dispatch({ type: LOGIN_SUCCESS, payload: response.data.responseDto });
        }
        if (response?.data?.errorCode !== 0) {
            dispatch({ type: LOGIN_FAILED, payload: response.data.errorDescription });
        }
    } catch (error) {
        dispatch({ type: LOGIN_FAILED, payload: getErrorMessage(error) });
    }
};
export const resetPassword = (token: string, password: string) => async (dispatch: any) => {
    try {
        dispatch({ type: PASSWORD_RESET });
        const response = await axios.post(`${API_URL}/users/admin/reset?token=${token}`, {
            password: password,
        });
        if (response?.data?.errorCode === 223) {
            dispatch({ type: PASSWORD_RESET_SUCCESS, payload: response.data.responseDto });
        }
        if (response?.data?.errorCode !== 223) {
            dispatch({ type: PASSWORD_RESET_FAILED, payload: response.data.errorDescription });
        }
    } catch (error) {
        dispatch({ type: PASSWORD_RESET_FAILED, payload: getErrorMessage(error) });
    }
};
export const sendEmailToResetPassword = (email: string) => async (dispatch: any) => {
    try {
        dispatch({ type: SEND_EMAIL_TO_RESET_PASSWORD });
        const response = await axios.post(`${API_URL}/users/forget/password`, { email: email });
        if (response?.data?.errorCode === 222) {
            dispatch({
                type: SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS,
                payload: response.data.responseDto,
            });
        }
        if (response?.data?.errorCode !== 222) {
            dispatch({
                type: SEND_EMAIL_TO_RESET_PASSWORD_FAILED,
                payload: response.data.errorDescription,
            });
        }
    } catch (error) {
        dispatch({ type: SEND_EMAIL_TO_RESET_PASSWORD_FAILED, payload: getErrorMessage(error) });
    }
};

export const logout = () => async (dispatch: any) => {
    try {
        dispatch({ type: LOGOUT });
        clearAdminLocalStorage();
        window.location.href = `${window.location.origin}/admin/signin`;
    } catch (error) {
        console.log('logout', getErrorMessage(error));
    }
};

export const clearAuth = () => (dispatch: any) => {
    dispatch({ type: CLEAR_AUTH });
};
